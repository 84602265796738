import styled from "styled-components";
import { StyledNumericWriteLine } from "../writeLine/NumericWriteLine.styles";


export const StyledNumericRange = styled.div<{
    _width?: string;
}>`
    display: flex;
    width: ${props => props._width ? props._width : "100%"};

    ${StyledNumericWriteLine} {
        flex: 1 1 auto;
        width: 0; // auto calculated by flex
    }
`;