import { TEntityKey } from "@odata/BindingContext";
import { EntitySetName, IDashboardTaskEntity } from "@odata/GeneratedEntityTypes";
import { OData } from "@odata/OData";
import { ODataQueryResult } from "@odata/ODataParser";
import {
    isDefined,
    isNotDefined,
    roundToDecimalPlaces,
    sortCompareBooleanFn,
    sortCompareFn,
    uuidv4
} from "@utils/general";

import { Sort } from "../../../enums";
import { getLocalTimeDate } from "../../../types/Date";


export interface ITodoListItem extends IDashboardTaskEntity {
}

// increment new sequence numbers by higher value, so there is enough space to put some items in between
const SequenceIncrement = 512;

export const isNewItemId = (id: TEntityKey): boolean => isNotDefined(id);
export const isNewItem = (item: ITodoListItem): boolean => isNewItemId(item?.Id);

export function createTodoListItem(allItems: ITodoListItem[], insertAfter?: ITodoListItem): ITodoListItem {
    let nextSequenceNumber: number;
    // Find two items in sequence and insert the new one between them or if insertAfter is not defined,
    // find the highest order number and inserts the new item before it. If allItems are empty array,
    // returns zero as first order number.
    allItems.forEach(item => {
        if ((!insertAfter || insertAfter.SequenceOrder > item.SequenceOrder) &&
            (isNotDefined(nextSequenceNumber) || item.SequenceOrder > nextSequenceNumber)) {
            nextSequenceNumber = item.SequenceOrder;
        }
    });
    switch (true) {
        case insertAfter && isDefined(nextSequenceNumber):
            // Two items have been found, we place the new one between
            nextSequenceNumber = roundToDecimalPlaces(4, (insertAfter.SequenceOrder + nextSequenceNumber) / 2.0);
            break;
        case !!insertAfter:
            // insertAfter is defined, but we haven't found any item after it - put it at the end
            nextSequenceNumber = insertAfter.SequenceOrder - SequenceIncrement;
            break;
        case isDefined(nextSequenceNumber):
            // insert at the beginning (increment highest found number
            nextSequenceNumber += SequenceIncrement;
            break;
        default:
            // nothing in the list, initialize the Sequence
            nextSequenceNumber = 0;
            break;
    }

    return {
        Id: null,
        Description: "",
        SequenceOrder: nextSequenceNumber,
        DateCreated: getLocalTimeDate(),
        TemporaryGuid: uuidv4(),
    };
}

// returns true if item is completed, but not recently - recently completed items should be rendered at the same place
function isCompleted(item: ITodoListItem, toBeCompleted: string[]): boolean {
    return item.DateCompleted && !toBeCompleted?.includes(item.TemporaryGuid);
}

export function sortTodoListItems(items: ITodoListItem[], toBeCompleted: string[]): ITodoListItem[] {
    return items.sort((a, b) => {
        const aCompleted = isCompleted(a, toBeCompleted),
            bCompleted = isCompleted(b, toBeCompleted);
        if (aCompleted && bCompleted) {
            return sortCompareFn(a.DateCompleted, b.DateCompleted, Sort.Desc);
        } else if (!aCompleted && !bCompleted) {
            return sortCompareFn(a.SequenceOrder, b.SequenceOrder, Sort.Desc);
        }
        return sortCompareBooleanFn(!a.DateCompleted, !b.DateCompleted);
    });
}


export function updateItemInList(items: ITodoListItem[], guid: string, data: Partial<ITodoListItem>): ITodoListItem[] {
    let changedItem: ITodoListItem;
    const restItems = items.filter(item => {
        if (item.TemporaryGuid === guid) {
            changedItem = item;
            return false;
        }
        return true;
    });
    changedItem = {
        ...changedItem,
        ...data
    };
    return [changedItem, ...restItems];
}

export function loadTodoListItems(oData: OData, signal?: AbortSignal): Promise<ITodoListItem[]> {
    return oData.getEntitySetWrapper(EntitySetName.DashboardTasks).query()
        .fetchData<IDashboardTaskEntity[]>(null, { signal })
        .then(res => res.value.map(item => ({ ...item, TemporaryGuid: uuidv4() })));
}

export async function saveItem(oData: OData, item: ITodoListItem, diff: Partial<ITodoListItem>): Promise<ITodoListItem> {
    const wrapper = oData.getEntitySetWrapper(EntitySetName.DashboardTasks);
    let res: ODataQueryResult;
    if (isNewItem(item)) {
        const { Description, DateCompleted, SequenceOrder, TemporaryGuid } = item;
        res = await wrapper.create({
            Description,
            DateCompleted,
            SequenceOrder,
            TemporaryGuid, ...diff
        }) as ODataQueryResult;
    } else {
        res = await wrapper.update(item.Id, diff) as ODataQueryResult;
    }
    return res?.value;
}

export async function removeItem(oData: OData, id: TEntityKey): Promise<void> {
    if (!isNewItemId(id)) {
        await oData.getEntitySetWrapper(EntitySetName.DashboardTasks).delete(id);
    }
}