import { FOCUS_MANAGER_WRAPPER_ATTR } from "@components/focusManager";
import { IDayInterval } from "@components/inputs/date/utils";
import Big from "big.js";
import { debounce, DebounceSettings } from "lodash";
import React from "react";

import { Sort } from "../enums";
import { TRecordAny, TValue } from "../global.types";
import { getUtcDate, getUtcDateBy, getUtcDayjs } from "../types/Date";
import NumberType from "../types/Number";
import memoizeOne from "./memoizeOne";
import { compareString } from "./string";
import { logger } from "@utils/log";

// sort array ascending
export const asc = (a: number, b: number): number => a - b;

// sort array descending
export const desc = (a: number, b: number): number => b - a;

// sort function for boolean values, true values goes first. For reverse order, you may use compare function.
export function sortCompareBooleanFn(a: boolean, b: boolean): number {
    return (!!b ? 1 : 0) - (!!a ? 1 : 0);
}

export function sortCompareFn<T = number | string | Date | boolean>(a: T, b: T, dir = Sort.Asc): number {
    const coeff = dir === Sort.Asc ? 1 : -1;

    if (typeof a === "boolean" && typeof b === "boolean") {
        return coeff * sortCompareBooleanFn(a, b);
    }

    if (typeof a === "number" || (a instanceof Date)) {
        return coeff * (((a ?? 0) as number) - (((b ?? 0) as unknown) as number));
    }
    return coeff * compareString((a as unknown) as string, (b as unknown) as string);
}

export const clamp = (number: number, min: number, max: number): number => {
    return Math.min(Math.max(number, min), max);
};

export const quantile = (arr: number[], q: number): number => {
    const sorted = arr.sort(asc);
    const pos = ((sorted.length) - 1) * q;
    const base = Math.floor(pos);
    const rest = pos - base;
    if ((sorted[base + 1] !== undefined)) {
        return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
    } else {
        return sorted[base];
    }
};

/**
 * Modulo function that always returns positive values
 */
export const mod = (n: number, m: number): number => {
    return ((n % m) + m) % m;
};

// returns cartesian multiplication of given array params
export function cartesian(...paramList: unknown[][]): unknown[][] {
    return paramList.reduce<unknown[][]>(
        (results, params) => {
            if (!params) {
                return results;
            }
            return results
                .map(result => params.map(entry => result.concat([entry])))
                .reduce((subResults, result) => subResults.concat(result), []);
        }, [[]]
    );
}

/**
 * For given length of string, returns the minimum and maximum possible number values
 * E.g. for length = 2, the function returns {min: 10, max: 99}
 *
 * Returns null for invalid values
 *
 * @param length
 * @returns {{min: number, max: number}} numberRange
 */
export const numberRangeFromStringLength = (length: number): { min: number, max: number } => {
    if (length <= 0) {
        return null;
    }

    return {
        min: Math.pow(10, length - 1) - (length === 1 ? 1 : 0),
        max: maxNumberFromMaxStringLength(length)
    };
};

/**
 * For given max length of string, returns maximum possible number value
 * E.g. for length = 2 returns 99
 *
 * Returns null for invalid values
 *
 * @param maxLength
 * @returns {number} maxNumber
 */
export const maxNumberFromMaxStringLength = (maxLength: number): number => {
    if (maxLength <= 0) {
        return null;
    }

    return Math.pow(10, maxLength) - 1;
};

export function arrayMove<T>(arr: T[], old_index: number, new_index: number): T[] {
    while (old_index < 0) {
        old_index += arr.length;
    }
    while (new_index < 0) {
        new_index += arr.length;
    }
    if (new_index >= arr.length) {
        let k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
}

// returns new array with inserted item
export function arrayInsert<T>(array: T[], item: T, index: number): T[] {
    return [...array.slice(0, index), item, ...array.slice(index)];
}

export function findLastRowItemInFlex(elem: HTMLElement): boolean[] {
    const childNodes = elem.children;
    const lastItemFlags = new Array(childNodes.length);
    let rowOffset = (childNodes[0] as HTMLElement).offsetTop;
    for (let i = 1; i < childNodes.length; i++) {
        const currentItemOffset = (childNodes[i] as HTMLElement).offsetTop;
        if (currentItemOffset !== rowOffset) {
            lastItemFlags[i - 1] = true;
            rowOffset = currentItemOffset;
        }
    }

    lastItemFlags[lastItemFlags.length - 1] = true;
    return lastItemFlags;
}

export function findItemFlexWidth(elem: HTMLElement): number {
    const childNodes = elem.children;
    const rowOffset = (childNodes[0] as HTMLElement).offsetTop;
    for (let i = 1; i < childNodes.length; i++) {
        const currentItemOffset = (childNodes[i] as HTMLElement).offsetTop;
        if (currentItemOffset !== rowOffset) {
            const previousItem = (childNodes[i - 1] as HTMLElement);
            return previousItem.offsetLeft + previousItem.offsetWidth;
        }
    }

    return 0;
}

export function forEachKey<T>(obj: T, callback: (key: keyof T) => void): void {
    for (const key of Object.keys(obj)) {
        if (obj.hasOwnProperty(key)) {
            callback(key as keyof T);
        }
    }
}

export const isObjectEmpty = (obj: unknown): boolean => {
    return !obj || (!(obj instanceof Date) && typeof obj === "object" && Object.keys(obj).length === 0);
};

export const getYearQuarter = (date: Date | string): number => {
    const month = getUtcDate(date).getMonth() + 1;

    return Math.floor(month / 3) + (month % 3 === 0 ? 0 : 1);
};

export const getWeekNumber = function(date: Date | string): number {
    date = getUtcDate(date);
    date.setHours(0, 0, 0, 0);
    // Thursday in current week decides the year.
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
    // January 4 is always in week 1.
    const week1 = getUtcDateBy(date.getFullYear(), 0, 4);
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
        - 3 + (week1.getDay() + 6) % 7) / 7);
};

export const handleRefHandlers = (ref: any, ...refHandlers: any[]): void => {
    for (const refHandler of refHandlers) {
        if (!refHandler) {
            continue;
        }

        if (typeof refHandler === "function") {
            refHandler(ref);
        } else {
            refHandler["current"] = ref;
        }
    }
};

// some neat TS trick to force composeRefHandlers to accept generic argument
interface IComposeRefHandlers {
    <T>(...refHandlers: React.Ref<T>[]): (element: T) => void;
}

export const composeRefHandlers: IComposeRefHandlers = memoizeOne(<T = HTMLElement, >(...refHandlers: React.Ref<T>[]): (element: T) => void => {
    return (element: T) => handleRefHandlers(element, ...refHandlers);
});


export enum TabIndex {
    NormalOrder = 0,
    Disabled = -1,
    TemporaryDisabled = -2
}

export const FOCUSABLE_INPUTS_SELECTORS = ["input:enabled", "select:enabled", "textarea:enabled"];
export const FOCUSABLE_ELEMENTS_SELECTORS = [...FOCUSABLE_INPUTS_SELECTORS, "button:enabled", "[href]", `[tabindex]:not([tabindex="${TabIndex.Disabled}"]):not([${FOCUS_MANAGER_WRAPPER_ATTR}=true])`];

export const FOCUSABLE_ELEMENTS_SELECTORS_WITHOUT_TEMPORARY_DISABLED = FOCUSABLE_ELEMENTS_SELECTORS
    .map(selector => selector.startsWith("[tabindex]") ? `${selector}:not([tabindex="${TabIndex.TemporaryDisabled}"])` : selector);

/** Returns focusable elements without elements that we deliberately don't ever have focus on. */
export const getFocusableElements = (rootElem: Element | HTMLElement): NodeListOf<HTMLElement> => {
    const selector = FOCUSABLE_ELEMENTS_SELECTORS_WITHOUT_TEMPORARY_DISABLED.join(", ");
    return rootElem?.querySelectorAll(selector);
};

/** Returns all focusable elements, without exception. Only use in specific cases. */
export const getAllFocusableElements = (rootElem: Element | HTMLElement): NodeListOf<HTMLElement> => {
    return rootElem?.querySelectorAll("button:enabled, [href], input:enabled, select:enabled, textarea:enabled, [tabindex]");
};

export const getFocusableInputs = (rootElem: Element | HTMLElement): NodeListOf<HTMLElement> => {
    const selector = FOCUSABLE_INPUTS_SELECTORS.join(", ");
    return rootElem?.querySelectorAll(selector);
};

export function getTemporaryDisabledFocusableElements(rootElem: Element | HTMLElement): NodeListOf<HTMLElement> {
    const selector = `[tabindex="${TabIndex.TemporaryDisabled}"]`;
    return rootElem?.querySelectorAll(selector);
}

export const focusNextElement = (currentElement: Element | HTMLElement): void => {
    if (currentElement) {
        const allFocusableNodes = getFocusableElements(document.body);

        for (const [i, node] of allFocusableNodes.entries()) {
            if (node.isSameNode(currentElement)) {
                (allFocusableNodes.item(i + 1) as HTMLElement)?.focus();
                break;
            }
        }
    }
};

export const doesElementContainsElement = (ref: Element, el: Element): boolean => {
    if (!ref) {
        return false;
    }

    return el instanceof Element && ref?.contains(el)
        // the target can get outside of DOM because React could have already done re-rendering
        && document.body.contains(el);
};

export const roundToDecimalPlaces = (places = 1, number: number): number => {
    if (isNaN(number) || !isFinite(number)) {
        return number;
    }
    try {
        const x = new Big(number).round(places);
        if (x.eq(0)) { // solves negative zero (eg. -9 * 0 = -0, but -0 === 0)
            return 0;
        }
        return x.toNumber();
    } catch(e) {
        logger.error(`roundToDecimalPlaces received invalid Number value: ${number}`, e);
        return number;
    }
};

// https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
export const uuidv4 = () => {
    /* eslint-disable no-mixed-operators */
    // @ts-ignore
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c: number) =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
    /* eslint-enable no-mixed-operators */
};

export const getRandomInt = (min: number, max: number): number => {
    return Math.floor((Math.random() * (max - min + 1)) + min);
};

export const getElementOuterHeight = (element: HTMLElement): number => {
    let height = element.offsetHeight;
    const style = getComputedStyle(element);

    height += parseInt(style.marginTop) + parseInt(style.marginBottom);
    return height;
};

export const isOverflowing = (element: HTMLElement, offset = 0): boolean => {
    return element && element.scrollWidth > element.clientWidth + offset;
};

export const isNotDefined = (element: unknown): element is (undefined | null) => {
    return element === undefined || element === null;
};

export const isDefined = (element: unknown): boolean => {
    return element !== undefined && element !== null;
};

/**
 * Returns result of function call or value itself if value is not callable
 * @param value
 * @param args
 */
export const getValue = <T>(value: ((...args: any[]) => T) | T, ...args: any[]): T => {
    if (typeof value === "function") {
        return (value as Function)(...args);
    }

    return value;
};

/** Compares simple (string, number, boolean, Date) values
 * Returns true if equal */
export const areSimpleValuesEqual = <T>(val1: T, val2: T): boolean => {
    if (val1 instanceof Date && val2 instanceof Date) {
        return (val1 as Date).getTime() === (val2 as Date).getTime();
    }

    return val1 === val2;
};

export function areObjectsEqual<T>(o1: T, o2: T, compareFields: string[] = o1 ? Object.keys(o1) : o2 ? Object.keys(o2) : []): boolean {
    if (!o1 || !o2) {
        // could be undefined/null
        return o1 === o2;
    }

    const o1Fixed = o1 as Record<string, unknown>;
    const o2Fixed = o1 as Record<string, unknown>;

    return !compareFields.some(field =>
        (isDefined(o1Fixed[field]) !== isDefined(o2Fixed[field]) || (isDefined(o1Fixed[field]) && o1Fixed[field] !== o2Fixed[field])));
}

// actually not sure why this exists instead of _.isEqual
export const areValuesEqual = <T>(val1: T, val2: T): boolean => {
    if ((typeof val1 === "object" || typeof val2 === "object") && !(val1 instanceof Date || val2 instanceof Date)) {
        return areObjectsEqual(val1 as Record<string, unknown>, val2 as Record<string, unknown>);
    }

    return areSimpleValuesEqual(val1, val2);
};

/**
 * Returns date range between start and end of the given day/s.
 * Used for filters that use DateTimeOffset but are only interested in the day value.
 *
 * @param {Date} dateTime
 * @param {Date} toDateTime
 * @returns {{from: Date, to: Date}} dateRange
 */
export const dateTimeToDateRange = (dateTime: Date | string, toDateTime?: Date): IDayInterval => {
    const from = getUtcDayjs(dateTime);
    const to = getUtcDayjs(toDateTime ?? dateTime);

    return {
        from: from.startOf("date").toDate(),
        to: to.endOf("day").toDate()
    };
};

export const isTwoPrimitiveArraysEqual = (arr1: unknown[], arr2: unknown[]): boolean => {
    if (!arr1 || !arr2) {
        // eslint-disable-next-line
        return arr1 == arr2;
    }

    if (arr1.length !== arr2.length) {
        return false;
    }

    for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) {
            return false;
        }
    }

    return true;
};

/** Simulate callback call repeat while mouse button is held down,
 * similar to what browser does automatically for keyDown event. */
export const mouseDownRepeat = (callback: () => void): void => {
    let isFirstTimeout = true;
    const firstDelay = 600;
    const delay = 60;
    let lastTimestamp: number;
    let shouldEnd = false;

    // use animation frame, otherwise the changes doesn't look smooth
    const handleHoldTimeout = (timestamp: number) => {
        if (shouldEnd) {
            return;
        }

        if (!lastTimestamp) {
            lastTimestamp = timestamp;
        }

        const currentDelay = isFirstTimeout ? firstDelay : delay;

        if (timestamp - lastTimestamp >= currentDelay) {
            callback();
            lastTimestamp = timestamp;
            isFirstTimeout = false;
        }

        requestAnimationFrame(handleHoldTimeout);
    };

    const handleMouseUp = () => {
        shouldEnd = true;
        document.removeEventListener("mouseup", handleMouseUp);
    };

    callback();
    document.addEventListener("mouseup", handleMouseUp);
    requestAnimationFrame(handleHoldTimeout);
};

export const deepFreeze = (obj: any) => {
    Object.freeze(obj);

    Object.getOwnPropertyNames(obj).forEach(function(prop) {
        if (obj.hasOwnProperty(prop)
            && obj[prop] !== null
            && (typeof obj[prop] === "object" || typeof obj[prop] === "function")
            && !Object.isFrozen(obj[prop])) {
            deepFreeze(obj[prop]);
        }
    });

    return obj;
};

/** Transform items from rows to columns order */
export function sortItemsInColumns<ItemType>(items: ItemType[], numColumns: number): ItemType[] {
    const orderedItems: ItemType[] = [];

    let base = 0;
    let columnFirstIndex = 0;

    for (let i = 0; i < items.length; i++) {
        if (i > 0 && i % numColumns === 0) {
            base += 1;
            columnFirstIndex = 0;
        }

        orderedItems[i] = items[columnFirstIndex + base];

        const rowsInThisColumn = Math.floor(items.length / numColumns) + (items.length % numColumns > i % numColumns ? 1 : 0);

        columnFirstIndex += rowsInThisColumn;
    }

    return orderedItems;
}

/** For range from 'min' to 'max' with 'step', returns the closest possible value in the range */
export const getClosestValueInRange = (value: number, min: number, max: number, step: number): number => {
    if (value <= min) {
        return min;
    }

    const stepModulo = value % step;
    const minModulo = min % step;
    const diff = stepModulo - minModulo;

    if (diff === 0) {
        return value > max ? max : value;
    }

    const delta = diff >= (step / 2) ? step - diff : -diff;

    if (value + delta > max) {
        // if value would get outside of range, return previous value in the range,
        // even though it isn't the closest
        return value - (diff < 0 ? step + diff : diff);
    }

    return value + delta;
};

/**
 * Return true if all values in array are defined and equal
 */
export const compareDefinedArrays = (arr1: unknown[], arr2: unknown[]): boolean => {
    if (!arr1 || !arr2) {
        return false;
    }

    for (let i = 0; i < arr1?.length; i++) {
        if (!compareDefined(arr1[i], arr2[i])) {
            return false;
        }
    }

    return true;
};

export const compareDefined = (val1: unknown, val2: unknown): boolean => {
    return val1 && val2 && val1 === val2;
};

/**
 * Removes item from array. Returns removed item or null if item is not present.
 * @param array
 * @param item
 */
export function removeItemFromArray<Type = unknown>(array: Type[], item: Type): Type {
    const index = array.findIndex(val => val === item);
    if (index !== -1) {
        array.splice(index, 1);
        return item;
    }
    return null;
}

/**
 * Adds od removes item from array according to its presence in array. Return true if item was added, false if removed.
 * @param array
 * @param item
 */
export function toggleItemInArray<Type = unknown>(array: Type[], item: Type): boolean {
    const removed = removeItemFromArray(array, item);
    if (removed === null) {
        array.push(item);
        return true;
    }
    return false;
}

/** Keeps queue of promises for all the calls and resolves them all with the final result, once the func is finally called */
export const asyncDebounced = <T extends (...args: any) => any>(func: T, wait?: number, options?: DebounceSettings): ((...args: any) => Promise<ReturnType<T>>) => {
    let resolvers: { resolve: ((value: ReturnType<T>) => void), reject: ((error: Error) => void) }[] = [];

    const debounced = debounce(async (...args) => {
        try {
            const result = await func(...args);

            for (const resolver of resolvers) {
                resolver.resolve(result);
            }
        } catch (e) {
            for (const resolver of resolvers) {
                resolver.reject(e);
            }
        } finally {
            resolvers = [];
        }
    }, wait, options);

    return (...args: any) => {
        return new Promise((resolve, reject) => {
            resolvers.push({ resolve, reject });
            debounced(...args);
        });
    };
};

/**
 * Returns a hash code from a string
 * @param  {String} str The string to hash.
 * @return {Number}    A 32bit integer
 * @see https://stackoverflow.com/questions/6122571/simple-non-secure-hash-function-for-javascript
 */
export function hashCode(str: string): string {
    let hash = 0;

    for (let i = 0, len = str.length; i < len; i++) {
        const chr = str.charCodeAt(i);

        hash = (hash << 5) - hash + chr;
        hash |= 0; // Convert to 32bit integer
    }

    return hash.toString();
}

export function repeatArray<T>(arr: T[], n: number): T[] {
    return [].concat(...Array(n).fill(arr));
}

export function ifPositive<T = unknown>(value: number, positive: T, negative: T, zero: T = null): T {
    if (value > 0) {
        return positive;
    } else if (value < 0) {
        return negative;
    } else {
        return zero;
    }
}

export function formatPercent(value: TValue, opts?: {
    decimalPlaces: number;
    absolute: boolean;
}): string {
    if (!isDefined(value)) {
        return "";
    }
    const numeric = parseFloat(value as unknown as string);
    const val = opts?.absolute === false ? numeric : Math.abs(numeric);
    const formattedNumber = NumberType.format(val, { maximumFractionDigits: opts?.decimalPlaces ?? 0 });
    return `${formattedNumber} %`;
}

export function removeNullValuesFromObject(o: TRecordAny): void {
    for (const [key, value] of Object.entries(o)) {
        if (value === null) {
            delete o[key];
        }
    }
}


export function isElementVisible(element: HTMLElement): boolean {
    const computedStyle = getComputedStyle(element);

    // https://stackoverflow.com/a/21696585/3352544
    return !!element.offsetParent && computedStyle.visibility !== "hidden" && computedStyle.opacity !== "0";
}

/**
 * Returns a promise that resolves after a given number of milliseconds,
 * debugging purposes
 * @param ms
 */
export function wait(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
}
