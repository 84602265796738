import React from "react";
import TableView, { ITableViewBaseProps } from "../../views/table/TableView";
import { BreadCrumbProvider } from "../../components/breadCrumb";
import View from "../../views/View";
import { TableWrapper, TopWrapper } from "../../views/table/TableView.styles";
import { SmartHeaderStyled } from "../../views/formView/FormView.styles";
import { IProps as ISmartTableProps, SmartTable } from "../../components/smart/smartTable";
import { ROUTE_ELECTRONIC_SUBMISSION } from "../../routes";
import { IRow } from "@components/table";
import { FILES_API_URL } from "../../constants";
import { DownloadIcon } from "@components/icon";
import { IconSize } from "../../enums";


export default class ElectronicSubmissionAuditTrailTableView extends TableView<ITableViewBaseProps> {

    drilldownIcon = (row: IRow): React.ReactElement => {
        const actionId = row.customData.entity.ActionId;
        const url = `${FILES_API_URL}/${actionId}`;

        return (
                <a href={url}>
                    <DownloadIcon width={IconSize.S}/>
                </a>
        );
    };

    getTableSharedProps(): ISmartTableProps & { key: string } {
        const props = super.getTableSharedProps();
        props.drilldown = this.drilldownIcon;
        return props;
    }

    render() {
        return (<>
            <BreadCrumbProvider back={ROUTE_ELECTRONIC_SUBMISSION}/>
            <View hotspotContextId={this.props.storage.id}
                  scrollProps={{
                      ref: this._scrollBarInstanceRef,
                  }}>
                <TopWrapper
                        _loaded={this.isLoaded()}>
                    <SmartHeaderStyled
                            storage={this.props.storage}
                            shouldHideVariant={true}
                            title={this.props.storage.data.definition.title}/>
                    {this.renderFilterBar()}
                    {this.renderAlert()}
                </TopWrapper>
                <TableWrapper>
                    <SmartTable {...this.getTableSharedProps()}/>
                </TableWrapper>
            </View>
        </>);
    }
}