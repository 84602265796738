import { ScrollBar } from "@components/scrollBar";
import { composeRefHandlers } from "@utils/general";
import i18next from "i18next";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import { IconSize, Status, TextAlign } from "../../enums";
import { TRecordAny } from "../../global.types";
import { WithHideOnBlur, withHideOnBlur } from "../../HOC/withHideOnBlur";
import { ObjectContent, ObjectWrapper } from "../alert/Alert.styles";
import { Button, ButtonGroup, IconButton } from "../button";
import { CheckIcon, CopySimpleIcon, InfoIcon } from "../icon";
import { ObjectExplorer } from "../objectExplorer";
import PopperTooltip from "../popperTooltip/PopperTooltip";

interface IProps extends WithHideOnBlur, WithTranslation {
    data: TRecordAny | string;
    size?: string;
    status?: Status;
    isLight?: boolean;
    style?: React.CSSProperties;
}

interface IState {
    isCopying: boolean;
}

class ErrorDetail extends React.PureComponent<IProps, IState> {
    tooltipRef = React.createRef<HTMLDivElement>();
    buttonRef = React.createRef<HTMLDivElement>();

    _timeout: ReturnType<typeof setTimeout>;

    state = {
        isCopying: false
    };

    componentDidMount() {
        this.props.setHideOnBlurOptions([this.buttonRef, this.tooltipRef]);
    }

    componentWillUnmount() {
        clearTimeout(this._timeout);
    }

    showDetail = (event: React.MouseEvent) => {
        event.stopPropagation();
        event.preventDefault();
        this.props.setIsVisible(!this.props.isVisible);
    };

    handleCopyClick = () => {
        const text = JSON.stringify(this.props.data);
        navigator.clipboard.writeText(text);

        this.setState({
            isCopying: true
        });

        clearTimeout(this._timeout);
        this._timeout = setTimeout(() => {
            this.setState({ isCopying: false });
        }, 3000);
    };

    renderContent = () => {
        const Icon = this.state.isCopying ? <CheckIcon/> : <CopySimpleIcon/>;
        const text = this.state.isCopying ? i18next.t("Components:ErrorDetail.Copied") : i18next.t("Components:ErrorDetail.Copy");
        const isString = typeof this.props.data === "string";

        return (
            <ObjectWrapper>
                <ScrollBar
                    isInFlex
                >
                    <ObjectContent isString={isString}>
                        {isString ? this.props.data : <ObjectExplorer data={this.props.data as TRecordAny}/>}
                    </ObjectContent>
                </ScrollBar>
                <ButtonGroup align={TextAlign.Right}>
                    <Button
                        isHover={this.state.isCopying}
                        onClick={this.handleCopyClick}
                        icon={Icon}
                        isTransparent
                        isLight>
                        {text}
                    </Button>
                </ButtonGroup>
            </ObjectWrapper>
        );
    };

    render() {
        return (
            <PopperTooltip content={this.renderContent()}
                           isHidden={!this.props.isVisible}
                           placement={"bottom"}
                           passRef={this.tooltipRef}
                           offsetY={5}
                           style={{
                               maxHeight: "300px"
                           }}
            >
                {(ref) => {
                    return (
                        <IconButton
                            passRef={composeRefHandlers(this.buttonRef, ref)}
                            status={this.props.status}
                            isLight={this.props.isLight}
                            onClick={this.showDetail}
                            title={i18next.t("Components:ErrorDetail.ShowDetail")}
                            isDecorative
                            style={this.props.style}>
                            <InfoIcon width={this.props.size ?? IconSize.M}/>
                        </IconButton>
                    );
                }}
            </PopperTooltip>
        );
    }
}

export default withHideOnBlur(withTranslation(["Components"])(ErrorDetail));