import React, { useCallback } from 'react';
import { CollapsibleSectionStyled } from "./ExtraSalary.styles";
import { FormStorage } from "../../../views/formView/FormStorage";
import { IPrEmploymentTemplateEntity } from "@odata/GeneratedEntityTypes";
import { ISmartFieldRenderProps } from "@components/smart/smartField/SmartField";

interface IProps extends ISmartFieldRenderProps {
}

const CollapsibleSectionToggleField: React.FC<IProps> = ({ parentProps, value }) => {
    const storage = parentProps.storage as FormStorage<IPrEmploymentTemplateEntity>;
    const { bindingContext } = parentProps;

    const handleToggle = useCallback((isExpanded: boolean) => {
        storage.setValue(bindingContext, !isExpanded);
        // todo: maybe refresh just the group where the field is added??
        storage.refresh();
    }, [storage, bindingContext]);

    const info = storage.getInfo(parentProps.bindingContext);

    return (
            <CollapsibleSectionStyled title={info.label}
                                      onToggle={handleToggle}
                                      isExpanded={value}
                                      renderChildrenOutside={true}/>
    );
};

export default CollapsibleSectionToggleField;