import { CheckIcon } from "@components/icon";
import { NoResultsText } from "@components/search/Search.styles";
import dayjs, { Dayjs } from "dayjs";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import { TRecordAny } from "../global.types";
import TestIds from "../testIds";
import DateType from "../types/Date";
import Notification from "./Notification";
import {
    BearAnimStyled,
    MarkAllAsReadButton,
    NotificationGroup,
    SeparatorStyledNotificationGroup,
    StyledNotifications
} from "./Notifications.styles";

export interface INotification {
    // UserNotification.Id
    id: string;
    title: string;
    subtitle: string;
    info: string;
    description: string;
    avatarSrc: string;
    time: Date;
    isUnread: boolean;
    url?: string;
    detailData?: TRecordAny | string;
}

interface INotificationGroup {
    title: string;
    notifications: INotification[];
}

interface IProps {
    notifications: INotification[];
    onIsUnreadChange?: (id: string, isUnread: boolean) => void;
    onNotificationClick?: (id: string) => void;
    onMarkAllAsRead?: () => void;
}

class Notifications extends React.PureComponent<IProps & WithTranslation> {
    static defaultProps: Partial<IProps> = {
        notifications: []
    };

    getNotificationGroups = (): INotificationGroup[] => {
        const dateGroups: (INotificationGroup & { dateThreshold: Dayjs })[] = [
            {
                title: this.props.t("Components:Notifications.Today"),
                dateThreshold: dayjs(),
                notifications: []
            },
            {
                title: this.props.t("Components:Notifications.Yesterday"),
                dateThreshold: dayjs().subtract(1, "day"),
                notifications: []
            },
            {
                title: this.props.t("Components:Notifications.LastWeek"),
                dateThreshold: dayjs().subtract(1, "week"),
                notifications: []
            },
            {
                title: this.props.t("Components:Notifications.LastMonth"),
                dateThreshold: dayjs().subtract(1, "month"),
                notifications: []
            },
            {
                title: this.props.t("Components:Notifications.Older"),
                dateThreshold: null,
                notifications: []
            }
        ];

        for (const notification of this.props.notifications) {
            for (const group of dateGroups) {
                if (!group.dateThreshold || dayjs(notification.time).isSameOrAfter(group.dateThreshold, "day")) {
                    group.notifications.push(notification);
                    break;
                }
            }
        }

        return dateGroups
            .filter(group => group.notifications.length > 0)
            .map(dateGroup => ({
                title: dateGroup.title,
                notifications: dateGroup.notifications.sort(this.compareNotifications)
            }));
    };

    compareNotifications = (a: INotification, b: INotification): number => {
        return DateType.compare(b.time, a.time);
    };

    renderGroup = (group: INotificationGroup) => {
        return (
            <React.Fragment key={group.title}>
                <NotificationGroup>
                    <SeparatorStyledNotificationGroup label={group.title}/>
                </NotificationGroup>
                {group.notifications.map((notification, index) => {
                    return (
                        <Notification {...notification}
                                      key={notification.id}
                                      onClick={this.props.onNotificationClick}
                                      onIsUnreadChange={this.props.onIsUnreadChange}/>
                    );
                })}
            </React.Fragment>
        );
    };

    renderNoNotificationsText = (): React.ReactNode => {
        return (
            <NoResultsText>
                <span>
                    {this.props.t("Components:Notifications.NoNewNotifications")}
                </span>
                <BearAnimStyled/>
            </NoResultsText>
        );
    };

    renderNotifications = (): React.ReactNode => {
        if (this.props.notifications.length === 0) {
            return this.renderNoNotificationsText();
        }

        return this.getNotificationGroups().map(group => this.renderGroup(group));
    };

    render() {
        const isEmpty = this.props.notifications.length === 0;
        return (
                <StyledNotifications $isEmpty={isEmpty} data-testid={TestIds.Notifications}>
                    {!isEmpty && <MarkAllAsReadButton icon={<CheckIcon/>}
                                                      onClick={this.props.onMarkAllAsRead}
                                                      isTransparent
                                                      isLight
                                                      isDecorative>
                        {this.props.t("Components:Notifications.MarkAllAsRead")}
                    </MarkAllAsReadButton>}
                    {this.renderNotifications()}
                </StyledNotifications>
        );
    }
}

export default withTranslation(["Components"])(Notifications);