import styled from "styled-components/macro";

export const StyledSvgImage = styled.div`
    display: flex;
    text-align: center;
    width: 100%;
    height: 100%;
    padding: 0 4px;

    svg {
        // document icons color
        .DI_4L_main {
            stroke: ${props => props.theme.C_ICON_document};
            fill: none;
            stroke-width: 2.5;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-miterlimit: 10;
        }

        // object page icon colors
        .OI_4L_main {
            stroke: ${props => props.theme.C_ICON_OBJECT_PAGE_main};
            fill: none;
            stroke-width: 2.5;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-miterlimit: 10;
        }

        .B_4L_main {
            fill: ${props => props.theme.C_ICON_OBJECT_PAGE_main};
        }
        
        .IC_4L_evalalogo {
            fill: ${props => props.theme.C_EVALA_LOGO};
            stroke: none;
        }

        display: block;
        max-width: 100%;
        max-height: 100%;
    }
`;