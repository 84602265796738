import { IGetTileDataArgs } from "@components/dashboard";
import { CustomerPortalPaymentEntity, EntitySetName } from "@odata/GeneratedEntityTypes";

export async function getUnpaidDocumentsForPayment(args: IGetTileDataArgs): Promise<number> {
    const { oData, signal } = args;

    const query = oData.getEntitySetWrapper(EntitySetName.CustomerPortalPayments)
        .query()
        .filter(`${CustomerPortalPaymentEntity.IsPaid} eq false`)
        .count();

    const data = await query.fetchData(null, { signal });

    return data?._metadata?.count ?? 0;
}
