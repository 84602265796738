import styled, { css } from "styled-components/macro";
import { TextAlign } from "../../enums";
import {
    getDisabledStyle,
    getFocusBorderElement,
    getFocusOutlineDefinition,
    T_PLAIN_big,
    T_PLAIN_big_hig,
    T_PLAIN_small,
    T_PLAIN_small_hig,
    T_TITLE_6
} from "../../global.style";
import { getRowPadding, getStickyStyles, GroupIconWrapperStyles } from "../table/Rows.styles";
import { ellipsis, transparentize } from "polished";
import { StatusIndicator } from "../tabs/Tabs.styles";
import { ISticky } from "../table";
import { SelectedHighLightType } from "./SimpleTable";

export const SIMPLE_TABLE_ROW_HEIGHT = 30;

interface IGetBottomLine {
    color: string;
    bottom: string;
    el?: "after" | "before";
    isDouble?: boolean;
    isFullWidth?: boolean;
}

const getLine = (args: IGetBottomLine) => {
    const pseudoElem = args.el ?? "after";

    return css`
        &::${pseudoElem} {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            bottom: ${args.bottom};
            height: 3px;
            width: auto;
            border-bottom: 1px solid ${args.color};
            border-top: 1px solid ${args.isDouble ? args.color : "transparent"};
        }

        ${!args.isFullWidth && css`
            &:first-child::${pseudoElem} {
                left: 12px;
            }

            &:last-child::${pseudoElem} {
                right: 12px;
            }
        `};
    `;
};

export const ScrollWrapper = styled.div`
    position: relative;
    display: flex;
    width: 100%;
`;

export const StyledSelectedDiv = styled.div`
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-radius: 3px;
    color: ${props => props.theme.C_BTN_4L_emph_text};
    width: 32px;
    height: 20px;
    padding-right: 2px;
    background-color: ${props => props.theme.C_ICON_primary_dark};

    & > svg {
        position: absolute;
        top: 0;
        right: -14px;
        transform: rotate(180deg);
    }
`;

export const StyledRowOrderCell = styled.td<{
    isSelected: boolean,
    isSelectable: boolean
}>`
    position: sticky;
    cursor: ${props => props.isSelectable ? "pointer" : "not-allowed"};
    left: 0;
    z-index: 3;
    ${T_TITLE_6};
    color: ${props => props.theme.C_ACT_thick_line};
    background-color: ${props => props.theme.C_SPLIT_LAYOUT_BUBBLE};
    min-width: 38px;
    text-align: right;
    padding: ${props => props.isSelected ? 0 : "0 8px"};
`;

export const StyledSimpleTable = styled.table<{ _width?: string; _hasHighlight?: boolean; }>`
    position: relative;
    border-collapse: collapse;
    width: ${props => props._width};
    height: fit-content;
    white-space: nowrap;

    ${getFocusBorderElement()};

    tr {
        height: ${SIMPLE_TABLE_ROW_HEIGHT}px;
    }

    tbody {
        ${props => props._hasHighlight && css`
            tr:hover td, tr:focus td {
                background-color: ${transparentize(0.5, props.theme.C_ACT_hover_without_opacity)};
            }
        `}
        tr:focus {
            ${getFocusOutlineDefinition({ offset: -2 })}
        }
    }
`;

interface ISimpleCell {
    maxWidth?: number;
    textAlign: TextAlign;
    isSelected?: boolean;
    isDisabled?: boolean;
    isBold?: boolean;
    hasBigFont?: boolean;
    hasTopLine?: boolean;
    hasBottomLine?: boolean;
    hasGroupPadding?: boolean;
    isFirst?: boolean;
    isLast?: boolean;
    hasAfterContent?: boolean;
    sticky?: ISticky;
    isGrey?: boolean;
    hasPinkBg?: boolean;
    selectedHighLightType?: SelectedHighLightType;
}
const simpleCell = css<ISimpleCell>`
    position: relative;
    text-align: ${props => props.textAlign || TextAlign.Left};
    ${props => getRowPadding(props.textAlign)};

    ${props => props.isFirst && css`
        padding-left: 12px;
    `};
    ${props => props.isLast && css`
        padding-right: 12px;
    `};

    ${props => props.isLast && props.hasAfterContent && css`
        padding-right: 0;
    `};
    ${props => props.maxWidth && css<ISimpleCell>`
        ${ellipsis(props.maxWidth)};
        // overflow-x: hidden for some reason hides vertical overflow as well,
        // using 'clip' instead seems to resolve the issue
        overflow: unset;
        overflow-x: clip;
        display: table-cell;
    `};
`;

export const DraggableArea = styled.div`
    position: absolute;
    width: 100%;
    height: 15px;
    top: 50%;
    transform: translate(0, -50%);
    background-color: transparent;
    cursor: row-resize;
`;


export const DraggableCircle = styled.div`
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    top: 50%;
    transform: translate(0, -50%);
    background-color: #fefefe;
    border: 2px solid #5e1146;
    cursor: row-resize;
`;

export const DashedResizeLine = styled.div`
    position: absolute;
    display: flex;
    z-index: 2;
    justify-content: center;
    overflow: visible;
    top: 60px;
    width: 100%;
    height: 2px;
    background-image: linear-gradient(to right, ${props => props.theme.C_ACT_main} 50%, transparent 0%);
    background-position: bottom;
    background-size: 16px 2px;
    background-repeat: repeat-x;
    cursor: row-resize;
`;

export const SimpleHeaderCell = styled.th.attrs<ISimpleCell>(props => ({
    style: {
        ...getStickyStyles(props.sticky),
        backgroundColor: props.isGrey ? "#f2f2f2" : props.sticky ? props.theme.C_BG_floorplan : null
    }
}))`
    ${simpleCell}
    ${props => props.isGrey ? T_PLAIN_small : props.hasBigFont ? T_PLAIN_big_hig : T_PLAIN_small_hig};

    ${props => props.hasBottomLine && getLine({
        color: props.theme.C_ACT_hover_without_opacity,
        bottom: "0",
        isFullWidth: true
    })};
    ${props => props.isGrey ? `
        color: ${props.theme.C_ACT_thick_line};
        text-align: ${TextAlign.Center};
    ` : ""};
`;

interface ISimpleRowCell extends ISimpleCell {
    isDivider?: boolean;
    hasSplitLine?: boolean;
    colSpan?: number;
}

export const SimpleRowCell = styled.td.attrs<ISimpleRowCell>(props => ({
    style: {
        ...getStickyStyles(props.sticky),
        backgroundColor: props.sticky ? props.theme.C_BG_floorplan : null,
        colspan: props.colSpan ? props.colSpan : null
    }
}))<ISimpleCell & { isDivider?: boolean, hasSplitLine?: boolean }>`
    ${simpleCell}

    ${props => getDisabledStyle(props.isDisabled)}
    ${props => props.isBold ? (props.hasBigFont ? T_PLAIN_big_hig : T_PLAIN_small_hig) : (props.hasBigFont ? T_PLAIN_big : T_PLAIN_small)};
    ${props => props.hasTopLine && getLine({
        color: props.theme.C_ACT_hover_without_opacity,
        bottom: "100%",
        el: "before",
        isDouble: true,
        isFullWidth: true
    })};
    ${props => props.hasBottomLine && getLine({
        color: props.theme.C_FIELD_section_line,
        bottom: "0",
        isDouble: false,
        isFullWidth: true
    })};
    ${props => props.isSelected && (props.selectedHighLightType === SelectedHighLightType.Bold ? "font-weight: bold;" : getLine({
        color: props.theme.C_TEXT_primary,
        bottom: "4px"
    }))};

    ${props => props.isDivider && css`
        ${getLine({ color: props.theme.C_ACT_hover_without_opacity, bottom: "100%", el: "before", isFullWidth: true })}
        ${getLine({ color: props.theme.C_ACT_hover_without_opacity, bottom: "0", el: "after", isFullWidth: true })}
    `}
    ${StatusIndicator} {
        position: absolute;
        left: ${props => props.hasGroupPadding ? "23px" : "3px"};
        top: 50%;
        transform: translate(-100%, -50%);
    }

    ${props => props.hasPinkBg && `background: ${transparentize(0.5, props.theme.C_ACT_hover_without_opacity)}`}
    ${props => !props.hasPinkBg && !props.isSelected && props.hasSplitLine && `opacity: 0.5`}
`;

export const SimpleRowCellContentWrapper = styled.div<{
    _lpad?: number;
}>`
    position: relative;
    display: flex;
    align-items: center;
    padding-left: ${props => `${props._lpad}px`};
`;

export const SimpleGroupIconWrapper = styled.span<{
    isOpen: boolean
}>`
    ${GroupIconWrapperStyles};
    position: relative;
    margin-right: 2px;
`;

export const ContentAfterRow = styled.div`
    display: flex;
    align-items: center;
    height: ${SIMPLE_TABLE_ROW_HEIGHT}px;
    padding-left: 12px;
`;