import { withDisplayName } from "@components/smart/GeneralFieldDefinition";
import {
    FilterBarGroup,
    getDefaultFilterGroupDef,
    IFilterGroupDef
} from "@components/smart/smartFilterBar/SmartFilterBar.types";
import { TSmartODataTableStorage } from "@components/smart/smartTable/SmartODataTableBase";
import { getBindingContext } from "@odata/BindingContext";
import { ActionLogEntity, EntitySetName } from "@odata/GeneratedEntityTypes";
import { ActionTypeCode } from "@odata/GeneratedEnums";
import { WithOData, withOData } from "@odata/withOData";
import i18next from "i18next";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import { AppContext, IAppContext } from "../../contexts/appContext/AppContext.types";
import { Sort } from "../../enums";
import { TValue } from "../../global.types";
import { TableStorage } from "../../model/TableStorage";
import DateType, { DateFormat } from "../../types/Date";
import { ISplitPageTableDef } from "../../views/table/TableView.utils";
import ElectronicSubmissionAuditTrailTableView from "./ElectronicSubmissionAuditTrailTableView";


interface IProps extends WithTranslation, WithOData {
}

class ElectronicSubmissionAuditTrail extends React.Component<IProps> {
    static contextType = AppContext;

    tableStorage: TSmartODataTableStorage;
    defLoaded = false;

    constructor(props: IProps, context: IAppContext) {
        super(props);
        this.tableStorage = new TableStorage({
            id: "ElectronicSubmissionActionLog",
            oData: props.oData,
            context: context,
            t: props.t
        });
    }

    componentDidMount() {
        this.load();
    }

    componentDidUpdate(prevProps: Readonly<IProps>) {
        this.load();
    }

    getTableDefinition = (): ISplitPageTableDef => {
        const filterBarDef: IFilterGroupDef[] = [{
            ...getDefaultFilterGroupDef(FilterBarGroup.Filters),
            createQuery: false,
            allowCustomFilters: false,
            defaultFilters: ["CreatedBy", "DateCreated"],
            filterDefinition: {
                DateCreated: {
                    label: i18next.t("Audit:Columns.DateCreated")
                },
                ...withDisplayName("CreatedBy", "Name", {
                    label: i18next.t("Audit:Columns.Author")
                })
            }
        }];

        return {
            filterBarDef,
            additionalProperties: [{ id: "ActionId" }],
            initialSortBy: [{ id: "DateCreated", sort: Sort.Desc }],
            columns: [
                "DateCreated",
                "CreatedBy",
                "ActionDetails",
                "ActionType"
            ],
            columnDefinition: {
                DateCreated: {
                    label: i18next.t("Audit:Columns.DateCreated"),
                    formatter: (val: TValue) => {
                        return DateType.localFormat(val as Date, DateFormat.dateAndTime);
                    }
                },
                DateLastModified: {},
                ...withDisplayName("CreatedBy", "Name", {
                    label: i18next.t("Audit:Columns.Author")
                }),
                ActionDetails: {},
                ...withDisplayName("ActionType", "Name")
            },
            id: "ElectronicSubmissionAuditTrailSpecialTable",
            filter: `${ActionLogEntity.ActionTypeCode} eq '${ActionTypeCode.XMLExport}'`,
            title: i18next.t("Audit:AuditTrail"),
            entitySet: EntitySetName.ActionLogs,
        };
    };

    load = async () => {
        if (this.props.tReady && !this.defLoaded) {
            this.defLoaded = true;

            await this.tableStorage.init({
                definition: this.getTableDefinition(),
                bindingContext: getBindingContext(EntitySetName.ActionLogs, this.props.oData.metadata)
            });

            this.setBreadCrumbs();

            this.forceUpdate();
        }
    };

    setBreadCrumbs = () => {
        this.context.setViewBreadcrumbs({
            items: [{
                key: "auditTrail",
                title: this.props.t("Audit:AuditTrail")
            }],
            lockable: false
        });
    };


    render() {
        if (!this.defLoaded) {
            return null;
        }

        return (
                <ElectronicSubmissionAuditTrailTableView storage={this.tableStorage}/>
        );
    }
}

export default withTranslation(["Common", "Audit", "ElectronicSubmission"])(withOData(ElectronicSubmissionAuditTrail));