import { createPath } from "@odata/BindingContext";
import {
    BusinessPartnerBankAccountEntity, BusinessPartnerContactEntity,
    BusinessPartnerEntity, BusinessPartnerIssuedDocumentDefaultEntity, BusinessPartnerReceivedDocumentDefaultEntity,
    DocumentBusinessPartnerEntity,
    DocumentEntity
} from "@odata/GeneratedEntityTypes";

export const BusinessPartnerSaveErrors = [
    "BusinessPartnerCombinationOfNameAndLegalNumberAlreadyExists",
    "BusinessPartnerCombinationOfNameAndAddressAlreadyExists"
];

export const DocumentBusinessPartnerAdditionalProperties = {
    id: createPath(DocumentEntity.BusinessPartner, DocumentBusinessPartnerEntity.BusinessPartner),
    additionalProperties: [
        {
            id: BusinessPartnerEntity.BankAccounts,
            additionalProperties: [{
                id: BusinessPartnerBankAccountEntity.CountryCode
            }, {
                id: BusinessPartnerBankAccountEntity.AccountNumber
            }, {
                id: BusinessPartnerBankAccountEntity.BankCode
            }, {
                id: BusinessPartnerBankAccountEntity.IBAN
            }, {
                id: BusinessPartnerBankAccountEntity.SWIFT
            }, {
                id: BusinessPartnerBankAccountEntity.AbaNumber
            }]
        },
        {
            id: BusinessPartnerEntity.Contacts,
            additionalProperties: [{
                id: BusinessPartnerContactEntity.FirstName
            }, {
                id: BusinessPartnerContactEntity.LastName
            }, {
                id: BusinessPartnerContactEntity.Email
            }, {
                id: BusinessPartnerContactEntity.PhoneNumber
            }]
        },
        {
            id: BusinessPartnerEntity.IssuedDocumentDefault,
            additionalProperties: [{
                id: BusinessPartnerIssuedDocumentDefaultEntity.DaysDue
            }, {
                id: BusinessPartnerIssuedDocumentDefaultEntity.VatClassification
            }]
        },
        {
            id: BusinessPartnerEntity.ReceivedDocumentDefault,
            additionalProperties: [{
                id: BusinessPartnerReceivedDocumentDefaultEntity.DaysDue
            }, {
                id: BusinessPartnerReceivedDocumentDefaultEntity.VatClassification
            }]
        }
    ]
};