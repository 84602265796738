import React, { Component } from "react";
import { FormStorage } from "../../../views/formView/FormStorage";
import { IPrAttendanceDayEntity, IPrAttendanceEntity } from "@odata/GeneratedEntityTypes";
import { IAttendanceCustomData } from "./AttendanceFormView";
import SimpleTable from "../../../components/simpleTable";
import { IColumn, IRow } from "@components/table";
import { TextAlign } from "../../../enums";
import {
    formatDateToDateString,
    formatHoursToTimeFormat,
    getMonthDays,
    getWeekdays,
    longDateFormat
} from "@components/inputs/date/utils";
import DateType, { getDateFormat, getUtcDayjs } from "../../../types/Date";
import { balanceFormatter, getDayAbsenceHours, getDayWorkingHours } from "./Attendance.utils";

interface IProps {
    storage: FormStorage<IPrAttendanceEntity, IAttendanceCustomData>;
    saldoMap: Record<number, number>;
    hoursFundMap: Record<number, number>;
}

export default class AttendanceDaysList extends Component<IProps> {
    get columns(): IColumn[] {
        return [
            { id: "day", label: this.props.storage.t("Attendance:DaysList.Day"), textAlign: TextAlign.Left },
            { id: "fund", label: this.props.storage.t("Attendance:DaysList.Fund"), textAlign: TextAlign.Right },
            { id: "worked", label: this.props.storage.t("Attendance:DaysList.Worked"), textAlign: TextAlign.Right },
            { id: "saldo", label: this.props.storage.t("Attendance:DaysList.Saldo"), textAlign: TextAlign.Right },
            { id: "start", label: this.props.storage.t("Attendance:DaysList.Start"), textAlign: TextAlign.Right },
            { id: "end", label: this.props.storage.t("Attendance:DaysList.End"), textAlign: TextAlign.Right },
            { id: "absence", label: this.props.storage.t("Attendance:DaysList.Absence"), textAlign: TextAlign.Right }
        ];
    }

    get rows(): IRow[] {
        const entity = this.props.storage.data.entity as IPrAttendanceEntity;
        const date = getUtcDayjs().set("month", entity.Month - 1).set("year", entity.Year);

        const days = getMonthDays(date);
        const weekDayLabels = getWeekdays();

        const mappedWorkDays = entity.Days?.reduce((map: Record<string, IPrAttendanceDayEntity>, day) => {
            map[formatDateToDateString(day.Date)] = day;
            return map;
        }, {}) ?? {};

        return days.map(d => {
            const data = mappedWorkDays[formatDateToDateString(d.toDate())];

            const dayIndex = d.get("date");
            const daysHoursFund = this.props.hoursFundMap[dayIndex];
            const workedHours = getDayWorkingHours(data);
            const absenceHours = getDayAbsenceHours(data, daysHoursFund);

            const start = data?.Intervals?.[0]?.TimeStart;
            const end = data?.Intervals?.[data?.Intervals?.length - 1]?.TimeEnd;

            return {
                id: dayIndex,
                values: {
                    day: `${weekDayLabels[d.day()].toUpperCase()} ${DateType.format(d.toDate())}`,
                    fund: `${daysHoursFund} ${this.props.storage.t("Components:Calendar.HourPlaceholder")}`,
                    saldo: balanceFormatter(this.props.saldoMap[dayIndex] * 60),
                    worked: formatHoursToTimeFormat(workedHours),
                    absence: formatHoursToTimeFormat(absenceHours),
                    start: start ? DateType.format(start, longDateFormat(getDateFormat(DateType.defaultTimeFormat))) : formatHoursToTimeFormat(0),
                    end: start ? DateType.format(end, longDateFormat(getDateFormat(DateType.defaultTimeFormat))) : formatHoursToTimeFormat(0),

                },
                isDisabled: !data
            };
        });
    }

    render() {
        return <SimpleTable
                columns={this.columns}
                rows={this.rows}
        />;
    }
}