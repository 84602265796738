import React from "react";
import { AppContext, IAppContext } from "../contexts/appContext/AppContext.types";
import { RequireKeys } from "../global.types";
import { IDefinition } from "./PageUtils";
import { IFormDef } from "../views/formView/Form";
import { getEntitySetFromDefinition } from "../views/formView/Form.utils";
import memoizeOne from "../utils/memoizeOne";
import BindingContext, { createBindingContext } from "../odata/BindingContext";
import { getDefByEntityType } from "./getDefByEntityType";
import { EntityTypeName } from "@odata/GeneratedEntityTypes";
import i18next from "i18next";
import { ISplitPagePaneData } from "./DialogPage";
import { StorageModel } from "../model/StorageModel";
import { PageViewMode } from "../enums";
import { ISplitPageTableDef } from "../views/table/TableView.utils";

export interface IDialogPageBaseProps {
    getDef: (context?: IAppContext) => any;
    rootStorage?: StorageModel;
    customFooterButtons?: React.ReactElement;
    pageViewMode?: PageViewMode;
    onAfterSave?: (bc: BindingContext) => void;
}

export default abstract class DialogPageBase<P extends IDialogPageBaseProps, S, T> extends React.Component<P, S> {
    static contextType = AppContext;
    //sadly, breaks typescript type checking
    //context: React.ContextType<typeof AppContext>;
    static defaultProps: RequireKeys<Partial<IDialogPageBaseProps>, "getDef">;

    protected definition: IDefinition;
    protected currentEntityType: string;


    protected masterData: ISplitPagePaneData<ISplitPageTableDef> = {};
    protected detailData: ISplitPagePaneData<IFormDef> = {};

    constructor(props: P, context: IAppContext) {
        super(props, context);

        this.definition = this.props.getDef(context);
        this.masterData.def = this.definition.table;

        this.bindEvents();
    }


    bindEvents = () => {
        this.handleRowSelect = this.handleRowSelect.bind(this);
        this.handleCloseDetail = this.handleCloseDetail.bind(this);
        this.handleAfterSave = this.handleAfterSave.bind(this);
    };

    getEntitySet() {
        return getEntitySetFromDefinition(this.definition, this.context);
    }

    getTableViewContext = memoizeOne(() => {
                return createBindingContext(this.getEntitySet(), this.props.rootStorage.oData.getMetadata());
            }, () => [this.getEntitySet()]
    );

    getChildCollectionForEditableParent = (): string => {
        return undefined;
    };

    setFormDefFromEntityType = async (bc: BindingContext): Promise<boolean> => {
        if (bc) {
            const bcs = bc.getFullPathAsArrayOfContexts();
            if (bcs?.length > 0) {
                const entityType: string = bcs[bcs.length - 1].getEntityType().getName();
                if (entityType !== this.currentEntityType) {
                    this.currentEntityType = entityType;
                    const definition = getDefByEntityType(entityType as EntityTypeName);
                    await i18next.loadNamespaces(definition.translationFiles);
                    this.detailData.def = definition(this.context).form;
                    return true;
                }
            }
        }

        return false;
    };

    getMandatoryChildParentProps = () => {
        return {};
    };

    handleRowSelect(bc: BindingContext): void {
        this.setFormDefFromEntityType(bc)
            .then(() => {
                // keep it here, so bc always corresponds with definition
                this.detailData.bc = bc;
                this.forceUpdate();
            });
    }

    handleCloseDetail(): void {
        this.detailData.bc = null;
        this.forceUpdate();
    }

    handleAfterSave(bc: BindingContext): void {
        this.detailData.bc = bc;
        this.props.onAfterSave?.(bc);
        this.forceUpdate();
    }

    abstract getMandatoryProps(): T & { key: string };

    abstract render(): React.ReactElement;
}