import { IFieldDefFn, IGetValueArgs } from "@components/smart/FieldInfo";
import { IFormatOptions } from "@odata/OData.utils";
import { getSingleAccountFieldDef } from "@pages/accountAssignment/AccountAssignment.utils";
import { DOCUMENT_DATE_CHANGE_DEBOUNCE } from "@pages/documents/DocumentDef";
import { IDefinition, IGetDefinition } from "@pages/PageUtils";
import i18next from "i18next";
import { ValidationError } from "yup";

import { BasicInputSizes, FieldType, ValidatorType } from "../../../../enums";
import { TValue } from "../../../../global.types";
import BindingContext from "../../../../odata/BindingContext";
import { IFormDef } from "../../../../views/formView/Form";
import { defaultDateFromActiveFiscalYear } from "../../../../views/formView/Form.utils";
import { FormStorage } from "../../../../views/formView/FormStorage";
import { IAddToCostsCustomData } from "./AddToCostsDialogFormView";

const AccountDefFormatter = (val: TValue, args?: IFormatOptions): string => {
    const entity = args.entity[BindingContext.localContext("DebitAccount")];
    return entity ? `${entity[BindingContext.localContext("Number")]} - ${entity[BindingContext.localContext("Name")]}` : "";
};

export const DateOfTransactionPath = BindingContext.localContext("DateOfTransaction");
export const DebitAccountIdPath = BindingContext.localContext("DebitAccount/Id");
export const HeaderPath = BindingContext.localContext("Header");

export function getAddToCostsDefFactory(dateOfTransactionValidator: (value: TValue, args: IGetValueArgs) => boolean | ValidationError): IGetDefinition {

    const getDefinition: IGetDefinition = (): IDefinition => {
        const DebitAccount = getSingleAccountFieldDef(DateOfTransactionPath);
        DebitAccount.fieldSettings.localDependentFields
            .forEach(ldf => {
                ldf.to = { id: BindingContext.localContext(`DebitAccount/${ldf.to.id}`) };
                delete ldf.navigateFrom;
            });

        const form: IFormDef = {
            id: "FixedAssetAddToCostsSpecialForm",
            translationFiles: getDefinition.translationFiles,
            fieldDefinition: {
                [DateOfTransactionPath]: {
                    type: FieldType.Date,
                    label: i18next.t("FixedAsset:AddToCosts.DateOfTransaction"),
                    fieldSettings: {
                        debouncedWait: DOCUMENT_DATE_CHANGE_DEBOUNCE
                    },
                    isRequired: true,
                    validator: {
                        type: ValidatorType.Date,
                        settings: {
                            customValidator: dateOfTransactionValidator,
                        }
                    },
                    defaultValue: defaultDateFromActiveFiscalYear
                },
                [DebitAccountIdPath]: {
                    ...DebitAccount,
                    formatter: AccountDefFormatter,
                    label: i18next.t("FixedAsset:AddToCosts.DebitAccount"),
                    filter: {
                        select: () => {
                            const excludeNumbers = ["01", "02", "03", "05", "07", "08", "09"];
                            const prefix = "startsWith(Number,'",
                                suffix = "')";
                            const excludeQuery = prefix + excludeNumbers.join(`${suffix} OR ${prefix}`) + suffix;
                            return `IsActive eq true AND NOT(${excludeQuery})`;
                        }
                    },
                    isRequired: true,
                    width: BasicInputSizes.L
                },
                [HeaderPath]: {
                    type: FieldType.Custom,
                    width: "100%",
                    render: (args: IFieldDefFn) => {
                        return (args.storage as FormStorage<unknown, IAddToCostsCustomData>).getCustomData().header;
                    }
                }
            },
            groups: [
                {
                    id: "Common",
                    showGroupDivider: false,
                    rows: [
                        [{ id: HeaderPath }],
                        [{ id: DateOfTransactionPath }, { id: DebitAccountIdPath }]
                    ]
                }
            ]
        };

        return {
            form
        };
    };

    getDefinition.translationFiles = ["FixedAsset", "Common"];

    return getDefinition;
}
