import styled from "styled-components/macro";
import { T_TITLE_4_NORMAL } from "../../global.style";

export const WorkDatePickerBody = styled.div`
    display: flex;
    align-items: center;
`;

export const WorkDatePickerDateLabel = styled.span`
    ${T_TITLE_4_NORMAL};
    margin-left: 5px;
`;