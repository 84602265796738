import styled from "styled-components/macro";
import PopperTooltip from "../popperTooltip/PopperTooltip";

export const StyledEmojiPicker = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 3px;
    user-select: none;
`;

export const PopperTooltipStyled = styled(PopperTooltip)`
    width: 225px;
    height: 225px;
`;