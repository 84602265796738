import { FillFormIcon } from "@components/icon";
import { ISelectItem } from "@components/inputs/select/Select.types";
import { IGetValueArgs } from "@components/smart/FieldInfo";
import {
    getCommonFilterDefs,
    getCommonTableColumnsDefs,
    withDisplayName
} from "@components/smart/GeneralFieldDefinition";
import {
    FilterBarGroup,
    getDefaultFilterGroupDef,
    IFilterGroupDef
} from "@components/smart/smartFilterBar/SmartFilterBar.types";
import { ISummaryItem } from "@components/smart/smartSummaryItem/SmartSummaryItem";
import {
    EntitySetName,
    EntityTypeName,
    IDocumentDraftEntity,
    IRecurringTaskEntity,
    IRecurringTaskStateEntity,
    RecurringTaskEntity
} from "@odata/GeneratedEntityTypes";
import { RecurringTaskIntervalCode, RecurringTaskPeriodCode, RecurringTaskStateCode } from "@odata/GeneratedEnums";
import { IFormatOptions } from "@odata/OData.utils";
import dayjs from "dayjs";
import i18next from "i18next";
import React from "react";

import { DASH_CHARACTER } from "../../constants";
import { BasicInputSizes, CacheStrategy, FieldType, GroupedField, LabelStatus, Sort, TextAlign } from "../../enums";
import { ColoredText } from "../../global.style";
import { TValue } from "../../global.types";
import { Model } from "../../model/Model";
import BindingContext from "../../odata/BindingContext";
import { ROUTE_RECURRING_TASKS, ROUTE_RECURRING_TASKS_EXECUTIONS } from "../../routes";
import DateType, { getUtcDate } from "../../types/Date";
import { IFormDef } from "../../views/formView/Form";
import { FormStorage } from "../../views/formView/FormStorage";
import { ISplitPageTableDef, ITableDefTabData } from "../../views/table/TableView.utils";
import { commonDocumentTranslations } from "../documents/Document.utils";
import { setDefByEntityType } from "../getDefByEntityType";
import { getItemBreadCrumbsText, IDefinition, IGetDefinition } from "../PageUtils";
import DraftPreviewInsideForm from "./DraftPreviewInsideForm";
import { TextStyled } from "./RecurringTask.styles";
import { getStatusColor, hasDraft, RecurringTaskFormViewAction } from "./RecurringTask.utils";
import ScheduledTaskFormView from "./RecurringTaskFormView";

const isVisible = (args: IGetValueArgs, periodCodes: RecurringTaskPeriodCode[]): boolean => {
    return periodCodes.includes(args.storage.data.entity.PeriodCode);
};

export enum DailyRadioButtonIds {
    WorkDay = "workday",
    Nth = "nth"
}

export const getDefinitions: IGetDefinition = (): IDefinition => {
    const filterBarDef: IFilterGroupDef[] = [{
        ...getDefaultFilterGroupDef(FilterBarGroup.Filters),
        createQuery: false,
        defaultFilters: [
            RecurringTaskEntity.Type,
            RecurringTaskEntity.Name,
            RecurringTaskEntity.DateStart
        ],
        filterDefinition: {
            ...getCommonFilterDefs(),
            Name: {},
            ...withDisplayName(RecurringTaskEntity.Type, {
                type: FieldType.ComboBox
            }),
            DateStart: {}
        }
    }];

    const tabs: ITableDefTabData[] = [{
        id: null,
        title: i18next.t("Components:FilterBar.All")
    }, {
        id: RecurringTaskStateCode.Active,
        title: i18next.t("Common:General.Active"),
        filterNames: [RecurringTaskStateCode.Active]
    }, {
        id: RecurringTaskStateCode.Paused,
        filterNames: [RecurringTaskStateCode.Paused],
        title: i18next.t("Common:General.Inactive")
    }];

    const table: ISplitPageTableDef = {
        id: `${EntityTypeName.RecurringTask}Table`,
        tabs,
        tabsSettings: {
            filterFieldName: RecurringTaskEntity.StateCode
        },
        initialSortBy: [{
            id: RecurringTaskEntity.Name,
            sort: Sort.Asc
        }],
        columns: [
            RecurringTaskEntity.Type,
            RecurringTaskEntity.Name,
            RecurringTaskEntity.State,
            RecurringTaskEntity.Executions,
            RecurringTaskEntity.DateNextExecution
        ],
        columnDefinition: {
            ...getCommonTableColumnsDefs(),
            ...withDisplayName(RecurringTaskEntity.Type),
            Name: {},
            ...withDisplayName(RecurringTaskEntity.State, {
                additionalProperties: [{ id: "Name" }],
                formatter: (val, args) => {
                    const color = getStatusColor(args.entity?.State?.Code as RecurringTaskStateCode);
                    return {
                        value: val ? <ColoredText color={color}> {val} </ColoredText> : DASH_CHARACTER,
                        tooltip: val as string
                    };
                }
            }),
            DateNextExecution: {},
            Executions: {
                label: i18next.t("RecurringTasks:Generated"),
                formatter: (val: TValue, args) => {
                    return args.entity.Executions?.length ?? "0";
                },
                textAlign: TextAlign.Right
            }

        },
        title: i18next.t("RecurringTasks:Title"),
        filterBarDef
    };

    const auditTrailSummary: ISummaryItem[] = [];

    const summary: ISummaryItem[] = [{
        id: RecurringTaskEntity.State,
        label: i18next.t("RecurringTasks:State"),
        additionalProperties: [{ id: "Name" }],
        formatter: (val, args) => {
            return (args.entity.State as IRecurringTaskStateEntity)?.Name ?? DASH_CHARACTER;
        },
        colorFormatter: (val, args) => {
            const status = args.entity.State as IRecurringTaskStateEntity;
            return getStatusColor(status?.Code as RecurringTaskStateCode);
        }
    }, {
        id: RecurringTaskEntity.Type,
        label: i18next.t("RecurringTasks:Type"),
        additionalProperties: [{ id: "Name" }],
        formatter: (val: TValue) => {
            return (val as IRecurringTaskStateEntity)?.Name ?? DASH_CHARACTER;
        }
    }, {
        id: RecurringTaskEntity.Executions,
        label: i18next.t("RecurringTasks:Generated"),
        updateFromLiveValue: true,
        formatter: (val: TValue) => {
            return (val as IDocumentDraftEntity[])?.length ?? DASH_CHARACTER;
        },
        link: args => {
            if (args.storage.data.bindingContext.isNew()) {
                return null;
            }
            return `${ROUTE_RECURRING_TASKS}/${args.storage.data.entity.Id}${ROUTE_RECURRING_TASKS_EXECUTIONS}`;
        },
        textAlign: TextAlign.Right
    }, {
        id: RecurringTaskEntity.DateNextExecution,
        label: i18next.t("RecurringTasks:NextTask"),
        formatter: (val: TValue, args: IFormatOptions<IRecurringTaskEntity>) => {
            if (!val || args.entity.State?.Code === RecurringTaskStateCode.Paused) {
                return DASH_CHARACTER;
            }
            return DateType.format(getUtcDate(val as Date));
        },
        updateFromLiveValue: true
    }];

    const form: IFormDef = {
        id: `${EntityTypeName.RecurringTask}Form`,
        getItemBreadCrumbText: (storage: Model) =>
            getItemBreadCrumbsText(storage, i18next.t("RecurringTasks:New"), i18next.t("RecurringTasks:ScheduledTask")),
        summary,
        translationFiles: getDefinitions.translationFiles,
        formControl: ScheduledTaskFormView,
        title: i18next.t("RecurringTasks:ScheduledTask"),
        isDeletable: true,
        auditTrailSummary,
        additionalProperties: [{
            id: RecurringTaskEntity.DocumentDraft
        }, {
            id: RecurringTaskEntity.DateLastModified
        }, {
            id: RecurringTaskEntity.DoesExecuteEveryWeekDay
        }],
        fieldDefinition: {
            Name: {
                width: BasicInputSizes.XL
            },
            PeriodCode: {
                type: FieldType.SegmentedButton,
                defaultValue: RecurringTaskPeriodCode.Monthly,
                labelStatus: LabelStatus.Removed,
                customizationData: { useForCustomization: false },
                fieldSettings: {
                    items: [
                        {
                            id: RecurringTaskPeriodCode.Daily,
                            label: i18next.t("RecurringTasks:Granularity.Daily")
                        }, {
                            id: RecurringTaskPeriodCode.Weekly,
                            label: i18next.t("RecurringTasks:Granularity.Weekly")
                        }, {
                            id: RecurringTaskPeriodCode.Monthly,
                            label: i18next.t("RecurringTasks:Granularity.Monthly")
                        }, {
                            id: RecurringTaskPeriodCode.Yearly,
                            label: i18next.t("RecurringTasks:Granularity.Yearly")
                        }
                    ]
                }
            },
            Interval: {
                type: FieldType.ComboBox,
                width: BasicInputSizes.S,
                defaultValue: RecurringTaskIntervalCode.ToDate,
                label: i18next.t("RecurringTasks:Scheduler.Interval"),
                isRequired: true,
                cacheStrategy: CacheStrategy.EndOfTime,
                extraFieldContent: <TextStyled>{i18next.t("RecurringTasks:Scheduler.Always")}</TextStyled>,
                fieldSettings: {
                    displayName: "Name",
                    additionalProperties: [{ id: "Number" }],
                    transformFetchedItems: (items: ISelectItem[]) => {
                        return items.sort((a, b) => a.additionalData.Number - b.additionalData.Number);
                    }
                },
                customizationData: { useForCustomization: false },
                isVisible: args => isVisible(args, [RecurringTaskPeriodCode.Monthly, RecurringTaskPeriodCode.Yearly])
            },
            ExecuteEverySpecificDayOfWeek: {
                type: FieldType.ComboBox,
                width: BasicInputSizes.S,
                isRequired: true,
                defaultValue: "MON",
                customFieldStyle: { marginRight: "20px" },
                label: i18next.t("RecurringTasks:Scheduler.Interval"),
                labelStatus: (args) => {
                    return args.storage.data.entity.PeriodCode === RecurringTaskPeriodCode.Weekly ? LabelStatus.Visible : LabelStatus.Removed;
                },
                cacheStrategy: CacheStrategy.EndOfTime,
                groupedField: GroupedField.MultiEnd,
                extraFieldContent: (args) => {
                    if (args.storage.data.entity.PeriodCode === RecurringTaskPeriodCode.Weekly) {
                        return <TextStyled>{i18next.t("RecurringTasks:Scheduler.Always")}</TextStyled>;
                    }
                    return null;
                },
                fieldSettings: {
                    displayName: "Name",
                    transformFetchedItems: (items: ISelectItem[]) => {
                        return dayjs.weekdays(true).map((day: string) => {
                            return items.find((item) => item.label.toLowerCase() === day.toLowerCase());
                        });
                    }
                },
                customizationData: { useForCustomization: false },
                isVisible: (args) => {
                    return (isVisible(args, [RecurringTaskPeriodCode.Monthly, RecurringTaskPeriodCode.Yearly])
                                    && args.storage.data.entity?.Interval?.Code !== RecurringTaskIntervalCode.ToDate) ||
                            isVisible(args, [RecurringTaskPeriodCode.Weekly]);
                }
            },
            ExecuteEveryNthDayOfMonth: {
                type: FieldType.ComboBox,
                width: BasicInputSizes.S,
                labelStatus: LabelStatus.Removed,
                cacheStrategy: CacheStrategy.EndOfTime,
                groupedField: GroupedField.MultiEnd,
                customizationData: { useForCustomization: false },
                defaultValue: "1",
                customFieldStyle: { marginRight: "20px" },
                fieldSettings: {
                    itemsFactory: async (): Promise<ISelectItem[]> => {
                        const days: ISelectItem[] = [];
                        for (let i = 1; i < 31; i++) {
                            days.push({
                                id: i.toString(),
                                label: i + "."
                            });
                        }
                        return [...days, { id: 31, label: i18next.t("RecurringTasks:Scheduler.LastDayInMonth") }];
                    }
                },
                tooltipAfter: i18next.t("RecurringTasks:Scheduler.LastMonthDayTooltip"),
                isVisible: (args) => {
                    return args.storage.data.entity?.Interval?.Code === RecurringTaskIntervalCode.ToDate &&
                            isVisible(args, [RecurringTaskPeriodCode.Monthly, RecurringTaskPeriodCode.Yearly]);
                }
            },
            ExecuteInMonth: {
                type: FieldType.ComboBox,
                cacheStrategy: CacheStrategy.EndOfTime,
                labelStatus: LabelStatus.Removed,
                defaultValue: "JAN",
                customFieldStyle: { marginRight: "20px" },
                customizationData: { useForCustomization: false },
                extraFieldContent: <TextStyled>{i18next.t("RecurringTasks:Scheduler.InMonth")}</TextStyled>,
                fieldSettings: {
                    displayName: "Name",
                    additionalProperties: [{ id: "Number" }],
                    transformFetchedItems: (items: ISelectItem[]) => {
                        return items.sort((a, b) => a.additionalData.Number - b.additionalData.Number);
                    }
                },
                isVisible: args => {
                    return isVisible(args, [RecurringTaskPeriodCode.Yearly]);
                }
            },
            DateStart: {
                type: FieldType.Date,
                customizationData: { useForCustomization: false }
            },
            ExecuteEveryNth: {
                labelStatus: LabelStatus.Removed,
                width: BasicInputSizes.S,
                defaultValue: 1,
                groupedField: GroupedField.MultiEnd,
                customizationData: { useForCustomization: false },
                extraFieldContent: (args) => {
                    if (args.storage.data.entity.PeriodCode === RecurringTaskPeriodCode.Daily) {
                        return null;
                    }
                    return <TextStyled>{i18next.t("RecurringTasks:Scheduler.Every")}</TextStyled>;
                },
                fieldSettings: {
                    unit: (args: IGetValueArgs) => {
                        return i18next.t(`RecurringTasks:GranularityUnit.${args.storage.data.entity.PeriodCode}`);
                    }
                }
            },
            [BindingContext.localContext("NoEnd")]: {
                type: FieldType.Checkbox,
                label: i18next.t("RecurringTasks:Scheduler.NoEnd"),
                labelStatus: LabelStatus.Removed,
                defaultValue: true,
                customizationData: { useForCustomization: false }
            },
            DateEnd: {
                type: FieldType.Date,
                isRequired: args => {
                    return !args.storage.getValueByPath(BindingContext.localContext("NoEnd"));
                },
                isDisabled: args => {
                    return !!args.storage.getValueByPath(BindingContext.localContext("NoEnd"));
                },
                customizationData: { useForCustomization: false }
            },
            [BindingContext.localContext("DailyRadioGroup")]: {
                type: FieldType.RadioButtonGroup,
                label: i18next.t("RecurringTasks:Scheduler.Interval"),
                isRequired: true,
                groupedField: GroupedField.MultiStart,
                customFieldStyle: { marginRight: 0 },
                defaultValue: DailyRadioButtonIds.WorkDay,
                fieldSettings: {
                    definition: [{
                        id: DailyRadioButtonIds.WorkDay,
                        label: i18next.t("RecurringTasks:Scheduler.EveryWorkDay")
                    }, {
                        id: DailyRadioButtonIds.Nth,
                        label: i18next.t("RecurringTasks:Scheduler.Every")
                    }]
                },
                customizationData: { useForCustomization: false },
                isVisible: args => isVisible(args, [RecurringTaskPeriodCode.Daily])
            },
            [BindingContext.localContext("DraftReadOnlyForm")]: {
                type: FieldType.Custom,
                label: i18next.t("RecurringTasks:TaskDialog.SelectedInvoice"),
                labelStatus: LabelStatus.Removed,
                render: (args) => {
                    if (args.storage.loading) {
                        // we can't render form when parent form is loading -> data mismatched
                        return null;
                    }
                    return <DraftPreviewInsideForm storage={args.storage as FormStorage}/>;
                },
                isVisible: hasDraft
            }
        },
        groups: [{
            id: "name",
            rows: [[{ id: RecurringTaskEntity.Name }]]
        }, {
            id: "scheduler",
            title: i18next.t("RecurringTasks:Repetition"),
            rows: [[
                { id: RecurringTaskEntity.PeriodCode }
            ], [
                { id: BindingContext.localContext("DailyRadioGroup") },
                { id: RecurringTaskEntity.Interval },
                { id: RecurringTaskEntity.ExecuteEverySpecificDayOfWeek },
                { id: RecurringTaskEntity.ExecuteEveryNthDayOfMonth },
                { id: RecurringTaskEntity.ExecuteInMonth },
                { id: RecurringTaskEntity.ExecuteEveryNth }
            ], [
                { id: RecurringTaskEntity.DateStart },
                { id: BindingContext.localContext("NoEnd") },
                { id: RecurringTaskEntity.DateEnd }
            ]],
            customizationData: {
                isLocked: true
            }
        }, {
            id: "Task",
            title: i18next.t("RecurringTasks:Task"),
            rows: [
                [{ id: BindingContext.localContext("DraftReadOnlyForm") }]
            ],
            actions: [{
                id: RecurringTaskFormViewAction.ChooseTask,
                icon: <FillFormIcon/>,
                title: i18next.t("RecurringTasks:SelectTask"),
                isVisible: (args: IGetValueArgs) => {
                    return !hasDraft(args);
                }
            }, {
                id: RecurringTaskFormViewAction.EditTask,
                icon: <FillFormIcon/>,
                title: i18next.t("RecurringTasks:EditTask"),
                isVisible: hasDraft
            }]
        }]
    };

    return {
        entitySet: EntitySetName.RecurringTasks,
        table,
        form
    };
};

getDefinitions.translationFiles = ["RecurringTasks", "InvoicesReceived", "InvoicesIssued", ...commonDocumentTranslations];
setDefByEntityType(EntityTypeName.RecurringTask, getDefinitions);