import styled from "styled-components/macro";


export const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`;

export const ToggleWrapper = styled.div`
    // visual alignment with read-only list
    margin-bottom: 14px;
`;
