import React from "react";
import { FormStorage, IGroupStatus } from "../../../views/formView/FormStorage";
import { IFieldDef } from "../FieldInfo";
import { getCollapsedGroupId } from "../Smart.utils";


export interface ISmartCollapsedBaseProps {
    storage: FormStorage;
    def: IFieldDef;
    onExpand?: (isExpanded: boolean, id: string) => void;
}

export function getCollapsedGroupStatus(storage: FormStorage, def: IFieldDef): IGroupStatus {
    return storage.data.status?.groups[getCollapsedGroupId(def)] ?? {};
}

abstract class SmartCollapsedBase<Props extends ISmartCollapsedBaseProps> extends React.Component<Props> {

    getStatus(): IGroupStatus {
        return getCollapsedGroupStatus(this.props.storage, this.props.def);
    }

    handleToggle = (): void => {
        const status = this.getStatus() || {};
        const id = getCollapsedGroupId(this.props.def);
        const isExpanded = !status?.isExpanded;
        this.props.storage.setGroupStatus({
            ...status,
            isExpanded
        }, id);

        this.props.onExpand?.(isExpanded, id);
    };
}

export default SmartCollapsedBase;