import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { usePopper } from "react-popper";
import { Menu } from "../inputs/select/SelectMenu";
import { KeyName } from "../../keyName";
import { doesElementContainsElement } from "@utils/general";
import ReactDOM from "react-dom";
import { TId } from "./Table";
import { ISelectGroup, ISelectItem } from "@components/inputs/select/Select.types";

export interface WithContextMenu {
    id?: TId;
    onContextMenu: (event: React.MouseEvent) => void;
    isContextMenuOpen: boolean;
}

export interface WithContextMenuProps {
    id?: TId;
    usePortal?: boolean;
    contextMenuItems?: ISelectItem[];
    contextMenuGroups?: ISelectGroup[];
    onContextMenuSelection?: (item: ISelectItem, id?: string) => void;
}

export const withContextMenu = <P extends WithContextMenu>(WrappedComponent: React.ComponentType<P>) => {
    return React.forwardRef<HTMLElement, React.PropsWithChildren<Omit<P, keyof WithContextMenu>> & WithContextMenuProps>((props, ref) => {
        const { contextMenuItems, onContextMenuSelection, ...passProps } = props;

        const [pos, setPosition] = useState({ top: 0, left: 0 });
        const [isOpen, setOpen] = useState(false);
        const virtualReference = useMemo(() => ({
            getBoundingClientRect() {
                return {
                    ...pos,
                    bottom: 0, right: 0,
                    width: 0, height: 0,
                    // add some props to comply with ts interface
                    x: pos.left, y: pos.top,
                    toJSON: () => {}
                };
            }
        }), [pos]);
        const [popperElement, setPopperElement] = useState(null);
        const { styles, update, state } = usePopper(virtualReference, popperElement, {
            modifiers: [],
            placement: "bottom-start"
        });
        // remember currentTarget, so we don't close currently opened context menu
        const currentTarget = useRef();

        const handleContextMenu = useCallback((event) => {
            event.preventDefault();
            currentTarget.current = event.target;
            setPosition({ top: event.pageY, left: event.pageX });
            setOpen(true);
        }, [setPosition, setOpen]);

        const handleKeyDown = useCallback((e: React.KeyboardEvent) => {
            if (e.key === KeyName.Escape) {
                setOpen(false);
            }
        }, [setOpen]);

        const handleSelectionChange = useCallback((item: ISelectItem) => {
            setOpen(false);
            onContextMenuSelection(item, props.id?.toString());
        }, [setOpen, onContextMenuSelection, props.id]);

        const handleMouseClick = useCallback((event: MouseEvent) => {
            const isContained = doesElementContainsElement(popperElement, event.target as HTMLElement) || event.target === currentTarget.current;
            if (isContained) {
                return;
            }
            setOpen(false);
        }, [popperElement, setOpen]);

        useEffect(() => {
            document.addEventListener("click", handleMouseClick);
            document.addEventListener("contextmenu", handleMouseClick);
            return () => {
                document.removeEventListener("click", handleMouseClick);
                document.removeEventListener("contextmenu", handleMouseClick);
            };
        }, [handleMouseClick]);

        let ContextMenu = (
            <Menu
                    onSelectionChange={handleSelectionChange}
                    onKeyDown={handleKeyDown}
                    value=""
                    items={contextMenuItems}
                    groups={props.contextMenuGroups}
                    isStandAlone={true}
                    popperProps={{
                    ref: setPopperElement,
                    style: styles.popper,
                    update,
                    placement: state?.placement
                }}
                // for some reason, in the newer versions,
                // SimpleBar renders with zero width for the context menu
                // => don't use it, we don't need scrollbar for context menu anyway
                    isSimpleBarDisabled
            />
        );

        const modalRoot = props.usePortal !== false && document.getElementById("context-menu-root");
        if (modalRoot) {
            ContextMenu = ReactDOM.createPortal(ContextMenu, modalRoot);
        }

        return (
            <>
                <WrappedComponent {...passProps as P}
                                  ref={ref}
                                  onContextMenu={contextMenuItems?.length && handleContextMenu}
                                  isContextMenuOpen={isOpen}/>
                {isOpen && ContextMenu}
            </>
        );
    });
};