import styled, { css } from "styled-components/macro";
import { T_PLAIN_big, T_PLAIN_big_hig, T_TITLE_4, T_TITLE_4_NORMAL } from "../../../global.style";
import { Select } from "@components/inputs/select";
import { IconButton } from "@components/button";
import Switch from "../../../components/inputs/switch";
import Checkbox from "../../../components/inputs/checkbox";
import SortableList from "../../../components/sortableList";
import Alert from "../../../components/alert";
import { NumericInput } from "@components/inputs/numericInput/NumericInput";
import { DIALOG_PADDING_VAL } from "@components/dialog/Dialog.styles";
import {
    LineNumbersWrapper,
    OrderBackground,
    STYLED_SORTABLE_LIST_OFFSET,
    StyledSortableList
} from "@components/sortableList/SortableList.styles";
import { CollapsibleBody } from "@components/collapsibleSection/CollapsibleSection.styles";

const COL_GAP = 30;
const READONLY_PADDING = 12;

export enum ValuePadding {
    None = "None",
    Input = "Input",
    Gap = "Gap"
}

export const Subtitle = styled.div`
    margin-bottom: 10px;
`;

export const LinkStyledBasic = styled.a <{
    isPaired: boolean;
}>`
    display: flex;
    color: ${props => props.theme.C_ACT_main};
    opacity: ${props => props.isPaired ? "1" : "0.5"};
    cursor: ${props => props.isPaired ? "pointer" : "default"};
`;

export const LinkText = styled.span`
    margin-left: 5px;
    margin-top: 2px;
`;

export const PairingTitle = styled.div`
    white-space: nowrap;
    margin-left: 40px;
    margin-top: 2px;
`;

export const PairingValue = styled.span<{
    value: number;
}>`
    color: ${props => !props.value ? props.theme.C_SEM_text_good : props.theme.C_SEM_text_warning};
    margin-left: 10px;

    ${T_PLAIN_big_hig};
`;

const _col = css`
    ${T_PLAIN_big_hig};
    padding-right: ${COL_GAP}px;
`;

export const InputColumn = styled.td<{
    isLast?: boolean;
}>`
    ${_col};
    ${props => props.isLast ? "padding-right: 0" : ""};
    text-align: right;
`;

export const LabelColumn = styled.td`
    ${_col};
    position: relative;
    text-align: left;
`;

export const GainRow = styled.div`
    display: flex;
    height: 50px;
    align-items: center;
    justify-content: space-between;
`;

const getColPadding = (padding: ValuePadding): number => {
    switch (padding) {
        case ValuePadding.Gap:
            return COL_GAP;
        case ValuePadding.Input:
            return READONLY_PADDING;
        case ValuePadding.None:
            return 0;
        default:
            return COL_GAP + READONLY_PADDING;
    }
};

export const ValueColumn = styled.td<{
    padding?: ValuePadding
}>`
    ${_col};
    text-align: right;
    padding-right: ${props => getColPadding(props.padding)}px;
`;

export const ExchangeRateDiffLbl = styled.div`
    ${T_TITLE_4_NORMAL};
`;

export const ExchangeRateDiffValue = styled.div`
    ${T_TITLE_4_NORMAL};
    text-align: right;
`;

export const ExchangeRateDiffLabel = styled.span <{
    isLoss?: boolean;
}>`
    ${T_TITLE_4_NORMAL};
    text-align: left;
    color: ${props => props.isLoss ? props.theme.C_SEM_text_bad : props.theme.C_SEM_text_good};
`;

export const AmountRow = styled.tr`
    height: 35px;

    & > td:nth-of-type(n+4) {
        padding-right: 0;
    }
`;

export const NumericInputStyled = styled(NumericInput)`
    margin-left: auto;
`;

export const TableStyled = styled.table`
    width: 100%;
    min-width: 240px;
    border-spacing: 0;
`;

export const TableRow = styled.tr`
    margin-bottom: 5px;
`;

export const SectionToolbar = styled.div`
    display: flex;
    margin-bottom: 25px;
    margin-left: 18px;
    position: relative;
`;

export const ToolbarIconButton = styled(IconButton)`
    margin-right: 10px;
`;

export const SmartTableWrapper = styled.div`
    height: 100%;
`;

export const AccountingBalanceStyled = styled.span`
    margin-right: 30px;
    font-weight: bold;
    margin-left: 8px;
`;

export const PairingRow = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
`;

export const AutomaticPairingCollapsibleHeader = styled.div`
    display: flex;
    flex-grow: 1;
`;

export const WriteLineParent = styled.div`
    width: 240px;
    margin-right: 12px;
    margin-left: 8px;
`;

export const AutomaticPairingSwitch = styled(Switch)`
    margin-right: 15px;
`;

export const IconButtonPair = styled(IconButton) `
    margin-right: 5px;
`;

export const BankAutLabel = styled.span<{
    isDisabled?: boolean;
}>`
    font-size: 14px;
    font-weight: bold;
    opacity: ${props => props.isDisabled ? 0.5 : 1};
`;

export const BankAutAnd = styled.span`
    margin-left: 13px;
    margin-right: 13px;
    font-size: 14px;
`;

export const BankAutLabelTolerance = styled.span<{
    isDisabled?: boolean;
}>`
    font-size: 14px;
    font-weight: bold;
    opacity: ${props => props.isDisabled ? 0.5 : 1};
    margin-right: 8px;
    margin-left: 20px;
`;

export const BankAutCol = styled.div`
    display: flex;
    height: 100%;
    align-items: center;
`;

export const BankAutSign = styled.div<{
    isDisabled?: boolean;
}>`
    position: relative;
    font-size: 14px;
    opacity: ${props => props.isDisabled ? 0.5 : 1};
    width: 10px;
    height: 100%;
    flex-shrink: 0;
    margin-right: 8px;
    margin-top: 13px;
    opacity: 0.5; // TODO: what is correct opacity? 
`;

export const BankAutSectionTitle = styled.div`
    font-size: 14px;
    font-weight: 800;
    text-transform: uppercase;
`;

export const AutLabel = styled.div`
    ${T_PLAIN_big_hig};
    margin-right: 20px;
`;

export const AutBoldLabel = styled.div`
    font-weight: bold;
    ${T_PLAIN_big_hig};
`;

export const AutBankMovement = styled.div`
    margin-bottom: 18px;
`;

export const CheckboxStyled = styled(Checkbox)`
    margin-right: 15px;
`;

export const BankAutHeader = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 7px;
`;

export const BankAutSubHeader = styled.div`
    ${T_TITLE_4};
    margin-top: 19px;
`;

export const BankAutHeaderSelect = styled(Select)`
    margin-left: 15px;
`;

export const BankAutSectionFieldsTitle = styled.div`
    font-size: 14px;
    font-weight: 800;
    text-transform: uppercase;
`;
export const AutoPairRow = styled.div<{
    isLast: boolean;
}>`
  padding: 10px 0 ${props => props.isLast ? 0 : '14px'};
  display: flex;

  ${CollapsibleBody} {
    padding-right: 19px;
  }
`;

export const ListParent = styled.div`
    position: relative;

    ${LineNumbersWrapper} {
        margin: 0 ${`-${DIALOG_PADDING_VAL}`};
    }

    ${OrderBackground} + ${StyledSortableList} {
        left: ${STYLED_SORTABLE_LIST_OFFSET};
        width: calc(100% - ${STYLED_SORTABLE_LIST_OFFSET});
    }
`;

export const SortableListStyled = styled(SortableList)``;

export const ReorderParent = styled.div`
    margin-right: 8px`;

export const CollapsibleSectionBodyParent = styled.div`
    padding-left: 2px;
    padding-top: 13px;

    ${CollapsibleBody} {
        padding-right: 0;
    }
`;

export const AlertAutoStyled = styled(Alert)`
    margin-bottom: 25px;
    width: 100%;
    margin-left: 20px;
`;

export const ConfirmationHeader = styled.div`
    margin-bottom: 30px;
`;
export const ConfirmationHeaderAccount = styled.span`
    ${T_PLAIN_big_hig};
`;

export const ConfirmationHeaderName = styled.span`
    ${T_PLAIN_big};
    margin-left: 10px;
    margin-right: 5px;
`;
export const ConfirmationHeaderAccountNumber = styled.span`
    color: ${props => props.theme.C_ACT_thick_line};
    ${T_PLAIN_big};
`;

export const GainWrapper = styled.div<{ $width?: string; $withMargin?: boolean; }>`
    display: flex;
    height: 34px;
    width: ${props => props.$width ? props.$width : "unset"};
    margin-right: ${props => props.$withMargin ? "5px" : 0};
    align-items: center;
`;
export const IconWrapper = styled.div`
    margin-right: 10px;
`;

export const SplitDialogHeader = styled.div`
    margin-bottom: 25px;
`;

export const SplitDialogValue = styled.span <{ value: number }>`
    margin-left: 5px;
    font-weight: bold;
    color: ${props => props.value === 0 ? props.theme.C_SEM_text_good : props.value > 0 ? props.theme.C_SEM_text_warning : props.theme.C_SEM_text_bad};
`;

export const AutomaticPairingTable = styled.table`
    border-collapse: separate;
    border-spacing: 0 12px;

    td {
        height: 34px;
    }
`;
