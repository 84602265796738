import React from "react";
import { StyledErrorPage } from "../notFound/NotFound.styles";
import { BatAnim } from "../../animations/Animations";
import { useTranslation } from "react-i18next";

interface IProps {
}

export const ErrorPage: React.FunctionComponent<IProps> = React.memo((props) => {
    const { t, i18n } = useTranslation("Common");
    const key = "Common:Errors.CommunicationWithServerFailed";
    const message = i18n.exists(key) ? t(key) : "Selhala komunikace se serverem. Kontaktujte, prosím, podporu.";
    return (
            <StyledErrorPage>
                <BatAnim/>
                <h2>{message}</h2>
            </StyledErrorPage>
    );
});
