import { removeWhiteSpace } from "../string";
import { SizeUnit } from "../../enums";
import { DPI, ONE_INCH_IN_MM, STANDARD_DPI } from "@utils/pdfPrinting/Print.types";

export const mmToPx = (mm: number) => {
    return mm * DPI / ONE_INCH_IN_MM;
};

export const pxToMm = (px: number) => {
    // 1 millimeter = 3.78 pixel
    return px * ONE_INCH_IN_MM / DPI;
};

export const inToPx = (inches: number) => {
    return inches * DPI;
};

export const pxToIn = (px: number) => {
    return px / DPI;
};

export const ptToPx = (pt: number): number => {
    return pt / 0.75 / STANDARD_DPI * DPI;
};

export const pxToPt = (px: number): number => {
    return px * 0.75 / STANDARD_DPI * DPI;
};

export const ptToMm = (pt: number): number => {
    // one pt is 1/72 inch
    return pt * ONE_INCH_IN_MM / 72;
};

export const convertFromPxToUnit = (value: number, unit: SizeUnit) => {
    switch (unit) {
        case SizeUnit.Inches:
            return pxToIn(value);
        case SizeUnit.Centimeters:
            return pxToMm(value) / 10;
        case SizeUnit.Millimeters:
        default:
            return pxToMm(value);
    }
};

export const convertFromUnitToPx = (value: number, unit: SizeUnit) => {
    switch (unit) {
        case SizeUnit.Inches:
            return inToPx(value);
        case SizeUnit.Centimeters:
            return mmToPx(value * 10);
        case SizeUnit.Millimeters:
        default:
            return mmToPx(value);
    }
};

export const adjustPxScale = (px: number) => {
    return px / STANDARD_DPI * DPI;
};

export const customPagesRangeRegex = /^((\d+)|(\d+-\d+))(,((\d+)|(\d+-\d+)))*$/;

export const parsePagesRange = (pagesRange: string): number[] => {
    const pages: number[] = [];
    // remove all white space
    pagesRange = removeWhiteSpace(pagesRange);

    if (!(customPagesRangeRegex.test(pagesRange))) {
        return pages;
    }

    const ranges = pagesRange.split(",");

    for (const range of ranges) {
        if (range.includes("-")) {
            // range
            const [fromPage, toPage] = range.split("-");
            let fromPageNumber = parseInt(fromPage);
            let toPageNumber = parseInt(toPage);

            if (fromPageNumber <= 0) {
                fromPageNumber = 1;
            }

            if (toPageNumber < fromPageNumber) {
                toPageNumber = fromPageNumber;
            }

            for (let i = fromPageNumber; i <= toPageNumber; i++) {
                pages.push(i);
            }
        } else {
            // single page
            pages.push(parseInt(range));
        }
    }

    return pages;
};

/** Compare aspect ratios of two rectangles. Useful when trying to fit image into new rectangle. */
export const compareRatios = (width1: number, height1: number, width2: number, height2: number) => {
    const ratio1 = width1 / height1;
    const ratio2 = width2 / height2;

    return ratio1 <= ratio2;
};

/** Returns size of the first rectangle so that it fits into the second. */
export const fitRectangle = (width1: number, height1: number, width2: number, height2: number, preventEnlarge?: boolean) => {
    if (preventEnlarge && width2 >= width1 && height2 >= height1) {
        return [width1, height1];
    }

    const shrinkByWidth = compareRatios(width2, height2, width1, height1);
    const ratio = shrinkByWidth ? width1 / width2 : height1 / height2;

    return [width1 / ratio, height1 / ratio];
};