import React from "react";
import Input from "./Input";
import Checkbox from "../checkbox";

interface IProps {
    checked: boolean;
    value: string;
    onChecked: (checked: boolean) => void;
}

export class InputWithCheckbox extends React.PureComponent<IProps> {
    handleClick = () => {
        if (this.props.onChecked) {
            this.props.onChecked(!this.props.checked);
        }
    };

    render = () => {
        return (
            <Input {...this.props}
                   content={
                       <Checkbox onChange={this.handleClick}
                                 checked={this.props.checked}/>
                   }
            />
        );
    };
}