import React, { useCallback, useEffect, useRef, useState } from "react";
import { areSimpleValuesEqual, isDefined, isNotDefined } from "@utils/general";
import { IInputOnChangeEvent } from "./Input";
import { IParserArgs } from "../../../types/Number";
import { KeyName } from "../../../keyName";

export interface WithFormatter {
    value?: string;
    isReadOnly?: boolean;
    isDisabled?: boolean;
    onChange?: (args: IInputOnChangeEvent<string>) => void;
    onBlur?: (...args: any[]) => void;
    onFocus?: (...args: any[]) => void;
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

export interface IFormatterFns<T> {
    formatter?: (value: T) => string;
    // parser with strict flag means that parsed value must be formatted to the same string by formatter,
    // with strict flag === false, we may do some optimizations for the user e.g. 1.1.2020 -> 01.01.2020
    parser?: (value: string, strict?: boolean, args?: IParserArgs) => T;
    parserArgs?: IParserArgs;
    isValid?: (value: T) => boolean;
    isSame?: (val1: T, val2: T) => boolean;
    // prevent handleBlur from firing onChange event
    // needed e.g. in DatePickerBase when popup is opened and user wants to select new value with mouse
    dontFireChangeEventOnBlur?: boolean;
    // pressing Enter will parse current value with strict set to false
    parseOnEnter?: boolean;
}

export interface WithFormatterProps<T> extends IFormatterFns<T> {
    value?: T;
    isReadOnly?: boolean;
    isDisabled?: boolean;
    onChange?: (args: IInputOnChangeEvent<T>) => void;
    onBlur?: (...args: any[]) => void;
    onFocus?: (...args: any[]) => void;
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

export const withFormatter = <P extends WithFormatter, Type>(compOrOpts: React.ComponentType<P> | IFormatterFns<Type>, component?: React.ComponentType<P>) => {
    const WrappedComponent = (component ?? compOrOpts) as React.ComponentType<P>;
    const options = ((component && compOrOpts) || {}) as IFormatterFns<Type>;

    const defaultProps: IFormatterFns<Type> = {
        formatter: (value) => isDefined(value) ? (value as unknown) as string : "",
        parser: (value, strict) => (value as unknown) as Type,
        isValid: () => true,
        isSame: (val1, val2) => areSimpleValuesEqual(val1, val2),
        ...options
    };

    return React.forwardRef((props: Omit<P, keyof WithFormatter> & WithFormatterProps<Type>, ref) => {
        const {
            value,
            onChange,
            onBlur,
            onFocus,
            onKeyDown,
            formatter,
            parser,
            parserArgs,
            isValid,
            isSame,
            dontFireChangeEventOnBlur,
            parseOnEnter,
            ...restProps
        } = props;

        const formatValueIfValid = useCallback((value: Type) => {
            if (isNotDefined(value) || (isValid ?? defaultProps.isValid)(value)) {
                return (formatter ?? defaultProps.formatter)(value) ?? "";
            }
            return undefined;
        }, [formatter, isValid]);

        const formatValue = useCallback((value: Type) => {
            return (formatter ?? defaultProps.formatter)(value);
        }, [formatter]);

        const formattedValidValue = formatValueIfValid(value);

        const [currentValue, setCurrentValue] = useState(formattedValidValue ?? formatValue(value) ?? "");
        const [parsedValue, setParsedValue] = useState(value);
        const [isFocused, setIsFocused] = useState(false);

        // we are using formatter as computed value in some cases -> always uses formatted value, no matter what
        const shouldAlwaysFormat = restProps.isReadOnly || restProps.isDisabled;

        const previousValue = useRef<Type>();

        useEffect(() => {
            const isSameFn = isSame ?? defaultProps.isSame;
            const valueHasChanged = (!isSameFn(value, previousValue.current) && !isFocused) || !isSameFn(value, parsedValue);

            // value is different from last parsedValue and is valid (= formattedValidValue is defined)
            // note (isNaN !== isNaN) is true
            if (valueHasChanged && isDefined(formattedValidValue)) {
                setParsedValue(value);
                setCurrentValue(formattedValidValue);
            }

            if (valueHasChanged) {
                previousValue.current = value;
            }
        }, [value, formattedValidValue, isSame, parsedValue, isFocused]);

        const forceParseValue = useCallback((forceTriggerChange?: boolean) => {
            const parsedVal = (parser ?? defaultProps.parser)(currentValue, false, parserArgs);
            if (!dontFireChangeEventOnBlur && (forceTriggerChange || (currentValue && !(isSame ?? defaultProps.isSame)(parsedVal, value)))) {
                onChange?.({ value: parsedVal, triggerAdditionalTasks: true });
            }
            const formattedValue = formatValueIfValid(parsedVal);
            if (isDefined(formattedValue)) {
                setCurrentValue(formattedValue);
            }
        }, [formatValueIfValid, currentValue, onChange, parser, isSame, value, dontFireChangeEventOnBlur]);

        const handleBlur = useCallback((...args) => {
            forceParseValue();
            setIsFocused(false);
            onBlur?.(...args);
        }, [onBlur, forceParseValue]);

        const handleFocus = useCallback((...args) => {
            setIsFocused(true);
            onFocus?.(...args);
        }, [onFocus]);

        const handleChange = useCallback((args: IInputOnChangeEvent<string>) => {
            let parsedValue = null;

            if (args.value) {
                parsedValue = (parser ?? defaultProps.parser)(args.value, true, parserArgs);
            }

            onChange?.({ ...args, value: parsedValue });

            let currentValue = args.value;

            // in some cases (e.g. when debouncedWait is set),
            // handleBlur that sets formatted current value is called first,
            // but then, handleChange is called with triggerAdditionalTasks and unformatted value,
            // so we need to set formatted value here, otherwise the input will show unformatted value
            if (args.triggerAdditionalTasks) {
                const newFormattedValue = formatValueIfValid(parsedValue);

                if (isDefined(newFormattedValue)) {
                    currentValue = formatValueIfValid(parsedValue);
                }
            }

            setCurrentValue(currentValue);
            setParsedValue(parsedValue);
        }, [onChange, parser, formatValueIfValid]);

        const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === KeyName.Enter) {
                // Using Enter should always call onChange with triggerAdditionalTasks,
                // because we need the onChange events to be called before onKeyDown.
                // Otherwise, e.g. Dialog is confirmed by pressing enter, before onChange of the input is handled => error
                forceParseValue(true);
            }

            onKeyDown?.(e);
        }, [onKeyDown, forceParseValue]);

        // ToDo: figure out how to typecheck this
        const passProps = (restProps as unknown) as P;

        return (
            <WrappedComponent {...passProps}
                              ref={ref}
                              value={shouldAlwaysFormat ? formattedValidValue : currentValue}
                              onBlur={handleBlur}
                              onFocus={handleFocus}
                              onChange={handleChange}
                              onKeyDown={options.parseOnEnter ?? parseOnEnter ? handleKeyDown : onKeyDown}/>
        );
    });
};

