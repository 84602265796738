import React from "react";
import { ProgressWheelCount, StyledProgressWheel } from "./ProgressWheel.styles";
import { isDefined } from "@utils/general";
import { getBadgeCount } from "../badge/Badge.utils";

interface IProps {
    value: number;
    parts: number;
    showMarks?: boolean;
    count?: number;
    /** Changes the color scheme */
    isSemantic?: boolean;
    className?: string;
}

const circleRadius = 50;
const circleWidth = 16;
const bordersWidth = 5;

export default class ProgressWheel extends React.PureComponent<IProps> {
    get circumference(): number {
        return 2 * Math.PI * circleRadius;
    }

    get percentage(): number {
        return this.props.value * 100 / this.props.parts;
    }

    get progressOffset(): number {
        return this.circumference - this.percentage / 100 * this.circumference;
    }

    get markSize(): number {
        return 5;
    }

    get markDashArray(): string {
        const allMarksSize = this.markSize * this.props.parts;
        const allSpaceSize = this.circumference - allMarksSize;
        const spaceSize = allSpaceSize / this.props.parts;

        return `${this.markSize} ${spaceSize}`;
    }

    get maxCircleSize(): number {
        // diameter of the outer border
        return (circleRadius * 2) + circleWidth + bordersWidth;
    }

    renderCount = (): React.ReactElement => {
        if (!isDefined(this.props.count)) {
            return null;
        }

        return (
            <ProgressWheelCount>
                {getBadgeCount(this.props.count, 9)}
            </ProgressWheelCount>
        );
    };

    render() {
        const maxCircleSize = this.maxCircleSize;
        const middle = maxCircleSize / 2;
        const isEmptyValue = this.props.value === 0;

        return (
            <StyledProgressWheel isSemantic={this.props.isSemantic} className={this.props.className}>
                <svg viewBox={`0 0 ${maxCircleSize} ${maxCircleSize}`} width={maxCircleSize} height={maxCircleSize}>
                    <defs>
                        <circle id="fillCircleBase"
                                cx={middle} cy={middle} r={circleRadius}
                                strokeWidth={circleWidth + bordersWidth}
                                strokeDasharray={this.circumference}
                                strokeDashoffset={this.progressOffset.toString()}
                        />
                        {/* white for masking*/}
                        <mask id="circleMask" stroke={"white"} fill={"none"}>
                            <use href="#fillCircleBase"/>
                        </mask>
                    </defs>
                    <circle className="borderCircle"
                            strokeWidth={bordersWidth}
                            cx={middle} cy={middle} r={circleRadius + (circleWidth / 2)}/>
                    <circle className="borderCircle"
                            strokeWidth={bordersWidth}
                            cx={middle} cy={middle} r={circleRadius - (circleWidth / 2)}/>
                    {/*we need conic-gradient, which is not provided by svg => use div instead*/}
                    {!isEmptyValue &&
                        <foreignObject x={0} y={0} width={maxCircleSize} height={maxCircleSize}
                                       mask="url(#circleMask)"
                        >
                            <div id={"fakeCircle"}/>
                        </foreignObject>
                    }

                    {this.props.showMarks &&
                        <>
                            <circle id="nonFiledMarks"
                                    cx={middle} cy={middle} r={circleRadius}
                                    strokeWidth={circleWidth - bordersWidth}
                                    strokeDasharray={this.markDashArray}/>
                            <circle id="filledMarks"
                                    cx={middle} cy={middle} r={circleRadius}
                                    strokeWidth={circleWidth + bordersWidth}
                                    strokeDasharray={this.markDashArray}
                                    mask="url(#circleMask)"/>
                        </>
                    }
                </svg>
                {this.renderCount()}
            </StyledProgressWheel>
        );
    }
}

export type { IProps as IProgressWheelProps };