import React from "react";
import { IAuditTrailData, Model } from "../../../model/Model";
import BindingContext from "../../../odata/BindingContext";
import { AutoSizedCheckboxGroup } from "./AutoSizedCheckboxGroup";
import { ICheckboxGroupChange, ICheckboxGroupItem } from "./CheckboxGroup";
import { getFieldInfo } from "@odata/FieldInfo.utils";

export interface ISmartCheckboxGroupChangeEvent {
    value: boolean;
    bindingContext: BindingContext;
}

interface IProps {
    bindingContext: BindingContext;
    storage: Model;
    items: ICheckboxGroupItem[];
    isDisabled?: boolean;
    isReadOnly?: boolean;
    onChange: (args: ISmartCheckboxGroupChangeEvent) => void;

    auditTrailData?: IAuditTrailData;
}

interface IState {
    items: ICheckboxGroupItem[];
}

export class SmartCheckboxGroup extends React.Component<IProps, IState> {
    state: IState = {
        items: null
    };

    componentDidMount() {
        if (this.props.items) {
            this.loadItems();
        }
    }

    componentDidUpdate(prevProps: IProps) {
        if (prevProps.items !== this.props.items) {
            this.loadItems();
        }
    }

    componentWillUnmount() {
        for (const item of this.props.items) {
            const bindingContext = this.props.bindingContext.navigate(item.id);
            const navigationPath = bindingContext.getNavigationPath();
            const refIndex = this.props.storage.refs[navigationPath]?.indexOf(this);

            if (refIndex >= 0) {
                // remove reference to this
                this.props.storage.refs[navigationPath].splice(refIndex, 1);
            }
        }
    }

    loadItems = async () => {
        const items = await Promise.all(
            this.props.items.map(async (item) => {
                const newItem = { ...item };
                const bindingContext = this.props.bindingContext.navigate(newItem.id);

                if (!newItem.label) {
                    newItem.label = (await getFieldInfo({
                        bindingContext: bindingContext,
                        context: this.props.storage.context
                    })).label;
                }

                const navigationPath = bindingContext.getNavigationPath();

                // because one Smart component uses several different properties
                // we have to manually add them to model.ref for automatic rerendering
                if (!this.props.storage.refs[navigationPath]) {
                    this.props.storage.refs[navigationPath] = [];
                }

                if (!this.props.storage.refs[navigationPath].includes(this)) {
                    this.props.storage.refs[navigationPath].push(this);
                }

                return newItem;
            })
        );

        this.setState({ items });
    };

    getValues = () => {
        return this.props.items
            .filter(item => this.props.storage.getValue(this.props.bindingContext.navigate(item.id)))
            .map(item => item.id);
    };

    handleChange = (args: ICheckboxGroupChange) => {
        const currentValues = this.getValues();
        const newValues = args.values;
        let changedItem, changedItemValue;

        if (currentValues.length < newValues.length) { // new item
            changedItem = newValues.filter(item => !currentValues.includes(item))[0];
            changedItemValue = true;
        } else { // removed item
            changedItem = currentValues.filter(item => !newValues.includes(item))[0];
            changedItemValue = false;
        }

        this.props.onChange({
            value: changedItemValue,
            bindingContext: this.props.bindingContext.navigate(changedItem)
        });
    };

    render() {
        if (!this.state.items) {
            return null;
        }

        return (
            <AutoSizedCheckboxGroup items={this.state.items} values={this.getValues()}
                                    isDisabled={this.props.isDisabled}
                                    isReadOnly={this.props.isReadOnly}
                                    auditTrailData={this.props.auditTrailData}
                                    onChange={this.handleChange}/>
        );
    }
}