import styled, { css } from "styled-components/macro";
import { getFocusBorderElement, getSemanticElColor, T_TITLE_4, T_TITLE_5 } from "../../global.style";
import { Status } from "../../enums";
import React from "react";

export const UnderlineBasic = css`
    content: "";
    bottom: -6px;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
`;

const BasicUnderline = css`
    &::after {
        content: "";
        bottom: -6px;
        background-color: ${props => props.theme.C_BG_filterbar};
        width: 100%;
        opacity: 0.15;
        height: 2px;
        position: absolute;
        left: 0;
    }
`;

const TABS_GAP = "17px";

export const UnderlineItem = css<{
    selected: boolean;
    isDisabled?: boolean;
    isActive?: boolean;
    disableFocus?: boolean;
}>`
    &::after {
        height: 4px;
        border-radius: 2px;
        background-color: ${props => props.theme.C_ACT_main};
        z-index: 1;

        width: ${props => props.selected || props.isActive ? "100%" : "0"};
        left: ${props => props.selected || props.isActive ? "0" : "50%"};
        opacity: ${props => props.selected ? 1 : 0.5};

        transition: 0.4s ease-out all .1s;
        ${UnderlineBasic};

        bottom: -8px;
    }

    ${props => !props.isDisabled && !props.disableFocus &&
        `&:hover::after {
          opacity: 0.5;
          width: 100%;
          left: 0;
        }`}
`;

export const StatusIndicator = styled.span<{
    status: Status,
    isInverse?: boolean
}>`
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${props => getSemanticElColor(props.theme, props.status, props.isInverse)};
`;

export const TabWrapper = styled.div<{
    isLastOnRow: boolean
}>`
    display: flex;
    flex-direction: row;
    width: fit-content;
    position: relative;
    flex-wrap: nowrap;
    align-items: center;

    margin-bottom: 36px;

    &:not(:first-child) {
        padding-left: ${TABS_GAP};
    }

    ${BasicUnderline};

    &:first-child::after {
        border-radius: 1px 0 0 1px;
    }

    &:last-child::after {
        border-radius: 0 1px 1px 0;
    }

    &::after {
        width: ${props => props.isLastOnRow ? `calc(100% - ${TABS_GAP})` : "100%"};
    }

    &:nth-last-child(1) {
        margin-right: 0;
    }
`;

export const IconWrapper = styled.div`
    width: 38px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const StyledTab = styled.div<{
    _opacity: number,
    hasIcon: boolean,
    isDraggable: boolean,
    selected: boolean,
    isDisabled?: boolean,
    isActive?: boolean;
    tooltip?: string;
    disableFocus?: boolean;
}>`
    ${props => !props.disableFocus && getFocusBorderElement({ offset: 2 })};

    display: flex;
    border: 1px solid transparent;
    position: relative;
    cursor: ${props => props.isDisabled && props.tooltip ? "help" : !props.isDisabled && !props.disableFocus ? "pointer" : "default"};
    user-select: none;
    opacity: ${props => props._opacity || props.isDisabled ? 0.5 : 1};
    white-space: nowrap;
    height: 19px;

    ${UnderlineItem}
`;

export const StyledDroppingArea = styled.div`
    width: ${TABS_GAP};
    height: 100%;
`;

export const TabsMenu = styled.div<{
    ref: React.Ref<HTMLDivElement>
}>`
    display: flex;
    position: relative;
    width: fit-content;
    flex-wrap: wrap;
`;

export const TabsWrapper = styled.div<{
    height: string
}>`
    position: relative;
    width: 100%;
    height: ${props => props.height || null};

    display: flex;
    flex-direction: column;

    ${getFocusBorderElement({ offset: 2, bottom: "18px", keyboardOnly: true })}
`;

export const TabContent = styled.div`
    position: relative;
    flex: 1 1 auto;
    overflow: hidden;
`;

export const Divider = styled.div`
    height: 17px;
    width: 2px;
    margin: 0 15px 0 -${TABS_GAP};
    background-color: ${props => props.theme.C_BG_filterbar};
    opacity: 0.15;
    border-radius: 2px;
`;

export const Prefix = styled.span`
    text-transform: capitalize;
`;

export const Title = styled.span`
    text-transform: uppercase;
    ${T_TITLE_4};

    ${StatusIndicator} {
        margin-right: 10px;
    }
`;
export const Count = styled.span<{
    _opacity: number,
    isDisabled?: boolean
}>`
    ${T_TITLE_5};
    opacity: ${props => props._opacity || props.isDisabled ? 0.5 : 1};
    margin-left: 5px;
`;