import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import Page, { IProps } from "../../pages/Page";
import { withOData } from "@odata/withOData";
import BackgroundJobsHistoryTableView from "./BackgroundJobsHistoryTableView";
import { getDefinitions } from "./BackgroundJobsHistory.def";

class BackgroundJobsHistory extends Page<IProps> {
    static defaultProps = {
        getDef: getDefinitions,
        tableView: BackgroundJobsHistoryTableView
    };
}

export default withRouter(withTranslation(["Common", "Components", "Error"])(withOData(BackgroundJobsHistory)));