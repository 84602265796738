import React from "react";
import { Manager, Popper, PopperChildrenProps, Reference, ReferenceChildrenProps } from "react-popper";
import { PopupWrapper } from "./Popover.styles";
import ReactDOM from "react-dom";
import SlideIn from "../slideIn/SlideIn";
import { KeyName } from "../../keyName";
import { composeRefHandlers, focusNextElement, getFocusableElements } from "@utils/general";

interface IProps {
    reference: (props: ReferenceChildrenProps) => React.ReactNode;
    isOpen: boolean;
    offsetX?: number;
    offsetY?: number;
    passRef?: React.Ref<HTMLDivElement>;
    popperProps?: PopperChildrenProps;
    preventSlideIn?: boolean;
    onKeyDown?: (e: React.KeyboardEvent) => void;
}

class PopperElement extends React.PureComponent<IProps> {
    update = () => {
        // update popover position (and placement),
        // otherwise the position is wrong (e.g. in filter bar)
        this.props.popperProps.update();
    };

    render = () => {
        return (
            <SlideIn in={this.props.isOpen}
                     onFirstHiddenRender={this.update}
                     innerProps={{
                         ref: this.props.popperProps.ref,
                         style: { ...this.props.popperProps.style, zIndex: 1000 },
                         "data-popper-placement": this.props.popperProps.placement
                     }}>
                <PopupWrapper ref={this.props.passRef} onKeyDown={this.props.onKeyDown}>
                    {this.props.children}
                </PopupWrapper>
            </SlideIn>
        );
    };
}

export default class Popover extends React.Component<IProps> {

    _popupWrapperRef = React.createRef<HTMLElement>();
    _triggerRef = React.createRef<HTMLElement>();

    get autocompleteRoot(): HTMLElement {
        return document.getElementById("autocomplete-root");
    }

    renderPopover = (popperProps: PopperChildrenProps): React.ReactElement => {
        const rootEl = this.autocompleteRoot;
        return rootEl ? ReactDOM.createPortal(this.renderPopoverContent(popperProps), rootEl) : this.renderPopoverContent(popperProps);
    };

    renderPopoverContent = (popperProps: PopperChildrenProps): React.ReactElement => {
        const { passRef, onKeyDown, ...restProps } = this.props;
        return (
            <PopperElement {...restProps}
                           onKeyDown={this.handleKeyDown}
                           passRef={composeRefHandlers(passRef, this._popupWrapperRef)}
                           popperProps={popperProps}/>
        );
    };

    handleKeyDown = (e: React.KeyboardEvent): void => {
        if (!!this.autocompleteRoot && e.key === KeyName.Tab) {
            // Handle Tab key - focus elements according to reference, because popup is rendered in autocomplete root
            const popupItems = getFocusableElements(this._popupWrapperRef.current);
            const lastFocusable = popupItems?.[popupItems.length - 1];
            const firstFocusable = popupItems?.[0];
            const referenceItems = getFocusableElements(this._triggerRef.current);
            const lastReferenceItem = referenceItems?.[referenceItems.length - 1];

            if (e.shiftKey) {
                if (firstFocusable?.isSameNode(document.activeElement)) {
                    // focus previous element before popup - last focusable el from reference
                    lastReferenceItem?.focus();
                    e.preventDefault();
                }
            } else if (lastFocusable.isSameNode(document.activeElement)) {
                // focus next element after reference
                focusNextElement(lastReferenceItem);
                e.preventDefault();
            }
        }
        this.props.onKeyDown(e);
    };

    render() {
        return (
            <Manager>
                <Reference>
                    {({ ref }) => {
                        return this.props.reference({ ref: composeRefHandlers(ref, this._triggerRef) });
                    }}
                </Reference>
                <Popper placement={"bottom-start"}
                        modifiers={[{
                            name: "offset",
                            options: {
                                offset: [this.props.offsetX ?? 0, this.props.offsetY ?? 0]
                            }
                        }]}>
                    {this.renderPopover}
                </Popper>
            </Manager>
        );
    }
}