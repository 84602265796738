import { getDefaultPostParams } from "@utils/customFetch";
import { compareString } from "@utils/string";
import i18next from "i18next";

import { EMAIL_REGEX, EVALA_WEBPAGE, GET_POST_LOGIN_ACTIONS_URL, SESSION_TENANTS_URL } from "../constants";
import { PasswordValidation, validatePassword } from "../contexts/authContext/Auth.utils";
import { IVerificationObject } from "./state/loginSlice";
import { ROUTE_LOGIN_VERIFY } from "../routes";

export const EvalaTermsAndConditionsLink = `${EVALA_WEBPAGE}/info/tos`;
export const EvalaTermsAndConditionsEduLink = `${EVALA_WEBPAGE}/info/EduTos`;
export const EvalaPersonalDataProcessingLink = `${EVALA_WEBPAGE}/info/personalDataProcessingRules`;
export const LoginTranslationNamespaces = ["Login", "Common", "Error"];

export const LOGIN_PAGE_BOX_MIN_MARGIN = 20;
export const MinimumAnimationWindow = {
    width: 1000,
    height: 600
};

export type Id = number | string;

export const validatePassToString = (pass: string): string => {
    const validations = validatePassword(pass);

    for (const id of Object.keys(validations)) {
        const validation = validations[id as PasswordValidation];

        if (!validation.isValid) {
            return validation.errMsg;
        }
    }

    return null;
};

export const validateEmail = (email: string): string => {
    if (!email) {
        return i18next.t("Login:Registration.EmailRequired");
    }

    if (!EMAIL_REGEX.test(email)) {
        return i18next.t("Login:Common.WrongEmailFormat");
    }

    return null;
};

const MAX_LENGTH = 100;
export const validateFirstName = (value: string): string => {
    if (!value) {
        return i18next.t("Login:Registration.NameRequired");
    }
    if (value.length > MAX_LENGTH) {
        return i18next.t("Common:Validation.Max", { max: MAX_LENGTH });
    }
    return null;
};
export const validateLastName = (value: string): string => {
    if (!value) {
        return i18next.t("Login:Registration.LastNameRequired");
    }
    if (value.length > MAX_LENGTH) {
        return i18next.t("Common:Validation.Max", { max: MAX_LENGTH });
    }
    return null;
};

export const getError = (msg: string) => {
    return msg ? { message: msg } : null;
};

// steps in registration queue animation
export enum QueueStates {
    Start = 0,
    FirstPerson = 135,
}

export enum AnimationType {
    BikerWithSign,
    StaticBiker,
    Queue
}

export enum RequestStatus {
    Idle = "Idle",
    Pending = "Pending",
    Error = "Error"
}

export enum AnimationPlayStatus {
    Stopped = "Stopped",
    Playing = "Playing",
}

export const MAX_BIKER_STEP = 90;
// minimum and maximum window size to fit
const BikerCfg = {
    min: { width: 1200, step: 25 },
    max: { width: 3150, step: MAX_BIKER_STEP }
};
// if biker animation doesn't finish in time, user is anyway redirected
export const MAX_ANIM_TIME = 2000; // ms

export const getBikerAnimationMaxStep = (): number => {
    // first check height
    const { offsetHeight: height, offsetWidth: width } = document.getElementsByTagName("body")[0] ?? {};
    const { max, min, round } = Math;

    const minWidth = max(MinimumAnimationWindow.width, BikerCfg.min.width);
    if (width < minWidth || height < MinimumAnimationWindow.height) {
        // animation not visible or under playable threshold -> do not play anything...
        return 0;
    }

    const calcWidth = min(BikerCfg.max.width, width) - BikerCfg.min.width;
    const widthDiff = BikerCfg.max.width - BikerCfg.min.width;
    const step = (calcWidth / widthDiff * (BikerCfg.max.step - BikerCfg.min.step)) + BikerCfg.min.step;

    return round(step);
};

export interface ITenantSessionOption {
    TenantId: number;
    TenantName: string;
    IsTenantActive: boolean;
}

export interface ICustomerSessionOption extends ITenantSessionOption {
    CompanyId: number;
    CompanyName: string;
}

export interface ILoginOptions extends ICustomerSessionOption, ITenantSessionOption {
    isCustomer: boolean;
    isResolved: boolean;
}

interface ILoginOptionsData {
    CustomerOptions: ICustomerSessionOption[];
    UserOptions: ITenantSessionOption[];
}

export async function getLoginOptionsData(): Promise<ILoginOptions[]> {
    const response = await fetch(SESSION_TENANTS_URL);
    if (response.ok) {
        const { CustomerOptions, UserOptions } = (await response.json()) as ILoginOptionsData;
        return [].concat(CustomerOptions?.sort((a, b) => compareString(a.CompanyName, b.CompanyName)).map(item => ({
            ...item,
            isCustomer: true
        })) ?? [])
                .concat(UserOptions?.sort((a, b) => compareString(a.TenantName, b.TenantName)).map(item => ({
                    ...item,
                    isCustomer: false
                })) ?? []);
    }
    return null;
}

export interface ISessionTenantData {
    TenantId: number;
    CompanyId?: number;
    LoginToCustomerPortal?: boolean;
}

export async function switchToTenant(params: ISessionTenantData): Promise<Response> {
    return fetch(SESSION_TENANTS_URL, {
        ...getDefaultPostParams(),
        body: JSON.stringify(params)
    });
}

export async function getPostLoginActions(): Promise<IVerificationObject[]> {
    const response = await fetch(GET_POST_LOGIN_ACTIONS_URL);
    return await response.json() as IVerificationObject[];
}

export function getTenantRelatedLoginOption(tenantId: number, actions: IVerificationObject[]): IVerificationObject[] {
    return actions.filter(v => v.Parameters && (JSON.parse(v.Parameters)?.TenantId === tenantId));
}

export function getVerificationUrl(verification: IVerificationObject): string {
    return `${ROUTE_LOGIN_VERIFY}/${verification.VerificationGuid}`;
}