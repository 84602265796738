import styled, { css } from "styled-components/macro";
import { CaretIconPressButton } from "../date/popup/ValuePicker";
import { LocalStackOrder } from "../../../global.style";

const KeepOnTopWhenHovered = css`
    /* Top & Bottom Stepper partly covers each other -> move on top the one, which is hovered, 
     so user has bigger active area when uses one of it and don't click on the second one by mistake. */

    &:hover {
        z-index: ${LocalStackOrder.AboveContent};
    }
`;

export const TopStepper = styled(CaretIconPressButton)`
    position: relative;
    top: 3px;
    transform: rotate(180deg);

    ${KeepOnTopWhenHovered};
`;
export const BottomStepper = styled(CaretIconPressButton)`
    position: relative;
    bottom: 3px;

    ${KeepOnTopWhenHovered};
`;