import React, { ReactNode, useCallback, useRef } from "react";
import { MoreItemsWrapper, TooltipContentWrapper } from "./MoreItems.styles";
import useHideOnBlur from "../../hooks/HideOnBlurHook";
import PopperTooltip from "../popperTooltip/PopperTooltip";
import { composeRefHandlers } from "@utils/general";
import { KeyName } from "../../keyName";
import TestIds from "../../testIds";

interface IProps {
    count: number;
    tooltipContent: ReactNode;
    passRef?: React.RefObject<HTMLDivElement>;
    showTooltip?: boolean;
}

const tooltipLikeStyles: React.CSSProperties = { padding: "3px 8px", maxWidth: "400px" };

const MoreItems = React.memo((props: IProps) => {
    const { count, tooltipContent } = props;

    const showTooltip = props.showTooltip !== false;

    const tooltipRef = useRef(null);
    const referenceElement = useRef(null);
    const { isVisible, setIsVisible } = useHideOnBlur({
        refs: [tooltipRef, referenceElement],
        initialIsVisible: false,
        onHide: () => {
            setIsVisible(false);
        }
    });

    const handleKeyDown = useCallback((e) => {
        if (e.key === KeyName.Enter) {
            handleClick(e);
        }
    }, [isVisible, showTooltip]);

    const handleClick = useCallback((e) => {
        if (showTooltip) {
            const nextIsVisible = !isVisible;
            e.stopPropagation();
            e.preventDefault();
            setIsVisible(nextIsVisible);
        }
    }, [isVisible, showTooltip]);

    // has to be rendered all the time, even if count is zero, tokenizer directly changes absolute position of more items wrapper before rerender
    return (
            <PopperTooltip content={<TooltipContentWrapper>{tooltipContent}</TooltipContentWrapper>}
                           passRef={tooltipRef}
                           inPlace={false}
                           useFade={false}
                           placement={"bottom-start"}
                           offsetY={4}
                           offsetX={18}
                           style={tooltipLikeStyles}
                           color={"C_ACT_hover_without_opacity"}
                           hideArrow={true}
                           isHidden={!isVisible}>
                {(ref) => {
                    return <MoreItemsWrapper
                            $showTooltip={showTooltip}
                            $selected={isVisible}
                            $isVisible={!!count}
                            onClick={handleClick}
                            onKeyDown={handleKeyDown}
                            ref={composeRefHandlers(ref, referenceElement, props.passRef)}
                            data-testid={TestIds.MoreItems}
                            tabIndex={showTooltip ? 0 : -1}>
                        {`+${count}`}
                    </MoreItemsWrapper>;
                }}
            </PopperTooltip>
    );
});

export default MoreItems;
