import React from "react";
import Dialog from "../../components/dialog/Dialog";
import ConfigurationList, { IConfigList } from "../../components/configurationList/ConfigurationList";
import { withTranslation, WithTranslation } from "react-i18next";
import { TableStorage } from "../../model/TableStorage";
import ConfirmationButtons from "./ConfirmationButtons";
import { AppContext } from "../../contexts/appContext/AppContext.types";
import { DASH_CHARACTER } from "../../constants";
import { convertConfigListToDefinition, convertDefinitionToConfigList } from "./TableCustomizationDialog.utils";
import { isEqual } from "lodash";

interface IProps extends WithTranslation {
    storage: TableStorage;
    title: string;
}

interface IState {
    configList?: IConfigList;
}


class TableCustomizationDialog extends React.PureComponent<IProps, IState> {
    static contextType = AppContext;
    //sadly, breaks typescript type checking
    //context: React.ContextType<typeof AppContext>;

    state: IState = {
        configList: null
    };

    componentDidMount() {
        this.init();
    }

    init = async () => {
        this.setState({
            configList: await convertDefinitionToConfigList(this.props.storage)
        });
    };

    handleConfigListChange = (configListData: IConfigList) => {
        this.setState({
            configList: configListData
        });
    };

    handleCancel = () => {
        this.close();
    };

    handleConfirm = async () => {
        const variant = this.props.storage.getVariant();
        const variantColumns = variant?.columns;
        const columns = convertConfigListToDefinition(this.props.storage, this.state.configList).map(column => {
            // keep sort from current variant
            const variantColumn = variantColumns?.find(col => col.id === column.id);

            return {
                ...column,
                ...variantColumn
            };
        });

        const isColumnsSame = isEqual(columns, variantColumns);

        if (!isColumnsSame) {
            this.props.storage.setLocalStorageVariant({
                ...variant,
                columns
            });
            await this.props.storage.updateCurrentTableColumns();
            this.props.storage.refresh();
        }


        this.close();
    };

    close = () => {
        this.props.storage.setCustomData({
            isCustomizationDialogOpen: false
        });
        this.props.storage.refresh();
    };


    render() {
        return (
            <Dialog
                title={`${this.props.title} ${DASH_CHARACTER} ${this.props.t("Common:Form.Customization")}`}
                // we need fixed height for the ConfigurationList columns scrollbars to work
                height={"9999px"}
                busy={!this.state.configList}
                onClose={this.handleCancel}
                onConfirm={this.handleConfirm}
                footer={<ConfirmationButtons onCancel={this.handleCancel} onConfirm={this.handleConfirm}
                                             useWrapper={false}/>}>
                {this.state.configList &&
                    <ConfigurationList data={this.state.configList}
                                       onDataChange={this.handleConfigListChange}/>
                }
            </Dialog>
        );
    }
}

export default withTranslation(["Common"])(TableCustomizationDialog);