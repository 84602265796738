import { WithConfirmationDialog, withConfirmationDialog } from "@components/dialog/withConfirmationDialog";
import {
    IPrAttendanceDayEntity,
    IPrAttendanceDayIntervalEntity,
    IPrAttendanceEntity,
    PrAttendanceDayEntity,
    PrAttendanceDayIntervalEntity,
    PrAttendanceEntity
} from "@odata/GeneratedEntityTypes";
import { AttendanceFastEntry } from "@pages/payroll/attendance/Attendance.types";
import { timeDiffPath } from "@pages/payroll/workingPatterns/WorkingPatternsDef";
import { isObjectEmpty, removeItemFromArray } from "@utils/general";
import { cloneDeep } from "lodash";
import React, { Component } from "react";

import CollapsibleSection from "../../../components/collapsibleSection/CollapsibleSection";
import FieldsWrapper from "../../../components/inputs/field/FieldsWrapper";
import SmartFastEntryList, {
    ActionType,
    addEmptyLineItem,
    ISmartFastEntriesActionEvent
} from "../../../components/smart/smartFastEntryList";
import SmartField, { ISmartFieldBlur, ISmartFieldChange } from "../../../components/smart/smartField/SmartField";
import BindingContext from "../../../odata/BindingContext";
import DateType, { getUtcDayjs } from "../../../types/Date";
import { FormStorage } from "../../../views/formView/FormStorage";
import { DayFundPath, HasAbsencePath, IsFullDayAbsencePath, SaldoPath, WorkedHoursPath } from "./Attendance.def";
import { DayEditWrapper } from "./Attendance.styles";
import {
    ATTENDANCE_FAKE_ID,
    createNewAttendanceDayEntity,
    generateIntervalsForDay,
    getDayWorkingHours,
    isAbsenceInWorkFEList,
    isValidInterval,
    refreshActions,
    refreshBalance,
    refreshSaldoMap,
    sortIntervalsByTimeStart
} from "./Attendance.utils";
import { IAttendanceCustomData } from "./AttendanceFormView";

interface IProps extends WithConfirmationDialog {
    storage: FormStorage<IPrAttendanceEntity, IAttendanceCustomData>;
    selectedDay: number;
    onChange: (args: ISmartFieldChange) => void;
}

const workColumns = [
    { id: PrAttendanceDayIntervalEntity.WorkType },
    { id: PrAttendanceDayIntervalEntity.TimeStart },
    { id: PrAttendanceDayIntervalEntity.TimeEnd },
    { id: timeDiffPath }
];

const absenceColumns = [
    { id: PrAttendanceDayIntervalEntity.AbsenceCategory },
    { id: PrAttendanceDayIntervalEntity.TimeStart },
    { id: PrAttendanceDayIntervalEntity.TimeEnd }
];

class AttendanceDayEditEntries extends Component<IProps> {
    componentDidMount() {
        this.setDefaults();
    }

    componentDidUpdate(prevProps: Readonly<IProps>) {
        if (this.props.selectedDay !== prevProps.selectedDay) {
            this.setDefaults();
        }
    }

    get selectedDay(): IPrAttendanceDayEntity {
        return this.selectedDayBc ? this.props.storage.getValue(this.selectedDayBc) : null;
    }

    get selectedDayBc(): BindingContext {
        const { storage, selectedDay } = this.props;
        let day = storage.data.entity.Days?.find(day => getUtcDayjs(day.Date).get("date") === selectedDay);
        if (!day) {
            day = createNewAttendanceDayEntity(selectedDay, this.props.storage);
        }
        const key = day.Id ?? day[BindingContext.NEW_ENTITY_ID_PROP];
        const isNew = !day.Id;

        return this.props.storage.data.bindingContext.navigate(PrAttendanceEntity.Days).addKey(key, isNew);
    }

    get hoursFund(): number {
        const dayIndex = getUtcDayjs(this.selectedDay.Date).get("date");
        return this.props.storage.getCustomData().hoursFundMap[dayIndex] ?? 0;
    }

    setDefaults = () => {
        const storage = this.props.storage;
        const intervals = this.selectedDay.Intervals ?? [];
        const hasAbsence = intervals.some(i => !!i.AbsenceCategory?.Id);
        const isWholeDay = hasAbsence && intervals.length === 1 && intervals[0].IsWholeDay;

        storage.setValue(this.selectedDayBc.navigate(HasAbsencePath), hasAbsence);
        storage.setValue(this.selectedDayBc.navigate(IsFullDayAbsencePath), isWholeDay);
        this.recalculateWorkingHours();
        storage.refresh();
    };

    recalculateWorkingHours = (overwrite?: boolean) => {
        const storage = this.props.storage;
        const workingHours = getDayWorkingHours(this.selectedDay);
        const workingHoursBc = this.selectedDayBc.navigate(WorkedHoursPath);

        if (overwrite || !storage.getValue(workingHoursBc)) {
            const time = getUtcDayjs().startOf("day").add(workingHours, "hours").toDate();
            storage.setValue(workingHoursBc, time);
            storage.refresh();
        }
    };

    handleAbsenceBlur = (e: ISmartFieldBlur): void => {
        const storage = this.props.storage;
        const interval: IPrAttendanceDayIntervalEntity = storage.getValue(e.bindingContext.getParent());

        if (e.wasChanged && isValidInterval(interval) && !interval.IsWholeDay && !isObjectEmpty(interval.AbsenceCategory)) {
            generateIntervalsForDay(this.selectedDayBc, this.hoursFund * 60, storage);
            storage.refresh();
        }
    };

    handleWorkIntervalBlur = (e: ISmartFieldBlur): void => {
        const storage = this.props.storage;
        const interval: IPrAttendanceDayIntervalEntity = storage.getValue(e.bindingContext.getParent());

        if (e.wasChanged && isValidInterval(interval) && isObjectEmpty(interval.AbsenceCategory)) {
            storage.setValue(this.selectedDayBc.navigate(PrAttendanceDayEntity.Intervals), this.selectedDay.Intervals.sort(sortIntervalsByTimeStart).map((interval, index) => {
                interval.Order = index;
                return interval;
            }));
            storage.refresh();
        }
    };

    refreshCalculatedFields = (): void => {
        const storage = this.props.storage;
        refreshSaldoMap(storage);
        refreshBalance(storage);
        refreshActions(storage);
        this.recalculateWorkingHours(true);
        storage.refresh();
    };

    handleBlur = async (e: ISmartFieldBlur): Promise<void> => {
        this.handleAbsenceBlur(e);
        this.handleWorkIntervalBlur(e);
        this.copyIntervalsToOtherSelected();
        this.refreshCalculatedFields();
    };

    handleChange = (e: ISmartFieldChange): void => {
        const storage = this.props.storage;
        this.handleHasAbsenceChange(e);
        this.handleIsFullDayAbsence(e);
        this.handleWorkedHoursChange(e);
        this.props.onChange(e);
        storage.refreshFields();
    };

    // if user selected more days edit first, and copy intervals to other selected days
    copyIntervalsToOtherSelected = (): void => {
        const storage = this.props.storage;
        const selectedDays = storage.getCustomData().selectedDays;
        if (selectedDays.size > 1) {
            const entity = storage.data.entity;
            selectedDays.forEach(day => {
                if (day === getUtcDayjs(this.selectedDay.Date).get("date")) {
                    return;
                }
                let dayEntity = entity.Days.find(d => getUtcDayjs(d.Date).get("date") === day);
                if (!dayEntity) {
                    dayEntity = createNewAttendanceDayEntity(day, storage);
                }
                dayEntity.Intervals = cloneDeep(this.selectedDay.Intervals);
            });
        }
    };

    handleWorkedHoursChange = (e: ISmartFieldChange): void => {
        if (e.bindingContext.getPath() === WorkedHoursPath) {
            const value = e.value as Date;
            if (!DateType.isValid(value)) {
                return;
            }
            const minutes = getUtcDayjs(value).get("hour") * 60 + getUtcDayjs(value).get("minute");
            generateIntervalsForDay(this.selectedDayBc, minutes, this.props.storage);
        }
    };

    handleHasAbsenceChange = (e: ISmartFieldChange): void => {
        if (e.bindingContext.getPath() === HasAbsencePath) {
            const storage = this.props.storage;
            if (e.value) {
                storage.setValue(this.selectedDayBc.navigate(IsFullDayAbsencePath), true);
                this.addNewWholeDayAbsence();
            } else {
                storage.setValue(this.selectedDayBc.navigate(PrAttendanceDayEntity.Intervals), []);
                generateIntervalsForDay(this.selectedDayBc, this.hoursFund * 60, storage);
            }
            this.refreshCalculatedFields();
        }
    };

    addNewWholeDayAbsence = (): void => {
        const storage = this.props.storage;
        const newItem = addEmptyLineItem({
            storage,
            bindingContext: this.selectedDayBc.navigate(PrAttendanceDayEntity.Intervals),
            newItemsCount: 1,
            index: 1,
            columns: [],
            context: storage.context
        });
        newItem.AbsenceCategory = {
            Id: ATTENDANCE_FAKE_ID
        };
        newItem.IsWholeDay = true;
        storage.setValue(this.selectedDayBc.navigate(PrAttendanceDayEntity.Intervals), [newItem]);
    };

    handleIsFullDayAbsence = (e: ISmartFieldChange): void => {
        if (e.bindingContext.getPath() === IsFullDayAbsencePath) {
            if (e.value === true) {
                this.addNewWholeDayAbsence();
            } else {
                for (const interval of (this.selectedDay.Intervals ?? [])) {
                    interval.IsWholeDay = false;
                }
                generateIntervalsForDay(this.selectedDayBc, this.hoursFund * 60, this.props.storage);
            }
            this.refreshCalculatedFields();
        }
    };

    handleAction = (e: ISmartFastEntriesActionEvent<IPrAttendanceDayIntervalEntity>) => {
        const storage = this.props.storage;
        if (e.groupId === AttendanceFastEntry.Absence) {
            const intervals = storage.getValue(this.selectedDayBc.navigate(PrAttendanceDayEntity.Intervals)) ?? [];
            const [item] = e.affectedItems;
            if (e.actionType === ActionType.Add) {
                item.AbsenceCategory = {
                    Id: ATTENDANCE_FAKE_ID
                };
                e.items = [...intervals, item];
            } else if (e.actionType === ActionType.Remove) {
                const intervalsCopy = [...intervals];
                removeItemFromArray(intervalsCopy, item);
                e.items = intervalsCopy;
            }
        }
        storage.handleLineItemsAction(e);

        if (e.actionType === ActionType.Remove) {
            this.recalculateWorkingHours(true);
            refreshSaldoMap(storage);
            refreshBalance(storage);
            refreshActions(storage);
        }

        storage.refresh();
    };

    render() {
        if (!this.selectedDay) {
            return null;
        }
        const storage = this.props.storage;
        const hasAbsence = storage.getValue(this.selectedDayBc.navigate(HasAbsencePath));
        const isFullDayAbsence = hasAbsence && storage.getValue(this.selectedDayBc.navigate(IsFullDayAbsencePath));
        const columnsLength = isFullDayAbsence ? 1 : 3;

        return <DayEditWrapper>
            <FieldsWrapper>
                <SmartField storage={storage} bindingContext={this.selectedDayBc.navigate(HasAbsencePath)}
                            onChange={this.handleChange}/>
                <SmartField storage={storage} bindingContext={this.selectedDayBc.navigate(IsFullDayAbsencePath)}
                            onChange={this.handleChange}/>
            </FieldsWrapper>
            {hasAbsence && <SmartFastEntryList
                    groupId={AttendanceFastEntry.Absence}
                    storage={storage}
                    bindingContext={this.selectedDayBc.navigate(PrAttendanceDayEntity.Intervals)}
                    filterItems={(items) => {
                        return (items as IPrAttendanceDayIntervalEntity[]).filter(i => !!i.AbsenceCategory?.Id);
                    }}
                    order={PrAttendanceDayIntervalEntity.Order}
                    columns={absenceColumns.slice(0, columnsLength)}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    useLabelWrapping={true}
                    canAdd={!isFullDayAbsence}
                    isItemCloneable={false}
                    onAction={this.handleAction}
                    isItemDisabled={isAbsenceInWorkFEList}
            />}

            <FieldsWrapper>
                <SmartField storage={storage} bindingContext={this.selectedDayBc.navigate(DayFundPath)}/>
                <SmartField storage={storage}
                            bindingContext={this.selectedDayBc.navigate(WorkedHoursPath)}
                            onChange={this.handleChange}/>
                <SmartField storage={storage} bindingContext={this.selectedDayBc.navigate(SaldoPath)}/>
            </FieldsWrapper>
            <FieldsWrapper>
                <CollapsibleSection
                        title={this.props.storage.t("Attendance:Form.RecordedWorkingHours")}
                        defaultIsOpen={false}
                        usedAsField
                        removeBodyPadding
                >
                    <SmartFastEntryList
                            groupId={AttendanceFastEntry.Work}
                            storage={storage}
                            bindingContext={this.selectedDayBc.navigate(PrAttendanceDayEntity.Intervals)}
                            columns={workColumns}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            order={PrAttendanceDayIntervalEntity.Order}
                            useLabelWrapping={true}
                            canAdd={!hasAbsence || !isFullDayAbsence}
                            isItemCloneable={false}
                            onAction={this.handleAction}
                            isItemDisabled={isAbsenceInWorkFEList}
                    />
                </CollapsibleSection>
            </FieldsWrapper>
        </DayEditWrapper>;
    }
}

export default withConfirmationDialog(AttendanceDayEditEntries);