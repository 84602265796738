import React from "react";
import { CollapseWrapper } from "../../formGroup";
import TestIds from "../../../testIds";
import FieldGroup, { FieldGroupPaddingType } from "../../fieldGroup/FieldGroup";
import SmartCollapsedBase, { ISmartCollapsedBaseProps } from "./SmartCollapsedBase";

interface IProps extends ISmartCollapsedBaseProps {
    hasEditableFields: boolean;
}

class SmartCollapsedFormGroup extends SmartCollapsedBase<IProps> {

    render() {
        const { hasEditableFields, children } = this.props;
        const { isCreating, isExpanded } = this.getStatus();

        return isExpanded ? (
                <CollapseWrapper style={{ marginTop: "-10px" }}
                                 className={isExpanded ? "show" : ""}
                                 data-testid={TestIds.HiddenItems}>
                    <FieldGroup displayCloseIcon={!isCreating}
                                paddingType={hasEditableFields ? FieldGroupPaddingType.Default : FieldGroupPaddingType.ReadOnlyContent}
                                onCloseClick={this.handleToggle}
                                isLight={isCreating}>
                        {children}
                    </FieldGroup>
                </CollapseWrapper>
        ) : null;
    }
}

export default SmartCollapsedFormGroup;
