import styled, { css } from "styled-components/macro";
import { transparentize } from "polished";
import { getDisabledStyle, getFocusBorderElement, getSemanticElColor, T_BTN_main } from "../../global.style";
import { AuditTrailFieldType } from "../smart/FieldInfo";
import { DefaultTheme } from "styled-components";
import { InputStatusCommonCSS, IStatus } from "../inputs/input/Input.styles";

export const StyledSegmentedButton = styled.div<{
    isDisabled: boolean;
    auditTrailType: AuditTrailFieldType
}>`
    display: flex;
    position: relative;
    ${props => getDisabledStyle(props.isDisabled)};

    ${props => (props.auditTrailType === AuditTrailFieldType.NoDifference || props.auditTrailType === AuditTrailFieldType.HoveredNoDifference) && `
        border-radius: 25px;
        background: ${props.theme.C_AUDIT_TRAIL_NO_DIFF};
    `}

    ${getFocusBorderElement({ offset: 0, keyboardOnly: true })}
`;

export const AuditTrailBorder = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: 3px solid ${props => props.theme.C_AUDIT_TRAIL_DIFF};
    border-radius: 25px;
    z-index: 100;
`;

const getBorderRadius = (mark: boolean): string => {
    return mark ? `${SegmentedButtonHeight/2}px` : "2.5px";
};

interface IStyledSegmentedButton {
    isSelected: boolean;
    // for disabled styles
    isDisabled: boolean;
    // for html disabled attribute
    disabled?: boolean;
    isFirst: boolean;
    isLast: boolean;
    isIcon: boolean;
    isLight?: boolean;
    hasText?: boolean;
    $width?: number;
    auditTrailType: AuditTrailFieldType;
}

interface ISegmentedButtonText {
    isLight?: boolean;
}

export const SegmentedButtonText = styled.span<ISegmentedButtonText>`
    color: ${props => props.isLight ? props.theme.C_ACT_light_line : props.theme.C_ACT_main};
    ${T_BTN_main};
    padding-left: 2px;
    white-space: nowrap;
    width: fit-content;
    position: relative;
`;

// design doesn't account for the real icon size (empty square around the icon)
// we have to substract from the button padding when icon is used
const getButtonPadding = (args: IStyledSegmentedButton, isBig: boolean) => {
    let padding = isBig ? 19 : 12;

    if (args.isIcon) {
        padding = args.isFirst || args.isLast ? padding - (isBig ? 4 : 6) : 11;
    }

    return `${padding}px`;
};

const getBoxShadowColor = (theme: DefaultTheme, isLight: boolean, isSelected: boolean) => {
    const colorBase = isLight ? theme.C_ACT_light_line : theme.C_ACT_main;

    if (isSelected) {
        return colorBase;
    }

    return transparentize(0.5, colorBase);
};

export const SegmentedButtonHeight = 32;

export const StyledSegmentedButtonItem = styled.button.attrs((props: IStyledSegmentedButton) => ({
    style: {
        width: props.$width ? `${props.$width}px` : "auto"
    }
}))<IStyledSegmentedButton>`
    position: relative;
    ${T_BTN_main};
    height: ${SegmentedButtonHeight}px;
    border: none;
    box-shadow: inset 0 0 0 1px ${props => getBoxShadowColor(props.theme, props.isLight, props.isSelected)};
        //border: 1px solid ${props => transparentize(0.5, props.theme.C_ACT_main)};
    padding-top: 0;
    padding-bottom: 0;
    padding-left: ${props => getButtonPadding(props, props.isFirst)};
    padding-right: ${props => props.hasText ? props.isLast ? "19px" : "12px" : getButtonPadding(props, props.isLast)};
    border-bottom-left-radius: ${props => getBorderRadius(props.isFirst)};
    border-top-left-radius: ${props => getBorderRadius(props.isFirst)};
    border-bottom-right-radius: ${props => getBorderRadius(props.isLast)};
    border-top-right-radius: ${props => getBorderRadius(props.isLast)};
    // collapse borders
    margin-left: ${props => props.isFirst ? "0" : "-1px"};

    display: flex;
    justify-content: center;
    align-items: center;
    cursor: ${props => !props.disabled ? "pointer" : "default"};
    background-color: ${props => props.isSelected ? props.isLight ? props.theme.C_BTN_4D_emph_bg_selected : props.theme.C_BTN_4L_trans_bg_selected : "transparent"};
    outline: none;

    ${props => !props.disabled && css<IStyledSegmentedButton>`
        &:hover {
            box-shadow: inset 0 0 0 1px ${props => props.isLight ? props.theme.C_ACT_light_line : props.theme.C_ACT_main};

            ${SegmentedButtonText} {
                color: ${props => props.isLight ? props.theme.C_BTN_4D_emph_text_hover : props.theme.C_ACT_main};
            }
        }
    `}

    ${props => getDisabledStyle(props.isDisabled)}
    ${props => getFocusBorderElement({ color: props.theme.C_BTN_hover_light, offset: -2 })};

    // keep after getDisabledStyle, so it has higher priority
    ${props => props.auditTrailType === AuditTrailFieldType.NoDifference ? "opacity: 0" : props.auditTrailType === AuditTrailFieldType.HoveredNoDifference ? "opacity: 0.6" : ""};
`;

export const SegmentedButtonStatus = styled.div<IStatus>`
    ${InputStatusCommonCSS};
    height: calc(100% - 2px);
    top: 1px;
    width: ${SegmentedButtonHeight / 2}px;
    left: 0;
    border: 2px solid ${props => getSemanticElColor(props.theme, props.status)};
    border-right: none;
    border-top-left-radius: ${getBorderRadius(true)};
    border-bottom-left-radius: ${getBorderRadius(true)};
`;
