import React, { useCallback } from "react";
import { TicketMessageAttachmentsStyled } from "./TicketMessage.styles";
import MessageFile from "./MessageFile";
import { IFileMetadataEntity } from "@odata/GeneratedEntityTypes";
import FileStorage from "../../utils/FileStorage";

interface IProps {
    attachments: IFileMetadataEntity[];
}

const TicketMessageAttachments: React.FC<IProps> = ({ attachments }) => {

    const handleDownload = useCallback((id: number) => {
        FileStorage.download(id);
    }, []);

    return attachments?.length ? (
        <TicketMessageAttachmentsStyled>
            {attachments.map(a => (
                <MessageFile key={a.Id} id={a.Id} name={a.Name} onClick={handleDownload}/>
            ))}
        </TicketMessageAttachmentsStyled>
    ) : null;
};

export default TicketMessageAttachments;