import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { IconSize, TextAlign } from "../../enums";
import { IInputOnChangeEvent } from "../inputs/input";
import { SearchHint, WriteLineStyled } from "./Search.styles";


interface IProps {
    value: string;
    hint: string;
    onChange: (value: string) => void;
    passRef?: React.Ref<HTMLInputElement>;
}

const SearchWriteline: React.FC<IProps> = (props) => {
    const { value, hint, onChange } = props;

    const { t } = useTranslation("Common");

    const handleChange = useCallback((args: IInputOnChangeEvent<string>) => {
        onChange(args.value);
    }, [onChange]);

    return (
        <>
            <WriteLineStyled value={value}
                             passRef={props.passRef}
                             maxWidth={"100%"}
                             textAlign={TextAlign.Left}
                             placeholder={t("Common:GlobalSearch.searchInEvala")}
                             isLight
                             withClearButton
                             withClearButtonSize={IconSize.M}
                             onChange={handleChange}/>
            <SearchHint>{hint}</SearchHint>
        </>
    );
};

export default SearchWriteline;
