import React from "react";
import { IFocusableItemProps } from "../focusManager";
import { ActionState } from "../../enums";
import File, { IFile } from "../fileUploader/File";
import {
    getCustomFileContextMenuItems,
    getFileContextMenuItems,
    getFileIconByName,
    getFileNameOnly
} from "./File.utils";
import { TRecordType } from "../../global.types";
import { TId } from "../table";

import { ISelectItem } from "@components/inputs/select/Select.types";

export interface IFileGridProps {
    focusableProps: IFocusableItemProps;
    files: IFile[];
    isReadOnly?: boolean;
    isDeleteDisabled?: boolean;
    showRemoveAction?: boolean;
    filesToRemove?: TRecordType<boolean>;
    onRemoveAction?: (fileId: TId[], forceState?: boolean) => void;
    onFileClick?: (fileId: TId) => void;
    customContextMenuItems?: ISelectItem[] | ((fileId: TId) => ISelectItem[]);
    onContextMenuSelection?: (item: ISelectItem, id?: string) => void;
}

export function getRemoveActionState(props: IFileGridProps, id: TId): ActionState {
    return !props.showRemoveAction ? ActionState.None
        : (props.filesToRemove[id.toString()] ? ActionState.Active : ActionState.Inactive);
}

export default class FileGrid extends React.PureComponent<IFileGridProps> {
    static defaultProps: Partial<IFileGridProps> = {
        customContextMenuItems: []
    };

    handleRemoveClick = (fileId: TId): void => {
        this.props.onRemoveAction?.([fileId]);
    };

    render() {
        return (
            this.props.files.map((file, index) => {
                return (
                    <File id={file.id} key={file.id.toString()}
                          name={getFileNameOnly(file.name, true)}
                          icon={getFileIconByName(file.name)}
                          removingAction={getRemoveActionState(this.props, file.id)}
                          onRemoveActionClick={this.handleRemoveClick}
                          onClick={this.props.onFileClick}
                          contextMenuItems={this.props.onContextMenuSelection && [...getFileContextMenuItems(this.props.isReadOnly, true, this.props.isDeleteDisabled), ...getCustomFileContextMenuItems(this.props.customContextMenuItems, file.id)]}
                          onContextMenuSelection={this.props.onContextMenuSelection}
                          passProps={this.props.focusableProps}
                    />
                );
            })
        );
    }
}