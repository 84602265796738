import {
    IDashboardDefinition,
    IDashboardLayoutConfig,
    TDashboardLayout,
    TDashboardTileConfig,
    TDashboardTileInfo,
    TLayout
} from "./Dashboard.types";
import { cloneDeep } from "lodash";


export function getDashboardTileInfo(definition: IDashboardDefinition, tile: TLayout): TDashboardTileInfo {
    if (definition.tileDefinition.hasOwnProperty(tile.i)) {
        return definition.tileDefinition[tile.i];
    }
    return null;
}

function addTileToMask(mask: boolean[][], tile: TLayout, cols: number): void {
    const _addRow = () => {
        const newRow = Array(cols).fill(false);
        mask.push(newRow);
    };

    for (let _y = tile.y; _y < tile.h + tile.y; ++_y) {
        for (let _x = tile.x; _x < tile.w + tile.x; ++_x) {
            while (_y >= mask.length) {
                _addRow();
            }
            mask[_y][_x] = true;
        }
    }
}

function createMaskFromLayout(layout: TDashboardLayout, cols: number): boolean[][] {
    const mask: boolean[][] = [];

    layout.forEach(tile => {
        addTileToMask(mask, tile, cols);
    });

    return mask;
}

/**
 * Inserts tile to existing layout, it modifies layout in place
 * @param layout
 * @param cols
 * @param tile
 * @param mask
 */
export function insertTileToLayout(layout: TDashboardLayout, cols: number, tile: TDashboardTileConfig, mask?: boolean[][]): void {
    if (!mask) {
        mask = createMaskFromLayout(layout, cols);
    }
    if (tile.w > cols) {
        throw new Error(`Cannot insert tile to layout, it can't fit. (tile width = ${tile.w}, cols = ${cols}`);
    }

    const _fits = (tile: TDashboardTileConfig, x: number, y: number): boolean => {
        for (let _y = y; _y < tile.h + y; ++_y) {
            for (let _x = x; _x < tile.w + x; ++_x) {
                if (_y >= mask.length) {
                    return x <= cols - tile.w;
                }
                if (_x >= mask[_y].length || mask[_y][_x]) {
                    return false;
                }
            }
        }

        return true;
    };

    const _add = (tile: TDashboardTileConfig, x: number, y: number): void => {
        addTileToMask(mask, { ...tile, x, y }, cols);
        layout.push({ ...tile, x, y });
    };

    let x = 0, y = 0;
    while (!_fits(tile, x, y)) {
        // search available space
        ++x;
        if (x >= cols) {
            ++y;
            x = 0;
        }
    }
    _add(tile, x, y);
}

/**
 * Calculated optimal layout from ordered tile configurations.
 * https://en.wikipedia.org/wiki/Bin_packing_problem
 * https://codeincomplete.com/articles/bin-packing/
 *
 * @param orderedTiles
 * @param cols
 */
export function createLayout(orderedTiles: TDashboardTileConfig[], cols: number): IDashboardLayoutConfig {
    const mask = createMaskFromLayout([], cols); // create empty mask
    const layout: TDashboardLayout = [];

    orderedTiles.forEach(tile => {
        if (tile.w > cols) {
            cols = tile.w;
        }
        insertTileToLayout(layout, cols, tile, mask);
    });

    return { cols, layout };
}

/**
 * Transforms actual layout to ordered set of tile config, so new layout can be calculated from the current layout.
 *
 * @param layout
 */
export function getOrderFromLayout(layout: TDashboardLayout): TDashboardTileConfig[] {
    const sortedLayout = cloneDeep(layout).sort((a, b) => {
        return (a.y === b.y) ? a.x - b.x : a.y - b.y;
    });

    return sortedLayout.map(({ i, w, h }) => ({ i, w, h }));
}

/**
 * returns minimum number of columns of given layout, which is equal to the widest tile
 * @param layout
 */
export function getMinCols(layout: TDashboardTileConfig[]): number {
    let minCols = 1;
    layout.forEach(item => {
        if (item.w > minCols) {
            minCols = item.w;
        }
    });
    return minCols;
}

export function createTileFromDefinition(id: string, definition: TDashboardTileInfo): TLayout {
    return { i: id, ...definition.size, x: 0, y: 0 };
}

/**
 * @param def
 * @param availableTiles
 */
export function createLayoutFromDefinition(def: IDashboardDefinition, availableTiles: Set<string>): TLayout[] {
    return def.groups
        .reduce((ret, group) => {
            return ret.concat(group.tiles.filter(id => availableTiles.has(id)));
        }, [])
        .map(id => createTileFromDefinition(id, def.tileDefinition[id]));
}
