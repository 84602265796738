import { ENV_EVALA_PRODUCTION, ENV_EVALA_VERSION, SYSTEM_CONFIG_URL } from "./constants";
import memoize from "./utils/memoize";

interface ISystemConfiguration {
    DisableSubdomains?: boolean;
    Domain?: string;
    InboxEmailDomain?: string;
}

export const getSystemConfig = memoize(async (): Promise<ISystemConfiguration> => {
    const result = await fetch(SYSTEM_CONFIG_URL, { method: "GET" });
    if (!result.ok) {
        // todo: what in this case...
        return {};
    }
    return await result.json();
});

/** This will only return true in actual production, not on nightly.
 * It differs from process.env.NODE_ENV which returns "development" when running dev server and "production" on build (nightly) */
export const isEvalaProduction = (): boolean => {
    return !!process.env[ENV_EVALA_PRODUCTION];
};

export const EvalaDevelVersion = "devel";

/** Returns current Evala version in production, "devel" in development. */
export const getEvalaVersion = (): string => {
    return process.env[ENV_EVALA_VERSION] || EvalaDevelVersion;
};