import { Circle, ErrorText, StyledError } from "./FormError.styles";
import TestIds from "../../../testIds";
import React from "react";
import { CloseIcon } from "../../icon";


interface IErrorProps {
    errorText: string;
    hideCircle?: boolean;
    hideText?: boolean;
    useErrorMinWidth?: boolean;
}

interface IErrorState {
    isTextVisible: boolean;
}

export default class FormError extends React.PureComponent<IErrorProps, IErrorState> {
    _errorRef = React.createRef<HTMLDivElement>();

    state: IErrorState = {
        isTextVisible: true
    };

    componentDidMount() {
        this._checkErrorVisibility();
    }

    componentDidUpdate(prevProps: Readonly<IErrorProps>, prevState: Readonly<IErrorState>) {
        this._checkErrorVisibility();
    }

    _checkErrorVisibility(): void {
        if (this.props.useErrorMinWidth) {
            const minWidth = 60;
            // we show only error without text if the input is too small to display it. Text is always visible in tooltip
            const isTextVisible = this._errorRef.current?.offsetWidth >= minWidth;
            if (isTextVisible !== this.state.isTextVisible) {
                this.setState({ isTextVisible });
            }
        }
    }

    render() {
        return (
            <StyledError data-testid={TestIds.ErrorWrapper}>
                {!this.props.hideCircle && (
                    <Circle>
                        <CloseIcon isLight width="14px" preventHover/>
                    </Circle>
                )}
                {!this.props.hideText && (
                    <ErrorText ref={this._errorRef} data-testid={TestIds.ErrorText}>
                        {(this.state.isTextVisible && this.props.errorText) || ""}
                    </ErrorText>
                )}
            </StyledError>
        );
    }
}
