import React, {HTMLAttributes} from "react";
import {
    NotificationAlertSubtitle,
    NotificationAlertTitle,
    NotificationTitlesWrapper,
    StyledNotificationAlert
} from "./Notifications.styles";
import Avatar from "../components/avatar";
import {AvatarSize} from "../enums";
import TestIds from "../testIds";

export interface IProps {
    avatarSrc: string;
    title: string;
    subtitle: string;
    onFadeEnd?: () => void;
    passProps?: HTMLAttributes<HTMLDivElement>;
    passRef?: React.Ref<HTMLDivElement>;
    className?: string;
    style?: React.CSSProperties;
}

export default class NotificationAlert extends React.PureComponent<IProps> {
    render() {
        return (
            <StyledNotificationAlert ref={this.props.passRef}
                                     className={this.props.className}
                                     style={this.props.style}
                                     onAnimationEnd={this.props.onFadeEnd}
                                     data-testid={TestIds.NotificationAlert}
                                     {...this.props.passProps}>
                <Avatar src={this.props.avatarSrc}
                        size={AvatarSize.SM}
                        isRound/>
                <NotificationTitlesWrapper>
                    <NotificationAlertTitle title={this.props.title} data-testid={TestIds.Title}>
                        {this.props.title}
                    </NotificationAlertTitle>
                    <NotificationAlertSubtitle title={this.props.subtitle} data-testid={TestIds.Subtitle}>
                        {this.props.subtitle}
                    </NotificationAlertSubtitle>
                </NotificationTitlesWrapper>
            </StyledNotificationAlert>
        );
    }
}