import React from "react";

import { FormStorage } from "../../../views/formView/FormStorage";
import { FormTabs } from "../../../views/formView/FormView.styles";
import { GroupHeader } from "../../formGroup/GroupHeader";
import { IFormGroupDef } from "./SmartFormGroup";

interface ISmartFormTabsProps {
    storage: FormStorage;
    group: IFormGroupDef;
    index: number;
    renderGroup: (group: IFormGroupDef, index: number, title?: string, isInTab?: boolean) => React.ReactElement;
}

// standalone component, so that we can use storage.addGroupRef on it to be re-renderable via storage.refreshGroupByKey
export default class SmartFormTabs extends React.Component<ISmartFormTabsProps> {
    handleTabChange = (activeTab: string): void => {
        this.props.storage.setGroupStatus({ activeTab }, this.props.group.id);
    };

    render() {
        const { group } = this.props;
        const groupStatus = this.props.storage.getGroupStatus(group.id);

        return (
            <React.Fragment key={group.id}>
                <GroupHeader title={group.title as string} showLine/>
                <FormTabs
                    isFirst={this.props.index === 0}
                    hasTitle={!!group.title}
                    onChange={this.handleTabChange}
                    data={group.tabs
                        .filter((groupDef) => {
                            return !!groupStatus?.visibleTabs?.[groupDef.id];
                        })
                        .map((groupDef, i) => {
                            return {
                                id: groupDef.id,
                                title: groupDef.title as string,
                                // content has to be memoized, to prevent table rerendering
                                content: () => {
                                    return this.props.renderGroup(groupDef, this.props.index, "", true);
                                }
                            };
                        })}
                    selectedTabId={groupStatus?.activeTab ?? group.tabs?.[0]?.id}/>
            </React.Fragment>
        );
    }
}
