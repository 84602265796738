import React, { useCallback, useEffect, useState } from "react";
import { IInputOnChangeEvent } from "@components/inputs/input";
import { IWriteLineSettings } from "@components/smart/FieldInfo";
import { getFileNameWriteLineDef } from "./FileViewUtils";
import { splitFileName } from "@components/fileUploader/File.utils";
import { useTranslation } from "react-i18next";
import WriteLine from "../../components/inputs/writeLine/WriteLine";
import styled from "styled-components/macro";

interface IProps {
    name: string;
    onChange: (name: string) => Promise<boolean>;
    className?: string;
}

const FileRenameWriteLineStyled = styled(WriteLine)`
    // negative margin to shift the field into the padding area of the parent
    // prevent from overlapping with SplitPage control buttons
    margin: -20px 130px 16px 0;
    font-size: 14px;
`;


const FileRenameWriteLine: React.FC<IProps> = ({ name, onChange, className }) => {
    const { prefix, extension } = splitFileName(name);
    const { t } = useTranslation("Components");

    const [currentName, setCurrentName] = useState<string>("");

    const handleChange = useCallback((args: IInputOnChangeEvent<string>) => {
        setCurrentName(args.value);
    }, [setCurrentName]);

    const handleCancel = useCallback(() => {
        setCurrentName(prefix);
    }, [setCurrentName, prefix]);

    const handleConfirm = useCallback(async () => {
        if (!currentName?.trim()) {
            handleCancel();
            return;
        }
        const isConfirmed = await onChange(`${currentName}.${extension}`);
        if (!isConfirmed) {
            handleCancel();
        }
    }, [currentName, extension, handleCancel, onChange]);

    const def = getFileNameWriteLineDef(t);
    delete def.fieldSettings.formatter;

    useEffect(() => {
        setCurrentName(prefix);
    }, [name, prefix]);

    return (
        <FileRenameWriteLineStyled value={currentName}
                                   name={"FileName"}
                                   textAlign={def.textAlign}
                                   className={className}
                                   onCancel={handleCancel}
                                   onConfirm={handleConfirm}
                                   isConfirmable
                                   {...(def.fieldSettings as IWriteLineSettings)}
                                   onChange={handleChange}/>
    );
};

export default FileRenameWriteLine;