import React, { lazy } from "react";
import PrivateRoute from "../routes/PrivateRoute";
import {
    AUDIT_TRAIL,
    ROUTE_INBOX,
    ROUTE_INBOX_CUSTOMER_APPROVAL,
    ROUTE_INBOX_SEND_TO_ACCOUNTANT,
    ROUTE_INBOX_SETTINGS
} from "../routes";
import { CompanyPermissionCode } from "@odata/GeneratedEnums";

const InboxItemLog = lazy(() => import(/* webpackChunkName: "inbox" */"../pages/inbox/InboxItemLog"));
const Inbox = lazy(() => import(/* webpackChunkName: "inbox" */"../pages/inbox/Inbox"));
const InboxCustomerApproval = lazy(() => import(/* webpackChunkName: "inbox" */"../pages/inbox/InboxCustomerApproval"));
const InboxCustomerItemLog = lazy(() => import(/* webpackChunkName: "inbox" */"../pages/inbox/InboxCustomerItemLog"));
const InboxSendToAccountant = lazy(() => import(/* webpackChunkName: "inbox" */"../pages/inbox/InboxSendToAccountant"));
const InboxFileActionLog = lazy(() => import(/* webpackChunkName: "inbox" */"../pages/inbox/InboxFileActionLog"));
const InboxSettings = lazy(() => import(/* webpackChunkName: "inbox" */"../pages/inboxSettings/InboxSettings"));

export default function inboxBundle() {
    return [
        <PrivateRoute
                path={`${ROUTE_INBOX}/${AUDIT_TRAIL}`}
                key={`${ROUTE_INBOX}/${AUDIT_TRAIL}`}
                permission={CompanyPermissionCode.Audit}
                component={InboxItemLog}/>,
        <PrivateRoute
                path={`${ROUTE_INBOX}/:folder?`}
                key={`${ROUTE_INBOX}/:folder?`}
                permission={CompanyPermissionCode.SortingFilesFromAPhotoFile}
                component={Inbox}/>,
        <PrivateRoute
                exact
                path={ROUTE_INBOX_CUSTOMER_APPROVAL}
                key={ROUTE_INBOX_CUSTOMER_APPROVAL}
                permission={null}
                component={InboxCustomerApproval}/>,
        <PrivateRoute
                path={`${ROUTE_INBOX_CUSTOMER_APPROVAL}/${AUDIT_TRAIL}`}
                key={`${ROUTE_INBOX_CUSTOMER_APPROVAL}/${AUDIT_TRAIL}`}
                permission={CompanyPermissionCode.Audit}
                component={InboxCustomerItemLog}/>,
        <PrivateRoute exact
                      path={ROUTE_INBOX_SEND_TO_ACCOUNTANT}
                      key={ROUTE_INBOX_SEND_TO_ACCOUNTANT}
                      permission={null}
                      component={InboxSendToAccountant}/>,
        <PrivateRoute
                path={`${ROUTE_INBOX_SEND_TO_ACCOUNTANT}/${AUDIT_TRAIL}`}
                key={`${ROUTE_INBOX_SEND_TO_ACCOUNTANT}/${AUDIT_TRAIL}`}
                permission={CompanyPermissionCode.Audit}
                component={InboxFileActionLog}/>,

        <PrivateRoute
                path={ROUTE_INBOX_SETTINGS}
                key={ROUTE_INBOX_SETTINGS}
                permission={CompanyPermissionCode.CustomerSettings}
                component={InboxSettings}/>
    ];
}