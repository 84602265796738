import styled, { css, DefaultTheme } from "styled-components/macro";
import { IButtonGroupProps } from "./Button";
import {
    getFocusBorderElement,
    getSemanticBgColor,
    getSemanticElColor,
    getSemanticTextColor,
    T_BTN_main,
    T_PLAIN_tiny,
    textAlignToJustify
} from "../../global.style";
import { COLOR_TRANSITION_TIME } from "../../constants";
import { Direction, IconSize, Status, TextAlign } from "../../enums";
import { PropsWithTheme, themes } from "../../theme";
import { StyledSegmentedButton } from "./SegmentedButton.styles";
import { ButtonSize } from "./Button.utils";
import { isDefined } from "@utils/general";
import { StyledBusyIndicator } from "../busyIndicator/BusyIndicator.styles";

export interface IStyledButtonProps {
    // use Transient props with $ prefix
    // to prevent styled-components from passing those props to the underlying element/component
    $ignoreTheme?: boolean;
    $status?: Status;
    $isLight?: boolean;
    $isTransparent?: boolean;
    $isDecorative?: boolean;
    $isDisabled?: boolean;
    $isBusy?: boolean;
    $isActive?: boolean;
    $hasIcon?: boolean;
    $isHover?: boolean;
    $cursor?: string;
    $isBold?: boolean;
    $size?: ButtonSize;
}

export const getThemeValue = (props: IStyledButtonProps & PropsWithTheme, appendix: string) => {
    const theme = props.$ignoreTheme ? themes.light : props.theme;
    if (props.$isDecorative && ["bg", "line_color", "bg_selected", "line_color_hover", "bg_hover"].includes(appendix)) {
        return "transparent";
    }
    if (props.$status) {
        if (["bg", "bg_hover"].includes(appendix)) {
            return "transparent";
        }
        if (["text", "text_selected", "text_hover"].includes(appendix)) {
            return getSemanticTextColor(theme, props.$status, (props.$isHover || appendix === "text_hover"));
        }
        if (appendix === "bg_selected") {
            return getSemanticBgColor(theme, props.$status);
        }
        if (appendix.startsWith("line_size")) {
            return "1";
        }
        return getSemanticElColor(theme, props.$status, false, (props.$isHover || appendix === "line_color_hover"));
    }
    return theme[`C_BTN_4${!props.$isLight ? "L" : "D"}_${(props.$isTransparent || props.$isDecorative) ? "trans" : "emph"}_${appendix}` as keyof DefaultTheme];
};

export const getFocusBorderColor = (props: IStyledButtonProps & PropsWithTheme) => {
    if ((props.$isTransparent || props.$isDecorative || props.$status) && !props.$isLight) {
        return props.theme.C_BTN_hover_light;
    }
    return props.theme.C_BTN_hover_dark;
};

const activeCss = css`
    background-color: ${props => getThemeValue(props, "bg_selected")};
    box-shadow: ${props => `inset 0 0 0 ${getThemeValue(props, "line_size_selected")}px ${getThemeValue(props, "line_color_selected")}`};
    color: ${props => getThemeValue(props, "text_selected")};
`;


export const ButtonExtraCount = styled.em`
    ${T_PLAIN_tiny};
    font-weight: normal;
    font-style: normal;
    margin-left: 6px;
    position: relative;
`;

const buttonStyles = css<IStyledButtonProps>`
    ${T_BTN_main};

    // flex-basis needs to be defined so that the button shrinks in flex before it wraps https://stackoverflow.com/questions/26125962/flex-box-shrink-before-wrap
    // min-content isn't widely supported, using 70px instead. it is limited with min/max width anyway
    flex: 1 1 70px;
    min-width: min-content;
    max-width: max-content;
    box-sizing: content-box;

    position: relative;
    background-color: ${props => getThemeValue(props, "bg")};
    box-shadow: ${props => `inset 0 0 0 ${getThemeValue(props, "line_size")}px ${getThemeValue(props, "line_color")}`};
    color: ${props => getThemeValue(props, "text")};
    text-align: center;
    cursor: ${props => props.$isDisabled || props.$isBusy ? "default" : props.$cursor ?? "pointer"};
    border-radius: 30px;
    display: inline-block;
    outline: none;
    border: none;
    padding: 10px 19px;
    margin: 0;
    text-decoration: none;

    -webkit-appearance: none;
    -moz-appearance: none;

    transition: background ${COLOR_TRANSITION_TIME}ms ease-in-out, color ${COLOR_TRANSITION_TIME}ms ease-in-out, transform 150ms ease;

    ${props => props.$size === ButtonSize.Small && css`
        padding: 6px;
        min-width: 20px;
        height: 20px;
    `};

    ${props => props.$size === ButtonSize.Big && css`
        font-size: 17px;
        padding: 16px 30px;
    `};

    ${props => !props.$isBold && css`
        font-weight: normal;
    `};

    ${props => !props.$isDisabled && css<IStyledButtonProps>`
        &:hover, &:active {
            box-shadow: ${props => `inset 0 0 0 ${getThemeValue(props, "line_size_hover")}px ${getThemeValue(props, "line_color_hover")}`};
            color: ${props => getThemeValue(props, "text_hover")};
        }

        &:active {
            ${activeCss};
        }

        ${props => getFocusBorderElement({
            color: getFocusBorderColor(props),
            offset: -2,
            keyboardOnly: props.$isDecorative
        })};
    `};

    ${props => props.$isDisabled && css`
        opacity: ${props => props.theme.disabled_opacity};
    `};

    ${props => props.$isActive && !props.$isDisabled && activeCss};

    ${props => !props.$isActive && !props.$isDisabled && css`
        &:hover:not(:active) {
            background-color: ${props => getThemeValue(props, "bg_hover")};
        }
    `};

    ${props => props.$isHover && !props.$isDisabled && css`
        box-shadow: ${props => `inset 0 0 0 ${getThemeValue(props, "line_size_hover")}px ${getThemeValue(props, "line_color_hover")}`};
        color: ${props => getThemeValue(props, "text_hover")};
        background-color: ${props => getThemeValue(props, "bg_hover")};
    `};

    ${props => props.$hasIcon && css`
        padding-left: 15px;
    `};

    // special indicator style for button, shown in the right side

    ${StyledBusyIndicator} svg {
        position: absolute;
        right: 4px;
    }

    ${props => props.$isBusy && css`
        ${ButtonExtraCount} {
            visibility: hidden;
        }
    `}
`;

// div is changed via "as" property to desired element (button/a/Link)
export const StyledButton = styled.div`
    ${buttonStyles};
`;

export const IconWrapper = styled.div`
    position: absolute;
    width: ${IconSize.S};
    height: ${IconSize.S};
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
`;

const iconButtonStyles = css<IStyledButtonProps>`
    padding: ${props => (isDefined(props.$size) && props.$size === ButtonSize.Small) || props.$isDecorative ? "0" : "6px"};
    line-height: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: unset;
    height: fit-content;
    flex: 0 0 auto;

    ${props => getFocusBorderElement({
        color: getFocusBorderColor(props),
        offset: props.$isDecorative ? 1 : -2,
        keyboardOnly: props.$isDecorative
    })};
`;

// div is changed via "as" property to desired element (button/a/Link)
export const StyledIconButton = styled.div`
    ${buttonStyles};
    ${iconButtonStyles};
`;

export const TextWrapper = styled.span<{ hasIcon: boolean }>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 1px;
    margin-left: ${props => props.hasIcon ? "27px" : "0"};
    line-height: 1.5;
`;

export const ClickCapture = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    // hidden, but fires click events
    opacity: 0
`;

export const StyledButtonGroup = styled.div<IButtonGroupProps>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: ${props => textAlignToJustify(props.align ?? TextAlign.Center)};
    flex-direction: ${props => props.direction === Direction.Horizontal ? "row" : "column"};
    flex-wrap: ${props => props.wrap};

    ${props => props.direction === Direction.Vertical && css`
        ${StyledButton} {
            // prevent buttons from stretching vertically
            flex: 0;
        }
    `};

    // target direct children, we could have nested ButtonGroups
    & > {
        // buttons should have 15px margin between themselves
        // between icon and regular button, there should be 15px margin
        // use [style*="display: none"] to ignore buttons that are hidden in small Toolbar 
        ${StyledButton}:not([style*="display: none"]) + ${StyledButton},
        ${StyledIconButton}:not([style*="display: none"]) + ${StyledButton},
        ${StyledButton}:not([style*="display: none"]) + ${StyledIconButton},
        ${StyledSegmentedButton}:not([style*="display: none"]) + ${StyledSegmentedButton},
        ${StyledButton}:not([style*="display: none"]) + ${StyledSegmentedButton},
        ${StyledSegmentedButton}:not([style*="display: none"]) + ${StyledButton},
        ${StyledIconButton}:not([style*="display: none"]) + ${StyledSegmentedButton},
        ${StyledSegmentedButton}:not([style*="display: none"]) + ${StyledIconButton} {
            margin-left: ${props => props.direction === Direction.Horizontal ? "15px" : "0"};
            margin-top: ${props => props.direction === Direction.Horizontal ? "0" : "15px"};
        }

        // icon buttons should have 2px margin between themselves
        ${StyledIconButton}:not([style*="display: none"]) + ${StyledIconButton} {
            margin-left: ${props => props.direction === Direction.Horizontal ? "2px" : "0"};
            margin-top: ${props => props.direction === Direction.Horizontal ? "0" : "2px"};
        }
    }
`;