import styled, { css } from "styled-components/macro";
import { transparentize } from "polished";
import { IconButton } from "../button";
import { getFocusBorderElement, LocalStackOrder } from "../../global.style";

export const ShadowStyles = css`
    box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.28);
`;

export const StyledSplitLayout = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    overflow: hidden;

    ${getFocusBorderElement({ offset: 0, keyboardOnly: true })};
`;

interface IPane {
    isCollapsedHovered: boolean;
    isCollapsed: boolean;
    isHighlighted: boolean;
    shouldShowGradient: boolean;
    isLastCollapsed: boolean;
    isFixed?: boolean;
}

export const StyledPaneHovered = css<IPane>`
    cursor: pointer;
    min-width: ${props => props.isLastCollapsed ? "75px" : "53px"};


    transition-delay: ${props => props.isLastCollapsed ? "0.4s" : ""};

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: ${props => props.isHighlighted ? transparentize(0.9, props.theme.C_ACT_der) : props.theme.C_SPLIT_LAYOUT_COLLAPSED_HOVER};
    }

`;

const BubbleHoverStyles = css<{ isCollapsed?: boolean }>`
    background-color: ${props => props.isCollapsed ? props.theme.C_SPLIT_LAYOUT_BUBBLE : ""};
`;

export const BubbleWrapper = styled.div<{ isSecond?: boolean }>`
    position: absolute;
    width: 100px;

    // make space for shadows
    height: 150px;
    top: ${props => props.isSecond ? "-12px" : "-50px"};

    display: flex;
    overflow: hidden;
    left: 0;
    z-index: ${LocalStackOrder.AboveOverlay};
    
    pointer-events: none;
`;

export const BubbleIconWrapper = styled.div`
    display: flex;
    width: 26px;
    height: 26px;
    padding: 0;
    position: relative;
    top: 5px;
    right: 2px;
`;

export const Bubble = styled.div<{
    isGrayBubble?: boolean;
    shouldShowGradient?: boolean;
    isWider?: boolean;
    isCollapsed?: boolean;
}>`
    width: ${props => props.isWider ? "38px" : "25px"};
    height: 38px;

    position: relative;
    // compensate for BubbleWrapper offset
    top: 50px;

    ${props => props.isWider && css`
        ${BubbleIconWrapper} {
            right: -9px;
        }
    `};

    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    ${props => props.shouldShowGradient ? PaneGradient
            : css`background-color: ${props.isGrayBubble ? props.theme.C_SPLIT_LAYOUT_BUBBLE : props.theme.C_BG_floorplan}`};
    z-index: -1;
    pointer-events: all;
    ${ShadowStyles};

    &:hover {
        ${BubbleHoverStyles};
    }

`;

export const SecondaryBubbleIconWrapper = styled.div<{ isSecond: boolean }>`
    display: flex;
    width: 26px;
    height: 26px;
    position: absolute;
    z-index: 1;
    top: ${props => props.isSecond ? "44px" : "6px"};
    right: -16px;
`;


export const StyledPane = styled.div<IPane>`
    display: flex;
    position: relative;
    min-width: 0;

    flex: 0 1 auto;
    flex-shrink: ${props => props.isCollapsed || props.isFixed ? 0 : 1};

    ${(props) => props.isCollapsed && css`
        &:hover {
            ${StyledPaneHovered};
        }
    `}
    ${(props) => (props.isCollapsedHovered || props.isHighlighted) && css`
        ${StyledPaneHovered};
    `}
    ${props => props.shouldShowGradient && PaneGradient}
    &:hover ${Bubble} {
        ${BubbleHoverStyles};
    }

    transition: min-width 0.2s;

    ${getFocusBorderElement({ offset: 0, keyboardOnly: true })};
`;

export const Splitter = styled.div<{
    isResizable: boolean
    hasSecondaryTab: boolean;
}>`
    width: 38px;

    height: 100%;
    position: absolute;
    right: ${props => props.hasSecondaryTab ? "-25px" : "-38px"};
    //z-index: 1;

    flex-shrink: 0;
    pointer-events: ${props => props.isResizable ? "all" : "none"};
`;

export const LastPaneIcon = styled.div`
    position: absolute;
    top: 8px;
    right: 49px;
`;

export const Resizer = styled.div<{
    isResizable: boolean
}>`
    width: 8px;
    left: -4px;
    height: 100%;

    position: relative;
    ${({ isResizable, theme }) => isResizable && `
    &:hover {
        cursor: ew-resize;
        border-left-color: ${theme.C_ACT_main};
        opacity: 0.5;     
    }
  `}
`;

export const SplitterBackground = styled.div<{
    isCollapsed: boolean;
    isLastOpen: boolean;
}>`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    overflow: visible;

    pointer-events: none;
`;

export const PaneGradient = css`
    background-color: ${props => props.theme.C_BG_floorplan};
    background-image: ${props => `linear-gradient(to bottom, ${transparentize(0.62, props.theme.C_ACT_der)}, ${props.theme.C_BG_floorplan})`};
    background-size: 100% 150px;
    background-repeat: no-repeat;
`;

export const Shadow = styled.div`
    height: 100%;
    position: absolute;
    width: 10px;
    left: -10px;
    z-index: 2;
    ${ShadowStyles};
    clip-path: inset(0 -100px -100px 1px);
`;

export const Content = styled.div<{
    isCollapsed: boolean;
    isFixed: boolean;
    hasSecondaryTab: boolean;
}>`
    height: 100%;
    width: ${props => props.isFixed ? "fit-content" : props.hasSecondaryTab ? "calc(100% - 19px)" : "100%"};
    opacity: ${props => props.isCollapsed ? 0 : 1};

    overflow: hidden;
    position: relative;

`;

export const Spacer = styled.div`
    width: 10px;
    z-index: 1;
    background-color: ${props => props.theme.C_BG_floorplan};
`;

export const IconsWrapper = styled.div<{
    isLastOpen: boolean
    hasSecondaryTab: boolean;
}>`
    align-items: center;
    position: absolute;
    display: flex;
    right: ${props => props.isLastOpen ? "30px" : props.hasSecondaryTab ? "13px" : "2px"};
    top: 7px;
    pointer-events: all;
    z-index: 2;
`;

export const PaneIconButton = styled(IconButton)`
    margin: 3px 8px 3px 3px;
`;

export const SecondaryTabPane = styled.div`
    position: relative;
    width: 19px;
    height: 100%;
    background-color: ${props => props.theme.C_BG_floorplan};
    clip-path: inset(-100px -100px -100px 1px);
    cursor: pointer;
    pointer-events: all;
    ${ShadowStyles};

    ${BubbleWrapper} {
        z-index: 1;
        left: 18px;
        overflow: hidden;
    }

    ${Bubble} {
        width: 19px;
    }
`;