import FileInput from "@components/inputs/fileInput/FileInput";
import React from "react";

import { IValidationError } from "../../../model/Validator.types";

interface IProps {
    onChange: (files: File[]) => void;
    width: string;
    error?: IValidationError;
}

class SmartFileInput extends React.Component<IProps> {
    render() {
        return <FileInput onChange={this.props.onChange}
                          error={this.props.error}
                          width={this.props.width}/>;
    }
}

export default SmartFileInput;