import { logger } from "./log";
import { TRecordAny, TRecordType } from "../global.types";
import { REST_API_URL } from "../constants";
import { getDefaultPostParams } from "./customFetch";

export interface IP13nResponse {
    Value: TRecordAny;
}

const emptyResponse: IP13nResponse = { Value: {} };

export class P13n {
    static baseUrl = `${REST_API_URL}/Personalization`;

    data: TRecordAny = {};
    unfinishedGet: TRecordType<Promise<TRecordAny>> = {};
    unfinishedUpdate: TRecordType<Promise<void>> = {};

    handleError(error?: Error | string): void {
        if (typeof error === "string") {
            logger.error(error);
            return;
        }

        // TODO change to global error handling (should it throw exception..?)
        logger.error("error in P13n", error);
    }

    async update<T, K extends keyof T = keyof T>(id: string, name: K, data: T[K] = null): Promise<boolean> {
        if (!this.data[id]) {
            this.data[id] = {};
        }
        this.data[id][name] = data;

        const value: TRecordAny = {
            [name]: data
        };

        // TODO batch multiple update calls fired at the same time into one,
        // instead of calling them on after each other

        // We should not send another request if the previous one is not finished yet
        // -> results to conflicts on BE and not storing value at all
        // and eventual get call can wait for it to be done before it tries to load current data
        const currentPromise = this.unfinishedUpdate[id];
        let resolveFn: () => void;

        // unfinishedUpdate has to await ALL the update calls (both PATCH and possible POST)
        // otherwise, wrong order of requests can happen, resulting with POST request on already existing path which cause error
        // => each update call has to create its own promise
        // so that all the calls happens sequentially
        const newPromise = new Promise<void>((resolve) => {
            resolveFn = resolve;
        });
        this.unfinishedUpdate[id] = newPromise;

        if (currentPromise) {
            await currentPromise;
        }

        try {
            // use PATCH (and POST if needed) instead of PUT
            // PUT replaces the whole object, but we don't want to GET the current data before every update
            // => using PATCH is safer
            let res = await fetch(`${P13n.baseUrl}/${id}`, {
                method: "PATCH",
                body: JSON.stringify({ value }),
                headers: {
                    "Content-Type": "application/json"
                }
            });

            if (res.status === 404) {
                res = await fetch(P13n.baseUrl, {
                    ...getDefaultPostParams(),
                    body: JSON.stringify({
                        value,
                        elementId: id
                    })
                });
            }

            if (res.status > 300) {
                this.handleError(`error in P13n status: ${res.status} - ${res.statusText}`);
                return false;
            }

            return true;
        } catch (e) {
            this.handleError(e);
            return false;
        } finally {
            resolveFn();

            if (this.unfinishedUpdate[id] === newPromise) {
                this.unfinishedUpdate[id] = null;
            } // else - another update call already stored its new promise
        }
    }

    async get<T>(id: string, name?: string, forceUpdate?: boolean): Promise<T> {
        let item = this.data[id];

        if (!item || forceUpdate) {
            if (!this.unfinishedGet[id] || forceUpdate) {
                this.unfinishedGet[id] = this._getOrEmpty(id);
            }
            item = (await this.unfinishedGet[id]).Value;
        }

        this.data[id] = item;

        return !name ? item : item[name] || undefined;
    }

    async delete(id: string): Promise<boolean> {
        try {
            if (this.unfinishedUpdate[id]) {
                // wait for all the currently running updates to end, before loading the new, correct data
                await this.unfinishedUpdate[id];
            }

            const response = await fetch(`${P13n.baseUrl}/${id}`, { method: "DELETE" });

            if (response.status > 300) {
                this.handleError();
                return false;
            }

            return true;
        } catch (e) {
            this.handleError(e);
            return false;
        }
    }

    async _getOrEmpty(id: string): Promise<IP13nResponse> {
        try {
            if (this.unfinishedUpdate[id]) {
                // wait for all the currently running updates to end, before loading the new, correct data
                await this.unfinishedUpdate[id];
            }

            const response = await fetch(`${P13n.baseUrl}/${id}`);

            if (response.status === 404) {
                return emptyResponse;
            } else if (response.status > 300) {
                this.handleError();
                return emptyResponse;
            }

            return await response.json();
        } catch (e) {
            this.handleError(e);
            return emptyResponse;
        }
    }
}

const personalization = new P13n();

export default personalization;
