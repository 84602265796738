import { AlertPosition } from "@components/alert/Alert";
import { WithAlert, withAlert } from "@components/alert/withAlert";
import { SendIcon } from "@components/icon";
import { ISelectionChangeArgs } from "@components/inputs/select/Select.types";
import { IToolbarItem } from "@components/toolbar";
import { ActionTypeCode } from "@odata/GeneratedEnums";
import { logAction } from "@odata/OData.utils";
import { saveFileFromUrl } from "@utils/FileStorage";
import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import IconSelect from "../../../components/inputs/select/IconSelect";
import { REST_API_URL } from "../../../constants";
import { QueryParam, Status, ToolbarItemType } from "../../../enums";
import { TableStorage } from "../../../model/TableStorage";
import { getQueryParameters } from "../../../routes/Routes.utils";
import memoizeOne from "../../../utils/memoizeOne";
import { TableButtonsAction } from "../../../views/table/TableToolbar.utils";
import Page, { IProps as IPageProps } from "../../Page";
import {
    getReportDisplayType,
    getReportDisplayTypeToolbarButtons,
    handleReportDisplayTypeChange,
    resetReportDisplayType,
    setReportDisplayType
} from "../balanceSheet/BalanceSheet";
import { IBalanceSheetCustomData } from "../balanceSheet/BalanceSheet.utils";
import { CommonReportProps, FiscalYearComparison, ReportDisplayType } from "../CommonDefs";
import { commonReportTranslations, getReportDefinition, PrintReportType } from "../Report.utils";
import { ReportSplitPage } from "../ReportSplitPage";
import { ReportStorage } from "../ReportStorage";
import { IReportFilterChangeEvent, IReportViewBaseProps } from "../ReportView";
import { getDefinition, IncomeStatementProps, IncomeStatementStructure, initialSortBy } from "./IncomeStatementDef";
import SendIncomeStatementDialog from "./sendIncomeStatementDialog";

export const INC_STMNT_SHORT_SHEET_OPEN_LINES: string[] = [];

export enum IncomeStatementDelta {
    Yes = "Yes",
    No = "No",
    InPercent = "InPercent"
}

export const getIncomeStatementExportUrl = (storage: TableStorage, isShortReport: boolean): string => {
    const company = storage.context.getCompany().Id;
    const printType = storage.data.entity[IncomeStatementProps.incomeStatementStructure] === IncomeStatementStructure.ByNatureOfExpense ? PrintReportType.IncomeStatementByNature : PrintReportType.IncomeStatementByFunction;
    const dateRange = storage.data.entity[CommonReportProps.dateRange];

    return `${REST_API_URL}/IncomeStatementPdfExport/${printType}/${dateRange}/${company}/${isShortReport}?CompanyId=${company}`;
};

interface IProps extends IPageProps, WithAlert {
}

class IncomeStatement extends Page<IProps> {
    static defaultProps = {
        getDef: getReportDefinition.bind(null, getDefinition)
    };

    storage: ReportStorage<IBalanceSheetCustomData>;

    onAfterLoad = async (storage: ReportStorage) => {
        this.storage = storage;

        setReportDisplayType(this.storage, getQueryParameters()?.[QueryParam.ReportDisplayType] as ReportDisplayType ?? getReportDisplayType(storage) ?? ReportDisplayType.Short);
    };

    handleReportDisplayTypeChange = (key: string) => {
        handleReportDisplayTypeChange(key as ReportDisplayType, this.storage, initialSortBy, INC_STMNT_SHORT_SHEET_OPEN_LINES);

        this.forceUpdate();
    };

    handleOpenSendDialog = (args: ISelectionChangeArgs) => {
        this.storage.setCustomData({ sendingMessageDialogOpen: true, messageReportType: args.value as string });
        this.forceUpdate();
    };

    handleSendMessageSuccess = () => {
        this.props.setAlert({
            status: Status.Success,
            title: this.storage.t("Common:Validation.SuccessTitle"),
            subTitle: this.storage.t("DataBox:SendDialog.MessageHasBeenSent")
        });
    };

    get sendMessageToolbarMenu(): IToolbarItem {
        return {
            id: "sendMessage",
            itemType: ToolbarItemType.Custom,
            render: () => {
                const { rowCount, loaded } = this.storage.tableAPI?.getState() ?? {};
                return <IconSelect
                    key={"sendMessage"}
                    hotspotId={"sendMessage"}
                    title={this.props.t("DataBox:SendDialog.SendIncomeStatement")}
                    onChange={this.handleOpenSendDialog}
                    headerText={this.props.t("DataBox:SendDialog.Send")}
                    icon={<SendIcon/>}
                    iconButtonProps={{ style: { order: 4, marginLeft: "2px" } }}
                    isDisabled={!rowCount || !loaded}
                    items={[{
                        id: "short",
                        iconName: "ExportReportShort",
                        label: this.props.t("Components:Table.PdfExportReportShort")
                    }, {
                        id: "full",
                        iconName: "ExportReportFull",
                        label: this.props.t("Components:Table.PdfExportReportFull")
                    }]}/>;
            }
        };
    }

    renderCustomToolbarButtons = () => {
        return [
            getReportDisplayTypeToolbarButtons(this.handleReportDisplayTypeChange, getReportDisplayType(this.storage)),
            this.sendMessageToolbarMenu
        ];
    };

    handleExpand = () => {
        resetReportDisplayType(this.storage);
    };

    handleFilterChange = (args: IReportFilterChangeEvent) => {
        if (args.filterChange.bindingContext.getPath() === CommonReportProps.comparison) {
            if (!args.settings[CommonReportProps.comparison] || args.settings[CommonReportProps.comparison] === FiscalYearComparison.None) {
                args.settings[CommonReportProps.showDelta] = IncomeStatementDelta.No;
            } else {
                args.settings[CommonReportProps.showDelta] = this.storage.data.entity[CommonReportProps.showDelta];
            }

            // call storage.handleFilterChange to properly store value in LocalSettings and storage
            this.storage.handleFilterChange({
                bindingContext: this.storage.data.bindingContext.navigate(CommonReportProps.showDelta),
                parsedValue: args.settings[CommonReportProps.showDelta],
                value: args.settings[CommonReportProps.showDelta]
            });
        }

        return args.settings;
    };

    handlePrintReportButtonClick = (buttonAction: TableButtonsAction) => {
        const isShortReport = buttonAction === TableButtonsAction.PdfExportReportShort;
        const url = getIncomeStatementExportUrl(this.storage, isShortReport);

        logAction({
            actionId: this.storage.id,
            actionType: ActionTypeCode.PDFExport,
            detail: JSON.stringify({
                CompanyId: this.storage.settings.CompanyId,
                IsShortReport: isShortReport,
                IncomeStatementStructure: this.storage.settings[IncomeStatementProps.incomeStatementStructure]
            })
        });

        saveFileFromUrl(url);
    };

    getReportViewProps = memoizeOne((): Partial<IReportViewBaseProps> => {
        return {
            customToolbarButtons: this.renderCustomToolbarButtons,
            onFilterChange: this.handleFilterChange,
            onExpandAll: this.handleExpand,
            onCollapseAll: this.handleExpand,
            onPrintReportButtonClick: this.handlePrintReportButtonClick,
            showPrintReportButtons: true,
            expandable: true
        };
    });

    render = () => {
        if (!this.isReady()) {
            return null;
        }

        return (
            <>
                <ReportSplitPage
                    {...this.getMandatoryProps()}
                    reportViewProps={this.getReportViewProps()}
                    onTableStorageLoad={this.onAfterLoad}
                />
                {this.storage?.getCustomData().sendingMessageDialogOpen &&
                    <SendIncomeStatementDialog
                        onAfterSend={this.handleSendMessageSuccess}
                        storage={this.storage}/>}
                {this.props.alert}
            </>
        );
    };
}

export default withRouter(withTranslation([...commonReportTranslations, "DataBox"])(withAlert({
    autoHide: true,
    position: AlertPosition.CenteredBottom,
    style: { width: "400px" }
})(IncomeStatement)));