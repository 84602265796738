import { FormStorage } from "../../views/formView/FormStorage";
import { CacheStrategy } from "../../enums";
import { fetchAndSetItemsByInfo, invalidateItems } from "@components/smart/smartSelect/SmartSelectAPI";

export const reloadAccounts = async (storage: FormStorage, fetchItems: boolean, path: string): Promise<void> => {
    const bindingContext = storage.data.bindingContext.navigate(path);
    const info = storage.getInfo(bindingContext);
    const cacheStrategy = info?.cacheStrategy ?? CacheStrategy.None;

    // clear cache and items (for document and all document items)  so that fetchSelectItems/SmartSelect can load new items
    invalidateItems([bindingContext], storage, cacheStrategy);

    let item;
    if (fetchItems) {
        const items = await fetchAndSetItemsByInfo(storage, info);
        const currentNumber = storage.getValue(bindingContext.navigateWithSiblingFallback("Number"));
        item = items.find(item => item.additionalData.Number === currentNumber);
        // fallback to the only item
        if (!item && items.length === 1) {
            item = items[0];
        }
    } else {
        info.fieldSettings.items = [];
    }

    if (item) {
        storage.clearAndSetValue(bindingContext, item.id);
        if (info?.fieldSettings?.localDependentFields?.length > 0) {
            storage.processDependentField(info.fieldSettings.localDependentFields, item.additionalData, bindingContext);
        }
    } else {
        storage.clearAndSetValue(bindingContext, null);
    }
};