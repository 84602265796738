import React from "react";
import { NextValue, PrevValue, StyledIconPressButton, StyledValuePicker, StyledWriteLine } from "./ValuePicker.styles";
import { WithTranslation, withTranslation } from "react-i18next";
import { IInputOnBlurEvent, IInputOnChangeEvent } from "../../input/Input";
import TestIds from "../../../../testIds";
import { deburr } from "lodash";
import { CaretIcon } from "../../../icon";
import { IconSize, MouseButton } from "../../../../enums";
import { KeyName } from "../../../../keyName";
import { mouseDownRepeat } from "@utils/general";
import { WriteLineComponentType } from "../../writeLine";

export interface ICaretIconPressButton {
    title: string;
    onClick?: () => void;
    onMouseDown?: (event: React.MouseEvent) => void;
    isLight?: boolean;
    isDisabled?: boolean;
    ignoreTheme?: boolean;
    size?: string;
    testid?: string;
    className?: string;
    style?: React.CSSProperties;
}

export function CaretIconPressButton(
    {
        title, onClick, onMouseDown, style, testid,
        isLight, isDisabled, ignoreTheme, size, className
    }: ICaretIconPressButton
) {
    return (
        <StyledIconPressButton title={title} data-testid={testid}
                               onClick={onClick}
                               // add tabIndex so that blur event.relatedTarget recognize this as focusable
                               tabIndex={-1}
                               onMouseDown={onMouseDown}
                               className={className}
                               isDisabled={isDisabled}
                               style={style}>
            <CaretIcon isLight={isLight}
                       ignoreTheme={ignoreTheme}
                       isLightHover
                       width={size ?? IconSize.M}
                       height={size ?? IconSize.M}/>
        </StyledIconPressButton>
    );
}

export interface IProps extends WithTranslation {
    mainValue: string;
    prevValue: string;
    nextValue: string;
    /** List of values for autocompletion */
    values?: string[];
    isReadOnly?: boolean;
    onChange?: (value: string) => void;
    onPrevValue: () => void;
    onNextValue: () => void;
    onEnterPress?: () => void;
    // allows mouse wheel to be used to change values
    allowMouseWheel?: boolean;
    isSmall?: boolean;
    maxLength?: number;
    // otherwise doesn't work for typescript, because of withTranslation HOC
    ref?: React.RefObject<React.ReactElement>;
}

interface IState {
    currentValue: string;
    matchingValue: string;
}

class ValuePicker extends React.PureComponent<IProps, IState> {
    _holdTimeout: number = null;
    _isFirstTimeout = true;
    _wheelRef = React.createRef<HTMLDivElement>();
    _inputRef = React.createRef<HTMLInputElement>();
    _lineRef = React.createRef<WriteLineComponentType>();

    state: IState = {
        currentValue: this.props.mainValue,
        matchingValue: ""
    };

    componentDidUpdate(prevProps: IProps, prevState: IState): void {
        if (prevProps.mainValue !== this.props.mainValue) {
            this.setState({
                currentValue: this.props.mainValue
            });
        }

        if (this.state.matchingValue) {
            this._inputRef.current.setSelectionRange(this.state.currentValue.length, this.state.matchingValue.length);
        }
    }

    // onWheel can't be handled by react, problem with passive events https://github.com/facebook/react/issues/6436
    // use addEventListener instead
    componentDidMount(): void {
        if (this._wheelRef.current && this.props.allowMouseWheel) {
            this._wheelRef.current.addEventListener("wheel", this.handleWheel, { passive: false });
        }
    }

    componentWillUnmount(): void {
        if (this._wheelRef.current && this.props.allowMouseWheel) {
            this._wheelRef.current.removeEventListener("wheel", this.handleWheel);
        }
    }

    handleWheel = (event: WheelEvent) => {
        const delta = event.deltaY;

        event.preventDefault(); // prevent scrolling

        if (delta < 0) {
            this.props.onPrevValue();
        } else {
            this.props.onNextValue();
        }
    };

    handlePrevMouseDown = (event: React.MouseEvent) => {
        if (event.button === MouseButton.MainButton) {
            event.preventDefault(); // prevents text selection
            mouseDownRepeat(this.props.onPrevValue);
        }
    };

    handleNextMouseDown = (event: React.MouseEvent) => {
        if (event.button === MouseButton.MainButton) {
            event.preventDefault(); // prevents text selection
            mouseDownRepeat(this.props.onNextValue);
        }
    };

    handlePrevKeyDown = (event: React.KeyboardEvent) => {
        // key down is automatically repetead when key is held down
        if (event.key === KeyName.Space) {
            this.props.onPrevValue();
        }
    };

    handleNextKeyDown = (event: React.KeyboardEvent) => {
        // key down is automatically repetead when key is held down
        if (event.key === KeyName.Space) {
            this.props.onNextValue();
        }
    };

    findMatchingValue = (value: string, values: string[]) => {
        return values.find(val => deburr(val.toLowerCase()).startsWith(deburr(value).toLowerCase()));
    };

    handleChange = (event: IInputOnChangeEvent<string>) => {
        let val = event.value;
        let matchingValue: string;

        if (val && (event.origEvent?.nativeEvent as any).inputType === "insertText" && this.props.values) {
            matchingValue = this.findMatchingValue(val, this.props.values);

            if (!matchingValue) {
                val = this.state.currentValue;
            }
        }

        // without this, input hold a wrong value for a moment and that causes errors
        (this._lineRef.current as React.Component).forceUpdate();

        this.setState({
            currentValue: val,
            matchingValue
        });
    };

    handleBlur = (event: IInputOnBlurEvent) => {
        this.handleValueSelection(event.origEvent.target.value);
    };

    handleEnterPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        this.handleValueSelection((event.target as HTMLInputElement).value);

        this.props.onEnterPress && this.props.onEnterPress();
    };

    handleValueSelection = (value: string) => {
        // reset to value from props, in case that the current value is flawed
        // if the value is correct, it will get propagated back from parent
        this.setState({
            currentValue: this.props.mainValue,
            matchingValue: ""
        });

        if (this.props.onChange) {
            this.props.onChange(value);
        }
    };

    handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        if (!this.props.isReadOnly) {
            this._inputRef.current.select();
        }
    };

    handleKeyDown = (event: React.KeyboardEvent) => {
        // key down is automatically repetead when key is held down
        if (event.key === KeyName.ArrowUp) {
            this.props.onPrevValue();
        } else if (event.key === KeyName.ArrowDown) {
            this.props.onNextValue();
        }
    };

    focus = () => {
        this._inputRef.current && this._inputRef.current.focus();
    };

    select = () => {
        this._inputRef.current && this._inputRef.current.select();
    };

    render = () => {
        return (
            <StyledValuePicker ref={this._wheelRef}
                               onKeyDown={this.handleKeyDown}
                               data-testid={TestIds.ValuePicker}>
                <CaretIconPressButton onMouseDown={this.handlePrevMouseDown}
                                      testid={TestIds.ValuePickerPrevValueButton}
                                      title={this.props.t("ValuePicker.Previous")}
                                      isLight ignoreTheme
                                      style={{
                                          transform: "rotate(180deg)"
                                      }}/>
                <PrevValue data-testid={TestIds.ValuePickerPrevValue}>{this.props.prevValue}</PrevValue>
                <StyledWriteLine value={this.state.matchingValue ? this.state.matchingValue : this.state.currentValue}
                                 maxLength={this.props.maxLength}
                                 ref={this._lineRef}
                                 isLight
                                 testid={TestIds.ValuePickerValue}
                                 passRef={this._inputRef}
                                 onChange={this.handleChange}
                                 onBlur={this.handleBlur}
                                 onFocus={this.handleFocus}
                                 onEnterPress={this.handleEnterPress}
                                 isReadOnly={this.props.isReadOnly}
                                 width={this.props.isSmall ? "32px" : "83px"}
                                 ignoreTheme
                />
                <NextValue data-testid={TestIds.ValuePickerNextValue}>{this.props.nextValue}</NextValue>
                <CaretIconPressButton onMouseDown={this.handleNextMouseDown}
                                      isLight ignoreTheme
                                      testid={TestIds.ValuePickerNextValueButton}
                                      title={this.props.t("ValuePicker.Next")}/>
            </StyledValuePicker>
        );
    };
}

const ValuePickerWithTranslation = withTranslation("Components", { withRef: true })(ValuePicker);
export { ValuePickerWithTranslation as ValuePicker };