import React from "react";
import { Dayjs } from "dayjs";
import { DatePickerView } from "../../../../enums";
import { Calendar, IRenderDayArgs } from "./Calendar";
import {
    dateIntervalToDayjsInterval,
    dayjsIntervalToDateInterval,
    getValidDayjsDate,
    IDayInterval,
    IDayjsInterval,
    isSameDay,
    isValidDateInterval
} from "../utils";
import { Day } from "./Day";
import RelativeDateValues from "./RelativeDateValues";
import { isInterval, PredefinedFilter } from "../../../conditionalFilterDialog/ConditionalFilterDialog.utils";
import { StyledDateRangePickerPopup } from "./DateRangePickerPopup.styles";
import { IDateRangeValuePopupSharedProps } from "./Calendar.utils";

interface IState {
    previewDay: Dayjs;
    view: DatePickerView;
    // not only mouse, but keyboard focus also
    mouseOverDay: Dayjs;
    value: IDayjsInterval | PredefinedFilter;
    firstTime: Dayjs;
    secondTime: Dayjs;
}

export interface IDateRangePickerPopupProps extends IDateRangeValuePopupSharedProps {
    showRelativeValues?: boolean;
    showTime?: boolean;
}

export class DateRangePickerPopup extends React.PureComponent<IDateRangePickerPopupProps, IState> {
    constructor(props: IDateRangePickerPopupProps) {
        super(props);

        const fromDate = getValidDayjsDate((this.props.value && isInterval(this.props.value) ? this.props.value.from : null));
        const toDate = getValidDayjsDate((this.props.value && isInterval(this.props.value) ? this.props.value.to : null));

        this.state = {
            previewDay: fromDate,
            view: DatePickerView.Days,
            mouseOverDay: null,
            value: this.props.value ?
                !isInterval(this.props.value) ?
                    this.props.value
                    : dateIntervalToDayjsInterval(this.props.value)
                : { from: null, to: null },
            firstTime: fromDate,
            secondTime: toDate
        };
    }

    componentDidUpdate(prevProps: Readonly<IDateRangePickerPopupProps>, prevState: Readonly<IState>) {
        const prevValue = prevProps.value as IDayInterval;

        if (isValidDateInterval(this.props.value) &&
            (this.props.value?.from !== prevValue?.from || this.props.value?.to !== prevValue?.to)) {
            this.setState({ value: dateIntervalToDayjsInterval(this.props.value) });
        }
    }

    // date internal is handled here internally in state
    // and applied via this.renderDay method
    // this is only used for the time if showTime is true
    getFirstValue = (): Dayjs => {
        return this.state.firstTime;
    };

    getSecondValue = (): Dayjs => {
        return this.state.secondTime;
    };

    isRelativeValueSelected = (): boolean => {
        return this.state.value && !isInterval(this.state.value);
    };

    getRelativeValue = (): PredefinedFilter => {
        if (this.isRelativeValueSelected()) {
            return this.state.value as PredefinedFilter;
        }

        return null;
    };

    getFirstCalendarPreviewDay = (): Dayjs => {
        return this.state.previewDay;
    };

    getSecondCalendarPreviewDay = (): Dayjs => {
        return this.state.previewDay.add(1, "month");
    };

    handleCalendarChange = (day: Dayjs): void => {
        if (!day) {
            this.setState({
                value: null
            });

            return;
        }

        const value = this.state.value as IDayjsInterval;

        const shouldSelectFromDay = !value?.from
            || (value.from && value.to);

        if (shouldSelectFromDay) {
            this.setState({
                value: {
                    from: day,
                    to: null
                }
            });
        } else {
            // User selects second date, swaps them to correct order
            const swapInterval = value.from.isAfter(day);
            this.setState({
                value: {
                    from: swapInterval ? day : value.from,
                    to: (swapInterval ? value.from : day)
                }
            });
        }
    };

    handleFirstCalendarChange = (day: Dayjs, isTimeChange: boolean): void => {
        if (isTimeChange) {
            this.setState({
                firstTime: day
            });
        } else {
            this.handleCalendarChange(day);
        }
    };

    handleSecondCalendarChange = (day: Dayjs, isTimeChange: boolean): void => {
        if (isTimeChange) {
            this.setState({
                secondTime: day
            });
        } else {
            this.handleCalendarChange(day);
        }
    };

    isDaySelected = (day: Dayjs): boolean => {
        if (!this.state.value || this.isRelativeValueSelected()) {
            return false;
        }

        const value = this.state.value as IDayjsInterval;

        return isSameDay(day, value.from) || isSameDay(day, value.to);
    };

    isInActiveRange = (day: Dayjs): boolean => {
        const value = this.state.value as IDayjsInterval;

        if (!this.state.value || this.isRelativeValueSelected() || !value.from || value.to || !this.state.mouseOverDay) {
            return false;
        }

        const { from } = value;
        const to = this.state.mouseOverDay;
        const swapInterval = from?.isAfter(to);

        return this.isDaySelected(day) ||
            isSameDay(day, this.state.mouseOverDay) ||
            day.isBetween(swapInterval ? to : from, swapInterval ? from : to);
    };

    isInSelectedRange = (day: Dayjs): boolean => {
        const value = this.state.value as IDayjsInterval;

        if (!this.state.value || this.isRelativeValueSelected() || !value.from || !value.to) {
            return false;
        }

        const { from, to } = value;

        return this.isDaySelected(day) || day.isBetween(from, to);
    };

    shouldPreventHover = (day: Dayjs): boolean => {
        return !!this.state.mouseOverDay;
    };

    handleMouseEnterDay = (day: Dayjs): void => {
        const value = this.state.value as IDayjsInterval;

        if (value?.from && !value.to) {
            this.setState({
                mouseOverDay: day
            });
        }
    };

    handleMouseLeaveDay = (day: Dayjs): void => {
        this.setState({
            mouseOverDay: null
        });
    };

    renderDay = (args: IRenderDayArgs): React.ReactElement => {
        const value = this.state.value as IDayjsInterval;

        const { from, to } = value ?? {};
        const swapInterval = !to && this.state.mouseOverDay && from?.isAfter(this.state.mouseOverDay);
        const isFirst = isSameDay(args.day, from);
        const isLast = isSameDay(args.day, to) || isSameDay(args.day, this.state.mouseOverDay);

        return <Day {...args}
                    onMouseEnter={this.handleMouseEnterDay}
                    onMouseLeave={this.handleMouseLeaveDay}
                    preventHover={this.shouldPreventHover(args.day)}
                    isFirst={swapInterval ? isLast : isFirst}
                    isLast={swapInterval ? isFirst : isLast}
                    isSelected={this.isDaySelected(args.day)}
                    isInActiveRange={this.isInActiveRange(args.day)}
                    isInSelectedRange={this.isInSelectedRange(args.day)}
                    onFocus={this.handleMouseEnterDay}
                    onBlur={this.handleMouseLeaveDay}/>;
    };

    handleFirstPreviewChange = (day: Dayjs): void => {
        this.setState({
            previewDay: day
        });
    };

    handleSecondPreviewChange = (day: Dayjs): void => {
        this.setState({
            previewDay: day.subtract(1, "month")
        });
    };

    handleOk = (): void => {
        if (!this.state.value) {
            this.props.onChange(null);

            return;
        }

        // todo how to propagate selected time when relative value is used?
        // should the time even be propagated or is relativeValue supposed to be without time?
        if (this.isRelativeValueSelected()) {
            this.props.onChange(this.state.value as PredefinedFilter);

            return;
        }

        const value = this.state.value as IDayjsInterval;
        let { from, to } = value;

        if (from.isAfter(to)) {
            from = to;
            to = value.from;
        }


        if (this.state.firstTime) {
            from = from.hour(this.state.firstTime.hour());
            from = from.minute(this.state.firstTime.minute());
        } else {
            from = from.endOf("day");
        }

        if (this.state.secondTime) {
            to = to.hour(this.state.secondTime.hour());
            to = to.minute(this.state.secondTime.minute());
        } else {
            to = to.endOf("day");
        }

        this.props.onChange(dayjsIntervalToDateInterval({
            from,
            to
        }));
    };

    handleCancel = (): void => {
        this.props.onChange(this.props.value);
    };

    handleRelativeValueChange = (value: PredefinedFilter): void => {
        this.setState({
            value
        });
    };

    renderRelativeValues = (): React.ReactElement => {
        if (!this.props.showRelativeValues) {
            return null;
        }

        return (
            <RelativeDateValues value={this.getRelativeValue()}
                                onChange={this.handleRelativeValueChange}
                                embedded/>
        );
    };


    render = (): React.ReactElement => {
        const value = this.state.value as IDayjsInterval;

        const disabledOk = this.state.value && !this.isRelativeValueSelected() && (!value.from || !value.to);

        return (
            <StyledDateRangePickerPopup>
                {this.renderRelativeValues()}
                <Calendar previewDay={this.getFirstCalendarPreviewDay()}
                          value={this.getFirstValue()}
                          view={this.state.view}
                          mainView={DatePickerView.Days}
                          onChange={this.handleFirstCalendarChange}
                          renderDay={this.renderDay}
                          disableAutoFocus={this.props.disableAutoFocus}
                          onPreviewChange={this.handleFirstPreviewChange}
                          showTime={this.props.showTime}
                          minDate={this.props.minDate}
                          maxDate={this.props.maxDate}
                          isDateDisabled={this.props.isDateDisabled}
                          disableViewChange
                          embedded
                          hideNextArrow/>
                <Calendar previewDay={this.getSecondCalendarPreviewDay()}
                          value={this.getSecondValue()}
                          view={this.state.view}
                          mainView={DatePickerView.Days}
                          onChange={this.handleSecondCalendarChange}
                          showSpecialValue={this.props.showSpecialValue}
                          renderDay={this.renderDay}
                          showConfirmButtons
                          disabledOk={disabledOk}
                          onOk={this.handleOk}
                          onCancel={this.handleCancel}
                          disableAutoFocus
                          onPreviewChange={this.handleSecondPreviewChange}
                          showTime={this.props.showTime}
                          minDate={this.props.minDate}
                          maxDate={this.props.maxDate}
                          disableViewChange
                          embedded
                          hideBackArrow/>
            </StyledDateRangePickerPopup>
        );
    };
}