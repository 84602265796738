import React, { useCallback, useMemo } from "react";
import Dialog from "../../../components/dialog";
import { useTranslation } from "react-i18next";
import ConfirmationButtons from "../../../views/table/ConfirmationButtons";
import SmartFastEntryList from "../../../components/smart/smartFastEntryList";
import { FormStorage } from "../../../views/formView/FormStorage";
import BindingContext, { IEntity } from "../../../odata/BindingContext";
import { IFieldDef } from "@components/smart/FieldInfo";
import { PrEmployeeCarEntity } from "@odata/GeneratedEntityTypes";
import { ISmartFieldChange } from "@components/smart/smartField/SmartField";
import { IEmployeeFormCustomData, IPrEmployeeEntityExtended } from "./Employee.utils";

interface IProps {
    storage: FormStorage<IPrEmployeeEntityExtended, IEmployeeFormCustomData>;
    bindingContext: BindingContext;
    onLineItemsTemporalChange: (args: ISmartFieldChange) => void;
}

const CarsDialog: React.FunctionComponent<IProps> = React.memo((props: React.PropsWithChildren<IProps>) => {
    const { t } = useTranslation(["Employee", "Common"]);
    const columns = useMemo((): IFieldDef[] => {
        return [
            { id: PrEmployeeCarEntity.Name },
            { id: PrEmployeeCarEntity.PurchasePrice },
            { id: PrEmployeeCarEntity.LowEmission },
            { id: PrEmployeeCarEntity.DateValidFrom },
            { id: PrEmployeeCarEntity.DateValidTo }
        ];
    }, []);
    const filterItems = useCallback((items: IEntity[]): IEntity[] => {
        const carsBc = props.bindingContext.removeKey();

        return items.filter(item => props.bindingContext.isSame(carsBc.addKey(item)));
    }, [props.bindingContext.toString()]);
    const close = useCallback(() => {
        props.storage.setCustomData({
            carsDialogBc: null
        });

        props.storage.refresh();
    }, [props.storage]);
    const getFieldsBcs = useCallback(() => {
        return columns.map(col => props.bindingContext.navigate(col.id));
    }, [columns, props.bindingContext.toString()]);
    const handleConfirm = useCallback(() => {
        props.storage.confirmFields(getFieldsBcs());
        close();
    }, [props.storage]);
    const handleCancel = useCallback(() => {
        props.storage.cancelFields(getFieldsBcs());
        close();
    }, [props.storage]);

    return (
        <Dialog title={t("Employee:Form.PlanCarsTitle")}
                onConfirm={null}
                onClose={null}
                footer={<ConfirmationButtons
                    confirmText={t("Common:General.Save")}
                    onConfirm={handleConfirm}
                    onCancel={handleCancel}
                    useWrapper={false}
                />}
        >
            <SmartFastEntryList
                bindingContext={props.bindingContext.removeKey()}
                columns={columns}
                storage={props.storage.getThis()}
                onTemporalChange={props.onLineItemsTemporalChange}
                isConfirmable
                useLabelWrapping={true}
                canAdd={false}
                isCollapsible={false}
                onBlur={null}
                canReorder={false}
                isItemRemovable={false}
                isItemCloneable={false}
                isItemSelectable={false}
                groupId={"carsDialog"}
                filterItems={filterItems}
                order={null}
            />
        </Dialog>
    );
});

export default CarsDialog;