import { isAbortException, TFetchFn } from "@utils/oneFetch";
import { createUrl } from "@utils/url";
import i18next from "i18next";

import { VALIDATE_DELETE_DEPENDENT_URL, VALIDATE_DELETE_URL } from "../../../constants";
import { TRecordAny } from "../../../global.types";
import BindingContext from "../../../odata/BindingContext";
import customFetch, { getDefaultPostParams } from "../../../utils/customFetch";
import { TId } from "../../table";
import { isSystemEntityId } from "../GeneralFieldDefinition";
import { TDeleteCheckServiceResponse, TDeleteDependentCheckServiceResponse } from "./SmartFormDeleteButton";

interface IMap {
    system: TId[],
    user: TId[]
}

export type TDeleteCheckError = TDeleteCheckServiceResponse | TDeleteDependentCheckServiceResponse;

function isDependentEntity(bc: BindingContext): boolean {
    return !!bc.getParent();
}

async function getValidateDeleteBackendErrors(args: IValidateDeleteArgs): Promise<TDeleteCheckError[]> {
    const { bindingContext, ids, fetchFn, queryParams } = args;
    const fetchFunction = fetchFn ?? customFetch;
    if (!isDependentEntity(bindingContext)) {
        let url = VALIDATE_DELETE_URL;

        if (queryParams) {
            url = createUrl(url, queryParams);
        }

        try {
            const res = await fetchFunction(url, {
                ...getDefaultPostParams(),
                body: JSON.stringify({
                    EntityTypeCode: bindingContext.getEntityType().getName(),
                    EntitiesIds: ids
                })
            });

            const data = await res.json();
            return Array.isArray(data) ? data as TDeleteCheckServiceResponse[] : [];
        } catch (e) {
            if (isAbortException(e)) {
                // if request was aborted, rethrow the exception to be handled in the caller
                throw (e);
            }

            // if request fail, treat entities as deletable
            // worst case, delete request with fail with some error explain message
            return [];
        }
    } else {
        // so far, probably only for ChartOfAccounts/Accounts
        try {
            const res = await fetchFunction(VALIDATE_DELETE_DEPENDENT_URL, {
                ...getDefaultPostParams(),
                body: JSON.stringify({
                    MainEntityTypeCode: bindingContext.getRootParent().getEntityType().getName(),
                    MainEntityId: bindingContext.getParent().getKey(),
                    DependentEntityTypeCode: bindingContext.getEntityType().getName(),
                    DependentEntitiesIds: ids
                })
            });

            const data = await res.json();
            return Array.isArray(data) ? data as TDeleteDependentCheckServiceResponse[] : [];
        } catch (e) {
            if (isAbortException(e)) {
                // if request was aborted, rethrow the exception to be handled in the caller
                throw (e);
            }

            // if request fail, treat entities as deletable
            // worst case, delete request with fail with some error explain message
            return [];
        }
    }
}

export interface IValidateDeleteArgs {
    bindingContext: BindingContext;
    ids: TId[];
    fetchFn?: TFetchFn;
    queryParams?: TRecordAny;
}

/** Returns if the given entities can be deleted or reasons why they can't be. */
export const validateDelete = async (args: IValidateDeleteArgs): Promise<TDeleteCheckError[]> => {
    const { bindingContext, ids, fetchFn, queryParams } = args;
    const errors: TDeleteCheckError[] = [];
    const idMap: IMap = {
        system: [],
        user: []
    };
    ids.forEach(id => {
        idMap[isSystemEntityId(id) ? "system" : "user"].push(id);
    });

    if (idMap.user.length) {
        const beErrors = await getValidateDeleteBackendErrors({
            bindingContext,
            ids: idMap.user,
            fetchFn,
            queryParams
        });
        errors.push(...beErrors);
    }

    if (idMap.system.length) {
        const IdProp = isDependentEntity(bindingContext) ? "DependentEntityId" : "EntityId";
        const systemEntityError = i18next.t("Common:Errors.SystemEntity");
        const deleteCheckResponse: TDeleteCheckError[] = idMap.system.map(id => ({
            [IdProp]: parseInt(id.toString()),
            ErrorCodes: [systemEntityError]
        } as unknown as TDeleteCheckError));
        errors.push(...deleteCheckResponse);
    }

    return errors;
};