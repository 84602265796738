import styled, { css } from "styled-components/macro";

import { Status } from "../../../enums";
import { getSemanticTextColor, T_PLAIN_big, T_PLAIN_big_hig, T_PLAIN_small, T_TITLE_1 } from "../../../global.style";
import { LinkStyled, StyledDashboardTileBackground } from "../../dashboard/Dashboard.styles";
import DashboardTileBackground from "../../dashboard/DashboardTileBackground";
import { ColoredIcon } from "../../icon";
import { Theme } from "../../../theme";

export const StyledInfoTile = styled(DashboardTileBackground)`
    justify-content: flex-start;
    gap: 14px;
`;

export const getLinkStylesWithHover = (theme: Theme, offset?: { top: number; bottom: number; left: number; right: number; }) => css`
    & > ${LinkStyled} {
        position: relative;
    }

    & > ${LinkStyled}::before {
        position: absolute;
        top: ${offset?.top ?? -8}px;
        bottom: ${offset?.bottom ?? -8}px;
        left: ${offset?.left ?? -10}px;
        right: ${offset?.right ?? -10}px;
        pointer-events: none;
        border-radius: 22px;
        border: solid 2px ${theme.C_ACT_hover_without_opacity};
        opacity: 0.5;
    }

    & > ${LinkStyled}:hover, & > ${LinkStyled}:active {
        &::before {
            content: "";
            display: block;
        }
    }
`;

export const StyledInfoTileCell = styled.div<{ $span: number; }>`
    position: relative;
    grid-column: span ${props => props.$span};

    ${props => getLinkStylesWithHover(props.theme)};
`;

export const InfoTileBody = styled.div<{ $columns: number; }>`
    display: grid;
    column-gap: 74px;
    grid-template-columns: repeat(${props => props.$columns}, 1fr);

    ${StyledInfoTileCell} + ${StyledInfoTileCell} {

        &::after {
            content: "";
            display: block;
            position: absolute;
            top: 3px;
            left: -38px;
            bottom: 3px;
            border-left: 2px solid ${props => props.theme.C_TEXT_primary};
            opacity: .2;
        }
    }
`;

interface ISeverity {
    $severity: Status;
}

export const ValueWrapper = styled.div`
    display: flex;
    align-items: end;
    justify-content: flex-start;
`;

export const Value = styled.div<ISeverity>`
    min-height: 36px;
    color: ${props => getSemanticTextColor(props.theme, props.$severity)};
    ${T_TITLE_1};
`;

export const Unit = styled.span`
    color: ${props => props.theme.C_TEXT_primary};
    ${T_PLAIN_big};
    opacity: 0.5;
`;

export const Fraction = styled.span`
    ${T_PLAIN_big_hig};
`;

export const Label = styled.span`
    display: block;
    ${T_PLAIN_small};
    opacity: 0.5;

    &::first-letter {
        text-transform: lowercase;
    }
`;

export const ColoredIconStyled = styled(ColoredIcon)<ISeverity>`
    color: ${props => getSemanticTextColor(props.theme, props.$severity)};
    align-self: center;
    margin-left: auto;
`;

export const GroupedInfoTileBackground = styled(DashboardTileBackground)`
    position: relative;
`;

export const GroupInfoTileHeader = styled.div`
    position: relative;
    z-index: 1;
    margin-bottom: 14px;
`;

export const GroupedInfoTileContentWrapper = styled.div<{
    cols: number;
    rows: number;
}>`
    display: grid;
    grid-template-columns: repeat(${props => props.cols}, 1fr);
    grid-template-rows: repeat(${props => props.rows}, 1fr);
    grid-column-gap: 0;
    grid-row-gap: 0;

    ${props => getLinkStylesWithHover(props.theme, { top: 33, bottom: -1, right: 14, left: 2 })};

    ${StyledDashboardTileBackground} {
        justify-content: flex-start;
        padding: 10px 24px 9px 12px;
    }
`;