import React from "react";
import { Manager, Modifier, Popper, Reference } from "react-popper";
import ReactDOM from "react-dom";
import * as PopperJS from "@popperjs/core";
import { StyledPopperWrapper } from "./PopperWrapper.styles";

export interface IPopperOptions {
    placement?: PopperJS.Placement;
    modifiers?: ReadonlyArray<Modifier<any>>;
}

// either use reference (element) for positioning,
// or virtualElementReference e.g. if you want to position the popper relative to mouse
interface IReferenceProps {
    reference: (ref: React.Ref<any>) => React.ReactNode;
    virtualElementReference?: never;
}

interface IVirtualElementReferenceProps {
    reference?: never;
    virtualElementReference: HTMLElement | PopperJS.VirtualElement;
}

interface IProps extends IPopperOptions {
    withoutPortal?: boolean;
    isHidden?: boolean;
}

type TProps = IProps & (IReferenceProps | IVirtualElementReferenceProps);

export default class PopperWrapper extends React.PureComponent<TProps> {
    static defaultProps = {
        placement: "auto"
    };

    render() {
        return (
            <Manager>
                {!this.props.virtualElementReference &&
                    <Reference>
                        {({ ref }) => {
                            return this.props.reference(ref);
                        }}
                    </Reference>
                }
                {!this.props.isHidden &&
                    <Popper placement={this.props.placement}
                            modifiers={this.props.modifiers}
                            referenceElement={this.props.virtualElementReference}
                    >
                        {(popperProps) => {
                            if (!this.props.children) {
                                return null;
                            }

                            let Object = (
                                <StyledPopperWrapper ref={popperProps.ref}
                                    // Note: we can't extend styles here like { ...popperProps.style, zIndex: 10 }
                                    //  -> it throws exception on production
                                                     style={popperProps.style}
                                                     data-popper-placement={popperProps.placement}>
                                    {this.props.children}
                                </StyledPopperWrapper>
                            );

                            if (!this.props.withoutPortal) {
                                Object = ReactDOM.createPortal(
                                    Object,
                                    document.body
                                );
                            }

                            return Object;
                        }}
                    </Popper>
                }
            </Manager>
        );
    }
}