import React from "react";
import { cloneDeep } from "lodash";
import { AppContext } from "../../../contexts/appContext/AppContext.types";
import { FormViewForExtend, IFormViewProps } from "../../../views/formView/FormView";
import { getBoundValue, setBoundValue } from "@odata/Data.utils";
import { CompanyEntity, IMinorAssetEntity, IMinorAssetItemEntity, MinorAssetEntity } from "@odata/GeneratedEntityTypes";
import { FormMode } from "../../../enums";
import { setMatchingNumberRange } from "../../numberRange/NumberRange.utils";
import { Button, ButtonGroup } from "../../../components/button";
import { ActionType, addNewLineItem, ISmartFastEntriesActionEvent } from "@components/smart/smartFastEntryList";
import { isFullForm } from "../Asset.utils";
import { withPermissionContext } from "../../../contexts/permissionContext/withPermissionContext";
import {
    correctMinorAssetItemEntity,
    correctMinorAssetItems,
    handleMinorAssetLineItemsChange
} from "./MinorAsset.utils";
import { ISmartFieldChange } from "@components/smart/smartField/SmartField";
import { uuidv4 } from "@utils/general";
import { MinorAssetAction, setItemIsRemoved } from "./MinorAssetDef";
import BusyIndicator from "../../../components/busyIndicator";
import BindingContext, { createPath, IEntity } from "../../../odata/BindingContext";
import { getCompanyCurrency } from "@utils/CompanyUtils";

interface IProps extends IFormViewProps<IMinorAssetEntity> {
    isPairing?: boolean;
}

class MinorAssetFormView extends FormViewForExtend<IMinorAssetEntity, IProps> {
    static contextType = AppContext;
    //sadly, breaks typescript type checking
    //context: React.ContextType<typeof AppContext>;
    static defaultProps = {
        title: "MinorAsset:FormTitle"
    };

    componentDidMount() {
        super.componentDidMount();
        this.registerHeader();
    }

    componentDidUpdate() {
        this.registerHeader();
    }

    registerHeader = (): void => {
        if (this.props.storage.data.bindingContext) {
            const numberOursBc = this.props.storage.data.bindingContext.navigate(MinorAssetEntity.NumberOurs);
            this.props.storage.addCustomRef(this._refHeader.current, numberOursBc);
        }
    };

    onAfterLoad = async () => {
        const { storage } = this.props;
        if (storage.formMode !== FormMode.AuditTrail) {

            if (!storage.data.entity.Items?.filter(item => !!item.Quantity).length) {
                addNewLineItem(storage, MinorAssetEntity.Items);
            }
            if (!storage.data.entity.NumberOurs) {
                await setMatchingNumberRange(storage);
            }

            // reloads movements table when switching between entities
            this.props.storage.store({
                uuid: uuidv4()
            });
        }
        return super.onAfterLoad();
    };

    onBeforeSave = (): IMinorAssetEntity => {
        const { storage } = this.props;
        // when creating new minor asset on simplified form at document form, we don't remove the only line item
        if (isFullForm({ storage })) {
            storage.clearEmptyLineItems(MinorAssetEntity.Items);
        }

        const { bindingContext, entity } = storage.data;
        const data: IMinorAssetEntity = cloneDeep(entity);

        data.Items = correctMinorAssetItems(data.Items, storage);

        if (bindingContext.isNew()) {
            setBoundValue({
                bindingContext: bindingContext.navigate(createPath(MinorAssetEntity.Company, CompanyEntity.Id)),
                data,
                newValue: storage.context.getCompany().Id,
                dataBindingContext: bindingContext,
                preventCloning: true
            });
            data.Currency = { Code: storage.context.getDefaultCurrency() };
        }

        return data;
    };

    disposeLineItem(itemBc: BindingContext, data4Save: IEntity) {
        const { storage } = this.props;
        setItemIsRemoved(storage, itemBc);
        storage.setValue(itemBc.navigate("Quantity"), 0);
        storage.setValue(itemBc.navigate("Amount"), 0);

        const newValue = storage.getValue(itemBc);
        const origItem = getBoundValue({
            bindingContext: itemBc,
            data: data4Save,
            dataBindingContext: storage.data.bindingContext
        });

        correctMinorAssetItemEntity(storage, newValue, origItem);
        setBoundValue({
            bindingContext: itemBc,
            dataBindingContext: storage.data.bindingContext,
            data: data4Save,
            preventCloning: true,
            newValue
        });
    }

    async handleDisposeItems(itemsBc: BindingContext, items: IMinorAssetItemEntity[]): Promise<void> {
        const { storage } = this.props;
        storage.setBusy();

        // save disposed items changes, show new
        const data = cloneDeep(storage.data.origEntity);

        // dispose all added line items
        items.forEach(item => {
            this.disposeLineItem(itemsBc.addKey(item), data);
        });

        const result = await this.props.storage.save({ data, skipLoad: true });

        if (result) {
            await storage.init({
                preserveInfos: true,
                preserveData: false,
                definition: storage.data.definition,
                bindingContext: storage.data.bindingContext
            });
        }
        // todo: should we show somehow result?
        storage.setBusy(false);
    }

    handleLineItemsChange = (args: ISmartFieldChange) => {
        // reads old data from storage, has to be called before actual change
        handleMinorAssetLineItemsChange(this.props.storage, args);
    };

    handleLineItemsAction = (args: ISmartFastEntriesActionEvent<IMinorAssetItemEntity>) => {
        const { storage } = this.props;
        const { bindingContext, items, affectedItems, actionType, customActionType } = args;
        if (actionType === ActionType.Custom && customActionType === MinorAssetAction.Dispose) {
            this.handleDisposeItems(bindingContext, items);
        } else {
            const hasSavedItem = affectedItems.find(item => !bindingContext.addKey(item).isNew());
            if (hasSavedItem && actionType === ActionType.Remove) {
                // according to DEV-23317 it is not possible to remove saved items, they needs to be explicitly disposed
                return;
            } else {
                const [item] = affectedItems;
                const isNew = bindingContext.addKey(item).isNew();
                if (isNew && actionType === ActionType.Add) {
                    item.CurrencyCode = getCompanyCurrency(storage.context);
                }
                if (actionType === ActionType.Clone) {
                    delete item.Items;
                }
                storage.handleLineItemsAction(args);
            }
            storage.refresh();
        }
    };

    renderButtons = () => {
        if (this.props.isPairing) {
            return (
                <ButtonGroup wrap={"wrap"}>
                    <Button
                        onClick={this.handleSaveClick}>{this.props.storage.t("Common:General.Save")}</Button>
                </ButtonGroup>
            );
        }

        return super.renderButtons();
    };


    render() {
        if (!this.isReady()) {
            return <BusyIndicator isDelayed/>;
        }

        return (
            <>
                {this.renderForm()}
            </>
        );
    }
}

export default withPermissionContext(MinorAssetFormView);