/**
 * Returns wrapped fetch function that only allows one fetch to be called at a time.
 * If another fetch is called during the previous is still pending, the previous request is canceled and 'AbortError' exception is thrown for it.
 * Use one fetch with try catch block and handle 'AbortError'.
 * @returns {function(...[*]=)}
 */
import { FETCH_ABORT_ERROR } from "../constants";
import customFetch from "./customFetch";

export type TFetchFn = (url: string, options?: RequestInit) => Promise<Response>;

export interface IOneFetch {
    fetch: TFetchFn;
    abort: () => void;
    isFetching: () => boolean;
}

export const ONE_FETCH_ABORT_MESSAGE = "oneFetch: fetch was aborted because another fetch was called. If you see this error, make sure you are not duplicating requests and alternatively try / catch FETCH_ABORT_ERROR.";

export const isAbortException = (e: Error): boolean => {
    return e.name === FETCH_ABORT_ERROR;
};

/** The reason parameter of the abort call is actually passed as the exception,
 * so we don't want to use string but custom DOMException with the same name as the default AbortError exception.*/
const getCustomAbortReason = () => {
    return new DOMException(ONE_FETCH_ABORT_MESSAGE, FETCH_ABORT_ERROR);
};

const getOneFetch = (): IOneFetch => {
    let abortController: AbortController;

    return {
        fetch: async (url: string, options?: RequestInit): Promise<Response> => {
            if (abortController && !abortController.signal.aborted) {
                abortController.abort(getCustomAbortReason());
            }

            abortController = new AbortController();

            const res = await customFetch(url, {
                ...options,
                signal: abortController.signal
            });

            abortController = null;

            return res;
        },
        abort() {
            abortController?.abort(getCustomAbortReason());
        },
        isFetching() {
            return !!abortController;
        }
    };
};

export { getOneFetch };