import styled, { css } from "styled-components/macro";

import { FIELD_VER_MARGIN } from "../../components/inputs/field/Field.styles";
import { StyledSwitch } from "../../components/inputs/switch/Switch.styles";
import { ContentColumn } from "../../components/objectList/ListItem.styles";
import { T_PLAIN_big, T_TITLE_3_small } from "../../global.style";

export const StyledDialogListItem = styled.div<{
    disableOverlay?: boolean;
    isInDialog?: boolean;
}>`
    display: flex;
    opacity: ${props => props.disableOverlay ? props.theme.disabled_opacity : 1};
    margin-top: ${FIELD_VER_MARGIN};
    justify-content: flex-start;

    & > ${ContentColumn} {
        &:first-of-type {
            margin-left: 12px; // align with input label
        }

        &:last-of-type {
            margin-right: 0;
        }

        width: fit-content;
        margin-bottom: 0;
    }

    ${props => props.isInDialog && css`
        flex-direction: column;
        gap: 10px;

        & > ${ContentColumn} {
            margin-left: 12px;
        }
    `};
`;

export const StyledBlockReadingMessages = styled.div<{
    isDialog: boolean;
}>`
    ${props => !props.isDialog && css`
        margin-top: 10px
    `};
    display: flex;
    align-items: center;
    padding: 5px 0; // circle inside switch is slightly bigger than it's wrapper

    & > ${StyledSwitch} {
        margin-left: 12px;
    }
`;

export const StyledPermissionRow = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    column-gap: 5px;
    ${T_PLAIN_big}
`;

export const StyledUserNameHeading = styled.div`
    margin-top: 20px;
    margin-bottom: 0.5em !important;
    ${T_TITLE_3_small};
    font-weight: bold;
`;