import { ITableTileData } from "@components/dashboard";
import { getTableDrillDownLink, IGetDrillDownParams } from "@components/drillDown/DrillDown.utils";
import { StatusWarnIcon, StatusYesIcon } from "@components/icon";
import { ISelectItem } from "@components/inputs/select/Select.types";
import { IGetValueArgs } from "@components/smart/FieldInfo";
import { TFilterDef } from "@components/smart/smartFilterBar/SmartFilterBar.types";
import { ISmartODataTableAPI } from "@components/smart/smartTable/SmartODataTableBase";
import {
    fetchValueHelperData,
    IFetchValueHelperItemsOptions
} from "@components/smart/smartValueHelper/ValueHelper.utils";
import { ICellValueObject, IColumn, IRow } from "@components/table";
import {
    getDocumentTypeCodeFromEntityType,
    getEntitySetByDocumentType,
    getRouteByEntityType
} from "@odata/EntityTypes";
import {
    DocumentDraftEntity,
    DocumentEntity,
    EntitySetName,
    EntityTypeName,
    IDocumentEntity,
    IFiscalYearEntity,
    IPaymentDocumentEntity,
    PaymentDocumentDraftEntity,
    PaymentDocumentEntity
} from "@odata/GeneratedEntityTypes";
import { IBatchResult, OData } from "@odata/OData";
import { transformToODataString } from "@odata/OData.utils";
import { ODataQueryResult } from "@odata/ODataParser";
import { getDocumentNameTableCell } from "@pages/documents/Document.utils";
import { isDraftViewCallback } from "@pages/documents/DocumentCommonDefs";
import { getEnumBuildFilterMethod } from "@pages/documents/DocumentDef";
import { CellContentStyled } from "@pages/fiscalYearClose/FiscalYearClose.styles";
import { isCashBasisAccountingCompany, isVatRegisteredCompany } from "@utils/CompanyUtils";
import i18next from "i18next";
import { cloneDeep } from "lodash";
import React from "react";

import { IAppContext } from "../contexts/appContext/AppContext.types";
import { FieldType, IconSize, QueryParam, TableViewMode, TextAlign, ValueType } from "../enums";
import { TableStorage } from "../model/TableStorage";
import BindingContext, { createPath, IEntity } from "../odata/BindingContext";
import { getQueryParameters } from "../routes/Routes.utils";
import { isNotDefined } from "./general";
import { getOneFetch } from "./oneFetch";

export enum DraftFilterItemKeys {
    UnsavedChanges = "UnsavedChanges",
    SavedDocuments = "SavedDocuments"
}

export interface ICopyEntityArgs {
    copyDataToNewDocument?: boolean;
    includeAttachments?: boolean;
    includeDocumentLinks?: boolean;
    excludeProps?: string[];
    entity?: IEntity;
    skipInvalidProps?: boolean;
}

export const draftFilterLocalPath = BindingContext.localContext("DocumentDraft");

export type IEntityWithDraft = IDocumentEntity | IPaymentDocumentEntity;

/**
 * returns custom filter for filtering documents with attached draft (or without them)
 */
export function getFilterForDocumentDrafts(isPaymentDocument = false): Record<string, TFilterDef> {
    const isEnum = true;
    const oneFetch = getOneFetch();

    return {
        [draftFilterLocalPath]: {
            isValueHelp: false,
            valueType: ValueType.String,
            label: i18next.t("Document:General.WorkInProgress"),
            type: FieldType.MultiSelect,
            // doesn't work for drafts
            isDisabled: isDraftViewCallback,
            fieldSettings: {
                isEnum,
                initialItems: [{
                    label: i18next.t("Document:General.UnsavedChanges"),
                    id: DraftFilterItemKeys.UnsavedChanges
                }, {
                    label: i18next.t("Document:General.Saved"),
                    id: DraftFilterItemKeys.SavedDocuments
                }],
                cacheFetchedItemsInFieldInfo: false,
                itemsFactory: async (args: IGetValueArgs): Promise<ISelectItem[]> => {
                    const { storage, bindingContext } = args;
                    const fieldInfo = cloneDeep(storage.getInfo(bindingContext));
                    // ANY boolean field, which is not nullable. Response will be grouped by that field,
                    // so we can easily determine from the results, if there is or isn't relation to document draft
                    fieldInfo.filterName = isPaymentDocument ? [createPath(PaymentDocumentEntity.PaymentDocumentDraft, PaymentDocumentDraftEntity.DocumentTypeCode)]
                        : [createPath(DocumentEntity.DocumentDraft, DocumentDraftEntity.CalculateVat)];

                    const opts: IFetchValueHelperItemsOptions = {
                        storage: storage as TableStorage,
                        bindingContext, fieldInfo,
                        isEnum
                    };
                    const data = await fetchValueHelperData(opts, oneFetch);
                    // prepare uniq items
                    let hasDraft = false, hasSavedDoc = false;
                    data.forEach((doc: (IDocumentEntity | IPaymentDocumentEntity)) => {
                        const conditionProp = isPaymentDocument ? (doc as IPaymentDocumentEntity).PaymentDocumentDraft.DocumentTypeCode : (doc as IDocumentEntity).DocumentDraft.CalculateVat;
                        if (isNotDefined(conditionProp)) {
                            hasSavedDoc = true;
                        } else {
                            // CalculateVat is true or false => means there is draft attached
                            hasDraft = true;
                        }
                    });

                    return fieldInfo.fieldSettings.initialItems.filter(item =>
                        (item.id === DraftFilterItemKeys.SavedDocuments && hasSavedDoc) ||
                        (item.id === DraftFilterItemKeys.UnsavedChanges && hasDraft)
                    );
                },
                itemsForRender(items: ISelectItem[]): ISelectItem[] {
                    // little hack, so getFilterBarItemRenderValue from FilterBar.utils.ts don't treat this as enum
                    // and uses actual items as values to render read only data.
                    return items;
                }
            },
            filter: {
                buildFilter: getEnumBuildFilterMethod((value: DraftFilterItemKeys[], negate = false) => {
                    if (!value?.length) {
                        return null;
                    }
                    const filters = value.map(val => {
                        const operator = val === DraftFilterItemKeys.SavedDocuments ? "eq" : "ne";
                        return `${isPaymentDocument ? PaymentDocumentEntity.PaymentDocumentDraft : DocumentEntity.DocumentDraft} ${operator} null`;
                    });
                    const query = filters.join(" OR ");
                    return negate ? `not(${query})` : query;
                }),
                groupByProperties: isPaymentDocument ? [createPath(PaymentDocumentEntity.PaymentDocumentDraft, PaymentDocumentDraftEntity.DocumentTypeCode)]
                    : [createPath(DocumentEntity.DocumentDraft, DocumentDraftEntity.CalculateVat)]
            }
        }
    };
}

export const setTableViewMode = (storage: TableStorage<ISmartODataTableAPI>, isInitial?: boolean): boolean => {
    const tableViewMode = (isInitial && getQueryParameters()[QueryParam.DraftId]) || getQueryParameters()[QueryParam.Drafts] ? TableViewMode.Draft : TableViewMode.Saved;

    if (storage.data.tableViewMode !== tableViewMode) {
        storage.data.tableViewMode = tableViewMode;
        storage.refresh();

        return true;
    }

    return false;
};

export const isBasicDraftFilter = "Document/Id eq null AND TemplateForRecurringTask eq null";


/**
 * Fetch info about unfinished documents and their drafts
 */
interface IGetUnfinishedDocumentArgs {
    addDocumentIcon?: boolean;
    hideStatusIcon?: boolean;
    showEmptyLines?: boolean;
}

interface IUnfinishedDocumentCount {
    Document: number;
    Draft: number;
}

export type IUnfinishedDocumentsCounts = Partial<Record<EntityTypeName, IUnfinishedDocumentCount>>;

export async function fetchUnfinishedDocumentsCounts(oData: OData, types: EntityTypeName[], fiscalYear?: IFiscalYearEntity, context?: IAppContext): Promise<IUnfinishedDocumentsCounts> {
    const ret: IUnfinishedDocumentsCounts = {};
    const docFilters: string[] = ["DocumentDraft ne null"];
    let draftDateFilter = ""; // draft doesn't have saved FY navigation
    if (fiscalYear?.Id) {
        const fyEndDate = transformToODataString(fiscalYear.DateEnd, ValueType.Date);
        const fyStartDate = transformToODataString(fiscalYear.DateStart, ValueType.Date);
        const dateProp = isCashBasisAccountingCompany(context) ? DocumentDraftEntity.DateCbaDocument : DocumentDraftEntity.DateAccountingTransaction;
        draftDateFilter = `${dateProp} le ${fyEndDate} AND ${dateProp} ge ${fyStartDate} AND `;
        docFilters.push(`FiscalYear/Id eq ${fiscalYear.Id}`);
    }
    const batch = oData.batch();

    types.forEach((type) => {
        const documentType = getDocumentTypeCodeFromEntityType(type);
        const entitySet = getEntitySetByDocumentType(documentType);

        batch.getEntitySetWrapper(entitySet).query()
            .filter(docFilters.join(" AND ")).count().top(0);
        batch.getEntitySetWrapper(EntitySetName.DocumentDrafts).query()
            .filter(`${draftDateFilter}${isBasicDraftFilter} AND DocumentTypeCode eq '${documentType}'`).count().top(0);
    });

    if (!batch.isEmpty()) {
        const results = await batch.execute() as IBatchResult<ODataQueryResult>[];

        types.forEach((type, idx) => {
            ret[type] = {
                Document: results?.[2 * idx]?.body?._metadata?.count ?? 0,
                Draft: results?.[2 * idx + 1]?.body?._metadata?.count ?? 0
            };
        });
    }

    return ret;
}

export function getUnfinishedDocumentsTableData(context: IAppContext, sortedEntityTypes: EntityTypeName[], counts: IUnfinishedDocumentsCounts, args?: IGetUnfinishedDocumentArgs): ITableTileData {
    const columns: IColumn[] = [
        { id: "docType", label: i18next.t("FiscalYearClose:PayableReceivable.DocType"), textAlign: TextAlign.Left },
        {
            id: "unsaved",
            label: i18next.t("FiscalYearClose:UnsavedChanges"),
            textAlign: TextAlign.Right,
            stretchContent: true
        },
        { id: "drafts", label: i18next.t("FiscalYearClose:Drafts"), textAlign: TextAlign.Right, stretchContent: true }
    ];
    const rows: IRow[] = [];
    const _isVatRegistered = isVatRegisteredCompany(context);

    const _addRow = (entityType: EntityTypeName, route: string, count: number, countDrafts: number) => {
        if (args?.showEmptyLines || count || countDrafts) {
            rows.push({
                id: rows.length + 1,
                values: {
                    docType: getDocumentNameTableCell(entityType, {
                        addIcon: args?.addDocumentIcon,
                        addDDOPPSuffix: _isVatRegistered
                    }),
                    unsaved: countTableCell(count, {
                        route,
                        context,
                        filters: {
                            [draftFilterLocalPath]: [DraftFilterItemKeys.UnsavedChanges]
                        }
                    }, args?.hideStatusIcon),
                    drafts: countTableCell(countDrafts, {
                        route,
                        context,
                        customQueryString: "drafts=true"
                    }, args?.hideStatusIcon)
                }
            });
        }
    };

    sortedEntityTypes.forEach(entityTypeName => {
        _addRow(entityTypeName, getRouteByEntityType(entityTypeName), counts[entityTypeName].Document, counts[entityTypeName].Draft);
    });

    return {
        rows,
        columns
    };
}

export const countTableCell = (count: number | string, args: IGetDrillDownParams, hideIcon?: boolean): ICellValueObject => {
    let countValue = count;
    if (typeof count === "string") {
        countValue = parseFloat(count);
    }
    const hasValue = (countValue as number) > 0;
    const Icon = hasValue ? StatusWarnIcon : StatusYesIcon;

    return {
        tooltip: count?.toString(),
        value: <CellContentStyled isBold={hasValue}>
            {!hideIcon && <Icon width={IconSize.XS} height={IconSize.XS}/>}
            {/*ensure correct position even without icon*/}
            {hideIcon && <div style={{ flex: "1 1 auto" }}/>}
            {hasValue ? getTableDrillDownLink(count?.toString(), args).value : count}
        </CellContentStyled>
    };
};