import { IGetValueArgs } from "@components/smart/FieldInfo";
import { getCommonTableColumnsDefs, withDisplayName } from "@components/smart/GeneralFieldDefinition";
import {
    FilterBarGroup,
    getDefaultFilterGroupDef,
    IFilterGroupDef
} from "@components/smart/smartFilterBar/SmartFilterBar.types";
import { EntitySetName } from "@odata/GeneratedEntityTypes";
import { AssetItemTypeCode } from "@odata/GeneratedEnums";
import { transformToODataString } from "@odata/OData.utils";

import { Sort, ValueType } from "../../../enums";
import { ISplitPageTableDef } from "../../../views/table/TableView.utils";
import { getDocumentStatusColumnDefinition } from "../../documents/DocumentCommonDefs";
import { getDocumentStatusFilterDefinition } from "../../documents/DocumentDef";
import { IDefinition } from "../../PageUtils";
import { StatusEntities } from "../../reports/CommonDefs";
import { getDocumentTypeByPairingType } from "./MinorAssetDef";

export const getMinorAssetPairingFilter = (args: IGetValueArgs): string => {
    const pairingType = AssetItemTypeCode.Acquisition;
    return `DocumentTypeCode in (${transformToODataString(getDocumentTypeByPairingType(pairingType), ValueType.String)})`;
};

export const getDefinitions = (): IDefinition => {
    const filterBarDef: IFilterGroupDef[] = [{
        ...getDefaultFilterGroupDef(FilterBarGroup.Filters),
        isValueHelp: true,
        defaultFilters: [
            "DocumentType",
            "NumberOurs",
            "Amount",
            "BusinessPartner"
        ],
        filterDefinition: {
            ...withDisplayName("DocumentType"),
            NumberOurs: {},
            Amount: {},
            ...withDisplayName("BusinessPartner"),
            ...getDocumentStatusFilterDefinition(StatusEntities),
            ...withDisplayName("Currency", "Code"),
            "BusinessPartner/Street": {},
            "BusinessPartner/City": {},
            "BusinessPartner/PostalCode": {},
            "BusinessPartner/Country": {},
            "BusinessPartner/FirstName": {},
            "BusinessPartner/LastName": {},
            "BusinessPartner/Email": {},
            "BusinessPartner/PhoneNumber": {}

        }
    }];


    const table: ISplitPageTableDef = {
        filterBarDef,
        preventStoreVariant: true,
        id: "MinorAssetDocumentPairingSpecialTable",
        columnDefinition: {
            NumberOurs: { id: "NumberOurs" },
            DateAccountingTransaction: { id: "DateAccountingTransaction" },
            DateDue: { id: "DateDue" },
            PaymentMethodCode: { id: "PaymentMethodCode" },
            SymbolVariable: { id: "SymbolVariable" },
            SymbolSpecific: { id: "SymbolSpecific" },
            SymbolConstant: { id: "SymbolConstant" },
            "BankAccount/AccountNumber": {},
            "BankAccount/BankCode": {},
            Amount: { id: "Amount" },
            Note: { id: "Note" },
            ...getDocumentStatusColumnDefinition(),
            BusinessPartner: {
                id: "BusinessPartner",
                fieldSettings: {
                    displayName: "Name"
                }
            },
            "BusinessPartner/Street": {},
            "BusinessPartner/City": {},
            "BusinessPartner/PostalCode": {},
            "BusinessPartner/Country": {},
            "BusinessPartner/FirstName": {},
            "BusinessPartner/LastName": {},
            "BusinessPartner/Email": {},
            "BusinessPartner/PhoneNumber": {},
            "AccountAssignmentSelection/AccountAssignment": {
                id: "AccountAssignmentSelection/AccountAssignment",
                fieldSettings: {
                    displayName: "Name"
                }
            },
            ...getCommonTableColumnsDefs(),
            DocumentType: {
                fieldSettings: {
                    displayName: "Name"
                }
            },
            DateIssued: {}
        },
        columns: [
            "DocumentType",
            "DateAccountingTransaction",
            "NumberOurs",
            "BusinessPartner",
            "Amount"
        ],
        filter: getMinorAssetPairingFilter,
        initialSortBy: [{ id: "DateCreated", sort: Sort.Desc }]
    };

    return {
        entitySet: EntitySetName.Documents,
        table
    };
};