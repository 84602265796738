import React from "react";
import memoizeOne from "../../utils/memoizeOne";

export const PageLoaderContext = React.createContext({
    isLoading: false,
    setLoading: (state: boolean) => {
    }
});


interface IState {
    isLoading: boolean;
}

export interface WithPageLoader extends IState {
    setLoading: (state: boolean) => void;
}

export default class PageLoaderProvider extends React.Component<{}, IState> {
    state: IState = {
        isLoading: false
    };

    setLoading = (isLoading: boolean) => {
        this.setState({ isLoading });
    };

    getContext = memoizeOne((): WithPageLoader => {
        return {
            isLoading: this.state.isLoading,
            setLoading: this.setLoading
        };
    }, () => [this.state.isLoading]);

    render = () => {
        return (
            <PageLoaderContext.Provider value={this.getContext()}>
                {this.props.children}
            </PageLoaderContext.Provider>
        );
    };
}

export const withPageLoader = <P extends WithPageLoader>(Component: React.ComponentType<P>): React.ComponentType<Omit<P, keyof WithPageLoader>> => {
    return React.forwardRef((props: P, ref) => (
        <PageLoaderContext.Consumer>
            {(context) => {
                return (
                    <Component {...context} ref={ref} {...props} />
                );
            }}
        </PageLoaderContext.Consumer>
    )) as any;
};