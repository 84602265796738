import { ellipsis, transparentize } from "polished";
import styled, { css } from "styled-components/macro";

import { LocalStackOrder, T_BOLD_tiny, T_PLAIN_tiny, T_TITLE_4_NORMAL } from "../../global.style";
import { Button, IconButton } from "../button";
import Clickable from "@components/clickable";

const BackgroundJobHeight = 100;
const MaxBackgroundJobsCount = 5;
const Padding = 12;

export const StyledBackgroundJobs = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 275px;
    height: fit-content;
    max-height: ${BackgroundJobHeight * MaxBackgroundJobsCount}px;
    background-color: ${props => props.theme.C_BG_menu};
    border-radius: ${props => props.theme.borderRadius};
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
`;

export const BackgroundJobDeleteAll = styled.div`
    padding: 12px;
`;

export const BackgroundJobClickable = styled(Clickable) `
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

export const DeleteAllText = styled.span `
    ${T_PLAIN_tiny};
    margin-left: 5px;    
`;

const OverlayStyles = css`
    content: "";
    position: absolute;
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: ${props => transparentize(0.25, props.theme.C_BG_menu)};
`;


interface IStyledBackgroundJob {
    isClosing: boolean;
    hasAction: boolean;
}

export const IconButtonBackgroundJobClose = styled(IconButton)`
    position: absolute;
    top: 5px;
    right: 5px;

    visibility: hidden;
    z-index: ${LocalStackOrder.AboveOverlay};
`;

export const ButtonBackgroundJobAction = styled(Button)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
    z-index: ${LocalStackOrder.AboveOverlay};
    visibility: hidden;
`;


export const StyledBackgroundJob = styled.div<IStyledBackgroundJob>`
    position: relative;
    width: 100%;
    height: ${props => props.isClosing ? 0 : BackgroundJobHeight}px;
    padding: ${props => props.isClosing ? 0 : Padding}px ${Padding}px;
    opacity: ${props => props.isClosing ? 0 : 1};

    transition: padding 0.3s;

    &:not(:last-child):after {
        content: "";
        position: absolute;
        width: calc(100% - ${Padding * 2}px);
        height: 1px;
        bottom: 0;
        background-color: ${props => props.theme.C_FORM_GROUP_HEADER_LINE};
    }

    ${props => props.hasAction && css`
        &:hover:before {
            z-index: ${LocalStackOrder.Overlay};
            ${OverlayStyles};
        }
    `};

    &:hover ${IconButtonBackgroundJobClose}, &:hover ${ButtonBackgroundJobAction} {
        visibility: visible;
    }
`;

export const BackgroundJobHeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 8px;
`;

export const BackgroundJobHeaderText = styled.div`
    margin-left: 5px;
    color: ${props => props.theme.C_ACT_thick_line};
    text-transform: uppercase;
    ${T_BOLD_tiny};
`;

export const BackgroundJobTitle = styled.div`
    ${T_TITLE_4_NORMAL};
    margin-bottom: 8px;
    ${ellipsis("100%")}
`;
