import React from "react";
import { FormStorage } from "../../../views/formView/FormStorage";
import { getDisabledProps, getInfoValue, TInfoValue } from "../FieldInfo";
import { Button } from "../../button";
import { IAlertProps } from "../../alert/Alert";
import { ActionButtonPosition } from "../smartFastEntryList";

export interface IActionItem {
    id: string;
    icon?: React.ReactElement;
    title: string;
    isVisible?: TInfoValue<boolean>;
    isDisabled?: TInfoValue<boolean>;
    disabledAlert?: TInfoValue<Partial<IAlertProps>>;
    hoverAlert?: TInfoValue<Partial<IAlertProps>>;
    // true by default
    isTransparent?: boolean;
    position?: ActionButtonPosition;
}

interface IProps {
    storage: FormStorage,
    action: IActionItem;
    className?: string;
    onClick: (id: string) => void;
}

export class SmartActionButton extends React.Component<IProps> {

    handleClick = (): void => {
        this.props.onClick(this.props.action.id);
    };

    render() {
        const { action, storage } = this.props;
        const { icon, title } = action;
        const { isDisabled, disabledAlert } = getDisabledProps(this.props.action, { storage, item: action });
        const hoverAlert = getInfoValue(this.props.action, "hoverAlert");

        return (
            <Button isTransparent={this.props.action.isTransparent !== false}
                    hotspotId={this.props.action.id}
                    className={this.props.className}
                    isDisabled={isDisabled}
                    alert={disabledAlert}
                    hoverAlert={hoverAlert}
                    onClick={this.handleClick}
                    icon={icon}>
                {title}
            </Button>
        );
    }
}