import { AppContext } from "../../../contexts/appContext/AppContext.types";
import { FormViewForExtend, IFormViewProps } from "../../../views/formView/FormView";
import { IPrSalaryAdvanceEntity } from "@odata/GeneratedEntityTypes";
import { ISmartFieldChange } from "@components/smart/smartField/SmartField";
import { handleCurrentTemporalPropChange } from "@odata/TemporalUtils";
import { withPermissionContext } from "../../../contexts/permissionContext/withPermissionContext";
import { loadAndStoreRanges, setMatchingNumberRange } from "../../numberRange/NumberRange.utils";
import { FormMode } from "../../../enums";

interface IProps extends IFormViewProps<IPrSalaryAdvanceEntity, null> {
}

class SalaryAdvanceFormView extends FormViewForExtend<IPrSalaryAdvanceEntity, IProps> {
    static contextType = AppContext;
    static defaultProps = {
        title: "SalaryAdvances:FormTitle"
    };

    onAfterLoad = async () => {
        const { storage } = this.props;

        const isAuditTrail = storage.formMode === FormMode.AuditTrail;

        if (!isAuditTrail) {
            const promises: Promise<unknown>[] = [];

            if (!storage.data.entity.NumberOurs) {
                promises.push(setMatchingNumberRange(storage, true));
            } else {
                promises.push(loadAndStoreRanges(storage));
            }

            await Promise.all(promises);
        }

        return super.onAfterLoad();
    };

    handleChange = (e: ISmartFieldChange): void => {
        this.props.storage.handleChange(e);
        handleCurrentTemporalPropChange(e, this.props.storage);
        this.props.storage.refreshFields(e.triggerAdditionalTasks);
    };
}

export default withPermissionContext(SalaryAdvanceFormView);