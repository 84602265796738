import React from "react";
import Dialog from "../../components/dialog";
import { withTranslation, WithTranslation } from "react-i18next";
import { IFileMetadataEntity } from "@odata/GeneratedEntityTypes";
import { Button, ButtonGroup, IconButton } from "../../components/button";
import { Label } from "@components/inputs/field";
import { CloseIcon } from "@components/icon";
import { IconSize, TextAlign } from "../../enums";
import Currency from "../../types/Currency";
import { CurrencyCode } from "@odata/GeneratedEnums";
import SimpleGridTable from "../../components/simpleGridTable/SimpleGridTable";
import {
    SimpleGridTableBoldValue,
    SimpleGridTableColumn,
    SimpleGridTableLine,
    SimpleGridTableSmallValue
} from "@components/simpleGridTable/SimpleGridTable.styles";
import { AppContext, IAppContext } from "../../contexts/appContext/AppContext.types";
import customFetch from "../../utils/customFetch";
import { ROSSUM_EXTRACT_URL } from "@utils/Rossum.utils";
import { isNotYetPayedForSubscription } from "../admin/subscriptions/Subscriptions.utils";

const PRICE_PER_DOC = 4;

interface IProps {
    files: IFileMetadataEntity[];
    onConfirm: (files: IFileMetadataEntity[]) => void;
    onCancel: () => void;
    /** Used for files on document. Only can be confirmed or declined. No other action is there.*/
    isOnlyOneFileConfirmation?: boolean;
}

interface IState {
    files: IFileMetadataEntity[];
    remainingFreeDocumentsCount: number;
    isBusy: boolean;
}

class RossumDialog extends React.PureComponent<IProps & WithTranslation, IState> {
    static contextType = AppContext;

    constructor(props: IProps & WithTranslation, context: IAppContext) {
        super(props, context);


        this.state = {
            files: props.files,
            remainingFreeDocumentsCount: 0,
            isBusy: true
        };
    }

    componentDidMount() {
        this.init();
    }

    init = async (): Promise<void> => {
        const response = await customFetch(`${ROSSUM_EXTRACT_URL}/GetTotalRemainingFreeDocumentsCount`);
        const remainingFreeDocumentsCount = await response.json();

        this.setState({
            isBusy: false,
            remainingFreeDocumentsCount
        });
    };


    handleConfirm = (): void => {
        this.props.onConfirm(this.state.files);
    };

    handleDontUse = (): void => {
        this.props.onConfirm([]);
    };

    get hasTrialDiscount(): boolean {
        return this.state.remainingFreeDocumentsCount > 0;
    }

    get isTrialDepleted(): boolean {
        const context = this.context as IAppContext;

        return isNotYetPayedForSubscription(context.getData().subscription) && this.state.remainingFreeDocumentsCount === 0;
    }

    renderTrialDepleted = (): React.ReactElement => {
        return (
            <div>
                {this.props.t("Inbox:RossumDialog.FreeRossumTrialDepleted")}
            </div>
        );
    };

    renderPriceTable = (): React.ReactElement => {
        const count = this.state.files.length;
        const fullAmount = count * PRICE_PER_DOC;
        let discount = 0;

        if (this.state.remainingFreeDocumentsCount > 0) {
            discount = Math.min(count, this.state.remainingFreeDocumentsCount) * PRICE_PER_DOC * -1;
        }

        return (
            <>
                <SimpleGridTableSmallValue textAlign={TextAlign.Left}>
                    {this.props.t("Inbox:RossumDialog.PricePerDocument")}
                </SimpleGridTableSmallValue>
                <SimpleGridTableSmallValue textAlign={TextAlign.Right}>
                    {Currency.format(PRICE_PER_DOC, { currency: CurrencyCode.CzechKoruna })}
                </SimpleGridTableSmallValue>
                <SimpleGridTableSmallValue textAlign={TextAlign.Left} data-name={"countTitle"}>
                    {this.props.t("Inbox:RossumDialog.DocumentCount")}
                </SimpleGridTableSmallValue>
                <SimpleGridTableSmallValue textAlign={TextAlign.Right} data-name={"countValue"}>
                    {count}
                </SimpleGridTableSmallValue>

                {this.hasTrialDiscount && <>
                    <SimpleGridTableSmallValue textAlign={TextAlign.Left} data-name={"discountTitle"}>
                        {this.props.t("Inbox:RossumDialog.Discount")}
                    </SimpleGridTableSmallValue>
                    <SimpleGridTableSmallValue textAlign={TextAlign.Right} data-name={"discountValue"}>
                        {Currency.format(discount, { currency: CurrencyCode.CzechKoruna })}
                    </SimpleGridTableSmallValue>
                </>
                }

                <SimpleGridTableBoldValue textAlign={TextAlign.Left}>
                    {this.props.t("Inbox:RossumDialog.Amount")}
                </SimpleGridTableBoldValue>
                <SimpleGridTableBoldValue textAlign={TextAlign.Right}>
                    {Currency.format(fullAmount + discount, { currency: CurrencyCode.CzechKoruna })}
                </SimpleGridTableBoldValue>
            </>
        );
    };

    renderTrialContent = (): React.ReactElement => {
        const tooManyFilesSelected = this.state.files.length > this.state.remainingFreeDocumentsCount;

        return (
            <>
                <SimpleGridTableSmallValue textAlign={TextAlign.Left}>
                    {this.props.t("Inbox:RossumDialog.RemainingFreeFiles")}
                </SimpleGridTableSmallValue>
                <SimpleGridTableSmallValue textAlign={TextAlign.Right}>
                    {this.state.remainingFreeDocumentsCount}
                </SimpleGridTableSmallValue>

                <SimpleGridTableSmallValue textAlign={TextAlign.Left}>
                    {this.props.t("Inbox:RossumDialog.SelectedFiles")}
                </SimpleGridTableSmallValue>
                <SimpleGridTableSmallValue textAlign={TextAlign.Right}>
                    {this.state.files.length}
                </SimpleGridTableSmallValue>

                {tooManyFilesSelected &&
                    <strong>
                        {this.props.t("Inbox:RossumDialog.TooManyFilesSelected")}
                    </strong>
                }
            </>
        );
    };

    renderContent = (): React.ReactElement => {
        return (
            <SimpleGridTable numColumns={2}>
                <Label hasPadding={false}
                       style={{ gridColumn: "1 / span 2" }}>
                    {this.props.t("Inbox:RossumDialog.FileName")}
                </Label>
                {this.state.files.map(file => {
                    return (
                        <React.Fragment key={file.Id}>
                            <SimpleGridTableColumn textAlign={TextAlign.Left}
                                                   data-name={"fileName"}
                                                   data-id={file.Id}
                                                   title={file.Name}
                            >
                                {file.Name}
                            </SimpleGridTableColumn>
                            <SimpleGridTableColumn textAlign={TextAlign.Right} data-name={"fileRemove"}
                                                   data-id={file.Id}>
                                {!this.props.isOnlyOneFileConfirmation &&
                                    <IconButton title={this.props.t("Inbox:RossumDialog.Remove")}
                                                onClick={() => {
                                                    this.setState({
                                                        files: this.state.files.filter(f => f.Id !== file.Id)
                                                    });
                                                }}
                                                isDecorative>
                                        <CloseIcon width={IconSize.S}/>
                                    </IconButton>
                                }
                            </SimpleGridTableColumn>
                        </React.Fragment>
                    );
                })}
            </SimpleGridTable>
        );
    };

    isConfirmDisabled = (): boolean => {
        const context = this.context as IAppContext;
        const isNotPurchasedYet = isNotYetPayedForSubscription(context.getData().subscription);
        const tooManyFilesSelected = isNotPurchasedYet && this.state.files.length > this.state.remainingFreeDocumentsCount;

        return this.state.files?.length === 0 || tooManyFilesSelected;
    };

    renderButtons = (): React.ReactElement => {
        const context = this.context as IAppContext;
        const isOnlyOneFileConfirmation = this.props.isOnlyOneFileConfirmation;
        const isTrialDepleted = this.isTrialDepleted;
        const isDepletedOneFileConfirmation = isOnlyOneFileConfirmation && isTrialDepleted;
        const isNotPurchasedYet = isNotYetPayedForSubscription(context.getData().subscription);

        return (
            <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                {!isTrialDepleted &&
                    <SimpleGridTable numColumns={2} style={{ marginBottom: "19px" }}>
                        <SimpleGridTableLine spanColumns={2}/>
                        {!isNotPurchasedYet ? this.renderPriceTable() : this.renderTrialContent()}
                    </SimpleGridTable>
                }

                <ButtonGroup align={TextAlign.Right}>
                    {!isDepletedOneFileConfirmation &&
                        <Button isTransparent
                                onClick={this.props.onCancel}>
                            {this.props.t("Common:General.Cancel")}
                        </Button>
                    }

                    {!isOnlyOneFileConfirmation &&
                        <Button isTransparent
                                onClick={this.handleDontUse}>
                            {this.props.t("Inbox:RossumDialog.DontUse")}
                        </Button>
                    }
                    {(!isTrialDepleted || isOnlyOneFileConfirmation) &&
                        <Button onClick={!isTrialDepleted ? this.handleConfirm : this.props.onCancel}
                                isDisabled={this.isConfirmDisabled() && !isDepletedOneFileConfirmation}>
                            {this.props.t("Common:General.Confirm")}
                        </Button>
                    }
                </ButtonGroup>
            </div>
        );
    };

    render() {
        const showTrialDepleted = this.isTrialDepleted;

        return (
            <Dialog
                title={this.props.t("Inbox:RossumDialog.Title")}
                onConfirm={this.handleConfirm}
                onClose={this.props.onCancel}
                footer={this.renderButtons()}
                footerWithoutButtonGroup
                busy={this.state.isBusy}
                width={"388px"}
            >
                {this.state.isBusy ? null :
                    showTrialDepleted ? this.renderTrialDepleted() : this.renderContent()}
            </Dialog>
        );
    }
}

export default withTranslation(["Inbox", "Common"])(RossumDialog) as React.ComponentType<IProps>;