import React, { Component, KeyboardEvent, MouseEvent } from "react";
import { CalendarWrapper, DayLabel, DayWrapper } from "./Calendar.styles";
import { getMonthDays, getMonthFirstDayWeekOffset, getWeekdays, isSameMonth } from "../inputs/date/utils";
import { Dayjs } from "dayjs";
import { debounce, range } from "lodash";
import Day from "./Day";
import FocusManager, { FocusDirection } from "../focusManager";
import { composeRefHandlers } from "@utils/general";
import { IDayAction, TileSize } from "./Calendar.utils";
import { getUtcDayjs } from "../../types/Date";

export interface IProps {
    onDaySelect?: (days: Set<number>) => void;
    month?: Dayjs;
    actions?: Record<number, IDayAction[]>;
    hoursSaldo?: Record<number, number>;
    holidays?: Date[];
}

interface IState {
    selectedDays: Set<number>;
    tileSize: number;
}

export default class Calendar extends Component<IProps, IState> {
    _lastSelectedDay: number;
    _mouseDownOnDay: number;
    _wrapper = React.createRef<HTMLDivElement>();
    resizeObserver: ResizeObserver;

    state: IState = {
        selectedDays: new Set(),
        tileSize: TileSize.L
    }

    constructor(props: IProps) {
        super(props);

        this.resizeObserver = new ResizeObserver(this.handleResize);
    }

    componentDidMount() {
        this.handleResize();
        this.handleResize.flush();
        this.resizeObserver.observe(this._wrapper.current);
    }

    componentWillUnmount() {
        this.resizeObserver.disconnect();
        this.handleResize.cancel();
    }

    handleResize = debounce(() => {
        const tileSize = this._wrapper.current.children[0].getBoundingClientRect().width;

        this.setState({
            tileSize
        });
    }, 100);

    handleMouseDown = (e: MouseEvent, dayNumber: number): void => {
        this._mouseDownOnDay = dayNumber;
        this.handleEnter(e, dayNumber);
    };

    handleMouseEnter = (e: MouseEvent, dayNumber: number): void => {
        if (this._mouseDownOnDay) {
            this.handleEnter(e, dayNumber);
        }
    };

    handleMouseLeave = (e: MouseEvent): void => {
        if (this._mouseDownOnDay) {
            this.handleEnter(e, this._mouseDownOnDay);
            this._mouseDownOnDay = null;
        }
    };

    handleMouseUp = (): void => {
        this._mouseDownOnDay = null;
        this.props.onDaySelect?.(this.state.selectedDays);
    };

    handleEnter = (e: MouseEvent | KeyboardEvent, dayNumber: number): void => {
        let selectedDays = this.state.selectedDays;

        if ((e.shiftKey && this._lastSelectedDay) || (this._mouseDownOnDay && !e.ctrlKey && this._mouseDownOnDay !== dayNumber)) {
            const rootDay = e.shiftKey ? this._lastSelectedDay : this._mouseDownOnDay;
            const start = Math.min(rootDay, dayNumber);
            const length = Math.abs(rootDay - dayNumber) + 1;
            selectedDays = new Set(Array.from({ length }, (_, i) => start + i));
        } else if (e.ctrlKey) {
            if (selectedDays.has(dayNumber)) {
                selectedDays.delete(dayNumber);
            } else {
                selectedDays.add(dayNumber);
            }
        } else {
            if (selectedDays.has(dayNumber) && selectedDays.size === 1) {
                selectedDays.delete(dayNumber);
            } else {
                selectedDays = new Set([dayNumber]);
            }
        }

        this._lastSelectedDay = dayNumber;

        if (!this._mouseDownOnDay) {
            this.props.onDaySelect?.(selectedDays);
        }

        this.setState({
            selectedDays
        });
    }

    render() {
        const currentDayjs = getUtcDayjs();
        const month = this.props.month ?? currentDayjs;
        const weekdays = getWeekdays();
        const weekdayOffset = getMonthFirstDayWeekOffset(month);
        const days = getMonthDays(month);
        let today = 0;

        if (isSameMonth(month.toDate(), currentDayjs.toDate())) {
            today = currentDayjs.date();
        }

        const daysLeft = 7 - (days.length + weekdayOffset) % 7; // days left to complete square

        return <FocusManager direction={FocusDirection.Grid} columnsCount={7}>
            {({ itemProps, wrapperProps }) => (
                    <CalendarWrapper
                            {...wrapperProps}
                            onMouseLeave={this.handleMouseLeave}
                            ref={composeRefHandlers(this._wrapper, wrapperProps.ref)}
                            $isSmall={this.state.tileSize < TileSize.M}
                    >
                        {weekdays.map((day, index) => <DayLabel key={day + index}>{day}</DayLabel>)}
                        {range(weekdayOffset).map((index) => <DayWrapper
                                key={"pre_" + index}
                                $isDummy={true}
                        />)}

                        {days.map((day, index) => {
                            const dayNumber = index + 1;
                            return <Day
                                    actions={this.props.actions?.[dayNumber] ?? []}
                                    hoursSaldo={this.props.hoursSaldo?.[dayNumber] ?? 0}
                                    size={this.state.tileSize}
                                    key={dayNumber}
                                    focusableProps={itemProps}
                                    isToday={today === dayNumber}
                                    isSelected={this.state.selectedDays.has(dayNumber)}
                                    isHoliday={this.props.holidays?.some(h => getUtcDayjs(h).date() === dayNumber)}
                                    dayNumber={dayNumber}
                                    onMouseDown={this.handleMouseDown}
                                    onMouseEnter={this.handleMouseEnter}
                                    onMouseUp={this.handleMouseUp}
                                    onEnter={this.handleEnter}
                            />;
                        })}

                        {range(daysLeft === 7 ? 0 : daysLeft).map((index) => <DayWrapper
                                key={"post_" + index}
                                $isDummy={true}
                        />)}
                    </CalendarWrapper>
            )}
        </FocusManager>;
    }
}
