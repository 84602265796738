import React from "react";
import TestIds from "../../testIds";
import { ItemDescription, ItemLabel, ItemStyled, ItemValue } from "./ReadOnlyList.styles";

export enum ListType {
    Standard,
    Condensed
}

export interface IReadOnlyListItem {
    label: string;
    value: string | React.ReactElement;
    description?: string;
}

export interface IProps extends IReadOnlyListItem {
    type?: ListType;
}

export function ReadOnlyListItem({ label, value, type, description }: IProps): React.ReactElement {
    return (
        <ItemStyled type={type} data-testid={TestIds.ReadOnlyListItem}>
            <ItemLabel type={type} data-testid={TestIds.ReadOnlyListItemLabel}>{label}:</ItemLabel>
            <ItemValue data-testid={TestIds.ReadOnlyListItemValue}>{value}
                {description && <ItemDescription>{description}</ItemDescription>}
            </ItemValue>
        </ItemStyled>
    );
}