import React from "react";
import { AntAnimStyled, NoPreviewStyled, NoPreviewTextStyled } from "./FileViewers.styles";
import TestIds from "../../testIds";
import { useTranslation } from "react-i18next";

interface IProps {
    text: string;
}

const NoPreviewAnt: React.FC<IProps> = ({ text }) => {
    const { t } = useTranslation("Components");

    return (
        <NoPreviewStyled data-testid={TestIds.NoPreview}
                         isWithoutBackground={true}>
            <AntAnimStyled/>
            <NoPreviewTextStyled>
                {text ?? t("Components:FileViewers.NoPreviewText")}
            </NoPreviewTextStyled>
        </NoPreviewStyled>
    );
};

export default NoPreviewAnt;