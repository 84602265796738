import React from "react";
import { ITemporalFormDialogProps } from "../../../../views/formView/TemporalFormDialog";
import NumberRangeTemporalFormDialog from "../../../documents/NumberRangeTemporalFormDialog";
import { IAssetEntity } from "@odata/GeneratedEntityTypes";
import { ROUTE_FIXED_ASSET } from "../../../../routes";
import { createAssetFromEntries } from "../../Asset.utils";


export interface IProps extends Omit<ITemporalFormDialogProps, "fieldPaths"> {
    entryIds: number[];
}

export default class CreateNewAssetDialog extends React.Component<IProps> {
    handleClose = async (isConfirmed: boolean): Promise<void> => {
        if (isConfirmed) {
            const {storage} = this.props;
            storage.setBusy();
            const entity = storage.data.entity as IAssetEntity;
            const result = await createAssetFromEntries(this.props.entryIds, entity);
            storage.setBusy(false);

            const newAsset = result.data as IAssetEntity;
            if (newAsset) {
                // redirect to newly created asset
                storage.history.push(`${ROUTE_FIXED_ASSET}/${newAsset.Id}`);
                return;
            } else {
                // todo: how we handle errors?
                return;
            }
        }
        this.props.onClose?.(isConfirmed);
    };

    render() {
        const { onClose, ...restProps } = this.props;

        return (
            <NumberRangeTemporalFormDialog
                {...restProps}
                fieldPaths={[["Name"], ["NumberRange", "NumberOurs"]]}
                minWidth={"670px"}
                onClose={this.handleClose}
            >
                {this.props.children}
            </NumberRangeTemporalFormDialog>
        );
    }
}