import { not } from "@components/smart/FieldInfo";
import { isSystemEntity, withDisplayName } from "@components/smart/GeneralFieldDefinition";
import {
    FilterBarGroup,
    getDefaultFilterGroupDef,
    IFilterGroupDef
} from "@components/smart/smartFilterBar/SmartFilterBar.types";
import {
    EntitySetName,
    EntityTypeName,
    PayableReceivableTypeEntity,
    VatClassificationEntity
} from "@odata/GeneratedEntityTypes";
import { PayableReceivableTypeCode } from "@odata/GeneratedEnums";
import {
    getAccountComparisonFunction,
    getAccountFormatter
} from "@pages/accountAssignment/AccountAssignment.utils.shared";
import i18next from "i18next";
import React from "react";
import { Trans } from "react-i18next";

import { BasicInputSizes, FieldType, Sort } from "../../../enums";
import { NoDataText } from "../../../global.style";
import { TValue } from "../../../global.types";
import { Model } from "../../../model/Model";
import BindingContext from "../../../odata/BindingContext";
import { IFormDef } from "../../../views/formView/Form";
import { ISplitPageTableDef } from "../../../views/table/TableView.utils";
import { flattenAccountsDef } from "../../accountAssignment/AccountAssignmentDef";
import { setDefByEntityType } from "../../getDefByEntityType";
import { getItemBreadCrumbsText, IDefinition, IGetDefinition } from "../../PageUtils";
import { VatRuleTranslationFiles } from "./VatRules.consts";
import { EmptyBaseConditionsPlaceholder, MouseAnimStyled } from "./VatRules.styles";
import {
    BaseConditionsPlaceholderPath,
    getNonTaxAccountFilter,
    getVatFieldsDef,
    getVatGroupRowsDef,
    getVatRowFields,
    getVatTableColumnsDef,
    getVatTableFilterDefs,
    hasBaseConditionsFilled,
    hasVatProportionalDeduction
} from "./VatRules.utils";
import VatRulesFormView from "./VatRulesFormView";


export const NonTaxAccountPath = BindingContext.localContext("NonTaxAccount");

const IsVatRegisteredFormatter = (val: TValue) => {
    return i18next.t(`Vats:Form.${ val ? "IsVat" : "IsVatNot"}`).toString();
};

export const getDefinitions: IGetDefinition = (): IDefinition => {
    const filterBarDef: IFilterGroupDef[] = [{
        ...getDefaultFilterGroupDef(FilterBarGroup.Filters),
        defaultFilters: [
            VatClassificationEntity.Name,
            VatClassificationEntity.PayableReceivableType,
            VatClassificationEntity.IsVatRegistered,
            VatClassificationEntity.CountryClassification
        ],
        filterDefinition: {
            Name: {},
            PayableReceivableType: {
                fieldSettings: {
                    displayName: PayableReceivableTypeEntity.Name
                }
            },
            IsVatRegistered: {
                formatter: IsVatRegisteredFormatter
            },
            ...withDisplayName(VatClassificationEntity.CountryClassification),
            ...getVatTableFilterDefs({ isVatRule: true })
        },
        isValueHelp: true,
        allowCustomFilters: true
    }];

    const table: ISplitPageTableDef = {
        filterBarDef,
        id: `${EntityTypeName.VatClassification}Table`,
        columns: [
            VatClassificationEntity.Name,
            VatClassificationEntity.PayableReceivableType,
            VatClassificationEntity.IsVatRegistered,
            VatClassificationEntity.CountryClassification
        ],
        columnDefinition: {
            Name: {},
            ...withDisplayName(VatClassificationEntity.PayableReceivableType, "Name"),
            IsVatRegistered: {
                formatter: IsVatRegisteredFormatter
            },
            ...withDisplayName(VatClassificationEntity.CountryClassification, "Name"),
            ...getVatTableColumnsDef({ isVatRule: true })
        },
        title: i18next.t("Vats:Title"),
        initialSortBy: [{ id: "Name", sort: Sort.Asc }]
    };

    const affectedFields = [...getVatRowFields(), { id: BaseConditionsPlaceholderPath }];

    const form: IFormDef = {
        id: `${EntityTypeName.VatClassification}Form`,
        translationFiles: getDefinitions.translationFiles,
        formControl: VatRulesFormView,
        isDeletable: true,
        getItemBreadCrumbText: (storage: Model) =>
                getItemBreadCrumbsText(storage, i18next.t("Vats:NewRule"), storage.data.entity?.Name),
        isReadOnly: isSystemEntity,
        additionalProperties: [
            { id: "NonTaxAccountNumber" },
            { id: "NonTaxAccountName" }
        ],
        fieldDefinition: {
            Name: {
                isRequired: true,
                width: BasicInputSizes.XL
            },
            PayableReceivableType: {
                type: FieldType.ComboBox,
                fieldSettings: {
                    displayName: "Name"
                },
                filter: {
                    select: `Code ne '${PayableReceivableTypeCode.InternalDocument}'`
                },
                affectedFields
            },
            IsVatRegistered: {
                type: FieldType.ComboBox,
                defaultValue: true,
                affectedFields,
                fieldSettings: {
                    items: [{
                        id: true,
                        label: i18next.t("Vats:Form.IsVat")
                    }, {
                        id: false,
                        label: i18next.t("Vats:Form.IsVatNot")
                    }]
                }
            },
            CountryClassification: {
                type: FieldType.ComboBox,
                fieldSettings: {
                    displayName: "Name"
                },
                affectedFields
            },
            ...getVatFieldsDef(false),
            [BaseConditionsPlaceholderPath]: {
                type: FieldType.Custom,
                // custom field that renders a placeholder in case base conditions are not filled at all,
                // and therefore we don't know which type of the rule it is
                // vatFields are not visible in this case, placeholder is visible instead
                isVisible: not(hasBaseConditionsFilled),
                render: () => {
                    return (<EmptyBaseConditionsPlaceholder>
                        <NoDataText>
                            <Trans i18nKey={"Vats:Form.BaseConditionsNotFilled"}>
                                Zatím tu nic není,<br/> nejdříve zadej vstupní podmínky...
                            </Trans>
                        </NoDataText>
                        <MouseAnimStyled/>
                    </EmptyBaseConditionsPlaceholder>);
                }
            },
            [NonTaxAccountPath]: {
                ...flattenAccountsDef,
                label: i18next.t("Vats:Form.NonTaxAccountNumber"),
                isRequired: hasVatProportionalDeduction,
                isVisible: hasVatProportionalDeduction,
                comparisonFunction: getAccountComparisonFunction("NonTaxAccount"),
                formatter: getAccountFormatter("NonTaxAccount"),
                fieldSettings: {
                    ...flattenAccountsDef.fieldSettings,
                },
                filter: {
                    select: getNonTaxAccountFilter()
                }
            },
        },
        groups: [{
            id: "general",
            rows: [[{ id: VatClassificationEntity.Name }]]
        }, {
            id: "Condition",
            title: i18next.t("Vats:Form.Conditions"),
            rows: [[{ id: VatClassificationEntity.PayableReceivableType }, { id: VatClassificationEntity.IsVatRegistered }, { id: VatClassificationEntity.CountryClassification }]]
        }, {
            id: "Rule",
            title: i18next.t("Vats:Form.Rule"),
            rows: getVatGroupRowsDef({ isVatRule: true })
        }]
    };

    // Add local context path for NonTaxAccount to the def
    const ruleGroup = form.groups.find(g => g.id === "Rule");
    ruleGroup.rows[ruleGroup.rows.length - 1].push({ id: NonTaxAccountPath });
    form.fieldDefinition.VatDeductionType.affectedFields.push({ id: NonTaxAccountPath });

    return {
        entitySet: EntitySetName.VatClassifications,
        table,
        form
    };
};

getDefinitions.translationFiles = VatRuleTranslationFiles;
setDefByEntityType(EntityTypeName.VatClassification, getDefinitions);