import { SwitchType } from "@components/inputs/switch/Switch";
import { not } from "@components/smart/FieldInfo";
import { ActionButtonPosition } from "@components/smart/smartFastEntryList";
import UserInitial from "@components/token/UserInitial";
import { createPath } from "@odata/BindingContext";
import {
    BankAccountEntity,
    EntityTypeName,
    IPrGroupDeductionEntity,
    PrDeductionBusinessPartnerEntity,
    PrDeductionTemporalEntity,
    PrEmployeeEntity,
    PrGroupDeductionEmployeeEntity,
    PrGroupDeductionEntity
} from "@odata/GeneratedEntityTypes";
import { PrDeductionTypeCode } from "@odata/GeneratedEnums";
import { getTemporalPropertyFieldDefinition } from "@odata/TemporalUtils";
import { paymentDetailFields } from "@pages/banks/bankAccounts/BankAccounts.utils";
import { setDefByEntityType } from "@pages/getDefByEntityType";
import { IDefinition, IGetDefinition } from "@pages/PageUtils";
import { getCommonDeductionDefinitions } from "@pages/payroll/deduction/commonDeduction.def";
import { ADD_EMPLOYEE_ITEM, DeductionType, isAmongTypes } from "@pages/payroll/deduction/Deduction.utils";
import { initials } from "@utils/string";
import i18next from "i18next";
import React from "react";

import { IAppContext } from "../../../../contexts/appContext/AppContext.types";
import { FieldType, TextAlign } from "../../../../enums";
import { FormStorage } from "../../../../views/formView/FormStorage";

export const getDefinitions: IGetDefinition = (context: IAppContext): IDefinition => {
    const definition = getCommonDeductionDefinitions(context, DeductionType.GroupDeduction);

    definition.table.columns = [
        ...definition.table.columns,
        PrGroupDeductionEntity.Employees
    ];

    definition.table.columnDefinition = {
        ...definition.table.columnDefinition,
        [PrGroupDeductionEntity.Employees]: {
            textAlign: TextAlign.Left,
            formatter: (value, args) => {
                if (args.entity.CurrentTemporalPropertyBag.IsIncludingAllEmployees) {
                    const title = i18next.t("GroupDeduction:Form.AllEmployees");
                    return {
                        value: <UserInitial title={title} tooltip={title}/>,
                        tooltip: title
                    };
                }
                return {
                    value: <>
                        {(args.entity as IPrGroupDeductionEntity).Employees?.map((e, index) => {
                            const wholeName = `${e.Employee?.FirstName} ${e.Employee?.LastName}`;
                            const shortCut = initials(wholeName);
                            return <UserInitial key={index}
                                                title={shortCut}
                                                tooltip={wholeName}/>;
                        })}
                    </>,
                    tooltip: (args.entity as IPrGroupDeductionEntity).Employees?.map(e => `${e.Employee?.FirstName} ${e.Employee?.LastName}`).join(", ")
                };
            }
        }
    };

    definition.table.additionalProperties = [
        ...(definition.table.additionalProperties ?? []),
        { id: createPath(PrGroupDeductionEntity.CurrentTemporalPropertyBag, PrDeductionTemporalEntity.IsIncludingAllEmployees) },
        { id: createPath(PrGroupDeductionEntity.Employees, PrGroupDeductionEmployeeEntity.Employee, PrEmployeeEntity.FirstName) },
        { id: createPath(PrGroupDeductionEntity.Employees, PrGroupDeductionEmployeeEntity.Employee, PrEmployeeEntity.LastName) }
    ];

    definition.form.fieldDefinition = {
        ...definition.form.fieldDefinition,
        [createPath(PrGroupDeductionEntity.Employees, PrGroupDeductionEmployeeEntity.Employee, PrEmployeeEntity.NumberOurs)]: {
            isReadOnly: true
        },
        ...getTemporalPropertyFieldDefinition({
            propName: PrDeductionTemporalEntity.IsIncludingAllEmployees,
            definition: {
                type: FieldType.Switch,
                fieldSettings: {
                    type: SwitchType.YesNo
                }
            },
            withOpeningButton: false
        }),
        [createPath(PrGroupDeductionEntity.Employees, PrGroupDeductionEmployeeEntity.Employee, PrEmployeeEntity.LastName)]: {
            isReadOnly: true
        }
    };

    definition.form.groups = [
        {
            id: "main",
            rows: [
                [
                    { id: PrGroupDeductionEntity.Name },
                    { id: createPath(PrGroupDeductionEntity.CurrentTemporalPropertyBag, PrDeductionTemporalEntity.IsIncludingAllEmployees) },
                ], [
                    { id: createPath(PrGroupDeductionEntity.CurrentTemporalPropertyBag, PrDeductionTemporalEntity.CalculationType) },
                    { id: createPath(PrGroupDeductionEntity.CurrentTemporalPropertyBag, PrDeductionTemporalEntity.Amount) },
                    { id: createPath(PrGroupDeductionEntity.CurrentTemporalPropertyBag, PrDeductionTemporalEntity.PercentageEmployer) },
                    { id: createPath(PrGroupDeductionEntity.CurrentTemporalPropertyBag, PrDeductionTemporalEntity.PercentageEmployee) },
                ],
                [
                    { id: PrGroupDeductionEntity.DateStart },
                    { id: PrGroupDeductionEntity.DateEnd }
                ]
            ]
        }, {
            id: "receiver",
            title: i18next.t("GroupDeduction:Form.Receiver"),
            isVisible: not(isAmongTypes([PrDeductionTypeCode.MealVoucher])),
            rows: [
                [
                    { id: createPath(PrGroupDeductionEntity.Recipient, PrDeductionBusinessPartnerEntity.Name) },
                    { id: createPath(PrGroupDeductionEntity.Recipient, PrDeductionBusinessPartnerEntity.LegalNumber) }
                ]
            ]
        }, {
            // id "payment" is expected in the change handlers
            id: "payment",
            title: i18next.t("Common:Form.BankAccount"),
            isVisible: not(isAmongTypes([PrDeductionTypeCode.MealVoucher])),
            rows: [
                [
                    { id: createPath(PrGroupDeductionEntity.BankAccount, BankAccountEntity.Country) },
                    { id: createPath(PrGroupDeductionEntity.BankAccount, BankAccountEntity.AbaNumber) },
                    { id: createPath(PrGroupDeductionEntity.BankAccount, BankAccountEntity.AccountNumber) },
                    { id: createPath(PrGroupDeductionEntity.BankAccount, BankAccountEntity.Bank) },
                    { id: createPath(PrGroupDeductionEntity.BankAccount, BankAccountEntity.IBAN) },
                    { id: createPath(PrGroupDeductionEntity.BankAccount, BankAccountEntity.SWIFT) }
                ],
                [...paymentDetailFields]
            ]
        }, {
            id: "employees",
            title: i18next.t("GroupDeduction:Form.EmployeesGroupTitle"),
            rows: [],
            isVisible: (args) => {
                return !args.storage.getValueByPath(createPath(PrGroupDeductionEntity.CurrentTemporalPropertyBag, PrDeductionTemporalEntity.IsIncludingAllEmployees));
            },
            lineItems: {
                collection: PrGroupDeductionEntity.Employees,
                canAdd: false,
                canReorder: false,
                order: createPath(PrGroupDeductionEmployeeEntity.Employee, PrEmployeeEntity.NumberOurs),
                columns: [{
                    id: createPath(PrGroupDeductionEmployeeEntity.Employee, PrEmployeeEntity.NumberOurs)
                }, {
                    id: createPath(PrGroupDeductionEmployeeEntity.Employee, PrEmployeeEntity.LastName)
                }],
                customActionButtons: [{
                    id: ADD_EMPLOYEE_ITEM,
                    title: i18next.t("GroupDeduction:Form.AddEmployee"),
                    isDisabled: args => {
                        return args.storage.isDisabled || !!(args.storage as FormStorage).getBackendDisabledFieldMetadata(args.storage.data.bindingContext.navigate(PrGroupDeductionEntity.Employees));
                    },
                    isTransparent: false,
                    position: ActionButtonPosition.Before
                }]
            }
        }
    ];


    return definition;
};

getDefinitions.translationFiles = [
    ...getCommonDeductionDefinitions.translationFiles,
    "GroupDeduction",
    "Employee"
];

setDefByEntityType(EntityTypeName.PrGroupDeduction, getDefinitions);