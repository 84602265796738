import { ColoredIcon, LockClosedIcon, LockOpenedIcon } from "@components/icon";
import { StyledStatusHighlight } from "@components/table/Rows.styles";
import Tooltip from "@components/tooltip";
import { ElectronicSubmissionTypeCode } from "@odata/GeneratedEnums";
import React, { useCallback } from "react";

import { IconSize, Status } from "../../enums";
import TestIds from "../../testIds";
import DateType from "../../types/Date";
import {
    CellContentWrapper,
    ClickableContentWrapper,
    ElectronicSubmissionCellStyled,
    LockIconWrapper,
    SelectIconWrapper,
    TitleWrapper
} from "./ElectronicSubmission.styles";
import { CaretIconRight } from "./YearSwitcher.styles";

interface IFileProps {
    date: Date;
    displayedDate?: Date;
    type: ElectronicSubmissionTypeCode;
    rowIndex: number;
    colIndex: number;
    rowSpan: number;
    isLocked?: boolean;
    isSelected?: boolean;
    warningMessage?: string;
    warningStatus?: Status;
    onSelect?: (type: ElectronicSubmissionTypeCode, date: Date) => void;
}

const ElectronicSubmissionTableCell: React.FC<IFileProps> = (props) => {
    const { isLocked, date, displayedDate, type, rowIndex, colIndex, rowSpan, isSelected, onSelect } = props;

    const handleSelect = useCallback(() => {
        onSelect?.(type, date);
    }, [type, date, onSelect]);

    const Icon = isLocked ? LockClosedIcon : LockOpenedIcon;

    return (
        <ElectronicSubmissionCellStyled $rowIndex={rowIndex}
                                        $colIndex={colIndex}
                                        $rowSpan={rowSpan}
                                        $isSelected={isSelected}
                                        data-testid={TestIds.ElSubPeriodCell}
                                        data-id={`${rowIndex-1}:${colIndex-1}`}>
            {props.warningMessage && (
                <Tooltip content={props.warningMessage}>
                    {(ref) => (
                        <StyledStatusHighlight ref={ref}
                                               data-testid={TestIds.State}
                                               statusHighlight={props.warningStatus}
                                               selected={false}/>
                    )}
                </Tooltip>
            )}
            <CellContentWrapper onClick={handleSelect}>
                <ClickableContentWrapper>
                    <LockIconWrapper>
                        <ColoredIcon><Icon width={IconSize.S} height={IconSize.S}/></ColoredIcon>
                    </LockIconWrapper>
                    <TitleWrapper>{DateType.format(displayedDate ?? date)}</TitleWrapper>
                    <SelectIconWrapper $isSelected={isSelected}>
                        <CaretIconRight isLightHover width={IconSize.M} height={IconSize.M}/>
                    </SelectIconWrapper>
                </ClickableContentWrapper>
            </CellContentWrapper>
        </ElectronicSubmissionCellStyled>
    );
};

export default ElectronicSubmissionTableCell;