import React, { RefObject } from "react";
import { PageViewMode } from "../../enums";
import { FormStorage } from "../../views/formView/FormStorage";
import { RecurringTaskTypeCode } from "@odata/GeneratedEnums";
import InvoicesReceivedFormView from "../documents/invoicesReceived/InvoicesReceivedFormView";
import InvoicesIssuedFormView from "../documents/invoicesIssued/InvoicesIssuedFormView";
import { isObjectEmpty } from "@utils/general";
import { IDocumentDraftEntity } from "@odata/GeneratedEntityTypes";

interface IProps {
    storage: FormStorage,
    draftData: IDocumentDraftEntity;
    page: number,
    typeCode: RecurringTaskTypeCode,
    passRef: RefObject<any>,
    style?: React.CSSProperties
    onAfterDraftLoad?: (draft: IDocumentDraftEntity) => void
}

class DraftForm extends React.Component<IProps> {
    componentDidMount() {
        this.init();
    }

    componentDidUpdate(prevProps: Readonly<IProps>) {
        this.init();
    }

    init = (): void => {
        if (!(this.props.storage.loaded || this.props.storage.loading)) {
            const entity = this.props.draftData;
            this.props.storage.init({
                bindingContext: this.props.storage.data.bindingContext,
                data: isObjectEmpty(entity) ? null : entity
            }).then(() => {
                this.props.storage.data.entity.DocumentTypeCode = this.props.typeCode;
                if (!this.props.storage.data.bindingContext.isNew()) {
                    this.props.onAfterDraftLoad?.(this.props.storage.data.entity);
                }
                this.props.passRef?.current?.forceUpdate();
            });
        }
        const viewMode = this.props.page === 3 ? PageViewMode.FormReadOnly : PageViewMode.Default;
        if (viewMode !== this.props.storage.pageViewMode) {
            this.props.storage.pageViewMode = viewMode;
            this.props.passRef?.current?.forceUpdate();
        }
    }

    render() {
        const defaultProps = {
            storage: this.props.storage,
            ref: this.props.passRef,
            style: this.props.style,
            title: "",
            preventSettingMatchingNumberRange: true,
            preventDraftSave: true,
            withoutButtons: true,
            withoutBreadCrumbs: true,
            hideButtons: true,
            formProps: {
                shouldHideAuditTrail: true,
                shouldHideVariant: true,
                hideHeader: true,
                hideBreadcrumbs: true,
                renderScrollbar: false,
                withoutPadding: true
            }
        };

        if (this.props.typeCode === RecurringTaskTypeCode.InvoiceReceived) {
            return <InvoicesReceivedFormView
                {...defaultProps}
            />;
        }

        return <InvoicesIssuedFormView
            {...defaultProps}
        />;
    }
}

export default DraftForm;
