import { AlertAction, AlertPosition } from "@components/alert/Alert";
import { ISmartFieldChange } from "@components/smart/smartField/SmartField";
import { TSmartODataTableStorage } from "@components/smart/smartTable/SmartODataTableBase";
import { TCustomRowAction } from "@components/smart/smartTable/SmartTable.utils";
import { IRow, IRowAction, ISort, TId } from "@components/table";
import { IRowProps } from "@components/table/Rows";
import { ITabValue } from "@components/tabs";
import { IToolbarItem, TToolbarItem } from "@components/toolbar";
import { getValueTypeFromProperty } from "@odata/FieldInfo.utils";
import { transformToODataString } from "@odata/OData.utils";
import { ExportType } from "@utils/ExcelExport";
import { isDefined, isObjectEmpty } from "@utils/general";
import { KeyboardShortcut } from "@utils/keyboardShortcutsManager/KeyboardShorcutsManager.utils";
import { ITextListGroup } from "@utils/pdfPrinting/PdfPrinting";
import { debounce } from "lodash";
import React, { Component, ReactElement } from "react";
import SimpleBar from "simplebar-react";

import { BreadCrumbProvider, THistoryBack } from "../../components/breadCrumb";
import SmartDrillDownFilters from "../../components/drillDown/SmartDrillDownFilters";
import { IProps as IIconProps } from "../../components/icon";
import { IProps as ISmartFilterBarProps } from "../../components/smart/smartFilterBar/SmartFilterBar";
import { IProps as ISmartTableProps, SmartTable } from "../../components/smart/smartTable/SmartTable";
import SmartTableSortingDialog from "../../components/smart/smartTable/SmartTableSortingDialog";
import { INPUT_DEBOUNCE_TIME } from "../../constants";
import { AppContext } from "../../contexts/appContext/AppContext.types";
import { PortalRootElementProvider } from "../../contexts/portalRootElement/PortalRootElementProvider";
import { GroupStatus, QueryParam, RowAction, ValueType } from "../../enums";
import { IModifierKeys, TRecordAny, TRecordString, TValue } from "../../global.types";
import { IAfterSaveEventArgs, ModelEvent } from "../../model/Model";
import { IFilterQuery, ITableStorageDefaultCustomData } from "../../model/TableStorage";
import BindingContext, { createBindingContext, IEntity, TEntityKey } from "../../odata/BindingContext";
import { getQueryParameters } from "../../routes/Routes.utils";
import KeyboardShortcutsManager from "../../utils/keyboardShortcutsManager/KeyboardShortcutsManager";
import LocalSettings from "../../utils/LocalSettings";
import memoizeOne, { IMemoized } from "../../utils/memoizeOne";
import { FormStorage } from "../formView/FormStorage";
import { SmartHeaderStyled } from "../formView/FormView.styles";
import View from "../View";
import { IProps as IConfirmationButtonProps } from "./ConfirmationButtons";
import TableCustomizationDialog from "./TableCustomizationDialog";
import TablePrintDialog from "./TablePrintDialog";
import TableToolbar from "./TableToolbar";
import { ICustomButtonDefArgs, TableButtonsAction, TableButtonsActionType } from "./TableToolbar.utils";
import { getConfirmationActionText } from "./TableView.render.utils";
import { SmartFilterBarStyled, StyledTableTabs, TableAlert, TableWrapper, TopWrapper } from "./TableView.styles";
import {
    getCachedFilter,
    getRowActionFromTableAction,
    handleExportButton,
    handleSortChange,
    isDraftView,
    ISplitPageTableDef,
    ITableDefTabData,
    SECONDARY_FILTERS_ALL,
    TTableViewTabSettings
} from "./TableView.utils";

export interface ITableViewBaseProps<C extends ITableStorageDefaultCustomData = ITableStorageDefaultCustomData> {
    hideDrilldown?: boolean;
    storage?: TSmartODataTableStorage<C>;
    // for dialog forms storage of "bottom" form, otherwise undef
    rootStorage?: FormStorage;
    formStorage?: FormStorage;
    onFormRefreshNeeded?: () => void;
    onRowSelect?: (bindingContext: BindingContext, props?: IRowProps, params?: TRecordString, customData?: TRecordAny) => void;
    onRemovedRows?: (rows: BindingContext[]) => void;
    onActiveRowActionCountChange?: (count: number) => void;
    onTableLoad?: () => void;
    onAdd?: (type?: string) => void;
    onAddingRowCancel?: () => void;
    parents?: IEntity[];
    parentEntity?: IEntity;
    onParentKeyChange?: (id: TEntityKey) => void;
    onFormDisabledChange?: (disabled: boolean) => void;
    ref?: React.Ref<React.ReactNode>;
    drilldown?: React.ReactElement | ((row: IRow) => React.ReactElement);
    back?: THistoryBack;
    onTableActionChange?: (action: TableButtonsAction | string) => void;
}

export interface ITableViewBaseState {
    selectedSecondaryFilter?: string;
    secondaryFilterValues?: ITabValue[];
    tabSettings?: TTableViewTabSettings;
    isTableActionInProgress?: boolean;
}

export interface IHandleCustomActionArgs {
    update?: boolean;
    value?: TValue;
}

class TableView<P extends ITableViewBaseProps = ITableViewBaseProps, S extends ITableViewBaseState = ITableViewBaseState> extends Component<P, S> {
    static contextType = AppContext;
    // context: React.ContextType<typeof AppContext>;
    static defaultProps: Partial<ITableViewBaseProps>;

    tableRef = React.createRef<HTMLDivElement>();
    _scrollBarInstanceRef = React.createRef<SimpleBar>();
    _unsubscribeKeyboardShortcuts: () => void;

    constructor(props: P) {
        super(props);

        // methods that are extended by the inherited classes needs to be created as functions, meaning they need to be bound
        this.handleClearFilter = this.handleClearFilter.bind(this);
        this.getFilter = this.getFilter.bind(this);
        this.getTableSharedProps = this.getTableSharedProps.bind(this);
        this.handleTableLoad = this.handleTableLoad.bind(this);
        this.handleFormAfterSave = this.handleFormAfterSave.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.getToolbarButtons = this.getToolbarButtons.bind(this);
        this.getConfirmText = this.getConfirmText.bind(this);
        this.getDisabledButtons = this.getDisabledButtons.bind(this);
        this.rowsFactory = this.rowsFactory.bind(this);
        this.handleTableButtonClick = this.handleTableButtonClick.bind(this);
        this.isLoaded = this.isLoaded.bind(this);
        this.isTableAction = this.isTableAction.bind(this);
        this.setTableAction = this.setTableAction.bind(this);
        this.isRowWithoutAction = this.isRowWithoutAction.bind(this);
        this.handleCustomAction = this.handleCustomAction.bind(this);
        this.handleToolbarConfirm = this.handleToolbarConfirm.bind(this);
        this.handleToolbarCancel = this.handleToolbarCancel.bind(this);
        this.handleKeyboardShortcut = this.handleKeyboardShortcut.bind(this);

        if (props.rootStorage) {
            // make root storage available in the dialog page storage, e.g.
            // so that we can access it in callbacks like filters
            props.storage.setCustomData({
                rootStorage: props.rootStorage
            });
        }

        this.props.storage.applyFilters();

        // tabs functionality
        let selectedSecondaryFilter = getQueryParameters()[QueryParam.DefaultTab] ?? LocalSettings.get(this.props.storage.id).selectedTab ?? this.props.storage.data.definition.tabs?.[0]?.id;

        // defensive check - we could've changed id of the filters
        // remove selectedSecondaryFilter if that id doesn't actually exist in definition
        if (!this.props.storage.data.definition.tabs?.find(tab => tab.id === selectedSecondaryFilter)) {
            selectedSecondaryFilter = null;
        }

        this.state = {
            ...this.state,
            selectedSecondaryFilter: selectedSecondaryFilter,
            isTableActionInProgress: false,
            tabSettings: {
                data: this.props.storage.data.definition.tabs ?? [],
                additionalData: [],
                ...this.props.storage.data.definition.tabsSettings
            }
        };

        this.applySecondaryFilter(selectedSecondaryFilter);

        this._unsubscribeKeyboardShortcuts = KeyboardShortcutsManager.subscribe({
            shortcuts: [KeyboardShortcut.ALT_N, KeyboardShortcut.CTRL_Q],
            callback: this.handleKeyboardShortcut
        });
    }

    // or handled manually for custom content
    get shouldApplyTabsAsSecondaryFilter(): boolean {
        return this.hasTabs && (!!this.props.storage.data.definition.tabsSettings?.filterFieldName || !!this.props.storage.data.definition.tabsSettings?.customFilter);
    }

    get shouldRenderBreadcrumbs(): boolean {
        return true;
    }

    get hasTabs(): boolean {
        return !!this.props.storage.data.definition.tabs;
    }

    get isAddingRow(): boolean {
        return !!this.props.storage.data.addingRow;
    }

    get isAddingNew(): boolean {
        return this.isAddingRow && !getQueryParameters()[QueryParam.DraftId];
    }

    get isToolbarDisabled(): boolean {
        return !this.props.storage.tableAPI?.getState().loaded;
    }

    applySecondaryFilter = (tabId: string = this.state.selectedSecondaryFilter): void => {
        if (!this.shouldApplyTabsAsSecondaryFilter) {
            return null;
        }

        const filter = this.createTabFilterQuery(tabId);

        this.props.storage.store({ customSecondaryFilter: filter });
    };

    getRowAction: IMemoized<[], TCustomRowAction> = memoizeOne((): TCustomRowAction => {
        if (!this.isTableAction()) {
            return null;
        }

        return {
            actionType: this.getRowActionType(),
            isRowWithoutAction: this.isRowWithoutAction,
            ...this.getCustomRowAction()
        };
    }, () => [this.getCustomRowAction(), this.getTableAction()]);

    // prepared to be overridden, enforce memoization
    getCustomRowAction: IMemoized<[], TCustomRowAction> = memoizeOne((): TCustomRowAction => {
        return null;
    });

    // prepared to be overridden
    // row status icon, the black one when no action is active
    getRowIcon = (id: TId, row: IRowProps, rowAction: IRowAction): React.ComponentType<IIconProps> => {
        return null;
    };

    isLoaded(): boolean {
        return !this.shouldApplyTabsAsSecondaryFilter || !!this.state.secondaryFilterValues;
    }

    shouldComponentUpdate(nextProps: P, nextState: S): boolean {
        return nextState.isTableActionInProgress !== this.state.isTableActionInProgress;
    }

    componentDidMount() {
        this.updateSecondaryQueryValues();

        this.props.storage.emitter.on(ModelEvent.AfterSave, this.handleFormAfterSave);
    }

    componentWillUnmount() {
        this._unsubscribeKeyboardShortcuts();

        this.props.storage.emitter.off(ModelEvent.AfterSave, this.handleFormAfterSave);
    }

    beforeRefresh = async (): Promise<void> => {
        await this.updateSecondaryQueryValues();
    };

    createTabFilterQuery = (id: string): string => {
        const { tabSettings } = this.state;
        const data = [...(tabSettings.data || []), ...(tabSettings.additionalData || [])];
        const tabDef = data.find(tabDef => tabDef.id === id);
        return this.getTabQuery(tabDef);
    };

    handleKeyboardShortcut(shortcut: KeyboardShortcut, event: KeyboardEvent): boolean {
        if (shortcut === KeyboardShortcut.ALT_N) {
            if (!this.getToolbarButtons()?.includes(TableButtonsAction.Add)) {
                return false;
            }
            this.handleAdd();
            return true;
        } else if (shortcut === KeyboardShortcut.CTRL_Q) {
            this.handleAddingRowCancel();
            return true;
        }

        return false;
    }

    handleAdd(type?: string): void {
        this.props.onAdd?.(type);
    }

    handleTabChange(id: string): void {
        LocalSettings.set(this.props.storage.id, { selectedTab: id });
        this.setState({
            selectedSecondaryFilter: id
        });
        this.applySecondaryFilter(id);

        if (this.shouldApplyTabsAsSecondaryFilter) {
            // let value helpers know about the change
            this.props.storage.emitter.emit(ModelEvent.FilterChanged);
        }

        this.forceUpdate();
    }

    getTabQuery = (tabDef: ITableDefTabData): string => {
        if (!tabDef || tabDef.id === SECONDARY_FILTERS_ALL) {
            return "";
        }

        if (this.state.tabSettings.customFilter) {
            return this.state.tabSettings.customFilter(tabDef.id, this.props.rootStorage);
        }

        if (tabDef.filterNames) {
            return `${this.state.tabSettings.filterFieldName} in (${transformToODataString(tabDef.filterNames, ValueType.String)})`;
        }

        if (isDefined(tabDef.value)) {
            const type = getValueTypeFromProperty(this.props.storage.data.bindingContext.navigate(this.state.tabSettings.filterFieldName).getProperty());
            return `${this.state.tabSettings.filterFieldName} eq ${transformToODataString(tabDef.value, type)}`;
        }

        return `${this.state.tabSettings.filterFieldName} eq '${tabDef.id}'`;
    };

    // tabs in definition can be either automatically used for filtering (if filterFieldName is set)

    getSecondaryTabsCount = async (filter = ""): Promise<ITabValue[]> => {
        const path = this.props.storage.data.bindingContext.toString();

        const query = this.props.storage.oData.fromPath(path).query()
            .filter(filter)
            .groupBy(this.state.tabSettings.filterFieldName)
            .aggregate("$count as @odata.count");

        const result = (await query.fetchData<IEntity[]>())?.value;
        const secondaryFilterValues: ITabValue[] = [];

        for (const status of this.state.tabSettings.data) {
            if (status.id === SECONDARY_FILTERS_ALL) {
                secondaryFilterValues.push({
                    id: SECONDARY_FILTERS_ALL,
                    value: result?.reduce((sum, current) => sum + current._metadata[""].count, 0)
                });
            } else {
                const _getCount = (name: string) => {
                    return result?.find(res =>
                        res[this.state.tabSettings.filterFieldName]?.toLowerCase?.() === name
                        || (isDefined(status.value) && res[this.state.tabSettings.filterFieldName] === status.value)
                    )?._metadata[""]?.count ?? 0;
                };

                let count = 0;
                if (status.filterNames?.length > 0) {
                    count = status.filterNames.reduce((prevValue: number, name: string) => {
                        return prevValue + _getCount(name?.toLowerCase());
                    }, 0);

                } else {
                    count = _getCount(status.id?.toLowerCase());
                }

                secondaryFilterValues.push({
                    id: status.id,
                    value: count
                });
            }
        }

        return secondaryFilterValues;
    };

    updateSecondaryQueryValues = memoizeOne(async (): Promise<void> => {
        if (!this.shouldApplyTabsAsSecondaryFilter) {
            return null;
        }

        const filter = this.props.storage.data.filterQuery;
        let secondaryFilterValues: ITabValue[];

        if (this.state.tabSettings?.withoutCounts) {
            secondaryFilterValues = [];
        } else if (!filter?.isInvalid) {
            secondaryFilterValues = await this.getSecondaryTabsCount(filter?.secondaryTabsValuesQuery);
        }

        if (secondaryFilterValues) {
            this.setState({
                secondaryFilterValues
            });

            this.forceUpdate();
        }
    }, () => [this.props.storage.data.uuid, this.shouldApplyTabsAsSecondaryFilter, this.state.tabSettings?.withoutCounts, this.props.storage.data.filterQuery]);

    // updateSecondaryQueryValues is memoized,
    // this will clear its cache and call it
    forceUpdateSecondaryQueryValues = (): Promise<void> => {
        this.updateSecondaryQueryValues.reset();

        return this.updateSecondaryQueryValues();
    };

    reloadTableAndTabsValues = async (): Promise<[void, void]> => {
        return await Promise.all([
            this.props.storage.tableAPI.reloadTable(),
            this.forceUpdateSecondaryQueryValues()
        ]);
    };

    performFilterChangeSync = (): void => {
        this.props.storage.applyFilters();
        this.updateSecondaryQueryValues();
        this.forceUpdate();
    };

    performFilterChange = debounce(() => {
        this.performFilterChangeSync();
    }, INPUT_DEBOUNCE_TIME);

    handleFilterChange = (args: ISmartFieldChange, storeVariantOnChange = true): void => {
        if (args) { // can be called without args, just to refresh filter query value from already changed filter data
            this.props.storage.handleFilterChange(args, storeVariantOnChange);
        }
        this.props.storage.refreshFields();
        this.performFilterChange();
    };

    handleExpandFinish = (): void => {
        // when we only filter one or two values in table and the width of the window is small
        // SimpleBar won't show when the filter bar is expanded and user can't scroll to the bottom of the page
        // => manually call recalculate
        this._scrollBarInstanceRef.current?.recalculate();
    };

    getFilter(): IFilterQuery {
        const currentQuery = this.props.storage.data.filterQuery;

        return getCachedFilter(currentQuery, this.props.storage.data.customSecondaryFilter);
    }

    // prepared to be overridden
    getCustomButtonDef(type: TableButtonsActionType, args: ICustomButtonDefArgs): IToolbarItem {
        return null;
    }

    // prepared to be overridden
    overrideDefaultToolbarButtonDef(defaultItem: IToolbarItem): IToolbarItem {
        return defaultItem;
    }

    getCustomPrintTextListGroups(): ITextListGroup[] {
        const tab = [...(this.state.tabSettings.data ?? []), ...this.state.tabSettings.additionalData].find(tab => tab.id === this.state.selectedSecondaryFilter);

        if (!tab || tab?.id === SECONDARY_FILTERS_ALL) {
            return [];
        }

        return [
            {
                label: `${this.props.storage.t("Common:General.SelectedTab")}`,
                subGroups: [{
                    values: [tab?.title?.toUpperCase() ?? this.state.selectedSecondaryFilter]
                }]
            }
        ];
    }

    get customToolbarItems(): IToolbarItem[] {
        // prepared to be overridden
        return null;
    }

    handlePdfExport = (): void => {
        // since the TableView is used for inheritance
        // it is more practical to use storage customData instead of state
        this.props.storage.setCustomData({
            isTableReady: false,
            showPdfExport: true
        });

        this.forceUpdate();
    };

    handleClearFilter(): boolean {
        const filtersChanged = this.props.storage.clearFilters();

        if (filtersChanged) {
            this.updateSecondaryQueryValues();
            this.forceUpdate();
        }

        return filtersChanged;
    }

    isCollapsible = (def: ISplitPageTableDef): boolean => {
        return !!def?.hierarchy;
    };

    get isExpandable(): boolean {
        const tableState = this.props.storage.tableAPI?.getState();

        return tableState?.allGroupStatus !== GroupStatus.Expanded;
    }

    expandAll = (): void => {
        this.props.storage.tableAPI.toggleAllGroups(GroupStatus.Expanded);
    };

    collapseAll = (): void => {
        this.props.storage.tableAPI.toggleAllGroups(GroupStatus.Collapsed);
    };

    handleGroupToggle = (): void => {
        this.forceUpdate();
    };

    async setTableAction(action: TableButtonsAction | string, active?: boolean, shouldDisableForm = true): Promise<void> {
        let tableAction = null;

        if (action === null) {
            active = false;
        }

        if (active) {
            tableAction = action;
        }

        if (shouldDisableForm) {
            this.props.onFormDisabledChange?.(active);
        }

        this.props.storage.data.tableAction = tableAction;
        this.props.onTableActionChange?.(tableAction);
        this.props.storage.refresh();
    }

    getRowActionType = (): RowAction => {
        return getRowActionFromTableAction(this.getTableAction());
    };


    isTableAction(action?: TableButtonsAction | string): boolean {
        const tableAction = this.getTableAction();

        if (action) {
            return tableAction === action;
        } else {
            return !!tableAction;
        }
    }

    getTableAction = (): TableButtonsAction | string => {
        return this.props.storage.tableAction;
    };

    getDisabledButtons(disableAll?: boolean): TableButtonsActionType[] {
        const tableAction = this.getTableAction();
        const disabledButtons = [];

        const isAddingNew = this.isAddingRow;

        if (tableAction || disableAll || isAddingNew) {
            [
                TableButtonsAction.Add,
                TableButtonsAction.Remove,
                TableButtonsAction.Lock,
                TableButtonsAction.MassEdit,
                TableButtonsAction.CSVExport,
                TableButtonsAction.XLSXExport,
                TableButtonsAction.Sorting,
                TableButtonsAction.Settings,
                TableButtonsAction.ToggleDraftView
            ].filter(action => action !== tableAction && (!isAddingNew || (action !== TableButtonsAction.Add))).forEach(action => {
                disabledButtons.push(action);
            });
        }

        if (this.props.storage.tableAPI?.getState().allGroupStatus === GroupStatus.Unknown || isAddingNew) {
            disabledButtons.push(TableButtonsAction.ExpandCollapseAll);
        }

        if (this.props.storage.tableAPI?.getState().rowCount === 0) {
            disabledButtons.push(
                TableButtonsAction.Remove,
                TableButtonsAction.MassEdit,
                TableButtonsAction.CSVExport,
                TableButtonsAction.XLSXExport,
                TableButtonsAction.ExpandCollapseAll,
                TableButtonsAction.Lock);
        }

        return disabledButtons;
    }

    async handleToolbarConfirm(): Promise<void> {
        this.setState({
            isTableActionInProgress: true
        });

        const refreshNeeded = await this.props.storage.tableAPI.confirmAction();

        // often, we need to reload tabs after confirming an action => clear cache
        this.updateSecondaryQueryValues.reset();

        await this.setTableAction(null);

        this.setState({
            isTableActionInProgress: false
        });

        if (refreshNeeded) {
            this.props.onFormRefreshNeeded();
        }
    }

    handleToolbarCancel(): void {
        if (this.isTableAction()) {
            this.props.storage.tableAPI.cancelAction();
            this.setTableAction(null);
            this.props.onFormDisabledChange?.(false);
            this.props.onActiveRowActionCountChange?.(0);
        }
    }

    isDraftView = (): boolean => {
        return isDraftView(this.props.storage);
    };

    getToolbarButtons(): TableButtonsActionType[] {
        const def = this.props.storage.data.definition;
        return [
            TableButtonsAction.Add,
            TableButtonsAction.Remove,
            ...(def.lockProperty ? [TableButtonsAction.Lock] : []),
            ...(def.massEditableDef && !isObjectEmpty(def.massEditableDef) ? [TableButtonsAction.MassEdit] : []),
            TableButtonsAction.CSVExport,
            TableButtonsAction.XLSXExport,
            TableButtonsAction.PdfExport,
            TableButtonsAction.Sorting,
            TableButtonsAction.Settings,
            ...(this.isCollapsible(def) ? [TableButtonsAction.ExpandCollapseAll] : [])
        ];
    }

    getStaticToolbarItems = (): TToolbarItem[] => null;

    getConfirmText(tableAction: TableButtonsAction | string): string | React.ReactElement {
        // todo this will show wrong number if toggle all checkbox is clicked and not all rows are loaded (when there are more rows than 100)
        const activeRowActionCount = this.props.storage.tableAPI?.getState().activeRows.size;

        const text = this.props.storage.t(`Common:TableAction.${tableAction}`);

        // we don't display counts when selecting only one row
        if (getRowActionFromTableAction(tableAction) === RowAction.Custom && this.getCustomRowAction()?.isSingleSelect) {
            return text;
        }

        return getConfirmationActionText(text, activeRowActionCount);
    }

    canConfirmToolbarAction = (): boolean => {
        return this.props.storage.tableAPI?.getState().changedRows.size > 0;
    };

    handleTableButtonClick(key: TableButtonsActionType, value: TValue): void {
        switch (key) {
            case TableButtonsAction.Add:
                this.handleAdd();
                break;
            case TableButtonsAction.Lock:
            case TableButtonsAction.MassEdit:
            case TableButtonsAction.Remove:
                if (!this.isTableAction(key)) {
                    this.setTableAction(key, true);
                }
                break;
            case TableButtonsAction.XLSXExport:
                handleExportButton(this.props.storage, ExportType.XLSX, this.getFilter()?.query);
                break;
            case TableButtonsAction.CSVExport:
                handleExportButton(this.props.storage, ExportType.CSV, this.getFilter()?.query);
                break;
            case TableButtonsAction.PdfExport:
                this.handlePdfExport();
                break;
            case TableButtonsAction.ExpandCollapseAll:
                if (this.isExpandable) {
                    this.expandAll();
                } else {
                    this.collapseAll();
                }
                break;
            case TableButtonsAction.Sorting:
                this.props.storage.setCustomData({
                    isSortingDialogOpen: true
                });
                this.props.storage.refresh();
                break;
            case TableButtonsAction.Settings:
                this.props.storage.setCustomData({
                    isCustomizationDialogOpen: true
                });
                this.props.storage.refresh();
                break;
            default:
                this.handleCustomAction(key, { value });
        }
    }

    // prepared to be overridden
    customToolbarContent = (): IToolbarItem[] => this.customToolbarItems;

    getCustomFilterBarProps(): Partial<ISmartFilterBarProps> {
        return {};
    }

    getActiveButtons(): TableButtonsActionType[] {
        const tableAction = this.getTableAction() as TableButtonsAction;
        return this.isAddingRow && !getQueryParameters()[QueryParam.DraftId] ? [TableButtonsAction.Add] : this.isTableAction() ? [tableAction] : null;
    }

    getSelectedRowsCount = (): number => {
        return this.props.storage.tableAPI?.getState()?.changedRows?.size;
    };

    getConfirmationButtonsProps = memoizeOne<[], IConfirmationButtonProps>(() => {
        const tableAction = this.getTableAction() as TableButtonsAction;

        if (!tableAction) {
            return null;
        }

        const isConfirmDisabled = !this.canConfirmToolbarAction();
        const isBothDisabled = this.state.isTableActionInProgress;
        const isLoadingCount = this.props.storage.tableAPI?.getState().loadingActionCount;

        return {
            confirmText: this.getConfirmText(tableAction),
            onCancel: this.handleToolbarCancel,
            onConfirm: this.handleToolbarConfirm,
            isDisabled: isConfirmDisabled || isBothDisabled,
            cancelButtonProps: isBothDisabled ? {
                isDisabled: true
            } : null,
            confirmButtonProps: isLoadingCount ? {
                isBusy: isLoadingCount
            } : null
        };
    }, () => {
        const tableAction = this.getTableAction() as TableButtonsAction;
        const tableState = this.props.storage.tableAPI?.getState();
        return [tableAction, this.getSelectedRowsCount(), this.state.isTableActionInProgress, this.getConfirmText(tableAction), tableState?.loadingActionCount];
    });

    renderToolbar(visibleButtons = this.getToolbarButtons()): React.ReactElement {

        return (
            <TableToolbar
                isDisabled={this.isToolbarDisabled}
                isExpandable={this.isExpandable}
                visibleButtons={visibleButtons}
                documentsCount={this.props.storage.tableAPI?.getState().rowCount}
                activeButtons={this.getActiveButtons()}
                disabledButtons={this.getDisabledButtons()}
                staticItems={this.getStaticToolbarItems()}
                getCustomButtonDef={this.getCustomButtonDef}
                overrideDefaultToolbarButtonDef={this.overrideDefaultToolbarButtonDef}
                onClick={this.handleTableButtonClick}
                confirmationButtons={this.getConfirmationButtonsProps()}>
                {this.customToolbarContent()}
            </TableToolbar>
        );
    }

    renderFilterBar(): React.ReactElement {
        return (
            <>
                <SmartDrillDownFilters storage={this.props.storage}/>
                <SmartFilterBarStyled
                    tableId={this.props.storage.id}
                    storage={this.props.storage}
                    isDisabled={this.isAddingNew}
                    shouldAutoSort
                    onClearFilter={this.handleClearFilter}
                    onFilterChange={this.handleFilterChange}
                    onExpandFinish={this.handleExpandFinish}
                    {...this.getCustomFilterBarProps()} />
            </>
        );
    }

    renderCustomizationDialog = (): ReactElement => {
        return this.props.storage.getCustomData().isCustomizationDialogOpen &&
            <TableCustomizationDialog storage={this.props.storage}
                                      title={this.props.storage.data.definition.title}/>;
    };

    renderSortingDialog = (): ReactElement => {
        return this.props.storage.getCustomData().isSortingDialogOpen &&
            <SmartTableSortingDialog storage={this.props.storage}/>;
    };

    renderTabs(tabData = this.state.tabSettings?.data): ReactElement {
        return (
            <StyledTableTabs
                onChange={this.handleTabChange}
                data={tabData}
                additionalData={this.state.tabSettings?.additionalData}
                additionalTabPrefix={this.state.tabSettings?.additionalTabPrefix}
                showSearchBoxInMenu={this.state.tabSettings?.showSearchBoxInMenu}
                selectWidth={this.state.tabSettings?.selectWidth}
                selectedTabId={this.state.selectedSecondaryFilter}
                values={this.state.secondaryFilterValues}
                isDisabled={this.isDraftView() || this.props.storage.isDisabled || this.isAddingNew}/>
        );
    }

    handleFormAfterSave(args: IAfterSaveEventArgs): void {
        // prepared for redefinition when some table view want to handle the event
    }

    handleTableLoad(): void {
        if (!this.props.storage.getCustomData().isTableReady) {
            this.props.storage.setCustomData({
                isTableReady: true
            });

            // todo: do we want to call this only for the first time? We can call it always and if someone want only for
            //  first time, he may check the isTableReady flag (if we change order of this method call and setting the flag)
            this.props.storage.getCustomData().customOnAfterLoadFn?.();
        }
        this.props.onTableLoad?.();
        // we need to rerender in case table has no data (toolbar items gets disabled)
        // -> needs to be done always, not only on first load, so if user deletes last item
        //    or add first item, toolbar gets disabled or stop being disabled
        this.forceUpdate();
    }

    handleToolbarRefreshNeeded = (): void => {
        this.forceUpdate();
    };

    handleRowSelect = (bindingContext: BindingContext, props?: IRowProps, modifiers?: IModifierKeys): void => {
        this.props.onRowSelect?.(bindingContext);
    };

    handleActiveRowActionCountChange = (count: number): void => {
        this.props.onActiveRowActionCountChange?.(count);
        // refresh would reload secondary query filters, we just need to rerender confirmation buttons
        this.forceUpdate();
    };

    onBeforeFetch = async (): Promise<void> => {
        // prepared to be overridden
    };

    rowsFactory(rows: IRow[]): IRow[] {
        // prepared to be overridden
        return rows;
    }

    isRowWithoutAction(rowId: TId, action: RowAction, row: IRow): boolean {
        // prepared to be overridden
        return null;
    }

    handleCustomAction(action: string, { update = true, value }: IHandleCustomActionArgs = {}): void {
        // prepared to be overridden
        this.setTableAction(action, true, true);
    }

    getChildColumns = memoizeOne(() => {
        return this.props.storage.data.definition.childColumns
            ?.filter(
                (col) => this.props.storage.data.mergedColumns.find(mergedColumn => {
                    return mergedColumn.id === col.id || mergedColumn.id.split("/").slice(-1)[0] === col.id;
                })
            );
    }, () => [this.props.storage.data.definition.childColumns, this.props.storage.data.mergedColumns]);

    handleSortChange = (sort: ISort[]): void => {
        handleSortChange(this.props.storage, sort);
    };

    getSelectedRows = memoizeOne(() => {
        const bc = this.props.storage.data.rowBindingContext;

        return bc ? [bc] : [];
    }, () => [this.props.storage.data.rowBindingContext]);

    getAdditionalProperties = memoizeOne((isDraft: boolean) => {
        return this.props.storage.data.definition.additionalProperties ?? [];
    }, () => [this.props.storage.data.definition.additionalProperties]);

    getDraftBindingContextMemoized = memoizeOne(() => {
        const draftDef = this.props.storage.data.definition.draftDef;
        return createBindingContext(draftDef.draftEntitySet, this.props.storage.oData.metadata);
    });

    handleAddingRowCancel = (): void => {
        this.props.onAddingRowCancel?.();
    };

    // !WARNING! none of the references can't change between renders (everything has to keep same reference - be memoized)
    // otherwise infinite re-render loop will happen (e.g. on pdf export)
    getTableSharedProps(): ISmartTableProps & { key: string } {
        const { storage } = this.props;
        const { definition } = storage.data;
        return {
            rowsFactory: this.rowsFactory,
            rowAction: this.getRowAction(),
            getRowIcon: this.getRowIcon,
            additionalProperties: this.getAdditionalProperties(this.isDraftView()),
            storage,
            formStorage: this.props.formStorage,
            onBeforeFetch: this.onBeforeFetch,
            onAddingRowCancel: this.handleAddingRowCancel,
            addingRow: !!getQueryParameters()[QueryParam.DraftId] || !!getQueryParameters()[QueryParam.Drafts] ? null : storage.data.addingRow,
            addingRowParent: this.props.formStorage?.data.customData?.Parent?.toString(),
            bindingContext: this.isDraftView() ? this.getDraftBindingContextMemoized() : storage.data.bindingContext,
            initialSortBy: storage.getInitialSortOrder(),
            onSortChange: this.handleSortChange,
            columns: storage.getMergedColumns(this.isDraftView()),
            // child columns don't have own columnDefinition that would add them into table configuration dialog
            // => use columns that are already add in mergedColumns
            // so far, only used in LabelsHierarchyDef
            childColumns: this.getChildColumns(),
            hierarchy: definition.hierarchy,
            filter: this.getFilter(),
            querySettings: storage.data.definition.querySettings,
            lockProperty: definition.lockProperty,
            key: storage.data.uuid,
            onRowSelect: this.handleRowSelect,
            onRemovedRows: this.props.onRemovedRows,
            onActiveRowActionCountChange: this.handleActiveRowActionCountChange,
            selectedRows: this.getSelectedRows(),
            hideDrilldown: this.props.hideDrilldown,
            tableId: definition.id,
            onAfterTableLoad: this.handleTableLoad,
            onToolbarRefreshNeeded: this.handleToolbarRefreshNeeded,
            onGroupToggle: this.handleGroupToggle,
            isForPrint: storage.getCustomData().showPdfExport,
            loadAll: storage.getCustomData().showPdfExport,
            passRef: this.tableRef,
            groupedRows: storage.getCustomData().groupedRows,
            groupBy: definition.groupBy,
            drilldown: this.props.drilldown
        };
    }

    handlePrintDialogClose = (): void => {
        this.props.storage.setCustomData({
            showPdfExport: false
        });

        this.forceUpdate();
    };

    renderPrintDialog = (): React.ReactElement => {
        if (!this.props.storage.getCustomData().showPdfExport) {
            return null;
        }

        return (
            <TablePrintDialog element={this.props.storage.getCustomData().isTableReady && this.tableRef.current}
                              onClose={this.handlePrintDialogClose}
                              storage={this.props.storage}
                              customTextListGroups={this.getCustomPrintTextListGroups()}
                              logActionDetail={this.getFilter()?.query}
            />
        );
    };

    renderDefaultDialogs(): React.ReactElement {
        return (
            <>
                {this.renderPrintDialog()}
                {this.renderCustomizationDialog()}
                {this.renderSortingDialog()}
            </>
        );
    }

    renderAlert = (): React.ReactElement => {
        const alert = this.props.storage.data.alert;
        const _close = () => {
            delete this.props.storage.data.alert;
            this.forceUpdate();
        };

        const action = alert?.action ?? AlertAction.Close;
        const position = alert?.position ?? AlertPosition.CenteredBottom;
        const isFullWidth = alert?.isFullWidth ?? (position === AlertPosition.Default);

        return (
            <>
                {alert &&
                    <TableAlert {...alert}
                                action={action}
                                onClose={_close}
                                onFadeEnd={_close}
                                isFullWidth={isFullWidth}
                                position={position}/>
                }
            </>
        );
    };

    renderSmartHeader(): React.ReactNode {
        return (
            <SmartHeaderStyled
                storage={this.props.storage}
                title={this.props.storage.data.definition.title}
                hotspotId={"tableHeader"}
                shouldHideVariant={!!this.props.storage.data.predefinedFilters || this.props.storage.data.definition.preventStoreVariant}/>
        );
    }

    renderTable(): React.ReactNode {
        return (
            <TableWrapper>
                <SmartTable {...this.getTableSharedProps()}/>
            </TableWrapper>
        );
    }

    getViewProps(): TRecordAny {
        return {};
    }

    render() {
        const viewProps = this.getViewProps();

        return (
            <>
                {this.shouldRenderBreadcrumbs && (
                    <BreadCrumbProvider back={this.props.back ?? this.props.storage?.initialHistoryState?.back}
                                        customBreadCrumbs={this.props.storage?.initialHistoryState?.breadCrumbs}
                                        removeLast={!!this.props.storage.data.rowBindingContext}/>
                )}
                <View scrollProps={{ ref: this._scrollBarInstanceRef }}
                      {...viewProps}
                      key={viewProps?.key}
                      hotspotContextId={this.props.storage.id}>
                    <PortalRootElementProvider>
                        <TopWrapper _loaded={this.isLoaded()}>
                            {this.renderSmartHeader()}
                            {this.renderFilterBar()}
                            {this.renderAlert()}
                            {this.hasTabs && this.renderTabs()}
                            {this.renderToolbar()}
                        </TopWrapper>
                        {this.renderTable()}
                    </PortalRootElementProvider>
                    {this.renderDefaultDialogs()}
                </View>
            </>
        );
    }
}

export default TableView;