import { Property, TEntityKey } from "./Metadata";

export interface IParsedEntityPath {
    path: string;
    key: TEntityKey;
    keyProp?: string;
}

export const parseEntityKey = (path: string): IParsedEntityPath => {
    const regExp = /(?<path>[^(]+)(\('?(?<key>[^)]+)'?\))?/;
    const matches = regExp.exec(path);

    let key = matches.groups?.key;
    let keyProp;

    if (key !== undefined && key !== null && key.indexOf("=") >= 0) {
        [keyProp, key] = key.split("=");
    }

    key = key?.replace(/'/g, "");

    return {
        path: matches.groups.path,
        key: key,
        keyProp
    };
};


const isOneOfTypes = (type: string | Property, testTypes: string[]) => {
    if (type instanceof Property) {
        type = type.getType().getName();
    }

    return testTypes.includes(type);
};

export function isNumericType(type: string | Property): boolean {
    return isOneOfTypes(type,["Byte", "SByte", "Int16", "Int32", "Int64", "Single", "Double", "Decimal"]);
}

export function isDateType(type: string | Property): boolean {
    return isOneOfTypes(type,["Date", "DateTimeOffset", "TimeOfDay"]);
}

export function isDateTimeType(type: string | Property): boolean {
    return isOneOfTypes(type,["DateTimeOffset"]);
}

export function isBooleanType(type: string | Property): boolean {
    return isOneOfTypes(type,["Boolean"]);
}

export function isStringType(type: string | Property): boolean {
    return isOneOfTypes(type, ["String", "Guid"]);
}