import styled, { css } from "styled-components/macro";
import { Theme } from "../../theme";
import { TicketMessageType } from "./TicketMessage.utils";
import { LocalStackOrder, T_BOLD_tiny, T_PLAIN_big, T_TITLE_4, T_TITLE_4_NORMAL } from "../../global.style";
import { ButtonGroup } from "../button";
import { VIEW_PADDING_VALUE } from "../../views/View.styles";
import TextArea from "../inputs/textArea";
import { Separator } from "../separator/Separator";
import { StyledInput, StyledTextArea } from "../inputs/textArea/TextArea.styles";
import { SeparatorLine } from "../separator/Separator.styles";
import Clickable from "../clickable";
import Switch from "../inputs/switch";
import { ColoredIcon } from "../icon";
import { DropArea } from "../fileUploader";

const ICON_SIZE = 38;
const MESSAGE_MARGIN = 24;
const BORDER_SIZE = 2;
const ICON_SIZE_WITH_BORDER = ICON_SIZE + 2 * BORDER_SIZE;
const STREAM_MAX_WIDTH = 1280;

interface IType {
    $type: TicketMessageType;
}

function getColorByType(type: TicketMessageType): keyof Theme {
    switch (type) {
        case TicketMessageType.Received:
            return "C_SPLIT_LAYOUT_BUBBLE";
        case TicketMessageType.Sent:
            return "C_BTN_4L_trans_bg_selected";
    }
}

function isReceived(type: TicketMessageType): boolean {
    return type === TicketMessageType.Received;
}

export const StyledMessage = styled.div<IType & { $grouped?: boolean }>`
    position: relative;
    margin-bottom: ${MESSAGE_MARGIN}px;
    padding: ${ICON_SIZE_WITH_BORDER / 2}px 50px 0;

    ${props => props.$grouped && css`
        margin-top: -${MESSAGE_MARGIN}px;
        padding-top: 18px;
    `};

    ${props => isReceived(props.$type) ? css`
        padding-right: 100px;
        align-self: flex-start;
    ` : css`
        padding-left: 100px;
        align-self: flex-end;
    `};
`;

export const MessageIconWrapper = styled.div<{ $isDisabled?: boolean } & IType>`
    position: absolute;
    top: 0;
    flex: 0 0 auto;
    width: ${ICON_SIZE}px;
    height: ${ICON_SIZE}px;
    overflow: hidden;

    ${props => isReceived(props.$type) ? css`
        left: 0;
    ` : css`
        right: 0;
    `};

    border: ${BORDER_SIZE}px solid ${props => props.theme[getColorByType(props.$type)]};
    border-radius: 50%;
    background-color: ${props => props.theme.C_BG_menu};
    ${props => props.$isDisabled && css`opacity: .5`};

    svg {
        background-color: ${props => props.theme.C_BG_menu};
    }
`;

export const CenteredIcon = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    // in case 
    ${T_TITLE_4};
    text-transform: uppercase;
    color: ${props => props.theme.C_TEXT_primary};
`;

const CONTENT_HOR_PADDING = 16;

export const ContentWrapper = styled.div<IType>`
    position: relative;
    display: inline-block;
    ${T_TITLE_4_NORMAL};
    padding: 12px ${CONTENT_HOR_PADDING}px;
    max-width: 640px;
    background-color: ${props => props.theme[getColorByType(props.$type)]};

    ${props => isReceived(props.$type) ? css`
        border-radius: 3px 20px 20px 20px;
    ` : css`
        border-radius: 20px 3px 20px 20px;
    `};
`;

export const MessageSubject = styled.h3`
    ${T_TITLE_4};
    margin-bottom: 12px;
`;

export const Header = styled.div<{ isVisible: boolean } & IType>`
    position: absolute;
    top: -3px;
    transform: translate(0, -100%);

    ${T_BOLD_tiny};
    color: ${props => props.theme.C_ACT_main};
    visibility: ${props => props.isVisible ? 'visible' : 'hidden'};
    min-width: fit-content;
    white-space: nowrap;

    ${props => isReceived(props.$type) ? css`
        left: ${CONTENT_HOR_PADDING}px;
    ` : css`
        right: ${CONTENT_HOR_PADDING}px
    `};
`;

export const TicketMessageStreamStyled = styled.div`
    position: relative;
    height: 100%;
    min-width: 100%;
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
`;

export const TicketMessagesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    max-width: ${STREAM_MAX_WIDTH}px;
`;

export const UploadedFilesStyled = styled.div`
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 10px 0 10px 16px;
`;

export const TicketMessageAttachmentsStyled = styled.div`
    margin-top: 16px;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
`;

export const ButtonGroupStyled = styled(ButtonGroup)`
    gap: 12px;
    flex: 1 0 0;
`;

export const InputWrapper = styled.div`
    flex: 1 1 100%;
    margin-right: 12px;
    padding-top: 6px;
    min-width: 220px;
`;

const MessageInputStyles = css`
    background: transparent;
    border: none;
    padding-left: 16px;
`;

export const SubjectInput = styled(TextArea)`
    ${StyledInput} {
        ${MessageInputStyles};

        &, &::placeholder {
            font-weight: bold;
        }
    }
`;

export const SeparatorStyled = styled(Separator)`
    margin: 6px 0 6px 16px;

    ${SeparatorLine} {
        height: 1px;
    }
`;

export const MessageInput = styled(TextArea)`
    ${StyledTextArea} {
        ${MessageInputStyles};
    }
`;

export const ClickableStyled = styled(Clickable)`
    margin: 0 3px;

    ${ColoredIcon} {
        display: inline-block;
        vertical-align: text-bottom;
        color: ${props => props.theme.C_TEXT_primary};
    }
`;

export const MessageFileStyled = styled.span`
    display: inline-flex;
    ${T_PLAIN_big};
    margin-right: 3px;
    flex-wrap: nowrap;
    white-space: nowrap;

    &:not(:last-child)::after {
        content: ",";
    }
`;

export const TicketMessageInputStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    z-index: ${LocalStackOrder.AboveContent};
    margin: 6px ${VIEW_PADDING_VALUE}px 0;
    max-width: ${STREAM_MAX_WIDTH}px;
    flex-wrap: wrap;
    gap: 24px;
`;

export const TicketMessageInputBackground = styled.div`
    position: relative;
    flex: 1 1 auto;

    ${DropArea} {
        display: flex;
        align-items: flex-end;
        flex-wrap: nowrap;
        padding: 3px;
        border-radius: 22px;
    }
    border-radius: 22px;
    background-color: ${props => props.theme.C_BG_menu};
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.12);
    min-height: 44px; // todo: remove
`;

export const SwitchStyled = styled(Switch)`
    flex: 0 0 auto;
`;