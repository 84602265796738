import React from "react";
import { MultipleFileSelectionWrapper } from "./Inbox.styles";
import NoPreviewAnt from "../../components/fileViewers/NoPreviewAnt";
import i18next from "i18next";

interface IProps {
    count: number;
}

const InboxMultipleFilesSelected: React.FC<IProps> = ({ count }) => {
    return (
            <MultipleFileSelectionWrapper>
                <NoPreviewAnt text={i18next.t("Inbox:MultipleFilePlaceholder")}/>
            </MultipleFileSelectionWrapper>
    );
};

export default InboxMultipleFilesSelected;