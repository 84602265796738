import React from "react";
import { withAlert } from "@components/alert/withAlert";
import { TitleStyled } from "@components/readOnlyList/ReadOnlyList.styles";
import PairingWithAssetTableView, {
    IPairingAssetTableViewProps,
    PairingAssetTableViewAlertProps
} from "../PairingWithAssetTableView";
import { Status } from "../../../enums";
import { IReturnValue, pairDocumentItemsWithAsset, unpairDocumentItemsFromAsset } from "../Asset.utils";

interface IProps extends IPairingAssetTableViewProps {
    renderedTable?: React.ReactElement;
    entryIds?: number[];
}

class PairingFixedAssetTableView extends PairingWithAssetTableView<IProps> {

    handleToolbarConfirm = async (): Promise<void> => {
        const { formStorage, storage, entryIds } = this.props;
        const rows = await storage.tableAPI.getAffectedRows();
        let result: IReturnValue;

        const assetBc = rows?.[0]?.bc;
        const selectedKey = assetBc?.getKey();
        const pairedKey = this._selectedItem?.getKey();
        const openedAssetDetailKey = formStorage?.data.bindingContext.getKey();
        const selectionChanged = selectedKey !== pairedKey;

        const openedAssetDetailFormIsGoingToChange = openedAssetDetailKey && selectionChanged
                && (openedAssetDetailKey === pairedKey || openedAssetDetailKey === selectedKey);

        // there could be opened detail of currently selected asset
        //  -> set the form to busy and reload after adding the new items, so the form is actual
        if (openedAssetDetailFormIsGoingToChange) {
            formStorage.setBusy();
        }

        if (pairedKey && selectionChanged) {
            // unpair items from the previously selected asset
            result = await unpairDocumentItemsFromAsset(storage.oData, entryIds, this._selectedItem);
        }

        if (selectedKey && selectionChanged) {
            // new asset was selected -> pair items with the asset
            result = await pairDocumentItemsWithAsset(entryIds, selectedKey);
        }

        if (openedAssetDetailFormIsGoingToChange) {
            // we don't need to wait for reload, storage reload also sets the form to not busy
            formStorage.reload({ preserveInfos: true, withoutBusy: false, preserveData: false });
        }

        const { alert } = result;

        this.props.setAlert(alert);
        if (alert.status === Status.Success) {
            // store BindingContext of the asset with paired items, so we can unpair if user unselects the asset
            this._selectedItem = assetBc;
            this.setTableAction(null);
            this.forceUpdate();
        }
    };

    getConfirmText = () => this.props.storage.t("FixedAsset:Unorganized.Complete");

    renderHeader() {
        const storage = this.props.rootStorage;

        return (<>
            <TitleStyled>{storage.t("FixedAsset:Unorganized.SelectedRecords")}</TitleStyled>
            {this.props.renderedTable}
            <TitleStyled>{storage.t("FixedAsset:Unorganized.CompleteTitle")}</TitleStyled>
        </>);
    }
}

export default withAlert(PairingAssetTableViewAlertProps)(PairingFixedAssetTableView);
