import React from "react";
import { ICashBoxEntity } from "@odata/GeneratedEntityTypes";
import { WithTranslation, withTranslation } from "react-i18next";
import { TableStorage } from "../../../model/TableStorage";
import { ISmartFieldChange } from "../smartField/SmartField";
import BindingContext from "../../../odata/BindingContext";
import CheckboxFilter from "../../checkboxFilter/CheckboxFilter";
import { CashBoxAccountNumber, CashBoxBalance, SmartCashBoxFilterStyled, Title } from "./SmartCashBoxFilter.styles";
import { formatCurrency } from "../../../types/Currency";
import {
    ISmartBankAccountFilterCustomData,
    toggleCompanyFilterValue
} from "../smartBankAccountFilter/SmartBankAccountFilter.utils";
import { LastImport } from "../../bankAccountCheckbox/BankAccountCheckbox.styles";
import { ColoredIcon, LastUpdatedIcon } from "../../icon";
import { IconSize } from "../../../enums";
import DateType from "../../../types/Date";
import { isAccountAssignmentCompany } from "@utils/CompanyUtils";
import Tooltip from "../../tooltip";

interface IProps extends WithTranslation {
    storage: TableStorage<unknown, ISmartBankAccountFilterCustomData>;
    className?: string;
    onChange: (args: ISmartFieldChange) => void;
    bindingContext?: BindingContext;
    isDisabled?: boolean;
    ref?: React.Ref<React.ReactNode>;
}


class SmartCashBoxFilter extends React.Component<IProps> {
    handleChange = (id: number) => {
        const values = this.props.storage.getValue(this.props.bindingContext, { useDirectValue: false }) || [];
        toggleCompanyFilterValue(values, id);

        this.props.onChange?.({
            value: values,
            bindingContext: this.props.bindingContext
        });
    };

    render() {
        const values: number[] = this.props.storage.getValue(this.props.bindingContext, { useDirectValue: false }) || [];
        const isDisabled = this.props.storage.isDisabled || this.props.isDisabled;
        const visibleCashBoxes: ICashBoxEntity[] = this.props.storage.getCustomData().CurrentBankAccountFilterAccounts ?? [];
        const hasAccount = isAccountAssignmentCompany(this.props.storage.context);
        return (
                <SmartCashBoxFilterStyled>
                {visibleCashBoxes.map(cashBox => {
                    const isChecked = !values.find((val: number) => val === cashBox.Id);
                    const formattedBalance = formatCurrency(cashBox.Balance?.TransactionBalance, cashBox.TransactionCurrencyCode);
                    return (
                            <CheckboxFilter
                                    isDisabled={isDisabled}
                                    key={cashBox.Id}
                                    checked={!!isChecked}
                                    onChange={() => {
                                        this.handleChange(cashBox.Id);
                                    }}
                            >
                                <Tooltip content={cashBox.Name}
                                         onlyShowWhenChildrenOverflowing={true}>
                                    {(ref) => {
                                        return (<Title ref={ref}>{cashBox.Name}</Title>);
                                    }}
                                </Tooltip>
                                {hasAccount &&
                                        <CashBoxAccountNumber>211{cashBox.BalanceSheetAccountNumberSuffix}</CashBoxAccountNumber>}
                                <CashBoxBalance>{formattedBalance}</CashBoxBalance>
                                <LastImport>
                                    {!cashBox.IsActive ? this.props.t("Components:BankAccountCheckbox.Inactive") : (
                                            <>
                                                <ColoredIcon><LastUpdatedIcon width={IconSize.XS}/></ColoredIcon>
                                                {DateType.localFormat(cashBox.Balance?.DateBalanceChanged, DateType.defaultDateTimeFormat)}
                                            </>
                                    )}
                                </LastImport>
                            </CheckboxFilter>
                    );
                })}
            </SmartCashBoxFilterStyled>
        );
    }
}

const SmartCashBoxFilterWithData = withTranslation(["Components"], { withRef: true })(SmartCashBoxFilter);
export { SmartCashBoxFilterWithData as SmartCashBoxFilter };