import { ITicketMessage } from "@components/ticketMessage/TicketMessage.utils";
import { TEntityKey } from "@odata/BindingContext";
import { ITicketEntity } from "@odata/GeneratedEntityTypes";
import { TicketStatusCode, WebSocketMessageTypeCode } from "@odata/GeneratedEnums";
import { TWebsocketMessage } from "@utils/websocketManager/Websocket.types";
import { debounce } from "lodash";
import React from "react";

import TicketMessageStream from "../../components/ticketMessage/TicketMessageStream";
import TestIds from "../../testIds";
import WebsocketManager from "../../utils/websocketManager/WebsocketManager";
import { FormStorage } from "../../views/formView/FormStorage";
import { currentUserIsCustomer } from "../admin/users/Users.utils";
import { checkTicketMessageNotification, getStoredMessages, markThreadAsRead } from "./Tickets.utils";

interface IProps {
    storage: FormStorage<ITicketEntity>;
    onCreateMessage: (message: ITicketMessage, files: File[]) => Promise<boolean>;
    onRefreshTicketNeeded: (unreadFlag?: boolean) => void;
}

interface IState {
    id?: TEntityKey;
    messages: ITicketMessage[];
}

export default class SmartTicketMessageStream extends React.Component<IProps, IState> {
    state: IState = {
        messages: []
    };

    _cancelWebsocketSubscription: () => void;

    componentDidMount() {
        this.setMessagesFromStorage();

        const { storage } = this.props;
        if (!storage.data.bindingContext.isNew()) {
            this.markThreadAsRead();
        }

        this._cancelWebsocketSubscription = WebsocketManager.subscribe({
            callback: this.handleTicketNotification,
            types: [WebSocketMessageTypeCode.Notification]
        });
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>) {
        const { entity, bindingContext } = this.props.storage.data;
        const entityHasChanged = bindingContext.getKey() !== this.state.id && !this.props.storage.loading;
        const messageshasBeenUpdated = (entity.Messages?.length || this.state.messages.length) && entity.Messages?.length !== this.state.messages.length;
        if (messageshasBeenUpdated || entityHasChanged) {
            this.setMessagesFromStorage();
        }
        if (entityHasChanged) {
            // cancel debounced task and mark the new thread as read
            this.markThreadAsRead.cancel();
            this.markThreadAsRead();
        }
    }

    componentWillUnmount() {
        this.markThreadAsRead.cancel();
        this._cancelWebsocketSubscription();
    }

    markThreadAsRead = debounce(async (): Promise<void> => {
        const { storage } = this.props;
        if (await markThreadAsRead(storage)) {
            // refresh table row
            this.props.onRefreshTicketNeeded?.(true);
        }
    }, 3000);

    handleTicketNotification = async (message: TWebsocketMessage): Promise<void> => {
        const { storage } = this.props;
        const {
            shouldRefreshStream,
            ticketId
        } = await checkTicketMessageNotification(message, storage.oData);
        if (shouldRefreshStream && ticketId === this.state.id) {
            await storage.reload({ preserveInfos: true, withoutBusy: true });
        }
    };

    handleCreate = async (message: ITicketMessage, files: File[]): Promise<boolean> => {
        return !!(await this.props.onCreateMessage(message, files));
    };

    handleSwitchTicket = async (): Promise<void> => {
        const { storage } = this.props;
        storage.data.entity.TicketStatusCode = storage.data.entity.TicketStatusCode === TicketStatusCode.Done ? TicketStatusCode.InProgress : TicketStatusCode.Done;
        await storage.save();
        this.props.onRefreshTicketNeeded?.();
        this.forceUpdate();
    };

    setMessagesFromStorage(): void {
        const { storage } = this.props;
        this.setState({
            messages: getStoredMessages(storage),
            id: storage.data.bindingContext.getKey()
        });
    }

    render() {
        const { storage } = this.props;
        const { entity, bindingContext } = storage.data;
        const _currentUserIsCustomer = currentUserIsCustomer(storage.context);

        return (
                <TicketMessageStream messages={this.state.messages}
                                     onCreateMessage={this.handleCreate}
                                     isDone={entity.TicketStatusCode === TicketStatusCode.Done}
                                     isCustomer={_currentUserIsCustomer}
                                     canClose={!bindingContext.isNew() && !_currentUserIsCustomer}
                                     onSwitch={this.handleSwitchTicket}
                                     data-testid={TestIds.TicketMessageStream}/>
        );
    }
}
