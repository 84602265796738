import React from "react";
import {
    BASIC_HEIGHT,
    Body,
    Content,
    ContentWrapper,
    ExpandArea,
    ExpandAreaLine,
    HalfCircle,
    Line,
    MIN_HEIGHT_FOR_COLLAPSIBLE,
    MOUSE_CATCHER_HEIGHT,
    MouseCatcher,
    Overlay,
    StyledCollapsibleContent,
    StyledIconButton,
    StyledMoreOptionsIcon
} from "./CollapsibleContent.styles";
import { IconSize } from "../../enums";
import TestIds from "../../testIds";
import { withTranslation, WithTranslation } from "react-i18next";
import { FOCUSABLE_ELEMENTS_SELECTORS, isDefined, TabIndex } from "@utils/general";
import { WithDomManipulator, withDomManipulator } from "../../contexts/domManipulator/withDomManipulator";
import CustomResizeObserver from "../customResizeObserver";

/** Component can be used as both controlled or uncontrolled.
 * Either set "isCollapsed" and "onCollapsed" props, or let component handle it in state */
interface IProps {
    // some cases require move start of collapsing effect down
    topMargin?: number;
    isCollapsed?: boolean;
    onCollapsed?: () => void;
    // can be used to set default state for uncontrolled usage
    defaultIsCollapsed?: boolean;
    // fires when transition is over (f.e. scrollbar needs to be recalculated)
    onCollapsingEnd?: () => void;
}

interface IState {
    // directs showing/hiding of the blurring filter (due to the transition delays, it requires special handling)
    showFilter: boolean;
    // if content is too small, we don't show collapsible stuff
    isCollapsible: boolean;
    // for transitions, we need to calculate max height
    maxHeight: number;
    // collapsed state for uncontrolled usage of the component
    isCollapsed: boolean;
    // true during the transition between isCollapsed changes
    isCollapseStateChanging: boolean;
}

class CollapsibleContent extends React.Component<IProps & WithTranslation & WithDomManipulator, IState> {
    private _refContent = React.createRef<HTMLDivElement>();

    static defaultProps: Partial<IProps> = {
        topMargin: 0
    };

    constructor(props: IProps & WithTranslation & WithDomManipulator) {
        super(props);

        this.state = {
            showFilter: true,
            isCollapsible: true,
            maxHeight: 0,
            isCollapsed: props.defaultIsCollapsed,
            isCollapseStateChanging: false
        };
    }

    get isControlled(): boolean {
        return isDefined(this.props.isCollapsed);
    }

    get isCollapsed(): boolean {
        return this.props.isCollapsed ?? this.state.isCollapsed;
    }

    componentDidMount(): void {
        this.update();
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
        if (prevProps.isCollapsed !== this.props.isCollapsed || prevState.isCollapsed !== this.state.isCollapsed) {
            this.setState({
                isCollapseStateChanging: true
            });
        }

        if (prevState.isCollapseStateChanging !== this.state.isCollapseStateChanging) {
            this.update();
        }
    }

    isCollapsible = (): boolean => {
        return this._refContent.current?.offsetHeight > MIN_HEIGHT_FOR_COLLAPSIBLE;
    };

    update = () => {
        this.updateState();
        this.updateFocusables();
    };

    updateFocusables = (): void => {
        this.props.domManipulatorOrchestrator.registerCallback(
            () => {
                const items = this._refContent.current.querySelectorAll(FOCUSABLE_ELEMENTS_SELECTORS.join(", "));
                const itemsTop = (Array.from(items) as HTMLElement[]).map(item => item.getBoundingClientRect().bottom);
                const contentTop = this._refContent.current.getBoundingClientRect().top;

                return { items, itemsTop, contentTop };
            },
            ({ items, itemsTop, contentTop }) => {
                const hiddenPartTop = contentTop + BASIC_HEIGHT + this.props.topMargin - MOUSE_CATCHER_HEIGHT;

                for (let i = 0; i < items.length; i++) {
                    const item = items[i] as HTMLElement;
                    const itemTop = itemsTop[i];
                    const isTabbable = !this.isCollapsed || itemTop < hiddenPartTop;

                    item.tabIndex = isTabbable ? 0 : TabIndex.TemporaryDisabled;
                }
            },
            [this._refContent]
        );
    };

    updateState = () => {
        this.props.domManipulatorOrchestrator.registerCallback(
            () => {
                const isCollapsible = this.isCollapsible();
                const maxHeight = this.getMaxHeight();

                return { isCollapsible, maxHeight };
            },
            ({ isCollapsible, maxHeight }) => {
                if (isCollapsible !== this.state.isCollapsible) {
                    this.setState({
                        isCollapsible
                    });
                }


                if (maxHeight !== this.state.maxHeight) {
                    this.setState({
                        maxHeight
                    });
                }

                this.setState({
                    showFilter: isCollapsible && this.isCollapsed
                });
            },
            [this._refContent]
        );
    };

    getMaxHeight = (): number => {
        return !this.isCollapsed || !this.isCollapsible() ? this._refContent.current?.offsetHeight : BASIC_HEIGHT + this.props.topMargin;
    };

    handleTransitionEnd = (): void => {
        this.setState({
            isCollapseStateChanging: false
        });

        this.props.onCollapsingEnd?.();
    };

    handleCollapseChange = (): void => {
        if (this.isControlled) {
            this.props.onCollapsed();
        } else {
            this.setState({
                isCollapsed: !this.state.isCollapsed
            });
        }
    };

    handleContentResize = () => {
        this.updateState();
    };

    render() {
        return (
            <StyledCollapsibleContent data-testid={TestIds.CollapsibleContent}>
                {this.isCollapsed && this.state.showFilter && this.state.isCollapsible &&
                    <Overlay/>
                }
                <Body>
                    <ContentWrapper
                        _maxHeight={this.state.maxHeight}
                        isCollapseStateChanging={this.state.isCollapseStateChanging}
                        onTransitionEnd={this.handleTransitionEnd}>
                        <Content ref={this._refContent} data-testid={TestIds.Content}>
                            <CustomResizeObserver onResize={this.handleContentResize}/>
                            {this.props.children}
                        </Content>
                    </ContentWrapper>
                    {this.isCollapsed &&
                        <MouseCatcher onClick={this.handleCollapseChange}/>
                    }
                    {this.state.isCollapsible &&
                        <ExpandArea
                            _isCollapsed={this.isCollapsed}>
                            <ExpandAreaLine onClick={this.handleCollapseChange}>
                                <Line/>
                                <HalfCircle>
                                    <StyledIconButton
                                        title={this.props.t(`Common:General.${this.isCollapsed ? "Open" : "Close"}`)}
                                        isDecorative
                                        testid={TestIds.ExpandButton}>
                                        <StyledMoreOptionsIcon _isCollapsed={this.isCollapsed}
                                                               width={IconSize.M}/>
                                    </StyledIconButton>
                                </HalfCircle>
                            </ExpandAreaLine>
                        </ExpandArea>
                    }
                </Body>
            </StyledCollapsibleContent>
        );
    }
}

const ExtendedCollapsibleContent = withDomManipulator(withTranslation(["Common"])(CollapsibleContent));

export { ExtendedCollapsibleContent as CollapsibleContent };