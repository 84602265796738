import styled from "styled-components/macro";
import { AnimationSize, MouseAnim } from "../../../animations/Animations";


export const EmptyBaseConditionsPlaceholder = styled.div`
    margin: 0 auto !important;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const MouseAnimStyled = styled(MouseAnim)`
    width: ${AnimationSize.S}px;
    height: ${AnimationSize.S}px;
`;