import ModalBackdrop from "@components/dialog/ModalBackdrop";
import { CaretIcon } from "@components/icon";
import { BasicSelect } from "@components/inputs/select/BasicSelect";
import { Direction, ISelectionChangeArgs, ISelectItem } from "@components/inputs/select/Select.types";
import { getValue } from "@utils/general";
import { KeyboardShortcut } from "@utils/keyboardShortcutsManager/KeyboardShorcutsManager.utils";
import KeyboardShortcutsManager from "@utils/keyboardShortcutsManager/KeyboardShortcutsManager";
import memoizeOne from "@utils/memoizeOne";
import i18next from "i18next";
import React, { useCallback, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { useHistory } from "react-router-dom";

import { AppContext, IAppContext } from "../../contexts/appContext/AppContext.types";
import { DevtoolsMenuDefinition } from "../../devtools/Devtools.utils";
import { IconSize } from "../../enums";
import { KeyName } from "../../keyName";
import {
    getMainMenuDefinition,
    IMenuDefGroup,
    OrganizationSettingsMenuDefinition,
    SettingsDefinition
} from "../../menu-def";

const getItems = memoizeOne((context: IAppContext) => {
    const menuDefs = [...getMainMenuDefinition(context), ...SettingsDefinition, ...OrganizationSettingsMenuDefinition, DevtoolsMenuDefinition];

    return menuDefs.reduce((items: ISelectItem[], def: IMenuDefGroup) => {
        const newItems = def.items.map((item) => {
            const titleKey = getValue(item.title);
            return {
                id: item.url,
                label: i18next.t(titleKey),
                tabularData: [i18next.t(titleKey), item.key]
            };
        });
        return [...items, ...newItems];
    }, []);
}, () => []);

export const MenuRoutesSearch: React.FC = () => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [value, setValue] = React.useState(null);
    const _refInput = React.createRef<HTMLInputElement>();
    const history = useHistory();
    const context = useContext(AppContext);

    const handleDoubleShift = useCallback((shortcut: KeyboardShortcut, event: KeyboardEvent): boolean => {
        if (shortcut === KeyboardShortcut.SHIFT_SHIFT) {
            setValue(null);
            setIsOpen(true);

            return true;
        }

        return false;
    }, []);

    useEffect(() => {
        return KeyboardShortcutsManager.subscribe({
            shortcuts: [KeyboardShortcut.SHIFT_SHIFT],
            callback: handleDoubleShift
        });
    }, [handleDoubleShift]);

    React.useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent): void => {
            if (event.key === KeyName.Escape) {
                setIsOpen(false);
            }
        };

        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    React.useEffect(() => {
        if (isOpen) {
            _refInput.current?.focus();
        }
    }, [_refInput, isOpen]);

    const handleBlur = useCallback(() => {
        setIsOpen(false);
    }, []);

    const handleRouteSelect = (args: ISelectionChangeArgs): void => {
        if (args.triggerAdditionalTasks) {
            setValue(args.value);
        }

        if (args.triggerAdditionalTasks && ![Direction.Up, Direction.Down].includes(args.sharedData.scrollDirection)) {
            setIsOpen(false);
            history.push(args.value as string);
        }
    };

    if (!isOpen) {
        return null;
    }

    const search = (
        <ModalBackdrop isBlurred={true} isCentered={true}>
            <BasicSelect
                inputIcon={<CaretIcon width={IconSize.M}/>}
                inputRef={_refInput}
                value={value}
                items={getItems(context)}
                width={"240px"}
                onChange={handleRouteSelect}
                onBlur={handleBlur}
            />
        </ModalBackdrop>
    );

    const modalRoot = document.getElementById("hotspots-root");

    if (modalRoot) {
        return ReactDOM.createPortal(search, modalRoot);
    }
    return null;
};
