import styled from "styled-components/macro";
import { T_PLAIN_big } from "../../../global.style";
import { StyledIconButton } from "@components/button";
import { StyledText } from "@components/text/Text.styles";

export const CalendarFieldWrapper = styled.div<{
    isDifferentOddAndEvenWeek: boolean
}>`
    display: flex;
    flex-direction: ${props => props.isDifferentOddAndEvenWeek ? "row" : "column"};
    margin-right: 60px;
    margin-bottom: 40px;
    align-self: flex-start;
`;

export const AdditionalCalendarInfo = styled.div`
    ${T_PLAIN_big};
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    margin-top: 20px;
`;

export const WorkDayIntervalsFastEntryWrapper = styled.div`
    align-self: flex-start;
    min-width: 340px;

    ${StyledText} {
        min-width: 50px;
    }
`;

export const WorkDayIntervalsFastEntryHeader = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    ${StyledIconButton} {
        margin-left: 15px;
    }
`;

export const WeekContentAfter = styled.div`
    padding: 28px 13px;

    & > div:nth-of-type(2) {
        margin-top: 27px;
    }
`;