import styled, { css } from "styled-components/macro";
import { FIELD_VER_MARGIN_SMALL } from "../inputs/field/Field.styles";
import { getSemanticElColor, T_PLAIN_small, T_TITLE_4_NORMAL } from "../../global.style";
import { Status } from "../../enums";
import { InputStatusCommonCSS } from "../inputs/input/Input.styles";
import { VIRTUALIZED_LIST_CLASSNAME } from "./SortableList";

export const ORDER_BACKGROUND_WIDTH = 32;
export const ORDER_BACKGROUND_RIGHT_MARGIN = 5;
const EMPTY_ITEM_MARGIN_BOTTOM = 5;
export const EmptyChildLineNumberTopOffset = "0px";
export const EmptyChildHeight = 33;

export const STYLED_SORTABLE_LIST_OFFSET = `${ORDER_BACKGROUND_WIDTH + ORDER_BACKGROUND_RIGHT_MARGIN}px`;

export const StyledSortableList = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
`;

const OrderBackgroundStyles = css`
    position: absolute;
    // changed from bottom: 0, miraculously fixes https://solitea-cz.atlassian.net/browse/DEV-14895
    top: 0;
    border-top-right-radius: ${props => props.theme.borderRadius};
    border-bottom-right-radius: ${props => props.theme.borderRadius};

    width: ${`${ORDER_BACKGROUND_WIDTH}px`};
    flex: 0 0 auto;
    background-color: ${props => props.theme.C_SPLIT_LAYOUT_BUBBLE};
`;

export const OrderBackground = styled.div`
    ${OrderBackgroundStyles};

    & + ${StyledSortableList} {
        left: ${STYLED_SORTABLE_LIST_OFFSET};
        width: 100%;
    }
`;

interface IItemWrapper {
    useVirtualization?: boolean;
    showLineNumbers?: boolean;
    showIndicator?: boolean;
    isEmptyItem?: boolean;
    rowGap?: string;
}

export const ItemWrapper = styled.div<IItemWrapper>`
    position: relative;

    // use margin instead of row-gap on the parent to prevent problems with DnD
    // when virtualized, margin is already part of the computed item height
    margin-bottom: ${props => props.isEmptyItem ? `${EMPTY_ITEM_MARGIN_BOTTOM}px` : props.useVirtualization ? 0 : props.rowGap ?? FIELD_VER_MARGIN_SMALL};

    // 3px is needed to fit possible draft indication line
    padding-left: ${props => props.useVirtualization ? props.showLineNumbers ? STYLED_SORTABLE_LIST_OFFSET : "3px" : null};

    ${props => props.showIndicator && css<IItemWrapper>`&:before {
        ${InputStatusCommonCSS};
        top: 1px;
        content: "";
        height: ${props => props.useVirtualization ? `calc(100% - ${props.isEmptyItem ? EMPTY_ITEM_MARGIN_BOTTOM : parseInt(props.rowGap ?? FIELD_VER_MARGIN_SMALL)}px)` : "100%"};
        left: ${props => props.useVirtualization ? !props.showLineNumbers ? "0" : "32px" : "-5px"};
        background: ${getSemanticElColor(props.theme, Status.Warning)};
    }`}
`;

interface IItemIndex {
    $height?: string;
    $top?: string;
    isAbsolute?: boolean;
}

export const ItemIndex = styled.div.attrs((props: IItemIndex) => ({
    style: {
        height: props.$height ? props.$height : "auto",
        top: !props.isAbsolute ? null : props.$top,
        paddingTop: props.isAbsolute ? null : props.$top
    }
}))<IItemIndex>`
    position: ${props => props.isAbsolute ? "absolute" : "relative"};
    left: 5px;
    width: 20px;
    text-align: right;
    ${T_PLAIN_small};
    color: ${props => props.theme.C_ACT_thick_line};
`;

export const LineNumbersWrapper = styled.div`
    position: relative;
    display: flex;
`;

export const VirtualizedSortableListWrapper = styled.div<{ showLineNumbers: boolean; $height: string }>`
    flex: 1 1 auto;
    height: ${props => props.$height ?? "100%"};

    ${props => props.showLineNumbers && css`
        & .${VIRTUALIZED_LIST_CLASSNAME} > div {
            position: relative;

            &::before {
                content: "";
                ${OrderBackgroundStyles};
                height: 100%;
            }
        }
    `}
`;

export const EmptyItem = styled.div<{ isDraggable?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: ${EmptyChildHeight}px;
    // 12 for input left padding, 35 for drag button
    padding-left: ${props => 12 + (props.isDraggable ? 35 : 0)}px;

    font-style: italic;
    color: ${props => props.theme.C_SEM_el_warning};

    ${T_TITLE_4_NORMAL};
`;