import { EntitySetName, PrEmployeeEntity } from "@odata/GeneratedEntityTypes";

import { FieldType } from "../../../enums";
import BindingContext from "../../../odata/BindingContext";
import { IFormDef } from "../../../views/formView/Form";
import { IDefinition, IGetDefinition } from "../../PageUtils";

export const payslipEmployeePath = BindingContext.localContext("payslipEmployee");
export const payslipMonthYearPath = BindingContext.localContext("payslipMonthYear");

export const getDefinitions: IGetDefinition = (): IDefinition => {
    const form: IFormDef = {
        id: "PayslipSpecialForm",
        translationFiles: getDefinitions.translationFiles,
        additionalProperties: [],
        fieldDefinition: {
            [payslipEmployeePath]: {
                type: FieldType.ComboBox,
                label: "Zaměstnanec",
                fieldSettings: {
                    displayName: PrEmployeeEntity.LastName,
                    entitySet: EntitySetName.PrEmployees,
                    additionalProperties: [{ id: PrEmployeeEntity.FirstName }],
                    transformFetchedItems: (items, args) => {
                        return items.map(item => ({
                            id: item.additionalData[PrEmployeeEntity.Id],
                            label: `${item.additionalData[PrEmployeeEntity.LastName]} ${item.additionalData[PrEmployeeEntity.FirstName]}`
                        }));
                    }
                }
            },
            [payslipMonthYearPath]: {
                type: FieldType.MonthYear,
                label: "Měsíc"
            }
        },
        groups: [{ id: payslipEmployeePath }, { id: payslipMonthYearPath }]
    };

    return {
        form
    };
};

getDefinitions.translationFiles = ["Payslip", "Common"];
