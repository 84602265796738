import React, { useCallback, useState } from "react";
import { LoginSmallBottomClickable, LoginSmallBottomText } from "../LoginPage.styles";
import { useTranslation } from "react-i18next";
import useCounter from "../../hooks/useCounter";
import TestIds from "../../testIds";

interface IProps {
    text?: string;
    onResend: () => Promise<void>;
}

const ResendLink: React.FC<IProps> = ({ text, onResend }) => {
    const { t } = useTranslation("Login");

    const [isPending, setPending] = useState<boolean>(false);
    const [count, resetCounter] = useCounter(null);

    const handleResend = useCallback(async () => {
        setPending(true);
        await onResend();
        setPending(false);
        resetCounter(10);
    }, [onResend, resetCounter]);

    const isDisabled = isPending || count > 0;

    return (<>
        <LoginSmallBottomText data-testid={TestIds.BottomTextLabel}>{text ?? t("Common.ResendText")}</LoginSmallBottomText>
        <LoginSmallBottomClickable testId={TestIds.BottomTextLink} onClick={handleResend} isDisabled={isDisabled}>
            {t("Common.ResendLink")} {isDisabled && (<>
            ({count})
        </>)}
        </LoginSmallBottomClickable>
    </>);
};

export default ResendLink;