import styled, { css } from "styled-components/macro";
import { transparentize } from "polished";
import { StyledIconButton } from "../button";
import { CaretIcon } from "../icon";
import { LocalStackOrder } from "../../global.style";
import { PropsWithTheme, Theme } from "../../theme";

interface ISliderProps extends PropsWithTheme {
    collapsed: boolean;
    $isResizing: boolean;
    $borderColor?: keyof Theme;
}

const getBorderColor = (props: ISliderProps) =>
    props.$borderColor ? props.theme[props.$borderColor] : transparentize(0.7, props.theme.C_ACT_main);

export const Slider = styled.div<ISliderProps>`
    width: 15px;
    height: 100%;
    flex-shrink: 0;
    border-right: 2px solid transparent;
    box-sizing: border-box;

    position: absolute;
    z-index: ${LocalStackOrder.Overlay};
    right: 0;
    top: 0;
    border-right-color: ${props => props.collapsed ? getBorderColor(props) : "transparent"};

    ${StyledIconButton} {
        opacity: 1;
        position: absolute;
        top: 57px;
        right: -12px;
        width: 22px;
        height: 22px;
        box-sizing: border-box;
        background: ${props => props.theme.C_BG_menu};
        box-shadow: rgba(9, 30, 66, 0.08) 0 0 0 1px, rgba(9, 30, 66, 0.08) 0 2px 4px 1px;

        &:hover {
            background: ${props => props.theme.C_BG_menu};
        }
    }

    ${props => props.$isResizing
        ? css`
            ${StyledIconButton} {
                opacity: 1;
            }

            cursor: ew-resize;
            border-right-color: ${getBorderColor(props)};
        ` : (!props.collapsed && css`
                ${StyledIconButton} {
                    opacity: 0;
                }

                &:hover, &:focus-within {
                    ${StyledIconButton} {
                        opacity: 1;
                    }

                    cursor: ew-resize;
                    border-right-color: ${getBorderColor(props)};
                }
            `)}
`;

export const ExpandIcon = styled(CaretIcon)<{
    isRotated: boolean
}>`
    transform: ${props => props.isRotated ? "rotate(-90deg) translateY(1px)" : "rotate(90deg) translateY(1px)"};
`;
