import { IReadOnlyListItem } from "@components/readOnlyList/ReadOnlyListItem";
import { ActionType, addNewLineItem, ISmartFastEntriesActionEvent } from "@components/smart/smartFastEntryList";
import { ISmartFieldChange } from "@components/smart/smartField/SmartField";
import {
    IPrEmploymentEntity,
    IPrEmploymentExtraSalaryEntity,
    IPrEmploymentTemplateEntity,
    IPrEmploymentTemplateExtraSalaryEntity,
    IPrEmploymentTemplateSalaryComponentEntity,
    IPrExtraSalaryEntity,
    IPrSalaryComponentEntity,
    PrEmploymentEntity,
    PrEmploymentTemplateEntity,
    PrEmploymentTemporalEntity
} from "@odata/GeneratedEntityTypes";
import {
    handleCurrentTemporalPropChange,
    handleTemporalLineItemsActions,
    TemporalEntityProp,
    TTemporalLineItem
} from "@odata/TemporalUtils";
import React from "react";

import SelectionTableViewDialog from "../../../components/smart/SelectionTableViewDialog";
import { FormMode } from "../../../enums";
import BindingContext, { IEntity } from "../../../odata/BindingContext";
import { FormViewForExtend, IFormViewProps } from "../../../views/formView/FormView";
import { loadAndStoreRanges, setMatchingNumberRange } from "../../numberRange/NumberRange.utils";
import { IGetDefinition } from "../../PageUtils";
import {
    createDefaultLegislativeExtraSalaryItems,
    createNewExtraSalaryItemFromDefault,
    createNewSalaryComponentItemFromDefault,
    DialogType,
    ExtraSalaryTogglePath,
    initWithPayrollSettings,
    isLegislativeExtraSalaryItem,
    LegislativeExtraSalaryLocalPath,
    TEmploymentLineItemProp
} from "../employment/Employment.utils";
import { getDefinitionsFactory as getPickExtraSalaryDefinitionsFactory } from "../extraSalary/ExtraSalary.def";
import PickExtraSalaryTableView from "../extraSalary/PickExtraSalaryTableView";
import PickSalaryComponentTableView from "../salaryComponent/PickSalaryComponentTableView";
import {
    getDefinitionsFactory as getPickSalaryComponentDefinitionsFactory
} from "../salaryComponent/SalaryComponent.def";


type IEmploymentBaseEntity = IPrEmploymentTemplateEntity | IPrEmploymentEntity;

interface IProps extends IFormViewProps<IEmploymentBaseEntity, null> {
}

abstract class EmploymentBaseFormView<Props extends IProps> extends FormViewForExtend<IEmploymentBaseEntity, Props> {
    _dialogType: DialogType = null;

    constructor(props: Props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleLineItemsChange = this.handleLineItemsChange.bind(this);
        this.handleExtraSalarySelection = this.handleExtraSalarySelection.bind(this);
        this.handleSalaryComponentSelection = this.handleSalaryComponentSelection.bind(this);
    }

    onAfterLoad = async () => {
        const { storage } = this.props;
        const isAuditTrail = storage.formMode === FormMode.AuditTrail;
        const isNew = storage.data.bindingContext.isNew();


        if (!isAuditTrail) {
            const promises: Promise<unknown>[] = [];

            if (!storage.data.entity.NumberOurs) {
                promises.push(setMatchingNumberRange(storage, true));
            } else {
                promises.push(loadAndStoreRanges(storage));
            }

            storage.setValueByPath(LegislativeExtraSalaryLocalPath, false);
            const customExtraSalary = storage.getValueByPath(PrEmploymentEntity.ExtraSalaries)?.find((item: IPrEmploymentExtraSalaryEntity) =>
                    !isLegislativeExtraSalaryItem(item));
            storage.setValueByPath(ExtraSalaryTogglePath, !!customExtraSalary);

            if (isNew) {
                promises.push(createDefaultLegislativeExtraSalaryItems(storage));
                promises.push(initWithPayrollSettings(storage));
            }
            await Promise.all(promises);
        }

        return super.onAfterLoad();
    };

    handleNumberRangeChange = (e: ISmartFieldChange): void => {
        if (e.bindingContext.getPath() === "NumberRange") {
            this.props.storage.data.entity.NumberRange = e.value ? e.additionalData : null;
        }
    };

    handleWorkingPatternChange = (e: ISmartFieldChange): void => {
        if (e.bindingContext.getPath() === PrEmploymentTemporalEntity.WorkingPattern && e.triggerAdditionalTasks) {
            this.props.storage.refresh();
        }
    };

    handleChange(e: ISmartFieldChange): void {
        this.props.storage.handleChange(e);
        handleCurrentTemporalPropChange(e, this.props.storage);

        this.handleNumberRangeChange(e);

        const shouldUpdate = e.triggerAdditionalTasks !== false;
        this.props.storage.refreshFields(shouldUpdate);

        this.handleWorkingPatternChange(e);
    }

    handleLineItemsChange(e: ISmartFieldChange): void {
        this.props.storage.handleLineItemsChange(e);

        const lineItemBc = e.bindingContext.getParent().getParent();
        const lineItemTemporalBagBc = lineItemBc.navigate(TemporalEntityProp.TemporalPropertyBag);
        const temporalBagPath = lineItemTemporalBagBc.getNavigationPath(false, lineItemTemporalBagBc);
        handleCurrentTemporalPropChange(e, this.props.storage, temporalBagPath);

        const shouldUpdate = e.triggerAdditionalTasks !== false;
        this.props.storage.refreshFields(shouldUpdate);
    }

    handleSmartTemporalPropertyDialogChange(args: ISmartFieldChange): void {
        if (args.bindingContext.getPath() === PrEmploymentTemporalEntity.WorkingPattern) {
            this.props.storage.addActiveField(args.bindingContext.getParent().navigate(PrEmploymentTemporalEntity.LeaveDays));
        }
    }

    handleLineItemsAction = (args: ISmartFastEntriesActionEvent<IPrEmploymentTemplateExtraSalaryEntity | IPrEmploymentTemplateSalaryComponentEntity>): void => {
        const { storage } = this.props;

        if (args.actionType === ActionType.Custom) {
            switch (args.customActionType) {
                case DialogType.SalaryComponent:
                case DialogType.ExtraSalary:
                    // const { storage } = this.props;
                    this._dialogType = args.customActionType as DialogType;
                    break;

                default:
                    throw new Error(`Unknown custom actionType ${args.customActionType}`);
            }
            this.forceUpdate();
        } else {
            handleTemporalLineItemsActions(storage, args as ISmartFastEntriesActionEvent<TTemporalLineItem>);
        }
    };

    handleExtraSalarySelection(selected: BindingContext[], entities: IPrExtraSalaryEntity[]): void {
        const { storage } = this.props;

        entities.forEach((entity, idx) => {
            const newExtraSalary = createNewExtraSalaryItemFromDefault(entity);
            addNewLineItem(storage, PrEmploymentTemplateEntity.ExtraSalaries, PrEmploymentTemplateEntity.ExtraSalaries, [], newExtraSalary);
        });

        storage.refresh();
    }

    handleSalaryComponentSelection(selected: BindingContext[], entities: IPrSalaryComponentEntity[]): void {
        const { storage } = this.props;

        entities.forEach((entity: IPrSalaryComponentEntity, idx) => {
            const newSalaryComponent = createNewSalaryComponentItemFromDefault(entity);
            addNewLineItem(storage, PrEmploymentTemplateEntity.SalaryComponents, PrEmploymentTemplateEntity.SalaryComponents, [], newSalaryComponent);
        });

        storage.refresh();
    }

    handleCloseDialog = (): void => {
        this._dialogType = null;
        this.forceUpdate();
    };

    abstract getHeaderData: () => IReadOnlyListItem[];

    abstract get readOnlyListTitle(): string;

    getAlreadySelectedItemIds(propName: TEmploymentLineItemProp): number[] {
        const { entity } = this.props.storage.data;
        const ids: number[] = [];
        entity[propName]?.forEach(comp => {
            if (comp?.Default?.Id) {
                ids.push(comp?.Default?.Id);
            }
        });
        return ids;
    }

    renderDialog(): React.ReactElement {
        const { storage } = this.props;
        let tableView;
        let getDef: IGetDefinition;
        let onSelection: (selected: BindingContext[], entities: IEntity[]) => void;

        switch (this._dialogType) {
            case DialogType.SalaryComponent:
                tableView = PickSalaryComponentTableView;
                getDef = getPickSalaryComponentDefinitionsFactory(true, this.getAlreadySelectedItemIds(PrEmploymentEntity.SalaryComponents));
                onSelection = this.handleSalaryComponentSelection;
                break;

            case DialogType.ExtraSalary:
                tableView = PickExtraSalaryTableView;
                getDef = getPickExtraSalaryDefinitionsFactory(true, this.getAlreadySelectedItemIds(PrEmploymentEntity.ExtraSalaries));
                onSelection = this.handleExtraSalarySelection;
                break;

            default:
                // possible wrong configuration or no dialog configured
                return null;
        }
        return (
                <SelectionTableViewDialog storage={storage}
                                          tableView={tableView}
                                          getDefinition={getDef}
                                          title={this.readOnlyListTitle}
                                          headerData={this.getHeaderData()}
                                          onSelection={onSelection}
                                          onClose={this.handleCloseDialog}
                                          isSingleSelect={false}/>
        );
    }

    render() {
        const content = super.render();

        return (<>
            {content}
            {this.renderDialog()}
        </>);
    }
}

export default EmploymentBaseFormView;