import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { INumberRangeWildcardEntity } from "@odata/GeneratedEntityTypes";
import { MandatoryForDocumentsText, NumRangeHelpTooltipStyled } from "./numRangeWithProgressBar.styles";
import { AppContext } from "../../contexts/appContext/AppContext.types";
import { isAccountAssignmentCompany } from "@utils/CompanyUtils";

interface IProps extends WithTranslation {
    wildcards: INumberRangeWildcardEntity[];
}

export class NumRangeHelpTooltip extends React.PureComponent<IProps> {
    static contextType = AppContext;

    static defaultProps: Partial<IProps> = {
        wildcards: []
    };

    get hasAccounting(): boolean {
        return isAccountAssignmentCompany(this.context);
    }

    render() {
        let wildcards = this.props.wildcards;
        if (!this.hasAccounting) {
            wildcards = wildcards.filter(wc => wc.IsFiscalYearDependent === false);
        }

        return (<NumRangeHelpTooltipStyled>
            <table>
                <thead>
                <tr>
                    <th>{this.props.t("NumberRange:HelpTooltip.Wildcard")}</th>
                    <th>{this.props.t("NumberRange:HelpTooltip.Content")}</th>
                </tr>
                </thead>
                <tbody>
                {wildcards.map((card, index) => (
                    <tr key={index}>
                        <td>{card.Code}</td>
                        <td>{card.Name}</td>
                    </tr>
                ))}
                </tbody>
            </table>
            {this.hasAccounting && <>
                <hr/>
                <MandatoryForDocumentsText>
                    {this.props.t("NumberRange:HelpTooltip.MandatoryForDocuments")}
                </MandatoryForDocumentsText>
            </>}
        </NumRangeHelpTooltipStyled>);
    }
}

export default withTranslation(["NumberRange"])(NumRangeHelpTooltip);