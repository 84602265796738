import {
    Condition,
    ConditionType,
    PredefinedFilter
} from "@components/conditionalFilterDialog/ConditionalFilterDialog.utils";
import { getTableIntentLink } from "@components/drillDown/DrillDown.utils";
import { formatDateToDateString, isSameMonth } from "@components/inputs/date/utils";
import { EMPTY_DASH } from "@components/readOnlyList/ReadOnlyList";
import { IGetValueArgs } from "@components/smart/FieldInfo";
import { TFormTable } from "@components/smart/smartFormGroup/SmartFormGroup";
import { fetchReportTableData, IFetchReportTableDataArgs } from "@components/smart/smartTable/SmartReportTable.utils";
import { ICellValueObject } from "@components/table";
import { getRouteByDocumentType } from "@odata/EntityTypes";
import {
    CompanyEntity,
    CompanyVatReducedCoefficientEntity,
    DocumentEntity,
    EntitySetName,
    EntityTypeName,
    ICompanyEntity,
    ICompanyVatReducedCoefficientEntity,
    IDocumentEntity,
    IElectronicSubmissionEntity
} from "@odata/GeneratedEntityTypes";
import {
    ClearedStatusCode,
    DocumentTypeCode,
    ElectronicSubmissionTypeCode,
    PostedStatusCode,
    VatStatementFrequencyCode,
    VatStatementStatusCode
} from "@odata/GeneratedEnums";
import { getEnumDisplayValue } from "@odata/GeneratedEnums.utils";
import { OData } from "@odata/OData";
import { IFormatOptions } from "@odata/OData.utils";
import { DOCUMENT_JOURNAL_PATH, DocumentJournalProps } from "@pages/reports/documentJournal/DocumentJournalDef";
import {
    VAT_CONTROL_STATEMENT_REPORT_PATH
} from "@pages/reports/vatControlStatementReport/VatControlStatementReportDef";
import { VatReportProps } from "@pages/reports/VatReport.utils";
import { VAT_STATEMENT_REPORT_PATH } from "@pages/reports/vatStatementReport/VatStatementReportDef";
import { VAT_VIES_STATEMENT_REPORT_PATH } from "@pages/reports/vatVIESStatementReport/VatVIESStatementReportDef";
import { isAccountAssignmentCompanyValue, isCashBasisAccountingCompany } from "@utils/CompanyUtils";
import { TFetchFn } from "@utils/oneFetch";
import { Dayjs } from "dayjs";
import i18next from "i18next";

import { IAppContext } from "../../contexts/appContext/AppContext.types";
import { LogicOperator, ReportTableRowType, Sort } from "../../enums";
import { TValue } from "../../global.types";
import BindingContext from "../../odata/BindingContext";
import {
    ROUTE_DOCUMENT_JOURNAL,
    ROUTE_VAT_CONTROL_STATEMENT_REPORT,
    ROUTE_VAT_STATEMENT_REPORT,
    ROUTE_VAT_VIES_STATEMENT_REPORT
} from "../../routes";
import DateType, { DATE_MAX, DATE_MIN, DateFormat, getUtcDate, getUtcDayjs } from "../../types/Date";
import { VatStatementPeriod } from "../companies/Company.utils";
import { CommonReportProps, getStatusFilterId } from "../reports/CommonDefs";
import { IReportData, ReportColumnType, ReportFilterNodeColumnType, ReportNodeOperator } from "../reports/Report.utils";

export interface IVatSubmissionCountArgs {
    submission?: IElectronicSubmissionEntity;
    from?: Date;
    to?: Date;
    drillDown?: boolean;
}

interface IGetDocumentJournalFilterDef {
    id: string;
    value: TValue;
    type: ReportColumnType;
}

export const PREVIOUS_SUBMISSIONS_TABLE_ID = "ElectronicSubmissionsTabsTable";

export function getDocumentJournalStatusFilter(statusType: EntityTypeName.VatStatementStatus | EntityTypeName.ClearedStatus | EntityTypeName.PostedStatus, values: (ClearedStatusCode | VatStatementStatusCode | PostedStatusCode)[]): IGetDocumentJournalFilterDef {
    return {
        id: BindingContext.localContext("DocumentStatus"),
        value: [{
            type: ConditionType.Included,
            filter: PredefinedFilter.Value,
            condition: Condition.Equals,
            value: values.map(code => getStatusFilterId(statusType, code))
        }],
        type: ReportColumnType.String
    };
}

export type TVatSubmissionDocumentsData = Partial<Record<ElectronicSubmissionTypeCode, number>>;

interface IGetVatSubmissionReportArgs {
    type: ElectronicSubmissionTypeCode;
    isLocked: boolean;
    isCorrective?: boolean;
    isDrilldown?: boolean;
}

export const getVatSubmissionReportArgs = ({
                                               type,
                                               isLocked,
                                               isCorrective,
                                               isDrilldown
                                           }: IGetVatSubmissionReportArgs) => {
    let path: string;
    const vatStatementStatus: string[] = [];

    vatStatementStatus.push(isLocked ? VatStatementStatusCode.Filed : VatStatementStatusCode.NotFiled);

    if (isLocked || isCorrective) {
        vatStatementStatus.push(VatStatementStatusCode.FiledAndModified);
    }

    switch (type) {
        case ElectronicSubmissionTypeCode.VatStatement:
            path = isDrilldown ? ROUTE_VAT_STATEMENT_REPORT : VAT_STATEMENT_REPORT_PATH;
            break;
        case ElectronicSubmissionTypeCode.VatControlStatement:
            path = isDrilldown ? ROUTE_VAT_CONTROL_STATEMENT_REPORT : VAT_CONTROL_STATEMENT_REPORT_PATH;
            break;
        case ElectronicSubmissionTypeCode.VatVIESStatement:
            path = isDrilldown ? ROUTE_VAT_VIES_STATEMENT_REPORT : VAT_VIES_STATEMENT_REPORT_PATH;
            break;
    }

    return {
        path, value: vatStatementStatus
    };
};

export const getDocumentJournalReportArgs = ({
                                                 type,
                                                 isLocked,
                                                 isCorrective,
                                                 isDrilldown
                                             }: IGetVatSubmissionReportArgs) => {
    const vatStatementStatus: string[] = [];

    vatStatementStatus.push(isLocked ? VatStatementStatusCode.Filed : VatStatementStatusCode.NotFiled);

    if (isLocked || isCorrective) {
        vatStatementStatus.push(VatStatementStatusCode.FiledAndModified);
    }

    let columnName: string;

    switch (type) {
        case ElectronicSubmissionTypeCode.VatStatement:
            columnName = "VatStatementStatus_Name";
            break;
        case ElectronicSubmissionTypeCode.VatControlStatement:
            columnName = "VatControlStatementStatus_Name";
            break;
        case ElectronicSubmissionTypeCode.VatVIESStatement:
            columnName = "VatViesStatementStatus_Name";
            break;
    }

    return {
        path: isDrilldown ? ROUTE_DOCUMENT_JOURNAL : DOCUMENT_JOURNAL_PATH,
        value: vatStatementStatus,
        columnName
    };
};

interface IGetVatSubmissionDocumentsDataArgs {
    subArgs: IVatSubmissionCountArgs;
    type: ElectronicSubmissionTypeCode;
    isLocked: boolean;
    isCorrective?: boolean;
    fetchFn?: TFetchFn;
}

export async function getVatSubmissionDocumentsData(args: IGetVatSubmissionDocumentsDataArgs): Promise<TVatSubmissionDocumentsData> {
    const { type, isLocked, isCorrective, subArgs } = args;
    const { path, value } = getVatSubmissionReportArgs({
        type, isLocked, isCorrective
    });
    const fetchArgs: IFetchReportTableDataArgs = {
        path,
        settings: {
            [BindingContext.cleanLocalContext(VatReportProps.vatStatementPeriod)]: {
                DateStart: subArgs.submission?.DatePeriodStart ?? subArgs?.from,
                DateEnd: subArgs.submission?.DatePeriodEnd ?? subArgs?.to
            },
            [VatReportProps.vatStatementStatus]: value
        },
        reportHierarchy: {
            Aggregate: false,
            Columns: [
                { ColumnAlias: "Document_NumberOurs" }
            ],
            Groups: [],
            Aggregations: []
        }
    };

    const response = await fetchReportTableData(fetchArgs, args.fetchFn);


    const retVal: TVatSubmissionDocumentsData = {};

    if (response?.ok) {
        const data = (await response.json()) as IReportData;
        const uniqueIds = new Set<string>();

        (data.Rows ?? []).forEach((row) => {
            if (row.Type === ReportTableRowType.Value) {
                // one document can be in multiple rows, so we need to only count unique ids
                uniqueIds.add(row.Value.Document_NumberOurs as string);
            }
        });

        retVal[type] = uniqueIds.size;
    }

    return retVal;
}

export async function getDocumentJournalDocumentsData(args: IGetVatSubmissionDocumentsDataArgs): Promise<TVatSubmissionDocumentsData> {
    const { type, isLocked, isCorrective, subArgs } = args;
    const { path, value, columnName } = getDocumentJournalReportArgs({
        type, isLocked, isCorrective
    });
    const dateStart = subArgs.submission?.DatePeriodStart ?? subArgs?.from;
    const dateEnd = subArgs.submission?.DatePeriodEnd ?? subArgs?.to;

    const fetchArgs: IFetchReportTableDataArgs = {
        path,
        settings: {
            [BindingContext.cleanLocalContext(CommonReportProps.dateRange)]: {
                DateStart: formatDateToDateString(DATE_MIN),
                DateEnd: formatDateToDateString(DATE_MAX)
            },
            [BindingContext.cleanLocalContext(DocumentJournalProps.dateClearingStatusTo)]: formatDateToDateString(getUtcDate()),
            Filter: {
                "Type": ReportFilterNodeColumnType.Logic,
                "Operator": LogicOperator.And,
                "Left": {
                    "Type": ReportFilterNodeColumnType.Logic,
                    "Operator": LogicOperator.And,
                    "Left": {
                        "Type": ReportFilterNodeColumnType.Date,
                        "Operator": ReportNodeOperator.GreaterOrEqual,
                        "Left": {
                            "ColumnAlias": "Document_DateDocumentVatStatement"
                        },
                        "Right": {
                            "Value": formatDateToDateString(getUtcDayjs(dateStart))
                        }
                    },
                    "Right": {
                        "Type": ReportFilterNodeColumnType.Date,
                        "Operator": ReportNodeOperator.LessOrEqual,
                        "Left": {
                            "ColumnAlias": "Document_DateDocumentVatStatement"
                        },
                        "Right": {
                            "Value": formatDateToDateString(getUtcDayjs(dateEnd))
                        }
                    }
                },
                "Right": {
                    "Type": ReportFilterNodeColumnType.Logic,
                    "Operator": LogicOperator.Or,
                    "Left": {
                        "Left": {
                            "ColumnAlias": columnName
                        },
                        "Right": {
                            // todo use enum id instead of label once its ready on backend
                            "Value": getEnumDisplayValue(EntityTypeName.VatStatementStatus, VatStatementStatusCode.FiledAndModified)
                        },
                        "Type": ReportFilterNodeColumnType.String,
                        "Operator": ReportNodeOperator.Equal
                    },
                    "Right": {
                        "Left": {
                            "ColumnAlias": columnName
                        },
                        "Right": {
                            // todo use enum id instead of label once its ready on backend
                            "Value": getEnumDisplayValue(EntityTypeName.VatStatementStatus, VatStatementStatusCode.NotFiled)
                        },
                        "Type": ReportFilterNodeColumnType.String,
                        "Operator": ReportNodeOperator.Equal
                    }
                }
            }
        },
        reportHierarchy: {
            Aggregate: false,
            Columns: [
                { ColumnAlias: "Document_NumberOurs" },
                { ColumnAlias: "Document_DateDocumentVatStatement" },
                { ColumnAlias: columnName }
            ],
            Groups: [],
            Aggregations: []
        }
    };


    const response = await fetchReportTableData(fetchArgs, args.fetchFn);


    const retVal: TVatSubmissionDocumentsData = {};

    if (response?.ok) {
        const data = (await response.json()) as IReportData;
        const uniqueIds = new Set<string>();

        (data.Rows ?? []).forEach((row) => {
            if (row.Type === ReportTableRowType.Value) {
                // one document can be in multiple rows, so we need to only count unique ids
                uniqueIds.add(row.Value.Document_NumberOurs as string);
            }
        });

        retVal[type] = uniqueIds.size;
    }

    return retVal;
}

export async function getVatSubmissionClearingRatio(oData: OData, context: IAppContext, year: number): Promise<ICompanyVatReducedCoefficientEntity> {
    const companyId = context.getCompanyId();
    const query = oData.getEntitySetWrapper(EntitySetName.Companies)
        .query(companyId)
        .select(CompanyEntity.UseVatReducedDeduction, CompanyEntity.Id)
        .expand(CompanyEntity.VatReducedCoefficients, q =>
            q.filter(`${CompanyVatReducedCoefficientEntity.Year} eq ${year}`).select(CompanyVatReducedCoefficientEntity.VatReducedCoefficient, CompanyVatReducedCoefficientEntity.Id));

    const result = await query.fetchData<ICompanyEntity>();
    const company = result.value;

    if (company.UseVatReducedDeduction) {
        return company.VatReducedCoefficients?.[0] ?? {
            Year: year,
            VatReducedCoefficient: 0
        };
    }
    return null;
}

export async function changeVatSubmissionClearingRatio(oData: OData, context: IAppContext, coeff: ICompanyVatReducedCoefficientEntity): Promise<void> {
    const companyId = context.getCompanyId();

    await oData.getEntitySetWrapper(EntitySetName.Companies).update(companyId, {
        [`${CompanyEntity.VatReducedCoefficients}@odata.delta`]: [coeff]
    });
}

export const getLiabilityDocumentTableDef = (context: IAppContext): TFormTable => {
    return {
        id: `LiabilityDocumentTable`,
        entitySet: EntitySetName.Documents,
        initialSortBy: [
            { id: "DateAccountingTransaction", sort: Sort.Asc }
        ],
        columns: [
            {
                id: DocumentEntity.DateAccountingTransaction,
                label: i18next.t("ElectronicSubmission:Tax.Date"),
                isVisible: isAccountAssignmentCompanyValue
            },
            {
                id: DocumentEntity.DateCbaDocument,
                label: i18next.t("ElectronicSubmission:Tax.Date"),
                isVisible: (args: IGetValueArgs) => isCashBasisAccountingCompany(args.context)
            },
            {

                id: DocumentEntity.DocumentType,
                fieldSettings: {
                    displayName: "Name"
                },
                label: i18next.t("ElectronicSubmission:Tax.DocumentType")
            },
            {
                id: DocumentEntity.NumberOurs,
                label: i18next.t("ElectronicSubmission:Tax.Document"),
                formatter: (val: TValue, args?: IFormatOptions<IDocumentEntity, IDocumentEntity>) => {
                    const { item } = args;
                    const { Id, NumberOurs } = item ?? {};

                    return Id ? getTableIntentLink(NumberOurs, {
                        route: `${getRouteByDocumentType(item.DocumentTypeCode as DocumentTypeCode)}/${Id}`,
                        context
                    }) : null;
                }
            },
            {
                id: DocumentEntity.Amount,
                label: i18next.t("ElectronicSubmission:Tax.Amount")
            }
        ]
    };
};

function isVatStatementPeriod(value: any): value is VatStatementPeriod {
    return value?.from && value?.to;
}

export function getVatSubmissionFrequencyFromPeriod(period: IElectronicSubmissionEntity | VatStatementPeriod): VatStatementFrequencyCode {
    let from: Date, to: Date;
    if (isVatStatementPeriod(period)) {
        from = period.from?.toDate();
        to = period.to?.toDate();
    } else {
        from = period.DatePeriodStart;
        to = period.DatePeriodEnd;
    }
    return isSameMonth(from, to) ? VatStatementFrequencyCode.Monthly : VatStatementFrequencyCode.Quarterly;
}

export function getVatSubmissionPeriodSortValue(date: Date | Dayjs, frequency: VatStatementFrequencyCode): TValue {
    if (!frequency || !date) {
        return DATE_MIN;
    }
    return getUtcDayjs(date).startOf(frequency === VatStatementFrequencyCode.Monthly ? "month" : "quarter").toDate();
}

export function getVatSubmissionPeriodTableCell(date: Date | Dayjs, frequency: VatStatementFrequencyCode, numeric?: boolean): ICellValueObject {
    if (!frequency || !date) {
        return { value: EMPTY_DASH, tooltip: EMPTY_DASH };
    }
    let value: string;
    const periodDate = getUtcDayjs(date);
    switch (frequency) {
        case VatStatementFrequencyCode.Quarterly:
            const quarter = periodDate.quarter();
            const year = periodDate.get("year");
            value = numeric ? `${periodDate.get("year")}/Q${periodDate.quarter()}`
                : i18next.t("ElectronicSubmission:VatSubmission.Quarter", { count: quarter, year, ordinal: true });
            break;
        case VatStatementFrequencyCode.Monthly:
            value = numeric ? periodDate.format("M/YY") : DateType.format(periodDate, DateFormat.monthAndYear);
            break;
    }
    return { value, tooltip: value };
}

export function getVatSubmissionPeriodName(date: Date | Dayjs, frequency: VatStatementFrequencyCode, numeric?: boolean): string {
    return getVatSubmissionPeriodTableCell(date, frequency, numeric).value as string;
}

export function isSubmissionLastPeriod(vatFrequency: VatStatementFrequencyCode, monthIndex: number): boolean {
    return vatFrequency === VatStatementFrequencyCode.Monthly ? monthIndex === 11 : monthIndex === 9;
}