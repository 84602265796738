import { EntityTypeName } from "@odata/GeneratedEntityTypes";
import { WithOData } from "@odata/withOData";
import { isDefined } from "@utils/general";
import React, { ReactElement } from "react";
import { WithTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";

import { NEW_ITEM_DETAIL } from "../constants";
import { AppContext, IAppContext } from "../contexts/appContext/AppContext.types";
import { QueryParam } from "../enums";
import { RequireKeys, TRecordAny, TRecordString } from "../global.types";
import BindingContext, { areBindingContextsDifferent, createBindingContext, TEntityKey } from "../odata/BindingContext";
import { getCorrectPath } from "../routes";
import { getQueryParameters } from "../routes/Routes.utils";
import memoizeOne from "../utils/memoizeOne";
import { IFormDef } from "../views/formView/Form";
import { getEntitySetFromDefinition } from "../views/formView/Form.utils";
import { ITableViewBaseProps } from "../views/table/TableView";
import { ISplitPageTableDef } from "../views/table/TableView.utils";
import { getDefByEntityType } from "./getDefByEntityType";
import { DRAFT_BC_PREFIX, getUniqBcSuffix, IDefinition, IPageProps } from "./PageUtils";
import { IReportTableDefinition } from "./reports/Report.utils";
import { SplitPage } from "./SplitPage";

export interface IPageParams {
    ParentId?: string;
    Id?: string;
}

export interface IProps<Params extends { [K in keyof Params]?: string; } = IPageParams> extends IPageProps, WithOData, WithTranslation, RouteComponentProps<Params> {
    getDef: (context?: IAppContext) => IDefinition;
    tableView?: React.ComponentType<ITableViewBaseProps>;
    hasPreview?: boolean;
    // indicates whether show prompt dialog when user wants to leave form without saving changes
    usePrompt?: boolean;
    auditTrailView?: ReactElement;
    dontUseUrlParams?: boolean;
    parentId?: TEntityKey;
}

export interface ISplitPagePaneData<Type = IFormDef | ISplitPageTableDef | IReportTableDefinition> {
    bc?: BindingContext;
    draftId?: string;
    def?: Type;
    entitySet?: string;
    customData?: TRecordAny;
}

export interface IPageState {
    parentId?: TEntityKey;
    childId?: TEntityKey;
}

export default class Page<P extends IProps, S extends IPageState = IPageState> extends React.Component<P, S> {
    static contextType = AppContext;
    static defaultProps: RequireKeys<Partial<IProps>, "getDef">;

    protected definition: IDefinition;
    protected currentEntityType: string;

    protected uuid = "";

    protected masterData: ISplitPagePaneData<ISplitPageTableDef> = {};
    protected detailData: ISplitPagePaneData<IFormDef> = {};

    constructor(props: P, context: IAppContext) {
        super(props);

        this.state = {
            ...this.state,
            parentId: props.parentId ?? null,
            childId: null
        };
        this.bindEvents();

        // currently, we always redirect to ROUTE_HOME on company change
        // and this.companyChange handler only causes problems with CompanyId param and browser history.
        // Uncomment and rewrite only if someone decides NOT to redirect to Home on company change again.
        // context.eventEmitter.on(ContextEvents.CompanyChanged, this.companyChange);
    }


    companyChange(id: number): void {
        // we don't want to leave new form when company is changed
        // or when its change to null (AppMode.OrganizationSettings)
        if (this.getKey() === NEW_ITEM_DETAIL || !id) {
            return;
        }

        this.context.setViewBreadcrumbs({
            items: this.context.getViewBreadcrumbs().items.slice(0, -1),
            lockable: false
        });

        this.detailData.bc = null;
        this.setUrl(null, null);
    }

    componentWillUnmount(): void {
        // this.context.eventEmitter.off(ContextEvents.CompanyChanged, this.companyChange);
    }


    bindEvents = (): void => {
        this.isReady = this.isReady.bind(this);
        this.handleRowSelect = this.handleRowSelect.bind(this);
        this.handleCloseDetail = this.handleCloseDetail.bind(this);
        this.handleAddDetail = this.handleAddDetail.bind(this);
        this.handleAfterSave = this.handleAfterSave.bind(this);
        this.companyChange = this.companyChange.bind(this);
        this.onAfterFormDefLoad = this.onAfterFormDefLoad.bind(this);
    };

    componentDidMount(): void {
        this.parseUrl();
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<Record<string, unknown>>): void {
        this.parseUrl();
    }

    parseUrl = (): void => {
        if (this.props.auditTrailView) {
            return;
        }
        let shouldUpdate = false;
        if (!this.props.tReady) {
            return;
        }

        // first time load once translation is ready
        if (!this.definition) {
            this.definition = this.props.getDef(this.context);
            this.masterData.def = this.definition.table;
            shouldUpdate = true;
        }

        const newBc = this.getBindingContextFromUrl();
        const isNewEntityType = this.setFormDefFromEntityType(newBc);

        // for changed entity type or new bc (typically row select) - call update
        if (isNewEntityType || areBindingContextsDifferent(newBc, this.detailData.bc)) {
            shouldUpdate = true;
            isNewEntityType && this.onAfterFormDefLoad();
        }

        this.detailData.bc = newBc;
        this.detailData.draftId = getQueryParameters()[QueryParam.DraftId];
        shouldUpdate && this.forceUpdate();
    };

    onAfterFormDefLoad(): void {
        /* Prepared for redefinition */
    }

    getParentKey = (): string => {
        if (this.props.dontUseUrlParams) {
            return this.state.parentId?.toString();
        }
        return this.props.match.params?.ParentId;
    };

    getKey = (): string => {
        if (this.props.dontUseUrlParams) {
            return this.state.childId?.toString();
        }
        return this.props.match.params.Id;
    };

    getEntitySet(): string {
        return getEntitySetFromDefinition(this.definition, this.context, this.getParentKey());
    }

    getTableViewContext = (): BindingContext => {
        return this.getTableViewContextMemoized(this.getEntitySet());
    };

    getTableViewContextMemoized = memoizeOne((entitySet: string) => {
        return createBindingContext(entitySet, this.props.oData.getMetadata());
    });

    getNewFormBindingContext = memoizeOne((parentId: string, childCollection?: string) => {
        if (parentId && childCollection) {
            return this.getTableViewContext().addKey(parentId).navigate(childCollection).addKey(NEW_ITEM_DETAIL, true);
        }
        const draftId = getQueryParameters()[QueryParam.DraftId];
        const draftKey = `${DRAFT_BC_PREFIX}${draftId}`;
        let newKey = draftId ? draftKey : NEW_ITEM_DETAIL;
        const { bc } = this.detailData;
        if (bc?.isNew() && bc?.getKey() !== draftKey) {
            newKey = `${newKey}${getUniqBcSuffix()}`;
        }
        return this.getTableViewContext().addKey(newKey, true);
    }, () => {
        const params = getQueryParameters();
        return [params[QueryParam.DraftId], params[QueryParam.Type]];
    });

    getChildCollectionForEditableParent = (): string => {
        return undefined;
    };

    getBindingContextFromUrl = (): BindingContext => {
        let ParentId: string;
        let Id: string;
        if (this.props.dontUseUrlParams) {
            ParentId = this.state.parentId?.toString();
            Id = this.state.childId?.toString();
        } else {
            ParentId = this.props.match.params?.ParentId;
            Id = this.props.match.params?.Id;
        }

        const entitySet = this.getEntitySet();
        const childCollection = this.getChildCollectionForEditableParent();

        if (isDefined(Id)) {
            if (Id === NEW_ITEM_DETAIL) {
                return this.getNewFormBindingContext(this.getParentKey(), childCollection);
            } else {
                if (isDefined(ParentId) && !!childCollection) {
                    return createBindingContext(`${entitySet}(${ParentId})/${childCollection}(${Id})`, this.props.oData.getMetadata());
                }
                return createBindingContext(`${entitySet}(${Id})`, this.props.oData.getMetadata());
            }
        } else {
            if (isDefined(ParentId) && !!childCollection) {
                if (ParentId === NEW_ITEM_DETAIL) {
                    return this.getNewFormBindingContext(null, childCollection);
                }

                return createBindingContext(`${entitySet}(${ParentId})`, this.props.oData.getMetadata());
            }
        }

        return null;
    };

    setFormDefFromEntityType = (bc: BindingContext): boolean => {
        if (bc) {
            const bcs = bc.getFullPathAsArrayOfContexts();
            if (bcs?.length > 0) {
                const entityType: string = bcs[bcs.length - 1].getEntityType().getName();
                if (entityType !== this.currentEntityType) {
                    this.currentEntityType = entityType;
                    const defByEntityType = getDefByEntityType(entityType as EntityTypeName);
                    const unwrappedDefinition = defByEntityType(this.context);
                    this.detailData.def = unwrappedDefinition.form;
                    return true;
                }
            }
        }
        return false;
    };

    setUrl = (entityKey?: string, parentKey?: string, searchData?: TRecordString, copyHistoryState?: boolean): void => {
        if (this.props.dontUseUrlParams) {
            this.setState({
                childId: entityKey,
                parentId: parentKey
            });
            return;
        }

        const pathname = getCorrectPath(this.preparePath(!!entityKey), {
            Id: entityKey,
            ParentId: parentKey
        });

        const queryParams = getQueryParameters();

        if (queryParams[QueryParam.PreventDraftSave]) {
            searchData = {
                ...searchData,
                [QueryParam.PreventDraftSave]: "true"
            };
        }

        if (queryParams.CompanyId && !searchData?.CompanyId) {
            searchData = {
                ...searchData,
                CompanyId: queryParams.CompanyId
            };
        }

        const search = new URLSearchParams(searchData).toString();

        // don't pointlessly add same url to history
        if (pathname !== this.props.history.location.pathname || search !== this.props.history.location.search) {
            if (copyHistoryState) {
                this.props.history.push({ pathname, search, state: this.props.history.location.state });
            } else {
                this.props.history.push({ pathname, search });
            }
        }
    };

    preparePath = (addEntityType: boolean): string => {
        let path = this.props.match.path;
        if (this.props.childCollection) {
            if (addEntityType) {
                if (!path.includes(`/${this.props.childCollection}/`)) {
                    path = path.replace(new RegExp(`:ParentId[?]?`, "g"), `:ParentId/${this.props.childCollection}`);
                }
            } else {
                path = path.replace(`/${this.props.childCollection}/`, "");
            }
        }

        return path;
    };

    getMandatoryChildParentProps = (): Record<string, unknown> => {
        return {};
    };

    handleRowSelect(bc: BindingContext, search?: TRecordString): void {
        if (typeof bc !== "string") {
            const key = bc.getKey();

            this.setUrl(key as string, undefined, search, true);
        } else {
            // NumberRange table, hierarchy rows have only string and are not selectable
        }
    }

    handleCloseDetail(): void {
        const queryParams = getQueryParameters();
        // newParams cannot be null, because it will be passed to urlSearchParams => use undefined instead
        const newParams = queryParams.drafts ? { drafts: queryParams.drafts } : undefined;

        this.setUrl(null, null, newParams);
    }

    handleAddDetail(type?: string): void {
        this.setUrl(NEW_ITEM_DETAIL);
    }

    handleAfterSave(bc: BindingContext): void {
        this.setUrl(bc.getKey() as string);
    }

    isSecondaryBookmarkActive = (): boolean => {
        const query = getQueryParameters();

        return !!query[QueryParam.SecondaryBookmarkActive];
    };

    getMandatoryProps() {
        this.masterData.entitySet = this.getEntitySet();

        return {
            key: this.context.getCompanyId() ?? "no-company",
            // need to duplicate wrapper object for correct prevProps in splitpage
            masterData: { ...this.masterData },
            detailData: { ...this.detailData },
            t: this.props.t,
            tableView: this.props.tableView,
            hasPreview: this.props.hasPreview,
            usePrompt: this.props.usePrompt,

            ...this.getMandatoryChildParentProps(),

            isSecondaryBookmarkActive: this.isSecondaryBookmarkActive(),

            onRowSelect: this.handleRowSelect,
            onCloseDetail: this.handleCloseDetail,
            onAddDetail: this.handleAddDetail,
            onAfterSave: this.handleAfterSave,
        };
    }

    isReady(): boolean {
        return this.props.tReady && !!this.definition && !(this.context as IAppContext).isLoadingCompany;
    }

    render() {
        if (this.props.auditTrailView) {
            return this.props.auditTrailView;
        }

        if (!this.isReady()) {
            return null;
        }

        return (
            <SplitPage
                {...this.getMandatoryProps()}
            />
        );
    }
}
