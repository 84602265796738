import Text from "@components/text";
import i18next from "i18next";
import React from "react";

import { SwitchType } from "../../components/inputs/switch/Switch";
import { IGetValueArgs } from "../../components/smart/FieldInfo";
import { IAppContext } from "../../contexts/appContext/AppContext.types";
import { BasicInputSizes, FieldType, LabelStatus, ValidatorType } from "../../enums";
import BindingContext, { createPath } from "../../odata/BindingContext";
import { IFormDef } from "../../views/formView/Form";
import { IDefinition, IGetDefinition } from "../PageUtils";

export const NewCustomerDescriptionPath = BindingContext.localContext("NewCustomerDescription");
export const NewCustomersSwitchPath = BindingContext.localContext("NewCustomersSwitch");

export const getDefinitions: IGetDefinition = (context: IAppContext): IDefinition => {
    const fakeUserPath = (path: string): string => {
        return createPath("Users", path);
    };

    const form: IFormDef = {
        id: "newCustomerSpecialForm",
        translationFiles: getDefinitions.translationFiles,
        fieldDefinition: {
            [NewCustomerDescriptionPath]: {
                labelStatus: LabelStatus.Removed,
                type: FieldType.Custom,
                render: () => {
                    return <Text>{i18next.t("Companies:Wizard.CustomerPortalAccessDescription").toString()}</Text>;
                }
            },
            [NewCustomersSwitchPath]: {
                type: FieldType.Switch,
                label: i18next.t("Companies:Wizard.NewCustomerSwitchLabel"),
                defaultValue: true,
                fieldSettings: {
                    type: SwitchType.YesNo
                }
            },
            [fakeUserPath("FirstName")]: {
                label: i18next.t("Companies:Wizard.FirstName"),
                isRequired: true,
                isCollectionField: true
            },
            [fakeUserPath("LastName")]: {
                label: i18next.t("Companies:Wizard.LastName"),
                isRequired: true,
                isCollectionField: true
            },
            [fakeUserPath("Email")]: {
                width: BasicInputSizes.L,
                label: i18next.t("Companies:Wizard.Email"),
                isRequired: true,
                isCollectionField: true,
                validator: {
                    type: ValidatorType.Email
                }
            },
        },

        groups: [{
            id: "main",
            rows: [
                [{ id: NewCustomerDescriptionPath }],
                [{ id: NewCustomersSwitchPath }]
            ]
        }, {
            id: "lineItems",
            rows: [],
            isVisible: (args: IGetValueArgs) => {
                return !!args.storage.getValueByPath(NewCustomersSwitchPath);
            },
            lineItems: {
                collection: "Users",
                order: "Id",
                canReorder: false,
                isItemCloneable: false,
                columns: [{
                    id: "FirstName"
                }, {
                    id: "LastName"
                }, {
                    id: "Email"
                }]
            }
        }]
    };

    return {
        form
    };
};

getDefinitions.translationFiles = ["Companies", "Common"];