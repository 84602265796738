import styled from "styled-components/macro";
import { multiline_ellipsis, T_PLAIN_small_hig } from "../../../global.style";
import DashboardTileBackground from "../../dashboard/DashboardTileBackground";
import Badge from "../../badge";

export const IconWidth = "85px";
export const IconHeight = "66px";

export const StyledLinkTile = styled(DashboardTileBackground)`

`;

export const BadgeStyled = styled(Badge)`
    transform: translateY(-50%);
    top: 0;
    right: 8px;
`;

export const StyledLinkTileTitleWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
`;

export const StyledLinkTileTitle = styled.div`
    ${T_PLAIN_small_hig};
    text-align: center;
    ${multiline_ellipsis("100%", 2)};
`;

export const IconWrapper = styled.div`

`;
