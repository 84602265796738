import {
    BankAccountEntity,
    EntitySetName,
    IPrInsuranceCompanyPredefinedEntity,
    PrInsuranceCompanyEntity,
    PrInsuranceCompanyPredefinedEntity,
    PrLifeInsuranceCompanyEntity
} from "@odata/GeneratedEntityTypes";
import { BasicInputSizes, FieldType, Status } from "../../../enums";
import { SwitchType } from "@components/inputs/switch/Switch";
import { TFieldsDefinition } from "../../PageUtils";
import { activeFormatter } from "../../chartOfAccounts/ChartOfAccountsDef";
import customFetch from "../../../utils/customFetch";
import { FormStorage } from "../../../views/formView/FormStorage";
import { FieldValidationNegativeIcon, FieldValidationPositiveIcon } from "@components/icon";
import { IFieldInfoProperties, IGetValueArgs } from "@components/smart/FieldInfo";
import i18next from "i18next";
import { PrInsuranceCompanyTypeCode, SelectionCode } from "@odata/GeneratedEnums";
import {
    BankAccountDependentFields,
    clearBankFields,
    getBankAccountFieldsDef,
    getBankBlurArgs,
    SAVED_ACCOUNTS_PATH,
    setSavedBankAccount,
    setSavedBankAccountItems
} from "../../banks/bankAccounts/BankAccounts.utils";
import { predefinedHealthInsuranceCompanyPath } from "./HealthInsuranceCompany.def";
import { isObjectEmpty } from "@utils/general";
import { ISmartFieldChange } from "@components/smart/smartField/SmartField";
import { handleBankAccountOrIBANBlur } from "@utils/BankUtils";
import memoize from "../../../utils/memoize";
import { DATABOX_REST_URL } from "../../../constants";
import { ISelectItem } from "@components/inputs/select/Select.types";

export const checkDataBoxUrl = `${DATABOX_REST_URL}/check/`;

export function getInsuranceCompaniesCommonFieldDef(): TFieldsDefinition {
    return {
        [PrInsuranceCompanyEntity.DataBoxId]: {},
        [PrInsuranceCompanyEntity.IsActive]: {
            type: FieldType.Switch,
            fieldSettings: {
                type: SwitchType.YesNo
            },
            defaultValue: true
        },
        [PrInsuranceCompanyEntity.BankAccount]: {
            type: FieldType.ComboBox
        },
        ...getTransformedBankAccountFieldsDef()
    };
}

const getTransformedBankAccountFieldsDef = (): TFieldsDefinition => {
    const fields = getBankAccountFieldsDef({
        addPaymentMethod: false,
        areFieldsDisabled: false,
        type: null
    });

    fields[SAVED_ACCOUNTS_PATH].isRequired = true;
    return fields;
};

export function getInsuranceCompaniesCommonColumnDef(): TFieldsDefinition {
    return {
        [PrInsuranceCompanyEntity.DataBoxId]: {},
        [PrInsuranceCompanyEntity.IsActive]: {
            formatter: activeFormatter
        }
    };
}

export const validateDataBox = async (storage: FormStorage): Promise<void> => {
    const dataBoxBc = storage.data.bindingContext.navigate(PrInsuranceCompanyEntity.DataBoxId);
    const dataBoxId = storage.getValue(dataBoxBc);
    const error = await storage.validateField(dataBoxBc);
    if (!error && dataBoxId) {
        storage.setAdditionalFieldData(dataBoxBc, "isBusy", true);
        storage.refreshField(dataBoxBc.getPath());


        const res = await customFetch(`${checkDataBoxUrl}/${dataBoxId}`);

        const validationResult = res.ok ? {
            status: Status.Success,
            message: storage.t("InsuranceCompanies:DataboxValidationSuccess"),
            icon: FieldValidationPositiveIcon
        } : {
            status: Status.Warning,
            message: storage.t("InsuranceCompanies:DataboxValidationError"),
            icon: FieldValidationNegativeIcon
        };

        storage.setAdditionalFieldData(dataBoxBc, "isBusy", false);
        storage.setAdditionalFieldData(dataBoxBc, "validationResult", validationResult);
    } else {
        storage.setAdditionalFieldData(dataBoxBc, "validationResult", null);
    }
    storage.refreshField(dataBoxBc.getPath());
};


export const getPredefinedCompanies = memoize(async (storage: FormStorage, type: PrInsuranceCompanyTypeCode): Promise<IPrInsuranceCompanyPredefinedEntity[]> => {
    const res = await storage.oData.getEntitySetWrapper(EntitySetName.PrInsuranceCompaniesPredefined)
            .query()
            .filter(`${PrInsuranceCompanyPredefinedEntity.PrInsuranceCompanyTypeCode} eq '${type}'`)
            .expand(PrInsuranceCompanyPredefinedEntity.BankAccounts, q => q.expand(BankAccountEntity.Country).expand(BankAccountEntity.Bank))
            .fetchData<IPrInsuranceCompanyPredefinedEntity[]>();

    return res.value;
});

export const getNameFieldDef = (type: PrInsuranceCompanyTypeCode): IFieldInfoProperties => ({
    type: FieldType.Autocomplete,
    fieldSettings: {
        itemsFactory: async (args: IGetValueArgs): Promise<ISelectItem[]> => {
            const companies = await getPredefinedCompanies(args.storage as FormStorage, type);
            return companies?.map(company => {
                return {
                    id: company.Name,
                    label: company.Name,
                    additionalData: company
                };
            });
        }
    },
    label: i18next.t(`InsuranceCompanies:Name`),
    width: BasicInputSizes.XL,
    useForValidation: true
});

export const handleInsuranceCompanyAfterLoad = async (storage: FormStorage, typeCode: PrInsuranceCompanyTypeCode): Promise<void> => {
    if (!storage.data.bindingContext.isNew()) {
        validateDataBox(storage);
        const companies = await getPredefinedCompanies(storage, typeCode);
        const company = companies.find(c => c.Name === storage.data.entity.Name);
        const bankAccounts = company?.BankAccounts?.length ? company.BankAccounts : [storage.data.entity.BankAccount];
        storage.setValueByPath(predefinedHealthInsuranceCompanyPath, company?.Code);
        setSavedBankAccountItems(storage, bankAccounts);
        setSavedBankAccount({
                    storage,
                    isEntityWithoutBP: true,
                    account: storage.data.entity.BankAccount
                }
                , bankAccounts);
    }
};

export const handleNameChange = (e: ISmartFieldChange, storage: FormStorage): void => {
    if (e.bindingContext.getPath() === PrLifeInsuranceCompanyEntity.Name && !isObjectEmpty(e.additionalData) &&
            e.triggerAdditionalTasks) {
        const predefinedData = e.additionalData as IPrInsuranceCompanyPredefinedEntity;
        const bankAccounts = predefinedData.BankAccounts ?? [];

        if (!isObjectEmpty(bankAccounts[0])) {
            setSavedBankAccountItems(storage, bankAccounts);
            storage.processDependentField(BankAccountDependentFields, bankAccounts[0]);
            setSavedBankAccount({
                        storage,
                        isEntityWithoutBP: true,
                        account: storage.data.entity.BankAccount
                    }
                    , bankAccounts);
        } else if (storage.data.bindingContext.isNew()) {
            // e.triggerAdditionalTasks means company was selected but is without bank account, therefore clear fields
            storage.clearAndSetValue(storage.data.bindingContext.navigate(SAVED_ACCOUNTS_PATH), null);
            setSavedBankAccountItems(storage, []);
            clearBankFields(storage, storage.data.bindingContext.navigate(PrInsuranceCompanyEntity.BankAccount));
        }

        storage.addActiveField(storage.data.bindingContext.navigate(SAVED_ACCOUNTS_PATH));

        storage.setValueByPath(PrLifeInsuranceCompanyEntity.DataBoxId, predefinedData?.DataBoxId);
        storage.refreshFields();
        validateDataBox(storage);
    }
};

export const handleInsuranceCompanyBankChange = (e: ISmartFieldChange, storage: FormStorage): void => {
    handleBankAccountOrIBANBlur(getBankBlurArgs(storage, e.bindingContext));
    if (e.bindingContext.getParent().getPath() === PrInsuranceCompanyEntity.BankAccount) {
        storage.clearAndSetValue(storage.data.bindingContext.navigate(SAVED_ACCOUNTS_PATH), SelectionCode.Own);
    }
};