import React from "react";
import { StyledUserInitial } from "./UserInitial.styles";
import { PropsWithTheme } from "../../theme";
import { withTheme } from "styled-components";

interface IProps {
    title: string;
    tooltip: string;
    isBig?: boolean;
    className?: string;
    style?: React.CSSProperties;
}

class UserInitial extends React.PureComponent<IProps & PropsWithTheme> {
    render() {
        return (
            <StyledUserInitial color={this.props.theme.C_LAB_blue}
                               title={this.props.title}
                               $isBig={this.props.isBig}
                               customTooltip={this.props.tooltip}
                               isRound
                               onlyShowWhenChildrenOverflowing={false}
                               className={this.props.className}
                               style={this.props.style}
            />
        );
    }
}

export default withTheme(UserInitial);