import styled, { css } from "styled-components/macro";
import {
    getDisabledStyle,
    getFocusBorderElement,
    LocalStackOrder,
    T_HEADER_small,
    T_PLAIN_big,
    T_PLAIN_small
} from "../../../../global.style";
import { StyledButton } from "../../../button";
import { COLOR_TRANSITION_TIME } from "../../../../constants";
import { StyledDay } from "./Day.styles";
import { transparentize } from "polished";
import { TextAlign } from "../../../../enums";
import { themes } from "../../../../theme";
import { TimePicker } from "../TimePicker";
import { StyledHtmlInput } from "../../input/Input.styles";

export const StyledDatePickerPopup = styled.div<{ embedded?: boolean; _height?: string; }>`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 291px;
    height: ${props => props._height ? props._height : "fit-content"};
    background: ${props => props.embedded ? "transparent" : props.theme.C_BG_dialogue};
    color: ${props => props.theme.C_TEXT_dialogue};
    padding: 0 19px 18px;

    & *::selection {
        background-color: ${props => transparentize(0.7, props.theme.C_TEXT_dialogue)};
    }
`;

export const StyledCalendar = styled.div`
    width: 100%;
    height: 100%;
`;

export const Header = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
`;

export const HeaderPart = styled.div<{ alignment: TextAlign; aboveContent?: boolean; }>`
    display: flex;

    position: ${props => props.alignment === TextAlign.Center ? "relative" : "absolute"};
    left: ${props => props.alignment === TextAlign.Left ? "-9px" : null};
    right: ${props => props.alignment === TextAlign.Right ? "-9px" : null};
    top: 11px;
    z-index: ${props => !props.aboveContent ? null : LocalStackOrder.AboveContent};

    ${props => props.alignment === TextAlign.Center && css`
        justify-content: center;
        width: 100%;
        height: 100%;
        top: 0;
    `};
`;

export const HeaderVerticalSplit = styled.span`
    opacity: 0.5;
    width: 1px;
    height: 17px;
    background-color: ${props => themes.light.C_TEXT_dialogue};
    position: absolute;
    top: 50%;
    left: 50%;
    // add 0.5px to get rid of weird subpixel blur artefact
    transform: translate(calc(-50% - 0.5px), -50%);
`;

export const DayLabels = styled.div<{ hasTopMargin?: boolean; }>`
    display: flex;
    user-select: none;
    margin-top: ${props => props.hasTopMargin ? "13px" : null};
`;

export const WeekDayLabel = styled(StyledDay)`
    opacity: 0.5;
    cursor: default;
    ${T_PLAIN_small};
`;


export const Weeks = styled.div`
    position: relative;
    margin-top: 1px;
    margin-bottom: 12px;
    display: flex;
    flex-direction: column;
    gap: 2px;
`;

export const PickersWrapper = styled.div<{ embedded?: boolean }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 42px;
    margin-top: ${props => props.embedded ? "23px" : "30px"};
`;

export const HeaderTextWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const HeaderTextLabelBaseStyles = css`
    position: relative;
    top: 1px;
    ${T_HEADER_small};
`;

export const HeaderTextLabel = styled.div`
    ${HeaderTextLabelBaseStyles};
    margin-right: 5px;
`;

export const HeaderTextButton = styled.button<{ alignment?: TextAlign }>`
    all: unset;
    ${HeaderTextLabelBaseStyles};

    ${getFocusBorderElement({ offset: 3, keyboardOnly: true, isLight: true })};

    &:enabled:hover {
        color: ${props => themes.light.C_BTN_4L_emph_text_hover};
        transition: color ${COLOR_TRANSITION_TIME}ms ease-in-out;
        cursor: pointer;
    }

    &:disabled {
        opacity: ${props => themes.light.disabled_opacity};
    }

    ${props => props.alignment && css<{ alignment?: TextAlign }>`
        position: absolute;
        top: 50%;
        left: 50%;
        transform: ${props => props.alignment === TextAlign.Left ? "translate(calc(-100% - 6px), -50%)" : "translate(6px, -50%)"};
    `}
`;

const GridSharedStyles = css`
    position: relative;
    display: grid;
    grid-gap: 2px;
    justify-content: center;
    align-items: center;
    justify-items: center;
`;

export const MonthsGrid = styled.div`
    ${GridSharedStyles};
    grid-template-columns: repeat(3, 84px);
    grid-template-rows: repeat(4, 35px);
    margin-top: 46px;
    margin-bottom: 82px;
`;

export const YearsGrid = styled.div`
    ${GridSharedStyles};
    grid-template-columns: repeat(3, 84px);
    grid-template-rows: repeat(7, 35px);
    grid-gap: 2px;
    margin-top: 17px;
`;

export const DateLabelButton = styled.button<{ $isSelected?: boolean; $isDisabled?: boolean; }>`
    all: unset;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: fit-content;
    padding: 5px;
    border-radius: ${props => props.theme.borderRadius};
    background-color: ${props => props.$isSelected ? themes.light.C_BTN_4L_emph_bg_hover : "transparent"};

    &:hover, &:focus {
        background-color: ${props => props.$isSelected ? themes.light.C_BTN_4L_emph_bg_hover : transparentize(0.7, themes.light.C_BTN_hover_dark)};
        outline: none;
    }

    &:active {
        background-color: ${themes.light.C_BTN_4L_emph_bg_hover};
    }

    & > span {
        position: relative;
        top: 1px;
    }

    ${T_PLAIN_big};

    ${getFocusBorderElement({ offset: 0, keyboardOnly: true, isLight: true })};
    ${props => getDisabledStyle(props.$isDisabled)}
`;

export const YearMonthLabelText = styled.span`
    cursor: default;
    ${T_HEADER_small};
`;

export const SmallLabel = styled.span`
    display: flex;
    justify-content: center;
    cursor: default;
    user-select: none;
    opacity: 0.5;
    ${T_PLAIN_big};
`;

export const StyledBottomButtons = styled.div<{ embedded?: boolean }>`
    display: flex;
    justify-content: flex-end;

    position: relative;
    margin-top: 19px;

    & ${StyledButton}:not(:first-child) {
        margin-left: 10px;
    }
`;

export const SpecialValue = styled(DateLabelButton)`
    position: absolute;
    right: 0;
    bottom: 0;
    ${T_PLAIN_small};
`;

// todo maybe this should be behind some props directly on Input/StyledInput for inputs inside select/calendar colored components
export const TimePickerCalendarStyled = styled(TimePicker)`
    background-color: ${transparentize(0.8, themes.light.C_TEXT_dialogue)};
    border: 1px solid ${props => transparentize(0.7, props.theme.C_FIELD_line)};

    ${StyledHtmlInput} {
        color: ${themes.light.C_TEXT_dialogue};
        background-color: transparent;
        caret-color: ${themes.light.C_TEXT_dialogue};
    }
;
`;

export const StyledRelativeDateValues = styled.div<{ embedded?: boolean; }>`
    display: flex;
    flex-direction: column;
    gap: 5px;
    flex: 0 0 auto;
    background: ${props => props.embedded ? "transparent" : themes.light.C_BG_dialogue};
    color: ${themes.light.C_TEXT_dialogue};
    padding: 12px 19px 12px 19px;
    box-shadow: 1px 0 0 ${props => transparentize(0.5, props.theme.C_ACT_hover_without_opacity)};

    & ${DateLabelButton} {
        ${T_PLAIN_small};
    }
`;