import { ellipsis } from "polished";
import styled, { css } from "styled-components/macro";

import { TextAlign } from "../../../enums";
import { T_PLAIN_big_hig, T_PLAIN_small, textAlignToJustify } from "../../../global.style";
import { AuditTrailFieldType } from "../../smart/FieldInfo";

export const StyledLabel = styled.div<{
    isLight: boolean;
    _width: string;
    _maxWidth: string;
    textAlign: TextAlign;
    isDisabled: boolean;
}>`
    width: ${props => props._width};
    max-width: ${props => props._maxWidth};
    height: 16px;
    display: flex;
    color: ${props => !props.isLight ? props.theme.C_TEXT_primary : props.theme.C_TEXT_dialogue};
    margin-bottom: 3px;
    justify-content: ${props => textAlignToJustify(props.textAlign)};
    position: relative;
    opacity: ${props => props.isDisabled ? 0.5 : 1};

    ${T_PLAIN_big_hig};
`;

export const LabelWrapper = styled.div<{
    hasPadding: boolean;
    isAuditTrail: boolean;
}>`
    position: relative;
    max-width: 100%;
    background: ${props => props.isAuditTrail ? props.theme.C_AUDIT_TRAIL_NO_DIFF : ""};
    ${props => props.isAuditTrail && css`
        color: ${props.theme.C_TEXT_primary};
    `}
    border-radius: 3px;
    padding: 0 ${props => props.hasPadding ? "12px" : "0"};

    & + button {
        margin-left: ${props => props.hasPadding ? "-10px" : "2px"};
        align-self: center;
    }

    display: flex;
    align-items: center;
`;

export const InnerLabel = styled.label<{
    auditTrailType: AuditTrailFieldType;
    useMinWidth?: boolean;
}>`
    opacity: ${props => props.auditTrailType === AuditTrailFieldType.HoveredNoDifference ?
        props.theme.C_AUDIT_TRAIL_DIFF_VISIBLE_OPACITY : props.auditTrailType === AuditTrailFieldType.NoDifference ? 0 : 1};
    ${ellipsis()}
    min-width: ${props => props.useMinWidth ? "50px": ""};
`;

export const RequiredMark = styled.div<{
    hasPadding?: boolean;
    auditTrailType?: AuditTrailFieldType;
}>`
    position: absolute;
    opacity: ${props => props.auditTrailType === AuditTrailFieldType.HoveredNoDifference ?
        props.theme.C_AUDIT_TRAIL_DIFF_VISIBLE_OPACITY : props.auditTrailType === AuditTrailFieldType.NoDifference ? 0 : 1};
    width: 5px;
    height: 5px;
    // todo: color
    background-color: #e50142;
    // There should be 3px space between text, so it means (hasPadding ? 12px : 0px) - 3px;
    left: ${props => props.hasPadding ? "9px" : "-3px"};;
    top: 0;
    transform: translate(-100%, 50%);
    border-radius: 50%;
`;

export const LabelDescription = styled.span`
    ${T_PLAIN_small};
    margin-left: 8px;
    font-weight: normal;
    font-style: italic;
    white-space: nowrap;
    flex-shrink: 0;
    ${ellipsis()};
    max-width: 70%;
`;