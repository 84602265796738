import styled, { css } from "styled-components/macro";
import { T_PLAIN_small } from "../../global.style";


export const Row = styled.div`
    display: flex;
    margin-bottom: 5px;
`;

export const Level = styled.div<{
    isTop : boolean;
}>`
    ${T_PLAIN_small};
    word-break: break-all;
    margin-left: ${props => props.isTop? "0" : "25px"};
    margin-bottom: 5px;
    padding-right: 5px;
`;

const TRIANGLE_SIZE = 4;
const TriangleBase = css`
    width: 0;
    height: 0;
    cursor: pointer;
`;
export const TriangleDown = styled.div `
    ${TriangleBase};

    border-left: ${TRIANGLE_SIZE}px solid transparent;
    border-right: ${TRIANGLE_SIZE}px solid transparent;
    border-top: ${TRIANGLE_SIZE * 2}px solid ${props => props.theme.C_BG_menu};
`;

export const TriangleRight = styled.div `
    ${TriangleBase};

    border-top: ${TRIANGLE_SIZE}px solid transparent;
    border-left: ${TRIANGLE_SIZE * 2}px solid ${props => props.theme.C_BG_menu};
    border-bottom: ${TRIANGLE_SIZE}px solid transparent;
`;

export const CollapseWrapper = styled.div `
    margin-right: 5px;
    margin-top: 4px;
`;