import { IInvoiceServiceIntegrationEntity } from "@odata/GeneratedEntityTypes";

import { withPermissionContext } from "../../contexts/permissionContext/withPermissionContext";
import DialogFormView, { IDialogFormViewProps } from "../../views/formView/DialogFormView";

interface IProps extends IDialogFormViewProps<IInvoiceServiceIntegrationEntity> {
}

class IntegrationsFormView extends DialogFormView<IInvoiceServiceIntegrationEntity, IProps> {
    static defaultProps = {
        skipLoad: true,
        isInDialog: true,
        hideButtons: true
    }
}

export default withPermissionContext(IntegrationsFormView);