import React, { useRef, useState } from "react";
import { Validator } from "../../../model/Validator";
import { useTranslation } from "react-i18next";
import { composeRefHandlers } from "@utils/general";
import Tooltip from "../../tooltip/Tooltip";
import FormError from "./FormError";
import { IValidationError } from "../../../model/Validator.types";

// internal props between HOC and wrapped component
export interface WithErrorAndTooltip {
    hasError?: boolean;
    hasErrorText?: boolean;
    errorAndTooltip?: React.ReactNode;
}

// Props to pass through to wrapped component
interface WithErrorAndTooltipExtendedProps extends WithErrorAndTooltip {
    value?: string;
    onBlur?: (...args: any[]) => void;
    onFocus?: (...args: any[]) => void;
    passRef?: React.Ref<HTMLElement>;
    outerRef?: React.Ref<HTMLElement>;
    isReadOnly?: boolean;
}

// enhanced component props
export interface WithErrorAndTooltipProps {
    error?: IValidationError;
    tooltip?: string | (() => string);
}

export interface WithErrorAndTooltipSettings {
    // render error state without circle, even when the input is focused
    renderErrorWithFocus?: boolean;
    renderErrorTextWithFocus?: boolean;
    useErrorMinWidth?: boolean;
    preventValueTooltip?: boolean;
}

export const withErrorAndTooltip = <P extends WithErrorAndTooltipExtendedProps>(WrappedComponent: React.ComponentType<P>, settings?: WithErrorAndTooltipSettings) => {

    return React.forwardRef((props: P & WithErrorAndTooltipProps, ref) => {
        const { error, tooltip, ...passProps } = props;

        const _inputRef = useRef<HTMLElement>();

        const { i18n } = useTranslation("Common");

        const [hasFocus, setHasFocus] = useState(false);
        const [showTooltip, setShowTooltip] = useState(true);
        // if error or manual tooltip is set, we want to always show the tooltip on mouse over
        // otherwise, show full "value" only when it's overflowing
        const showTooltipOnlyWhenOverflowing = !(props.tooltip || props.error);

        function _getErrorText(error: IValidationError) {
            return Validator.getErrorText(error, i18n);
        }

        const renderErrorStateOnFocus = settings?.renderErrorWithFocus !== false;

        // we can turn off rendering error state when field is focused, but by default it should be rendered to
        // support form submission by shortcuts (so focus is kept in the current field and the user should be
        // informed about the error state in the current field)
        const _showError = (renderErrorStateOnFocus || !hasFocus) && !!props.error;

        const _getTooltip = () => {
            if (typeof props.tooltip === "function") {
                return props.tooltip();
            }

            return props.tooltip || (props.error && (
                    <strong>{_getErrorText(props.error)}</strong>)) || (!settings?.preventValueTooltip && props.value);
        };

        const handleBlur = (...args: any[]) => {
            setHasFocus(false);
            setShowTooltip(true);

            props.onBlur?.(...args);
        };

        const handleFocus = (...args: any[]) => {
            if (!hasFocus) {
                setHasFocus(true);
                setShowTooltip(false);
            }
            props.onFocus?.(...args);
        };

        function renderError() {
            return (
                <>
                    {_showError && (
                        <FormError errorText={_getErrorText(props.error)}
                                   hideText={!settings?.renderErrorTextWithFocus && hasFocus}
                                   useErrorMinWidth={settings?.useErrorMinWidth}
                                   hideCircle={false}/>
                    )}
                </>
            );
        }

        return (
            <Tooltip isHidden={!showTooltip}
                     onlyShowWhenChildrenOverflowing={showTooltipOnlyWhenOverflowing}
                     content={_getTooltip()}>
                {(refForTooltip, refRowTooltipOverflowCheck) =>
                    <WrappedComponent {...passProps as any}
                                      error={error}
                                      tooltip={tooltip}
                                      ref={ref}
                                      passRef={composeRefHandlers(_inputRef, props.passRef, refRowTooltipOverflowCheck)}
                                      outerRef={composeRefHandlers(refForTooltip, props.outerRef)}
                                      onBlur={handleBlur}
                                      onFocus={handleFocus}
                                      hasError={_showError}
                                      hasErrorText={_showError && !hasFocus}
                                      errorAndTooltip={renderError()}/>}
            </Tooltip>
        );
    });
};
