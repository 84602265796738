import { CompanyPermissionCode, GeneralPermissionCode } from "@odata/GeneratedEnums";
import { getValue } from "@utils/general";
import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";

import { AppContext, IAppContext } from "../contexts/appContext/AppContext.types";
import { SessionType } from "../contexts/authContext/Auth.utils";
import { WithAuthContext, withAuthContext } from "../contexts/authContext/withAuthContext";
import { WithPermissionContext, withPermissionContext } from "../contexts/permissionContext/withPermissionContext";
import { PageViewMode, QueryParam } from "../enums";
import { getAllMenuGroups } from "../menu-def";
import { isNonCustomerPortalRoute, ROUTE_LOGIN, ROUTE_NOT_FOUND } from "../routes";
import { NoPermission } from "../views/notFound";
import { getQueryParameters, getRedirectUriQueryParam } from "./Routes.utils";

interface IProps extends WithAuthContext, WithPermissionContext, RouteProps {
    id?: string;
    permission: CompanyPermissionCode | GeneralPermissionCode;
}

interface IState {
    // automatically redirect to not found page if route should be hidden
    // based on menu-def isVisible callback
    routeShouldBeHidden: boolean;
}

class PrivateRoute extends React.PureComponent<IProps, IState> {
    static contextType = AppContext;

    constructor(props: IProps, context: IAppContext) {
        super(props, context);

        this.state = { routeShouldBeHidden: false };

        let path = Array.isArray(props.path) ? props.path[0] : props.path;

        path = path?.toLowerCase();

        const allMenuGroups = getAllMenuGroups(context);
        const matchingMenuItem = allMenuGroups
            .flatMap(group => group.items)
            .find(item => item.url.toLowerCase().startsWith(path));

        if (matchingMenuItem && matchingMenuItem.isVisible) {
            const shouldBeVisible = getValue(matchingMenuItem.isVisible, { context });

            if (!shouldBeVisible) {
                this.state = { routeShouldBeHidden: true };
            }
        }
    }

    render() {
        if (this.state.routeShouldBeHidden) {
            return <Redirect to={ROUTE_NOT_FOUND}/>;
        }

        const { authContext, ...routeProps } = this.props;
        const permissionContext = this.props.permissionContext;

        if (authContext.sessionType === SessionType.Customer) {
            // todo: should we stay with the opt-out approach for customer login?
            if (isNonCustomerPortalRoute(routeProps.location?.pathname)) {
                routeProps.component = NoPermission;
                routeProps.render = null;
            }
        } else if (this.props.permission && !permissionContext.companyPermissions.has(this.props.permission as CompanyPermissionCode) &&
            !permissionContext.generalPermissions.has(this.props.permission as GeneralPermissionCode)) {

            // this is special case for intent navigation in edge case when you have permission for reports, but
            // not for invoices, then you should be able, to see invoice in view mode after using intent navigation, yet
            // you should not see invoices screen, these are just FE security through obscurity as on BE read permission
            // is available for all entities regardless on permissions
            const viewMode = getQueryParameters()?.[QueryParam.ViewMode];
            if (viewMode !== PageViewMode.FormReadOnly) {
                routeProps.component = NoPermission;
                routeProps.render = null;
            }
        }

        return authContext.isAuthenticated
            ? <Route {...routeProps}/>
            : <Redirect to={{
                pathname: ROUTE_LOGIN,
                search: window.location?.pathname && window.location.pathname !== "/" ? `?${getRedirectUriQueryParam()}` : null
            }}/>;
    }
}

export default withPermissionContext(withAuthContext(PrivateRoute));