import { ArcElement, Chart as ChartJS, ChartData, ChartOptions, Legend, Tooltip } from "chart.js";
import React, { useMemo } from "react";
import { Doughnut } from "react-chartjs-2";
import { withTheme } from "styled-components/macro";

import { PropsWithTheme } from "../../theme";
import { ChartWrapperStyled } from "./Charts.styles";
import { ChartType, getChartTooltipOptions, IChartComponentData, IChartProps, legend } from "./Charts.types";

ChartJS.register(ArcElement, Tooltip, Legend);

type TDoughnutChartOptions = ChartOptions<ChartType.Doughnut>;
type TDoughnutChartData = ChartData<ChartType.Doughnut>;

export interface IDoughnutChartComponentData extends IChartComponentData {
    name?: string;
}

interface IProps extends PropsWithTheme, IChartProps<IDoughnutChartComponentData> {
}

const DoughnutChart: React.FC<IProps> = (props) => {
    const { data: { name, labels, colors, values }, formatter, width, height, responsive, theme } = props;

    const options = useMemo<TDoughnutChartOptions>(() => ({
        plugins: {
            legend: {
                ...legend,
                title: {
                    // fake title for extra padding between legend and chart
                    display: true,
                    text: "",
                    padding: 1
                }
            },
            tooltip: getChartTooltipOptions<ChartType.Doughnut>(formatter)
        },
        cutout: "65%",
        responsive
    }), [responsive, formatter]);

    const data = useMemo<TDoughnutChartData>(() => ({
        labels,
        datasets: [{
            label: name ?? "",
            data: values,
            backgroundColor: colors.map(c => theme[c]),
            borderWidth: 0,
            borderRadius: 3,
            spacing: 3
        }]
    }), [name, labels, values, colors, theme]);

    return (
        <ChartWrapperStyled className={props.className}>
            <Doughnut data={data} options={options} width={width} height={height}/>
        </ChartWrapperStyled>
    );
};

export default withTheme(DoughnutChart);
