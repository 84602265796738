import styled from "styled-components/macro";

export const StyledImageUploader = styled.div<{
    isDisabled: boolean;
}>`
    display: inline-flex;
    justify-content: center;
    align-items: center;
`;

export const StyledImage = styled.img`
    display: block;
    max-width: 178px;
    max-height: 200px;
    width: 100%;
    min-width: 20px;
    height: auto;
    min-height: 20px;
    object-fit: contain;
    border: 1px solid black;
`;