import { getAddressFields, PhoneNumberDef, SingleBusinessPartnerDef } from "@components/smart/GeneralFieldDefinition";
import { createPath } from "@odata/BindingContext";
import { BillingAddressEntity, BillingContactEntity, EntitySetName, TenantEntity } from "@odata/GeneratedEntityTypes";
import { setDefForTesting } from "@pages/getDefByEntityType";
import { IDefinition, IGetDefinition } from "@pages/PageUtils";
import i18next from "i18next";

import { IAppContext } from "../../../../contexts/appContext/AppContext.types";
import { BasicInputSizes, GroupedField, ValidatorType } from "../../../../enums";
import { IFormDef } from "../../../../views/formView/Form";


const businessPartnerDependentFields = [
    {
        from: { id: "LegalNumber" },
        to: { id: "LegalNumber" }
    },
    {
        from: { id: "Name" },
        to: { id: "BillingAddress/Name" }
    },
    {
        from: { id: "TaxNumber" },
        to: { id: "TaxNumber" }
    },
    {
        from: { id: "BillingAddress/Street" },
        to: { id: "BillingAddress/Street" }
    },
    {
        from: { id: "BillingAddress/City" },
        to: { id: "BillingAddress/City" }
    },
    {
        from: { id: "BillingAddress/Country" },
        to: { id: "BillingAddress/Country" }
    },
    {
        from: { id: "BillingAddress/PostalCode" },
        to: { id: "BillingAddress/PostalCode" }
    }
];

const businessPartnerDef = {
    ...SingleBusinessPartnerDef,
    fieldSettings: {
        ...SingleBusinessPartnerDef.fieldSettings,
        localDependentFields: businessPartnerDependentFields
    }
};

export const getDefinition: IGetDefinition = (context: IAppContext): IDefinition => {
    const entitySet = EntitySetName.Tenants;
    const addressFields = getAddressFields("BillingAddress");

    const form: IFormDef = {
        id: "InvoicingSpecialForm",
        translationFiles: getDefinition.translationFiles,
        summary: [],
        fieldDefinition: {
            [createPath(TenantEntity.BillingAddress, BillingAddressEntity.Name)]: {
                ...businessPartnerDef,
                groupedField: GroupedField.MultiStart,
                width: BasicInputSizes.XL
            },
            [TenantEntity.LegalNumber]: {
                ...businessPartnerDef,
                groupedField: GroupedField.MultiEnd,
                width: BasicInputSizes.S
            },
            [TenantEntity.TaxNumber]: {},
            [TenantEntity.PhoneNumber]: {
                ...PhoneNumberDef,
                isRequired: true
            },
            ...addressFields,
            [createPath(TenantEntity.BillingContacts, BillingContactEntity.Email)]: {
                width: BasicInputSizes.L,
                validator: {
                    type: ValidatorType.Email
                }
            },
            [createPath(TenantEntity.BillingContacts, BillingContactEntity.FirstName)]: {},
            [createPath(TenantEntity.BillingContacts, BillingContactEntity.LastName)]: {}
        },
        groups: [
            {
                id: "address",
                rows: [
                    [
                        { id: createPath(TenantEntity.BillingAddress, BillingAddressEntity.Name) },
                        { id: TenantEntity.LegalNumber },
                        { id: TenantEntity.TaxNumber }
                    ],
                    [
                        { id: createPath(TenantEntity.BillingAddress, BillingAddressEntity.Street) },
                        { id: createPath(TenantEntity.BillingAddress, BillingAddressEntity.City) },
                        { id: createPath(TenantEntity.BillingAddress, BillingAddressEntity.PostalCode) },
                        { id: createPath(TenantEntity.BillingAddress, BillingAddressEntity.Country) }
                    ],
                    [
                        { id: TenantEntity.PhoneNumber }
                    ]
                ]
            },
            {
                id: "BillingContacts",
                title: i18next.t("Subscriptions:Invoicing.InvoiceRecipients"),
                tooltip: i18next.t("Subscriptions:Invoicing.BillingContactsInfo"),
                lineItems: {
                    collection: TenantEntity.BillingContacts,
                    order: "Order",
                    canReorder: false,
                    isGroupToggleable: true,
                    isItemCloneable: false,
                    columns: [
                        { id: BillingContactEntity.FirstName },
                        { id: BillingContactEntity.LastName },
                        { id: BillingContactEntity.Email }
                    ]
                }
            }
        ]
    };

    return {
        entitySet,
        form
    };
};

getDefinition.translationFiles = ["Subscriptions", "Common"];
setDefForTesting("InvoicingDef", getDefinition);