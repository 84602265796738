import { FormViewForExtend, IFormViewProps } from "../../views/formView/FormView";
import { CbaCategoryEntity, ICbaCategoryEntity } from "@odata/GeneratedEntityTypes";
import { ISmartFieldChange } from "@components/smart/smartField/SmartField";
import { CbaCategoryTaxImpactCode } from "@odata/GeneratedEnums";
import { AppContext } from "../../contexts/appContext/AppContext.types";
import { getDefaultPercetageFromImpactCode } from "../cashBasisAccounting/CashBasisAccounting.utils";
import { withPermissionContext } from "../../contexts/permissionContext/withPermissionContext";

interface IProps extends IFormViewProps<ICbaCategoryEntity> {}

class CbaCategoriesFormView extends FormViewForExtend<ICbaCategoryEntity, IProps> {
    static contextType = AppContext;

    static defaultProps = {
        title: "Categories:Title"
    };

    handleTaxImpactChange = (e: ISmartFieldChange): void => {
        if (e.bindingContext.getPath() === CbaCategoryEntity.TaxImpact) {
            this.props.storage.setValueByPath(CbaCategoryEntity.TaxPercentage, getDefaultPercetageFromImpactCode(e.value as CbaCategoryTaxImpactCode));
            this.props.storage.clearErrorByPath(CbaCategoryEntity.TaxPercentage);
            this.props.storage.setValueByPath(CbaCategoryEntity.IsAssetAcquisition, false);
        }
    };

    handleChange(e: ISmartFieldChange): void {
        this.props.storage.handleChange(e);
        this.handleTaxImpactChange(e);
        this.props.storage.refresh();
    }

    onBeforeSave(): ICbaCategoryEntity {
        const data = this.entity;

        if (this.props.storage.data.bindingContext.isNew()) {
            data.Company = {
                Id: this.context.getCompany().Id
            };
        }

        return data;
    }
}

export default withPermissionContext(CbaCategoriesFormView);