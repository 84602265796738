import React from "react";
import { IDefinition, IGetDefinition } from "../../PageUtils";
import { setDefByEntityType } from "../../getDefByEntityType";
import { cloneDeep } from "lodash";
import { getCashReceiptsFormDef } from "./CashReceiptsDef";
import CashReceiptsReceivedFormView from "./CashReceiptsReceivedFormView";
import { EntityTypeName } from "@odata/GeneratedEntityTypes";
import { IAppContext } from "../../../contexts/appContext/AppContext.types";

export const getDefinitions: IGetDefinition = (context: IAppContext): IDefinition => {
    const definition = cloneDeep(getCashReceiptsFormDef(CashReceiptsReceivedFormView, true, context));
    return definition;
};

getDefinitions.translationFiles = ["Cash", "Document"];
setDefByEntityType(EntityTypeName.CashReceiptReceived, getDefinitions);