import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { IconSize } from "../../enums";
import { KeyName } from "../../keyName";
import TestIds from "../../testIds";
import { IconButton } from "../button";
import FileUploadButton from "../fileUploadButton/FileUploadButton";
import FileDropArea from "../fileUploader/FileDropArea";
import { AttachmentIcon, SendMessageIcon } from "../icon";
import {
    ButtonGroupStyled,
    InputWrapper,
    MessageInput,
    SeparatorStyled,
    SubjectInput,
    SwitchStyled,
    TicketMessageInputBackground,
    TicketMessageInputStyled
} from "./TicketMessage.styles";
import { ITicketMessage, TicketMessageType } from "./TicketMessage.utils";
import UploadedFiles from "./UploadedFiles";

interface IProps {
    showSubject?: boolean;
    onConfirm: (message: ITicketMessage, files: File[]) => Promise<boolean>;
    onSwitch: () => Promise<void>;
    showSwitch?: boolean;
    isDone?: boolean;
}

const TicketMessageInput: React.FC<IProps> = ({ showSubject, onConfirm, onSwitch, showSwitch, isDone }) => {
    const [text, setText] = useState<string>("");
    const [subject, setSubject] = useState<string>("");
    const [files, setFiles] = useState<File[]>([]);
    const [busy, setBusy] = useState<boolean>(false);

    const { t } = useTranslation("Components");

    const canConfirm = !!((text?.trim() || files?.length) && (!showSubject || subject?.trim()));

    const handleConfirm = useCallback(async () => {
        if (!canConfirm) {
            return;
        }
        setBusy(true);
        const message: ITicketMessage = {
            text, subject,
            type: TicketMessageType.Sent
        };
        const res = await onConfirm(message, files);
        if (res) {
            // successfully saved
            setText("");
            setSubject("");
            setFiles([]);
        }
        setBusy(false);
    }, [canConfirm, text, subject, onConfirm, files]);

    const handleFileAttached = useCallback((newFiles: File[]) => {
        setFiles([...files, ...newFiles]);
    }, [files]);

    const handleFileRemove = useCallback((file: File, idx: number) => {
        const newFiles = [...files];
        newFiles.splice(idx, 1);
        setFiles(newFiles);
    }, [files]);

    const renderFileUploadButton = useCallback((onClick: () => void, isDisabled: boolean) => {
        return (
                <IconButton title={t("Components:TicketMessageInput.AttachmentsTitle")}
                            onClick={onClick}
                            isDecorative
                            isDisabled={isDisabled}>
                    <AttachmentIcon width={IconSize.S}/>
                </IconButton>
        );
    }, [t]);

    const handleKeyDown = useCallback((e: React.KeyboardEvent) => {
        if (e.key === KeyName.Enter && !e.shiftKey) {
            e.preventDefault();
            if (!busy) {
                handleConfirm();
            }
        }
    }, [handleConfirm, busy]);

    const handleTextChange = useCallback((e) => setText(e.value), []);
    const handleSubjectChange = useCallback((e) => setSubject(e.value), []);

    const handleSwitch = useCallback(async () => {
        setBusy(true);
        await onSwitch();
        setBusy(false);
    }, [setBusy, onSwitch]);

    const hasContent = !!(subject || text || files?.length);

    return (
            <TicketMessageInputStyled data-testid={TestIds.TicketMessageInput}>
                <TicketMessageInputBackground>
                    <FileDropArea showContent={true}
                                  onNewFiles={handleFileAttached}>
                        <InputWrapper data-testid={TestIds.InputWrapper}>
                            {showSubject && (
                                    <>
                                        <SubjectInput value={subject}
                                                      onChange={handleSubjectChange}
                                                      onKeyDown={handleKeyDown}
                                                      placeholder={t("Components:TicketMessageInput.SubjectPlaceholder")}
                                                      maxRows={1}/>
                                        <SeparatorStyled color={"C_FIELD_line"} isBold/>
                                    </>
                            )}
                            <MessageInput value={text}
                                          onChange={handleTextChange}
                                          onKeyDown={handleKeyDown}
                                          isDisabled={isDone}
                                          placeholder={t("Components:TicketMessageInput.TextPlaceholder")}
                                          maxRows={10}/>
                            <UploadedFiles files={files} onRemove={handleFileRemove}/>
                        </InputWrapper>
                        <ButtonGroupStyled>
                            <FileUploadButton onFileChange={handleFileAttached}
                                              accept={"*/*"}
                                              isDisabled={busy || isDone}
                                              renderCustomButton={renderFileUploadButton}
                                              multiple
                                              hotspotId={"TicketMessageUploadButton"}
                            />
                            <IconButton title={t("Components:TicketMessageInput.SubmitTitle")}
                                        onClick={handleConfirm}
                                        isBusy={busy}
                                        isDisabled={!canConfirm}>
                                <SendMessageIcon width={IconSize.M}/>
                            </IconButton>
                        </ButtonGroupStyled>
                    </FileDropArea>
                </TicketMessageInputBackground>
                {showSwitch && <SwitchStyled checked={isDone}
                                             onChange={handleSwitch}
                                             hasSemanticColor={true}
                                             label={t("Components:TicketMessageInput.Solved")}
                                             isDisabled={hasContent || busy}/>}
            </TicketMessageInputStyled>
    );
};

export default TicketMessageInput;