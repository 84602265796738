import React from "react";
import EditableText, { IEditableTextProps } from "./EditableText";
import { TextMeasure } from "../responsiveInput/ResponsiveInput.styles";

interface IProps extends Omit<IEditableTextProps, "width"> {

}

export default class ResponsiveEditableText extends React.PureComponent<IProps> {
    _textRef: React.RefObject<HTMLTextAreaElement> = React.createRef();
    _textMeasure: React.RefObject<HTMLSpanElement> = React.createRef();

    componentDidMount(): void {
        this.adaptSize();
    }

    componentDidUpdate(): void {
        this.adaptSize();
    }

    adaptSize = () => {
        if (this._textRef.current) {
            const textMeasureWidth = Math.ceil(parseFloat(getComputedStyle(this._textMeasure.current).width));
            const textRefStyles = getComputedStyle(this._textRef.current);
            // we have to retrieve the border size instead of using constant value
            // because browser changes it dynamically if user zooms out (probably so that it is still visible)
            const borderSize = parseInt(textRefStyles.borderLeftWidth) + parseInt(textRefStyles.borderRightWidth);
            // text width + 2*padding + 2*border
            this._textRef.current.style.width = `calc(${textMeasureWidth}px + ${2 * 12}px + ${2 * borderSize}px)`;
        }
    };

    handleIsEditChange = (isInEdit: boolean) => {
        if (isInEdit) {
            this.adaptSize();
        }
    };

    render() {
        return (
            <>
                <EditableText {...this.props} passRef={this._textRef}
                              onIsEditChanged={this.handleIsEditChange}
                              width={"min-content"}/>
                <TextMeasure ref={this._textMeasure}>
                    {this.props.value}
                </TextMeasure>
            </>
        );
    }
}