import { IGetValueArgs } from "@components/smart/FieldInfo";
import { getCommonTableColumnsDefs, withDisplayName } from "@components/smart/GeneralFieldDefinition";
import {
    FilterBarGroup,
    getDefaultFilterGroupDef,
    IFilterGroupDef
} from "@components/smart/smartFilterBar/SmartFilterBar.types";
import { EntitySetName } from "@odata/GeneratedEntityTypes";
import { IFormatOptions } from "@odata/OData.utils";
import { isCashBasisAccountingCompany } from "@utils/CompanyUtils";
import i18next from "i18next";
import React from "react";

import { IAppContext } from "../../../contexts/appContext/AppContext.types";
import { LogicOperator, Sort } from "../../../enums";
import { TValue } from "../../../global.types";
import { TableStorage } from "../../../model/TableStorage";
import BindingContext from "../../../odata/BindingContext";
import { ISplitPageTableDef } from "../../../views/table/TableView.utils";
import { docOnlyAmountPaidFormatter } from "../../banks/bankTransactions/BankTransactionDocumentPairDef";
import { createConditionForPairTable } from "../../banks/Pair.utils";
import { IDefinition } from "../../PageUtils";
import { DocumentStatusLocalPath } from "../../reports/CommonDefs";
import { getDocumentStatusColumnDefinition } from "../DocumentCommonDefs";
import { getDocumentStatusFilterDefinition, getUsedStatuses } from "../DocumentDef";


export const getDefinitions = (context: IAppContext): IDefinition => {
    const hasAccountAssignment = !isCashBasisAccountingCompany(context);

    const filterBarDef: IFilterGroupDef[] = [{
        ...getDefaultFilterGroupDef(FilterBarGroup.Filters),
        isValueHelp: true,
        defaultFilters: [
            "ClearedStatus",
            "DocumentType",
            "NumberOurs",
            "TransactionAmount",
            "TransactionCurrency",
            "BusinessPartner",
            "TransactionAmountToPay",
            "TransactionAmountToReceive",
            DocumentStatusLocalPath
        ],
        filterDefinition: {
            TransactionAmountToPay: {},
            TransactionAmountToReceive: {},
            ...getDocumentStatusFilterDefinition(getUsedStatuses(context)),
            ...withDisplayName("DocumentType"),
            NumberOurs: {},
            TransactionAmount: {},
            ...withDisplayName("BusinessPartner"),
            ...withDisplayName("TransactionCurrency", "Name"),
            "BusinessPartner/Street": {},
            "BusinessPartner/City": {},
            "BusinessPartner/PostalCode": {},
            "BusinessPartner/Country": {},
            "BusinessPartner/FirstName": {},
            "BusinessPartner/LastName": {},
            "BusinessPartner/Email": {},
            "BusinessPartner/PhoneNumber": {}
        }
    }];

    const accAssAdditionalProperties = hasAccountAssignment ? [{ id: "AccountAssignment" }, { id: "AccountAssignment/ShortName" },
        { id: "AccountAssignment/CreditAccount/Id" }, { id: "AccountAssignment/DebitAccount/Id" }, { id: "AccountAssignment/CreditAccount/Name" },
        { id: "AccountAssignment/CreditAccount/Number" }, { id: "AccountAssignment/DebitAccount/Name" },
        { id: "AccountAssignment/DebitAccount/Number" }] : [];

    const table: ISplitPageTableDef = {
        filterBarDef,
        filterOperator: LogicOperator.NonSoloOr,
        filter: (args: IGetValueArgs) => {
            return createConditionForPairTable(args.storage as TableStorage);
        },

        preventStoreVariant: true,
        id: "TransactionDocumentPairingSpecialTable",
        additionalProperties: [{ id: "TransactionCurrency" }, { id: "TransactionCurrency/MinorUnit" }, { id: "Currency" },
            { id: "TransactionAmountDue" }, { id: "ExchangeRatePerUnit" }, { id: "Amount" }, { id: "TransactionAmount" },
            { id: "DateAccountingTransaction" }, ...accAssAdditionalProperties],
        columnDefinition: {
            TransactionAmountToPay: {},
            TransactionAmountToReceive: {},
            NumberOurs: { id: "NumberOurs" },
            DateDue: { id: "DateDue" },
            PaymentMethodCode: { id: "PaymentMethodCode" },
            SymbolVariable: {},
            "BankAccount/AccountNumber": {},
            "BankAccount/BankCode": {},
            TransactionAmountDue: {
                // formatter: bankAmountDueFormatter
            },
            Note: { id: "Note" },
            ...getDocumentStatusColumnDefinition(),
            BusinessPartner: {
                id: "BusinessPartner",
                fieldSettings: {
                    displayName: "Name"
                }
            },
            ...getCommonTableColumnsDefs(),
            DocumentType: {
                fieldSettings: {
                    displayName: "Name"
                }
            },
            DateIssued: {},
            [BindingContext.localContext("AmountPaid")]: {
                label: i18next.t("Banks:Pairing.AmountPaid"),
                fieldSettings: {
                    disableSort: true
                },
                formatter: docOnlyAmountPaidFormatter
            }
        },
        columns: [
            "DocumentType",
            "DateIssued",
            "NumberOurs",
            BindingContext.localContext("AmountPaid"),
            "TransactionAmountDue",
            DocumentStatusLocalPath,
            "SymbolVariable",
            "BusinessPartner"
        ],
        initialSortBy: [{ id: "DateCreated", sort: Sort.Desc }]
    };

    if (hasAccountAssignment) {
        table.columnDefinition.AccountAssignment = {
            additionalProperties: [{ id: "ShortName" }, { id: "CreditAccount/Id" }, { id: "DebitAccount/Id" }, { id: "CreditAccount/Name" }, { id: "CreditAccount/Number" }, { id: "DebitAccount/Name" }, { id: "DebitAccount/Number" }],
            formatter: (val: TValue, args: IFormatOptions) => {
                const value = args.item.AccountAssignment?.ShortName;
                return {
                    value: <>{value}</>,
                    tooltip: value
                };
            }
        };
    }

    return {
        entitySet: EntitySetName.PayingDocuments,
        table
    };
};