import { formatDateInterval } from "@components/inputs/date/utils";
import { IGetValueArgs } from "@components/smart/FieldInfo";
import { FilterBarGroup, getDefaultFilterGroupDef } from "@components/smart/smartFilterBar/SmartFilterBar.types";
import { createPath } from "@odata/BindingContext";
import { EntitySetName, IInvoiceEntity, InvoiceEntity, SubscriptionEntity } from "@odata/GeneratedEntityTypes";
import { IFormatOptions } from "@odata/OData.utils";
import { setDefForTesting } from "@pages/getDefByEntityType";
import { IDefinition, IGetDefinition } from "@pages/PageUtils";
import i18next from "i18next";

import { Sort, TextAlign } from "../../../../enums";
import { TValue } from "../../../../global.types";
import CurrencyType from "../../../../types/Currency";
import { ISplitPageTableDef } from "../../../../views/table/TableView.utils";

export const getDefinitions: IGetDefinition = (): IDefinition => {
    const table: ISplitPageTableDef = {
        id: "billingPaymentHistorySpecialTable",
        filterBarDef: [
            {
                ...getDefaultFilterGroupDef(FilterBarGroup.Filters),
                defaultFilters: [
                    InvoiceEntity.DateStart,
                    InvoiceEntity.DateEnd,
                    InvoiceEntity.Amount
                ],
                filterDefinition: {
                    [InvoiceEntity.DateStart]: {},
                    [InvoiceEntity.DateEnd]: {},
                    [InvoiceEntity.Amount]: {}
                },
                isValueHelp: true
            }

        ],
        columns: [
            InvoiceEntity.DateStart,
            InvoiceEntity.Amount
        ],
        columnDefinition: {
            [InvoiceEntity.DateStart]: {
                label: i18next.t("Subscriptions:Payments.Period"),
                textAlign: TextAlign.Left,
                formatter: (val: TValue, args: IFormatOptions) => {
                    const invoice = args.item as IInvoiceEntity;

                    return formatDateInterval({
                        from: invoice.DateStart,
                        to: invoice.DateEnd
                    });
                }
            },
            [InvoiceEntity.Amount]: {
                textAlign: TextAlign.Right,
                formatter: (val: TValue, args: IFormatOptions) => {
                    const invoice = args.item as IInvoiceEntity;

                    return CurrencyType.format(val, {
                        currency: invoice.CurrencyCode
                    });
                }
            }
        },
        additionalProperties: [
            { id: InvoiceEntity.DateEnd },
            { id: InvoiceEntity.FileMetadataId },
            { id: InvoiceEntity.CurrencyCode }
        ],
        title: i18next.t("Subscriptions:Payments.Title"),
        initialSortBy: [{ id: InvoiceEntity.DateStart, sort: Sort.Asc }],
        preventStoreVariant: true
    };


    return {
        // special case - even though there is only one subscription for tenant, we still needs its id
        // to load table and filters => use callback
        entitySet: (args: IGetValueArgs): string => {
            const subscriptionId = args.context.getData().subscription.Id;

            return `${EntitySetName.Subscriptions}(${subscriptionId})/${SubscriptionEntity.Invoices}`;
        },
        table
    };
};

getDefinitions.translationFiles = ["Subscriptions", "Common"];
setDefForTesting(createPath(EntitySetName.Subscriptions, SubscriptionEntity.Invoices), getDefinitions);
