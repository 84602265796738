import React from "react";
import { AddMenuDefinition, IMenuDefItem } from "../../menu-def";
import { WithTranslation, withTranslation } from "react-i18next";
import Menu from "../menu/Menu";
import { ExtendedShell, IExtendedShellPropsForExtend } from "./ExtendedShell";
import { StyledTitle } from "./SettingsMenu.style";
import memoizeOne from "../../utils/memoizeOne";

interface IProps extends IExtendedShellPropsForExtend, WithTranslation {
}


class AddMenu extends React.PureComponent<IProps> {
    handleItemClick = () => {
        this.props.onHide(true);
    };

    renderHeader = () => {
        return (
            <StyledTitle>
                {this.props.t("Common:AddMenu.Title")}
            </StyledTitle>
        );
    };

    getItemMapMemoized = memoizeOne(() => {
        const map = new Map<string, IMenuDefItem>();

        AddMenuDefinition.forEach(group => {
            group.items?.forEach(item => {
                map.set(item.key, item);
            });
        });

        return map;
    });

    isAddMenuSelected = (key: string): boolean => {
        const item = this.getItemMapMemoized().get(key);
        const { pathname, search } = window.location;
        return item.url === `${pathname}${search}`;
    };

    render() {
        return (
            <ExtendedShell header={this.renderHeader()}
                           hotspotContextId={"AddMenu"}
                           {...this.props}>
                <Menu definition={AddMenuDefinition}
                      onItemClick={this.handleItemClick}
                      isItemSelected={this.isAddMenuSelected}
                      dontSetItemAsSelectedMenu/>
            </ExtendedShell>
        );
    }
}


export default withTranslation(["Common"])(AddMenu);
