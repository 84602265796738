import React, { useCallback } from 'react';
import { FormStorage } from "../../../views/formView/FormStorage";
import { IPrEmploymentEntity } from "@odata/GeneratedEntityTypes";
import { IconButton } from "@components/button";
import { CloseIcon } from "@components/icon";
import { IconSize } from "../../../enums";
import { TemplateName, TemplateSummaryItemStyled } from "./Employment.styles";
import SyncSymbol from "../../../components/inputs/field/SyncSymbol";
import { removeTemplate } from "./Employment.utils";
import i18next from "i18next";

interface IProps {
    storage: FormStorage<IPrEmploymentEntity>;
}

const TemplateSummaryItem: React.FC<IProps> = ({ storage }) => {
    const { entity, bindingContext } = storage.data;

    const isReadOnly = bindingContext.isNew();

    const handleRemoveTemplate = useCallback(() => {
        removeTemplate(storage);
    }, [storage]);

    return (<TemplateSummaryItemStyled>
        <TemplateName>
            <SyncSymbol/>
            {entity.Template?.Name}
        </TemplateName>
        {!isReadOnly && (
                <IconButton title={i18next.t("Employment:Form.RemoveTemplate")}
                            isDecorative
                            onClick={handleRemoveTemplate}>
                    <CloseIcon width={IconSize.M}/>
                </IconButton>
        )}
    </TemplateSummaryItemStyled>);
};

export default TemplateSummaryItem;