import { ElectronicSubmissionTypeCode, GeneralPermissionCode } from "@odata/GeneratedEnums";
import {
    ElectronicSubmissionNamespaces,
    ISubmissionOverviewRow
} from "@pages/electronicSubmission/ElectronicSubmission.utils";
import ElectronicSubmissionOverviewGrid from "@pages/electronicSubmission/ElectronicSubmissionOverviewGrid";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import { BreadCrumbProvider } from "../../components/breadCrumb";
import Header from "../../components/header/Header";
import { AppContext } from "../../contexts/appContext/AppContext.types";
import { WithPermissionContext, withPermissionContext } from "../../contexts/permissionContext/withPermissionContext";
import { IHistoryState } from "../../model/Model";
import { AUDIT_TRAIL, ROUTE_ELECTRONIC_SUBMISSION } from "../../routes";
import { getUtcDateBy } from "../../types/Date";
import View from "../../views/View";
import { Content } from "./ElectronicSubmission.styles";


interface IProps extends WithTranslation, WithPermissionContext {
    year: number;
    selectedType?: ElectronicSubmissionTypeCode;
    month?: number;
    onSelectYear?: (year: number) => void;
    onSelectDetail?: (type: ElectronicSubmissionTypeCode, month: number) => void;
    rows: ISubmissionOverviewRow[];
    initialHistoryState?: IHistoryState;
}

interface IState {
    // same rows enhanced with isSelected
    rows: ISubmissionOverviewRow[];
}

class ElectronicSubmissionOverview extends React.PureComponent<IProps, IState> {
    static contextType = AppContext;

    state: IState = {
        rows: null
    };

    componentDidMount() {
        this.initIsSelected();
    }

    //
    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>) {
        if (prevProps.rows !== this.props.rows || prevProps.month !== this.props.month
            || prevProps.year !== this.props.year || prevProps.selectedType !== this.props.selectedType) {
            this.initIsSelected();
        }
    }

    initIsSelected = () => {
        const updatedRows = [...this.props.rows];
        let monthIndex = 0;

        for (const row of updatedRows) {
            const date = getUtcDateBy(this.props.year, monthIndex, 1);

            for (const type of Object.values(ElectronicSubmissionTypeCode)) {
                const submissionInfo = row[type as ElectronicSubmissionTypeCode];

                if (submissionInfo) {
                    submissionInfo.isSelected = this.props.selectedType === type && this.props.year === date.getFullYear() && this.props.month === date.getMonth();
                }
            }

            monthIndex += 1;
        }


        this.setState({
            rows: updatedRows
        });
    };

    render() {
        return (<>
            <BreadCrumbProvider back={this.props.initialHistoryState?.back}
                                customBreadCrumbs={this.props.initialHistoryState?.breadCrumbs}
                                removeLast={!!this.props.selectedType}/>
            <View hotspotContextId={"electronicSubmissionOverview"}>
                <Header title={this.props.t("ElectronicSubmission:Title")}
                        icons={[{
                            id: "auditTrail",
                            label: this.props.t("Audit:AuditTrail"),
                            iconName: "AuditTrail",
                            link: `${ROUTE_ELECTRONIC_SUBMISSION}/${AUDIT_TRAIL}`,
                            isDisabled: !this.props.permissionContext?.generalPermissions?.has(GeneralPermissionCode.AuditTrail)
                        }]}/>
                <Content>
                    <ElectronicSubmissionOverviewGrid rows={this.state.rows}
                                                      year={this.props.year}
                                                      onSelectYear={this.props.onSelectYear}
                                                      onSelectDetail={this.props.onSelectDetail}
                    />
                </Content>
            </View>
        </>);
    }
}

export default withTranslation(ElectronicSubmissionNamespaces)(withPermissionContext(ElectronicSubmissionOverview));