import { TRecordString } from "../global.types";

export function join(...parts: string[]): string {
    if (parts.length === 0) {
        return '';
    }
    let result = parts[0];
    for (let i = 1; i < parts.length; i++) {
        if (!result.endsWith('/')) {
            result += '/';
        }
        result += parts[i];
    }
    return result;
}

export function createUrl(url: string, parameters: TRecordString = {}): string {
    const queryString = joinParameters(parameters);
    return [url, queryString].filter(i => i).join('?');
}

/**
 * Join parameters with encoding, which is optional to be able to prevent double encoding of params
 * (e.g. in oData expand statement)
 * @param parameters
 * @param separator
 * @param shouldEncodeParams
 * @returns {string}
 */
export function joinParameters(parameters: TRecordString, separator = "&", shouldEncodeParams = true): string {
    const keys = Object.keys(parameters);
    keys.sort();
    if (keys.length > 0) {
        return keys
            .map(k => k + '=' + (shouldEncodeParams ? encodeURIComponent(parameters[k]) : parameters[k]))
            .join(separator);
    }
    return "";
}