import { WithBusyIndicator, withBusyIndicator } from "@components/busyIndicator/withBusyIndicator";
import { ButtonSize } from "@components/button/Button.utils";
import { IInputOnBlurEvent, IInputOnChangeEvent } from "@components/inputs/input";
import { ODataError } from "@odata/Data.types";
import { ITenantEntity } from "@odata/GeneratedEntityTypes";
import { parseResponse } from "@odata/ODataParser";
import { getDefaultPostParams } from "@utils/customFetch";
import { deburr } from "lodash";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { RouteComponentProps } from "react-router";
import { Redirect } from "react-router-dom";

import { Button } from "../components/button";
import Clickable from "../components/clickable";
import WriteLine from "../components/inputs/writeLine";
import { AUTH_TENANTS } from "../constants";
import { ROUTE_LOGIN_TENANT, ROUTE_LOGOUT } from "../routes";
import TestIds from "../testIds";
import { BackArrow } from "./components/BackArrow";
import LoginButtonGroup from "./components/LoginButtonGroup";
import LoginCheckbox from "./components/LoginCheckbox";
import LoginField from "./components/LoginField";
import Redirects from "./components/Redirects";
import { EvalaTermsAndConditionsLink, getError, getLoginOptionsData, LoginTranslationNamespaces } from "./Login.utils";
import { LoginFormStyled, LoginTitle } from "./LoginPage.styles";
import { ISessionData, selectSessionData } from "./state/sessionSlice";
import { TRootLoginState } from "./state/store";
import { handleTenantSwitch } from "./TenantPicker.utils";
import { PHONE_REG } from "../model/Validator.types";


interface IProps extends RouteComponentProps, WithBusyIndicator, WithTranslation {
    session: ISessionData;
}

const MAX_NAME_LENGTH = 100;
const MAX_DOMAIN_LENGTH = 63;

interface IValidationMessages {
    name: string;
    domain: string;
    general: string;
    legalNumber: string;
    phoneNumber: string;
}

interface IState {
    name: string;
    domain: string;
    validationMessages: IValidationMessages;
    registered: boolean;
    legalNumber: string;
    phoneNumber: string;
    consent: boolean;
    consentVOP: boolean;
    hasOptions: boolean;
    redirect: string;
}

class TenantRegister extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            name: "",
            domain: "",
            legalNumber: "",
            phoneNumber: "",
            consent: true,
            consentVOP: true,
            hasOptions: false,
            redirect: null,
            validationMessages: {
                name: null,
                phoneNumber: null,
                domain: null,
                general: null,
                legalNumber: null
            },
            registered: false
        };
    }

    componentDidMount() {
        getLoginOptionsData().then((options) => {
            this.setState({
                hasOptions: options && options.length > 0
            });
        });
    }

    handleChanged(args: IInputOnChangeEvent<string>, fieldName: "name" | "domain" | "legalNumber" | "phoneNumber") {
        this.setState((state: IState) => ({
            ...state,
            [fieldName]: args.value,
            validationMessages: {
                ...state.validationMessages,
                [fieldName]: null
            }
        }));
    }

    handleNameChanged = (args: IInputOnChangeEvent<string>): void => {
        this.handleChanged(args, "name");
    };

    handleNameBlur = (args: IInputOnBlurEvent): void => {
        if (args.wasChanged && this.state.name) {
            const url = deburr(this.state.name)
                .trim()
                .replace(/\W+/g, "-")
                .replace(/[^a-zA-Z0-9-]/g, "")
                .toLowerCase()
                .substring(0, MAX_DOMAIN_LENGTH)
                .replace(/^-|-$/, "");

            this.setState({
                domain: url
            });
        }
    };

    handleDomainChanged = (args: IInputOnChangeEvent<string>): void => {
        this.handleChanged(args, "domain");
    };

    handleLegalNumberChanged = (args: IInputOnChangeEvent<string>): void => {
        this.handleChanged(args, "legalNumber");
    };

    handlePhoneNumberChanged = (args: IInputOnChangeEvent<string>): void => {
        this.handleChanged(args, "phoneNumber");
    }

    handleConsentChanged = (value: boolean): void => {
        this.setState((state: IState) => ({
            ...state,
            consent: value,
            validationMessages: {
                ...state.validationMessages,
                general: null
            }
        }), () => {
            const legalNumberValidationError = this.validateLegalNumber();
            if (legalNumberValidationError !== this.state.validationMessages.legalNumber) {
                this.setState((state: IState) => ({
                    ...state,
                    validationMessages: {
                        ...state.validationMessages,
                        legalNumber: legalNumberValidationError
                    }
                }));
            }
        });
    };

    handleConsentVOPChanged = (value: boolean): void => {
        this.setState((state: IState) => ({
            ...state,
            consentVOP: value,
            validationMessages: {
                ...state.validationMessages,
                general: null
            }
        }));
    };

    validateName = (): string => {
        if ((this.state.name == null) || (this.state.name.trim() === "")) {
            return this.props.t("Login:TenantRegister.NoName");
        }

        if (this.state.name.length > MAX_NAME_LENGTH) {
            return this.props.t("Login:TenantRegister.NameTooLong");
        }

        return null;
    };

    validateDomain = (): string => {
        if ((this.state.domain == null) || (this.state.domain.trim() === "")) {
            return this.props.t("Login:TenantRegister.NoDomain");
        }
        if (this.state.domain.length > MAX_DOMAIN_LENGTH) {
            return this.props.t("Login:TenantRegister.DomainTooLong");
        }

        // safari doesn't support negative look behind https://stackoverflow.com/questions/51568821/works-in-chrome-but-breaks-in-safari-invalid-regular-expression-invalid-group
        // instead, check that that last character is alphanumeric
        if (!this.state.domain.match(/^(?!-)[a-zA-Z0-9-]{1,63}[a-zA-Z0-9]$/)) {
            return this.props.t("Login:TenantRegister.DomainWrongFormat");
        }

        if (this.state.domain.includes("--")) {
            return this.props.t("Login:TenantRegister.DomainWrongFormat");
        }

        return null;
    };

    validatePhoneNumber = () => {
        const number = this.state.phoneNumber?.replace(/\s/g, "");
        if (!number) {
            return this.props.t("Login:TenantRegister.NoPhoneNumber");
        }

        if (!number?.match(PHONE_REG)) {
            return this.props.t("Login:TenantRegister.WrongPhoneNumber");
        }

        return null;
    }

    validateLegalNumber = (): string => {
        if (this.state.consent && !this.state.legalNumber) {
            return this.props.t("Login:TenantRegister.LegalNumberIsMandatory");
        }
        return null;
    };

    validateInputs = () => {
        let ret = true;
        let objErr =  {};

        const _validateInput = (fnMethod: () => string, propName: string) => {
            const error = fnMethod();
            if (error) {
                objErr = {
                    ...objErr,
                    [propName]: error
                }

                ret = false;
            }
        }

        _validateInput(this.validateName, "name");
        _validateInput(this.validateDomain, "domain");
        _validateInput(this.validatePhoneNumber, "phoneNumber");
        _validateInput(this.validateLegalNumber, "legalNumber");

        this.setState({
            validationMessages: {
                name: null,
                domain: null,
                general: null,
                legalNumber: null,
                phoneNumber: null,
                ...objErr
            }
        });

        return ret;
    };

    setRedirect = (redirect: string) => {
        this.setState({
            redirect
        });
    };

    handleRegisterPressed = async () => {
        if (!this.validateInputs()) {
            return;
        }

        this.props.setBusy(true);

        const response = await fetch(AUTH_TENANTS, {
            ...getDefaultPostParams(),
            body: JSON.stringify({
                name: this.state.name,
                domainName: this.state.domain,
                LegalNumber: this.state.legalNumber,
                PhoneNumber: this.state.phoneNumber
            })
        });

        const errorMessagePrefix = this.props.t("Login:TenantRegister.ErrorPrefix");
        if (response.ok) {
            const tenant: ITenantEntity = await response.json();
            const tenantSelected = await handleTenantSwitch(this.props.session, { TenantId: tenant.Id }, this.setRedirect);
            if (tenantSelected) {
                this.setState({
                    registered: true
                });
            } else {
                this.props.setBusy(false);
            }
        } else {
            const error: ODataError = await parseResponse(response);
            if (response.status === 400) {
                this.handleValidationError(error);
            } else if (response.status > 500) {
                this.handleGeneralError(error, errorMessagePrefix);
            } else {
                this.setState(state => ({
                    validationMessages: {
                        ...state.validationMessages,
                        general: errorMessagePrefix
                    }
                }));
            }
            // turn off busy when error occurs
            this.props.setBusy(false);
        }
    };

    handleGeneralError = (error: ODataError, errorMessagePrefix: string) => {
        this.setState(state => ({
            validationMessages: {
                ...state.validationMessages,
                general: errorMessagePrefix + (error?._message ? `: ${error?._message}` : "")
            }
        }));
    };

    handleValidationError = (error: ODataError) => {
        const nameErrorMessages: string[] = [];
        const domainErrorMessages: string[] = [];

        error?._validationMessages?.forEach(message => {
            if (message.property === "DomainName") {
                domainErrorMessages.push(message.message);
            } else if (message.property === "Name") {
                nameErrorMessages.push(message.message);
            }
        });

        if (nameErrorMessages.length || domainErrorMessages.length) {
            this.setState(state => ({
                validationMessages: {
                    ...state.validationMessages,
                    name: nameErrorMessages.join(" "),
                    domain: domainErrorMessages.join(" ")
                }
            }));
        } else {
            this.setState(state => ({
                validationMessages: {
                    ...state.validationMessages,
                    general: error._message
                }
            }));
        }
    };

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect}/>;
        }
            const { t } = this.props;

        return (<>
            <Redirects skipActiveSessionCheck={!this.state.registered}/>

            <LoginTitle data-testid={TestIds.Title}>
                <BackArrow url={this.state.hasOptions ? ROUTE_LOGIN_TENANT : ROUTE_LOGOUT}/>
                {t("Login:TenantRegister.Title")}
            </LoginTitle>

            <LoginFormStyled tinyMargin>
                {this.state.validationMessages?.general ?? "" /* ToDo: Login alert */}

                <LoginField name={"tenant-name"} label={t("Login:TenantRegister.Name")}>
                    <WriteLine error={getError(this.state.validationMessages.name)} isExtending
                               value={this.state.name}
                               onChange={this.handleNameChanged}
                               onBlur={this.handleNameBlur}/>
                </LoginField>

                <LoginField name={"subdomain"}
                            label={t("Login:TenantRegister.WebAddress")}
                            tooltip={t("Login:TenantRegister.TooltipText")}>
                    <WriteLine error={getError(this.state.validationMessages.domain)}
                               isExtending
                               value={this.state.domain}
                               floatingText=".evala.cz"
                               onChange={this.handleDomainChanged}/>
                </LoginField>

                <LoginField name={"legalNumber"}
                            label={t("Login:TenantRegister.LegalNumber")}>
                    <WriteLine error={getError(this.state.validationMessages.legalNumber)}
                               isExtending
                               value={this.state.legalNumber}
                               onChange={this.handleLegalNumberChanged}/>
                </LoginField>

                <LoginField name="PhoneNumber"
                            label={t("Login:TenantRegister.PhoneNumber")}>
                    <WriteLine error={getError(this.state.validationMessages.phoneNumber)}
                               isExtending
                               value={this.state.phoneNumber}
                               onChange={this.handlePhoneNumberChanged}/>
                </LoginField>


                <LoginCheckbox value={this.state.consent} onChange={this.handleConsentChanged}
                               label={t("Login:TenantRegister.Consent")}/>
                <LoginCheckbox value={this.state.consentVOP} onChange={this.handleConsentVOPChanged} label={(<>
                    {t("Login:TenantRegister.ConsentVOPprefix")} <Clickable
                            link={EvalaTermsAndConditionsLink}>{t("Login:TenantRegister.ConsentVOPconditions")}</Clickable>
                </>)}/>

                <LoginButtonGroup smallMargin>
                    <Button type={"submit"}
                            size={ButtonSize.Big}
                            isDisabled={!this.state.consentVOP}
                            onClick={this.handleRegisterPressed}>
                        {t("Login:TenantRegister.Create")}
                    </Button>
                </LoginButtonGroup>
            </LoginFormStyled>

            {this.props.busyIndicator}
        </>);
    }
}

const mapStateToProps = (state: TRootLoginState) => ({
    session: selectSessionData(state),
});

export default connect(mapStateToProps)(
    withBusyIndicator({
        isDelayed: true,
        passBusyIndicator: true
    })(withTranslation([...LoginTranslationNamespaces])(TenantRegister)));