import { FiscalYearStatusCode } from "@odata/GeneratedEnums";
import { getSortedFYs } from "@pages/fiscalYear/FiscalYear.utils";
import { getCompanyLogoUrl } from "@utils/CompanyUtils";
import { getValue, isNotDefined } from "@utils/general";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import Avatar from "../../components/avatar/Avatar";
import { AppContext, IAppContext } from "../../contexts/appContext/AppContext.types";
import { AvatarSize } from "../../enums";
import { SettingsDefinition } from "../../menu-def";
import TestIds from "../../testIds";
import Menu from "../menu/Menu";
import { ExtendedShell, IExtendedShellPropsForExtend } from "./ExtendedShell";
import { Icons, StyledTitle } from "./SettingsMenu.style";

interface IProps extends IExtendedShellPropsForExtend, WithTranslation {
}

interface IState {
    isReady: boolean;
}

class SettingsMenu extends React.PureComponent<IProps, IState> {
    static contextType = AppContext;
    state: IState = {
        isReady: false
    };

    componentDidMount() {
        this.init();
    }

    init = async () => {
        const context = this.context as IAppContext;

        // wait until the fiscal years are loaded
        await context.getFYPromise();

        this.setState({ isReady: true });
    };

    handleItemClick = () => {
        this.props.onHide(true);
    };

    renderHeader() {
        const currentCompany = this.context.getCompany();
        const logoUrl = getCompanyLogoUrl(currentCompany);

        return (
            <>
                <Icons>
                    <Avatar src={logoUrl} size={AvatarSize.L} transparent/>
                </Icons>
                <StyledTitle data-testid={TestIds.Title}>{currentCompany.Name}</StyledTitle>
            </>
        );
    }

    render() {
        if (!this.state.isReady) {
            return null;
        }

        let definition = SettingsDefinition
            .filter(group => isNotDefined(group.isVisible) ? true : getValue(group.isVisible, { context: this.context }));

        // only show initial balances if the oldest fiscal year is still active
        const oldestFiscalYear = getSortedFYs(this.context)[0];

        if (oldestFiscalYear?.StatusCode !== FiscalYearStatusCode.Active) {
            definition = definition
                .map(group => {
                    return {
                        ...group,
                        items: group.items.filter(item => item.key !== "InitialBalances")
                    };
                });
        }

        return (
            <ExtendedShell header={this.renderHeader()}
                           hotspotContextId={"SettingsMenu"}
                           {...this.props}>
                <Menu definition={definition} onItemClick={this.handleItemClick}/>
            </ExtendedShell>
        );
    }
}


export default withTranslation(["Companies"])(SettingsMenu);
