import { DropArea } from "@components/fileUploader/FileUploader.styles";
import { IconWrapper, StyledInput } from "@components/inputs/input/Input.styles";
import styled from "styled-components/macro";

import { T_PLAIN_big } from "../../../global.style";

export const StyledFileInput = styled(StyledInput)`
    border: none;
    cursor: default;

    ${DropArea} {
        border-radius: inherit;
        border-width: 2px;
        border-color: ${props => props.theme.C_FIELD_line};
        border-style: dashed;
        width: 100%;
        height: 100%;
    }

    ${IconWrapper} {
        display: flex;
        align-items: center;
    }
`;

export const FileNamesWrapper = styled.div<{
    hasError: boolean
}>`
    width: calc(100% - 34px);
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 12px;
    ${T_PLAIN_big};
    padding-top: ${props => props.hasError ? "9px" : null};
`;

export const ErrorWrapper = styled.div`
    padding: 0 12px;
    position: absolute;
    width: 100%;
    top: 0;
`;