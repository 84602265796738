import SmartFilterBar from "../../components/smart/smartFilterBar/SmartFilterBar";
import styled from "styled-components/macro";

export const StyledSmartFilterBar = styled(SmartFilterBar)`
 margin-bottom: 42px;
`;

export const Warnings = styled.div`
  margin-top: -17px;
  margin-bottom: 23px;
`;

export const DrillDownFiltersWrapper = styled.div`
    position: relative;
    top: -18px;
    margin-bottom: 18px;
`;