import { IEntity } from "@odata/BindingContext";
import { EntitySetName, EntityTypeName } from "@odata/GeneratedEntityTypes";
import { CompanyPermissionCode, DocumentTypeCode } from "@odata/GeneratedEnums";
import { setDefByEntityType } from "@pages/getDefByEntityType";
import { getItemBreadCrumbsText, IDefinition, IGetDefinition } from "@pages/PageUtils";
import { hasEverBeenVatRegisteredCompany } from "@utils/CompanyUtils";
import i18next from "i18next";
import { cloneDeep } from "lodash";

import { IAppContext } from "../../../../contexts/appContext/AppContext.types";
import { Model } from "../../../../model/Model";
import { commonDocumentTranslations } from "../../Document.utils";
import { addPaymentOrderTableDefs } from "../../DocumentCommonDefs";
import { clearEmptyDateGroupDateFields, getDefinitions as getDocumentDefinitions } from "../../DocumentDef";
import { addDateReceived, addDateVATDeduction, addNumberTheirs } from "../../invoicesReceived/InvoicesReceivedDef";
import { enhanceWithCommonProformaDefinitions } from "../ProformaInvoice.utils";
import ProformaInvoicesReceivedFormView from "./ProformaInvoicesReceivedFormView";

const getItemBreadCrumbText = (entity: IEntity) => {
    const value = entity.NumberOurs;
    return value
        ? i18next.t("ProformaInvoicesReceived:Breadcrumbs.InvoiceWithNumber", { number: value })
        : i18next.t("ProformaInvoicesReceived:Breadcrumbs.NewInvoice");
};

export const getDefinitions: IGetDefinition = (context: IAppContext): IDefinition => {
    const definition = cloneDeep(getDocumentDefinitions({
        entitySet: EntitySetName.ProformaInvoicesReceived,
        documentType: DocumentTypeCode.ProformaInvoiceReceived,
        tableId: `${EntityTypeName.ProformaInvoiceReceived}Table`,
        formId: `${EntityTypeName.ProformaInvoiceReceived}Form`,
        formControl: ProformaInvoicesReceivedFormView,
        translationFiles: getDefinitions.translationFiles,
        getItemBreadCrumbText: (storage: Model) =>
                getItemBreadCrumbsText(storage, i18next.t("ProformaInvoicesReceived:Breadcrumbs.NewInvoice"),
                        storage.data.entity?.NumberOurs && i18next.t("ProformaInvoicesReceived:Breadcrumbs.InvoiceWithNumber", { number: storage.data.entity.NumberOurs })),
        permissions: [CompanyPermissionCode.InvoicesReceived],
        context
    }));

    if (!hasEverBeenVatRegisteredCompany(context)) {
        definition.table.title = i18next.t(`ProformaInvoicesReceived:Title_WithoutVat`);
    }

    addDateVATDeduction(definition, context);
    enhanceWithCommonProformaDefinitions(definition, DocumentTypeCode.ProformaInvoiceReceived, getDefinitions.translationFiles[0], context);

    // Specific definitions for received proforma / DDOP
    addNumberTheirs(definition);
    addDateReceived(definition, true);
    addPaymentOrderTableDefs(definition);

    return clearEmptyDateGroupDateFields(definition);
};

getDefinitions.translationFiles = ["ProformaInvoicesReceived", "Proforma", "InvoicesReceived", ...commonDocumentTranslations];
setDefByEntityType(EntityTypeName.ProformaInvoiceReceived, getDefinitions);
