import { IAlertProps } from "@components/alert/Alert";
import { IconButton } from "@components/button";
import { AddIcon } from "@components/icon";
import { IInputOnChangeEvent } from "@components/inputs/input";
import { WriteLineWrapper } from "@components/navigation/NavDashboard.styles";
import { TEntityKey } from "@odata/BindingContext";
import { KeyboardShortcut } from "@utils/keyboardShortcutsManager/KeyboardShorcutsManager.utils";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import { BreadCrumbProvider } from "../components/breadCrumb/BreadCrumbProvider";
import Dialog from "../components/dialog/Dialog";
import WriteLine from "../components/inputs/writeLine/WriteLine";
import ObjectList, { ISection } from "../components/objectList/ObjectList";
import { ScrollBar } from "../components/scrollBar/ScrollBar";
import ViewHeader from "../components/smart/smartHeader/SmartHeader";
import { Toolbar } from "../components/toolbar";
import { IconSize, TextAlign } from "../enums";
import KeyboardShortcutsManager from "../utils/keyboardShortcutsManager/KeyboardShortcutsManager";
import ConfirmationButtons from "../views/table/ConfirmationButtons";
import { TableButtonsAction } from "../views/table/TableToolbar.utils";
import View from "../views/View";

interface IProps extends WithTranslation {
    className?: string;
    title?: string;
    sections?: ISection[];
    id: string;
    searchTerm?: string;

    onFilter?: (searchTerm: string) => void;
    onAdd?: () => void;
    onHandleAction?: (templateId: TEntityKey, type: string) => void;
    onDelete?: (id: string) => Promise<void>;
    messages?: Record<string, IAlertProps>;

    scrollRef?: React.RefObject<HTMLDivElement>;
}

interface IState {
    confirmationDialogId?: string;
    isConfirmationDialogBusy?: boolean;
}

const scrollBarStyles: React.CSSProperties = { overflowX: "hidden", position: "relative" };

class ObjectListPage extends React.Component<IProps, IState> {
    _unsubscribeKeyboardShortcuts: () => void;

    componentDidMount() {
        this._unsubscribeKeyboardShortcuts = KeyboardShortcutsManager.subscribe({
            shortcuts: [KeyboardShortcut.ALT_N],
            callback: this.handleKeyboardShortcut
        });
    }

    componentWillUnmount() {
        this._unsubscribeKeyboardShortcuts();
    }

    state: IState = {
        confirmationDialogId: "",
        isConfirmationDialogBusy: false
    };

    handleKeyboardShortcut = (shortcut: KeyboardShortcut, event: KeyboardEvent): boolean => {
        if (shortcut === KeyboardShortcut.ALT_N) {
            this.handleAdd();
            return true;
        }

        return false;
    };

    handleFilterChange = (e: IInputOnChangeEvent) => {
        const searchTerm = e.value as string;
        this.props.onFilter?.(searchTerm);
    };

    handleAction = async (id: string, type: string) => {
        if (type === "delete") {
            this.setState({
                confirmationDialogId: id
            });
        }

        this.props.onHandleAction(id, type);
    };

    handleConfirmAccept = async () => {
        this.setState({
            isConfirmationDialogBusy: true
        });

        await this.props.onDelete?.(this.state.confirmationDialogId);

        this.setState({
            confirmationDialogId: "",
            isConfirmationDialogBusy: false
        });
    };

    handleConfirmCancel = () => {
        this.setState({
            confirmationDialogId: ""
        });
    };

    handleAdd = (): void => {
        this.props.onAdd?.();
    };

    get scrollbarNodeProps() {
        return {
            ref: this.props.scrollRef
        };
    }

    getDeleteItemName = (): string => {
        return this.props.sections?.find(section => section.children?.find(child => child.id === this.state.confirmationDialogId))?.children?.find(child => child.id === this.state.confirmationDialogId)?.name;
    };

    render() {
        return (
            <>
                <BreadCrumbProvider/>
                <View hotspotContextId={`objectListPage-${this.props.id}`}
                      className={this.props.className}>
                    <ViewHeader title={this.props.title}
                                shouldHideVariant/>

                    <Toolbar>
                        <WriteLineWrapper>
                            <WriteLine value={this.props.searchTerm}
                                       width="240px"
                                       placeholder={this.props.t("Common:General.Search")}
                                       textAlign={TextAlign.Left}
                                       onChange={this.handleFilterChange}/>
                        </WriteLineWrapper>
                        <IconButton title={this.props.t("Common:General.Create")}
                                    hotspotId={TableButtonsAction.Add}
                                    onClick={this.handleAdd}>
                            <AddIcon width={IconSize.M} isLight={true}/>
                        </IconButton>
                    </Toolbar>
                    <ScrollBar primary
                               scrollableNodeProps={this.scrollbarNodeProps}
                               style={scrollBarStyles}>
                        <ObjectList
                            listId={this.props.id}
                            sections={this.props.sections}
                            onTriggerAction={this.handleAction}
                        />
                    </ScrollBar>
                </View>
                {this.state.confirmationDialogId &&
                    <Dialog
                        onConfirm={this.handleConfirmAccept}
                        onClose={this.handleConfirmCancel}
                        isConfirmation
                        busy={this.state.isConfirmationDialogBusy}
                        footer={<ConfirmationButtons onConfirm={this.handleConfirmAccept}
                                                     onCancel={this.handleConfirmCancel}
                                                     useWrapper={false}/>}>
                        {this.props.t("Common:General.DeleteMessage", { item: this.getDeleteItemName() })}
                    </Dialog>
                }
            </>
        );
    }
}

export default withTranslation(["Common", "Components", "Error"])(ObjectListPage);