import React from "react";
import { StyledImage } from "./ImageUploader.styles";
import memoizeOne from "../../utils/memoizeOne";
import { TImage } from "./ImageUploader";

export interface IProps {
    image: TImage;
    onImageError?: () => void;
    alt?: string;
    className?: string;
    style?: React.CSSProperties;
}

export default class ImageUploaderImage extends React.PureComponent<IProps> {
    static defaultProps: Partial<IProps> = {
        alt: ""
    };

    _fileImgUrl?: string;
    getUrl = memoizeOne((image: TImage) => {
        if (image instanceof File) {
            if (this._fileImgUrl) {
                URL.revokeObjectURL(this._fileImgUrl);
            }

            this._fileImgUrl = URL.createObjectURL(image);

            return this._fileImgUrl;
        } else {
            return image;
        }
    });

    componentWillUnmount() {
        if (this._fileImgUrl) {
            URL.revokeObjectURL(this._fileImgUrl);
        }
    }

    render() {
        return (
            <StyledImage src={this.getUrl(this.props.image)}
                         alt={this.props.alt}
                         onError={this.props.onImageError}
                         className={this.props.className}
                         style={this.props.style}
            />
        );
    }
}