import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import { REST_API_URL } from "../../../constants";
import { AppContext } from "../../../contexts/appContext/AppContext.types";
import { TableStorage } from "../../../model/TableStorage";
import BindingContext from "../../../odata/BindingContext";
import { getDocumentExportFileName, IGetExportFileNameProps } from "../../documents/Document.utils";
import SendMessageDialog, { IDataBoxSettings, IEmailSettings } from "../../messages/SendMessageDialog";
import { IBalanceSheetCustomData } from "../balanceSheet/BalanceSheet.utils";
import { CommonReportProps } from "../CommonDefs";
import { PrintReportType } from "../Report.utils";
import { getIncomeStatementExportUrl } from "./IncomeStatement";
import { IncomeStatementProps, IncomeStatementStructure } from "./IncomeStatementDef";


interface IProps extends WithTranslation {
    storage: TableStorage<unknown, IBalanceSheetCustomData>;
    onAfterSend?: () => void;
}

class SendIncomeStatementDialog extends React.Component<IProps> {
    static contextType = AppContext;
    //sadly, breaks typescript type checking
    //context: React.ContextType<typeof AppContext>;

    get message() {
        const company = this.context.getCompany();
        return (<>Vážená paní / Vážený pane,<br/><br/>
            v příloze Vám zasíláme výsledovku společnosti {company.LegalName}, prosím podívejte se.<br/><br/>
        </>);
    }

    emailSettings: IEmailSettings = {
        sendMessageUrl: `${REST_API_URL}/Dispatch/report/${this.reportType}/EMAIL`
    };

    dataBoxSettings: IDataBoxSettings = {
        isReport: true,
        sendMessageUrl: `${REST_API_URL}/Dispatch/report/${this.reportType}/DATABOX`
    };

    filename = "";

    componentDidMount() {
        const company = this.context.getCompany();
        this.emailSettings = {
            ...this.emailSettings,
            defaultEmail: company?.CommunicationContact?.Email
        };

        this.dataBoxSettings = {
            ...this.dataBoxSettings,
            defaultId: company.FinancialAdministration?.DataBoxId
        };
    }

    onAfterOpen = async () => {
        const { storage } = this.props;
        const isFull = storage.getCustomData().messageReportType === "full";
        const opts: IGetExportFileNameProps = {
            queryParams: {
                dateRange: storage.data.entity[CommonReportProps.dateRange]
            },
            isFull: (!isFull).toString()
        };
        this.filename = await getDocumentExportFileName(this.context, "report", this.reportType, opts);

        this.forceUpdate();
    };

    get reportType() {
        const statementStructure = this.props.storage.data.entity[IncomeStatementProps.incomeStatementStructure];
        return statementStructure === IncomeStatementStructure.ByNatureOfExpense ? PrintReportType.IncomeStatementByNature : PrintReportType.IncomeStatementByFunction;
    }

    handleCloseDialog = () => {
        this.props.storage.setCustomData({ sendingMessageDialogOpen: false });
        this.forceUpdate();
    };

    render = () => {
        if (!this.props.tReady || !this.props.storage?.getCustomData().sendingMessageDialogOpen) {
            return null;
        }
        const company = this.context.getCompany();
        const fullReport = this.props.storage.getCustomData().messageReportType === "full";
        const subject = `${company.LegalName}: ${fullReport ? this.props.t("DataBox:SendDialog.FullIncomeStatement") : this.props.t("DataBox:SendDialog.ShortIncomeStatement")}`;
        const dateRange = this.props.storage.data.entity[CommonReportProps.dateRange];

        const additionalRequestData = {
            BasicView: !fullReport,
            DateRangeCode: dateRange
        };

        const fileSettings = [{
            previewUrl: getIncomeStatementExportUrl(this.props.storage, !fullReport),
            name: this.filename,
            fileId: BindingContext.NEW_ENTITY_ID_PROP as unknown as number,
            shouldHideCheckBox: true
        }];

        return (
            <SendMessageDialog
                title={this.props.t("DataBox:SendDialog.SendIncomeStatement")}
                message={this.message}
                addSignature={true}
                subject={subject}
                handleClose={this.handleCloseDialog}
                onAfterOpen={this.onAfterOpen}
                emailSettings={this.emailSettings}
                fileSettings={fileSettings}
                dataBoxSettings={this.dataBoxSettings}
                onAfterSend={this.props.onAfterSend}
                additionalRequestData={additionalRequestData}
            />
        );
    };
}

export default withTranslation(["DataBox"], { withRef: true })(SendIncomeStatementDialog);