import { IFieldDefFn, IGetValueArgs } from "@components/smart/FieldInfo";
import {
    FilterBarGroup,
    getDefaultFilterGroupDef,
    IFilterGroupDef
} from "@components/smart/smartFilterBar/SmartFilterBar.types";
import { ISummaryItem } from "@components/smart/smartSummaryItem/SmartSummaryItem";
import {
    EntitySetName,
    EntityTypeName,
    GeneralPermissionEntity,
    GeneralRoleEntity,
    GeneralRolePermissionEntity
} from "@odata/GeneratedEntityTypes";
import i18next from "i18next";
import React from "react";

import { BasicInputSizes, FieldType, Sort } from "../../../enums";
import { Model } from "../../../model/Model";
import BindingContext from "../../../odata/BindingContext";
import { IFormDef } from "../../../views/formView/Form";
import { FormStorage } from "../../../views/formView/FormStorage";
import FormView from "../../../views/formView/FormView";
import { ISplitPageTableDef } from "../../../views/table/TableView.utils";
import { setDefByEntityType } from "../../getDefByEntityType";
import { getItemBreadCrumbsText, IDefinition, IGetDefinition } from "../../PageUtils";
import AssignedUsersTable from "../generalRoles/AssignedUsersTable";
import PermissionsTable from "./PermissionsTable";

export const getDefinitions: IGetDefinition = (): IDefinition => {
    const filterBarDef: IFilterGroupDef[] = [{
        ...getDefaultFilterGroupDef(FilterBarGroup.Filters),
        defaultFilters: [
            "GeneralRoleName",
            "UserCount"
        ],
        filterDefinition: {
            GeneralRoleName: {},
            UserCount: {}
        },
        isValueHelp: true,
        allowCustomFilters: false
    }];

    const table: ISplitPageTableDef = {
        id: `${EntityTypeName.GeneralRole}Table`,
        filterBarDef,
        columns: [
            "GeneralRoleName",
            "UserCount"
        ],
        columnDefinition: {
            GeneralRoleName: {
                formatter: val => {
                    return {
                        value: <b>{val}</b>,
                        tooltip: val as string
                    };
                }
            },
            UserCount: {}
        },
        title: i18next.t("GeneralRoles:Title"),
        initialSortBy: [{ id: "GeneralRoleName", sort: Sort.Asc }]
    };

    const summary: ISummaryItem[] = [{
        id: "GeneralRoleName"
    }];

    const form: IFormDef = {
        id: `${EntityTypeName.GeneralRole}Form`,
        translationFiles: getDefinitions.translationFiles,
        formControl: FormView,
        isDeletable: true,
        summary,
        getItemBreadCrumbText: (storage: Model) =>
                getItemBreadCrumbsText(storage, i18next.t("GeneralRoles:NewRole"), storage.data.entity?.GeneralRoleName),
        title: i18next.t("GeneralRoles:FormTitle"),
        additionalProperties: [],
        isReadOnly: (args: IGetValueArgs) => {
            return args.data?.Id < 0;
        },
        fieldDefinition: {
            "GeneralRoleName": {
                width: BasicInputSizes.L
            },
            [BindingContext.localContext("AssignedUsers")]: {
                type: FieldType.Custom,
                render: (args: IFieldDefFn) => {
                    return (
                        <AssignedUsersTable storage={args.storage as FormStorage}/>
                    );
                }
            },
            [BindingContext.localContext("Permissions")]: {
                type: FieldType.Custom,
                additionalProperties: [
                    {
                        id: GeneralRoleEntity.GeneralRolePermissions,
                        additionalProperties: [
                            {
                                id: GeneralRolePermissionEntity.Permission,
                                additionalProperties: [
                                    { id: GeneralPermissionEntity.Name },
                                    { id: GeneralPermissionEntity.Code },
                                    { id: GeneralPermissionEntity.Description }
                                ] },
                            { id: GeneralRolePermissionEntity.IsEnabled }
                        ]
                    }],
                render: (args: IFieldDefFn) => {
                    return (
                        <PermissionsTable storage={args.storage as FormStorage} roleId={args.storage.data.entity.Id}/>
                    );
                },
                isVisible: (args: IGetValueArgs) => {
                    const { bindingContext, entity } = args.storage.data;
                    return bindingContext.isNew() || entity.GeneralRolePermissions?.length;
                }
            },
        },
        groups: [{
            id: "general",
            rows: [[{ id: GeneralRoleEntity.GeneralRoleName }]]
        }, {
            id: "users",
            title: i18next.t("GeneralRoles:AssignedUsers"),
            rows: [[{ id: BindingContext.localContext("AssignedUsers") }]],
            customizationData: {
                isLocked: true
            }
        }, {
            id: "permissions",
            title: i18next.t("GeneralRoles:Permissions"),
            rows: [[{ id: BindingContext.localContext("Permissions") }]],
            customizationData: {
                isLocked: true
            }
        }]
    };

    return {
        entitySet: EntitySetName.GeneralRoles,
        table,
        form
    };
};

getDefinitions.translationFiles = ["GeneralRoles"];
setDefByEntityType(EntityTypeName.GeneralRole, getDefinitions);