import React from "react";
import { ReadOnlyGroupToggle, StyledReadOnlyGroup } from "./FilterBar.styles";
import { IFilterBarReadOnlyItem } from "./FilterBar";
import { EMPTY_VALUE } from "../../constants";
import { WithTranslation, withTranslation } from "react-i18next";
import Clickable from "../clickable";
import TestIds from "../../testIds";
import { getFilterBarItemRenderValue } from "./FilterBar.utils";
import { Model } from "../../model/Model";
import FilterBarItemReadOnly from "./FilterBarItemReadOnly";

interface IState {
    expandedItems: string[];
    isAllExpanded: boolean;
}

interface IProps {
    groups: IFilterBarReadOnlyItem[][];
    maxRows: number;
    // uses bigger font
    isBig?: boolean;
    hideFilters?: string[];
}

class ReadOnlyGroup extends React.PureComponent<IProps & WithTranslation, IState> {
    state: IState = {
        expandedItems: [],
        isAllExpanded: false
    };

    _ref = React.createRef<HTMLDivElement>();
    _toggleRef = React.createRef<HTMLSpanElement>();

    _storage: Model;

    componentDidMount() {
        this._updateToggleStyles();
    }

    componentDidUpdate(prevProps: Readonly<IProps & WithTranslation>, prevState: Readonly<IState>, snapshot?: any) {
        this._updateToggleStyles();
    }

    componentWillUnmount() {
        this._storage?.removeRef(this);
    }

    _updateToggleStyles() {
        const div = this._ref.current,
            toggle = this._toggleRef.current;

        if (div && toggle) {
            const overflowed = div.offsetHeight < div.scrollHeight;

            // Change position and left padding to render the toggle over text in bottom right corner
            toggle.style.paddingLeft = overflowed ? "1.5em" : "0";
            toggle.style.position = overflowed ? "absolute" : "relative";
        }
    }

    isExpanded(itemId: string) {
        return this.state.isAllExpanded || this.state.expandedItems.includes(itemId);
    }

    // handles expand of single filter value
    handleExpand = (id: string) => {
        this.setState(({ expandedItems }) => ({
            expandedItems: [...(expandedItems || []), id],
            isAllExpanded: false
        }));
    };

    // handles expand of whole tab group
    handleToggleExpanded = (hasHiddenItems: boolean) => {
        this.setState({
            expandedItems: [],
            isAllExpanded: hasHiddenItems
        });
    };

    render() {
        let isFirstItem = true;
        let hiddenCount = 0;
        let anyItemCanHideValue = false;

        const groupFields = this.props.groups.map((group, i) => {
            return group
                .filter(item => !this.props.hideFilters?.includes(item.bindingContext?.getNavigationPath()))
                .map((item, j) => {
                    const {
                        mayHideValues,
                        hiddenValues,
                        loadEnumsResult,
                        value
                    } = getFilterBarItemRenderValue(item, this.isExpanded.bind(this));

                    if (loadEnumsResult && loadEnumsResult.notLoadedNamespaces.length > 0) {
                        loadEnumsResult.namespacesPromise.then(() => this.forceUpdate());
                    }

                    if (item.storage) {
                        // sometimes (e.g. when SmartSelect loads its values) we need to rerender SmartFilterBarItemReadOnly
                        // for that, its bindingContext has to be registered!!!!
                        // ToDo: maybe remove once https://solitea-cz.atlassian.net/browse/DEV-3145 is fixed.
                        //  We currently doesn't support multiselect in filters, the implementation is therefore a little bit hacky and buggy :-/
                        item.storage?.addRef(this, item.bindingContext);
                        // we for later ref removal...
                        this._storage = item.storage;
                    }

                    if (!value) {
                        return null;
                    }

                    if (hiddenValues) {
                        hiddenCount++;
                    }
                    anyItemCanHideValue = anyItemCanHideValue || mayHideValues;


                    const render = (
                        <FilterBarItemReadOnly
                            id={item.bindingContext?.toString()}
                            value={value}
                            label={item.label}
                            description={item.description}
                            isFirst={isFirstItem}
                            onExpand={this.handleExpand}
                            hiddenItems={hiddenValues}
                            key={`${i.toString()}:${j.toString()}`}/>
                    );

                    isFirstItem = false;

                    return render;
                }).filter(item => item);
        });

        const isEmpty = groupFields.every(group => group.length === 0);
        if (isEmpty) {
            // if tab fields are empty, it should be rendered as 3 dashes
            groupFields.push([<React.Fragment key={EMPTY_VALUE}>{EMPTY_VALUE}</React.Fragment>]);
        }

        return (
            <StyledReadOnlyGroup ref={this._ref}
                                 className={isEmpty ? "empty" : ""}
                                 fontSize={this.props.isBig ? 18 : 14}
                                 _maxRows={!!hiddenCount ? this.props.maxRows : undefined}
                                 data-testid={TestIds.FilterReadOnlyGroup}>
                {groupFields}
                {anyItemCanHideValue &&
                <>
                    {/* Keep unbreakable space + space so it could be rendered at the start of row and also with enough space after text */}
                    &nbsp; <ReadOnlyGroupToggle ref={this._toggleRef}>
                    <Clickable onClick={this.handleToggleExpanded.bind(this, !!hiddenCount)}>
                        {this.props.t(`Components:FilterBar.${hiddenCount ? "ShowWholeText" : "HideText"}`)}
                    </Clickable>
                </ReadOnlyGroupToggle>
                </>
                }
            </StyledReadOnlyGroup>
        );
    }
}

export default withTranslation(["Common", "Components"])(ReadOnlyGroup);