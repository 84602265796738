import styled from "styled-components/macro";
import { T_TITLE_2, T_TITLE_4_NORMAL } from "../../global.style";

export const TitleStyled = styled.div`
    ${T_TITLE_2};
`;

export const StyledSubtitle = styled.p`
    ${T_TITLE_4_NORMAL};
`;

export const StyledErrorValue = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
`;

export const HeaderStyled = styled.div`
    display: flex;
    justify-content: space-between;
`;
