import React from "react";
import SelectionTableViewBase from "../../../components/smart/SelectionTableViewBase";
import { withBusyIndicator } from "@components/busyIndicator/withBusyIndicator";

class PickExtraSalaryTableView extends SelectionTableViewBase {
        get secondaryTitle(): string {
            return this.props.storage.t("ExtraSalary:SelectionTableView.Title");
        }

    get selectionConfirmText(): string {
        return this.props.storage.t("ExtraSalary:SelectionTableView.Complete");
    }

    get successMessage(): string {
        return this.props.storage.t("ExtraSalary:SelectionTableView.Success");
    }
}

export default withBusyIndicator({ passBusyIndicator: true })(PickExtraSalaryTableView);
