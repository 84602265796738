import React, { useCallback } from 'react';
import { ICheckboxChange } from "@components/inputs/checkbox";
import { CheckboxStyled } from "../LoginPage.styles";

export interface ILoginCheckboxProps {
    value: boolean;
    onChange: (val: boolean) => void;
    label: React.ReactNode;
}

const LoginCheckbox: React.FC<ILoginCheckboxProps> = ({ value, onChange, label }) => {

    const handleChange = useCallback((args: ICheckboxChange) => {
        onChange(args.value);
    }, [onChange]);

    return (
            <CheckboxStyled checked={value} onChange={handleChange} label={label}/>
    );
};

export default LoginCheckbox;