import React, { useCallback } from "react";
import {
    Background,
    Label,
    Line,
    Mark,
    MarkLabel,
    MarksLabels,
    StyledSlider,
    StyledThumb,
    ValueRange
} from "./Slider.styles";
import { ReactComponent as ThumbSvg } from "./thumb.svg";
import animationFrameThrottle from "../../utils/animationFrameThrottle";
import { TRecordType } from "../../global.types";
import { clamp, getClosestValueInRange } from "@utils/general";
import { range } from "lodash";
import { KeyName } from "../../keyName";
import TestIds from "../../testIds";
import memoize from "../../utils/memoize";

export enum SliderLabel {
    Hidden = "Hidden",
    Visible = "Visible",
    VisibleOnHover = "VisibleOnHover"
}

export enum SliderMarks {
    Hidden = "Hidden",
    Visible = "Visible",
    FirstAndLast = "FirstAndLast"
}

export enum SliderMarksLabels {
    Hidden = "Hidden",
    Visible = "Visible",
    FirstAndLast = "FirstAndLast"
}

export interface IThumb {
    id: string;
    value: number;
}

export interface IProps {
    // start of the colored part
    from: number;
    // end of the colored part
    to: number;

    thumbs: IThumb[];
    onThumbValueChange: (id: string, value: number, dragEnd: boolean) => string | void;

    min: number;
    max: number;
    step: number;

    labelFormatter?: (value: number) => string;
    marksFormatter?: (markValue: number) => string;

    labelMode: SliderLabel;
    marksMode: SliderMarks;
    marksLabelsMode: SliderMarksLabels;

    width: string;
}


export default class SliderBase extends React.PureComponent<IProps> {
    lineRef = React.createRef<HTMLDivElement>();
    valueRangeRef = React.createRef<HTMLDivElement>();
    thumbsRefs: TRecordType<HTMLDivElement> = {};
    lastMoveValue: number = null;

    getClosestThumb = (val: number) => {
        return this.props.thumbs.reduce((closestThumb, currentThumb) => {
            if (!closestThumb || Math.abs(currentThumb.value - val) < Math.abs(closestThumb.value - val)) {
                return currentThumb;
            }

            return closestThumb;

        }, null);
    };

    handleLineMouseDown = (event: React.MouseEvent) => {
        this.handleMouseDown(
            this.getClosestThumb(this.domPositionToValue(event.clientX)).id,
            event
        );
    };

    handleLineTouchStart = (event: React.TouchEvent) => {
        this.handleTouchStart(
            this.getClosestThumb(this.domPositionToValue(event.touches[0].clientX)).id,
            event
        );
    };

    handleMouseDown = (thumbId: string, event: React.MouseEvent) => {
        if (event.button !== 0) {
            return;
        }

        // prevent mouse selection
        event.preventDefault();
        // prevent multiple thumbs mouse down
        event.stopPropagation();

        this.handlePosChange(thumbId, event.clientX);

        document.addEventListener("mousemove", this.handleMouseMove(thumbId));
        document.addEventListener("mouseup", this.handleMouseUp(thumbId));
    };

    // keep reference for add/removeEventListener by memoizing the handlers for 'id'
    handleMouseMove = memoize((thumbId: string) => {
        return (event: MouseEvent): void => {
            event.preventDefault();

            this.handlePosChange(thumbId, event.clientX);
        };
    });

    handleMouseUp = memoize((thumbId: string) => {
        return (event: MouseEvent) => {
            this.handleDragEnd(thumbId);

            document.removeEventListener("mousemove", this.handleMouseMove(thumbId));
            document.removeEventListener("mouseup", this.handleMouseUp(thumbId));
        };
    });

    handleTouchStart = (thumbId: string, event: React.TouchEvent) => {
        this.handlePosChange(event.touches[0].clientX);

        document.addEventListener("touchmove", this.handleTouchMove(thumbId));
        document.addEventListener("touchend", this.handleTouchEnd(thumbId));
    };

    handleTouchMove = memoize((thumbId: string) => {
        return (event: TouchEvent): void => {
            this.handlePosChange(thumbId, event.touches[0].clientX);
        };
    });

    handleTouchEnd = memoize((thumbId: string) => {
        return (event: TouchEvent) => {
            this.handleDragEnd(thumbId);

            document.removeEventListener("touchmove", this.handleTouchMove(thumbId));
            document.removeEventListener("touchend", this.handleTouchEnd(thumbId));
        };
    });

    handleDragEnd = (thumbId: string) => {
        this.setThumbValue(thumbId, this.lastMoveValue);
        this.lastMoveValue = null;
    };

    handlePosChange = animationFrameThrottle((thumbId: string, dragPos: number) => {
        this.lastMoveValue = this.getClosestValue(
            this.domPositionToValue(dragPos)
        );
        const thumbRef = this.thumbsRefs[thumbId];

        thumbRef.focus();

        this.props.onThumbValueChange?.(thumbId, this.lastMoveValue, false);
        // this.updatePosWithoutRerender(thumbId, this.lastMoveValue);
    });

    // performance optimization
    // can be used in 'handlePosChange' instead of calling firing 'onThumbValueChange' every time,
    // thus changing state of the parent component and rerendering slider everytime
    // this changes the DOM directly
    updatePosWithoutRerender = (thumbId: string, value: number) => {
        const percentValue = this.valueToPercentage(value);
        const thumbRef = this.thumbsRefs[thumbId];

        thumbRef.style.left = percentValue;
        thumbRef.children[1].textContent = this.getLabel(value);

        const sortedThumbs: IThumb[] = [
            ...this.props.thumbs.filter(thumb => thumb.id !== thumbId),
            { id: thumbId, value: this.lastMoveValue }
        ];


        sortedThumbs.sort((thumb1, thumb2) => thumb1.value - thumb2.value);

        const left = sortedThumbs.length > 1 ? this.valueToPercentage(sortedThumbs[0].value) : "0";
        const right = `calc(100% - ${this.valueToPercentage(sortedThumbs[sortedThumbs.length - 1].value)})`;

        this.valueRangeRef.current.style.clipPath = `polygon(${left} 0, calc(100% - ${right}) 0, calc(100% - ${right}) 100%, ${left} 100%)`;
    };

    handleKeyDown = (thumbId: string, event: React.KeyboardEvent) => {
        switch (event.key) {
            case KeyName.ArrowUp:
            case KeyName.ArrowRight:
                event.preventDefault();
                this.setThumbValue(thumbId, this.getCurrentThumbValue(thumbId) + this.props.step);
                break;
            case KeyName.ArrowDown:
            case KeyName.ArrowLeft:
                event.preventDefault();
                this.setThumbValue(thumbId, this.getCurrentThumbValue(thumbId) - this.props.step);
                break;
            case KeyName.Home:
                event.preventDefault();
                this.setThumbValue(thumbId, this.props.min);
                break;
            case KeyName.End:
                event.preventDefault();
                this.setThumbValue(thumbId, this.props.max);
                break;
        }
    };

    getCurrentThumbValue = (thumbId: string) => {
        const thumb = this.props.thumbs.find(thumb => thumb.id === thumbId);

        return clamp(thumb.value, this.props.min, this.props.max);
    };

    setThumbValue = (thumbId: string, value: number) => {
        const thumbFocusId = this.props.onThumbValueChange?.(
            thumbId, clamp(value, this.props.min, this.props.max),
            true
        );

        // onThumbValueChange can return thumbId in case we need to change focus to another one, after the change
        // this happens when one of the thumbs cross over the other
        // because element stays the same when being dragged
        // but changes its position after rerender - From thumb is always the left one To thumb the right one
        if (thumbFocusId) {
            this.thumbsRefs[thumbFocusId].focus();
        }
    };

    getClosestValue = (position: number) => {
        return getClosestValueInRange(position, this.props.min, this.props.max, this.props.step);
    };

    domPositionToValue = (position: number) => {
        const backgroundRect = this.lineRef.current.getBoundingClientRect();
        const normalizedPos = clamp(position - backgroundRect.x, 0, backgroundRect.width);

        return this.percentageToValue(normalizedPos * 100 / backgroundRect.width);
    };

    valueToPercentage = (value: number) => {
        return `${
            clamp(
                (value - this.props.min) * 100 / this.getMaxRange(),
                0,
                100
            )}%`;
    };

    percentageToValue = (percent: number) => {
        return (percent * this.getMaxRange() / 100) + this.props.min;
    };

    getMaxRange = () => {
        return this.props.max - this.props.min;
    };

    getLabel = (value: number): string => {
        return this.props.labelFormatter?.(value) ?? value.toString();
    };

    renderMarks = () => {
        if (this.props.marksMode === SliderMarks.Hidden) {
            return null;
        }

        const marks = [];
        let values: number[];

        if (this.props.marksMode === SliderMarks.FirstAndLast) {
            values = [this.props.min, this.props.max];
        } else {
            values = [this.props.min, ...range(this.props.min + this.props.step, this.props.max, this.props.step), this.props.max];
        }

        for (let i = 0; i < values.length; i++) {
            const val = values[i];

            marks.push(
                <Mark key={i}
                      pos={this.valueToPercentage(val)}
                      data-testid={TestIds.SliderMark}/>
            );
        }

        return marks;
    };

    renderMarksLabels = () => {
        if (this.props.marksLabelsMode === SliderMarksLabels.Hidden) {
            return null;
        }

        const labels = [];
        let values: number[];

        if (this.props.marksLabelsMode === SliderMarksLabels.FirstAndLast) {
            values = [this.props.min, this.props.max];
        } else {
            values = [this.props.min, ...range(this.props.min + this.props.step, this.props.max, this.props.step), this.props.max];
        }

        for (let i = 0; i < values.length; i++) {
            const val = values[i];

            labels.push(
                <MarkLabel key={i}
                           isFirst={i === 0}
                           pos={this.valueToPercentage(val)}
                           data-testid={TestIds.SliderMarkLabel}>{val}</MarkLabel>
            );
        }

        return (
            <MarksLabels>
                {labels}
            </MarksLabels>
        );
    };

    handleThumbRef = memoize((thumbId: string) => {
        return (ref: HTMLDivElement) => {
            this.thumbsRefs[thumbId] = ref;
        };
    });

    renderThumbs = () => {
        return (
            this.props.thumbs.map(thumb => {
                const pos = this.valueToPercentage(thumb.value);

                return (
                    <Thumb id={thumb.id}
                           key={thumb.id}
                           pos={pos}
                           onMouseDown={this.handleMouseDown}
                           onTouchStart={this.handleTouchStart}
                           onKeyDown={this.handleKeyDown}
                           label={this.getLabel(thumb.value)}
                           labelMode={this.props.labelMode}
                           value={thumb.value}
                           min={this.props.min}
                           max={this.props.max}
                           ref={this.handleThumbRef(thumb.id)}/>
                );
            })

        );
    };

    render() {
        return (
            <StyledSlider width={this.props.width} 
                        data-testid={TestIds.Slider}>
                <Line ref={this.lineRef}
                      onMouseDown={this.handleLineMouseDown}
                      onTouchStart={this.handleLineTouchStart}
                >
                    <Background>
                        <ValueRange
                            left={this.valueToPercentage(this.props.from)}
                            right={`calc(100% - ${this.valueToPercentage(this.props.to)})`}
                            ref={this.valueRangeRef}/>
                    </Background>
                    {this.renderMarks()}
                    {this.renderThumbs()}
                </Line>
                {this.renderMarksLabels()}
            </StyledSlider>
        );
    }
}

export interface IThumbProps {
    id: string;
    pos: string;

    onMouseDown?: (id: string, e: React.MouseEvent) => void;
    onTouchStart?: (id: string, e: React.TouchEvent) => void;
    onKeyDown?: (id: string, e: React.KeyboardEvent) => void;

    label: string;
    labelMode: SliderLabel;

    // accessibility props
    value: number;
    min: number;
    max: number;
}

export const Thumb = React.forwardRef((props: IThumbProps, ref: React.Ref<HTMLDivElement>) => {
    const handleMouseDown = useCallback((e) => {
        props.onMouseDown(props.id, e);
    }, [props.id, props.onMouseDown]);

    const handleTouchStart = useCallback((e) => {
        props.onTouchStart(props.id, e);
    }, [props.id, props.onTouchStart]);

    const handleKeyDown = useCallback((e) => {
        props.onKeyDown(props.id, e);
    }, [props.id, props.onKeyDown]);

    return (
        <StyledThumb tabIndex={0}
                     pos={props.pos}
                     onMouseDown={handleMouseDown}
                     onTouchStart={handleTouchStart}
                     onKeyDown={handleKeyDown}
                     aria-valuemax={props.max}
                     aria-valuemin={props.min}
                     aria-valuenow={props.value}
                     aria-label={props.label}
                     title={props.label}
                     ref={ref}
                     data-testid={TestIds.SliderThumb}>
            <ThumbSvg/>
            {props.labelMode !== SliderLabel.Hidden &&
            <Label hoverOnly={props.labelMode === SliderLabel.VisibleOnHover} data-testid={TestIds.SliderThumbLabel}>
                {props.label}
            </Label>
            }
        </StyledThumb>
    );
});