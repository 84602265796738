import React from "react";
import {
    CloseIconButton,
    Content,
    ContentWrapper,
    OverflowWrapper,
    ShellHeader,
    StyledExtendedShell
} from "./ExtendedShell.style";
import { CloseIcon } from "@components/icon";
import { IconSize } from "../../enums";
import { AppContext } from "../../contexts/appContext/AppContext.types";
import { ScrollBar } from "../../components/scrollBar/ScrollBar";
import TestIds from "../../testIds";
import { WithTranslation, withTranslation } from "react-i18next";
import { composeRefHandlers, isDefined } from "@utils/general";
import { HOTSPOT_VIEW_ATTR } from "@components/hotspots/Hotspots.utils";

export interface IExtendedShellProps {
    show: boolean;
    onHide?: (hideDashboard?: boolean) => void;
    header?: React.ReactElement;
    hideScrollbar?: boolean;
    width?: number;
    panelRef?: React.RefObject<HTMLDivElement>;
    onMouseOver?: (e: React.MouseEvent) => void;
    onMouseLeave?: (e: React.MouseEvent) => void;
    onTransitionEnd?: () => void;
    extraContent?: React.ReactElement;
    hotspotContextId: string;
    padding?: string;
}

export interface IExtendedShellPropsForExtend extends Omit<IExtendedShellProps, "hotspotContextId"> {

}

class ExtendedShell extends React.Component<IExtendedShellProps & WithTranslation> {
    static contextType = AppContext;
    //sadly, breaks typescript type checking
    //context: React.ContextType<typeof AppContext>;

    _ref = React.createRef<HTMLDivElement>();

    handleClose = (): void => {
        this.props.onHide(false);
    };

    handleTransitionEnd = (e: React.TransitionEvent): void => {
        if (e.target === this._ref.current) {
            this.props.onTransitionEnd?.();
        }
    };

    render() {
        return (
            <StyledExtendedShell $show={this.props.show}
                                 $width={this.props.width}
                                 onMouseOver={this.props.onMouseOver}
                                 onMouseLeave={this.props.onMouseLeave}
                                 onTransitionEnd={this.handleTransitionEnd}
                                 ref={composeRefHandlers(this.props.panelRef, this._ref)}
                                 data-testid={TestIds.ShellbarContent}
                                 {...{ [HOTSPOT_VIEW_ATTR]: this.props.hotspotContextId }}>
                <OverflowWrapper>
                    <ContentWrapper $responsive={isDefined(this.props.width)}>
                        {this.props.onHide &&
                            <CloseIconButton isDecorative isLight
                                             title={this.props.t("Common:General.Close")}
                                             onClick={this.handleClose}>
                                <CloseIcon width={IconSize.M} ignoreTheme/>
                            </CloseIconButton>}
                        {this.props.header && (
                            <ShellHeader $padding={this.props.padding}>
                                {this.props.header}
                            </ShellHeader>
                        )}
                        {this.props.hideScrollbar ? this.props.children : (
                            <ScrollBar isInFlex>
                                <Content $hasHeader={!!this.props.header} $padding={this.props.padding}>
                                    {this.props.children}
                                </Content>
                            </ScrollBar>
                        )}
                    </ContentWrapper>
                </OverflowWrapper>
                {this.props.show && this.props.extraContent}
            </StyledExtendedShell>
        );
    }
}

const ExtendedShellExtended = withTranslation(["Common"])(ExtendedShell);

export { ExtendedShellExtended as ExtendedShell };