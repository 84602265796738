import { IRow } from "@components/table";
import { WebSocketMessageTypeCode } from "@odata/GeneratedEnums";
import { TWebsocketMessage } from "@utils/websocketManager/Websocket.types";
import { debounce } from "lodash";

import { ContextEvents, IAppContext } from "../../contexts/appContext/AppContext.types";
import { Status } from "../../enums";
import BindingContext from "../../odata/BindingContext";
import WebsocketManager from "../../utils/websocketManager/WebsocketManager";
import TableView from "../../views/table";
import { TableButtonsActionType } from "../../views/table/TableToolbar.utils";
import { ITableViewBaseProps } from "../../views/table/TableView";
import { currentUserIsCustomer } from "../admin/users/Users.utils";
import {
    checkTicketMessageNotification,
    getUnreadCount,
    hasUnreadMessage,
    ITicketsStorageCustomData
} from "./Tickets.utils";

interface IProps extends ITableViewBaseProps<ITicketsStorageCustomData> {

}

class TicketsTableView extends TableView<IProps> {
    _cancelWebsocketSubscription: () => void;

    constructor(props: IProps, context: IAppContext) {
        super(props);

        context.eventEmitter.on(ContextEvents.TicketsUnreadCount, this.refreshUnreadCount);

        this._cancelWebsocketSubscription = WebsocketManager.subscribe({
            callback: this.handleTicketNotification,
            types: [WebSocketMessageTypeCode.Notification]
        });
    }

    componentWillUnmount() {
        this.context.eventEmitter.off(ContextEvents.TicketsUnreadCount, this.refreshUnreadCount);
        this._cancelWebsocketSubscription();
    }

    refreshUnreadCount = debounce(async (bc?: BindingContext): Promise<void> => {
        const { storage } = this.props;
        const unreadTickets = await getUnreadCount(storage);
        storage.setCustomData({
            unreadTickets
        });
        if (bc) {
            // refresh only one row
            await storage.tableAPI.reloadRow(bc);
        } else {
            storage.tableAPI.forceUpdate();
        }
    }, 0);

    handleTableLoad(): void {
        super.handleTableLoad();
        this.refreshUnreadCount();
    }

    handleTicketNotification = async (message: TWebsocketMessage): Promise<void> => {
        const { storage } = this.props;
        const {
            shouldRefreshTable,
            shouldRefreshStream,
            ticketId
        } = await checkTicketMessageNotification(message, storage.oData);
        if (shouldRefreshTable) {
            // refresh tabs & table
            await Promise.all([
                this.updateSecondaryQueryValues(),
                storage.tableAPI.reloadTable()
            ]);
        } else if (shouldRefreshStream) {
            const bc = storage.data.bindingContext.addKey(ticketId);
            await this.refreshUnreadCount(bc);
        }
    };

    // sets rows, which contain unread messages as highlighted
    rowsFactory(rows: IRow[]): IRow[] {
        return rows.map(row => {
            if (!row) {
                return undefined;
            }

            const hasUnreadMessages = hasUnreadMessage(this.props.storage, row.customData.entity);
            row.statusHighlight = hasUnreadMessages ? Status.Warning : null;
            row.isBold = hasUnreadMessages;
            return row;
        });
    }

    getDisabledButtons(disableAll?: boolean): TableButtonsActionType[] {
        const buttons = super.getDisabledButtons(disableAll);
        const _currentUserIsCustomer = currentUserIsCustomer(this.context);
        if (!_currentUserIsCustomer) {
            // buttons.push(TableButtonsAction.Add);
        }
        return buttons;
    }
}

export default TicketsTableView;