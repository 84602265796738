import { BusyIndicatorSize } from "@components/busyIndicator/BusyIndicator.utils";
import { WebSocketMessageTypeCode } from "@odata/GeneratedEnums";
import { CustomerPortal } from "@pages/home/CustomerPortal.utils";
import { getDefaultPostParams } from "@utils/customFetch";
import fetchWithMiddleware, {
    TFetchMiddlewareAfter,
    TFetchMiddlewareBefore
} from "@utils/fetchWithMiddleware/FetchWithMiddleware";
import { LocalSettingsManager } from "@utils/LocalSettings";
import { logger } from "@utils/log";
import { handleRedirectResponse } from "@utils/RedirectUtils";
import React from "react";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";

import BusyIndicator from "../../components/busyIndicator";
import { API_URL, LOGIN_URL, SESSION_URL } from "../../constants";
import { HTTPStatusCode } from "../../enums";
import { EvalaDevelVersion, getEvalaVersion } from "../../global.utils";
import { ROUTE_LOGIN_TENANT } from "../../routes";
import memoizeOne from "../../utils/memoizeOne";
import WebsocketManager from "../../utils/websocketManager/WebsocketManager";
import {
    ApplicationSessionTypes,
    CHANGE_PASSWORD_URL,
    clearDataOnLogout,
    clearSessionCookies,
    EVALA_AUTHORIZED_HEADER,
    EVALA_VERSION_HEADER,
    EVALA_VERSION_WRONG,
    EvalaAuthorized,
    getLoginUrl,
    getLoginUrlWithRedirect,
    logout,
    SessionType
} from "./Auth.utils";

export const AuthContext = React.createContext<IAuthContext>(undefined);

export interface IAuthContext {
    isAuthenticated: boolean;
    sessionType: string;
    loginEmail: string;
    userId: number;
    tenantId: number;
    logout: () => Promise<void>;
    changePassword: (oldPassword: string, newPassword: string) => Promise<Response>;
    isSessionLost: boolean;
    // set to true if BE serves new version and FE files are not up to date
    isWrongEvalaVersion: boolean;
    error: string;
}

interface IState extends Pick<IAuthContext, "error" | "isAuthenticated" | "sessionType" | "loginEmail" | "userId" | "tenantId" | "isSessionLost" | "isWrongEvalaVersion"> {
    isLoaded: boolean;
    redirect: string;
}

// TODO move login/logout functionality here from login pages

const initialState: IState = {
    isAuthenticated: false,
    sessionType: null,
    loginEmail: null,
    userId: null,
    tenantId: null,
    isLoaded: false,
    redirect: null,
    isSessionLost: false,
    isWrongEvalaVersion: false,
    error: null
};

class AuthContextProvider extends React.PureComponent<RouteComponentProps, IState> {
    state: IState = initialState;
    _unsubscribeWebsocket: () => void;

    componentDidMount() {
        fetchWithMiddleware.useBefore(this.beforeFetchMiddleware);
        fetchWithMiddleware.useAfter(this.afterFetchMiddleware);

        window.addEventListener("focus", this.handleWindowFocus);

        this.fetchCurrentSession();
    }

    componentDidUpdate(prevProps: Readonly<RouteComponentProps>, prevState: Readonly<IState>) {
        if (this.state.isLoaded && !prevState.isLoaded) {
            // subscribe to websocket events only after session is loaded
            this._unsubscribeWebsocket = WebsocketManager.subscribe({
                callback: this.handleSessionTerminated,
                types: [WebSocketMessageTypeCode.SessionTerminated]
            });
        }
    }

    componentWillUnmount() {
        fetchWithMiddleware.removeBefore(this.beforeFetchMiddleware);
        fetchWithMiddleware.removeAfter(this.afterFetchMiddleware);

        this._unsubscribeWebsocket?.();
        window.removeEventListener("focus", this.handleWindowFocus);
    }

    handleWindowFocus = (event: FocusEvent): void => {
        // the session lost dialog is now shown on SessionTerminated websocket event
        // potential improvement -
        // we could try to fetch new session here in handleWindowFocus,
        // to try if the user has logged in to the same session
        // and remove the dialog if he did
    };

    handleSessionTerminated = (): void => {
        clearSessionCookies();
        clearDataOnLogout();
        this.setState({
            isSessionLost: true
        });
    };

    beforeFetchMiddleware: TFetchMiddlewareBefore = async (request: Request): Promise<Request> => {
        const url = request.url;

        const evalaVersion = getEvalaVersion();
        const link = typeof url === "string" ? url : (url as URL).pathname;

        // Inject Evala-Version header into every request
        // so that the server can check if the client is up to date.
        // If not, reload page.
        // only check Evala version for api calls,
        // caching for static files should be handled by webpack at build time (hash in file names)
        if (evalaVersion && evalaVersion !== EvalaDevelVersion && link.startsWith(API_URL)) {
            const headers = new Headers(request.headers);

            headers.set(EVALA_VERSION_HEADER, evalaVersion);
            request = new Request(request, {
                headers
            });
        }

        return request;
    };

    afterFetchMiddleware: TFetchMiddlewareAfter = async (originalRequest: Request, response: Response): Promise<void> => {
        if (response.status === HTTPStatusCode.Gone) {
            const body = await response.text();

            if (body === EVALA_VERSION_WRONG) {
                this.setState({
                    isWrongEvalaVersion: true
                });
            }
        }

        if (response.headers?.get(EVALA_AUTHORIZED_HEADER) === EvalaAuthorized.NoSession) {
            clearDataOnLogout();
            this.handleRedirectToLogin();
        }
    };

    handleRedirectToLogin = async (): Promise<void> => {
        const info = await getLoginUrl(getLoginUrlWithRedirect());
        if (info.isDomainRedirect) {
            window.location.href = info.url;
        } else {
            this.setState({
                redirect: info.url
            });
        }
    };

    fetchCurrentSession = async () => {
        let response = await fetchWithMiddleware.originalFetch(SESSION_URL);

        if (process.env.NODE_ENV !== "production" && !response.ok && response.status !== HTTPStatusCode.Forbidden) {
            // Try to log in first
            const loginResponse = await fetchWithMiddleware.originalFetch(`${LOGIN_URL}?noRedirect=true`);

            if (!loginResponse.ok) {
                //We cannot fast login. We are on production. // TODO This should be replaced by compilation option.
                this.setState({
                    isLoaded: true
                });

                // or backend just failed
                if (loginResponse.status >= 500) {
                    this.setState({
                        error: `${loginResponse.status},${loginResponse.statusText}`
                    });
                }

                return;
            } else {
                LocalSettingsManager.clear();
                CustomerPortal.isActive = false;
            }

            response = await fetchWithMiddleware.originalFetch(SESSION_URL);
            if (!response.ok) {
                logger.error("`Session doesn't exist, auto login failed with error code: ${response.status}`");
                // this.setErrorState(this.props.t("Common:Errors.CommunicationWithServerFailed"), `Session doesn't exist, auto login failed with error code: ${response.status}`);
                return;
            }
        }

        if (response.ok) {
            const responseJson = await response.json();

            if (responseJson.Type === SessionType.Customer) {
                CustomerPortal.isActive = true;
            }

            if (ApplicationSessionTypes.includes(responseJson.Type)) {
                this.setState({
                    isLoaded: true,
                    isAuthenticated: true,
                    sessionType: responseJson.Type,
                    loginEmail: responseJson.LoginEmail,
                    userId: responseJson.UserId,
                    tenantId: responseJson.TenantId
                });
            } else {
                this.setState({
                    redirect: ROUTE_LOGIN_TENANT
                });
            }
        } else if (response.status === HTTPStatusCode.Forbidden) {
            //Redirect response
            await handleRedirectResponse(response);
        } else if (response.status >= 500) {
            this.setState({
                error: `${response.status},${response.statusText}`
            });
        } else {
            this.handleRedirectToLogin();
        }
    };

    logout = async (): Promise<void> => {
        // because we are awaiting the logout method, it takes time
        // => SessionTerminated event can be received via WebSocket before redirecting to login page causing SessionInvalidatedDialog to render briefly
        // ==> terminate websocket first
        WebsocketManager.close();

        await logout(fetchWithMiddleware.originalFetch);

        const info = await getLoginUrl();
        if (info.isDomainRedirect) {
            window.location.href = info.url;
        } else {
            this.props.history.push(info.url);
        }
    };

    changePassword = async (oldPassword: string, newPassword: string): Promise<Response> => {
        const res = await fetch(CHANGE_PASSWORD_URL, {
            ...getDefaultPostParams(),
            body: JSON.stringify({
                CurrentPassword: oldPassword,
                NewPassword: newPassword
            })
        });

        return res;
    };

    getContext = memoizeOne((): IAuthContext => {
        return {
            isAuthenticated: this.state.isAuthenticated,
            sessionType: this.state.sessionType,
            loginEmail: this.state.loginEmail,
            userId: this.state.userId,
            tenantId: this.state.tenantId,
            logout: this.logout,
            changePassword: this.changePassword,
            isSessionLost: this.state.isSessionLost,
            isWrongEvalaVersion: this.state.isWrongEvalaVersion,
            error: this.state.error
        };
    }, () => [this.state]);

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect}/>;
        }

        if (!this.state.isLoaded) {
            return <BusyIndicator size={BusyIndicatorSize.L} isDelayed/>;
        }

        return (
            <AuthContext.Provider value={this.getContext()}>
                {this.props.children}
            </AuthContext.Provider>
        );
    }
}

export default withRouter(AuthContextProvider);