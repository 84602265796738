import { EntitySetName, ICurrencyWithExchangeRateInfoEntity } from "@odata/GeneratedEntityTypes";
import { CurrencyCode } from "@odata/GeneratedEnums";
import { OData } from "@odata/OData";
import { logger } from "@utils/log";
import { memoize } from "lodash";

export const loadCurrencyInfosMemoized = memoize(async (oData: OData) => {
    try {
        const res = await oData.getEntitySetWrapper(EntitySetName.CurrenciesWithExchangeRateInfo).query()
            .fetchData<ICurrencyWithExchangeRateInfoEntity[]>();
        return res.value;
    } catch (e) {
        logger.error("CurrenciesWithExchangeRateInfo request failed", e);
        return [];
    }
});

export const getCurrencyInfos = async (oData: OData, currencyCode: CurrencyCode): Promise<ICurrencyWithExchangeRateInfoEntity> => {
    const allInfos = await loadCurrencyInfosMemoized(oData);
    return allInfos?.find(info => info.Code === currencyCode);
};