import BusyIndicator from "@components/busyIndicator";
import { ButtonSize } from "@components/button/Button.utils";
import FocusManager, { FocusDirection } from "@components/focusManager";
import { PlusIcon } from "@components/icon";
import { ScrollBar } from "@components/scrollBar";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";

import { ROUTE_LOGIN, ROUTE_LOGIN_TENANT_REGISTER, ROUTE_LOGOUT } from "../routes";
import TestIds from "../testIds";
import { BackArrow } from "./components/BackArrow";
import Redirects from "./components/Redirects";
import TenantSelector from "./components/TenantSelector";
import { AnimationType, getLoginOptionsData, Id, ILoginOptions, LoginTranslationNamespaces } from "./Login.utils";
import {
    LoginTitle,
    NewTenantButtonStyled,
    TenantButtonWrapper,
    Tenants,
    TenantsBody,
    TenantScrollbarWrapper
} from "./LoginPage.styles";
import { useLoginDispatch, useLoginSelector } from "./state/hooks";
import { selectLoginOption, setAnimationType, setLoginOptions } from "./state/loginSlice";
import { loadPostLoginVerifications } from "./state/sessionSlice";

interface IProps {
}

const getOptionId = (opt: ILoginOptions) => {
    return opt.isCustomer ? `${opt.TenantId}-${opt.CompanyId}` : opt.TenantId;
};

const TenantPicker: React.FC<IProps> = () => {
    const { t } = useTranslation([...LoginTranslationNamespaces]);
    const dispatch = useLoginDispatch();
    const loginOption = useLoginSelector(selectLoginOption);

    const [loginOptionsList, setLoginOptionsList] = useState<ILoginOptions[]>([]);
    const [redirect, setRedirect] = useState<string>(null);
    const [isBusyLocal, setIsBusy] = useState<boolean>(true);

    const isBusy = isBusyLocal || (loginOption && !loginOption.isResolved);

    useEffect(() => {
        (async function loadData() {
            const loginOptions = await getLoginOptionsData();

            if (loginOptions) {
                setLoginOptionsList(loginOptions);
                await dispatch(loadPostLoginVerifications());

                if (loginOptions.length === 0) {
                    setRedirect(ROUTE_LOGIN_TENANT_REGISTER);
                }
            } else {
                setRedirect(ROUTE_LOGIN);
            }
            setIsBusy(false);
        })();
    }, []);

    useEffect(() => {
        dispatch(setAnimationType(AnimationType.StaticBiker));
    }, []);

    const handleTenantClicked = async (id: Id) => {
        if (isBusy) {
            return;
        }

        const option = loginOptionsList.find(opt => id === getOptionId(opt));
        dispatch(setLoginOptions(option));
    };

    const handleAddTenantClicked = () => {
        setRedirect(ROUTE_LOGIN_TENANT_REGISTER);
    };

    if (redirect) {
        return (
            <Redirect to={redirect}/>
        );
    } else {
        const hasAnyOption = loginOptionsList?.length > 0;
        const hasTenantOption = loginOptionsList?.find(item => !item.isCustomer);
        const isPureCustomer = hasAnyOption && !hasTenantOption;
        const showTitle = !isBusy || hasAnyOption;
        return (<>
            {isBusy && <BusyIndicator isDelayed/>}
            <Redirects skipActiveSessionCheck={true}/>
            <LoginTitle data-testid={TestIds.Title}>
                <BackArrow url={ROUTE_LOGOUT}/>
                {showTitle && t(`Login:TenantRegister.${isPureCustomer ? "PickTitleCompanies" : "PickTitle"}`)}
            </LoginTitle>
            <TenantsBody>
                <TenantScrollbarWrapper>
                    <ScrollBar style={{
                        overflowX: "hidden"
                    }}>
                        <FocusManager direction={FocusDirection.Vertical}
                            // todo disable (and the keyboard click)when busy?
                            // isDisabled={this.state.isBusy}
                        >
                            {({ itemProps, wrapperProps }) => (
                                <Tenants {...wrapperProps}
                                         ref={wrapperProps.ref as React.RefObject<HTMLDivElement>}>
                                    {loginOptionsList.map(opt => {
                                        const id = getOptionId(opt);
                                        return (
                                            <TenantSelector key={id}
                                                            id={id}
                                                            name={opt.CompanyName ?? opt.TenantName}
                                                            isActive={opt.IsTenantActive}
                                                            isCustomer={opt.isCustomer}
                                                            onClick={handleTenantClicked}
                                                            itemProps={itemProps}/>
                                        );
                                    })}
                                </Tenants>
                            )}
                        </FocusManager>
                    </ScrollBar>
                </TenantScrollbarWrapper>
                <TenantButtonWrapper>
                    <NewTenantButtonStyled onClick={handleAddTenantClicked}
                                           icon={<PlusIcon/>}
                                           size={ButtonSize.Big}
                                           isDisabled={isBusy}
                                           isTransparent>
                        {t("Login:TenantRegister.AddNew")}
                    </NewTenantButtonStyled>
                </TenantButtonWrapper>
            </TenantsBody>
        </>);
    }
};

export default TenantPicker;
