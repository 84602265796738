import styled, { css } from "styled-components/macro";
import { AvatarStyle } from "../../enums";
import { PropsWithTheme } from "../../theme";
import { LocalStackOrder } from "../../global.style";

interface IStyledAvatar {
    size: string;
    transparent: boolean;
    isRound: boolean;
    backgroundStyle: AvatarStyle;
}

function getBackgroundColor(props: IStyledAvatar & PropsWithTheme) {
    if (props.transparent || props.backgroundStyle === AvatarStyle.None) {
        return "transparent";
    } else if (props.backgroundStyle === AvatarStyle.Light) {
        return props.theme.C_AVATAR_BG_LIGHT;
    } else {
        return props.theme.C_AVATAR_BG_DARK;
    }
}

export const AvatarHover = css`
    content: "";
    position: absolute;
    top: -7px;
    left: -7px;
    width: calc(100% + 14px);
    height: calc(100% + 14px);
    border: solid 2px ${props => props.theme.C_ACT_hover_without_opacity};
    border-radius: 10px;
`;

export const AvatarSelected = css`
    &:after {
        ${AvatarHover};
        background-color: ${props => props.theme.C_ACT_hover_without_opacity};
        z-index: ${LocalStackOrder.BelowContent};
    }
`;

export const StyledAvatar = styled.div<IStyledAvatar>`
    position: relative;
    width: ${props => props.size};
    height: ${props => props.size};
    background-color: ${props => getBackgroundColor(props)};
    flex: 0 0 auto;
    border-radius: ${props => props.isRound ? "50%" : "3px"};

    & > img {
        border-radius: inherit;
    }
`;

export const Image = styled.img`
    height: 100%;
    width: 100%;
    object-fit: contain;
    display: block;
`;