import styled from "styled-components/macro";
import { T_TITLE_4_NORMAL } from "../../global.style";
import { TooltipIconCopyLink } from "../../components/tooltipIcon";

export const QrCodeWrapper = styled.div `
    display: flex;
`;

export const QrCodeDataWrapper = styled.div `
    margin-left: 20px;
    margin-top: 20px;
    ${T_TITLE_4_NORMAL};
`;

export const QrCodeDataColumn = styled.td `
    font-weight: bold;
    padding-bottom: 10px;
`;

export const TooltipIconCopyLinkQrCode = styled(TooltipIconCopyLink)`
    visibility: hidden;
`;

export const QrCodeDataValue = styled.td `
  padding-left: 20px;
  padding-bottom: 10px;

  &:hover ${TooltipIconCopyLinkQrCode} {
    visibility: visible;
  }
}
`;

export const QrCodeDataValueInner = styled.div `
    display: flex;
    justify-content: flex-end;
`;

export const QrCodeValue = styled.span `
    margin-right: 6px;
`;

export const IsPaidWrapper = styled.td `
    margin-left: auto;
    margin-bottom: 5px;
`;

