import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { IProps as IIconProps, VisibleFilledIcon, VisibleIcon } from "../../icon";

type TInputType = "password" | "text";

export interface IPasswordInputProps {
    type: TInputType;
    icon?: React.ComponentType<IIconProps>;
    iconTitle?: string;
    inputRef: React.Ref<HTMLInputElement>;
    onIconClick: () => void;
}

export interface WithPasswordInput {
    password: IPasswordInputProps;
}

export const withPasswordInput = <P extends WithPasswordInput>(Component: React.ComponentType<P>): React.ComponentType<Omit<P, keyof WithPasswordInput>> => {
    return React.forwardRef((props: Omit<P, keyof WithPasswordInput>, ref) => {
        const passwordInputProps = usePasswordInput();

        return (
            <Component password={passwordInputProps}
                       ref={ref}
                // cast props as per https://github.com/Microsoft/TypeScript/issues/28938#issuecomment-450636046
                       {...props as P}/>
        );
    }) as any;
};


export function usePasswordInput(): IPasswordInputProps {
    // use key to reset animation if setAlert is called again before previous animation ended
    const [type, setType] = useState<TInputType>("password");
    const [cursorPosition, setCursorPosition] = useState<number>(null);
    const icon = type === "text" ? VisibleFilledIcon : VisibleIcon;
    const { t } = useTranslation("Components");
    const inputRef = useRef<HTMLInputElement>();

    const handleIconClick = useCallback(() => {
        const newType = type === "text" ? "password" : "text";
        const newCursorPosition = inputRef.current.selectionStart;
        setType(newType);
        setCursorPosition(newCursorPosition);
    }, [type]);

    useEffect(() => {
        if (inputRef.current && cursorPosition !== null) {
            // for some reason, without timeout the cursor still jumps back to start
            if (document.activeElement !== inputRef.current) {
                inputRef.current.focus();
            }
            inputRef.current.selectionStart = cursorPosition;
        }
    }, [type]);

    return {
        type: type,
        icon,
        iconTitle: t(`Components:Password:${type === "text" ? "HidePassword" : "ShowPassword"}`),
        inputRef,
        onIconClick: handleIconClick
    };
}