import { IAlertProps } from "@components/alert/Alert";
import { IFormGroupDef } from "@components/smart/smartFormGroup/SmartFormGroup";
import { ISmartReportTableRow } from "@components/smart/smartTable";
import { filterChildRowsBy, updateRows } from "@components/smart/smartTable/SmartTable.utils";
import { ICellValueObject, IRow } from "@components/table";
import {
    EntitySetName,
    IReceivableAdjustmentTypeEntity,
    IRequiredAccountEntity,
    RequiredAccountEntity
} from "@odata/GeneratedEntityTypes";
import {
    ClearedStatusCode,
    CurrencyCode,
    DocumentLinkTypeCode,
    DocumentTypeCode,
    PostedStatusCode,
    ReceivableAdjustmentTypeCode,
    RequiredAccountTypeCode,
    VatStatementStatusCode,
    WriteOffTypeCode
} from "@odata/GeneratedEnums";
import { OData } from "@odata/OData";
import { transformToODataString } from "@odata/OData.utils";
import i18next from "i18next";
import React from "react";

import { REST_API_URL } from "../../../constants";
import { RowType, Status, ValueType } from "../../../enums";
import { ColoredText } from "../../../global.style";
import { formatCurrency } from "../../../types/Currency";
import memoizeOne from "../../../utils/memoizeOne";
import { IFormStorageDefaultCustomData } from "../../../views/formView/FormStorage";
import { getDocumentPairedTable } from "../../documents/Document.utils";
import {
    CustomCellContentWrapper,
    CustomCellCustomContent,
    CustomCellOriginalContent
} from "./ReceivableAdjustments.styles";

export const SMALL_ADJUSTMENT_MAX = 30000;
export const RECEIVABLE_ADJUSTMENTS_FORM_TAB = "ReceivableAdjustments";
const ADJUSTMENTS_URL = `${REST_API_URL}/ReceivableAdjustmentProcessing`;
export const PROCESS_ADJUSTMENTS_URL = `${ADJUSTMENTS_URL}/Process`;
export const REMOVE_ADJUSTMENTS_URL = `${ADJUSTMENTS_URL}/DeleteCurrent`;
export const REC_ADJ_LINK_TYPES = [
    DocumentLinkTypeCode.ReceivableAdjustment, DocumentLinkTypeCode.ReceivableAdjustmentSmall,
    DocumentLinkTypeCode.ReceivableAdjustmentCancellation,
    DocumentLinkTypeCode.DebtWriteOffTaxDeductible, DocumentLinkTypeCode.DebtWriteOffNotTaxDeductible
];
export const WRITE_OFF_REC_ADJ_TYPES = [ReceivableAdjustmentTypeCode.TaxWriteOff, ReceivableAdjustmentTypeCode.NontaxWriteOff];
export const REC_ADJ_TYPES_WITH_PARTIAL_AMOUNTS = [
    ...WRITE_OFF_REC_ADJ_TYPES,
    ReceivableAdjustmentTypeCode.ReceivableAdjustmentCancellationPer8ZOR
];

export interface IReceivableAdjustmentsCustomData extends IFormStorageDefaultCustomData {
    adjustmentTypeCode?: ReceivableAdjustmentTypeCode;
    adjustmentTypes?: IReceivableAdjustmentTypeEntity[];
    requiredAccounts?: IRequiredAccountEntity[];
}

export interface IProcessAdjustmentsParams {
    ReceivableAdjustmentTypeCode: string;
    DateAccountingTransaction: string;
    AccountAssignment: {
        SelectionCode?: string;
        ShortName?: string;
        Name?: string;
        CreditAccountId?: number;
        DebitAccountId: number;
        ChartOfAccountsId: number;
    };
    DocumentIds: number[];
    PartialAdjustments?: {
        DocumentId: number;
        Amount: number;
    }[];
}

export interface IRecAdjDocument {
    BusinessPartner_Id?: number;
    BusinessPartner_Name?: string;
    BusinessPartner_BusinessPartnerId?: number;
    MonthsAfterDateDue?: number;
    ReceivableAdjustment_AdjustmentAmount_Small?: number;
    ReceivableAdjustment_NumberOurs?: string;
    ReceivableAdjustment_AdjustmentAmount_Active?: number;
    ReceivableAdjustment_AdjustmentAmount_Total?: number;
    ReceivableAdjustmentInternalDocumentIds_Total?: string[];
    ReceivableAdjustmentInternalDocumentIds_Active?: string[];
    ReceivableAdjustment_Amount?: number;
    ReceivableAdjustment_AmountDue?: number;
    ReceivableAdjustment_CompanyId?: number;
    ReceivableAdjustment_CurrencyCode?: CurrencyCode;
    ReceivableAdjustment_DateDue?: string;
    ReceivableAdjustment_DocumentTypeCode?: DocumentTypeCode;
    DocumentStatus?: string;
    ReceivableAdjustment_PostedStatusCode?: PostedStatusCode;
    ReceivableAdjustment_ClearedStatusCode?: ClearedStatusCode;
    ReceivableAdjustment_VatStatementStatusCode?: VatStatementStatusCode;
    ReceivableAdjustment_Id: number;
    ReceivableAdjustment_WriteOffCode?: WriteOffTypeCode;
    ReceivableAdjustment_WriteOffName?: string;
    ReceivableAdjustment_WriteOffDateAccountingTransaction?: string;
    ReceivableAdjustment_WriteOffFiscalYearId?: number;
    ReceivableAdjustment_WriteOffId?: number;
    ReceivableAdjustment_WriteOffNumberOurs?: string;
}

export interface IPartialAdjustment {
    Id: number;
    NumberOurs: string;
    DocumentTypeCode: DocumentTypeCode;
    BusinessPartner_Name: string;
    BusinessPartner_Id: number;
    AmountDue: number;
    Amount: number;
    CurrencyCode: CurrencyCode;
}

export const getReceivableAdjustmentsTab = (isInternalDocument: boolean, isCbaCompany: boolean): IFormGroupDef => {
    // internal document shows different values in this tab than all other documents
    // https://solitea-cz.atlassian.net/browse/DEV-12168
    const linkTypes = isInternalDocument ? REC_ADJ_LINK_TYPES :
        [DocumentLinkTypeCode.ReceivableAdjustment, DocumentLinkTypeCode.ReceivableAdjustmentSmall, DocumentLinkTypeCode.ReceivableAdjustmentCancellation];

    return {
        id: RECEIVABLE_ADJUSTMENTS_FORM_TAB,
        title: i18next.t("Document:FormTab.ReceivableAdjustments"),
        table: getDocumentPairedTable({
            selectQuery: `TypeCode in (${transformToODataString(linkTypes, ValueType.String)})`,
            addAmountColumn: true,
            isCbaCompany
        })
    };
};

interface IGetDefaultAccAss {
    requiredAccounts: IRequiredAccountEntity[];
    adjustmentType: ReceivableAdjustmentTypeCode;
    adjustmentTypes: IReceivableAdjustmentTypeEntity[];
}

export const getDefaultAccAss = ({ requiredAccounts, adjustmentType, adjustmentTypes }: IGetDefaultAccAss) => {
    let debitAccount;
    let creditAccount;

    const adjustmentGroup = adjustmentTypes.find((at) => at.Code === adjustmentType)?.GroupCode;
    const requiredAccount = adjustmentGroup && requiredAccounts.find((ra) => ra.ReceivableAdjustmentGroupCode === adjustmentGroup);

    switch (adjustmentType) {
        case ReceivableAdjustmentTypeCode.ReceivableAdjustmentPer8cZORUnder30Thousand:
            debitAccount = "558";
            creditAccount = requiredAccount?.AccountNumber ?? "391004";
            break;
        case ReceivableAdjustmentTypeCode.ReceivableAdjustmentPer8aZOR50:
        case ReceivableAdjustmentTypeCode.ReceivableAdjustmentPer8aZOR100:
            debitAccount = "558";
            creditAccount = requiredAccount?.AccountNumber ?? "391002";
            break;
        case ReceivableAdjustmentTypeCode.ReceivableAdjustmentPer8ZORInsolvency:
            debitAccount = "558";
            creditAccount = requiredAccount?.AccountNumber ?? "391001";
            break;
        case ReceivableAdjustmentTypeCode.ReceivableAdjustmentCancellationPer8ZOR:
            debitAccount = "391";
            creditAccount = requiredAccount?.AccountNumber ?? "558";
            break;
        case ReceivableAdjustmentTypeCode.TaxWriteOff:
            debitAccount = "546001";
            break;
        case ReceivableAdjustmentTypeCode.NontaxWriteOff:
            debitAccount = "546009";
            break;
    }

    if (!debitAccount) {
        return null;
    }

    return {
        DebitAccount: debitAccount,
        CreditAccount: creditAccount
    };
};

const isDocumentWithUnder30PossiblyApplied = (doc: IRecAdjDocument) => {
    let isApplicable = true;
    const monthsAfterDueDate = doc.MonthsAfterDateDue;

    isApplicable = isApplicable && monthsAfterDueDate >= 12;
    isApplicable = isApplicable && monthsAfterDueDate < 30;
    isApplicable = isApplicable && doc.ReceivableAdjustment_AdjustmentAmount_Small > 0;


    return isApplicable;
};

export const isDocumentApplicable = (doc: IRecAdjDocument, adjustmentType: ReceivableAdjustmentTypeCode, isRemoving: boolean): boolean => {
    let isApplicable = !doc.ReceivableAdjustment_WriteOffCode || doc.ReceivableAdjustment_WriteOffCode === WriteOffTypeCode.No;

    if (isRemoving) {
        return doc.ReceivableAdjustment_AdjustmentAmount_Active > 0 || (!!doc.ReceivableAdjustment_WriteOffCode && doc.ReceivableAdjustment_WriteOffCode !== WriteOffTypeCode.No);
    }

    const monthsAfterDueDate = doc.MonthsAfterDateDue;

    switch (adjustmentType) {
        case ReceivableAdjustmentTypeCode.ReceivableAdjustmentPer8cZORUnder30Thousand:
            isApplicable = isApplicable && monthsAfterDueDate >= 12;
            isApplicable = isApplicable && monthsAfterDueDate < 30;
            isApplicable = isApplicable && doc.ReceivableAdjustment_Amount <= SMALL_ADJUSTMENT_MAX;
            isApplicable = isApplicable && !doc.ReceivableAdjustment_AdjustmentAmount_Total;
            break;
        case ReceivableAdjustmentTypeCode.ReceivableAdjustmentPer8aZOR50:
            isApplicable = monthsAfterDueDate >= 18 && monthsAfterDueDate < 30;
            isApplicable = isApplicable && (doc.ReceivableAdjustment_AdjustmentAmount_Total < doc.ReceivableAdjustment_AmountDue);
            isApplicable = isApplicable && (!doc.ReceivableAdjustment_AdjustmentAmount_Total || doc.ReceivableAdjustment_AdjustmentAmount_Total < doc.ReceivableAdjustment_AdjustmentAmount_Active);
            break;
        case ReceivableAdjustmentTypeCode.ReceivableAdjustmentPer8aZOR100:
            isApplicable = monthsAfterDueDate >= 30;
            isApplicable = isApplicable && (doc.ReceivableAdjustment_AdjustmentAmount_Total < doc.ReceivableAdjustment_AmountDue);
            isApplicable = isApplicable && (!doc.ReceivableAdjustment_AdjustmentAmount_Total || doc.ReceivableAdjustment_AdjustmentAmount_Total < doc.ReceivableAdjustment_AdjustmentAmount_Active);
            break;
        case ReceivableAdjustmentTypeCode.ReceivableAdjustmentCancellationPer8ZOR:
            isApplicable = doc.ReceivableAdjustment_AdjustmentAmount_Total > 0 && doc.ReceivableAdjustment_AdjustmentAmount_Total !== doc.ReceivableAdjustment_AdjustmentAmount_Active;
            break;
        case ReceivableAdjustmentTypeCode.ReceivableAdjustmentPer8ZORInsolvency:
            isApplicable = isApplicable && (!doc.ReceivableAdjustment_AdjustmentAmount_Total || doc.ReceivableAdjustment_AdjustmentAmount_Total < doc.ReceivableAdjustment_AmountDue);
            break;
        case ReceivableAdjustmentTypeCode.NontaxWriteOff:
        case ReceivableAdjustmentTypeCode.TaxWriteOff:
            break;
        default:
            isApplicable = true;
    }

    return isApplicable;
};

interface IEnhanceRowContent {
    row: ISmartReportTableRow;
    alert: IAlertProps;
}

const getOriginalBpRowValue = (row: ISmartReportTableRow): string => {
    const originalValue = row.values["BusinessPartner_Name"];
    const originalStringValue: string = (originalValue as ICellValueObject)?.tooltip as string ?? originalValue?.toString();

    return originalStringValue;
};

export const enhanceRowWithCustomContent = (row: ISmartReportTableRow, selectedRows: ISmartReportTableRow[], adjustmentType: ReceivableAdjustmentTypeCode, isRemoving: boolean): IEnhanceRowContent => {
    if (isRemoving || adjustmentType !== ReceivableAdjustmentTypeCode.ReceivableAdjustmentPer8cZORUnder30Thousand) {
        return {
            row,
            alert: null
        };
    }

    const allChildrenValueRows: IRow[] = filterChildRowsBy(row, (childRow: IRow) => {
        return childRow.type === RowType.Value;
    });

    const amount = allChildrenValueRows.reduce((sum: number, currentRow: ISmartReportTableRow) => {
        let newSum = sum;
        const currentRowValues = currentRow.customData.originalValues as IRecAdjDocument;

        if (selectedRows.includes(currentRow)) {
            newSum += currentRowValues.ReceivableAdjustment_Amount;
        } else if (isDocumentWithUnder30PossiblyApplied(currentRowValues)) {
            // we have to subtract items that Small was already applied on, but that are not written off
            newSum += currentRowValues.ReceivableAdjustment_AdjustmentAmount_Active;
        }

        return newSum;
    }, 0);
    const amountLeft = SMALL_ADJUSTMENT_MAX - amount;
    const content = (
        <>
            {`${i18next.t("ReceivableAdjustments:RowContent.Small")}: `}
            <ColoredText
                color={amountLeft > 0 ? "C_SEM_text_warning" : "C_SEM_text_bad"}>
                {formatCurrency(SMALL_ADJUSTMENT_MAX - amount, CurrencyCode.CzechKoruna)}
            </ColoredText>
        </>
    );

    const originalStringValue = getOriginalBpRowValue(row);
    const newBusinessPartnerValue: ICellValueObject = {
        tooltip: originalStringValue,
        value: (
            <>
                <CustomCellContentWrapper>
                    <CustomCellOriginalContent>
                        {originalStringValue}
                    </CustomCellOriginalContent>
                    <CustomCellCustomContent>
                        {content}
                    </CustomCellCustomContent>
                </CustomCellContentWrapper>
            </>
        )
    };
    const newOpenedValues = {
        ...row.openedValues,
        "BusinessPartner_Name": newBusinessPartnerValue
    };
    const updatedRow: ISmartReportTableRow = {
        ...row,
        values: newOpenedValues,
        openedValues: newOpenedValues,
        allowOverflow: { BusinessPartner_Name: true }
    };


    const alert: IAlertProps = amountLeft >= 0 ? null : {
        title: i18next.t("ReceivableAdjustments:Error.Small"),
        isSmall: true,
        status: Status.Error
    };

    return {
        row: updatedRow,
        alert: alert
    };

};

export const resetRows = (rows: Record<string, ISmartReportTableRow>, rowsOrder: string[], backupRows: Record<string, ISmartReportTableRow>): Record<string, ISmartReportTableRow> => {
    return updateRows({
        rows,
        rowsOrder,
        updateFn: (row: ISmartReportTableRow) => {
            const cleanRow = {
                ...(backupRows[row.id.toString()] ?? row),
                isDivider: false,
                isDisabled: false
            };

            if (row.openedValues) {
                const cleanOpenedValues = {
                    ...row.openedValues,
                    BusinessPartner_Name: getOriginalBpRowValue(row)
                };

                cleanRow.values = cleanOpenedValues;
                cleanRow.openedValues = cleanOpenedValues;
            }

            return cleanRow;
        }
    });
};

export const fetchReceivableAdjustmentTypes = memoizeOne(
    async (oData: OData): Promise<IReceivableAdjustmentTypeEntity[]> => {
        const res = await oData.getEntitySetWrapper(EntitySetName.ReceivableAdjustmentTypes)
            .query()
            .fetchData<IReceivableAdjustmentTypeEntity[]>();

        return res?.value;
    }
);

export const fetchRequiredAccounts = memoizeOne(
    async (oData: OData): Promise<IRequiredAccountEntity[]> => {
        const res = await oData.getEntitySetWrapper(EntitySetName.RequiredAccounts)
            .query()
            .filter(`${RequiredAccountEntity.RequiredAccountTypeCode} eq '${RequiredAccountTypeCode.ReceivableAdjustment}'`)
            .fetchData<IRequiredAccountEntity[]>();

        return res?.value;
    }
);