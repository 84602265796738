import { FILES_API_URL } from "../constants";
import { roundToDecimalPlaces } from "./general";
import { IFileMetadataEntity } from "@odata/GeneratedEntityTypes";
import { parseError } from "@odata/ODataParser";
import { saveAs } from "file-saver";
import { getFileNameFromResponseHeaders } from "@components/fileUploader/File.utils";

import { ODataError } from "@odata/Data.types";

export interface IFileProgress {
    /** Size of the file */
    total: number;
    /** Size of the currently downloaded/uploaded part */
    loaded: number;
    percent: number;
    finished: boolean;
    name?: string;
}

export interface IFileDownload {
    // custom endpoint
    url?: string;
    progressCallback?: TFileProgressEvent;
    fileName?: string;
}

export interface IFileUpload {
    // custom endpoint
    url?: string;
    file: File,
    name?: string;
    progressCallback?: TFileProgressEvent
}

type TFileProgressEvent = (event: IFileProgress) => void;

export default class FileStorage {
    /** Downloads the file and returns it as File object. Can be used to create custom downloader with progress tracker. */
    static async get(id?: number, args: IFileDownload = {}): Promise<File> {
        let url = args?.url ?? FILES_API_URL;

        if (id) {
            url += `/${id}`;
        }

        const response = await fetch(url);

        if (!response.ok) {
            throw new Error(`Error while fetching a file: ${response.status}, ${response.statusText}`);
        }

        const fileName = getFileNameFromResponseHeaders(response.headers);
        const type = response.headers.get("Content-Type");
        const reader = response.body.getReader();
        const totalSize = +response.headers.get("Content-Length");

        let receivedLength = 0; // received that many bytes at the moment
        const chunks = []; // array of received binary chunks (comprises the body)

        while (true) {
            const { done, value } = await reader.read();

            if (done) {
                break;
            }

            chunks.push(value);
            receivedLength += value.length;

            const percentComplete = (receivedLength / totalSize) * 100;

            if (args.progressCallback) {
                args.progressCallback({
                    total: totalSize,
                    loaded: receivedLength,
                    percent: roundToDecimalPlaces(2, percentComplete),
                    finished: totalSize === receivedLength
                });
            }
        }

        return new File(chunks, args.fileName ?? fileName, { type });
    }

    /** Opens saveAs dialog for the file, browser will handle the download. */
    static download(id: number) {
        saveFileFromUrl(`${FILES_API_URL}/${id}`);
    }

    static async upload(args: IFileUpload): Promise<IFileMetadataEntity> {
        // use XMLHttpRequest instead of fetch to provide progress event

        return new Promise((resolve, reject) => {
            const request = new XMLHttpRequest();
            const fileName = encodeURIComponent(args.name ?? args.file.name);

            request.open("POST", args.url ?? FILES_API_URL);
            request.setRequestHeader("Content-Disposition", `form-data;filename="${fileName}"`);

            if (args.file.type) {
                request.setRequestHeader("Content-Type", args.file.type);
            }

            if (args.progressCallback) {
                request.upload.onprogress = (e) => {
                    const percentComplete = (e.loaded / e.total) * 100;

                    args.progressCallback({
                        name: fileName,
                        total: e.total,
                        loaded: e.loaded,
                        percent: roundToDecimalPlaces(2, percentComplete),
                        finished: e.total === e.loaded
                    });
                };
            }

            request.onload = (e) => {
                if (request.status < 200 || request.status >= 300) {
                    try {
                        reject(parseError(JSON.parse(request.responseText)));
                    } catch (e) {
                        reject(new Error(`Error while uploading a file: ${request.status}, ${request.statusText}`));
                    }
                }

                resolve(JSON.parse(request.response));
            };

            request.onerror = (event) => {
                reject(new Error(`Uknown error while uploading a file`));
            };

            request.send(args.file);
        });
    }

    static async delete(id: number): Promise<Response> {
        return fetch(`${FILES_API_URL}/${id}`, {
            method: "DELETE"
        });
    }
}

export const getFileStorageUploadErrorMessage = (error: ODataError | Error): string => {
    // error thrown from FileStorage.upload can be either oDataError or default Error (with message) when there
    // is complete unknown BE error thrown
    return (error as ODataError)._validationMessages?.[0]?.message ?? (error as ODataError)._message ?? (error as Error).message;
};

/**
 * Saves file from url to user's computer.
 **/
export const saveFileFromUrl = (url: string, name?: string): void => {
    // keep using the saveAs library, because if we use window.location.assign instead,
    // cypress stucks on waiting for page load event, which never happens
    console.info("Downloading file from url:", url, name ? ` with name: ${name}` : "");
    saveAs(url, name);
};