import DemoTenantWrapper from "@components/tiles/demoTenant/DemoTenantWrapper";
import { WithOData, withOData } from "@odata/withOData";
import React, { memo } from "react";
import { useTranslation } from "react-i18next";

import { IDashboardTileComponentProps } from "../../dashboard";
import { DemoTenantIcon } from "../../icon";
import { IconHeight, IconWidth, StyledLinkTileTitle, StyledLinkTileTitleWrapper } from "../linkTile/LinkTile.styles";
import { CreateDemoTenant, StyledDemoTenantTile } from "./DemoTenant.styles";

interface IProps extends IDashboardTileComponentProps, WithOData {
}

const DemoTenant: React.FC<IProps> = ({ inEditMode }) => {
    const { t } = useTranslation("Home");

    return (<DemoTenantWrapper>
        {({ handleClick, isLoading, isNotCreated }) => (
                <StyledDemoTenantTile inEditMode={inEditMode}
                                      onClick={handleClick}
                                      isLoading={isLoading}>
                    {isNotCreated ? (
                            <CreateDemoTenant>{t("DemoTenant.Create")}</CreateDemoTenant>
                    ) : (<>
                        <DemoTenantIcon width={IconWidth} height={IconHeight}/>
                        <StyledLinkTileTitleWrapper>
                            <StyledLinkTileTitle>{t("DemoTenant.Title")}</StyledLinkTileTitle>
                        </StyledLinkTileTitleWrapper>
                    </>)}
                </StyledDemoTenantTile>
        )}
    </DemoTenantWrapper>);
};

export default memo(withOData(DemoTenant));
