import styled from "styled-components/macro";
import { StyledTokenizer } from "../token";

export const MoreItemsWrapper = styled.span<{
    $showTooltip: boolean;
    $selected: boolean;
    $isVisible: boolean;
}>`
    color: ${props => props.$showTooltip ? props.theme.C_ACT_main : props.theme.C_TEXT_primary};
    cursor: pointer;
    text-transform: none;
    visibility: ${props => props.$isVisible ? "visible" : "hidden"};

    ${props => props.$selected && props.$showTooltip && `color: ${props.theme.C_BTN_4L_emph_bg_selected};`}
    &:hover {
        color: ${props => !props.$selected && props.$showTooltip && props.theme.C_BTN_4L_emph_text_hover};
    }
`;

export const TooltipContentWrapper = styled.div`
  ${StyledTokenizer} {
      padding: 5px 0;
  }
`;