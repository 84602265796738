import React, { useCallback } from "react";
import { ConfirmationDialog } from "@components/dialog/ConfirmationDialog";
import { useTranslation } from "react-i18next";

// todo check if this is what PO/users wants
const WrongEvalaVersionDialog: React.FunctionComponent = () => {
    const { t } = useTranslation(["Common"]);
    const handleConfirm = useCallback(() => {
        window.location.reload();
    }, []);


    return (
        <ConfirmationDialog onClose={null}
                            onConfirm={handleConfirm}>
            {t("Common:General.WrongEvalaVersion")}
        </ConfirmationDialog>
    );
};

export default WrongEvalaVersionDialog;