import { TEntityKey } from "@odata/BindingContext";
import { IDocumentLabelEntity, ILabelEntity } from "@odata/GeneratedEntityTypes";
import { ODataQueryResult } from "@odata/ODataParser";

import { REST_API_URL } from "../../constants";
import customFetch from "../../utils/customFetch";

const GET_REFERENCED_LABELS_PATH = `${REST_API_URL}/LabelHierarchies/GetReferencedLabels`;

export const getUsedLabels = async (labelsIds: TEntityKey[]): Promise<ILabelEntity[]> => {
    const result = await customFetch(`${GET_REFERENCED_LABELS_PATH}?${labelsIds.map(id => `labelIds=${id}`).join("&")}`);
    const labels = await result.json() as ODataQueryResult<ILabelEntity[]>;

    return labels?.value;
};

export function compareLabels(a: IDocumentLabelEntity[], b: IDocumentLabelEntity[]): boolean {
    if (a?.length !== b?.length) {
        return false;
    }
    return a?.every((label) => b.find(bLabel => bLabel.Label?.Id === label.Label?.Id) !== undefined);
}