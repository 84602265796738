import { CacheStrategy } from "../enums";
// import { MemoizedFunction } from "lodash";
import memoize from "./memoize";

export interface IClearable {
    clear?: () => void;
}

type TCacheRegistry = Partial<Record<CacheStrategy, IClearable[]>>;

export class CacheCleaner {
    static caches: TCacheRegistry = {};

    static registerCache(cache: IClearable, cs: CacheStrategy) {
        if (!CacheCleaner.caches[cs]) {
            CacheCleaner.caches[cs] = [];
        }
        CacheCleaner.caches[cs].push(cache);
    }

    static clear(cs: CacheStrategy) {
        CacheCleaner.caches[cs]?.forEach(cache => cache.clear?.());
    }
}

/**
 * Function creates and register functions for every cache strategy, cached content is then cleared automatically by
 * CacheCleaner class
 *
 * @param fn
 * @param resolverFn
 */
export function memoizedWithCacheStrategy<T, R>(fn: (args: T) => Promise<R>, resolverFn: (args: T) => string) {
    const memoizedMethods: Partial<Record<CacheStrategy, ((args: T) => Promise<R>) & Partial<any>>> = {};

    for (const key in CacheStrategy) {
        if (CacheStrategy.hasOwnProperty(key)) {
            const cs = CacheStrategy[key as CacheStrategy];
            if (cs === CacheStrategy.None) {
                memoizedMethods[cs] = fn;
            } else {
                memoizedMethods[cs] = memoize(fn, resolverFn);
                CacheCleaner.registerCache((memoizedMethods[cs] as any).cache, cs);
            }
        }
    }

    return (args: T, cacheStrategy: CacheStrategy) => {
        return memoizedMethods[cacheStrategy](args);
    };
}