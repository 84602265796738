import React from "react";
import PrintDialog, {
    IPrintDialogVariant,
    IProps as IPrintDialogProps,
    PRINT_DIALOG_DEFAULT_VARIANT_ID
} from "../../utils/pdfPrinting/PrintDialog";
import { TRecordType } from "../../global.types";
import { WithTranslation, withTranslation } from "react-i18next";
import memoizeOne from "../../utils/memoizeOne";
import { TableStorage } from "../../model/TableStorage";
import { getPrintDialogFilterList } from "./TableView.utils";
import { P13nType } from "../../enums";
import BusyIndicator from "../../components/busyIndicator/BusyIndicator";
import { getDefaultLogActionDetail, logAction } from "@odata/OData.utils";
import { ITextListGroup } from "@utils/pdfPrinting/PdfPrinting";
import { ActionTypeCode } from "@odata/GeneratedEnums";
import { AppContext, IAppContext } from "../../contexts/appContext/AppContext.types";
import {IPrintDialogSettings} from "@utils/pdfPrinting/Print.types";

interface IProps extends Pick<IPrintDialogProps, "element" | "onClose">, WithTranslation {
    storage: TableStorage;
    /** Can be used to pass text groups that will be printed out before applied filters */
    customTextListGroups?: ITextListGroup[];
    logActionDetail?: string;
}

interface IState {
    variantId: string;
    settings: TRecordType<IPrintDialogSettings>;
    isLoaded: boolean;
}

const CUSTOM_VARIANT_ID = "custom";
const TMP_VARIANT_ID = "tmp";

/**
 * Wrapper around PrintDialog, takes care of loading and saving variants (settings) of the print dialog.
 * We provide three variants, but in dropdown user can only choose from two (default, custom).
 * The third is temporary and is only distinguishable by its label.
 * Every change is immediately stored into the temporary variant.
 * Pressing "save settings" button stores values into the custom variant and tmp variant is removed.
 * Tmp variant is also removed everytime default or custom variant is selected in the dropdown.
 * */
class TablePrintDialog extends React.PureComponent<IProps, IState> {
    static contextType = AppContext;
    //sadly, breaks typescript type checking
    //context: React.ContextType<typeof AppContext>;
    static defaultProps: Partial<IProps> = {
        customTextListGroups: []
    };

    saveTmpTimeoutId: number = null;

    state: IState = {
        variantId: null,
        settings: null,
        isLoaded: false
    };

    constructor(props: IProps, context: IAppContext) {
        super(props, context);

        this.init(context);
    }

    init = async (context: IAppContext) => {
        const storedSettings: TRecordType<IPrintDialogSettings> = await context.p13n.get<TRecordType<IPrintDialogSettings>>(this.tableId, P13nType.PrintDialogSettings) ?? {};
        let initialVariant;

        if (storedSettings[TMP_VARIANT_ID]) {
            initialVariant = TMP_VARIANT_ID;
        } else if (storedSettings[CUSTOM_VARIANT_ID]) {
            initialVariant = CUSTOM_VARIANT_ID;
        } else {
            initialVariant = PRINT_DIALOG_DEFAULT_VARIANT_ID;
        }

        this.setState({
            variantId: initialVariant,
            settings: storedSettings,
            isLoaded: true
        });
    };

    getVariants = memoizeOne(() => {
        const variants: TRecordType<IPrintDialogVariant> = {};
        const storedSettings = this.state.settings;

        if (storedSettings[TMP_VARIANT_ID]) {
            variants[TMP_VARIANT_ID] = {
                id: TMP_VARIANT_ID,
                label: `${this.props.t("Components:PrintDialog.VariantCustom")}*`,
                settings: storedSettings[TMP_VARIANT_ID]
            };
        }

        if (storedSettings[CUSTOM_VARIANT_ID]) {
            variants[CUSTOM_VARIANT_ID] = {
                id: CUSTOM_VARIANT_ID,
                label: this.props.t("Components:PrintDialog.VariantCustom"),
                settings: storedSettings[CUSTOM_VARIANT_ID]
            };
        }

        return variants;
    }, () => [this.state.settings]);

    getVisibleVariants = memoizeOne(() => {
        const visibleVariants = [];
        const storedVariants = this.state.settings;

        if (storedVariants[CUSTOM_VARIANT_ID]) {
            visibleVariants.push(CUSTOM_VARIANT_ID);
        }


        // tmp variant is never pushed into visibleVariants,
        // because it is not supposed to be shown in the variant dropdown, just its label

        return visibleVariants;
    }, () => [this.state.settings]);


    uploadSettings = async (settings: TRecordType<IPrintDialogSettings>) => {
        clearTimeout(this.saveTmpTimeoutId);

        await this.context.p13n.update(this.tableId, P13nType.PrintDialogSettings, settings);
    };

    handleVariantChange = (variantId: string) => {
        const settings = { ...this.state.settings };

        delete settings[TMP_VARIANT_ID];

        this.setState({
            variantId,
            settings
        });

        this.uploadSettings(settings);
    };

    handleVariantSave = async (settings: IPrintDialogSettings) => {
        const storeSettings = {
            [CUSTOM_VARIANT_ID]: settings
        };

        this.setState({
            variantId: CUSTOM_VARIANT_ID,
            settings: storeSettings
        });

        this.uploadSettings(storeSettings);

    };

    handleSettingsChange = (settings: IPrintDialogSettings) => {
        const storeSettings = {
            ...this.state.settings,
            [TMP_VARIANT_ID]: settings
        };

        this.setState({
            variantId: TMP_VARIANT_ID,
            settings: storeSettings
        });

        clearTimeout(this.saveTmpTimeoutId);

        this.saveTmpTimeoutId = window.setTimeout(() => {
            this.uploadSettings(storeSettings);
        }, 250);
    };

    handleConfirm = async () => {
        // CompanyId is automatically added to OData request and thus is not part of 'filter'
        // but we still want Company Id to be part of the log
        const companyFilter = getDefaultLogActionDetail(this.props.storage);
        const logDetail = this.props.logActionDetail ? `${this.props.logActionDetail} AND ${companyFilter}` : companyFilter;

        logAction({
            actionId: this.tableId,
            actionType: ActionTypeCode.PDFExport,
            detail: logDetail
        });

        this.props.onClose?.();
    };

    get tableId() {
        return this.props.storage.id;
    }

    getTextListAppendix = memoizeOne(() => {
        return [
            ...this.props.customTextListGroups,
            ...getPrintDialogFilterList(this.props.storage)
        ];
    });

    render() {
        if (!this.state.isLoaded) {
            return <BusyIndicator/>;
        }

        return (
            <PrintDialog element={this.props.element}
                         onClose={this.props.onClose}
                         onConfirm={this.handleConfirm}
                         fileName={this.props.storage?.data?.definition?.title}
                         textListAppendix={this.getTextListAppendix()}
                         variants={this.getVariants()}
                         visibleVariants={this.getVisibleVariants()}
                         variant={this.state.variantId}
                         onVariantChange={this.handleVariantChange}
                         onVariantSave={this.handleVariantSave}
                         onSettingsChanged={this.handleSettingsChange}
            />
        );
    }
}

export default withTranslation(["Components"])(TablePrintDialog);