import styled, { css } from "styled-components/macro";
import { T_PLAIN_big, T_TITLE_1 } from "../../global.style";
import { StyledButton } from "@components/button";

export const LayoutContainer = styled.div`
    position: relative;
    z-index: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    background-color: ${props => props.theme.C_BG_floorplan};
`;

const CommonTextStyles = css`
    text-align: center;
    max-width: 530px;
    width: 90%;
    margin: 0 auto;
`;

export const Title = styled.h1`
    ${T_TITLE_1};
    ${CommonTextStyles};
    margin-top: 200px;
    margin-bottom: 42px;
`;

export const Paragraph = styled.p`
    ${T_PLAIN_big};
    ${CommonTextStyles};
`;

export const ButtonGroupStyled = styled.div`
    margin-top: 52px;

    ${StyledButton} {
        display: block;
        margin: 0 auto;
    }

    ${StyledButton} + ${StyledButton} {
        margin-top: 18px;
    }
`;

export const BackButtonWrapper = styled.div`
    position: absolute;
    top: 24px;
    left: 24px;
`;