// route urls
import { escapeRegExp } from "lodash";

import { TRecordString } from "./global.types";

export const ROUTE_HOME = "/";

export const ROUTE_NUMBER_RANGES = "/numberRanges";
export const ROUTE_CHARTS_OF_ACCOUNTS = "/chartOfAccounts";
export const ROUTE_FISCAL_YEAR = "/fiscalYear";
export const ROUTE_INVOICE_RECEIVED = "/invoicesReceived";
export const ROUTE_INVOICE_ISSUED = "/invoicesIssued";
export const ROUTE_PROFORMA_INVOICE_RECEIVED = "/proformaInvoicesReceived";
export const ROUTE_PROFORMA_INVOICE_ISSUED = "/proformaInvoicesIssued";
export const ROUTE_OTHER_LIABILITY = "/otherLiability";
export const ROUTE_OTHER_RECEIVABLE = "/otherReceivable";
export const ROUTE_INTERNAL_DOCUMENT = "/internalDocument";
export const ROUTE_CORRECTIVE_INVOICE_RECEIVED = "/correctiveInvoicesReceived";
export const ROUTE_CORRECTIVE_INVOICE_ISSUED = "/correctiveInvoicesIssued";
export const ROUTE_GENERAL_LEDGER = "/generalLedger";
export const ROUTE_ELECTRONIC_SUBMISSION = "/electronicSubmission";
export const ROUTE_ACCOUNTING_JOURNAL = "/accountingJournal";
export const ROUTE_DOCUMENT_JOURNAL = "/documentJournal";
export const ROUTE_VAT_STATEMENT_REPORT = "/vatStatementReport";
export const ROUTE_VAT_CONTROL_STATEMENT_REPORT = "/vatControlStatementReport";
export const ROUTE_VAT_VIES_STATEMENT_REPORT = "/vatVIESStatementReport";
export const ROUTE_PAYMENT_JOURNAL = "/paymentJournal";
export const ROUTE_ACCOUNT_ANALYSIS = "/accountAnalysis";
export const ROUTE_ASSET_ANALYSIS = "/assetAnalysis";
export const ROUTE_SALDO = "/saldo";
export const ROUTE_BALANCE_SHEET = "/balanceSheet";
export const ROUTE_INCOME_STATEMENT = "/incomeStatement";
export const ROUTE_AGING_AP = "/agingAP";
export const ROUTE_AGING_AR = "/agingAR";
export const ROUTE_RECEIVABLE_ADJUSTMENTS = "/receivableAdjustments";
export const ROUTE_FISCAL_YEAR_CLOSE = "/fiscalYearClose";
export const ROUTE_COMPANIES = "/companies";
export const ROUTE_NEW_COMPANY = "/newCompany";
export const ROUTE_COMPANY_SETTING = "/companySetting";
export const ROUTE_MENU = "/menu";
export const ROUTE_DOCUMENTS = `${ROUTE_MENU}/documents`;
export const ROUTE_ACTIONS = `${ROUTE_MENU}/actions`;
export const ROUTE_PAYMENTS = `${ROUTE_MENU}/payments`;
export const ROUTE_ASSETS = `${ROUTE_MENU}/assets`;
export const ROUTE_REPORTING = `${ROUTE_MENU}/reporting`;
export const ROUTE_AUDIT = `${ROUTE_MENU}/audit`;
export const ROUTE_PAYROLL = `${ROUTE_MENU}/payroll`;
export const ROUTE_INITIAL_ACCOUNT_BALANCES = "/initialAccountBalances";
export const ROUTE_DATA_IMPORT = "/dataImport";
export const ROUTE_DATA_EXPORT = "/dataExport";
export const ROUTE_INTEGRATIONS = "/integrations";

export const ROUTE_NOT_FOUND = "/notFound";
export const ROUTE_USER_SETTING = "/userSetting";
export const AUDIT_TRAIL = "auditTrail";
export const ROUTE_DEVTOOLS = "/devtools";
export const ROUTE_LOGIN = "/login";
export const ROUTE_LOGIN_REGISTER = `${ROUTE_LOGIN}/register`;
export const ROUTE_LOGIN_RESET_PASS_REQUEST = `${ROUTE_LOGIN}/resetPasswordRequest`;
export const ROUTE_LOGIN_VERIFY = `${ROUTE_LOGIN}/verify`;
export const ROUTE_EMAIL_CONFIRM = `${ROUTE_LOGIN}/confirmEmail`;
export const ROUTE_LOGIN_TENANT = `${ROUTE_LOGIN}/tenant`;
export const ROUTE_LOGIN_INVITATION = `${ROUTE_LOGIN}/invitation`;
export const ROUTE_LOGIN_TENANT_REGISTER = `${ROUTE_LOGIN}/tenantregister`;
export const ROUTE_LOGIN_DEVICE = `${ROUTE_LOGIN}/device`;
export const ROUTE_LOGIN_RESET_PASS = `${ROUTE_LOGIN}/resetpassword`;
export const ROUTE_LOGOUT = "/logout";
export const ROUTE_LABEL_HIERARCHIES = "/labelHierarchy";
export const ROUTE_RECURRING_TASKS = "/recurringTasks";
export const ROUTE_RECURRING_TASKS_EXECUTIONS = "/executions";
export const ROUTE_EXCHANGE_RATES = "/exchangeRates";
export const ROUTE_BUSINESS_PARTNER = "/businessPartner";
export const ROUTE_CUSTOMER_PORTAL_PAYMENT = "/customerPortalPayment";
export const ROUTE_MINOR_ASSET = "/minorAsset";
export const ROUTE_FIXED_ASSET = "/asset";
export const ROUTE_UNORGANIZED_ASSET = "/unorganizedAsset";
export const ROUTE_CURRENCY_SETTINGS = "/currencySettings";
export const ROUTE_CATEGORIES = "/categories";
export const ROUTE_INBOX_SETTINGS = "/inboxSettings";
export const ROUTE_INBOX_CUSTOMER_APPROVAL = "/inboxApproval";
export const ROUTE_INBOX_SEND_TO_ACCOUNTANT = "/createInboxFiles";
export const ROUTE_BANK_ACCOUNTS = "/bankAccounts";
export const ROUTE_BANK_STATEMENTS = "/bankStatements";
export const ROUTE_BANK_TRANSACTIONS = "/bankTransactions";
export const ROUTE_BACKGROUNDJOBS_HISTORY = "/backgroundJobsHistory";
export const ROUTE_DATA_BOX = "/dataBox";
export const ROUTE_MESSAGES = "/messages";
export const ROUTE_TICKETS = "/tickets";
export const ROUTE_DELETE_LOG = "/deleteLog";
export const ROUTE_INBOX = "/inbox";
export const ROUTE_CASH_BOXES = "/cashBoxes";
export const ROUTE_CASH_RECEIPTS = "/cashReceipts";
export const ROUTE_CASH_RECEIPTS_RECEIVED = `${ROUTE_CASH_RECEIPTS}/CashReceiptsReceived`;
export const ROUTE_CASH_RECEIPTS_ISSUED = `${ROUTE_CASH_RECEIPTS}/CashReceiptsIssued`;
export const ROUTE_INCOME_AND_EXPENDITURE = "/CbaIncomeExpense";
export const ROUTE_CBA_ENTRY_LEDGER = "/CbaEntryLedger";

export const ROUTE_WEBINVOICE = "/webInvoice";
export const ROUTE_WELCOME_SCREEN = "/welcomeScreen";
export const ROUTE_CONNECT_WITH_IDOKLAD = "/connectiDoklad";
export const ROUTE_SWITCH_TO_CUSTOMER_PORTAL = "/customerPortal";
// redirects to correct route based on notification type
export const ROUTE_NOTIFICATION_REDIRECT = "/notificationRedirect";

// organization settings routes
export const ROUTE_ORG_SETTINGS_PREFIX = "/org";
export const ROUTE_ORG_MENU = `${ROUTE_ORG_SETTINGS_PREFIX}${ROUTE_MENU}`;
export const ROUTE_MENU_USERS = `${ROUTE_ORG_MENU}/users`;
export const ROUTE_MENU_SUBSCRIPTION = `${ROUTE_ORG_MENU}/subscription`;
export const ROUTE_MENU_COMMON_SETTINGS = `${ROUTE_ORG_MENU}/commonSettings`;

export const ROUTE_USERS = `${ROUTE_ORG_SETTINGS_PREFIX}/users`;
export const ROUTE_COMPANY_ROLES = `${ROUTE_ORG_SETTINGS_PREFIX}/companyRoles`;
export const ROUTE_SYSTEM_ROLES = `${ROUTE_ORG_SETTINGS_PREFIX}/generalRoles`;
export const ROUTE_SUBSCRIPTIONS_PLAN = `${ROUTE_ORG_SETTINGS_PREFIX}/plan`;
export const ROUTE_SUBSCRIPTIONS_INVOICING = `${ROUTE_ORG_SETTINGS_PREFIX}/invoicing`;
export const ROUTE_SUBSCRIPTIONS_EXTENSIONS = `${ROUTE_ORG_SETTINGS_PREFIX}/extensions`;
export const ROUTE_SUBSCRIPTIONS_PAYMENTS = `${ROUTE_ORG_SETTINGS_PREFIX}/payments`;
export const ROUTE_ACCOUNT_ASSIGNMENT = `${ROUTE_ORG_SETTINGS_PREFIX}/accountAssignment`;
export const ROUTE_CHARTS_OF_ACCOUNTS_TEMPLATES = `${ROUTE_ORG_SETTINGS_PREFIX}/chartOfAccountsTemplates`;
export const ROUTE_VAT = `${ROUTE_ORG_SETTINGS_PREFIX}/Vat`;
export const ROUTE_DOCUMENT_ITEM_TEMPLATES = `${ROUTE_ORG_SETTINGS_PREFIX}/itemTemplates`;
export const ROUTE_GOODBYE_SCREEN = `${ROUTE_ORG_SETTINGS_PREFIX}/goodbye`;

// dashboard pages linked only trough tile
export const ROUTE_POSTED_DOCUMENTS_LAST_MONTH = `${ROUTE_ORG_SETTINGS_PREFIX}/postedDocumentsLastMonth`;
export const ROUTE_JOURNAL_ENTRIES_LAST_MONTH = `${ROUTE_ORG_SETTINGS_PREFIX}/postedJournalEntriesLastMonth`;
export const ROUTE_AGENDA_WORK_OVERVIEW = `${ROUTE_ORG_SETTINGS_PREFIX}/agendaWorkOverview`;
export const ROUTE_FISCAL_YEAR_CLOSE_OVERVIEW = `${ROUTE_ORG_SETTINGS_PREFIX}/fiscalYearCloseOverview`;

// Payroll routes
export const ROUTE_GOVERNMENT_INSTITUTION = "/governmentInstitution";
export const ROUTE_HEALTH_INSURANCE_COMPANIES = "/healthInsuranceCompanies";
export const ROUTE_PENSION_INSURANCE_COMPANIES = "/pensionInsuranceCompanies";
export const ROUTE_LIFE_INSURANCE_COMPANIES = "/lifeInsuranceCompanies";
export const ROUTE_WORKING_PATTERNS = "/workingPattern";
export const ROUTE_EMPLOYMENT = "/employment";
export const ROUTE_EMPLOYMENT_TEMPLATE = "/employmentTemplate";
export const ROUTE_EMPLOYEES = "/employees";
export const ROUTE_SALARY_COMPONENTS = "/salaryComponents";
export const ROUTE_SALARY_ADVANCES = "/salaryAdvances";
export const ROUTE_EXTRA_SALARIES = "/extraSalaries";
export const ROUTE_ABSENCE_CATEGORIES = "/absenceCategories";
export const ROUTE_ATTENDANCE = "/attendance";
export const ROUTE_PAYSLIP = "/payslip";
export const ROUTE_GROUP_DEDUCTIONS = "/groupDeductions";
export const ROUTE_INDIVIDUAL_DEDUCTIONS = "/individualDeductions";

/** Whether this view should support new company wizard
 * (e.g. its not gonna automatically redirect to /companies when opened) */
export const WIZARD_SUPPORTED_ROUTES = [
    ROUTE_COMPANIES, ROUTE_DEVTOOLS, ROUTE_USER_SETTING, ROUTE_NEW_COMPANY
];

export const ROUTES_AVAILABLE_WITHOUT_COMPANY = [
    ROUTE_WELCOME_SCREEN, ROUTE_DEVTOOLS, ROUTE_USER_SETTING, ROUTE_NEW_COMPANY
];

export const LIMITED_ACCESS_SUPPORTED_ROUTES = [
    ROUTE_GOODBYE_SCREEN, ROUTE_DEVTOOLS
];

// These routes are available only in customer portal -> if user navigates to them, CP settings is forced
export const ROUTES_CUSTOMER_PORTAL = [
    ROUTE_CONNECT_WITH_IDOKLAD,
    ROUTE_INBOX_CUSTOMER_APPROVAL,
    ROUTE_INBOX_SEND_TO_ACCOUNTANT
    /* todo: specify this */
];

// Customer is usually able to navigate to several routes, which are shared for accountant and customer,
// however some routes has to force turning the customer portal layout off. E.g. companies in case user hits
// back when previously switch to customer portal from companies dashboard
export const ROUTES_NOT_IN_CUSTOMER_PORTAL = [
    ROUTE_COMPANIES,
    ROUTE_INBOX
    /* todo: specify this */
];

export function containsRoute(list: string[], route: string): boolean {
    if (!route) {
        return false;
    }
    const needle = route.toLowerCase();
    return !!list.find(r => {
        const lowerRoute = r.toLowerCase();
        return needle === lowerRoute || needle?.startsWith(`${lowerRoute}/`);
    });
}

export const doesRouteSupportWizard = (route: string): boolean => {
    return containsRoute(WIZARD_SUPPORTED_ROUTES, route);
};

export const isCompanyRelatedRoute = (route: string): boolean => {
    return !isOrganizationSettingsRoute(route) && !containsRoute(ROUTES_AVAILABLE_WITHOUT_COMPANY, route);
};

export const isCustomerPortalRoute = containsRoute.bind(null, ROUTES_CUSTOMER_PORTAL);
export const isNonCustomerPortalRoute = containsRoute.bind(null, ROUTES_NOT_IN_CUSTOMER_PORTAL);

/* Route for both OrganizationSettings and normal mode */
export const isUniversalRoute = (route: string): boolean =>
    [ROUTE_HOME, ROUTE_WELCOME_SCREEN, ROUTE_COMPANIES, ROUTE_NEW_COMPANY, ROUTE_USER_SETTING].includes(route);


export const isOrganizationSettingsRoute = (route: string): boolean => {
    return !!route?.toLowerCase().startsWith(ROUTE_ORG_SETTINGS_PREFIX.toLowerCase()) || route === ROUTE_DEVTOOLS;
};

export const getCorrectPath = (path: string, params: TRecordString): string => {
    // todo: Use always :Id parametr in the route, so we don't need this workaround
    if (!path.match(/:Id(\?|\/|$)/) && params.Id) {
        path = `${path}/${params.Id}`;
    }

    for (const key in params) {
        if (params.hasOwnProperty(key)) {
            const re = new RegExp(`:${escapeRegExp(key)}[?]?`, "g");
            path = path.replace(re, encodeURIComponent(params[key] ?? ""));
        }
    }
    // Replace optional params, which are not set
    path = path.replace(/:[a-zA-Z]+\?/g, "");

    // Return path without last slash
    return path.replace(/\/+$/, "");
};
