import React from "react";
import { DemoIcon, GraphIcon, PinIcon } from "@components/icon";
import { TenantSelectorStyled, TenantTitle, TenantTitleWrapper } from "../LoginPage.styles";
import TestIds from "../../testIds";
import { IconSize } from "../../enums";
import { Id } from "../Login.utils";
import { WithTranslation, withTranslation } from "react-i18next";
import { IFocusableItemProps } from "@components/focusManager";
import { KeyName } from "../../keyName";

interface ITenantSelectorProps {
    id: Id;
    name: string;
    isActive?: boolean;
    onClick?: (id: Id) => void;
    isWithBackground?: boolean;
    iconsSrc?: string;
    className?: string;
    isDemo?: boolean;
    isCustomer?: boolean;
    itemProps?: IFocusableItemProps;
}

class TenantSelector extends React.PureComponent<ITenantSelectorProps & WithTranslation> {
    handleClick = (): void => {
        if (this.props.isActive) {
            this.props.onClick?.(this.props.id);
        }
    };

    handleKeyDown = (e: React.KeyboardEvent): void => {
        if (e.key === KeyName.Enter || e.key === KeyName.Space) {
            this.handleClick();
        }
    };

    render() {
        const { isDemo, isCustomer, isActive } = this.props;
        const Icon = isDemo ? DemoIcon : (isCustomer ? GraphIcon : PinIcon);
        const title = this.props.t(`Login:TenantRegister.${isCustomer ? "CustomerAccess" : "AccountingAccess"}`);

        return (
            <TenantSelectorStyled onClick={this.handleClick}
                                  onKeyDown={this.handleKeyDown}
                // for security testing tool
                                  id={this.props.id?.toString()}
                                  isDisabled={!isActive}
                                  data-testid={TestIds.TenantSelector}
                                  className={this.props.className}
                                  tabIndex={0}
                                  {...(this.props.itemProps ?? {})}>
                <Icon width={IconSize.XL} title={title}/>
                <TenantTitleWrapper>
                    <TenantTitle data-testid={TestIds.TenantTitle}>{this.props.name}</TenantTitle>
                </TenantTitleWrapper>
            </TenantSelectorStyled>
        );
    }
}

export default withTranslation(["Common"])(TenantSelector);