import { IGetValueArgs, TValidatorFn, TValidatorMessageCallback } from "@components/smart/FieldInfo";
import * as yup from "yup";
import { Model } from "./Model";
import { ValidationErrorDetailType, ValidationErrorType } from "../enums";
import { TRecordAny } from "../global.types";
import { IAppContext } from "../contexts/appContext/AppContext.types";

export const PHONE_REG = /^(\+[1-9][0-9]{0,3})?[0-9]{4,}$/;
export const POSTAL_CODE_REG = /^\d{2,3} ?\d{2}$/;

export interface IValidationError {
    /** Type from schema, field or backend*/
    errorType?: ValidationErrorType;
    detailType?: ValidationErrorDetailType;
    message?: string;
    params?: TRecordAny;
}

export interface IValidationSchema {
    columnNames: string [];
    context: IAppContext;
    storage: Model<any>;
    useCollections: boolean;
}

export type TBuildInValidationKey = "type" | "min" | "max" | "integer" | "length" | "email" | "required" | "moreThan" | "lessThan";
export type TCustomBuildInValidationMessageDef = Partial<Record<TBuildInValidationKey, string>>;

type TCustomValidatorMessage = string | TValidatorMessageCallback;

interface ICustomValidator {
    validator: TValidatorFn;
    message?: TCustomValidatorMessage;
}

// todo if possible, add some typescript magic to conditionally pick correct interface based on selected ValidatorType
export type TValidatorSettings = (IStringValidatorSettings | INumberValidatorSettings | ICustomValidatorSettings) & {
    // custom validator callback which will cause 'message' to appear if 'false' is returned
    customValidator?: TValidatorFn | ICustomValidator[];
    // plain message is only used together with customValidator,
    // use TCustomBuildInValidationMessageDef for custom messages of built in validations
    message?: TCustomValidatorMessage | TCustomBuildInValidationMessageDef;
};

export interface IStringValidatorSettings {
    // Set a required length for the string value
    // mutually exclusive with min and max
    length?: number;
    // Set a minimum length limit for the string value
    min?: number;
    // Set a maximum length limit for the string value
    max?: number;
    numberString?: boolean;
}

export interface INumberValidatorSettings {
    // Set the minimum value allowed
    min?: number;
    excludeMin?: boolean;
    // Set the maximum value allowed
    max?: number;
    excludeMax?: boolean;
}

export interface ICustomValidatorSettings {
    // completely custom yup schema, can be used instead of customValidator callback
    customSchema?: (args: IGetValueArgs) => yup.Schema<any>;
}

// values has to exist in Common.toml Validation section
export enum ValidationMessage {
    Length = "Validation.Length",
    Max = "Validation.Max",
    Min = "Validation.Min",
    Above = "Validation.Above",
    Below = "Validation.Below",
    NotADate = "Validation.NotADate",
    NotARange = "Validation.NotARange",
    NotANumber = "Validation.NotaNumber",
    NotAYear = "Validation.NotAYear",
    Integer = "Validation.Integer",
    SmallNumber = "Validation.SmallNumber",
    BigNumber = "Validation.BigNumber",
    WrongEmail = "Validation.WrongEmail",
    Required = "Validation.Required",
    TemporalData = "Validation.TemporalData",
    InvalidValue = "Validation.InvalidValue",
    PositiveNumber = "Validation.PositiveNumber",
    PositiveNumberOrZero = "Validation.PositiveNumberOrZero",
    NonZeroNumber = "Validation.NonZeroNumber",
}