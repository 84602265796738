import React from "react";
import Dialog from "../../components/dialog/Dialog";
import { AppContext, IAppContext } from "../../contexts/appContext/AppContext.types";
import ConfirmationButtons from "../../views/table/ConfirmationButtons";
import Field from "../../components/inputs/field/Field";
import { BasicInputSizes } from "../../enums";
import Input, { IInputOnChangeEvent } from "../../components/inputs/input/Input";
import { WithTranslation, withTranslation } from "react-i18next";
import { createTemplateFromChartOfAccounts } from "./ChartOfAccounts.utils";
import { IEntity } from "@odata/BindingContext";
import { IValidationError } from "../../model/Validator.types";
import FieldsWrapper from "../../components/inputs/field/FieldsWrapper";

interface IProps extends WithTranslation {
    newTemplateName: string;
    onSave: (templateName?: string) => any;
    onClose: () => void;
    setAlert?: (name: string) => void;
    onAfterOpen?: () => void;
    parent: IEntity;
}

interface IState {
    newTemplateName: string;
    isLoading: boolean;
    error: IValidationError;
}

class NewTemplateDialog extends React.Component<IProps, IState> {
    static contextType = AppContext;
    state: IState = {
        newTemplateName: "",
        isLoading: false,
        error: null
    };

    _isMounted = false;

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getContext = () => {
        return this.context as IAppContext;
    };

    handleTemplateNameChange = (e: IInputOnChangeEvent<string>): void => {
        this.setState({ error: null, newTemplateName: e.value });
    };

    handleOnAfterOpen = () => {
        this.setState({ newTemplateName: this.props.newTemplateName });
        this.props.onAfterOpen?.();
    };

    handleSave = async (): Promise<void> => {
        if (!this.state.newTemplateName) {
            this.setState({ error: { message: this.props.t("Error:ValueRequired") } });
            return;
        }
        this.setState({ isLoading: true });
        if (this.props.parent?.Id) {
            const response = await createTemplateFromChartOfAccounts(this.props.parent.Id, this.state.newTemplateName);
            if (response?.Id) {
                this.props.setAlert && this.props.setAlert(this.state.newTemplateName);
                this.props.onSave(this.state.newTemplateName);
            } else {
                let message;
                // TODO: add error code on BE
                if (response.error?.innererror?.innererror?.message.startsWith("Cannot insert duplicate key")) {
                    message = this.props.t("Error:NameAlreadyExists");
                } else {
                    message = this.props.t(`Error:${response.error?.validationMessages?.[0]?.code}`);
                }
                this.setState({ error: { message } });
            }
        } else {
            const response = await this.props.onSave(this.state.newTemplateName);
            if (response?._validationMessages) {
                const message = this.props.t(`Error:${response?._validationMessages?.[0]?.code}`);
                this.setState({ error: { message } });
            } else if (response) {
                // unknown 500 error
                this.setState({ error: { message: response._message } });
            }
        }

        if (this._isMounted) {
            this.setState({ isLoading: false });
        }
    };

    render() {
        return <Dialog title={this.props.t("ChartsOfAccounts:Table.CreateNewTemplate")}
                       onClose={this.props.onClose}
                       onConfirm={this.handleSave}
                       onAfterOpen={this.handleOnAfterOpen}
                       busy={this.state.isLoading}
                       footer={
                           <ConfirmationButtons
                                   confirmText={this.props.t("ChartsOfAccounts:Table.Create")}
                                   onCancel={this.props.onClose} onConfirm={this.handleSave}
                                   useWrapper={false}/>
                       }>
            {<FieldsWrapper>
                <Field
                        width={BasicInputSizes.XL}
                        label={this.props.t("ChartsOfAccounts:Table.Name")}
                        isRequired>
                    <Input type="string"
                           width={BasicInputSizes.XL}
                           error={this.state.error}
                           value={this.state.newTemplateName}
                           onChange={this.handleTemplateNameChange}/>
                </Field>
            </FieldsWrapper>}
        </Dialog>;
    }
}

export default withTranslation(["ChartsOfAccounts", "Common", "Error"])(NewTemplateDialog);