import styled from "styled-components/macro";
import { T_TITLE_5 } from "../../global.style";
import { Theme } from "../../theme";

export const StyledSeparator = styled.div`
    display: flex;
    align-items: center;
    ${T_TITLE_5}
`;

export const SeparatorLine = styled.hr<{
    $isEmphasised?: boolean;
    $isBold?: boolean;
    $color?: keyof Theme;
}>`
    width: 100%;
    border: none;
    border-radius: 1px;
    height: ${props => props.$isBold ? "2" : "1"}px;
    background-color: ${props => props.$color ? props.theme[props.$color] : props.$isEmphasised ? props.theme.C_ACT_thick_line : props.theme.C_FIELD_section_line};
    margin: 0;
`;

export const SeparatorLabel = styled.span`
    padding-right: 9px;
    margin: -6px 0;
    white-space: nowrap;
`;