import React from "react";
import Text from "../text";
import { ISharedInputProps } from "./input";
import { TValue } from "../../global.types";

export function renderReadOnlyText(component: React.PureComponent<ISharedInputProps & {
    value?: TValue
}>): React.ReactElement {
    let text = component.props.value;

    if (component.props.unit) {
        text += ` ${component.props.unit}`;
    }

    return (
        <Text textAlign={component.props.textAlign} width={component.props.width}
              isLight={component.props.isLight}>{text}</Text>
    );
}
