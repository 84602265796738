import React, { PropsWithChildren } from "react";
import { InnerLabel, LabelDescription, LabelWrapper, RequiredMark, StyledLabel } from "./Label.styles";
import { TextAlign } from "../../../enums";
import TestIds from "../../../testIds";
import { IAuditTrailData } from "../../../model/Model";
import { AuditTrailFieldType } from "../../smart/FieldInfo";
import { TooltipIconInfo } from "../../tooltipIcon";
import { HOTSPOT_ID_ATTR } from "../../hotspots/Hotspots.utils";
import { isNotDefined } from "@utils/general";
import { StyledTooltipContent } from "../../tooltipIcon/TooltipIcon.styles";
import Tooltip from "../../tooltip";

interface IProps {
    // id of the input for accessibility
    for?: string;
    /** Smaller, italic text, next to the label*/
    description?: string;
    hotspotId?: string;
    isLight?: boolean;
    isRequired?: boolean;
    isDisabled?: boolean;
    isHidden?: boolean;
    isWithoutColon?: boolean;
    width?: string;
    maxWidth?: string;
    textAlign?: TextAlign;
    className?: string;
    style?: React.CSSProperties;
    isSharpLeft?: boolean;
    isSharpRight?: boolean;
    hasPadding?: boolean;
    auditTrailData?: IAuditTrailData;
    tooltip?: string;
}

class Label extends React.PureComponent<PropsWithChildren<IProps>> {
    static defaultProps = {
        hasPadding: true,
        textAlign: TextAlign.Left
    };

    render() {
        const isAuditTrailDiff = this.props.auditTrailData?.type === AuditTrailFieldType.NoDifference || this.props.auditTrailData?.type === AuditTrailFieldType.HoveredNoDifference;
        return (
                <Tooltip content={this.props.children}
                         onlyShowWhenChildrenOverflowing>
                    {(ref) =>
                            <StyledLabel
                                    isDisabled={this.props.isDisabled && !this.props.auditTrailData?.type}
                                    {...{[HOTSPOT_ID_ATTR]: this.props.hotspotId}}
                                    className={this.props.className}
                                    style={this.props.style}
                                    isLight={this.props.isLight}
                                    _width={this.props.width} _maxWidth={this.props.maxWidth}
                                    textAlign={this.props.textAlign}
                                    data-testid={TestIds.FieldLabel}>
                                {!this.props.isHidden &&
                                        <>
                                            <LabelWrapper
                                                    isAuditTrail={isAuditTrailDiff}
                                                    hasPadding={this.props.hasPadding}>
                                                {this.props.isRequired &&
                                                        <RequiredMark
                                                                hasPadding={this.props.hasPadding}
                                                                auditTrailType={this.props.auditTrailData?.type}
                                                                data-testid={TestIds.RequiredMark}/>
                                                }
                                                <InnerLabel
                                                        htmlFor={this.props.for}
                                                        useMinWidth={this.props.textAlign === TextAlign.Left && !!this.props.description}
                                                        ref={ref}
                                                        auditTrailType={this.props.auditTrailData?.type}>
                                                    {this.props.children}{isNotDefined(this.props.children) || this.props.isWithoutColon ? null : ":"}
                                                </InnerLabel>
                                                {this.props.description &&
                                                    <LabelDescription title={this.props.description}
                                                                      data-testid={TestIds.LabelDescription}>{this.props.description}</LabelDescription>
                                                }
                                            </LabelWrapper>
                                            {this.props.tooltip && (
                                                    <TooltipIconInfo>
                                                        <StyledTooltipContent>{this.props.tooltip}</StyledTooltipContent>
                                                    </TooltipIconInfo>
                                            )}
                                        </>
                                }
                            </StyledLabel>}
                </Tooltip>
        );
    }
}

export {Label};