import React from "react";
import {
    CaretIconLeft,
    CaretIconRight,
    MonthAndYearValue,
    MonthNavButton,
    StyledMonthYearSelector
} from "./MonthYearSelector.styles";
import { IconSize } from "../../../enums";
import DateType, { DateFormat, getUtcDayjs } from "../../../types/Date";
import { withTranslation } from "react-i18next";
import MonthYearPopup from "./popup/MonthYearPopup";
import { composeRefHandlers } from "@utils/general";
import { ICustomReferenceElement } from "./DatePickerBase";
import { DatePickerClean } from "./DatePicker";
import { IInputProps } from "../input";
import { capitalize } from "@utils/string";

/** Shows month and a year, without input. MonthYearPopup can be opened by clicking on the label. */
class MonthYearSelector extends DatePickerClean {
    get pickerPopup() {
        return MonthYearPopup;
    }

    get inputComponent(): React.ComponentType<IInputProps<Date>> {
        return null;
    }

    get popoverOffsetX(): number {
        // center the popup
        return -10;
    }

    get popoverOffsetY(): number {
        return 10;
    }

    getCustomReferenceElement = (args: ICustomReferenceElement): React.ReactNode => {
        return this.renderValue(args);
    };

    handlePrevious = (): void => {
        this.props.onChange({
            value: getUtcDayjs(this.props.value).subtract(1, "month").toDate(),
            triggerAdditionalTasks: true
        });
    };

    handleNext = (): void => {
        this.props.onChange({
            value: getUtcDayjs(this.props.value).add(1, "month").toDate(),
            triggerAdditionalTasks: true
        });
    };

    renderValue = (args: ICustomReferenceElement): React.ReactElement => {
        return (
            <StyledMonthYearSelector ref={composeRefHandlers(args.ref)}
                                     className={this.props.className}
                                     style={this.props.style}>
                <MonthNavButton title={this.props.t("Components:Calendar.PreviousMonth")}
                                onClick={this.handlePrevious}
                                isDisabled={this.props.minDate && getUtcDayjs(this.props.value).isSame(this.props.minDate, "month")}
                                isDecorative>
                    <CaretIconLeft width={IconSize.M}/>
                </MonthNavButton>
                <MonthAndYearValue tabIndex={0}
                                   onClick={args.onClick}
                                   onKeyDown={args.onKeyDown}>
                    {capitalize(DateType.format(this.props.value, DateFormat.monthAndYear))}
                </MonthAndYearValue>
                <MonthNavButton title={this.props.t("Components:Calendar.NextMonth")}
                                onClick={this.handleNext}
                                isDisabled={this.props.maxDate && getUtcDayjs(this.props.value).isSame(this.props.maxDate, "month")}
                                isDecorative>
                    <CaretIconRight width={IconSize.M}/>
                </MonthNavButton>
            </StyledMonthYearSelector>
        );
    };
}

export default withTranslation(["Common", "Component"])(MonthYearSelector);