import React, { lazy } from "react";
import FormRoute from "../routes/FormRoute";
import FormDynamicEntityRoute from "../routes/FormDynamicEntityRoute";
import {
    ROUTE_BANK_ACCOUNTS,
    ROUTE_BANK_STATEMENTS,
    ROUTE_BANK_TRANSACTIONS,
    ROUTE_CASH_BOXES,
    ROUTE_CASH_RECEIPTS
} from "../routes";
import { EntityTypeName } from "@odata/GeneratedEntityTypes";
import { CompanyPermissionCode } from "@odata/GeneratedEnums";

const BankStatements = lazy(() => import(/* webpackChunkName: "bank" */"../pages/banks/bankStatements/BankStatements"));
const BankTransactions = lazy(() => import(/* webpackChunkName: "bank" */"../pages/banks/bankTransactions/BankTransactions"));
const BankAccounts = lazy(() => import(/* webpackChunkName: "bank" */"../pages/banks/bankAccounts/BankAccounts"));
const CashBoxes = lazy(() => import(/* webpackChunkName: "bank" */"../pages/cashBoxes/CashBoxes"));
const CashReceipts = lazy(() => import(/* webpackChunkName: "bank" */"../pages/cashBoxes/cashReceipts/CashReceipts"));

export default function bankBundle() {
    return [
        <FormRoute
                path={ROUTE_BANK_STATEMENTS}
                key={ROUTE_BANK_STATEMENTS}
                entityType={EntityTypeName.BankStatement}
                permission={CompanyPermissionCode.Bank}
                page={BankStatements}/>,

        <FormRoute
                path={ROUTE_BANK_TRANSACTIONS}
                key={ROUTE_BANK_TRANSACTIONS}
                entityType={EntityTypeName.BankTransaction}
                permission={CompanyPermissionCode.Bank}
                page={BankTransactions}/>,

        <FormRoute
                path={ROUTE_BANK_ACCOUNTS}
                key={ROUTE_BANK_ACCOUNTS}
                entityType={EntityTypeName.CompanyBankAccount}
                permission={CompanyPermissionCode.CustomerSettings}
                page={BankAccounts}/>,

        <FormRoute
                path={ROUTE_CASH_BOXES}
                key={ROUTE_CASH_BOXES}
                entityType={EntityTypeName.CashBox}
                permission={CompanyPermissionCode.CustomerSettings}
                page={CashBoxes}/>,

        <FormDynamicEntityRoute
                path={ROUTE_CASH_RECEIPTS}
                key={ROUTE_CASH_RECEIPTS}
                permission={CompanyPermissionCode.CashBox}
                page={CashReceipts}/>
    ];
}