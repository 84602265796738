/* eslint-disable @typescript-eslint/no-this-alias */
import { isNumericType, Metadata } from "@evala/odata-metadata/src";
import { isDefined, isNotDefined } from "@utils/general";

import { TRecordAny } from "../global.types";
import memoizeOne from "../utils/memoizeOne";
import { IEntityBase } from "./GeneratedEntityTypes";

export type TEntityKey = string | number;

interface IParsedProps {
    path?: string;
    parent?: BindingContext;
    key?: TEntityKey;
    isProperty?: boolean;
    isCollection?: boolean;
    isLocal?: boolean;
    isNew?: boolean;
}

interface IBindingContextProps extends IParsedProps {
    metadata?: Metadata;
    isNew?: boolean;
}

interface IParsedEntityPath {
    path: string;
    key: TEntityKey;
    keyProp?: string;
}

export type TEntityPropValue = IEntity[] | IEntity | TEntityKey | string | number | boolean | Date;

export interface IEntity {
    [key: string]: any;
}

// todo find a way to make this work
export type TLocalContextString = `##${string}##`;
export const ENUM_KEY_PROP_NAME = "Code";
export const ENUM_DISPLAY_NAME = "Name";
export const SEPARATOR = "/";

export default class BindingContext {
    static NEW_ENTITY_ID_PROP: keyof Pick<IEntityBase, "#id"> = "#id";
    static LOCAL_CONTEXT_SIGN = "##";
    static METADATA_KEY = "_metadata";
    static ENUM_KEY_PROP = ENUM_KEY_PROP_NAME;

    _metadata: Metadata;
    _path: string;
    _parent: BindingContext;
    _key: TEntityKey;

    _isProperty: boolean;
    _isCollection: boolean;
    _isNew: boolean;
    _isLocal: boolean;

    static isLocalContextPath(path: string): boolean {
        return splitPath(path).some(part => part.indexOf(BindingContext.LOCAL_CONTEXT_SIGN) === 0);
    }

    /**
     * Returns true if key is sort of meta property, which cannot be used with bc.navigate(key).
     * @param key
     */
    static isMetaProperty(key: string): boolean {
        return BindingContext.isLocalContextPath(key)
            || key === BindingContext.NEW_ENTITY_ID_PROP
            || key === BindingContext.METADATA_KEY;
    }

    constructor(properties: IBindingContextProps) {
        const {
            path, metadata, parent, key,
            isProperty, isCollection,
            isNew, isLocal
        } = properties;

        this._metadata = metadata;
        this._parent = parent;
        this._path = path;

        this._isProperty = isProperty;
        this._isCollection = isCollection;
        this._isNew = isNew;
        this._isLocal = isLocal;

        if (!isLocal && (key !== undefined && key !== null)) {
            const numberKey = Number(key);
            this._key = isNumericType(this.getKeyProperty()) && !isNaN(numberKey) ? numberKey : key;
        } else if (isDefined(key) && isLocal) {
            this._key = key;
            this._path = BindingContext.cleanLocalContext(path);
        }
    }

    static localContext = (property: string): string => {
        return splitPath(property).map(part => `${BindingContext.LOCAL_CONTEXT_SIGN}${part}${BindingContext.LOCAL_CONTEXT_SIGN}`).join(SEPARATOR);
    };

    static cleanLocalContext = (localContextPath: string): string => {
        return localContextPath.replace(new RegExp(`${BindingContext.LOCAL_CONTEXT_SIGN}`, "g"), "");
    };

    static each(data: TEntityPropValue, bc: BindingContext, callback: (value: TEntityPropValue, bindingContext: BindingContext) => boolean, ignoreInvalidProps?: boolean): void {
        if (bc.isCollection()) {
            const shouldProcessCollection = callback(data, bc) && Array.isArray(data);
            if (shouldProcessCollection) {
                const collection = data as IEntity[];
                collection.forEach((item: IEntity, idx: number) => {
                    const itemBc = bc.addKey(item);
                    BindingContext.each(collection[idx], itemBc, callback, ignoreInvalidProps);
                });
            }
        } else {
            const shouldProcessChildren = callback(data, bc);
            if ((bc.isNavigation() || bc.isRoot()) && isDefined(data) && shouldProcessChildren) {
                for (const key of Object.keys(data)) {
                    if (!BindingContext.isMetaProperty(key)) {
                        try {
                            BindingContext.each((data as IEntity)[key], bc.navigate(key), callback, ignoreInvalidProps);
                        } catch (e) {
                            if (!ignoreInvalidProps) {
                                throw e;
                            }
                        }
                    }
                }
            }
        }
    }

    static find(data: IEntity, rootBindingContext: BindingContext, callback: (value: TEntityPropValue, bindingContext: BindingContext) => boolean): BindingContext {
        let foundEntityBc: BindingContext = null;

        BindingContext.each(data, rootBindingContext, (value, bindingContext) => {
            if (callback(value, bindingContext)) {
                foundEntityBc = bindingContext;
            }

            return !foundEntityBc;
        });

        return foundEntityBc;
    }

    /** Clean object of all (non local context) properties, that are not part of the given entity type*/
    static clean<T = IEntity>(data: T & any, rootBc: BindingContext): T {
        const cleanData = { ...data };
        const entityType = rootBc.getEntityType();
        const properties = entityType.getProperties();

        for (const key of Object.keys(cleanData)) {
            if (isNotDefined(properties[key]) && !BindingContext.isLocalContextPath(key)) {
                delete cleanData[key];
                continue;
            }

            const bc = rootBc.navigate(key);

            if (bc.isCollection()) {
                for (let i = 0; i < data[key].length; i++) {
                    data[key][i] = BindingContext.clean(data[key][i], bc.addKey(data[key][i]));
                }
            } else if (bc.isNavigation()) {
                cleanData[key] = BindingContext.clean(cleanData[key], bc);
            }
        }

        return cleanData;
    }

    parsePath(path: string, parent: BindingContext): IParsedProps {
        const properties: IParsedProps = {
            path: null,
            isProperty: false,
            isCollection: false,
            parent: parent,
            isLocal: parent.isLocal() || BindingContext.isLocalContextPath(path),
            isNew: false,
            key: null
        };

        if (!parent.isLocal() && !properties.isLocal && parent.isProperty() && parent.getParent()) {
            throw new Error(`wrong path - cannot navigate from property: ${parent.toString()} / ${path}`);
        } else {
            const parentEntityType = parent.getEntityType();
            const { path: cleanPath, key, keyProp } = BindingContext.parseKey(path);
            const property = parentEntityType && parentEntityType.getProperty(cleanPath);

            properties.path = cleanPath;
            properties.isNew = keyProp === BindingContext.NEW_ENTITY_ID_PROP;

            if (properties.isLocal) {
                properties.path = BindingContext.cleanLocalContext(cleanPath);
                properties.parent = parent;
            } else if (!property) {
                throw new Error(`BindingContext: parsePath - wrong path '${parent.toString()}'/'${cleanPath}'`);
            }

            if (property && property.isNavigation()) {
                if (property.getType().isCollection()) {
                    properties.isCollection = !key;
                    properties.key = key;
                }
            } else {
                properties.isProperty = true;
            }

            if (properties.isLocal) {
                properties.key = key;
            }
        }

        return properties;
    }

    getProperty() {
        let entityType;

        if (this.isNavigation()) {
            entityType = this.getParent().getEntityType();
        } else {
            entityType = this.getEntityType();
        }

        return entityType && entityType.getProperty(this._path);
    }

    getKeyProperty() {
        return this.getEntityType().getKeys()[0];
    }

    getKeyPropertyName() {
        if (this.isNew()) {
            return BindingContext.NEW_ENTITY_ID_PROP;
        } else if (this.isLocal()) {
            return BindingContext.localContext("Id");
        } else {
            return this.getKeyProperty().getName();
        }
    }

    getEntitySet() {
        return this._metadata.getEntitySet(this.getFullPath());
    }

    getLastValidEntitySet() {
        return this._metadata.getLastValidEntitySet(this.getFullPath());
    }

    getEntityType() {
        if (!this.isLocal()) {
            return this._metadata.getTypeForPath(this.getFullPath());
        }

        return null;
    }

    getParent() {
        return this._parent;
    }

    getRootParent() {
        let bindingContext: BindingContext = this;

        while (bindingContext.getParent()) {
            bindingContext = bindingContext.getParent();
        }

        return bindingContext;
    }

    static parseKey = (path: string, intKey = false): IParsedEntityPath => {
        const regExp = /(?<path>[^(]+)(\('?(?<key>[^)]+)'?\))?/;
        const matches = regExp.exec(path);

        let key: TEntityKey = matches.groups?.key;
        let keyProp;

        if (isDefined(key) && key.indexOf("=") >= 0) {
            [keyProp, key] = key.split("=");
        }

        key = key?.replace(/'/g, "");

        if (intKey) {
            key = parseInt(key);
        }

        return {
            path: matches.groups.path,
            key: key,
            keyProp
        };
    };

    // maybe move to get/set bound data utils somehow
    static createNewEntity<T = TRecordAny>(id: TEntityKey, obj: Partial<T> = {}): Partial<T> {
        return {
            ...obj,
            [BindingContext.NEW_ENTITY_ID_PROP]: id
        };
    }

    isRoot(): boolean {
        return !this.getParent();
    }

    isSame(bindingContext: BindingContext, withoutKey = false): boolean {
        return !areBindingContextsDifferent(this, bindingContext, withoutKey);
    }

    isInCollection(): boolean {
        return !isNotDefined(this.getKey()) && !this.isRoot();
    }

    isCollection(): boolean {
        return !!this._isCollection && !this.isRoot();
    }

    isValid = (): boolean => {
        let isOK = true;
        let bindingContext: BindingContext = this.getParent();

        while (isOK && bindingContext) {
            isOK = !bindingContext.isCollection() || isDefined(bindingContext.getKey());
            bindingContext = bindingContext.getParent();
        }

        return isOK;
    };

    // finds parent collection and returns its bindingContext plus remainingPath
    // false if no collection is found
    splitByCollectionPath(): { collectionBindingContext: BindingContext, path: string } {
        let isCollection = false;
        const paths: string[] = [];
        let bindingContext: BindingContext = this;

        while (!isCollection && !bindingContext.isRoot()) {
            isCollection = !!bindingContext.removeKey().isCollection();
            if (!isCollection) {
                paths.unshift(bindingContext.getPath());
                bindingContext = bindingContext.getParent();
            }
        }

        return isCollection ? {
            collectionBindingContext: bindingContext,
            path: paths.join(SEPARATOR)
        } : undefined;
    }

    /** Checks if any part of the binding context, other than the root context, is collection
     * Custom rootContext can be passed for cases like ChartOfAccounts/Accounts when the root itself is nested collection */
    isAnyPartCollection(rootContext?: BindingContext): boolean {
        let isAnyPartCollection = false;
        let bindingContext: BindingContext = this;

        while (!isAnyPartCollection && !bindingContext.isRoot() && !bindingContext.isSame(rootContext)) {
            isAnyPartCollection = isDefined(bindingContext.getKey()) || !!bindingContext.isCollection();
            bindingContext = bindingContext.getParent();
        }

        return isAnyPartCollection;
    }

    getParentCollection(rootContext?: BindingContext): BindingContext {
        let bindingContext: BindingContext = this;
        let isRoot: boolean;

        do {
            bindingContext = bindingContext.getParent();
            isRoot = !bindingContext || bindingContext.isSame(rootContext);
        } while (!bindingContext?.removeKey().isCollection() && !isRoot);

        return isRoot ? undefined : bindingContext;
    }

    /**
     * Checks if the binding context is first level collection with key
     * e.g. InvoicesReceived(1)/Items(1)/Description (true)
     *    InvoicesReceived(1)/Items/Description (false)
     * true also for InvoicesReceived(1)/Items(1)/LabelSelection/Labels
     *  even though Labels does not have key, but it is first level collection with key
     *  ==> it should use fields info of the item(1)
     * */
    isinSecondLevelCollectionWithKey(): boolean {
        const secondLevelBc = this.getNthLevel(2);

        return secondLevelBc && isDefined(secondLevelBc.getKey()) && secondLevelBc.removeKey().isCollection();
    }

    getNthLevel(n: number): BindingContext {
        let currentLevel = this.getFullPath().split(SEPARATOR).length;

        if (n <= 0 || n > currentLevel) {
            return null;
        }

        let bindingContext: BindingContext = this;

        while (currentLevel > n) {
            bindingContext = bindingContext.getParent();
            currentLevel -= 1;
        }

        return bindingContext;
    }


    isProperty() {
        return !!this._isProperty;
    }

    isNavigation() {
        return !this.isProperty() && !!this.getParent();
    }

    /** Backend use Id as key property for regular entities and Code for enumerations */
    isEnum() {
        return this.getKeyPropertyName() === BindingContext.ENUM_KEY_PROP;
    }

    isNew() {
        return !!this._isNew;
    }

    isLocal() {
        return !!this._isLocal;
    }

    /**
     * Creates new BindingContext with path merged from current path and given path.
     *
     * @param path {string} Can be complex path with '/'. In that case creates deep BindingContext structure.
     * @returns {BindingContext}
     */
    navigate(path: string) {
        const parts = splitPath(path);
        let bindingContext: BindingContext = this;

        for (let i = 0; i < parts.length; i++) {
            const properties = {
                metadata: this._metadata,
                ...this.parsePath(parts[i], bindingContext)
            };
            bindingContext = new BindingContext(properties);
        }

        return bindingContext;
    }

    /** Returns false if the navigation fails */
    isValidNavigation(path: string): boolean {
        try {
            this.navigate(path);
        } catch {
            return false;
        }

        return true;
    }

    * iterateNavigation(navigationPropertyName: string, entities: IEntity[]) {
        const navigationBindingContext = this.navigate(navigationPropertyName);
        for (const entity of entities || []) {
            yield {
                entity: entity,
                bindingContext: navigationBindingContext.addKey(entity)
            };
        }
    }

    /** If current bindingContext isn't property, navigates just like navigate method.
     * If current bindingContext is property, takes parent and navigates from it instead - returns sibling bindingContext.*/
    navigateWithSiblingFallback(path: string) {
        if (this.isNavigation() || !this.getParent()) {
            return this.navigate(path);
        } else {
            return this.getParent().navigate(path);
        }
    }

    getNavigationBindingContext = (displayName?: string) => {
        return this.isNavigation() ? this.navigate(displayName ?? this.getKeyPropertyName()) : this;
    };

    getKey() {
        return this._key;
    }

    /**
     * Creates new binding context, with same parent and adds key
     * InvoicesReceived(1)/Items and InvoicesReceived(1)/Items(1) will be two different context instances with same parent InvoicesReceived(1)
     *
     * @param key {string|object} key string or entity value object
     * @param isNew {boolean} whether returned binding context represents new entity
     * @returns {BindingContext}
     */
    addKey(key: TEntityKey | IEntity, isNew = false) {
        // key can be '0'
        if (isNotDefined(this.getKey())) {
            if (typeof key === "object") {
                if (BindingContext.NEW_ENTITY_ID_PROP in key) {
                    key = ((key as IEntity)[BindingContext.NEW_ENTITY_ID_PROP] as string);
                    isNew = true;
                } else {
                    const propertyName = isNew ? BindingContext.NEW_ENTITY_ID_PROP : this.getKeyPropertyName();
                    key = ((key as IEntity)[propertyName] as string);
                }
            }

            return new BindingContext({
                path: this.getPath(),
                metadata: this._metadata,
                parent: this.getParent(),
                key,
                isCollection: false,
                isLocal: this.isLocal(),
                isNew
            });

        } else {
            throw new Error("key already exists");
        }
    }

    /**
     * Removes key and return new binding context.
     * */
    removeKey() {
        return new BindingContext({
            path: this.getPath(true),
            metadata: this._metadata,
            parent: this.getParent(),
            key: null,
            isCollection: !!(this.getProperty()?.getType().isCollection())
        });
    }

    toString(withoutKey = false): string {
        return this.getFullPath(withoutKey);
    }

    getPath(withoutKey = false) {
        let path = this._path;
        let key = this.getKey();

        if (!withoutKey && !isNotDefined(key)) {
            if (typeof key === "string") {
                key = `'${key}'`;
            }

            if (this.isNew()) {
                path = `${this._path}(${BindingContext.NEW_ENTITY_ID_PROP}=${key})`;
            } else {
                path = `${this._path}(${key})`;
            }
        }

        return this.isLocal() ? `${BindingContext.LOCAL_CONTEXT_SIGN}${path}${BindingContext.LOCAL_CONTEXT_SIGN}` : path;
    }

    /**
     * Returns path up to the first parent that is not root (better support for local context, then with isNavigation check)
     * @param {boolean} withoutKey to ignore key of the binding context
     * @param {BindingContext} rootContext defines the root binding context, up to which the search can go (instead of the rootParent of this BindingContext)
     * e.g. for "ChartsOfAccounts(1)/Accounts/Name" with "ChartsOfAccounts(1)/Accounts" set as root context, "Name" will be returned instead of "Accounts/Name"
     */
    getNavigationPath(withoutKey?: boolean, rootContext?: BindingContext) {
        if (this.isRoot()) {
            return "";
        }

        return this.getPathBy((bindingContext) => {
            if (rootContext && rootContext.getFullPath(true) === bindingContext.getParent()?.getFullPath(true)) {
                return false;
            }
            return bindingContext.getParent() && !bindingContext.getParent().isRoot();
        }, withoutKey);
    }

    /**
     * Returns path up to the first parent that is not navigation or is entitySet (isNavigation === false || isCollection === true)
     */
    getEntityPath(withoutKey?: boolean) {
        return this.getPathBy((bindingContext) => {
            return bindingContext.getParent() && (bindingContext.getParent().isNavigation()) && !bindingContext.getParent().isCollection();
        }, withoutKey);
    }

    getPathBy(testFn: (bindingContext: BindingContext) => boolean, withoutKey?: boolean) {
        let path = this.getPath(withoutKey);
        let bindingContext: BindingContext = this;

        while (testFn(bindingContext)) {
            bindingContext = bindingContext.getParent();
            path = `${bindingContext.getPath(withoutKey)}/${path}`;
        }

        return path;
    }

    getFullPath(withoutKey = false) {
        let currentContext: BindingContext = this;
        let path;

        do {
            if (path) {
                path = `${currentContext.getPath(withoutKey)}/${path}`;
            } else {
                path = this.getPath(withoutKey);
            }

            currentContext = currentContext.getParent();
        } while (currentContext);

        return path;
    }

    getFullPathAsArrayOfContexts() {
        const contexts = [];
        let currentContext: BindingContext = this;

        do {
            contexts.unshift(currentContext);
            currentContext = currentContext.getParent();
        } while (currentContext);

        return contexts;
    }

    createNewBindingContext(path: string) {
        return createBindingContext(path, this._metadata);
    }
}

export const createBindingContext = (path: string, metadata: Metadata) => {
    const [rootEntitySet, ...navigation] = splitPath(path);

    let entitySetName = rootEntitySet.split("(")[0];
    const { key, keyProp } = BindingContext.parseKey(rootEntitySet);
    const isLocal = BindingContext.isLocalContextPath(path);

    if (isLocal) {
        entitySetName = BindingContext.cleanLocalContext(entitySetName);
    }

    const bindingContext = new BindingContext({
        path: entitySetName,
        key,
        isLocal,
        isNew: keyProp === BindingContext.NEW_ENTITY_ID_PROP,
        metadata: metadata,
        isCollection: true
    });

    if (navigation.length > 0) {
        return bindingContext.navigate(navigation.join(SEPARATOR));
    } else {
        return bindingContext;
    }
};

export const getBindingContext = memoizeOne((entitySet: string, metadata: Metadata): BindingContext => {
    return createBindingContext(entitySet, metadata);
});

export const areBindingContextsDifferent = (bc1: BindingContext, bc2: BindingContext, withoutKey = false): boolean => {
    if (bc1 && bc2) {
        return bc1.toString(withoutKey) !== bc2.toString(withoutKey);
    }

    return !!((!bc1 && bc2) || (bc1 && !bc2));
};

export const createPath = (...args: string[]): string => {
    return args.filter(path => !!path).join(SEPARATOR);
};

export const splitPath = (path: string): string[] => {
    return path.split(SEPARATOR);
};
