import { withConfirmationDialog } from "@components/dialog/withConfirmationDialog";
import IssuedDocumentBaseFormView from "../IssuedDocumentBaseFormView";

import { withPermissionContext } from "../../../contexts/permissionContext/withPermissionContext";
import { DocumentTypeCode } from "@odata/GeneratedEnums";
import { withTimeResolution } from "../extensions/timeResolution/withTimeResolution";
import { withDomManipulator } from "../../../contexts/domManipulator/withDomManipulator";
import { withAccruals } from "../extensions/accruals/withAccruals";
import { withProforma } from "../extensions/proforma/withProforma";
import { withPromisedComponent } from "@components/dialog/withPromisedComponent";


class InvoicesIssuedFormView extends IssuedDocumentBaseFormView {
    documentTypeCode = DocumentTypeCode.InvoiceIssued;
}

export default withPromisedComponent(withConfirmationDialog(withTimeResolution(withAccruals({ isExpense: false })(withProforma({ isIssuedType: true })(withPermissionContext(withDomManipulator(InvoicesIssuedFormView)))))));
