import { IDefinition } from "@pages/PageUtils";
import ItemsSummary, { ItemsSummaryPath } from "./ItemsSummary";
import { FormStorage } from "../../../../views/formView/FormStorage";
import React from "react";
import CorrectiveItemsSummary, {
    CorrectiveItemsSummaryPath
} from "../../corrective/extensions/correctiveItemsSummary/CorrectiveItemsSummary";
import { IAppContext } from "../../../../contexts/appContext/AppContext.types";

export const ItemsSummariesPaths = [ItemsSummaryPath, CorrectiveItemsSummaryPath];

export const addItemsSummaryDef = (definition: IDefinition, context: IAppContext, addCorrectiveItemsSummary?: boolean) => {
    const itemsGroup = definition.form.groups.find(group => group.id === "Items");
    const itemsSummaryWrapperStyle: React.CSSProperties = {
        flex: "1 1 auto",
        alignItems: "flex-end",
        display: "flex",
        flexDirection: "column",
        rowGap: "60px",
        marginLeft: "38px"
    };

    itemsGroup.lineItems.itemsSummaryRenderer = (args) => {
        return (
            <div style={itemsSummaryWrapperStyle}>
                <ItemsSummary storage={args.storage as FormStorage}/>
                {addCorrectiveItemsSummary && <CorrectiveItemsSummary storage={args.storage as FormStorage}/>}
            </div>
        );
    };

    // default value, controlled by variant management
    // by default true, isVatRegisteredCompany behavior handled in ItemsSummary directly
    itemsGroup.lineItems.shouldShowItemsSummary = true;
};