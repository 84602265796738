import { EntitySetName, EntityTypeName } from "@odata/GeneratedEntityTypes";
import { CompanyPermissionCode, DocumentTypeCode } from "@odata/GeneratedEnums";
import { setDefByEntityType } from "@pages/getDefByEntityType";
import { getItemBreadCrumbsText, IDefinition, IGetDefinition } from "@pages/PageUtils";
import { isCashBasisAccountingCompany } from "@utils/CompanyUtils";
import i18next from "i18next";
import { cloneDeep } from "lodash";

import { IAppContext } from "../../../../contexts/appContext/AppContext.types";
import { Model } from "../../../../model/Model";
import { commonDocumentTranslations } from "../../Document.utils";
import { clearEmptyDateGroupDateFields, getDefinitions as getDocumentDefinitions } from "../../DocumentDef";
import { addItemsSummaryDef } from "../../extensions/itemsSummary/ItemsSummary.utils";
import { enhanceIssuedDocDefinition } from "../../invoicesIssued/InvoicesIssuedDef";
import { addCorrectionDefinition } from "../CommonCorrectiveSharedUtils";
import CorrectiveInvoicesIssuedFormView from "./CorrectiveInvoicesIssuedFormView";

export const getDefinitions: IGetDefinition = (context: IAppContext): IDefinition => {
    const hasAccountAssignment = !isCashBasisAccountingCompany(context);
    const definition = cloneDeep(getDocumentDefinitions({
        entitySet: EntitySetName.CorrectiveInvoicesIssued,
        documentType: DocumentTypeCode.CorrectiveInvoiceIssued,
        tableId: `${EntityTypeName.CorrectiveInvoiceIssued}Table`,
        formId: `${EntityTypeName.CorrectiveInvoiceIssued}Form`,
        formControl: CorrectiveInvoicesIssuedFormView,
        translationFiles: getDefinitions.translationFiles,
        getItemBreadCrumbText: (storage: Model) =>
                getItemBreadCrumbsText(storage, i18next.t("CorrectiveInvoicesIssued:Breadcrumbs.NewInvoice"),
                        storage.data.entity?.NumberOurs && i18next.t("CorrectiveInvoicesIssued:Breadcrumbs.InvoiceWithNumber", { number: storage.data.entity.NumberOurs })),
        permissions: [CompanyPermissionCode.InvoicesIssued],
        context
    }));

    enhanceIssuedDocDefinition(definition, {
        docType: DocumentTypeCode.CorrectiveInvoiceIssued,
        transFile: "CorrectiveInvoicesIssued",
        withoutAmountDueSummary: false,
        hasAccountAssignment,
        withoutAccruals: true,
        context
    });

    addItemsSummaryDef(definition, context, true);
    addCorrectionDefinition(definition, context);

    return clearEmptyDateGroupDateFields(definition);
};

getDefinitions.translationFiles = ["CorrectiveInvoicesIssued", "CorrectiveInvoicesShared", ...commonDocumentTranslations];
setDefByEntityType(EntityTypeName.CorrectiveInvoiceIssued, getDefinitions);