import { AppContext } from "../../../contexts/appContext/AppContext.types";
import { FormViewForExtend, IFormViewProps } from "../../../views/formView/FormView";
import {
    IPrSalaryComponentEntity,
    PrSalaryComponentEntity,
    PrSalaryComponentTemporalEntity
} from "@odata/GeneratedEntityTypes";
import { ISmartFieldChange } from "@components/smart/smartField/SmartField";
import {
    handleCurrentTemporalPropChange,
    setTemporalRequiredSelectDefaultPropertyBagValue
} from "@odata/TemporalUtils";
import { withPermissionContext } from "../../../contexts/permissionContext/withPermissionContext";
import { createPath } from "@odata/BindingContext";

interface IProps extends IFormViewProps<IPrSalaryComponentEntity, null> {
}

class SalaryComponentFormView extends FormViewForExtend<IPrSalaryComponentEntity, IProps> {
    static contextType = AppContext;
    static defaultProps = {
        title: "SalaryComponent:FormTitle"
    };

    async onAfterLoad(){

        const bindingContext = this.props.storage.data.bindingContext;

        if (bindingContext.isNew()) {
            setTemporalRequiredSelectDefaultPropertyBagValue(
                bindingContext.navigate(createPath(PrSalaryComponentEntity.CurrentTemporalPropertyBag, PrSalaryComponentTemporalEntity.Type)),
                this.props.storage
            );
        }
        return super.onAfterLoad();
    }

    handleChange = (e: ISmartFieldChange): void => {
        this.props.storage.handleChange(e);
        handleCurrentTemporalPropChange(e, this.props.storage);
        this.props.storage.refreshFields(e.triggerAdditionalTasks);
    };
}

export default withPermissionContext(SalaryComponentFormView);