import React from "react";
import { WorkDateFilledIcon, WorkDateIcon } from "../icon";
import { IconSize } from "../../enums";
import { Button, IconButton } from "../button";
import Dialog from "../dialog";
import Field from "../inputs/field";
import { DatePicker } from "../inputs/date";
import LocalSettings from "../../utils/LocalSettings";
import { WorkDatePickerBody, WorkDatePickerDateLabel } from "./WorkDatePicker.styles";
import { getWorkDateFromLocalStorage, isValidDate } from "../inputs/date/utils";
import DateType from "../../types/Date";
import { IInputOnChangeEvent } from "../inputs/input";
import { WithTranslation, withTranslation } from "react-i18next";

interface IProps extends WithTranslation {
    className?: string;
    hideEmpty?: boolean;
}

interface IState {
    isOpened: boolean;
    date: Date;
    errorText: string;
}

class WorkDatePicker extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            isOpened: false,
            date: getWorkDateFromLocalStorage(),
            errorText: ""
        };
    }

    handleDlgClose = () => {
        this.setState({ isOpened: false });
    };

    handleDlgConfirm = () => {
        if (this.validateDate()) {
            LocalSettings.set("WorkDate", {
                customData: {
                    workDate: this.state.date
                }
            });

            this.handleDlgClose();
        }
    };

    validateDate = () => {
        if (!this.state.date) {
            this.setState({
                errorText: this.props.t("Common:Validation.Required")
            });

            return false;
        }

        if (!isValidDate(this.state.date)) {
            this.setState({
                errorText: this.props.t("Common:Validation.NotADate")
            });

            return false;
        }

        if (this.state.errorText) {
            this.setState({
                errorText: ""
            });
        }

        return true;
    };

    handleDateBlur = () => {
        // in WithFormatter, date is parsed onChange using strictMode: true,
        // that doesn't allow for values like 010122 to be parsed correctly..
        // those values are parsed onBlur using strictMode: false,
        // problem is WorkDatePicker.handleDateChange is called in same tick as handleDateBlur
        // and we use state to store the current date value
        // => use setTimeout, so that the state already contains the correct parsed value
        // TODO maybe this should be already done in WorkDatePicker, so that we don't have to handle it again for each case?
        // probrat na review
        setTimeout(() => {
            this.validateDate();
        });
    };


    handleDlgOpen = () => {
        this.setState({
            date: getWorkDateFromLocalStorage(),
            errorText: "",
            isOpened: true
        });
    };

    handleDateChange = (e: IInputOnChangeEvent) => {
        this.setState({
            date: e.value as Date
        });
    };

    handleClearDate = () => {
        LocalSettings.remove("WorkDate", "customData");
        this.handleDlgClose();
    };

    createError = () => {
        return this.state.errorText ? {
            message: this.state.errorText
        } : null;
    };

    render() {
        const date = getWorkDateFromLocalStorage();
        if (!date && this.props.hideEmpty) {
            return null;
        }

        return (
            <WorkDatePickerBody className={this.props.className}>
                <IconButton isTransparent isActive={this.state.isOpened}
                            hotspotId={"workDatePicker"}
                            title={this.props.t("Document:General.WorkDate")} onClick={this.handleDlgOpen}>
                    {date ? <WorkDateFilledIcon width={IconSize.M}/> : <WorkDateIcon width={IconSize.M}/>}
                </IconButton>
                {date &&
                    <WorkDatePickerDateLabel>
                        {DateType.format(date)}
                    </WorkDatePickerDateLabel>
                }

                {this.state.isOpened &&
                    <Dialog
                        onClose={this.handleDlgClose}
                        onConfirm={this.handleDlgConfirm}
                        footer={
                            <>
                                <Button isTransparent onClick={this.handleDlgClose}>
                                    {this.props.t("Common:General.Cancel")}
                                </Button>
                                {date &&
                                    <Button isTransparent onClick={this.handleClearDate}>
                                        {this.props.t("Common:General.ShutDown")}
                                    </Button>
                                }
                                <Button onClick={this.handleDlgConfirm}>
                                    {this.props.t("Common:General.Confirm")}
                                </Button>
                            </>
                        }
                        title={this.props.t("Document:General.WorkDate")}>
                        <Field isRequired label={this.props.t("Document:General.WorkDate")}>
                            <DatePicker error={this.createError()}
                                        value={this.state.date}
                                        onBlur={this.handleDateBlur}
                                        onChange={this.handleDateChange}/>
                        </Field>
                    </Dialog>
                }
            </WorkDatePickerBody>
        );
    }
}

export default (withTranslation(["Common", "Document"])(WorkDatePicker));