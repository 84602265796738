import React from "react";
import { Dayjs } from "dayjs";
import { DatePickerView } from "../../../../enums";
import { getValidDayjsDate, isMaxDay, isMinDay } from "../utils/DateUtils";
import { DatePickerPopup } from "./DatePickerPopup";
import { WithTranslation, withTranslation } from "react-i18next";
import DateType, { getUtcDayjs } from "../../../../types/Date";
import { IDateSingleValuePopupSharedProps } from "./Calendar.utils";

interface IState {
    previewValue: Dayjs;
    view: DatePickerView;
}

interface IProps extends IDateSingleValuePopupSharedProps, WithTranslation {
    previewValue?: Date;
}

class DateTimePickerPopup extends React.PureComponent<IProps, IState> {
    static defaultProps: Partial<IProps> = {
        disableAutoFocus: true
    };

    _calendarRef = React.createRef<React.ElementType>();

    constructor(props: IProps) {
        super(props);

        const isSpecialDay = isMinDay(props.value) || isMaxDay(props.value);

        this.state = {
            previewValue: getValidDayjsDate(isSpecialDay ? null : this.props.value ?? this.props.previewValue),
            view: DatePickerView.Days
        };
    }

    componentDidMount(): void {
        if (!this.props.disableAutoFocus) {
            setTimeout(() => this.focusOnCalendar(), 0);
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>) {
        if (this.props.value !== prevProps.value && DateType.isValid(this.props.value)) {
            this.setState({ previewValue: getValidDayjsDate(this.props.value) });
        }
    }

    focusOnCalendar = () => {
        // focus on day only after the popup opens (not when switching between date/time using tabs)
        (this._calendarRef?.current as any)?.focus();
    };

    handleViewChange = (view: DatePickerView) => {
        this.setState({
            view: view
        }, this.focusOnCalendar);
    };

    handleDateChange = (value: Date) => {
        this.setState(() => {
            return {
                previewValue: !value ? null : getUtcDayjs(value)
            };
        });
    };

    handleOk = () => {
        this.props.onChange(!this.state.previewValue ? null : this.state.previewValue.toDate());
    };

    handleCancel = () => {
        this.props.onChange(this.props.value);
    };

    render = () => {
        return (
            <DatePickerPopup value={this.state.previewValue?.toDate()}
                             onChange={this.handleDateChange}
                             onViewChange={this.handleViewChange}
                             showSpecialValue={this.props.showSpecialValue}
                             calendarRef={this._calendarRef}
                             showTime
                             showConfirmButtons
                             onOk={this.handleOk}
                             onCancel={this.handleCancel}
                             disableAutoFocus/>
        );
    };
}

const DateTimePickerPopupWithTranslation = withTranslation("Components")(DateTimePickerPopup);

export { DateTimePickerPopupWithTranslation as DateTimePickerPopup };