import React, { useCallback, useContext } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Button, ButtonGroup } from "../button";
import { StyledDialog, Subtitle, Title } from "./RemainingTrialDialog.styles";
import ModalBackdrop from "../dialog/ModalBackdrop";
import { AppContext } from "../../contexts/appContext/AppContext.types";
import { isUserOwner } from "@pages/admin/users/Users.utils";
import TestIds from "../../testIds";
import { isInTrial, isNotYetPayedForSubscription } from "@pages/admin/subscriptions/Subscriptions.utils";
import { PaymentMethodCode } from "@odata/GeneratedEnums";
import { ROUTE_GOODBYE_SCREEN } from "../../routes";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";


interface IProps extends RouteComponentProps {
    remainingDays: number;
    onClose: (addSubscription: boolean) => void;
}

// Definition of used colors, keep the order to work correctly
const ColorDefs = [
    { maxDays: 2, background: "#373737", text: "#fff" },
    { maxDays: 5, background: "#787878", text: "#fff" },
    { maxDays: 8, background: "#afafaf", text: "#4b4a4b" },
    { maxDays: 11, background: "#dcdcdc", text: "#4b4a4b" },
    { maxDays: 14, background: "#fafafa", text: "#4b4a4b" }
];

const RemainingTrialDialog: React.FC<IProps> = ({ remainingDays, onClose }) => {
    const context = useContext(AppContext);
    const { t, ready: tReady } = useTranslation("Components");

    const isOwner = !!isUserOwner(context.getData()?.userSettings);
    const isFinal = remainingDays <= 0;
    const subscription = context.getData().subscription;
    const isNotPayedFor = isNotYetPayedForSubscription(subscription);
    const isTrial = isInTrial(subscription);
    const isPurchasedViaWire = !isNotPayedFor && subscription.PaymentMethodCode === PaymentMethodCode.WireTransfer;

    const handleBack = useCallback(() => {
        onClose?.(false);
    }, [onClose]);

    const handleClose = useCallback(() => {
        onClose?.(true);
    }, [onClose]);

    const def = ColorDefs.find(item => item.maxDays >= remainingDays);

    if (isFinal) {
        return null;
    }

    return !(def && tReady) ? null : (
        <ModalBackdrop onClose={handleClose}>
            <StyledDialog $backgroundColor={def.background} $color={def.text} $remains={def.maxDays}
                          data-testid={TestIds.Dialog}>
                {isFinal
                    ? (
                        <>
                            {/*just to be sure*/}
                            <Redirect to={ROUTE_GOODBYE_SCREEN}/>
                            {/*isFinal should from now on always be handled by GoodbyeScreen*/}
                            {/*  keeping this code for just a bit longer, cuz who knows if it won't change again*/}
                            {/*{isOwner ?*/}
                            {/*    (*/}
                            {/*        <>*/}
                            {/*            <Subtitle*/}
                            {/*                data-testid={TestIds.Subtitle}>{t("Components:RemainingTrialDialog.Deactivated")}</Subtitle>*/}
                            {/*            <Title*/}
                            {/*                data-testid={TestIds.Title}>{t("Components:RemainingTrialDialog.PleaseAddPaymentMethod")}</Title>*/}
                            {/*        </>*/}
                            {/*    ) : (*/}
                            {/*        <>*/}
                            {/*            <Subtitle*/}
                            {/*                data-testid={TestIds.Subtitle}>{t("Components:RemainingTrialDialog.Deactivated")}</Subtitle>*/}
                            {/*        </>*/}
                            {/*    )*/}
                            {/*}*/}

                        </>
                    ) : (<>
                        <Subtitle data-testid={TestIds.Subtitle}>
                            {isTrial
                                ? t("Components:RemainingTrialDialog.TrialRemaining")
                                : <Trans i18nKey={"Components:RemainingTrialDialog.PaymentNotReceived"}>
                                    Nepřijali jsme platbu za služby Evaly,<br/> do deaktivace služeb zbývá:
                                </Trans>
                            }
                        </Subtitle>
                        <Title $isBig
                               data-testid={TestIds.Title}>{t("Components:RemainingTrialDialog.XDays", { count: remainingDays })}</Title>
                    </>)}

                <ButtonGroup>
                    <Button isDecorative onClick={handleBack}>
                        {t(`Components:RemainingTrialDialog.${isFinal ? "GoBack" : "Skip"}`)}
                    </Button>
                    {isOwner && !isPurchasedViaWire &&
                        <Button onClick={handleClose}>
                            {t(`Components:RemainingTrialDialog.${isNotPayedFor ? "Purchase" : "EditPaymentMethod"}`)}
                        </Button>
                    }
                </ButtonGroup>
            </StyledDialog>
        </ModalBackdrop>
    );
};

export default withRouter(RemainingTrialDialog);