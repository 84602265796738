import React, { PureComponent } from "react";
import FileStrip, { IFileStripProps } from "../../fileStrip/FileStrip";
import { IFileStripItem } from "../../fileStrip/FileStrip.utils";
import CustomizationDialog from "../smartFilterBar/CustomizationDialog";
import { ICheckboxGroupChange } from "../../inputs/checkboxGroup/CheckboxGroup";
import { getDefaultVisibleItems } from "@pages/inbox/Inbox.utils";
import { AppContext, IAppContext } from "../../../contexts/appContext/AppContext.types";
import { OData } from "@odata/OData";
import { InboxEntityTypeCode } from "@odata/GeneratedEnums";


interface IProps extends Pick<IFileStripProps, "selectedId" | "onSelectionChange" | "items" | "onResize" |
    "isDragging" | "dragOverId" | "onDrop" | "onDragEnter" | "onDragLeave"> {
    dialogTitle: string;
    oData: OData;
}

interface IState {
    visibleFolders?: string[]; // folders actually visible in the strip
    selectedItems?: string[];  // folders selected in the customization dialog
    showAddDialog?: boolean;
}

export class SmartFileStrip extends PureComponent<IProps, IState> {
    static contextType = AppContext;

    state: IState = {
        visibleFolders: null,
        selectedItems: [],
    };

    componentDidMount() {
        this.loadData(this.context);
    }

    loadData = async (context: IAppContext): Promise<void> => {
        const settings = context.getData()?.personalizationSettings;
        let visibleFolders = settings?.selectedInboxFolders as InboxEntityTypeCode[];
        if (!visibleFolders) {
            visibleFolders = await getDefaultVisibleItems(context, this.props.oData);
        }

        this.setState({
            visibleFolders,
        });
    };

    getVisibleFolderItems(selectedIds: string[]): IFileStripItem[] {
        return this.props.items?.filter(item => item.isRequired || selectedIds?.includes(item.id)) ?? [];
    }

    getCustomizableItems(): IFileStripItem[] {
        return this.props.items
            .filter(item => !item.isRequired);
    }

    handleAddItems = (): void => {
        this.setState((currentState) => ({
            selectedItems: this.getCustomizableItems()
                    .filter(item => currentState.visibleFolders?.includes(item.id))
                    .map(item => item.id) ?? [],
            showAddDialog: true
        }));
    };

    handleFolderCustomization = (args: ICheckboxGroupChange): void => {
        this.setState({ selectedItems: args.values });
    };

    handleClose = (): void => {
        this.setState({ showAddDialog: false });
    };

    handleConfirm = (): void => {
        // InboxLocalSettings.set(SettingsId, { selectedFolders: this.state.selectedItems });
        this.context.changePersonalizationSetting("selectedInboxFolders", this.state.selectedItems);

        if (!this.state.selectedItems.includes(this.props.selectedId)) {
            this.props.onSelectionChange?.(null);
        }

        this.setState((state) => ({
            visibleFolders: this.getVisibleFolderItems(state.selectedItems).map(item => item.id),
            selectedItems: [],
            showAddDialog: false
        }));
    };

    renderCustomizationDialog(): React.ReactElement {
        return <CustomizationDialog title={this.props.dialogTitle}
                                    items={this.getCustomizableItems()}
                                    selectedItems={this.state.selectedItems}
                                    onChange={this.handleFolderCustomization}
                                    onClose={this.handleClose}
                                    onConfirm={this.handleConfirm}/>;
    }

    render() {
        const { dialogTitle, items, ...passProps } = this.props;
        const { visibleFolders } = this.state;
        const isLoaded = visibleFolders !== null;
        return (
            <>
                {isLoaded &&
                    <FileStrip {...passProps}
                               items={this.getVisibleFolderItems(visibleFolders)}
                               onAddItems={this.handleAddItems}
                    />}
                {this.state.showAddDialog && this.renderCustomizationDialog()}
            </>
        );
    }
}