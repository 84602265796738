import React from "react";
import { IIconButtonProps } from "../../button";
import { ISmartSelectProps, SmartSelect } from "./SmartSelect";
import IconSelect, { IIconSelectProps } from "../../inputs/select/IconSelect";

interface IProps extends ISmartSelectProps, Omit<IIconSelectProps, "onClick" | "ref" | "onIconActivate"> {
    icon: React.ReactElement;
    iconButtonProps?: Partial<IIconButtonProps>;
    hotspotId: string;
}

class SmartIconSelect extends React.PureComponent<IProps> {

    renderContent = (sharedProps: any) => {
        const { icon, iconButtonProps, ...rest } = this.props;

        return <SmartSelect
            {...rest}
            {...sharedProps}
        />;
    };

    handleClick = (e: React.MouseEvent) => {
        const { storage, bindingContext } = this.props;
        return this.props.onClick?.(e, { storage, bindingContext });
    };

    render() {
        const {onClick, onIconActivate, ref, ...rest} = this.props;

        return <IconSelect
            {...rest}
            onClick={this.handleClick}
            render={this.renderContent}/>;
    }
}

export default SmartIconSelect;