import React, { useMemo } from "react";
import {
    CategoryScale,
    Chart as ChartJS,
    ChartData,
    ChartOptions,
    Filler,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Tooltip
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { ChartWrapperStyled } from "./Charts.styles";
import { PropsWithTheme } from "../../theme";
import { withTheme } from "styled-components/macro";
import {
    ChartType,
    getChartTooltipOptions,
    getDefaultScalesOptions,
    IChartProps,
    legend,
    legendHeight
} from "./Charts.types";
import { IBarChartComponentData } from "./BarChart";
import { transparentize } from "polished";

ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Tooltip,
        Legend,
        Filler
);

type TLineChartOptions = ChartOptions<ChartType.Line>;
type TLineChartData = ChartData<ChartType.Line>;

export interface ILineChartComponentData extends IBarChartComponentData {
    filled?: boolean;
}

interface IProps extends PropsWithTheme, IChartProps<ILineChartComponentData> {
}

const LineChart: React.FC<IProps> = (props) => {
    const {
        data: { labels, dataSetLabels, filled, colors, values },
        formatter,
        width,
        height,
        responsive,
        theme
    } = props;

    const hasLegend = !!dataSetLabels;
    const options = useMemo<TLineChartOptions>(() => ({
        plugins: {
            legend: hasLegend ? legend : { display: false },
            title: { display: false },
            tooltip: getChartTooltipOptions(formatter)
        },
        layout: {
            padding: {
                bottom: hasLegend ? 0 : legendHeight
            }
        },
        scales: getDefaultScalesOptions(theme, formatter.bind(null, "y")),
        elements: {
            point: {
                radius: 5,
                hoverRadius: 7,
                hitRadius: 20,
                borderWidth: 0
            }
        },
        responsive
    }), [responsive, hasLegend, theme, formatter]);

    const data = useMemo<TLineChartData>(() => ({
        labels,
        datasets: values.map((dateSetValues, idx) => {
            const palleteColor = theme[colors[idx % colors.length]];
            return {
                label: dataSetLabels?.[idx] ?? "",
                fill: filled ? "origin" : false,
                data: dateSetValues,
                backgroundColor: transparentize(0.7, palleteColor),
                pointBackgroundColor: palleteColor,
                borderColor: palleteColor,
                pointHoverBackgroundColor: palleteColor,
                pointHoverBorderColor: theme["C_SEM_el_neutral"]
            };
        })
    }), [labels, filled, dataSetLabels, values, colors, theme]);

    return (
            <ChartWrapperStyled className={props.className}>
                <Line data={data} options={options} width={width} height={height}/>
            </ChartWrapperStyled>
    );
};

export default withTheme(LineChart);
