import styled from "styled-components/macro";
import DashboardTileBackground from "../../dashboard/DashboardTileBackground";
import { DashboardTileRadius, DashboardTileRadiusPx } from "../../dashboard/Dashboard.styles";
import { T_HEADER_bold } from "../../../global.style";

const BorderWidth = 3;

export const StyledDemoTenantTile = styled(DashboardTileBackground)`
    justify-content: space-around;
`;

export const CreateDemoTenant = styled.div`
    z-index: 1;
    text-align: center;
    ${T_HEADER_bold};
    font-weight: 600;
    letter-spacing: 0.18px;

    &::before, &::after {
        display: block;
        position: absolute;
        content: " ";
        z-index: -1;

        border-radius: ${DashboardTileRadiusPx};
    }

    &::after {
        border-radius: ${DashboardTileRadius - BorderWidth}px;
        background-color: ${props => props.theme.C_ACT_hover_without_opacity};
        top: ${BorderWidth}px;
        left: ${BorderWidth}px;
        bottom: ${BorderWidth}px;
        right: ${BorderWidth}px;
    }

    &::before {
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: ${props => props.theme.C_MAIN_brand_gradient};
    }
`;