import React from "react";
import { IEntity } from "@odata/BindingContext";

/**
 * Context to keep selected template and info, if it has changed, so the message could be shown on dialog close
 */
export const ChartOfAccountTemplateContext = React.createContext<ICOATemplateContext>(undefined);

export interface ICOATemplateContext {
    onTemplateNameChange: (template: IEntity, name: string) => void;
    onTemplateNameChangeFailed: () => void;
    isChangingName: boolean;
    isChanged: boolean;
}
