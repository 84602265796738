import styled from "styled-components/macro";
import { T_TITLE_4_NORMAL } from "../../global.style";
import { TooltipIconCopyLink } from "../../components/tooltipIcon";

export const TooltipIconCopyLinkStyled = styled(TooltipIconCopyLink)`
    visibility: hidden;
    margin-left: 2px;
`;

export const InboxCompanyEmailAddressStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    ${T_TITLE_4_NORMAL};

    &:hover ${TooltipIconCopyLinkStyled}, &:focus ${TooltipIconCopyLinkStyled} {
        visibility: visible;
    }
`;
