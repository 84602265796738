import React, { useCallback, useEffect, useState } from "react";
import { doesElementContainsElement, isDefined } from "@utils/general";
import { KeyName } from "../keyName";

interface IHideOnBlurHook {
    refs: (React.RefObject<HTMLElement> | HTMLElement)[];
    initialIsVisible?: boolean;
    onHide?: (event: FocusEvent | KeyboardEvent) => void;
    /** If this is undefined, hook will take care of the visible state.
     * If this is defined, visible state is taken from the props and only onHide callback is handled. */
    isVisible?: boolean;
}

/** Automatically recognize when focus leaves component and the component can be hidden.
 * !! WARNING !!
 * For this to work, we expect that the component this is used in
 * sets tabIndex=-1 on its main wrapper (or is focusable).
 * Otherwise, when user uses mouse to click in the component, focus would switch on body, and we would hide the component.
 * ALSO
 * if the component has more complex state and can be re-rendered and loose focus (like Calendar),
 * it has to set the focus back inside after the re-rendering is done. Otherwise, it will get closed.*/
export default function useHideOnBlur(args: IHideOnBlurHook) {
    const [isVisible, setIsVisible] = useState(args.initialIsVisible);
    const drivenFromProps = isDefined(args.isVisible);
    const visible = drivenFromProps ? args.isVisible : isVisible;

    const isContained = useCallback((elem: HTMLElement) => {
        return args.refs.some((ref) => {
            const isRefObject = typeof ref === "object" && ref.hasOwnProperty("current");

            return doesElementContainsElement(isRefObject ? (ref as React.RefObject<HTMLElement>).current : ref as HTMLElement, elem);
        });
    }, [args.refs]);

    const hideOnBlur = useCallback((event: FocusEvent) => {
        // use short setTimeout, so that the component that uses this can set focus
        // back inside, after it is re-rendered
        setTimeout(() => {
            const elementToCheck = document.activeElement as HTMLElement;

            if (visible && !isContained(elementToCheck)) {
                if (!drivenFromProps) {
                    setIsVisible(false);
                }

                args.onHide?.(event);
            }
        }, 10);
    }, [visible, isContained, drivenFromProps, args.onHide]);

    const closeOnEscape = useCallback((event: KeyboardEvent) => {
        if (visible && event.key === KeyName.Escape) {
            if (!drivenFromProps) {
                setIsVisible(false);
            }

            args.onHide?.(event);
        }
    }, [visible]);

    useEffect(() => {
        if (visible) {
            document.addEventListener("blur", hideOnBlur, true);
            document.addEventListener("keydown", closeOnEscape);
        }
        return () => {
            document.removeEventListener("blur", hideOnBlur, true);
            document.removeEventListener("keydown", closeOnEscape);
        };
    }, [visible, hideOnBlur]);

    return { isVisible, setIsVisible, isContained };
}
