import { TValue } from "../../global.types";
import { IFormatOptions } from "@odata/OData.utils";
import { BasicInputSizes, FieldType, NavigationSource, ValidatorType } from "../../enums";
import { IFieldDef, IFieldInfoProperties, IGetValueArgs, TValidatorFnResult } from "@components/smart/FieldInfo";
import {
    CashBoxEntity,
    CompanyBankAccountEntity,
    EntityTypeName,
    INumberRangeDefinitionEntity,
    INumberRangeEntity
} from "@odata/GeneratedEntityTypes";
import { ValidationError } from "yup";
import i18next from "i18next";

export const NumberRangeDefinitionPatternFormatter = (val: TValue, args?: IFormatOptions): string => {
    const item = args.item as INumberRangeDefinitionEntity;

    const parts = [
        item?.NumberRangeTypePrefix,
        item?.CarryForwardPrefix,
        item?.Prefix,
        "x".repeat(item?.NumberOfDigits),
        item?.Suffix
    ].filter(part => !!part);
    return parts?.length > 0 ? parts.join(" ") : undefined;
};

const NumberRangeToNumberRangeDefinitionFormatter = (val: TValue, args?: IFormatOptions) => {
    const entity = (args.bindingContext && args.storage ? args.storage.getValue(args.bindingContext) ?? args.entity : args.entity) as INumberRangeEntity;

    return NumberRangeDefinitionPatternFormatter(val, {
        ...args,
        item: entity?.Definition
    });
};

export const getDefaultRangeSelectAdditionalProperties = (prefix = ""): IFieldDef[] => {
    return [
        { id: `${prefix}Definition/Suffix` },
        { id: `${prefix}Definition/NumberRangeTypePrefix` },
        { id: `${prefix}Definition/Prefix` },
        { id: `${prefix}Definition/NumberOfDigits` },
        { id: `${prefix}Definition/IsDefault` }
    ];
};

const additionDefFields = [
    { id: `Suffix` },
    { id: `NumberRangeTypePrefix` },
    { id: `Prefix` },
    { id: `NumberOfDigits` },
    { id: `IsDefault` },
    { id: `IsActive` },
    { id: `Name` }
];

type TSupportedTypes =
        EntityTypeName.CashReceiptIssued
        | EntityTypeName.CashReceiptReceived
        | EntityTypeName.BankStatement;
export const getDefaultRangeDefinition = (type: TSupportedTypes): IFieldInfoProperties => {
    const propName = type === EntityTypeName.BankStatement ? CompanyBankAccountEntity.BankStatementNumberRangeDefinition
            : (type === EntityTypeName.CashReceiptIssued ? CashBoxEntity.ReceiptIssuedNumberRangeDefinition : CashBoxEntity.ReceiptReceivedNumberRangeDefinition);

    return {
        type: FieldType.ComboBox,
        width: BasicInputSizes.XXL,
        formatter: NumberRangeDefinitionPatternFormatter,
        isRequired: true,
        fieldSettings: {
            shouldDisplayAdditionalColumns: true,
            displayName: "Prefix",
            localDependentFields: [{
                from: { id: "IsActive" }, to: { id: "IsActive" },
                navigateFrom: NavigationSource.Itself
            }]
        },
        filter: {
            select: `NumberRangeTypeCode eq '${type}' and IsActive eq true`
        },
        columns: [
            {
                id: "Prefix",
                formatter: NumberRangeDefinitionPatternFormatter,
                additionalProperties: additionDefFields
            },
            {
                id: "Name"
            }
        ],
        additionalProperties: additionDefFields,
        validator: {
            type: ValidatorType.Custom,
            settings: {
                customValidator: (value: TValue, args: IGetValueArgs): TValidatorFnResult => {
                    const { entity } = args.storage.data;
                    if (!entity[propName]?.IsActive) {
                        return new ValidationError(i18next.t("Error:NumberRangeIsNotActive"), value, args.bindingContext.getPath(true));
                    }
                    return true;
                }
            }
        }
    };
};


export const getDefaultRange = (type: string, preload = false): IFieldInfoProperties => {
    return {
        type: FieldType.ComboBox,
        width: BasicInputSizes.XXL,
        formatter: NumberRangeToNumberRangeDefinitionFormatter,
        isRequired: true,
        fieldSettings: {
            shouldDisplayAdditionalColumns: true,
            displayName: "Definition/Prefix",
            preloadItems: preload
        },
        filter: {
            select: `Definition/NumberRangeTypeCode eq '${type}'`
        },
        columns: [
            {
                id: "Definition/Prefix",
                formatter: NumberRangeToNumberRangeDefinitionFormatter,
                additionalProperties: getDefaultRangeSelectAdditionalProperties("/")
            },
            {
                id: "Definition/Name"
            }
        ],
        additionalProperties: getDefaultRangeSelectAdditionalProperties()
    };
};
