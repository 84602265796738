import React from "react";
import { CommentImageOverlay, CommentImageUploaderImage, StyledCommentImage } from "./HotspotTooltipContent.styles";
import { IProps as IImageUploaderImageProps } from "../imageUploader/ImageUploaderImage";
import { BinIcon } from "../icon";
import { IconButton } from "../button";
import { TImage } from "../imageUploader";
import { WithTranslation, withTranslation } from "react-i18next";

interface IProps extends IImageUploaderImageProps, WithTranslation {
    onClick?: (image: TImage) => void;
    customOverlayContent?: React.ReactNode;
    customOverlayTitle?: string;
}

class CommentImage extends React.PureComponent<IProps> {
    handleClick = () => {
        this.props.onClick?.(this.props.image);
    };

    render() {
        return (
            <StyledCommentImage>
                <CommentImageUploaderImage {...this.props}/>
                <CommentImageOverlay>
                    {this.props.customOverlayContent ??
                        <IconButton
                            title={this.props.customOverlayTitle ?? this.props.t("Components:ImageUploader.RemoveImage")}
                            isLight
                            isDecorative
                            onClick={this.handleClick}>
                            <BinIcon forceHover/>
                        </IconButton>
                    }
                </CommentImageOverlay>
            </StyledCommentImage>
        );
    }
}

export default withTranslation(["Components"])(CommentImage);