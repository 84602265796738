import React from "react";
import { TableWrapper } from "../../../views/table/TableView.styles";
import View from "../../../views/View";
import TableWithAutoSizedColumns from "../../../components/table/TableWithAutoSizedColumns";
import ViewHeader from "../../../components/smart/smartHeader/SmartHeader";
import { withTranslation, WithTranslation } from "react-i18next";
import { IRow } from "@components/table";
import { TextAlign } from "../../../enums";
import { DocumentTypeCode } from "@odata/GeneratedEnums";
import { getEnumNameSpaceName } from "@odata/GeneratedEnums.utils";
import { EntityTypeName, ICompanyEntity } from "@odata/GeneratedEntityTypes";
import { BreadCrumbProvider } from "../../../components/breadCrumb";
import { ROUTE_HOME } from "../../../routes";
import customFetch from "../../../utils/customFetch";
import { DASHBOARD_DATA_API } from "../../../constants";
import { AppContext } from "../../../contexts/appContext/AppContext.types";
import i18next from "i18next";
import { getDocumentNameTableCell, SortedDocumentTypeCodes } from "../../documents/Document.utils";
import { TRecordType } from "../../../global.types";
import { getEntityTypeFromDocumentType } from "@odata/EntityTypes";

interface IProps extends WithTranslation {
}

interface IState {
    rows: IRow[];
    loading: boolean;
}

const COMPANY_DOCUMENTS_COUNT_URL = `${DASHBOARD_DATA_API}/CrossCompanyDocumentCountByDocumentType`;

interface ICompanyDocumentsCountRes {
    CompanyId: number;
    Count: Record<DocumentTypeCode, number>;
}

class PostedDocumentsLastMonth extends React.Component<IProps, IState> {
    static contextType = AppContext;

    state: IState = {
        rows: [],
        loading: true
    };

    componentDidMount() {
        this.load();

        // not sure we want those breadcrumbs => no translation
        this.context.setViewBreadcrumbs({
            items: [{
                key: "DashBoard",
                title: "Domov",
                link: ROUTE_HOME
            }, {
                key: "PostedDocumentsLastMonth",
                title: "Počet dokladů zaúčtovaných za poslední skončený měsíc",
            }],
            lockable: false
        });
    }

    getCompanyKey = (cId: number) => {
        return `company-${cId}`;
    };

    get columns() {
        const companies = this.context.getData().companies;
        const companyCols = companies.map((company: ICompanyEntity) => (
            { id: this.getCompanyKey(company.Id), label: company?.Name, textAlign: TextAlign.Right }
        ));
        return [
            { id: "name", label: this.props.t("Home:PostedDocumentsLastMonth.Name"), textAlign: TextAlign.Left },
            ...companyCols
        ];
    }

    load = async (): Promise<void> => {
        const [res,] = await Promise.all([
            customFetch(COMPANY_DOCUMENTS_COUNT_URL),
            i18next.loadNamespaces(["Document", "Home"])
        ]);

        const data = await res.json() as ICompanyDocumentsCountRes[];

        const transposedData: Record<string, Record<string, number>> = {};
        const totalKey = "total";

        for (const row of data) {
            for (const type of Object.keys(row.Count)) {
                if (!transposedData[type]) {
                    transposedData[type] = {
                        [totalKey]: 0
                    };
                }
                const count = row.Count[type as DocumentTypeCode];
                transposedData[type][this.getCompanyKey(row.CompanyId)] = count;
                transposedData[type][totalKey] += count;
            }
        }

        const companies = this.context.getData().companies;
        const sum: TRecordType<number> = {};

        const rows: IRow[] = SortedDocumentTypeCodes.map((documentTypeCode: DocumentTypeCode) => {
            const rowValues: TRecordType<number> = {};
            companies.forEach((c: ICompanyEntity) => {
                const key = this.getCompanyKey(c.Id);
                rowValues[key] = transposedData[documentTypeCode]?.[key] ?? 0;
                sum[key] = (sum[key] ?? 0) + rowValues[key];
            });
            return {
                id: documentTypeCode,
                values: {
                    name: getDocumentNameTableCell(getEntityTypeFromDocumentType(documentTypeCode), { showJustDDOPP: true }),
                    ...rowValues
                }
            };
        });

        rows.push({
            id: "total",
            values: {
                name: this.props.t("Home:PostedDocumentsLastMonth.Total").toString(),
                ...sum
            }
        });

        this.setState({ loading: false, rows });
    };

    render() {
        if (!this.props.tReady) {
            return null;
        }

        return (
            <>
                <BreadCrumbProvider back={ROUTE_HOME}/>
                <View hotspotContextId={"postedDocumentsLastMonth"}>
                    <ViewHeader title={this.props.t("Home:PostedDocumentsLastMonth.TableHeading")}
                                shouldHideVariant/>
                    <TableWrapper>
                        <TableWithAutoSizedColumns
                            busy={this.state.loading}
                            rows={this.state.rows}
                            disableSort={true}
                            columns={this.columns}/>
                    </TableWrapper>
                </View>
            </>
        );
    }
}

export default withTranslation(["Home", getEnumNameSpaceName(EntityTypeName.DocumentType)])(PostedDocumentsLastMonth);