import { IPrEmployeeEntity, PrEmployeeEntity, PrSalaryAdvanceEntity } from "@odata/GeneratedEntityTypes";
import { IFormatOptions } from "@odata/OData.utils";
import { ISmartTemporalPropertyDialogCustomData } from "@odata/TemporalUtils";

import { BasicInputSizes, FieldType } from "../../../enums";
import { TValue } from "../../../global.types";
import BindingContext, { createPath } from "../../../odata/BindingContext";
import { transformUserSelectItems, universalPersonNameFormatter } from "../../admin/users/Users.utils";
import { TFieldDefinition } from "../../PageUtils";

export const ShowContactAddressPath = "##ShowContactAddress##";
export const ShowDisabilityPath = "##ShowDisability##";
export const ShowStudyPath = "##ShowStudy##";
export const ShowPensionPath = "##ShowPension##";
export const ShowOtherIdentifiers = "##ShowOtherIdentifiers##";

export const PreviousEmploymentDurationDaysPath = "##PreviousEmploymentDurationDays##";
export const PreviousEmploymentDurationYearsPath = "##PreviousEmploymentDurationYears##";

export interface IPrEmployeeEntityExtended extends IPrEmployeeEntity {
    [ShowContactAddressPath]: boolean;
    [PreviousEmploymentDurationDaysPath]: number;
    [PreviousEmploymentDurationYearsPath]: number;
    [ShowOtherIdentifiers]: boolean;
    [ShowDisabilityPath]: boolean;
    [ShowStudyPath]: boolean;
}

export interface IEmployeeFormCustomData extends ISmartTemporalPropertyDialogCustomData {
    carsDialogBc?: BindingContext;
    taxPayerDeclarationDialogOpen?: boolean;
}

export const employeeNameFormatter = (val: TValue, args?: IFormatOptions<IPrEmployeeEntity, IPrEmployeeEntity>): string => {
    return universalPersonNameFormatter(args.item);
};

export const employeeSelectDef: TFieldDefinition = {
    type: FieldType.ComboBox,
    width: BasicInputSizes.XL,
    fieldSettings: {
        displayName: PrEmployeeEntity.FirstName,
        shouldDisplayAdditionalColumns: true,
        passItemContentToInput: true,
        transformFetchedItems: transformUserSelectItems,
        localDependentFields: [
            {
                from: { id: PrEmployeeEntity.FirstName },
                to: { id: createPath(PrSalaryAdvanceEntity.Employee, PrEmployeeEntity.FirstName) }
            },
            {
                from: { id: PrEmployeeEntity.LastName },
                to: { id: createPath(PrSalaryAdvanceEntity.Employee, PrEmployeeEntity.LastName) }
            },
            {
                from: { id: PrEmployeeEntity.NumberOurs },
                to: { id: createPath(PrSalaryAdvanceEntity.Employee, PrEmployeeEntity.NumberOurs) }
            }
        ]
    },
    formatter: employeeNameFormatter,
    columns: [
        {
            id: PrEmployeeEntity.FirstName,
            additionalProperties: [
                { id: PrEmployeeEntity.LastName }
            ],
            formatter: employeeNameFormatter
        },
        { id: PrEmployeeEntity.NumberOurs }
    ],
    additionalProperties: [
        { id: PrEmployeeEntity.FirstName },
        { id: PrEmployeeEntity.LastName },
        { id: PrEmployeeEntity.NumberOurs }
    ]
};