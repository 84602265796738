import { IAppContext } from "../../../contexts/appContext/AppContext.types";
import { REST_API_URL } from "../../../constants";
import { getActiveFiscalYear } from "@pages/fiscalYear/FiscalYear.utils";
import customFetch, { getDefaultPostParams } from "../../../utils/customFetch";
import { formatDateToDateString } from "../../inputs/date/utils";
import { IReportData, NumberAggregationFunction } from "@pages/reports/Report.utils";
import { CbaEntryTypeCode } from "@odata/GeneratedEnums";
import { IFiscalYearEntity } from "@odata/GeneratedEntityTypes";
import { TValue } from "../../../global.types";

export interface IIncomeExpenseOverviewData {
    income: {
        tax: number,
        nontax: number
    },
    expense: {
        tax: number,
        nontax: number
    }
}

export async function getIncomeExpenseOverviewData(context: IAppContext, fiscalYear?: IFiscalYearEntity): Promise<IIncomeExpenseOverviewData> {
    const url = `${REST_API_URL}/CbaIncomeExpenseOverview`;

    let fy = fiscalYear;
    if (!fy) {
        // await FY promise before getting them...
        await context.getFYPromise();
        fy = getActiveFiscalYear(context);
    }

    let incomeValues: Record<string, TValue> = {};
    let expenseValues: Record<string, TValue> = {};

    try {
        const response = await customFetch(url, {
            ...getDefaultPostParams(),
            body: JSON.stringify({
                "DateRange": {
                    DateStart: formatDateToDateString(fy?.DateStart),
                    DateEnd: formatDateToDateString(fy?.DateEnd)
                },
                "IncludeUnrelatedToBusiness": false,
                "ReportHierarchy": {
                    "Aggregate": true,
                    "Groups": [{
                        ColumnAlias: "CbaEntryType_Name"
                    }],
                    "Columns": [],
                    "Aggregations": [{
                        ColumnAlias: "CbaIncomeExpenseOverview_AmountTaxed",
                        AggregationFunction: NumberAggregationFunction.Sum
                    }, {
                        "ColumnAlias": "CbaIncomeExpenseOverview_AmountNonTaxed",
                        AggregationFunction: NumberAggregationFunction.Sum
                    }]
                }
            })
        });

        const data = await response.json() as IReportData;

        incomeValues = data.Rows?.find(r => r.Value["CbaIncomeExpenseOverview_CbaEntryTypeCode"] === CbaEntryTypeCode.Income)?.Value;
        expenseValues = data.Rows?.find(r => r.Value["CbaIncomeExpenseOverview_CbaEntryTypeCode"] === CbaEntryTypeCode.Expense)?.Value;
    } catch {

    }

    return {
        income: {
            tax: Math.abs(incomeValues?.["CbaIncomeExpenseOverview_AmountTaxed_SUM"] as number ?? 0),
            nontax: Math.abs(incomeValues?.["CbaIncomeExpenseOverview_AmountNonTaxed_SUM"] as number ?? 0)
        },
        expense: {
            tax: Math.abs(expenseValues?.["CbaIncomeExpenseOverview_AmountTaxed_SUM"] as number ?? 0),
            nontax: Math.abs(expenseValues?.["CbaIncomeExpenseOverview_AmountNonTaxed_SUM"] as number ?? 0)
        }
    };
}