import React from "react";
import { Label, SummaryItemStyled, Value, ValueWrapper } from "./Summary.styles";
import TestIds from "../../testIds";
import { IconButton } from "../button";
import { WithTranslation, withTranslation } from "react-i18next";
import { IToString, TValue } from "../../global.types";
import { isNotDefined } from "@utils/general";
import { TextAlign } from "../../enums";
import { EditIcon, IProps as IIconProps, SearchIcon } from "../icon";
import { IAlertProps } from "../alert/Alert";
import { IValidationError } from "../../model/Validator.types";
import { Validator } from "../../model/Validator";
import FormError from "../inputs/formError/FormError";

interface IProps extends WithTranslation {
    id: IToString;
    hotspotId?: string;
    label: string;
    value?: TValue;
    editable?: boolean;
    link?: string;
    isDisabled?: boolean;
    disabledAlert?: IAlertProps;
    isReadOnly?: boolean;
    color?: string;
    textAlign?: TextAlign;
    dataName?: string;
    onEdit?: (id: IToString) => void;
    error?: IValidationError;
    icon?: React.ComponentType<IIconProps>;
}

class SummaryItem extends React.PureComponent<IProps> {
    handleClick = () => {
        this.props.onEdit?.(this.props.id);
    };

    render() {
        const showEditButton = this.props.editable && !this.props.isReadOnly;

        if (isNotDefined(this.props.value) && !showEditButton) {
            return null;
        }

        const error = this.props.error && Validator.getErrorText(this.props.error, this.props.i18n);
        const Icon = this.props.icon ?? EditIcon;
        const hasLabel = !!this.props.label;
        const hasIcon = showEditButton || (this.props.link && !this.props.isReadOnly);

        const withoutPadding = hasLabel && hasIcon;

        return (
            <SummaryItemStyled
                textAlign={this.props.textAlign}
                data-testid={TestIds.SummaryItem}
                data-name={this.props.dataName}>
                {this.props.label &&
                    <Label isEditable={showEditButton}
                           hasLink={!!this.props.link}
                           data-testid={TestIds.SummaryItemLabel}>
                        {this.props.label}:
                    </Label>
                }
                <ValueWrapper textAlign={this.props.textAlign} hasPadding={!withoutPadding}>
                    <Value color={this.props.color} label={this.props.label} hasIcon={hasIcon}
                           hasPadding={!this.props.label}
                           data-testid={TestIds.SummaryItemValue}>
                        {this.props.value}
                        {error && <FormError errorText={error}/>}
                    </Value>
                    {showEditButton &&
                        <IconButton hotspotId={`${this.props.hotspotId ?? this.props.id}-summaryItemEdit`}
                                    title={this.props.t("Common:General.Edit")}
                                    onClick={this.handleClick}
                                    isDisabled={this.props.isDisabled}
                                    alert={this.props.disabledAlert}
                                    isTransparent>
                            <Icon/>
                        </IconButton>
                    }
                    {this.props.link && !this.props.isReadOnly &&
                        <IconButton hotspotId={`${this.props.hotspotId ?? this.props.id}-summaryItemLink`}
                                    title={this.props.t("Common:General.Open")}
                                    link={this.props.link}
                                    isDisabled={this.props.isDisabled}
                                    isTransparent>
                            <SearchIcon/>
                        </IconButton>
                    }
                </ValueWrapper>
            </SummaryItemStyled>
        );
    }
}

export default withTranslation(["Common"])(SummaryItem);