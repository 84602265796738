import { OData } from "@odata/OData";
import { CountryCode, TaxCalendarTaxTypeCode } from "@odata/GeneratedEnums";
import { EntitySetName, ITaxCalendarEntity } from "@odata/GeneratedEntityTypes";
import { transformToODataString } from "@odata/OData.utils";
import { ValueType } from "../../../enums";


interface IGetTaxCalendarDataArgs {
    country: CountryCode;
    from?: Date;
    to?: Date;
    type?: TaxCalendarTaxTypeCode;
    limit?: number;
}

// Next electronic submission date
export async function getTaxCalendarData(oData: OData, args: IGetTaxCalendarDataArgs): Promise<ITaxCalendarEntity[]> {
    const filterQueries: string[] = [];

    filterQueries.push(`CountryCode eq '${args.country}'`);

    if (args.from) {
        filterQueries.push(`Date ge ${transformToODataString(args.from, ValueType.Date)}`);
    }
    if (args.to) {
        filterQueries.push(`Date ge ${transformToODataString(args.to, ValueType.Date)}`);
    }
    if (args.type) {
        filterQueries.push(`TaxTypeCode eq '${args.type}'`);
    }

    const query = oData.getEntitySetWrapper(EntitySetName.TaxCalendars).query()
        .filter(filterQueries.join(" AND "))
        .select("Id", "Date", "Description")
        .expand("TaxType", (query) =>
            query.select("Name"))
        .orderBy("Date", true);

    if (args.limit) {
        query.top(args.limit);
    }
    const res = await query.fetchData<ITaxCalendarEntity[]>();

    return res.value;
}