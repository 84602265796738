import { TRecordAny, TRecordValue } from "../../global.types";
import { ICustomBreadCrumb } from "../breadCrumb";
import { IHistoryState, Model } from "../../model/Model";
import LocalSettings from "../../utils/LocalSettings";
import * as History from "history";
import { PageViewMode, QueryParam } from "../../enums";
import { IAppContext } from "../../contexts/appContext/AppContext.types";
import { ICellValueObject } from "../table";
import DrillDownLink from "./DrillDownLink";
import React from "react";
import { getQueryParameters } from "../../routes/Routes.utils";
import { getValue } from "@utils/general";
import { addCompanyIdToUrl } from "../../contexts/appContext/AppContext.utils";

export const presetFilters = (tableId: string, filters: TRecordAny): void => {
    LocalSettings.set(tableId,
        {
            filters
        }
    );
};

export const encodeQueryParam = (param: TRecordAny) => {
    return encodeURIComponent(JSON.stringify(param));
};

export type THistoryLocation = History.LocationDescriptor<IHistoryState>;

export interface IGetDrillDownParams {
    route: string;
    context: IAppContext;
    storage?: Model;
    filters?: TRecordAny;
    customQueryString?: string;
    customContent?: React.ReactNode;
    companyId?: number;
}

export const getDrillDownNavParams = (args: IGetDrillDownParams): THistoryLocation => {
    const selectedMenu = args.context.getSelectedMenu();
    const viewBreadcrumbItem = args.context.getViewBreadcrumbs()?.items?.[0];
    const breadCrumbs: ICustomBreadCrumb[] = [...(args.storage?.initialHistoryState?.breadCrumbs ?? [])];

    const currentNavigation: THistoryLocation = {
        pathname: window.location.pathname, // todo: do we need sometimes just: selectedMenu.item.url,
        search: window.location.search,
        state: {
            back: breadCrumbs[breadCrumbs.length - 1]?.to,
            breadCrumbs: breadCrumbs.length > 0 ? [...breadCrumbs] : null
        }
    };

    const selectedMenuGroupBreadcrumb: ICustomBreadCrumb = selectedMenu?.group ? {
        // key needs to be unique, because breadcrumbs can be repeated
        key: `${selectedMenu.group.key}-${breadCrumbs.length}`,
        label: selectedMenu.group.title,
        to: selectedMenu.group.url
    } : null;
    const selectedMenuItemBreadcrumb: ICustomBreadCrumb = selectedMenu?.item ? {
        key: `${selectedMenu.item.key}-${breadCrumbs.length}`,
        label: getValue(selectedMenu.item.title),
        to: selectedMenu.item.url
    } : null;
    const currentViewBreadcrumb: ICustomBreadCrumb = viewBreadcrumbItem ? {
        key: `${viewBreadcrumbItem.key}-${breadCrumbs.length}`,
        label: viewBreadcrumbItem.title,
        to: currentNavigation
    } : null;
    const possibleBreadcrumbs = [selectedMenuGroupBreadcrumb, selectedMenuItemBreadcrumb, currentViewBreadcrumb].filter(val => val);
    const lastBreadcrumb = possibleBreadcrumbs.slice(-1)[0];
    const rootBreadcrumbs = possibleBreadcrumbs.slice(0, -1);

    // if this is first level navigation, whole breadcrumbs path should be stored in the link
    if (breadCrumbs.length === 0) {
        for (const breadcrumb of rootBreadcrumbs) {
            breadCrumbs.push(breadcrumb);
        }
    }

    if (lastBreadcrumb) {
        breadCrumbs.push(lastBreadcrumb);
    }

    let query = args.filters ? `?${QueryParam.Filter}=${encodeQueryParam(args.filters)}` : "";

    if (args.customQueryString) {
        if (query) {
            query += "&";
        } else {
            query += "?";
        }

        query += args.customQueryString;
    }

    return {
        pathname: args.route,
        search: query,
        state: {
            back: currentNavigation,
            breadCrumbs
        }
    };
};

export const getIntentNavParams = (args: IGetDrillDownParams): THistoryLocation => {
    const params = getDrillDownNavParams(args);

    params.search = `?${QueryParam.ViewMode}=${PageViewMode.FormReadOnly}`;

    if (args.customQueryString) {
        params.search += `&${args.customQueryString}`;
    }

    return params;
};

export const getNavLinkComponent = (text: string, args: IGetDrillDownParams, getParams: (args: IGetDrillDownParams) => THistoryLocation): React.ReactElement => {
    const params = getParams(args);

    return (
        <DrillDownLink to={params} companyId={args.companyId}>
            {args.customContent ?? text}
        </DrillDownLink>
    );
};

/**
 * Enhances link with drilldown params (e.g. with history state to show "back" arrow in breadcrumbs),
 * adds companyId to the link
 * @param link
 * @param context
 */
export function enhanceLinkWithDrilldownParams<T extends THistoryLocation>(link: T, context: IAppContext): T {
    if (typeof link === "string") {
        link = getDrillDownNavParams({ route: link, context }) as T;
    }
    return addCompanyIdToUrl(link, context);
}

/** Returns drilldown link component */
export const getDrillDownLink = (text: string, args: IGetDrillDownParams): React.ReactElement => {
    return getNavLinkComponent(text, args, getDrillDownNavParams);
};

/** Returns intent link component */
export const getIntentLink = (text: string, args: IGetDrillDownParams): React.ReactElement => {
    return getNavLinkComponent(text, args, getIntentNavParams);
};

export const getTableLink = (text: string, args: IGetDrillDownParams, getParams: (args: IGetDrillDownParams) => THistoryLocation): ICellValueObject => {
    return {
        tooltip: text,
        value: getNavLinkComponent(text, args, getParams)
    };
};

/** Returns drilldown link formatted for table */
export const getTableDrillDownLink = (text: string, args: IGetDrillDownParams): ICellValueObject => {
    return getTableLink(text, args, getDrillDownNavParams);
};

/** Returns intent link formatted for table */
export const getTableIntentLink = (text: string, args: IGetDrillDownParams): ICellValueObject => {
    return getTableLink(text, args, getIntentNavParams);
};

export const getDrillDownFilters = (): TRecordValue => {
    const query = getQueryParameters();

    if (!query?.[QueryParam.Filter]) {
        return null;
    }

    return JSON.parse(query[QueryParam.Filter] as string);
};

export const getDrillDownVariant = (): string => {
    const query = getQueryParameters();
    return query[QueryParam.Variant];
};

