import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import {
    ButtonGroupStyled,
    EditableTextStyled,
    HALF_ICON_SIZE,
    ICON_SIZE,
    MessageStreamWrapper,
    NewMessageWrapper,
    StyledChat,
    ToggleButton
} from "./Chat.styles";
import { useTranslation } from "react-i18next";
import { CaretIcon } from "../icon";
import { IconSize } from "../../enums";
import Message from "./Message";
import { IEditableTextChangeEvent } from "../inputs/editableText";
import { Button } from "../button";
import { ScrollBar } from "../scrollBar";
import CustomResizeObserver from "../customResizeObserver";
import SimpleBar from "simplebar-react";
import { getMessageIcon } from "@pages/tickets/Tickets.utils";
import { AppContext } from "../../contexts/appContext/AppContext.types";
import { currentUserIsCustomer } from "@pages/admin/users/Users.utils";
import TestIds from "../../testIds";


interface IProps {
    currentValue: string;
    onChange: (value: string) => void;
    onSendMessage?: () => void;
    placeholder?: string;
    customButtons?: React.ReactNode;
    maxHeight?: number;
    isDisabled?: boolean;
    hideNewMessage?: boolean;
}

const Chat: React.FC<IProps> = (props) => {
    const { isDisabled, currentValue, onChange, onSendMessage, placeholder, customButtons, hideNewMessage } = props;
    const { t } = useTranslation("Components");

    const context = useContext(AppContext);

    const [maxHeight, setMaxHeight] = useState<number>(0);
    const [minHeight, setMinHeight] = useState<number>(0);
    const [isCollapsed, collapse] = useState<boolean>(true);
    let childMessagesCnt = 0;
    React.Children.forEach(props.children, (child) => {
        if (React.isValidElement(child)) {
            childMessagesCnt++;
        }
    });
    const isCollapsible = childMessagesCnt > 1;

    const scrollRef = useRef<SimpleBar>();

    const _getMessagesHeight = () => {
        let height = maxHeight;
        if (isCollapsed) {
            height = minHeight;
        } else if (props.maxHeight) {
            height = Math.min(props.maxHeight, maxHeight);
        }
        return height ? `${height}px` : "100%";
    };

    const _recalculateSize = useCallback((): void => {
        const list = scrollRef.current.getContentElement();
        const { height: calcMaxHeight } = list.getBoundingClientRect();
        const lastItem = list.children[list.children.length - 1];
        const { height: calcMinHeight } = lastItem.getBoundingClientRect();

        if (calcMaxHeight !== maxHeight || calcMinHeight !== minHeight) {
            setMaxHeight(calcMaxHeight);
            setMinHeight(calcMinHeight);
        }
    }, [minHeight, setMinHeight, maxHeight, setMaxHeight]);

    const handleToggle = useCallback(() => {
        collapse(!isCollapsed);
    }, [isCollapsed, collapse]);

    const handleChange = useCallback((event: IEditableTextChangeEvent) => {
        onChange(event.value);
    }, [onChange]);

    useEffect(() => {
        _recalculateSize();
    });

    useEffect(() => {
        if (isCollapsed) {
            const scrollEl = scrollRef.current.getScrollElement();
            scrollEl.scrollTop = scrollEl.scrollHeight;
        }
    }, [maxHeight, isCollapsed]);

    const hideChatBorder = hideNewMessage && !isCollapsible;

    return (
        <StyledChat _bottomLine={hideNewMessage ? minHeight - HALF_ICON_SIZE : ICON_SIZE} _hasBorder={!hideChatBorder} data-testid={TestIds.Chat}>
            {!hideChatBorder &&
                <ToggleButton title={t(`Chat.${isCollapsed ? "Show" : "Hide"}`)}
                              _isCollapsed={isCollapsed}
                              isDisabled={!isCollapsible}
                              onClick={handleToggle}
                              isDecorative>
                    <CaretIcon width={IconSize.M} height={IconSize.M}/>
                </ToggleButton>}
            <MessageStreamWrapper _hasBorder={!hideChatBorder} data-testid={TestIds.MessageStreamWrapper}>
                <ScrollBar
                    ref={scrollRef}
                    style={{
                        overflowX: "hidden",
                        overflowY: isCollapsed ? "hidden" : "auto",
                        maxHeight: _getMessagesHeight()
                    }}>
                    <CustomResizeObserver onResize={_recalculateSize}/>
                    {props.children}
                </ScrollBar>
            </MessageStreamWrapper>
            {!hideNewMessage &&
                    <Message icon={getMessageIcon(context, currentUserIsCustomer(context))} hasIconBorder
                             hasDisabledIcon wrapContent={false} align={"bottom"} testId={TestIds.NewMessage}>
                        <NewMessageWrapper>
                            <EditableTextStyled value={currentValue}
                                                placeholder={placeholder || t("Chat.Placeholder")}
                                                width={"100%"}
                                                isInEdit={!!currentValue}
                                                isDisabled={isDisabled}
                                                isMultiLine
                                                maxRows={4}
                                                name="new-chat-message"
                                                onChange={handleChange}/>
                        <ButtonGroupStyled>
                            {customButtons || (
                                <Button onClick={onSendMessage} isDisabled={isDisabled}>{t("Chat.Send")}</Button>
                            )}
                        </ButtonGroupStyled>
                    </NewMessageWrapper>
                </Message>}
        </StyledChat>
    );
};

export default Chat;