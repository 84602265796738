import { TTemporalDialogSettings } from "@components/smart/FieldInfo";
import { FilterBarGroup, getDefaultFilterGroupDef } from "@components/smart/smartFilterBar/SmartFilterBar.types";
import { ISummaryItem } from "@components/smart/smartSummaryItem/SmartSummaryItem";
import { createPath } from "@odata/BindingContext";
import {
    EntitySetName,
    EntityTypeName,
    PrAbsenceCategoryEntity,
    PrAbsenceCategoryTemporalCurrentEntity,
    PrAbsenceCategoryTemporalEntity
} from "@odata/GeneratedEntityTypes";
import {
    getTemporalAdditionalProperties,
    getTemporalPropertyFieldDefinition,
    TemporalValidityFieldsDef
} from "@odata/TemporalUtils";
import { isDefined } from "@utils/general";
import i18next from "i18next";

import { FastEntryInputSizes, FieldType, GroupedField, Sort } from "../../../enums";
import { Model } from "../../../model/Model";
import { IFormDef } from "../../../views/formView/Form";
import { ISplitPageTableDef } from "../../../views/table/TableView.utils";
import { setDefByEntityType } from "../../getDefByEntityType";
import { getItemBreadCrumbsText, IDefinition, IGetDefinition } from "../../PageUtils";
import AbsenceCategoryFormView from "./AbsenceCategoryFormView";


export const getDefinitions: IGetDefinition = (): IDefinition => {
    const table: ISplitPageTableDef = {
        id: `${EntityTypeName.PrAbsenceCategory}Table`,
        filterBarDef: [{
            ...getDefaultFilterGroupDef(FilterBarGroup.Filters),
            defaultFilters: [
                createPath(PrAbsenceCategoryEntity.CurrentTemporalPropertyBag, PrAbsenceCategoryTemporalCurrentEntity.Name),
                createPath(PrAbsenceCategoryEntity.CurrentTemporalPropertyBag, PrAbsenceCategoryTemporalCurrentEntity.CompensationRate)
            ],
            filterDefinition: {
                [createPath(PrAbsenceCategoryEntity.CurrentTemporalPropertyBag, PrAbsenceCategoryTemporalCurrentEntity.Name)]: {},
                [createPath(PrAbsenceCategoryEntity.CurrentTemporalPropertyBag, PrAbsenceCategoryTemporalCurrentEntity.CompensationRate)]: {
                    formatter: (val, args) => {
                        return isDefined(val) ? `${val} %` : args.placeholder;
                    }
                }
            },
            isValueHelp: true
        }],
        initialSortBy: [{
            id: createPath(PrAbsenceCategoryEntity.CurrentTemporalPropertyBag, PrAbsenceCategoryTemporalCurrentEntity.Name),
            sort: Sort.Desc
        }],
        columns: [
            createPath(PrAbsenceCategoryEntity.CurrentTemporalPropertyBag, PrAbsenceCategoryTemporalCurrentEntity.Name),
            createPath(PrAbsenceCategoryEntity.CurrentTemporalPropertyBag, PrAbsenceCategoryTemporalCurrentEntity.CompensationRate)
        ],
        columnDefinition: {
            [createPath(PrAbsenceCategoryEntity.CurrentTemporalPropertyBag, PrAbsenceCategoryTemporalCurrentEntity.Name)]: {},
            [createPath(PrAbsenceCategoryEntity.CurrentTemporalPropertyBag, PrAbsenceCategoryTemporalCurrentEntity.CompensationRate)]: {
                fieldSettings: {
                    unit: "%"
                }
            }
        },
        title: i18next.t("AbsenceCategory:Title"),
        tabsSettings: {}
    };
    const summary: ISummaryItem[] = [];
    const temporalDialogSettings: TTemporalDialogSettings = {
        dialogTitle: i18next.t("AbsenceCategory:Form.PlanAbsenceCategoryTitle"),
        columns: [
            PrAbsenceCategoryTemporalEntity.Name,
            PrAbsenceCategoryTemporalEntity.CompensationRate
        ]
    };

    const form: IFormDef = {
        id: `${EntityTypeName.PrAbsenceCategory}Form`,
        translationFiles: getDefinitions.translationFiles,
        getItemBreadCrumbText: (storage: Model) =>
                getItemBreadCrumbsText(storage, i18next.t("AbsenceCategory:New"), i18next.t("AbsenceCategory:FormTitle")),
        isDeletable: true,
        formControl: AbsenceCategoryFormView,
        summary,
        additionalProperties: [
            ...getTemporalAdditionalProperties(PrAbsenceCategoryTemporalEntity, PrAbsenceCategoryEntity)
        ],
        fieldDefinition: {
            ...getTemporalPropertyFieldDefinition({
                propName: PrAbsenceCategoryTemporalEntity.Name,
                definition: {
                    width: FastEntryInputSizes.M,
                    groupedField: GroupedField.MultiStart,
                    fieldSettings: {
                        temporalDialog: temporalDialogSettings
                    }
                },
                withOpeningButton: false
            }),
            ...getTemporalPropertyFieldDefinition({
                propName: PrAbsenceCategoryTemporalEntity.CompensationRate,
                definition: {
                    width: FastEntryInputSizes.M,
                    groupedField: GroupedField.MultiEnd,
                    fieldSettings: {
                        temporalDialog: temporalDialogSettings,
                        unit: "%"
                    }
                }
            }),
            [PrAbsenceCategoryEntity.Color]: {},
            ...TemporalValidityFieldsDef
        },
        groups: [
            {
                id: "main",
                rows: [
                    [
                        { id: createPath(PrAbsenceCategoryEntity.CurrentTemporalPropertyBag, PrAbsenceCategoryTemporalCurrentEntity.Name) },
                        { id: createPath(PrAbsenceCategoryEntity.CurrentTemporalPropertyBag, PrAbsenceCategoryTemporalCurrentEntity.CompensationRate) }
                    ],
                    [
                        {
                            id: PrAbsenceCategoryEntity.Color,
                            type: FieldType.ColorPicker,
                            fieldSettings: {
                                colors: [
                                    "C_CHART_blue",
                                    "C_CHART_green",
                                    "C_CHART_yellow",
                                    "C_CHART_red",
                                    "C_CHART_orange",
                                    "C_CHART_grey"
                                ]
                            }
                        }
                    ]
                ]
            }
        ]
    };
    return {
        entitySet: EntitySetName.PrAbsenceCategories,
        table,
        form
    };
};

getDefinitions.translationFiles = ["AbsenceCategory", "Common"];
setDefByEntityType(EntityTypeName.PrAbsenceCategory, getDefinitions);