import { getTableIntentLink } from "@components/drillDown/DrillDown.utils";
import { formatDateToDateString, isDateBetween, maxDate } from "@components/inputs/date/utils";
import { ISelectItem, SelectGroups } from "@components/inputs/select/Select.types";
import { IGetValueArgs } from "@components/smart/FieldInfo";
import { IFormGroupDef } from "@components/smart/smartFormGroup/SmartFormGroup";
import { TCellValue } from "@components/table";
import { getNestedValue } from "@odata/Data.utils";
import {
    AssetItemEntity,
    EntitySetName,
    IAssetEntity,
    IAssetValueEntity,
    IDepreciationSettingEntity,
    IEntityBase,
    IFiscalYearEntity,
    IPaymentDocumentItemEntity,
    ITaxDepreciationCoefficientEntity,
    ITaxDepreciationPolicyEntity,
    ITaxPriceLimitEntity,
    TaxDepreciationCategoryEntity,
    TaxDepreciationCoefficientEntity,
    TaxPriceLimitEntity,
    UnorganizedAssetEntity
} from "@odata/GeneratedEntityTypes";
import {
    AssetDisposalTypeCode,
    AssetItemTypeCode,
    AssetTypeCode,
    CbaAssetDisposalTypeCode,
    DepreciationSettingTypeCode,
    DepreciationTypeCode,
    LanguageCode
} from "@odata/GeneratedEnums";
import { OData } from "@odata/OData";
import { formatValue, IFormatOptions, transformToODataString } from "@odata/OData.utils";
import { getFiscalYearByDate, getOldestActiveFY } from "@pages/fiscalYear/FiscalYear.utils";
import { isAccountAssignmentCompany } from "@utils/CompanyUtils";
import FileStorage from "@utils/FileStorage";
import { getValue, isDefined } from "@utils/general";
import { Dayjs } from "dayjs";
import { saveAs } from "file-saver";
import i18next from "i18next";

import { ASSET_API_URL, ASSET_TO_EXPENSE_API_URL, EMPTY_VALUE, REST_API_URL } from "../../../constants";
import { IAppContext } from "../../../contexts/appContext/AppContext.types";
import { Sort, ValueType } from "../../../enums";
import { TValue } from "../../../global.types";
import { Model } from "../../../model/Model";
import { IFilterQuery } from "../../../model/TableStorage";
import BindingContext, { IEntity, TEntityKey } from "../../../odata/BindingContext";
import { ROUTE_FIXED_ASSET } from "../../../routes";
import DateType, { getUtcDate, getUtcDayjs } from "../../../types/Date";
import memoizeOne from "../../../utils/memoizeOne";
import { FormStorage, IFormStorageDefaultCustomData } from "../../../views/formView/FormStorage";
import { getCompanyCountryCode } from "../../companies/Company.utils";
import { IDefinition } from "../../PageUtils";
import { fetchDataAndParseError, getAsset } from "../Asset.utils";
import { compareString } from "@utils/string";

export const DateFirstPutInUsePath = "DateFirstPutInUse";
export const DisposalAccountPath = BindingContext.localContext("DisposalAccount");
export const DisposalDatePath = BindingContext.localContext("DisposalDate");
export const DisposalReasonPath = BindingContext.localContext("Reason");
export const DamageCompensationAmountPath = BindingContext.localContext("DamageCompensationAmount");

export const FIXED_ASSET_MASS_PDF_ACTION_ID = "FixedAssetMassPdfExportAction";

export enum Validity {
    SingleYear = "SingleYear",
    LongTerm = "LongTerm"
}

export type TRootAssetItems = Record<number, number[]>;

export enum FixedAssetFormViewAction {
    Tax = "ShowTaxDepreciationPolicy",
    Accounting = "ShowAccountingDepreciationPolicy",
    AddFromUnsorted = "AddFromUnsorted",
    ChangePrice = "ChangePrice",
    Discard = "Discard",
    Interrupt = "Interrupt",
    CancelInterrupt = "CancelInterrupt",
    PartialDepreciationSettings = "PartialDepreciationSettings",
    CancelPartialDepreciationSettings = "CancelPartialDepreciationSettings",
    AssetCardPdfExport = "AssetCardPdfExport"
}

export interface IFixedAssetCustomData extends IFormStorageDefaultCustomData {
    entityBeforeAction?: IAssetEntity;
    FixedAssetFormViewAction?: FixedAssetFormViewAction;
    AssetValueLimit?: number;
    AssetItemTypeCode?: AssetItemTypeCode;
    rootItems?: TRootAssetItems;
    coefficients?: ITaxDepreciationCoefficientEntity[];
}

export interface IFixedAssetDisposalCustomData extends IFormStorageDefaultCustomData {
    assetStorage?: FormStorage<IAssetEntity, IFixedAssetCustomData>;
}

/**
 * Function returns data for TaxDepreciationPolicy selects - Type, Category and first year of increase
 * @param storage
 */
export async function getTaxDepreciationCoefficients(storage: FormStorage): Promise<ITaxDepreciationCoefficientEntity[]> {
    const { oData } = storage;

    const res = await oData.getEntitySetWrapper(EntitySetName.TaxDepreciationCoefficients).query()
        .select(TaxDepreciationCoefficientEntity.Code, TaxDepreciationCoefficientEntity.Name, TaxDepreciationCoefficientEntity.FirstYearValueIncrease,
                TaxDepreciationCoefficientEntity.IsMonthly, TaxDepreciationCoefficientEntity.CanBeInterrupted, TaxDepreciationCoefficientEntity.CanBeImproved,
                TaxDepreciationCoefficientEntity.TaxDepreciationCategoryCode)
        .expand(TaxDepreciationCoefficientEntity.DepreciationType)
        .expand(TaxDepreciationCoefficientEntity.TaxDepreciationCategory, (query) =>
            query.select(TaxDepreciationCategoryEntity.Code, TaxDepreciationCategoryEntity.Name, TaxDepreciationCategoryEntity.DateValidFrom,
                TaxDepreciationCategoryEntity.DateValidTo, TaxDepreciationCategoryEntity.YearsOfDepreciation))
        .fetchData<ITaxDepreciationCoefficientEntity[]>();

    return res?.value || [];
}

export const getTaxDepreciationCoefficientsMemoized = memoizeOne(getTaxDepreciationCoefficients);


/**
 * Function returns data for TaxDepreciationPolicy selects - Type, Category and first year of increase
 * @param storage
 */
export async function getTaxPriceLimits(storage: FormStorage): Promise<ITaxPriceLimitEntity[]> {
    const { oData } = storage;

    const res = await oData.getEntitySetWrapper(EntitySetName.TaxPriceLimits).query()
            .select(TaxPriceLimitEntity.Name, TaxPriceLimitEntity.ShortName, TaxPriceLimitEntity.DateValidFrom,
                    TaxPriceLimitEntity.DateValidTo, TaxPriceLimitEntity.Code, TaxPriceLimitEntity.TaxDepreciationCategoryCode)
            .fetchData<ITaxPriceLimitEntity[]>();

    return res?.value || [];
}

export const getTaxPriceLimitsMemoized = memoizeOne(getTaxPriceLimits);

export interface IGetTaxDepreciationReturnValue {
    coefficients: ITaxDepreciationCoefficientEntity[];
    Types: DepreciationTypeCode[];
    Categories: ISelectItem[];
    FirstYearValues: ISelectItem[];
}

export function getTaxDepreciationPolicyLocalCategoryId(policy: Partial<ITaxDepreciationPolicyEntity>): string {
    return policy.TaxPriceLimitCode ?? policy.TaxPriceLimit?.Code ?? policy.Coefficient?.TaxDepreciationCategoryCode ?? policy.Coefficient?.TaxDepreciationCategory?.Code;
}

export async function getTaxDepreciationSelectItems(storage: FormStorage, type: DepreciationTypeCode, category: string, date: Date): Promise<IGetTaxDepreciationReturnValue> {
    const [coefficients, taxLimits] = await Promise.all([getTaxDepreciationCoefficientsMemoized(storage), getTaxPriceLimitsMemoized(storage)]);

    const Types = new Set<DepreciationTypeCode>();
    const Categories = new Map<string, ISelectItem>();
    const FirstYearValues = new Map<number, ISelectItem>();

    let categoryId = category;

    const _addCategoryItem = (coeff: ITaxDepreciationCoefficientEntity, TaxPriceLimit: ITaxPriceLimitEntity = null) => {
        // Create ISelectItem from TaxDepreciationCategory
        const { Name, YearsOfDepreciation } = coeff.TaxDepreciationCategory;
        const id = getTaxDepreciationPolicyLocalCategoryId({ TaxPriceLimit, Coefficient: coeff });
        const label = TaxPriceLimit?.ShortName ? `${Name} (${TaxPriceLimit.ShortName})` : Name;
        const hasFixedYearsOfDepreciation = YearsOfDepreciation > 0;
        const cat: ISelectItem<string> = {
            id,
            label,
            tabularData: [
                label,
                hasFixedYearsOfDepreciation ? i18next.t("FixedAsset:Form.YearsCnt", { count: YearsOfDepreciation }) : null
            ].filter(isDefined),
            groupId: hasFixedYearsOfDepreciation ? "NoYears" : SelectGroups.Default,
            additionalData: {
                YearsOfDepreciation,
                Coefficient: coeff,
                TaxPriceLimitCode: TaxPriceLimit?.Code
            }
        };
        Categories.set(cat.id, cat);
        if (!categoryId) {
            // if category is not set, we will match firstYearValues to the first category
            categoryId = id;
        }

        if (id === categoryId) {
            // Create ISelectItem from TaxDepreciationCoefficient
            const inc = coeff.FirstYearValueIncrease;
            const fyv: ISelectItem = {
                id: inc ?? EMPTY_VALUE,
                label: inc ? `${inc} %` : i18next.t("Common:Select.NoRecord"),
                groupId: inc ? "data" : "default",
                additionalData: {
                    isNoRecord: true
                }
            };
            FirstYearValues.set(fyv.id as number, fyv);
        }
    };

    const _isValid = ({ DateValidFrom, DateValidTo }: { DateValidFrom?: Date; DateValidTo?: Date }, current: Dayjs): boolean =>
            !current || (current.isSameOrAfter(DateValidFrom, "date") && (!DateValidTo || current.isSameOrBefore(DateValidTo, "date")));

    const current = date && DateType.isValid(date) ? getUtcDayjs(date) : null;
    const filteredLimits = taxLimits.filter(limit => _isValid(limit, current));

    coefficients.forEach(coeff => {
        const Type = coeff.DepreciationType?.Code as DepreciationTypeCode;
        Types.add(Type);

        if (Type === type && _isValid(coeff.TaxDepreciationCategory, current)) {
            const { Code } = coeff.TaxDepreciationCategory;

            _addCategoryItem(coeff);

            const categoryLimits = filteredLimits.filter(limit => limit.TaxDepreciationCategoryCode === Code);
            categoryLimits.forEach(taxLimit => {
                _addCategoryItem(coeff, taxLimit);
            });
        }
    });
    
    const sortedCategories = [...Categories.values()]
            .sort((a, b) => compareString(a.label, b.label));

    return {
        coefficients,
        Types: [...Types.values()],
        Categories: sortedCategories,
        FirstYearValues: [...FirstYearValues.values()]
    };
}

function firstCollectionItemValueFormatter(path: string) {
    return (val: TValue, args?: IFormatOptions): TCellValue => {
        const item = args.entity.Items?.[0];
        if (!item) {
            return null;
        }
        args.bindingContext = args.bindingContext.addKey(item);
        const value = getNestedValue(path, item);
        const infoWithoutFormatter = { ...args.info };
        delete infoWithoutFormatter.formatter;
        /**
         * todo: formatValue took Currency from entity in options, which is probably wrong
         *  -> it should consider binding context. Correct this later in formatValue (may break a lot of things)
         **/
        return formatValue(value, infoWithoutFormatter, { ...args, entity: item });
    };
}

/**
 * Adds asset definitions to document definition
 * @param definition
 * @param pathToDocFromAssetItem
 */
export function addAssetDef(definition: IDefinition, pathToDocFromAssetItem?: string): void {
    const entitySet = getValue(definition.entitySet) as EntitySetName;
    // Payment documents has to be linked with assets through their items
    const isPaymentDocument = [EntitySetName.CashReceiptsIssued, EntitySetName.CashReceiptsReceived, EntitySetName.BankTransactions].includes(entitySet);

    const filterPath = pathToDocFromAssetItem ?? AssetItemEntity.Document;
    const fixedAssetTab: IFormGroupDef = {
        id: "Asset",
        title: i18next.t("Document:FormTab.Asset"),
        table: {
            id: `asset_${definition.entitySet}`,
            entitySet: EntitySetName.Assets,
            filter: ({ storage }): IFilterQuery => {
                const { entity } = storage.data ?? {};
                if (isPaymentDocument && !entity?.Items?.some((item: IPaymentDocumentItemEntity) => !!item.LinkedDocument?.Id)) {
                    // in case there is none linked doc, we have to return empty array as in() or in(null) fails
                    return {
                        query: "Id eq null"
                    };
                }
                const ids = isPaymentDocument ? entity?.Items?.map((item: IPaymentDocumentItemEntity) => item.LinkedDocument?.Id).filter((id: TEntityKey) => !!id) : entity?.Id;
                const formattedIds = transformToODataString(ids, ValueType.Number);
                return {
                    query: `Items/any(x: x/${filterPath}/Id in (${formattedIds}))`,
                    collectionQueries: {
                        Items: { query: `${filterPath}/Id in (${formattedIds})` }
                    }
                };
            },
            initialSortBy: [{
                id: "NumberOurs",
                sort: Sort.Asc
            }],
            columns: [
                {
                    id: "Items/DocumentItem/Order",
                    label: i18next.t("FixedAsset:DocumentTable.Order"),
                    formatter: firstCollectionItemValueFormatter("DocumentItem/Order")
                }, {
                    id: "NumberOurs",
                    label: i18next.t("FixedAsset:DocumentTable.NumberOurs"),
                    formatter: (val: TValue, args: IFormatOptions): TCellValue => {
                        if (!args.entity?.Id) {
                            return args.entity?.NumberOurs;
                        }

                        return getTableIntentLink(val as string, {
                            route: `${ROUTE_FIXED_ASSET}/${args.entity.Id}`,
                            context: args.storage.context,
                            storage: args.storage
                        });
                    }
                }, {
                    id: "Name",
                    label: i18next.t("FixedAsset:DocumentTable.Name")
                }, {
                    id: "Items/ItemType/Name",
                    label: i18next.t("FixedAsset:DocumentTable.Type"),
                    formatter: firstCollectionItemValueFormatter("ItemType/Name")
                }, {
                    id: "Items/Amount",
                    label: i18next.t("FixedAsset:DocumentTable.Amount"),
                    formatter: (val: TValue, args?: IFormatOptions): TCellValue => {
                        const items = args.entity.Items;
                        if (!items?.length) {
                            return null;
                        }
                        const infoWithoutFormatter = { ...args.info };
                        delete infoWithoutFormatter.formatter;
                        args.bindingContext = args.bindingContext.addKey(items[0].Id);
                        let value = 0;
                        for (const item of items) {
                            value += getNestedValue("Amount", item) ?? 0;
                        }
                        return formatValue(value, infoWithoutFormatter, { ...args, entity: items[0] });
                    }
                }
            ]
        }
    };
    // Add fixed asset tab to the def
    const tabsGroup: IFormGroupDef = definition.form.groups.find(group => group.id === "Tabs");
    tabsGroup.tabs.splice(2, 0, fixedAssetTab);
}

export const AssetTranslations = ["FixedAsset", "MinorAsset", "Document", "Components"];

interface IAccrualDisposalParameters {
    AssetId: number;
    Date: string;
    AccrualDisposalParameters?: IDisposeAssetRequestData;
    CbaDisposalParameters?: ICbaDisposalParameters;
}

interface IDisposeAssetRequestData {
    AssetDisposalTypeCode?: AssetDisposalTypeCode;
    DisposalAccountId?: number;
}

interface ICbaDisposalParameters {
    DamageCompensationAmount?: number;
    CbaDisposalTypeCode: AssetDisposalTypeCode;
}

export function disposeAsset(storage: FormStorage, AssetId: number): Promise<boolean | IEntity> {
    const params: IAccrualDisposalParameters = {
        AssetId,
        Date: formatDateToDateString(storage.getValueByPath(DisposalDatePath))
    };

    if (isAccountAssignmentCompany(storage.context)) {
        params.AccrualDisposalParameters = {
            AssetDisposalTypeCode: storage.getValueByPath(DisposalReasonPath),
            DisposalAccountId: storage.getValueByPath(DisposalAccountPath)
        };
    } else {
        params.CbaDisposalParameters = {
            CbaDisposalTypeCode: storage.getValueByPath("Reason")
        };

        if (storage.getValueByPath("Reason") === CbaAssetDisposalTypeCode.OtherDamage) {
            params.CbaDisposalParameters.DamageCompensationAmount = storage.getValueByPath("DamageCompensationAmount");
        }
    }

    return fetchDataAndParseError(`${ASSET_API_URL}/${AssetItemTypeCode.Disposal}`, params);
}

interface IChangeAssetPriceData {
    AssetId: TEntityKey;
    DocumentItemIds?: number[];
    Date: string;
}

export function changeAssetPrice(type: AssetItemTypeCode, storage: FormStorage<any>, entryIds: number[], AssetId: TEntityKey): Promise<IAssetEntity> {
    const data: IChangeAssetPriceData = {
        DocumentItemIds: entryIds,
        AssetId,
        Date: formatDateToDateString(storage.getValueByPath("Date"))
    };

    return fetchDataAndParseError(`${ASSET_API_URL}/${type}`, data);
}

interface IAddToCostsData {
    DocumentItemIds: number[];
    AccountId: number;
    Date: string;
}

export function addToCosts(DocumentItemIds: number[], AccountId: number, date: Date): Promise<boolean | IEntity> {
    const data: IAddToCostsData = {
        DocumentItemIds,
        AccountId,
        Date: formatDateToDateString(date)
    };

    return fetchDataAndParseError(ASSET_TO_EXPENSE_API_URL, data);
}

const getLowPriceAssetValuesMemoized = memoizeOne(async (storage: Model) => {
    const query = storage.oData.getEntitySetWrapper(EntitySetName.AssetValues)
        .query()
        .filter(`CountryCode eq '${getCompanyCountryCode(storage.context)}'`)
        .select("Code", "Name", "minValue", "DateValidFrom", "DateValidTo")
        .orderBy("DateValidFrom");

    const result = await query.fetchData<IAssetValueEntity[]>();
    return result.value;
}, (storage: Model) => {
    return [getCompanyCountryCode(storage.context)];
});


export const getLowPriceAssetLimit = async (storage: FormStorage<IAssetEntity, IFixedAssetCustomData>, date: Date): Promise<number> => {
    const assetValues = await getLowPriceAssetValuesMemoized(storage);
    const searchDay = getUtcDayjs(date);
    const matchedValue = assetValues.find(val => {
        const from = val.DateValidFrom ?? searchDay;
        const to = val.DateValidTo ?? searchDay;
        return searchDay.isBetween(from, to, "day", "[]");
    });
    const limit = matchedValue?.MinValue ?? 0;
    storage.setCustomData({ AssetValueLimit: limit });
    return limit;
};

export const getLowPriceAssetLimitSync = (storage: FormStorage<IAssetEntity, IFixedAssetCustomData>): number => {
    return storage.getCustomData().AssetValueLimit;
};

interface IAssetCounts {
    [AssetTypeCode.Tangible]?: number;
    [AssetTypeCode.Intangible]?: number;
}

interface IGetAssetCountOptions {
    from?: Date;
    to?: Date;
}

interface IGetAssetCountResult extends IEntityBase {
    AssetTypeCode: AssetTypeCode;
}

export async function getUnorganizedAssetCounts(oData: OData, opts: IGetAssetCountOptions): Promise<IAssetCounts> {
    const filters: string[] = [];
    if (opts.from) {
        filters.push(`${UnorganizedAssetEntity.EntryDate} ge ${transformToODataString(opts.from, ValueType.Date)}`);
    }
    if (opts.to) {
        filters.push(`${UnorganizedAssetEntity.EntryDate} le ${transformToODataString(opts.to, ValueType.Date)}`);
    }

    const filter = filters.join(" and ");
    const query = oData.getEntitySetWrapper(EntitySetName.UnorganizedAssets).query()
        .filter(filter)
        .groupBy(UnorganizedAssetEntity.AssetTypeCode)
        .aggregate("$count as @odata.count");

    const result = await query.fetchData<IGetAssetCountResult[]>();

    const counts: IAssetCounts = {};

    result.value.forEach(val => {
        counts[val.AssetTypeCode] = val._metadata[""].count ?? 0;
    });

    return counts;
}

export const exportAssetCard = async (ids: string[], context: IAppContext, showFutureTaxDepreciationPolicy: boolean, showFutureTaxAccountingPolicy: boolean): Promise<void> => {
    const pdfUrl = `${REST_API_URL}/AssetCardPdfExport/${showFutureTaxDepreciationPolicy}/${showFutureTaxAccountingPolicy}?languageCode=${LanguageCode.Czech}&${ids.map(id => `assetIds=${id}`).join("&")}&CompanyId=${context.getCompanyId()}`;

    const file = await FileStorage.get(null, {
        url: pdfUrl
    });

    saveAs(file);
};

export function getFirstActiveFiscalYearOfAssetInUse(storage: FormStorage): Pick<IFiscalYearEntity, "DateStart" | "DateEnd"> {
    const putInUseDate = storage.getValueByPath(DateFirstPutInUsePath);
    const firstActiveFY = getOldestActiveFY(storage.context);
    const DateStart = maxDate(firstActiveFY?.DateStart ?? getUtcDate(), putInUseDate);
    const { DateEnd } = getFiscalYearByDate(storage.context, DateStart) ?? {};
    return { DateStart, DateEnd };
}

export function getDepreciationSettings(storage: FormStorage, forDate: Date, type?: DepreciationSettingTypeCode): IDepreciationSettingEntity {
    const settings = getAsset({ storage })?.TaxDepreciationPolicy?.DepreciationSettings;
    return settings?.find(item => isDateBetween(item.DateValidFrom, item.DateValidTo, forDate) && (!type || item.DepreciationSettingTypeCode === type));
}

export function getActualDepreciationSettings(storage: FormStorage, type?: DepreciationSettingTypeCode): IDepreciationSettingEntity {
    const { DateStart } = getFirstActiveFiscalYearOfAssetInUse(storage);
    return getDepreciationSettings(storage, DateStart, type);
}

export const isInterrupted = (args: IGetValueArgs) => {
    return getActualDepreciationSettings(args.storage as FormStorage)?.DepreciationSettingTypeCode === DepreciationSettingTypeCode.Interrupted;
};