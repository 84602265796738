import { ISelectionChangeArgs, ISelectItem } from "@components/inputs/select/Select.types";
import { KeyboardShortcut } from "@utils/keyboardShortcutsManager/KeyboardShorcutsManager.utils";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import { BasicInputSizes, IconSize, Sort } from "../../enums";
import TestIds from "../../testIds";
import KeyboardShortcutsManager from "../../utils/keyboardShortcutsManager/KeyboardShortcutsManager";
import memoize from "../../utils/memoize";
import memoizeOne from "../../utils/memoizeOne";
import ConfirmationButtons from "../../views/table/ConfirmationButtons";
import { IconButton } from "../button";
import { AddButton } from "../configurationList/Column";
import Dialog from "../dialog/Dialog";
import { BinIcon, SortDownIcon, SortIcon } from "../icon";
import Field from "../inputs/field";
import { Select } from "../inputs/select";
import { IColumn, ISort } from "./index";
import { TableSortingDialogContent, TableSortingSortItem } from "./TableSortingDialog.styles";

export interface IProps {
    columns: IColumn[];
    sort: ISort[];
    isBusy?: boolean;
    onCancel?: () => void;
    onConfirm?: (sort: ISort[]) => void;
}

interface IState {
    sort: ISort[];
}

const newSort: ISort = { id: null, sort: Sort.Asc };

class TableSortingDialog extends React.PureComponent<IProps & WithTranslation, IState> {
    state: IState = {
        sort: null
    };

    _unsubscribeKeyboardShortcuts: () => void;

    constructor(props: IProps & WithTranslation) {
        super(props);

        this.state = {
            sort: props.sort.length > 0 ? props.sort : [{ ...newSort }]
        };
    }

    componentDidMount() {
        this._unsubscribeKeyboardShortcuts = KeyboardShortcutsManager.subscribe({
            shortcuts: [KeyboardShortcut.ALT_R],
            callback: this.handleKeyboardShortcut,
            isPrioritized: true
        });
    }

    componentWillUnmount() {
        this._unsubscribeKeyboardShortcuts();
    }

    handleKeyboardShortcut = (shortcut: KeyboardShortcut, event: KeyboardEvent): boolean => {
        if (shortcut === KeyboardShortcut.ALT_R) {
            this.handleAdd();
            return true;
        }

        return false;
    };

    handleCancel = (): void => {
        this.props.onCancel?.();
    };

    handleConfirm = (): void => {
        this.props.onConfirm?.(this.state.sort.filter(sort => !!sort.id));
    };

    getSelectItems = memoizeOne((): ISelectItem[] => {
        return this.props.columns.map(column => {
            return {
                id: column.id,
                label: column.label,
                isDisabled: !!this.state.sort.find(sort => sort.id?.toString() === column.id)
            };
        });
    }, () => [this.state.sort]);

    // use memoize so that the function returned by handleSortIconClick is always the same for given id
    handleSortIconClick = memoize((index: number): () => void => {
        return () => {
            const newSort = [...this.state.sort];

            newSort[index] = {
                ...newSort[index],
                sort: newSort[index].sort === Sort.Asc ? Sort.Desc : Sort.Asc
            };

            this.setState({
                sort: newSort
            });
        };
    });

    handleBinIconClick = memoize((index: number): () => void => {
        return () => {
            this.setState({
                sort: this.state.sort.filter((sort, i) => i !== index)
            });
        };
    });

    handleAdd = () => {
        this.setState({
            sort: [...this.state.sort, { ...newSort }]
        });
    };

    handleSelectChange = memoize((index: number): (args: ISelectionChangeArgs) => void => {
        return (args: ISelectionChangeArgs) => {
            const newSort = [...this.state.sort];

            newSort[index] = {
                ...newSort[index],
                id: args.value
            };

            this.setState({
                sort: newSort
            });
        };
    });

    renderItem = (sort: ISort, index: number): React.ReactElement => {
        const Icon = sort.sort === Sort.Asc ? SortIcon : SortDownIcon;
        const title = this.props.t(`Components:Table.${sort.sort === Sort.Asc ? "Ascending" : "Descending"}`);

        return (
            <TableSortingSortItem key={index} data-testid={TestIds.TableSortingItem}>
                <IconButton title={title}
                            isDecorative
                            onClick={this.handleSortIconClick(index)}>
                    <Icon width={IconSize.S} height={IconSize.S}/>
                </IconButton>

                <Field label={`${index + 1}. ${this.props.t("Components:TableSortingDialog.Column")}`}
                       width={BasicInputSizes.L}>
                    <Select value={sort.id?.toString()}
                            items={this.getSelectItems()}
                            noRecordText={this.props.t("Common:Select.NoRecord")}
                            onChange={this.handleSelectChange(index)}
                            width={BasicInputSizes.L}/>
                </Field>
                {index <= 0 ? null :
                    <IconButton title={this.props.t("Common:General.Remove")}
                                isDecorative
                                onClick={this.handleBinIconClick(index)}>
                        <BinIcon width={IconSize.S} height={IconSize.S}/>
                    </IconButton>
                }

            </TableSortingSortItem>
        );
    };

    renderItems = (): React.ReactElement[] => {
        return this.state.sort.map((sort, index) => this.renderItem(sort, index));
    };

    render() {
        return (
            <Dialog
                busy={!!this.props.isBusy}
                title={this.props.t("Components:Table.Sorting")}
                width={"332px"}
                onClose={this.handleCancel}
                onConfirm={this.handleConfirm}
                footer={<ConfirmationButtons onCancel={this.handleCancel} onConfirm={this.handleConfirm}
                                             useWrapper={false}/>}>
                <TableSortingDialogContent>
                    {!this.props.isBusy && this.renderItems()}
                </TableSortingDialogContent>
                <AddButton onClick={this.handleAdd}/>
            </Dialog>
        );
    }
}

export default withTranslation(["Components", "Common"])(TableSortingDialog);