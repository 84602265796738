import React, { useCallback } from "react";
import {
    DashboardTileHeading,
    IconButtonStyled,
    IconsWrapper,
    StyledDashboardTileHeader,
    TooltipIconInfoStyled
} from "./Dashboard.styles";
import { IconSize } from "../../enums";
import { getIcon } from "../icon";
import { StyledTooltipContent } from "../tooltipIcon/TooltipIcon.styles";
import TestIds from "../../testIds";

export interface ITileHeaderIcon {
    id: string;
    label?: string;
    iconName: string;
    isVisible?: boolean;
    isDisabled?: boolean;
}

interface IIconProps extends ITileHeaderIcon {
    onClick?: (id: string) => void;
}

const DashboardTileHeaderIcon: React.FC<IIconProps> = ({ id, label, iconName, isVisible, isDisabled, onClick }) => {

    const handleClick = useCallback(() => {
        onClick?.(id);
    }, [onClick, id]);

    if (isVisible === false) {
        return null;
    }
    const Icon = getIcon(iconName);

    return (
        <IconButtonStyled title={label}
                          isDisabled={isDisabled}
                          onClick={handleClick}
                          isDecorative>
            <Icon width={IconSize.S} height={IconSize.S}/>
        </IconButtonStyled>
    );
};

interface IProps {
    tooltip?: string;
    icons?: ITileHeaderIcon[];
    onHeaderAction?: (id: string) => void;
    addPadding?: boolean;
    small?: boolean;
    className?: string;
}

const DashboardTileHeader: React.FC<IProps> = ({
                                                   className,
                                                   icons,
                                                   tooltip,
                                                   addPadding,
                                                   onHeaderAction,
                                                   small,
                                                   children
                                               }) => {
    return (
        <StyledDashboardTileHeader $addPadding={addPadding} className={className}>
            <DashboardTileHeading small={small}
                                  data-testid={TestIds.DashboardTileHeading}>{children}</DashboardTileHeading>
            {tooltip && (
                <TooltipIconInfoStyled>
                    <StyledTooltipContent>{tooltip}</StyledTooltipContent>
                </TooltipIconInfoStyled>
            )}
            {icons?.length && (
                <IconsWrapper>
                    {icons.map(item => (
                        <DashboardTileHeaderIcon key={item.id} {...item} onClick={onHeaderAction}/>
                    ))}
                </IconsWrapper>
            )}

        </StyledDashboardTileHeader>
    );
};

export default DashboardTileHeader;

