import React from "react";
import { IExtendedShellPropsForExtend } from "../views/main/ExtendedShell";
import NotificationsExtendedShell from "./NotificationsExtendedShell";
import { WithNotifications, withNotifications } from "./withNotifications";

export interface IProps extends IExtendedShellPropsForExtend {

}

/** Wrapper for NotificationsExtendedShell that connects the component with NotificationsContext */
class SmartNotificationsExtendedShell extends React.PureComponent<IProps & WithNotifications> {

    handleIsUnreadChange = (id: string, isUnread: boolean) => {
        this.props.notifications.updateNotification({
            Id: parseInt(id),
            IsRead: !isUnread
        });
    };

    handleNotificationClick = (id: string) => {
        this.props.notifications.updateNotification({
            Id: parseInt(id),
            IsRead: true
        });

        this.props.onHide();
    };

    render() {
        return <NotificationsExtendedShell {...this.props}
                                           notifications={this.props.notifications.notifications}
                                           onlyShowUnread={this.props.notifications.onlyShowUnread}
                                           onShouldShowReadChange={this.props.notifications.setOnlyShowUnread}
                                           onMarkAllAsRead={this.props.notifications.markAllAsRead}
                                           onIsUnreadChange={this.handleIsUnreadChange}
                                           onNotificationClick={this.handleNotificationClick}/>;
    }
}

export default withNotifications(SmartNotificationsExtendedShell);