import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { BreadCrumbProvider } from "../../../components/breadCrumb";
import { getTableDrillDownLink } from "@components/drillDown/DrillDown.utils";
import ViewHeader from "../../../components/smart/smartHeader/SmartHeader";
import { IRow, IRowValues, ISort, TColumn } from "@components/table";
import TableWithAutoSizedColumns from "../../../components/table/TableWithAutoSizedColumns";
import { AppContext } from "../../../contexts/appContext/AppContext.types";
import { Sort, TextAlign } from "../../../enums";
import { ColoredText } from "../../../global.style";
import { IToString, TValue } from "../../../global.types";
import { ICompanyEntity } from "@odata/GeneratedEntityTypes";
import { WithOData, withOData } from "@odata/withOData";
import { ROUTE_HOME, ROUTE_INBOX, ROUTE_TICKETS } from "../../../routes";
import { forEachKey } from "@utils/general";
import memoizeOne from "../../../utils/memoizeOne";
import { TableWrapper } from "../../../views/table/TableView.styles";
import View from "../../../views/View";
import { ExpensesAndRevenuesFilterTypes } from "../../documents/internalDocument/InternalDocumentDef";
import {
    getVatSubmissionPeriodSortValue,
    getVatSubmissionPeriodTableCell
} from "../../electronicSubmission/VatSubmission.utils";
import {
    getAgendaWorkOverviewData,
    getFutureExpensesAndRevenuesDrilldownParams,
    getOpenAccruedDrilldowns,
    IAgendaWorkOverviewData,
    TAgendaWorkOverviewDataMap
} from "../Home.utils";
import { getSortedRows, ILinkedPagesState } from "./linkedPagesUtils";
import { getUtcDayjs } from "../../../types/Date";

interface IProps extends WithTranslation, WithOData {
}

interface IState extends ILinkedPagesState {
    loading: boolean;
    data?: TAgendaWorkOverviewDataMap;
}

const CompanyColumnId = "company";

class AgendaWorkOverview extends React.Component<IProps, IState> {
    static contextType = AppContext;

    _breadcrumbsAreSet = false;

    state: IState = {
        loading: true,
        sort: [{
            id: CompanyColumnId,
            sort: Sort.Asc
        }]
    };

    componentDidMount() {
        this.load();
        this.setBreadcrumbs();
    }

    componentDidUpdate() {
        this.setBreadcrumbs();
    }

    setBreadcrumbs() {
        if (!this.props.tReady || this._breadcrumbsAreSet) {
            return;
        }
        this._breadcrumbsAreSet = true;

        this.context.setViewBreadcrumbs({
            items: [{
                key: "Home",
                title: this.props.t("Home:Title"),
                link: ROUTE_HOME
            }, {
                key: "AgendaWorkOverview",
                title: this.props.t("Home:AgendaWorkOverview.Title"),
            }],
            lockable: false
        });
    }

    getColumnsMemoized = memoizeOne((): TColumn[] => {
        const {t} = this.props;

        const columns = [
            "Received",
            "Sorted",
            "UnreadTickets",
            "OpenTickets",
            "Revenue",
            "Expense",
            "LastFiscalYear",
            "ThisFiscalYear",
            "NextPeriodFrequencyCode"
        ];
        return [
            {
                id: CompanyColumnId,
                label: t("Home:AgendaWorkOverview.CompanyName"),
                textAlign: TextAlign.Left
            },
            ...columns.map(name => ({
                id: name,
                label: t(`Home:AgendaWorkOverview.${name}`),
                textAlign: TextAlign.Right
            }))
        ];
    });

    getValueForSort = (company: ICompanyEntity, key: IToString): TValue => {
        const {data} = this.state;
        const values = data.get(company.Id);

        switch (key) {
            case "NextPeriodFrequencyCode":
                const {DatePeriodEnd, NextPeriodFrequencyCode} = values;
                const startOfNextPeriod = DatePeriodEnd && getUtcDayjs(DatePeriodEnd).add(1, 'day');
                return getVatSubmissionPeriodSortValue(startOfNextPeriod, NextPeriodFrequencyCode);
            default:
                return values[key?.toString() as keyof IAgendaWorkOverviewData] as TValue;
        }
    }

    getFormattedValues(companyId: number) {
        const values = this.state.data.get(companyId);

        const formatted: IRowValues = {};
        forEachKey(values, (key) => {
            switch (key) {
                case "Sorted":
                case "Received":
                    if (values[key] > 0) {
                        const customContent = (<ColoredText color={"C_SEM_text_warning"}>{values[key]}</ColoredText>);
                        formatted[key] = getTableDrillDownLink(values[key].toString(), {
                            route: ROUTE_INBOX,
                            context: this.context,
                            customContent,
                            companyId
                        });
                    } else {
                        formatted[key] = values[key];
                    }
                    break;
                case "UnreadTickets":
                    if (values[key] > 0) {
                        const customContent = (<ColoredText color={"C_SEM_text_warning"}>{values[key]}</ColoredText>);
                        formatted[key] = getTableDrillDownLink(values[key].toString(), {
                            route: ROUTE_TICKETS,
                            context: this.context,
                            customContent,
                            companyId
                        });
                    } else {
                        formatted[key] = values[key];
                    }
                    break;
                case "OpenTickets":
                    if (values[key] > 0) {
                        formatted[key] = getTableDrillDownLink(values[key].toString(), {
                            route: ROUTE_TICKETS,
                            context: this.context,
                            companyId
                        });
                    } else {
                        formatted[key] = values[key];
                    }
                    break;
                case "LastFiscalYear":
                    const {LastFiscalYearParams} = getOpenAccruedDrilldowns(this.context, {...values});
                    if (values[key] > 0 && LastFiscalYearParams) {
                        formatted[key] = getTableDrillDownLink(values[key].toString(), {
                            ...LastFiscalYearParams,
                            companyId
                        });
                    } else {
                        formatted[key] = values[key];
                    }
                    break;
                case "Expense":
                case "Revenue":
                    const type = key === "Expense" ? ExpensesAndRevenuesFilterTypes.Expenses : ExpensesAndRevenuesFilterTypes.Revenues;
                    if (values[key] > 0) {
                        formatted[key] = getTableDrillDownLink(`${values[key]}`, {
                            ...getFutureExpensesAndRevenuesDrilldownParams(this.context, type),
                            companyId
                        });
                    } else {
                        formatted[key] = values[key];
                    }
                    break;
                case "ThisFiscalYear":
                    const {ThisFiscalYearParams} = getOpenAccruedDrilldowns(this.context, {...values});
                    if (values[key] > 0 && ThisFiscalYearParams) {
                        formatted[key] = getTableDrillDownLink(values[key].toString(), {
                            ...ThisFiscalYearParams,
                            companyId
                        });
                    } else {
                        formatted[key] = values[key];
                    }
                    break;
                case "NextPeriodFrequencyCode":
                    const {DatePeriodEnd, NextPeriodFrequencyCode} = values;
                    const startOfNextPeriod = DatePeriodEnd && getUtcDayjs(DatePeriodEnd).add(1, 'day');
                    formatted[key] = getVatSubmissionPeriodTableCell(startOfNextPeriod, NextPeriodFrequencyCode);
                    break;
                case "ThisFiscalYearInfo":
                case "LastFiscalYearInfo":
                    break;
                default:
                    formatted[key] = `${values[key]}`;
            }
        });

        return formatted;
    }

    load = async (): Promise<void> => {
        const companies = this.context.getData().companies as ICompanyEntity[];
        const data = await getAgendaWorkOverviewData();

        this.setState({loading: false, companies, data});
    };

    handleSort = (sort: ISort[]): void => {
        this.setState({sort});
    };

    getSortedRows(): IRow[] {
        const {sort, companies, data} = this.state;

        if (!companies || !data) {
            return [];
        }

        return getSortedRows({
            companyColumnId: CompanyColumnId,
            companies,
            sort,
            getValueForSort: this.getValueForSort.bind(this),
            getFormattedValues: this.getFormattedValues.bind(this)
        });
    }

    render() {
        if (!this.props.tReady) {
            return null;
        }

        return (
                <>
                    <BreadCrumbProvider back={ROUTE_HOME}/>
                    <View hotspotContextId={"agendaWorkOverview"}>
                        <ViewHeader title={this.props.t("Home:AgendaWorkOverview.Title")}
                                    shouldHideVariant/>
                        <TableWrapper>
                            <TableWithAutoSizedColumns busy={this.state.loading}
                                                       rows={this.getSortedRows()}
                                                       columns={this.getColumnsMemoized()}
                                                       sort={this.state.sort}
                                                       onSortChange={this.handleSort}/>
                        </TableWrapper>
                    </View>
                </>
        );
    }
}

export default withOData(withTranslation(["Home", "ElectronicSubmission"])(AgendaWorkOverview));