import Alert, { IBaseAlertProps } from "@components/alert/Alert";
import BusyIndicator from "@components/busyIndicator";
import { BusyIndicatorSize, BusyIndicatorType } from "@components/busyIndicator/BusyIndicator.utils";
import { Button, ButtonGroup } from "@components/button";
import Dialog from "@components/dialog/Dialog";
import { EditSimpleIcon } from "@components/icon";
import Field from "@components/inputs/field";
import { Select } from "@components/inputs/select";
import { ISelectionChangeArgs, ISelectItem } from "@components/inputs/select/Select.types";
import { ODataError } from "@odata/Data.types";
import {
    ChartOfAccountsTemplateEntity,
    EntitySetName,
    IChartOfAccountsTemplateEntity
} from "@odata/GeneratedEntityTypes";
import { AccountingCode } from "@odata/GeneratedEnums";
import { WithOData, withOData } from "@odata/withOData";
import {
    cloneChartOfAccountsTemplate,
    createMinimalChartOfAccountsTemplate
} from "@pages/chartOfAccounts/ChartOfAccounts.utils";
import {
    ChartOfAccountTemplateContext,
    ICOATemplateContext
} from "@pages/chartOfAccountsTemplates/ChartOfAccountsTemplatesContext";
import { getDefinitions } from "@pages/chartOfAccountsTemplates/ChartOfAccountsTemplatesDef";
import TemplateEditPage from "@pages/companies/TemplateEditPage";
import { arrayInsert, isNotDefined } from "@utils/general";
import { logger } from "@utils/log";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import { AppContext } from "../../contexts/appContext/AppContext.types";
import { BasicInputSizes, TextAlign } from "../../enums";
import memoize from "../../utils/memoize";
import { getAlertFromError } from "../../views/formView/Form.utils";

interface IProps extends WithTranslation, WithOData {
    templateId: number;
    onChange: (templateId: number) => void;
}

interface IState {
    items: ISelectItem[];
    templates: IChartOfAccountsTemplateEntity[];
    isEditDialogOpen: boolean;
    isCreatingTemplate: boolean;
    alert: IBaseAlertProps;
    isLoadingItems: boolean;
}

export const MINIMAL_CHART_OF_ACCOUNTS_TEMPLATE_ID = 0;

class COATemplateSelection extends React.Component<IProps, IState> {
    static contextType = AppContext;
    templateContext: ICOATemplateContext;
    state: IState = {
        items: [],
        isEditDialogOpen: false,
        templates: [],
        isCreatingTemplate: false,
        alert: null,
        isLoadingItems: false
    };

    constructor(props: IProps) {
        super(props);
        this.templateContext = {
            isChangingName: false,
            isChanged: false,
            onTemplateNameChange: null,
            onTemplateNameChangeFailed: null
        };
    }

    async componentDidMount() {
        await this.loadTemplates();
        this.forceUpdate();
    }

    loadTemplates = async () => {
        this.setState({ isLoadingItems: true });
        let alert: IBaseAlertProps = null;
        let items: ISelectItem[] = [];
        let templates: IChartOfAccountsTemplateEntity[] = [];

        try {
            const res = await this.props.oData.getEntitySetWrapper(EntitySetName.ChartOfAccountsTemplates).query()
                    .select(ChartOfAccountsTemplateEntity.Id, ChartOfAccountsTemplateEntity.Name)
                    .filter(`${ChartOfAccountsTemplateEntity.Company} eq null OR ${ChartOfAccountsTemplateEntity.Company}/Id eq ${this.context.getCompanyId()}`)
                    .expand(ChartOfAccountsTemplateEntity.Company, query => query.select("Id"))
                    .fetchData<IChartOfAccountsTemplateEntity[]>();

            templates = arrayInsert(res.value, {
                Id: MINIMAL_CHART_OF_ACCOUNTS_TEMPLATE_ID,
                Name: this.props.t("Companies:Wizard.MinimalChart")
            }, 1);

            items = templates.map(template => ({
                id: template.Id,
                label: template.Name + (template.Id < 0 ? ` (${this.props.t("Companies:Wizard.Default")})` : ""),
                groupId: template.Id < 1 ? "system" : "user"
            }));
        } catch (e) {
            alert = getAlertFromError(e as ODataError);
        }

        this.setState({ items, templates, alert, isLoadingItems: false });
    }

    getGroups = memoize(() => [{
        id: "system"
    }, {
        id: "user",
        title: this.props.t("Companies:Wizard.UserTemplates").toUpperCase()
    }]);

    handleTemplateSelect = (e: ISelectionChangeArgs) => {
        this.props.onChange(e.value as number);
    };

    getNextTemplateCopyName = (template: IChartOfAccountsTemplateEntity) => {
        const copyName = `${template.Name} (${this.props.t("Companies:Wizard.Edited")})`;
        let existingCopy = this.state.templates.some(t => t.Name === copyName);
        let index: number;

        const findTemplateByName = (t: IChartOfAccountsTemplateEntity) => {
            return t.Name === `${template.Name} ${index} (${this.props.t("Companies:Wizard.Edited")})`;
        };

        while (existingCopy) {
            index = (index || 1) + 1;
            existingCopy = this.state.templates.some(findTemplateByName);
        }

        return `${template.Name}${index > 0 ? " " + index : ""} (${this.props.t("Companies:Wizard.Edited")})`;
    }

    handleEdit = async () => {
        const template = this.state.templates.find(t => t.Id === this.props.templateId);
        if (isNotDefined(template?.Company?.Id)) {
            this.setState({ isCreatingTemplate: true });
            const newName = this.getNextTemplateCopyName(template);
            let newTemplate: IChartOfAccountsTemplateEntity;
            const companyId = this.context.getCompanyId();
            try {
                if (!companyId) {
                    logger.error("Company is not set");
                } else {
                    if (template.Id === MINIMAL_CHART_OF_ACCOUNTS_TEMPLATE_ID) {
                        newTemplate = await createMinimalChartOfAccountsTemplate(AccountingCode.AccountingForBusiness, newName, companyId);
                    } else {
                        newTemplate = await cloneChartOfAccountsTemplate(template.Id.toString(), newName, companyId);
                    }
                    await this.loadTemplates();
                    this.props.onChange(newTemplate.Id);
                }
            } catch (e) {
                const alert = getAlertFromError(e as ODataError);
                this.setState({ alert });
                this.forceUpdate();
            }
        }
        this.setState({ isEditDialogOpen: true, isCreatingTemplate: false });
    };

    handleCloseDialog = () => {
        this.setState({ isEditDialogOpen: false });
    };

    handleAlertClose = () => {
        this.setState({ alert: null });
    };

    render() {
        if (!this.props.tReady) {
            return null;
        }

        if (this.state.isLoadingItems) {
            return <BusyIndicator/>;
        }

        const { templateId, t } = this.props;

        return (
                <>
                    <Field label={t("Companies:Wizard.ChartOfAccount")}
                           isRequired>
                        <Select
                                onChange={this.handleTemplateSelect}
                                value={templateId}
                                width={BasicInputSizes.XL}
                                isDisabled={this.state.isCreatingTemplate}
                                items={this.state.items}
                                groups={this.getGroups()}
                        />
                    </Field>
                    <ButtonGroup align={TextAlign.Right}>
                        <Button isTransparent
                                onClick={this.handleEdit}
                                icon={this.state.isCreatingTemplate ? <BusyIndicator
                                        type={BusyIndicatorType.WithoutBackground}
                                        isInverse size={BusyIndicatorSize.XS}/> : <EditSimpleIcon/>}
                                isDisabled={this.state.isCreatingTemplate || isNotDefined(templateId)}>
                            {t("Companies:Wizard.EditChart")}
                        </Button>
                    </ButtonGroup>
                    {this.state.alert && <Alert
                            {...this.state.alert}
                            onClose={this.handleAlertClose}
                    />}
                    {this.state.isEditDialogOpen &&
                            <ChartOfAccountTemplateContext.Provider value={this.templateContext}>
                                <Dialog onClose={this.handleCloseDialog}
                                        onConfirm={null}
                                        isEditableWindow>
                                    {/* Render split page in dialog */}
                                    {<TemplateEditPage
                                            getDef={getDefinitions}
                                            hasPreview={false}
                                            usePrompt={true}
                                            parentId={templateId}
                                            dontUseUrlParams={true}
                                            isParentEditable={false}/>}
                                </Dialog>
                            </ChartOfAccountTemplateContext.Provider>
                    }
                </>
        );
    }
}

export default withTranslation(["Companies"])(withOData(COATemplateSelection));