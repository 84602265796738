import React from "react";
import { StyledSimpleGridTable } from "./SimpleGridTable.styles";
import TestIds from "../../testIds";

interface IProps {
    numColumns: number;
    width?: string;
    height?: string;
    style?: React.CSSProperties;
    className?: string;
}

export default class SimpleGridTable extends React.PureComponent<IProps> {
    render() {
        return (
            <StyledSimpleGridTable numColumns={this.props.numColumns}
                                   $width={this.props.width}
                                   $height={this.props.height}
                                   style={this.props.style}
                                   className={this.props.className}
                                   data-testid={TestIds.SimpleGridTable}>
                {this.props.children}
            </StyledSimpleGridTable>
        );
    }
}

export type { IProps as ISimpleGridTableProps };