import { isNotDefined } from "@utils/general";
import i18next from "i18next";
import { TFormatterFn } from "@components/smart/smartTable/SmartTable.utils";

class BooleanType {
    static parse(value: string): boolean {
        if (isNotDefined(value) || value === "") {
            return null;
        }

        value = value.toLowerCase();

        if (value === "true" || value === i18next.t("Common:General.Yes").toLowerCase()) {
            return true;
        } else if (value === "false" || value === i18next.t("Common:General.No").toLowerCase()) {
            return false;
        }

        return null;
    }

    static format(value: boolean): string {
        return value ? i18next.t("Common:General.Yes") : i18next.t("Common:General.No");
    }

    static isValid(value: boolean): boolean {
        return typeof value === "boolean";
    }
}

export const booleanFormatter: TFormatterFn = (val) => {
    return BooleanType.format(val as boolean);
};

export default BooleanType;
