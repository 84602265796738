import React from "react";
import { PageViewMode } from "../../../enums";
import Dialog from "../../dialog/Dialog";
import DialogPage from "../../../pages/DialogPage";
import { StorageModel } from "../../../model/StorageModel";
import BindingContext, { IEntity } from "../../../odata/BindingContext";
import PairingDocumentTableView, { IPairingDocumentTableViewProps } from "./PairingDocumentTableView";
import { TDefinitionFn } from "../../../global.types";
import { Model } from "../../../model/Model";
import { IFieldInfo } from "@odata/FieldInfo.utils";

export interface ISmartPairingSelectSharedProps {
    onPair?: (selected: BindingContext[], entities: IEntity[]) => void;
    dialogTableViewProps?: Pick<IPairingDocumentTableViewProps, "title" | "secondaryTitle" | "headerData" | "confirmText" | "successMessage" | "isSingleSelect" | "withoutMainToggle" | "isRowWithoutAction" | "presetDefaultFilters">;
    getDefinition: TDefinitionFn;
}

export interface ISmartPairingSelectBaseProps extends ISmartPairingSelectSharedProps {
    isSingleSelect: boolean;
    selectedItems: BindingContext[];
    renderSelect: (onIconClick: (e: React.MouseEvent) => void) => React.ReactElement;

    fieldInfo: IFieldInfo;
    bindingContext: BindingContext;
    storage?: Model;
}

interface IState {
    isDialogOpen: boolean;
}

/** Combination of Select and Dialog to pick one or multiple documents for pairing.
 * Hardcoded for documents, but could be changed to be used with anything. */
export default class SmartPairingSelectBase extends React.Component<ISmartPairingSelectBaseProps, IState> {
    state: IState = {
        isDialogOpen: false
    };

    handleIconClick = (e: React.MouseEvent): void => {
        // prevent select from opening
        e.preventDefault();

        this.setState({
            isDialogOpen: true
        });
    };

    handleCloseDialog = (): void => {
        this.setState({
            isDialogOpen: false
        });
    };

    handlePairing = (selected: BindingContext[], entities: IEntity[]): void => {
        const updatedPairedItems = entities.map((entity, index) => {
            const entityBc = selected[index];

            return {
                ...entity,
                _metadata: {
                    "": { type: `#${entityBc.getEntityType().getFullName()}` }
                }
            };
        });

        this.props.onPair?.(selected, updatedPairedItems);
    };

    renderDialog = (): React.ReactElement => {
        const tableViewProps: IPairingDocumentTableViewProps = {
            selectedItems: this.props.selectedItems,
            onClose: this.handleCloseDialog,
            onSelection: this.handlePairing,
            isSingleSelect: this.props.isSingleSelect,
            ...this.props.dialogTableViewProps
        };

        return (
            <Dialog isEditableWindow
                    onConfirm={null}
                    onClose={this.handleCloseDialog}>
                <DialogPage
                    pageViewMode={PageViewMode.FormReadOnly}
                    rootStorage={this.props.storage as StorageModel}
                    tableView={PairingDocumentTableView}
                    tableViewProps={tableViewProps}
                    getDef={this.props.getDefinition}/>
            </Dialog>
        );
    };

    render() {
        return (
            <>
                {this.state.isDialogOpen && this.renderDialog()}
                {this.props.renderSelect(this.handleIconClick)}
            </>
        );
    }
}