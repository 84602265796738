import { FormViewForExtend, IFormViewProps } from "../../../views/formView/FormView";
import { withPermissionContext } from "../../../contexts/permissionContext/withPermissionContext";
import BindingContext, { IEntity } from "../../../odata/BindingContext";
import { ISmartFieldChange } from "@components/smart/smartField/SmartField";
import { cloneDeep } from "lodash";
import {
    isInAutomatedVatInclusionMode,
    IVatClassificationExtendedEntity,
    VAT_REVERSE_CHARGE_SELECT_PATH
} from "./VatRules.utils";
import { isVisibleByPath } from "@components/smart/FieldInfo";
import { VatReverseChargeCode } from "@odata/GeneratedEnums";
import { VatClassificationEntity } from "@odata/GeneratedEntityTypes";
import { ICachedFieldState } from "@odata/Data.utils";
import { NonTaxAccountPath } from "./VatRulesDef";

interface IProps extends IFormViewProps<IVatClassificationExtendedEntity> {}

class VatRulesFormView extends FormViewForExtend<IVatClassificationExtendedEntity, IProps> {
    static defaultProps = {
        title: "FormTitle"
    };

    onAfterLoad = async () => {

        this.entity[VAT_REVERSE_CHARGE_SELECT_PATH] = this.entity.VatReverseCharge?.Code;
        // local context for nonTaxAccount has to have some id so validation and select works properly, but it
        // should not be linked to existing accounts (eg. you have account with id 4, you change his name, but you still
        // want to display old name here until you chose again from select items)
        this.props.storage.setValueByPath(NonTaxAccountPath, BindingContext.NEW_ENTITY_ID_PROP);
        return super.onAfterLoad();
    };

    handleFieldStateChange(bc: BindingContext, state: ICachedFieldState, prevState: ICachedFieldState) {
        super.handleFieldStateChange(bc, state, prevState);
        const path = bc.getPath();
        if (path === VatClassificationEntity.VatReverseCharge && !state.isVisible) {
            // clear reverse-charge value when field became invisible, so further calculations are correct
            this.props.storage.clearValueByPath(VatClassificationEntity.VatReverseCharge);
        }
    }

    handleChange = (e: ISmartFieldChange) => {
        const { storage } = this.props;
        const path = e.bindingContext.getPath();

        // original value before handle change
        const wasVisible = isVisibleByPath(storage, VatClassificationEntity.IsInAutomatedVatInclusionMode);
        const wasInVatAutomatedInclusionMode = wasVisible && isInAutomatedVatInclusionMode({ storage });

        if (path === VAT_REVERSE_CHARGE_SELECT_PATH && e.triggerAdditionalTasks) {
            storage.setValueByPath(VatClassificationEntity.VatReverseCharge, e.value);
        }

        storage.handleChange(e);

        this.handleAccountChange(e);

        if (e.bindingContext.getPath() === VatClassificationEntity.VatReverseCharge) {
            storage.clearAndSetValueByPath(VatClassificationEntity.VatReverseCharge, e.value ? VatReverseChargeCode.Ano : VatReverseChargeCode.Ne);
            storage.clearAndSetValueByPath(VAT_REVERSE_CHARGE_SELECT_PATH, null);
        }

        if ((VatClassificationEntity.CountryClassification === path && e.triggerAdditionalTasks) || VatClassificationEntity.IsVatRegistered === path) {
            const reverseChargePath = VatClassificationEntity.VatReverseCharge;
            const rcBc = storage.data.bindingContext.navigate(reverseChargePath);

            if (!storage.isDirty(rcBc)) {
                storage.setDefaultValueByPath(reverseChargePath);
            }
        }

        if (([VatClassificationEntity.PayableReceivableType, VatClassificationEntity.CountryClassification].includes(path as VatClassificationEntity) && e.triggerAdditionalTasks) ||
            VatClassificationEntity.IsVatRegistered === path) {
            // These fields changes their values according to CountryClassification and document type -> clear their values if changed

            // Vat statement has no default value, so we clear it directly ...
            storage.clearValueByPath(VatClassificationEntity.VatStatementSection);
            // ... however there is default value for VatControlStatementSection
            storage.setDefaultValueByPath(VatClassificationEntity.VatControlStatementSection);
        }

        const isVisible = isVisibleByPath(storage, VatClassificationEntity.IsInAutomatedVatInclusionMode);
        if (!wasVisible && isVisible) {
            // set default value manually here as we need to trigger additional calculations according to it
            storage.setDefaultValueByPath(VatClassificationEntity.IsInAutomatedVatInclusionMode);
        }
        const isInVatAutomatedInclusionMode = isVisible && isInAutomatedVatInclusionMode({ storage });
        if (isInVatAutomatedInclusionMode !== wasInVatAutomatedInclusionMode) {
            storage.setDefaultValueByPath(VatClassificationEntity.VatControlStatementSection);
        }

        storage.refreshFields();
    };

    handleAccountChange = (e: ISmartFieldChange): void => {
        const path = e.bindingContext.getPath();

        if (path !== NonTaxAccountPath) {
            return;
        }

        const cleanPath = BindingContext.cleanLocalContext(path);
        const { Name, Number } = e.additionalData ?? {};

        this.props.storage.setValueByPath(`${cleanPath}Name`, Name);
        this.props.storage.setValueByPath(`${cleanPath}Number`, Number);
    };

    onBeforeSave = (): IEntity => {
        const { storage } = this.props;

        const entity = cloneDeep(this.entity);

        if (!isVisibleByPath(storage, VatClassificationEntity.IsInAutomatedVatInclusionMode)) {
            entity.IsInAutomatedVatInclusionMode = false;
        }

        const isReverseChargeVisible = isVisibleByPath(storage, VatClassificationEntity.VatReverseCharge);
        if (isReverseChargeVisible && !entity.VatReverseChargeCode) {
            entity.VatReverseChargeCode = VatReverseChargeCode.Ne;
        } else if (!isReverseChargeVisible) {
            entity.VatReverseChargeCode = null;
        }

        return entity;
    };
}

export default withPermissionContext(VatRulesFormView);
