import React from "react";
import { CloseIconWrapper, SimpleItem, StyledItemsList } from "./SimpleItemsList.styles";
import { CloseIcon } from "../icon";
import { IconSize } from "../../enums";
import { IconButton } from "../button";
import { WithTranslation, withTranslation } from "react-i18next";
import TestIds from "../../testIds";

export interface ISimpleItem {
    id: string;
    label: string;
    contentBefore?: string | React.ReactElement;
}

interface IProps {
    items: ISimpleItem[];
    onRemove?: (itemId: string) => void;
    style?: React.CSSProperties;
}

class SimpleItemsList extends React.PureComponent<IProps & WithTranslation> {
    renderItem = (item: ISimpleItem) => {
        return (
            <React.Fragment key={item.id}>
                <SimpleItem data-testid={TestIds.SimpleItem}>
                    {item.contentBefore}
                    {item.label}
                </SimpleItem>
                <CloseIconWrapper>
                    <IconButton title={this.props.t("Common:General.Remove")}
                                onClick={() => {
                                    this.props.onRemove?.(item.id);
                                }}
                                isDecorative>
                        <CloseIcon width={IconSize.XS} height={IconSize.XS}/>
                    </IconButton>
                </CloseIconWrapper>
            </React.Fragment>
        );
    };

    renderItems = () => {
        return this.props.items.map(item => this.renderItem(item));
    };

    render() {
        return (
            <StyledItemsList style={this.props.style}>
                {this.renderItems()}
            </StyledItemsList>
        );
    }
}

export default withTranslation(["Common"])(SimpleItemsList);