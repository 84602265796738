import {
    FilterBarGroup,
    getDefaultFilterGroupDef,
    IFilterGroupDef
} from "@components/smart/smartFilterBar/SmartFilterBar.types";
import { IReportHierarchy } from "@components/smart/smartTable";
import i18next from "i18next";

import { FieldType, Sort } from "../../../enums";
import BindingContext from "../../../odata/BindingContext";
import {
    assetIntentFormatter,
    CommonReportProps,
    documentNumberOursOverrideFormatterWithPaymentDocs,
    getDocumentTypeFilterDef
} from "../CommonDefs";
import {
    composedDateRangeOnBeforeLoadCallback,
    getComposedDateRangeFieldsDefs
} from "../customFilterComponents/ComposedDateRange";
import { IReportTableDefinition } from "../Report.utils";
import { ReportId } from "../ReportIds";
import { ReportStorage } from "../ReportStorage";

export const AccountingJournalProps = {
    impactedAccount: BindingContext.localContext("ImpactedAccount")
};

export const getDefinition = (): IReportTableDefinition => {
    const tableId = ReportId.AccountingJournal;
    const title = i18next.t("Reporting:AccountingJournal.Title");
    const path = "AccountingJournal";
    const initialSortBy = [
        { id: "JournalEntry_DateAccountingTransaction", sort: Sort.Desc },
        { id: "Document_NumberOurs", sort: Sort.Desc }
    ];
    const filterBarDef: IFilterGroupDef[] = [
        {
            ...getDefaultFilterGroupDef(FilterBarGroup.Parameters),
            defaultFilters: [
                CommonReportProps.dateRange, CommonReportProps.dateRangeCustomValue,
                AccountingJournalProps.impactedAccount
            ],
            filterDefinition: {
                ...getComposedDateRangeFieldsDefs(tableId),
                [AccountingJournalProps.impactedAccount]: {
                    type: FieldType.Input,
                    label: i18next.t("Reporting:AccountingJournal.ImpactedAccount"),
                    // BE accepts string[], we use string
                    parser: (val: string) => {
                        return [val];
                    },
                    formatter: (val) => {
                        if (!Array.isArray(val)) {
                            return val as string;
                        }

                        return (val as string[])?.[0];
                    }
                }
            }
        },
        {
            ...getDefaultFilterGroupDef(FilterBarGroup.Filters),
            allowCustomFilters: false,
            defaultFilters: [],
            filterDefinition: {
                [BindingContext.localContext("DocumentType_Name")]: getDocumentTypeFilterDef("JournalEntry_DocumentTypeCode")
            }
        }
    ];
    const onBeforeLoad = async (storage: ReportStorage) => {
        await composedDateRangeOnBeforeLoadCallback(storage);
    };
    const parameters: string[] = [CommonReportProps.dateRange, CommonReportProps.dateRangeCustomValue, AccountingJournalProps.impactedAccount];
    const defaultReportHierarchy: IReportHierarchy = {
        "Aggregate": false,
        "Groups": [],
        "Columns": [
            {
                "ColumnAlias": "JournalEntry_DateAccountingTransaction"
            },
            {
                "ColumnAlias": "Document_NumberOurs"
            },
            {
                "ColumnAlias": "DocumentType_Name"
            },
            {
                "ColumnAlias": "JournalEntry_Description"
            },
            {
                "ColumnAlias": "JournalEntry_Amount"
            },
            {
                "ColumnAlias": "DebitAccount_Number"
            },
            {
                "ColumnAlias": "CreditAccount_Number"
            }
        ],
        "Aggregations": []
    };

    const columnOverrides = {
        Document_NumberOurs: {
            formatter: documentNumberOursOverrideFormatterWithPaymentDocs
        },
        Asset: {
            formatter: assetIntentFormatter
        }
    };

    return {
        title, path, id: tableId, initialSortBy, filterBarDef,
        onBeforeLoad, columnOverrides, parameters, defaultReportHierarchy
    };
};