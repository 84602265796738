import React, { memo, useContext, useEffect, useState } from "react";
import { getTileData, IDashboardTileComponentProps, ILinkTileInfo } from "../../dashboard";
import { WithOData, withOData } from "@odata/withOData";
import {
    BadgeStyled,
    IconHeight,
    IconWidth,
    StyledLinkTile,
    StyledLinkTileTitle,
    StyledLinkTileTitleWrapper,
} from "./LinkTile.styles";
import { getIcon } from "../../icon";
import { AppContext } from "../../../contexts/appContext/AppContext.types";
import { getInfoValue } from "../../smart/FieldInfo";

interface IProps extends IDashboardTileComponentProps<ILinkTileInfo>, WithOData {
}

const LinkTile: React.FC<IProps> = ({ inEditMode, info, oData }) => {
    const context = useContext(AppContext);
    const [count, setCount] = useState<number>(null);

    useEffect(() => {
        let mounted = true;
        if (info.count) {
            try {
                getTileData(info.count, { context, oData })
                        .then((data) => mounted && setCount(data))
                        .catch(() => setCount(null));
            } catch (e) {
                /* Fall silently, just don't show badge */
                if (mounted) {
                    setCount(null);
                }
            }
        }

        return () => {
            mounted = false;
        };
    }, [context, info.count, oData]);

    const to = getInfoValue(info, "link", { context });
    const Icon = info.iconName && getIcon(info.iconName);

    return (
            <StyledLinkTile backgroundColor={info.backgroundColor}
                            inEditMode={inEditMode}
                            to={to}>
                {Icon && (<Icon width={IconWidth} height={IconHeight}/>)}
                <StyledLinkTileTitleWrapper>
                    <StyledLinkTileTitle>{info.title}</StyledLinkTileTitle>
                </StyledLinkTileTitleWrapper>
                <BadgeStyled count={count} max={999}/>
            </StyledLinkTile>
    );
};

export default memo(withOData(LinkTile));