import { IDefinition } from "@pages/PageUtils";
import i18next from "i18next";
import { REST_API_URL } from "../../../../constants";
import { DocumentLinkTypeCode } from "@odata/GeneratedEnums";
import { IFormGroupDef } from "@components/smart/smartFormGroup/SmartFormGroup";
import { getDocumentPairedTable } from "../../Document.utils";
import { FormStorage } from "../../../../views/formView/FormStorage";

export const PROCESS_ACCRUAL_URL = `${REST_API_URL}/AccruedProcessing/Process`;
export const ACCRUALS_FORM_TAB = "Accruals";
export const FUTURE_REVENUES_FORM_TAB = "FutureRevenues";
export const FUTURE_EXPENSES_FORM_TAB = "FutureExpenses";
export const ACCRUED_DOC_LINK_TYPES: DocumentLinkTypeCode[] = [
    DocumentLinkTypeCode.AccruedExpense, DocumentLinkTypeCode.AccruedRevenue,
    DocumentLinkTypeCode.EstimatedAccruedRevenue, DocumentLinkTypeCode.EstimatedAccruedExpense
];
export const ACCRUED_DOC_LINK_SETTLEMENT_TYPES: DocumentLinkTypeCode[] = [
    DocumentLinkTypeCode.AccruedExpenseSettlement, DocumentLinkTypeCode.AccruedRevenueSettlement,
    DocumentLinkTypeCode.EstimatedAccruedRevenueSettlement, DocumentLinkTypeCode.EstimatedAccruedExpenseSettlement
];
export const ACCRUED_DOC_LINK_ALL_TYPES: DocumentLinkTypeCode[] = [
    ...ACCRUED_DOC_LINK_SETTLEMENT_TYPES, ...ACCRUED_DOC_LINK_TYPES
];
export const ACCRUAL_DOC_LINK_FILTER = `TypeCode in (${[DocumentLinkTypeCode.EstimatedAccruedExpense, DocumentLinkTypeCode.EstimatedAccruedExpenseSettlement,
    DocumentLinkTypeCode.EstimatedAccruedRevenue, DocumentLinkTypeCode.EstimatedAccruedRevenueSettlement].map(linkType => `'${linkType}'`).join(", ")})`;
export const FUTURE_REVENUES_DOC_LINK_FILTER = `TypeCode in (${[DocumentLinkTypeCode.AccruedRevenue, DocumentLinkTypeCode.AccruedRevenueSettlement].map(linkType => `'${linkType}'`).join(", ")})`;
export const FUTURE_EXPENSES_DOC_LINK_FILTER = `TypeCode in (${[DocumentLinkTypeCode.AccruedExpense, DocumentLinkTypeCode.AccruedExpenseSettlement].map(linkType => `'${linkType}'`).join(", ")})`;
export const CONNECT_ACCRUALS_ACTION = "connectAccruals";
export const CONNECT_REVENUES_ACTION = "connectRevenues";
export const CONNECT_EXPENSES_ACTION = "connectExpenses";

interface IAddAccrualsSettings {
    addAction?: boolean;
    isExpense?: boolean;
    isRevenue?: boolean;
}

export enum AccrualDialogType {
    Accruals = "Accruals",
    Revenues = "Revenues",
    Expenses = "Expenses"
}

export const getAccrualDialogTypeFromHeaderAction = (actionId: string) => {
    switch (actionId) {
        case CONNECT_ACCRUALS_ACTION:
            return AccrualDialogType.Accruals;
        case CONNECT_REVENUES_ACTION:
            return AccrualDialogType.Revenues;
        case CONNECT_EXPENSES_ACTION:
            return AccrualDialogType.Expenses;
    }

    return null;
};

export const addAccrualsDef = (definition: IDefinition, settings: IAddAccrualsSettings = {}): void => {
    if (settings.addAction) {
        definition.form.customHeaderActions = definition.form.customHeaderActions ?? [];


        definition.form.customHeaderActions.push({
            id: CONNECT_ACCRUALS_ACTION,
            label: i18next.t("Document:Form.OpenAccrualsLabel"),
            iconName: "PairingAccruedItems",
            isVisible: (args) => {
                return !(args.storage as FormStorage).isReadOnly && !args.storage.data.bindingContext.isNew();
            }
        });

        if (settings.isRevenue) {
            definition.form.customHeaderActions.push({
                id: CONNECT_REVENUES_ACTION,
                label: i18next.t("Document:Form.OpenRevenuesLabel"),
                iconName: "PairingExpenses",
                isVisible: (args) => {
                    return !(args.storage as FormStorage).isReadOnly && !args.storage.data.bindingContext.isNew();
                }
            });
        }

        if (settings.isExpense) {
            definition.form.customHeaderActions.push({
                id: CONNECT_EXPENSES_ACTION,
                label: i18next.t("Document:Form.OpenExpensesLabel"),
                iconName: "PairingExpenses",
                isVisible: (args) => {
                    return !(args.storage as FormStorage).isReadOnly && !args.storage.data.bindingContext.isNew();
                }
            });
        }
    }

    const tabsGroup: IFormGroupDef = definition.form.groups.find(group => group.id === "Tabs");

    tabsGroup.tabs.push(getAccrualsTab());

    if (settings.isRevenue) {
        tabsGroup.tabs.push(getFutureRevenuesTab());
    }

    if (settings.isExpense) {
        tabsGroup.tabs.push(getFutureExpensesTab());
    }
};

const getAccrualsTab = (): IFormGroupDef => {
    return {
        id: ACCRUALS_FORM_TAB,
        title: i18next.t("Document:FormTab.Accruals"),
        table: getDocumentPairedTable({
            selectQuery: ACCRUAL_DOC_LINK_FILTER,
            addAmountColumn: true,
            isCbaCompany: false
        })
    };
};

const getFutureRevenuesTab = (): IFormGroupDef => {
    return {
        id: FUTURE_REVENUES_FORM_TAB,
        title: i18next.t("Document:FormTab.FutureRevenues"),
        table: getDocumentPairedTable({
            selectQuery: FUTURE_REVENUES_DOC_LINK_FILTER,
            addAmountColumn: true,
            isCbaCompany: false
        })
    };
};

const getFutureExpensesTab = (): IFormGroupDef => {
    return {
        id: FUTURE_EXPENSES_FORM_TAB,
        title: i18next.t("Document:FormTab.FutureExpenses"),
        table: getDocumentPairedTable({
            selectQuery: FUTURE_EXPENSES_DOC_LINK_FILTER,
            addAmountColumn: true,
            isCbaCompany: false
        })
    };
};
