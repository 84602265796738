import { StyledIconButton } from "@components/button";
import styled from "styled-components/macro";

import { T_PLAIN_big, T_TITLE_2 } from "../../global.style";

export const IconWrapper = styled.div`
    display: flex;
    justify-content: center;
    overflow: hidden;
    width: 100%;
    padding: 54px 0 0;
`;

export const StyledHeader = styled.h4`
    ${T_TITLE_2};
    text-align: center;
    margin-bottom: 20px;
`;

export const StyledSubtitle = styled.h5`
    ${T_PLAIN_big};
    text-align: center;

    & > ${StyledIconButton} {
        position: relative;
        display: inline-block;
        top: 4px;
        margin-left: 6px;
    }
`;

export const DialogContentWrapper = styled.div<{
    width: string;
}>`
    width: ${props => props.width};
`;

export const HeaderWrapper = styled.div`
    padding: 16px 19px 24px;
`;

export const ContentWrapper = styled.div`
    padding: 0 19px 24px;

    &:empty {
        padding: 0;
    }
`;