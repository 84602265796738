import React from "react";
import TableSortingDialog from "../../table/TableSortingDialog";
import { handleSortChange } from "../../../views/table/TableView.utils";
import { TSmartODataTableStorage } from "./SmartODataTableBase";
import { IColumn, ISort } from "../../table";
import { prepareColumns } from "./SmartTable.utils";
import { AppContext } from "../../../contexts/appContext/AppContext.types";
import { isEqual } from "lodash";
import { FieldVisibility } from "../../../enums";

export interface IProps {
    storage: TSmartODataTableStorage;
}

interface IState {
    isLoaded: boolean;
    columns: IColumn[];
}

class SmartTableSortingDialog extends React.PureComponent<IProps, IState> {
    static contextType = AppContext;

    state: IState = {
        isLoaded: false,
        columns: []
    };

    componentDidMount() {
        this.init();
    }

    init = async (): Promise<void> => {
        const columns = this.props.storage.data.mergedColumns
            .filter(column => !column.fieldSettings?.disableSort && column.fieldVisibility !== FieldVisibility.ExportOnly && !column.disableSort);

        // TODO change to this, after variants support sort on non-visible columns
        // const columns = Object.entries(this.props.storage.data.definition.columnDefinition)
        //     .filter(([name, def]) => def.fieldVisibility !== FieldVisibility.ExportOnly)
        //     .map(([name, def]) => ({
        //         ...def,
        //         id: name
        //     }));

        const columnsWithLabels = await prepareColumns({
            bindingContext: this.props.storage.data.bindingContext,
            context: this.context,
            columns
        });

        this.setState({
            isLoaded: true,
            columns: columnsWithLabels.map(column => ({
                id: column.id,
                label: column.label
            }))
        });
    };

    close = (): void => {
        this.props.storage.setCustomData({
            isSortingDialogOpen: false
        });
        this.props.storage.refresh();
    };

    getCurrentSort = () => {
        return this.props.storage.getInitialSortOrder();
    };

    handleConfirm = (sort: ISort[]): void => {
        if (!isEqual(sort, this.getCurrentSort())) {
            handleSortChange(this.props.storage, sort);

            this.props.storage.tableAPI.setState({
                sort
            }, this.props.storage.tableAPI.reloadTable);
        }


        this.close();
    };

    handleCancel = (): void => {
        this.close();
    };

    render() {
        return (
            <TableSortingDialog columns={this.state.columns}
                                sort={this.getCurrentSort()}
                                isBusy={!this.state.isLoaded}
                                onConfirm={this.handleConfirm}
                                onCancel={this.handleCancel}/>
        );
    }
}

export default SmartTableSortingDialog;