import { ISmartODataTableAPI } from "@components/smart/smartTable/SmartODataTableBase";
import { ODataError } from "@odata/Data.types";
import { saveEntity } from "@odata/Data.utils";
import {
    CorrectiveInvoiceReceivedEntity,
    DocumentEntity,
    EntitySetName,
    IBankTransactionEntity,
    ICompanyEntity,
    IDocumentDraftEntity
} from "@odata/GeneratedEntityTypes";
import { DocumentTypeCode } from "@odata/GeneratedEnums";
import { BatchRequest, isBatchResultOk } from "@odata/OData";
import { ODataQueryResult } from "@odata/ODataParser";
import { amountFields } from "@pages/documents/corrective/CommonCorrectiveSharedUtils";
import { DRAFT_ITEM_ID_PATH } from "@pages/documents/Document.utils";
import { IDocumentExtendedEntity } from "@pages/documents/DocumentInterfaces";
import { getCompanyCurrency } from "@utils/CompanyUtils";
import { ICopyEntityArgs } from "@utils/DraftUtils";
import { isDefined, isNotDefined } from "@utils/general";
import { KeyboardShortcut } from "@utils/keyboardShortcutsManager/KeyboardShorcutsManager.utils";
import { UnregisterCallback } from "history";
import { debounce } from "lodash";
import React, { ReactElement } from "react";

import { Button, ButtonGroup } from "../../components/button";
import SmartFormDeleteButton from "../../components/smart/smartFormDeleteButton/SmartFormDeleteButton";
import SmartTableManager from "../../components/smart/smartTable/SmartTableManager";
import { NEW_ITEM_DETAIL, SAVE_DRAFT_DELAY } from "../../constants";
import { ContextEvents } from "../../contexts/appContext/AppContext.types";
import { PageViewMode, QueryParam } from "../../enums";
import BindingContext, { areBindingContextsDifferent, createBindingContext, IEntity } from "../../odata/BindingContext";
import { getQueryParameters, setQueryParams } from "../../routes/Routes.utils";
import { getAlertFromError } from "./Form.utils";
import { IFormStorageDefaultCustomData, IFormStorageSaveResult, ISaveArgs } from "./FormStorage";
import { FormViewForExtend, IFormViewProps } from "./FormView";

export interface IDraftFormViewProps<E extends IEntityWithDraft, C extends IFormStorageDefaultCustomData = IFormStorageDefaultCustomData> extends IFormViewProps<E, C> {
    preventDraftSave?: boolean;
    withoutButtons?: boolean;
}

interface IEntityWithDraft extends IEntity {
    DocumentTypeCode?: string;
    Company?: ICompanyEntity;
    Amount?: number;
    TransactionAmount?: number;
}

class DraftFormView<E extends IEntityWithDraft, P extends IDraftFormViewProps<E> = IDraftFormViewProps<E>, S = Record<string, unknown>> extends FormViewForExtend<E, P, S> {
    _lastUsedStorageBc: BindingContext = null;
    _removeBusyAfterUpdate = false;
    _isDeletingDraft = false;
    _savingDraftPromise: Promise<number> = null;
    _copyAmountFromDraft = false;

    _unblockUrlChanges: UnregisterCallback;
    _ignoreNextBlock = false;

    documentTypeCode: DocumentTypeCode = null;

    get _documentItemDomainType(): string {
        return null;
    }

    get preventDraftSave(): boolean {
        return !!getQueryParameters()?.[QueryParam.PreventDraftSave] || this.props.preventDraftSave || this.props.formProps?.isSimple || !!this.props.storage.getEditOverride();
    }

    get savedDraftKey(): string {
        // when called in catchUrlChanges, url in browser is already changed, but storage.history still has previous one
        return getQueryParameters({ historyLocation: this.props.storage.history?.location })[QueryParam.DraftId];
    }

    get draftProp(): keyof E {
        return this.props.storage.data.definition.draftDef.draftProperty as keyof E;
    }

    get draftEntitySet(): EntitySetName {
        return this.props.storage.data.definition.draftDef.draftEntitySet;
    }

    componentDidMount(): void {
        super.componentDidMount();
        this.catchUrlChanges();
        window.addEventListener("beforeunload", this.handlePageUnload);
    }

    async saveWithMove() {
        const result = await this.save({
            skipAfterSave: true
        });

        if (result) {
            this.props.storage.setFormAlert(null);
            this.props.onOpenNextDraft?.();
        }
    }

    handleKeyboardShortcut(shortcut: KeyboardShortcut, event: KeyboardEvent): boolean {
        if (!this.canProcessShortcuts()) {
            return false;
        }

        if (shortcut === KeyboardShortcut.ALT_SHIFT_S && this.isDraftView()) {
            if (!this.shouldDisableSaveButton()) {
                this.saveWithMove();
            }

            return true;
        }

        return super.handleKeyboardShortcut(shortcut, event);
    }

    // because PureForm is re-rendered before FormStorage.updateTabsVisibility is called
    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>): void {
        const lastUsedStorageBc = this._lastUsedStorageBc;

        if (areBindingContextsDifferent(this.props.storage.data.bindingContext, lastUsedStorageBc)) {
            this._lastUsedStorageBc = this.props.storage.data.bindingContext;

            if (lastUsedStorageBc || this.entity.TransactionCurrency?.Code !== getCompanyCurrency(this.props.storage.context)) {
                this.resetFormTabsTable();
            }
            if (this.props.storage.isBusy && this._removeBusyAfterUpdate) {
                // when saving draft from new entity, we need to keep the busy state until we have correct
                // bindingContext in storage, which is changed according to URL in SplitPageBase,
                // so we wait on next didUpdate
                this.props.storage.setBusy(false);
                this._removeBusyAfterUpdate = false;
            }
        }
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        this.flushSaveDraft();
        // can be used inside other contexts like audit trail or recurring tasks, which doesn't have history object, only call conditionally
        this._unblockUrlChanges?.();
        window.removeEventListener("beforeunload", this.handlePageUnload);
    }

    /** Temporarily prevents the URL from changing when the user makes modifications to the form and then navigates away.
     * It saves the draft and generates a new draft ID, which is then used to update the browser's history state. */
    catchUrlChanges = (): void => {
        // catch before url is changed, probably equivalent of onbeforeunload event
        // we want to save draft before url change happens, so that we have current bc and company
        // TODO if opened inside editable window dialog, throws this error "react_devtools_backend_compact.js:2367 Warning: A history supports only one prompt at a time "
        this._unblockUrlChanges = this.props.storage.history?.block((args) => {
            if (!this._ignoreNextBlock) {
                // pathname is not enough for when changing between drafts => take search in consideration as well
                const isDifferentPath = args.pathname !== this.props.storage.history.location.pathname || args.search !== this.props.storage.history.location.search;

                if (isDifferentPath) {
                    this.flushSaveDraft();

                    if (this.props.storage.data.bindingContext?.isNew() && !this.savedDraftKey) {
                        const newDraftId = this.entity.DocumentDraft?.Id;

                        if (newDraftId) {
                            this._ignoreNextBlock = true;
                            this.redirectToDraftPage(newDraftId, true);
                        } else {
                            const draftPromise = this._savingDraftPromise ?? this.saveDraftDebounced.flush();

                            if (draftPromise) {
                                this.props.storage.setBusy(true);
                                // wait until the draft is saved
                                draftPromise.then((newDraftId) => {
                                    // request can fail and return null
                                    if (newDraftId) {
                                        this._ignoreNextBlock = true;
                                        this.redirectToDraftPage(newDraftId, true);
                                    }

                                    this.props.storage.setBusy(false);
                                    // navigate to the original path
                                    this._ignoreNextBlock = true;
                                    this.props.storage.history.push(args);
                                });
                                // block url change until draft is saved
                                return false;
                            }
                        }
                    }
                }
            } else {
                this._ignoreNextBlock = false;
            }

            // don't actually block the url change
            return null;
        });
    };

    // Tab close/refresh (pageUnload) is not handled by catchUrlChanges,
    // it has to be handled here.
    // This handler cannot be async, so it is only going to work if the newDraftId is already available.
    handlePageUnload = (event: BeforeUnloadEvent): void => {
        this.flushSaveDraft();

        if (this.props.storage.data.bindingContext.isNew() && !this.savedDraftKey) {
            const newDraftId = this.entity.DocumentDraft?.Id;

            if (newDraftId) {
                this._ignoreNextBlock = true;
                this.redirectToDraftPage(newDraftId, true);
            }
        }
    };


    isDraftView = (): boolean => {
        return !!this.savedDraftKey;
    };

    shouldDisableDraftButton = (): boolean => {
        const isNew = this.props.storage.data.bindingContext.isNew();
        const isDirty = this.props.storage.isDirty(this.props.storage.data.bindingContext);

        return !isNew && !isDirty;
    };

    // => reset table manually
    resetFormTabsTable = (): void => {
        const activeTab = this.props.storage.getGroupStatus("Tabs");
        const tabDef = this.props.storage.data.definition?.groups?.find(group => group.id === "Tabs")?.tabs?.find(tab => tab.id === activeTab?.activeTab);

        if (tabDef?.table.id) {
            (SmartTableManager.getTable(tabDef?.table.id) as ISmartODataTableAPI)?.reset();
        }
    };

    handleSaveDraft = async (): Promise<void> => {
        await this.saveDraftAndApplyEffects();
    };

    flushSaveDraft = async (): Promise<void> => {
        await this.saveDraftDebounced.flush();
    };

    /** Changes url to "new" and presets the document with current data OR in draft view creates a new draft */
    handleCopy = async (): Promise<void> => {
        const { storage } = this.props;
        storage.setBusy(true);
        const copy = this.createEntityCopy({
            copyDataToNewDocument: true,
            excludeProps: [
                DocumentEntity.SymbolVariable, DocumentEntity.NumberTheirs,
                CorrectiveInvoiceReceivedEntity.ExternalCorrectedDocumentNumber
            ]
        });
        if (this.isDraftView()) {
            const draftId = await this.createNewDraft(copy);
            if (draftId) {
                setQueryParams({
                    history: storage.history,
                    newParams: { [QueryParam.DraftId]: draftId }
                });
                this.context.eventEmitter.emit(ContextEvents.RecalculateDrafts);
                this.props.onTableRefreshNeeded();
            }
        } else {
            storage.setCustomData({
                initialData: copy
            });
            this.props.onAdd?.();
        }
        this._removeBusyAfterUpdate = true;
    };

    // todo would be nice to have this in formStorage, so that it is not tied to view and can be called from anywhere
    // probably all the draft related methods should be in formStorage
    deleteDraft = async (draftId?: number): Promise<void> => {
        if (this._isDeletingDraft === true) {
            return;
        }
        this._isDeletingDraft = true;
        this.saveDraftDebounced.cancel();
        const newId = await this._savingDraftPromise;
        const idToRemove = draftId ?? this.entity[this.draftProp]?.Id ?? newId;
        if (idToRemove) {
            await this.props.storage.oData.getEntitySetWrapper(this.props.storage.data.definition.draftDef.draftEntitySet).delete(idToRemove);
            this.entity[this.draftProp] = null;
            this.forceUpdate(); // reload footer buttons
            this.context.eventEmitter.emit(ContextEvents.RecalculateDrafts);
        }
        this._isDeletingDraft = false;
    };

    handleDiscardChanges = async (): Promise<void> => {
        await this.deleteDraft();
        const bc = this.props.storage.data.bindingContext;
        if (bc.isNew()) {
            // !!! order matters
            // in draftView, discard changes acts like delete, so we need to reload table, but stay on draftTable
            const wasDraftView = this.isDraftView();
            wasDraftView && this.props.onTableRefreshNeeded();
            this.props.onCancel?.();
            wasDraftView && setQueryParams({
                history: this.props.storage.history,
                newParams: { [QueryParam.Drafts]: "true" }
            });
        } else {
            this.props.onTableRefreshNeeded({ refreshRowOnly: true, rowBc: this.getCorrectRowBc() });
            this.props.onCancel?.();
        }
    };

    getKey = (): string => {
        const draftProp = this.props.storage.data.definition.draftDef.draftProperty;

        return this.entity?.[draftProp]?.Id ?? this.savedDraftKey;
    };

    getDraftBc = (): BindingContext => {
        const key = this.getKey();
        return createBindingContext(this.draftEntitySet, this.props.storage.oData.metadata).addKey(key ?? NEW_ITEM_DETAIL, !key);
    };

    // saveDraftSync must not be async because savingDraftPromise check should be done synchronously
    // not to duplicate the new draft request.
    saveDraftSync = (updateTable = true): Promise<number> => {
        if (this.preventDraftSave) {
            return null;
        }
        const { storage } = this.props;
        // this is draft only entity - there is draft table on the left, entity has not been saved yet.
        const isDraftOnly = storage.data.bindingContext.isNew();

        const draftProp = storage.data.definition.draftDef.draftProperty;
        const key = this.getKey();

        if (this._savingDraftPromise && !key) {
            // if new draft is already saving, wait for it to end, to get the new draftId
            // we don't need to wait for it to end if we already have draftId - we just need to fire request with the last changes
            return this._savingDraftPromise.then(() => this.saveDraftSync(updateTable));
        }

        const documentTypeCode = this.documentTypeCode;
        // during evaluation of this function, row can be change and storage will have new data loaded
        // => copy everything from storage that we need to use, but do not use _.cloneDeep, because it will try copy all the data (html references) and can freeze everything
        const storageCopyData = {
            entity: { ...this.entity },
            draft: { ...storage.getCustomData().draft },
            draftEtag: storage.getCustomData().draftEtag,
            bindingContext: storage.data.bindingContext
        };

        const entity = this.entity;
        const draftBc = this.getDraftBc();

        this._savingDraftPromise = new Promise(async (resolve, reject) => {
            // first call prepareDataForSave and AFTER createEntityCopy, because createEntityCopy removes some unwanted properties
            const preparedData = await this.prepareDataForSave(entity, true);
            const draftData = this.createEntityCopy({
                includeAttachments: true,
                includeDocumentLinks: true,
                entity: preparedData,
                skipInvalidProps: true
            }) as IEntity;

            const batch: BatchRequest = storage.oData.batch();
            batch.beginAtomicityGroup("draft");

            if (draftBc.isNew()) {
                // remove copied Id from the document
                delete draftData.Id;
            }

            const navigationToEntity = storage.data.definition.draftDef.navigationFromDraft;
            const navigationToItem = storage.data.definition.draftDef.navigationToItem;

            draftData[navigationToEntity] = isDraftOnly ? null : {
                Id: entity.Id,
                _metadata: {
                    "": { type: `#Solitea.Evala.DomainModel.${documentTypeCode}` }
                }
            };

            draftData.DocumentTypeCode = documentTypeCode;
            draftData.Company = { Id: entity.Company?.Id ?? this.context.getCompanyId() };

            draftData.Items = (draftData.Items ?? []).map((item: IEntity): IEntity => {
                // add reference to original DocumentItem, so we can compare values with original ones
                if (item.Id && draftData[navigationToEntity]?.Id) {
                    item[navigationToItem] = {
                        Id: item.Id,
                        _metadata: {
                            "": { type: `#Solitea.Evala.DomainModel.${this._documentItemDomainType}` }
                        }
                    };
                }

                const boundDraftId = item[DRAFT_ITEM_ID_PATH];
                if (boundDraftId) {
                    item.Id = boundDraftId;
                }
                return item;
            });

            saveEntity({
                bindingContext: draftBc,
                entity: draftData,
                originalEntity: storageCopyData.draft,
                etag: storageCopyData.draftEtag,
                batch
            });

            if (draftData.Company.Id !== this.context.getCompanyId()) {
                // when user switch company before draft request is send, saving draft would fail or it would lead to
                // unexpected results (save the draft in different company, etc...).
                // todo: We should handle this in another way, for now, just prevent obvious errors
                return null;
            }

            try {
                const result = await batch.execute();

                if (!isBatchResultOk(result[0])) {
                    const error = result[0];

                    if (storage.isEtagMismatchError(error)) {
                        storage.handleEtagMismatchError().then(this.scrollPageUp);
                    } else {
                        storage.setFormAlert(getAlertFromError(error?.body as ODataError));
                    }

                    this.forceUpdate(this.scrollPageUp);

                    resolve(null);
                    return;
                }
                const savedDraft = result[0]?.body?.value;
                const draftId = result[0]?.body?.value?.Id;
                const draftEtag = result[0].body._metadata.etag;

                if (this._isDeletingDraft === true) {
                    resolve(draftId);
                    return;
                }

                storage.setCustomData({
                    draftEtag: draftEtag
                });

                const _activeRowHasChanged = () => {
                    const copiedEntity = storageCopyData.entity;
                    return (copiedEntity?.Id && copiedEntity?.Id !== this.entity.Id) ||
                        (copiedEntity?.[this.draftProp]?.Id && copiedEntity[draftProp].Id !== this.entity[this.draftProp]?.Id);
                };

                if (!_activeRowHasChanged()) {
                    const draftData = await storage.queryDraft(storage.data.mergedDefinition, draftId ?? key);

                    // we have to check again after async call if active row has not changed
                    if (!_activeRowHasChanged()) {
                        const expandedDraft: IEntity = draftData.draft;
                        storage.setCustomData({ draft: expandedDraft });

                        for (const item of (expandedDraft.Items || [])) {
                            const documentItem = storage.data.entity.Items?.find((docItem: IEntity) => {
                                return (isDefined(docItem.Id) && docItem.Id === item[navigationToItem]?.Id) || (isNotDefined(docItem.Id) && isNotDefined(docItem[DRAFT_ITEM_ID_PATH]) && docItem.Order === item[navigationToItem].Order);
                            });
                            if (documentItem) {
                                documentItem[DRAFT_ITEM_ID_PATH] = item.Id;
                            }
                        }

                        if (draftId) {
                            (this.entity[this.draftProp] as IDocumentDraftEntity) = {
                                Id: draftId,
                                DateLastModified: savedDraft.DateLastModified
                            };

                            // summary items should display current value - https://solitea-cz.atlassian.net/browse/DEV-14449

                            // BankTransactions/CashReceipts have Amount fields and we don't want to change them here
                            // we also want to keep header in sync with draft data only when it's pure draft entity (without saved entity),
                            // see DEV-14449 - value from the header should be in sync with table.
                            if (this._copyAmountFromDraft && isDraftOnly) {
                                amountFields.forEach(field => {
                                    // only apply new value if the value wasn't changed
                                    // while the draft was saving
                                    // e.g. in Corrective documents, TransactionAmount is applied from corrected document
                                    // and we don't want to override the value with old value from draft
                                    const prop = field as keyof E;

                                    if (storageCopyData.entity[prop] === this.entity[prop]) {
                                        this.entity[field as keyof E] = expandedDraft[field];
                                    }
                                });
                            }

                            if (draftBc.isNew()) {
                                // we need to reload footer buttons and disable send action
                                this.forceUpdate();
                            }
                        }
                    }
                }

                this._savingDraftPromise = null;
                resolve(draftId);
            } catch (err) {
                reject(err);
            }
        });

        this._savingDraftPromise.catch(() => {
            this._savingDraftPromise = null;
        });

        return this._savingDraftPromise.then((draftId) => {
            if (storageCopyData.bindingContext.isNew()) {
                this.context.eventEmitter.emit(ContextEvents.RecalculateDrafts);
            }
            storage.refresh();
            if (updateTable) {
                this.props.onTableRefreshNeeded?.({
                    refreshRowOnly: true,
                    rowBc: this.getCorrectRowBc()
                });
            }

            return draftId;
        });
    };

    getCorrectRowBc = (): BindingContext => {
        return this.isDraftView() ? this.getDraftBc() : this.props.storage.data.bindingContext;
    };

    saveDraftDebounced = debounce(() => {
        return this.saveDraftSync();
    }, SAVE_DRAFT_DELAY);

    /** Saves a draft of the form after the first change is made.
     * Subsequent changes will trigger a delayed save or a save when the user navigates away from the page.*/
    saveDraft = (): Promise<number> => {
        if (this.preventDraftSave) {
            return null;
        }

        const draftId = this.entity[this.draftProp]?.Id ?? this.savedDraftKey;

        if (!draftId && this.props.storage.data.bindingContext.isNew() && !this._savingDraftPromise) {
            // save draft without redirecting url, to prevent visual changes
            // url will will be replaced in history the moment user tries to navigate away
            return this.saveDraftSync();
        } else {
            return this.saveDraftDebounced();
        }
    };

    redirectToDraftPage = (draftId: number, replaceState?: boolean): void => {
        setQueryParams({
            history: this.props.storage.history,
            newParams: {
                [QueryParam.Drafts]: "true",
                [QueryParam.DraftId]: draftId.toString()
            },
            replaceState
        });
    };


    saveDraftAndApplyEffects = async (withoutBusy?: boolean): Promise<void> => {
        const { storage } = this.props;

        if (!withoutBusy) {
            storage.setBusy(true);
        }

        this.saveDraftDebounced.cancel();

        if (this._savingDraftPromise) {
            await this._savingDraftPromise;
        }

        try {
            const draftSaved = await this.saveDraftSync(false);

            if (draftSaved) {
                // https://solitea-cz.atlassian.net/browse/DEV-13662
                storage.clearAllErrors();
                storage.clearFormAlert();

                const bc = storage.data.bindingContext;

                if (bc.isNew() && !this.isDraftView()) {
                    this.redirectToDraftPage(this.entity[this.draftProp].Id);
                    if (!withoutBusy) {
                        this._removeBusyAfterUpdate = true;
                    }
                    // skip set busy -> false
                    return;
                } else {
                    this.props.onTableRefreshNeeded({ refreshRowOnly: true, rowBc: this.getCorrectRowBc() });
                }
            }
        } catch (error) {
            storage.setFormAlert(getAlertFromError(error));
            this.scrollPageUp();
        }

        if (!withoutBusy) {
            storage.setBusy(false);
        }
    };

    isSavedWithoutChanges = (): boolean => {
        const isNew = this.props.storage.data.bindingContext.isNew();
        const isChanged = this.props.storage.data.status?.isChanged;

        return !isNew && !this.entity[this.draftProp]?.Id && !isChanged;
    };

    shouldAllowCopy = (): boolean => {
        return this.props.storage.pageViewMode !== PageViewMode.FormReadOnly && (!this.props.storage.data.bindingContext.isNew() || this.isDraftView());
    };

    shouldDisableCopyButton = (): boolean => {
        return this.props.storage.data.disabled
            // most buttons should be disabled when the form is in editOverride mode
            || !!this.props.storage.getEditOverride()
            || (!this.isSavedWithoutChanges() && !this.isDraftView());
    };

    renderCustomFooterButtons = (): React.ReactElement => {
        return null;
    };

    createNewDraft = async (copy: IEntity): Promise<string> => {
        const storage = this.props.storage;
        const draftBc = createBindingContext(storage.data.definition.draftDef.draftEntitySet, storage.oData.metadata).addKey(NEW_ITEM_DETAIL, true);
        const batch: BatchRequest = storage.oData.batch();
        const data = await this.prepareDataForSave(this.entity, true);
        data.DocumentTypeCode = this.documentTypeCode;
        data.Company = { Id: this.context.getCompany().Id };

        delete data.PaymentDocumentDraft;

        batch.beginAtomicityGroup("draft");
        saveEntity({
            bindingContext: draftBc,
            entity: data,
            batch
        });
        const result = await batch.execute();
        return (result[0]?.body as ODataQueryResult)?.value?.Id;
    };

    async save(saveArgs?: Partial<ISaveArgs>): Promise<IFormStorageSaveResult> {
        // if draft save is in progress, wait for it to end, unless it might result to unexpected behavior due
        // to temporal entity processing in onAfterLoad (of the draft)
        await this._savingDraftPromise;
        return super.save(saveArgs);
    }

    createEntityCopy = (args: ICopyEntityArgs = {}): IDocumentExtendedEntity => {
        throw Error(`createEntityCopy must be specified in ${this.constructor.name}`);
    };

    prepareDataForSave = async (entity: IBankTransactionEntity, isDraft: boolean): Promise<E> => {
        throw Error(`prepareDataForSave must be specified in ${this.constructor.name}`);
    };

    renderSaveButtons(): ReactElement {
        throw Error(`renderSaveButtons must be specified in ${this.constructor.name}`);
    }

    renderButtons = (): React.ReactElement => {
        if (this.props.withoutButtons) {
            return <></>;
        }
        const { storage } = this.props;
        const isFormReadOnly = storage.isReadOnly;
        const isSavedWithoutChanges = this.isSavedWithoutChanges();

        return (
            <ButtonGroup wrap={"wrap"}>
                {this.isDeletable &&
                    <SmartFormDeleteButton storage={this.props.storage}
                                           onClick={this.handleDelete}/>
                }
                {!isFormReadOnly &&
                    <Button hotspotId={"formCancelDraft"}
                            onClick={this.handleDiscardChanges}
                        // allow in editOverride to cancel the edit mode
                            isDisabled={(storage.isDisabled || isSavedWithoutChanges) && !storage.getEditOverride()}
                            isTransparent>{storage.t(`Common:${this.isDraftView() ? "General.Delete" : "Form.DiscardChanges"}`)}</Button>
                }
                {this.renderCustomFooterButtons()}

                {!isFormReadOnly &&
                    <>
                        {this.shouldAllowCopy() && <Button hotspotId={"formCopy"}
                                                           onClick={this.handleCopy}
                                                           isDisabled={this.shouldDisableCopyButton()}
                                                           isTransparent>{storage.t("Document:Buttons.Copy")}</Button>}
                        {!this.preventDraftSave &&
                            <Button hotspotId={"formSaveDraft"}
                                    onClick={this.handleSaveDraft}
                                    isDisabled={this.shouldDisableDraftButton()}
                                    isTransparent>{storage.t("Document:Buttons.SaveDraft")}</Button>
                        }
                        {this.renderSaveButtons()}
                    </>
                }
            </ButtonGroup>
        );
    };
}

export default DraftFormView;