import styled from "styled-components/macro";
import { T_TITLE_4_NORMAL } from "../../../global.style";

export const StyledWriteLineGroup = styled.div`
    display: flex;
    align-items: center;
`;

export const WriteLineWrapper = styled.div`
  &:not(:first-child) {
    margin-left: 8px;
  }
  
  ${T_TITLE_4_NORMAL};
`;

export const ActionsWrapper = styled.div`
    display: flex;
    margin-left: 8px;
`;