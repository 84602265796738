export const BUSY_INDICATOR_DELAY = 1000;

export enum BusyIndicatorSize {
    L = "L",
    M = "M",
    XS = "XS"
}

export enum BusyIndicatorType {
    Normal = "Normal",
    BackgroundOnly = "BackgroundOnly",
    WithoutBackground = "WithoutBackground"
}