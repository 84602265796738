import { getDrillDownFilters } from "@components/drillDown/DrillDown.utils";
import { IncomingIcon, OutgoingIcon } from "@components/icon";
import { PairingFastEntryList } from "@components/pairingFastEntryList";
import { IGetValueArgs } from "@components/smart/FieldInfo";
import {
    AttachmentsAdditionalProperties,
    AttachmentsDef,
    ExchangeRatePerUnit,
    getCommonFilterDefs,
    getCommonTableColumnsDefs,
    withDisplayName
} from "@components/smart/GeneralFieldDefinition";
import { getFilterBarItemParsedValue } from "@components/smart/smartBankAccountFilter/SmartBankAccountFilter.utils";
import {
    FilterBarGroup,
    getDefaultFilterGroupDef,
    IFilterGroupDef
} from "@components/smart/smartFilterBar/SmartFilterBar.types";
import { IGroupRenderFn } from "@components/smart/smartFormGroup/SmartFormGroup";
import { TSmartODataTableStorage } from "@components/smart/smartTable/SmartODataTableBase";
import {
    CashReceiptEntity,
    CashReceiptIssuedEntity,
    CashReceiptReceivedEntity,
    EntitySetName,
    EntityTypeName,
    PaymentDocumentDraftEntity,
    PaymentDocumentItemDraftEntity,
    UserEntity
} from "@odata/GeneratedEntityTypes";
import { CompanyPermissionCode, DocumentTypeCode } from "@odata/GeneratedEnums";
import { getEnumNameSpaceName } from "@odata/GeneratedEnums.utils";
import { ICreateFilterStringSettings, IFormatOptions } from "@odata/OData.utils";
import { isAccountAssignmentCompany, isCashBasisAccountingCompany } from "@utils/CompanyUtils";
import { getFilterForDocumentDrafts } from "@utils/DraftUtils";
import { arrayInsert, isDefined } from "@utils/general";
import i18next from "i18next";
import { isEmpty } from "lodash";
import React from "react";

import { SmartCashBoxFilter } from "../../../components/smart/smartCashBoxFilter/SmartCashBoxFilter";
import { IAppContext } from "../../../contexts/appContext/AppContext.types";
import { BasicInputSizes, FieldType, IconSize, Sort, TextAlign, ValidatorType } from "../../../enums";
import { TValue } from "../../../global.types";
import { Model } from "../../../model/Model";
import { IFilterQuery, TableStorage } from "../../../model/TableStorage";
import BindingContext, { createPath } from "../../../odata/BindingContext";
import { getUtcDate } from "../../../types/Date";
import { IFormDef } from "../../../views/formView/Form";
import { FormStorage } from "../../../views/formView/FormStorage";
import { IChangedFilter, isDraftView, ISplitPageTableDef } from "../../../views/table/TableView.utils";
import { addAssetDef } from "../../asset/fixedAsset/FixedAsset.utils";
import { getAmountFilterDefs, paymentDocDateValidator } from "../../banks/bankTransactions/BankTransactions.utils";
import { _bankFormatter, CUSTOMIZE_BUTTON_ID } from "../../banks/bankTransactions/BankTransactionsDef";
import { getPairDef, PairAdditionalData } from "../../banks/Pair.utils";
import {
    getPaymentStatusSummaryItem,
    getPaymentStatusTabData,
    getPaymentStatusTableColumnDef,
    getPaymentTabsDef,
    isExchangeRateVisible,
    makeCbaDefAdjustments,
    PaymentStatusSummaryAdditionalData
} from "../../banks/Payments.utils";
import {
    draftNumberOursFormatter,
    getCurrencyUnit,
    readOnlyCurrencyItemFormatter
} from "../../documents/Document.utils";
import {
    DOCUMENT_DATE_CHANGE_DEBOUNCE,
    getDocumentStatusFilterDefinition,
    getUsedStatuses
} from "../../documents/DocumentDef";
import { setDefByEntityType } from "../../getDefByEntityType";
import {
    getNumberOursSummaryDef,
    getNumberRangeFieldDefs,
    NumberRangeAdditionalProperties
} from "../../numberRange/NumberRange.utils";
import { getItemBreadCrumbsText, IDefinition, IGetDefinition } from "../../PageUtils";
import { DocumentStatusLocalPath, StatusCodeEnumTranslations } from "../../reports/CommonDefs";
import { getCashBoxSelectDef, hasItems } from "./CashReceipts.utils";

const cashAmountFormatter = (value: TValue, args: IFormatOptions) => {
    return _bankFormatter({
        amount: args.entity?.Amount,
        transactionAmount: args.entity?.TransactionAmount,
        transactionCurrencyCode: args.entity?.TransactionCurrencyCode,
        currencyCode: args.entity?.CurrencyCode,
        useColor: true,
        showPositive: true,
        isPositive: args.entity?.DocumentType?.Code === DocumentTypeCode.CashReceiptReceived
    });
};

export const isPairFastEntryDisabled = (storage: FormStorage): boolean => {
    const isExVisible = isExchangeRateVisible(storage);

    const allIsSet = storage.data.entity.DateIssued && storage.data.entity.CashBox && isDefined(storage.data.entity.TransactionAmount)
        && (!isExVisible || isDefined(storage.data.entity.ExchangeRatePerUnit));

    return !allIsSet;
};

export const getCashReceiptsFormDef = (formControl: React.ComponentType<any>, isReceived: boolean, context: IAppContext): IDefinition => {
    const hasAccountAssignment = isAccountAssignmentCompany(context);
    const isCbaCompany = isCashBasisAccountingCompany(context);

    const target = isReceived ? [{ id: CashReceiptReceivedEntity.ReceivedFrom }, { id: CashReceiptReceivedEntity.ReceivedBy }] : [{ id: CashReceiptIssuedEntity.PaidBy }, { id: CashReceiptIssuedEntity.PaidTo }];
    const targetDef = isReceived ? { ReceivedFrom: {}, ReceivedBy: {} } : { PaidBy: {}, PaidTo: {} };
    const type = isReceived ? DocumentTypeCode.CashReceiptReceived : DocumentTypeCode.CashReceiptIssued;

    const form: IFormDef = {
        id: isReceived ? `${EntityTypeName.CashReceiptReceived}Form` : `${EntityTypeName.CashReceiptIssued}Form`,
        permissions: [CompanyPermissionCode.Bank],
        summary: [
            getNumberOursSummaryDef("Cash"),
            getPaymentStatusSummaryItem()
        ],
        draftDef: {
            draftProperty: CashReceiptEntity.PaymentDocumentDraft,
            draftEntitySet: EntitySetName.PaymentDocumentDrafts,
            navigationToItem: PaymentDocumentItemDraftEntity.PaymentDocumentItem,
            navigationFromDraft: PaymentDocumentDraftEntity.PaymentDocument,
            draftAdditionalProps: [{ id: "DateLastModified" }, { id: "Items/PaymentDocumentItem" }]
        },
        showChanges: true,
        additionalProperties: [
            { id: createPath(CashReceiptEntity.CreatedBy, "Id") },
            { id: CashReceiptEntity.PaymentDocumentDraft },
            ...AttachmentsAdditionalProperties,
            ...NumberRangeAdditionalProperties,
            ...PairAdditionalData,
            ...PaymentStatusSummaryAdditionalData,
            { id: "IsUnrelatedToBusiness" },
            { id: "TransactionCurrency/MinorUnit" },
            { id: "CashBox/BalanceSheetAccountNumberSuffix" }, { id: "CashBox/Name" }
        ],
        files: AttachmentsDef,
        translationFiles: getDefinitions.translationFiles,
        isDeletable: true,
        formControl,
        getItemBreadCrumbText: (storage: Model) =>
            getItemBreadCrumbsText(storage, i18next.t("Cash:CashReceipts.NewFormTitle"), storage.data.entity?.NumberOurs),
        title: i18next.t(`Cash:CashReceipts.${isReceived ? "SingTitle" : "SingIssuedTitle"}`),
        fieldDefinition: {
            ...getNumberRangeFieldDefs("Cash"),
            TransactionAmount: {
                isDisabled: hasItems,
                affectedFields: [{ id: BindingContext.localContext("ItemsHeader") }],
                label: i18next.t("Cash:CashReceipts.Amount"),
                validator: {
                    type: ValidatorType.PositiveNumber
                },
                formatter: readOnlyCurrencyItemFormatter,
                fieldSettings: {
                    unit: getCurrencyUnit
                },
                customizationData: {
                    dependents: [CashReceiptEntity.ExchangeRatePerUnit]
                }
            },
            CashBox: getCashBoxSelectDef(),
            Purpose: {
                isConfirmable: true,
                width: BasicInputSizes.XL,
                type: FieldType.EditableText
            },
            DateIssued: {
                defaultValue: () => {
                    return getUtcDate();
                },
                isDisabled: hasItems,
                fieldSettings: {
                    debouncedWait: DOCUMENT_DATE_CHANGE_DEBOUNCE
                },
                validator: {
                    type: ValidatorType.Custom,
                    settings: {
                        customValidator: paymentDocDateValidator
                    }
                }
            },
            ...targetDef,
            ExchangeRatePerUnit: {
                ...ExchangeRatePerUnit,
                isDisabled: hasItems,
                width: BasicInputSizes.S,
                defaultValue: "",
                label: i18next.t("Document:Form.CurrencyRate")
            },
            ...getPairDef(type, hasAccountAssignment)
        },
        groups: [{
            id: "payment",
            title: i18next.t("Cash:CashReceipts.Payment"),
            rows: [[{ id: "CashBox" }, { id: "DateIssued" }, { id: "TransactionAmount" }, { id: "ExchangeRatePerUnit" }, ...target], [{ id: "Purpose" }]]
        }, {
            id: "Pair",
            title: i18next.t("Banks:Pairing.PairForm"),
            lineItems: {
                collection: "Items",
                order: "Id",
                columns: [{
                    id: "Amount"
                }, {
                    id: "TransactionAmount"
                }, {
                    id: "LinkedDocument/NumberOurs"
                }],
                isReadOnly: (args: IGetValueArgs) => {
                    return args.data.IsUnrelatedToBusiness;
                },
                render: (args: IGroupRenderFn) => {
                    return <PairingFastEntryList
                        hasAccountAssignment={hasAccountAssignment}
                        groupId="Pair"
                        bindingContext={args.props.bindingContext}
                        storage={args.storage as FormStorage}
                        onChange={args.props.onChange}
                        isDisabled={isPairFastEntryDisabled.bind(null, args.storage as FormStorage)}
                        isReadOnly={args.props.isReadOnly}
                        onBlur={args.props.onBlur}/>;
                }
            }
        },
            getPaymentTabsDef(isCbaCompany, context)]
    };

    const itemsGroup = form.groups.find(g => g.id === "Pair");

    if (hasAccountAssignment) {
        itemsGroup.lineItems.columns = arrayInsert(itemsGroup.lineItems.columns, { id: "DateAccountingTransaction" }, 2);
        itemsGroup.lineItems.columns = arrayInsert(itemsGroup.lineItems.columns, { id: "AccountAssignmentSelection/AccountAssignment" }, 3);
    }

    if (isCbaCompany) {
        makeCbaDefAdjustments(form, itemsGroup);
    }

    const definition = {
        entitySet: isReceived ? EntitySetName.CashReceiptsReceived : EntitySetName.CashReceiptsIssued,
        form
    };

    if (isCbaCompany) {
        addAssetDef(definition);
    }

    return definition;
};

const cashBoxAccountFormatter = (val: TValue, args?: IFormatOptions) => {
    const cashBoxes = args.storage.context.getCashBoxes();
    const acc = cashBoxes.find(acc => acc.Id === val);
    if (acc) {
        return acc.Name;
    }

    return val as string;
};

export const getDefinitions: IGetDefinition = (context: IAppContext): IDefinition => {
    const hasAccountAssignment = isAccountAssignmentCompany(context);
    const filterBarDef: IFilterGroupDef[] = [{
        ...getDefaultFilterGroupDef(FilterBarGroup.Parameters),
        isExpanded: true,
        defaultFilters: ["CashBox"],
        clearOnVariantChange: true,
        customButtons: [{
            id: CUSTOMIZE_BUTTON_ID,
            title: i18next.t("Cash:CustomizationDialog.ButtonTitle"),
            iconName: "Settings"
        }],
        filterDefinition: {
            CashBox: {
                fieldSettings: {
                    displayName: "Name"
                },
                // after yup update, without useForValidation, the filter is not applied because of validation error
                // => useForValidation: false
                useForValidation: false,
                formatter: cashBoxAccountFormatter,
                filterName: "Id",
                type: FieldType.Custom,
                filter: {
                    buildFilter(item: IChangedFilter, settings: ICreateFilterStringSettings, storage: TableStorage): string | IFilterQuery {
                        const values = item?.value as [];

                        if (isDraftView(storage as TSmartODataTableStorage) || !item || isEmpty(values)) {
                            return "";
                        }

                        return `NOT(Cashbox/Id in (${values.join(", ")}))`;
                    },
                    customReadOnlyParsedValue: getFilterBarItemParsedValue
                },
                render: ({ props, storage }) => {
                    const hasDrilldownFilter = !!getDrillDownFilters()?.["CashBox"];

                    return <SmartCashBoxFilter
                        ref={storage.addRef}
                        bindingContext={props.parentProps.bindingContext}
                        onChange={props.parentProps.onChange}
                        storage={storage as TableStorage}
                        isDisabled={hasDrilldownFilter}>
                    </SmartCashBoxFilter>;
                }
            }
        }
    }, {
        ...getDefaultFilterGroupDef(FilterBarGroup.Filters),
        isValueHelp: true,
        defaultFilters: [
            "DocumentType",
            "DateIssued",
            "NumberOurs",
            "TransactionAmount",
            DocumentStatusLocalPath,
            "Purpose"
        ],
        filterDefinition: {
            ...withDisplayName("DocumentType"),
            DateIssued: {},
            NumberOurs: {},
            Purpose: { isValueHelp: false },
            ...getAmountFilterDefs(),
            ...getDocumentStatusFilterDefinition(getUsedStatuses(context, DocumentTypeCode.CashReceiptIssued)),
            ...getFilterForDocumentDrafts(true),
            ...getCommonFilterDefs()
        }
    }];

    const table: ISplitPageTableDef = {
        filterBarDef,
        ...getPaymentStatusTabData(),
        id: `${EntityTypeName.CashReceipt}Table`,
        draftDef: {
            draftEntitySet: EntitySetName.PaymentDocumentDrafts,
            draftFilter: `${PaymentDocumentDraftEntity.PaymentDocument}/Id eq null AND ${PaymentDocumentDraftEntity.DocumentTypeCode} in ('${DocumentTypeCode.CashReceiptReceived}', '${DocumentTypeCode.CashReceiptIssued}')`,
            draftProperty: CashReceiptEntity.PaymentDocumentDraft,
            draftPropsBlacklist: [CashReceiptEntity.PaymentDocumentDraft, CashReceiptEntity.PaymentStatus, CashReceiptEntity.PaymentStatusCode]
        },
        massEditableDef: {},
        initialSortBy: [
            { id: CashReceiptEntity.DateIssued, sort: Sort.Desc },
            { id: CashReceiptEntity.NumberOurs, sort: Sort.Desc }
        ],
        additionalProperties: [{
            id: createPath(CashReceiptEntity.LastModifiedBy, UserEntity.Name)
        }, {
            id: CashReceiptEntity.DateLastModified
        }, {
            id: CashReceiptEntity.PaymentDocumentDraft
        }],
        columns: [
            CashReceiptEntity.NumberOurs,
            CashReceiptEntity.DocumentType,
            CashReceiptEntity.DateIssued,
            CashReceiptEntity.CashBox,
            CashReceiptEntity.TransactionAmount,
            CashReceiptEntity.PaymentStatus,
            CashReceiptEntity.Purpose
        ],
        columnDefinition: {
            ...getPaymentStatusTableColumnDef(),
            DateIssued: {
                label: i18next.t("Common:General.Issued")
            },
            DateCreated: getCommonTableColumnsDefs().DateCreated,
            NumberOurs: {
                formatter: draftNumberOursFormatter(EntitySetName.PaymentDocumentDrafts, CashReceiptEntity.PaymentDocumentDraft)
            },
            CashBox: {
                fieldSettings: {
                    displayName: "Name"
                },
                label: i18next.t("Cash:CashBoxes.SingleTitle")
            },
            Purpose: {},
            DocumentType: {
                textAlign: TextAlign.Center,
                formatter: (val: TValue) => {
                    if (val === DocumentTypeCode.CashReceiptIssued) {
                        const title = i18next.t("Banks:Transactions.Out");
                        return {
                            tooltip: title,
                            value: <OutgoingIcon width={IconSize.M} title={title}/>
                        };
                    }

                    const title = i18next.t("Banks:Transactions.In");
                    return {
                        tooltip: title,
                        value: <IncomingIcon width={IconSize.M} title={title}/>
                    };
                }
            },
            TransactionAmount: {
                label: i18next.t("Banks:Transactions.Converted"),
                additionalProperties: [{ id: "/CurrencyCode" }, { id: "/TransactionCurrencyCode" },
                    { id: "/DocumentType" }, { id: "/ExchangeRatePerUnit" }, { id: "/Amount" }],
                formatter: cashAmountFormatter
            },
            Amount: {
                label: i18next.t("Banks:Transactions.Amount")
            }
        },
        title: i18next.t("Cash:CashReceipts.Title")
    };

    if (hasAccountAssignment) {
        table.columnDefinition["CashBox/BalanceSheetAccountNumberSuffix"] = {
            label: i18next.t("Cash:CashBoxes.AccountTitle"),
            formatter: (value: TValue) => {
                return `211.${value}`;
            }
        };
    }
    return {
        entitySet: EntitySetName.CashReceipts,
        table
    };
};

getDefinitions.translationFiles = ["Cash", "Banks", "Common", "Document", "Components", "NumberRange", "Error", "Enums", "Categories", "FixedAsset", getEnumNameSpaceName(EntityTypeName.CbaCategoryTaxImpact), ...StatusCodeEnumTranslations];
setDefByEntityType(EntityTypeName.CashReceipt, getDefinitions);
