import { addCompanyIdToUrl } from "../../contexts/appContext/AppContext.utils";
import { EXPORT_ALL_URL, EXPORT_URL } from "../../constants";
import { saveFileFromUrl } from "@utils/FileStorage";
import { ExportRangeCode, ExportResultTypeCode, ExportTypeCode } from "@odata/GeneratedEnums";
import { IAppContext } from "../../contexts/appContext/AppContext.types";

/**
 * Creates data export from application according to params.
 * @param contextOrCompanyId
 * @param typeCode
 * @param resultTypeCode
 * @param rangeCode
 * @param includeAttachments
 */
export function createExport(typeCode: ExportTypeCode, resultTypeCode: ExportResultTypeCode, rangeCode: ExportRangeCode, includeAttachments: boolean, contextOrCompanyId: IAppContext | number): void {
    const queryParams = {
        typeCode, resultTypeCode, rangeCode,
        includeAttachments: includeAttachments.toString()
    };
    const fileUrl = addCompanyIdToUrl(`${EXPORT_URL}?${new URLSearchParams(queryParams)}`, contextOrCompanyId);

    saveFileFromUrl(fileUrl);
}

/**
 * Creates data export from application for All companies, downloads data to one zip file.
 */
export function createSingleFileExportOfAllData(): void {
    // ExportResultTypeCode.SingleFile, ExportRangeCode.All, includeAttachments: false
    // todo: do we want to sey the params explicitly?
    saveFileFromUrl(EXPORT_ALL_URL);
}