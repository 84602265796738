import { DocumentFormViewForExtend } from "../DocumentFormView";
import { IDocumentCustomData, IDocumentExtendedEntity  } from "../DocumentInterfaces";
import { WithConfirmationDialog, withConfirmationDialog } from "@components/dialog/withConfirmationDialog";
import { withPermissionContext } from "../../../contexts/permissionContext/withPermissionContext";
import { DocumentLinkTypeCode, DocumentTypeCode } from "@odata/GeneratedEnums";
import { WithDomManipulator, withDomManipulator } from "../../../contexts/domManipulator/withDomManipulator";
import { ISmartFastEntriesActionEvent } from "@components/smart/smartFastEntryList";
import { ADD_CLEARING_ITEM } from "./InternalDocumentDef";
import { AccountAssignmentDialog } from "../../accountAssignment/AccountAssignmentDialog";
import React, { ReactElement } from "react";
import Dialog from "../../../components/dialog/Dialog";
import DialogPage from "../../DialogPage";
import { PageViewMode } from "../../../enums";
import { getDefinitions } from "./InternalDocumentPairDef";
import { StorageModel } from "../../../model/StorageModel";
import InternalDocumentPairTableView from "./InternalDocumentPairTableView";
import { IEntity } from "@odata/BindingContext";
import { cloneDeep } from "lodash";
import { DocumentEntity, IInternalDocumentEntity, InternalDocumentEntity } from "@odata/GeneratedEntityTypes";
import { ISmartFieldChange } from "@components/smart/smartField/SmartField";
import { isNotDefined, roundToDecimalPlaces } from "@utils/general";
import { IFormViewProps } from "../../../views/formView/FormView";
import { WithMinorAssetPairing } from "../../asset/minorAsset/withMinorAssetPairing";
import { WithTimeResolution } from "../extensions/timeResolution/withTimeResolution";
import { WithAccruals } from "../extensions/accruals/withAccruals";
import { isCashBasisAccountingCompany } from "@utils/CompanyUtils";
import { WithPromisedComponent, withPromisedComponent } from "@components/dialog/withPromisedComponent";

export enum IDItemPairingDataValues {
    PairedDialogOpened = "pairedDialogOpened",
}

interface IInternalDocumentCustomData extends IDocumentCustomData {
    pairedDialogOpened?: boolean;
}

interface IInternalDocumentFormViewProps extends IFormViewProps<IDocumentExtendedEntity, IInternalDocumentCustomData>, WithConfirmationDialog,
    WithMinorAssetPairing, WithTimeResolution, WithAccruals, WithDomManipulator, WithPromisedComponent {
}


class InternalDocumentFormView extends DocumentFormViewForExtend<IInternalDocumentFormViewProps> {
    documentTypeCode = DocumentTypeCode.InternalDocument;

    get datePropForNumberRange(): string {
        if (isCashBasisAccountingCompany(this.context)) {
            return InternalDocumentEntity.DateCbaEntry;
        }
        return DocumentEntity.DateAccountingTransaction;
    }


    prepareSavedAccounts = () => {
        // override document form => we don't want this to be called for InternalDocument
    };

    handleCustomLineItemAction = async (args: ISmartFastEntriesActionEvent): Promise<void> => {
        if (args.customActionType === ADD_CLEARING_ITEM) {
            this.props.storage.setCustomData({ pairedDialogOpened: true });
            this.props.storage.refresh();
        }
    };

    handleClosePairing = () => {
        const { storage } = this.props;
        storage.setCustomData({ [IDItemPairingDataValues.PairedDialogOpened]: false });
        storage.clearEmptyLineItems("Items");
        storage.refresh();
    };

    handleLineAmountChange = (args: ISmartFieldChange) => {
        if (args.bindingContext.getPath() === "Amount") {
            const bc = args.bindingContext.getParent();
            const item = this.props.storage.getValue(bc);
            const exchangeRate = item.LinkedDocument?.ExchangeRatePerUnit ?? 1;
            const transValue = roundToDecimalPlaces(2, Number(args.value) / exchangeRate);

            this.props.storage.setValue(bc.navigate("TransactionAmount"), transValue);
        }
    };

    handleLineItemsChange(args: ISmartFieldChange): void {
        super.handleLineItemsChange(args);

        this.handleLineAmountChange(args);
    }

    renderClearingDialog = (): ReactElement => {
        const storage = this.props.storage;

        if (!storage.getCustomData().pairedDialogOpened) {
            return null;
        }
        return <Dialog
            isEditableWindow
            onConfirm={null}
            onClose={this.handleClosePairing}>
            <DialogPage
                pageViewMode={PageViewMode.FormReadOnly}
                rootStorage={storage as StorageModel}
                tableView={InternalDocumentPairTableView}
                getDef={getDefinitions}/>
        </Dialog>;
    };

    onBeforeSave(data: IInternalDocumentEntity): IEntity {
        const entity = cloneDeep(data);
        // don't change DocumentLinks of other types, we just manage InternalDocumentClearing here
        const documentLinks = (entity.DocumentLinks ?? []).filter(link => link.TypeCode !== DocumentLinkTypeCode.InternalDocumentClearing);

        for (const item of entity.Items) {
            if (item.LinkedDocument?.Id) {
                const documentLink = entity.DocumentLinks?.find(link => link.TargetDocument?.Id === item.LinkedDocument.Id);

                if (documentLink) {
                    documentLinks.push({
                        ...documentLink,
                        TransactionAmount: item.TransactionAmount
                    });
                } else {
                    documentLinks.push({
                        TypeCode: DocumentLinkTypeCode.InternalDocumentClearing,
                        TransactionAmount: item.TransactionAmount,
                        TargetDocument: item.LinkedDocument
                    });
                }
            }

            item.AmountNet = item.Amount;
            item.TransactionAmountNet = item.TransactionAmount;

            if (isNotDefined(item.TransactionAmountVat)) {
                item.TransactionAmountVat = 0;
            }
        }

        entity.DocumentLinks = documentLinks;

        return entity;
    }

    render() {
        if (!this.isReady()) {
            return null;
        }

        return (
            <>
                {this.renderForm()}
                <AccountAssignmentDialog storage={this.props.storage}
                                         onChange={this.handleChange}
                                         onTemporalChange={this.handleTemporalChange}
                                         onConfirm={this.updateItemsVat}/>
                {this.renderCustomCbaCategoryDialog()}
                {this.renderVatDialog()}
                {this.renderClearingDialog()}
            </>
        );
    }
}

export default withPromisedComponent(withConfirmationDialog(withPermissionContext(withDomManipulator(InternalDocumentFormView))));
