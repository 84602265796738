import { TRecordString, TValue } from "../global.types";
import { EntityType, Metadata, Type } from "@evala/odata-metadata/src";
import i18next from "i18next";
import { logger } from "@utils/log";
import { ODATA_API_URL } from "../constants";
import { ODataEntityMetadata } from "./GeneratedEntityTypes";
import { BackendErrorCode, HTTPStatusCode } from "../enums";
import { getUtcDate, getUtcDayjs } from "../types/Date";
import { IValidationMessage, ODataError, ODataInnerError, ODataPropertyValue } from "@odata/Data.types";

export interface IBackendErrorObject {
    code: BackendErrorCode;
    message: string;
    innererror: {
        message: string;
        stacktrace: string;
        type?: string;
    };
    validationMessages?: IValidationMessage[];
}

export function isPermissionErrorCode(code: string | BackendErrorCode): boolean {
    return [BackendErrorCode.PermissionError, BackendErrorCode.PermissionMissing].includes(code as BackendErrorCode);
}

export function parseError(dataObject: { error?: IBackendErrorObject }): ODataError {
    function replaceWinNl(s: string) {
        return s.replace(/\r\n/g, "\n");
    }

    const error = dataObject.error;
    if (!error) {
        return null;
    }
    let innerError = null;
    if (error.innererror) {
        let stackTrace = error.innererror.stacktrace;
        let stackTraceArray: string[] = [];

        if (stackTrace) {
            stackTrace = replaceWinNl(stackTrace);
            stackTraceArray = stackTrace.split("\n");
        }
        innerError = new ODataInnerError(error.innererror.type, replaceWinNl(error.innererror.message), stackTraceArray);
    }

    const validationMessages = error.validationMessages?.map((valMess: IValidationMessage) => {
        const key = `Error:${valMess.code}`;

        return {
            ...valMess,
            message: i18next.exists(key) ? i18next.t(key, valMess.messageParameters) : valMess.message
        };
    });

    return new ODataError(error.code, replaceWinNl(error.message), innerError, validationMessages);
}

function getBEErrorCodeFromHTTPStatus(status: HTTPStatusCode): BackendErrorCode {
    switch (status) {
        case HTTPStatusCode.Forbidden:
            return BackendErrorCode.PermissionError;
        case HTTPStatusCode.Unauthorized:
            return BackendErrorCode.NoSession;
        case HTTPStatusCode.GatewayTimeout:
            return BackendErrorCode.GatewayTimeout;
        default:
            return null;
    }
}

/**
 * Method parses response.
 *  - if response is ok, it returns parsed json / text according to response content type
 *  - if response is not ok, calls custom handler
 *  - if custom handler does not return error, tries to use common handlers defined in the method
 *  - otherwise fallbacks to generic error
 * @param response
 * @param customErrorHandler
 */
export async function parseResponse<T = unknown>(response: Response, customErrorHandler?: (error: IBackendErrorObject) => ODataError): Promise<ODataError | T> {
    const contentType = response.headers?.get("Content-Type");
    const isJSON = contentType?.includes("application/json");

    const _getContents = () => {
        try {
            return isJSON ? response.json() : response.text();
        } catch (e) {
            logger.error("Error parsing response", e);
        }
        return (isJSON ? {} : "") as T;
    };

    const contents = await _getContents();
    if (response.ok) {
        // success response
        return contents;
    }

    const error = contents?.error ? contents.error as IBackendErrorObject : null;

    if (customErrorHandler) {
        const customError = customErrorHandler(error);
        if (customError) {
            // if the error is handled by caller, return it
            return customError;
        }
    }
    // common error handlers
    switch (response.status) {
        case HTTPStatusCode.Forbidden:
        case HTTPStatusCode.GatewayTimeout:
            // timeout doesn't return any response => create custom error to show user what's happening
            const code = getBEErrorCodeFromHTTPStatus(response.status);
            return new ODataError(code, i18next.t(`Common:Errors.${code}`), null);
        default:
            return parseError(contents as { error: IBackendErrorObject });
    }
}

function parseSimpleType(type: string, value: TValue): ODataPropertyValue {
    if (value === null) {
        return null;
    }
    switch (type) {
        case "Date":
        case "DateTimeOffset":
            return getUtcDate(value as string);
        case "TimeOfDay":
            if (!value) {
                return null;
            }
            const [hours, minutes] = (value as string).split(":");
            const date = getUtcDayjs().set("hour", parseInt(hours)).set("minute", parseInt(minutes));
            return date.toDate();
        case "Byte":
        case "SByte":
        case "Int16":
        case "Int32":
        case "Int64":
        case "Single":
        case "Double":
        case "Decimal":
            switch (value) {
                case "-INF":
                    return -Infinity;
                case "INF":
                    return Infinity;
                case "NaN":
                    return NaN;
                default:
                    return value as number;
            }
        default:
            return value as string;
    }
}

function parseComplexType(obj: Record<string, TRecordString | string>, metadata: Metadata, type: Type) {
    const resultObj: Record<string, TValue | object> = {};
    const entity = metadata.entities[type.getFullName()];
    for (const key of Object.keys(obj)) {
        const property = entity.getProperty(key);
        const type = property.getType();
        if (type.getNamespace() === "Edm") {
            resultObj[key] = parseSimpleType(type.getName(), obj[key] as string);
        } else {
            resultObj[key] = parseComplexType(obj[key] as TRecordString, metadata, type);
        }
    }
    return resultObj;
}

function parseResultObject(obj: Record<string, any> | Record<string, any>[], metadata: Metadata, currentPath: string, withoutLogs?: boolean): Record<string, any> | Record<string, any>[] {
    if (Array.isArray(obj)) {
        return obj.map(o => parseResultObject(o, metadata, currentPath, withoutLogs));
    } else if (currentPath.startsWith("Edm.")) { // sometimes @odata.context has Edm type instead of property path
        return obj;
    } else {
        const resultMetadata: Record<string, Record<string, any>> = {};
        const resultObj: Record<string, any> = {};

        if (obj) {
            let entity: EntityType = metadata.entities[obj["@odata.type"]?.slice(1)];

            if (!entity) {
                try {
                    entity = metadata.getTypeForPath(currentPath);
                } catch (e) {
                    // currentPath can include action (e.g. DocumentDrafts(5)/ExtractIsdoc)
                    // => try to remove the last part and parse entity path again (just DocumentDrafts(5))
                    const lastSlashIndex = currentPath.lastIndexOf("/");

                    entity = metadata.getTypeForPath(currentPath.slice(0, lastSlashIndex));
                }
            }

            for (const key of Object.keys(obj)) {
                const indexOfAtOdata = key.indexOf("@odata.");
                const indexOfAtEvala = key.indexOf("@evala.");
                const index = indexOfAtOdata >= 0 ? indexOfAtOdata : indexOfAtEvala;

                if (index >= 0) {
                    const prop = key.substring(0, index);
                    const metadataProp = key.substring(index + 7);

                    if (!resultMetadata[prop]) {
                        resultMetadata[prop] = {};
                    }
                    resultMetadata[prop][metadataProp] = obj[key];
                } else {
                    const property = entity.getProperty(key);
                    if (!property) {
                        if (!withoutLogs) {
                            logger.error("Type for property " + key + " not found.");
                        }
                        continue;
                    }
                    const type = property.getType();
                    if (type.getNamespace() === "Edm") {
                        resultObj[key] = parseSimpleType(type.getName(), obj[key]);
                    } else if (property.isNavigation()) {
                        const nextPath = currentPath + "/" + entity.getProperty(key).getName();
                        resultObj[key] = parseResultObject(obj[key], metadata, nextPath, withoutLogs);
                    } else {
                        resultObj[key] = parseComplexType(obj[key], metadata, type);
                    }
                }
            }
        }

        if (Object.keys(resultMetadata).length > 0) {
            resultObj._metadata = resultMetadata;
        }

        return resultObj;
    }
}

export interface ODataResponse {
    // todo better interface
    [key: string]: any;
}

export interface ODataQueryResult<T = any> {
    value?: T;
    _metadata?: ODataEntityMetadata;
}

/** withoutLogs is for audit trail witch contains some weird data that are not in the odata entities.
 * Backend probably dumps the whole table, not the odata entity. Use withoutLogs to prevent the substantial amount of
 * logging requests that would otherwise be sent when audit trail is opened.*/
export function parseQueryResult<T>(data: ODataResponse, metadata: Metadata, currentPath: string, withoutLogs?: boolean): ODataQueryResult<T> {
    const result: any = {};
    const directValues: {
        [key: string]: any
    } = {};
    const resultMetadata: ODataEntityMetadata = {};

    if (currentPath) {
        currentPath = currentPath.split("?")[0].replace(`${ODATA_API_URL}/`, "");
    }

    if (Array.isArray(data)) {
        result.value = data;
    } else {
        for (const [key, value] of Object.entries(data)) {
            if (key === "value") {
                result.value = value as TValue;
            } else if (key.startsWith("@odata.") || key.startsWith("@evala.")) {
                (resultMetadata as any)[key.substring(7)] = value;
            } else {
                directValues[key] = value;
            }
        }
        if (!result.value) {
            result.value = directValues;
        }
    }

    if (Object.keys(resultMetadata).length > 0) {
        result._metadata = resultMetadata;
    }
    if (result._metadata || currentPath) {
        // let context: Record<string, any> = parseContextTree(result._metadata.context);
        // we don't need to parse the whole contextTree (and use context.property instead of currentPath)
        // because we only used context.property anyway and we know this from the request url
        result.value = parseResultObject(result.value, metadata, currentPath, withoutLogs);
    }
    return result;
}

export { ODataError };
