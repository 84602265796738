import styled, { css, DefaultTheme } from "styled-components/macro";
import { IconButton } from "@components/button";
import {
    getDisabledStyle,
    getFocusBorderElement,
    LocalStackOrder,
    T_PLAIN_small,
    T_PLAIN_small_hig,
    T_TITLE_5,
    T_TITLE_6
} from "../../global.style";
import Avatar from "../../components/avatar/Avatar";
import { AvatarHover } from "@components/avatar";
import { ellipsis, grayscale, transparentize } from "polished";
import View from "../../views/View";

export const ViewStyledCompanyDashboard = styled(View)``;

export const StyledCompanyTile = styled.div<{
    _color: keyof DefaultTheme;
    _isSelected: boolean;
    _isGray: boolean;
    _isWithoutPointer: boolean;
}>`
    outline: none;
    ${getFocusBorderElement({ offset: -5 })};

    width: 211px;
    height: 211px;
    flex: 0 0 auto;

    display: flex;
    padding: 20px;

    flex-direction: column;
    cursor: ${props => props._isWithoutPointer ? "default" : "pointer"};

    border-radius: 3px;
    border: solid 3px ${props => !props._isGray ? props.theme[props._color] : transparentize(0.5, grayscale(props.theme[props._color]))};
    background-color: ${props => {
        if (props._isSelected && props._isGray) {
            return props.theme.C_BG_INACTIVE_COMPANY;
        } else if (props._isSelected) {
            return props.theme.C_ACT_hover_without_opacity;
        } else if (props._isGray) {
            return transparentize(props.theme.INACTIVE_COMPANY_transparentize, props.theme.C_BG_stripes);
        }

        return "";
    }};

    position: relative;
`;

export const CompanyTileAvatar = styled(Avatar)<{
    _isWithoutHover: boolean;
}>`
    ${props => !props._isWithoutHover && css`
        ${StyledCompanyTile}:hover &:after {
            ${AvatarHover}
        }
    `}
`;

export const Content = styled.div<{
    hasAction: boolean;
    isDisabled: boolean;
}>`
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;

    ${props => getDisabledStyle(props.isDisabled)}
    pointer-events: ${props => props.hasAction ? "none" : "all"};
`;


export const Header = styled.div`
    position: relative;
    display: inline-block;
`;

export const AvatarButton = styled(IconButton)`
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translate(50%, 30%);
`;
export const Body = styled.div`
    margin-top: 15px;
    width: 100%;
    text-align: center;
`;
export const BoldLabel = styled.div<{
    _color?: keyof DefaultTheme;
    _isGray: boolean;
}>`
    ${T_TITLE_5};
    color: ${props => !props._isGray ? props.theme[props._color] : transparentize(props.theme.INACTIVE_COMPANY_transparentize, grayscale(props.theme[props._color]))};
    letter-spacing: 0.12px;

    ${ellipsis()};
    display: block;
`;

export const LegalNumber = styled.span`
    ${T_TITLE_6};
    letter-spacing: 0.12px;
    margin-top: 2px;
    ${ellipsis()};
    cursor: text;
    align-self: center;
`;

export const Footer = styled.div`
    ${T_PLAIN_small};
    ${ellipsis()};
    margin-top: auto;
`;

export const DeleteRequestedText = styled.div<{
    _color?: keyof DefaultTheme;
}>`
    ${T_PLAIN_small_hig};
    color: ${props => transparentize(props.theme.INACTIVE_COMPANY_transparentize, grayscale(props.theme[props._color]))};
    text-align: center;
    margin-top: auto;
`;

export const CompanyActionWrapper = styled.div`
    position: absolute;
    top: 6px;
    left: 6px;
    z-index: ${LocalStackOrder.AboveContent};
`;
