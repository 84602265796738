import styled from "styled-components/macro";

export const StyledTextViewer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
`;

export const FileText = styled.div`
    white-space: pre-wrap;
    height: 100%;
    overflow: auto;
`;