import React from "react";
import { StyledDashboardTile } from "./Dashboard.styles";
import { DashboardTileType, IDashboardTileComponentProps, TDashboardTileInfo } from "./Dashboard.types";
import InfoTile from "../tiles/infoTile/InfoTile";
import TableTile from "../tiles/tableTile";
import LinkTile from "../tiles/linkTile";
import ChartTile from "../tiles/chartTile/ChartTile";
import TestIds from "../../testIds";

interface IProps {
    key: string;
    info: TDashboardTileInfo;
    inEditMode?: boolean;
}

const DashboardTile: React.FC<IProps> = React.forwardRef((props, ref: React.Ref<HTMLDivElement>) => {
    const { info, inEditMode, ...passProps } = props;

    let content: React.ReactNode;
    let Component: React.ComponentType<IDashboardTileComponentProps<any>>;

    switch (info.type) {
        case DashboardTileType.Custom:
            Component = info.component;
            break;
        case DashboardTileType.Info:
            Component = InfoTile;
            break;
        case DashboardTileType.Table:
            Component = TableTile;
            break;
        case DashboardTileType.Link:
            Component = LinkTile;
            break;
        case DashboardTileType.Chart:
            Component = ChartTile;
            break;
            // default:
            //     content = (
            //         <StyledDashboardTileBackground $backgroundColor={info.backgroundColor}>
            //             <DashboardTileHeading>{info.title}</DashboardTileHeading>
            //         </StyledDashboardTileBackground>
            //     );
            //     break;
    }

    if (Component) {
        content = <Component inEditMode={inEditMode} info={info}/>;
    }

    return (
            <StyledDashboardTile ref={ref} {...passProps} className="dragHandle" data-testid={TestIds.DashboardTile}>
                {content}
            </StyledDashboardTile>
    );
});

export default DashboardTile;