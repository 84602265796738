import React, { useContext } from "react";

/** Use to easily create HOC wrapper injecting context provider */
export function withContextFactory<WithProps>(contextObj: React.Context<any>, injectPropName: string) {
    return <P extends WithProps>(Component: React.ComponentType<P>): React.ComponentType<Omit<P, keyof WithProps>> => {
        return React.forwardRef((props: Omit<P, keyof WithProps>, ref) => {
            const context = useContext(contextObj);

            return (
                <Component ref={ref}
                           {...{ [injectPropName]: context }}
                           {...props as P}/>
            );
        }) as unknown as React.ComponentType<Omit<P, keyof WithProps>>;
    };
}