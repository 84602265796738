import React from "react";
import { StyledToken } from "./Token.styles";
import { TCellValue } from "../table";
import TestIds from "../../testIds";
import Tooltip from "../tooltip";
import { DescriptionSeparator, InputDescriptionText } from "../inputs/select/Select.styles";
import { TabIndex } from "@utils/general";
import { THistoryLocation } from "../drillDown/DrillDown.utils";
import Clickable from "../clickable";

export interface ITokenProps {
    title: string;
    color?: string;
    description?: string;
    id?: string;
    /** If given, selected value is rendered as clickable link */
    link?: THistoryLocation;
    isSelected?: boolean;
    isRound?: boolean;
    customTooltip?: string;
    // true by default
    onlyShowWhenChildrenOverflowing?: boolean;
    onClick?: (id: string, e: React.MouseEvent) => void;
    onKeyDown?: (e: React.KeyboardEvent) => void;
    className?: string;
    style?: React.CSSProperties;
}

export const tableTokenFormatter = (title: string, color: string): TCellValue => {
    if (!title) {
        return undefined as TCellValue;
    }

    const instance = color ? (
        <Token
            color={color}
            title={title}
        />) : title;

    return { tooltip: title, value: instance };
};

export default class Token extends React.PureComponent<ITokenProps> {
    static defaultProps: Partial<ITokenProps> = {
        onlyShowWhenChildrenOverflowing: true
    };

    handleClick = (e: React.MouseEvent): void => {
        this.props.onClick?.(this.props.id, e);
    };

    handleKeyDown = (e: React.KeyboardEvent): void => {
        this.props.onKeyDown?.(e);
    };

    getTooltipText = (): string => {
        if (this.props.customTooltip) {
            return this.props.customTooltip;
        }

        let tooltip = this.props.title;

        if (this.props.description) {
            tooltip += `${DescriptionSeparator}${this.props.description}`;
        }

        return tooltip;
    };

    renderTitle = (): React.ReactNode => {
        if (!this.props.link) {
            return this.props.title;
        }

        return (
            <Clickable link={this.props.link}>
                {this.props.title}
            </Clickable>
        );
    };

    render() {
        return (
            <Tooltip onlyShowWhenChildrenOverflowing={this.props.onlyShowWhenChildrenOverflowing}
                     ignoreHeightOverflow
                     content={this.getTooltipText()}>
                {(ref) => {
                    return (
                        <StyledToken _color={this.props.color}
                                     isLabel={!!this.props.color}
                                     isSelectable={!!this.props.onClick}
                                     isRound={this.props.isRound}
                                     onClick={this.handleClick}
                                     onKeyDown={this.handleKeyDown}
                                     tabIndex={this.props.onKeyDown ? TabIndex.NormalOrder : TabIndex.Disabled}
                                     selected={this.props.isSelected}
                                     ref={ref}
                                     data-testid={TestIds.Token}
                                     className={this.props.className}
                                     style={this.props.style}>
                            {this.renderTitle()}
                            {!this.props.description ? null :
                                <InputDescriptionText>
                                    {DescriptionSeparator}{this.props.description}
                                </InputDescriptionText>
                            }
                        </StyledToken>
                    );
                }}
            </Tooltip>
        );
    }
}
