import styled from "styled-components/macro";
import { VIEW_PADDING_VALUE } from "../../views/View.styles";
import { SmartHeaderStyled } from "../../views/formView/FormView.styles";

export const TicketsFormViewStyled = styled.div`
    position: relative;
    padding: ${VIEW_PADDING_VALUE}px 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: 100%;
    overflow-x: auto;
`;

export const SmartHeaderTicketsStyled = styled(SmartHeaderStyled)`
    padding: 0 ${VIEW_PADDING_VALUE}px;
`;
