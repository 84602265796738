import React, { useCallback, useContext } from "react";
import { getCompanyLogoUrl } from "@utils/CompanyUtils";
import { AvatarStyledCompany } from "./Company.styles";
import { AvatarSize } from "../../enums";
import { AvatarButton } from "./CompanyDashboard.styles";
import { EditIcon } from "@components/icon";
import { WriteLineWrapper } from "@components/navigation/NavDashboard.styles";
import { FormStorage } from "../../views/formView/FormStorage";
import { PermissionContext } from "../../contexts/permissionContext/PermissionContext";
import { GeneralPermissionCode } from "@odata/GeneratedEnums";
import { ICompanyEntity } from "@odata/GeneratedEntityTypes";
import { ICompanyFormCustomData } from "./Company.utils";

interface IProps {
    storage: FormStorage<ICompanyEntity, ICompanyFormCustomData>;
    fieldElement: React.ReactNode;
}

const CompanyFormAvatarWithName: React.FC<IProps> = (props) => {
    const { storage, fieldElement } = props;

    const permissionContext = useContext(PermissionContext);

    const company = storage.data.entity;
    const logoUrl = getCompanyLogoUrl(company);

    const isDisabled = !permissionContext.generalPermissions.has(GeneralPermissionCode.CompanyManagement);
    const handleAvatarEdit = useCallback(() => {
        storage.setCustomData({ isAvatarSelectionOpen: true });
        storage.refresh();
    }, [storage]);

    return (<>
        <AvatarStyledCompany src={logoUrl} size={AvatarSize.XL}>
            <AvatarButton isDisabled={isDisabled}
                          onClick={handleAvatarEdit}
                          title={storage.t("Common:General.Edit")}>
                <EditIcon isLight/>
            </AvatarButton>
        </AvatarStyledCompany>
        <WriteLineWrapper isBig>
            {fieldElement}
        </WriteLineWrapper>
    </>);
};

export default CompanyFormAvatarWithName;