import React from "react";
import { StyledDashboard, StyledGridLayout } from "./Dashboard.styles";
import CustomResizeObserver from "../customResizeObserver";
import { GridTileSize, IDashboardDefinition, IDashboardLayoutConfig, TDashboardLayout } from "./Dashboard.types";
import DashboardTile from "./DashboardTile";
import { debounce } from "lodash";
import { createLayout, getDashboardTileInfo, getMinCols, getOrderFromLayout } from "./Dashboard.utils";
import { ItemCallback } from "react-grid-layout";
import { LayoutStackOrder } from "../../global.style";


interface IProps {
    inEditMode: boolean;
    definition: IDashboardDefinition;
    config?: IDashboardLayoutConfig;
    onLayoutConfigChange?: (config: IDashboardLayoutConfig) => void;
}

interface IState extends IDashboardLayoutConfig {
}

export default class Dashboard extends React.PureComponent<IProps, IState> {

    _ref = React.createRef<HTMLDivElement>();

    state: IState = {};

    componentDidMount() {
        this.recalculate();

        document.body.addEventListener("mouseup", this.clearDraggingWrapper);
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>) {
        if (prevProps.config !== this.props.config) {
            this.recalculate();
        }
    }

    componentWillUnmount() {
        document.body.removeEventListener("mouseup", this.clearDraggingWrapper);
        // cleanup DOM
        this._draggingWrapper?.parentElement?.removeChild(this._draggingWrapper);
    }

    // wrapper element for currently dragged tile content
    _draggingWrapper: HTMLDivElement = null;

    // currently dragged tile element, to which we want to append the dragged tile content back later.
    _draggedTileElement: HTMLElement = null;

    // function returns wrapper element in a body to be parent of currently dragged tile,
    // so it's not affected by overflow:hidden/scroll (appended directly to body)
    getDraggingWrapper(): HTMLDivElement {
        if (!this._draggingWrapper) {
            this._draggingWrapper = document.createElement("div");
            this._draggingWrapper.style.position = "absolute";
            this._draggingWrapper.style.padding = "16px";
            this._draggingWrapper.style.zIndex = LayoutStackOrder.Tooltips.toString();
            document.body.appendChild(this._draggingWrapper);
        }
        return this._draggingWrapper;
    }

    clearDraggingWrapper = (): void => {
        if (this._draggedTileElement) {
            const wrapper = this.getDraggingWrapper();
            this._draggedTileElement.appendChild(wrapper.childNodes[0]);
            wrapper.style.visibility = "hidden";
            this._draggedTileElement = null;
        }
    }

    recalculate(): void {
        const { cols, layout } = this.state;
        const { config } = this.props;
        const minCols = getMinCols(config?.layout);

        const { max, floor } = Math;
        const width = this._ref.current?.offsetWidth;
        const calcCols = max(minCols, floor(width / GridTileSize));

        if (Number.isInteger(calcCols)) {
            if (config.cols === calcCols) {
                // if calculated cols are same as cols from props, use saved layout from props
                this.setState({ ...config });
            } else if (calcCols !== cols || config.layout !== layout) {
                // if number of cols differs, calculate new layout from ordered tiles
                const orderedTiles = config?.layout && getOrderFromLayout(config.layout);
                const newConfig = createLayout(orderedTiles, calcCols);
                this.props.onLayoutConfigChange(newConfig);
            }
            // otherwise, there is no change -> keep current layout
        }
    }

    handleLayoutChange = (currentLayout: TDashboardLayout): void => {
        if (this.props.inEditMode) {
            this.props.onLayoutConfigChange({
                cols: this.state.cols,
                layout: currentLayout
            });
        }
    };

    handleResize = debounce((): void => {
        this.recalculate();
    }, 100);

    handleDragStart: ItemCallback = (layout, oldItem, newItem, placeholder, e, element) => {
        const innerEl = element.childNodes[0] as HTMLElement;
        const { top, left, width, height } = element.getBoundingClientRect();
        const wrapper = this.getDraggingWrapper();
        wrapper.appendChild(innerEl);
        // setup correct size and initial position
        wrapper.style.width = `${width}px`;
        wrapper.style.height = `${height}px`;
        wrapper.style.top = `${e.clientY}px`;
        wrapper.style.left = `${e.clientX}px`;
        // the mouse event occure in the element -> move it relatively to it, so it doesn't jump with the top left corner to mouse position
        const diffTop = e.clientY - top;
        const diffLeft = e.clientX - left;
        wrapper.style.transform = `translate(-${diffLeft}px, -${diffTop}px)`;
        wrapper.style.visibility = "visible";
        // element, which is being dragged -> we need to put the dragged element back to it later
        this._draggedTileElement = element;
    }

    handleDragStop = (): void => {
        this.clearDraggingWrapper();
    }

    handleDrag: ItemCallback = (layout, oldItem, newItem, placeholder, e, element) => {
        const wrapper = this.getDraggingWrapper();
        wrapper.style.top = `${e.clientY}px`;
        wrapper.style.left = `${e.clientX}px`;
    }

    renderGrid(): React.ReactNode {
        if (!this.state?.layout) {
            return null;
        }
        const { cols, layout } = this.state;
        const { definition, inEditMode } = this.props;
        const calcWidth = cols * GridTileSize;

        return (
            <StyledGridLayout $showGrid={inEditMode}
                              isDraggable={!!inEditMode}
                              layout={layout}
                              margin={[0, 0]}
                              compactType={"vertical"}
                              preventCollision={false}
                              onLayoutChange={this.handleLayoutChange}
                              onDragStart={this.handleDragStart}
                              onDragStop={this.handleDragStop}
                              onDrag={this.handleDrag}
                              cols={cols}
                              rowHeight={GridTileSize}
                              width={calcWidth}>
                {layout.map(l => (
                    <DashboardTile info={getDashboardTileInfo(definition, l)}
                                   inEditMode={inEditMode}
                                   key={l.i}/>
                ))}
            </StyledGridLayout>
        );
    }

    render() {
        return (
            <StyledDashboard ref={this._ref}>
                {this.renderGrid()}
                <CustomResizeObserver onResize={this.handleResize}/>
            </StyledDashboard>
        );
    }
}

