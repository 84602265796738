import { ISelectItem, ISelectPropsBase } from "@components/inputs/select/Select.types";

export const NO_RECORD_FOUND = "##NORECORDFOUND##";
export const PAGE_SIZE = 300;
export const ITEM_PAGE_SIZE = 15;
export const _isNoRecord = (item: ISelectItem): boolean => {
    return item?.id === NO_RECORD_FOUND;
};

export function _showTabularHeader(props: Pick<ISelectPropsBase, "columns" | "showTabularHeader">): boolean {
    return props.showTabularHeader ?? props.columns?.length > 2;
}