import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import { KeyName } from "../../keyName";
import TestIds from "../../testIds";
import { CashBoxIcon } from "../icon";
import Checkbox from "../inputs/checkbox";
import { CheckboxFilterStyled, CheckboxWrapper, ContentWrapper, LogoWrapper } from "./CheckboxFilter.styles";


interface IProps extends WithTranslation {
    className?: string;
    isDisabled: boolean;
    checked: boolean;
    onChange: () => void;
}

class CheckboxFilter extends React.PureComponent<IProps> {
    handleKeyDown = (callback: (e?: React.MouseEvent) => void, e: React.KeyboardEvent) => {
        if (e.key === KeyName.Space || e.key === KeyName.Enter) {
            e.preventDefault();
            callback();
        }
    };

    render() {
        return (
                <CheckboxFilterStyled className={this.props.className}
                                      data-testid={TestIds.CashboxFilterTile}
                                      isDisabled={this.props.isDisabled}>
                <CheckboxWrapper onClick={this.props.onChange}>
                    <Checkbox onChange={this.props.onChange} checked={this.props.checked} isDisabled={this.props.isDisabled}/>
                </CheckboxWrapper>
                <LogoWrapper>
                    <CashBoxIcon isLight preventHover/>
                </LogoWrapper>
                <ContentWrapper>
                    {this.props.children}
                </ContentWrapper>
            </CheckboxFilterStyled>
        );
    }
}

export default withTranslation(["Components"])(CheckboxFilter);