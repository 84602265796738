import React from "react";
import { StyledImageUploader } from "./ImageUploader.styles";
import { IconButton } from "../button/Button";
import { BinIcon } from "../icon/Icon";
import { WithTranslation, withTranslation } from "react-i18next";
import ImageUploaderImage from "./ImageUploaderImage";
import FileUploadButton from "../fileUploadButton/FileUploadButton";

export type TImage = File | string;

interface IProps extends WithTranslation {
    image: TImage;
    onImageChange: (image: File) => void;
    customIcon?: string;
    isDisabled?: boolean;
    renderCustomButton?: (onClick: () => void, isDisabled: boolean) => void;
}

class ImageUploader extends React.PureComponent<IProps> {
    handleImageChange = (images: File[]) => {
        this.props.onImageChange?.(images?.[0]);
    };

    handleRemoveButtonClick = () => {
        this.props.onImageChange?.(null);
    };


    handleImageError = () => {
        this.handleRemoveButtonClick();
        alert(this.props.t("Components:ImageUploader.WrongImage"));
    };

    renderImage = () => {
        return (
            <>
                <ImageUploaderImage image={this.props.image}
                                    onImageError={this.handleImageError}/>
                <IconButton title={this.props.t("Components:ImageUploader.RemoveImage")}
                            onClick={this.handleRemoveButtonClick}
                            isDecorative>
                    <BinIcon/>
                </IconButton>
            </>
        );
    };

    render() {
        return (
            <StyledImageUploader isDisabled={this.props.isDisabled}>
                {!this.props.image && <FileUploadButton onFileChange={this.handleImageChange}
                                                        accept={"image/*"}
                                                        isDisabled={this.props.isDisabled}
                                                        renderCustomButton={this.props.renderCustomButton}
                                                        hotspotId={"ImageUploaderUploadButton"}/>}
                {this.props.image && this.renderImage()}
            </StyledImageUploader>
        );
    }
}

export default withTranslation(["Components"])(ImageUploader);