function animationFrameThrottle(callback) {
    let animationBusy = false;

    return (...args) => {
        if (!animationBusy) {
            animationBusy = true;

            requestAnimationFrame(() => {
                callback.apply(this, args);
                animationBusy = false;
            });
        }
    };
}

export default animationFrameThrottle;