import { WithConfirmationDialog, withConfirmationDialog } from "@components/dialog/withConfirmationDialog";
import { ISelectItem } from "@components/inputs/select/Select.types";
import { buildTreeFromAllItems } from "@components/inputs/select/SelectAPI";
import { IFieldDef } from "@components/smart/FieldInfo";
import { ISmartFieldChange } from "@components/smart/smartField/SmartField";
import { ILabelEntity, ILabelHierarchyEntity, LabelEntity, LabelHierarchyEntity } from "@odata/GeneratedEntityTypes";
import { prepareQuery } from "@odata/OData.utils";
import { isEmpty } from "lodash";
import React from "react";
import { DefaultTheme, withTheme } from "styled-components";

import { AppContext } from "../../contexts/appContext/AppContext.types";
import { withPermissionContext } from "../../contexts/permissionContext/withPermissionContext";
import { QueryParam } from "../../enums";
import { toTuple } from "../../global.types";
import BindingContext, { createPath } from "../../odata/BindingContext";
import { getQueryParameters, removeQueryParam } from "../../routes/Routes.utils";
import { PropsWithTheme } from "../../theme";
import { IContextInitArgs } from "../../views/formView/FormStorage";
import { FormViewForExtend, IFormViewProps } from "../../views/formView/FormView";
import { getUsedLabels } from "./Labels.utils";

interface IProps extends IFormViewProps<ILabelEntity>, PropsWithTheme, WithConfirmationDialog {
}


const HierarchyItemId = "hierarchy";

export const hierarchyLoadFieldDefs: IFieldDef[] = [
    { id: LabelHierarchyEntity.Color },
    { id: LabelHierarchyEntity.Name },
    { id: createPath(LabelHierarchyEntity.Labels, LabelEntity.Id) },
    { id: createPath(LabelHierarchyEntity.Labels, LabelEntity.Name) },
    { id: createPath(LabelHierarchyEntity.Labels, LabelEntity.IsActive) },
    { id: createPath(LabelHierarchyEntity.Labels, LabelEntity.Parent) },
    { id: createPath(LabelHierarchyEntity.Labels, LabelEntity.Children) }
];

class LabelsFormView extends FormViewForExtend<ILabelEntity, IProps> {
    static contextType = AppContext;

    _refScroll = React.createRef<HTMLElement>();

    getAdditionalLoadPromise = (args: IContextInitArgs) => {
        const query = prepareQuery({
            oData: this.props.storage.oData,
            bindingContext: args.bindingContext.getRootParent(),
            fieldDefs: hierarchyLoadFieldDefs
        });

        return toTuple([query.fetchData<ILabelHierarchyEntity>()]);
    };

    handleChange = (e: ISmartFieldChange) => {
        this.props.storage.handleChange(e);

        // triggerAdditionalTasks === true for select like components has same
        // meaning as triggerAdditionalTasks === undefined for the rest types
        const shouldUpdate = e.triggerAdditionalTasks !== false;
        this.props.storage.refreshFields(shouldUpdate);
    };

    onAfterLoad = async () => {

        const storage = this.props.storage;
        const modelBc = storage.data.bindingContext;

        const hierarchy = storage.data.additionalResults[0].value;

        storage.getInfo(modelBc.navigate(LabelEntity.Name)).fieldSettings.color = hierarchy.Color;

        let items: ISelectItem[] = [];
        items.push({
            label: hierarchy.Name,
            id: HierarchyItemId,
            additionalData: {
                Parent: null,
                Children: hierarchy.Labels.filter((label: ILabelEntity) => !label.Parent.Id)
            }
        });

        for (const label of hierarchy.Labels) {
            items.push({
                label: label.Name,
                id: label.Id,
                color: this.props.theme[hierarchy.Color as keyof DefaultTheme],
                additionalData: {
                    Parent: label.Parent.Id ? label.Parent : { Id: HierarchyItemId },
                    Children: label.Children,
                    IsActive: label.IsActive
                }
            });
        }

        // label which is being edited should appear in parent select tree, but should be disabled along witch it's children
        const disableBranch = (id: string) => {
            const item = items.find((item: ISelectItem) => item.id === id);
            if (item) {
                item.isDisabled = true;
                for (const child of item.additionalData.Children) {
                    disableBranch(child.Id);
                }
            }
        };
        disableBranch(modelBc.getKey() as string);

        items = buildTreeFromAllItems(items);
        storage.getInfo(modelBc.navigate(LabelEntity.Parent)).fieldSettings.items = items;

        // label doesn't have any navigation property to hierarchy, this is just for select to act like it have
        // hierarchy as parent in parent select and has to be se to empty object before save
        if (!this.entity.Parent?.Id) {
            this.entity.Parent = { Id: HierarchyItemId as unknown as number };
        }

        // set color of the hierarchy, just to show it as disabled field
        storage.setValueByPath(BindingContext.localContext("LabelColor"), hierarchy.Color);

        if (!!getQueryParameters()[QueryParam.NewLabelSaved]) {
            this.props.storage.displaySaveOkMessage(this.props.storage.t("Labels:Validation.Saved"));
            removeQueryParam(this.props.storage.history, QueryParam.NewLabelSaved, true);
        }

        return super.onAfterLoad();
    };

    async onBeforeDelete(): Promise<boolean> {
        this.props.storage.setCustomData({
            isDeleteButtonBusy: true
        });
        this.forceUpdate();

        let shouldConfirm = true;
        const label = this.entity;
        const isLabelUsed = await getUsedLabels([label.Id]);

        this.props.storage.setCustomData({
            isDeleteButtonBusy: false
        });
        this.forceUpdate();

        if (isLabelUsed.length > 0) {
            shouldConfirm = await this.props.confirmationDialog.open({
                content: this.props.storage.t("Labels:RemoveConfirmationText", { labels: [label.Name] })
            });
        }

        return shouldConfirm;
    }

    save = async () => {
        this.props.onBeforeSave();

        const isNew = this.props.storage.data.bindingContext.isNew();

        // label doesn't have any navigation property to hierarchy, this was set just for select to act like it have
        // hierarchy as parent in parent select and has to be se to empty object before save
        if (this.entity.Parent?.Id === HierarchyItemId as unknown as number) {
            this.entity.Parent = {};
        }

        const result = await this.props.storage.save({
            successSubtitle: this.props.storage.t("Labels:Validation.Saved")
        });

        if (!result) {
            this.props.onSaveFail?.();
            if (isEmpty(this.props.storage.data.entity.Parent)) {
                this.entity.Parent.Id = HierarchyItemId as unknown as number;
            }
        } else {
            this.onAfterSave(isNew, false);
        }
        this.forceUpdate();

        return result;
    };
}

export default withConfirmationDialog(withTheme(withPermissionContext(LabelsFormView)));