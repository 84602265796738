import {
    cbaReportCommonParams,
    getCbaReportParamsDef,
    IReportTableDefinition,
    NumberAggregationFunction
} from "../Report.utils";
import i18next from "i18next";
import { IReportHierarchy, IReportVariantDef } from "@components/smart/smartTable";
import { ReportId } from "../ReportIds";
import {
    FilterBarGroup,
    getDefaultFilterGroupDef,
    IFilterGroupDef
} from "@components/smart/smartFilterBar/SmartFilterBar.types";
import { ReportStorage } from "../ReportStorage";
import { ISort } from "@components/table";
import { getDocumentNumberOursOverride } from "../CommonDefs";
import { composedDateRangeOnBeforeLoadCallback } from "../customFilterComponents/ComposedDateRange";
import { Sort } from "../../../enums";
import { IAppContext } from "../../../contexts/appContext/AppContext.types";
import { isVatRegisteredCompany } from "@utils/CompanyUtils";

export enum CbaEntryLedgerVariants {
    Evala = "-87",
    CZK = "-92"
}

export const getDefinition = (context: IAppContext): IReportTableDefinition => {
    const tableId = ReportId.CbaEntryLedger;
    const title = i18next.t("Reporting:CbaEntryLedger.Title");
    const path = "CbaEntryLedger";
    const initialSortBy = [
        { id: "CbaEntry_Date", sort: Sort.Desc },
        { id: "CbaEntryType_Name", sort: Sort.Asc }
    ] as ISort[];
    const filterBarDef: IFilterGroupDef[] = [
                {
                    ...getDefaultFilterGroupDef(FilterBarGroup.Parameters),
                    defaultFilters: [...cbaReportCommonParams],
                    filterDefinition: {
                        ...getCbaReportParamsDef(tableId)
                    },
                },
                {
                    ...getDefaultFilterGroupDef(FilterBarGroup.Filters),
                    allowCustomFilters: false,
                    defaultFilters: [],
                    filterDefinition: {}
                }
            ]
    ;
    const onBeforeLoad = async (storage: ReportStorage) => {
        await composedDateRangeOnBeforeLoadCallback(storage);
    };

    const parameters: string[] = [...cbaReportCommonParams];

    const isVatRegistered = isVatRegisteredCompany(context);

    const defaultReportVariants: Record<CbaEntryLedgerVariants, IReportVariantDef> = {
        [CbaEntryLedgerVariants.CZK]: {
            ReportHierarchy: {
                "Aggregate": true,
                "Groups": [
                    { "ColumnAlias": "CbaEntryType_Name" }
                ],
                "Columns": [
                    { "ColumnAlias": "CbaEntry_Date" },
                    { "ColumnAlias": "CbaEntry_NumberOurs" },
                    { "ColumnAlias": "DocumentType_Name" },
                    { "ColumnAlias": "PairedDocument_NumberOurs" },
                    { "ColumnAlias": "CbaEntry_Description" }
                ],
                "Aggregations": [{
                    ColumnAlias: "CbaEntry_AmountTaxed",
                    AggregationFunction: NumberAggregationFunction.Sum
                }, {
                    "ColumnAlias": "CbaEntry_AmountNonTaxed",
                    AggregationFunction: NumberAggregationFunction.Sum
                }]
            }
        }, [CbaEntryLedgerVariants.Evala]: {
            ReportHierarchy: {
                "Aggregate": false,
                "Groups": [],
                "Columns": [
                    { "ColumnAlias": "CbaEntryType_Name" },
                    { "ColumnAlias": "CbaEntry_Date" },
                    { "ColumnAlias": "CbaEntry_NumberOurs" },
                    { "ColumnAlias": "DocumentType_Name" },
                    { "ColumnAlias": "PairedDocument_NumberOurs" },
                    { "ColumnAlias": "CbaEntry_TransactionAmountCleared" },
                    { "ColumnAlias": "CbaEntry_AmountTaxed" },
                    { "ColumnAlias": "CbaEntry_AmountNonTaxed" },
                    ...(isVatRegistered ? [{ "ColumnAlias": "CbaEntry_AmountVat" }] : []),
                    { "ColumnAlias": "CbaEntry_Description" }
                ],
                "Aggregations": []
            }
        }
    };

    const defaultReportHierarchy: IReportHierarchy = defaultReportVariants[CbaEntryLedgerVariants.Evala].ReportHierarchy;

        const columnOverrides = {
            CbaEntry_NumberOurs: getDocumentNumberOursOverride({
                documentTypeProperty: "CbaEntry_DocumentTypeCode",
                documentIdProperty: "CbaEntry_DocumentId"
            }),
            PairedDocument_NumberOurs: getDocumentNumberOursOverride({
                documentTypeProperty: "PairedDocument_DocumentTypeCode",
                documentIdProperty: "PairedDocument_Id"
            })
        };

        return {
            title, path, id: tableId, initialSortBy, filterBarDef,
            onBeforeLoad, columnOverrides, parameters, defaultReportHierarchy, defaultReportVariants
        };
    }
;