import {
    EntitySet,
    EntityType,
    IODataActionParameter,
    IPropertyInit,
    IReferentialConstraint,
    Metadata,
    NavigationBinding,
    ODataAction,
    Property,
    Type
} from "./Metadata";

class MetadataError {
    code: number;
    message: string;

    constructor(code: number, message: string) {
        this.code = code;
        this.message = message;
    }
}

function parseBoolean(value: string, defaultValue?: boolean): boolean {
    return value === null ? defaultValue : value === "true";
}

function parseType(value: string) {
    let name, namespace, collection;
    //parses Collection(Solitea.Evala.DomainModel.Test)
    let match = value.match(/(?<collection>Collection\()?(?<type>[^)]+)(\))?/);
    collection = !!match.groups.collection;
    name = match.groups.type;
    let index = name.lastIndexOf(".");
    if (index > 0) {
        namespace = name.substring(0, index);
        name = name.substring(index + 1);
    }
    return new Type(name, namespace, collection);
}

function parseAnnotations(propertyData: Element) {
    const annotations: Record<string, string | boolean | IReferentialConstraint> = {};

    // parse Annotation
    Array.from(propertyData.getElementsByTagName("Annotation")).forEach(annotation => {
        let term = annotation.getAttribute("Term");
        let annotationName = term.split(".").pop();
        let propertyKey = annotationName.charAt(0).toLowerCase() + annotationName.slice(1);
        let boolVal = annotation.getAttribute("Bool");
        let val;
        if (boolVal) {
            val = parseBoolean(boolVal);
        } else {
            val = annotation.getAttribute("String");
        }
        if (propertyKey) {
            annotations[propertyKey] = val;
        }
    });

    // parse ReferentialConstraint
    Array.from(propertyData.getElementsByTagName("ReferentialConstraint")).forEach(refConstraint => {
        const property = refConstraint.getAttribute("Property");
        const referencedProperty = refConstraint.getAttribute("ReferencedProperty");

        annotations.referentialConstraint = {
            property,
            referencedProperty
        };
    });

    return annotations;
}

function parseProperty(propertyData: Element, keyMap: Record<string, any>, namespace: string, entityTypeName: string) {
    let name = propertyData.getAttribute("Name");
    let type = parseType(propertyData.getAttribute("Type"));
    let properties: IPropertyInit = {
        key: !!keyMap[name],
        navigation: false,
        nullable: parseBoolean(propertyData.getAttribute("Nullable"), true),
        maxLength: propertyData.getAttribute("MaxLength"),
        precision: propertyData.getAttribute("Precision"),
        scale: propertyData.getAttribute("Scale"),
        defaultValue: propertyData.getAttribute("DefaultValue")
    };

    // add all annotations to properties object
    const annotations = parseAnnotations(propertyData);
    properties = { ...properties, ...annotations };

    return new Property(name, type, properties, new Type(entityTypeName, namespace));
}

function parseNavigationProperty(propertyData: Element, namespace: string, entityTypeName: string) {
    let name = propertyData.getAttribute("Name");
    let type = parseType(propertyData.getAttribute("Type"));
    let properties: IPropertyInit = {
        navigation: true,
        key: false,
        nullable: parseBoolean(propertyData.getAttribute("Nullable"), true)
    };

    // add all annotations to properties object
    const annotations = parseAnnotations(propertyData);
    properties = { ...properties, ...annotations };

    return new Property(name, type, properties, new Type(entityTypeName, namespace));
}

function parseKeyMap(entityData: Element) {
    let key = entityData.getElementsByTagName("Key");
    let keyMap: Record<string, string> = {};
    if (key.length > 0) {
        for (let ref of key[0].getElementsByTagName("PropertyRef")) {
            let name = ref.getAttribute("Name");
            keyMap[name] = name;
        }
    }
    return keyMap;
}

function parseEntity(entityData: Element, namespace: string) {
    let name = entityData.getAttribute("Name");
    let propertyMap: Record<string, Property> = {};
    let keyMap = parseKeyMap(entityData);
    for (let propertyData of entityData.getElementsByTagName("Property")) {
        let property = parseProperty(propertyData, keyMap, namespace, name);
        propertyMap[property.getName()] = property;
    }
    for (let propertyData of entityData.getElementsByTagName("NavigationProperty")) {
        let property = parseNavigationProperty(propertyData, namespace, name);
        propertyMap[property.getName()] = property;
    }
    return new EntityType(name, namespace, propertyMap);
}

function parseNavigationBinding(navigationBinding: Element) {
    return new NavigationBinding(navigationBinding.getAttribute("Path"), navigationBinding.getAttribute("Target"));
}

function parseEntitySet(entitySetData: Element) {
    let name = entitySetData.getAttribute("Name");
    let type = parseType(entitySetData.getAttribute("EntityType"));
    let navigationBindings: Record<string, NavigationBinding> = {};
    for (let navigationBindingData of entitySetData.getElementsByTagName("NavigationPropertyBinding")) {
        let navigationBinding = parseNavigationBinding(navigationBindingData);
        navigationBindings[navigationBinding.getPath()] = navigationBinding;
    }
    return new EntitySet(name, type, navigationBindings);
}

function parseAction(actionElement: Element) {
    const name = actionElement.getAttribute("Name");
    const isBound = parseBoolean(actionElement.getAttribute("IsBound"), false);
    const parameters = Array.from(actionElement.getElementsByTagName("Parameter")).map(element => {
        let isOptional = false;

        Array.from(element.getElementsByTagName("Annotation")).forEach(annotation => {
            if (annotation.getAttribute("Term") === "Org.OData.Core.V1.OptionalParameter") {
                isOptional = true;
            }
        });

        return {
            name: element.getAttribute("Name"),
            type: parseType(element.getAttribute("Type")),
            nullable: parseBoolean(element.getAttribute("Name"), true),
            optional: isOptional
        } as IODataActionParameter;
    });
    const returnType = actionElement.getElementsByTagName("ReturnType");
    const returnTypeType = returnType?.[0] ? parseType(returnType[0].getAttribute("Type")) : null;

    return new ODataAction(name, parameters, returnTypeType, isBound);
}

async function parseMetadata(url: string, text: string) {
    let parser = new DOMParser();
    let xml: Document = await parser.parseFromString(text, "text/xml");
    let schemas: HTMLCollectionOf<Element> = xml.getElementsByTagName("Schema");
    let entityMap: Record<string, EntityType> = {};
    let entitySetMap: Record<string, EntitySet> = {};
    const actionsMap: Record<string, ODataAction> = {};

    for (let schema of schemas) {
        let namespace = schema.getAttribute("Namespace");
        if (namespace === "Default") {
            namespace = null;
        }

        let entities = schema.getElementsByTagName("EntityType");
        const entitiesWithBaseType: Record<string, string> = {};
        for (let entityData of entities) {
            let entity = parseEntity(entityData, namespace);
            entityMap[entity.getFullName()] = entity;

            const baseType = entityData.getAttribute("BaseType");

            if (baseType) {
                entitiesWithBaseType[entity.getFullName()] = baseType;
            }
        }

        for (let [entityFullName, baseTypeFullName] of Object.entries(entitiesWithBaseType)) {
            entityMap[entityFullName].setBaseType(entityMap[baseTypeFullName]);
        }

        for (let complexData of schema.getElementsByTagName("ComplexType")) {
            let entity = parseEntity(complexData, namespace);
            entityMap[entity.getFullName()] = entity;
        }

        let entitySets = schema.getElementsByTagName("EntitySet");

        for (let entitySetData of entitySets) {
            let entitySet = parseEntitySet(entitySetData);
            entitySetMap[entitySet.getName()] = entitySet;
        }

        let actions = schema.getElementsByTagName("Action");

        for (let actionElement of actions) {
            const action = parseAction(actionElement);
            actionsMap[action.getFullName()] = action;
        }
    }
    return new Metadata(url, entityMap, entitySetMap, actionsMap);
}

async function fetchMetadata(url: string) {
    let metadataUrl = `${url}/$metadata`;
    let response: Response = await fetch(metadataUrl);

    if (!response.ok) {
        throw new MetadataError(response.status, response.statusText);
    }

    let text = await response.text();
    let metadata = await parseMetadata(url, text);
    return metadata;
}

function tokenizeContext(str: string) {
    let result = [];
    let tokenStart = 0;
    for (let pos = 0; pos < str.length; pos++) {
        let ch = str.charAt(pos);
        if (ch === "(" || ch === "," || ch === ")" || ch === "/") {
            if (tokenStart < pos) {
                result.push(str.substring(tokenStart, pos));
            }
            result.push(str.substring(pos, pos + 1));
            tokenStart = pos + 1;
        }
    }
    if (tokenStart < str.length) {
        result.push(str.substring(tokenStart));
    }
    return result;
}

function findMatchingBracket(tokens: string[], start: number) {
    let bracketDepth = 0;
    for (let pos = start; pos < tokens.length; pos++) {
        if (tokens[pos] === "(") {
            bracketDepth++;
        } else if (tokens[pos] === ")") {
            if (bracketDepth === 0) {
                return pos;
            } else {
                bracketDepth--;
            }
        }
    }
    throw new Error("Matching bracket not found.");
}

interface IMetadataNode {
    property?: string;
    children?: boolean | Record<string, IMetadataNode>;
}

function buildAst(tokens: string[], start: number, stop: number) {
    if (start > stop) {
        return null;
    }
    let result: Record<string, any> = {};
    while (start <= stop) {
        if (tokens[start] === ",") {
            start++;
            continue;
        }
        let element: IMetadataNode = {
            property: tokens[start],
            children: false
        };
        result[element.property] = element;
        if (start + 1 <= stop && tokens[start + 1] === "(") {
            let endPos = findMatchingBracket(tokens, start + 2) - 1;
            element.children = buildAst(tokens, start + 2, endPos);
            start = endPos + 2;
        } else {
            start++;
        }
    }
    return result;
}

function filterNonExpandProperties(ast: Record<string, IMetadataNode>) {
    let keys = Object.keys(ast);
    for (let key of keys) {
        if (ast[key].children === false) {
            delete ast[key];
        } else if (ast[key].children) {
            ast[key].children = filterNonExpandProperties(ast[key].children as Record<string, IMetadataNode>);
        }
    }
    if (Object.keys(ast).length === 0) {
        return null;
    } else {
        return ast;
    }
}

function parseContextTree(context: string) {
    let start = context.indexOf("#");
    if (start < 0) {
        start = 0;
    } else {
        start++;
    }
    let end = context.indexOf("/$entity");
    if (end < 0) {
        end = context.length;
    }
    let pathStr = context.substring(start, end);
    let tokens = tokenizeContext(pathStr);

    let astStart = 0;
    let prefix = "";
    for (let i = 0; i < tokens.length; i++) {
        if (tokens[i] === "/") {
            if (prefix.length > 0) {
                prefix += "/";
            }
            prefix += tokens[astStart];
            astStart = i + 1;
        }
    }
    if (prefix.length > 0) {
        prefix += "/";
    }

    let ast = buildAst(tokens, astStart, tokens.length - 1);
    let keys = Object.keys(ast);
    if (keys.length < 1) {
        throw new Error("Unexpected structure of context");
    }
    ast = ast[keys[0]];
    if (ast.property === "Collection") {
        return Object.values(ast.children)[0];
    }
    if (ast.children) {
        ast.children = filterNonExpandProperties(ast.children);
    }
    if (ast.children === false) {
        ast.children = null;
    }
    ast.property = prefix + ast.property;
    return ast;
}

export { parseMetadata, fetchMetadata, parseContextTree, tokenizeContext };
