import { WithOData, withOData } from "@odata/withOData";
import React, { memo, useContext } from "react";
import { useTranslation } from "react-i18next";

import { AppContext } from "../../../contexts/appContext/AppContext.types";
import { IDashboardTileComponentProps } from "../../dashboard";
import { CustomerSupportIcon } from "../../icon";
import { Heading, StyledCustomerSupportTile } from "./CustomerSupportTile.styles";

interface IProps extends IDashboardTileComponentProps, WithOData {
}

const CustomerSupportTile: React.FC<IProps> = ({ inEditMode, info, oData }) => {
    const { t } = useTranslation("Home");

    const context = useContext(AppContext);
    const { CustomerSupportPin } = context.getData().userSettings;

    return (
            <StyledCustomerSupportTile inEditMode={inEditMode}>
                <CustomerSupportIcon height={"125px"} width={"44px"}/>
                <Heading>{t("CustomerSupport.Heading")}</Heading>
                <dl>
                    <dt>{t("CustomerSupport.Phone")}:</dt>
                    <dd><a href={"tel:+420549522523"}>+420 549 522 523</a></dd>
                    <dt>{t("CustomerSupport.PIN")}:</dt>
                    <dd>{CustomerSupportPin}</dd>
                </dl>
            </StyledCustomerSupportTile>
    );
};

export default memo(withOData(CustomerSupportTile));