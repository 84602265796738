import React from "react";
import { getRemoveActionState, IFileGridProps } from "./FileGrid";
import { IColumn, IRow, IRowAction, ISort, TId } from "../table";
import { IRowProps } from "../table/Rows";
import {
    fileNameFormatter,
    fileSizeFormatter,
    getCustomFileContextMenuItems,
    getFileContextMenuItems,
    strongTextFormatter
} from "./File.utils";
import DateType from "../../types/Date";
import { ActionState, RowAction, Sort, TextAlign, ToggleState } from "../../enums";
import { WithTranslation, withTranslation } from "react-i18next";
import { SmartTableBase } from "../smart/smartTable/SmartTableBase";
import { sortCompareFn } from "@utils/general";
import { IFileMetadataEntity } from "@odata/GeneratedEntityTypes";
import { IModifierKeys } from "../../global.types";
import memoizeOne from "../../utils/memoizeOne";

import { ISelectItem } from "@components/inputs/select/Select.types";

export interface IFileListProps extends IFileGridProps, WithTranslation {
    visibleColumns?: string[];
    selectedFileId?: string;    // table row, which is selected
    showDrilldown?: boolean;
}

interface IState {
    sort: ISort;
    contextMenuFileId?: string; // table row, with triggered context menu
}

export enum FileListColumn {
    Name = "Name",
    Size = "Size",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
}

class FileList extends React.PureComponent<IFileListProps, IState> {

    state: IState = {
        sort: {
            id: "Name",
            sort: Sort.Asc
        }
    };

    get sortedFiles() {
        const propName = this.state.sort.id as keyof IFileMetadataEntity;
        return this.props.files.sort((a, b) => sortCompareFn(a.metadata[propName], b.metadata[propName], this.state.sort.sort));
    }

    get rows(): IRow[] {
        return this.sortedFiles.map(({ id, name, metadata }) => ({
            id,
            dataId: id.toString(),
            values: {
                Name: fileNameFormatter(name),
                Size: metadata?.Size && strongTextFormatter(fileSizeFormatter(metadata.Size)),
                DateCreated: metadata?.DateCreated && strongTextFormatter(DateType.localFormat(metadata.DateCreated)),
                DateLastModified: metadata?.DateLastModified && strongTextFormatter(DateType.localFormat(metadata.DateLastModified))
            },
            drilldown: this.props.showDrilldown
        }));
    }

    get columns(): Omit<IColumn, "width">[] {
        const columns = [
            {
                id: FileListColumn.Name,
                label: this.props.t("Components:FileUploader.Name")
            }, {
                id: FileListColumn.Size,
                label: this.props.t("Components:FileUploader.Size"),
                textAlign: TextAlign.Right
            }, {
                id: FileListColumn.DateCreated,
                label: this.props.t("Components:FileUploader.Created"),
                textAlign: TextAlign.Right
            }, {
                id: FileListColumn.DateLastModified,
                label: this.props.t("Components:FileUploader.LastModified"),
                textAlign: TextAlign.Right
            }
        ];
        const { visibleColumns } = this.props;
        if (visibleColumns?.length) {
            return columns.filter(c => visibleColumns.includes(c.id));
        }
        return columns;
    }

    get toggleState(): ToggleState {
        const fileIds = Object.keys(this.props.filesToRemove);
        return fileIds.length === 0 ? ToggleState.AllUnchecked
                : (this.props.files.length === fileIds.length ? ToggleState.AllChecked : ToggleState.Other);
    }

    getRowActionState = (rowId: TId, row: IRowProps): ActionState => {
        return getRemoveActionState(this.props, rowId);
    };

    handleRowRemoveIconClick = (rowId: TId, row: IRowProps) => {
        this.props.onRemoveAction?.([rowId]);
    };

    handleToggleChange = (nextState: ToggleState) => {
        const fileIds = [];
        const checkAll = nextState === ToggleState.AllChecked;
        if (checkAll) {
            fileIds.push(...this.props.files.map(file => file.id));
        }
        this.props.onRemoveAction?.(fileIds, checkAll);
    };

    getRowAction = (): IRowAction => {
        if (!this.props.showRemoveAction) {
            return null;
        }

        return {
            actionType: RowAction.Remove,
            getActionState: this.getRowActionState,
            onClick: this.handleRowRemoveIconClick,
            toggleState: this.toggleState,
            onToggleChange: this.handleToggleChange
        };
    };

    getSelectedRows = memoizeOne(() => {
        const { selectedFileId } = this.props;
        return selectedFileId ? [selectedFileId] : [];
    }, () => [this.props.selectedFileId]);

    handleSortChange = ([sort]: ISort[]) => {
        this.setState({ sort });
    };

    handleRowSelect = (id: TId, props: IRowProps, modifiers?: IModifierKeys) => {
        if (this.props.onFileClick) {
            const shouldUnselect = (modifiers?.ctrlKey || modifiers?.metaKey) && id === this.props.selectedFileId;
            this.props.onFileClick(shouldUnselect ? null : id);
        }
    };

    handleRowContextMenuSelection = (id: TId, props: IRowProps, modifiers?: IModifierKeys) => {
        this.setState({ contextMenuFileId: id?.toString() });
    };

    handleContextMenuItemSelection = (item: ISelectItem): void => {
        this.props.onContextMenuSelection(item, this.state.contextMenuFileId);
    };

    // renderFileIcon = (rowId: TId, rowProps: IRowProps): React.ReactElement => {
    //     return (
    //             <TableFileIcon id={rowId}
    //                            onClick={this.props.onFileClick}
    //                            contextMenuItems={this.props.onContextMenuSelection && [...getFileContextMenuItems(this.props.isReadOnly), ...getCustomFileContextMenuItems(this.props.customContextMenuItems, rowId)]}
    //                            onContextMenuSelection={this.props.onContextMenuSelection}/>
    //     );
    // };

    getContextMenuItems() {
        if (this.props.onContextMenuSelection) {
            const items = [...getFileContextMenuItems(this.props.isReadOnly, true, this.props.isDeleteDisabled)];
            if (this.state.contextMenuFileId) {
                items.push(...getCustomFileContextMenuItems(this.props.customContextMenuItems, this.state.contextMenuFileId));
            }
            return items;
        }
        return [];
    }

    render() {
        return (
                <SmartTableBase tableId={"AttachmentsFileList"}
                                isList
                                rows={this.rows}
                                columns={this.columns}
                        // contentBefore={this.renderFileIcon}
                                contextMenuItems={this.getContextMenuItems()}
                                onContextMenuSelection={this.handleContextMenuItemSelection}
                                rowAction={this.getRowAction()}
                                sort={[this.state.sort]}
                                onSortChange={this.handleSortChange}
                                onRowSelect={this.handleRowSelect}
                                onRowContextMenuSelection={this.handleRowContextMenuSelection}
                                selectedRows={this.getSelectedRows()}
                                loaded/>
        );
    }
}

export default withTranslation(["Components"])(FileList);