import React from "react";
import { TableWrapper } from "../../../views/table/TableView.styles";
import View from "../../../views/View";
import TableWithAutoSizedColumns from "../../../components/table/TableWithAutoSizedColumns";
import ViewHeader from "../../../components/smart/smartHeader/SmartHeader";
import { withTranslation, WithTranslation } from "react-i18next";
import { IRow } from "@components/table";
import { TextAlign } from "../../../enums";
import { BreadCrumbProvider } from "../../../components/breadCrumb";
import { ROUTE_HOME } from "../../../routes";
import customFetch from "../../../utils/customFetch";
import { DASHBOARD_DATA_API } from "../../../constants";
import { AppContext } from "../../../contexts/appContext/AppContext.types";
import { ICompanyEntity } from "@odata/GeneratedEntityTypes";

interface IProps extends WithTranslation {}

interface IState {
    rows: IRow[];
    loading: boolean;
}

const JOURNAL_ENTRIES_COUNT_URL = `${DASHBOARD_DATA_API}/CrossCompanyJournalEntryCountByCompany`;

interface ICompanyJournalEntriesCountRes {
    CompanyId: number;
    Count: number;
}

class JournalEntriesLastMonth extends React.Component<IProps, IState> {
    static contextType = AppContext;

    state: IState = {
        rows: [],
        loading: false
    }

    componentDidMount() {
        this.load();

        // not sure we want those breadcrumbs => no translation
        this.context.setViewBreadcrumbs({
            items: [{
                key: "DashBoard",
                title: "Domov",
                link: ROUTE_HOME
            }, {
                key: "JournalEntriesLastMonth",
                title: "Počet záznamů v účetním deníku zaúčtovaných za poslední skončený měsíc",
            }],
            lockable: false
        });
    }

    get columns() {
        const { t } = this.props;

        return [
            { id: "name", label: t("Home:JournalEntriesLastMonth.Name"), textAlign: TextAlign.Left, width: 200 },
            { id: "total", label: t("Home:JournalEntriesLastMonth.Total"), textAlign: TextAlign.Right }
        ];
    }

    load = async (): Promise<void> => {
        this.setState({ loading: true });
        const res = await customFetch(JOURNAL_ENTRIES_COUNT_URL);
        const data = await res.json() as ICompanyJournalEntriesCountRes[];
        const companies = this.context.getData().companies;

        const rows: IRow[] = data.map(row => ({
            id: row.CompanyId,
            values: {
                name: companies.find((c: ICompanyEntity) => c.Id === row.CompanyId)?.Name,
                total: row.Count
            }
        }));

        this.setState({ loading: false, rows });
    }

    render() {
        if (!this.props.tReady) {
            return null;
        }

        return (
            <>
                <BreadCrumbProvider back={ROUTE_HOME}/>
                <View hotspotContextId={"journalEntriesLastMonth"}>
                    <ViewHeader title={this.props.t("Home:JournalEntriesLastMonth.TableHeading")}
                                shouldHideVariant/>
                    <TableWrapper>
                        <TableWithAutoSizedColumns
                            busy={this.state.loading}
                            rows={this.state.rows}
                            disableSort={true}
                            columns={this.columns}/>
                    </TableWrapper>
                </View>
            </>
        );
    }
}

export default withTranslation(["Home"])(JournalEntriesLastMonth);