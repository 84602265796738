import React from "react";
import { isDefined, isNotDefined, mouseDownRepeat } from "@utils/general";
import { KeyName } from "../../../keyName";
import { IInputOnBlurEvent, IInputOnChangeEvent } from "../input";

export interface INumericValueInput {
    value?: number;
    step?: number;
    precision?: number;
    min?: number;
    max?: number;
    showSteppers?: boolean;
    onChange?: (args: IInputOnChangeEvent<number>) => void;
}

interface IProps extends INumericValueInput {
    onBlur?: (args: IInputOnBlurEvent) => void;
    onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
    onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

/** "Abstract" class for NumericInput and NumericWriteLine */
export class NumericValueInputBase<T> extends React.PureComponent<IProps & T> {
    static defaultProps: any = {
        step: 1,
        precision: 0,
        max: Infinity,
        min: -Infinity
    };

    inputRef = React.createRef<HTMLInputElement>();
    inputWrapperRef = React.createRef<HTMLDivElement>();

    componentDidMount(): void {
        // wheel handling now wanted https://solitea-cz.atlassian.net/browse/DEV-9424
        // if (this.inputWrapperRef.current) {
        //     // we need to manually attach to onWheel event so that it can be set as 'active'
        //     // otherwise, we can't call preventDefault in the handler
        //     this.inputWrapperRef.current.addEventListener("wheel", this.handleWheel, { passive: false });
        // }
    }

    componentWillUnmount() {
        // if (this.inputWrapperRef.current) {
        //     this.inputWrapperRef.current.removeEventListener("wheel", this.handleWheel);
        // }
    }

    handleNextMouseDown = (): void => {
        mouseDownRepeat(this.increment);
    };

    handlePrevMouseDown = (): void => {
        mouseDownRepeat(this.decrement);
    };

    handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
        if (event.key === KeyName.ArrowDown) {
            event.preventDefault();
            this.decrement();
        } else if (event.key === KeyName.ArrowUp) {
            event.preventDefault();
            this.increment();
        }

        this.props.onKeyDown?.(event);
    };

    handleWheel = (event: WheelEvent) => {
        // only catch wheel events for "showSteppers=true" variant!
        if (!this.props.showSteppers) {
            return;
        }

        event.preventDefault();

        if (event.deltaY < 0) {
            this.increment();
        } else {
            this.decrement();
        }
    };

    getNumberValue = () => {
        const { value } = this.props;
        return !value || isNaN(value) ? 0 : value;
    };

    calcNextStepValue(delta: number): number {
        let step = this.props.step as number;
        const round = delta > 0 ? Math.floor : Math.ceil;

        if (step < 1) {
            // for better accuracy, calc with steps > 1
            step = 1 / step;
            return (round(this.getNumberValue() * step) + delta) / step;
        } else {
            return (round(this.getNumberValue() / step) + delta) * step;
        }
    }

    increment = () => {
        this.updateValue(
                this.calcNextStepValue(1)
        );
    };

    decrement = () => {
        this.updateValue(
                this.calcNextStepValue(-1)
        );
    };

    canIncrement = (number: number) => {
        return isNotDefined(this.props.max) || number < this.props.max;
    };

    canDecrement = (number: number) => {
        return isNotDefined(this.props.min) || number > this.props.min;
    };

    updateValue = (value: number) => {
        let newVal = value;

        if (isDefined(this.props.max) && newVal > this.props.max) {
            newVal = this.props.max;
        }

        if (isDefined(this.props.min) && newVal < this.props.min) {
            newVal = this.props.min;
        }

        if (this.props.precision) {
            newVal = parseFloat(newVal.toFixed(this.props.precision));
        }

        // always focus the input before changing the value, so validations and _wasChangedSinceBlur flag works correctly
        this.inputRef.current?.focus();

        this.props.onChange?.({
            value: newVal,
            triggerAdditionalTasks: true
        });
    };
}
