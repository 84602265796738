import { getCommonFilterDefs } from "@components/smart/GeneralFieldDefinition";
import { FilterBarGroup, getDefaultFilterGroupDef } from "@components/smart/smartFilterBar/SmartFilterBar.types";
import { ISummaryItem } from "@components/smart/smartSummaryItem/SmartSummaryItem";
import { EntitySetName, EntityTypeName, PrPensionInsuranceCompanyEntity } from "@odata/GeneratedEntityTypes";
import { PrInsuranceCompanyTypeCode } from "@odata/GeneratedEnums";
import { generalBankAccountFormatter } from "@utils/BankUtils";
import i18next from "i18next";

import { Sort } from "../../../enums";
import { IFormDef } from "../../../views/formView/Form";
import { ISplitPageTableDef } from "../../../views/table/TableView.utils";
import { paymentDetailFields, SAVED_ACCOUNTS_PATH } from "../../banks/bankAccounts/BankAccounts.utils";
import { addBankAccountTableDefs } from "../../documents/DocumentCommonDefs";
import { setDefByEntityType } from "../../getDefByEntityType";
import { IDefinition, IGetDefinition } from "../../PageUtils";
import {
    getInsuranceCompaniesCommonColumnDef,
    getInsuranceCompaniesCommonFieldDef,
    getNameFieldDef
} from "../healthInsuranceCompany/insuranceCompanies.utils";
import PensionInsuranceCompanyFormView from "./PensionInsuranceCompanyFormView";

export const insuranceCompanyAccountAdditionalFields = [{
    id: "AccountNumber"
}, {
    id: "PaymentServiceID"
}, {
    id: "BankCode"
}, {
    id: "AbaNumber"
}, {
    id: "Country"
}, {
    id: "IBAN"
}];

export const getDefinitions: IGetDefinition = (): IDefinition => {
    const table: ISplitPageTableDef = {
        id: `${EntityTypeName.PrPensionInsuranceCompany}Table`,
        title: i18next.t("InsuranceCompanies:PensionInsurance.Title"),
        filterBarDef: [{
            ...getDefaultFilterGroupDef(FilterBarGroup.Filters),
            defaultFilters: [
                PrPensionInsuranceCompanyEntity.Name,
                PrPensionInsuranceCompanyEntity.IsActive
            ],
            filterDefinition: {
                ...getCommonFilterDefs(),
                [PrPensionInsuranceCompanyEntity.Name]: {},
                [PrPensionInsuranceCompanyEntity.IsActive]: {}
            },
            isValueHelp: true
        }],
        initialSortBy: [{ id: PrPensionInsuranceCompanyEntity.Name, sort: Sort.Desc }],
        columns: [
            PrPensionInsuranceCompanyEntity.Name,
            PrPensionInsuranceCompanyEntity.BankAccount,
            PrPensionInsuranceCompanyEntity.IsActive,

        ],
        columnDefinition: {
            [PrPensionInsuranceCompanyEntity.Name]: {},
            [PrPensionInsuranceCompanyEntity.BankAccount]: {
                additionalProperties: insuranceCompanyAccountAdditionalFields,
                formatter: generalBankAccountFormatter
            },
            ...getInsuranceCompaniesCommonColumnDef()
        },
    };

    const summary: ISummaryItem[] = [
        { id: PrPensionInsuranceCompanyEntity.Name }
    ];

    const form: IFormDef = {
        id: `${EntityTypeName.PrPensionInsuranceCompany}Form`,
        translationFiles: getDefinitions.translationFiles,
        isDeletable: true,
        formControl: PensionInsuranceCompanyFormView,
        summary,
        additionalProperties: [],
        fieldDefinition: {
            "Name": {
                ...getNameFieldDef(PrInsuranceCompanyTypeCode.PensionInsuranceCompany)
            },
            ...getInsuranceCompaniesCommonFieldDef()
        },
        groups: [
            {
                id: "main",
                rows: [
                    [{ id: PrPensionInsuranceCompanyEntity.Name },
                        { id: PrPensionInsuranceCompanyEntity.DataBoxId }, { id: PrPensionInsuranceCompanyEntity.IsActive }]
                ]
            },
            {
                id: "bankAccount",
                title: i18next.t("InsuranceCompanies:BankAccounts"),
                rows: [[
                    { id: SAVED_ACCOUNTS_PATH }
                ], [
                    ...paymentDetailFields
                ]]
            }
        ]
    };

    const def = {
        entitySet: EntitySetName.PrPensionInsuranceCompanies,
        table,
        form
    };

    addBankAccountTableDefs(def);

    return def;
};

getDefinitions.translationFiles = ["InsuranceCompanies", "Common"];
setDefByEntityType(EntityTypeName.PrPensionInsuranceCompany, getDefinitions);