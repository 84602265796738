import React from "react";
import memoizeOne from "../../utils/memoizeOne";
import { getValue, handleRefHandlers } from "@utils/general";
import { IAlertProps } from "../alert/Alert";
import PopperAlert from "../alert/PopperAlert";
import { buttonAlertPopperOptions } from "../../views/table/ConfirmationButtons";
import { IProps } from "../button";

export interface IRenderClickable {
    renderClickable: (onClick: (event: React.MouseEvent, ...args: any[]) => void, handleRef: (element: any) => void) => React.ReactElement;
    onClick?: (event: React.MouseEvent, ...args: any[]) => void;
}

/** Handles alert message for disabled clickable components (e.g. Button) */
export class ClickableWithAlertBase extends React.PureComponent<Pick<IProps, "alert" | "isDisabled" | "isBusy" | "type" | "alertPopperOptions" | "passRef"> & IRenderClickable> {
    getHandleRef = memoizeOne((ref?: React.Ref<any>) => {
                return (element: any) => {
                    handleRefHandlers(element, this.props.passRef, ref);
                };
            }
    );

    getAlert = (): IAlertProps => {
        if (!this.props.alert) {
            return null;
        }

        return getValue(this.props.alert);
    };

    getHandleClickWithAlert = (setAlert: ((alert: IAlertProps) => void)) => {
        return (event: React.MouseEvent, ...args: unknown[]) => {
            setAlert(this.getAlert());

            if (this.props.type === "submit") {
                // prevent submit button from calling form.onSubmit when clicked
                event.preventDefault();
            }

            if (!this.props.isDisabled && !this.props.isBusy) {
                this.props.onClick?.(event, ...args);
            }
        };
    };

    handleClickWithoutAlert = (event: React.MouseEvent, ...args: unknown[]) => {
        if (this.props.type === "submit") {
            // prevent submit button from calling form.onSubmit when clicked
            event.preventDefault();
        }

        if (!this.props.isDisabled && !this.props.isBusy) {
            this.props.onClick?.(event, ...args);
        }
    };

    render() {
        if (!this.props.alert) {
            return this.props.renderClickable(this.handleClickWithoutAlert, this.getHandleRef());
        }

        return (
            <PopperAlert {...this.props.alertPopperOptions ?? buttonAlertPopperOptions}>
                {(ref: React.Ref<any>, setAlert: ((alert: IAlertProps) => void)) => {
                    return this.props.renderClickable(this.getHandleClickWithAlert(setAlert), this.getHandleRef(ref));
                }}
            </PopperAlert>
        );
    }
}