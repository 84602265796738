import { AppContext } from "../../../contexts/appContext/AppContext.types";
import { FormViewForExtend, IFormViewProps } from "../../../views/formView/FormView";
import { IPrAbsenceCategoryEntity } from "@odata/GeneratedEntityTypes";
import { withPermissionContext } from "../../../contexts/permissionContext/withPermissionContext";

interface IProps extends IFormViewProps<IPrAbsenceCategoryEntity, null> {
}

class AbsenceCategoryFormView extends FormViewForExtend<IPrAbsenceCategoryEntity, IProps> {
    static contextType = AppContext;
    static defaultProps = {
        title: "AbsenceCategory:FormTitle"
    };
}

export default withPermissionContext(AbsenceCategoryFormView);