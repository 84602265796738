import { darken, desaturate, ellipsis, lighten, saturate } from "polished";
import styled, { css } from "styled-components/macro";

import { Status } from "../../enums";
import {
    fadeOutAnimation,
    getSemanticBgColor,
    getSemanticElColor,
    LocalStackOrder,
    T_ALERT_SUBTITLE,
    T_HEADER_small,
    T_TITLE_3
} from "../../global.style";
import { AlertPadding, AlertPosition } from "./Alert";

interface IAlertStatus {
    status: Status;
    useFade?: boolean;
    position?: AlertPosition;
}

const ACTION_ICON_RIGHT_MARGIN = 17;
const ACTION_ICON_RIGHT_MARGIN_SMALL = 2;
const ACTION_ICON_SIZE = 26;
const ACTION_ICON_TOP = 12;

interface IAlert {
    // width max-content, but smaller than 500px
    _small?: boolean;
    // width 100%
    _isFullWidth?: boolean;
    // type of alert padding
    _padding?: AlertPadding;
    // whether content should be shown in one line
    _inline?: boolean;
    _shouldAddBottomMargin?: boolean;

    _hasInlineDetailAndCloseIcon?: boolean;
}

export const StyledAlert = styled.div<IAlertStatus & IAlert>`
    position: relative;
    width: ${props => props._isFullWidth ? "100%" : "max-content"};
    max-width: ${props => props._small ? "400px" : (!props._isFullWidth ? "700px" : "none")};
    color: ${props => props.theme.C_TEXT_primary};
    margin-bottom: ${props => props._shouldAddBottomMargin ? "19px" : null};
    z-index: ${LocalStackOrder.Tooltips};

    &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: ${props => props._small ? 0 : "22px"};
        border-radius: 3px;
        background-color: ${props => getSemanticBgColor(props.theme, props.status, !props._small)};
        z-index: -1;

        ${props => props.position !== AlertPosition.Default && css`
            // absolutely positioned alerts have shadow
            box-shadow: rgba(0, 0, 0, 0.15) 0 0 5px 0;
        `}
    }

    ${props => props.useFade && css`
        ${fadeOutAnimation()}
    `};

    ${props => props.position !== AlertPosition.Default && css`
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: ${props.position === AlertPosition.CenteredBottom ? "150px" : "auto"};
    `}
    & + & {
        margin-top: 5px;
    }
`;

export const StatusIconWrapper = styled.div`
    position: absolute;
    width: 42px;
    height: 42px;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
`;

export const ActionWrapper = styled.div<{ status: Status; isSmall: boolean; isInline: boolean; }>`
    display: flex;
    position: absolute;
    top: ${props => !(props.isSmall || props.isInline) ? `${ACTION_ICON_TOP}px` : "50%"};
    right: ${props => props.isSmall ? `${ACTION_ICON_RIGHT_MARGIN_SMALL}px` : `${ACTION_ICON_RIGHT_MARGIN}px`};
    transform: ${props => !(props.isSmall || props.isInline) ? null : "translateY(-50%)"};

    button {
        &:hover {
            color: ${props => saturate(0.1, lighten(0.1, getSemanticElColor(props.theme, props.status)))};
        }

        &:active {
            color: ${props => desaturate(0.1, darken(0.1, getSemanticElColor(props.theme, props.status)))};
        }
    }
`;

const OneLinerPadding = 14;
export const OneLinerHeight = 22 + 2 * OneLinerPadding;

const getBodyPadding = (settings: IAlert): string => {
    switch (settings._padding) {
        case AlertPadding.L:
            return "17px 60px 17px 81px";
        case AlertPadding.M:
            return `${OneLinerPadding}px 19px ${OneLinerPadding}px 81px`;
        case AlertPadding.S:
            return "7px 12px";
    }

};

export const Body = styled.div<IAlert>`
    padding: ${props => getBodyPadding(props)};
    padding-right: ${props => props._hasInlineDetailAndCloseIcon ? (props._small ? `${2 * ACTION_ICON_RIGHT_MARGIN_SMALL + ACTION_ICON_SIZE}px` : `${2 * ACTION_ICON_RIGHT_MARGIN + ACTION_ICON_SIZE}px`) : null};
    white-space: ${props => props._inline ? "default" : "pre-wrap"};
    word-break: break-word;

    ${props => props._inline && ellipsis()};
    display: ${props => props._inline ? "block" : "flex"};
    justify-content: flex-start;
    align-items: flex-start;

    ${props => !props._inline && css`
        flex-direction: column;
    `};
`;

export const Title = styled.h4<IAlert & { disableDefaultStyles: boolean }>`
    ${props => !props._small && css<{ disableDefaultStyles: boolean }>`
        ${T_HEADER_small};
        font-weight: ${props => props.disableDefaultStyles ? "default" : "bold"};
    `};

    ${props => props._small && css`
        ${T_ALERT_SUBTITLE};
    `};

    ${props => props._inline && css`
        margin-right: 1ex;
        display: inline;
    `};
`;

export const Subtitle = styled.div<IAlert & IAlertStatus & { _showDivider: boolean }>`
    width: 100%;
    margin: 0;
    ${T_TITLE_3};

    ${props => !props._inline && props._showDivider && css<IAlertStatus>`
        &::before {
            content: " ";
            display: block;
            height: 1px;
            margin: 12px 0;
            background-color: ${props => getSemanticElColor(props.theme, props.status)};
        }
    `};

    ${props => props._inline && css`
        display: inline;
    `};

    ${Title} + & {
        margin-top: 2px;
    }
`;

export const ObjectWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 3px;
    max-width: 544px;
    min-width: 300px;
    max-height: 235px;
`;

export const ObjectContent = styled.div<{ isString: boolean; }>`
    ${props => props.isString && css`
        word-break: break-word;
    `};
`;

export const AlertDetailWrapper = styled.div <{
    isInline: boolean;
    isSmall?: boolean;
}>`
    ${props => !props.isInline && `
        position: absolute;
        bottom: ${ACTION_ICON_TOP}px;
        right: ${props.isSmall ? `${ACTION_ICON_RIGHT_MARGIN_SMALL}px` : `${ACTION_ICON_RIGHT_MARGIN}px`};
    `};
`;