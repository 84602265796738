import React, { useMemo } from "react";
import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    ChartData,
    ChartOptions,
    Legend,
    LinearScale,
    Tooltip
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { ChartWrapperStyled } from "./Charts.styles";
import { PropsWithTheme } from "../../theme";
import { withTheme } from "styled-components/macro";
import {
    ChartType,
    getChartTooltipOptions,
    getDefaultScalesOptions,
    IChartComponentData,
    IChartProps,
    legend,
    legendHeight
} from "./Charts.types";

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

type TBarChartOptions = ChartOptions<ChartType.Bar>;
type TBarChartData = ChartData<ChartType.Bar>;

export interface IBarChartComponentData extends IChartComponentData<number[]> {
    dataSetLabels?: string[];
}

interface IProps extends PropsWithTheme, IChartProps<IBarChartComponentData> {
}

const BarChart: React.FC<IProps> = (props) => {
    const { data: { labels, dataSetLabels, colors, values }, formatter, width, height, responsive, theme } = props;

    const hasLegend = !!dataSetLabels;
    const options = useMemo<TBarChartOptions>(() => ({
        plugins: {
            legend: hasLegend ? legend : { display: false },
            title: { display: false },
            tooltip: getChartTooltipOptions(formatter)
        },
        layout: {
            padding: {
                bottom: hasLegend ? 0 : legendHeight
            }
        },
        scales: getDefaultScalesOptions(theme, formatter.bind(null, "y")),
        responsive
    }), [responsive, hasLegend, theme, formatter]);

    const data = useMemo<TBarChartData>(() => ({
        labels,
        datasets: values.map((dateSetValues, idx) => ({
            label: dataSetLabels?.[idx] ?? "",
            data: dateSetValues,
            backgroundColor: theme[colors[idx % colors.length]]
        }))
    }), [labels, dataSetLabels, values, colors, theme]);

    return (
        <ChartWrapperStyled className={props.className}>
            <Bar data={data} options={options} width={width} height={height}/>
        </ChartWrapperStyled>
    );
};

export default withTheme(BarChart);
