import React from "react";
import SimpleTable from "../../../../components/simpleTable";
import { IDeferredItemTemplate } from "./TimeResolution.utils";
import { IColumn, IRow } from "@components/table";
import { useTranslation } from "react-i18next";
import { RowType } from "../../../../enums";
import DateType, { DateFormat, getUtcDayjs } from "../../../../types/Date";
import { formatCurrency } from "../../../../types/Currency";

interface IProps {
    items: IDeferredItemTemplate[];
    currency: string;
}

export const TimePlanTable: React.FunctionComponent<IProps> = (props) => {
    const { t } = useTranslation(["TimeResolution"]);
    const columns: IColumn[] = [
        { id: "period", label: `${t("TimeResolution:TimePlanTable.Period")}:` },
        { id: "docType", label: `${t("TimeResolution:TimePlanTable.DocType")}:` },
        { id: "amount", label: `${t("TimeResolution:TimePlanTable.Amount")}:` }
    ];
    const today = getUtcDayjs().startOf("day");
    const rows: IRow[] = [];
    const itemsByYears: Record<string, IDeferredItemTemplate[]> = {};
    let firstYear: string;

    for (const item of props.items) {
        const year = DateType.format(item.dateAccountingTransaction, DateFormat.year);

        if (!firstYear) {
            firstYear = year;
        }

        if (!itemsByYears[year]) {
            itemsByYears[year] = [];
        }

        itemsByYears[year].push(item);
    }

    for (const year of Object.keys(itemsByYears)) {
        const childRows: IRow[] = [];

        for (const item of itemsByYears[year]) {
            childRows.push({
                id: item.dateAccountingTransaction.toString(),
                values: {
                    period: DateType.format(item.dateAccountingTransaction),
                    docType: t(`TimeResolution:TimePlanTable.${getUtcDayjs(item.dateAccountingTransaction).isSameOrBefore(today, "date") ? "InternalDocument" : "PlannedDocument"}`) as string,
                    amount: formatCurrency(item.amount, props.currency)
                }
            });
        }

        rows.push({
            id: year,
            values: { period: year },
            type: RowType.Group,
            rows: childRows,
            open: true
        });
    }

    return (
        <SimpleTable rows={rows} columns={columns}/>
    );
};