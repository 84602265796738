import { StyledChat } from "@components/chat/Chat.styles";
import { StyledFileStrip } from "@components/fileStrip/FileStrip.styles";
import { DropArea, DROPAREA_PADDING, DropAreaWithPadding } from "@components/fileUploader/FileUploader.styles";
import { StyledFileViewer } from "@components/fileViewers/FileViewers.styles";
import {
    Bubble,
    BubbleWrapper,
    PaneGradient,
    ShadowStyles,
    Splitter,
    StyledSplitLayout
} from "@components/splitLayout/SplitLayout.styles";
import { RowShadow, StyledValueRow } from "@components/table/Rows.styles";
import { BeforeRowContentWrapper } from "@components/table/Table.styles";
import { ApprovalStatusTypeCode } from "@odata/GeneratedEnums";
import styled, { css } from "styled-components/macro";

import Clickable from "../../components/clickable";
import ProgressBar from "../../components/progressBar";
import { Pane, SplitLayout } from "../../components/splitLayout";
import { LocalStackOrder, T_TITLE_2, T_TITLE_5 } from "../../global.style";
import { Theme } from "../../theme";
import { TableWrapper } from "../../views/table/TableView.styles";
import View from "../../views/View";
import { StyledViewWrapper, VIEW_PADDING_VALUE } from "../../views/View.styles";

const COLLAPSED_SPLITTER_WIDTH = 38;
const HOVER_SPLITTER_WIDTH = 53;
export const InboxFakeSplitterStyled = styled(Clickable)`
    height: 100%;
    flex: 0 0 ${COLLAPSED_SPLITTER_WIDTH}px;
    transition: flex-basis .2s ease-in-out;
    transition-delay: 0s;
    ${ShadowStyles};

    ${BubbleWrapper} {
        left: 100%;
    }

    &, ${Bubble} {
        background-color: ${props => props.theme.C_BG_floorplan};
    }

    &:hover {
        flex-basis: ${HOVER_SPLITTER_WIDTH}px;
        transition-delay: .4s;

        &, ${Bubble} {
            background-color: ${props => props.theme.C_SPLIT_LAYOUT_BUBBLE};
        }
    }
`;

export const InboxSplitPageStyled = styled.div<{ $stripOnTheLeft: boolean }>`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;

    ${StyledSplitLayout} {
        overflow: visible;
    }

    ${InboxFakeSplitterStyled}, ${StyledFileStrip}, ${StyledSplitLayout} {
        position: relative;
    }

    ${InboxFakeSplitterStyled} {
        z-index: ${LocalStackOrder.AboveContent + 1};
    }

    ${StyledFileStrip} {
        ${props => props.$stripOnTheLeft && ShadowStyles};
        z-index: ${LocalStackOrder.AboveContent};
        flex: 1 1 0;
    }

    ${StyledSplitLayout}, ${Splitter} {
        z-index: ${props => LocalStackOrder.AboveContent + (props.$stripOnTheLeft ? -1 : 1)};
    }

    ${StyledSplitLayout} {
        flex: 1 1 0;
    }
`;

export const TableWrapperStyled = styled(TableWrapper)<{ isDragging: boolean }>`
    ${DropArea} {
        height: 100%;
        ${DropAreaWithPadding};
    }

    ${props => props.isDragging && css`
        ${BeforeRowContentWrapper}, ${StyledValueRow}, ${RowShadow} {
            &[aria-selected="true"] {
                opacity: 0;
            }
        }
    `}
`;

export const SendToAccountantListStyled = styled(View)`
    ${DropArea} {
        ${DropAreaWithPadding};
        margin-top: ${DROPAREA_PADDING}px;
    }
`;

export const ProgressBarStyled = styled(ProgressBar)`
    min-width: 340px;
`;

export const ProgressBarWrapper = styled.div <{ isVisible: boolean }>`
    margin-top: -15px;
    margin-bottom: -18px;
    visibility: ${props => props.isVisible ? 'visible' : 'hidden'};
`;
export const SplitLayoutStyled = styled(SplitLayout)`
    overflow: visible;
`;

export const PaneStyled = styled(Pane)<{
    order: number;
    addShadow?: boolean;
    addBackground?: boolean;
}>`
    position: relative;
    // first panes in DOM needs to be above the next ones as the shadows are on right side
    z-index: ${props => 10 - props.order};

    ${props => props.addBackground && `background-color: ${props.theme.C_BG_floorplan}`};
    ${props => props.addShadow && ShadowStyles};
`;

interface IStatus {
    _status: ApprovalStatusTypeCode;
}

export const FileIconWithStatusStyled = styled.div<IStatus>`
    position: relative;

    ::before {
        display: block;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(0, -50%);
        content: " ";
        width: 2px;
        height: 23px;
        border-radius: 1px;
        background-color: ${props => props.theme[getStatusColor(props._status)]};
    }
`;

export function getStatusColor(status: ApprovalStatusTypeCode): keyof Theme {
    switch (status) {
        case ApprovalStatusTypeCode.Pending:
            return "C_SEM_text_warning";
        case ApprovalStatusTypeCode.Rejected:
            return "C_SEM_text_bad";
        default:
            return "C_SEM_text_good";
    }
}

export const StatusText = styled.span<IStatus>`
    color: ${props => props.theme[getStatusColor(props._status)]};
    text-transform: uppercase;
`;

export const OtherFolderWindowStyled = styled.div`
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: stretch;
    justify-content: stretch;

    ${PaneGradient};

    ${StyledFileStrip} {
        ${ShadowStyles};
        flex: 0 0 auto;
    }

    ${StyledViewWrapper} {
        flex: 1 1 auto;
    }
`;

export const StyledTextInDialog = styled.pre<{ $isPlaceholder: boolean; }>`
    text-align: ${props => props.$isPlaceholder ? "center" : "left"};
    white-space: pre-wrap;
    padding: 19px;
    ${props => props.$isPlaceholder && css`
        font-style: italic;
    `}
`;

export const InboxFileDetailStyled = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    max-height: 100%;
    max-width: 100%;
    padding: ${VIEW_PADDING_VALUE}px;

    ${StyledFileViewer} {
        flex: 1 1 auto;
        height: 0;
        margin-bottom: 15px;
    }

    ${StyledChat} {
        flex: 0 0 auto;
        height: fit-content;
    }
`;

export const NoAttachmentText = styled.em`
    font-style: italic;
    color: ${props => props.theme.C_ACT_thick_line};
`;

export const MultipleFileSelectionWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    // todo: temporary
    ${T_TITLE_2};
`;

export const InboxApprovalStatusTooltip = styled.div<IStatus>`
    ${T_TITLE_5};
    color: ${props => props.theme[getStatusColor(props._status)]};
`;
