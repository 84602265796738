import { CompanyPermissionCode, GeneralPermissionCode } from "@odata/GeneratedEnums";
import { IEntity } from "@odata/BindingContext";
import { TableStorage } from "../model/TableStorage";
import { FormStorage } from "../views/formView/FormStorage";
import { IPermissionContext } from "../contexts/permissionContext/PermissionContext";
import { Model } from "../model/Model";
import { isEntityOwner } from "@pages/admin/users/Users.utils";

export const canUnlock = (storage: FormStorage | TableStorage, generalPermissions: Set<GeneralPermissionCode>, entity: IEntity): boolean => {
    return generalPermissions.has(GeneralPermissionCode.Unlocking) ||
            entity[storage.data.definition.lockProperty]?.every?.((lock: IEntity) => lock.CreatedBy?.Id === storage.context.getData()?.userSettings?.Id);
};

export function checkPermissionWithOwnPermission(storage: Model, permissionContext: IPermissionContext, permission: CompanyPermissionCode): boolean {
    return permissionContext.ownOnlyPermissions.has(permission) ? isEntityOwner(storage.context, storage.data.entity)
        : permissionContext.companyPermissions.has(permission);
}