import {
    ISelectionChangeArgs,
    ISelectItem,
    ISelectPropsBase,
    TOnItemsFetchedCallback
} from "@components/inputs/select/Select.types";
import { IFieldInfo } from "@odata/FieldInfo.utils";
import {
    BillingAddressEntity,
    BusinessPartnerEntity,
    CountryEntity,
    EntitySetName,
    IBusinessPartnerEntity
} from "@odata/GeneratedEntityTypes";
import { CountryCode } from "@odata/GeneratedEnums";
import { transformToODataString } from "@odata/OData.utils";
import { WithOData, withOData } from "@odata/withOData";
import { fetchAresDetail, loadPartner } from "@pages/businessPartner/BusinessPartner.utils";
import { debounce } from "lodash";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import { ARES_SEARCH_API, INPUT_DEBOUNCE_TIME } from "../../../constants";
import { AppContext } from "../../../contexts/appContext/AppContext.types";
import { ValueType } from "../../../enums";
import { TValue } from "../../../global.types";
import BindingContext, { IEntity } from "../../../odata/BindingContext";
import TestIds from "../../../testIds";
import memoizeOne from "../../../utils/memoizeOne";
import { FormStorage } from "../../../views/formView/FormStorage";
import { IFieldDef } from "../../smart/FieldInfo";
import { prepareColumns } from "../../smart/smartTable/SmartTable.utils";
import { BasicSelect } from "../select/BasicSelect";
import { AresSpecialGroupTitleWithoutLine, GroupSubTitle } from "../select/Select.styles";

interface IProps extends ISelectPropsBase, WithOData, WithTranslation {
    columns: IFieldDef[];
    bindingContext?: BindingContext;
    loadFromDb?: boolean;
    storage: FormStorage;
}

interface IState {
    items: ISelectItem[];
    columns: IFieldInfo[];
}

export interface IMultiFieldSelectChange extends ISelectionChangeArgs {
}

const ARES_MAX_ITEMS = 50;

class SingleBusinessPartnerSelect extends React.Component<IProps, IState> {
    static contextType = AppContext;
    //sadly, breaks typescript type checking
    //context: React.ContextType<typeof AppContext>;

    public static defaultProps = {
        allowCreate: true
    };

    _searchValue = "";
    _selectRef = React.createRef<any>();

    constructor(props: IProps) {
        super(props);
        this.state = {
            items: [],
            columns: []
        };
    }

    componentDidMount = () => {
        this.updateColumns((this.props.columns || []).map(col => col));
    };

    componentWillUnmount() {
        this.fetchSuggestions.cancel();
    }

    updateColumns = memoizeOne(async (columnNames: IFieldDef[]) => {
        const columns = await prepareColumns({
            bindingContext: this.props.bindingContext.getParent(),
            context: this.context,
            columns: columnNames
        });

        this.setState({
            columns
        });
    });

    _transformAresItem = (item: IEntity, isSearchedByName: boolean, countryCode: CountryCode.CzechRepublic | CountryCode.Slovakia): ISelectItem => ({
        id: "ARES-" + item.IdentificationNumber,
        groupId: `ares${countryCode}`,
        label: isSearchedByName ? item.CompanyName : item.IdentificationNumber,
        additionalData: {
            isAres: true,
            LegalNumber: item.IdentificationNumber,
            Name: item.CompanyName,
            Country: {
                Code: countryCode,
                IsEuMember: true,
                IsEEA: true
            }
        },
        tabularData: [item.CompanyName, item.IdentificationNumber]
    });

    _transformDbItem = (item: IEntity, isSearchedByName: boolean): ISelectItem => ({
        id: item.Id,
        groupId: "db",
        label: isSearchedByName ? item.Name : item.LegalNumber,
        additionalData: {
            isAres: false,
            Id: item.Id,
            LegalNumber: item.LegalNumber,
            Name: item.Name,
            Country: item.BillingAddress.Country
        },
        tabularData: [item.Name, item.LegalNumber]
    });

    fetchFromARES = async (searchValue: string, isSearchedByName: boolean, country: CountryCode.CzechRepublic | CountryCode.Slovakia): Promise<ISelectItem[]> => {
        if (searchValue?.length < 3) {
            // ares doesn't support search for string shorter than 3 chars
            return [];
        }

        const searchType = isSearchedByName ? "CompanyNameOnly" : "IdentificationNumberOnly";
        const result = await fetch(`${ARES_SEARCH_API}?country=${country}&count=${ARES_MAX_ITEMS}&searchText=${searchValue}&searchType=${searchType}`);
        const rawItems = await result.json();

        return (rawItems.Records || []).map((item: IEntity) => this._transformAresItem(item, isSearchedByName, country));
    };

    fetchFromDB = async (searchValue: string, isSearchedByName: boolean): Promise<ISelectItem[]> => {
        const propName = isSearchedByName ? "Name" : "LegalNumber";
        const filter = `startswith(${propName},${transformToODataString(searchValue, ValueType.String)})`;

        const response = await this.props.oData
            .getEntitySetWrapper(EntitySetName.BusinessPartners)
            .query()
            .expand("BillingAddress", q => {
                q.expand("Country", (q) => {
                    q.select(CountryEntity.IsEuMember, CountryEntity.IsEEA, CountryEntity.Code, CountryEntity.Name);
                });
            })
            .select("Id", "Name", "LegalNumber")
            .filter(filter)
            .fetchData<IBusinessPartnerEntity[]>();

        return (response.value || []).map((item: IEntity) => this._transformDbItem(item, isSearchedByName));
    };

    fetchSuggestions = debounce(async (value: string, onFetched?: TOnItemsFetchedCallback) => {
        if (value) {
            this._searchValue = value;
            const isSearchedByName = this.props.bindingContext.getPath().includes("Name");

            let dbItems: ISelectItem[] = [];
            this.setBottomMenuContent(value);

            const promiseAres = Promise.all([
                this.fetchFromARES(value, isSearchedByName, CountryCode.CzechRepublic),
                this.fetchFromARES(value, isSearchedByName, CountryCode.Slovakia)
            ]);

            if (this.props.loadFromDb) {
                const promiseDB = this.fetchFromDB(value, isSearchedByName);

                dbItems = await promiseDB;
                if (this._searchValue === value) {
                    this.setState({
                        items: dbItems
                    });
                }
            }

            const aresItems = await promiseAres;
            this.setLoadingStatus(false);
            if (this._searchValue === value) {
                const items = dbItems.concat(...aresItems);
                onFetched?.(items);
                this.setState({
                    items
                });
            }
        }
    }, INPUT_DEBOUNCE_TIME);

    isAres = (value: TValue) => {
        return value?.toString().startsWith("ARES");
    };

    handleChange = async (args: ISelectionChangeArgs) => {
        let loadedDetail: IBusinessPartnerEntity;
        if (args.triggerAdditionalTasks) {
            const Id = args.additionalData?.Id;
            // we send id to the upper states to the states, as the ID should be ignored

            // loads full BP detail to additional Data
            if (args.additionalData?.isAllowCreateItem) {
                const { bindingContext, storage } = this.props;
                const partnerBc = bindingContext.getParent();
                const propName = bindingContext.getPath();
                const value = args.additionalData.value;
                delete args.additionalData.value;

                loadedDetail = {
                    [propName]: value,
                    [BusinessPartnerEntity.BillingAddress]: {
                        [BillingAddressEntity.Country]: {
                            Code: CountryCode.CzechRepublic
                        },
                        [BillingAddressEntity.PostalCode]: ""
                    }
                };

                if (propName === BusinessPartnerEntity.LegalNumber) {
                    loadedDetail[BusinessPartnerEntity.Name] = storage.getValue(partnerBc.navigate(BusinessPartnerEntity.Name));
                }
            } else if (Id) {
                // load from DB
                loadedDetail = await loadPartner(Id, this.props.oData);
            } else {
                // load from ARES
                loadedDetail = await fetchAresDetail(args.additionalData.LegalNumber, args.additionalData.Country?.Code);
            }

            args.additionalData = {
                ...args.additionalData,
                ...loadedDetail
            };
        }

        this.props.onChange(args);
    };

    handleSuggestionsFetchRequested = (value: string, onFetched?: TOnItemsFetchedCallback) => {
        this.setLoadingStatus(true);
        if (value?.length > 2 || this.state.items.length === 0) {
            delete this._selectRef.current.sharedData.bottomMenuCustomContent;
        }

        this._selectRef.current.sharedData.dontDisplayNoDataFound = !this.props.loadFromDb && value?.length < 3;
        this.fetchSuggestions(value, onFetched);
    };

    setLoadingStatus = (isLoading: boolean) => {
        if (this._selectRef.current) {
            this._selectRef.current.sharedData.isLoading = isLoading;
        }
    };

    setBottomMenuContent = (value: string) => {
        if (value && value.length < 3) {
            this._selectRef.current.sharedData.bottomMenuCustomContent = (
                <AresSpecialGroupTitleWithoutLine data-testid={TestIds.SelectMenuGroup}>
                    <span>ARES</span>
                    <GroupSubTitle>Vyhledává až od tří napsaných znaků</GroupSubTitle>
                </AresSpecialGroupTitleWithoutLine>
            );
        } else {
            delete this._selectRef.current.sharedData.bottomMenuCustomContent;
        }
    };

    render() {
        return (
            <BasicSelect
                ref={this._selectRef}
                useAutoSelection={false}
                closeOnSelection
                {...this.props}
                items={this.state.items}
                onBlur={this.props.onBlur}
                onChange={this.handleChange}
                onItemsFetchRequested={(value: string, onFetched: TOnItemsFetchedCallback) => this.handleSuggestionsFetchRequested(value, onFetched)}
            />
        );
    }
}

export default withTranslation("Common")(withOData(SingleBusinessPartnerSelect));