import SmartField from "../../components/smart/smartField/SmartField";
import styled from "styled-components/macro";
import { T_HEADER_bold } from "../../global.style";

export const StyledSmartFieldTemplate = styled(SmartField)`
  margin-top:10px;
  margin-bottom: 26px;
`;

export const AddRow = styled.div`
  display: flex;
  padding-left: 17px;
  align-items: center;
  &> * {
    margin-right: 20px;
  }
`;

export const AddRowLabel = styled.span`
  color: ${props => props.theme.C_SPLIT_LAYOUT_COLLAPSED_GRADIENT_TOP}
`;

export const StyledInfoText = styled.div`
    ${T_HEADER_bold};
`;

export const StyledInfoWrapper = styled.div `
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`;
