import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";

import { ApplicationSessionTypes, getInitialAppRedirectUrl, SessionType } from "../../contexts/authContext/Auth.utils";
import {
    ROUTE_EMAIL_CONFIRM,
    ROUTE_LOGIN,
    ROUTE_LOGIN_DEVICE,
    ROUTE_LOGIN_INVITATION,
    ROUTE_LOGIN_TENANT,
    ROUTE_LOGIN_VERIFY
} from "../../routes";
import { AnimationPlayStatus, getTenantRelatedLoginOption, MAX_ANIM_TIME } from "../Login.utils";
import { useLoginSelector } from "../state/hooks";
import { selectInvitationCode } from "../state/invitationSlice";
import {
    selectAnimationStatus,
    selectLoginOption,
    selectNextURL,
    selectPostLoginVerifications
} from "../state/loginSlice";
import { selectIsSessionLoaded, selectSessionData } from "../state/sessionSlice";
import TenantRedirect from "../TenantRedirect";

interface IProps {
    skipNoSessionCheck?: boolean;
    skipActiveSessionCheck?: boolean;
}

const Redirects: React.FC<IProps> = ({ skipNoSessionCheck, skipActiveSessionCheck }) => {
    const isSessionLoaded = useLoginSelector(selectIsSessionLoaded);
    const session = useLoginSelector(selectSessionData);
    const invitationCode = useLoginSelector(selectInvitationCode);
    const postLoginVerifications = useLoginSelector(selectPostLoginVerifications) ?? [];
    const animationStatus = useLoginSelector(selectAnimationStatus);
    const nextURL = useLoginSelector(selectNextURL);
    const loginOption = useLoginSelector(selectLoginOption);
    const tenantId = loginOption?.TenantId;
    const tenantPostLoginVerifications = tenantId ? getTenantRelatedLoginOption(tenantId, postLoginVerifications) : [];

    const [force, setForce] = useState<boolean>(false);

    useEffect(() => {
        let tId: number;
        if (animationStatus === AnimationPlayStatus.Playing) {
            // Playing animation may block redirect, but only certain amount of time...
            // Force the redirect then even if the animation is still playing
            tId = window.setTimeout(() => {
                setForce(true);
            }, MAX_ANIM_TIME);
        } else {
            setForce(false);
        }
        return function cancelTimeout() {
            if (tId) {
                window.clearTimeout(tId);
            }
        };
    }, [animationStatus]);

    if (!isSessionLoaded || (animationStatus === AnimationPlayStatus.Playing && !force)) {
        // session not loaded
        return null;
    }

    const hasInvitation = !!invitationCode;

    switch (true) {
        case !session && !skipNoSessionCheck:
            return (
                <Redirect to={ROUTE_LOGIN}/>
            );
        case session?.Type === SessionType.Login && !session?.IsEmailVerified && !hasInvitation:
            return (
                <Redirect to={ROUTE_EMAIL_CONFIRM}/>
            );
        case session?.Type === SessionType.Login && !session?.IsVerifiedDevice && !hasInvitation:
            return (
                <Redirect to={ROUTE_LOGIN_DEVICE}/>
            );
        // keep after device check
        case tenantPostLoginVerifications?.length > 0:
            const verification = tenantPostLoginVerifications[0];

            return (
                <Redirect to={`${ROUTE_LOGIN_VERIFY}/${verification.VerificationGuid}`}/>
            );
        case session?.Type === SessionType.Login && !skipActiveSessionCheck:
            return (
                <Redirect
                    to={invitationCode ? [ROUTE_LOGIN_INVITATION, invitationCode].join("/") : ROUTE_LOGIN_TENANT}/>
            );
        case ApplicationSessionTypes.includes(session?.Type) && !skipActiveSessionCheck:
            return (
                <Redirect to={getInitialAppRedirectUrl(session, nextURL)}/>
            );
        case !!loginOption:
            return (
                    <TenantRedirect />
            );
    }

    return null;
};

export default Redirects;
