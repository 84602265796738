//@ts-nocheck
import { EntityTypeName } from "@odata/GeneratedEntityTypes";
import { CompanyPermissionCode, GeneralPermissionCode } from "@odata/GeneratedEnums";
import Page from "@pages/Page";
import React from "react";
import { RouteProps } from "react-router";
import { Switch } from "react-router-dom";

import AuditTrailView from "../components/auditTrail/AuditTrailView";
import { WithPermissionContext, withPermissionContext } from "../contexts/permissionContext/withPermissionContext";
import { AUDIT_TRAIL } from "../routes";
import { NoPermission } from "../views/notFound";
import PrivateRoute from "./PrivateRoute";

export interface IFormSwitchProps extends WithPermissionContext {
    page: React.ComponentType<any>;
    entityType: EntityTypeName;
    parentType?: string;
    childCollection?: string;
    permission: CompanyPermissionCode | GeneralPermissionCode;
}

class FormRoute extends React.PureComponent<RouteProps & IFormSwitchProps> {
    render() {
        let parentRoute = "";
        let parentId = "";

        const hasAuditTrailPermission = this.props.permissionContext.generalPermissions.has(GeneralPermissionCode.AuditTrail);

        if (this.props.childCollection) {
            // if parent is editable, load proper form data in split page manageUrl function
            parentId = "/:ParentId?";
            parentRoute = `/:ParentId/${this.props.childCollection}`;
        }

        const Page = this.props.page;

        return (
            <Switch location={this.props.location}>
                <PrivateRoute exact path={`${this.props.path}${parentId}`} permission={this.props.permission} render={() => {
                    return (
                        <Page childCollection={this.props.childCollection}/>
                    );
                }}/>
                <PrivateRoute path={`${this.props.path}/:Id/${AUDIT_TRAIL}`} permission={this.props.permission} render={() => {
                    if (!hasAuditTrailPermission) {
                        return <NoPermission/>;
                    }
                    return (
                            <Page childCollection={this.props.childCollection} auditTrailView={<AuditTrailView
                                    entityType={this.props.parentType || this.props.entityType}/>}/>
                    );
                }}/>
                <PrivateRoute path={`${this.props.path}${parentRoute}/:Id/${AUDIT_TRAIL}`} permission={this.props.permission} render={() => {
                    return (
                            <Page childCollection={this.props.childCollection}
                                  auditTrailView={<AuditTrailView entityType={this.props.entityType}/>}/>
                    );
                }}/>
                <PrivateRoute path={`${this.props.path}${parentRoute}/:Id`} permission={this.props.permission} render={() => {
                    return (
                            <Page childCollection={this.props.childCollection}/>
                    );
                }}/>
            </Switch>
        );
    }
}

export default withPermissionContext(FormRoute);