import React from "react";

interface ICallbacks {
    read: () => any;
    update: (data: any) => void;
    data?: any;
    // read/update callbacks are called asynchronously and the component lifecycle can change, before their execution
    // => only call them if the references used in the callbacks actually exists, to prevent null errors
    dependentRefs: React.RefObject<any>[];
}

export class DomManipulatorScope {
    _orchestrator: DomManipulatorOrchestrator;
    _parentScope?: DomManipulatorScope;
    _callbacks: ICallbacks[];
    _isDisposed: boolean;
    _isExecuting: boolean;

    constructor(orchestrator: DomManipulatorOrchestrator, parentScope?: DomManipulatorScope) {
        this._orchestrator = orchestrator;
        this._parentScope = parentScope;
        this._isDisposed = false;
        this._isExecuting = false;
        this._callbacks = [];
    }

    public registerCallback(domRead: () => any, domUpdate: (data: any) => void, dependentRefs: React.RefObject<any>[]): (() => void) {
        if (this._isExecuting) {
            // ignore wrong calls that are done during executeAndDispose
            // TODO should we try again for calls that ends here?
            // something like:
            // setTimeout(() => {
            //     this._orchestrator.registerCallback(domRead, domUpdate, dependentRefs);
            // })
            return null;
        }

        if (this.isDisposed()) {
            throw new Error("Disposed scopes cannot accept new callbackes.");
        }
        const parent = this.getUndisposedParent();

        if (parent) {
            return parent.registerCallback(domRead, domUpdate, dependentRefs);
        } else {
            const callbacks = {
                read: domRead,
                update: domUpdate,
                dependentRefs
            };

            this._callbacks.push(callbacks);

            // return unregister function to be used in componentWillUnmount
            return () => {
                const index = this._callbacks.findIndex(c => c === callbacks);

                if (index >= 0) {
                    this._callbacks.splice(index, 1);
                }
            };
        }
    }

    public executeAndDispose(): void {
        if (this.isDisposed()) {
            throw new Error("Disposed scopes cannot be re-executed.");
        }
        // prevent read/update functions from registering another callbacks during executeAndDispose
        this._isExecuting = true;

        // only use callbacks that have their dependent references rendered
        const callbacks = this._callbacks.filter(callback => callback.dependentRefs.every(ref => ref.current));

        for (let callback of callbacks) {
            callback.data = callback.read();
        }
        for (let callback of callbacks) {
            callback.update(callback.data);
        }
        this._isDisposed = true;
        if (this._orchestrator._currentScope === this) {
            this._orchestrator._currentScope = this.getUndisposedParent();
        }

        this._isExecuting = false;
    }

    private isDisposed() {
        return this._isDisposed;
    }

    private getUndisposedParent(): DomManipulatorScope {
        if (!this._parentScope) {
            return null;
        }
        if (this._parentScope.isDisposed()) {
            return this._parentScope.getUndisposedParent();
        } else {
            return this._parentScope;
        }
    }
}

/**
 * An orchestrator used for DOM manipulations.
 * All code which uses things from the list below should use this API.
 * https://gist.github.com/paulirish/5d52fb081b3570c81e3a
 */
export class DomManipulatorOrchestrator {
    _currentScope: DomManipulatorScope;

    constructor() {
        this._currentScope = null;
    }

    /**
     * Registers callbacks used for DOM manipulations.
     *
     * @param domRead This callback is called in the first phase. It should get all properties from
     * DOM it needs for the update phase. This callback should not update DOM!
     *
     * @param domUpdate This callback should do the update. Either update the DOM or setState.
     * @param dependentRefs Array of references that used in the domRead/domUpdate callbacks.
     * The callbacks are called asynchronously and the component lifecycle can change, before their execution
     * => only call them if the references used in the callbacks actually exists, to prevent null errors.
     */
    public registerCallback<TData>(domRead: () => TData, domUpdate: (data: TData) => void, dependentRefs: React.RefObject<any>[]): (() => void) {
        return this.getScope().registerCallback(domRead, domUpdate, dependentRefs);
    }

    /**
     * Creates a new scope for managed execution.
     */
    public createScope(): DomManipulatorScope {
        this._currentScope = new DomManipulatorScope(this, this._currentScope);
        return this._currentScope;
    }

    private getScope(): DomManipulatorScope {
        if (!this._currentScope) {
            const newScope = new DomManipulatorScope(this);
            this._currentScope = newScope;
            setTimeout(() => {
                newScope.executeAndDispose();
            }, 0);
        }
        return this._currentScope;
    }
}