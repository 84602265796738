import { IAccountEntity } from "@odata/GeneratedEntityTypes";
import { isMatchingAccount } from "@pages/documents/Document.utils";
import { TFormatterFn } from "@components/smart/smartTable/SmartTable.utils";
import { toTuple, TValue } from "../../global.types";
import { IFormatOptions } from "@odata/OData.utils";
import { IEntity } from "@odata/BindingContext";
import { Model } from "../../model/Model";

export function getAccountFormatter(propName: string, accountEntityGetter?: (entity: IEntity, storage: Model) => IEntity): TFormatterFn {
    return (val: TValue, { entity, storage }: IFormatOptions) => {
        const e = (accountEntityGetter ? accountEntityGetter(entity, storage) : entity) ?? {};
        const numberProp = `${propName}Number` as keyof IEntity;
        const nameProp = `${propName}Name` as keyof IEntity;
        return e[numberProp] || e[nameProp] ? `${e[numberProp] ?? ""} - ${e[nameProp] ?? ""}` : null;
    };
}

export function getAccountComparisonFunction(propName: string, accountEntityGetter?: (entity: IEntity) => IEntity) {
    return (e1: IEntity, e2: IEntity): boolean => {
        const [account1, account2] = toTuple([e1, e2].map((e) => {
            const aa = (accountEntityGetter ? accountEntityGetter(e) : e as IEntity) ?? {};
            const numberProp = `${propName}Number` as keyof IEntity;
            const nameProp = `${propName}Name` as keyof IEntity;
            return {
                Name: aa[nameProp] as string,
                Number: aa[numberProp] as string
            } as IAccountEntity;
        }));
        return isMatchingAccount(account1, account2);
    };
}

