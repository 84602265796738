import React from "react";
import { DroppableStateSnapshot } from "react-beautiful-dnd";
import { isNotDefined } from "@utils/general";
import { ItemPlaceholderLine, ItemPlaceholderWrapper } from "../configurationList/DnD.utils.styles";
import TestIds from "../../testIds";
import { ISortableListChild, SortableListEvent } from "./SortableList";
import ReactDOM from "react-dom";
import { IconSize } from "../../enums";
import { ORDER_BACKGROUND_RIGHT_MARGIN, ORDER_BACKGROUND_WIDTH } from "./SortableList.styles";
import Emittery from "emittery";

interface IProps {
    emitter: Emittery;
    snapshot?: DroppableStateSnapshot;
    items: ISortableListChild[];
    itemsHeight: Record<string, number>;
    portalTo?: React.RefObject<HTMLDivElement>;
    addLineNumbersPadding?: boolean;
}

interface IState {
    placeholderIndex: number;
}

/** Performance optimization - use as standalone component controlled by events, instead of using state in SortableList */
export default class SortableListPlaceholder extends React.PureComponent<IProps, IState> {
    state: IState = {
        placeholderIndex: null
    };

    constructor(props: IProps) {
        super(props);

        props.emitter.on(SortableListEvent.PlaceholderChange, this.handlePlaceholderChange);
    }

    componentWillUnmount() {
        this.props.emitter.off(SortableListEvent.PlaceholderChange, this.handlePlaceholderChange);
    }


    handlePlaceholderChange = (placeholder: number) => {
        this.setState({
            placeholderIndex: placeholder
        });
    };

    render() {
        if (isNotDefined(this.state.placeholderIndex)) {
            return null;
        }

        let itemHeight: number = this.props.itemsHeight[this.props.items[this.state.placeholderIndex].id];

        if (!itemHeight) {
            itemHeight = this.props.itemsHeight["0"];
        }

        const top = itemHeight * this.state.placeholderIndex;
        // for the DnD handler
        let left = IconSize.asNumber("M");

        if (this.props.addLineNumbersPadding) {
            left += ORDER_BACKGROUND_WIDTH + ORDER_BACKGROUND_RIGHT_MARGIN;
        }

        let renderedContent = this.props.snapshot?.isDraggingOver && (
            <ItemPlaceholderWrapper
                style={{
                    top,
                    left: `${left}px`,
                    height: itemHeight,
                    width: `calc(100% - ${left}px)`
                }}>
                <ItemPlaceholderLine data-testid={TestIds.PlaceholderLine}/>
            </ItemPlaceholderWrapper>
        );

        if (this.props.portalTo) {
            renderedContent = ReactDOM.createPortal(renderedContent, this.props.portalTo.current);
        }

        return renderedContent;
    }
}