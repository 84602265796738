import BindingContext from "../odata/BindingContext";
import { FormStorage } from "../views/formView/FormStorage";
import React from "react";

interface ICustomGroupRenderWrapperProps {
    bindingContext: BindingContext;
    storage: FormStorage;
    children: () => React.ReactNode;
}

export class RefRegistrar extends React.Component<ICustomGroupRenderWrapperProps> {
    constructor(props: ICustomGroupRenderWrapperProps) {
        super(props);
        props.storage.addRef(this, props.bindingContext);
    }

    componentWillUnmount() {
        this.props.storage.removeRef(this, this.props.bindingContext);
    }

    render() {
        return this.props.children();
    }
}