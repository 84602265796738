import React from "react";
import { KeyName } from "../../keyName";
import {
    FOCUSABLE_ELEMENTS_SELECTORS_WITHOUT_TEMPORARY_DISABLED,
    getAllFocusableElements,
    getFocusableElements,
    getTemporaryDisabledFocusableElements,
    isElementVisible,
    mod,
    TabIndex
} from "@utils/general";
import memoizeOne from "../../utils/memoizeOne";

export interface IFocusManager {
    focusableId?: string;
    /** Whether to use left/right or up/down arrow keys */
    direction?: FocusDirection;
    // required for FocusDirection.Grid
    columnsCount?: number;
    /** If not disabled, focus jumps from last item to the first and the other way around. */
    disableLoop?: boolean;
    /** If wheel enabled, user can use it instead of up/down key for vertical movemenet */
    enableWheel?: boolean;
    /** Disabled FocusManager */
    isDisabled?: boolean;
    /** Allow navigation by tab to children of this focus manager */
    allowTabbingToChildren?: boolean;

    /** Called on every focus change. Clean index is after modulo operation, index is the original focus index (can point outside of items)
     * Handler should return true if it changed its children based on this event. */
    onFocusChange?: (cleanIndex: number, index: number) => boolean;

    // add if needed - default selected item/index

    children: (args: IFocusManagerChildren) => React.ReactElement;
}

export const FOCUSABLE_ATTR = "data-focusable";
export const FOCUSABLE_ORDER_ATTR = "data-focusable-order";
export const FOCUSABLE_DISABLED_ITEM_ATTR = "data-focusable-item-disabled";
export const FOCUS_MANAGER_WRAPPER_ATTR = "data-focus-manager-wrapper";

export interface IFocusableItemProps {
    "data-focusable"?: string;
    tabIndex?: number;
}

export interface IFocusableWrapperProps {
    ref: React.RefObject<HTMLElement>;
    tabIndex?: number;
    onKeyDown: (event: React.KeyboardEvent) => void;
    onFocus: (event: React.FocusEvent) => void;
    onWheel: (event: React.WheelEvent<HTMLElement>) => void;
    [FOCUS_MANAGER_WRAPPER_ATTR]: boolean;
}

export type TFocusManagerFocus = (index: number) => void;

export interface IFocusManagerChildren {
    itemProps: IFocusableItemProps;
    wrapperProps: IFocusableWrapperProps;
    /** Public API that lets components that use FocusManager change focus */
    focus?: TFocusManagerFocus;
}

export enum FocusDirection {
    Horizontal = "horizontal",
    Vertical = "vertical",
    Grid = "grid"
}


/** Automatically manages tabindex for composite components (e.g. segmented button, tab menu, list..)
 *  Hijacks ref, keyDown and focus events on wrapper element and tabIndex property on items
 */
export default class FocusManager extends React.PureComponent<IFocusManager> {
    public static defaultProps = {
        focusableId: "focusable",
        direction: FocusDirection.Horizontal
    };

    focusableWrapperRef = React.createRef<HTMLElement>();
    focusedElement: HTMLElement;

    componentDidMount() {
        this.updateTabIndex();
    }

    componentDidUpdate() {
        this.updateTabIndex();
    }

    belongsToThisManager(el: HTMLElement): boolean {
        return el?.getAttribute?.(FOCUSABLE_ATTR) === this.props.focusableId;
    }

    // mby update tabIndex?
    updateTabIndex = (): void => {
        const element = this.focusedElement && this.focusableWrapperRef.current?.contains(this.focusedElement) ? this.focusedElement : this.getOrderedFocusableElementsWithoutDisabled()[0];

        if (this.props.isDisabled || !element) {
            return;
        }

        if (!this.belongsToThisManager(element)) {
            this.focusedElement = null;
        } else {
            this.setFocusedElement(element as HTMLElement);
            this.temporaryDisableFocusOnInactiveChildren();
        }
    };

    getFocusableElements = (): NodeListOf<HTMLElement> | [] => {
        return this.focusableWrapperRef.current?.querySelectorAll(`[${FOCUSABLE_ATTR}="${this.props.focusableId}"]`) ?? [];
    };

    getOrderedFocusableElements = (): HTMLElement[] => {
        return [...this.getFocusableElements()].sort((el1, el2) => {
            const s1 = el1.getAttribute?.(FOCUSABLE_ORDER_ATTR);
            const s2 = el2.getAttribute?.(FOCUSABLE_ORDER_ATTR);
            if (s1 && s2) {
                return parseInt(s1) - parseInt(s2);
            }
            return 0;
        });
    };

    isElementDisabled = (element: HTMLElement): boolean => {
        // "disabled" attribute is only on input elements,
        // FOCUSABLE_DISABLED_ITEM_ATTR can be used on any custom divs
        return (element as HTMLInputElement)?.disabled || element?.getAttribute(FOCUSABLE_DISABLED_ITEM_ATTR) === "true";
    };

    getOrderedFocusableElementsWithoutDisabled = (): HTMLElement[] => {
        return this.getOrderedFocusableElements().filter(el => !this.isElementDisabled(el));
    };

    temporaryDisableFocusOnInactiveChildren(): void {
        if (!this.props.allowTabbingToChildren) {
            // temporary disable focus on all underlaying focusable elements, that are not under actually focused element
            const rootSelector = `[${FOCUSABLE_ATTR}="${this.props.focusableId}"][tabindex="${TabIndex.TemporaryDisabled}"]`;
            const selector = FOCUSABLE_ELEMENTS_SELECTORS_WITHOUT_TEMPORARY_DISABLED.map(selector => `${rootSelector} ${selector}`).join(", ");
            this.focusableWrapperRef.current?.querySelectorAll(selector)?.forEach(el =>
                el.setAttribute("tabindex", `${TabIndex.TemporaryDisabled}`));
        }
    }

    handleKeyDown = (event: React.KeyboardEvent): void => {
        if (this.props.isDisabled || !this.belongsToThisManager(event.target as HTMLElement)) {
            return;
        }

        switch (this.props.direction) {
            case FocusDirection.Horizontal:
                this.handleHorizontalMovement(event);
                break;
            case FocusDirection.Vertical:
                this.handleVerticalMovement(event);
                break;
            case FocusDirection.Grid:
                this.handleGridMovement(event);
                break;
        }
    };

    handleHorizontalMovement = (event: React.KeyboardEvent): void => {
        let keyHandled = true;

        switch (event.key) {
            case KeyName.ArrowLeft:
                this.focusPrevious();
                break;
            case KeyName.ArrowRight:
                this.focusNext();
                break;
            case KeyName.Home:
                this.focusFirst();
                break;
            case KeyName.End:
                this.focusLast();
                break;
            case KeyName.Escape:
                this.focusWrapper();
                // treat as not handled,
                // e.g. in Dialog, we want to close it on Escape and this prevents it
                keyHandled = false;
                break;
            default:
                keyHandled = false;
        }

        if (keyHandled) {
            event.preventDefault();
            event.stopPropagation();
        }
    };

    handleVerticalMovement = (event: React.KeyboardEvent): void => {
        let keyHandled = true;

        switch (event.key) {
            case KeyName.ArrowUp:
                this.focusPrevious();
                break;
            case KeyName.ArrowDown:
                this.focusNext();
                break;
            case KeyName.Home:
                this.focusFirst();
                break;
            case KeyName.End:
                this.focusLast();
                break;
            case KeyName.Escape:
                this.focusWrapper();
                break;
            default:
                keyHandled = false;
        }

        if (keyHandled) {
            event.preventDefault();
            event.stopPropagation();
        }
    };

    handleGridMovement = (event: React.KeyboardEvent): void => {
        let keyHandled = true;

        switch (event.key) {
            case KeyName.ArrowUp:
                this.focusDelta(-this.props.columnsCount);
                break;
            case KeyName.ArrowDown:
                this.focusDelta(this.props.columnsCount);
                break;
            case KeyName.ArrowRight:
                this.focusNext();
                break;
            case KeyName.ArrowLeft:
                this.focusPrevious();
                break;
            case KeyName.Home:
                this.focusFirst();
                break;
            case KeyName.End:
                this.focusLast();
                break;
            case KeyName.Escape:
                this.focusWrapper();
                break;
            default:
                keyHandled = false;
        }

        if (keyHandled) {
            event.preventDefault();
            event.stopPropagation();
        }
    };

    handleWheel = (event: React.WheelEvent<HTMLElement>): void => {
        if (this.props.isDisabled) {
            return;
        }

        const delta = this.props.direction === FocusDirection.Grid ? this.props.columnsCount : 1;

        if (event.deltaY > 0) {
            this.focusDelta(delta);
        } else {
            this.focusDelta(-delta);
        }
    };

    focusNext = (): void => {
        this.focusDelta(1);
    };

    focusPrevious = (): void => {
        this.focusDelta(-1);
    };

    focusDelta = (delta: number, startingFocusIndex?: number, isNestedCall?: boolean): void => {
        const focusableElements = this.getOrderedFocusableElements();
        const oldFocusIndex = startingFocusIndex ?? focusableElements.findIndex(element => element === (this.focusedElement));
        let newFocusIndex: number;
        if (oldFocusIndex < 0) {
            newFocusIndex = 0;
        } else {
            newFocusIndex = oldFocusIndex + (isNestedCall ? 0 : delta);
        }

        let elementsTriedCount = 0;

        while (true) {
            if (elementsTriedCount >= focusableElements.length) {
                // no focusable element found
                return;
            }

            const element = (focusableElements[mod(newFocusIndex, focusableElements.length)] as HTMLInputElement);

            // skip disabled items
            if (!this.isElementDisabled(element)) {
                break;
            }

            newFocusIndex += delta;
            elementsTriedCount += 1;
        }

        if (isNestedCall) {
            newFocusIndex = mod(newFocusIndex, focusableElements.length);
        }

        const isRerendering = this.focusIndex(newFocusIndex, focusableElements, true);

        if (isRerendering && !isNestedCall) {
            // wait for the re-rendered children and set the focus based on the previous delta
            setTimeout(() => {
                let newStartIndex: number;

                const cleanIndex = mod(newFocusIndex, focusableElements.length);

                if (newFocusIndex < 0) {
                    newStartIndex = cleanIndex - delta;
                } else if (newFocusIndex > focusableElements.length) {
                    newStartIndex = mod(cleanIndex, delta);
                } else {
                    newStartIndex = newFocusIndex;
                }


                this.focusDelta(delta, newStartIndex, true);
            }, 0);
            return;
        }
    };

    focusFirst = (): void => {
        this.focusIndex(0, this.getOrderedFocusableElementsWithoutDisabled(), true);
    };

    focusLast = (): void => {
        const focusableElements = this.getOrderedFocusableElementsWithoutDisabled();
        this.focusIndex(focusableElements.length - 1, focusableElements, true);
    };

    focusWrapper = (): void => {
        this.focusableWrapperRef.current?.focus();
    };

    focusIndex = (index: number, focusableElements: HTMLElement[], triggerFocus: boolean): boolean => {
        if (this.props.disableLoop && (index >= focusableElements.length || index < 0)) {
            return false;
        }

        const cleanIndex = mod(index, focusableElements.length);


        const rerendering = !!(this.props.onFocusChange?.(cleanIndex, index));

        if (!rerendering) {
            this.setFocusedElement(focusableElements[cleanIndex] as HTMLElement, triggerFocus);
        }

        return rerendering;
    };

    public focus = (index: number): void => {
        this.focusIndex(index, this.getOrderedFocusableElements(), true);
    };

    handleFocus = (event: React.FocusEvent): void => {
        const target = event.target as HTMLElement;

        // there can be multiple FocusManagers inside on DOM
        event.stopPropagation();

        const wrapper = this.focusableWrapperRef.current;
        if (target === wrapper) {
            if (!wrapper.contains(event.relatedTarget)) {
                // focus moved from outside the wrapper on the wrapper
                // => focus one of the inner focusable elements
                if (this.focusedElement) {
                    this.setFocusedElement(this.focusedElement, true);
                } else {
                    this.focusFirst();
                }
            } else {
                // focus moved from inside the wrapper on the wrapper
                // => focus previous focusable element - we don't want to ever show focus on the wrapper itself.
                // use getAllFocusableElements instead getFocusableElements, otherwise we wouldn't get our target,
                // because wrapper elements ir omitted in getFocusableElements.
                const focusableElements = getAllFocusableElements(document.body);
                const targetIndex = Array.from(focusableElements).indexOf(target);

                if (targetIndex >= 0) {
                    // find first visible element
                    const count = focusableElements.length;

                    let tries = 0;
                    let index = targetIndex - 1;
                    let element: HTMLElement;

                    while (tries < focusableElements.length) {
                        element = focusableElements[mod(index, count)] as HTMLElement;

                        if (isElementVisible(element)) {
                            element.focus();
                            break;
                        }

                        tries += 1;
                        index -= 1;
                    }
                }
            }
        }

        if (this.props.isDisabled || !this.belongsToThisManager(target)) {
            return;
        }

        this.setFocusedElement(target);
    };

    setFocusedElement = (element: HTMLElement, triggerFocus?: boolean) => {
        // disable currently focused children and its underlying focusable nodes (if not prevented)
        if (this.focusedElement && !this.props.allowTabbingToChildren) {
            getFocusableElements(this.focusedElement)?.forEach(el =>
                el.setAttribute("tabindex", `${TabIndex.TemporaryDisabled}`));
            this.focusedElement.setAttribute("tabindex", `${TabIndex.TemporaryDisabled}`);
        }
        // change currently focused element
        this.focusedElement = element;
        if (this.focusedElement && !this.props.allowTabbingToChildren) {
            // make it accessible by tabbing in usual order
            this.focusedElement.setAttribute("tabindex", `${TabIndex.NormalOrder}`);
            // enable all focusable underlying nodes of newly focused item, so user may access them with tab.
            getTemporaryDisabledFocusableElements(this.focusedElement)?.forEach(el => {
                const closestManagedFocusable = el.closest(`[${FOCUSABLE_ATTR}]`) as HTMLElement;
                if (this.belongsToThisManager(closestManagedFocusable)) {
                    el.setAttribute("tabindex", `${TabIndex.NormalOrder}`);
                }
            });
        }

        if (triggerFocus) {
            this.focusedElement?.focus();
        }
    };

    // childrenProps should keep same reference if possible, to prevent pointless rerenders
    getChildrenProps = memoizeOne((): IFocusManagerChildren => {
        return {
            itemProps: {
                [FOCUSABLE_ATTR]: this.props.focusableId,
                tabIndex: this.props.allowTabbingToChildren ? TabIndex.NormalOrder : TabIndex.TemporaryDisabled
            },
            wrapperProps: {
                ref: this.focusableWrapperRef,
                tabIndex: TabIndex.NormalOrder,
                onKeyDown: this.handleKeyDown,
                onFocus: this.handleFocus,
                onWheel: this.props.enableWheel ? this.handleWheel : null,
                [FOCUS_MANAGER_WRAPPER_ATTR]: true
            },
            focus: this.focus
        };
    }, () => [this.props.focusableId, this.focusableWrapperRef, this.props.enableWheel]);

    render() {
        if (this.props.direction === FocusDirection.Grid && !this.props.columnsCount) {
            throw new Error("FocusManager - when FocusDirection.Grid is used, columnsCount has to be defined");
        }

        return this.props.children?.(this.getChildrenProps());
    }
}