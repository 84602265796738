import { ITicketMessage } from "@components/ticketMessage/TicketMessage.utils";
import { ITicketEntity, TicketEntity } from "@odata/GeneratedEntityTypes";
import { TicketStatusCode } from "@odata/GeneratedEnums";
import React from "react";

import { BreadCrumbProvider } from "../../components/breadCrumb";
import { ContextEvents } from "../../contexts/appContext/AppContext.types";
import { withPermissionContext } from "../../contexts/permissionContext/withPermissionContext";
import FileStorage from "../../utils/FileStorage";
import { FormViewForExtend, IFormViewProps } from "../../views/formView/FormView";
import SmartTicketMessageStream from "./SmartTicketMessageStream";
import { SmartHeaderTicketsStyled, TicketsFormViewStyled } from "./Tickets.styles";
import { getTicketMessageFromCreatedMessage } from "./Tickets.utils";

interface IProps extends IFormViewProps<ITicketEntity> {
}

class TicketsFormView extends FormViewForExtend<ITicketEntity, IProps> {
    static defaultProps = {
        formProps: {
            shouldHideAuditTrail: true,
            shouldHideVariant: true
        }
    };

    onBeforeSave(data?: ITicketEntity): ITicketEntity {
        const { entity, bindingContext } = this.props.storage.data;
        if (bindingContext.isNew()) {
            entity.Number = "P0000001";
            entity.TicketStatusCode = TicketStatusCode.InProgress;
        }
        return entity;
    }

    handleCreateMessage = async (message: ITicketMessage, files: File[]): Promise<boolean> => {
        const { storage } = this.props;
        const { entity } = storage.data;
        if (message.subject && !entity.Messages?.length) {
            storage.setValueByPath(TicketEntity.Title, message.subject);
        }
        const currentMessages = storage.getValueByPath(TicketEntity.Messages) ?? [];
        const filePromises = files.map(file => FileStorage.upload({ file }));
        const fileMetadataEntities = await Promise.all(filePromises);
        const enhancedMessage = getTicketMessageFromCreatedMessage(storage, message, fileMetadataEntities);
        const messages = [...currentMessages, enhancedMessage];
        storage.setValueByPath(TicketEntity.Messages, messages);

        const result = await this.save({
            successSubtitle: null,
            // just adding a new message, we don't need to check for data consistency
            skipETag: true
        });

        return !!result;
    };

    handleTableRowRefresh = (unreadFlag: boolean) => {
        const { context, data: { bindingContext } } = this.props.storage;
        if (unreadFlag) {
            context.eventEmitter.emit(ContextEvents.TicketsUnreadCount, bindingContext);
        } else {
            this.props.onTableRefreshNeeded();
        }
    };

    renderForm(): React.ReactElement {
        return (
                <TicketsFormViewStyled>
                    <BreadCrumbProvider
                            back={false}
                            customBreadCrumbs={this.props.storage?.initialHistoryState?.breadCrumbs}/>
                    <SmartHeaderTicketsStyled
                            ref={this._refHeader}
                            title={this.getFormTitle()}
                            subtitle={this.getFormSubtitle()}
                            subtitleStatus={this.getFormSubtitleStatus()}
                            hotspotId={"formHeader"}
                            storage={this.props.storage}
                            icons={this.getHeaderIcons()}
                            shouldHideVariant={true}
                            onChange={this.handleChange}/>

                    <SmartTicketMessageStream storage={this.props.storage}
                                              onRefreshTicketNeeded={this.handleTableRowRefresh}
                                              onCreateMessage={this.handleCreateMessage}/>
                </TicketsFormViewStyled>
        );
    }

    // handleKeyboardShortcut(shortcut: KeyboardShortcut, event: KeyboardEvent): boolean {
    //     return false;
    // }

    // renderButtons = (): React.ReactElement => {
    //     return null;
    // };
}

export default withPermissionContext(TicketsFormView);
