import React from "react";
import TestIds from "../../testIds";
import { StyledHiddenFileInput } from "./FileUploader.styles";

interface IProps {
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    // accepted extensions/MIME types
    accept?: string;
    multiple?: boolean;
    passRef?: React.RefObject<HTMLInputElement>;
}

/** Wrapper around invisible file input tag to prevent redundant code in every place file upload is used */
export default class HiddenFileInput extends React.PureComponent<IProps> {
    handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.props.onChange?.(event);
        // remove the value so that change event is triggered even when the same file is add again
        event.target.value = "";
    };

    render() {
        return (
            <StyledHiddenFileInput ref={this.props.passRef}
                                   onChange={this.handleFileInputChange}
                                   data-testid={TestIds.HiddenFileInput}
                                   accept={this.props.accept}
                                   multiple={this.props.multiple}/>
        );
    }
}