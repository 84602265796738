import { StyledFieldsWrapper } from "@components/inputs/field/FieldsWrapper.styles";
import styled from "styled-components/macro";

import { T_HEADER_small, T_TITLE_4, T_TITLE_4_NORMAL } from "../../../global.style";

export const DayEditWrapper = styled.div`
    & > ${StyledFieldsWrapper} {
        margin-top: 30px;
    }
`;

export const CalendarWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 30px;
`;

export const CalendarGrid = styled.div`
    display: grid;
    grid-gap: 25px;
    grid-template-columns: repeat(7, 1fr);
`;

export const DayLabel = styled.div`
    text-transform: uppercase;
    ${T_TITLE_4};
    text-align: center;
    margin-bottom: -12px;
`;

export const Day = styled.div`
    ${T_TITLE_4_NORMAL};
    text-align: center;
`;

export const AdditionalInfo = styled.div`
    ${T_TITLE_4_NORMAL};
    align-self: flex-start;
    margin-top: 30px;
`;

export const MonthYearLabel = styled.div`
    ${T_HEADER_small};
    text-transform: capitalize;
    margin-bottom: 24px;
`;