import { getDrillDownFilters } from "@components/drillDown/DrillDown.utils";
import { IBankTransactionEntity, ICompanyBankAccountEntity } from "@odata/GeneratedEntityTypes";
import { IFormatOptions } from "@odata/OData.utils";
import { xor } from "lodash";

import { P13nType } from "../../../enums";
import { TRecordType, TValue } from "../../../global.types";
import { ITableStorageDefaultCustomData, TableStorage } from "../../../model/TableStorage";
import BindingContext, { IEntity } from "../../../odata/BindingContext";
import { toggleItemInArray } from "../../../utils/general";
import { IGetValueArgs } from "../FieldInfo";
import { TSmartODataTableStorage } from "../smartTable/SmartODataTableBase";

export interface ISmartBankAccountFilterCustomData extends ITableStorageDefaultCustomData {
    isBankAccountFilterCustomizationDialogOpen?: boolean;
    accountIdForCreationDialog?: boolean;
    CurrentBankAccountFilterAccounts?: ICompanyBankAccountEntity[];
    waitingTransactions?: Record<string, IBankTransactionEntity[]>;
    csvFile?: File;
    bankAccountId?: number;
    uploadedFileId?: number;
    uploadCsv?: boolean;
    initialBalance?: number;
    transactionCount?: number;
    isStatementDialog?: boolean;
}

export function toggleCompanyFilterValue(values: number[], id?: number): number[] {
    if (id) {
        toggleItemInArray(values, id);
    }

    return values;
}

export function companyBankAccountFormatter(val: TValue, args?: IFormatOptions): string {
    const companyBankAccounts = args.storage.context.getCompanyBankAccounts();
    const acc = companyBankAccounts.find(acc => acc.Id === val);
    if (acc) {
        return acc.Name;
    }

    return val as string;
}

const getHiddenIds = (itemIds: number[], items: IEntity[]) => {
    /**
     * Note: We store both type of accounts - visible bank accounts to keep order, which user prefers.
     * Hidden ones to be able to distinguish newly added bank accounts and to add them to the end of the list.
     */
    return items.reduce<number[]>((retVal, currentValue) => {
        if (!itemIds.includes(currentValue.Id)) {
            retVal.push(currentValue.Id);
        }
        return retVal;
    }, []);
};

type TBankAccountFilterSettings = TRecordType<number[]>;

export function storeCustomizedBankAccounts(storage: TableStorage, accountIds: number[]): void {
    const { context, id } = storage;
    context.p13n.update<TBankAccountFilterSettings>(id, P13nType.SelectedBankAccounts, accountIds);
    context.p13n.update<TBankAccountFilterSettings>(id, P13nType.HiddenBankAccounts, getHiddenIds(accountIds, storage.context.getCompanyBankAccounts()));
}

export function storeCustomizedCashBoxes(storage: TableStorage, cashBoxIds: number[]): void {
    const { context, id } = storage;
    context.p13n.update<TBankAccountFilterSettings>(id, P13nType.SelectedCashBoxes, cashBoxIds);
    context.p13n.update<TBankAccountFilterSettings>(id, P13nType.HiddenCashBoxes, getHiddenIds(cashBoxIds, storage.context.getCashBoxes()));
}

interface ILoadCustomizationItemsArgs {
    allAccounts: IEntity[];
    visibleIds: number[];
    hiddenIds: number[];
    storage: TableStorage;
    bindingContext: BindingContext;
    addedAccountIds: number[];
}

const _loadCustomizationItems = (args: ILoadCustomizationItemsArgs) => {
    let activeAccounts = args.allAccounts;
    if (args.visibleIds) {
        // map visibleIds to accounts, so configured order from customization is respected
        activeAccounts = args.visibleIds.reduce((activeExistingAccounts, id) => {
            const account = args.allAccounts.find(account => account.Id === id);
            if (account) {
                activeExistingAccounts.push(account);
            }
            return activeExistingAccounts;
        }, []);

        // add newly added account to the end of the array
        const newAccounts = args.allAccounts.filter(account => !(args.hiddenIds ?? []).includes(account.Id) && !args.visibleIds.includes(account.Id));
        activeAccounts.push(...newAccounts);
    }

    const storage = args.storage as TSmartODataTableStorage<ISmartBankAccountFilterCustomData>;
    // store synced to storage
    storage.setCustomData({ CurrentBankAccountFilterAccounts: activeAccounts });

    // update filter value to contain only accounts, which are configured as visible
    const origValues: number[] = args.storage.getValue(args.bindingContext, { useDirectValue: false }) || [];
    let values: number[];

    if (origValues.length) {
        values = origValues.filter((id) => activeAccounts.find(acc => acc.Id === id));
    } else {
        values = [];
    }

    values.push(...args.addedAccountIds || []);
    // just fixes with FakeId in case values are empty
    values = toggleCompanyFilterValue(values);

    const diff = xor(origValues, values);

    if (diff.length) {
        // skip variant save as it makes converting default variant -> custom on some types of page load
        args.storage.handleFilterChange({
            value: values,
            bindingContext: args.bindingContext
        }, false);
    }

    return activeAccounts;
};

export async function loadCustomizedBankAccounts(storage: TableStorage, bindingContext: BindingContext, addedAccountIds: number[] = []): Promise<ICompanyBankAccountEntity[]> {
    const { context, id } = storage;
    const allAccounts = context.getCompanyBankAccounts();
    const visibleIds = await context.p13n.get<number[]>(id, P13nType.SelectedBankAccounts);
    const hiddenIds = await context.p13n.get<number[]>(id, P13nType.HiddenBankAccounts);

    return _loadCustomizationItems({ allAccounts, visibleIds, hiddenIds, bindingContext, addedAccountIds, storage });
}

export async function loadCustomizedCashBoxes(storage: TableStorage, bindingContext: BindingContext, addedAccountIds: number[] = []): Promise<ICompanyBankAccountEntity[]> {
    const { context, id } = storage;
    const allAccounts = context.getCashBoxes();
    const visibleIds = await context.p13n.get<number[]>(id, P13nType.SelectedCashBoxes);
    const hiddenIds = await context.p13n.get<number[]>(id, P13nType.HiddenCashBoxes);

    return _loadCustomizationItems({ allAccounts, visibleIds, hiddenIds, bindingContext, addedAccountIds, storage });
}


export function getCustomizedBankAccountsSync(storage: TSmartODataTableStorage<ISmartBankAccountFilterCustomData>): ICompanyBankAccountEntity[] {
    return storage.getCustomData().CurrentBankAccountFilterAccounts ?? [];
}

/** By default, filter values are stored in "parsedValue", and this array of values is used to create read only values of FilterBar.
 *  Because we store inverse values for BankStatement/BankAccount (values that are supposed to be unchecked),
 *  we need to define custom function for the getFilterBarItemRenderValue, so that it can show the inverse set correctly. */
export const getFilterBarItemParsedValue = (args: IGetValueArgs): TValue => {
    const origParsedValue = args.storage.getAdditionalFieldData(args.bindingContext, "parsedValue") as number[] ?? [];
    const fixedParsedValue: number[] = [];
    const visibleBankAccounts = getCustomizedBankAccountsSync(args.storage as TSmartODataTableStorage) ?? [];

    for (const acc of visibleBankAccounts) {
        if (!origParsedValue.find(id => id === acc.Id)) {
            fixedParsedValue.push(acc.Id);
        }
    }

    return fixedParsedValue;
};

/**
 * We store "inverse" value (unselected accounts instead of selected accounts)
 * for BankStatement/BankAccount, so that it can work correctly in variants.
 * When drilldown is used, it would be to complicated to pass the reversed value.
 * Instead, we inverse the value here.
 * */
export const fixInverseDrilldownFilterValue = (storage: TSmartODataTableStorage, filterPath: string): void => {
    const drilldownFilterValue = getDrillDownFilters()?.[filterPath] as number[];

    if (drilldownFilterValue) {
        // reverse the values and refresh filter
        const visibleBankAccounts = getCustomizedBankAccountsSync(storage) ?? [];
        const reversedBankAccountIds: number[] = [];

        for (const acc of visibleBankAccounts) {
            if (!drilldownFilterValue.find(id => id === acc.Id)) {
                reversedBankAccountIds.push(acc.Id);
            }
        }

        storage.setFilterValue(storage.data.bindingContext.navigate(filterPath), reversedBankAccountIds);
        storage.setValue(storage.data.bindingContext.navigate(`${filterPath}/Id`), reversedBankAccountIds);
        storage.applyFilters();
        storage.refresh(true);
    }
};