import React, { useState } from "react";
import { usePopper } from "react-popper";
import { SHELL_NOTIFICATION_ID } from "../views/main/Shell.types";
import ReactDOM from "react-dom";
import NotificationAlert from "./NotificationAlert";
import { IProps as INotificationAlertProps } from "./NotificationAlert";

interface IProps extends Pick<INotificationAlertProps, "avatarSrc" | "title" | "subtitle" | "onFadeEnd"> {

}

const PopperNotificationAlert = React.memo((props: React.PropsWithChildren<IProps>) => {
    const notificationButton = document.getElementById(SHELL_NOTIFICATION_ID);
    const [popperElement, setPopperElement] = useState(null);
    const { styles, attributes } = usePopper(notificationButton, popperElement, {
        placement: "right",
        modifiers: [
            { name: "offset", options: { offset: [0, 20] } }
        ]
    });

    return ReactDOM.createPortal((
        <NotificationAlert
            avatarSrc={props.avatarSrc}
            title={props.title}
            subtitle={props.subtitle}
            onFadeEnd={props.onFadeEnd}

            passRef={setPopperElement}
            style={styles.popper}
            passProps={attributes.popper}
        />
    ), document.getElementById("context-menu-root"));
});

export default PopperNotificationAlert;