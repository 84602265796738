import { AppContext } from "../../../contexts/appContext/AppContext.types";
import { withPermissionContext } from "../../../contexts/permissionContext/withPermissionContext";
import EmploymentBaseFormView from "../employmentTemplate/EmploymentBaseFormView";
import { IFormViewProps } from "../../../views/formView/FormView";
import { IPrEmploymentEntity, PrEmploymentEntity } from "@odata/GeneratedEntityTypes";
import { IReadOnlyListItem } from "@components/readOnlyList/ReadOnlyListItem";
import { formatHeaderValue } from "@components/readOnlyList/ReadOnlyList";
import { universalPersonNameFormatter } from "../../admin/users/Users.utils";
import { ISmartFieldChange } from "@components/smart/smartField/SmartField";
import { initiateWithTemplate, onBeforeSave, TEmploymentLineItemProp } from "./Employment.utils";
import { IEntity } from "@odata/BindingContext";
import { cloneDeep } from "lodash";


interface IProps extends IFormViewProps<IPrEmploymentEntity, null> {
}

class EmploymentFormView extends EmploymentBaseFormView<IProps> {
    static contextType = AppContext;
    static defaultProps = {
        title: "Employment:FormTitle"
    };

    getHeaderData = (): IReadOnlyListItem[] => {
        const { storage } = this.props;
        const { bindingContext, entity } = storage.data;

        return [
            {
                label: storage.getInfo(bindingContext.navigate(PrEmploymentEntity.Employee)).label,
                value: formatHeaderValue(universalPersonNameFormatter(entity.Employee))
            },
            {
                label: storage.getInfo(bindingContext.navigate(PrEmploymentEntity.Type)).label,
                value: formatHeaderValue(entity.Type?.Name)
            },
            {
                label: storage.getInfo(bindingContext.navigate(PrEmploymentEntity.Template)).label,
                value: formatHeaderValue(entity.Template?.Name)
            },
            // todo: ... there will be Name property on the EmploymentEntity in the future
            // {
            //
            //     label: storage.getInfo(bindingContext.navigate(PrEmploymentEntity.Name)).label,
            //     value: formatHeaderValue(entity.Name)
            // }
        ];
    };

    get readOnlyListTitle(): string {
        return this.props.storage.t("Employment:FormTitle");
    }

    getAlreadySelectedItemIds(propName: TEmploymentLineItemProp): number[] {
        const { entity } = this.props.storage.data;
        const ids: number[] = [];
        entity[propName]?.forEach(comp => {
            const defaultItem = comp?.Template?.Default ?? comp?.Default;
            if (defaultItem?.Id) {
                ids.push(defaultItem?.Id);
            }
        });
        return ids;
    }

    async handleTemplateChange(e: ISmartFieldChange) {
        if (e.bindingContext.getPath() === PrEmploymentEntity.Template) {
            await initiateWithTemplate(this.props.storage, e.value as string);
        }
    }

    async handleChange(e: ISmartFieldChange): Promise<void> {
        await this.handleTemplateChange(e);
        super.handleChange(e);
    }

    onBeforeSave = (): IEntity => {
        const { storage } = this.props;

        const entity = cloneDeep(storage.data.entity);

        return onBeforeSave(storage, entity);
    };

}

export default withPermissionContext(EmploymentFormView);