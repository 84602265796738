import { StyledButton, StyledButtonGroup } from "@components/button";
import { transparentize } from "polished";
import styled, { css } from "styled-components/macro";

import Alert from "../../components/alert";
import { OneLinerHeight } from "../../components/alert/Alert.styles";
import SmartHeader from "../../components/smart/smartHeader";
import Tabs from "../../components/tabs/Tabs";
import { LocalStackOrder, T_PLAIN_small } from "../../global.style";
import { TableWrapper } from "../table/TableView.styles";
import { VIEW_PADDING_VALUE } from "../View.styles";

export const FormFooter = styled.div<{
    hasTopMargin?: boolean;
    isEditOverride?: boolean;
}>`
    display: flex;
    /* Top margin reserves space for possible alert, but do we need this always (e.g. in read-only state)? */
    margin-top: ${props => props.hasTopMargin === false ? 0 : "22px"};
    justify-content: flex-end;

    ${StyledButton} {
        margin-bottom: 38px;
    }

    ${StyledButtonGroup} {
        justify-content: flex-end;
    }

    ${props => props.isEditOverride && css`
        z-index: ${LocalStackOrder.AboveContent + 1};
    `}
`;

export const FormContent = styled.form.attrs({
    autocomplete: "off"
})<{
    withoutPadding?: boolean;
    isEditOverride?: boolean;
}>`
    position: relative;
    ${props => !props.withoutPadding && css`
        padding-bottom: ${VIEW_PADDING_VALUE}px;
    `};
    ${props => props.isEditOverride && css`
        fieldset, ${LockedFormEditOverrideStatus} {
            z-index: ${LocalStackOrder.AboveContent + 1};
        }
    `};
`;

export const FormHeaderTopMargin = 12;

export const SmartHeaderStyled = styled(SmartHeader) <{
    hideBreadcrumbs?: boolean
}>`
`;

export const FormAlertContainer = styled(Alert)`
    margin-bottom: 30px;
`;

export const StyledErrorWrapper = styled.div`
    margin-bottom: 19px;
`;

export const LockedFormEditOverrideStatus = styled.div`
    position: relative;
    display: flex;
    gap: 6px;
    align-items: center;
    margin-bottom: 42px;
    padding: 4px 0 4px 18px;
    color: ${props => props.theme.C_ACT_main};
    background-color: ${props => props.theme.C_BG_tooltip};
    border-radius: ${props => props.theme.borderRadius};
    ${T_PLAIN_small};
`;

export const LockedFormOverrideOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${props => transparentize(0.7, props.theme.C_BG_tooltip)};
    z-index: ${LocalStackOrder.AboveContent};
    // idk how to do this properly without pointer-events: none
    // hopefully UX won't care about the elements under the overlay being clickable
    pointer-events: none;
`;

export const StyledSuccessWrapper = styled.div`
    position: absolute;
    width: 100%;

    pointer-events: none; // otherwise cannot click on the add button BEHIND IT

    z-index: 500;
    bottom: ${(60 - OneLinerHeight) / 2 - 22}px; // show just below the form, in the middle of the space
`;

export const FormTabs = styled(Tabs) <{
    hasTitle?: boolean;
    isFirst?: boolean;
}>`
    margin-top: ${props => props.isFirst ? 0 : props.hasTitle ? "35px" : "65px"};

    ${TableWrapper} {
        margin-top: 0;
    }
`;