import { Button } from "@components/button";
import Dialog, { IDialogProps } from "@components/dialog";
import { ConnectedIcon, ConnectionErrorIcon, DisconnectedIcon } from "@components/icon";
import { TooltipIconInfo } from "@components/tooltipIcon";
import { StyledTooltipContent } from "@components/tooltipIcon/TooltipIcon.styles";
import React, { ReactElement } from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import TestIds from "../../testIds";
import {
    ContentWrapper,
    DialogContentWrapper,
    HeaderWrapper,
    IconWrapper,
    StyledHeader,
    StyledSubtitle
} from "./ConnectionDialog.styles";

export enum ConnectionStatus {
    Disconnected = "Disconnected",
    WaitingForConfiguration = "WaitingForConfiguration",
    Connected = "Connected",
    ConnectionError = "ConnectionError",
    WaitingForAllow = "WaitingForAllow"
}

const ERROR_DIALOG_WIDTH = "380px";
const BASE_DIALOG_WIDTH = "338px";

interface IProps extends WithTranslation, Pick<IDialogProps, "onClose" | "onConfirm" | "onAfterOpen"> {
    onDisconnect?: () => void;
    status: ConnectionStatus;
    title?: string;
    subtitle?: string | ReactElement;
    accountName?: string;
    isBusy?: boolean;
    children?: ReactElement;
    preFormContent?: ReactElement;
    tooltip?: string;
    connectedStateIsJustPreview?: boolean;
    width?: string;
}

const CONNECTED_ICON_SIZE = "456px";

class ConnectionDialog extends React.Component<IProps> {
    get title(): string {
        let key = null;
        switch (this.props.status) {
            case ConnectionStatus.WaitingForConfiguration:
                key = "WaitingForConfiguration";
                break;
            case ConnectionStatus.WaitingForAllow:
                key = "WaitingForAllow";
                break;
            case ConnectionStatus.Disconnected:
                key = "Disconnected";
                break;
            case ConnectionStatus.Connected:
                key = "Connected";
                break;
            case ConnectionStatus.ConnectionError:
                key = "ConnectionError";
                break;
        }
        return key ? this.props.t(`ConnectionDialog:${key}`) : null;
    }

    get subTitle(): string | ReactElement {
        switch (this.props.status) {
            case ConnectionStatus.WaitingForAllow:
                return <>{this.props.t("ConnectionDialog:AllowSubtitle")}&nbsp;<b>{this.props.accountName}.</b></>;
            case ConnectionStatus.Disconnected:
                return <>{this.props.t("ConnectionDialog:DisconnectedSubtitlePre")}&nbsp;
                    <b>{this.props.accountName}</b> {this.props.t("ConnectionDialog:DisconnectedSubtitlePost")}</>;
            case ConnectionStatus.WaitingForConfiguration:
                return <>{this.props.t("ConnectionDialog:WaitingForConfigurationSubtitlePre")}&nbsp;
                    <b>{this.props.accountName}</b> {this.props.t("ConnectionDialog:WaitingForConfigurationSubtitlePost")}</>;
            case ConnectionStatus.Connected:
                return <>{this.props.t("ConnectionDialog:ConnectedSubtitlePre")}&nbsp;
                    <b>{this.props.accountName}</b> {this.props.t("ConnectionDialog:ConnectedSubtitlePost")}</>;
            case ConnectionStatus.ConnectionError:
                return this.props.t("ConnectionDialog:ConnectionErrorSubtitle");
        }
    }

    renderIcon() {
        switch (this.props.status) {
            case ConnectionStatus.WaitingForAllow:
            case ConnectionStatus.Disconnected:
                return <DisconnectedIcon/>;
            case ConnectionStatus.Connected:
            case ConnectionStatus.WaitingForConfiguration:
                return <ConnectedIcon width={CONNECTED_ICON_SIZE}/>;
            case ConnectionStatus.ConnectionError:
                return <ConnectionErrorIcon width={"582px"}/>;
            default:
                return null;
        }
    }

    getConfirmButtonText = () => {
        switch (this.props.status) {
            case ConnectionStatus.Connected:
                return this.props.connectedStateIsJustPreview ? this.props.t("Common:General.Close") : this.props.t("Common:General.Save");
            case ConnectionStatus.ConnectionError:
                return this.props.t("ConnectionDialog:TryAgain");
            case ConnectionStatus.WaitingForAllow:
                return this.props.t("Common:General:Continue");
            case ConnectionStatus.WaitingForConfiguration:
                return this.props.t("ConnectionDialog:Finish");
            case ConnectionStatus.Disconnected:
            default:
                return this.props.t("ConnectionDialog:Connect");
        }
    };

    get isPreview() {
        return this.props.status === ConnectionStatus.Connected && this.props.connectedStateIsJustPreview;
    }

    handleConfirm = () => {
        if (this.isPreview) {
            this.props.onClose();
        } else {
            this.props.onConfirm();
        }
    };

    renderFooter() {
        return (
            <>
                {!this.isPreview && <Button isTransparent
                    onClick={this.props.onClose}>
                    {this.props.t(`Common:General.${ConnectionStatus.ConnectionError === this.props.status ? "Close" : "Cancel"}`)}
                </Button>}
                {[ConnectionStatus.Connected, ConnectionStatus.ConnectionError].includes(this.props.status) && !!this.props.onDisconnect &&
                    <Button isTransparent
                            onClick={this.props.onDisconnect}>
                        {this.props.t("ConnectionDialog:Disconnect")}
                    </Button>}
                <Button onClick={this.handleConfirm}>
                    {this.getConfirmButtonText()}
                </Button>
            </>
        );
    }

    render() {
        if (!this.props.tReady) {
            return null;
        }

        const defaultDialogWidth = this.props.status === ConnectionStatus.ConnectionError && !!this.props.onDisconnect ? ERROR_DIALOG_WIDTH : BASE_DIALOG_WIDTH;
        const dialogWidth = this.props.width ?? defaultDialogWidth;
        return (
            <Dialog
                onClose={this.props.onClose}
                busy={this.props.isBusy}
                onConfirm={this.props.onConfirm}
                onAfterOpen={this.props.onAfterOpen}
                width={"338px"} // size of status icons
                minWidth={dialogWidth}
                footer={this.renderFooter()}
                removePadding
                withoutHeader
            >
                <DialogContentWrapper width={dialogWidth}>
                    <IconWrapper>
                        {this.renderIcon()}
                    </IconWrapper>
                    <HeaderWrapper data-testid={TestIds.DialogHeader}>
                        <StyledHeader data-testid={TestIds.DialogTitle}>
                            {this.props.title ?? this.title}
                        </StyledHeader>
                        <StyledSubtitle data-testid={TestIds.DialogSubTitle}>
                            {this.props.subtitle ?? this.subTitle}
                            {!this.props.isBusy && this.props.tooltip && (
                                <TooltipIconInfo>
                                    <StyledTooltipContent>{this.props.tooltip}</StyledTooltipContent>
                                </TooltipIconInfo>
                            )}
                        </StyledSubtitle>
                    </HeaderWrapper>
                    <ContentWrapper>
                        {this.props.children}
                    </ContentWrapper>
                </DialogContentWrapper>
            </Dialog>
        );
    }
}

export default withTranslation(["ConnectionDialog", "Common"])(ConnectionDialog);