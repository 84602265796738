import React from "react";
import { IMultiSelectProps, MultiSelect } from "../../inputs/select/MultiSelect";
import SmartSelectBase, { ISmartSelectBaseProps } from "./SmartSelectBase";

export interface ISmartMultiSelectProps extends ISmartSelectBaseProps, Omit<IMultiSelectProps, "onClick" | "onIconActivate"> {
}

class SmartMultiSelect extends React.Component<ISmartMultiSelectProps> {

    render = () => {
        const passSelectProps = {
            withoutMainToggle: this.props.withoutMainToggle
        };

        return (
            <SmartSelectBase
                {...this.props}
                selectProps={passSelectProps}
                select={MultiSelect}
            />
        );
    };
}

export { SmartMultiSelect };