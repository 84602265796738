import { SwitchType } from "@components/inputs/switch/Switch";
import { ifAll, ifAny, IGetValueArgs, not } from "@components/smart/FieldInfo";
import { SingleBusinessPartnerDef } from "@components/smart/GeneralFieldDefinition";
import BindingContext, { createPath } from "@odata/BindingContext";
import {
    CompanyEntity,
    TaxStatementSignatoryPersonEntity,
    TaxStatementSubmitterEntity,
    TypeOfBusinessEntity
} from "@odata/GeneratedEntityTypes";
import { SignatoryPersonCode, TaxStatementSubmitterCode, TypeOfBusinessCode } from "@odata/GeneratedEnums";
import { TFieldsDefinition } from "@pages/PageUtils";
import i18next from "i18next";
import { ValidationError } from "yup";

import { BasicInputSizes, FieldType, GroupedField, ValidatorType } from "../../enums";
import { TValue } from "../../global.types";
import { getUtcDayjs } from "../../types/Date";

export const hasTaxStatementSignatoryPersonPath = BindingContext.localContext("hasTaxStatementSignatoryPerson");

const hasTaxStatementSignatoryPerson = (args: IGetValueArgs): boolean => {
    return !!args.storage.getValueByPath(hasTaxStatementSignatoryPersonPath);
};

const isCustomer = (args: IGetValueArgs): boolean => {
    return args.storage.getValueByPath(createPath(CompanyEntity.TaxStatementSignatoryPerson, TaxStatementSignatoryPersonEntity.TaxStatementSubmitter))?.Code === TaxStatementSubmitterCode.Customer;
};

const isTaxAdvisor = (args: IGetValueArgs): boolean => {
    return args.storage.getValueByPath(createPath(CompanyEntity.TaxStatementSignatoryPerson, TaxStatementSignatoryPersonEntity.TaxStatementSubmitter))?.Code === TaxStatementSubmitterCode.TaxAdvisor;
};

const isLegalPerson = (args: IGetValueArgs): boolean => {
    return args.storage.getValueByPath(createPath(CompanyEntity.TaxStatementSignatoryPerson, TaxStatementSignatoryPersonEntity.PersonType))?.Code === TypeOfBusinessCode.LegalPerson;
};

export const getTaxStatementSignatoryPersonFieldDefs: () => TFieldsDefinition = () => ({
    [hasTaxStatementSignatoryPersonPath]: {
        type: FieldType.Switch,
        fieldSettings: {
            type: SwitchType.YesNo
        },
        affectedFields: [{
            id: createPath(CompanyEntity.TaxStatementSignatoryPerson, TaxStatementSignatoryPersonEntity.FirstName)
        }, {
            id: createPath(CompanyEntity.TaxStatementSignatoryPerson, TaxStatementSignatoryPersonEntity.LastName)
        }, {
            id: createPath(CompanyEntity.TaxStatementSignatoryPerson, TaxStatementSignatoryPersonEntity.TaxStatementSubmitter)
        }, {
            id: createPath(CompanyEntity.TaxStatementSignatoryPerson, TaxStatementSignatoryPersonEntity.PersonType)
        }, {
            id: createPath(CompanyEntity.TaxStatementSignatoryPerson, TaxStatementSignatoryPersonEntity.DateOfBirth)
        }, {
            id: createPath(CompanyEntity.TaxStatementSignatoryPerson, TaxStatementSignatoryPersonEntity.SignatoryPerson)
        }, {
            id: createPath(CompanyEntity.TaxStatementSignatoryPerson, TaxStatementSignatoryPersonEntity.TaxAdvisorRegistrationNumber)
        }, {
            id: createPath(CompanyEntity.TaxStatementSignatoryPerson, TaxStatementSignatoryPersonEntity.LegalPersonName)
        }, {
            id: createPath(CompanyEntity.TaxStatementSignatoryPerson, TaxStatementSignatoryPersonEntity.LegalNumber)
        }, {
            id: createPath(CompanyEntity.TaxStatementSignatoryPerson, TaxStatementSignatoryPersonEntity.RelationshipToTaxSubject)
        }],
        customizationData: {
            useForCustomization: false // group switch
        }
    },
    [createPath(CompanyEntity.TaxStatementSignatoryPerson, TaxStatementSignatoryPersonEntity.FirstName)]: {
        groupedField: GroupedField.MultiStart,
        isRequired: true,
        isVisible: hasTaxStatementSignatoryPerson
    },
    [createPath(CompanyEntity.TaxStatementSignatoryPerson, TaxStatementSignatoryPersonEntity.LastName)]: {
        groupedField: GroupedField.MultiEnd,
        isRequired: true,
        isVisible: hasTaxStatementSignatoryPerson
    },
    [createPath(CompanyEntity.TaxStatementSignatoryPerson, TaxStatementSignatoryPersonEntity.TaxStatementSubmitter)]: {
        type: FieldType.ComboBox,
        isVisible: hasTaxStatementSignatoryPerson,
        affectedFields: [{
            id: createPath(CompanyEntity.TaxStatementSignatoryPerson, TaxStatementSignatoryPersonEntity.DateOfBirth)
        }, {
            id: createPath(CompanyEntity.TaxStatementSignatoryPerson, TaxStatementSignatoryPersonEntity.PersonType)
        }, {
            id: createPath(CompanyEntity.TaxStatementSignatoryPerson, TaxStatementSignatoryPersonEntity.SignatoryPerson)
        }, {
            id: createPath(CompanyEntity.TaxStatementSignatoryPerson, TaxStatementSignatoryPersonEntity.LegalPersonName)
        }, {
            id: createPath(CompanyEntity.TaxStatementSignatoryPerson, TaxStatementSignatoryPersonEntity.LegalNumber)
        }, {
            id: createPath(CompanyEntity.TaxStatementSignatoryPerson, TaxStatementSignatoryPersonEntity.RelationshipToTaxSubject)
        }, {
            id: createPath(CompanyEntity.TaxStatementSignatoryPerson, TaxStatementSignatoryPersonEntity.TaxAdvisorRegistrationNumber)
        }],
        fieldSettings: {
            transformFetchedItems: (items, args) => {
                if (args.storage.getValueByPath(CompanyEntity.TypeOfBusinessCode) === TypeOfBusinessCode.NaturalPerson) {
                    return items.filter(i => i.id !== TaxStatementSubmitterCode.Customer);
                }
                return items;
            },
            displayName: TaxStatementSubmitterEntity.Name
        },
    },
    [createPath(CompanyEntity.TaxStatementSignatoryPerson, TaxStatementSignatoryPersonEntity.PersonType)]: {
        type: FieldType.ComboBox,
        isRequired: true,
        isVisible: ifAll(hasTaxStatementSignatoryPerson, not(isCustomer)),
        affectedFields: [{
            id: createPath(CompanyEntity.TaxStatementSignatoryPerson, TaxStatementSignatoryPersonEntity.DateOfBirth)
        }, {
            id: createPath(CompanyEntity.TaxStatementSignatoryPerson, TaxStatementSignatoryPersonEntity.SignatoryPerson)
        }, {
            id: createPath(CompanyEntity.TaxStatementSignatoryPerson, TaxStatementSignatoryPersonEntity.LegalPersonName)
        }, {
            id: createPath(CompanyEntity.TaxStatementSignatoryPerson, TaxStatementSignatoryPersonEntity.LegalNumber)
        }, {
            id: createPath(CompanyEntity.TaxStatementSignatoryPerson, TaxStatementSignatoryPersonEntity.RelationshipToTaxSubject)
        }, {
            id: createPath(CompanyEntity.TaxStatementSignatoryPerson, TaxStatementSignatoryPersonEntity.TaxAdvisorRegistrationNumber)
        }],
        fieldSettings: {
            displayName: TypeOfBusinessEntity.Name
        }
    },
    [createPath(CompanyEntity.TaxStatementSignatoryPerson, TaxStatementSignatoryPersonEntity.DateOfBirth)]: {
        type: FieldType.Date,
        isRequired: true,
        isVisible: ifAll(hasTaxStatementSignatoryPerson, not(isLegalPerson), not(isCustomer)),
        validator: {
            type: ValidatorType.Date,
            settings: {
                customValidator: (value: TValue, args: IGetValueArgs): boolean | ValidationError => {
                    const is18yo = getUtcDayjs().subtract(18, "years").isSameOrAfter(value as Date, "day");
                    if (value && !is18yo) {
                        return new ValidationError(i18next.t("Companies:Validation.TaxStatementSignatoryPersonDateOfBirth"), false, args.bindingContext.getEntityPath(true));
                    }
                    return true;
                },
            }
        }
    },
    [createPath(CompanyEntity.TaxStatementSignatoryPerson, TaxStatementSignatoryPersonEntity.LegalPersonName)]: {
        groupedField: GroupedField.MultiStart,
        width: BasicInputSizes.XL,
        isRequired: true,
        isVisible: ifAll(hasTaxStatementSignatoryPerson, not(isCustomer), isLegalPerson),
        ...SingleBusinessPartnerDef,
        columns: [{
            id: TaxStatementSignatoryPersonEntity.LegalPersonName
        }, {
            id: TaxStatementSignatoryPersonEntity.LegalNumber
        }]
    },
    [createPath(CompanyEntity.TaxStatementSignatoryPerson, TaxStatementSignatoryPersonEntity.LegalNumber)]: {
        groupedField: GroupedField.MultiEnd,
        width: BasicInputSizes.S,
        isRequired: true,
        isVisible: ifAll(hasTaxStatementSignatoryPerson, not(isCustomer), isLegalPerson),
        ...SingleBusinessPartnerDef,
        columns: [{
            id: TaxStatementSignatoryPersonEntity.LegalPersonName
        }, {
            id: TaxStatementSignatoryPersonEntity.LegalNumber
        }]
    },
    [createPath(CompanyEntity.TaxStatementSignatoryPerson, TaxStatementSignatoryPersonEntity.RelationshipToTaxSubject)]: {
        width: BasicInputSizes.L,
        isRequired: true,
        isVisible: ifAll(hasTaxStatementSignatoryPerson, ifAny(isLegalPerson, isCustomer))
    },
    [createPath(CompanyEntity.TaxStatementSignatoryPerson, TaxStatementSignatoryPersonEntity.TaxAdvisorRegistrationNumber)]: {
        isVisible: ifAll(hasTaxStatementSignatoryPerson, isTaxAdvisor, not(isLegalPerson)),
        width: BasicInputSizes.L
    },
    [createPath(CompanyEntity.TaxStatementSignatoryPerson, TaxStatementSignatoryPersonEntity.SignatoryPerson)]: {
        type: FieldType.ComboBox,
        isVisible: ifAll(hasTaxStatementSignatoryPerson, not(isCustomer)),
        isReadOnly: true,
        fieldSettings: {
            items: [{
                id: SignatoryPersonCode._4aGeneralAttorneyIndividualAndLegalPerson,
                label: i18next.t("Companies:Form.SignatoryPerson4a")
            }, {
                id: SignatoryPersonCode._4bIndividual_TaxAdvisorOrAttorney,
                label: i18next.t("Companies:Form.SignatoryPerson4b")
            }, {
                id: SignatoryPersonCode._4cLegalEntityProvidingTaxConsultancy,
                label: i18next.t("Companies:Form.SignatoryPerson4c")
            }]
        },
        customizationData: { useForCustomization: false }
    }
});