import React from "react";
import { DateTimeIcon } from "../../icon";
import { DateTimePickerPopup } from "./popup/DateTimePickerPopup";
import { DatePickerClean } from "./DatePicker";
import DateType, { getDateFormat, getUtcDayjs } from "../../../types/Date";
import { BasicInputSizes, IconSize } from "../../../enums";
import { longDateFormat } from "./utils";
import { withTranslation } from "react-i18next";

class DateTimePicker extends DatePickerClean {
    static defaultProps = {
        width: BasicInputSizes.M
    };

    get format() {
        const format = `${getDateFormat(DateType.defaultDateFormat)}, ${getDateFormat(DateType.defaultTimeFormat)}`;

        return longDateFormat(format);
    }

    get pickerPopup() {
        return DateTimePickerPopup;
    }

    get icon() {
        return <DateTimeIcon width={IconSize.M}/>;
    }

    isSame = (date1: Date, date2: Date): boolean => {
        if (date1 instanceof Date) {
            return getUtcDayjs(date1).isSame(date2, "minutes");
        }

        return date1 === date2;
    };
}

const DateTimePickerExtended = withTranslation(["Components"])(DateTimePicker);
export { DateTimePickerExtended as DateTimePicker };