import styled from "styled-components/macro";

interface IProps {
    sharpLeft?: boolean;
}

export const PopupWrapper =  styled.div<IProps>`
    position: relative;
    // left: ${props => props.sharpLeft ? "0" : "12px"};
    outline: none;
    border-radius: 3px;
    box-shadow: 0 0 12.7px 0.3px rgba(0, 0, 0, 0.15);
    background: ${props => props.theme.C_BG_dialogue};
`;
