import React from "react";
import { LinkStyledBreadcrumb, StyledBreadCrumbItem, StyledItem } from "./BreadCrumbItem.styles";
import TestIds from "../../testIds";
import { THistoryLocation } from "../drillDown/DrillDown.utils";

interface IProps {
    to?: THistoryLocation;
    onClick?: () => void;
}

class BreadCrumbItem extends React.PureComponent<IProps> {
    render = () => {
        return (
            <StyledBreadCrumbItem data-testid={TestIds.BreadcrumbItem}>
                {this.props.to &&
                    <LinkStyledBreadcrumb link={this.props.to}
                                          onClick={this.props.onClick}>
                        {this.props.children}
                    </LinkStyledBreadcrumb>
                }
                {!this.props.to && this.props.onClick &&
                    <StyledItem onClick={this.props.onClick}>
                        {this.props.children}
                    </StyledItem>
                }

                {!this.props.to && !this.props.onClick && this.props.children}
            </StyledBreadCrumbItem>
        );
    };
}

export { BreadCrumbItem };