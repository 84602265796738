import IssuedDocumentBaseFormView from "../../IssuedDocumentBaseFormView";
import { withConfirmationDialog } from "@components/dialog/withConfirmationDialog";
import { withDomManipulator } from "../../../../contexts/domManipulator/withDomManipulator";
import { withPermissionContext } from "../../../../contexts/permissionContext/withPermissionContext";
import { DocumentTypeCode } from "@odata/GeneratedEnums";
import { ISmartFieldBlur, ISmartFieldChange } from "@components/smart/smartField/SmartField";
import { IEntity } from "@odata/BindingContext";
import {
    IProformaInvoiceIssuedEntity,
    IRegularDocumentItemEntity,
    ProformaInvoiceIssuedEntity,
    ProformaInvoiceReceivedEntity,
    RegularDocumentItemEntity
} from "@odata/GeneratedEntityTypes";
import {
    canConvertToDDOP,
    getProformaFormTitle,
    getSuccessMessage,
    handleCalculateVatChange,
    handleConvertToDDOP,
    handleDateProcessedChange,
    handleDocumentSpecificBlurHandlers,
    handleInvoiceTypeSwitchChange,
    isDDOP,
    onAfterLoad,
    onBeforeSave,
    setMatchingNumberRangeForDocumentWithoutFiscalYear,
    shouldNotAllowDDOPP
} from "../ProformaInvoice.utils";
import React from "react";
import { Button } from "../../../../components/button";
import { IDocumentFormViewProps } from "../../DocumentInterfaces";
import { NoPermission } from "../../../../views/notFound";
import { isAccountAssignmentCompany } from "@utils/CompanyUtils";
import { withPromisedComponent } from "@components/dialog/withPromisedComponent";

class ProformaInvoicesIssuedFormView extends IssuedDocumentBaseFormView {
    documentTypeCode = DocumentTypeCode.ProformaInvoiceIssued;

    constructor(props: IDocumentFormViewProps) {
        super(props);

        this.setMatchingNumberRangeForDocument = this.setMatchingNumberRangeForDocument.bind(this);
    }

    get preventDraftSave(): boolean {
        return super.preventDraftSave || !this.props.storage.afterLoaded;
    }

    async onAfterLoad(hasPreloadedData?: boolean): Promise<void> {
        const { storage } = this.props;

        await super.onAfterLoad(hasPreloadedData);
        onAfterLoad(storage, this);
        // use delayed refresh instead of sync, so that preventDraftSave has afterLoaded already set to true
        storage.refresh(true);
    }

    get hasAccountAssignment(): boolean {
        return isAccountAssignmentCompany(this.context) && isDDOP({ storage: this.props.storage });
    }

    // keep price with VAT by default when recalculating prices
    get defaultItemLastChangedProp(): keyof IRegularDocumentItemEntity {
        return RegularDocumentItemEntity.TransactionAmount;
    }

    get datePropForNumberRange(): keyof IProformaInvoiceIssuedEntity {
        return this.isCashBasisAccountingCompany ? ProformaInvoiceIssuedEntity.DateIssued
            : (isDDOP({ storage: this.props.storage }) ? ProformaInvoiceIssuedEntity.DateAccountingTransaction
                : ProformaInvoiceReceivedEntity.DateProcessed);
    }

    get datePropForCurrencyExchangeRate(): keyof IProformaInvoiceIssuedEntity {
        return this.datePropForNumberRange;
    }

    getFormTitle = (): string => {
        return getProformaFormTitle(this.props.storage);
    };

    async setMatchingNumberRangeForDocument(forceReload?: boolean) {
        setMatchingNumberRangeForDocumentWithoutFiscalYear(this.props.storage, this.props.storage.getEntity<IProformaInvoiceIssuedEntity>()[this.datePropForNumberRange] as Date, forceReload);

        await super.setMatchingNumberRangeForDocument(forceReload);
        this.props.storage.refresh();
    }

    handleConvertToDDOP = () => {
        handleConvertToDDOP(this);
    };

    renderSaveButtons(): React.ReactElement {
        const saveButtons = super.renderSaveButtons();
        if (canConvertToDDOP({ storage: this.props.storage })) {
            return (<>
                <Button onClick={this.handleConvertToDDOP}
                        isDisabled={this.shouldDisableSaveButton()}
                        isTransparent>
                    {this.props.storage.t("Proforma:Form.ConvertToTaxDocument")}</Button>
                {saveButtons}
            </>);
        }
        return saveButtons;
    }

    documentSpecificChangeHandlers = (e: ISmartFieldChange) => {
        this.handleDateIssuedChange(e);
        handleInvoiceTypeSwitchChange(this.props.storage, e);
        handleDateProcessedChange(this, e);
        handleCalculateVatChange(this, e);
    };

    documentSpecificBlurHandlers = (e: ISmartFieldBlur) => {
        handleDocumentSpecificBlurHandlers(this, e, true);
    };

    getSuccessMessage(isPosted: boolean, isClosed: boolean): string {
        return getSuccessMessage(this.props.storage, isPosted, isClosed);
    }

    onBeforeSave(): IEntity {
        const data: IProformaInvoiceIssuedEntity = super.onBeforeSave();
        return onBeforeSave(this.props.storage, data, this);
    }

    render(): JSX.Element {
        if (shouldNotAllowDDOPP(this.props.storage)) {
            // don't allow access to DDOPP for non vat payer
            return <NoPermission/>;
        }

        return super.render();
    }
}

export default withPromisedComponent(withConfirmationDialog(withPermissionContext(withDomManipulator(ProformaInvoicesIssuedFormView))));
