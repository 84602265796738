import React from "react";
import { Image, StyledAvatar } from "./Avatar.styles";
import { WithTranslation, withTranslation } from "react-i18next";
import { AvatarSize, AvatarStyle } from "../../enums";
import TestIds from "../../testIds";
import { SystemAvatarDefs } from "./SystemAvatarDef";

interface IProps extends WithTranslation {
    src: string;
    title?: string;
    id?: string;
    onClick?: (id: string) => void;
    size: AvatarSize | string;
    transparent?: boolean;
    isRound?: boolean;
    className?: string;
    style?: React.CSSProperties;
}

class Avatar extends React.PureComponent<IProps> {
    handleOnClick = () => {
        this.props.onClick?.(this.props.id);
    };

    getFileId = () => {
        const parts = this.props.src.split("/");
        return Number(parts[parts.length - 1]);
    };

    isSystemAvatar = () => {
        return this.getFileId() < 0;
    };

    getBackgroundStyle = () => {
        if (this.isSystemAvatar()) {
            return SystemAvatarDefs[this.getFileId()]?.style || AvatarStyle.None;
        } else {
            return AvatarStyle.None;
        }
    };

    render() {
        return (
            <StyledAvatar size={this.props.size}
                          transparent={this.props.transparent || !this.isSystemAvatar()}
                          isRound={this.props.isRound}
                          className={this.props.className} style={this.props.style}
                          onClick={this.handleOnClick}
                          backgroundStyle={this.getBackgroundStyle()}
                          data-testid={TestIds.CompanyAvatar}>
                <Image src={this.props.src}
                       alt={this.props.t("Components:Avatar.AlternativeText")}
                       title={this.props.title}
                />
                {this.props.children}
            </StyledAvatar>
        );
    }
}

export default withTranslation(["Components"])(Avatar);