import React, { useCallback, useEffect, useState } from "react";
import { BasicLoginTextBlock, LoginTitle } from "./LoginPage.styles";
import WriteLine from "../components/inputs/writeLine/WriteLine";
import { IInputOnChangeEvent } from "@components/inputs/input";
import { EMAIL_REGEX } from "../constants";
import { ButtonSize } from "@components/button/Button.utils";
import TestIds from "../testIds";
import { BackArrow } from "./components/BackArrow";
import { useLoginDispatch, useLoginSelector } from "./state/hooks";
import {
    resetPasswordRequest,
    selectIsInvalidVerificationCode,
    selectLoginEmail,
    selectRequestStatus,
    setAnimationType,
    setLoginEmail
} from "./state/loginSlice";
import { AnimationType, getError, LoginTranslationNamespaces, RequestStatus } from "./Login.utils";
import Redirects from "./components/Redirects";
import { ROUTE_LOGIN } from "../routes";
import LoginField from "./components/LoginField";
import { useTranslation } from "react-i18next";
import LoginButtonGroup from "./components/LoginButtonGroup";
import { Button } from "../components/button";
import ResendLink from "./components/ResendLink";

interface IProps {
}

const ResetPasswordRequest: React.FC<IProps> = (props) => {
    const { t } = useTranslation([...LoginTranslationNamespaces]);

    const dispatch = useLoginDispatch();
    const email = useLoginSelector(selectLoginEmail);
    const status = useLoginSelector(selectRequestStatus);
    const hasInvalidVerificationCode = useLoginSelector(selectIsInvalidVerificationCode);

    const [requestResetPasswordSent, setRequestResetPasswordSent] = useState(false);
    const [emailError, setEmailError] = useState<string | null>(null);

    const handleEmailChange = useCallback((args: IInputOnChangeEvent<string>) => {
        dispatch(setLoginEmail(args.value));
        setEmailError(null);
    }, [dispatch]);

    const handleSendResetPasswordEmail = useCallback(async () => {
        if (email && EMAIL_REGEX.test(email)) {
            // Handle sending reset password email
            dispatch(resetPasswordRequest(email));
            setRequestResetPasswordSent(true);
        } else {
            setEmailError(t("Login:Common.WrongEmailFormat"));
        }
    }, [dispatch, email, t]);

    useEffect(() => {
        dispatch(setAnimationType(AnimationType.StaticBiker));
    }, [dispatch]);

    if (requestResetPasswordSent) {
        return (<>
            <Redirects skipNoSessionCheck/>
            <LoginTitle data-testid={TestIds.Title}>
                <BackArrow url={ROUTE_LOGIN}/>
                {t("PassReset.Title")}
            </LoginTitle>

            <div data-testid={TestIds.Text}>
                <BasicLoginTextBlock>{t("PassReset.IdentityText", { email })}</BasicLoginTextBlock>
                <BasicLoginTextBlock>{t("PassReset.IdentityText2")}</BasicLoginTextBlock>
            </div>

            <LoginButtonGroup>
                <ResendLink onResend={handleSendResetPasswordEmail}/>
            </LoginButtonGroup>
        </>);
    } else {
        return (<>
            <Redirects skipNoSessionCheck/>
            <LoginTitle data-testid={TestIds.Title}>
                <BackArrow url={ROUTE_LOGIN}/>
                {t("PassReset.Title")}
            </LoginTitle>

            <BasicLoginTextBlock data-testid={TestIds.Text}>
                {t(hasInvalidVerificationCode ? "PassReset.NeedResendVerCode" : "PassReset.Description")}
            </BasicLoginTextBlock>

            <LoginField name={"email"} label={t("Login:Login.Email")}>
                <WriteLine value={email}
                           error={getError(emailError)}
                           onChange={handleEmailChange}/>
            </LoginField>

            <LoginButtonGroup>
                <Button type={"submit"}
                        size={ButtonSize.Big}
                        isDisabled={status === RequestStatus.Pending}
                        onClick={handleSendResetPasswordEmail}>
                    {t("PassReset.Send")}
                </Button>
            </LoginButtonGroup>
        </>);
    }
};

export default ResetPasswordRequest;