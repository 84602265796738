import React, { ReactElement } from "react";
import { TEntityKey } from "@evala/odata-metadata/src";
import BindingContext, { IEntity } from "../../odata/BindingContext";
import { PairingTitle, PairingValue } from "./bankTransactions/BankTransactions.styles";
import {
    DocumentCbaCategoryEntity,
    DocumentEntity,
    IDocumentCbaCategoryEntity,
    IDocumentItemCbaCategoryEntity,
    IPayingDocumentEntity,
    IPaymentDocumentItemEntity,
    IProformaInvoiceIssuedEntity,
    IRegularDocumentItemEntity,
    RegularDocumentItemEntity
} from "@odata/GeneratedEntityTypes";
import { DocumentTypeCode, PaymentDocumentItemTypeCode } from "@odata/GeneratedEnums";
import {
    getTransactionTypeFromEntity,
    hasAdditionalExRate,
    IBankCustomData
} from "./bankTransactions/BankTransactions.utils";
import { FormStorage } from "../../views/formView/FormStorage";
import { savePairChanges } from "@utils/PairTableUtils";
import { AmountDialog } from "./PairTableAmountDialog";
import {
    getBankItemDefaultDateAccountingTransaction,
    getCorrectExchangeRate,
    getCorrectTranAmount,
    getPairDataForRow
} from "./Pair.utils";
import { TId } from "@components/table";
import { getRow } from "@components/smart/smartTable/SmartTable.utils";
import { loadExchangeRate } from "../documents/Document.utils";
import { PAIR_ACTION_ID } from "../asset/PairingWithAssetTableView";
import TestIds from "../../testIds";
import { isAccountAssignmentCompany, isCashBasisAccountingCompany } from "@utils/CompanyUtils";
import { getEntitySetByDocumentType } from "@odata/EntityTypes";
import { IDocumentExtendedEntity } from "../documents/DocumentInterfaces";
import { IPairTableViewBaseProps, PairTableViewBaseForExtend } from "./PairTableViewBase";
import { formatCurrency } from "../../types/Currency";
import { isDefined, isObjectEmpty, roundToDecimalPlaces } from "@utils/general";
import { isSameCbaCategory } from "../cashBasisAccounting/CashBasisAccounting.utils";
import { TToolbarItem } from "@components/toolbar";


interface IPaymentOrigData {
    Amount: number;
    ClearedAmount: number;
}

export interface IPaymentDocumentItemEntityExpanded extends IPaymentDocumentItemEntity {
    ExchangeGain?: number;
    IsTaxDocument?: boolean;
    Gains?: IPaymentDocumentItemEntity[];
    OrigData?: IPaymentOrigData;
}

// prefix Doc --> values of paired DOCUMENT
// prefix Tran --> values of bank transaction (stored in rootStorage)
class PairTableView<P extends IPairTableViewBaseProps = IPairTableViewBaseProps> extends PairTableViewBaseForExtend<P> {
    needConfirmDialog: boolean;

    get hasAccountAssignment(): boolean {
        return isAccountAssignmentCompany(this.props.storage.context);
    }

    getPairedDocuments(): Record<TEntityKey, IPaymentDocumentItemEntityExpanded> {
        return this.props.storage.getCustomData().pairedDocuments || {};
    }

    savePairChanges = async (storage: FormStorage, pairedDocs: Record<TEntityKey, IPaymentDocumentItemEntityExpanded>): Promise<void> => {
        const type = this.getTransactionType();
        this.props.setBusy?.(true);
        await savePairChanges(this.props.rootStorage, pairedDocs, type);
        this.props.rootStorage.setDirty(this.props.rootStorage.data.bindingContext); // to allow draft save (shouldDisableDraftButton function)
        this.props.setBusy?.(false);
    };

    fetchDocumentCategories = async (document: IPayingDocumentEntity): Promise<void> => {
        const entitySet = getEntitySetByDocumentType(document.DocumentType?.Code as DocumentTypeCode);
        const res = await this.props.storage.oData.getEntitySetWrapper(entitySet)
            .query(document.Id)
            .select("Id", "Items")
            .expand(DocumentEntity.CbaCategory, q => {
                q.expand(DocumentCbaCategoryEntity.TaxImpact);
                q.expand(DocumentCbaCategoryEntity.Category);
                return q;
            })
            .expand("Items", q => {
                q.select(RegularDocumentItemEntity.Id, RegularDocumentItemEntity.CbaCategory);
                q.expand(RegularDocumentItemEntity.CbaCategory, query => query
                    .expand(DocumentCbaCategoryEntity.TaxImpact)
                    .expand(DocumentCbaCategoryEntity.Category));
                return q;
            })
            .fetchData<IDocumentExtendedEntity>();

        const doc = res.value;
        const rootStorage = this.props.rootStorage as FormStorage<unknown, IBankCustomData>;
        const map = rootStorage.getCustomData().pairedDocumentCategoriesMap ?? {};
        map[doc.Id] = doc.Items?.reduce((ret: (IDocumentCbaCategoryEntity | IDocumentItemCbaCategoryEntity)[], item: IRegularDocumentItemEntity) => {
            const c = isObjectEmpty(item.CbaCategory) ? doc.CbaCategory : item.CbaCategory;
            if (!ret.some(i => isSameCbaCategory(i, c))) {
                ret.push(c);
            }
            return ret;
        }, []);
        rootStorage.setCustomData({ pairedDocumentCategoriesMap: map });
    };

    handleRowPairClick = async (rowId: TId): Promise<void> => {
        const bc = rowId as BindingContext;
        const key = bc.getKey();
        let pairedDoc = this.getPairedDocuments();

        if (pairedDoc[key]) {
            delete pairedDoc[key];
        } else {
            if (this.props.skipAmountPair) {
                if (this.props.isSingleSelect) {
                    pairedDoc = { [key]: {} };
                } else {
                    pairedDoc[key] = {};
                }
            } else {
                const type = this.props.rootStorage.oData.metadata.getTypeForPath(this.getRowEntitySet(bc));
                const row = getRow(this.props.storage.tableAPI.getState().rows, bc);
                const document = row?.customData.entity;
                const numberOurs = document.NumberOurs;
                if (isCashBasisAccountingCompany(this.context)) {
                    this.fetchDocumentCategories(document);
                }
                const transDate = this.props.rootStorage.data.entity.DateBankTransaction ?? this.props.rootStorage.data.entity.DateIssued;

                const docCurrency = document.TransactionCurrency?.Code;
                const docAmount = getCorrectTranAmount(this.props.rootStorage, document.Id, Math.abs(document.TransactionAmountDue), docCurrency);
                const docExchangeRate = await getCorrectExchangeRate({
                    id: document?.Id,
                    date: transDate,
                    currency: docCurrency
                }, this.context);

                const amounts = this.getPairedAmounts();
                const _transAmount = Math.abs(this.props.rootStorage.data.entity.TransactionAmount || 0);
                const tranCurrency = this.getTransactionCurrency();
                const tranExRate = this.getTransactionExchangeRate();
                const docType = document.DocumentTypeCode;

                const _hasAdditionalExRate = hasAdditionalExRate(docCurrency, tranCurrency, this.context);

                let defaultExchangeRate;
                if (_hasAdditionalExRate) {
                    // this is case when we pair transaction and document with different currencies but non of them is system
                    defaultExchangeRate = await loadExchangeRate(this.props.storage, docCurrency, transDate);
                    if (!defaultExchangeRate) {
                        this.needConfirmDialog = true;
                        this.props.storage.setCustomData({
                            amountDialogRow: row
                        });
                    }
                }

                const data = getPairDataForRow({
                    amounts,
                    tranExRate,
                    docAmount,
                    docType,
                    defaultExchangeRate,
                    docCurrency,
                    tranCurrency,
                    originalTransactionAmount: _transAmount,
                    tranType: getTransactionTypeFromEntity(this.props.rootStorage),
                    docExchangeRate
                }, this.context);

                pairedDoc[key] = {
                    Amount: data.amount,
                    TransactionAmount: data.transactionAmount,
                    ExchangeGain: data.exchangeGain,
                    ExchangeRate: defaultExchangeRate,
                    ClearedAmount: data.ratedAmount,
                    IsTaxDocument: row.customData.entity.IsTaxDocument,
                    LinkedDocument: {
                        TransactionAmount: document?.TransactionAmount,
                        ExchangeRatePerUnit: docExchangeRate,
                        TransactionCurrencyCode: docCurrency,
                        BusinessPartner: document?.BusinessPartner,
                        TransactionCurrency: {
                            Code: docCurrency
                        },
                        TransactionAmountDue: row.customData.entity.TransactionAmountDue,
                        Id: parseInt(key as string),
                        NumberOurs: numberOurs as string,
                        DocumentTypeCode: row.customData.entity.DocumentType.Code,
                        _metadata: {
                            "": { type: `#${type.getFullName()}` }
                        }
                    }
                };

                if (this.hasAccountAssignment) {
                    const dateAccountingTrans = getBankItemDefaultDateAccountingTransaction(this.props.rootStorage);

                    pairedDoc[key] = {
                        ...pairedDoc[key],
                        DateAccountingTransaction: dateAccountingTrans,
                        LinkedDocument: {
                            ...pairedDoc[key].LinkedDocument,
                            DateAccountingTransaction: document?.DateAccountingTransaction
                        }
                    };
                }
            }
        }

        this.props.storage.setCustomData({ pairedDocuments: pairedDoc });
        this.forceUpdate();
        this.rerenderTable();
    };

    // forceUpdate won't re-render table, because no table props has changed
    // call tableApi.forceUpdate directly, to re-render just the table
    rerenderTable = (): void => {
        this.props.storage.tableAPI.forceUpdate();
    };

    setOriginItems(): void {
        const items = this.props.rootStorage.data.entity.Items as IPaymentDocumentItemEntity[];
        const pairedDocuments: Record<TEntityKey, IPaymentDocumentItemEntityExpanded> = {};

        // we convert Items to hashtable, this hashtable object merge exchange gain and payment to one single object (for more simple operations)
        // at the end when saving, we split this object to two posting items (of course only in case there is any exchange gain (pairing with different currencies then CZK))
        for (const item of (items || [])) {
            if (item.LinkedDocument?.Id) {
                const key = item.LinkedDocument.Id;
                if (item.PaymentDocumentItemTypeCode === PaymentDocumentItemTypeCode.Payment) {
                    const gains = items.filter(_item => (_item.PaymentDocumentItemTypeCode === PaymentDocumentItemTypeCode.ExchangeGain || _item.PaymentDocumentItemTypeCode === PaymentDocumentItemTypeCode.ExchangeLoss) && _item.LinkedDocument.Id === item.LinkedDocument.Id);
                    pairedDocuments[key] = {
                        ...pairedDocuments[key],
                        Id: item.Id,
                        IsTaxDocument: (item.LinkedDocument as IProformaInvoiceIssuedEntity).IsTaxDocument,
                        LinkedDocument: item.LinkedDocument,
                        ExchangeRate: item.ExchangeRate,
                        OrigData: {
                            Amount: item.Amount,
                            ClearedAmount: item.ClearedAmount
                        },
                        Amount: item.Amount,
                        ClearedAmount: item.ClearedAmount,
                        TransactionAmount: item.TransactionAmount,
                        SplitAccountAssignments: item.SplitAccountAssignments,
                        Gains: gains
                    };
                    if (this.hasAccountAssignment) {
                        pairedDocuments[key] = {
                            ...pairedDocuments[key],
                            DateAccountingTransaction: item.DateAccountingTransaction,
                            AccountAssignmentSelection: item.AccountAssignmentSelection
                        };
                    }

                    if (isDefined(item[BindingContext.NEW_ENTITY_ID_PROP])) {
                        pairedDocuments[key] = {
                            ...pairedDocuments[key],
                            [BindingContext.NEW_ENTITY_ID_PROP]: item[BindingContext.NEW_ENTITY_ID_PROP]
                        };
                    }
                }

                if (item.PaymentDocumentItemTypeCode === PaymentDocumentItemTypeCode.ExchangeGain || item.PaymentDocumentItemTypeCode === PaymentDocumentItemTypeCode.ExchangeLoss) {
                    // in DB value is always positive (gain or loss) is indicated by type
                    // but we need to have it like the sign matters
                    // so now on we ignore type and calculate it from sign of the amount
                    const _convertedGain = item.PaymentDocumentItemTypeCode === PaymentDocumentItemTypeCode.ExchangeLoss ? Math.abs(item.Amount) * -1 : item.Amount;

                    pairedDocuments[key] = {
                        ...pairedDocuments[key],
                        ExchangeGain: _convertedGain
                    };
                }
            }
        }

        this.origPairedDocuments = { ...pairedDocuments };
        this.props.storage.setCustomData({ rootStorage: this.props.rootStorage });
        this.props.storage.setCustomData({ pairedDocuments: pairedDocuments });
        this.getRootStorage().setCustomData({
            pairedStorage: this.props.storage
        });
        this.props.storage.data.tableAction = PAIR_ACTION_ID;
    }

    getTransactionExchangeRate = (): number => {
        return this.props.rootStorage.data.entity.ExchangeRatePerUnit ?? 1;
    };

    getTransactionCurrency = (): string => {
        return this.props.rootStorage.data.entity?.TransactionCurrency?.Code ?? this.props.rootStorage.data.entity?.TransactionCurrencyCode;
    };

    formatCurrency = (val: number): string => {
        const currency = this.getTransactionCurrency();
        return formatCurrency(val, currency);
    };

    getPairedAmounts = (): { transactionAmount: number, amount: number } => {
        const docs = (this.props.rootStorage.data.entity.Items || []).filter((link: IEntity) => {
            return !link.LinkedDocument?.Id;
        }).concat(Object.values(this.getPairedDocuments()));

        let transactionAmount = 0;
        let amount = 0;

        for (const doc of docs) {
            transactionAmount += doc.TransactionAmount || 0;
            amount += doc.Amount || 0;
        }

        return {
            transactionAmount,
            amount
        };
    };

    getRestAmount = (): number => {
        const totalAmount = Math.abs(this.props.rootStorage.data.entity.TransactionAmount || 0);
        const paidAmount = this.getPairedAmounts().transactionAmount;

        return roundToDecimalPlaces(2, totalAmount - paidAmount);
    };

    getPairingTitleValue = (): string => {
        return this.formatCurrency(this.getRestAmount());
    };

    getStaticToolbarItems = (): TToolbarItem[] => {
        const balanceValue = this.getRestAmount();
        const item = <PairingTitle data-testid={TestIds.PairingValueTitle} key={"pairing-title"}>
            {`${this.props.rootStorage.t("Document:Pair.ToPairTitle")}:`}
            <PairingValue value={balanceValue}
                          data-testid={TestIds.PairingValue}>{this.getPairingTitleValue()}</PairingValue>
        </PairingTitle>;

        return [item];
    };

    renderAmountDialog = (): ReactElement => {
        return <AmountDialog
            needConfirmDialog={this.needConfirmDialog}
            rootStorage={this.props.rootStorage}
            onClose={() => {
                this.needConfirmDialog = null;
                this.forceUpdate();
                this.rerenderTable();
            }}
            storage={this.props.storage}/>;
    };
}

export { PairTableView as PairTableViewForExtend };


