import { IAlertProps } from "@components/alert/Alert";
import { IPopperOptions } from "@components/popperWrapper";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import { Button, ButtonGroup, IProps as IButtonProps } from "../../components/button";

export interface IProps {
    onConfirm: () => void;
    onCancel: () => void;
    confirmText?: string | React.ReactElement;
    cancelText?: string | React.ReactElement;
    isDisabled?: boolean;
    isLight?: boolean;
    isTransparent?: boolean;
    ignoreTheme?: boolean;
    disabledAlert?: IAlertProps;
    cancelButtonProps?: Partial<IButtonProps>;
    confirmButtonProps?: Partial<IButtonProps>;
    useWrapper?: boolean;
}

export const buttonAlertPopperOptions: IPopperOptions = {
    placement: "bottom",
    modifiers: [
        {
            name: "offset",
            options: {
                offset: [0, 8]
            }
        }
    ]
};

class ConfirmationButtons extends React.PureComponent<IProps & WithTranslation> {
    getDisableAlert = (): IAlertProps => {
        return this.props.isDisabled ? this.props.disabledAlert : null;
    };

    render() {
        const Wrapper = this.props.useWrapper ? ButtonGroup : React.Fragment;
        return (
            // ButtonGroup can be replaced with fragment,
            // if we improve Toolbar to not use components but custom item definitions instead (or dedicated item type for confirmation buttons)
                <Wrapper>
                {this.props.children}
                {this.props.onCancel && <Button onClick={this.props.onCancel}
                                                isLight={this.props.isLight}
                                                ignoreTheme={this.props.ignoreTheme}
                                                isTransparent
                                                {...this.props.cancelButtonProps}>
                    {this.props.cancelText ?? this.props.t("Common:General.Cancel")}
                </Button>}
                <Button onClick={this.props.onConfirm}
                        isLight={this.props.isLight}
                        ignoreTheme={this.props.ignoreTheme}
                        isDisabled={this.props.isDisabled}
                        hoverAlert={this.getDisableAlert()}
                        alertPopperOptions={buttonAlertPopperOptions}
                        {...this.props.confirmButtonProps}>
                    {this.props.confirmText ?? this.props.t("Common:General.Confirm")}
                </Button>
                </Wrapper>
        );
    }
}

export default withTranslation(["Common"])(ConfirmationButtons);