import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { AppContext } from "../../contexts/appContext/AppContext.types";
import { addCompanyIdToUrl } from "../../contexts/appContext/AppContext.utils";
import { ROUTE_HOME } from "../../routes";
import { CustomerPortal } from "./CustomerPortal.utils";

interface ISwitchToCustomerPortalRouteParams {
    id: string;
}

interface IProps extends RouteComponentProps<ISwitchToCustomerPortalRouteParams> {
}

class SwitchToCustomerPortal extends React.PureComponent<IProps> {
    static contextType = AppContext;

    _triggered = false;

    componentDidMount() {
        this.switchToCustomerPortal();
    }

    componentDidUpdate() {
        this.switchToCustomerPortal();
    }

    switchToCustomerPortal() {
        if (this.context.loaded && !this._triggered) {
            this._triggered = true;

            const id = parseInt(this.props.match.params.id);

            CustomerPortal.isActive = true;
            this.props.history.replace(addCompanyIdToUrl(ROUTE_HOME, id));
        }
    }

    render(): React.ReactNode {
        return null;
    }
}


export default withRouter(SwitchToCustomerPortal);