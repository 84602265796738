import { EntitySetName, EntityTypeName } from "@odata/GeneratedEntityTypes";
import { CompanyPermissionCode, DocumentTypeCode } from "@odata/GeneratedEnums";
import { isCashBasisAccountingCompany } from "@utils/CompanyUtils";
import i18next from "i18next";
import { cloneDeep } from "lodash";

import { IAppContext } from "../../../contexts/appContext/AppContext.types";
import { Model } from "../../../model/Model";
import { AssetTranslations } from "../../asset/fixedAsset/FixedAsset.utils";
import { setDefByEntityType } from "../../getDefByEntityType";
import { getItemBreadCrumbsText, IDefinition, IGetDefinition } from "../../PageUtils";
import { commonDocumentTranslations } from "../Document.utils";
import { addCorrectiveDocument } from "../DocumentCommonDefs";
import { clearEmptyDateGroupDateFields, getDefinitions as getDocumentDefinitions } from "../DocumentDef";
import { addItemsSummaryDef } from "../extensions/itemsSummary/ItemsSummary.utils";
import { enhanceIssuedDocDefinition } from "../invoicesIssued/InvoicesIssuedDef";
import OtherReceivableFormView from "./OtherReceivableFormView";


export const getDefinitions: IGetDefinition = (context: IAppContext): IDefinition => {
    const hasAccountAssignment = !isCashBasisAccountingCompany(context);

    const definition = cloneDeep(getDocumentDefinitions({
        entitySet: EntitySetName.OtherReceivables,
        documentType: DocumentTypeCode.OtherReceivable,
        tableId: `${EntityTypeName.OtherReceivable}Table`,
        formId: `${EntityTypeName.OtherReceivable}Form`,
        formControl: OtherReceivableFormView,
        translationFiles: getDefinitions.translationFiles,
        getItemBreadCrumbText: (storage: Model) =>
                getItemBreadCrumbsText(storage, i18next.t("OtherReceivables:Breadcrumbs.NewReceivable"),
                        storage.data.entity?.NumberOurs && i18next.t("OtherReceivables:Breadcrumbs.ReceivableWithNumber", { number: storage.data.entity.NumberOurs })),
        permissions: [CompanyPermissionCode.InvoicesReceived],
        context
    }));

    enhanceIssuedDocDefinition(definition, {
        docType: DocumentTypeCode.OtherReceivable,
        transFile: "OtherReceivables",
        isBusinessPartnerOptional: true,
        hasAccountAssignment,
        context
    });

    addCorrectiveDocument(definition, hasAccountAssignment);
    addItemsSummaryDef(definition, context);

    return clearEmptyDateGroupDateFields(definition);
};

getDefinitions.translationFiles = ["OtherReceivables", ...AssetTranslations, ...commonDocumentTranslations];
setDefByEntityType(EntityTypeName.OtherReceivable, getDefinitions);