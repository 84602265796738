import React from "react";
import { NoPreviewStyled, NoPreviewTextStyled, SquirrelAnimStyled } from "./FileViewers.styles";
import TestIds from "../../testIds";
import { useTranslation } from "react-i18next";

interface IProps {
    text: string;
}

const NoPreviewSquirrel: React.FC<IProps> = ({ text }) => {
    const { t } = useTranslation("Components");

    return (
        <NoPreviewStyled data-testid={TestIds.NoPreview}>
            <SquirrelAnimStyled/>
            <NoPreviewTextStyled>
                {text ?? t("Components:FileViewers.NoPreviewText")}
            </NoPreviewTextStyled>
        </NoPreviewStyled>
    );
};

export default NoPreviewSquirrel;