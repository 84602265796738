import React, { Component } from "react";
import Dialog from "../../../components/dialog/Dialog";
import { Button } from "../../../components/button";
import { FormStorage } from "../../../views/formView/FormStorage";
import { getWeekdays } from "@components/inputs/date/utils";
import { AdditionalInfo, CalendarGrid, CalendarWrapper, Day, DayLabel, MonthYearLabel } from "./Attendance.styles";
import { IPrAttendanceEntity, PrAttendanceEntity } from "@odata/GeneratedEntityTypes";
import { IAttendanceCustomData } from "./AttendanceFormView";
import DateType, { DateFormat, getUtcDayjs } from "../../../types/Date";
import { roundToDecimalPlaces } from "@utils/general";
import { generateIntervalsForDay, refreshBalance, refreshSaldoMap } from "./Attendance.utils";

interface IProps {
    storage: FormStorage<IPrAttendanceEntity, IAttendanceCustomData>;
    onClose: (isConfirmed: boolean) => void;
}

export default class UsedWorkingPatternOverview extends Component<IProps> {
    usePattern = (): void => {
        const storage = this.props.storage;
        const saldoMap = storage.getCustomData().saldoMap;
        const daysBc = this.props.storage.data.bindingContext.navigate(PrAttendanceEntity.Days);
        const daysToUpdate: Set<number> = new Set();

        for (const [key, value] of Object.entries(saldoMap)) {
            // TODO: still not sure what this should do
            if (value < 0) {
                const dayIndex = parseInt(key);
                daysToUpdate.add(dayIndex);
                const day = this.props.storage.data.entity.Days.find(d => getUtcDayjs(d.Date).get("date") === dayIndex);
                generateIntervalsForDay(daysBc.addKey(day.Id), roundToDecimalPlaces(0, value * -60), storage);
            }
        }

        refreshSaldoMap(storage, daysToUpdate);
        refreshBalance(storage);
        storage.refresh();
        this.props.onClose(true);
    };

    handleCancel = (): void => {
        this.props.onClose(false);
    };

    render() {
        const weekdayLabels = getWeekdays();
        const entity = this.props.storage.data.entity;
        const startOfMonth = getUtcDayjs().set("month", entity.Month - 1).set("year", entity.Year).startOf("month");
        const firstDayOfMonthWeekday = startOfMonth.get("day");
        const offsetLength = firstDayOfMonthWeekday === 0 ? 6 : firstDayOfMonthWeekday - 1;

        const fundMap = this.props.storage.getCustomData().hoursFundMap;

        const totalFund = roundToDecimalPlaces(1, Object.values(fundMap).reduce((total, f) => {
            return total + f;
        }, 0));

        const planned = roundToDecimalPlaces(1, totalFund + (entity.Balance / 60));

        return <Dialog title={this.props.storage.t("Attendance:Form.UsedPattern")}
                       width={"420px"}
                       onClose={this.handleCancel}
                       onConfirm={this.usePattern}
                       footer={
                           <>
                               <Button isTransparent
                                       onClick={this.handleCancel}>
                                   {this.props.storage.t("Common:General.Close")}
                               </Button>
                               <Button onClick={this.usePattern}>
                                   {this.props.storage.t("Attendance:Form.UsePattern")}
                               </Button>
                           </>
                       }>
            <CalendarWrapper>
                <MonthYearLabel>
                    {DateType.format(startOfMonth.toDate(), DateFormat.monthAndYear)}
                </MonthYearLabel>
                <CalendarGrid>
                    {weekdayLabels.map((day, index) => <DayLabel key={day + index}>{day}</DayLabel>)}
                    {Array.from(Array(offsetLength).keys()).map((h, index) => {
                        return <Day key={"dummy" + index}/>;
                    })}
                    {Object.values(fundMap).map((h, index) => {
                        return <Day key={index}>
                            {h}
                        </Day>;
                    })}
                </CalendarGrid>
                <AdditionalInfo>
                    {this.props.storage.t("Attendance:Form.WorkingHoursFund")}
                    <b>&nbsp;{totalFund}&nbsp;{this.props.storage.t("Components:Calendar.HourPlaceholder")}</b><br/>
                    {this.props.storage.t("Attendance:Form.Planned")}
                    <b>&nbsp;{planned}&nbsp;{this.props.storage.t("Components:Calendar.HourPlaceholder")}</b>
                </AdditionalInfo>
            </CalendarWrapper>
        </Dialog>;
    }
}