import styled, { css } from "styled-components/macro";
import { IconButton } from "@components/button";
import { ShellBarWidth } from "./Main.utils";
import { LayoutStackOrder, LocalStackOrder } from "../../global.style";

export const SHELL_WIDTH = 570;
const defaultPadding = 82;

export const StyledExtendedShell = styled.div <{
    $show: boolean;
    $width?: number;
}>`
    width: ${props => props.$show ? (props.$width ?? SHELL_WIDTH) : 0}px;
    transition: width 0.5s;
    height: 100%;
    position: absolute;
    z-index: ${LayoutStackOrder.ExtendedShell};
    left: 64px;

    box-shadow: inset 0 0 40px 0 rgba(0, 0, 0, 0.2);
    background-image: linear-gradient(to top, #790399, #b10372);
`;

export const OverflowWrapper = styled.div`
    height: 100%;
    width: 100%;
    overflow: hidden;
`;

export const CloseIconButton = styled(IconButton)`
    right: 26px;
    top: 26px;
    position: absolute;
    z-index: ${LocalStackOrder.AboveContent};
`;

export const ContentWrapper = styled.div<{
    $responsive?: boolean;
}>`
    height: 100%;
    width: ${props => props.$responsive ? "100%" : `${SHELL_WIDTH}px`};
    display: flex;
    flex-direction: column;
`;

export const Content = styled.div<{ $hasHeader?: boolean; $padding?: string; }>`
    padding: ${props => props.$padding ?? `${defaultPadding}px`};
    min-height: 100%;
    ${props => props.$hasHeader && css`padding-top: 0;`}
`;

export const ShellHeader = styled.div<{ $padding?: string; }>`
    padding: ${props => props.$padding ?? `${defaultPadding}px`};
    padding-bottom: 0;
    flex: 0 0 auto;
`;

export const ExtendedShellOverlay = styled.div<{
    _show: boolean;
}>`
    width: ${props => props._show ? `calc(100vw - ${ShellBarWidth})` : 0};
    position: absolute;
    background: black;
    height: 100%;
    z-index: ${LayoutStackOrder.ExtendedShellBackdrop};
    left: ${ShellBarWidth};
    opacity: ${props => props._show ? 0.5 : 0};
    transition: opacity 0.3s;
`;
