import React from "react";
import { formatValue, IFormatOptions } from "@odata/OData.utils";
import BindingContext from "../../../odata/BindingContext";
import { Model } from "../../../model/Model";
import { compareDefinedArrays, isNotDefined, isObjectEmpty } from "@utils/general";
import { buildTreeFromAllItems } from "../../inputs/select/SelectAPI";
import { fetchItemsByInfo, getItemsForRenderCallbackArgs } from "./SmartSelectAPI";
import { AppContext } from "../../../contexts/appContext/AppContext.types";
import { WithOData, withOData } from "@odata/withOData";
import { ISmartEventData } from "../FieldInfo";
import { getEnumDisplayValue, getEnumName, isEnum, loadEnums } from "@odata/GeneratedEnums.utils";
import { FormStorage } from "../../../views/formView/FormStorage";
import { IFieldInfo } from "@odata/FieldInfo.utils";
import { TValue } from "../../../global.types";
import { IMultiSelectProps } from "../../inputs/select/MultiSelect";
import { ISelectItem, ISelectPropsBase, TSelectItemId } from "@components/inputs/select/Select.types";

export interface ISmartSelectBaseProps {
    // todo fieldInfo to fieldDef
    fieldInfo: IFieldInfo;
    bindingContext: BindingContext;
    storage?: Model;
    isHierarchical?: boolean;
    onClick?: (e: React.MouseEvent, args: ISmartEventData) => void;
    onIconActivate?: (e: React.MouseEvent, args: ISmartEventData) => void;
    onItemsFetched?: (bc: BindingContext, items: ISelectItem[]) => void;
    getCustomTabularData?: (val: TValue, args: IFormatOptions) => string[];
}

interface IProps extends ISmartSelectBaseProps, WithOData {
    selectProps?: ISelectPropsBase | IMultiSelectProps;
    select: React.ComponentType<any>;
    value?: TSelectItemId | TSelectItemId[];
}


class SmartSelectBase extends React.PureComponent<IProps> {
    static contextType = AppContext;
    //sadly, breaks typescript type checking
    //context: React.ContextType<typeof AppContext>;
    _isMounted: boolean;

    // we need to put this outside of state as we need sync operation with the property
    isLoading: boolean;

    componentDidMount = async () => {
        this._isMounted = true;
        if (this.shouldLoadItems()) {
            this.load();
        } else {
            this.setInitialItems();
        }
    };

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<{}>) {
        const hasValueChanged = Array.isArray(prevProps.value) && Array.isArray(this.props.value) ? !compareDefinedArrays(prevProps.value, this.props.value) : prevProps.value !== this.props.value;
        if (this.shouldLoadItems()) {
            this.load();
        } else if (hasValueChanged
            // the condition cannot be this.props.fieldInfo !== prevProps.fieldInfo because fieldInfo is updated on every render in SmartFieldUtils if fieldDef is set
            // => check is fieldInfo.fieldSettings.initialItems changed instead
            || this.props.fieldInfo?.fieldSettings?.initialItems !== prevProps.fieldInfo?.fieldSettings?.initialItems // if fieldsInfo.fieldSettings.initialItems was changed, it is probable, that initialItems are not set (e.g. after save of new entity)
            || (this.props.fieldInfo?.fieldSettings?.items !== prevProps.fieldInfo?.fieldSettings?.items && !this.props.fieldInfo?.fieldSettings?.items)
        ) {
            this.setInitialItems();
        }
    }

    shouldLoadItems = (): boolean => {
        const { fieldSettings } = this.props.fieldInfo;
        return !!fieldSettings.preloadItems || (!!fieldSettings.itemsFactory && fieldSettings.cacheFetchedItemsInFieldInfo !== false);
    };

    componentWillUnmount(): void {
        this._isMounted = false;
    }

    // fieldSettings.items can be callback, but once it's called, we work directly with the array
    get items(): ISelectItem[] {
        return this.props.fieldInfo.fieldSettings?.items;
    }

    set items(items: ISelectItem[]) {
        this.props.fieldInfo.fieldSettings.items = items;
    }

    load = async (reload?: boolean) => {
        // storage.loading -> do not trigger load before storage is loaded - it would load data according to wrong filters (based on previously loaded entity)
        if (this.isLoading || !this.props.storage || this.props.storage.loading) {
            return;
        }

        if (this.props.fieldInfo.fieldSettings.cacheFetchedItemsInFieldInfo === false && reload === true) {
            // remove items, so they can be fetched again without caching
            this.items = null;
        }

        if (this.props.fieldInfo && !this.items) {
            this.isLoading = true;
            this.forceUpdate();
            await this.fetchItems();
        }

        if (this.props.isHierarchical && this.items?.length > 0) {
            const item = this.items[0];
            // check whether items are already transformed
            if (isNotDefined(item.indent)) {
                this.items = buildTreeFromAllItems(this.items);
            }
        }

        if (this.isLoading) {
            this.isLoading = false;
            this.forceUpdate();
        }
    };

    isEnum = (): boolean => {
        return isEnum(this.props.fieldInfo);
    };

    getEnumName = (): string => {
        return getEnumName(this.props.fieldInfo, this.props.oData);
    };

    setInitialItems = async () => {
        const fieldInfo = this.props.fieldInfo;
        const shouldSetInitialItem = this.isEnum()
            // is this condition correct? shouldn't we actually always create initial item?
            || (isObjectEmpty(fieldInfo.fieldSettings?.items) && fieldInfo.fieldSettings?.shouldDisplayAdditionalColumns);

        if (!shouldSetInitialItem) {
            return;
        }

        let enumName: string;

        if (this.isEnum()) {
            // we need to load the enum name space even without initial item, to show correct translations in conditional dialog
            enumName = this.getEnumName();

            if (!enumName) {
                return;
            }

            const { namespacesPromise, notLoadedNamespaces } = loadEnums(enumName);

            if (notLoadedNamespaces.length > 0) {
                await namespacesPromise;
                // because we load the name space manually,
                // we have to trigger manual rerender as well
                this.forceUpdate();
            }
        }

        const value = this.props.value;

        if (!value) {
            return;
        }

        const allAdditionalProperties = new Set([...(this.props.fieldInfo.additionalProperties ?? []), ...(this.props.fieldInfo.columns ?? [])]?.filter(v => v).map(property => property.id));

        if (this.props.fieldInfo.fieldSettings.displayName) {
            allAdditionalProperties.add(this.props.fieldInfo.fieldSettings.displayName);
        }

        const values = Array.isArray(value) ? value : [value];
        let initialItems: ISelectItem[] = values.map(val => {
            const item: ISelectItem = {
                id: val,
                additionalData: {
                    Code: val
                }
            };

            if (this.isEnum()) {
                item.additionalData.Name = getEnumDisplayValue(enumName, val as string);
            }

            for (const prop of allAdditionalProperties) {
                try {
                    // we can't ensure always correct path
                    const bc = this.props.bindingContext.navigate(prop);
                    const val = this.props.storage.getValue(bc);
                    if (val) {
                        item.additionalData[prop] = val;
                    }
                } catch {
                }
            }

            if (this.props.fieldInfo.fieldSettings.displayName && !this.props.fieldInfo.formatter) {
                item.label = item.additionalData[this.props.fieldInfo.fieldSettings.displayName];
            } else if (this.isEnum()) {
                item.label = item.additionalData.Name;
            } else {
                item.label = formatValue(val, fieldInfo, {
                    entity: item.additionalData,
                    item: item.additionalData,
                    storage: this.props.storage
                }) ?? val?.toString() ?? "";
            }

            if (this.props.fieldInfo.columns) {
                item.tabularData = this.props.fieldInfo.columns.map(col => {
                    const val = item.additionalData[col.id];

                    return formatValue(val, {
                        ...col,
                        bindingContext: this.props.bindingContext.navigate(col.id)
                    }, {
                        entity: item.additionalData,
                        item: item.additionalData,
                        storage: this.props.storage
                    }) ?? val?.toString() ?? "";
                });
            }

            return item;
        });

        if (initialItems?.length) {
            if (!this.props.fieldInfo.fieldSettings) {
                this.props.fieldInfo.fieldSettings = {};
            }

            if (this.props.fieldInfo.fieldSettings.transformFetchedItems) {
                // apply the transformFetchedItems even to initial items or something could be missing from the item
                initialItems = this.props.fieldInfo.fieldSettings.transformFetchedItems(initialItems,
                    getItemsForRenderCallbackArgs(this.props.storage, this.props.fieldInfo, this.props.bindingContext, this.context));
            }

            this.props.fieldInfo.fieldSettings.initialItems = initialItems;
            this.props.storage.addActiveField(this.props.bindingContext);
            // make the call debounced, to improve performance
            this.props.storage.refreshFields(false, true);
        }
    };

    // todo when there are bigger amount of same SmartSelects used on one page, this function is kidna slow
    // it would be nice to only call it once for all selects with the same definition.
    // at the moment we only cache getItems, but not the rest of it even though some parts of it, like transformItems,
    // are quite performance heavy
    fetchItems = async () => {
        const { fieldInfo, storage, isHierarchical, bindingContext } = this.props;

        this.items = await fetchItemsByInfo(storage as FormStorage, fieldInfo, isHierarchical);

        if (fieldInfo.fieldSettings !== this.props.fieldInfo.fieldSettings) {
            console.warn(`SmartSelectBase::fetchItems(${bindingContext.getFullPath()}) - items loading was triggered with different fieldInfo.fieldSettings object than it was passed after data resolution. Copying items to new fieldSettings.`);
            if (!this.props.fieldInfo.fieldSettings) {
                this.props.fieldInfo.fieldSettings = {};
            }
            this.props.fieldInfo.fieldSettings.items = fieldInfo.fieldSettings.items;
        }

        this.props.onItemsFetched?.(bindingContext, this.items);

        if (this._isMounted) {
            if (storage) {
                // rerender read only item in the SmartFilterBar for the same bc as well
                storage.addActiveField(this.props.bindingContext);
                // make the call debounced, to improve performance
                this.props.storage.refreshFields(false, true);
            } else {
                this.forceUpdate();
            }
        }
    };

    handleClick = (e: React.MouseEvent) => {
        const { storage, bindingContext } = this.props;
        return this.props.onClick?.(e, { storage, bindingContext });
    };

    handleOpen = (): void => {
        this.load(true);
    };

    handleFocus = (): void => {
        // we don't want user that use keyboard to navigate wait for items to load on the first arrow down click (which seems like a bug because nothing happens)
        // => fetch immediately on focus
        this.load(true);
    };

    handleIconActivate = (e: React.MouseEvent) => {
        const { storage, bindingContext } = this.props;
        return this.props.onIconActivate?.(e, { storage, bindingContext });
    };

    render() {
        const Select = this.props.select;

        const items = this.props.fieldInfo?.fieldSettings?.itemsForRender?.(
            this.items ?? [],
            getItemsForRenderCallbackArgs(this.props.storage, this.props.fieldInfo, this.props.bindingContext, this.context)
        ) || this.items || [];

        let customTabularData: string[] = null;
        if (this.props.getCustomTabularData) {
            customTabularData = this.props.getCustomTabularData(this.props.value as TValue, {
                storage: this.props.storage,
                bindingContext: this.props.bindingContext
            });
        }

        // take out properties that are only relevant of SmartSelectBase
        const {
            fieldInfo,
            bindingContext,
            storage,
            isHierarchical,
            onClick,
            onIconActivate,
            oData,
            ...restSelectProps
        } = this.props;

        return (<Select
                {...this.props.selectProps}
                {...restSelectProps}
                onClick={this.handleClick}
                isLoading={this.isLoading}
                onOpen={this.handleOpen}
                onIconActivate={this.handleIconActivate}
                onFocus={this.handleFocus}
                items={items}
                initialItems={this.props.fieldInfo?.fieldSettings?.initialItems}
                columns={this.props.fieldInfo?.columns}
                customTabularData={customTabularData}
            />
        );
    }
}

export default withOData(SmartSelectBase);