import { IRow, TId } from "@components/table";
import React from "react";

import SmartHierarchyTable from "../../components/smart/smartTable/SmartHierarchyTable";
import { RowAction } from "../../enums";
import memoizeOne from "../../utils/memoizeOne";
import { SmartHeaderStyled } from "../../views/formView/FormView.styles";
import { TableButtonsAction } from "../../views/table/TableToolbar.utils";
import TableView, { ITableViewBaseProps, ITableViewBaseState } from "../../views/table/TableView";
import { TableWrapper } from "../../views/table/TableView.styles";
import View from "../../views/View";
import { StyledInfoText, StyledInfoWrapper } from "../chartOfAccounts/ChartOfAccounts.styles";
import {
    accountsIsRowWithoutAction,
    accountsRowsFactory,
    loadUnRemovableAccounts
} from "../chartOfAccounts/ChartOfAccounts.utils";
import CoAEditableHeader from "./CoAEditableHeader";

interface IState extends ITableViewBaseState {
    errorText?: string;
    unRemovableAccounts?: number[];
}

interface IProps extends ITableViewBaseProps {
}

class ChartOfAccountsTemplatesTableView extends TableView<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            ...this.state,
            errorText: null,
            unRemovableAccounts: null
        };

        // to provide correct file name for excel export
        props.storage.data.definition.title = this.props.parentEntity?.Name;
    }

    componentDidMount() {
        super.componentDidMount();
        this.loadUnRemovableAccounts();
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        this.loadUnRemovableAccounts();
    }

    loadUnRemovableAccounts = memoizeOne(
            async () => {
                const unRemovableAccounts = await loadUnRemovableAccounts(this.props.storage, this.props.parentEntity?.Id);
                this.setState({ unRemovableAccounts });
            }, () => [this.props.parentEntity?.Id]);

    get isReadOnly(): boolean {
        return this.props.parentEntity?.Id < 0;
    }

    isRowWithoutAction = (rowId: TId, action: RowAction, row: IRow): boolean => {
        return accountsIsRowWithoutAction(rowId, action, row, this.state.unRemovableAccounts, this.props.storage);

    };

    rowsFactory = (rows: IRow[]): IRow[] => {
        return accountsRowsFactory(rows, this.props.storage, this.state.unRemovableAccounts, this.getRowActionType());
    };

    render() {
        const visibleButtons = [
            TableButtonsAction.XLSXExport,
            TableButtonsAction.CSVExport,
            TableButtonsAction.PdfExport,
            TableButtonsAction.ExpandCollapseAll,
            TableButtonsAction.Sorting
        ];
        if (!this.isReadOnly) {
            visibleButtons.push(
                TableButtonsAction.Add,
                TableButtonsAction.Remove
            );
        }
        return (
            <View hotspotContextId={this.props.storage.id}
                  style={{ padding: "38px", marginTop: "-28px" }}>
                {this.context.getAddingNewCompany() ? null : this.isReadOnly ?
                    <SmartHeaderStyled title={this.props.parentEntity?.Name} shouldHideVariant/>
                    :
                    <CoAEditableHeader parentEntity={this.props.parentEntity}
                                        storage={this.props.storage}/>
                }
                {this.renderToolbar(visibleButtons)}
                {!this.state.errorText &&
                    <TableWrapper>
                        <SmartHierarchyTable {...this.getTableSharedProps()}
                                             loadAll/>
                    </TableWrapper>
                }
                {this.state.errorText &&
                    <StyledInfoWrapper>
                        <StyledInfoText>
                            {this.state.errorText}
                        </StyledInfoText>
                    </StyledInfoWrapper>
                }
                {this.renderDefaultDialogs()}
            </View>
        );
    }
}

export default ChartOfAccountsTemplatesTableView;
