export enum KeyboardShortcut {
    ALT_D = "ALT_D",
    ALT_N = "ALT_N",
    ALT_C = "ALT_C",
    ALT_R = "ALT_R",
    ALT_S = "ALT_S",
    ALT_X = "ALT_X",
    CTRL_Q = "CTRL_Q",
    ALT_UP = "ALT_UP",
    ALT_DOWN = "ALT_DOWN",
    ALT_SHIFT_UP = "ALT_SHIFT_UP",
    ALT_SHIFT_DOWN = "ALT_SHIFT_DOWN",
    ALT_SHIFT_S = "ALT_SHIFT_S",
    SHIFT_SHIFT = "SHIFT_SHIFT",
}

export interface IKeyboardShortcut {
    id: KeyboardShortcut;
    keys: {
        char: string;
        // alt key on Windows, option key on Mac
        altKey?: boolean;
        ctrlKey?: boolean;
        shiftKey?: boolean;
    }[];
}

/** If callback returns true, the event is considered as resolved and will not be propagated to another subscriber */
export type TSubscriberCallback = (shortcut: KeyboardShortcut, event: KeyboardEvent) => boolean;

export interface ISubscriber {
    callback: TSubscriberCallback,
    shortcuts: KeyboardShortcut[];
    /** This subscriber will always be called first, even if other subscribers has subscribed after this one */
    isPrioritized?: boolean;
}