import { HotspotViewIds } from "@components/hotspots/HotspotViewIds";
import { getEntitySetByDocumentType } from "@odata/EntityTypes";
import { EntitySetName } from "@odata/GeneratedEntityTypes";

import BindingContext from "../../../odata/BindingContext";
import memoizeOne from "../../../utils/memoizeOne";
import { WithBusyIndicator, withBusyIndicator } from "../../busyIndicator/withBusyIndicator";
import { IRow } from "../../table";
import SelectionTableViewBase, { ISelectionTableViewProps } from "../SelectionTableViewBase";

export interface IPairingDocumentTableViewProps extends ISelectionTableViewProps, WithBusyIndicator {
}

class PairingDocumentTableView extends SelectionTableViewBase<IPairingDocumentTableViewProps> {
    _hotspotContextId = HotspotViewIds.PairingDocTableView;

    getRowEntitySet = (row: IRow): EntitySetName => {
        return getEntitySetByDocumentType(row.customData.entity.DocumentType?.Code);
    };

    getCorrectBindingContext = (bindingContext: BindingContext, row: IRow): BindingContext => {
        return bindingContext.createNewBindingContext(this.getRowEntitySet(row)).addKey(bindingContext.getKey());
    };

    // change the bc different caused by "getCorrectBindingContext" back to the entity set of the table rows
    getSelectedRows = memoizeOne(() => {
        const oldBc = this.props.storage.data.rowBindingContext;
        const bc = oldBc?.createNewBindingContext(this.props.storage.data.bindingContext.getPath()).addKey(oldBc.getKey());

        return bc ? [bc] : [];
    }, () => [this.props.storage.data.rowBindingContext]);

    handleRowSelect = (bindingContext: BindingContext): void => {
        const correctedBindingContext = this.getCorrectBindingContext(bindingContext, this.getRow(bindingContext));
        this.props.onRowSelect?.(correctedBindingContext);
    };
}

export default withBusyIndicator({ passBusyIndicator: true })(PairingDocumentTableView);
