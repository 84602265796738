import { ConnectionStatus } from "@components/connectionDialog/ConnectionDialog";
import { StatusNoIcon, StatusYesIcon } from "@components/icon";
import { IColumnContent, ListItemContentRow } from "@components/objectList";
import { DataBoxEntity, IDataBoxEntity } from "@odata/GeneratedEntityTypes";
import { StyledPermissionRow } from "@pages/dataBox/DataBox.styles";
import memoize from "@utils/memoize";
import i18next from "i18next";
import React from "react";

import { DASH_CHARACTER, DATABOX_REST_URL } from "../../constants";
import { IconSize } from "../../enums";
import TestIds from "../../testIds";
import { IFormStorageDefaultCustomData } from "../../views/formView/FormStorage";

export const CHECK_CONNECTION_URL = `${DATABOX_REST_URL}/details`;

export interface IDataBoxCustomData extends IFormStorageDefaultCustomData {
    connectionStatus?: ConnectionStatus;
    connectionError?: string;
}

const getPermissionIcon = memoize((value: boolean): React.ReactElement => {
    const Icon = value ? StatusYesIcon : StatusNoIcon;
    return (<Icon width={IconSize.S} height={IconSize.S}/>);
});

const getEmptyDataBoxTile = memoize((): IColumnContent[] => {
    return [{
        content: <ListItemContentRow data-testid={TestIds.State} label={i18next.t("DataBox:DataBoxState")}
                                     text={i18next.t("DataBox:DataBoxNotConnected")}/>
    }];
});

export const getListItemContents = (entity: IDataBoxEntity, isDialog: boolean): IColumnContent[] => {
    if (!entity?.DataBoxId) {
        return isDialog ? [] : getEmptyDataBoxTile();
    } else {
        return [{
            content: (<>
                {!isDialog &&
                        <ListItemContentRow
                                hasSpaceAfter
                                color={entity.IsActive ? "C_SEM_text_good" : "C_SEM_text_warning"}
                                label={i18next.t("DataBox:DataBoxState")}
                                text={i18next.t(`DataBox:${entity.IsActive ? "DataBoxConnected" : "ConnectionDeactivated"}`)}/>}

                <ListItemContentRow label={i18next.t("DataBox:DataBoxID")} text={entity.DataBoxId}/>
                <ListItemContentRow label={i18next.t("DataBox:Name")} text={entity.CompanyName ?? DASH_CHARACTER}/>

                <ListItemContentRow label={i18next.t("DataBox:LegalNumber")}
                                    text={entity.LegalNumber ?? DASH_CHARACTER}/>
                <ListItemContentRow label={i18next.t("DataBox:Address")} text={entity.CompanyAddress ?? DASH_CHARACTER}
                                    hasSpaceAfter/>

                <ListItemContentRow data-testid={TestIds.UserName} label={i18next.t("DataBox:User")}
                                    text={entity.DataBoxUserName}/>
                <ListItemContentRow label={i18next.t("DataBox:Login")} text={entity.UserName}/>
            </>)
        }, {
            title: i18next.t("DataBox:Permissions.Title"),
            content: (<>
                {[DataBoxEntity.CanCheckMessages, DataBoxEntity.CanSendMessages, DataBoxEntity.CanReadMessages].map(permission => {
                    return (<StyledPermissionRow key={permission} data-testid={TestIds.DataBoxPermissionRow}>
                        {getPermissionIcon(entity[permission] as boolean)}
                        <span>{i18next.t(`DataBox:Permissions.${permission}`)}</span>
                    </StyledPermissionRow>);
                })}
            </>)
        }];
    }
};