import styled from "styled-components/macro";
import DashboardTileBackground from "../../dashboard/DashboardTileBackground";
import { DashboardTileHorizontalPadding } from "../../dashboard/Dashboard.styles";
import { T_HEADER_bold, T_PLAIN_small } from "../../../global.style";


export const StyledCustomerSupportTile = styled(DashboardTileBackground)`
    position: relative;
    padding: 24px ${DashboardTileHorizontalPadding}px 0 72px;

    svg {
        position: absolute;
        top: 0;
        left: 0;
    }

    dl {
        margin: 0;
        padding: 0;
        ${T_PLAIN_small};

        dt {
            float: left;
            padding-right: 3px;
        }

        dd {
            margin-left: 0;
            margin-bottom: 3px;
        }
    }
`;

export const Heading = styled.h2`
    margin-bottom: 24px;

    ${T_HEADER_bold};
`;
