import styled, { css, keyframes } from "styled-components/macro";
import DashboardTileBackground from "../../dashboard/DashboardTileBackground";
import { PureTextArea } from "../../inputs/textArea/TextArea";
import { T_PLAIN_small } from "../../../global.style";
import { ScrollBar } from "../../scrollBar";
import Checkbox from "../../inputs/checkbox";
import { DashboardTileHorizontalPadding, DashboardTileVerticalPadding } from "../../dashboard/Dashboard.styles";
import { transparentize } from "polished";
import { isDefined } from "@utils/general";

export const StyledTodoListTile = styled(DashboardTileBackground)`
    align-items: stretch;
    justify-content: flex-start;
    padding: 0;
`;

export const StyledTodoList = styled(ScrollBar)`
    padding: 0 ${DashboardTileHorizontalPadding}px ${DashboardTileVerticalPadding}px;
    flex: 1 1 auto;
    height: 0;
`;

const remove = keyframes`
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
`;

export const StyledTodoListItem = styled.div<{ $hideAnimationDelay: number }>`
    position: relative;
    padding-left: 30px;
    ${props => isDefined(props.$hideAnimationDelay) && css`
        animation: ${remove} .3s ease-out;
        animation-delay: ${props.$hideAnimationDelay}s;
    `};
`;

export const CheckboxStyled = styled(Checkbox)`
    position: absolute;
    top: 15px;
    left: 0;
    transform: translate(0, -50%);
`;

const getDashImage = (color: string) =>
    `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='6' height='30' preserveAspectRatio='none' viewBox='0 0 6 30'%3e%3cg%3e%3crect width='2' height='1' x='0' y='27' fill='${color}'%3e%3c/rect%3e%3c/g%3e%3c/svg%3e")`;

export const PureTextAreaStyled = styled(PureTextArea)`
    background: ${props => props.theme.C_ACT_hover_without_opacity} ${getDashImage("rgba(0,0,0,0.1)")} top left repeat;

    textarea {
        ${T_PLAIN_small};
        padding: 0;
        line-height: 30px;
        border: none;
        background-color: transparent;
        color: ${props => props.theme.C_TEXT_primary};
        max-height: unset;
    }

    textarea::selection {
        background-color: ${props => transparentize(0.5, props.theme.C_BG_menu)};
    }
`;
