import { getCleanColumnId, IReportColumnGroupDef, IReportRowDef } from "@components/smart/smartTable";
import { IReportStorageDefaultCustomData, ReportStorage } from "../ReportStorage";
import { IEntity } from "@odata/BindingContext";
import memoize from "../../../utils/memoize";

export interface IBalanceSheetCustomData extends IReportStorageDefaultCustomData {
    sendingMessageDialogOpen?: boolean;
    messageReportType?: string;
}

export const enhanceRowsWithIsAsset = (row: IReportRowDef, isAsset: boolean) => {
    row.Value.IsAsset = isAsset;

    for (const child of row.Rows) {
        enhanceRowsWithIsAsset(child, isAsset);
    }
};

export const getColumnGroupForColumn = memoize((columnId: string, storage: ReportStorage): IReportColumnGroupDef => {
    const columnGroups: IReportColumnGroupDef[] = storage.tableAPI.getState().data.ColumnGroups;

    return columnGroups.find(colGroup => colGroup.ColumnsInGroup.includes(getCleanColumnId(columnId)));
});

export const getAccountsPropNames = (columnId: string) => {
    const splitted = columnId.split("_");
    const isComp = splitted.slice(-1)[0] === "Comp";

    splitted[0] += "Accounts";

    return {
        // without date part
        generic: splitted[0] + (isComp ? "_Comp" : ""),
        // with date part
        extended: splitted.join("_")
    };
};

export const getAllAccounts = (id: string, entity: IEntity) => {
    const accounts: string[] = [];
    const props = getAccountsPropNames(id);

    if (entity[props.generic]) {
        accounts.push(...entity[props.generic]);
    }

    if (props.extended !== props.generic && entity[props.extended]) {
        accounts.push(...entity[props.extended]);
    }

    return accounts;
};