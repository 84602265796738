import React, { useCallback, useEffect, useRef } from "react";

import MessageFile from "./MessageFile";
import { UploadedFilesStyled } from "./TicketMessage.styles";

interface IProps {
    files: File[];
    onRemove: (file: File, idx: number) => void;
}

const UploadedFiles: React.FC<IProps> = ({ files, onRemove }) => {
    const fileUrl = useRef("");

    useEffect(() => {
        return () => {
            if (fileUrl.current) {
                // free memory on component unmount
                URL.revokeObjectURL(fileUrl.current);
            }
        };
    }, []);

    const getFileUrl = useCallback((file: File) => {
        if (fileUrl.current) {
            URL.revokeObjectURL(fileUrl.current);
        }

        fileUrl.current = URL.createObjectURL(file);

        return fileUrl.current;
    }, []);

    const handleRemove = useCallback((idx) => {
        const file = files[idx];
        onRemove(file, idx);
    }, [files, onRemove]);

    const handleClick = useCallback((idx) => {
        const file = files[idx];
        window.open(getFileUrl(file));
    }, [files]);

    return files?.length ? (
        <UploadedFilesStyled>
            {files.map((f, idx) => (
                <MessageFile key={idx} id={idx} name={f.name} onClick={handleClick} onRemove={handleRemove}/>
            ))}
        </UploadedFilesStyled>
    ) : null;
};

export default UploadedFiles;