import { isDateType, isNumericType } from "@evala/odata-metadata/src";
import { IFieldInfo } from "@odata/FieldInfo.utils";
import { debounce } from "lodash";
import React from "react";

import { AppContext } from "../../../contexts/appContext/AppContext.types";
import { P13nType, TextAlign } from "../../../enums";
import { Optional } from "../../../global.types";
import BindingContext from "../../../odata/BindingContext";
import memoizeOne from "../../../utils/memoizeOne";
import { ILoadMoreItemsEvent, ISort, ITableProps, TColumn, TId } from "../../table";
import { isMetaColumn } from "../../table/TableUtils";
import TableWithAutoSizedColumns from "../../table/TableWithAutoSizedColumns";

export interface IGroupToggleEvent {
    id: TId;
}

export interface ISmartLoadMoreItemsEvent {
    startIndex: number;
    stopIndex: number;
    group?: BindingContext;
}

type TSmartTableBaseColumn = Optional<IFieldInfo, "bindingContext">[];

export interface ISmartTableBaseProps extends Omit<ITableProps, "columns" | "onGroupToggle"> {
    /** Id used in personalization service */
    tableId: string;
    columns: TSmartTableBaseColumn;
    bindingContext?: BindingContext;
    onGroupToggle?: (props: IGroupToggleEvent) => void;
    onLoadMoreRows?: (props: ISmartLoadMoreItemsEvent) => void;
    loaded?: boolean;
    passRef?: React.Ref<HTMLDivElement>;
}

interface ISmartTableBaseState {
    initialColumnWidths?: Record<string, number>;
}

class SmartTableBase extends React.Component<ISmartTableBaseProps, ISmartTableBaseState> {
    static contextType = AppContext;

    _isMounted: boolean;

    state: ISmartTableBaseState = {
        initialColumnWidths: {}
    };

    componentDidMount() {
        this._isMounted = true;

        this.init();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    init = async (): Promise<void> => {
        const initialColumnWidths = await this.context.p13n.get(this.props.tableId, P13nType.Widths) ?? {};

        if (this._isMounted) {
            this.setState({
                initialColumnWidths
            });
        }
    };
    handleSortChange = (sorts: ISort[]): void => {
        const sort = sorts[0];

        this.props.onSortChange?.([sort]);
    };

    handleColumnResize = (columnId: TId, newWidth: number, columnsWidths: Record<string, number>): void => {
        this.context.p13n.update(this.props.tableId, P13nType.Widths, columnsWidths);
    };

    // use debounce, to prevent too many requests,
    // which can cause poor performance.
    // remove debounce, if it causes unforeseen errors
    handleLoadMoreRows = debounce((args: ILoadMoreItemsEvent): void => {
        if (!this.props.onLoadMoreRows) {
            console.error("SmartTableBase: missing prop handleLoadMoreRows");
            return;
        }

        this.props.onLoadMoreRows((args as unknown) as ISmartLoadMoreItemsEvent);
    }, 100);

    handleGroupToggle = async (id: TId): Promise<void> => {
        if (this.props.onGroupToggle) {
            this.props.onGroupToggle({ id });
        }
    };

    getColumnTextAlign = (column: IFieldInfo): TextAlign => {
        let textAlign;

        if (column.textAlign) {
            textAlign = column.textAlign;
        } else if (column.bindingContext) {
            const property = column.bindingContext.getNavigationBindingContext(column.fieldSettings?.displayName).getProperty();
            textAlign = isNumericType(property) || isDateType(property) ? TextAlign.Right : TextAlign.Left;
        }

        return textAlign as TextAlign;
    };

    prepareColumns = (columns: any[]): TColumn[] => {
        return columns.map((column: any) => {
                if (isMetaColumn(column)) {
                    return {
                        ...column,
                        columns: this.prepareColumns(column.columns)
                    };
                } else {
                    return {
                        id: column.id, label: column.label,
                        isHighlighted: column.isHighlighted,
                        stretchContent: column.stretchContent,
                        info: column.info || column.tooltip,
                        border: column.border,
                        disableSort: column.fieldSettings?.disableSort ?? column.disableSort,
                        textAlign: column.textAlign ?? this.getColumnTextAlign(column),
                        width: (column.width && typeof column.width === "string") ? parseInt(column.width) : column.width
                    };
                }
            }
        );
    };

    getColumns = memoizeOne((): TColumn[] => {
        return this.prepareColumns(this.props.columns);
    }, () => [this.props.columns]);

    render() {
        return (
            <TableWithAutoSizedColumns {...this.props}
                                       busy={!this.props.loaded}
                                       customBusyContent={this.props.customBusyContent}
                                       onGroupToggle={this.handleGroupToggle}
                                       rowCount={this.props.rowCount}
                                       onAddingRowCancel={this.props.onAddingRowCancel}
                                       tableId={this.props.tableId}
                                       onLoadMoreItems={this.handleLoadMoreRows}
                                       minimumBatchSize={this.props.minimumBatchSize}
                                       onSortChange={this.handleSortChange}
                                       disableSort={this.props.disableSort}
                                       onColumnResize={this.handleColumnResize}
                                       onRowSelect={this.props.onRowSelect}
                                       onRowContextMenuSelection={this.props.onRowContextMenuSelection}
                                       selectedRows={(this.props.selectedRows ?? []).map(item => item.toString())}
                                       sort={this.props.sort}
                                       noDataText={this.props.noDataText}
                                       disableVirtualization={this.props.disableVirtualization}
                                       isForPrint={this.props.isForPrint}
                                       columns={this.getColumns()}
                                       rows={this.props.rows}
                                       addingRow={this.props.addingRow}
                                       addingRowParent={this.props.addingRowParent}
                                       onRowAdd={this.props.onRowAdd}
                                       rowAction={this.props.rowAction}
                                       rowIcon={this.props.rowIcon}
                                       passRef={this.props.passRef}
                                       hierarchy={this.props.hierarchy}
                                       loaded={this.props.loaded && !!this.state.initialColumnWidths}
                                       initialColumnWidths={this.state.initialColumnWidths}
            />

        );
    }
}

export { SmartTableBase };