import { ISmartFieldChange } from "@components/smart/smartField/SmartField";
import { EntitySetName, IAssetEntity, IChartOfAccountsEntity } from "@odata/GeneratedEntityTypes";
import { reloadAccounts } from "@pages/accountAssignment/Account.utils";
import { DateOfTransactionPath, DebitAccountIdPath } from "@pages/asset/unorganizedAsset/dialogs/AddToCostsDef";
import { getFiscalDataCorrespondingToDateAccountingTransaction } from "@pages/documents/Document.utils";
import { getActiveFiscalYear } from "@pages/fiscalYear/FiscalYear.utils";
import React from "react";

import { withPermissionContext } from "../../../../contexts/permissionContext/withPermissionContext";
import BindingContext from "../../../../odata/BindingContext";
import DialogFormView, { IDialogFormViewProps } from "../../../../views/formView/DialogFormView";
import { getAlertFromError } from "../../../../views/formView/Form.utils";
import { IFormStorageDefaultCustomData, IFormStorageSaveResult } from "../../../../views/formView/FormStorage";
import { addToCosts } from "../../fixedAsset/FixedAsset.utils";

export interface IAddToCostsCustomData extends IFormStorageDefaultCustomData {
    documentItemIds?: number[];
    header?: React.ReactElement;
}


class AddToCostsDialogFormView extends DialogFormView<IAssetEntity, IDialogFormViewProps<IAssetEntity, IAddToCostsCustomData>> {
    _lastDateOfTransaction: Date;

    onAfterLoad = async () => {
        this._lastDateOfTransaction = this.props.storage.getValueByPath(DateOfTransactionPath);
        this.setDefaultDebitAccount();
        return super.onAfterLoad();
    };

    handleDateOfTransactionChange = async (e: ISmartFieldChange): Promise<void> => {
        if (e.bindingContext.getPath() === DateOfTransactionPath && e.triggerAdditionalTasks) {
            const { storage } = this.props;
            const hasError = storage.validateFieldSync(e.bindingContext);
            if (hasError) {
                // do not reload accounts if date is not valid (e.g. not in active FY or out of range)
                return;
            }
            const DateOfTransaction = storage.getValueByPath(DateOfTransactionPath);
            const previousFiscalData = getFiscalDataCorrespondingToDateAccountingTransaction(storage, this._lastDateOfTransaction);
            const { fiscalYear } = getFiscalDataCorrespondingToDateAccountingTransaction(storage, DateOfTransaction);
            this._lastDateOfTransaction = DateOfTransaction;

            const hasChanged = previousFiscalData?.fiscalYear?.Id !== fiscalYear?.Id;
            if (hasChanged) {
                storage.setBusy(true);
                await reloadAccounts(storage, !!fiscalYear, DebitAccountIdPath);
                storage.setBusy(false);
            }
        }
    };

    handleChange(e: ISmartFieldChange): void {
        super.handleChange(e);
        this.handleDateOfTransactionChange(e);
    }

    setDefaultDebitAccount = async (): Promise<void> => {
        const { storage } = this.props;
        const fy = getActiveFiscalYear(storage.context);
        const res = await storage.oData.getEntitySetWrapper(EntitySetName.ChartsOfAccounts).query()
                .filter(`FiscalYear/Id eq ${fy.Id}`)
                .expand("Accounts", q => {
                    q.filter("Number eq '548'")
                            .select("Id, Number, Name");
                })
                .fetchData<IChartOfAccountsEntity[]>();
        const account = res.value?.[0].Accounts?.[0];
        if (account) {
            const bc = storage.data.bindingContext.navigate(DebitAccountIdPath);
            const parentBc = bc.getParent();
            this.props.storage.setValue(bc, account.Id);
            this.props.storage.setValue(parentBc.navigate(BindingContext.localContext("Name")), account.Name);
            this.props.storage.setValue(parentBc.navigate(BindingContext.localContext("Number")), account.Number);
            this.props.storage.refresh();
        }
    };

    save = async (): Promise<IFormStorageSaveResult> => {
        const { storage } = this.props;
        if (await storage.validate(true)) {
            this.forceUpdate();
            return null;
        }

        const { bindingContext, entity } = storage.data;

        storage.setBusy();
        this.forceUpdate();

        try {
            const accountId = storage.getValueByPath(DebitAccountIdPath);
            const date = storage.getValueByPath(DateOfTransactionPath);
            const documentItemIds = storage.getCustomData().documentItemIds;
            await addToCosts(documentItemIds, accountId, date);
        } catch (error) {
            storage.setFormAlert(getAlertFromError(error));
            storage.setBusy(false);
            this.forceUpdate();
            return null;
        }

        return {
            bindingContext,
            data: entity
        };
    };
}

export default withPermissionContext(AddToCostsDialogFormView);