import BusyIndicator from "@components/busyIndicator";
import { IFileViewerProps } from "@components/fileViewers/FileViewers.utils";
import TextViewer from "@components/fileViewers/textViewer";
import React from "react";
import type { XMLFormatterOptions } from "xml-formatter";

interface IProps extends IFileViewerProps {

}

interface IState {
    isLoaded: boolean;
}

class XmlViewer extends React.PureComponent<IProps> {
    state: IState = {
        isLoaded: false
    };
    formatXml: (xml: string, options?: XMLFormatterOptions) => string;

    componentDidMount() {
        this.init();
    }

    init = async (): Promise<void> => {
        // lazily load library only on demand
        const { default: formatXml } = await import("xml-formatter");
        this.formatXml = formatXml;
        this.setState({ isLoaded: true });
    };

    formatter = (xml: string): string => {
        return this.formatXml(xml, {
            indentation: "    ",
            collapseContent: true
        });
    };

    render() {
        if (!this.state.isLoaded) {
            return <BusyIndicator isDelayed/>;
        }

        return (
            <TextViewer {...this.props}
                        formatter={this.formatter}/>
        );
    }
}

export default XmlViewer;
