import styled, { css } from "styled-components/macro";
import SmartField from "../smartField/index";
import Dialog from "../../dialog";
import { Body } from "../../dialog/Dialog.styles";
import { IconButton } from "../../button";
import { FIELD_HOR_MARGIN } from "../../inputs/field/Field.styles";


export const StyledDialog = styled(Dialog)`
    ${Body} {
        padding-right: 44px;
    }
`;

/**
 * SmartField styles with conditional dialog icon
 */
export const SmartFieldStyled = styled(SmartField)<{
    groupHasConditionalDialog: boolean;
    itemHasConditionalDialog: boolean;
}>`
    ${props => props.groupHasConditionalDialog && css`
        margin-right: ${props.itemHasConditionalDialog ? 0 : 60}px;
    `};
`;


export const IconButtonStyled = styled(IconButton)`
    margin-left: 2px;
    margin-right: ${FIELD_HOR_MARGIN};
    // prevent the button from making the whole field bigger
    height: 34px;
    align-self: flex-end;
`;
