// import dayjs and locales for date time formatting in i18n.ts
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import localeData from "dayjs/plugin/localeData";
import relativeTime from "dayjs/plugin/relativeTime";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isBetween from "dayjs/plugin/isBetween";
import minMax from "dayjs/plugin/minMax";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import customParseFormat from "dayjs/plugin/customParseFormat";
import weekOfYear from "dayjs/plugin/weekOfYear";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

// all used locales has to be imported
// ContextProvider -> changeDayJsLocale imports locale dynamically based on current locale
// import "dayjs/locale/en";
// import "dayjs/locale/cs";
// import "dayjs/locale/sk";

dayjs.extend(utc);
dayjs.extend(timezone);

// by default
dayjs.tz.setDefault("GMT");

// Load dayjs plugin for date manipulation (startOf/endOf quarter)
// https://day.js.org/docs/en/plugin/quarter-of-year
dayjs.extend(quarterOfYear);
// Load dayjs plugin for locale formatting
// https://day.js.org/docs/en/plugin/localized-format#__docusaurus
dayjs.extend(localizedFormat);
// https://day.js.org/docs/en/plugin/locale-data#__docusaurus
dayjs.extend(localeData);
// https://day.js.org/docs/en/plugin/relative-time#__docusaurus
dayjs.extend(relativeTime);

dayjs.extend(minMax);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);
dayjs.extend(weekOfYear);
// https://day.js.org/docs/en/parse/string-format
dayjs.extend(customParseFormat);

export default dayjs;