import { isNumericType } from "@evala/odata-metadata/src";
import { IFieldInfo } from "@odata/FieldInfo.utils";
import { ExpandQueryBuilder, OData } from "@odata/OData";
import { formatValue, prepareQuery } from "@odata/OData.utils";
import { CacheCleaner, memoizedWithCacheStrategy } from "@utils/CacheCleaner";
import { isDefined } from "@utils/general";
import { logger } from "@utils/log";

import { AUTOCOMPLETE_SUG_ROWS } from "../../../constants";
import { IAppContext } from "../../../contexts/appContext/AppContext.types";
import { CacheStrategy, ODataFilterFunction } from "../../../enums";
import { RequireKeys } from "../../../global.types";
import { Model } from "../../../model/Model";
import { StorageModel } from "../../../model/StorageModel";
import BindingContext, { createBindingContext, IEntity } from "../../../odata/BindingContext";
import customFetch from "../../../utils/customFetch";
import { FormStorage } from "../../../views/formView/FormStorage";
import { buildTreeFromAllItems } from "../../inputs/select/SelectAPI";
import { getInfoValue, IFieldDef, IGetValueArgs } from "../FieldInfo";
import { getFormattedValues, prepareColumns } from "../smartTable/SmartTable.utils";
import { ISelectItem } from "@components/inputs/select/Select.types";


interface IGetDataArgs {
    value?: string;
    oData: OData;
    storage: Model;
    isHierarchical?: boolean;
    bindingContext: BindingContext;
    fieldBindingContext: BindingContext;
    columns: IFieldDef[];
    convertedColumns: IFieldInfo[];
    queryColumns?: IFieldDef[];
    orderBy?: string;
    asc?: boolean;
    queryParams: Record<string, string>;
    additionalProperties?: string[];
    filter?: string;
    filterFunction?: ODataFilterFunction;
    displayName?: string;
    idName?: string;
    entitySet?: string;
    info?: IFieldInfo;
    appContext: IAppContext;
    formatter?: any;
    language?: string;
}

interface IGetDataArgsMemoized extends RequireKeys<IGetDataArgs, "language"> {
}

interface IGetColumnsArgs {
    columns: IFieldDef[];
    displayName: string;
    appContext: IAppContext;
    bindingContext: BindingContext;
}

export const HIERARCHICAL_CHILDREN_PROP_NAME = "Children";
export const HIERARCHICAL_PARENT_PROP_NAME = "Parent";
export const getColumns = async ({ columns, displayName, appContext, bindingContext }: IGetColumnsArgs) => {
    let mergedColumns = columns;
    const displayNameColumn = { id: displayName };

    if (displayName) {
        if (mergedColumns && !mergedColumns.find(col => col.id === displayName)) {
            mergedColumns.push(displayNameColumn);
        } else if (!mergedColumns) {
            mergedColumns = [displayNameColumn];
        }
    } else {
        logger.warn(`getColumns: ${bindingContext.toString()} possibly missing displayName.`);
    }

    return await prepareColumns({
        bindingContext: bindingContext,
        context: appContext,
        columns: mergedColumns
    });
};

const getAdditionalArgs = (args: IGetDataArgs) => {
    const keyPropertyName = args.idName ?? args.fieldBindingContext?.getKeyProperty().getName() ?? args.bindingContext.getKeyPropertyName();
    const displayName = args.displayName ?? keyPropertyName;

    return { keyPropertyName, displayName };
};

interface IEndpointResult {
    Key: string;
    Value: any;
}

const fetchItemsFromEndpoint = async (args: IGetDataArgsMemoized): Promise<ISelectItem[]> => {
    const endpoint = getInfoValue(args.info.fieldSettings, "endpoint", {
        info: args.info,
        context: args.appContext
    });

    const res = await customFetch(endpoint);
    const result: IEndpointResult[] = await res.json();
    const items = result.map(async ({ Key: key, Value: value }) => {
        const formattedLabel = args.info.formatter ? await args.info.formatter(key) : key;

        return {
            id: key,
            label: formattedLabel as string,
            additionalData: value
        };
    });

    return Promise.all(items);
};

const fetchData = async (args: IGetDataArgsMemoized): Promise<IEntity[]> => {
    const queryBc = args.bindingContext;
    const { keyPropertyName } = getAdditionalArgs(args);
    const asc = args.asc ?? true;

    let query = prepareQuery({
        oData: args.oData,
        bindingContext: queryBc,
        fieldDefs: args.queryColumns ?? args.columns,
        settings: args.info.fieldSettings?.querySettings
    });

    if (args.isHierarchical) {
        query.expand(HIERARCHICAL_CHILDREN_PROP_NAME, (q: ExpandQueryBuilder) => {
            q.select(keyPropertyName).orderBy(args.orderBy, asc);
        }).expand(HIERARCHICAL_PARENT_PROP_NAME, (q: ExpandQueryBuilder) => {
            q.select(keyPropertyName).orderBy(args.orderBy, asc);
        });
    }

    if (args.queryParams) {
        query.queryParameters(args.queryParams);
    }

    const filters = [];
    if (args.value) {
        const isDisplayNumeric = isNumericType(queryBc.navigate(args.displayName).getProperty());
        const functionQuery = isDisplayNumeric ? `cast(${args.displayName}, 'Edm.String')` : args.displayName;
        const func = args.filterFunction ?? ODataFilterFunction.Startswith;

        query = query.top(AUTOCOMPLETE_SUG_ROWS);
        filters.push(`${func}(${functionQuery},'${args.value}')`);
    }

    args.filter && filters.push(args.filter);

    if (filters.length > 0) {
        query = query.filter(filters.join(" AND "));
    }

    if (args.orderBy) {
        query.orderBy(args.orderBy, asc);
    }

    try {
        const result = (await query.fetchData<IEntity[]>()) ?? { value: [] };
        return result.value;
    } catch (error) {
        // todo global error handling
        logger.error("error in fetchSuggestions", error);
        return [];
    }
};

export const transformItems = (args: IGetDataArgs, items: IEntity[]): ISelectItem[] => {
    const { keyPropertyName, displayName } = getAdditionalArgs(args);

    const selectItems = items.map((item: IEntity) => {
        const formattedValues = getFormattedValues({
            columns: args.convertedColumns,
            values: item,
            valuesBindingContext: args.bindingContext
        });

        // always add entity key property into the values
        if (!(keyPropertyName in formattedValues)) {
            formattedValues[keyPropertyName] = item[keyPropertyName];
        }

        const keyPropertyValue = item[keyPropertyName];
        let propertyValue = formattedValues[displayName];
        if (args.info?.formatter && args.columns.length > 1) {
            propertyValue = formatValue(item, args.info, {
                entity: item,
                item: item,
                storage: args.storage
            });
        }
        const tabularData = (args.columns || []).map(column => formattedValues[column.id]);
        const additionalData = item;

        return {
            groupId: "db",
            id: keyPropertyValue,
            label: propertyValue,
            tabularData,
            additionalData
        };
    });
    return args.info?.fieldSettings?.transformFetchedItems ? args.info.fieldSettings.transformFetchedItems(selectItems, args) : selectItems;
};

/***
 * Force smart selects to reload their items
 * by removing 'items' from field info and clearing cache for given cache strategy.
 * Rerender SmartSelect after this (e.g. by calling model.refreshFields) to fetch new items
 */
export const invalidateItems = (bindingContexts: BindingContext[], storage: StorageModel, cacheStrategy = CacheStrategy.View): void => {
    for (const bc of bindingContexts) {
        const info = storage.getInfo(bc);
        if (info) {
            if (info.fieldSettings?.items) {
                info.fieldSettings.items = null;
            }

            storage.addActiveField(bc);
        }
    }

    CacheCleaner.clear(cacheStrategy);
};

export function fetchItemsByPath(storage: FormStorage, path: string): Promise<ISelectItem[]> {
    const bc = storage.data.bindingContext.navigate(path);
    const info = storage.getInfo(bc);
    return info && fetchItemsByInfo(storage, info);
}

export async function fetchAndSetItemsByInfo(storage: FormStorage, info: IFieldInfo, isHierarchical = false): Promise<ISelectItem[]> {
    const items = await fetchItemsByInfo(storage, info, isHierarchical);
    info.fieldSettings.items = items;

    storage.addActiveField(info.bindingContext);

    return items;
}

export async function fetchItemsByInfo(storage: FormStorage, info: IFieldInfo, isHierarchical = false): Promise<ISelectItem[]> {
    let items: ISelectItem[];
    if (info.fieldSettings.itemsFactory) {
        items = (await info.fieldSettings.itemsFactory({
            storage,
            context: storage?.context,
            bindingContext: info.bindingContext
        })) ?? [];
    } else {
        items = await fetchSelectItems({
            fieldInfo: info,
            oData: storage.oData,
            appContext: storage.context,
            storage: storage,
            isHierarchical
        });
    }
    return items;
}

export async function getRenderedItemsByInfo(storage: FormStorage, info: IFieldInfo, fieldBc?: BindingContext, includeAdditionalItems = true): Promise<ISelectItem[]> {
    let items = info.fieldSettings?.items;
    if (!items) {
        items = await fetchItemsByInfo(storage, info);
    }
    if (info.fieldSettings?.itemsForRender) {
        items = info.fieldSettings?.itemsForRender(items ?? [], getItemsForRenderCallbackArgs(storage, info, fieldBc ?? info.bindingContext));
    }
    return [
        ...(includeAdditionalItems ? info?.fieldSettings?.additionalItems ?? [] : []),
        ...(items ?? info?.fieldSettings?.initialItems ?? [])
    ];
}

interface IFetchSelectItems {
    fieldInfo: IFieldInfo;
    oData: OData;
    appContext: IAppContext;
    storage?: Model;
    isHierarchical?: boolean;
}

export const fetchSelectItems = async (args: IFetchSelectItems): Promise<ISelectItem[]> => {
    const infoArgs = {
        info: args.fieldInfo,
        bindingContext: args.fieldInfo.bindingContext,
        data: args.storage?.data?.entity,
        context: args.appContext,
        storage: args.storage
    };

    const entitySet = getInfoValue(args.fieldInfo.fieldSettings, "entitySet", infoArgs);
    const filter = getInfoValue(args.fieldInfo.filter, "select", infoArgs);

    if (isDefined(args.fieldInfo.fieldSettings.entitySet) && !entitySet) {
        args.fieldInfo.fieldSettings.items = [];
        return [];
    }

    const bc = entitySet ? createBindingContext(entitySet, args.oData.getMetadata()) : args.fieldInfo.bindingContext;
    const fieldBc = args.fieldInfo.bindingContext.isLocal() ? null : bc.navigate(bc.getKeyPropertyName());
    const displayName = args.fieldInfo.fieldSettings?.entitySetDisplayName ?? args.fieldInfo.fieldSettings?.displayName;
    const mergedColumns = args.fieldInfo.columns ?? ([{ id: displayName || bc.getKeyPropertyName() }]);

    const queryColumns = args.fieldInfo?.fieldSettings?.additionalProperties ?
        [...mergedColumns, ...args.fieldInfo.fieldSettings.additionalProperties] : mergedColumns;

    if (args.fieldInfo?.fieldSettings?.onBeforeRequest) {
        await args.fieldInfo.fieldSettings.onBeforeRequest(infoArgs);
    }

    const columns = await getColumns({
        bindingContext: bc,
        columns: args.fieldInfo.columns,
        displayName: displayName,
        appContext: args.appContext
    });

    let items = await getItems({
        info: args.fieldInfo,
        displayName: displayName,
        storage: args.storage,
        idName: args.fieldInfo?.fieldSettings?.idName,
        oData: args.oData,
        appContext: args.appContext,
        isHierarchical: args.isHierarchical,
        bindingContext: bc,
        formatter: args.fieldInfo?.formatter,
        filter: filter,
        fieldBindingContext: fieldBc,
        columns: mergedColumns,
        convertedColumns: columns,
        queryColumns: queryColumns,
        orderBy: args.fieldInfo?.fieldSettings?.orderBy?.propertyName?.toString() || displayName || (args.fieldInfo.columns?.[0].id),
        asc: args.fieldInfo?.fieldSettings?.orderBy?.asc,
        queryParams: getInfoValue(args.fieldInfo?.fieldSettings, "queryParams", infoArgs),
        language: args.appContext.getData().userSettings.LanguageCode
    }, args.fieldInfo.cacheStrategy);

    if (args.isHierarchical) {
        items = buildTreeFromAllItems(items, args.fieldInfo?.fieldSettings?.idName ?? bc.getKeyPropertyName());
    }

    if (!args.fieldInfo.fieldSettings) {
        args.fieldInfo.fieldSettings = {};
    }

    args.fieldInfo.fieldSettings.items = items;
    args.fieldInfo.columns = columns as IFieldDef[];

    return items;
};

export const getItems = async (args: IGetDataArgs, cs: CacheStrategy = CacheStrategy.None): Promise<ISelectItem[]> => {
    if (cs !== CacheStrategy.None && !args.language) {
        logger.error("language property is mandatory when using cache");
    }

    const fetchArgs = {
        ...args,
        language: args.language
    };

    if (args.info?.fieldSettings?.endpoint) {
        const items = await fetchFromEndpointMemoized(fetchArgs, cs);

        return args.info.fieldSettings.transformFetchedItems ? args.info.fieldSettings.transformFetchedItems(items, args) : items;
    } else {
        const rawItems = await fetchMemoized(fetchArgs, cs);

        return transformItems(args, rawItems);
    }
};

const transformColumnsIntoString = (columns: IFieldDef[]): string => {
    if (!columns) {
        return "";
    }

    // don't return everything, there are things like bindingContext that would mess with the result
    return columns.map(col => {
        return JSON.stringify({
            id: col.id,
            additionalProperties: col.additionalProperties
        });
    }).join(",");
};

// caches the retrieved items, supposed to be used by SmartSelect which only downloads the values once
// don't use from components that uses value to filter the items (e.g. autocomplete)
export const fetchMemoized = memoizedWithCacheStrategy<IGetDataArgsMemoized, IEntity[]>(fetchData,
    (args: IGetDataArgsMemoized) => {
        // Base endpoint URL
        const endpointURL = args.oData.fromPath(args.bindingContext.toString()).getUrl();

        // add other properties to resolver, if the requests needs to be differentiated more
        // e.g. multiple SmartSelects on same property with different columns
        const props = `${args.language},${args.filter},${args.orderBy},${transformColumnsIntoString(args.columns)}`;

        return `${endpointURL},${props}`;
    });

export const fetchFromEndpointMemoized = memoizedWithCacheStrategy<IGetDataArgsMemoized, ISelectItem[]>(fetchItemsFromEndpoint,
    (args: IGetDataArgsMemoized) => {
        return `${args.info.fieldSettings.endpoint},${args.info.formatter},${args.language}`;
    });

export function getItemsForRenderCallbackArgs(storage: Model, info: IFieldInfo, fieldBc?: BindingContext, context?: IAppContext): IGetValueArgs {
    return {
        data: storage?.data.entity,
        context: context ?? storage?.context,
        bindingContext: fieldBc ?? info.bindingContext,
        info, storage
    };
}