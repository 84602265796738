import styled from "styled-components/macro";
import DashboardTileBackground from "../../dashboard/DashboardTileBackground";
import { T_HEADER_bold, T_PLAIN_small, T_TITLE_2_2 } from "../../../global.style";
import { ellipsis } from "polished";
import { getDashboardBoxShadow } from "../../dashboard/Dashboard.styles";

const BusinessCardLeftPadding = 32;
const AvatarSize = 64;

export const StyledBusinessCardTile = styled(DashboardTileBackground)`
    position: relative;
    margin-left: ${BusinessCardLeftPadding}px;
    padding-left: 44px;
`;

export const BusinessCardAvatar = styled.div`
    ${T_TITLE_2_2};
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%);
    width: ${AvatarSize}px;
    height: ${AvatarSize}px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    z-index: 1;
    color: ${props => props.theme.C_SEM_text_good};
    background-color: ${props => props.theme.C_LAB_green};
    ${props => getDashboardBoxShadow(props)};
`;

export const Heading = styled.h3`
    ${T_HEADER_bold};
    ${ellipsis()};
    margin: 8px 0 24px;
`;

export const Contact = styled.a`
    ${T_PLAIN_small};
    ${ellipsis()};
    align-self: flex-start;
    color: ${props => props.theme.C_TEXT_primary};
    margin-bottom: 3px;
`;
