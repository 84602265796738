import React from "react";
import { IInputProps } from "../input";
import { TextAlign } from "../../../enums";
import { INumericValueInput, NumericValueInputBase } from "../numericValueInputBase/NumericValueInputBase";
import { Stepper } from "../numericValueInputBase/Stepper";
import { InputWithErrorAndTooltipStyled, StepperPlaceholder } from "./NumericInput.styles";
import { withFormatter } from "../input/WithFormatter";
import NumberType, { IParserArgs } from "../../../types/Number";
import { WithErrorAndTooltipProps } from "../formError/WithErrorAndTooltip";

export const NumericInput = withFormatter<IInputProps & { showSteppers?: boolean } & WithErrorAndTooltipProps, number>({
    parser: (value: string, strict?: boolean, args?: IParserArgs) => NumberType.parse(value, strict, { maximumFractionDigits: 3, ...(args ?? {}) }),
    formatter: (val) => NumberType.format(val, { maximumFractionDigits: 3 }),
    isValid: NumberType.isValid
}, InputWithErrorAndTooltipStyled);

export interface IProps extends INumericValueInput, Omit<IInputProps, "value" | "onChange"> {
    maximumFractionDigits?: number;
}

export default class NumericInputWithStepper extends NumericValueInputBase<IProps> {
    render() {
        const numberValue = this.getNumberValue();
        let content;

        const { step, precision, min, max, showSteppers, ...passProps } = this.props;

        if (!this.props.isReadOnly && showSteppers) {
            content = (
                <>
                    <StepperPlaceholder/>
                    <Stepper isDisabled={this.props.isDisabled}
                             onNextMouseDown={this.handleNextMouseDown}
                             onPrevMouseDown={this.handlePrevMouseDown}
                             isNextDisabled={!this.canIncrement(numberValue)}
                             isPrevDisabled={!this.canDecrement(numberValue)}/>
                </>
            );
        }

        return <NumericInput {...passProps}
                             showSteppers={showSteppers}
                             passRef={this.inputRef}
                             outerRef={this.inputWrapperRef}
                             textAlign={TextAlign.Right}
                             onKeyDown={this.handleKeyDown}
                             content={content}
                             selectOnFocus={this.props.value === 0}
        />;
    }
}