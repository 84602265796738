import styled, { css } from "styled-components/macro";
import {
    getDisabledStyle,
    getFocusBorderDefinition,
    getFocusBorderElement,
    T_ALERT_SUBTITLE,
    T_BTN_main,
    T_PLAIN_big,
    T_TITLE_4_NORMAL
} from "../../global.style";
import { ellipsis, transparentize } from "polished";
import { PropsWithTheme } from "../../theme";
import { ButtonGroup } from "../button";
import { ReadLabelText, StyledEditableText } from "../inputs/editableText/EditableText.styles";
import { StyledInput } from "../inputs/textArea/TextArea.styles";
import Alert from "../alert/Alert";
import { DarkIcon } from "../icon";

export const ITEM_MARGIN = 16;

export const StyledConfigurationList = styled.div`
    height: 100%;
    width: fit-content;
`;

export const Columns = styled.div`
    display: flex;
    height: 100%;
`;

interface IItem {
    _width?: string;
    isDragging?: boolean;
    preventTransform?: boolean;
    isHighlighted?: boolean;
    isDisabled?: boolean;
    level?: number;
    isDescription?: boolean;
    isCombined?: boolean;
    hasRightIcon?: boolean;
}

export const StyledConfigurationItem = styled.div<IItem>`
    position: relative;
    outline: none;
    border-radius: ${props => props.theme.borderLargeRadius};
    padding: 6px 0;
    width: fit-content;
    margin-left: ${props => props.level * 32}px;

    ${props => props.preventTransform && !props.isDragging && css`
        transform: none !important;
    `}
`;

export const SubItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 14px;
    ${T_PLAIN_big};
    margin: 12px 0 12px 32px;
    padding-right: 14px;
`;

export const ItemSelectOpener = styled.div`
    position: absolute;
    width: calc(50% - 14px);
    top: 100%;
    left: 50%;
`;

const getItemBackgroundColor = (props: IItem & PropsWithTheme) => {
    let color = props.isHighlighted ? transparentize(0.9, props.theme.C_ACT_der) : props.theme.C_FIELD_line_der;

    if (props.isDisabled) {
        color = transparentize(1 - parseFloat(props.theme.disabled_opacity), color);
    }

    return color;
};

export const DraggedItemStyle = css`
    border: 1px solid ${props => transparentize(0.5, props.theme.C_ACT_der)};
    opacity: 80%;
    box-shadow: 0 0 9.2px 2.8px rgba(0, 0, 0, 0.2);
`;

const itemBorder = css<IItem>`
    border: 1px solid ${props => props.isHighlighted || props.isDisabled ? "transparent" : transparentize(0.5, props.theme.C_ACT_der)}
`;

const combinedItemLine = css`
    content: "";
    position: absolute;
    height: 2px;
    width: calc(100% - 4px);
    left: 2px;
    background-color: ${props => props.theme.C_ACT_der};
    opacity: 0.5;
`;

const topLine = css`
    &::before {
        ${combinedItemLine};
        top: -2px;
    }
`;

const bottomLine = css`
    &::after {
        ${combinedItemLine};
        bottom: -2px;
    }
`;

// TODO this is be a bit redundant to Input
// with some problems, disabled Input could be used instead
export const ItemInnerWrapper = styled.div<IItem>`
    height: 34px;
    width: ${props => props._width};
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 12px;
    padding-right: ${props => props.hasRightIcon ? "40px" : "20px"};
    border-radius: ${props => props.theme.borderLargeRadius};
    background-color: ${props => getItemBackgroundColor(props)};
    border: 1px solid transparent; //${props => props.isHighlighted ? transparentize(0.9, props.theme.C_ACT_der) : props.theme.C_FIELD_line_der};

    &:hover {
        ${itemBorder};
    }

    ${props => props.isDragging && DraggedItemStyle}
    ${StyledConfigurationItem}:focus & {
        &:before {
            ${getFocusBorderDefinition({ offset: 2 })}
        }
    }

    ${props => props.isCombined && css`
        & + & {
            margin-top: 12px;
        }

        &:not(:first-child):not(:last-child) {
            border-radius: 0;
        }

        &:first-child {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }

        &:last-child {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }

        ${StyledConfigurationItem}:hover & {
            ${itemBorder};

            &:not(:first-child):not(:last-child) {
                ${topLine};
                ${bottomLine};
            }

            &:first-child {
                ${bottomLine};
            }

            &:last-child {
                ${topLine};
            }
        }
    `}
`;

export const ItemInnerContentWrapper = styled.div`
    position: absolute;
    right: 12px;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
`;

export const ItemValueWrapper = styled.div<IItem>`
    ${T_TITLE_4_NORMAL};
    ${ellipsis()};
    width: 100%;
    opacity: ${props => props.isDisabled ? props.theme.disabled_opacity : 1};
    // match description text color for correct text ellipsis color
    color: ${props => props.isDescription ? props.theme.C_ACT_thick_line : props.theme.C_TEXT_primary};
`;

export const ItemValue = styled.span`
    color: ${props => props.theme.C_TEXT_primary};
`;

export const ItemDescription = styled.span`
    font-style: italic;
    color: ${props => props.theme.C_ACT_thick_line};
`;

export const RequiredAlert = styled(Alert)`
    position: absolute;
    left: 50%;
    top: 50%;
`;

interface IColumn {
    isInEdit?: boolean;
    isFirstGroupSmall?: boolean;
}

export const StyledConfigurationColumn = styled.div<IColumn>`
    --separator-width: 2px;
    --column-margin: 16px;
    --sum: calc(var(--separator-width) + var(--column-margin));

    display: flex;
    flex-direction: column;
    position: relative;
    margin: 0 var(--column-margin);

    &:not(:last-child) {
        margin-right: var(--sum);

        &:after {
            content: '';
            position: absolute;
            top: 0;
            right: calc(var(--sum) * -1);
            display: block;
            width: var(--separator-width);
            height: ${props => props.isInEdit ? "100%" : "calc(100% - 12px)"};
            background: ${props => props.theme.C_DEF_tableBorder};
        }
    }
`;

export const AddButtonWrapper = styled.div`
`;

export const ColumnLabelWrapper = styled.div<IColumn>`
    margin-bottom: ${props => props.isFirstGroupSmall ? "3px" : "9px"};
    margin-left: 16px;
`;

export const ColumnContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100%;
`;

export const Spacer = styled.div`
    display: block;
    height: 5px;
`;

export const GroupList = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    width: fit-content;
    flex-grow: 1;
`;

interface IGroup {
    isTransparent?: boolean;
    isDraggable?: boolean;
    isDragging?: boolean;
    isRemovable?: boolean;
    isGrowing?: boolean;
    isLocked?: boolean;
    _width?: string;
}

export const StyledConfigurationGroup = styled.div<IGroup>`
    display: flex;
    flex-direction: column;
    flex-grow: ${props => props.isGrowing ? 1 : 0};
    ${props => !props.isGrowing && !props.isLocked && css`min-height: 100px`};
    position: relative;
    background-color: ${props => props.isTransparent ? "transparent" : props.theme.C_BG_menu};
    margin-bottom: 28px;
    border: 2px solid transparent;
    border-radius: 3px;

    ${props => props.isDraggable && !props.isTransparent && css`
        &:hover {
            border: 2px solid ${props => props.theme.C_ACT_hover_without_opacity};

            ${DragIconWrapper} {
                visibility: visible;
            }
        }
    `};

    ${props => props.isDragging && DraggedItemStyle};

    ${props => props.isRemovable && css`
        ${ControlButtonsGroup} > button:first-child {
            visibility: hidden;
        }

        &:hover ${ControlButtonsGroup} > button:first-child {
            visibility: visible;
        }
    `};
`;

export const StyledGroupHeader = styled.div<{ isLocked?: boolean; }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding: 10px 10px 0 14px;
    // in case group is locked, there is no body with spacing -> make margin greater to keep 
    // the header on center of the group
    margin-bottom: ${props => props.isLocked ? 6 : 2}px;

    ${StyledEditableText} {
        min-height: 26px;
    }
`;

export const DarkIconStyled = styled(DarkIcon)<{ hasTooltip?: boolean }>`
    ${props => props.hasTooltip && css`cursor: pointer`};
`;

export const StyledGroupSeparator = styled.div`
    display: flex;
    align-items: center;
`;

export const GroupSeparatorLine = styled.div`
    width: 100%;
    height: 2px;
    margin-right: 11px;
    margin-left: 12px;
    background-color: ${props => props.theme.C_ACT_hover_without_opacity};
`;

export const DragIconWrapper = styled.div`
    visibility: hidden;
    margin-left: 2px;
`;

export const ControlButtonsGroup = styled(ButtonGroup)`
    // on the right side of flex container
    margin-left: auto;
`;

export const GroupLabel = styled.div<{ isEditable: boolean }>`
    ${T_BTN_main};

    ${ReadLabelText}, ${StyledInput} {
        ${T_BTN_main};
    }

    ${ellipsis(205)};
    
    ${props => !props.isEditable && css`
        padding: 6px 12px 4px 12px;
    `};
`;

export const ItemList = styled.div<IGroup>`
    position: relative;
    flex-grow: 1;
    min-width: ${props => `calc(${props._width} + 2 * ${ITEM_MARGIN}px)`};
    min-height: ${props => props.isTransparent ? "0" : "56px"};
    padding: 0 ${ITEM_MARGIN}px;
    padding-bottom: ${props => props.isTransparent ? "0" : "6px"};
`;

export const ItemSelector = styled.div<{ isDisabled?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    left: 13px;
    outline: none;
    ${getFocusBorderElement({ offset: 2, keyboardOnly: true })};
    ${props => getDisabledStyle(props.isDisabled)};
`;

export const SelectedItemLabel = styled.div`
    color: ${props => props.theme.C_ACT_main};
    margin-right: 3px;

    ${T_ALERT_SUBTITLE};
`;

export const FakeGroup = styled.div`
    &:last-child {
        margin-bottom: 0;
    }
`;

export const PlaceholderVisibility = styled.div<{ visible: boolean }>`
    display: ${props => props.visible ? "block" : "none"};
`;

export const PlaceholderLineDefinition = css`
    border-radius: 1px;
    background-color: ${props => props.theme.C_ACT_main};
    opacity: 0.5;
`;

export const HorizontalLineWithoutBubbles = css`
    ${PlaceholderLineDefinition};

    position: relative;
    height: 2px;
    flex: 1 1 auto;
`;

export const GroupPlaceholder = styled.div`
    position: absolute;
    border: dashed 2px ${props => props.theme.C_ACT_main};
    background-color: ${props => props.theme.C_ACT_hover_without_opacity};
    opacity: 0.5;
    border-radius: 4px;
`;