import styled from "styled-components/macro";

import Clickable from "../../components/clickable";
import { T_PLAIN_big, T_TITLE_2 } from "../../global.style";
import { VIEW_PADDING_VALUE } from "../../views/View.styles";

export const StyledVatSubmissionDetail = styled.div`
    padding-bottom: ${VIEW_PADDING_VALUE}px;
    ${T_PLAIN_big};
`;

export const StyledHeading = styled.h2`
    ${T_TITLE_2};
    display: flex;
    align-items: center;
    margin-bottom: 16px;
`;

export const StyledParagraph = styled.p`
    ${T_PLAIN_big};
    margin-bottom: 17px;
`;

export const HeaderSubtitle = styled.div`
    ${T_TITLE_2};
`;

export const LinksGrid = styled.div`
    width: fit-content;
    margin-bottom: 24px;
    ${T_PLAIN_big};
    display: grid;
    grid-template-columns: auto auto auto;
    row-gap: 12px;
    column-gap: 25px;
`;

export const ClickableWrapper = styled.div`
    display: flex;
    gap: 30px;
    margin-bottom: 30px;
`;

export const DocumentCountWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
`;

export const TaxFileLabelTitle = styled.div `
    font-weight: bold;
`;

export const TaxFileValue = styled.div `
`;

export const ClickableStyled = styled(Clickable)`
    display: inline-flex;
    align-items: center;

    & > svg {
        margin-left: 5px;
    }
`;

export const TableWrapperStyled = styled.div`
    margin-bottom: 42px;
`;

export const CustomFormWrapper = styled.div`
    padding-bottom: 38px; // todo: how much padding??
`;