import React, { useCallback } from "react";
import { ISmartFieldRenderProps } from "@components/smart/smartField/SmartField";
import { FormStorage } from "../../../views/formView/FormStorage";
import { IPrEmploymentTemplateEntity, PrEmploymentTemplateEntity } from "@odata/GeneratedEntityTypes";
import SmartFastEntryList from "../../../components/smart/smartFastEntryList";
import { LegislativeExtraSalariesSmartFastEntryListStyled } from "./ExtraSalary.styles";
import CollapsibleSection from "../../../components/collapsibleSection/CollapsibleSection";
import { ExtraSalaryGrpId, isLegislativeExtraSalary } from "../employment/Employment.utils";

interface IProps extends ISmartFieldRenderProps {
}

const LegislativeExtraSalariesSmartFastEntryList: React.FC<IProps> = ({ parentProps, value }) => {
    const storage = parentProps.storage as FormStorage<IPrEmploymentTemplateEntity>;
    const { definition, bindingContext: dataBindingContext } = storage.data;
    const { bindingContext } = parentProps;

    const handleToggle = useCallback((isExpanded: boolean) => {
        storage.setValue(bindingContext, !isExpanded);
        storage.refreshFields(true);
    }, [storage, bindingContext]);

    const info = storage.getInfo(parentProps.bindingContext);
    const groupDef = definition.groups.find(grp => grp.id === ExtraSalaryGrpId);

    return (
            <LegislativeExtraSalariesSmartFastEntryListStyled>
                <CollapsibleSection title={info.label}
                                    onToggle={handleToggle}
                                    isExpanded={value}
                                    renderChildrenOutside={true}/>
                {value && (
                        <SmartFastEntryList
                                groupId={groupDef.id}
                                storage={storage as FormStorage}
                                bindingContext={dataBindingContext.navigate(PrEmploymentTemplateEntity.ExtraSalaries)}
                                useLabelWrapping
                                canAdd={false}
                                isItemRemovable={false}
                                isItemCloneable={false}
                                columns={groupDef.lineItems.columns}
                                filterItems={(items: IPrEmploymentTemplateEntity[]) => {
                                    return items.filter(item => isLegislativeExtraSalary({ item }));
                                }}
                                order={"Id"}
                        />
                )}
            </LegislativeExtraSalariesSmartFastEntryListStyled>
    );
};

export default LegislativeExtraSalariesSmartFastEntryList;