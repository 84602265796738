import styled, {
    createGlobalStyle,
    css,
    DefaultTheme,
    FlattenSimpleInterpolation,
    keyframes
} from "styled-components/macro";
import { Status, TextAlign } from "./enums";
import { ellipsis, mix, transparentize } from "polished";
import { Theme } from "./theme";

export const FONTS = ["Lato", "Helvetica", "sans-serif"];

export const FONT_FAMILY = css`
    font-family: ${FONTS.join(" , ")};
`;

export enum LocalStackOrder {
    BackgroundLayer = -9,           // resize observer, etc...
    BelowContent = -3,              // Shadows and backgrounds
    Base = 0,                       // Base content, just to reset StackContext
    FocusBorder = 2,
    AboveContent = 3,               // e.g. select menu, conditionally rendered content
    Tooltips = 6,                   // Alerts, Tooltips, depends on mouse position
    Overlay = 9,                    // Loaders, MouseEvent blockers
    AboveOverlay = 10               // e.g. close button over loading overlay
}

export enum LayoutStackOrder {
    MenuDashboard = 20,
    Menu = 21,
    ExtendedShellBackdrop = 470,
    DashboardConfiguration = 475,
    ExtendedShell = 480,
    Modal = 490,
    ShellBar = 500,
    Hotspots = 510,
    Tooltips = 550
}

export const GlobalStyle = createGlobalStyle<{ theme: DefaultTheme }>`

    :root {
        --main-brand-gradient-start: ${props => props.theme.C_MAIN_brand_gradient_start};
        --main-brand-gradient-end: ${props => props.theme.C_MAIN_brand_gradient_end};
        --icon-menu-gradient-start: ${props => props.theme.C_ICON_MENU_grad_start};
        --icon-menu-gradient-end: ${props => props.theme.C_ICON_MENU_grad_end};
    }

    html, body, #root {
        height: 100%;
    }

    html {
        box-sizing: border-box;
    }

    .menu-slide {
        height: 100%;
    }

    .menu-slide-enter {
        left: 100%;
    }

    .menu-slide-enter.dashboard-menu > div {
        opacity: 0;
    }

    .menu-slide-enter-active.dashboard-menu {
        left: 0;
        transition: left 500ms;
    }

    .menu-slide-enter-done.dashboard-menu > div {
        transition: opacity 300ms ease-out;
        opacity: 1;
    }

    div[transitionname='menu-slide'] {
        height: 100%;
    }

    .menu-slide-exit.dashboard-menu {
        left: 0;
    }

    .menu-slide-exit.dashboard-menu .dashboard-background {
        opacity: 0;
        transition: opacity 200ms ease-out;
    }

    .menu-slide-exit-active.dashboard-menu {
        left: 100%;
        transition: left 500ms;
    }

    *, *:before, *:after {
        box-sizing: inherit;
        //outline: none; // i wouldn't do this globaly, but per component (can remove usefull outline)
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
    }

    body {
        overflow: hidden;
    }

    input,
    textarea {
        font-family: Lato;
        font-size: 14px;
        letter-spacing: 0.14px;
    }

    body,
    pre {
        ${FONT_FAMILY};
        margin: 0;
        font-weight: normal;
        background-color: ${(props) => props.theme.C_BG_floorplan};
        color: ${props => props.theme.C_TEXT_primary};
    }

    input, textarea {
        color: ${props => props.theme.C_TEXT_primary};
    }

    a {
        text-decoration: none;
        color: inherit;
    }

    h1, h2, h3, h4, h5, h6 {
        margin: 0;
        padding: 0;
    }

    ::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background-color: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background-color: #555;;
        border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {

    }

    #root {
        display: flex;
        overflow: hidden;
        background-color: ${props => props.theme.C_BG_menu};
    }

    .react-pdf__Page__textContent {
        transform: none !important;
        top: -1px !important;
        left: 0 !important;
        overflow: hidden
    }

    .react-pdf__Page__textContent span::selection {
        color: #fff;
        background-color: #3297FD;
    }

    .tab-dragable-over {
        cursor: pointer;
        background-color: red;
    }

    .react-resizable-handle {
        z-index: 80;
        display: none;
    }

    .showMenuDashboard {
        animation: left-to-right 0.5s;
    }

    .hideMenuDashboard {
        animation: right-to-left 0.5s;
    }

    @keyframes left-to-right {
        from {
            left: 100%
        }
        to {
            left: 0
        }
    }

    @keyframes right-to-left {
        from {
            left: 0
        }
        to {
            left: 100%
        }
    }

    // fixes cursor in scrollbar
    textarea {
        cursor: auto;
    }

    textarea::-webkit-scrollbar-track {
        background: transparent;
    }

    textarea::-webkit-scrollbar-thumb {
        background: ${props => transparentize(0.7, props.theme.C_BTN_hover_light)};
        border-radius: 7px;
    }

    input::selection, textarea::selection {
        background-color: ${props => props.theme.C_TEXT_selection};
    }

    .timeline .date.inline {
        background-color: #690048;
    }

    .timeline .marker {
        border-color: #690048;
    }

    .timeline::after {
        background-color: #690048 !important;
    }

    /**
     * Global styles for the icons in the navigation list -> will be added in global scope, so
     * it will affect all the icons anywhere in the app
     */
    body svg { // increase priority, so the style take effect
        // menu icons colors

        .MI_4L_gradient, .MI_4L_stroke, .MI_4L_main,
        .a, .b, .c, .d, .e, .f, .g, .h, .i, .j, .k {
            fill: none;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-width: 2.5px;
        }

        .MI_4L_main {
            stroke: ${props => props.theme.C_ICON_MENU_main};
        }

        .MI_4L_main_fill {
            fill: ${props => props.theme.C_ICON_MENU_main};
        }

        .MI_4L_stroke {
            stroke: ${props => props.theme.C_ICON_MENU_stroke};
        }

        .MI_4L_dot-fill {
            fill: ${props => props.theme.C_ICON_MENU_fill};
        }
    }
`;

export const T_TITLE_1 = css`
    font-weight: bold;
    font-size: 30px;
    letter-spacing: 0.14px;
`;

export const T_TITLE_2 = css`
    font-weight: bold;
    font-size: 24px;
    letter-spacing: 0.14px;
`;

export const T_TITLE_2_2 = css`
    font-weight: normal;
    font-size: 24px;
    letter-spacing: 0.24px;
`;

export const T_TITLE_3 = css`
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 0.14px;
`;

export const T_TITLE_3_normal = css`
    ${T_TITLE_3};
    font-weight: normal;
`;

export const T_TITLE_3_small = css`
    ${T_TITLE_3};
    font-size: 16px;
`;

export const T_TITLE_4 = css`
    font-size: 14px;
    font-weight: 900;
    letter-spacing: 0.14px;
`;

export const T_TITLE_4_NORMAL = css`
    ${T_TITLE_4};
    font-weight: normal;
`;

export const T_TITLE_5 = css`
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 900;
    letter-spacing: 0.14px;
`;

export const T_TITLE_6 = css`
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.14px;
`;

export const T_ALERT_SUBTITLE = css`
    font-size: 12px;
    letter-spacing: 0.16px;
    font-weight: 600;
`;

export const T_BTN_main = css`
    font-family: inherit;
    font-weight: bold;
    font-size: 12px;
    letter-spacing: 0.6px;
    text-transform: uppercase;
`;

export const T_HEADER_small = css`
    font-size: 18px;
    letter-spacing: 0.14px;
`;

export const T_HEADER_bold = css`
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.14px;
`;


export const T_PLAIN_small = css`
    font-size: 12px;
    letter-spacing: 0.14px;
`;

export const T_PLAIN_small_hig = css`
    ${T_PLAIN_small};
    font-weight: bold;
`;

export const T_PLAIN_big = css`
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.14px;
`;

export const T_PLAIN_med_hig = css`
    font-weight: bold;
    font-size: 13px;
    letter-spacing: 0.14px;
`;

export const T_PLAIN_big_hig = css`
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0.14px;
`;

export const T_PLAIN_EMP = css`
    font-weight: 300;
    font-size: 24px;
    letter-spacing: 0.24px;
`;

export const T_BOLD_tiny = css`
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 0.1px;
`;

export const T_PLAIN_tiny = css`
    font-size: 10px;
`;

export const SmallText = styled.span`
    ${T_PLAIN_tiny};
`;

export const BoldText = styled.span`
    ${T_PLAIN_small_hig};
`;

export const T_BREAD = css`
    font-weight: 300;
    font-size: 12px;
    letter-spacing: 0.14px;
`;

export const Title = styled.span.attrs(() => {
    return {
        role: "heading"
    };
})` // TODO i would move this to either standalone component or view.style file and kept Global.style.js just for shared css defininitions and util classes
    ${T_TITLE_1};
`;


export const textAlignToJustify = (textAlign: TextAlign) => {
    let justify;

    switch (textAlign) {
        case TextAlign.Left:
            justify = "flex-start";
            break;
        case TextAlign.Right:
            justify = "flex-end";
            break;
        case TextAlign.Center:
            justify = "center";
            break;
        default:
            justify = "flex-start";
            break;
    }

    return justify;
};

/**
 * Function returns multiline ellipsis (webkit only) styles
 * Note: polished from version 4.X supports this using ellipsis, but storybook has dependency for older version of
 * polished library, so it's not possible tu use it in storybook now.
 *  ToDo: once storybook supports polished 4.x, this may be replaced by native ellipsis call with rows
 * @param width
 * @param rows
 */
export function multiline_ellipsis(width: string | number, rows: number) {
    if (rows === 1) {
        return css`
            ${ellipsis(width)};
        `;
    }

    return css`
        ${ellipsis(width)}
        display: -webkit-box;
        -webkit-line-clamp: ${rows};
        -webkit-box-orient: vertical;
        // default nowrap from ellipsis() breaks the multi line ellipsis functionality
        white-space: pre-line;
    `;
}

// sometimes we need to get the inverse opacity of a given opacity value, e.g. to pass to transparentize polished fn
export function getInverseOpacity(opacity: string): number {
    return 1 - parseFloat(opacity);
}

export const getSemanticElColor = (theme: DefaultTheme, status: Status, isInverse = false, isHover = false) => {
    switch (status) {
        case Status.Success:
            return isHover ? theme.C_SEM_el_good_hover
                : isInverse ? theme.C_SEM_el_good_inv : theme.C_SEM_el_good;
        case Status.Warning:
            return isInverse ? theme.C_SEM_el_warning_inv : theme.C_SEM_el_warning;
        case Status.Error:
            return isHover ? theme.C_SEM_el_bad_hover
                : isInverse ? theme.C_SEM_el_bad_inv : theme.C_SEM_el_bad;
        default:
            return theme.C_SEM_el_neutral;
    }
};

export const getSemanticTextColor = (theme: DefaultTheme, status: Status, isHover = false) => {
    switch (status) {
        case Status.Success:
            return isHover ? theme.C_SEM_text_good_hover : theme.C_SEM_text_good;
        case Status.Warning:
            return theme.C_SEM_text_warning;
        case Status.Error:
            return isHover ? theme.C_SEM_text_bad_hover : theme.C_SEM_text_bad;
        default:
            return null;
    }
};

export const getSemanticTextColorName = (status: Status): keyof DefaultTheme => {
    switch (status) {
        case Status.Success:
            return "C_SEM_text_good";
        case Status.Warning:
            return "C_SEM_text_warning";
        case Status.Error:
            return "C_SEM_text_bad";
        default:
            return null;
    }
};

export const getSemanticBgColor = (theme: DefaultTheme, status: Status, forValidationMessage = false) => {
    const baseColor = getSemanticElColor(theme, status);
    const opacity = forValidationMessage ? theme.C_SEM_opacity_validation : theme.C_SEM_opacity_bg_color;
    return mix(opacity, baseColor, theme.C_BG_menu);
};

interface IGetCustomizedDashedBorderOpts {
    borderColor: keyof Theme | string;
    borderOpacity?: number | string;
    backgroundColor?: keyof Theme | string;
    backgroundOpacity?: number | string;
    dashArray?: number[];
    strokeWidth?: number;
    rx?: number; // use rx as one value for both x and y
    ry?: number;
    w?: number;
    h?: number;
    padding?: number;
}

export function getCustomizedDashedBorderBackground(theme: Theme, opts: IGetCustomizedDashedBorderOpts): FlattenSimpleInterpolation {
    const fillColor = opts?.backgroundColor ? (opts.backgroundColor in theme ? theme[opts.backgroundColor as keyof Theme] : opts.backgroundColor) : "none";
    const bgOpacity = opts?.backgroundOpacity ?? 1;
    const strokeColor = opts?.borderColor in theme ? theme[opts.borderColor as keyof Theme] : opts.borderColor;
    const borderOpacity = opts?.borderOpacity ?? 1;
    const strokeDash = (opts?.dashArray ?? [3, 7]).join(",");
    const strokeWidth = opts?.strokeWidth ?? 2;
    const padding = opts?.padding ?? 0;
    const w = opts?.w ?? "100%";
    const h = opts?.h ?? "100%";
    const elW = opts?.w ? (opts.w - 2 * padding) : "100%";
    const elH = opts?.h ? (opts.h - 2 * padding) : "100%";
    const rx = opts?.rx ?? 0;
    const ry = opts?.ry ?? opts?.rx ?? 0;
    let svg = `<svg width='${w}' height='${h}' xmlns='http://www.w3.org/2000/svg'>
        <rect width='${elW}' height='${elH}' x='${padding}' y='${padding}' fill='${fillColor}' rx='${rx}' ry='${ry}' stroke-width='0' opacity='${bgOpacity}' />
        <rect width='${elW}' height='${elH}' x='${padding}' y='${padding}' vector-effect='non-scaling-stroke' fill='none' rx='${rx}' ry='${ry}' stroke='${strokeColor}' opacity='${borderOpacity}' stroke-width='${strokeWidth}' stroke-dasharray='${strokeDash}' stroke-dashoffset='0' stroke-linecap='round'/>
    </svg>`;
    svg = encodeURIComponent(svg);
    return css`
        background-image: url("data:image/svg+xml;utf8,${svg}");
    `;
}

export interface IGetFocusBorder {
    /** Custom color of the focus border.*/
    color?: string;
    /** By default, focus is rendered in dark color, this will change the color to light. Only used if property `color` is not specified. */
    isLight?: boolean;
    /** Focus border will only applied when user use Tab key to get to thet element. Mouse click won't show focus status. */
    keyboardOnly?: boolean;
    /** Radius of the focus border, inherited from parent by default */
    borderRadius?: string;
    /** Offset of the border, similar to outline-offset */
    offset?: number;
    offsetX?: number;
    offsetY?: number;

    top?: string;
    left?: string;
    bottom?: string;
    right?: string;

    width?: string;
    height?: string;
}

export const getFocusBorderDefinition = ({
                                             color, isLight, borderRadius,
                                             offset, offsetY, offsetX, top,
                                             left, bottom, right, width, height
                                         }: IGetFocusBorder = {}) => {

    const _addPx = (val: string, offsetVal: number) => {
        const _offset = offsetVal || offset;
        return val ?? (_offset ? `${-_offset}px` : 0);
    };

    return css`
        content: "";
        position: absolute;
        top: ${_addPx(top, offsetY)};
        left: ${_addPx(left, offsetX)};
        bottom: ${height ? null : _addPx(bottom, offsetY)};
        right: ${width ? null : _addPx(right, offsetX)};
        width: ${width};
        height: ${height};
        border-radius: ${borderRadius ? borderRadius : "inherit"};
        border: ${props => `1px dotted ${color || (isLight ? props.theme.C_BTN_hover_dark : props.theme.C_BTN_hover_light)}`};
        pointer-events: none;
        z-index: ${LocalStackOrder.FocusBorder};
        transform: translateZ(3px);
    `;
};

export const getFocusBorderElement = (args: IGetFocusBorder = {}) => {
    return css`
        &:focus {
            outline: none;
        }

        &:focus:before {
            ${!args.keyboardOnly && getFocusBorderDefinition(args)}
        }

        &:focus-visible:before {
            ${args.keyboardOnly && getFocusBorderDefinition(args)}
        }
    `;
};

/** For cases where pseudo elements or 'border' doesn't work (e.g. in table, pseudo element breaks layout)
 * Doesn't allow for borderRadius which makes it less flexible than 'getFocusBorderDefinition' */
export const getFocusOutlineDefinition = (args: IGetFocusBorder) => {
    return css`
        outline: 1px dotted ${props => props.theme.C_BTN_hover_light};
        outline-offset: ${`${args.offset}px`};
    `;
};

export const fadeInKeyframe = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

export const fadeInAnimation = css`
    animation: ${fadeInKeyframe} 0.4s;
`;

export const fadeOutAnimation = (delay = 3) => css`
    animation: ${fadeInKeyframe};
    opacity: 1;
    animation-delay: ${delay}s;
    animation-duration: 0.3s;
    animation-direction: reverse;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

    &:hover {
        animation-play-state: paused;
    }
`;

export const getDisabledStyle = (isDisabled: boolean, opacity?: number) => {
    return css`
        opacity: ${props => isDisabled ? opacity ?? props.theme.disabled_opacity : 1};
        pointer-events: ${isDisabled ? "none" : "all"};
    `;
};

export interface ILinkStyleProps {
    $isLight?: boolean;
}

/** Try not to use this, if not necessary.
 * Instead, use Clickable component, that has all the logic needed.*/
export const LinkStyles = css<ILinkStyleProps>`
    position: relative;
    color: ${props => !props.$isLight ? props.theme.C_TEXT_link : props.theme.C_TEXT_link_light};

    &:hover {
        color: ${props => !props.$isLight ? props.theme.C_TEXT_link_hover : props.theme.C_TEXT_link_hover_light};
    }

    &:active {
        color: ${props => !props.$isLight ? props.theme.C_TEXT_link_selected : props.theme.C_TEXT_link_selected_light};
    }

    ${getFocusBorderElement({ offset: 0, keyboardOnly: true })};
`;

export const CenteredTextPage = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 38px;
    text-align: center;
    height: 100%;

    h1 {
        font-size: 55px;
        font-weight: 300;
        margin-bottom: 50px;
    }

    h3 {
        font-size: 25px;
        font-weight: normal;
        max-width: 700px;
        margin: 0 auto 35px;
    }
`;

export const NoDataStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1 1 100%;
    width: 100%;
    height: 100%;
`;

export const NoDataText = styled.div<{ $isSimplified?: boolean }>`
    max-width: 80%;
    flex: 0 0 auto;
    color: ${props => props.theme.C_ACT_thick_line};
    ${props => props.$isSimplified ? T_HEADER_bold : T_TITLE_2};
    text-align: center;
`;

export const ToolbarStaticText = styled.div`
    align-self: center;
    white-space: nowrap;
    padding-top: 2px;
`;

export const ColoredText = styled.span<{
    color: keyof DefaultTheme
}>`
    color: ${props => props.theme[props.color]};
`;

export const CActDetBGStyles = css`
    color: ${props => props.theme.C_TEXT_purple_bg};

    svg {
        .OI_4L_main,
        .DI_4L_main {
            stroke: ${props => props.theme.C_TEXT_purple_bg};
        }

        .B_4L_main {
            fill: ${props => props.theme.C_TEXT_purple_bg};
        }
    }
`;