import { ISelectItem } from "@components/inputs/select/Select.types";
import {
    AccountAssignmentDocumentTypeEntity,
    AccountAssignmentEntity,
    EntitySetName,
    EntityTypeName,
    IAccountAssignmentEntity,
    IItemTemplateEntity,
    ItemTemplateCbaTaxImpactEntity
} from "@odata/GeneratedEntityTypes";
import { CbaCategoryTaxImpactCode, DocumentTypeCode } from "@odata/GeneratedEnums";
import { getEnumDisplayValue, getEnumNameSpaceName } from "@odata/GeneratedEnums.utils";
import { transformToODataString } from "@odata/OData.utils";
import { getAssetAcquisitionLabel } from "@pages/cashBasisAccounting/CashBasisAccounting.utils";

import { ValueType } from "../../../enums";
import { FormStorage, IFormStorageDefaultCustomData } from "../../../views/formView/FormStorage";

export type TItemTemplateStorage = FormStorage<IItemTemplateEntity, IItemTemplateCustomData>;

export const ItemTemplatesTranslationFiles = [
    "ItemTemplates",
    "Document",
    "Categories",
    getEnumNameSpaceName(EntityTypeName.CbaCategoryTaxImpact),
    getEnumNameSpaceName(EntityTypeName.VatDeductionType),
    getEnumNameSpaceName(EntityTypeName.VatStatus)
];

export enum DialogType {
    AccountAssignment = "AccountAssignment",
    VatDeduction = "VatDeduction",
    TaxImpact = "TaxImpact"
}

export interface IItemTemplateCustomData extends IFormStorageDefaultCustomData {
    AccountAssignments: IAccountAssignmentEntity[];
    FlattenAccounts: ISelectItem[];
    FlattenNonTaxAccounts: ISelectItem[];
    openedDialog: DialogType;
}

const ItemTemplateAllowedTypes = [
    DocumentTypeCode.InvoiceIssued, DocumentTypeCode.InvoiceReceived,
    DocumentTypeCode.ProformaInvoiceIssued, DocumentTypeCode.ProformaInvoiceReceived,
    DocumentTypeCode.OtherLiability, DocumentTypeCode.OtherReceivable,
    DocumentTypeCode.CorrectiveInvoiceIssued, DocumentTypeCode.CorrectiveInvoiceReceived,
];

export async function loadAccountAssignments(storage: TItemTemplateStorage): Promise<IAccountAssignmentEntity[]> {
    let { AccountAssignments } = storage.getCustomData() ?? {};
    if (!AccountAssignments) {
        const query = storage.oData.getEntitySetWrapper(EntitySetName.AccountAssignments).query()
                .filter(`${AccountAssignmentEntity.DocumentTypes}/any(x: x/${AccountAssignmentDocumentTypeEntity.DocumentTypeCode} in (${transformToODataString(ItemTemplateAllowedTypes, ValueType.String)}))`)
                .orderBy(AccountAssignmentEntity.ShortName);

        const data = await query.fetchData<IAccountAssignmentEntity[]>();
        AccountAssignments = data.value;

        storage.setCustomData({
            AccountAssignments
        });
    }

    return AccountAssignments;
}

export const nonTaxAssetID = `${CbaCategoryTaxImpactCode.Nontax}_asset`;

export function getCbaTaxImpactSelectItems(): ISelectItem[] {

    const _createItem = (code: CbaCategoryTaxImpactCode): ISelectItem => ({
        id: code,
        label: getEnumDisplayValue(EntityTypeName.CbaCategoryTaxImpact, code)
    });

    return [
            _createItem(CbaCategoryTaxImpactCode.Tax),
            _createItem(CbaCategoryTaxImpactCode.Nontax),
            // nonTax, Asset Acquisition
            {
                id: nonTaxAssetID,
                label: getAssetAcquisitionLabel(getEnumDisplayValue(EntityTypeName.CbaCategoryTaxImpact, CbaCategoryTaxImpactCode.Nontax)),
                additionalData: {
                    [ItemTemplateCbaTaxImpactEntity.IsAssetAcquisition]: true
                }
            },
            _createItem(CbaCategoryTaxImpactCode.Partial),
    ];
}