import styled, { css } from "styled-components/macro";
import { T_BREAD } from "../../global.style";
import { Underline } from "../navigation/NavMenu.styles";
import { IconButton } from "../button";
import Clickable from "../clickable";

const StyledLinkCss = css`
    position: relative;
    ${Underline};   
    
    &:after {
        bottom: -3px;
        height: 1px;
        transition: none;
    }
`;

export const StyledBreadCrumbItem = styled.li`
    ${T_BREAD};
    display: inline;
    list-style-type: none;
    margin-right: 5px;

    direction: ltr;
    unicode-bidi: isolate-override;

    &:not(:last-child):before {
        content: "/";
        margin-right: 5px;
    }

    color: ${props => props.theme.C_TEXT_primary};

    & a:hover {
        color: ${props => props.theme.C_TEXT_primary};
    }
`;

export const StyledItem = styled.span`
    ${StyledLinkCss};
    cursor: pointer;
`;

export const LinkStyledBreadcrumb = styled(Clickable)`
    ${StyledLinkCss};
    color: ${props => props.theme.C_TEXT_primary};
`;

export const StyledArrowButton = styled(IconButton)`
    margin-right: 10px;
`;