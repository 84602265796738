import { DocumentFormViewForExtend } from "./DocumentFormView";
import { ISmartFieldChange } from "@components/smart/smartField/SmartField";
import { setVariableSymbolFromNumberOurs } from "../banks/bankAccounts/BankAccounts.utils";

abstract class IssuedDocumentBaseFormView extends DocumentFormViewForExtend {
    handlePaymentMethodChange_variableSymbol(e: ISmartFieldChange): void {
        if (e.bindingContext.getPath() === "PaymentMethod") {
            // we may switch to wire transfer payment method -> VariableSymbol becomes visible, fill it.
            const { storage } = this.props;
            setVariableSymbolFromNumberOurs(storage, storage.data.entity.NumberOurs, storage.data.entity.NumberOurs);
        }
    }

    documentSpecificChangeHandlers(e: ISmartFieldChange): void {
        super.documentSpecificChangeHandlers(e);

        this.handlePaymentMethodChange_variableSymbol(e);
        this.handleVatItemsDecisiveDateChange(e);
    }
}

export default IssuedDocumentBaseFormView;
