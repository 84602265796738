import styled from "styled-components/macro";
import { BinIcon, EditIcon, RefreshIcon } from "../icon";
import { RadioButton } from "../inputs/radioButtonGroup/RadioButtonGroup";
import Input from "../inputs/input/Input";
import { ellipsis } from "polished";
import { getDisabledStyle } from "../../global.style";
import IconSelect from "../inputs/select/IconSelect";
import { VIEW_PADDING_VALUE } from "../../views/View.styles";
import { TabWrapper } from "../tabs/Tabs.styles";

export const StyledVariantSelector = styled.div`
    display: flex;
    align-items: center;
`;

export const Label = styled.div<{
    _isCustom?: boolean;
    _isDisabled?: boolean;
}>`
    font-style: ${props => props._isCustom ? "italic" : "normal"};
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 0.18px;
    height: 38px;
    line-height: 38px;
    vertical-align: middle;
    
    ${props => getDisabledStyle(props._isDisabled)}
`;
export const IconSelectStyled = styled(IconSelect)`
    margin-left: 2px;
`;

export const VariantInput = styled(Input)`
    min-height: 28px;
    width: 100%;
`;

export const HeaderTitle = styled.div`
    font-weight: bold;
`;

export const Icons = styled.div`
    display: flex;
`;
export const StyledEditIcon = styled(EditIcon)`
    margin-right: 6px
`;

export const StyledBinIcon = styled(BinIcon)`
    margin-top: -2px;
`;

export const StyledRefreshIcon = styled(RefreshIcon)`
    margin-top: -2px;
`;

export const Grid = styled.div`
    display: grid;
    font-size: 14px;
    grid-template-columns: min-content max-content min-content;
    grid-column-gap: 24px;

    & > div {
        height: 28px;
        display: flex;
        align-items: center;
    }
`;

export const MiddleColumn = styled.div`
    vertical-align: middle;
    height: 28px;
    line-height: 28px;
    ${ellipsis()};
    display: block !important;
`;

export const TableRadioButton = styled(RadioButton)`
    width: 100%;
    justify-content: center;
`;

export const TextParent = styled.span <{
    isDisabled?: boolean;
}>`
    ${props => getDisabledStyle(props.isDisabled)};
`;

export const ActiveText = styled.span`
    font-style: italic;
    color: ${props => props.theme.C_ACT_thick_line};
`;

export const EditVariantContent = styled.div`
    padding: 28px ${VIEW_PADDING_VALUE}px 24px ${VIEW_PADDING_VALUE}px;
    min-width: 450px;
    width: fit-content;

    ${TabWrapper} {
        margin-bottom: 28px;
    }
`;