import styled from "styled-components/macro";
import { T_BREAD } from "../../global.style";

export const StyledDraftTimeStamp = styled.div`
    ${T_BREAD};
    font-style: italic;
    color: ${props => props.theme.C_TEXT_primary};
    margin-right: 8px;
    display: flex;
    align-items: center;
    svg {
        margin-right: 5px;
    }
`;