import styled, { css } from "styled-components/macro";
import { T_PLAIN_small, T_TITLE_4, T_TITLE_4_NORMAL } from "../../global.style";
import { RequiredMark } from "../inputs/field/Label.styles";
import { ItemSelector, SelectedItemLabel } from "../configurationList/ConfigurationList.styles";
import { ellipsis } from "polished";
import CollapsibleSection from "../collapsibleSection/CollapsibleSection";

const RowGap = 12;

export const StyledImportSettings = styled.div`
    display: flex;
    justify-content: flex-start;
    width: 100%;
    min-height: 300px;
`;

export const ColumnHeader = styled.div`
    width: 300px;
    ${T_TITLE_4};
    font-weight: bold;
    padding: 0 0 8px 0;
`;

export const ColumnHeaderRow = styled.div`
    display: flex;
`;

export const Grid = styled.div`
    width: fit-content;
    display: grid;
    grid-template-columns: auto auto auto;
    grid-auto-rows: min-content;

    & > ${ColumnHeader} {
        height: 34px;
        margin-bottom: ${`${RowGap}px`};
    }

    padding-right: 10px;
`;


export const ValuePreview = styled.div`
    ${T_PLAIN_small};
    margin-bottom: 12px;
`;

export const Column = styled.div<{ withBorder?: boolean }>`
    display: flex;
    flex-direction: column;
    gap: ${RowGap}px;
    border-left: 2px solid ${props => props.withBorder ? props.theme.C_FIELD_line_der : "transparent"};

    padding: 0 25px;

    &:first-child {
        padding-left: 0;
    }
`;

interface IDropItemColumn {
    isInGroup: boolean;
    addTopMargin?: boolean;
}

export const DropItemColumnWrapper = styled.div<IDropItemColumn>`
    padding-bottom: ${RowGap}px;

    ${props => props.isInGroup && css`
    background: ${props => props.theme.C_BG_menu};

    &:nth-child(3n + 3) {
        padding-right: 15px;
    }
`}

    ${props => props.addTopMargin && css`
    margin-top: ${RowGap}px;
`}
`;

const DropItemColumnBase = styled.div<IDropItemColumn>`
    display: flex;
    height: 34px;
`;

export const DropItemFirstColumn = styled(DropItemColumnBase)`
    padding-right: 20px;
    position: relative;
    align-items: center;
    min-width: max-content;
    ${T_TITLE_4_NORMAL};

    ${props => props.isInGroup && css`
        padding-left: 20px;
    `};
`;

export const DropItemSecondColumn = styled(DropItemColumnBase)`
    align-items: center;
    margin-right: 5px;
`;

export const DropItemThirdColumn = styled(DropItemColumnBase)<{ hasValue: boolean } & IDropItemColumn>`
    width: 300px;
    white-space: nowrap;
    position: relative;
    border-radius: 17px;
    border: 1px dashed ${props => !props.hasValue ? props.theme.C_ACT_thick_line : "transparent"};
    ${T_TITLE_4_NORMAL};
    padding: 8px 30px 8px 15px;
    background-color: ${props => props.hasValue ? props.theme.C_FIELD_line_der : props.theme.C_BG_floorplan};
`;

export const RequiredMarkStyled = styled(RequiredMark)`
    top: -9px;
    margin-right: 3px;
    position: relative;
    left: 6px;
`;

export const ItemSelectorStyled = styled(ItemSelector)`
    height: 30px;
    left: 0;
    cursor: pointer;
    top: -6px;
`;

export const SelectedItemLabelStyled = styled(SelectedItemLabel)`
    ${ellipsis()};
    padding: 5px;
`;

export const ValueWrapper = styled.div`
    ${ellipsis()};
    flex-grow: 1;
`;

export const StyledDragItem = styled.div<{ selected: boolean }>`
    width: 300px;
    height: 34px;
    cursor: pointer;
    ${T_TITLE_4_NORMAL};
    border-radius: 17px;
    background: ${props => props.theme.C_FIELD_line_der};
    padding: 8px 15px;
    border: 1px solid ${props => props.selected ? props.theme.C_BTN_border_hover : "transparent"};
    ${ellipsis()};
    display: block;
    margin-bottom: 12px;
`;

export const ImportSettingsGroupColumnBase = styled.div`
    background: ${props => props.theme.C_BG_menu};

`;

export const ImportSettingsGroupFirstColumn = styled(ImportSettingsGroupColumnBase)`
    display: flex;
    padding: 10px;

    &:not(:first-child) {
        margin-top: 15px;
    }
`;

export const ImportSettingsGroupSecondColumn = styled(ImportSettingsGroupColumnBase)`
    &:not(:nth-child(2)) {
        margin-top: 15px;
    }
`;

export const ImportSettingsGroupThirdColumn = styled(ImportSettingsGroupColumnBase)`
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    min-width: 300px;

    &:not(:nth-child(3)) {
        margin-top: 15px;
    }
`;

export const ImportSettingsSubItemColumn = styled.div<IDropItemColumn>`
    ${props => props.isInGroup && css`
        background: ${props => props.theme.C_BG_menu};
    `}
`;

export const StyledImportSettingsSubItem = styled.div<IDropItemColumn>`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 8px;
    ${T_TITLE_4_NORMAL};

    ${props => props.isInGroup && css`
        background: ${props => props.theme.C_BG_menu};
    `}
    
    & > * {
        margin-right: 15px;
    }
`;

export const SubItemSelectWrapper = styled.div`
    flex: 0 0 auto;
`;

export const SubItemDescription = styled.div`
    ${ellipsis()};
`;

export const CollapsibleSectionPohodaImport = styled(CollapsibleSection)`
    margin-top: 24px;
`;