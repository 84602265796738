import React from "react";
import { withAlert, WithAlert } from "./withAlert";
import PopperWrapper, { IPopperOptions } from "../popperWrapper/PopperWrapper";
import { IAlertProps } from "./Alert";

interface IProps extends IPopperOptions, WithAlert {
    children: (ref: React.Ref<any>, setAlert: (alert: IAlertProps) => void) => React.ReactElement;
}

class PopperAlert extends React.PureComponent<IProps> {
    render() {
        return (
            <PopperWrapper placement={this.props.placement}
                           modifiers={this.props.modifiers}
                           reference={(ref: React.Ref<any>) => {
                               return (
                                   this.props.children(ref, this.props.setAlert)
                               );
                           }}>
                {this.props.alert}
            </PopperWrapper>
        );
    }
}

export default withAlert({ autoHide: true })(PopperAlert);