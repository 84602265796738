import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Columns, StyledConfigurationList } from "./ConfigurationList.styles";
import { IProps as IConfigListGroup } from "./Group";
import { Column, IProps as IConfigListColumn } from "./Column";
import { IItemSelectedItemChange, IProps as IConfigListItem, ISubItemVisibilityChange } from "./Item";
import { TRecordType } from "../../global.types";
import { DragDropContext, DragStart, DragUpdate, DropResult } from "react-beautiful-dnd";
import { ConfigListItemBoundType, GroupListDropType } from "../../enums";
import memoizeOne from "../../utils/memoizeOne";
import TestIds from "../../testIds";
import { compareString } from "@utils/string";
import { computePlaceholderPos, getDragedElement, getDroppableElement } from "./DnD.utils";

export const COPY_SIGN = "-copy-";
export const COPY_APPENDIX = new RegExp(`${COPY_SIGN}(?<number>\\d+)$`);

export const CustomDnDContext = React.createContext<ICustomDnDContext>(null);

export interface ICustomDnDContext {
    placeholder: IPlaceholderPos;
    dropAnimationPos: IDropAnimationPos;
    data: IConfigList;
    configListRef: React.RefObject<HTMLDivElement>;
}

export function connectToContext(WrappedComponent: React.ElementType, select: (...args: any) => any) {
    return function(props: any) {
        const selectors = select(props);
        return <WrappedComponent {...selectors} {...props}/>;
    };
}

export const getItemCopyId = (itemId: string, index: number) => {
    return `${itemId}${COPY_SIGN}${index}`;
};

export const shouldComponentUpdateIgnoreData = (props: any, nextProps: any): boolean => {
    if (Object.keys(props).length !== Object.keys(nextProps).length) {
        return true;
    }

    const changes = [];

    for (const key of Object.keys(props)) {
        if (props[key] !== nextProps[key]) {
            changes.push(key);
        }
    }

    // ignore when data object changes to prevent pointless rerenders
    return changes.length === 1 ? changes[0] !== "data" : changes.length > 0;
};

// draggableId has to be unique among ALL draggables (both Items and Groups in our case)
// https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/guides/identifiers.md
// each has either -item or -group suffix, this method will remove it
export const getCleanDraggableId = (draggableId: string) => {
    if (!draggableId) {
        return null;
    }

    const lastDashIndex = draggableId.lastIndexOf("-");
    return draggableId.slice(0, lastDashIndex);
};

export const trimCopyId = (id: string) => {
    return id && id.replace(COPY_APPENDIX, "");
};

export const isCopy = (id: string) => {
    return COPY_APPENDIX.test(id);
};

export interface IGroupListColumnDef extends Omit<IConfigListColumn, "index" | "items" | "groups"> {
    groupIds: string[];
}

export interface IGroupListGroupDef extends Omit<IConfigListGroup, "index" | "items" | "provided" | "snapshot"> {
    itemIds: string[];
}

export interface IGroupListItemDef extends Omit<IConfigListItem, "index" | "provided" | "snapshot" | "dropAnimationPos"> {
}

export interface IConfigList {
    id?: string;
    name?: string;
    columns: TRecordType<IGroupListColumnDef>;
    groups: TRecordType<IGroupListGroupDef>;
    items: TRecordType<IGroupListItemDef>;
}

interface IProps extends WithTranslation {
    data: IConfigList;
    onDataChange: (newData: IConfigList) => void;
    onGroupAdd?: (columnId: string) => void;
    onGroupRemove?: (groupId: string) => void;
}

export interface IPlaceholderPos {
    clientHeight: number;
    clientWidth: number;
    clientY: number;
    clientX: number;
    droppableId: string;
}

export interface IDropAnimationPos {
    x: number;
    y: number;
}

interface IState {
    placeholder: IPlaceholderPos;
    dropAnimationPos: IDropAnimationPos;
    tmpGroups: TRecordType<IGroupListGroupDef>;
}

class ConfigurationList extends React.PureComponent<IProps> {
    configListRef = React.createRef<HTMLDivElement>();

    state: IState = {
        // move outside of state and use event emitter instead,
        // if re-rendering caused by placeholder changes would be performance issue
        placeholder: null,
        dropAnimationPos: null,
        tmpGroups: null
    };

    getGroupsDef = () => {
        return this.state.tmpGroups ?? this.props.data.groups;
    };

    getColumnsGroups = memoizeOne(
            () => {
                const groups = this.getGroups();
                const columnsGroups: any = {};

                for (const [colName, column] of Object.entries(this.props.data.columns)) {
                    columnsGroups[colName] = column.groupIds.map(groupId => groups[groupId]);
                }

                return columnsGroups;
            },
            () => [this.props.data.columns, this.getGroupsDef()]);

    getColumnGroups = (columnId: string) => {
        return this.getColumnsGroups()[columnId];
    };

    // returns groups with sorted items (for groups with sorting enabled)
    getGroups = memoizeOne(
            () => {
                const preparedGroups: TRecordType<IGroupListGroupDef> = {};

                Object.keys(this.getGroupsDef()).forEach(groupId => {
                    preparedGroups[groupId] = this.getGroup(groupId);
                });

                return preparedGroups;
            }, () => [this.getGroupsDef()]);

    getGroup = (groupId: string) => {
        let group = this.getGroupsDef()[groupId];

        if (group.shouldSort) {
            // todo this causes the group with sorting to always rerender when other group changes
            // can we remove automatic sorting from ConfigurationList?
            group = this.getSortedGroup(group);
        }

        return group;
    };

    getSortedGroup = (group: IGroupListGroupDef) => {
        const groupItems = this.getGroupItems(group);
        groupItems.sort(this.sortItems);

        return {
            ...group,
            itemIds: groupItems.map(item => item.id)
        };
    };

    getGroupItems = (group: IGroupListGroupDef) => {
        return group.itemIds.map(itemId => this.props.data.items[itemId] ?? this.props.data.items[trimCopyId(itemId)]);
    };

    getItems = memoizeOne(
            () => {
                // enrich items with event props
                return Object.values(this.props.data.items).reduce((allItems: Record<string, IGroupListItemDef>, currentItem) => {
                    allItems[currentItem.id] = {
                        ...currentItem,
                        onSelectedItemChange: this.handleSelectedItemChange,
                        onSubItemVisibilityChange: this.handleSubItemVisibilityChange
                    };

                    return allItems;
                }, {});
            }, () => [this.props.data.items]);

    sortItems = (a: any, b: any) => {
        if (a.icon) {
            return -1;
        } else if (b.icon) {
            return 1;
        }

        return compareString(a.value, b.value);
    };

    handleSelectedItemChange = (args: IItemSelectedItemChange) => {
        const newData: IConfigList = {
            ...this.props.data,
            items: {
                ...this.props.data.items,
                [args.itemId]: {
                    ...this.props.data.items[args.itemId],
                    selectedItemId: args.selectedItemId
                }
            }
        };

        this.props.onDataChange(newData);
    };

    handleSubItemVisibilityChange = (args: ISubItemVisibilityChange) => {
        const item = this.props.data.items[args.itemId];

        const newData: IConfigList = {
            ...this.props.data,
            items: {
                ...this.props.data.items,
                [args.itemId]: {
                    ...item,
                    subItems: item.subItems.map(subItem => {
                        return subItem.id === args.subItemId ? {
                            ...subItem, isVisible: args.visible
                        } : subItem;
                    })
                }
            }
        };

        this.props.onDataChange(newData);
    };

    handleGroupRemoveClick = (groupId: string) => {
        this.props.onGroupRemove?.(groupId);
    };

    handleGroupLabelChange = (groupId: string, newLabel: string) => {
        const newData: IConfigList = {
            ...this.props.data,
            groups: {
                ...this.props.data.groups,
                [groupId]: {
                    ...this.props.data.groups[groupId],
                    label: newLabel
                }
            }
        };

        this.props.onDataChange(newData);
    };

    renderColumns = (columns: IGroupListColumnDef[] = []) => {
        return columns.map((column, index) => {
            const columnGroups = this.getColumnGroups(column.id);
            const items = this.getItems();

            return (
                <Column key={column.id} index={index}
                        groups={columnGroups} items={items}
                        onGroupAddClick={this.props.onGroupAdd}
                        onGroupRemoveClick={this.handleGroupRemoveClick}
                        onGroupLabelChange={this.handleGroupLabelChange}
                        {...column}
                >
                </Column>
            );
        });
    };

    getMaxCopyNum = (id: string) => {
        return Object.values(this.props.data.groups).reduce((max, group) => {
            let localMax = 0;

            for (const itemId of group.itemIds) {
                if (itemId.startsWith(id) && isCopy(itemId)) {
                    const number = parseInt(COPY_APPENDIX.exec(itemId).groups.number);
                    localMax = Math.max(localMax, number);
                }
            }

            return Math.max(localMax, max);
        }, 0);
    };

    handleItemDragStart = (event: DragStart) => {
        if (event.type !== GroupListDropType.Item) {
            return;
        }

        const cleanDraggableId = getCleanDraggableId(event.draggableId);
        const item = this.props.data.items[cleanDraggableId] ?? this.props.data.items[trimCopyId(cleanDraggableId)];

        if (!item.boundTo && !item.allowedGroups) {
            return;
        }

        let tmpGroups: TRecordType<IGroupListGroupDef>;

        if (item.boundTo) {
            tmpGroups = item.boundTo === ConfigListItemBoundType.Group ? this.handleBoundToGroupItemDragStart(item) : this.handleBoundToColumnItemDragStart(item);
        } else {
            tmpGroups = this.handleItemWithAllowedGroupsDragStart(item);
        }

        this.setState({
            tmpGroups
        });
    };

    // disable drop for groups in other columns
    handleBoundToColumnItemDragStart = (item: IGroupListItemDef) => {
        const tmpGroups = { ...this.props.data.groups };
        const itemGroup = Object.values(this.props.data.groups).find(group => group.itemIds.includes(item.id));
        const groupColumn = Object.values(this.props.data.columns).find(column => column.groupIds.includes(itemGroup.id));

        if (groupColumn.cannotBeBoundTo) {
            return tmpGroups;
        }

        // disable drop for all groups in the columns that doesn't contain the item
        for (const column of Object.values(this.props.data.columns)) {
            if (column.id === groupColumn.id) {
                continue;
            }
            for (const groupId of column.groupIds) {
                const group = this.props.data.groups[groupId];

                tmpGroups[group.id] = {
                    ...group,
                    isDropDisabled: true
                };
            }
        }

        return tmpGroups;
    };

    // disable drop for all other groups
    handleBoundToGroupItemDragStart = (item: IGroupListItemDef) => {
        const tmpGroups = { ...this.props.data.groups };

        for (const group of Object.values(this.props.data.groups)) {
            if (!group.itemIds.includes(item.id)) {
                tmpGroups[group.id] = {
                    ...group,
                    isDropDisabled: true
                };
            }
        }

        return tmpGroups;
    };

    handleItemWithAllowedGroupsDragStart = (item: IGroupListItemDef) => {
        const tmpGroups = { ...this.props.data.groups };

        for (const group of Object.values(this.props.data.groups)) {
            if (!item.allowedGroups.includes(group.id)) {
                tmpGroups[group.id] = {
                    ...group,
                    isDropDisabled: true
                };
            }
        }

        return tmpGroups;
    };

    handleItemDragEnd = (event: DragStart) => {
        if (event.type !== GroupListDropType.Item || !this.state.tmpGroups) {
            return Promise.resolve();
        }

        return new Promise((resolve) => {
            this.setState({
                tmpGroups: null
            }, resolve as (() => void));
        });
    };

    handleDragStart = (event: DragStart) => {
        const draggedElement = getDragedElement(event.draggableId);

        if (!draggedElement) {
            return;
        }

        this.handleItemDragStart(event);

        const { element, elements } = this.getPlaceholderComputationElements(draggedElement, event);

        const placeholder = computePlaceholderPos(
            element,
            elements,
            event.source.index,
            event.source.droppableId,
            event.type as GroupListDropType
        );

        this.setState({
            placeholder,
            dropAnimationPos: null
        });
    };

    getPlaceholderComputationElements = (draggedElement: HTMLElement, event: DragStart) => {
        let element: HTMLElement;
        let elements: HTMLElement[];

        // because renderClone (portal) is used, we need to get the children element
        // reference directly from ConfigurationList not from draggedElement
        elements = [...this.configListRef.current.querySelector(`[data-rbd-droppable-id="${event.source.droppableId}"]`).children] as HTMLElement[];

        if (event.type === GroupListDropType.Item) {
            element = draggedElement;
        } else {
            element = draggedElement.parentElement.parentElement;
        }

        return { element, elements };
    };

    // when copy of an item is dropped to column with its original,
    // we want the animation to end in the original item
    // calculate the position and store it in the CustomDnDContext
    setDropAnimationPos = (event: DragUpdate) => {
        if (event.type !== GroupListDropType.Item) {
            return;
        }

        const cleanDraggableId = getCleanDraggableId(event.draggableId);
        const dropGroup = this.props.data.groups[event.destination.droppableId];
        const trimmedId = trimCopyId(cleanDraggableId);
        let dropAnimationPos = null;

        if (dropGroup.itemIds.includes(trimmedId) && this.props.data.items[trimmedId].isCopyOnly) {
            // draggedElement has position:fixed, we can use offsetLeft/Top to get its position WITHOUT transform: translate
            const draggedElement = getDragedElement(event.draggableId);
            // endElement doesn't have any transformation, we can use getBoundingClientRect to get its value relative to page
            const endElement = getDragedElement(`${trimmedId}-${event.type}`);
            let posX, posY;

            if (draggedElement === endElement) {
                posX = posY = 0;
            } else {
                const endElementRect = endElement.getBoundingClientRect();
                posX = endElementRect.x - draggedElement.offsetLeft;
                posY = endElementRect.y - draggedElement.offsetTop;
            }


            dropAnimationPos = {
                x: posX,
                y: posY
            };
        }

        this.setState({
            dropAnimationPos
        });
    };

    handleDragUpdate = (event: DragUpdate) => {
        this.setState({
            placeholder: null
        });

        if (!event.destination) {
            return;
        }

        const draggedElement = getDragedElement(event.draggableId);
        const destinationElement = getDroppableElement(event.destination?.droppableId);

        if (!draggedElement || !destinationElement) {
            return;
        }

        // prevent hover on items under the dragged item
        // todo try to disable hover/tooltip on all items while one is being dragged
        // draggedElement.style["pointerEvents"] = "all";

        this.setDropAnimationPos(event);

        const { element } = this.getPlaceholderComputationElements(draggedElement, event);
        const sourceIndex = event.source.index;
        const destinationIndex = event.destination.index;
        const childrenArray = [...destinationElement.children];

        // only remove elemement, if portal cannot be used
        if (!document.getElementById("modal-root") && event.source.droppableId === event.destination.droppableId) {
            childrenArray.splice(sourceIndex, 1);
        }

        const updatedArray = [
            ...childrenArray.slice(0, destinationIndex),
            element,
            ...childrenArray.slice(destinationIndex + 1)
        ];

        // disable placeholder when dragging over disabled items
        const placeholder = this.isItemDraggedOverDisabledItems(event) ? null
            : computePlaceholderPos(
                element,
                updatedArray as HTMLElement[],
                destinationIndex,
                event.destination.droppableId,
                event.type as GroupListDropType
            );

        this.setState({
            placeholder
        });
    };

    isItemDraggedOverDisabledItems = (event: DragUpdate) => {
        if (event.type !== GroupListDropType.Item) {
            return null;
        }

        const group = this.props.data.groups[event.destination.droppableId];
        const groupItems = this.getGroupItems(group);
        const destinationIndex = event.destination.index;

        return (
            (destinationIndex === 0 && groupItems[0]?.isDisabled) // start
            // || (destinationIndex === groupItems.length && groupItems[destinationIndex - 1]?.disabled) // end
            || (groupItems[destinationIndex - 1]?.isDisabled && groupItems[destinationIndex]?.isDisabled) // in between
        );
    };

    handleDragEnd = async (result: DropResult) => {
        this.setState({
            placeholder: null
        });

        await this.handleItemDragEnd(result);

        const { destination, source, draggableId, type } = result;

        if (!destination) {
            return; // dropped outside?
        }

        if (destination.droppableId === source.droppableId &&
            destination.index === source.index) {
            return; // dropped in the same place
        }

        const cleanDraggableId = getCleanDraggableId(draggableId);

        let newResult;
        let idsName;
        let isCopyOnly;
        let shouldRemove;
        let maxCopyNum;
        let newDraggableId = cleanDraggableId;
        let destinationIndex = destination.index;

        switch (type) {
            case GroupListDropType.Item:
                newResult = {
                    ...this.getGroups()
                };
                idsName = "itemIds";

                const sourceItem = this.props.data.items[cleanDraggableId];

                if (destination.droppableId !== source.droppableId) {
                    if (sourceItem?.isCopyOnly) {
                        isCopyOnly = true;
                        maxCopyNum = this.getMaxCopyNum(newDraggableId) + 1;
                        newDraggableId = getItemCopyId(newDraggableId, maxCopyNum);
                    }

                    if (this.props.data.groups[destination.droppableId].itemIds.includes(trimCopyId(cleanDraggableId))) {
                        shouldRemove = true;
                    }
                }

                destinationIndex = this.getFirstNonDisabledDestinationIndex(result);

                break;
            case GroupListDropType.Group:
                newResult = {
                    ...this.props.data.columns
                };
                idsName = "groupIds";
                break;
        }


        const startDroppable = newResult[source.droppableId];
        const startDraggableIds = [...(startDroppable as any)[idsName]];

        if (!isCopyOnly) {
            startDraggableIds.splice(source.index, 1);
        }

        const newStartDroppable = {
            ...startDroppable,
            [idsName]: startDraggableIds
        };
        newResult[newStartDroppable.id] = newStartDroppable;

        const finishDroppable = newResult[destination.droppableId];
        const finishDraggableIds = [...(finishDroppable as any)[idsName]];

        if (!shouldRemove) {
            finishDraggableIds.splice(destinationIndex, 0, newDraggableId);
        }

        const newFinishDroppable = {
            ...finishDroppable,
            [idsName]: finishDraggableIds
        };
        newResult[newFinishDroppable.id] = newFinishDroppable;

        const newData = {
            ...this.props.data
        };

        if (type === GroupListDropType.Item) {
            newData.groups = newResult as TRecordType<IGroupListGroupDef>;

            if (isCopyOnly) {
                // we need to clone the whole item, so that every clone can have different selectedItemId
                newData.items[newDraggableId] = {
                    ...this.props.data.items[draggableId],
                    id: newDraggableId,
                    isCopyOnly: false
                };
            }
        } else if (type === GroupListDropType.Group) {
            newData.columns = newResult as TRecordType<IGroupListColumnDef>;
        }

        this.props?.onDataChange(newData);
    };

    getFirstNonDisabledDestinationIndex = (event: DropResult) => {
        const group = this.props.data.groups[event.destination.droppableId];
        const groupItems = this.getGroupItems(group);
        let destinationIndex = event.destination.index;

        while (destinationIndex < groupItems.length && groupItems[destinationIndex].isDisabled) {
            destinationIndex += 1;
        }

        return destinationIndex;
    };

    getCustomDndContext = memoizeOne(() => {
        return {
            placeholder: this.state.placeholder,
            dropAnimationPos: this.state.dropAnimationPos,
            data: this.props.data,
            configListRef: this.configListRef
        };
    }, () => [this.state.placeholder, this.state.dropAnimationPos, this.props.data]);

    render() {
        const customDndContext: ICustomDnDContext = this.getCustomDndContext();

        return (
            <DragDropContext onDragEnd={this.handleDragEnd}
                             onDragStart={this.handleDragStart}
                             onDragUpdate={this.handleDragUpdate}
            >
                <CustomDnDContext.Provider value={customDndContext}>
                    <StyledConfigurationList data-testid={TestIds.ConfigurationList} ref={this.configListRef}>
                        <Columns>
                            {this.renderColumns(Object.values(this.props.data.columns))}
                        </Columns>
                    </StyledConfigurationList>
                </CustomDnDContext.Provider>
            </DragDropContext>
        );
    }
}

export default withTranslation(["Components"])(ConfigurationList);