export const INPUT_DEBOUNCE_TIME = 250;
export const SAVE_DRAFT_DELAY = 15000;
export const AUTOCOMPLETE_SUG_ROWS = 50;
export const LOGIN_PAGE_SUBDOMAIN = "app";
export const EVALA_WEBPAGE = "https://www.evala.cz";
export const API_URL = "/api";
export const ODATA_API_URL = `${API_URL}/odata`;
export const TRANSLATIONS_API_URL = `${API_URL}/translations`;
export const REST_API_URL = `${API_URL}/rest`;
export const PUBLIC_API_URL = `${API_URL}/public`;
export const ARES_SEARCH_API = `${REST_API_URL}/Ares/search`;
export const ARES_GET_API = `${REST_API_URL}/Ares/get`;
export const FILES_API_URL = `${REST_API_URL}/Files`;
export const GET_FILE_NAME_URL = `${REST_API_URL}/FileName`;
export const AUDIT_API_URL = `${REST_API_URL}/Audit`;
export const EXPORT_URL = `${REST_API_URL}/Export`;
export const EXPORT_ALL_URL = `${EXPORT_URL}/AllCompanies`;
export const BANK_STATEMENTS = `${REST_API_URL}/BankStatements`;
export const ASSET_API_URL = `${REST_API_URL}/Asset`;
export const ACCOUNTING_API_URL = `${REST_API_URL}/Accounting`;
export const ACCOUNT_USAGE_ACROSS_CHARTS_API_URL = `${ACCOUNTING_API_URL}/GetAccountUsageAcrossCharts`;
export const ACCOUNT_USAGE_API_URL = `${ACCOUNTING_API_URL}/GetAccountUsage`;
export const GLOBAL_SEARCH_URL = `${REST_API_URL}/Search`;
export const RETRIEVE_ISDOC_URL = `${REST_API_URL}/Isdoc`;
export const DATABOX_REST_URL = `${REST_API_URL}/DataBox`;
// on this endpoint you get finance administration office based on postal code
export const FU_BY_POSTAL_CODE_URL = `${DATABOX_REST_URL}/FA/`;
export const ASSET_TO_EXPENSE_API_URL = `${REST_API_URL}/AssetToExpense`;
export const ATTACH_INBOX_FILE_URL = `${REST_API_URL}/InboxFile/ConvertToDocuments`;
export const SYSTEM_CONFIG_URL = `${PUBLIC_API_URL}/SystemConfiguration`;
export const VALIDATE_DELETE_URL = `${REST_API_URL}/entityvalidator/validatedelete`;
export const VALIDATE_DELETE_DEPENDENT_URL = `${REST_API_URL}/entityvalidator/validatedependententitiesdelete`;
export const DASHBOARD_DATA_API = `${REST_API_URL}/DashboardData`;
export const AGING_AP_API_PATH = "AgingAP";
export const AGING_AR_API_PATH = "AgingAR";
export const AGING_AP_API_URL = `${REST_API_URL}/${AGING_AP_API_PATH}`;
export const AGING_AR_API_URL = `${REST_API_URL}/${AGING_AR_API_PATH}`;
export const CREATE_CLEARING_SPLIT_ITEMS = `${REST_API_URL}/PaymentDocument/CreateClearingItems`;
export const UPDATE_CLEARING_SPLIT_ITEMS = `${REST_API_URL}/PaymentDocument/CreateExchangeRateDifferenceItems`;
export const ACCOUNTING_JOURNAL_API_URL = `${REST_API_URL}/AccountingJournal`;
export const CBA_INCOME_EXPENSE_OVERVIEW_API_URL = `${REST_API_URL}/CbaIncomeExpenseOverview`;
export const VAT_STATEMENT_CALCULATE_API_URL = `${REST_API_URL}/VatStatement/CalculateSettlementCoefficient`;
export const FETCH_ABORT_ERROR = "AbortError";
export const NEW_ITEM_DETAIL = "new";
export const COLOR_TRANSITION_TIME = 50;
export const API_AUTH_URL = `${API_URL}/auth`;
export const LOGIN_URL = `${API_AUTH_URL}/login`;
export const REGISTER_URL = `${API_AUTH_URL}/registration`;
export const REGISTER_RESEND_EMAIL_URL = `${API_AUTH_URL}/registration/resendEmail`;
export const SESSION_URL = `${API_AUTH_URL}/session`;
export const AUTH_DEVICE_URL = `${API_AUTH_URL}/device`;
export const INVITATION_URL = `${API_AUTH_URL}/invitation`;
export const SESSION_TENANTS_URL = `${SESSION_URL}/tenants`;
export const DEMO_URL = `${REST_API_URL}/demoTenant`;
export const SESSION_DEMO_STATUS_URL = `${DEMO_URL}/getDemoTenantStatus`;
export const SESSION_DEMO_URL = `${DEMO_URL}/demo`;
export const AUTH_DEVICE_URL_ADD_URL = `${AUTH_DEVICE_URL}/add`;
export const AUTH_DEVICE_URL_RESEND_URL = `${AUTH_DEVICE_URL}/resendEmail`;
export const AUTH_LOGOUT_URL = `${API_AUTH_URL}/logout`;
export const AUTH_USER_SETTINGS_URL = `${API_AUTH_URL}/userSettings`;
export const AUTH_VERIFICATION_VERIFY_URL = `${API_AUTH_URL}/verification`;
export const GET_POST_LOGIN_ACTIONS_URL = `${AUTH_VERIFICATION_VERIFY_URL}/postloginactions`;
export const AUTH_VERIFICATION_ACTIVATE_URL = `${API_AUTH_URL}/verification/activate`;
export const AUTH_REQUEST_RESET_PASS_URL = `${AUTH_USER_SETTINGS_URL}/requestResetPassword`;
export const AUTH_TENANTS = `${API_AUTH_URL}/tenants`;
export const COMPANY_REMOVAL_REQUESTED = `${REST_API_URL}/companyremoval/aredeleterequested`;
export const COMPANY_REMOVAL_CANCEL = `${REST_API_URL}/companyremoval/cancel`;
export const COMPANY_ROLE_ALLOWED_PERMISSION_URL = `${REST_API_URL}/companyrole/allowedpermissions`;
export const ENCRYPTION_URL = `${REST_API_URL}/Encryption`;
export const CUSTOMER_DASHBOARD_API_URL = `${REST_API_URL}/CustomerDashboard`;
export const ASSET_DEPRECIATION_MONTH_API_URL = `${CUSTOMER_DASHBOARD_API_URL}/AssetsDepreciation`;
export const UPLOAD_INBOX_FILE_URL = `${FILES_API_URL}/uploadinboxfile`;

export const DASH_CHARACTER = "–";
export const ELLIPSIS_CHAR = "\u2026";
export const EMAIL_REGEX = /^\S+@\S+\.\S+$/;
export const NON_BREAKING_SPACE = "\u00a0";
export const EMPTY_VALUE = DASH_CHARACTER.repeat(3);
export const INFINITY = "∞";
export const ENV_EVALA_PRODUCTION = "REACT_APP_EVALA_PRODUCTION";
export const ENV_EVALA_VERSION = "REACT_APP_EVALA_VERSION";
export const ENV_DISABLE_WEBSOCKET = "REACT_APP_DISABLE_WEBSOCKET";
export const BANK_ACCOUNT_BALANCE_SHEET_ACCOUNT_PREFIX = 221;
export const CASH_BOXES_BALANCE_SHEET_ACCOUNT_PREFIX = 211;

export const WORK_LENGTH = 4.5;
export const BREAK_LENGTH = 0.5;
export const BANK_ACCOUNT_PROFORMA_RECEIVED = 314;
export const BANK_ACCOUNT_PROFORMA_ISSUED = 324;

/**
 * Accounting constants
 */
export const VAT_ASSESSMENT_TURNOVER_LIMIT = 2000000;
export const MAX_FLAT_TAX_BASIS = 2000000;