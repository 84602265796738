import { REST_API_URL } from "../constants";
import { getDefaultPostParams } from "./customFetch";

enum ConsoleLogType {
    Trace = "trace",
    Debug = "debug",
    Information = "info",
    Warning = "warn",
    Error = "error"
}

enum LogType {
    Trace = "Trace",
    Debug = "Debug",
    Information = "Information",
    Warning = "Warning",
    Error = "Error"
}

type TCustomLogData = Record<string, string | number | boolean>;

interface ILogBody {
    level: LogType;
    error?: {
        innerError?: Error;
        message?: string;
    },
    stacktrace: string;
    customData?: TCustomLogData;
}

class Logger {
    trace(message: string, error?: Error, customLogData?: TCustomLogData) {
        this.log(LogType.Trace, ConsoleLogType.Trace, message, error, customLogData);
    }

    debug(message: string, error?: Error, customLogData?: TCustomLogData) {
        this.log(LogType.Debug, ConsoleLogType.Debug, message, error, customLogData);
    }

    info(message: string, error?: Error, customLogData?: TCustomLogData) {
        this.log(LogType.Information, ConsoleLogType.Information, message, error, customLogData);
    }

    warn(message: string, error?: Error, customLogData?: TCustomLogData) {
        this.log(LogType.Warning, ConsoleLogType.Warning, message, error, customLogData);
    }

    error(message: string, error?: Error, customLogData?: TCustomLogData) {
        this.log(LogType.Error, ConsoleLogType.Error, message, error, customLogData);
    }

    log(logType: LogType, consoleLogType: ConsoleLogType, message: string | unknown, error?: Error, customLogData?: TCustomLogData) {
        if (message && typeof message === "object") {
            // in case someone calls logger with error from catch block by mistake
            if (message instanceof Error) {
                message = (message as Error).message;
                error = message as Error;
            } else {
                message = "unknown error";
            }
        }

        // eslint-disable-next-line no-console
        console[consoleLogType](message, error);
        this._sendLog(logType, message as string, error, customLogData);
    }

    _buildErrorBody(error: Error) {
        if (error instanceof Error) {
            const result = {
                name: "", // TODO: add name ?
                message: error.message,
                stackTrace: error.stack
            };
            // if (error.innerError) {
            //     result.innerError = this._buildErrorBody(error.innerError);
            // }
            return result;
        } else {
            return null;
        }
    }

    _buildLogBody(level: LogType, message: string, error?: Error, customLogData?: TCustomLogData) {
        const innerError = this._buildErrorBody(error);
        const err = new Error();
        const body: ILogBody = {
            level: level,
            stacktrace: err.stack,
            error: {
                message: message,
                innerError: innerError
            },
            customData: customLogData
        };
        return body;
    }

    async _sendLog(level: LogType, message: string, error: Error, customLogData?: TCustomLogData) {
        const body = this._buildLogBody(level, message, error, customLogData);
        try {
            await fetch(`${REST_API_URL}/logging`, {
                ...getDefaultPostParams(),
                redirect: "error",
                body: JSON.stringify(body)
            });
        } catch (e) {
        }
    }
}

const logger = new Logger();

export { logger };