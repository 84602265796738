import React from "react";
import LottieWrapper, { IProps } from "./LottieWrapper";

// Light mode
import ant from "../static/animations/json/ant.json";
import squirrel from "../static/animations/json/squirrel.json";
import spider from "../static/animations/json/spider.json";
import mouse from "../static/animations/json/mouse.json";
import dog from "../static/animations/json/dog.json";
import mole from "../static/animations/json/mole.json";
import beaver from "../static/animations/json/beaver.json";
import bear from "../static/animations/json/bear.json";
import bike from "../static/animations/json/bike.json";
import queue from "../static/animations/json/queue.json";
import rossumProgress from "../static/animations/json/rossumProgress.json";
import fireworks from "../static/animations/json/fireworks.json";
import bat from "../static/animations/json/bat.json";
// Note: busy indicators are not used as animations. They are plain SVGs animated with CSS
// because of performance. Check BusyIndicator component directly.
// Dark mode animations
import antDM from "../static/animations/json/dm/ant.json";
import squirrelDM from "../static/animations/json/dm/squirrel.json";
import spiderDM from "../static/animations/json/dm/spider.json";
import mouseDM from "../static/animations/json/dm/mouse.json";
import moleDM from "../static/animations/json/dm/mole.json";
import beaverDM from "../static/animations/json/dm/beaver.json";
import rossumProgressDM from "../static/animations/json/dm/rossumProgress.json";
import batDM from "../static/animations/json/dm/bat.json";


export enum AnimationSize {
    L = 400,
    M = 200,
    S = 100
}

export interface IAnimationProps extends Omit<IProps, "path" | "darkModePath"> {
}

export const ALL_ANIMATIONS_COMPONENTS: Record<string, React.ElementType> = {};

export const toAnimation = (path: unknown, dmPath: unknown, name: string): React.ComponentType<IAnimationProps> => {
    const animation = React.forwardRef((props: IAnimationProps, ref: any) => {
        return (
            <LottieWrapper ref={ref}
                           path={path as string}
                           darkModePath={dmPath as string}
                           {...props} />
        );
    });

    ALL_ANIMATIONS_COMPONENTS[name] = animation as React.ElementType;

    return animation;
};

export const AntAnim = toAnimation(ant, antDM, "Ant");
export const SquirrelAnim = toAnimation(squirrel, squirrelDM, "Squirrel");
export const SpiderAnim = toAnimation(spider, spiderDM, "Spider");
export const MouseAnim = toAnimation(mouse, mouseDM, "Mouse");
export const DogAnim = toAnimation(dog, dog, "Dog");
export const MoleAnim = toAnimation(mole, moleDM, "Mole");
export const BeaverAnim = toAnimation(beaver, beaverDM, "Beaver");
export const BearAnim = toAnimation(bear, bear, "Bear");
export const BikeAnim = toAnimation(bike, bike, "Bike");
export const QueueAnim = toAnimation(queue, queue, "Queue");
export const RossumProgressAnim = toAnimation(rossumProgress, rossumProgressDM, "RossumProgress");
export const FireworksAnim = toAnimation(fireworks, fireworks, "Fireworks");
export const BatAnim = toAnimation(bat, batDM, "Bat");