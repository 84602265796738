import React from "react";
import SliderBase, { SliderLabel, SliderMarks, SliderMarksLabels } from "./SliderBase";
import memoizeOne from "../../utils/memoizeOne";

export interface IProps {
    value: number;
    onChange?: (value: number) => void;

    min?: number;
    max?: number;
    step?: number;

    labelFormatter?: (value: number) => string;
    marksFormatter?: (markValue: number) => string;

    labelMode?: SliderLabel;
    marksMode?: SliderMarks;
    marksLabelsMode?: SliderMarksLabels;

    width?: string;
}
export default class Slider extends React.PureComponent<IProps> {
    thumbId = "value";

    static defaultProps = {
        min: 0,
        max: 100,
        step: 1,
        labelMode: SliderLabel.Hidden,
        marksMode: SliderMarks.Hidden,
        marksLabelsMode: SliderMarksLabels.Hidden,
        width: "100%"
    };

    handleThumbValueChange = (id: string, value: number) => {
        this.props.onChange?.(value);
    };

    getThumbs = memoizeOne((value: number) => {
        return [{
            id: this.thumbId,
            value
        }];
    });

    render() {
        return (
            <SliderBase from={0} to={this.props.value}
                        thumbs={this.getThumbs(this.props.value)}
                        onThumbValueChange={this.handleThumbValueChange}
                        min={this.props.min}
                        max={this.props.max}
                        step={this.props.step}
                        labelMode={this.props.labelMode}
                        marksMode={this.props.marksMode}
                        marksLabelsMode={this.props.marksLabelsMode}
                        width={this.props.width}/>
        );
    }
}
