import XmlViewer from "@components/fileViewers/xmlViewer/XmlViewer";
import { TFunction } from "i18next";
import React from "react";

import { MimeType, ToolbarItemType } from "../../enums";
import {
    fileNameToMime,
    isFileImage,
    isFileIsDoc,
    isFilePdf,
    isFilePlainText,
    isFileXml
} from "../fileUploader/File.utils";
import { TToolbarItem } from "../toolbar";
import ImgViewer from "./imgViewer/ImgViewer";
import NoPreview from "./NoPreview";
import PDFViewer from "./pdfViewer/PDFViewer";
import TextViewer from "./textViewer/TextViewer";

export interface IFileViewerProps {
    src: string;
    fileName?: string;
    isReadOnly?: boolean;
    hiddenItems?: FileToolbarItem[];
    isRemoveDisabled?: boolean;
    onFileRemove?: (src: string) => void;
    onGoToList?: () => void;
    customFileActions?: TToolbarItem[];
    onCustomFileAction?: (actionId: string) => void;
}

const getVisibleItem = (item: TToolbarItem, isVisible: boolean) => {
    return isVisible ? [item] : [];
};

export enum FileToolbarItem {
    Dashboard = "Dashboard",
    PreviousPage = "PreviousPage",
    NextPage = "NextPage",
    PageCount = "PageCount",
    PointerType = "PointerType",
    ZoomIn = "ZoomIn",
    ZoomOut = "ZoomOut",
    Zoom = "Zoom",
    Fit = "Fit",
    Note = "Note",
    Download = "Download",
    Remove = "Remove",
    Print = "Print"
}

export const getSharedToolbarDefinition = (isReadOnly: boolean, hiddenItems: FileToolbarItem[], isRemoveDisabled: boolean, t: TFunction): {
    staticItems: TToolbarItem[],
    items: TToolbarItem[]
} => {
    return {
        staticItems: [
            ...getVisibleItem({
                id: FileToolbarItem.Dashboard,
                label: t("Components:Pdf.BackToList"),
                itemType: ToolbarItemType.Icon,
                iconName: "Arrow"
            }, !hiddenItems.includes(FileToolbarItem.Dashboard))
        ],
        items: [
            ...getVisibleItem({
                id: FileToolbarItem.Download,
                label: t("Components:FileUploader.Download"),
                itemType: ToolbarItemType.Icon,
                iconName: "Download"
            }, !hiddenItems.includes(FileToolbarItem.Download)),
            ...getVisibleItem({
                id: FileToolbarItem.Remove,
                label: t("Components:FileUploader.Delete"),
                itemType: ToolbarItemType.Icon,
                iconName: "Bin",
                isDisabled: isRemoveDisabled
            }, !isReadOnly && !hiddenItems.includes(FileToolbarItem.Remove))
        ]
    };
};

export const getFileNameFromSrc = (src: string): string => {
    return src.split("/").slice(-1)[0];
};

export function isFileOpenable(name: string): boolean {
    const mimeType = fileNameToMime(name);
    return isFilePdf(mimeType) || isFileImage(mimeType) || isFilePlainText(mimeType) /*|| isFileIsDoc(name) || isFileXml(mimeType)*/;
}

export function getFileViewer(mime: MimeType, props: IFileViewerProps): React.ReactElement {
    let viewer;

    if (isFilePdf(mime)) {
        viewer = <PDFViewer {...props} />;
    } else if (isFilePlainText(mime)) {
        viewer = <TextViewer {...props}/>;
    } else if (isFileImage(mime)) {
        viewer = <ImgViewer {...props}/>;
    } else if (isFileIsDoc(props.fileName) || isFileXml(mime)) {
        viewer = <XmlViewer {...props}/>;

    } else {
        // cannot be opened - illustration and possibility to download
        viewer = <NoPreview isEmpty={!props.src} {...props}/>;
    }

    return viewer;
}
