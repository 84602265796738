import styled from "styled-components/macro";
import { ellipsis } from "polished";

export const CustomCellContentWrapper = styled.div`
  position: relative;
  white-space: nowrap;
`;

export const CustomCellOriginalContent = styled.div`
  ${ellipsis()};
`;

export const CustomCellCustomContent = styled.div`
  position: absolute;
  top: 0;
  left: calc(100% + 32px);
`;