import React, { useCallback, useEffect, useRef } from 'react';
import {
    ErrorMessage,
    FramePadding,
    LoginFrame,
    LoginFrameBody,
    LoginPageStyled,
    LoginPageWrapper
} from "./LoginPage.styles";
import TestIds from "../testIds";
import Animation from "./components/Animation";
import { checkSubdomainAndLoadSession, selectFirstSessionLoaded } from "./state/sessionSlice";
import { useLoginDispatch, useLoginSelector } from "./state/hooks";
import CustomResizeObserver from "../components/customResizeObserver";
import { LOGIN_PAGE_BOX_MIN_MARGIN, RequestStatus } from "./Login.utils";
import { clearRequestState, selectError, selectRequestStatus, selectValidationMessages } from "./state/loginSlice";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { scrollIntoViewIfNeeded } from "@utils/domUtils";

interface IProps {
}

const LoginPage: React.FC<IProps> = (props) => {

    const { t } = useTranslation("Login");
    const dispatch = useLoginDispatch();

    const formRef = useRef<HTMLFormElement>();
    const pageRef = useRef<HTMLDivElement>();
    const errorRef = useRef<HTMLDivElement>();

    const error = useLoginSelector(selectError);
    const validationMessages = useLoginSelector(selectValidationMessages);
    const status = useLoginSelector(selectRequestStatus);
    const isSessionLoadedForTheFirstTime = useLoginSelector(selectFirstSessionLoaded);
    const location = useLocation();

    const showError = !!error || (status === RequestStatus.Error && !validationMessages);

    const handleResize = useCallback(() => {
        // set form class according to if the form fits to page or not
        const windowHeight = window.innerHeight;
        const formHeight = formRef.current.offsetHeight + 2 * LOGIN_PAGE_BOX_MIN_MARGIN;
        const fits = formHeight <= windowHeight;
        formRef.current.classList[fits ? "add" : "remove"]("fit-height");
    }, []);

    useEffect(() => {
        // clear error on location change so it won't persist between screens
        // todo: refactor error handling, it should be most likely done in different way
        dispatch(clearRequestState());
    }, [location, dispatch]);

    useEffect(() => {
        dispatch(checkSubdomainAndLoadSession());
    }, [dispatch]);

    useEffect(() => {
        // scroll to top when error occurred, so it is visible
        if (pageRef.current && formRef.current) {
            scrollIntoViewIfNeeded(pageRef.current, formRef.current, { behavior: "smooth" });
        }
    }, [error]);

    if (!isSessionLoadedForTheFirstTime) {
        return null;
    }

    return (
            <LoginPageWrapper>
                <LoginPageStyled data-testid={TestIds.LoginPage} ref={pageRef}>
                    <Animation/>
                    <LoginFrame ref={formRef}>
                        {showError &&
                                <ErrorMessage ref={errorRef}><span>{error ?? t("Common.Error")}</span></ErrorMessage>}
                        <FramePadding></FramePadding>
                        <LoginFrameBody>
                            {props.children}
                        </LoginFrameBody>
                        <FramePadding></FramePadding>
                        <CustomResizeObserver onResize={handleResize} includeWindowResize/>
                    </LoginFrame>
                </LoginPageStyled>
            </LoginPageWrapper>
    );
};

export default LoginPage;
