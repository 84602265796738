import { StyledButtonGroup } from "@components/button";
import styled from "styled-components/macro";

import { T_PLAIN_small, T_PLAIN_small_hig, T_TITLE_2, T_TITLE_3_normal } from "../../../global.style";

export const UserNameStyled = styled.span<{ isBold: boolean; isBig?: boolean; }>`
    margin-left: ${props => props.isBig ? 12 : 8}px;
    ${props => props.isBig ? T_TITLE_2 : props.isBold ? T_PLAIN_small_hig : T_PLAIN_small};
`;

export const PermissionsOverviewGrid = styled.div`
    display: flex;
    flex-wrap: wrap;

    & > div {
        display: flex;
        width: 250px;
        align-items: flex-start;
        margin: 0 20px 20px 0;

        & > span {
            ${T_TITLE_3_normal};
            font-size: 16px;
        }

        & > svg {
            margin-right: 10px;
        }
    }
`;

export const PermissionName = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

export const AgendasSpan = styled.div`
    color: ${props => props.theme.C_ACT_main};
    cursor: pointer;
`;

export const CompanyRolesListStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    & > ${StyledButtonGroup} {
        margin: 20px 0 34px 0;
    }
`;

export const CompanyNameWithAvatar = styled.div`
    text-transform: uppercase;
    display: flex;
    align-items: center;
`;