import React from "react";
import { TableStorage } from "../../../model/TableStorage";

/** Base class for SmartTables that uses custom setTableState that propagates data into storage */
class SmartTableStateBase<P extends {storage?: TableStorage}, S> extends React.PureComponent<P, S> {
    _isMounted = false;
    // only used if storage is not passed
    _tableState: S;

    componentWillUnmount() {
        this._isMounted = false;
    }

    getTableState = (): S => {
        return this.state;
        // return this.props.storage ? this.props.storage.data.table : this._tableState;
    };

    // keeping the method in case we ever want to use it
    // interface taken from react setState - we don't want to loose type checking on the setTableState function
    setTableState = <K extends keyof S>(
        state: ((prevState: Readonly<S>, props: Readonly<P>) => (Pick<S, K> | S | null)) | (Pick<S, K> | S | null),
        callback?: () => void
    ) => {
        const oldState = this.getTableState();
        let newState;

        if (typeof state === "function") {
            // for some reason ts complains about calling state even with the state is function check
            // @ts-ignore
            newState = {...oldState, ...state(oldState, this.props)};
        } else {
            newState = {...oldState, ...state};
        }

        if (this.props.storage) {
            // @ts-ignore
            this.props.storage.data.table = newState;
        } else {
            this._tableState = newState;
        }

        if (this._isMounted) {
            this.forceUpdate();
        }

        callback?.();
    };
}

export default SmartTableStateBase;