import styled from "styled-components/macro";
import {
    getDisabledStyle,
    multiline_ellipsis,
    T_BTN_main,
    T_PLAIN_small,
    T_TITLE_4_NORMAL,
    Title
} from "../../../global.style";
import { ellipsis } from "polished";
import {Button, IconButton, IconWrapper, StyledIconButton, TextWrapper} from "../../button";

export const CompanySelectorWrapper = styled.div<{ isDisabled?: boolean; }>`
    height: 110px;
    padding-top: 38px;
    padding-right: 20px;
    min-width: 230px;
    max-width: 300px;

    ${props => getDisabledStyle(props.isDisabled)};
`;

export const CustomerCompanySelectorWrapper = styled.div`
    display: flex;
    align-items: center;
    // align with other items in header
    margin-top: 4px;

    ${Title} {
        margin: 0 2px 0 8px;
    }
`;

export const Container = styled.div<{
    isInactiveCompany: boolean;
}>`
    box-sizing: border-box;
    width: 100%;
    height: 60px;
    border-radius: 3px;
    border-style: solid;
    border-width: 3px;
    border-image-source: ${props => props.isInactiveCompany ? props.theme.C_MAIN_brand_gradient_inactive_company : props.theme.C_MAIN_brand_gradient};
    border-image-slice: 1;
    display: flex;
`;

export const Hover = styled.div`
    background-color: ${props => props.theme.C_ACT_hover_without_opacity};
    position: absolute;
    top: 5px;
    height: calc(100% - 10px);
    width: 2px;
    left: 54px;
    display: none;
`;

export const InactiveText = styled.div`
    ${T_TITLE_4_NORMAL};
    text-transform: uppercase;
`;

export const LeftPart = styled.div`
    display: flex;
    flex: 1 1 auto;
    cursor: pointer;
    min-width: 0; // fix text-overflow problem
    align-items: center;

    &:hover ${Hover} {
        display: block;
    }
    
    & + ${StyledIconButton}:focus-visible:before {
        // button is stretched, for better user experience, but keyboard focus dotted border should still be circle
        // https://solitea-cz.atlassian.net/browse/DEV-20313
        top: 15px;
        left: 4px;
        bottom: 15px;
        right: 4px;
    } 
`;

export const Background = styled.div`
    background: ${props => props.theme.C_BG_menu};
    display: flex;
    width: 100%;
    padding: 5px 0 5px 5px;
    align-items: center;
    position: relative;
`;

export const Text = styled.div`
    max-height: 100%;
    margin-left: 12px;
    display: flex;
    flex-direction: column;
    min-width: 0;
    flex: 1 1 auto;
`;

export const CompanyTitle = styled.div`
    ${T_BTN_main};
    max-height: 100%;
    ${multiline_ellipsis("100%", 2)}
`;

export const CompanyNumber = styled.div`
    ${T_PLAIN_small};
    ${ellipsis()};
    display: block;
`;

export const CreateCompanyButton = styled(Button)`
    display: flex;
    justify-content: stretch;
    align-items: center;
    padding: 0;
    height: 44px;
    min-width: 100%;
    
    &:hover + ${Hover} {
        display: block;
    }
    
    ${IconWrapper} {
        display: block;
        width: 44px;
        height: 44px;
        background-color: ${props => props.theme.C_GREY_x};
        border-radius: 3px;

        position: relative;
        top: 0;
        left: 0;
        transform: none;

        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    ${TextWrapper} {
        flex: 1 1 auto;
        text-align: center;
        margin: 0;
    }
`;

export const IconButtonStyled = styled(IconButton) `
    padding: 16px 5px;
`;
