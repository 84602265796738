import { LabelStatus } from "../../enums";
import Dialog, { IDialogProps } from "../dialog/Dialog";
import { Button } from "../button";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { EntitySetName, IFileMetadataEntity } from "@odata/GeneratedEntityTypes";
import SmartField from "../smart/smartField";
import { Model } from "../../model/Model";
import { createBindingContext, IEntity } from "@odata/BindingContext";
import { ODataContext } from "@odata/ODataProvider";
import { fileSizeFormatter } from "./File.utils";
import { IFieldDef } from "../smart/FieldInfo";
import Input from "../inputs/input/Input";
import Field from "../inputs/field";
import { EMPTY_DASH } from "../readOnlyList/ReadOnlyList";

export interface IFileInfoDialogDataProps extends IFileMetadataEntity, IEntity {
}

interface IProps extends Pick<IDialogProps, "onClose"> {
    file: IFileInfoDialogDataProps;
    additionalColumns?: IFieldDef[];
}


const FileInfoDialog: React.FC<IProps> = (props) => {
    const fieldProps = {
        isReadOnly: true,
        labelStatus: LabelStatus.Visible
    };

    const { t } = useTranslation(["Components", "Common"]);
    const oData = useContext(ODataContext);

    const bindingContext = createBindingContext(EntitySetName.FilesMetadata, oData.metadata);
    const storage = new Model({
        id: "FileInfoDialogStorage",
        bindingContext
    });

    const { file, additionalColumns, onClose } = props;

    storage.data.entity = file;

    return (
        <Dialog onClose={onClose}
                onConfirm={null}
                title={t("Components:FileUploader.Info")}
                footer={(
                    <Button onClick={onClose}>
                        {t("Common:General.Close")}
                    </Button>
                )}
        >
            <SmartField storage={storage} fieldProps={fieldProps}
                        bindingContext={storage.data.bindingContext.navigate("Name")}/>
            <SmartField storage={storage} fieldProps={{ ...fieldProps, formatter: fileSizeFormatter }}
                        bindingContext={storage.data.bindingContext.navigate("Size")}/>
            <SmartField storage={storage} fieldProps={fieldProps}
                        bindingContext={storage.data.bindingContext.navigate("DateCreated")}/>
            <SmartField storage={storage} fieldProps={fieldProps}
                        bindingContext={storage.data.bindingContext.navigate("DateLastModified")}/>
            {additionalColumns?.map(column => (
                <Field name={column.id} label={column.label} isReadOnly>
                    <Input value={file[column.id] ?? EMPTY_DASH} isReadOnly/>
                </Field>
            ))}
        </Dialog>
    );
};

export default FileInfoDialog;
