import styled, { css } from "styled-components/macro";
import WriteLine from "../inputs/writeLine";
import { multiline_ellipsis, T_BREAD, T_TITLE_2, T_TITLE_4, T_TITLE_4_NORMAL } from "../../global.style";
import { Line, ValueInput } from "../inputs/writeLine/WriteLine.style";
import { ellipsis } from "polished";
import { ColoredIcon } from "../icon";
import { Link } from "react-router-dom";
import { AnimationSize } from "../../animations/Animations";

export const WriteLineStyled = styled(WriteLine)`
    font-size: 30px;

    ${props => css`
        color: ${props.theme.C_TEXT_dialogue};

        ${ValueInput} {
            color: ${props.theme.C_TEXT_dialogue};

            &::placeholder {
                color: ${props.theme.C_ACT_thick_line};
            }
        }

        ${Line} {
            background-color: ${props.theme.C_TEXT_dialogue};
        }
    `};
`;

export const SearchHint = styled.div`
    text-align: right;
    color: ${props => props.theme.C_TEXT_dialogue};
    ${T_BREAD};
    margin-top: 7px;
`;

export const ColoredIconStyled = styled(ColoredIcon)`
    position: absolute;
    right: 7px;
    top: 50%;
    transform-origin: center center;
    transform: translate(0, -50%) rotate(180deg);
    visibility: hidden;
`;

export const SearchResultStyled = styled(Link)`
    display: block;
    position: relative;
    padding: 12px 45px 12px 12px;
    color: ${props => props.theme.C_TEXT_dialogue};
    margin-bottom: 4px;
    cursor: pointer;

    b {
        font-weight: 800;
    }

    &:hover {
        background-color: ${props => props.theme.C_ACT_hover};

        ${ColoredIconStyled} {
            visibility: visible;
        }
    }
`;

export const Header = styled.span`
    display: block;
    ${ellipsis()};
`;

export const Title = styled.span`
    ${T_TITLE_4_NORMAL};
`;

export const SubTitle = styled.span`
    ${T_TITLE_4_NORMAL};
    font-weight: 300;
    margin-left: 8px;
`;

export const Description = styled.span`
    display: block;
    ${T_TITLE_4_NORMAL};
    font-weight: 300;

    ${multiline_ellipsis("100%", 2)}
`;

export const GroupHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: stretch;
    margin: 15px 0;
    color: ${props => props.theme.C_TEXT_dialogue};
`;

export const GroupTitle = styled.div`
    flex: 0 0 auto;
    ${T_TITLE_4};
    text-transform: uppercase;
`;

export const ResultCount = styled.span`
    ${T_BREAD};
    margin-left: .5em;
`;

export const HeaderLine = styled.div`
    flex: 1 1 auto;
    width: 0;
    height: 1px;
    background-color: ${props => props.theme.C_ACT_thick_line};
    margin: 0 5px 0 3px;
`;

const NoResultsTextMinSize = "50px";
export const NoResultsText = styled.div`
    position: relative;
    ${T_TITLE_2};
    text-align: center;
    color: ${props => props.theme.C_TEXT_dialogue};
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    min-height: ${NoResultsTextMinSize};
    align-items: center;
    justify-content: space-between;
    padding: 0 85px;
    margin-bottom: 85px;

    span {
        display: flex;
        flex: 1 0 auto;
        min-height: ${NoResultsTextMinSize};
        max-height: 50%;
        align-items: flex-end;
    }

    div {
        width: ${AnimationSize.L}px;
        height: ${AnimationSize.L}px;
        min-height: ${AnimationSize.L / 2}px;
        max-height: calc(100% - ${NoResultsTextMinSize}); // space for the no results text span
        max-width: 100%;
    }
`;

