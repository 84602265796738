import { IBaseAlertProps } from "@components/alert/Alert";
import { ISmartFieldChange } from "@components/smart/smartField/SmartField";
import BindingContext, { createBindingContext, IEntity } from "@odata/BindingContext";
import { ODataError } from "@odata/Data.types";
import { createEntity, updateEntity } from "@odata/Data.utils";
import { EntitySetName, IUserEntity, UserCompanyRoleEntity, UserEntity } from "@odata/GeneratedEntityTypes";
import { isBatchResultOk } from "@odata/OData";
import React from "react";

import { withPermissionContext } from "../../contexts/permissionContext/withPermissionContext";
import { Status } from "../../enums";
import { getAlertFromError } from "../../views/formView/Form.utils";
import { IFormStorageSaveResult } from "../../views/formView/FormStorage";
import { FormViewForExtend, IFormViewProps } from "../../views/formView/FormView";
import { CustomerCompanyRoleId, CustomerGeneralRoleId } from "../admin/users/Users.utils";
import { NewCustomerFormStyled } from "./CompanyWizard.styles";
import { NewCustomersSwitchPath } from "./NewCustomers.def";

interface INewCustomersFormViewProps extends IFormViewProps<IEntity> {

}

class NewCustomersFormView extends FormViewForExtend<IEntity, INewCustomersFormViewProps> {

    async onAfterLoad(): Promise<void> {
        const storage = this.props.storage;
        const contact = storage.context.getCompany()?.CommunicationContact;

        const usersBc = storage.data.bindingContext.navigate("Users");
        storage.setValue(usersBc, [{
            [BindingContext.NEW_ENTITY_ID_PROP]: 0,
            [BindingContext.localContext("FirstName")]: contact?.FirstName,
            [BindingContext.localContext("LastName")]: contact?.LastName,
            [BindingContext.localContext("Email")]: contact?.Email
        }]);
        return super.onAfterLoad();
    }

    handleSwitchChange = (e: ISmartFieldChange) => {
        if (e.bindingContext.getPath() === NewCustomersSwitchPath) {
            this.forceUpdate();
        }
    }

    handleChange(e: ISmartFieldChange) {
        super.handleChange(e);
        this.handleSwitchChange(e);
    }

    renderForm(): React.ReactElement {
        return <NewCustomerFormStyled>
            {super.renderForm()}
        </NewCustomerFormStyled>;
    }

    async save(): Promise<IFormStorageSaveResult> {
        const storage = this.props.storage;
        if (storage.getValueByPath(NewCustomersSwitchPath)) {
            const batch = storage.oData.batch();
            const usersBc = storage.data.bindingContext.navigate("Users");
            const users = storage.getValue(usersBc);

            const existingUsersRes = await storage.oData.getEntitySetWrapper(EntitySetName.Users)
                    .query()
                    .filter(`GeneralRoles/any(x: x/GeneralRole/Id eq ${CustomerGeneralRoleId}) and ${UserEntity.Email} in (${users.map((user: IEntity) => `'${user[BindingContext.localContext("Email")]}'`).join(",")})`)
                    .select(UserEntity.Id, UserEntity.Email)
                    .expand(UserEntity.CompanyRoles, q => q.expand(UserCompanyRoleEntity.CompanyRole).expand(UserCompanyRoleEntity.Company))
                    .fetchData<IUserEntity[]>();
            const existingUsers = existingUsersRes.value ?? [];

            for (const user of users) {
                const email = user[BindingContext.localContext("Email")];
                const existingUser = existingUsers.find(u => u.Email === email);

                const newRole = {
                    CompanyRole: {
                        Id: CustomerCompanyRoleId
                    },
                    Company: {
                        Id: storage.context.getCompanyId()
                    }
                };

                if (existingUser) {
                    if (existingUser.CompanyRoles.some(cr => cr.CompanyRole.Id === CustomerCompanyRoleId && cr.Company.Id === storage.context.getCompanyId())) {
                        continue;
                    }
                    const userEntity = {
                        Id: existingUser.Id,
                        CompanyRoles: [
                            newRole
                        ],
                    };

                    updateEntity({
                        entity: userEntity,
                        changesOnly: true,
                        bindingContext: createBindingContext(EntitySetName.Users, storage.oData.metadata).addKey(existingUser.Id),
                        batch
                    });
                } else {
                    const userEntity: IUserEntity = {
                        FirstName: user[BindingContext.localContext("FirstName")],
                        LastName: user[BindingContext.localContext("LastName")],
                        Email: email,
                        CompanyRoles: [newRole],
                        GeneralRoles: [{
                            GeneralRole: {
                                Id: CustomerGeneralRoleId
                            }
                        }]
                    };

                    createEntity({
                        entity: userEntity,
                        bindingContext: createBindingContext(EntitySetName.Users, storage.oData.metadata),
                        batch
                    });
                }
            }

            if (!batch.isEmpty()) {
                const batchResponse = await batch.execute();

                let error = null;
                for (const res of batchResponse) {
                    if (!isBatchResultOk(res)) {
                        error = res.body as ODataError;
                        break;
                    }
                }

                if (error) {
                    let alert: IBaseAlertProps;
                    if (error._validationMessages[0].code === "CannotInsertDuplicateRecord") {
                        alert = {
                            status: Status.Error,
                            title: this.props.storage.t("Companies:Wizard.DuplicateUserErrorTitle"),
                            subTitle: this.props.storage.t("Companies:Wizard.DuplicateUserErrorSubTitle")
                        };
                    } else {
                        alert = getAlertFromError(error);
                    }
                    storage.setFormAlert(alert);
                    this.props.onSaveFail?.();
                    this.forceUpdate(this.scrollPageUp);
                    return null;
                }
            }

        }

        return {
            data: storage.data,
            bindingContext: storage.data.bindingContext,
        };
    }
}

export default withPermissionContext(NewCustomersFormView);