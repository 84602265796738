import { IEntityLockEntity } from "@odata/GeneratedEntityTypes";
import { LockTypeCode } from "@odata/GeneratedEnums";
import { IPrepareQuerySettings, prepareQuery } from "@odata/OData.utils";
import { WithOData, withOData } from "@odata/withOData";
import { isDefined } from "@utils/general";
import { logger } from "@utils/log";
import { isAbortException } from "@utils/oneFetch";
import { canUnlock } from "@utils/permissionUtils";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import {
    WithPermissionContext,
    withPermissionContext
} from "../../../contexts/permissionContext/withPermissionContext";
import { GroupStatus, RowAction, RowType, Sort, TableBatch } from "../../../enums";
import BindingContext, { IEntity } from "../../../odata/BindingContext";
import LocalSettings from "../../../utils/LocalSettings";
import { AlertPosition } from "../../alert/Alert";
import { WithAlert, withAlert } from "../../alert/withAlert";
import { IProps as IIconProps } from "../../icon";
import { IRow, IRowAction, IRowAddEvent, ISort, ITableProps, TId } from "../../table";
import { IRowProps } from "../../table/Rows";
import { isRowSelected } from "../../table/TableUtils";
import { WithContextMenuProps } from "../../table/withContextMenu";
import { IFieldDef } from "../FieldInfo";
import {
    defaultODataTableProps,
    defaultODataTableState,
    ISmartODataTableProps,
    ISmartODataTableState,
    LOADING_ROW_ID,
    SmartODataTableBase
} from "./SmartODataTableBase";
import { getLoadingValues, getRow, getRowsArrayFromRows, getTableKey } from "./SmartTable.utils";
import { ISmartLoadMoreItemsEvent } from "./SmartTableBase";

export interface IFetchDataArgs {
    skip?: number;
    top?: number;
    loadAll?: boolean;
}

interface ICreateRowProperties {
    row: IEntity;
    level?: number;
    navigationPath?: string;
    parentId?: BindingContext;
    parent?: IRow;
}

export interface ISmartTableCommonProps extends Pick<ITableProps, "isList" | "contentBefore" | "onDragStart" | "tableWrapperRef" | "isForPrint" | "customNewRowLabel" | "addingRow" | "addingRowParent"> {
    tableId: string;
    hideDrilldown?: boolean;
    drilldown?: React.ReactElement | ((row: IRow) => React.ReactElement);
    disableSort?: boolean;
    initialSortBy?: ISort[];
    onAddingRowCancel?: () => void;
    onRowAdd?: (args: IRowAddEvent) => void;
    disableVirtualization?: boolean;
    onAfterTableLoad?: () => void;
    onToolbarRefreshNeeded?: () => void;
    passRef?: React.Ref<HTMLDivElement>;
    onBeforeFetch?: () => Promise<void>;
    /** row state icon in front of the row (in front if action if both are active at same time) */
    getRowIcon?: (id: TId, row: IRowProps, rowAction: IRowAction) => React.ComponentType<IIconProps>;
}

export interface IProps extends ISmartODataTableProps {
    groupBy?: string;
    groupedRows?: IRow[];

    querySettings?: IPrepareQuerySettings;
}

interface IState extends ISmartODataTableState {

}

class SmartTable extends SmartODataTableBase<IProps & WithTranslation & WithOData & WithAlert & WithContextMenuProps & WithPermissionContext, IState> {
    static defaultProps = defaultODataTableProps;

    state = {
        ...defaultODataTableState,
        allGroupStatus: GroupStatus.Collapsed
    };

    // isOnlyPartialDataFetch means that the table has already been loaded, but we need to load some missing rows (caused by handleLoadMoreRows)
    async fetchData(args?: IFetchDataArgs): Promise<void> {
        // sometimes, asynchronicity causes that fetchData is called at the moment when state is reset to defaultState
        // => no columns are available and this fetchData call no longer make sense
        // + Do not trigger request with invalid filterQuery
        if (this.props.filter?.isInvalid || this.getTableState().columns.length === 0 || !this._isMounted) {
            return;
        }

        // if table gets reloaded and some action is opened
        // old rowsBackup could have wrong rows and should be removed
        if (!this.props.keepActiveRowsOnFilterChange) {
            this.rowsBackup = null;
        }

        // firstTime => either it is the first rendering, or filter or sort has changed,
        // it means fetchData was not called by handleLoadMoreRows
        const firstTime = !args;
        const skip = args?.skip ?? 0;
        const top = args?.top ?? TableBatch.DefaultBatchSize;

        const sortColumns = this.getSort();
        const settings: IPrepareQuerySettings = {};

        if (sortColumns) {
            const sort: ISort[] = [];

            for (const sortColumn of sortColumns) {
                const sortColumnInfo = this.getTableState().columns.find((col) => col.id === sortColumn.id);

                if (!sortColumnInfo) {
                    logger.error(`SmartTable: sort is set to non existing column. Probably caused by two table definitions with same tableId: ${this.props.tableId} or changed table customization. Or perform db migration.`);
                } else {
                    let sortId: string;

                    if (!sortColumnInfo.bindingContext.isCollection()) {
                        sortId = sortColumnInfo.bindingContext.getNavigationBindingContext(sortColumnInfo.fieldSettings?.displayName).getEntityPath();
                    } else {
                        // allow sorting for collections over its length.
                        // either disable it for the column if not wanted,
                        // or refactor the code to support something other than collection length as well
                        sortId = `${sortColumnInfo.bindingContext.getNavigationPath()}/$count`;
                    }

                    sort.push({
                        id: sortId,
                        sort: sortColumn.sort
                    });
                }
            }

            if (sort.length > 0) {
                // DEV-22366 always add ID as last sort param, if exists
                if (this.props.bindingContext.getEntityType().getProperty("Id")) {
                    sort.push({ id: "Id", sort: Sort.Desc });
                }
                // orderBy is always done on the root level, not in the expands
                settings[""] = { // "" for top level settings
                    sort
                };
            }
        }

        if (this.props.filter?.collectionQueries) {
            for (const key of Object.keys(this.props.filter.collectionQueries)) {
                settings[key] = {
                    filter: this.props.filter.collectionQueries[key].query
                };
            }
        }

        if (this.props.querySettings) {
            for (const [key, setts] of Object.entries(this.props.querySettings)) {
                if (settings[key]) {
                    settings[key] = {
                        ...settings[key],
                        ...setts
                    };
                } else {
                    settings[key] = setts;
                }
            }
        }

        const fieldDefs: IFieldDef[] = [...this.getTableState().columns];

        if (this.props.bindingContext.getEntityType().getProperty(this.props.lockProperty)) {
            const additionalProperties = [{ id: "CreatedBy/Id" }];

            // not all lock entities has Type property (only document)
            if (this.props.bindingContext.navigate(this.props.lockProperty).getEntityType().getProperty("Type")) {
                additionalProperties.push({ id: "Type" });
            }

            fieldDefs.push({
                id: this.props.lockProperty,
                additionalProperties
            });
        }

        let query = prepareQuery({
            oData: this.props.oData,
            bindingContext: this.props.bindingContext,
            fieldDefs: [...fieldDefs, ...(this.props.additionalProperties ?? [])],
            settings
        });

        if (!this.props.loadAll && !args?.loadAll) {
            query = query.skip(skip).top(top);
        }

        if (firstTime) {
            query.count();
        }

        if (this.props.filter?.query) {
            let filterQuery = this.props.filter.query;

            if (this.props.keepActiveRowsOnFilterChange) {
                let activeRowIds: string[];
                if (this.state.loaded && this.state.activeRows?.size) {
                    activeRowIds = Array.from(this.state.activeRows).map(rowId => {
                        const row = getRow(this.getTableState().rows, rowId);
                        return (row?.id as BindingContext)?.getKey().toString();
                    });
                } else if (this.props.initialActiveRows?.length) {
                    activeRowIds = this.props.initialActiveRows.map(bc => bc.getKey().toString());
                }
                if (activeRowIds?.length) {
                    // keep active rows in the query automatically, so they are visible
                    filterQuery = `(${filterQuery}) OR ${this.props.bindingContext.getKeyPropertyName()} in (${activeRowIds.filter(isDefined).join(",")})`;
                }
            }

            query.filter(filterQuery);
        }

        const origRows = this.getTableState().rows;
        const origRowsOrder = this.getTableState().rowsOrder;

        if (!firstTime) {
            // indicate that the item is being loaded so InfiniteLoader won't try to fetch it again
            // todo request error handling
            const newRows = {
                ...origRows
            };
            const newRowsOrder = [...origRowsOrder];

            for (let i = skip; i < skip + top; i++) {
                const id = `${LOADING_ROW_ID}-${i}`;

                newRows[id] = {
                    id,
                    type: RowType.Value,
                    values: getLoadingValues(this.getTableState().columns),
                    isLoading: true
                };
                newRowsOrder[i] = id;
            }

            // sets the currently fetched data to loading state
            this.setState({
                rows: newRows,
                rowsOrder: newRowsOrder
            });
        } else {
            this.setState({
                loaded: false
            });
        }

        let result: IEntity;

        try {
            result = await query.fetchData(firstTime ? this.fetchDataOneFetch.fetch : undefined);
        } catch (error) {
            // OneFetch -> raises AbortError exception -> ignore
            if (isAbortException(error)) {
                return;
            }
            // todo global error handling
            logger.error(`error in fetchData: ${query.toUrl("")}`, error);
            this.setState({
                rows: {},
                rowsOrder: [],
                rowCount: 0,
                loaded: true
            });
            return;
        }

        let undeletableRows = this.state.undeletableRows;
        const rows: Record<string, IRow> = {};
        let rowsArray: IRow[] = [];
        let rowsOrder: string[] = [];
        const fetchedRowsCount = result.value?.length ?? 0;
        if (args?.top && args.top !== fetchedRowsCount) {
            // we are requesting exact number of rows, but we got less
            //  -> it means there is some BE misconfiguration between count and actual rows.
            // log the error and stop processing the request. Loading row remains in the table
            // todo: it may occur also when user loads table, someone removes some rows in different window
            //  and then user loads more rows. Should we handle this case in different way?
            logger.error(`SmartTable: requested ${args.top} rows, but got only ${fetchedRowsCount}. [${this.props.bindingContext.toString()}]`);
            return;
        }

        for (const entity of (result.value as IEntity[])) {
            const newRow = this.createRowProperties({ row: entity });
            const id = newRow.id.toString();

            rows[id] = newRow;
            rowsOrder.push(id);
            // todo can we get rid of rowsArray completely here or not?
            rowsArray.push(newRow);

            // probably only for FiscalYears
            // - table of FiscalYears with their Periods as children rows,
            // => we need to set the children into rows object so that they can be found using standards functions like updateRows
            if (this.props.hierarchy && newRow.rows.length) {
                for (const childRow of newRow.rows) {
                    const childId = childRow.id.toString();

                    if (!rows[childId]) {
                        rows[childId] = childRow;
                    }
                }
            }
        }

        // we need to check if the newly loaded rows are deletable if Remove action is already turned on
        if (this.rowActionType === RowAction.Remove) {
            undeletableRows = { ...this.state.undeletableRows };

            await this.setUndeletableRows(rowsArray, undeletableRows);
        }

        if (this.props.groupedRows) {
            const openedRows = LocalSettings.get(getTableKey(this.props.storage, this.props.tableId)).openedRowsIds;
            // grouped rows live in definition, so we need to clear its rows on each fetch
            for (const group of this.props.groupedRows) {
                const groupId = group.id.toString();

                group.rows = [];
                group.open = openedRows?.[groupId];
                rows[groupId] = group;
            }

            for (const row of rowsArray) {
                const group = this.props.groupedRows.find(group => group.id === row.customData[this.props.groupBy]);
                if (isRowSelected(this.props.selectedRows, row.id)) {
                    group.open = true;
                }
                group?.rows.push(row);
            }
            rowsArray = this.props.groupedRows.filter(group => group.rows.length);
            rowsOrder = rowsArray.map(group => group.id.toString());
        }

        const allGroupStatus = this.getGroupToggleState(rowsArray, { ignoreCount: true, preventSaving: true });

        // callers of fetchData expects new state (rows) to be set in the date object when the function finishes
        // => await here before the new state is set and only after then resolve returned promise
        let resolveFn: () => void;
        const promise = new Promise<void>((resolve) => {
            resolveFn = resolve;
        });


        this.setState((state) => {
            const newState: IState = {
                loaded: true,
                allGroupStatus,
                undeletableRows
            };

            let newRows: Record<string, IRow>;
            let newRowsOrder: string[];
            if (args?.loadAll) {
                newRows = rows;
                newRowsOrder = rowsOrder;
            } else {
                newRows = firstTime ? rows : { ...state.rows, ...rows };
                newRowsOrder = firstTime ? [] : [...state.rowsOrder];


                for (let i = skip; i < skip + top; i++) {
                    if (!rowsOrder[i - skip]) {
                        // during first load we don't know how many rows there actually are
                        // there can be less than DefaultBatchSize
                        // => remove the "loading" rows from both rows and rowsOrder
                        delete newRows[newRowsOrder[i]];
                        delete newRowsOrder[i];
                        continue;
                    }
                    newRowsOrder[i] = rowsOrder[i - skip];
                }
            }

            // Comment out filter,
            // to allow null rows to prevent debounced handleLoadMoreRows from failing.
            // Otherwise, handleLoadMoreRows can get into loop.
            newState.rows = newRows;//.filter(row => row);
            newState.rowsOrder = newRowsOrder;

            if (this.props.groupedRows) {
                newState.rowCount = rowsOrder.length;
            } else {
                if (result._metadata && result._metadata.count !== undefined) {
                    newState.rowCount = result._metadata.count;
                }
            }

            if (state.activeRows?.size) {
                // change toggleAll state according to active rows
                newState.toggleState = this.getToggleState(getRowsArrayFromRows(newRows, newRowsOrder), state.activeRows, newState.rowCount);
            }

            return newState;
        }, () => {
            resolveFn();
            this.props.onAfterTableLoad?.();
            this.props.rowAction?.onTableReloaded?.();
        });

        return promise;
    }

    async reloadRow(bindingContext: BindingContext) {
        const columns = [...this.getTableState().columns];
        if (bindingContext.isNew()) {
            return;
        }
        return await this.reloadRowInternal(bindingContext, columns, (newRowData: IEntity, oldRow) => {
            const newRow = this.createRowProperties(({ row: newRowData }));
            return {
                ...oldRow,
                ...newRow
            };
        });
    }

    createRowProperties = ({ row, level = 0, navigationPath, parentId, parent }: ICreateRowProperties): IRow => {
        const rootContext = parentId ? parentId : this.props.bindingContext;
        const idBindingContext = navigationPath ? rootContext.navigate(navigationPath) : rootContext;
        const id = idBindingContext.addKey(row);

        // this is temporary check, only for Documents
        // should be removed after BE lock refactoring is done
        const locks: IEntityLockEntity[] = row[this.props.lockProperty];
        const isLocked = locks && locks.length > 0 && !!locks.find(lock => lock.Type?.Code === LockTypeCode.User);

        const newRow: IRow = {
            id: id,
            dataId: id.getKey().toString(),
            drilldown: this.props.drilldown ?? (!this.props.hideDrilldown && !parentId),
            isLocked,
            level,
            values: this.getRowValues(row, this.getTableState().columns, id),
            open: this.getTableState().allGroupStatus === GroupStatus.Expanded || LocalSettings.get(getTableKey(this.props.storage, this.props.tableId)).openedRowsIds?.[id.toString()],
            customData: {
                parent,
                entity: row,
                canUnlock: !!this.props.storage && canUnlock(this.props.storage, this.props.permissionContext.generalPermissions, row)
            }
        };

        if (this.props.groupBy) {
            newRow.customData[this.props.groupBy] = row[this.props.groupBy];
        }

        const rows = row[this.props.hierarchy]?.map((childRow: IEntity) => {
            return this.createRowProperties({
                row: childRow,
                level: level + 1,
                navigationPath: this.props.hierarchy,
                parentId: id,
                parent: newRow
            });
        }) ?? [];

        newRow.groupId = rows && rows.length > 0 ? id : parentId;
        newRow.type = RowType.Value;
        newRow.rowCount = rows.length;
        newRow.rows = rows;

        return newRow;
    };

    handleRowActionClick(rowId: TId, row: IRow) {
        super.handleRowActionClick(rowId, row, false);
    }

    handleLoadMoreRows = ({ startIndex, stopIndex }: ISmartLoadMoreItemsEvent) => {
        if (this.props.groupedRows) {
            return;
        }
        const skip = startIndex;
        const top = stopIndex - startIndex + 1;

        this.fetchData({ skip, top: top });
    };
}

const SmartTableExtended = withTranslation(["Error"])(withPermissionContext(withOData(withAlert({
    autoHide: true,
    position: AlertPosition.CenteredBottom
})(SmartTable))));

export { SmartTableExtended as SmartTable };