import React, { useEffect, useState } from "react";
import { Model } from "../../model/Model";
import { InboxCompanyEmailAddressStyled, TooltipIconCopyLinkStyled } from "./InboxCompanyEmailAddress.styles";
import { getSystemConfig } from "../../global.utils";


interface IProps {
    storage: Model;
}

const InboxCompanyEmailAddress: React.FC<IProps> = ({ storage }) => {
    const [email, setEmail] = useState<string>();

    useEffect(() => {
        getSystemConfig()
            .then((config) => {
                const domain = config.InboxEmailDomain;
                const inboxEmail = storage.context.getCompany().InboxEmail;
                setEmail(`${inboxEmail}@${domain}`);
            });
    });

    return (
        <InboxCompanyEmailAddressStyled>
            {email}
            <TooltipIconCopyLinkStyled link={email}/>
        </InboxCompanyEmailAddressStyled>
    );
};

export default InboxCompanyEmailAddress;