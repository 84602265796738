import { getSimpleBoolSelectItems, IGetValueArgs, TInfoValue } from "@components/smart/FieldInfo";
import {
    getCommonFilterDefs,
    getCommonTableColumnsDefs,
    withDisplayName
} from "@components/smart/GeneralFieldDefinition";
import {
    FilterBarGroup,
    getDefaultFilterGroupDef,
    IFilterGroupDef
} from "@components/smart/smartFilterBar/SmartFilterBar.types";
import { ISummaryItem } from "@components/smart/smartSummaryItem/SmartSummaryItem";
import { TCellValue } from "@components/table";
import { tableTokenFormatter } from "@components/token";
import { createPath, IEntity } from "@odata/BindingContext";
import {
    EntitySetName,
    EntityTypeName,
    ILabelHierarchyEntity,
    LabelEntity,
    LabelHierarchyEntity
} from "@odata/GeneratedEntityTypes";
import i18next from "i18next";

import { BasicInputSizes, FieldType, Sort, ValueType } from "../../enums";
import { TValue } from "../../global.types";
import { Model } from "../../model/Model";
import { IFormDef } from "../../views/formView/Form";
import { ISplitPageTableDef } from "../../views/table/TableView.utils";
import { activeFormatter } from "../chartOfAccounts/ChartOfAccountsDef";
import { setDefByEntityType } from "../getDefByEntityType";
import { getItemBreadCrumbsText, IDefinition, IGetDefinition } from "../PageUtils";
import { isActiveDef } from "./LabelsDef";
import LabelsHierarchyFormView from "./labelsHierarchyFormView";

export const labelColors = [
    "C_LAB_grey",
    "C_LAB_yellow",
    "C_LAB_green",
    "C_LAB_blue",
    "C_LAB_purple",
    "C_LAB_orange"
];

export const LabelHierarchyIsActivePath = "##IsActive##";
export const LabelHierarchyParentPath = "##Parent##";

export interface IExtendedLabelHierarchyEntity extends ILabelHierarchyEntity {
    [LabelHierarchyIsActivePath]: boolean;
    [LabelHierarchyParentPath]: number | string;
}

// new form is opened and parent is selected ==> creating new label, not hierarchy
const isCreatingLabel: TInfoValue<boolean> = (args: IGetValueArgs) => {
    const entity = args.storage.data.entity as IExtendedLabelHierarchyEntity;

    return args.bindingContext.getRootParent().isNew() && !!entity?.[LabelHierarchyParentPath];
};

const tokenFormatter = (val: TValue, values: IEntity): TCellValue => {
    const color = values.entity?.rootEntity?.Color ?? "";
    const title = val as string;

    return tableTokenFormatter(title, color);
};

export const getDefinitions: IGetDefinition = (): IDefinition => {
    const filterBarDef: IFilterGroupDef[] = [{
        ...getDefaultFilterGroupDef(FilterBarGroup.Filters),
        createQuery: false,
        allowCustomFilters: true,
        defaultFilters: [
            LabelHierarchyEntity.Name,
            createPath(LabelHierarchyEntity.Labels, LabelEntity.IsActive)
        ],
        filterDefinition: {
            Name: {
                // filter on both Name itself ("") and Labels/Name, which navigates from root entity "/"
                filterName: ["", "/Labels/Name"]
            },
            "Labels/IsActive": {
                type: FieldType.MultiSelect,
                valueType: ValueType.Boolean,
                description: false,
                fieldSettings: {
                    items: getSimpleBoolSelectItems()
                }
            },
            "Labels/Parent": {
                fieldSettings: {
                    displayName: "Name"
                }
                // isValueHelp: true
                // filterName: [""]
            },
            ...getCommonFilterDefs()
        }
    }];

    const table: ISplitPageTableDef = {
        id: `${EntityTypeName.LabelHierarchy}Table`,
        initialSortBy: [{
            id: LabelHierarchyEntity.Name,
            sort: Sort.Asc
        }],
        columns: [
            LabelHierarchyEntity.Name,
            LabelHierarchyIsActivePath
        ],
        columnDefinition: {
            Name: {
                isRequired: true,
                isDisabled: true,
                formatter: tokenFormatter,
                additionalProperties: [{ id: `/${LabelHierarchyEntity.Color}` }]
            },
            ...getCommonTableColumnsDefs(),
            // we need the column to have same id here and in childColumns,
            // but IsActive isn't in LabelHierarchy entity type, only in Label type => using local context instead
            [LabelHierarchyIsActivePath]: {
                label: i18next.t("Labels:IsActive"),
                disableSort: true
            }
        },
        childColumns: [
            // define Name again for childColumns, even though its already defined in columns/columnDefinition
            // label will be reused, because we cant have multiple labels for one column
            // but we don't want to have Color as additionalProperties for childColumns, because Color doesn't exist on Labels
            {
                id: LabelEntity.Name,
                formatter: tokenFormatter
            },
            {
                id: LabelHierarchyIsActivePath,
                additionalProperties: [
                    { id: `/${LabelEntity.IsActive}` }
                ],
                formatter: activeFormatter
            },
            { id: LabelEntity.DateLastModified },
            { id: LabelEntity.DateCreated },
            withDisplayName(LabelEntity.CreatedBy).CreatedBy,
            withDisplayName(LabelEntity.LastModifiedBy).LastModifiedBy
        ],
        title: i18next.t("Labels:Title"),
        filterBarDef
    };

    const auditTrailSummary: ISummaryItem[] = [{
        id: LabelHierarchyEntity.Name,
        label: i18next.t("Labels:LabelHierarchy")
    }];

    const summary: ISummaryItem[] = [];
    const form: IFormDef = {
        id: `${EntityTypeName.LabelHierarchy}Form`,
        summary,
        translationFiles: getDefinitions.translationFiles,
        formControl: LabelsHierarchyFormView,
        isDeletable: true,
        getItemBreadCrumbText: (storage: Model) =>
            getItemBreadCrumbsText(storage, i18next.t("Labels:New"),
                storage.data.entity?.Name && i18next.t("Labels:Breadcrumbs.Hierarchy", { name: storage.data.entity?.Name })),
        title: "Labels:Title",
        auditTrailSummary,
        fieldDefinition: {
            Name: {
                width: BasicInputSizes.XL
            },
            Color: {
                type: FieldType.ColorPicker,
                width: BasicInputSizes.XL,
                fieldSettings: {
                    colors: labelColors
                },
                isDisabled: isCreatingLabel
            },
            [LabelHierarchyIsActivePath]: {
                ...isActiveDef,
                label: i18next.t("Labels:Form.IsActive"),
                isVisible: isCreatingLabel,
                customizationData: {
                    useForCustomization: false
                }
            },
            [LabelHierarchyParentPath]: {
                label: i18next.t("Labels:Form.Parent"),
                width: BasicInputSizes.M,
                type: FieldType.HierarchyComboBox,
                isDisabled: (args: IGetValueArgs) => {
                    return !args.storage.data.bindingContext.isNew();
                },
                fieldSettings: {
                    displayName: LabelEntity.Name,
                    items: [],
                    noRecordText: i18next.t("Common:Select.NoRecord")
                },
                affectedFields: [{ id: LabelHierarchyIsActivePath }]
            }

        },
        groups: [{
            id: "mainGroup",
            rows: [
                [{ id: LabelHierarchyParentPath }, { id: LabelHierarchyEntity.Color }],
                [{ id: LabelHierarchyEntity.Name }, { id: LabelHierarchyIsActivePath }]
            ]
        }],
        additionalProperties: [
            // needed in onBeforeDelete check
            { id: createPath(LabelHierarchyEntity.Labels, LabelEntity.Name) }
        ]
    };

    return {
        entitySet: EntitySetName.LabelHierarchies,
        table,
        form
    };
};

getDefinitions.translationFiles = ["Common", "Labels"];
setDefByEntityType(EntityTypeName.LabelHierarchy, getDefinitions);