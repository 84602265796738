import React from "react";
import ReportView, { IReportViewBaseProps } from "./ReportView";
import { IReportDefinition, IReportTableDefinition } from "./Report.utils";
import { ISplitPageProps, SplitPageBase, TRenderTableArgs } from "../SplitPageBase";
import { ReportStorage } from "./ReportStorage";
import { composeRefHandlers } from "@utils/general";

export interface IReportSplitPageProps extends Omit<ISplitPageProps<IReportDefinition>, "renderTable"> {
    reportView?: React.ComponentType<IReportViewBaseProps>;
    onTableStorageLoad?: (storage: ReportStorage) => Promise<void>;
    reportViewProps?: Partial<IReportViewBaseProps>;
}

class ReportSplitPage extends React.PureComponent<IReportSplitPageProps> {
    renderTable = (args: TRenderTableArgs): React.ReactElement => {
        const CustomReportView = this.props.reportView ?? ReportView;
        return (
            <CustomReportView {...args}
                              {...this.props.reportViewProps}
                              ref={composeRefHandlers(args.ref, this.props.reportViewProps?.ref)}
                              storage={args.storage as ReportStorage}
                              rootStorage={args.rootStorage}
                              definition={(args.storage.data.definition as IReportTableDefinition)}
            />
        );
    };

    render() {
        return (
            <SplitPageBase {...this.props}
                           renderTable={this.renderTable}
                           customTableStorage={ReportStorage as any}
                           hasPreview/>
        );
    }
}

export { ReportSplitPage };