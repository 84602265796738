import { doesElementContainsElement } from "@utils/general";
import { delay } from "lodash";
import React, { useCallback, useRef, useState } from "react";

import TestIds from "../../testIds";
import DateType from "../../types/Date";
import TextExpandable from "../textExpandable/TextExpandable";
import { CenteredIcon, Header, MessageContentWrapper, MessageIconWrapper, StyledMessage } from "./Chat.styles";


export interface IChatMessageProps {
    icon: React.ReactNode;
    created?: Date;
    createdBy?: string;
    hasIconBorder?: boolean;
    hasDisabledIcon?: boolean;
    hasSolidBorder?: boolean;
    text?: string;
    wrapContent?: boolean;
    align?: 'bottom';
    testId?: string;
}


const Message: React.FC<IChatMessageProps> = (props) => {
    const {
        icon,
        hasIconBorder,
        hasDisabledIcon,
        hasSolidBorder,
        created,
        createdBy,
        wrapContent,
        align,
        text,
        testId
    } = props;
    const [isVisible, show] = useState<boolean>(false);
    const textRef = useRef();
    const timer = useRef<number>();

    const handleMouseEnter = useCallback((event: React.MouseEvent) => {
        timer.current = delay(show, 1000, true);
    }, [show]);

    const handleMouseLeave = useCallback((event: React.MouseEvent) => {
        if (!doesElementContainsElement(textRef.current, event.relatedTarget as Element)) {
            clearTimeout(timer.current);
            show(false);
        }
    }, [show]);

    let content = props.children ?? (
        <TextExpandable ref={textRef}
                        text={text}
                        maxLength={230}
                        data-testid={TestIds.Text}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}/>
    );
    
    if (wrapContent !== false) {
        content = (
            <MessageContentWrapper onMouseEnter={handleMouseEnter}
                                   onMouseLeave={handleMouseLeave}>
                {content}
            </MessageContentWrapper>
        );
    }

    return (
        <StyledMessage _hasBorder={hasSolidBorder} align={align} data-testid={testId ?? TestIds.Message}>
            <MessageIconWrapper _hasBorder={hasIconBorder} _isDisabled={hasDisabledIcon}>
                <CenteredIcon>{icon}</CenteredIcon>
            </MessageIconWrapper>
            {content}
            {(created || createdBy) &&
                <Header
                    isVisible={isVisible}>{DateType.localFormat(created, DateType.defaultDateTimeFormat)} ({createdBy})</Header>}
        </StyledMessage>
    );
};

export default Message;
