import React, { useCallback, useState } from "react";
import { InboxFileDetailStyled } from "./Inbox.styles";
import CustomResizeObserver from "../../components/customResizeObserver";

interface IProps {
    children: (width: number, height: number) => React.ReactElement;
}

interface ISize {
    width?: number;
    height?: number;
}

const InboxFileDetailLayout: React.FC<IProps> = ({ children }) => {
    const [size, setSize] = useState<ISize>({});

    const _recalculate = useCallback((width: number, height: number) => {
        width = Math.floor(width);
        height = Math.floor(height);
        if (width && height && (width !== size.width || height !== size.height)) {
            setSize({ width, height });
        }
    }, [size, setSize]);

    const setRef = useCallback((node: HTMLDivElement) => {
        if (node !== null) {
            const { width, height } = node.getBoundingClientRect();
            _recalculate(width, height);
        }
    }, [_recalculate]);

    const handleResize = useCallback((entries: readonly ResizeObserverEntry[]) => {
        const { width, height } = entries?.[0]?.contentRect ?? {};
        _recalculate(width, height);
    }, [_recalculate]);

    return (
        <InboxFileDetailStyled ref={setRef}>
            {children(size.width, size.height)}
            <CustomResizeObserver onResize={handleResize}/>
        </InboxFileDetailStyled>
    );
};

export default InboxFileDetailLayout;
