import React, { useState } from "react";

import { LoginFormStyled, Row } from "../LoginPage.styles";
import { IInputOnChangeEvent } from "@components/inputs/input";
import {
    EvalaPersonalDataProcessingLink,
    LoginTranslationNamespaces,
    validateEmail,
    validateFirstName,
    validateLastName,
    validatePassToString
} from "../Login.utils";
import PasswordWriteLine from "../../components/inputs/writeLine/PasswordWriteLine";
import { useTranslation } from "react-i18next";
import { ButtonSize } from "@components/button/Button.utils";
import { useLoginDispatch, useLoginSelector } from "../state/hooks";
import {
    IRegistrationData,
    register,
    resetPassword,
    selectLoginEmail,
    selectRegisterIsBusy,
    selectValidationMessages,
    selectVerificationCode,
    setLoginEmail,
    setValidationMessage
} from "../state/loginSlice";
import WriteLine from "../../components/inputs/writeLine";
import { selectInvitationCode, selectInvitationData } from "../state/invitationSlice";
import LoginField from "./LoginField";
import LoginButtonGroup from "./LoginButtonGroup";
import { Button } from "../../components/button";
import LoginCheckbox from "./LoginCheckbox";
import Clickable from "../../components/clickable";
import { ProgressBarStyled, ProgressBarWrapper } from "@pages/inbox/Inbox.styles";

interface IProps {
    isPasswordReset?: boolean;
}

const RegisterForm: React.FC<IProps> = ({ children, isPasswordReset }) => {
    const { t } = useTranslation(LoginTranslationNamespaces);

    const dispatch = useLoginDispatch();
    const invitation = useLoginSelector(selectInvitationData);
    const invitationCode = useLoginSelector(selectInvitationCode);
    const hasInvitation = !!invitation;
    const passwordResetCode = useLoginSelector(selectVerificationCode);
    const isBusy = useLoginSelector(selectRegisterIsBusy);
    const validationMessages = useLoginSelector(selectValidationMessages);

    // email handling
    const email = useLoginSelector(selectLoginEmail);
    const handleUsernameChanged = (args: IInputOnChangeEvent<string>) => {
        dispatch(setLoginEmail(args.value));
        dispatch(setValidationMessage({ key: "username", message: null }));
    };

    // lastName handling
    const [lastName, setLastName] = useState<string>("");
    const handleLastNameChanged = (args: IInputOnChangeEvent<string>) => {
        setLastName(args.value);
        dispatch(setValidationMessage({ key: "lastName", message: null }));
    };
    const [firstName, setFirstName] = useState<string>("");
    const handleFirstNameChanged = (args: IInputOnChangeEvent<string>) => {
        setFirstName(args.value);
        dispatch(setValidationMessage({ key: "firstName", message: null }));
    };

    // password handling
    const [password, setPassword] = useState<string>("");
    const handlePasswordChanged = (args: IInputOnChangeEvent<string>) => {
        setPassword(args.value);
        dispatch(setValidationMessage({ key: "password", message: null }));
    };

    const [personalDataConsent, setPersonalDataConsent] = useState<boolean>(true);
    const [marketingConsent, setMarketingConsent] = useState<boolean>(true);
    const [marketingPersonalDataConsent, setMarketingPersonalDataConsent] = useState<boolean>(false);
    const [hasPasswordFocus, setPasswordFocus] = useState<boolean>(false);

    const handleRegisterPressed = async () => {
        const Email = invitation?.Email ?? email;
        const passwordError = validatePassToString(password);

        if (isPasswordReset) {
            if (passwordError) {
                dispatch(setValidationMessage({ key: "password", message: passwordError }));
                return;
            }
            // reset password
            dispatch(resetPassword(passwordResetCode, Email, password));
        } else {
            const FirstName = invitation?.FirstName ?? firstName;
            const LastName = invitation?.LastName ?? lastName;

            const usernameError = validateEmail(Email);
            const firstNameError = validateFirstName(FirstName);
            const lastNameError = validateLastName(LastName);

            if (usernameError || passwordError || firstNameError || lastNameError) {
                dispatch(setValidationMessage({ key: "username", message: usernameError }));
                dispatch(setValidationMessage({ key: "password", message: passwordError }));
                dispatch(setValidationMessage({ key: "firstName", message: firstNameError }));
                dispatch(setValidationMessage({ key: "lastName", message: lastNameError }));
                return;
            }

            const data: IRegistrationData = {
                FirstName, LastName,
                Email,
                Password: password,
                IsAgreedToCollectData: marketingConsent,
                IsAgreedToCollectPersonalDataForMarketing: marketingPersonalDataConsent,
                Invitation: invitationCode
            };

            await dispatch(register(data));
        }
    };

    const _handlePassFocus = () => {
        setPasswordFocus(true);
    };

    const _handlePassBlur = () => {
        setPasswordFocus(false);
    };

    return (
        <LoginFormStyled>
            {!hasInvitation && (<>
                {!isPasswordReset && (
                    <Row>
                        <LoginField name={"firstName"} label={t("Login:Registration.Name")}>
                            <WriteLine value={firstName}
                                       error={validationMessages?.firstName}
                                       onChange={handleFirstNameChanged}/>
                        </LoginField>
                        <LoginField name={"lastName"} label={t("Login:Registration.LastName")}>
                            <WriteLine value={lastName}
                                       error={validationMessages?.lastName}
                                       onChange={handleLastNameChanged}/>
                        </LoginField>
                    </Row>
                )}

                <LoginField name={"username"} label={t("Login:Login.Email")}>
                    <WriteLine value={email}
                               autocomplete={"username"}
                               error={validationMessages?.username}
                               isDisabled={isPasswordReset}
                               onChange={handleUsernameChanged}/>
                </LoginField>
            </>)}

            <LoginField name={"new-password"}
                        label={t(`Login:${hasInvitation ? "Invitation.NewPassword" : "Registration.Password"}`)}
                        tooltip={t("Login:Registration.Tooltip")}>
                <PasswordWriteLine value={password}
                                   onFocus={_handlePassFocus}
                                   onBlur={_handlePassBlur}
                                   autocomplete={"new-password"}
                                   error={validationMessages?.password}
                                   onChange={handlePasswordChanged}/>
            </LoginField>

            <ProgressBarWrapper isVisible={hasPasswordFocus}>
                <ProgressBarStyled value={password?.length || 0} parts={12} showMarks hideDescription/>
            </ProgressBarWrapper>

            {!isPasswordReset && (<>
                <LoginCheckbox value={personalDataConsent} onChange={setPersonalDataConsent}
                               label={(<>
                                   {t("Login:Registration.AgreeWith")} <Clickable
                                   link={EvalaPersonalDataProcessingLink}>{t("Login:Registration.PersonalData")}</Clickable>
                               </>)}/>
                <LoginCheckbox value={marketingConsent} onChange={setMarketingConsent}
                               label={t("Login:Registration.MarketingConsent")}/>
                <LoginCheckbox value={marketingPersonalDataConsent} onChange={setMarketingPersonalDataConsent}
                               label={t("Login:Registration.MarketingOwnerConsent")}/>
            </>)}

            <LoginButtonGroup additionalText={children}>
                <Button type={"submit"}
                        size={ButtonSize.Big}
                        isDisabled={!personalDataConsent || isBusy}
                        onClick={handleRegisterPressed}>
                    {t(`Login:${isPasswordReset ? "Login.SubmitLogin" : (hasInvitation ? "Invitation.Activate" : "Registration.Register")}`)}
                </Button>
            </LoginButtonGroup>
        </LoginFormStyled>
    );
};

export default RegisterForm;
