import { IGetValueArgs } from "@components/smart/FieldInfo";
import i18next from "i18next";
import { ValidationError } from "yup";

import { FieldType, ValidatorType } from "../../../enums";
import { TValue } from "../../../global.types";
import BindingContext from "../../../odata/BindingContext";
import { IFormDef } from "../../../views/formView/Form";
import { defaultDateFromActiveFiscalYear } from "../../../views/formView/Form.utils";
import { DOCUMENT_DATE_CHANGE_DEBOUNCE } from "../../documents/DocumentDef";
import { IDefinition, IGetDefinition } from "../../PageUtils";


export function getChangePriceDefFactory(changePriceDateValidator: (value: TValue, args: IGetValueArgs) => boolean | ValidationError): IGetDefinition {

    const getDefinition: IGetDefinition = (): IDefinition => {

        const form: IFormDef = {
            id: "FixedAssetChangePriceSpecialForm",
            translationFiles: getDefinition.translationFiles,
            fieldDefinition: {
                [BindingContext.localContext("Date")]: {
                    type: FieldType.Date,
                    label: i18next.t("FixedAsset:ChangePrice.Date"),
                    fieldSettings: {
                        debouncedWait: DOCUMENT_DATE_CHANGE_DEBOUNCE
                    },
                    isRequired: true,
                    validator: {
                        type: ValidatorType.Date,
                        settings: {
                            customValidator: changePriceDateValidator,
                        }
                    },
                    defaultValue: defaultDateFromActiveFiscalYear
                }
            },
            groups: [
                {
                    id: "Common",
                    showGroupDivider: false,
                    rows: [
                        [{ id: BindingContext.localContext("Date") }]
                    ]
                }
            ]
        };

        return {
            form
        };
    };

    getDefinition.translationFiles = ["FixedAsset", "Common"];
    // we don't add this definition even for testing as it is used with
    // no entity set and localContext, there is nothing to test against

    return getDefinition;
}
