import { IGetValueArgs, not, TTemporalDialogSettings } from "@components/smart/FieldInfo";
import { FilterBarGroup, getDefaultFilterGroupDef } from "@components/smart/smartFilterBar/SmartFilterBar.types";
import { ISummaryItem } from "@components/smart/smartSummaryItem/SmartSummaryItem";
import {
    EntitySetName,
    EntityTypeName,
    IPrExtraSalaryEntity,
    PrBaseTypeEntity,
    PrExtraSalaryEntity,
    PrExtraSalaryTemporalEntity
} from "@odata/GeneratedEntityTypes";
import { PrBaseTypeCode } from "@odata/GeneratedEnums";
import { getEnumDisplayValue, getEnumNameSpaceName } from "@odata/GeneratedEnums.utils";
import { IFormatOptions, transformToODataString } from "@odata/OData.utils";
import {
    getTemporalAdditionalProperties,
    getTemporalPropertyFieldDefinition,
    TemporalValidityFieldsDef
} from "@odata/TemporalUtils";
import { isNotDefined } from "@utils/general";
import i18next from "i18next";

import { BasicInputSizes, FastEntryInputSizes, FieldType, GroupedField, Sort, ValueType } from "../../../enums";
import { TValue } from "../../../global.types";
import { Model } from "../../../model/Model";
import BindingContext, { createPath } from "../../../odata/BindingContext";
import { IFormDef } from "../../../views/formView/Form";
import { FormStorage } from "../../../views/formView/FormStorage";
import { ISplitPageTableDef, ITableDefTabData } from "../../../views/table/TableView.utils";
import { setDefByEntityType } from "../../getDefByEntityType";
import { getItemBreadCrumbsText, IDefinition, IGetDefinition } from "../../PageUtils";
import { extraSalaryValueFormatter, getExtraSalaryValueUnit } from "./ExtraSalary.utils";
import ExtraSalaryFormView from "./ExtraSalaryFormView";

// we want to show Type value in SmartTemporalPropertyDialog,
// even though it is not a temporal value => use local context
const FakeTypePath = BindingContext.localContext("Type");


function isCustom(args: IGetValueArgs): boolean {
    const entity = args.storage.data.entity as IPrExtraSalaryEntity;

    return entity?.BaseType?.Code === PrBaseTypeCode.Custom;
}

export const getDefinitionsFactory = (inEditableWindow?: boolean, selectedIds?: number[]): IGetDefinition => {
    const getDefinitions: IGetDefinition = (): IDefinition => {

        const preventStoreVariant = inEditableWindow;

        let filter: string;
        if (selectedIds?.length) {
            filter = `not(${PrExtraSalaryEntity.Id} in (${transformToODataString(selectedIds, ValueType.Number)}))`;
        }

        const defaultTabs: ITableDefTabData[] = [
            { id: "1", title: i18next.t("ExtraSalary:Tabs.Paid") },
            { id: "2", title: i18next.t("ExtraSalary:Tabs.Unpaid") },
            { id: null, title: i18next.t("ExtraSalary:Tabs.All") }
        ];
        const tabs: ITableDefTabData[] = inEditableWindow ? null : defaultTabs;

        const table: ISplitPageTableDef = {
            id: `${EntityTypeName.PrExtraSalary}Table`,
            preventStoreVariant,
            filterBarDef: [{
                ...getDefaultFilterGroupDef(FilterBarGroup.Filters),
                defaultFilters: [
                    createPath(PrExtraSalaryEntity.CurrentTemporalPropertyBag, PrExtraSalaryTemporalEntity.Name),
                    PrExtraSalaryEntity.BaseType,
                    createPath(PrExtraSalaryEntity.CurrentTemporalPropertyBag, PrExtraSalaryTemporalEntity.Value),
                    createPath(PrExtraSalaryEntity.CurrentTemporalPropertyBag, PrExtraSalaryTemporalEntity.ComputationType)
                ],
                filterDefinition: {
                    [PrExtraSalaryEntity.BaseType]: {
                        fieldSettings: {
                            displayName: PrBaseTypeEntity.Name
                        }
                    },
                    [createPath(PrExtraSalaryEntity.CurrentTemporalPropertyBag, PrExtraSalaryTemporalEntity.Name)]: {},
                    [createPath(PrExtraSalaryEntity.CurrentTemporalPropertyBag, PrExtraSalaryTemporalEntity.Value)]: {},
                    [createPath(PrExtraSalaryEntity.CurrentTemporalPropertyBag, PrExtraSalaryTemporalEntity.ComputationType)]: {
                        fieldSettings: {
                            displayName: "Name"
                        }
                    }
                },
                isValueHelp: true
            }],
            filter,
            initialSortBy: [{ id: PrExtraSalaryEntity.BaseType, sort: Sort.Desc }],
            columns: [
                createPath(PrExtraSalaryEntity.CurrentTemporalPropertyBag, PrExtraSalaryTemporalEntity.Name),
                PrExtraSalaryEntity.BaseType,
                createPath(PrExtraSalaryEntity.CurrentTemporalPropertyBag, PrExtraSalaryTemporalEntity.Value),
                createPath(PrExtraSalaryEntity.CurrentTemporalPropertyBag, PrExtraSalaryTemporalEntity.ComputationType)
            ],
            columnDefinition: {
                [createPath(PrExtraSalaryEntity.CurrentTemporalPropertyBag, PrExtraSalaryTemporalEntity.Name)]: {},
                [createPath(PrExtraSalaryEntity.CurrentTemporalPropertyBag, PrExtraSalaryTemporalEntity.Value)]: {
                    formatter: extraSalaryValueFormatter
                },
                [createPath(PrExtraSalaryEntity.CurrentTemporalPropertyBag, PrExtraSalaryTemporalEntity.ComputationType)]: {
                    fieldSettings: {
                        displayName: "Name"
                    }
                },
                [PrExtraSalaryEntity.BaseType]: {
                    fieldSettings: {
                        displayName: PrBaseTypeEntity.Name
                    }
                }
            },
            title: i18next.t("ExtraSalary:Title"),
            tabs,
            tabsSettings: {}
        };
        const summary: ISummaryItem[] = [];
        const temporalDialogSettings: TTemporalDialogSettings = {
            dialogTitle: i18next.t("ExtraSalary:Form.PlanExtraSalaryTitle"),
            columns: [
                PrExtraSalaryTemporalEntity.Name,
                FakeTypePath,
                PrExtraSalaryTemporalEntity.ComputationType,
                PrExtraSalaryTemporalEntity.Value
            ]
        };

        const tmpFieldsDef = { ...TemporalValidityFieldsDef };

        tmpFieldsDef["TemporalPropertyBag/DateValidFrom"].isReadOnly = (args: IGetValueArgs) => {
            const custom = isCustom(args);
            const formStorage = args.storage as FormStorage;

            // called from multiple different context in SmartFastEntryList (for both field and column itself)
            // return when parent doesn't have key => meaning its column check, so that we don't disable the whole column
            // todo better solution/check
            if (custom || isNotDefined(args.bindingContext.getParent().getKey())) {
                return false;
            }

            const isSaved = !!args.storage.getValue(args.bindingContext.getParent(), { dataStore: formStorage.data.origEntity });

            return isSaved;
        };
        tmpFieldsDef["TemporalPropertyBag/DateValidTo"].isDisabled = not(isCustom);

        const form: IFormDef = {
            id: `${EntityTypeName.PrExtraSalary}Form`,
            translationFiles: getDefinitions.translationFiles,
            getItemBreadCrumbText: (storage: Model) =>
                    getItemBreadCrumbsText(storage, i18next.t("ExtraSalary:New"), i18next.t("ExtraSalary:FormTitle")),
            isDeletable: true,
            formControl: ExtraSalaryFormView,
            summary,
            additionalProperties: [
                ...getTemporalAdditionalProperties(PrExtraSalaryTemporalEntity, PrExtraSalaryEntity)
            ],
            fieldDefinition: {
                [createPath("TemporalPropertyBag", FakeTypePath)]: {
                    label: i18next.t("ExtraSalary:Form.Type"),
                    isReadOnly: true,
                    formatter: (val: TValue, args: IFormatOptions) => {
                        const entity = args.storage.data.entity as IPrExtraSalaryEntity;
                        const type = entity.BaseTypeCode;

                        return getEnumDisplayValue(EntityTypeName.PrBaseType, type);
                    }
                },
                ...getTemporalPropertyFieldDefinition({
                    propName: PrExtraSalaryTemporalEntity.Name,
                    definition: {
                        width: FastEntryInputSizes.M,
                        isReadOnly: not(isCustom),
                        groupedField: GroupedField.MultiStart,
                        fieldSettings: {
                            temporalDialog: temporalDialogSettings
                        }
                    },
                    withOpeningButton: false
                }),
                ...getTemporalPropertyFieldDefinition({
                    propName: PrExtraSalaryTemporalEntity.ComputationType,
                    definition: {
                        type: FieldType.ComboBox,
                        fieldSettings: {
                            displayName: "Name",
                            temporalDialog: temporalDialogSettings
                        },
                        isReadOnly: not(isCustom)
                    },
                    withOpeningButton: false
                }),
                [PrExtraSalaryEntity.BaseType]: {
                    width: BasicInputSizes.S,
                    defaultValue: PrBaseTypeCode.Custom,
                    type: FieldType.ComboBox,
                    isReadOnly: true,
                    fieldSettings: {
                        displayName: PrBaseTypeEntity.Name
                    }
                },
                ...getTemporalPropertyFieldDefinition({
                    propName: PrExtraSalaryTemporalEntity.Value,
                    definition: {
                        fieldSettings: {
                            unit: getExtraSalaryValueUnit,
                            temporalDialog: temporalDialogSettings
                        },
                        groupedField: GroupedField.MultiEnd
                    }
                }),
                ...tmpFieldsDef

            },
            groups: [
                {
                    id: "main",
                    rows: [
                        [
                            { id: createPath(PrExtraSalaryEntity.CurrentTemporalPropertyBag, PrExtraSalaryTemporalEntity.Name) },
                            { id: PrExtraSalaryEntity.BaseType },
                            { id: createPath(PrExtraSalaryEntity.CurrentTemporalPropertyBag, PrExtraSalaryTemporalEntity.ComputationType) },
                            { id: createPath(PrExtraSalaryEntity.CurrentTemporalPropertyBag, PrExtraSalaryTemporalEntity.Value) }
                        ]
                    ]
                }
            ]
        };
        return {
            entitySet: EntitySetName.PrExtraSalaries,
            table,
            form
        };
    };
    getDefinitions.translationFiles = ["ExtraSalary", "Common", getEnumNameSpaceName(EntityTypeName.PrBaseType)];

    return getDefinitions;
};

export const getDefinitions = getDefinitionsFactory();

setDefByEntityType(EntityTypeName.PrExtraSalary, getDefinitions);