import styled from "styled-components/macro";
import { VersionSelect } from "./VersionSelect";
import { getFocusBorderDefinition } from "../../global.style";
import { StyledView, StyledViewWrapper } from "../../views/View.styles";


export const StyledAuditTrail = styled.div`
  display: flex;
  height: 100%;
  padding: 28px 0 34px 0;
  
  ${StyledView} {
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  
  ${StyledViewWrapper} {
    overflow-x: hidden;
  }
`;

export const StyledVersionSelectInAudit = styled(VersionSelect)`
  margin-bottom: 20px;
  margin-left: 38px;
`;

export const StyledRightVersionSelectInAudit = styled(VersionSelect)`
  margin-bottom: 20px;
  margin-left: 38px;
`;

export const LeftPane = styled.div`
  width: calc(50% - 5px);

`;
export const RightPane = styled.div`
  width: calc(50% - 5px);
`;

export const ScrollBar = styled.div`
  min-width: 10px;
  margin-top: 100px;
  height: calc(100% - 100px);
  overflow-y: auto;
  overflow-x: hidden; 
  outline: none;
   
  & ::-webkit-scrollbar-thumb {
    border-radius: 3.5px;
    background-color: ${props => props.theme.C_BTN_4L_emph_bg_selected};
  }
  
  & ::-webkit-scrollbar {
    width: 10px;
    background-color: ${props => props.theme.C_DEF_tableBorder};
  }
  
  & ::-webkit-scrollbar-track {
  }
`;

export const StyledFocusBorder = styled.div`
    ${getFocusBorderDefinition()};
    border-style: none;

    position: absolute;
    top: 94px;
    left: 10px;
    width: calc(100% - 20px);
    height: calc(100% - 114px);
    pointer-events: none;
    
    ${ScrollBar}:focus ~ &{
      border-style: dotted;
    }
`;


export const ScrollLabelContent = styled.div`
  height: 5000px;
  width: 10px;
`;

export const StyledVersionSelect = styled.div`
  display: flex;
  align-items: center;
`;
export const Title = styled.span`
  font-size: 20px;
  font-weight: bold;
`;

export const Info = styled.span`
  font-size: 14px;
  margin-left: 15px;
`;

export const Header = styled.div``;
export const VersionIcon = styled.div`
  margin-left: 25px;
`;
