import React from "react";
import { FieldGroupIconWrapper, StyledFieldGroup } from "./FieldGroup.styles";
import { IconButton } from "../button";
import TestIds from "../../testIds";
import { CloseIcon } from "../icon";
import { IconSize } from "../../enums";
import { WithTranslation, withTranslation } from "react-i18next";
import { focusNextElement } from "@utils/general";
import FieldsWrapper from "../inputs/field/FieldsWrapper";

export enum FieldGroupPaddingType {
    Default = "Default",
    ReadOnlyContent = "ReadOnlyContent",
    None = "None"
}

interface IProps extends WithTranslation {
    isLight?: boolean;
    paddingType?: FieldGroupPaddingType;
    displayCloseIcon?: boolean;
    opacity?: number;
    onCloseClick?: () => void;
    customButtons?: React.ReactElement[];
    isFirst?: boolean;
    isLast?: boolean;
    className?: string;
}

class FieldGroup extends React.PureComponent<IProps> {
    static defaultProps: Partial<IProps> = {
        opacity: 1,
        displayCloseIcon: true,
        isFirst: true,
        isLast: true,
        paddingType: FieldGroupPaddingType.Default
    };

    _closeBtnRef = React.createRef<HTMLButtonElement>();

    handleClose = () => {
        focusNextElement(this._closeBtnRef?.current);
        this.props.onCloseClick();
    };

    render() {
        return (
            <StyledFieldGroup _opacity={this.props.opacity}
                              _paddingType={this.props.paddingType}
                              isLight={this.props.isLight}
                              isFirst={this.props.isFirst}
                              isLast={this.props.isLast}
                              data-testid={TestIds.FieldGroup}
                              className={this.props.className}>
                <FieldsWrapper isColumn testid={TestIds.FieldsWrapper}>
                    {this.props.children}
                </FieldsWrapper>
                {/*render close button after the fields for correct tab index order*/}
                <FieldGroupIconWrapper>
                    {this.props.customButtons}
                    {this.props.displayCloseIcon &&
                        <IconButton isDecorative isLight
                                    passRef={this._closeBtnRef}
                                    onClick={this.handleClose}
                                    title={this.props.t("Common:General.Close")}>
                            <CloseIcon width={IconSize.M}/>
                        </IconButton>
                    }
                </FieldGroupIconWrapper>
            </StyledFieldGroup>
        );
    }
}

export default withTranslation(["Common"])(FieldGroup);