import { createPath } from "@odata/BindingContext";
import {
    EntitySetName,
    EntityTypeName,
    GovernmentInstitutionBankAccountEntity,
    GovernmentInstitutionEntity,
    PrGovernmentInstitutionBankAccountDetailEntity,
    PrGovernmentInstitutionDetailEntity
} from "@odata/GeneratedEntityTypes";
import i18next from "i18next";

import { IFormDef } from "../../../views/formView/Form";
import { setDefByEntityType } from "../../getDefByEntityType";
import { IDefinition, IGetDefinition } from "../../PageUtils";
import { BankAccountsFastEntryPath, getContactsRowsDef, GOVERNMENT_INSTITUTIONS } from "./GovernmentInstitution.utils";

export const getDefinitions: IGetDefinition = (): IDefinition => {
    const entitySet = EntitySetName.PrPayrollSettings;
    const form: IFormDef = {
        id: `${EntityTypeName.PrPayrollSetting}Form`,
        title: i18next.t("GovernmentInstitution:Title"),
        translationFiles: getDefinitions.translationFiles,
        fieldDefinition: {
            ...getContactsRowsDef()
        },
        groups: [
            ...GOVERNMENT_INSTITUTIONS.reduce((defs, institution) => {
                return [
                    ...defs,
                    {
                        id: institution,
                        title: i18next.t(`GovernmentInstitution:${institution}`),
                        rows: [[{ id: createPath(institution, PrGovernmentInstitutionDetailEntity.GovernmentInstitution) }]],
                        collapsedRows: [[
                            { id: createPath(institution, PrGovernmentInstitutionDetailEntity.GovernmentInstitution, GovernmentInstitutionEntity.Street) },
                            { id: createPath(institution, PrGovernmentInstitutionDetailEntity.GovernmentInstitution, GovernmentInstitutionEntity.City) },
                            { id: createPath(institution, PrGovernmentInstitutionDetailEntity.GovernmentInstitution, GovernmentInstitutionEntity.PostalCode) },
                            { id: createPath(institution, PrGovernmentInstitutionDetailEntity.GovernmentInstitution, GovernmentInstitutionEntity.Country) },
                            { id: createPath(institution, PrGovernmentInstitutionDetailEntity.GovernmentInstitution, GovernmentInstitutionEntity.DataBoxId) }
                        ], [
                            { id: createPath(institution, BankAccountsFastEntryPath) }
                        ]]
                    },
                    {
                        id: `${institution}Contacts`,
                        rows: [[
                            { id: createPath(institution, PrGovernmentInstitutionDetailEntity.ContactName) },
                            { id: createPath(institution, PrGovernmentInstitutionDetailEntity.ContactEmail) },
                            { id: createPath(institution, PrGovernmentInstitutionDetailEntity.ContactPhone) }
                        ]]
                    }];
            }, [])
        ],
        additionalProperties: [
            ...GOVERNMENT_INSTITUTIONS.reduce((defs, institution) => {
                return [
                    ...defs,
                    {
                        id: createPath(institution, PrGovernmentInstitutionDetailEntity.BankAccountDetails),
                        additionalProperties: [{
                            id: PrGovernmentInstitutionBankAccountDetailEntity.GovernmentInstitutionBankAccount,
                            additionalProperties: [{
                                id: GovernmentInstitutionBankAccountEntity.BankCode
                            }, {
                                id: GovernmentInstitutionBankAccountEntity.AccountNumber
                            }, {
                                id: GovernmentInstitutionBankAccountEntity.AccountPrefix
                            }, {
                                id: GovernmentInstitutionBankAccountEntity.IBAN
                            }, {
                                id: GovernmentInstitutionBankAccountEntity.Name
                            }]
                        }, {
                            id: PrGovernmentInstitutionBankAccountDetailEntity.SymbolVariable,
                            useForValidation: true
                        }, {
                            id: PrGovernmentInstitutionBankAccountDetailEntity.SymbolSpecific,
                            useForValidation: true
                        }, {
                            id: PrGovernmentInstitutionBankAccountDetailEntity.SymbolConstant,
                            useForValidation: true
                        }, {
                            id: PrGovernmentInstitutionBankAccountDetailEntity.RemittanceInformation,
                            useForValidation: true
                        }]
                    }];
            }, [])
        ]
    };

    return {
        entitySet,
        form
    };
};

getDefinitions.translationFiles = ["Common", "GovernmentInstitution"];
setDefByEntityType(EntityTypeName.PrPayrollSetting, getDefinitions);