import React from "react";

import {
    DashboardBody,
    DashboardHeader,
    DashboardTitle,
    WorkDatePickerStyled,
    StyledNavDashboard, StyledNavDashBoardBody
} from "./NavDashboard.styles";
import DashboardBackground from "./DashboardBackground";
import { WithTranslation, withTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ScrollBar } from "../scrollBar";
import { IMenuGroup } from "./NavMenu";
import TestIds from "../../testIds";
import NavDashboardItem from "./NavDashboardItem";

export interface INavDashboardProps {
    group: IMenuGroup;

    className?: string;
}

class NavDashboard extends React.PureComponent<INavDashboardProps & WithTranslation & RouteComponentProps> {
    refFooter = React.createRef<HTMLDivElement>();
    refItems = React.createRef<HTMLDivElement>();
    refBody = React.createRef<HTMLDivElement>();

    render() {
        // this class is very important as it directs animation for "csstransition" with react router in app.tsx
        const className = `dashboard-menu ${this.props.className || ''}`;
        return (
            <StyledNavDashboard
                ref={this.refBody}
                className={className}
                data-testid={TestIds.Dashboard}>
                <StyledNavDashBoardBody>
                    {this.props.group &&
                    <>
                        <DashboardHeader data-testid={TestIds.Header}>
                            <DashboardTitle data-testid={TestIds.HeaderTitle}>
                                {this.props.t(this.props.group.title)}
                                {/*for now this works only for documents*/}
                                {this.props.group.key === "Documents" &&
                                <WorkDatePickerStyled/>
                                }
                            </DashboardTitle>
                        </DashboardHeader>
                        <ScrollBar primary style={{ overflowX: "hidden", height: "calc(100% - 80px)" }}>
                            <DashboardBody ref={this.refItems}>
                                {this.props.group.items.map((item) => {
                                    return <NavDashboardItem {...item} />;

                                })}
                            </DashboardBody>
                        </ScrollBar>
                        <DashboardBackground/>
                    </>
                    }
                </StyledNavDashBoardBody>
            </StyledNavDashboard>
        );
    }

}

const NavDashboardWithTranslation = withTranslation("Common")(withRouter(NavDashboard));
export { NavDashboardWithTranslation as NavDashboard };
