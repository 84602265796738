import {
    Condition,
    ConditionType,
    formatFilterAsSentence,
    formatFilterDisplayCondition,
    getComplexFilterValue,
    IComplexFilter,
    isComplexFilterArr,
    isInterval,
    IValueInterval,
    PredefinedFilter,
    TFilterValue
} from "@components/conditionalFilterDialog/ConditionalFilterDialog.utils";
import { formatDateInterval, formatDateToDateString, isValidDateInterval } from "@components/inputs/date/utils";
import { getInfoValue, IFieldDef } from "@components/smart/FieldInfo";
import { ISort } from "@components/table";
import { isBooleanType, isDateType, isNumericType } from "@evala/odata-metadata/src";
import { IFetchDefinition } from "@pages/PageUtils";
import { formatDateByTimeAggFn, isValidDateIntervalWithTimeAggFn } from "@pages/reports/Report.utils";
import { getDefaultPostParams } from "@utils/customFetch";
import { dateTimeToDateRange, isDefined, isNotDefined } from "@utils/general";
import { logger } from "@utils/log";
import { Dayjs } from "dayjs";
import i18next from "i18next";

import { EMPTY_VALUE, NON_BREAKING_SPACE, ODATA_API_URL } from "../constants";
import { IAppContext } from "../contexts/appContext/AppContext.types";
import { FieldType, ODataFilterFunction, Sort, ValueType } from "../enums";
import { IToString, TRecordAny } from "../global.types";
import { Model } from "../model/Model";
import { formatCurrency, formatCurrencyVariableDecimals } from "../types/Currency";
import DateType from "../types/Date";
import NumberType from "../types/Number";
import { IChangedFilter } from "../views/table/TableView.utils";
import BindingContext, { IEntity } from "./BindingContext";
import { getUniqueContextsSuffixAsString } from "./Data.utils";
import { getValueTypeFromProperty, IFieldInfo } from "./FieldInfo.utils";
import { ActionTypeCode } from "./GeneratedEnums";
import { BatchRequest, EntitySetWrapper, OData, ODataQueryBuilder, Query } from "./OData";

interface IParsedPath {
    navigation: string;
    propertyPath: string;
}

export interface ICreateFilterStringSettings {
    rootBindingContext?: BindingContext;
    prefix?: string;
}

// we are using one character filter prefixes -> increment on every recursion
function incrementString(char: string): string {
    return String.fromCharCode(...(Array.from(char).map(c => c.charCodeAt(0) + 1)));
}

export function createFilterValue(bindingContext: BindingContext, filterName: string, filter: IComplexFilter): string {
    const valueType = getValueTypeFromProperty(bindingContext.getProperty());
    let value = getComplexFilterValue(filter);

    const _patterns: Partial<Record<Condition, string>> = {
        [Condition.Equals]: "%name% eq %value%",
        [Condition.IsBefore]: "%name% lt %value%",
        [Condition.IsAfter]: "%name% gt %value%",
        [Condition.IsBeforeOrEqualsTo]: "%name% le %value%",
        [Condition.IsAfterOrEqualsTo]: "%name% ge %value%",
        [Condition.GreaterThan]: "%name% gt %value%",
        [Condition.LesserThan]: "%name% lt %value%",
        [Condition.GreaterOrEqual]: "%name% ge %value%",
        [Condition.LesserOrEqual]: "%name% le %value%",
        [Condition.Contains]: "contains(%name%,%value%)",
        [Condition.BeginsWith]: "startswith(%name%,%value%)",
        [Condition.EndsWith]: "endswith(%name%,%value%)"
    };

    const _intervalPatterns: Partial<Record<Condition, string>> = {
        [Condition.Equals]: "(%name% ge %from% and %name% le %to%)",
        [Condition.Between]: "(%name% ge %from% and %name% le %to%)",
        [Condition.IsBefore]: "%name% lt %from%",
        [Condition.IsAfter]: "%name% gt %to%",
        [Condition.IsBeforeOrEqualsTo]: "%name% le %to%",
        [Condition.IsAfterOrEqualsTo]: "%name% ge %from%"
    };

    let filterStr: string;

    if (Array.isArray(value)) {
        if (value.length === 0) {
            return "";
        }
        // for array of values use IN clause instead of multiple ORs => faster
        const joinedValues = transformToODataString(value as string[], valueType);

        filterStr = `${filterName} in (${joinedValues})`;
    } else {
        const isDate = valueType === ValueType.Date;

        // We should transform dates to intervals to cover whole day regardless of time of the day
        if (value !== null && isDate && !isInterval(value)) {
            value = dateTimeToDateRange(value as Date);
        }

        filterStr = (isInterval(value) ? _intervalPatterns : _patterns)[filter.condition];

        // We are using pattern for the filter, so we need to replace placeholders with actual values
        filterStr = filterStr.replaceAll("%name%", filterName);

        if (isInterval(value)) {
            Object.keys(value).forEach((key) => {
                const v = transformToODataString((value as TRecordAny)[key], valueType);
                filterStr = filterStr.replaceAll(new RegExp(`%${key}%`, "g"), v);
            });
        } else {
            filterStr = filterStr.replaceAll(new RegExp(`%value%`, "g"), transformToODataString(value as Exclude<TFilterValue, TRecordAny>, valueType));
        }
    }

    if (filter.type === ConditionType.Excluded) {
        filterStr = `not (${filterStr})`;
    }

    return filterStr;
}

function formatFilterToDisplayValue(filterValue: IComplexFilter, info: IFieldInfo, opts: IFormatOptions) {
    const { value } = filterValue;
    let displayValue;

    if (isInterval(value)) {
        displayValue = {
            from: formatValue((value as IValueInterval).from, info, opts),
            to: formatValue((value as IValueInterval).to, info, opts)
        };
    } else {
        displayValue = formatValue(value, info, opts);
    }

    return displayValue;
}

export function getFilterToken(filterValue: IComplexFilter, info: IFieldInfo, opts: IFormatOptions): string {
    const { condition, filter, type, value } = filterValue;
    let prefix = "";
    let displayValue;

    if (filter !== PredefinedFilter.Value) {
        displayValue = i18next.t(`Components:ValueHelper.${filter}`);
    } else {
        displayValue = formatFilterToDisplayValue(filterValue, info, opts);
    }

    if (type === ConditionType.Excluded) {
        prefix = "!";
    }

    return `${prefix}${formatFilterDisplayCondition(condition, displayValue)}`;
}

export function getFilterSentence(filterValue: IComplexFilter, info: IFieldInfo, opts: IFormatOptions): string {
    const displayValue = formatFilterToDisplayValue(filterValue, info, opts);

    return formatFilterAsSentence(filterValue.condition, displayValue);
}

export const createFilterString = (filter: IChangedFilter, settings: ICreateFilterStringSettings = {}): string => {
    const _getFilterName = (bc: BindingContext) => {
        const navigation = bc.getNavigationPath(true, settings.rootBindingContext);
        return settings.prefix ? `${settings.prefix}/${navigation}` : navigation;
    };

    const parentCollection = filter.bindingContext.getParentCollection(settings.rootBindingContext);

    if (parentCollection) {
        const prefix = settings.prefix ? incrementString(settings.prefix) : "x";
        const filterString = createFilterString(filter, {
            rootBindingContext: parentCollection,
            prefix
        });

        if (filter.info.filter?.nullFilterMeansEmptyCollectionFilter && filterString === EMPTY_VALUE) {
            return `not(${_getFilterName(parentCollection)}/any())`;
        }

        return `${_getFilterName(parentCollection)}/any(${prefix}: ${filterString})`;
    }
    let filterName = _getFilterName(filter.bindingContext);

    if (filter.info.filter?.oDataFn) {
        filterName = `${filter.info.filter?.oDataFn}(${filterName})`;
    }

    // first truthy case will be used
    switch (true) {
        case isNotDefined(filter.value):
            return "";

        case isComplexFilterArr(filter.value):
            return (filter.value as IComplexFilter[])
                .map((value: IComplexFilter) => createFilterValue(filter.bindingContext, filterName, value))
                .filter(filterStr => filterStr !== "")
                .join(" AND ");

        // isCollection vs field.info.type === FieldType.MultiInput
        case filter.bindingContext.isCollection():
            const filterValue = filter.value as string[];
            const paramName = filter.info.fieldSettings?.displayName ?? filter.bindingContext.getKeyPropertyName();

            if (!filterValue?.length) {
                return "";
            }

            const joinedParams = filterValue
                .map((value) => `param/${paramName} eq '${value}'`)
                .join(" OR ");

            return `${filter.info.id}/any(param: ${joinedParams})`;

        // Dates have special treatment - date arrays and also single values...
        //  due to bug in oData library (https://github.com/OData/WebApi/issues/2373) we need to treat Date type
        //  values from value helper using "eq" operator joined with OR condition instead of "in".
        case filter.info.valueType === ValueType.Date:

            if (Array.isArray(filter.value)) {
                // Date Array is list of dates from value helper - exact values, we can directly filter by
                // array of date objects or strings representing date object (in case it comes from storage)
                return (filter.value as string[])
                    .map(val => `${filterName} eq ${transformToODataString(val, filter.info.valueType)}`)
                    .join(" OR ");

            } else {
                // Single date inserted by user manual should be converted to day interval (we don't want to use exact match)
                const { from, to } = dateTimeToDateRange(filter.value as Date);

                return `${filterName} ge ${transformToODataString(from, filter.info.valueType)} AND ${filterName} le ${transformToODataString(to, filter.info.valueType)}`;
            }

        // Multiselects - user picks exact values, so we are using in operator
        case Array.isArray(filter.value):
            const joinedValues = transformToODataString(filter.value as string[], filter.info.valueType);
            return `${filterName} in (${joinedValues})`;

        // It's single value filled manually by user
        // or EMPTY_VALUE created in fnBuildFilterQuery
        default:
            if (filter.value !== null && typeof filter.value === "object") {
                logger.error(`wrong filter value for: ${filter.bindingContext.getFullPath()}`);
                return "";
            }
            const transformedValue = transformToODataString(filter.value, filter.info.valueType);
            if (filter.info.valueType === ValueType.String && filter.value !== EMPTY_VALUE) {
                return `${filter.info.filter?.stringFilterFn ?? ODataFilterFunction.Startswith}(${filterName},${transformedValue})`;
            }

            // sometimes (e.g. when filtering over Labels), we want to filter only items WITHOUT any label "not(Labels/any()"
            // => return EMPTY_VALUE so that the caller knows it has to create query wrapped in "not"
            if (filter.info.filter?.nullFilterMeansEmptyCollectionFilter && filter.value === EMPTY_VALUE) {
                return EMPTY_VALUE;
            }

            return `${filterName} eq ${transformedValue}`;
    }
};

export interface ILogAction {
    actionId: string;
    actionType: ActionTypeCode;
    detail?: string;
}

export const logAction = async (args: ILogAction) => {
    const response = await fetch(`${ODATA_API_URL}/ActionLogs`, {
        ...getDefaultPostParams(),
        body: JSON.stringify({
            ActionId: args.actionId,
            ActionTypeCode: args.actionType,
            ActionDetails: args.detail ?? ""
        })
    });

    return response.ok;
};

// we still want Company Id to be part of the log
export const getDefaultLogActionDetail = (storage: Model) => {
    return `Company/Id eq ${storage.context.getCompanyId()}`;
};

export enum FormattingTarget {
    Summary = "Summary"
}

export interface IFormatOptions<E = IEntity, I = IEntity> {
    // whole entity or row in case of table
    entity?: E;
    // value related to the bindingContext, or usually row again in case of table
    item?: I;
    storage?: Model<any>;
    context?: IAppContext;
    bindingContext?: BindingContext;
    info?: IFieldInfo;
    readonly?: boolean;
    placeholder?: string;
    unit?: string;
    target?: FormattingTarget;
    customArgs?: Record<string, unknown>;
}

export const isEmptyValue = (val: unknown): boolean => isNotDefined(val) || val === EMPTY_VALUE;

export const formatValue = (value: any, fieldInfo: IFieldInfo, options?: IFormatOptions) => {
    let formattedValue = value;

    if (!fieldInfo) {
        return value;
    }

    const property = fieldInfo.bindingContext.getProperty();

    if (fieldInfo.formatter) {
        formattedValue = fieldInfo.formatter(value, options);
    } else if (property?.getCurrency()
        && options?.readonly) { // we don't want to format currency name in inputs
        const currency = property.getCurrency();
        const constraint = fieldInfo.bindingContext.getEntityType().getProperty(currency).getReferentialConstraint();
        // in case navigation object doesn't exist (Currency), try to use property (CurrencyCode)
        const currencyValue = (options.entity[currency]?.[constraint.referencedProperty] as string) ?? options.entity[constraint.property];

        const method = options.target === FormattingTarget.Summary ? formatCurrencyVariableDecimals : formatCurrency;
        formattedValue = method(value, currencyValue);
    } else if (isEmptyValue(value) && options?.readonly) {
        formattedValue = options?.placeholder ?? "";
    } else if (isNumericType(property)
        || fieldInfo.type === FieldType.NumberInput) { // local context field don't specify fieldInfo.propertyMetadata but can use NumberInput
        formattedValue = NumberType.format(value);
        if (options?.unit && options?.readonly) {
            formattedValue = formattedValue + NON_BREAKING_SPACE + options.unit;
        }
    } else if (isDateType(property) || (fieldInfo.type === FieldType.Date)) {
        if (isValidDateIntervalWithTimeAggFn(value)) {
            // for case when filter is passed as date interval via drilldown filters,
            // but is defined as FieldType.Date in local filter definition
            // used in GeneralLedgerDef
            formattedValue = formatDateByTimeAggFn(value.from, value.type);
        } else if (isValidDateInterval(value)) {
            formattedValue = formatDateInterval(value);
        } else {
            // todo: localFormat ??
            formattedValue = DateType.format(value, fieldInfo.displayFormat, {
                isReadOnly: options.readonly
            });
        }
    } else if (isBooleanType(property)) {
        formattedValue = formatBooleanValue(value);
    }

    return formattedValue;
};

export function formatBooleanValue(value: boolean): string {
    return i18next.t(`Common:General.${value ? "Yes" : "No"}`);
}

export function parsePropertyPath(path: string): IParsedPath {
    let navigation;
    let propertyPath = path;

    if (path.indexOf("/") >= 0) {
        navigation = path.split("/");
        propertyPath = navigation.pop();
        navigation = navigation.join("/");
    }

    return {
        navigation,
        propertyPath
    };
}

interface IParsedTree {
    properties?: string [];
    navigation?: {
        [navigationProperty: string]: IParsedTree;
    };
}

export const parseNavigationTree = (properties: string [], bindingContext: BindingContext): IParsedTree => {
    const tree: IParsedTree = {
        properties: [],
        navigation: {}
    };

    const nextLevel: {
        [key: string]: string[]
    } = {};

    for (const property of properties) {
        const propBindingContext = bindingContext.navigate(property);
        const split = property.split("/");

        if (split.length === 1 && !propBindingContext.isNavigation()) {
            tree.properties.push(split[0]);
        } else {
            const navigation = split.shift();

            if (!(navigation in nextLevel)) {
                nextLevel[navigation] = [];
            }

            if (split.length > 0) {
                nextLevel[navigation].push(
                    split.join("/")
                );
            }
        }
    }


    for (const nextNav of Object.keys(nextLevel)) {
        const navBindingContext = bindingContext.navigate(nextNav);
        tree.navigation[nextNav] = parseNavigationTree(nextLevel[nextNav], navBindingContext);
    }

    return tree;
};

const applyQuerySettings = ({ query, settings, path = "" }: {
    query: Query,
    settings: IPrepareQuerySettings,
    path?: string
}) => {
    if (!(path in settings)) {
        return query;
    }

    const pathSettings = settings[path];

    if (pathSettings.sort) {
        for (const sort of pathSettings.sort) {
            query = query.orderBy(sort.id, sort.sort === Sort.Asc);
        }
    }

    if (pathSettings.filter) {
        query = query.filter(pathSettings.filter);
    }

    if (pathSettings.count) {
        query = query.count();
    }

    if (isDefined(pathSettings.top)) {
        query = query.top(pathSettings.top);
    }

    if (isDefined(pathSettings.skip)) {
        query = query.skip(pathSettings.skip);
    }

    return query;
};

export const queryFromTree = ({ bindingContext, fieldDefs, navigationTree, settings, isRoot }: {
    bindingContext: BindingContext,
    navigationTree: IParsedTree,
    fieldDefs?: IFieldDef[],
    settings: IPrepareQuerySettings,
    isRoot?: boolean
}) => {
    return (q: Query) => {
        const enrichedProperties = getEnrichedProperties(navigationTree.properties, bindingContext, fieldDefs);
        const parsedTree = parseNavigationTree(enrichedProperties, bindingContext);

        q.select(...parsedTree.properties);

        const navigations = {
            ...navigationTree.navigation,
            ...parsedTree.navigation
        };

        for (const nav of Object.keys(navigations)) {
            const newBindingContext = bindingContext.navigate(nav);

            q.expand(nav, queryFromTree({
                bindingContext: newBindingContext,
                navigationTree: navigations[nav],
                fieldDefs,
                settings
            }));
        }

        applyQuerySettings({ query: q, settings, path: isRoot ? "" : bindingContext.getNavigationPath() });
    };
};

// const flattenTree = (entityTree, flattenArray, fullPath = "") => {
//     let flattenedTree = {};
//
//     for (let [name, value] of Object.entries(entityTree)) {
//         const newPath = fullPath === "" ? name : `${fullPath}/${name}`;
//         if (value !== null && typeof value === "object" && !(value instanceof Date)) {
//             if (!flattenArray && Array.isArray(value)) {
//                 flattenedTree[newPath] = [];
//                 for (let val of value) {
//                     flattenedTree[newPath].push(flattenTree(val, flattenArray));
//                 }
//             } else {
//                 flattenedTree = {
//                     ...flattenedTree,
//                     ...flattenTree(value, flattenArray, newPath)
//                 };
//             }
//         } else {
//             flattenedTree[newPath] = value;
//         }
//     }
//
//     return flattenedTree;
// };

/**
 * Adds key properties of entitySet, so that we can create unique ids for each returned item
 Adds Currency properties
 Adds displayName property if defined in FieldInfo
 */
export const getEnrichedProperties = (properties: string[], bindingContext: BindingContext, fieldDefs: IFieldDef[]) => {
    const _getAllAdditionalProperties = (fieldDef: IFieldDef, fieldBc?: BindingContext) => {
        let allAdditionalProperties: string[] = [];

        if (fieldDef) {
            const fieldBindingContext = fieldBc ? fieldBc : bindingContext.navigate(fieldDef.id);

            if (!fieldBindingContext.isLocal() && fieldDef.fieldSettings?.displayName) {
                allAdditionalProperties.push(getUniqueContextsSuffixAsString(fieldBindingContext.navigateWithSiblingFallback(fieldDef.fieldSettings?.displayName), bindingContext));
            }

            if (fieldDef.additionalProperties) {
                // if the path starts with '/' it represents full path from the root so we take it without any change
                allAdditionalProperties = [...allAdditionalProperties, ...(fieldDef.additionalProperties.map(additionalDef => {
                    return additionalDef.id?.startsWith("/") ? additionalDef.id?.slice(1)
                        : getUniqueContextsSuffixAsString(fieldBindingContext.navigateWithSiblingFallback(additionalDef.id), bindingContext);
                }))];
            }
        }

        return allAdditionalProperties;
    };

    const propertiesWithKeys = [...properties];
    const entityType = bindingContext.getEntityType();

    // add entity keys
    for (const keyProp of entityType.getKeys()) {
        if (propertiesWithKeys.indexOf(keyProp.getName()) < 0) {
            propertiesWithKeys.push(keyProp.getName());
        }
    }

    // virtual "local" properties cannot be part of the odata request
    const newProperties = [...propertiesWithKeys.filter(property => !BindingContext.isLocalContextPath(property))];

    for (const property of propertiesWithKeys) {
        for (const additionalProp of _getAllAdditionalProperties(fieldDefs.find((fieldDef) => removeKeyFromPath(fieldDef.id) === property))) {
            // todo find better condition than removeKeyFromPath(fieldDef.id) === property)
            // to match property with its fieldDef => get rid of this try catch block
            try {
                bindingContext.navigate(additionalProp);
                if (!newProperties.find(prop => additionalProp === prop)) {
                    newProperties.push(additionalProp);
                }
            } catch {
            }

        }
    }

    for (const propName of propertiesWithKeys) {
        const property = entityType.getProperty(propName);

        if (!property) {
            continue;
        }

        const currency = property.getCurrency();

        if (currency) {
            if (newProperties.indexOf(currency) < 0) {
                const referencedProperty = entityType.getProperty(currency).getReferentialConstraint().referencedProperty;
                newProperties.push(`${currency}/${referencedProperty}`);
            }
        }
    }

    return newProperties;
};

const KEY_REGEX = /\((\d+)\)/;

export function removeKeyFromPath(path: string): string {
    return path.replace(KEY_REGEX, "");
}

export interface IPrepareQuerySettings {
    [entityType: string]: {
        sort?: ISort[];
        filter?: string;
        count?: boolean;
        top?: number;
        skip?: number;
    };
}

interface IPrepareQueryArgsBase {
    bindingContext: BindingContext;
    fieldDefs?: IFieldDef[];
    settings?: IPrepareQuerySettings;
}

interface IPrepareQueryArgs extends IPrepareQueryArgsBase {
    oData: OData;
}

interface IPrepareQueryForBatchArgs extends IPrepareQueryArgsBase {
    batch: BatchRequest;
}

export const prepareQueryBase = (query: Query, {
    bindingContext,
    fieldDefs = [],
    settings = {}
}: IPrepareQueryArgsBase): Query => {
    const properties = fieldDefs.map(fieldDef => fieldDef.id)
        .map(property => removeKeyFromPath(property));

    const applyQuery = queryFromTree({
        bindingContext,
        fieldDefs,
        navigationTree: {
            properties: properties,
            navigation: {}
        },
        settings,
        isRoot: true
    });

    applyQuery(query);

    return query;
};

export const prepareQuery = (args: IPrepareQueryArgs): ODataQueryBuilder => {
    const { oData, ...restArgs } = args;
    const entitySetWrapper: EntitySetWrapper = oData.fromPath(args.bindingContext.toString());
    const query = entitySetWrapper.query();

    return prepareQueryBase(query, restArgs) as ODataQueryBuilder;
};

export const prepareQueryForBatch = (args: IPrepareQueryForBatchArgs): Query => {
    const { batch, ...restArgs } = args;
    const entitySetWrapper = batch.fromPath(args.bindingContext.toString());
    const query = entitySetWrapper.query();

    return prepareQueryBase(query, restArgs);
};

type TBaseValue = string | number | Date | Dayjs | boolean | IToString;
type TTransformValue = TBaseValue | TBaseValue[];

export function transformToODataString(val: TTransformValue, type: ValueType): string {
    if (isEmptyValue(val)) {
        return "null";
    }
    if (Array.isArray(val)) {
        return val.map((v) => transformToODataString(v, type)).join(",");
    }
    switch (type) {
        case ValueType.String:
            return `'${val.toString().replace(/'/g, "''")}'`;
        case ValueType.Date:
            return formatDateToDateString(val as Date);
        default:
            // ValueType.Number, ValueType.Boolean or type === undefined - mostly for navigation properties, etc...
            return `${val}`;
    }
}

export const fetchData = async (bindingContext: BindingContext, oData: OData, definition: IFetchDefinition, context: IAppContext) => {
    const settings: IPrepareQuerySettings = {},
        { sort, columns } = definition,
        filter = getInfoValue(definition, "filter", { bindingContext, context });

    settings[""] = {
        sort, filter
    };

    const query = prepareQuery({
        oData: oData,
        bindingContext,
        fieldDefs: [
            ...columns
        ],
        settings
    });

    const result = await query.fetchData();
    return result.value;
};

