import React, { useEffect } from "react";

import { LoginSmallBottomClickable, LoginSmallBottomText, LoginTitle } from "./LoginPage.styles";
import { AnimationType, LoginTranslationNamespaces } from "./Login.utils";
import { ROUTE_LOGIN } from "../routes";
import { useTranslation } from "react-i18next";
import TestIds from "../testIds";
import { BackArrow } from "./components/BackArrow";
import RegisterForm from "./components/RegisterForm";
import Redirects from "./components/Redirects";
import { setAnimationType } from "./state/loginSlice";
import { useLoginDispatch } from "./state/hooks";

interface IProps {
}

const Register: React.FC<IProps> = (props) => {

    const { t } = useTranslation([...LoginTranslationNamespaces]);

    const dispatch = useLoginDispatch();

    useEffect(() => {
        dispatch(setAnimationType(AnimationType.Queue));
    }, [dispatch]);

    return (<>
        <Redirects skipNoSessionCheck/>
        <LoginTitle data-testid={TestIds.Title}>
            <BackArrow url={ROUTE_LOGIN}/>
            {t("Login:Registration.Title")}
        </LoginTitle>
        <RegisterForm>
            <LoginSmallBottomText
                data-testid={TestIds.BottomTextLabel}>{t("Login:Registration.AlreadyRegistered")}</LoginSmallBottomText>
            <LoginSmallBottomClickable testId={TestIds.BottomTextLink}
                                       link={`${ROUTE_LOGIN}`}>{t("Login:Registration.LoginHere")}</LoginSmallBottomClickable>
        </RegisterForm>
    </>);
};

export default Register;
