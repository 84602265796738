import styled, { css } from "styled-components/macro";
import { getFocusBorderElement, T_PLAIN_small, T_TITLE_5 } from "../../global.style";
import { ellipsis, transparentize } from "polished";

interface IOrganizationTile {
    $backgroundSrc: string;
    $isSelected: boolean;
}

export const InnerRect = styled.div<IOrganizationTile>`
    display: flex;
    flex-direction: column;
    align-items: center;

    position: absolute;
    width: calc(100% - 12px);
    height: calc(100% - 12px);
    top: 6px;
    left: 6px;
    border: 2px solid ${props => props.theme.C_ACT_hover_without_opacity};
    border-radius: 10px;

    background-image: url("${props => props.$backgroundSrc}");
    background-position: -8px -8px;
    background-repeat: no-repeat;
    background-size: 211px 211px;
}
`;

export const StyledOurOrganizationTile = styled.div<IOrganizationTile>`
    position: relative;
    width: 211px;
    height: 211px;

    border-radius: 3px;
    background-image: url("${props => props.$backgroundSrc}");
    color: ${props => props.theme.C_TEXT_dialogue};

    ${getFocusBorderElement({ offset: -5, isLight: true })};
    
    ${props => !props.$isSelected && css`
        &:not(:hover) ${InnerRect} {
            border-color: transparent;
        }
    `}
`;

// use separate element instead of "before" on StyledOurOrganizationTile,
// to prevent clashing with getFocusBorderElement
export const SelectedOverlay = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: ${props => transparentize(0.5, props.theme.C_ACT_hover_without_opacity)};
`;

export const Title = styled.div`
    margin-top: 113px;

    ${T_TITLE_5};
    font-weight: 800;

    ${ellipsis()};
`;

export const Subtitle = styled.div`
    margin-top: 2px;

    ${T_PLAIN_small};
    ${ellipsis()};
`;