import styled, { css } from "styled-components/macro";
import { SIMPLE_TIMELINE_POINT_SIZE, SimpleTimeLineStatus } from "./SimpleTimeline.utils";
import { DefaultTheme } from "styled-components";
import { transparentize } from "polished";

const getPointColor = (theme: DefaultTheme, status: SimpleTimeLineStatus): string => {
    switch (status) {
        case SimpleTimeLineStatus.History:
            return transparentize(0.5, theme.C_ACT_main);
        case SimpleTimeLineStatus.Current:
            return theme.C_ACT_main;
        case SimpleTimeLineStatus.Future:
            return "transparent";
    }
};

const getPointBorderColor = (theme: DefaultTheme, status: SimpleTimeLineStatus): string => {
    switch (status) {
        case SimpleTimeLineStatus.History:
            return transparentize(0.5, theme.C_ACT_main);
        case SimpleTimeLineStatus.Current:
            return theme.C_ACT_main;
        case SimpleTimeLineStatus.Future:
            return theme.C_ACT_main;
    }
};

interface IPoint {
    status?: SimpleTimeLineStatus;
    isFirst?: boolean;
    isLast?: boolean;
    isNextFuture?: boolean;
    topOffset?: string;
    bottomLineOverflow?: string;
}

const LineStyles = css<IPoint>`
    content: "";
    position: absolute;
    width: 1px;
    border: 1px;
    border-color: ${props => props.status === SimpleTimeLineStatus.Future ? props.theme.C_ACT_main : transparentize(0.5, props.theme.C_ACT_main)};


`;

const TopLineStyles = css<IPoint>`
    ${LineStyles};
    top: 0;
    height: calc(${props => props.topOffset} - ${SIMPLE_TIMELINE_POINT_SIZE / 2}px);
    border-style: ${props => props.status === SimpleTimeLineStatus.Future ? "dashed" : "solid"};
`;

const BottomLineStyles = css<IPoint>`
    ${LineStyles};
    top: calc(${props => props.topOffset} + ${SIMPLE_TIMELINE_POINT_SIZE / 2}px);
    // -3 px so that the dashed lines (top and bottom) do not connect into one continue line and there is a correct space between them 
    height: calc(100% - ${props => props.isNextFuture ? "3px" : "0px"} - ${props => props.topOffset} - ${SIMPLE_TIMELINE_POINT_SIZE / 2}px + ${props => props.bottomLineOverflow});
    border-color: ${props => props.isNextFuture ? props.theme.C_ACT_main : transparentize(0.5, props.theme.C_ACT_main)};
    border-style: ${props => props.isNextFuture ? "dashed" : "solid"};
`;

export const StyledSimpleTimelineItem = styled.div<IPoint>`
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;

    ${props => !props.isFirst && css<IPoint>`
        &:before {
            ${TopLineStyles};
        }
    `};

    ${props => !props.isLast && css<IPoint>`
        &:after {
            ${BottomLineStyles};
        }
    `};
`;


export const SimpleTimeLinePoint = styled.div<IPoint>`
    position: relative;
    width: ${SIMPLE_TIMELINE_POINT_SIZE}px;
    height: ${SIMPLE_TIMELINE_POINT_SIZE}px;
    border-radius: 50%;
    background-color: ${props => getPointColor(props.theme, props.status)};
    border: 1px solid ${props => getPointBorderColor(props.theme, props.status)};
    top: calc(${props => props.topOffset} - ${SIMPLE_TIMELINE_POINT_SIZE / 2}px);
`;

export const StyledSimpleTimeline = styled.div`
    display: flex;
    flex-direction: column;

    & ${StyledSimpleTimelineItem} {
        height: 50px;
    }
`;