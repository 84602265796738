import React, { useCallback, useContext, useEffect, useState } from "react";
import { Background, TooltipArrow } from "../tooltipIcon/TooltipIcon.styles";
import { focusNextElement, getFocusableElements, getValue, handleRefHandlers } from "@utils/general";
import TestIds from "../../testIds";
import { usePopper } from "react-popper";
import ReactDOM from "react-dom";
import * as PopperJS from "@popperjs/core";
import { DefaultTheme } from "styled-components";
import { StyledPopperTooltip } from "./PopperTooltip.styles";
import { AppContext, ContextEvents } from "../../contexts/appContext/AppContext.types";
import { KeyName } from "../../keyName";

export interface IProps {
    /** Content of the opened tooltip.
     * Can receive content as callback - performance optimization. We don't need to render tooltips that are not opened. */
    content: React.ReactNode | (() => React.ReactNode);
    /** Pass the reference element */
    children: (reference: React.Ref<any>) => React.ReactElement;
    /** Render in the current DOM instead of React.Portal to support different cases  */
    isHidden?: boolean;
    color?: string | keyof DefaultTheme;
    inPlace?: boolean;
    /** By default, if inPlace==false, tooltip is rendered via React.Portal into "context-menu-root",
     * use domPortalParent to choose custom DOM parent element */
    domPortalParent?: React.RefObject<HTMLElement>;
    useFade?: boolean;
    offsetY?: number;
    offsetX?: number;
    placement?: PopperJS.Placement;
    onAnimationEnd?: (event: React.AnimationEvent) => void;
    passRef?: React.Ref<HTMLDivElement>;
    className?: string;
    style?: React.CSSProperties;
    hideArrow?: boolean;
}

/** Tooltip with dark background and pointer arrow, positioned by Popper.js */
const PopperTooltip = React.memo((props: React.PropsWithChildren<IProps>) => {
    const appContext = useContext(AppContext);
    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const [arrowElement, setArrowElement] = useState(null);
    const usedPopper = usePopper(referenceElement, popperElement, {
        placement: props.placement ?? "top",
        modifiers: [
            { name: "arrow", options: { element: arrowElement } },
            { name: "offset", options: { offset: [props.offsetX ?? 0, props.offsetY] } },
            // add 19px padding from window borders
            { name: "preventOverflow", options: { mainAxis: true, padding: 19 } }
        ]
    });
    const onPopperUpdate = useCallback(() => {
        if (!props.isHidden && usedPopper.update) {
            usedPopper.update();
        }
    }, [props.isHidden, usedPopper]);

    useEffect(() => {
        appContext?.eventEmitter?.on(ContextEvents.UpdatePoppers, onPopperUpdate);

        return () => {
            appContext?.eventEmitter?.off(ContextEvents.UpdatePoppers, onPopperUpdate);
        };
    }, [appContext, onPopperUpdate]);

    /** If inPlace==false, default browser tabindex order is broken for keyboard navigation.
     * When use press Tab on the reference element, set the focus inside the PopperTooltip to enable keyboard navigation. */
    const onReferenceElementKeydown = useCallback((event: KeyboardEvent) => {
        if (!props.inPlace && !props.isHidden && event.key === KeyName.Tab && !event.shiftKey) {
            const focusableElements = getFocusableElements(popperElement);

            if (focusableElements?.length > 0) {
                event.stopPropagation();
                event.preventDefault();
                focusableElements[0]?.focus();
            }
        }
    }, [popperElement, props.isHidden, props.inPlace]);

    useEffect(() => {
        referenceElement?.addEventListener("keydown", onReferenceElementKeydown);

        return () => {
            referenceElement?.removeEventListener("keydown", onReferenceElementKeydown);
        };
    }, [referenceElement, onReferenceElementKeydown]);

    /** If inPlace==false, default browser tabindex order is broken for keyboard navigation.
     * When user press Tab inside the PopperTooltip and the focus should move out of it,
     * return it to the reference element or move it to the element after the reference element.*/
    const onPopperElementKeyDown = useCallback((event: KeyboardEvent) => {
        if (!props.inPlace && !props.isHidden && event.key === KeyName.Tab) {
            const focusableElements = getFocusableElements(popperElement);

            if (!event.shiftKey) {
                // forward
                if (document.activeElement === focusableElements[focusableElements.length - 1]) {
                    event.stopPropagation();
                    event.preventDefault();
                    focusNextElement(referenceElement);
                }
            } else {
                // backward
                if (document.activeElement === focusableElements[0] && referenceElement) {
                    event.stopPropagation();
                    event.preventDefault();
                    referenceElement.focus();
                }
            }
        }
    }, [popperElement, referenceElement, props.isHidden, props.inPlace]);

    useEffect(() => {
        if (!props.inPlace && !props.isHidden && popperElement) {
            popperElement?.addEventListener("keydown", onPopperElementKeyDown);

        }

        return () => {
            popperElement?.removeEventListener("keydown", onPopperElementKeyDown);
        };
    }, [props.inPlace, props.isHidden, popperElement, onPopperElementKeyDown]);

    const renderTooltip = (isGlobal?: boolean) => {
        return !props.isHidden &&
                (
                        <StyledPopperTooltip
                                // for HideOnBlur to work
                                tabIndex={-1}
                                isGlobal={isGlobal}
                                onClick={(e) => {
                                    // stop event here, to prevent e.g. triggering sort change event in table
                                    e.stopPropagation();
                                }}
                                useFade={props.useFade}
                                onAnimationEnd={props.onAnimationEnd}
                                ref={(ref) => {
                                    handleRefHandlers(ref, setPopperElement, props.passRef);
                                }}
                                className={props.className}
                                style={{
                                    ...usedPopper.styles.popper,
                                    ...props.style
                                }}
                                {...usedPopper.attributes.popper}
                                data-testid={TestIds.Tooltip}>
                            {!props.hideArrow && <TooltipArrow ref={setArrowElement}
                                    // for HideOnBlur to work
                                                               tabIndex={-1}
                                                               color={props.color}
                                                               style={usedPopper.styles.arrow}
                                                               {...usedPopper.attributes.arrow}/>}
                            <Background color={props.color}/>
                            {getValue(props.content)}
                        </StyledPopperTooltip>
                );
    };

    const contextMenuRoot = !props.inPlace && (props.domPortalParent?.current ?? document.getElementById("context-menu-root"));
    const tooltip = contextMenuRoot ? ReactDOM.createPortal(renderTooltip(true), contextMenuRoot) : renderTooltip();

    return (
            <>
                {props.children?.(setReferenceElement)}
                {tooltip}
            </>
    );
});

export default PopperTooltip;