import { AlertAction, AlertPosition, IAlertProps } from "@components/alert/Alert";
import { WithAlert, withAlert } from "@components/alert/withAlert";
import { WithPromisedComponent, withPromisedComponent } from "@components/dialog/withPromisedComponent";
import { IFileStripItem } from "@components/fileStrip/FileStrip.utils";
import { ISelectItem } from "@components/inputs/select/Select.types";
import { SmartFileStrip } from "@components/smart/smartFileStrip/SmartFileStrip";
import { ISmartODataTableAPI, TSmartODataTableStorage } from "@components/smart/smartTable/SmartODataTableBase";
import { SplitLayout } from "@components/splitLayout";
import { TId } from "@components/table";
import { IRowProps } from "@components/table/Rows";
import { IFileMetadataEntity, IInboxFileEntity } from "@odata/GeneratedEntityTypes";
import { ApprovalStatusTypeCode, InboxEntityTypeCode } from "@odata/GeneratedEnums";
import { BatchRequest } from "@odata/OData";
import { parseError } from "@odata/ODataParser";
import { WithOData, withOData } from "@odata/withOData";
import { isRossumModuleActivated } from "@pages/admin/subscriptions/Subscriptions.utils";
import { toggleItemInArray } from "@utils/general";
import { isFileRossumEligible, ROSSUM_EXTRACT_URL } from "@utils/Rossum.utils";
import i18next from "i18next";
import React, { PureComponent } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { withTheme } from "styled-components/macro";

import { AppContext, IAppContext } from "../../contexts/appContext/AppContext.types";
import { PaneStatus, Status } from "../../enums";
import { LocalStackOrder } from "../../global.style";
import { TRecordAny, TRecordString, TRecordType } from "../../global.types";
import { ITableStorageDefaultCustomData, TableStorage } from "../../model/TableStorage";
import BindingContext from "../../odata/BindingContext";
import TestIds from "../../testIds";
import { PropsWithTheme } from "../../theme";
import customFetch from "../../utils/customFetch";
import { getAlertFromError } from "../../views/formView/Form.utils";
import { ISplitPageTableDef } from "../../views/table/TableView.utils";
import { ISplitPagePaneData } from "../Page";
import { InboxSplitPageStyled, PaneStyled } from "./Inbox.styles";
import {
    attachInboxFile,
    getDragImage,
    getEntityTypeFileStripItems,
    getFilterAndEntityTypeFromSelectItem,
    getFolderItemCounts,
    getInboxFilesBindingContext,
    getPaneIcon,
    handleAttachFiles,
    IAttachFileResult,
    IInboxTableCustomData,
    InboxFilter,
    isSupportedFolderForDataMining
} from "./Inbox.utils";
import InboxFakeSplitter from "./InboxFakeSplitter";
import InboxFileDetail from "./InboxFileDetail";
import InboxMultipleFilesSelected from "./InboxMultipleFilesSelected";
import InboxTableView, { COMPLETE_ACTION_ID, IInboxTableViewProps } from "./InboxTableView";
import RossumDialog from "./RossumDialog";

interface IInboxSplitPageCustomData extends ITableStorageDefaultCustomData {
    type?: InboxFilter;
}

interface IProps extends WithOData, PropsWithTheme, RouteComponentProps, WithAlert, WithPromisedComponent {
    t: any;

    paneData: ISplitPagePaneData<ISplitPageTableDef>;
    selectedFolderId?: string;

    onFolderSelection?: (folder: string) => void;

    // Other folder in editable window
    inEditableWindow?: boolean;
    onAttachFiles?: (Ids: TId[]) => Promise<IAlertProps>;
    hideInboxFileIds?: number[];

    supportedEntities?: InboxEntityTypeCode[];
}

interface IState {
    paneStatus: PaneStatus[];
    draggedRows: BindingContext[];
    dragOverId?: string;
    counts?: TRecordType<number>;
    fileStripItemsCounts?: TRecordType<number>;
    selectedInboxFiles?: BindingContext[];
}

const defaultPaneStatus = [PaneStatus.Expanded, PaneStatus.Normal];
const withRightPaneStatus = [PaneStatus.Normal, PaneStatus.Normal];

class InboxSplitPage extends PureComponent<IProps, IState> {
    static contextType = AppContext;
    //sadly, breaks typescript type checking
    //context: React.ContextType<typeof AppContext>;

    storage: TSmartODataTableStorage<IInboxTableCustomData>;
    #tableViewRef = React.createRef<React.Component<IInboxTableViewProps>>();

    #canvasEl: HTMLCanvasElement;

    constructor(props: IProps, context: IAppContext) {
        super(props);

        this.storage = this.createAndInitStorage(this.props.paneData, this.#tableViewRef, context);
        const paneStatus = [...defaultPaneStatus];

        this.state = {
            paneStatus,
            draggedRows: [],
            selectedInboxFiles: []
        };
    }

    componentDidMount() {
        this.updateFileStripItemsCounts();
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>) {
        const { selectedInboxFiles } = this.state;
        if (selectedInboxFiles !== prevState.selectedInboxFiles) {
            if (!selectedInboxFiles?.length) {
                // InboxFilePreview closed
                this.setPaneStatus(defaultPaneStatus);
            } else if (this.state.selectedInboxFiles?.length === 1 &&
                prevState.selectedInboxFiles?.length <= 1 &&
                prevState.selectedInboxFiles?.[0] !== this.state.selectedInboxFiles[0]) {
                // opens detail panel automatically if there is just one file selected and
                // previous selection is different from the current selection
                this.setPaneStatus(withRightPaneStatus);
            } else {
                // todo: switch from one opened Inbox preview to another
            }
        }

        if (this.props.selectedFolderId !== prevProps.selectedFolderId) {
            this.reInitStorage(this.props.paneData);
            this.props.setAlert(null);
        }

        if (prevProps.hideInboxFileIds !== this.props.hideInboxFileIds) {
            this.storage.applyFilters();
            this.storage.refresh();
        }
    }

    componentWillUnmount() {
        this.removeDragListeners();
    }

    get renderRightPane(): boolean {
        return !!(this.state.selectedInboxFiles?.length);
    }

    get isDragging() {
        return !!this.state.draggedRows?.length;
    }

    get isApprovalPage() {
        return this.storage.getCustomData().type === InboxFilter.ForApprove;
    }

    get supportedEntities() {
        return this.props.supportedEntities ?? [];
    }

    updateFileStripItemsCounts = async (): Promise<void> => {
        const countStatus = [
            ApprovalStatusTypeCode.NotNeeded,
            ApprovalStatusTypeCode.Approved,
            ApprovalStatusTypeCode.Canceled
        ];
        const fileStripItemsCounts = await getFolderItemCounts(this.props.oData, countStatus);

        this.setState({
            fileStripItemsCounts
        });
    };

    removeFromSelection(bcs: BindingContext[]) {
        const { selectedInboxFiles } = this.state;
        const selectedWithoutRemoved = selectedInboxFiles?.filter(bc => !bcs.find(removedBc => removedBc.toString() === bc.toString()));
        if (selectedWithoutRemoved?.length !== selectedInboxFiles?.length) {
            this.setState({ selectedInboxFiles: selectedWithoutRemoved ?? [] });
        }
    }

    handlePaneStatusChange = (paneStatus: PaneStatus[]): void => {
        if (!this.renderRightPane) {
            // pane status cannot be changed if there is only one non-fixed pane
            return;
        }
        // replace invalid statuses
        paneStatus[2] = PaneStatus.Normal;
        if (paneStatus[0] === PaneStatus.Collapsed && paneStatus[1] === PaneStatus.Collapsed) {
            paneStatus[0] = PaneStatus.Expanded;
        }
        // apply
        this.setPaneStatus(paneStatus);
    };

    setPaneStatus = (paneStatus: PaneStatus[]): void => {
        this.setState({
            paneStatus: [...paneStatus]
        });
    };

    handleFolderSelection = (item: IFileStripItem): void => {
        this.setPaneStatus(withRightPaneStatus);
        this.props.onFolderSelection?.(item?.id);
        this.setState({ selectedInboxFiles: [] });
    };

    handleFolderUnSelect = (): void => {
        this.setPaneStatus(defaultPaneStatus);
        this.props.onFolderSelection?.(null);
        this.setState({ selectedInboxFiles: [] });
    };

    _isSortingFiles = false;

    async sortFiles(selectedRows: BindingContext[], folder: ISelectItem) {
        this._isSortingFiles = true;
        const { entityType } = getFilterAndEntityTypeFromSelectItem(folder);
        const path = selectedRows[0]?.removeKey().toString();

        const values: Partial<IInboxFileEntity> = {
            IsSorted: true,
            EntityTypeCode: entityType ?? null
        };

        const batch: BatchRequest = this.props.oData.batch();
        batch.beginAtomicityGroup("group1");

        const queryableEntity = batch.fromPath(path);

        selectedRows.forEach(row => {
            queryableEntity.update(row.getKey(), values);
        });

        const result = await batch.execute();

        if (result[0]?.status < 300) {
            this.removeFromSelection(selectedRows);
            // refresh tables, which contained sorted files
            await this.storage?.tableAPI?.reloadTable();
            this.updateFileStripItemsCounts();
        } else {
            // todo: better error handling
            this.props.setAlert({
                status: Status.Error,
                title: this.props.t("Common:Errors.ErrorHappened"),
                action: AlertAction.Close
            });
        }
        this._isSortingFiles = false;
        this.clearDraggingState();
    }

    handleContextMenu = (selectedRows: BindingContext[], item: ISelectItem) => {
        this.sortFiles(selectedRows, item);
    };

    addDragListeners() {
        document.addEventListener("drag", this.handleDrag);
        document.addEventListener("dragover", this.handleDragOver);
        document.addEventListener("dragend", this.handleDragEnd);
    }

    removeDragListeners() {
        document.removeEventListener("drag", this.handleDrag);
        document.removeEventListener("dragover", this.handleDragOver);
        document.removeEventListener("dragend", this.handleDragEnd);
    }

    handleDragStart = (selectedRows: BindingContext[], fileNames: string[], event: React.DragEvent) => {
        // ux spec wants different style for the ghost image than browser provides,
        // and browser won't let us style it
        // => hide the browser's ghost image and draw and move our own manually
        const canvas = getDragImage(fileNames, this.props.theme, this.storage.t("Inbox:TableView.WithoutAttachment"));
        const fakeGhost = new Image();

        canvas.style.position = "fixed";
        canvas.style.zIndex = `${LocalStackOrder.AboveOverlay}`;


        event.dataTransfer.effectAllowed = "move";
        event.dataTransfer.dropEffect = "move";
        event.dataTransfer.setDragImage(fakeGhost, 0, 0);

        document.body.appendChild(canvas);

        this.#canvasEl = canvas;
        this.setGhostElementPosition(event.pageX, event.pageY);
        this.addDragListeners();

        if (selectedRows.length === 1) {
            this._lastSelectedRow = selectedRows[0];
        }
        this.setState({ draggedRows: selectedRows });
    };

    handleDrop = async (item: IFileStripItem, event: React.DragEvent) => {
        const tableView = this.#tableViewRef.current;
        tableView.props.setBusy(true);
        tableView.forceUpdate();

        await this.sortFiles(this.state.draggedRows, item);

        tableView.props.setBusy(false);
        tableView.forceUpdate();
    };

    handleDrag = (event: DragEvent): void => {
        event.preventDefault();
    };

    handleDragOver = (event: DragEvent): void => {
        this.setGhostElementPosition(event.pageX, event.pageY);
    };

    setGhostElementPosition = (pageX: number, pageY: number): void => {
        this.#canvasEl.style.left = `${pageX - 40}px`;
        this.#canvasEl.style.top = `${pageY + 5}px`;
    };

    handleTableViewDrop = (event: React.DragEvent) => {
        const selectedFolder = getEntityTypeFileStripItems(this.supportedEntities).find(item => item.id === this.props.selectedFolderId);
        return this.handleDrop(selectedFolder, event);
    };

    handleDragEnter = (id: string = this.props.selectedFolderId) => {
        this.setState({
            dragOverId: id
        });
    };

    handleDragLeave = (id: string = this.props.selectedFolderId) => {
        this.setState(state => ({
            dragOverId: state.dragOverId === id ? null : state.dragOverId
        }));
    };

    handleDragEnd = () => {
        if (this.#canvasEl) {
            document.body.removeChild(this.#canvasEl);
        }
        this.removeDragListeners();
        if (!this._isSortingFiles) {
            this.clearDraggingState();
        }
    };

    clearDraggingState() {
        this.setState({ draggedRows: [], dragOverId: null });
    }

    async getSelectedRowsInInterval(start: BindingContext, end: BindingContext): Promise<BindingContext[]> {
        const selectedRows: BindingContext[] = [];
        // marks all rows in between these two as selected
        const rowsArray = this.storage.tableAPI.getRowsArray();
        const selectionInterval = [start?.toString(), end?.toString()];
        let shouldIncludeRow = false;
        let notLoaded = false;
        for (let i = 0; i < rowsArray.length; ++i) {
            if (!rowsArray[i]) {
                notLoaded = true;
                break;
            }
            const bc = rowsArray[i].id as BindingContext;
            const startOrEnd = selectionInterval.includes(bc.toString());
            if (startOrEnd) {
                shouldIncludeRow = !shouldIncludeRow;
            }
            if ((shouldIncludeRow || startOrEnd)) {
                selectedRows.push(bc);
            }
            if (startOrEnd && !shouldIncludeRow) {
                break;
            }
        }
        if (notLoaded) {
            // download data and repeat the process
            await this.storage.tableAPI.reloadTable({ loadAll: true });
            return this.getSelectedRowsInInterval(start, end);
        }
        return selectedRows;
    }

    _lastSelectedRow: BindingContext = null;
    handleRowSelect = async (bc: BindingContext, props?: IRowProps, params?: TRecordString, customData?: TRecordAny): Promise<void> => {
        if (bc === null) {
            this._lastSelectedRow = bc;
            this.setState({ selectedInboxFiles: [] });
        } else if (this.isApprovalPage) {
            // in approval table, only single row can be selected (file detail opened)
            this.setState({ selectedInboxFiles: [bc] });
        } else {
            const { modifiers } = customData ?? {};
            let selectedRows: BindingContext[];

            if (modifiers?.shiftKey && this._lastSelectedRow && this._lastSelectedRow !== bc) {
                selectedRows = await this.getSelectedRowsInInterval(bc, this._lastSelectedRow);
            } else {
                selectedRows = [...((modifiers?.ctrlKey || modifiers?.metaKey) ? this.state.selectedInboxFiles ?? [] : [])];
                toggleItemInArray(selectedRows, bc);
                this._lastSelectedRow = bc;
            }
            this.setState({ selectedInboxFiles: selectedRows }, () => this.forceUpdate());
        }
    };

    handleAttachFiles = async (fileIds: TId[]): Promise<IAttachFileResult> => {
        if (this.props.inEditableWindow) {
            const res = await this.props.onAttachFiles?.(fileIds);
            return {
                alert: res,
                shouldRefreshTable: res.status === Status.Success
            };
        } else {
            const tableRows = this.storage.tableAPI.getRowsArray();
            const selectedRows = tableRows.filter(row => fileIds.includes((row.id as BindingContext).getKey().toString()));
            const rossumActivated = isRossumModuleActivated(this.storage);
            const rossumEligibleInboxFiles = isSupportedFolderForDataMining(this.props.selectedFolderId) && rossumActivated && selectedRows
                .filter(row => isFileRossumEligible((row.customData.entity as IInboxFileEntity)?.FileMetadata))
                .map(row => row.customData.entity as IInboxFileEntity);
            let inboxFilesForRossum: IInboxFileEntity[];
            let actionCanceled = false;

            if (rossumEligibleInboxFiles?.length > 0) {
                inboxFilesForRossum = await this.props.promisedComponent.openComponent<IInboxFileEntity[]>((onFinish) => {
                    return (
                        <RossumDialog
                            onConfirm={(files: IFileMetadataEntity[]) => onFinish(rossumEligibleInboxFiles.filter(inboxFile => files.find(file => file.Id === inboxFile.FileMetadata.Id)))}
                            onCancel={() => onFinish(null)}
                            files={rossumEligibleInboxFiles.map(file => file.FileMetadata)}
                        />
                    );
                });

                // null means cancel, empty array just means that rossum won't be applied to any file
                if (!inboxFilesForRossum) {
                    actionCanceled = true;
                }
            }

            if (!actionCanceled) {
                // tableView doesn't handle busy state for files attaching,
                // because we don't want the view to be busy when the RossumDialog is opened
                // => set busy state here instead
                const tableView = this.#tableViewRef.current;

                tableView.props.setBusy(true);
                tableView.forceUpdate();

                const rossumRes: Response[] = [];
                if (inboxFilesForRossum?.length > 0) {
                    // don't apply attachInboxFile on files processed by rossum
                    const rossumConfirmedInboxFiles = rossumEligibleInboxFiles.filter(file => inboxFilesForRossum.find(f => f.Id === file.Id));

                    fileIds = fileIds.filter(id => !rossumConfirmedInboxFiles.find(file => file.Id === parseInt(id.toString())));

                    const documentType = this.props.selectedFolderId;

                    // await request before refreshing the table
                    rossumRes.push(
                        await customFetch(`${ROSSUM_EXTRACT_URL}/${documentType}/?${inboxFilesForRossum.map(file => `ids=${file.Id}`).join("&")}`)
                    );
                }

                const entityType = this.props.selectedFolderId as InboxEntityTypeCode;
                const alert = await handleAttachFiles("Inbox:TableView.CompleteSuccess", () => attachInboxFile(fileIds, entityType));
                this.updateFileStripItemsCounts();

                // check if there is any error in Rossum requests
                const errorRes = rossumRes?.find(response => response.status !== 200);
                const shouldRefreshTable = alert.status === Status.Success || rossumRes?.some(response => response.status === 200);

                if (alert.status === Status.Success && errorRes) {
                    const error = parseError(await errorRes.json());
                    const alertProps = error ? getAlertFromError(error) : null;

                    alert.status = Status.Error;
                    alert.title = this.props.t("Common:Errors.ErrorHappened");
                    alert.subTitle = alertProps?.subTitle ?? this.props.t("Inbox:TableView.CompleteErrorRossum");
                    alert.action = AlertAction.Close;
                }

                return {
                    alert,
                    shouldRefreshTable
                };
            } else {
                return null;
            }
        }
    };

    handleAfterUpdateFile = (): void => {
        this.updateFileStripItemsCounts();
    };

    handleAfterRemoveFiles = (bcs: BindingContext[]): void => {
        this.updateFileStripItemsCounts();
        // if we removed file with opened detail, close it
        this.removeFromSelection(bcs);
    };

    handleTableLoad = (storage: TSmartODataTableStorage) => {
        // we need to check if there are any files and re-render, so correct icon is displayed
        const count = storage.tableAPI.getState().rowCount;
        const id = storage.tableId;
        this.setState(({ counts }) => ({
            counts: {
                ...counts,
                [id]: count
            }
        }));
    };

    hasRows(tableId: string): boolean {
        return tableId && !!this.state.counts?.[tableId];
    }

    createTableStorage(data: ISplitPagePaneData, ref: React.RefObject<React.Component<IInboxTableViewProps>>, context: IAppContext) {
        return new TableStorage<ISmartODataTableAPI, IInboxSplitPageCustomData>({
            oData: this.props.oData,
            t: this.props.t,
            definition: data.def,
            history: this.props.history,
            match: this.props.match,
            theme: this.props.theme,
            context,
            id: data.def.id,
            refresh: async () => {
                const tableViewRef = ref.current as any;

                if (tableViewRef?.beforeRefresh) {
                    await tableViewRef.beforeRefresh();
                }
                tableViewRef?.forceUpdate();
            },
            // copy state in constructor instead of using it directly in the rest of the app
            // because browser history state is changed on any navigation
            initialHistoryState: this.props.history.location.state
        });
    }

    async initStorage(storage: TSmartODataTableStorage<IInboxTableCustomData>, definition: ISplitPageTableDef): Promise<void> {
        await storage.init({
            bindingContext: getInboxFilesBindingContext(this.props.oData),
            definition
        });
        this.forceUpdate();

        return;
    }

    createAndInitStorage(data: ISplitPagePaneData, ref: React.RefObject<React.Component<IInboxTableViewProps>>, context: IAppContext) {
        const storage = this.createTableStorage(data, ref, context);
        storage.setCustomData(data.customData);
        if (this.props.inEditableWindow) {
            storage.data.tableAction = COMPLETE_ACTION_ID;
        }
        this.initStorage(storage, data.def as ISplitPageTableDef);
        return storage;
    }

    reInitStorage(data: ISplitPagePaneData) {
        const { storage } = this;
        storage.setCustomData(data.customData);
        storage.data.definition = data.def as ISplitPageTableDef;
        storage.clearFilters();
        // clearFilters doesn't apply any filter if there are no filters set,
        // but we need to update base query from definition
        storage.applyFilters();
        this.refreshTable(storage);
        this.forceUpdate();
    }

    refreshTable(storage: TSmartODataTableStorage, rowBc?: BindingContext) {
        if (!rowBc) {
            // in case we want to reload everything - table, tabs..
            storage.updateUuid();
            storage.refresh();
        } else {
            // reload just one row of the table according to rowBc
            storage.tableAPI.reloadRow(rowBc);
        }
    }

    renderTable(data: ISplitPagePaneData, storage: TSmartODataTableStorage, ref: React.Ref<any>, key?: string): React.ReactElement {
        let props: Partial<IInboxTableViewProps> = {};

        if (!this.isApprovalPage) {
            const { type, entityType } = this.storage.getCustomData();
            if (type !== InboxFilter.Unsorted) {
                props = {
                    ...props,
                    onDrop: this.handleTableViewDrop,
                    onDragEnter: () => this.handleDragEnter(),
                    onDragLeave: () => this.handleDragLeave(),
                    folderId: entityType ?? InboxFilter.Other
                };
            }
            if (type === InboxFilter.Entity || this.props.inEditableWindow) {
                props.onAttachFiles = this.handleAttachFiles;
            }
            props.onDragStart = this.handleDragStart;
        }
        // used also for approval - when items are approved, they are removed from list
        props.onAfterRemoveFiles = this.handleAfterRemoveFiles;

        const { selectedInboxFiles, draggedRows } = this.state;
        const selectedRows = (draggedRows?.length ? draggedRows : selectedInboxFiles) ?? [];
        return (
            <InboxTableView ref={ref}
                            key={key}
                            storage={storage}
                            isDragging={this.isDragging}
                            inEditableWindow={this.props.inEditableWindow}
                            onContextMenu={this.handleContextMenu}
                            onAfterUpdateFile={this.handleAfterUpdateFile}
                            onRowSelect={this.handleRowSelect}
                            selectedRows={selectedRows}
                            supportedEntities={this.supportedEntities}
                            onTableLoad={this.handleTableLoad.bind(this, storage)}
                            {...props} />
        );
    }

    render() {
        const { selectedFolderId, inEditableWindow } = this.props;
        const { selectedInboxFiles, fileStripItemsCounts } = this.state;
        const isApproval = this.isApprovalPage;
        const multipleDetail = selectedInboxFiles?.length > 1;
        const tableViewHasRows = this.hasRows(this.storage?.tableId);
        const isRightInboxPane = !!(!isApproval && selectedFolderId) && !inEditableWindow;
        const isLeftInboxPane = !isApproval && !selectedFolderId && !inEditableWindow;
        return (
            <InboxSplitPageStyled data-testid={TestIds.InboxPage} $stripOnTheLeft={isRightInboxPane}>
                {isRightInboxPane && (<>
                    <InboxFakeSplitter onClick={this.handleFolderUnSelect}
                                       iconName={getPaneIcon(fileStripItemsCounts?.[InboxFilter.Unsorted] > 0)}/>
                    <SmartFileStrip dialogTitle={i18next.t("Inbox:CustomizationDialogTitle")}
                                    items={getEntityTypeFileStripItems(this.supportedEntities, true, fileStripItemsCounts)}
                                    oData={this.props.oData}
                                    onSelectionChange={this.handleFolderSelection}
                                    isDragging={this.isDragging}
                                    dragOverId={this.state.dragOverId}
                                    onDrop={this.handleDrop}
                                    onDragEnter={this.handleDragEnter}
                                    onDragLeave={this.handleDragLeave}
                                    selectedId={selectedFolderId}/>
                </>)}
                <SplitLayout onPaneStatusChanged={this.handlePaneStatusChange}
                             paneStatus={this.state.paneStatus}>
                    <PaneStyled visible
                                order={0} addBackground
                                width={this.renderRightPane ? "50%" : "100%"}
                                showFakeBubble={isLeftInboxPane}
                                showPaneGradient={tableViewHasRows && isRightInboxPane}
                                showSplitterGradient={tableViewHasRows && isRightInboxPane}
                                icon={getPaneIcon(tableViewHasRows)}>
                        {this.storage?.loaded && this.renderTable(this.props.paneData, this.storage, this.#tableViewRef)}
                    </PaneStyled>
                    <PaneStyled visible={this.renderRightPane}
                                width="50%" order={1}
                                icon={getPaneIcon(true)}
                                showPaneGradient={isRightInboxPane}
                                isLast={!isLeftInboxPane}>
                        {this.renderRightPane && (
                            multipleDetail ? (
                                <InboxMultipleFilesSelected count={selectedInboxFiles.length}/>
                            ) : (
                                <InboxFileDetail storage={this.storage}
                                                 bindingContext={selectedInboxFiles[0]}
                                                 onAfterRemoveFiles={this.handleAfterRemoveFiles}/>
                            )
                        )}
                    </PaneStyled>
                </SplitLayout>
                {isLeftInboxPane && (
                    <SmartFileStrip dialogTitle={i18next.t("Inbox:CustomizationDialogTitle")}
                                    items={getEntityTypeFileStripItems(this.supportedEntities, true, fileStripItemsCounts)}
                                    oData={this.props.oData}
                                    onSelectionChange={this.handleFolderSelection}
                                    isDragging={this.isDragging}
                                    dragOverId={this.state.dragOverId}
                                    onDrop={this.handleDrop}
                                    onDragEnter={this.handleDragEnter}
                                    onDragLeave={this.handleDragLeave}/>
                )}
                {this.props.alert}
            </InboxSplitPageStyled>
        );
    }
}

export default withPromisedComponent(withOData(withTheme(withRouter(withAlert({
    autoHide: true,
    position: AlertPosition.CenteredBottom
})(InboxSplitPage)))));