import React, { useEffect, useReducer } from "react";
import { TFormatterFn } from "../smart/smartTable/SmartTable.utils";
import { TValue } from "../../global.types";
import dayjs from "dayjs";
import DateType, { DateFormat } from "../../types/Date";

interface IProps {
    value: Date;
}

const RelativeTime: React.FC<IProps> = ({ value }) => {
    const [, forceUpdate] = useReducer(x => ++x, 0);

    useEffect(() => {
        const int = setInterval(() => {
            forceUpdate();
        }, 1000);

        return () => clearInterval(int);
    });

    return (<>
        {dayjs(value).fromNow()}
    </>);
};


export const dateTimeFormatter: TFormatterFn = (val: TValue) => DateType.localFormat(val as Date, DateFormat.dateAndTime);

export const relativeTimeTableFormatter: TFormatterFn = (val: TValue) => ({
    tooltip: dateTimeFormatter(val),
    value: (<RelativeTime value={val as Date}/>)
});

export default RelativeTime;