import { Dayjs } from "dayjs";
import React from "react";
import TestIds from "../../../../testIds";
import { DayInner, DayLabel, StyledDay } from "./Day.styles";
import { KeyName } from "../../../../keyName";
import { TRecordAny } from "../../../../global.types";
import DateType, { DateFormat } from "../../../../types/Date";
import { FOCUSABLE_DISABLED_ITEM_ATTR } from "../../../focusManager";

export interface IDaySettingsProps {
    // "hover" state
    isInActiveRange?: boolean;
    // selected state
    isInSelectedRange?: boolean;
    // isInRange?: boolean;
    isFirst?: boolean;
    isLast?: boolean;
    isOutsideMonth?: boolean;
    isSelected?: boolean;
    isCurrent?: boolean;
    isDisabled?: boolean;
    preventHover?: boolean;
}

export interface IDayProps extends IDaySettingsProps {
    day: Dayjs;
    onClick?: (day: Dayjs) => void;
    onMouseEnter?: (day: Dayjs) => void;
    onMouseLeave?: (day: Dayjs) => void;
    onFocus?: (day: Dayjs) => void;
    onBlur?: (day: Dayjs) => void;
    preventHover?: boolean;
    passProps?: TRecordAny;
    passRef?: React.Ref<HTMLDivElement>;
}

export class Day extends React.PureComponent<IDayProps> {

    handleClick = () => {
        if (this.props.onClick) {
            this.props.onClick(this.props.day);
        }
    };

    handleMouseEnter = () => {
        if (this.props.onMouseEnter) {
            this.props.onMouseEnter(this.props.day);
        }
    };

    handleMouseLeave = (event: React.MouseEvent) => {
        if (this.props.onMouseLeave) {
            this.props.onMouseLeave(this.props.day);
        }
    };

    handleFocus = () => {
        if (this.props.onFocus) {
            this.props.onFocus(this.props.day);
        }
    };

    handleBlur = () => {
        if (this.props.onBlur) {
            this.props.onBlur(this.props.day);
        }
    };

    handleKeyDown = (event: React.KeyboardEvent) => {
        if (this.props.onClick && [KeyName.Enter, KeyName.Space].includes(event.key as KeyName)) {
            this.props.onClick(this.props.day);
            event.stopPropagation();
            event.preventDefault();
        }
    };

    render = () => {
        let testId;

        if (this.props.isSelected && this.props.isCurrent) {
            testId = TestIds.DayCurrentSelected;
        } else if (this.props.isSelected) {
            testId = TestIds.DaySelected;
        } else if (this.props.isCurrent) {
            testId = TestIds.DayCurrent;
        } else if (this.props.isOutsideMonth) {
            testId = TestIds.DayOutsideMonth;
        } else {
            testId = TestIds.DayCurrentMonth;
        }

        return (
            <StyledDay isFirst={this.props.isFirst}
                       isLast={this.props.isLast}
                       onMouseEnter={this.handleMouseEnter}
                       onMouseLeave={this.handleMouseLeave}
                       onFocus={this.handleFocus}
                       onBlur={this.handleBlur}
                       isSelected={this.props.isSelected}
                       isDisabled={this.props.isDisabled}
                       isInActiveRange={this.props.isInActiveRange}
                       isInSelectedRange={this.props.isInSelectedRange}
            >
                <DayInner data-testid={testId}
                          {...this.props.passProps}
                          {...{ [FOCUSABLE_DISABLED_ITEM_ATTR]: this.props.isDisabled }}
                          ref={this.props.passRef}
                          preventHover={this.props.preventHover}
                          isSelected={this.props.isSelected}
                          isOutsideMonth={this.props.isOutsideMonth}
                          isCurrent={this.props.isCurrent}
                          onKeyDown={this.handleKeyDown}
                          onClick={this.handleClick}>
                    <DayLabel>{DateType.format(this.props.day, DateFormat.dayOfMonth)}</DayLabel>
                </DayInner>
            </StyledDay>
        );
    };
}
