import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router";
import { LoginTitle } from "./LoginPage.styles";
import i18next from "i18next";
import { Redirect } from "react-router-dom";
import TestIds from "../testIds";
import { ROUTE_LOGIN, ROUTE_LOGIN_RESET_PASS_REQUEST } from "../routes";
import { getVerificationInfo, selectIsInvalidVerificationCode, setAnimationType } from "./state/loginSlice";
import { useLoginDispatch, useLoginSelector } from "./state/hooks";
import Redirects from "./components/Redirects";
import { AnimationType } from "./Login.utils";
import RegisterForm from "./components/RegisterForm";
import { BackArrow } from "./components/BackArrow";

interface IProps extends RouteComponentProps<{ code: string }> {
}

const ResetPassword: React.FC<IProps> = ({ match }) => {

    const dispatch = useLoginDispatch();
    const isInvalidPasswordReset = useLoginSelector(selectIsInvalidVerificationCode);

    const { code } = match.params ?? {};

    useEffect(() => {
        dispatch(getVerificationInfo(code));
        dispatch(setAnimationType(AnimationType.BikerWithSign)); // todo: ...
    }, []);

    if (isInvalidPasswordReset) {
        return (
                <Redirect to={ROUTE_LOGIN_RESET_PASS_REQUEST}/>
        );
    }

    return (<>
        <Redirects skipNoSessionCheck/>
        <LoginTitle data-testid={TestIds.Title}>
            <BackArrow url={ROUTE_LOGIN}/>
            {i18next.t("Login:NewPass.Title")}
        </LoginTitle>
        <RegisterForm isPasswordReset></RegisterForm>
    </>);
};

export default ResetPassword;