import { getTableIntentLink } from "@components/drillDown/DrillDown.utils";
import { IGetValueArgs } from "@components/smart/FieldInfo";
import { withDisplayName } from "@components/smart/GeneralFieldDefinition";
import {
    FilterBarGroup,
    getDefaultFilterGroupDef,
    IFilterGroupDef
} from "@components/smart/smartFilterBar/SmartFilterBar.types";
import { getRouteByDocumentType } from "@odata/EntityTypes";
import { EntitySetName, EntityTypeName } from "@odata/GeneratedEntityTypes";
import { getEnumNameSpaceName } from "@odata/GeneratedEnums.utils";
import { IFormatOptions } from "@odata/OData.utils";
import i18next from "i18next";

import { FieldType, QueryParam, Sort, TextAlign } from "../../../enums";
import { TValue } from "../../../global.types";
import BindingContext from "../../../odata/BindingContext";
import DateType from "../../../types/Date";
import { ISplitPageTableDef } from "../../../views/table/TableView.utils";
import { genericStatusFormatter } from "../../chartOfAccounts/ChartOfAccountsDef";
import { IDefinition, IGetDefinition } from "../../PageUtils";

export const getDefinitions: IGetDefinition = (): IDefinition => {
    const filterBarDef: IFilterGroupDef[] = [{
        ...getDefaultFilterGroupDef(FilterBarGroup.Filters),
        defaultFilters: [
            "DateCreated",
            "CreatedBy"
        ],
        filterDefinition: {
            DateCreated: {},
            ...withDisplayName("CreatedBy")
        },
        isValueHelp: true,
        allowCustomFilters: false
    }];

    const table: ISplitPageTableDef = {
        filterBarDef,
        id: `${EntityTypeName.RecurringTaskExecution}Table`,
        additionalProperties: [
            { id: "ErrorMessage" },
            {
                id: "GeneratedDocumentDraft",
                additionalProperties: [
                    { id: "NumberOurs" },
                    { id: "DocumentTypeCode" }
                ]
            }
        ],
        columns: [
            "DateCreated",
            BindingContext.localContext("TimeCreated"),
            "CreatedBy",
            "ErrorMessage",
            "GeneratedDocument"
        ],
        columnDefinition: {
            DateCreated: {},
            ...withDisplayName("CreatedBy"),
            GeneratedDocument: {
                additionalProperties: [
                    {
                        id: "/GeneratedDocumentDraft",
                        additionalProperties: [
                            { id: "NumberOurs" },
                            { id: "DocumentTypeCode" }
                        ]
                    },
                    { id: "NumberOurs" },
                    { id: "DocumentTypeCode" }
                ],
                formatter: (val: TValue, args?: IFormatOptions) => {
                    const entity = args.entity;
                    const isDraft = !entity.GeneratedDocument?.Id;
                    const doc = isDraft ? entity.GeneratedDocumentDraft : entity.GeneratedDocument;
                    const { Id, NumberOurs, DocumentTypeCode } = doc ?? {};
                    return Id ? getTableIntentLink(NumberOurs ?? i18next.t("RecurringTasks:GoToDoc"), {
                        route: `${getRouteByDocumentType(DocumentTypeCode as typeof DocumentTypeCode)}/${isDraft ? "new" : Id}`,
                        context: args.storage.context,
                        storage: args.storage,
                        customQueryString: isDraft ? `${QueryParam.Drafts}=true&${QueryParam.DraftId}=${Id}` : null
                    }) : i18next.t("RecurringTasks:DocDoesntExist").toString();
                }
            },
            ErrorMessage: {
                label: i18next.t("RecurringTasks:State"),
                formatter: val => genericStatusFormatter(!val)
            },
            [BindingContext.localContext("TimeCreated")]: {
                label: i18next.t("RecurringTasks:TimeCreated"),
                type: FieldType.Date,
                textAlign: TextAlign.Right,
                additionalProperties: [{ id: "DateCreated" }],
                formatter: (val: TValue, args?: IFormatOptions) => {
                    return DateType.localFormat(args.entity?.DateCreated, DateType.defaultTimeFormat);
                }
            }
        },
        title: i18next.t("RecurringTasks:GeneratedTasks"),
        initialSortBy: [{ id: "DateCreated", sort: Sort.Asc }]
    };

    const getEntitySet = (args: IGetValueArgs) => {
        return `${EntitySetName.RecurringTasks}(${args.data?.parentId})/Executions`;
    };

    return {
        entitySet: getEntitySet,
        table
    };
};

getDefinitions.translationFiles = ["RecurringTasks", getEnumNameSpaceName(EntityTypeName.RecurringTaskType)];
