import React, { Component, ErrorInfo } from "react";
import { logger } from "@utils/log";
import { WithTranslation, withTranslation } from "react-i18next";
import Alert from "../alert";
import { Status } from "../../enums";
import Dialog from "../dialog";
import ConfirmationButtons from "../../views/table/ConfirmationButtons";

interface IProps {

}

interface IState {
    error: Error;
}

class ErrorBoundary extends Component<IProps & WithTranslation, IState> {
    state: IState = {
        error: null
    };

    static getDerivedStateFromError(error: Error) {
        // error probably caused by some plugin changing DOM under Reacts hands,
        // typically caused by chrome auto translate https://solitea-cz.atlassian.net/browse/DEV-27578 (which is now disabled),
        // but could be something else as well
        const reactReconciliationError = "NotFoundError: Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.";

        if (error?.toString()?.startsWith(reactReconciliationError)) {
            // ignore this kind of error and keep the app running,
            // could be ok, only e.g. some text may not be re-rendered
            logger.error(`FE react reconciliation error: ${error.message}`, error);
            return null;
        }

        // Update state so the next render will show the fallback UI.
        return { error: error };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // this is for logging and side effects
        logger.error(`FE error: ${error.message}`, error, {
            componentStack: errorInfo?.componentStack
        });
    }

    handleClearError = () => {
        // todo maybe window.location.reload() instead, to really make sure the error is gone?
        this.setState({
            error: null
        });
    };

    renderErrorDialog = () => {
        return (
            <Dialog onConfirm={this.handleClearError}
                    onClose={this.handleClearError}
                    footer={<ConfirmationButtons onConfirm={this.handleClearError}
                                                 onCancel={null}
                                                 useWrapper={false}/>}>
                <Alert status={Status.Error}
                       title={this.props.t("Common:Errors.ErrorHappened")}
                       subTitle={this.props.t("Common:Errors.UnexpectedErrorExplanation")}
                       detailData={{
                           error: this.state.error.stack
                       }}/>
            </Dialog>
        );
    };

    render() {
        return (
            this.state.error ? this.renderErrorDialog() :
                this.props.children
        );
    }
}

const ErrorBoundaryExtended = withTranslation(["Common"])(ErrorBoundary);

export { ErrorBoundaryExtended as ErrorBoundary };