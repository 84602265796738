import { AppContext } from "../../../contexts/appContext/AppContext.types";
import { FormViewForExtend, IFormViewProps } from "../../../views/formView/FormView";
import { IPrLifeInsuranceCompanyEntity, PrLifeInsuranceCompanyEntity } from "@odata/GeneratedEntityTypes";
import {
    handleInsuranceCompanyAfterLoad,
    handleInsuranceCompanyBankChange,
    handleNameChange,
    validateDataBox
} from "../healthInsuranceCompany/insuranceCompanies.utils";
import { ISmartFieldBlur, ISmartFieldChange } from "@components/smart/smartField/SmartField";
import { PrInsuranceCompanyTypeCode } from "@odata/GeneratedEnums";
import { withPermissionContext } from "../../../contexts/permissionContext/withPermissionContext";

interface IProps extends IFormViewProps<IPrLifeInsuranceCompanyEntity, null> {
}

class LifeInsuranceCompanyFormView extends FormViewForExtend<IPrLifeInsuranceCompanyEntity, IProps> {
    static contextType = AppContext;

    static defaultProps = {
        title: "InsuranceCompanies:LifeInsurance.FormTitle"
    };

    async onAfterLoad() {
        const storage = this.props.storage;

        await handleInsuranceCompanyAfterLoad(storage, PrInsuranceCompanyTypeCode.LifeInsuranceCompany);
        return super.onAfterLoad();
    }

    handleNameChange = (e: ISmartFieldChange): void => {
        handleNameChange(e, this.props.storage);
    };

    handleChange(e: ISmartFieldChange): void {
        super.handleChange(e);
        handleInsuranceCompanyBankChange(e, this.props.storage);
        this.handleNameChange(e);
    }

    async handleBlur(e: ISmartFieldBlur): Promise<void> {
        await this.props.storage.handleBlur(e);
        if (e.bindingContext.getPath() === PrLifeInsuranceCompanyEntity.DataBoxId) {
            await validateDataBox(this.props.storage);
        }
        this.props.storage.refreshFields();
    }
}

export default withPermissionContext(LifeInsuranceCompanyFormView);