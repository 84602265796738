import React from "react";
import WriteLine, { IProps as WriteLineProps } from "../writeLine/WriteLine";
import { WithPasswordInput, withPasswordInput } from "../input/withPasswordInput";
import { WithErrorAndTooltipProps } from "../formError/WithErrorAndTooltip";

interface IProps extends WriteLineProps<string>, WithErrorAndTooltipProps {

}

class PasswordInput extends React.PureComponent<IProps & WithPasswordInput> {

    render = () => {
        return (
            <WriteLine {...this.props}
                       passRef={this.props.password.inputRef}
                       onIconClick={this.props.password.onIconClick}
                       type={this.props.password.type}
                       onFocus={this.props.onFocus}
                       onBlur={this.props.onBlur}
                       icon={this.props.password.icon}
                       iconTitle={this.props.password.iconTitle}/>
        );
    };
}

export default withPasswordInput(PasswordInput);