import React from "react";
import Input, { getSharedInputProps, IInputOnChangeEvent, IInputProps, ISharedInputProps } from "../input/Input";
import { FastEntryInputSizes } from "../../../enums";
import { TextMeasure } from "./ResponsiveInput.styles";
import { handleRefHandlers } from "@utils/general";

interface IProps extends IInputProps, ISharedInputProps {
    onWidthChange?: (newWidth: string) => void;
}

interface IState {
    width: FastEntryInputSizes;
}

export default class ResponsiveInput extends React.Component<IProps, IState> {
    _inputRef: React.RefObject<HTMLInputElement> = React.createRef();
    _textMeasure: React.RefObject<HTMLSpanElement> = React.createRef();

    _isControlled: boolean;

    state: IState = {
        width: FastEntryInputSizes.XS
    };

    constructor(props: IProps) {
        super(props);

        this._isControlled = props.width !== undefined;
    }

    componentDidMount(): void {
        this.adaptSize("");
    }

    componentDidUpdate(prevProps: IProps, prevState: IState): void {
        if (!this._isControlled || this.props.value !== prevProps.value) {
            this.adaptSize(prevProps.value as string);
        }
    }

    adaptSize = (oldValue: string) => {
        if (this.props.isReadOnly) {
            return;
        }

        // textMeasure value doesn't have to come from the props for the uncontrolled version
        const textMeasureValue = this._textMeasure.current.textContent;
        this._textMeasure.current.textContent = oldValue;
        const oldWidth = `${this._textMeasure.current.scrollWidth}px`;
        this._textMeasure.current.textContent = textMeasureValue;

        const newWidth = this.getNewWidth(this._textMeasure.current.scrollWidth, this._inputRef.current.clientWidth, parseInt(this.getCurrentWidth()));
        const isWidthChanged = oldWidth !== newWidth;

        if (isWidthChanged) {
            if (!this._isControlled && this.state.width !== newWidth) {
                this.setState({
                    width: newWidth
                });
            }

            this.props.onWidthChange && this.props.onWidthChange(newWidth);
        }
    };

    getNewWidth = (textWidth: number, inputWidth: number, currentWidth: number) => {
        // ordered widths from smallest to biggest
        const widths = [FastEntryInputSizes.XS, FastEntryInputSizes.S, FastEntryInputSizes.M, FastEntryInputSizes.L];
        const padding = currentWidth - inputWidth;

        const newWidth = widths.find((width: string) => {
            return parseInt(width) - padding >= textWidth;
        });

        return newWidth || FastEntryInputSizes.L;
    };

    getCurrentWidth = () => {
        return this._isControlled ? this.props.width : this.state.width;
    };

    handleChange = (event: IInputOnChangeEvent<string>) => {
        if (!this._isControlled) {
            // force update to trigger componentDidUpdate for uncontrolled input value
            this._textMeasure.current.textContent = event.value;
            this.forceUpdate();
        }

        this.props.onChange?.(event);
    };

    handleInputRef = (ref: HTMLInputElement) => {
        handleRefHandlers(ref, this._inputRef, this.props.passRef);
    };

    render = () => {
        return (
            <>
                <Input {...getSharedInputProps(this.props)}
                       value={this.props.value}
                       passRef={this.handleInputRef}
                       onChange={this.handleChange}
                       width={this.getCurrentWidth()}/>
                <TextMeasure ref={this._textMeasure}>
                    {this.props.value}
                </TextMeasure>
            </>
        );
    };
}