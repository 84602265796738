import {
    IKeyboardShortcut,
    ISubscriber,
    KeyboardShortcut
} from "@utils/keyboardShortcutsManager/KeyboardShorcutsManager.utils";
import React from "react";
import * as deviceDetect from "react-device-detect";

import { KeyName } from "../../keyName";

const shortcuts: Record<KeyboardShortcut, IKeyboardShortcut> = {
    [KeyboardShortcut.ALT_D]: {
        id: KeyboardShortcut.ALT_D,
        keys: [
            {
                char: "d",
                altKey: true
            }
        ]
    },
    [KeyboardShortcut.ALT_N]: {
        id: KeyboardShortcut.ALT_N,
        keys: [
            {
                char: "n",
                altKey: true
            }
        ]
    },
    [KeyboardShortcut.ALT_C]: {
        id: KeyboardShortcut.ALT_C,
        keys: [
            {
                char: "c",
                altKey: true
            }
        ]
    },
    [KeyboardShortcut.ALT_S]: {
        id: KeyboardShortcut.ALT_S,
        keys: [
            {
                char: "s",
                altKey: true
            }
        ]
    },
    [KeyboardShortcut.ALT_R]: {
        id: KeyboardShortcut.ALT_R,
        keys: [
            {
                char: "r",
                altKey: true
            }
        ]
    },
    [KeyboardShortcut.ALT_X]: {
        id: KeyboardShortcut.ALT_X,
        keys: [
            {
                char: "x",
                altKey: true
            }
        ]
    },
    [KeyboardShortcut.ALT_UP]: {
        id: KeyboardShortcut.ALT_UP,
        keys: [
            {
                char: KeyName.ArrowUp,
                altKey: true
            }
        ]
    },
    [KeyboardShortcut.ALT_DOWN]: {
        id: KeyboardShortcut.ALT_DOWN,
        keys: [
            {
                char: KeyName.ArrowDown,
                altKey: true
            }
        ]
    },
    [KeyboardShortcut.ALT_SHIFT_UP]: {
        id: KeyboardShortcut.ALT_SHIFT_UP,
        keys: [
            {
                char: KeyName.ArrowUp,
                altKey: true,
                shiftKey: true
            }
        ]
    },
    [KeyboardShortcut.ALT_SHIFT_DOWN]: {
        id: KeyboardShortcut.ALT_SHIFT_DOWN,
        keys: [
            {
                char: KeyName.ArrowDown,
                altKey: true,
                shiftKey: true
            }
        ]
    },
    [KeyboardShortcut.CTRL_Q]: {
        id: KeyboardShortcut.CTRL_Q,
        keys: [
            {
                char: "q",
                ctrlKey: true
            }
        ]
    },
    [KeyboardShortcut.ALT_SHIFT_S]: {
        id: KeyboardShortcut.ALT_SHIFT_S,
        keys: [
            {
                char: "s",
                altKey: true,
                shiftKey: true
            }
        ]
    },
    [KeyboardShortcut.SHIFT_SHIFT]: {
        id: KeyboardShortcut.SHIFT_SHIFT,
        keys: [
            {
                char: KeyName.Shift,
                shiftKey: true
            },
            {
                char: KeyName.Shift,
                shiftKey: true
            }
        ]
    }
};

class KeyboardShortcutsManager {
    #subscribers: ISubscriber[] = [];
    pressedKeys: string[] = [];
    #pressTimer: ReturnType<typeof setTimeout>;
    static PARTIAL_SHORTCUT = "partial";

    // we start with global handling, but subscribe could be provided DOM ref,
    // to only handle key presses from that DOM subtree
    public subscribe = (subscriber: ISubscriber): () => void => {
        this.#subscribers.push(subscriber);

        if (this.#subscribers.length === 1) {
            this.startListening();
        }

        return /*unsubscribe*/() => {
            const index = this.#subscribers.findIndex(s => s === subscriber);
            this.#subscribers.splice(index, 1);

            if (this.#subscribers.length === 0) {
                this.stopListening();
            }
        };
    };

    private startListening = (): void => {
        document.body.addEventListener("keydown", this.handleKeyDown);
    };

    private stopListening = (): void => {
        document.body.removeEventListener("keydown", this.handleKeyDown);
    };

    _notifySubscribers = (shortcut: KeyboardShortcut, event: KeyboardEvent, subscribers: ISubscriber[]): boolean => {
        // iterate from the back so that the later subscribers are notified first
        for (let i = subscribers.length - 1; i >= 0; i--) {
            const subscriber = subscribers[i];

            if (subscriber.shortcuts.includes(shortcut)) {
                const eventHandled = subscriber.callback(shortcut, event);

                if (eventHandled) {
                    return true;
                }
            }
        }

        return false;
    };

    private notifySubscribers = (shortcut: KeyboardShortcut, event: KeyboardEvent): void => {
        let notified = false;
        const prioritySubscribers = this.#subscribers.filter(sub => sub.isPrioritized);

        if (prioritySubscribers.length > 0) {
            notified = this._notifySubscribers(shortcut, event, prioritySubscribers);
        }

        if (!notified) {
            this._notifySubscribers(shortcut, event, this.#subscribers);
        }
    };

    public isEventShortcut = (event: KeyboardEvent | React.KeyboardEvent, shortcutId: KeyboardShortcut): boolean | string => {
        const shortcut = shortcuts[shortcutId];
        const index = this.pressedKeys.length;
        const currentShortcutKey = shortcut.keys[index];

        if (!currentShortcutKey) {
            return false;
        }

        const currentShortcutChar = currentShortcutKey.char.toLowerCase();
        let condition: boolean = !!currentShortcutKey.ctrlKey === event.ctrlKey && !!currentShortcutKey.altKey === event.altKey && !!currentShortcutKey.shiftKey === event.shiftKey;
        const pressedKey = event.key.toLowerCase();

        // on Mac, alt by default works as altGr,
        // meaning for some keyboard layouts, different character is triggered than the on user pressed (like "<" or accent characters)
        // and the event.key returns "Dead" or some special value instead of value of the pressed key.
        // ==> use event.code which always returns value of the pressed key.
        // !warning! the returned key always corresponds to the key on the keyboard and doesn't care about the system keyboard layout;
        // meaning, if the user use QUERTZ layout, event.code will still return y when y is pressed on keyboard.
        // https://stackoverflow.com/questions/61255621/trying-to-prevent-a-dead-key-from-being-typed-from-a-mac-keyboard
        // https://apple.stackexchange.com/questions/10761/how-to-disable-composite-keys-on-mac-os-x
        if (currentShortcutKey.altKey && deviceDetect.isMacOs) {
            const keyValue = event.code?.toLowerCase().startsWith("key") ? `key${currentShortcutChar}` : currentShortcutChar;

            condition = condition && keyValue === event.code?.toLowerCase();
        } else {

            condition = condition && currentShortcutChar === pressedKey;
        }

        if (condition && index < shortcut.keys.length - 1) {
            return KeyboardShortcutsManager.PARTIAL_SHORTCUT;
        }

        return condition;
    };

    private getShortcutFromEvent = (event: KeyboardEvent): { shortcut: KeyboardShortcut, finished: boolean } => {
        if (!event.key) {
            return null;
        }

        for (const shortcut of Object.values(shortcuts)) {
            const res = this.isEventShortcut(event, shortcut.id);

            if (res === KeyboardShortcutsManager.PARTIAL_SHORTCUT) {
                return { shortcut: shortcut.id, finished: false };
            } else if (res) {
                return { shortcut: shortcut.id, finished: true };
            }
        }

        return null;
    };

    private finishPress = (): void => {
        this.pressedKeys = [];

        if (this.#pressTimer) {
            clearTimeout(this.#pressTimer);
            this.#pressTimer = null;
        }
    };

    private handleKeyDown = (event: KeyboardEvent): void => {
        if (event.repeat) {
            return;
        }

        const res = this.getShortcutFromEvent(event);

        if (res) {
            if (!res.finished) {
                this.pressedKeys.push(event.key.toLowerCase());
                this.#pressTimer = setTimeout(() => {
                    this.finishPress();
                }, 300);
            } else {
                this.finishPress();
                event.preventDefault();
                this.notifySubscribers(res.shortcut, event);
            }
        } else {
            this.finishPress();
        }
    };
}

const keyboardShortcutsManager = new KeyboardShortcutsManager();

export default keyboardShortcutsManager;