import { BackgroundJobStatusCode, BackgroundJobTypeCode, WebSocketMessageTypeCode } from "@odata/GeneratedEnums";
import {
    createDemoTenant,
    DemoTenantStatusCode,
    getDemoTenantInfo,
    IDemoTenantInfo,
    switchToDemoTenant
} from "@pages/userSetting/UserSetting.utils";
import { TWebsocketMessage } from "@utils/websocketManager/Websocket.types";
import { isBackgroundJobWebsocketMessage } from "@utils/websocketManager/Websocket.utils";
import WebsocketManager from "@utils/websocketManager/WebsocketManager";
import React, { ReactNode, useCallback, useEffect } from 'react';

import {
    getBackgroundJobFromWebsocketMessage
} from "../../../contexts/backgroundJobsContext/BackgroundJobsContext.utils";

interface IChildrenProps {
    handleClick: () => Promise<void>;
    isLoading?: boolean;
    isNotCreated?: boolean;
    hasDemoTenant?: boolean;
}

interface DemoTenantWrapperProps {
    children: (props: IChildrenProps) => ReactNode;
}

const DemoTenantWrapper: React.FC<DemoTenantWrapperProps> = ({ children }) => {
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [hasError, setHasError] = React.useState<boolean>(false);
    // initial state - unknown status
    const [info, setInfo] = React.useState<IDemoTenantInfo | null>(undefined);

    const _getTenantInfo = useCallback(async () => {
        const info = await getDemoTenantInfo();

        if (info === false) {
            setHasError(true);
        } else {
            setInfo(info);
            setIsLoading(info?.DemoTenantStatus === DemoTenantStatusCode.Creating);
        }
    }, []);

    const handleMessage = useCallback((message: TWebsocketMessage) => {
        if (!isBackgroundJobWebsocketMessage(message)) {
            return;
        }

        const backgroundJob = getBackgroundJobFromWebsocketMessage(message);
        const terminalStates = [BackgroundJobStatusCode.Finished, BackgroundJobStatusCode.Error, BackgroundJobStatusCode.Expired];
        if (backgroundJob.TypeCode === BackgroundJobTypeCode.CreatingDemoCompany && terminalStates.includes(backgroundJob.StatusCode as BackgroundJobStatusCode)) {
            _getTenantInfo();
        }
    }, [_getTenantInfo]);

    useEffect(() => {
        _getTenantInfo();
    }, [_getTenantInfo]);

    useEffect(() => {
        return WebsocketManager.subscribe({
            callback: handleMessage,
            types: [WebSocketMessageTypeCode.BackgroundJob]
        });
    }, [handleMessage]);

    const hasDemoTenant = info && info.DemoTenantStatus === DemoTenantStatusCode.Created;
    // note, it's not the same as !hasDemoTenant, because we don't want to show the button when we don't know the status
    const isNotCreated = info && info.DemoTenantStatus === DemoTenantStatusCode.NotCreated;

    const handleClick = useCallback(async () => {
        if (isLoading) {
            return;
        }
        setIsLoading(true);
        setHasError(false);
        if (isNotCreated) {
            const data = await createDemoTenant();
            if (data === false) {
                setHasError(true);
                setIsLoading(false);
            } else {
                setInfo(data);
                setIsLoading(data.DemoTenantStatus === DemoTenantStatusCode.Creating);
            }
        } else {
            const succ = await switchToDemoTenant(info.DemoTenantId);
            setHasError(!succ);
            setIsLoading(false);
        }
    }, [info, isNotCreated, isLoading]);

    return (<>
        {children({
            handleClick,
            isLoading: isLoading || hasError,
            isNotCreated,
            hasDemoTenant
        })}
    </>);
};

export default DemoTenantWrapper;