import {
    EntitySetName,
    IInvoiceServiceIntegrationEntity,
    InvoiceServiceIntegrationEntity,
    InvoiceServiceIntegrationSettingsEntity
} from "@odata/GeneratedEntityTypes";

import { REST_API_URL } from "../../constants";
import { FormStorage } from "../../views/formView/FormStorage";

export const INTEGRATIONS_API_URL = `${REST_API_URL}/InvoiceServiceIntegration`;
export const INTEGRATION_SETTINGS_ACTION = "integrationSettings";
export const INTEGRATION_SYNC_ACTION = "integrationSync";

export const getIntegrations = async (storage: FormStorage): Promise<IInvoiceServiceIntegrationEntity[]> => {
    const res = await storage.oData.getEntitySetWrapper(EntitySetName.InvoiceServiceIntegrations)
            .query()
            .expand(InvoiceServiceIntegrationEntity.Parameters)
            .expand(InvoiceServiceIntegrationEntity.Settings, query => query.expand(InvoiceServiceIntegrationSettingsEntity.IntegrationApiImportFrequency))
            .fetchData<IInvoiceServiceIntegrationEntity[]>();

    return res.value;
};