import { WithConfirmationDialog, withConfirmationDialog } from "@components/dialog/withConfirmationDialog";
import { WithPromisedComponent, withPromisedComponent } from "@components/dialog/withPromisedComponent";
import { isSameDay } from "@components/inputs/date/utils";
import { ISelectItem } from "@components/inputs/select/Select.types";
import { IGetValueArgs, isFieldDisabled, isVisible, isVisibleByPath } from "@components/smart/FieldInfo";
import { ActionType, ISmartFastEntriesActionEvent } from "@components/smart/smartFastEntryList";
import { ISmartFieldBlur, ISmartFieldChange } from "@components/smart/smartField/SmartField";
import { ISmartFormGroupActionEvent } from "@components/smart/smartFormGroup/SmartFormGroup";
import { fetchSelectItems } from "@components/smart/smartSelect/SmartSelectAPI";
import { getBoundValue, setBoundValue, setDirtyFlag } from "@odata/Data.utils";
import {
    AccountingDepreciationPolicyEntity,
    AssetEntity,
    EntityTypeName,
    IAssetEntity,
    IAssetItemEntity,
    IDepreciationSettingEntity,
    TaxDepreciationCategoryEntity,
    TaxDepreciationCoefficientEntity,
    TaxDepreciationPolicyEntity
} from "@odata/GeneratedEntityTypes";
import {
    AssetItemTypeCode,
    AssetStatusCode,
    AssetTypeCode,
    DepreciationPolicyTypeCode,
    DepreciationSettingTypeCode,
    DepreciationTypeCode
} from "@odata/GeneratedEnums";
import { getCurrentRangeIndex, TTemporal } from "@odata/TemporalUtils";
import { updateAssetPartially } from "@pages/asset/Asset.utils";
import { getFiscalDataCorrespondingToDateAccountingTransaction } from "@pages/documents/Document.utils";
import { getCompanyCurrency, isAccountAssignmentCompany, isCashBasisAccountingCompany } from "@utils/CompanyUtils";
import { isDefined } from "@utils/general";
import { startsWith } from "@utils/string";
import { cloneDeep } from "lodash";
import React from "react";
import { Trans } from "react-i18next";

import BusyIndicator from "../../../components/busyIndicator";
import Dialog from "../../../components/dialog";
import { AppContext } from "../../../contexts/appContext/AppContext.types";
import { withPermissionContext } from "../../../contexts/permissionContext/withPermissionContext";
import { FormMode } from "../../../enums";
import { TValue } from "../../../global.types";
import BindingContext, { createBindingContext, createPath } from "../../../odata/BindingContext";
import DateType, { DATE_MAX, getUtcDate, getUtcDayjs } from "../../../types/Date";
import { FormViewForExtend, IFormViewProps } from "../../../views/formView/FormView";
import PlugAndPlayForm from "../../../views/formView/PlugAndPlayForm";
import TemporalFormDialog from "../../../views/formView/TemporalFormDialog";
import { ITableDef } from "../../../views/table/TableView.utils";
import { reloadAccounts } from "../../accountAssignment/Account.utils";
import { getCurrentCoAId } from "../../accountAssignment/AccountAssignment.utils";
import DialogPage from "../../DialogPage";
import { getFiscalYearByChartOfAccount, getOldestActiveFY, getSortedFYs } from "../../fiscalYear/FiscalYear.utils";
import { setMatchingNumberRange } from "../../numberRange/NumberRange.utils";
import { getDefinitionsFactory } from "../unorganizedAsset/UnorganizedAssetDef";
import UnorganizedAssetTableView from "../unorganizedAsset/UnorganizedAssetTableView";
import {
    DateFirstPutInUsePath,
    FixedAssetFormViewAction,
    getFirstActiveFiscalYearOfAssetInUse,
    getLowPriceAssetLimit,
    getTaxDepreciationPolicyLocalCategoryId,
    getTaxDepreciationSelectItems,
    IFixedAssetCustomData,
    TRootAssetItems,
    Validity
} from "./FixedAsset.utils";
import {
    AccountingDepreciationPolicyGroup,
    AccountingDepreciationPolicyTypePath,
    AccountingRequestedLastDepreciationPath,
    canBeDepreciated,
    DepreciationSettingsValidityLocalPath,
    DepreciationValueLocalPath,
    FixedAssetEditableWindowActionFinish,
    FixedAssetFormViewActionStorageKey,
    getCalculatedPrice,
    getTaxDepreciationCoefficient,
    hasAcceleratedTaxDepreciation,
    hasTaxDepreciation,
    INTANGIBLE_ACCOUNT_PREFIX,
    INTANGIBLE_DEPRECIATION_ACCOUNT_PREFIX,
    isCategoryWithFlexibleAccountingDepreciation,
    isCategoryWithFlexibleTaxDepreciation,
    isFVE30bTaxCategory,
    isImportedAsset,
    isImportedAssetEntity,
    isImportedAssetInCreatedState,
    isInCreatedState,
    isNotInCreatedState,
    TANGIBLE_ACCOUNT_PREFIX,
    TANGIBLE_DEPRECIATION_ACCOUNT_PREFIX,
    TaxDepreciationCategoryCodePath,
    TaxDepreciationPolicyFirstYearValueIncreasePath,
    TaxDepreciationPolicyGroup,
    TaxDepreciationPolicyLocalCategoryPath,
    TaxDepreciationPolicyTypePath,
    TaxRequestedLastDepreciationPath
} from "./FixedAssetDef";
import { getDefinition as getDiscardDefinition } from "./FixedAssetDisposalDef";
import FixedAssetDisposalDialogFormView from "./FixedAssetDisposalDialogFormView";
import FixedAssetMassPdfExportDialog from "./FixedAssetMassPdfExportDialog";
import PolicyDepreciationDialog from "./PolicyDepreciationDialog";
import { getAlertFromError } from "../../../views/formView/Form.utils";
import { isODataError } from "@odata/Data.types";

interface IProps extends IFormViewProps<IAssetEntity, IFixedAssetCustomData>, WithConfirmationDialog, WithPromisedComponent {
}

enum AssetDialogType {
    None,
    PolicyDepreciation,
    PartialDepreciation,
    Interrupt,
    UnsortedAsset,
    Discard
}

interface IState {
    dialogType?: AssetDialogType;
    policyDialogType?: DepreciationPolicyTypeCode;
}

const _30bDefaultMonths = 240;

class FixedAssetFormView extends FormViewForExtend<IAssetEntity, IProps, IState> {
    static contextType = AppContext;
    //sadly, breaks typescript type checking
    //context: React.ContextType<typeof AppContext>;
    static defaultProps = {
        title: "FixedAsset:FormTitle"
    };

    state: IState = {
        dialogType: null
    };
    _lastDatePutInUse: Date;

    componentDidMount() {
        super.componentDidMount();
        this.registerHeader();

        this.props.storage.emitter.on(FixedAssetEditableWindowActionFinish, this.handleEditableWindowActionFinish);
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>) {
        this.registerHeader();
    }


    shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<IState>): boolean {
        // this form is updated only directly using force update
        return this.state.dialogType !== nextState.dialogType
                || this.state.policyDialogType !== nextState.policyDialogType;
    }

    componentWillUnmount() {
        super.componentWillUnmount();

        this.props.storage.emitter.off(FixedAssetEditableWindowActionFinish, this.handleEditableWindowActionFinish);
    }

    registerHeader = (): void => {
        if (this.props.storage.data.bindingContext) {
            const numberOursBc = this.props.storage.data.bindingContext.navigate("NumberOurs");
            this.props.storage.addCustomRef(this._refHeader.current, numberOursBc);
        }
    };

    get hasAccounting(): boolean {
        return isAccountAssignmentCompany(this.context);
    }

    get isCbaCompany(): boolean {
        return isCashBasisAccountingCompany(this.context);
    }

    get defaultDateFirstPutInUse(): Date {
        const { storage } = this.props;
        if (isImportedAssetEntity(storage.data.entity)) {
            // default date for imported asset is a day before first fiscalYear
            const firstFY = getSortedFYs(storage?.context)?.[0];
            if (firstFY) {
                return getUtcDayjs(firstFY.DateStart).subtract(1, "day").toDate();
            }
        }
        return getUtcDate();
    }

    onAfterLoad = async () => {
        const { storage } = this.props;
        const isAuditTrail = storage.formMode === FormMode.AuditTrail;
        const promises: Promise<unknown>[] = [];
        const isNew = storage.data.bindingContext.isNew();


        if (!isAuditTrail) {
            if (!storage.data.entity.NumberOurs) {
                promises.push(setMatchingNumberRange(storage, true));
            }

            const { DateFirstPutInUse } = storage.data.entity;
            if (!this._lastDatePutInUse || (isNew && isImportedAsset({ storage }))) {
                // first time onAfterLoad is called
                if (isImportedAssetInCreatedState({ storage })) {
                    // set isInUse for old assets
                    await this.correctFormDataAfterIsInUseChange(true);
                    await this.updateLowPriceAssetFlag();
                } else {
                    promises.push(getLowPriceAssetLimit(storage, DateFirstPutInUse));
                }
                this._lastDatePutInUse = DateFirstPutInUse ?? this.defaultDateFirstPutInUse;
            } else if (DateFirstPutInUse) {
                // user is switching between rows in table, DatePutInUse may change, so we have to refresh
                //  the form onAfterLoad (reloadAccounts). On the first load, _lastDatePutInUse will be same
                //  as DateFirstPutInUse, so there won't be any account reload
                promises.push(this.refreshFormAfterDatePutInUseChange());
            }

            if (!storage.data.entity.IsInUse) {
                this.clearAccountsData();
            }
            if (isNew && isCashBasisAccountingCompany(this.context) && isImportedAsset({ storage })) {
                const typeBc = storage.data.bindingContext.navigate("Type");
                storage.setValue(typeBc, AssetTypeCode.Tangible);
                storage.correctEnumValue(typeBc, AssetTypeCode.Tangible);
            }
        }
        /**
         * always updates taxDeprecationSelects
         *  - if not in use, we would like to prepare default data, so there is no delay when "putInUse" is toggled
         *  - if already in use, the data has to be there, so user can change them
         */
        if (!isNew) {
            // set local contexts from entity
            this.initTaxDepreciationLocalContexts();
            this.processAssetItems();
        }
        promises.push(this.updateTaxDepreciationSelects(!isAuditTrail));

        await Promise.all(promises);

        // refresh whole form, we need to update groups visibility + button states
        this.forceUpdate();
        return super.onAfterLoad();
    };

    onBeforeSave = (): IAssetEntity => {
        const { storage } = this.props;
        const { bindingContext, entity, origEntity } = storage.data;
        const data: IAssetEntity = cloneDeep(entity);

        if (bindingContext.isNew()) {
            setBoundValue({
                bindingContext: bindingContext.navigate("Company/Id"),
                data,
                newValue: storage.context.getCompany().Id,
                dataBindingContext: bindingContext,
                preventCloning: true
            });
            data.Currency = { Code: storage.context.getDefaultCurrency() };
        }

        const canBeTaxDepreciated = hasTaxDepreciation({ storage });
        const canBeAccountingDepreciated = canBeDepreciated({ storage });
        const isFlexibleTaxDepreciationAsset = isCategoryWithFlexibleTaxDepreciation({ storage });
        const isFlexibleAccDepreciationAsset = isCategoryWithFlexibleAccountingDepreciation({ storage });

        if (!data.Items?.length && this.isCbaCompany && this.entity.Type?.Code === AssetTypeCode.Intangible) {
            const item: IAssetItemEntity = {
                ItemTypeCode: AssetItemTypeCode.PutInUse,
                Amount: data.CalculatedPrice,
                CurrencyCode: getCompanyCurrency(storage.context),
                DateOfTransaction: data.DateFirstPutInUse
            };
            data.Items = [item];
        }

        if (!isFlexibleTaxDepreciationAsset && data.TaxDepreciationPolicy?.DateRequestedLastDepreciation) {
            data.TaxDepreciationPolicy.DateRequestedLastDepreciation = null;
        }

        if (this.hasAccounting) {
            if (isFlexibleAccDepreciationAsset) {
                data.AccountingDepreciationPolicy.UsefulLife = null;
            } else if (data.AccountingDepreciationPolicy) {
                data.AccountingDepreciationPolicy.DateRequestedLastDepreciation = null;
            }
        } else {
            // remove possible default values on CBA company
            delete data.AccountingDepreciationPolicy;
        }

        if (!data.IsImportedAsset && origEntity.IsInUse && !data.IsInUse) {
            // user removed "PutInUse" item and asset is saved immediately - we don't want to remove policy object,
            // they are removed on BE later -> DEV-20166
            return data;
        }

        // last Acquisition item was removed -> cancel IsInUse
        if (!data.IsImportedAsset && !data.Items?.length && !(this.isCbaCompany && this.entity.Type?.Code === AssetTypeCode.Intangible)) {
            data.IsInUse = false;
        }

        const isImportedAssetInCreatedState = data.IsImportedAsset && isInCreatedState({ storage });

        if (isImportedAssetInCreatedState && !isVisibleByPath(storage, AssetEntity.RemainingTaxPrice)) {
            data.RemainingTaxPrice = entity.RemainingTaxPrice = 0;
        }

        if (isImportedAssetInCreatedState && !isVisibleByPath(storage, AssetEntity.RemainingAccountingPrice)) {
            data.RemainingAccountingPrice = entity.RemainingAccountingPrice = 0;
        }

        if ((data.IsImportedAsset && !hasAcceleratedTaxDepreciation({ storage })) || data.RemainingTaxPrice === 0) {
            data.RemainingTaxYears = entity.RemainingTaxYears = 0;
        }

        if (data.IsInUse) {
            // update coefficient according to latest selection in localContext fields
            if (canBeTaxDepreciated) {
                this.updateTaxDepreciationCoefficient(data);
            }
            if (!data.ChartOfAccounts?.Id && this.hasAccounting) {
                data.ChartOfAccounts = {
                    Id: getCurrentCoAId({ storage }, {
                        datePath: "DateFirstPutInUse",
                        fallbackToActiveChOA: false
                    })
                };
            }
        } else {
            // remove possible default values
            if (origEntity.InUseAccount) {
                data.InUseAccount = entity.InUseAccount = null;
            } else {
                delete data.InUseAccount;
                delete entity.InUseAccount;
            }

            data.Items = data.Items?.filter((item: IAssetItemEntity) => item.ItemType?.Code === AssetItemTypeCode.Acquisition);
        }

        if (!canBeTaxDepreciated || !data.IsInUse) {
            if (origEntity.TaxDepreciationPolicy) {
                data.TaxDepreciationPolicy = entity.TaxDepreciationPolicy = null;
            } else {
                delete data.TaxDepreciationPolicy;
                delete entity.TaxDepreciationPolicy;
            }
        }

        if (!canBeAccountingDepreciated || !data.IsInUse || !this.hasAccounting) {
            // todo: fix this in Data.utils
            if (origEntity.AccountingDepreciationPolicy) {
                data.AccountingDepreciationPolicy = entity.AccountingDepreciationPolicy = null;
            } else {
                delete data.AccountingDepreciationPolicy;
                delete entity.AccountingDepreciationPolicy;
            }
            if (origEntity.DepreciationAccount) {
                data.DepreciationAccount = entity.DepreciationAccount = null;
            } else {
                delete data.DepreciationAccount;
                delete entity.DepreciationAccount;
            }
            if (origEntity.AccumulatedDepreciationsAccount) {
                data.AccumulatedDepreciationsAccount = null;
                entity.AccumulatedDepreciationsAccount = null;
            } else {
                delete data.AccumulatedDepreciationsAccount;
                delete entity.AccumulatedDepreciationsAccount;
            }
        }

        if (isDefined(data.TaxDepreciationPolicy) && !data.TaxDepreciationPolicy?.ReductionRate) {
            data.TaxDepreciationPolicy = {
                ...data.TaxDepreciationPolicy,
                ReductionRate: 0
            };
        }

        return data;
    };

    async updateTaxDepreciationSelects(setInitialValue = true): Promise<void> {
        const { storage } = this.props;
        if (!hasTaxDepreciation({ storage })) {
            this.updateUsefulLife(3);
            return;
        }

        const firstPutInUse = storage.getValueByPath(DateFirstPutInUsePath) ?? this.defaultDateFirstPutInUse;
        const type = storage.getValueByPath(TaxDepreciationPolicyTypePath) ?? DepreciationTypeCode.Linear;
        const localCategoryId = storage.getValueByPath(TaxDepreciationPolicyLocalCategoryPath);
        const firstYear = storage.getValueByPath(TaxDepreciationPolicyFirstYearValueIncreasePath);

        const {
            coefficients,
            Categories,
            FirstYearValues
        } = await getTaxDepreciationSelectItems(storage, type, localCategoryId, firstPutInUse);
        storage.setCustomData({ coefficients });

        const _updateItemsAndValue = (path: string, items: ISelectItem[], originalValue: TValue): TValue => {
            const bc = storage.data.bindingContext.navigate(path);
            const info = storage.getInfo(bc);
            info.fieldSettings = {
                ...info.fieldSettings,
                items
            };

            if (setInitialValue && !items.find(item => item.id === originalValue)) {
                const firstItem = items[0];
                const newValue = firstItem?.id ?? null;
                storage.clearAndSetValue(bc, newValue);
                if (firstItem && info?.fieldSettings?.localDependentFields?.length > 0) {
                    storage.processDependentField(info.fieldSettings.localDependentFields, firstItem.additionalData, bc);
                }
                return newValue;
            }

            storage.addActiveField(bc);
            return originalValue;
        };

        const newLocalCategoryId = _updateItemsAndValue(TaxDepreciationPolicyLocalCategoryPath, Categories, localCategoryId) as string;
        let firstYearValueSelectItems = FirstYearValues;
        if (newLocalCategoryId !== localCategoryId) {
            // active category has changed, we need to recalculate related first year values
            firstYearValueSelectItems = (await getTaxDepreciationSelectItems(storage, type, newLocalCategoryId, firstPutInUse)).FirstYearValues;
        }
        _updateItemsAndValue(TaxDepreciationPolicyFirstYearValueIncreasePath, firstYearValueSelectItems, firstYear);
        if (setInitialValue) {
            // Sets default usefulLife if not set already
            this.updateUsefulLife(Categories[0]?.additionalData?.YearsOfDepreciation);
        }
    }

    updateUsefulLife(YearsOfDepreciation: number, force = false): void {
        // change UsefulLife when user changes manually Category select
        if ((force || !this.props.storage.getValueByPath("AccountingDepreciationPolicy/UsefulLife")) && YearsOfDepreciation > 0) {
            this.props.storage.clearAndSetValueByPath("AccountingDepreciationPolicy/UsefulLife", YearsOfDepreciation);
        }
    }

    updateTaxDepreciationCoefficient(data: IAssetEntity): void {
        const { storage } = this.props;
        const { bindingContext } = storage.data;

        const coeff = getTaxDepreciationCoefficient(storage);

        setBoundValue({
            bindingContext: bindingContext.navigate("TaxDepreciationPolicy/Coefficient"),
            data,
            newValue: coeff ?? null,
            dataBindingContext: bindingContext,
            preventCloning: true
        });
        setBoundValue({
            bindingContext: bindingContext.navigate("TaxDepreciationPolicy/CoefficientCode"),
            data,
            newValue: coeff?.Code ?? null,
            dataBindingContext: bindingContext,
            preventCloning: true
        });
    }

    initTaxDepreciationLocalContexts(): void {
        const { storage } = this.props;
        const asset = storage.data.entity as IAssetEntity;

        storage.setValueByPath(TaxDepreciationPolicyTypePath, asset.TaxDepreciationPolicy?.Coefficient?.DepreciationTypeCode);
        storage.setValueByPath(TaxDepreciationPolicyLocalCategoryPath, getTaxDepreciationPolicyLocalCategoryId(asset.TaxDepreciationPolicy));
        storage.setValueByPath(TaxDepreciationPolicyFirstYearValueIncreasePath, asset.TaxDepreciationPolicy?.Coefficient?.FirstYearValueIncrease);
    }

    async updateLowPriceAssetFlag(): Promise<void> {
        const { storage } = this.props;
        const dateFirstPutInUse = storage.getValueByPath("DateFirstPutInUse");
        const limit = await getLowPriceAssetLimit(storage, dateFirstPutInUse);
        const price = getCalculatedPrice(storage.data.entity);
        storage.setValueByPath("IsLowPriceAsset", isDefined(price) && price <= limit);
    }

    updateCanBeDepreciatedFlag(skipUpdateOfTaxDepreciationSelects = false): void {
        const { storage } = this.props;
        const inUseAccountNumber = storage.getValueByPath("InUseAccount/Number") || "";
        const canBeDepraciated = !inUseAccountNumber.startsWith("03");
        storage.setValueByPath("CanBeDepreciated", canBeDepraciated);
        if (canBeDepraciated && !skipUpdateOfTaxDepreciationSelects) {
            // set initial values to selects related to depreciation
            this.updateTaxDepreciationSelects(true);
        }
    }

    // process asset items - find related items according to documentItem, so remove icon can be hidden for them
    processAssetItems() {
        const { storage } = this.props;
        const { Items } = storage.data.entity;
        const processedItems: IAssetItemEntity[] = [];
        const rootItems: TRootAssetItems = {};
        Items?.sort((a, b) => b.Order - a.Order).forEach(item => {
            const foundRelated = item.DocumentItem?.Id && processedItems.find(i => i.Document?.Id === item.Document?.Id && i.DocumentItem?.Id === item.DocumentItem?.Id);
            if (!foundRelated) {
                // if no related items found, current item is rootItem
                rootItems[item.Id] = [];
                processedItems.push(item);
            } else {
                rootItems[foundRelated.Id].push(item.Id);
            }
        });
        storage.setCustomData({ rootItems });
    }

    updateAssetType(): void {
        const { storage } = this.props;
        const inUseAccountNumber = storage.getValueByPath("InUseAccount/Number") || "";
        const isIntangible = inUseAccountNumber.startsWith(INTANGIBLE_ACCOUNT_PREFIX);
        const typeBc = storage.data.bindingContext.navigate("Type");
        const newValue = isIntangible ? AssetTypeCode.Intangible : AssetTypeCode.Tangible;
        storage.setValue(typeBc, newValue);
        storage.correctEnumValue(typeBc, newValue);
        storage.refresh();
    }

    getDepreciationTypeFromAction(action: FixedAssetFormViewAction): DepreciationPolicyTypeCode {
        switch (action) {
            case FixedAssetFormViewAction.Tax:
                return DepreciationPolicyTypeCode.TaxDepreciation;
            case FixedAssetFormViewAction.Accounting:
                return DepreciationPolicyTypeCode.AccountingDepreciation;
            default:
                return null;
        }
    }

    recalculateFormAfterInUseAccountChange() {
        const { storage } = this.props;
        if (isImportedAssetInCreatedState({ storage })) {
            this.updateAssetType();
        }
        this.updateCanBeDepreciatedFlag();

        return this.defaultEmptyValuesIfVisible();
    }

    async defaultEmptyValuesIfVisible() {
        const { storage } = this.props;

        if (!storage.getValueByPath(TaxDepreciationPolicyTypePath) && isVisibleByPath(storage, TaxDepreciationPolicyTypePath)) {
            storage.setDefaultValueByPath(TaxDepreciationPolicyTypePath);
            await this.updateTaxDepreciationSelects(true);
        }
        if (this.hasAccounting) {
            if (!storage.getValueByPath(AccountingDepreciationPolicyTypePath) && isVisibleByPath(storage, AccountingDepreciationPolicyTypePath)) {
                storage.setDefaultValueByPath(AccountingDepreciationPolicyTypePath);
            }
            if (!storage.getValueByPath(AssetEntity.DepreciationAccount, { useDirectValue: false }) && isVisibleByPath(storage, AssetEntity.DepreciationAccount)) {
                await this.setDefaultDeprecationAccount();
            }
        }
    }

    getFirstAvailableFiscalYearDateStart() {
        return getFirstActiveFiscalYearOfAssetInUse(this.props.storage)?.DateStart;
    }

    async handleCustomHeaderAction(actionId: string): Promise<void> {
        const { storage } = this.props;

        switch (true) {
            case [AssetItemTypeCode.TechnicalImprovement, AssetItemTypeCode.IncreaseOfPrice, AssetItemTypeCode.ReductionOfPrice].includes(actionId as AssetItemTypeCode):
                storage.setCustomData({
                    FixedAssetFormViewAction: FixedAssetFormViewAction.ChangePrice,
                    AssetItemTypeCode: actionId as AssetItemTypeCode
                });
                this.setState({ dialogType: AssetDialogType.UnsortedAsset });
                break;
            case actionId === FixedAssetFormViewAction.Discard:
                this.setState({ dialogType: AssetDialogType.Discard });
                break;
            case [FixedAssetFormViewAction.CancelPartialDepreciationSettings, FixedAssetFormViewAction.CancelInterrupt].includes(actionId as FixedAssetFormViewAction):
                const isInterrupt = FixedAssetFormViewAction.CancelInterrupt === actionId;
                // const { DateValidFrom, PartialExpense } = this.getCurrentDepreciationSettingsInfo();
                const dateFrom = this.getFirstAvailableFiscalYearDateStart();

                this.props.confirmationDialog.open({
                    content: (
                        <Trans i18nKey={`FixedAsset:Form.${isInterrupt ? "Interrupt" : "PartialDepreciation"}MessageCancel`}
                               /*values={{ year: dateFrom.getFullYear() }}*/
                               components={{
                                   1: <br/>
                               }}/>
                    ),
                    onConfirm: this.handleDepreciationSettingCancel.bind(this, dateFrom),
                });
                break;
            case [FixedAssetFormViewAction.PartialDepreciationSettings, FixedAssetFormViewAction.Interrupt].includes(actionId as FixedAssetFormViewAction):
                const isPartialDep = actionId === FixedAssetFormViewAction.PartialDepreciationSettings;
                if (isPartialDep) {
                    this.props.storage.setValueByPath(DepreciationValueLocalPath, this.getCurrentDepreciationSettingsInfo()?.PartialExpense);
                }
                this.props.storage.setValueByPath(DepreciationSettingsValidityLocalPath, Validity.SingleYear);
                this.setState({ dialogType: isPartialDep ? AssetDialogType.PartialDepreciation : AssetDialogType.Interrupt });
                break;
            case actionId === FixedAssetFormViewAction.AssetCardPdfExport:
                this.props.promisedComponent.openComponent<void>((onFinish) => {
                    return (
                            <FixedAssetMassPdfExportDialog assetIds={[this.entity.Id.toString()]}
                                                           onConfirm={onFinish}
                                                           onClose={onFinish}/>
                    );
                });
                break;
        }

        return super.handleCustomHeaderAction(actionId);
    }

    handleGroupAction = (args: ISmartFormGroupActionEvent) => {
        switch (args.id) {
            case FixedAssetFormViewAction.Tax:
            case FixedAssetFormViewAction.Accounting:
                const { storage } = this.props;
                this.updateTaxDepreciationCoefficient(storage.data.entity);
                this.setState({
                    dialogType: AssetDialogType.PolicyDepreciation,
                    policyDialogType: this.getDepreciationTypeFromAction(args.id)
                });
                break;
            case FixedAssetFormViewAction.AddFromUnsorted:
                this.props.storage.setCustomData({ [FixedAssetFormViewActionStorageKey]: args.id });
                this.setState({ dialogType: AssetDialogType.UnsortedAsset });
                break;
        }
    };

    handleInUseAccountChange = async (e: ISmartFieldChange): Promise<void> => {
        if (e.bindingContext.getPath() === "InUseAccount" && e.triggerAdditionalTasks) {
            const { storage } = this.props;
            const { fiscalYear } = this.getFiscalDataCorrespondingToDateAccountingTransaction();

            await this.recalculateFormAfterInUseAccountChange();

            storage.setBusy(true);
            await reloadAccounts(storage, !!fiscalYear, "AccumulatedDepreciationsAccount");
            storage.setBusy(false);
        }
    };

    handleAccumulatedDepreciationAccountChange = (e: ISmartFieldChange): void => {
        if (e.bindingContext.getPath() === "AccumulatedDepreciationsAccount" && e.triggerAdditionalTasks) {
            const { storage } = this.props;
            const { Number } = e.additionalData;
            const inUseAccountBc = storage.data.bindingContext.navigate("InUseAccount");
            const currentInUseAccountNumber = storage.getValue(inUseAccountBc.navigate("Number"));

            const _matches = (n1: string, n2: string) => n1?.[2] === n2?.[2];

            if (!_matches(currentInUseAccountNumber, Number) && canBeDepreciated({ storage })) {
                let prefix: string[] = null;
                if (startsWith(Number, INTANGIBLE_DEPRECIATION_ACCOUNT_PREFIX)) {
                    prefix = [INTANGIBLE_ACCOUNT_PREFIX];
                } else if (startsWith(Number, TANGIBLE_DEPRECIATION_ACCOUNT_PREFIX)) {
                    prefix = TANGIBLE_ACCOUNT_PREFIX;
                }
                const info = storage.getInfo(inUseAccountBc);
                const item = info.fieldSettings.items?.find(i => {
                    const num = i.additionalData.Number;
                    return (!prefix || prefix.some(p => startsWith(num, p))) && _matches(i.additionalData.Number, Number);
                });

                if (item) {
                    storage.clearAndSetValue(inUseAccountBc, item.id);
                    storage.clearAndSetValue(inUseAccountBc.navigate("Number"), item.additionalData.Number);
                    storage.clearAndSetValue(inUseAccountBc.navigate("Name"), item.additionalData.Name);

                    this.recalculateFormAfterInUseAccountChange();
                }
            }
        }
    };

    getFiscalDataCorrespondingToDateAccountingTransaction() {
        const { storage } = this.props;
        const asset = storage.data.entity as IAssetEntity;
        let { DateFirstPutInUse } = asset;
        const previousFiscalData = getFiscalDataCorrespondingToDateAccountingTransaction(storage, this._lastDatePutInUse);
        let { fiscalYear } = getFiscalDataCorrespondingToDateAccountingTransaction(storage, DateFirstPutInUse);
        let hasChanged = previousFiscalData.fiscalYear?.Id !== fiscalYear?.Id;

        if (isImportedAsset({ storage }) && !fiscalYear) {
            const { ChartOfAccounts } = asset;
            if (isNotInCreatedState({ storage }) && ChartOfAccounts?.Id) {
                fiscalYear = getFiscalYearByChartOfAccount(storage.context, ChartOfAccounts.Id);
            } else {
                fiscalYear = getOldestActiveFY(storage.context);
            }
            if (getUtcDayjs(DateFirstPutInUse).isBefore(fiscalYear.DateStart)) {
                DateFirstPutInUse = fiscalYear.DateStart;
            }
            // force reload the accounts for imported asset
            hasChanged = true;
        }
        this._lastDatePutInUse = DateFirstPutInUse;

        return { fiscalYear, hasChanged };
    }

    refreshFormAfterDatePutInUseChange = async (): Promise<void> => {
        const { storage } = this.props;
        const { hasChanged, fiscalYear } = this.getFiscalDataCorrespondingToDateAccountingTransaction();

        if (this.hasAccounting && hasChanged) {
            // set value, so InUseAccount can read the data of its parent during save and during account reloading
            storage.setValueByPath("ChartOfAccounts", fiscalYear?.ChartOfAccounts?.Id);
            // we can't let user save the form before the values are changed => busy indicator
            storage.setBusy(true);
            await Promise.all([
                reloadAccounts(storage, !!fiscalYear, "InUseAccount"),
                reloadAccounts(storage, !!fiscalYear, "DepreciationAccount"),
                this.updateLowPriceAssetFlag()
            ]);
            // AccumulatedDepreciationsAccount depends on InUseAccount
            await reloadAccounts(storage, !!fiscalYear, "AccumulatedDepreciationsAccount");
            storage.setBusy(false);
            storage.refresh(true);
        } else {
            // lowPriceAssetFlag is not related to FY change - update this anyway
            await this.updateLowPriceAssetFlag();
            storage.refresh(true);
        }
    };

    setDefaultDeprecationAccount = async (): Promise<void> => {
        const { storage } = this.props;
        const bc = storage.data.bindingContext.navigate(AssetEntity.DepreciationAccount);
        const fieldInfo = storage.getInfo(bc);
        const items = await fetchSelectItems({
            fieldInfo,
            oData: storage.oData,
            appContext: this.context,
            storage
        });
        fieldInfo.fieldSettings.items = items;
        storage.clearAndSetValue(bc, items?.[0]?.additionalData ?? null);
        storage.refresh();
    };

    handleIsInUseChange = async (e: ISmartFieldChange): Promise<void> => {
        if (e.bindingContext.getPath() === AssetEntity.IsInUse) {
            await this.correctFormDataAfterIsInUseChange(!!e.value);

            // update whole form as we show entire groups when IsInUse switch is toggled
            this.forceUpdate();
        }
    };

    handleRemainingTaxPriceChange = (e: ISmartFieldChange): void => {
        if (e.bindingContext.getPath() === "RemainingTaxPrice") {
            const { storage } = this.props;
            const bc = storage.data.bindingContext.navigate(AssetEntity.RemainingTaxYears);
            if (storage.getValue(bc)) {
                storage.validateFieldSync(bc);
            }
        }
    };

    handleReductionRateChange = (e: ISmartFieldChange): void => {
        if (e.bindingContext.getPath() === "ReductionRate") {
            // to refresh header actions
            this.props.storage.refresh(true);
        }
    };

    clearAccountsData() {
        if (!this.hasAccounting) {
            return;
        }

        const { storage } = this.props;
        const { bindingContext } = storage.data;
        ["InUseAccount", "AccumulatedDepreciationsAccount", "DepreciationAccount"]
                .forEach(path => {
                    const bc = bindingContext.navigate(path);
                    storage.clearAndSetValue(bc, null);
                    const info = storage.getInfo(bc);
                    delete info.fieldSettings.items;
                });
    }

    async correctFormDataAfterIsInUseChange(isInUse: boolean): Promise<void> {
        const { storage } = this.props;

        if (isInUse) {
            // Start using the asset
            storage.setValueByPath(DateFirstPutInUsePath, this.defaultDateFirstPutInUse);
            storage.setDefaultValueByPath(TaxDepreciationPolicyTypePath);
            this.updateCanBeDepreciatedFlag(true);
            if (this.hasAccounting) {
                storage.setDefaultValueByPath(AccountingDepreciationPolicyTypePath);
                await this.setDefaultDeprecationAccount();
            }
            await this.refreshFormAfterDatePutInUseChange();
            // depends on previous, needs to be separated
            await this.updateTaxDepreciationSelects(true);
        } else {
            // stop using the asset:
            //  -> remove line item
            //  -> clear data
            storage.clearAndSetValueByPath(DateFirstPutInUsePath, null);
            storage.clearAndSetValueByPath("ChartOfAccounts", null);
            storage.clearAndSetValueByPath(TaxDepreciationPolicyLocalCategoryPath, null);
            storage.clearAndSetValueByPath(TaxDepreciationPolicyTypePath, null);
            storage.clearAndSetValueByPath(TaxDepreciationPolicyFirstYearValueIncreasePath, null);
            storage.clearAndSetValueByPath("TaxDepreciationPolicy", null);
            storage.clearAndSetValueByPath("AccountingDepreciationPolicy", null);
            this.clearAccountsData();
        }
    }

    storeChangedDataBeforeAction() {
        const { storage } = this.props;
        storage.setCustomData({
            entityBeforeAction: cloneDeep(storage.data.entity)
        });
    }

    async restoreChangedDataAfterAction() {
        const { storage } = this.props;
        const dataBindingContext = storage.data.bindingContext;
        const entityBeforeAction = storage.getCustomData().entityBeforeAction;

        const copyField = (fieldPath: string) => {
            const bindingContext = dataBindingContext.navigate(fieldPath);
            const info = storage.getInfo(bindingContext);
            const args: IGetValueArgs = {
                data: storage.data.entity,
                storage, info, bindingContext
            };
            const origValue = getBoundValue({
                bindingContext, dataBindingContext,
                data: entityBeforeAction
            });
            if (isDefined(origValue) && isVisible(args) && !isFieldDisabled(info, storage, bindingContext)) {
                setBoundValue({
                    bindingContext, dataBindingContext,
                    data: storage.data.entity,
                    newValue: origValue,
                    preventCloning: true
                });
                storage.correctEnumValue(bindingContext, origValue, storage.data.entity);
                setDirtyFlag(storage, bindingContext);
            }
        };

        /**
         * Fields, which are kept and restored during actions, when we need to save/reload whole entity,
         * but without user's current changes
         */
        const basicFields = [
            AssetEntity.Note, AssetEntity.Name, AssetEntity.Labels,
            AssetEntity.IsInUse, AssetEntity.DateFirstPutInUse, AssetEntity.InUseAccount,
            createPath(AssetEntity.TaxDepreciationPolicy, TaxDepreciationPolicyEntity.TaxPriceLimitCode),
            TaxDepreciationCategoryCodePath,
            createPath(AssetEntity.TaxDepreciationPolicy, TaxDepreciationPolicyEntity.Coefficient, TaxDepreciationCoefficientEntity.TaxDepreciationCategory, TaxDepreciationCategoryEntity.YearsOfDepreciation),
        ];
        const additionalFields = [
            "DepreciationAccount", "AccumulatedDepreciationsAccount",
            TaxDepreciationPolicyTypePath, TaxDepreciationPolicyLocalCategoryPath, TaxDepreciationPolicyFirstYearValueIncreasePath,
            "TaxDepreciationPolicy/ReductionRate", TaxRequestedLastDepreciationPath,
            AccountingDepreciationPolicyTypePath, "AccountingDepreciationPolicy/UsefulLife",
            AccountingRequestedLastDepreciationPath,
        ];

        basicFields.forEach(copyField);

        this.updateCanBeDepreciatedFlag(true);
        await this.updateLowPriceAssetFlag();
        additionalFields.forEach(copyField);
        await this.updateTaxDepreciationSelects(true);
        this.updateTaxDepreciationCoefficient(storage.data.entity);
        // clear data
        storage.setCustomData({ entityBeforeAction: null });
    }

    _lastValidPutInUseDate: Date = null;
    handleDateFirstPutInUseChange = async (e: ISmartFieldChange): Promise<void> => {
        if (e.bindingContext.getPath() === DateFirstPutInUsePath) {
            const { storage } = this.props;

            if (e.triggerAdditionalTasks) {
                await Promise.all([
                    this.updateTaxDepreciationSelects(),
                    this.refreshFormAfterDatePutInUseChange()
                ]);

                const _is30bCat = isFVE30bTaxCategory({ storage });
                const lastDepValue = getUtcDayjs(storage.getValueByPath(TaxRequestedLastDepreciationPath));
                const prevPutInUse = getUtcDayjs(this._lastValidPutInUseDate ?? e.previousValue as Date);
                if (_is30bCat && (!storage.data.entity.TaxDepreciationPolicy.DateRequestedLastDepreciation || lastDepValue.diff(prevPutInUse, "month") === _30bDefaultMonths)) {
                    const lastDep = getUtcDayjs(e.value as Date).add(_30bDefaultMonths, "months").endOf("month").toDate();
                    const prevValue = storage.getValueByPath(TaxRequestedLastDepreciationPath);
                    storage.clearAndSetValueByPath(TaxRequestedLastDepreciationPath, lastDep);
                    this.defaultAccountingUsefulLifeFromRequestedLastDepreciation(lastDep, prevValue, true);
                }

                storage.addActiveField(storage.data.bindingContext.navigate(TaxDepreciationPolicyLocalCategoryPath), { revalidate: true });
                storage.refreshFields(true);
                storage.refreshGroupByKey(TaxDepreciationPolicyGroup);
            }

            if (DateType.isValid(e.value as Date)) {
                this._lastValidPutInUseDate = e.value as Date;
            } else if (DateType.isValid(e.previousValue as Date)) {
                this._lastValidPutInUseDate = e.previousValue as Date;
            }
        }
    };

    handleTaxDepreciationPolicyTypeChange = async (e: ISmartFieldChange): Promise<void> => {
        if (e.bindingContext.getPath() === TaxDepreciationPolicyTypePath) {
            const { storage } = this.props;
            await this.updateTaxDepreciationSelects();
            storage.addActiveField(this.props.storage.data.bindingContext.navigate("RemainingTaxYears"));

            storage.refresh(true);
        }
    };

    handleAccountingDepreciationPolicyTypeChange = (e: ISmartFieldChange): void => {
        if (e.bindingContext.getNavigationPath() === AccountingDepreciationPolicyTypePath) {
            const { storage } = this.props;
            const taxDateBc = storage.data.bindingContext
                    .navigate(AssetEntity.TaxDepreciationPolicy)
                    .navigate(TaxDepreciationPolicyEntity.DateRequestedLastDepreciation);
            const requestedLastDepreciation = storage.getValue(taxDateBc);
            const force = (e.previousValue === DepreciationTypeCode.TimeDependent || e.value === DepreciationTypeCode.TimeDependent) && e.previousValue !== e.value;

            this.defaultAccountingUsefulLifeFromRequestedLastDepreciation(requestedLastDepreciation, requestedLastDepreciation, force);
            storage.addActiveGroupByKey(AccountingDepreciationPolicyGroup);
        }
    };

    defaultAccountingUsefulLifeFromRequestedLastDepreciation = (value: Date, prevValue: Date, force = false) => {
        const { storage } = this.props;
        const { bindingContext, entity } = storage.data;
        const isFlexibleAccDepreciation = isCategoryWithFlexibleAccountingDepreciation({ storage });
        const accBc = bindingContext
                .navigate(AssetEntity.AccountingDepreciationPolicy);

        if (isFlexibleAccDepreciation) {
            const { DateRequestedLastDepreciation } = entity.AccountingDepreciationPolicy;
            if (force || !DateRequestedLastDepreciation || isSameDay(prevValue, DateRequestedLastDepreciation)) {
                storage.setValue(accBc.navigate(AccountingDepreciationPolicyEntity.DateRequestedLastDepreciation), value);
            }
        } else {
            const usefulLifeBc = accBc
                    .navigate(AccountingDepreciationPolicyEntity.UsefulLife);

            const firstPutInUse = storage.getValueByPath(DateFirstPutInUsePath);
            const _calc = (d: Date) =>
                    Math.ceil(getUtcDayjs(d).diff(getUtcDayjs(firstPutInUse).endOf("month"), "year", true));

            const calculatedUsefulLife = _calc(value);
            const currValue = storage.getValue(usefulLifeBc);
            if (calculatedUsefulLife > 0 && (force || !currValue || currValue === _calc(prevValue))) {
                storage.setValue(usefulLifeBc, calculatedUsefulLife);
                storage.addActiveGroupByKey(AccountingDepreciationPolicyGroup);
            }
        }
    };

    handleTaxDepreciationRequestedLastDepreciationChange = (e: ISmartFieldChange): void => {
        if (e.bindingContext.getNavigationPath() === TaxRequestedLastDepreciationPath && e.triggerAdditionalTasks) {
            this.defaultAccountingUsefulLifeFromRequestedLastDepreciation(e.value as Date, e.previousValue as Date);

            // refresh also tax group to enable depreciation button
            this.props.storage.refreshGroupByKey(TaxDepreciationPolicyGroup);
            this.props.storage.refreshGroupByKey(AccountingDepreciationPolicyGroup);
        }
    };

    handleAccountingDepreciationRequestedLastDepreciationChange = (e: ISmartFieldChange): void => {
        if (e.bindingContext.getNavigationPath() === AccountingRequestedLastDepreciationPath && e.triggerAdditionalTasks) {
            this.props.storage.refreshGroupByKey(AccountingDepreciationPolicyGroup);
        }
    };

    handleTaxDepreciationPolicyCategoryChange = async (e: ISmartFieldChange): Promise<void> => {
        if (e.bindingContext.getPath() === TaxDepreciationPolicyLocalCategoryPath && e.triggerAdditionalTasks) {
            await this.updateTaxDepreciationSelects();
            if (e.additionalData.YearsOfDepreciation) {
                this.updateUsefulLife(e.additionalData.YearsOfDepreciation, true);
            }

            const { storage } = this.props;
            const _is30bCat = isFVE30bTaxCategory({ storage });
            const date = getUtcDayjs(storage.getValueByPath(DateFirstPutInUsePath));
            if (_is30bCat && !storage.data.entity.TaxDepreciationPolicy.DateRequestedLastDepreciation) {
                const lastDep = date.add(_30bDefaultMonths, "months").endOf("month").toDate();
                const prevValue = storage.getValueByPath(TaxRequestedLastDepreciationPath);
                storage.clearAndSetValueByPath(TaxRequestedLastDepreciationPath, lastDep);
                this.defaultAccountingUsefulLifeFromRequestedLastDepreciation(lastDep, prevValue, true);
            }
            storage.addActiveGroupByKey(TaxDepreciationPolicyGroup);
        }
    };

    handleTypeChange = (e: ISmartFieldChange): void => {
        if (e.bindingContext.getPath() === AssetEntity.Type && this.isCbaCompany) {
            // needed to update visibility of action button
            this.entity.IsInUse = e.value === AssetTypeCode.Intangible;
            this.forceUpdate();
        }
    };

    refreshAddFromUnsortedButton(e: ISmartFieldChange) {
        if (this.props.storage.data.bindingContext.isNew() && ["NumberOurs", "Name"].includes(e.bindingContext.getPath())) {
            // Rerender form to refresh groups buttons (disabled flag)
            this.props.storage.refreshGroupByKey("Items");
        }
    }

    async handleBlur(args: ISmartFieldBlur): Promise<void> {
        await super.handleBlur(args);
        const path = args.bindingContext.getPath() as AssetEntity | string;

        if (path === "UsefulLife") {
            this.props.storage.refreshGroupByKey(AccountingDepreciationPolicyGroup);
        }
        if (path === AssetEntity.ImportedAssetPrice) {
            await this.updateLowPriceAssetFlag();
            await this.defaultEmptyValuesIfVisible();
        }
        if ([AssetEntity.RemainingTaxPrice, AssetEntity.RemainingTaxYears, AssetEntity.RemainingAccountingPrice, AssetEntity.ImportedAssetPrice].includes(path as AssetEntity)) {
            this.props.storage.refresh();
        }
    }

    async handleChange(e: ISmartFieldChange): Promise<void> {
        this.handleTypeChange(e);

        super.handleChange(e);
        // ^^^ calling super will refresh fields before async handler, so dateFields work correctly
        await Promise.all([
            this.handleIsInUseChange(e),
            this.handleDateFirstPutInUseChange(e),
            this.handleInUseAccountChange(e),
            this.handleTaxDepreciationPolicyTypeChange(e),
            this.handleTaxDepreciationPolicyCategoryChange(e)
        ]);
        this.handleAccumulatedDepreciationAccountChange(e);
        this.handleRemainingTaxPriceChange(e);
        this.handleReductionRateChange(e);
        this.handleTaxDepreciationRequestedLastDepreciationChange(e);
        this.handleAccountingDepreciationRequestedLastDepreciationChange(e);
        this.handleAccountingDepreciationPolicyTypeChange(e);

        this.refreshAddFromUnsortedButton(e);
        this.props.storage.refreshFields();
    }

    handleLineItemsAction = async (args: ISmartFastEntriesActionEvent<IAssetItemEntity>) => {
        const { storage } = this.props;

        const _defaultHandler = () => {
            storage.handleLineItemsAction(args);
            storage.refresh();
        };
        if (args.actionType !== ActionType.Remove) {
            _defaultHandler();
            return;
        }

        const _confirmDialog = (messageKey: string) => {
            const content = (<>
                {storage.t(`FixedAsset:Form.${messageKey}`)}<br/><br/>
                {storage.t("Common:Confirmations.ConfirmContinue")}
            </>);
            return this.props.confirmationDialog
                    .open({
                        content
                    });
        };

        const _saveFormAndRestoreChanges = async () => {
            this.storeChangedDataBeforeAction();
            // await resetData so that onAfterLoad is properly called and all the data are set
            await storage.resetData();
            _defaultHandler();
            this.save()
                    .then(() => this.restoreChangedDataAfterAction())
                    .then(() => storage.refresh(true));
        };

        // We handle here only remove action - differently for several item types
        const [item] = args.affectedItems;
        let isConfirmed: boolean;
        switch (item.ItemType?.Code) {
            case AssetItemTypeCode.ImportOfOldAsset:
                _defaultHandler();
                this.recreateOldImportedAsset();
                break;

            case AssetItemTypeCode.PutInUse:
                isConfirmed = await _confirmDialog("CancelPutInUseConfirm");
                if (isConfirmed) {
                    this.cancelPutInUseAndSave();
                }
                break;

            case AssetItemTypeCode.Disposal:
            case AssetItemTypeCode.ReductionOfPrice:
            case AssetItemTypeCode.IncreaseOfPrice:
            case AssetItemTypeCode.TechnicalImprovement:
                isConfirmed = await _confirmDialog(item.ItemType?.Code === AssetItemTypeCode.Disposal ? "CancelDisposalConfirm" : "CancelChangePriceConfirm");
                if (isConfirmed) {
                    _saveFormAndRestoreChanges();
                }
                break;

            case AssetItemTypeCode.Acquisition:
                // remove similar items
                const { rootItems } = storage.getCustomData();
                const related = rootItems[item.Id];
                args.items = args.items.filter(i => !related.includes(i.Id));
                // save
                _saveFormAndRestoreChanges();
                break;
            default:
                // no action
        }
    };

    // When user finishes adding from unsorted action in editable window (or changing price), FormView is notified
    // by emitting event, so we can reload it.
    handleEditableWindowActionFinish = async (newEntity?: IAssetEntity) => {
        const { storage } = this.props;
        this.storeChangedDataBeforeAction();
        const customData = storage.data.customData;
        if (newEntity) {
            const bindingContext = createBindingContext(storage.data.bindingContext.getFullPath(), storage.oData.getMetadata()).removeKey().addKey(newEntity.Id);
            storage.store({ bindingContext });
        }
        this.onAfterSave(!!newEntity, false);
        await storage.loadDataAfterSave();
        // keep custom data as we need them in editable window (e.g. action type, etc...)
        storage.setCustomData(customData);
        await this.restoreChangedDataAfterAction();
    };

    recreateOldImportedAsset = async () => {
        const { storage } = this.props;
        storage.setValueByPath(AssetEntity.Status, AssetStatusCode.Created);
        storage.setValueByPath(AssetEntity.DateForUsefulLife, null);

        this.forceUpdate();
    };

    cancelPutInUseAndSave = async () => {
        const { storage } = this.props;
        // set-up the storage
        storage.setValueByPath(AssetEntity.IsInUse, false);
        // save the form
        const isSaved = await this.save();

        if (!isSaved) {
            // revert value - Error from BE will be displayed automatically
            storage.setValueByPath(AssetEntity.IsInUse, true);
            this.forceUpdate();
        } else {
            await this.correctFormDataAfterIsInUseChange(false);
        }
    };

    handleDialogClose = () => {
        this.setState({ dialogType: null });
    };

    renderPolicyDeprecationDialog() {
        return (
                <PolicyDepreciationDialog storage={this.props.storage}
                                          type={this.state.policyDialogType}
                                          onClose={this.handleDialogClose}/>
        );
    }

    handleUnsortedAssetDialogClose = () => {
        this.processAssetItems();
        this.handleDialogClose();
    };

    renderUnsortedAssetDialog() {
        const { FixedAssetFormViewAction: key, AssetItemTypeCode } = this.props.storage.getCustomData();
        const isChangePrice = key === FixedAssetFormViewAction.ChangePrice;
        const prefix = isChangePrice ? "ChangePrice" : "AddFromUnsorted";
        const id: ITableDef["id"] = `${prefix}::${EntityTypeName.UnorganizedAsset}SpecialTable`;
        return (
                <Dialog isEditableWindow
                        onConfirm={null}
                        onClose={this.handleUnsortedAssetDialogClose}>
                    <DialogPage
                            rootStorage={this.props.storage}
                            tableView={UnorganizedAssetTableView}
                            tableViewProps={{}}
                            getDef={getDefinitionsFactory(id, true, isChangePrice ? AssetItemTypeCode : null)}/>
                </Dialog>
        );
    }

    handleDiscardDialogConfirm = () => {
        this.props.storage.loadDataAfterSave()
                .then(() => this.forceUpdate());
        this.onAfterSave(false, false);
        // we want to close the dialog without waiting on form refresh
        this.handleDialogClose();
    };

    // returns info about last partial depreciation
    getCurrentDepreciationSettingsInfo(): IDepreciationSettingEntity {
        const currentDate = this.getFirstAvailableFiscalYearDateStart();
        const { DepreciationSettings } = this.entity.TaxDepreciationPolicy;
        return DepreciationSettings[getCurrentRangeIndex(DepreciationSettings as TTemporal[], currentDate)];
    }

    async saveDepreciationSettings(newData: IDepreciationSettingEntity[]): Promise<void> {
        const { storage } = this.props;
        const bc = storage.data.bindingContext
                .navigate(AssetEntity.TaxDepreciationPolicy)
                .navigate(TaxDepreciationPolicyEntity.DepreciationSettings);

        const ret = await updateAssetPartially(storage, bc, newData);

        await this.handleEditableWindowActionFinish();
        if (isODataError(ret)) {
            storage.setFormAlert(getAlertFromError(ret));
        }
    }

    async createNewDepreciationSettings(type = DepreciationSettingTypeCode.Partial): Promise<void> {
        const { storage } = this.props;
        storage.setBusy(true);

        const { DateStart, DateEnd } = getFirstActiveFiscalYearOfAssetInUse(storage) ?? {};
        const { TaxDepreciationPolicy } = this.entity;
        if (!TaxDepreciationPolicy.DepreciationSettings) {
            TaxDepreciationPolicy.DepreciationSettings = [];
        }
        // filter out all rows after the new one - in case some settings was configured in the future
        const currentDayjs = getUtcDayjs(DateStart);
        TaxDepreciationPolicy.DepreciationSettings = TaxDepreciationPolicy.DepreciationSettings
                .filter(item => currentDayjs.isAfter(getUtcDayjs(item.DateValidFrom), "day"));

        const expense = type === DepreciationSettingTypeCode.Partial ? storage.getValueByPath(DepreciationValueLocalPath) : null;
        const validTo = storage.getValueByPath(DepreciationSettingsValidityLocalPath) === Validity.LongTerm ? DATE_MAX : DateEnd;
        const defaultData: IDepreciationSettingEntity = {
            DateValidFrom: DateStart,
            DateValidTo: validTo,
            PartialExpense: expense,
            DepreciationSettingTypeCode: type
        };
        const newSettings = BindingContext.createNewEntity(1, defaultData);
        TaxDepreciationPolicy.DepreciationSettings.push(newSettings);

        await this.saveDepreciationSettings(TaxDepreciationPolicy.DepreciationSettings);

        storage.setBusy(false);
    }

    handleDepreciationSettingCancel = async (dateFrom: Date) => {
        let currentRow: IDepreciationSettingEntity | false = null;
        const { TaxDepreciationPolicy } = this.entity;
        while (currentRow === null) {
           const lastRow = this.getCurrentDepreciationSettingsInfo();
            if (!lastRow) {
                // in case there is no remaining rows to update, we just set false to break the loop
                currentRow = false;
            } else if (getUtcDayjs(lastRow.DateValidFrom).isSameOrAfter(dateFrom)) {
               // last row is in the future, it should not be there at all
               TaxDepreciationPolicy.DepreciationSettings =
                       TaxDepreciationPolicy.DepreciationSettings.filter(row => row !== lastRow);
            } else {
               currentRow = lastRow;
            }
        }
        if (currentRow !== false) {
            currentRow.DateValidTo = getUtcDayjs(dateFrom).subtract(1, "day").toDate();
        }

        await this.saveDepreciationSettings(TaxDepreciationPolicy.DepreciationSettings);
    };

    handleDepreciationSettingDialogClose = async (type: DepreciationSettingTypeCode, isConfirmed: boolean) => {
        this.handleDialogClose();
        if (isConfirmed) {
            await this.createNewDepreciationSettings(type);
        }
    };

    renderNewDepreciationSettingDialog(isInterrupt: boolean) {
        const { storage } = this.props;
        const type = isInterrupt ? DepreciationSettingTypeCode.Interrupted : DepreciationSettingTypeCode.Partial;
        const row = isInterrupt ? [] : [DepreciationValueLocalPath];
        row.push(DepreciationSettingsValidityLocalPath);

        return (
                <TemporalFormDialog storage={storage}
                                    fieldPaths={[row]}
                                    title={storage.t(`FixedAsset:Form.${isInterrupt ? "Interrupt" : "PartialDepreciation"}DialogTitle`)}
                                    onClose={this.handleDepreciationSettingDialogClose.bind(this, type)}/>
        );
    }

    renderDiscardDialog() {
        const { storage } = this.props;

        const initialData = {
            origEntity: {
                AssetId: this.props.storage.data.bindingContext.getKey()
            },
            customData: {
                assetStorage: storage
            }
        };
        // use local context, there is no real entity
        const bindingContext = createBindingContext(BindingContext.localContext("AssetDisposal"), storage.oData.metadata);
        return (
                <PlugAndPlayForm
                        getDef={getDiscardDefinition}
                        t={storage.t}
                        bindingContext={bindingContext}
                        formView={FixedAssetDisposalDialogFormView}
                        initialData={initialData}
                        formViewProps={{
                            dialogProps: {
                                title: storage.t("FixedAsset:Disposal.Title")
                            },
                            formProps: {
                                renderScrollbar: false
                            },
                            onAfterConfirm: this.handleDiscardDialogConfirm,
                            onClose: this.handleDialogClose
                        }}
                />
        );
    }

    renderDialog() {
        switch (this.state?.dialogType) {
            case AssetDialogType.PolicyDepreciation:
                return this.renderPolicyDeprecationDialog();
            case AssetDialogType.UnsortedAsset:
                return this.renderUnsortedAssetDialog();
            case AssetDialogType.Discard:
                return this.renderDiscardDialog();
            case AssetDialogType.PartialDepreciation:
            case AssetDialogType.Interrupt:
                return this.renderNewDepreciationSettingDialog(this.state.dialogType === AssetDialogType.Interrupt);
            default:
                return null;
        }
    }

    render() {
        if (!this.isReady()) {
            return <BusyIndicator isDelayed/>;
        }

        return (
                <>
                    {this.renderForm()}
                    {this.renderDialog()}
                </>
        );
    }
}

export default withPromisedComponent(withPermissionContext(withConfirmationDialog(FixedAssetFormView)));