import { ISelectItem } from "@components/inputs/select/Select.types";
import { IGetValueArgs } from "@components/smart/FieldInfo";
import { getCommonFilterDefs } from "@components/smart/GeneralFieldDefinition";
import { FilterBarGroup, getDefaultFilterGroupDef } from "@components/smart/smartFilterBar/SmartFilterBar.types";
import { ISummaryItem } from "@components/smart/smartSummaryItem/SmartSummaryItem";
import {
    EntitySetName,
    EntityTypeName,
    PrHealthInsuranceCompanyEntity,
    PrInsuranceCompanyPredefinedEntity
} from "@odata/GeneratedEntityTypes";
import { PrInsuranceCompanyTypeCode } from "@odata/GeneratedEnums";
import { generalBankAccountFormatter } from "@utils/BankUtils";
import i18next from "i18next";

import { BasicInputSizes, CacheStrategy, FieldType, Sort } from "../../../enums";
import BindingContext from "../../../odata/BindingContext";
import { IFormDef } from "../../../views/formView/Form";
import { FormStorage } from "../../../views/formView/FormStorage";
import { ISplitPageTableDef } from "../../../views/table/TableView.utils";
import { paymentDetailFields, SAVED_ACCOUNTS_PATH } from "../../banks/bankAccounts/BankAccounts.utils";
import { addBankAccountTableDefs } from "../../documents/DocumentCommonDefs";
import { setDefByEntityType } from "../../getDefByEntityType";
import { IDefinition, IGetDefinition } from "../../PageUtils";
import { insuranceCompanyAccountAdditionalFields } from "../pensionInsuranceCompany/PensionInsuranceCompany.def";
import HealthInsuranceCompanyFormView from "./HealthInsuranceCompanyFormView";
import {
    getInsuranceCompaniesCommonColumnDef,
    getInsuranceCompaniesCommonFieldDef,
    getPredefinedCompanies
} from "./insuranceCompanies.utils";

export const predefinedHealthInsuranceCompanyPath = BindingContext.localContext("PredefinedHealthInsuranceCompany");

export const getDefinitions: IGetDefinition = (): IDefinition => {
    const table: ISplitPageTableDef = {
        id: `${EntityTypeName.PrHealthInsuranceCompany}Table`,
        title: i18next.t("InsuranceCompanies:HealthInsurance.Title"),
        filterBarDef: [{
            ...getDefaultFilterGroupDef(FilterBarGroup.Filters),
            defaultFilters: [
                PrHealthInsuranceCompanyEntity.Name,
                PrHealthInsuranceCompanyEntity.IsActive
            ],
            filterDefinition: {
                ...getCommonFilterDefs(),
                [PrHealthInsuranceCompanyEntity.Name]: {},
                [PrHealthInsuranceCompanyEntity.IsActive]: {}
            },
            isValueHelp: true
        }],
        initialSortBy: [{ id: PrHealthInsuranceCompanyEntity.ShortName, sort: Sort.Desc }],
        columns: [
            PrHealthInsuranceCompanyEntity.ShortName,
            PrHealthInsuranceCompanyEntity.Name,
            PrHealthInsuranceCompanyEntity.HealthInsuranceCompanyCode,
            PrHealthInsuranceCompanyEntity.BankAccount,
            PrHealthInsuranceCompanyEntity.IsActive
        ],
        columnDefinition: {
            [PrHealthInsuranceCompanyEntity.ShortName]: {},
            [PrHealthInsuranceCompanyEntity.Name]: {},
            [PrHealthInsuranceCompanyEntity.BankAccount]: {
                additionalProperties: insuranceCompanyAccountAdditionalFields,
                formatter: generalBankAccountFormatter
            },
            [PrHealthInsuranceCompanyEntity.HealthInsuranceCompanyCode]: {},
            ...getInsuranceCompaniesCommonColumnDef()
        },
    };

    const summary: ISummaryItem[] = [
        { id: PrHealthInsuranceCompanyEntity.Name }
    ];

    const form: IFormDef = {
        id: `${EntityTypeName.PrHealthInsuranceCompany}Form`,
        translationFiles: getDefinitions.translationFiles,
        isDeletable: true,
        formControl: HealthInsuranceCompanyFormView,
        summary,
        additionalProperties: [{
            id: PrInsuranceCompanyPredefinedEntity.ShortName
        }],
        fieldDefinition: {
            [predefinedHealthInsuranceCompanyPath]: {
                type: FieldType.ComboBox,
                label: i18next.t("InsuranceCompanies:Name"),
                width: BasicInputSizes.XL,
                cacheStrategy: CacheStrategy.EndOfTime,
                fieldSettings: {
                    displayName: PrInsuranceCompanyPredefinedEntity.ShortName,
                    shouldDisplayAdditionalColumns: true,
                    itemsFactory: async (args: IGetValueArgs): Promise<ISelectItem[]> => {
                        const companies = await getPredefinedCompanies(args.storage as FormStorage, PrInsuranceCompanyTypeCode.HealthInsuranceCompany);
                        return companies?.map(company => {
                            return {
                                id: company.Code,
                                label: company.ShortName,
                                additionalData: company
                            };
                        });
                    }
                },
                columns: [
                    {
                        id: PrInsuranceCompanyPredefinedEntity.ShortName,
                    },
                    {
                        id: PrInsuranceCompanyPredefinedEntity.Name
                    }
                ]
            },
            [PrHealthInsuranceCompanyEntity.HealthInsuranceCompanyCode]: {
                width: BasicInputSizes.XS
            },
            ...getInsuranceCompaniesCommonFieldDef()
        },
        groups: [
            {
                id: "main",
                rows: [
                    [{ id: predefinedHealthInsuranceCompanyPath }, { id: PrHealthInsuranceCompanyEntity.HealthInsuranceCompanyCode },
                        { id: PrHealthInsuranceCompanyEntity.DataBoxId }, { id: PrHealthInsuranceCompanyEntity.IsActive }]
                ]
            },
            {
                id: "bankAccount",
                title: i18next.t("InsuranceCompanies:BankAccounts"),
                rows: [[
                    { id: SAVED_ACCOUNTS_PATH }
                ], [
                    ...paymentDetailFields
                ]],
            }
        ]
    };

    const def = {
        entitySet: EntitySetName.PrHealthInsuranceCompanies,
        table,
        form
    };

    addBankAccountTableDefs(def);

    return def;
};

getDefinitions.translationFiles = ["InsuranceCompanies", "Common"];
setDefByEntityType(EntityTypeName.PrHealthInsuranceCompany, getDefinitions);