import React from "react";
import { ActionsWrapper, StyledWriteLineGroup, WriteLineWrapper } from "./WriteLineGroup.styles";
import { IconButton } from "../../button";
import { ZoomInIcon, ZoomOutIcon } from "../../icon";
import NumericWriteLine from "./NumericWriteLine";
import { WithTranslation, withTranslation } from "react-i18next";
import { isDefined } from "@utils/general";
import NumberType from "../../../types/Number";
import { IValidationError } from "../../../model/Validator.types";

interface IProps extends WithTranslation {
    value: string[];
    disabledValues: boolean[];
    onChange: (values: string[], lengthChange: boolean) => void;
    width?: string;
    isLight?: boolean;
    defaultValue?: string;
    errors?: IValidationError[];
    min?: number;
    max?: number;
}

class WriteLineGroup extends React.PureComponent<IProps> {
    static defaultProps = {
        defaultValue: "0",
        min: 1
    };

    handleValueChange = (value: number, index: number) => {
        const newValues = [...this.props.value];

        newValues[index] = value?.toString();

        this.props.onChange(newValues, false);
    };

    renderValue = (value: string, index: number, isDisabled: boolean, error: IValidationError) => {
        return (
            <WriteLineWrapper key={index}>
                <NumericWriteLine
                    min={1}
                    width={this.props.width}
                    isLight={this.props.isLight}
                    isDisabled={isDisabled}
                    value={NumberType.parse(value)}
                    error={error}
                    onChange={(args) => {
                        this.handleValueChange(args.value, index);
                    }}/>
            </WriteLineWrapper>
        );
    };

    handleRemoveClick = () => {
        this.props.onChange(this.props.value.slice(0, -1), true);
    };

    handleAddClick = () => {
        this.props.onChange([...this.props.value, this.props.value?.length > 0 ? this.props.value.slice(-1)[0] : this.props.defaultValue], true);
    };

    render() {
        return (
            <StyledWriteLineGroup>
                {this.props.value.map((value, index) => {
                    return this.renderValue(value, index, !!this.props.disabledValues?.[index], this.props.errors?.[index]);
                })}
                <ActionsWrapper>
                    <IconButton onClick={this.handleRemoveClick}
                                isDisabled={isDefined(this.props.min) && this.props.value.length === this.props.min}
                                isLight={this.props.isLight}
                                title={this.props.t("Common:General.Remove")} isDecorative>
                        <ZoomOutIcon/>
                    </IconButton>
                    <IconButton onClick={this.handleAddClick}
                                isDisabled={isDefined(this.props.max) && this.props.value.length === this.props.max}
                                isLight={this.props.isLight}
                                title={this.props.t("Common:General.Add")} isDecorative>
                        <ZoomInIcon/>
                    </IconButton>
                </ActionsWrapper>
            </StyledWriteLineGroup>
        );
    }
}

export default withTranslation(["Common"])(WriteLineGroup);