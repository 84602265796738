import React, { Component } from "react";
import { IInputOnChangeEvent, IInputProps } from "../input";
import { TextAlign } from "../../../enums";
import { StyledNumericInput } from "./DayInput.styles";
import { WithTranslation, withTranslation } from "react-i18next";
import { Dayjs } from "dayjs";
import { KeyName } from "../../../keyName";

export interface IProps extends WithTranslation, Omit<IInputProps, "value" | "onChange"> {
    date: Dayjs;
    value: number;
    onChange: (val: number, date: Dayjs) => void;
    onDaySelect: (date: Dayjs) => void;
    isSelected: boolean;
    hasError: boolean;
}

class DayInput extends Component<IProps> {
    handleChange = (args: IInputOnChangeEvent<number>) => {
        this.props.onChange(args.value, this.props.date);
    };

    handleSelect = () => {
        return this.props.onDaySelect(this.props.date);
    };

    handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === KeyName.Enter) {
            return this.props.onDaySelect(this.props.date);
        }
    }

    render() {
        return <>
            <StyledNumericInput
                $isSelected={this.props.isSelected}
                $hasError={this.props.hasError}
                onClick={this.handleSelect}
                onKeyPress={this.handleKeyPress}
                placeholder={this.props.t("Components:Calendar.HourPlaceholder")}
                value={this.props.value}
                onChange={this.handleChange}
                textAlign={TextAlign.Right}/>
        </>;
    }
}

export default withTranslation(["Components"])(DayInput);