import styled from "styled-components/macro";

import { T_PLAIN_big } from "../../../global.style";

export const PrimarySubTitle = styled.span `
    text-align: center;
    font-size: 14px;
    margin-top: 10px;
    font-style: italic;
`;

export const StyledAPISettingsDescText = styled.span`
    ${T_PLAIN_big};
    margin-top: 15px;
`;

export const BankKeyLinkWrapper = styled.div`
    display: flex;
    justify-content: center;
    ${T_PLAIN_big};
    width: 100%;
    padding-bottom: 8px;
    
    & > a {
        display: flex;
    }
`;

export const ErrorText = styled.div`
    ${T_PLAIN_big};
`;
