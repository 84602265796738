import NumericWriteLine from "../writeLine/NumericWriteLine";
import { BasicInputSizes } from "../../../enums";
import { IValueInterval } from "../../smart/smartValueHelper";
import React, { useCallback } from "react";
import { IInputOnChangeEvent, IInputProps } from "../input";
import { StyledNumericRange } from "./NumericRange.styles";
import { IValidationError } from "../../../model/Validator.types";

export interface IValidationErrorInterval {
    from: IValidationError;
    to: IValidationError;
}

interface IProps extends Omit<IInputProps<IValueInterval<number>>, "error" | "onChange"> {
    error: IValidationErrorInterval;
    onChange: (value: IValueInterval<number>) => void;
}

export const NumericRange = (props: IProps) => {
    const { width, value, onChange, onBlur, error, ...forwardProps } = props;

    const handleChange = useCallback((key: keyof IValueInterval, e: IInputOnChangeEvent) => {
        const newVal = {
            ...value,
            [key]: e.value
        };
        onChange(newVal);
    }, [onChange, value]);

    const handleChangeFrom = useCallback((e: IInputOnChangeEvent) => handleChange("from", e), [handleChange]);
    const handleChangeTo = useCallback((e: IInputOnChangeEvent) => handleChange("to", e), [handleChange]);

    return (
        <>
            <StyledNumericRange _width={width}>
                <NumericWriteLine {...forwardProps}
                                  value={value?.from}
                                  error={error?.from}
                                  onBlur={onBlur}
                                  onChange={handleChangeFrom}
                />
                &nbsp;-&nbsp;
                <NumericWriteLine {...forwardProps}
                                  width={BasicInputSizes.S}
                                  value={value?.to}
                                  error={error?.to}
                                  onBlur={onBlur}
                                  onChange={handleChangeTo}
                />
            </StyledNumericRange>
        </>
    );
};