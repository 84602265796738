import {
    getDaysUnit,
    ifAll,
    IFieldDefFn,
    IGetValueArgs,
    not,
    TTemporalDialogSettings
} from "@components/smart/FieldInfo";
import { ActionButtonPosition } from "@components/smart/smartFastEntryList";
import { FilterBarGroup, getDefaultFilterGroupDef } from "@components/smart/smartFilterBar/SmartFilterBar.types";
import { IFormGroupDef } from "@components/smart/smartFormGroup/SmartFormGroup";
import { ISummaryItem } from "@components/smart/smartSummaryItem/SmartSummaryItem";
import {
    EntitySetName,
    EntityTypeName,
    IPrEmploymentTemplateEntity,
    IPrWorkingPatternEntity,
    PrEmploymentEntity,
    PrEmploymentExtraSalaryEntity,
    PrEmploymentExtraSalaryTemporalCurrentEntity,
    PrEmploymentExtraSalaryTemporalEntity,
    PrEmploymentSalaryComponentEntity,
    PrEmploymentSalaryComponentTemporalCurrentEntity,
    PrEmploymentSalaryComponentTemporalEntity,
    PrEmploymentTemplateEntity,
    PrEmploymentTemplateExtraSalaryEntity,
    PrEmploymentTemplateExtraSalaryTemporalCurrentEntity,
    PrEmploymentTemplateExtraSalaryTemporalEntity,
    PrEmploymentTemplateSalaryComponentEntity,
    PrEmploymentTemplateSalaryComponentTemporalCurrentEntity,
    PrEmploymentTemplateSalaryComponentTemporalEntity,
    PrEmploymentTemplateTemporalEntity,
    PrEmploymentTemporalEntity,
    PrExtraSalaryComputationTypeEntity,
    PrExtraSalaryEntity,
    PrSalaryComponentEntity,
    PrSalaryComponentTypeEntity,
    PrWorkingPatternDayEntity,
    PrWorkingPatternEntity
} from "@odata/GeneratedEntityTypes";
import {
    PrExtraSalaryComputationTypeCode,
    PrSalaryComponentTypeCode,
    PrWorkingPatternTypeCode
} from "@odata/GeneratedEnums";
import { IFormatOptions } from "@odata/OData.utils";
import {
    getTemporalAdditionalProperties,
    getTemporalPropertyFieldDefinition,
    getTemporalPropertyLineItemFieldDefinition,
    TemporalValidityFieldsDef
} from "@odata/TemporalUtils";
import { getCompanyCurrency } from "@utils/CompanyUtils";
import { roundToDecimalPlaces } from "@utils/general";
import Big from "big.js";
import i18next from "i18next";
import React from "react";

import { DASH_CHARACTER } from "../../../constants";
import { BasicInputSizes, FastEntryInputSizes, FieldType, LabelStatus, NavigationSource, Sort } from "../../../enums";
import { TValue } from "../../../global.types";
import { Model } from "../../../model/Model";
import BindingContext, { createPath } from "../../../odata/BindingContext";
import CurrencyType, { formatCurrency } from "../../../types/Currency";
import { getUtcDayjs } from "../../../types/Date";
import { IFormDef } from "../../../views/formView/Form";
import { ISplitPageTableDef } from "../../../views/table/TableView.utils";
import { setDefByEntityType } from "../../getDefByEntityType";
import {
    getNumberOursSummaryDef,
    getNumberRangeFieldDefs,
    NumberRangeAdditionalProperties
} from "../../numberRange/NumberRange.utils";
import { getItemBreadCrumbsText, IDefinition, IGetDefinition, TFieldsDefinition } from "../../PageUtils";
import {
    DialogType,
    EmploymentTranslationFiles,
    ExtraSalaryGrpId,
    ExtraSalaryTogglePath,
    getExtractHistoryFromTemplateFn,
    getExtraSalaryTemporalDialogSettings,
    getSalaryComponentTemporalDialogSettings,
    isLegislativeExtraSalary,
    isLegislativeExtraSalaryItem,
    LegislativeExtraSalaryLocalPath,
    SalaryComponentGrpId
} from "../employment/Employment.utils";
import CollapsibleSectionToggleField from "../extraSalary/CollapsibleSectionToggleField";
import { getExtraSalaryValueUnit } from "../extraSalary/ExtraSalary.utils";
import LegislativeExtraSalariesSmartFastEntryList from "../extraSalary/LegislativeExtraSalariesSmartFastEntryList";
import EmploymentTemplateFormView from "./EmploymentTemplateFormView";


function getLineItemBindingContext(bc: BindingContext, collectionName: string): BindingContext {
    do {
        bc = bc.getParent();
    } while (bc.getPath(true) !== collectionName);
    return bc;
}

// function isLineItemWithoutDefault(collectionName: string, { storage, bindingContext }: IGetValueArgs): boolean {
//     const lineItemBc = getLineItemBindingContext(bindingContext, collectionName);
//     const lineItem = storage.getValue(lineItemBc);
//     return lineItem && (isNotDefined(lineItem.Default) || isEmpty(lineItem.Default));
// }

// const isExtraSalaryWithoutDefault = isLineItemWithoutDefault.bind(null, PrEmploymentTemplateEntity.ExtraSalaries);
// const isSalaryComponentWithoutDefault = isLineItemWithoutDefault.bind(null, PrEmploymentTemplateEntity.SalaryComponents);

export function isPartOfLegislativeExtraSalaryItem({ storage, bindingContext }: IGetValueArgs): boolean {
    const bc = getLineItemBindingContext(bindingContext, PrEmploymentTemplateEntity.ExtraSalaries);
    return isLegislativeExtraSalary({ storage, bindingContext: bc });
}

function areExtraSalariesToggled({ storage }: IGetValueArgs): boolean {
    return storage.getValueByPath(ExtraSalaryTogglePath);
}

const leaveDaysTemporalDialogSettings: TTemporalDialogSettings = {
    columns: [
        PrEmploymentTemporalEntity.LeaveDays,
        PrEmploymentTemporalEntity.WorkingPattern
    ]
};


// TODO: remove after this is merged https://dev.azure.com/soliteacr/IRIS/_git/IRIS/pullrequest/25244?_a=files&path=/WebApp/ClientApp/src/pages/payroll/attendance/Attendance.utils.tsx
const getHoursFromTimeStamp = (timeStamp: Date): number => {
    return getUtcDayjs(timeStamp).get("hours") + roundToDecimalPlaces(3, getUtcDayjs(timeStamp).get("minutes") / 60);
};

export const getCommonEmploymentDefs = (isTemplate: boolean): TFieldsDefinition => {

    const tmpFieldsDef = { ...TemporalValidityFieldsDef };

    const TemporalEntity = isTemplate ? PrEmploymentTemplateTemporalEntity : PrEmploymentTemporalEntity;
    const MainEntity = isTemplate ? PrEmploymentTemplateEntity : PrEmploymentEntity;
    const SalaryComponentTemporalEntity = isTemplate ? PrEmploymentTemplateSalaryComponentTemporalEntity : PrEmploymentSalaryComponentTemporalEntity;
    const ExtraSalaryTemporalEntity = isTemplate ? PrEmploymentTemplateExtraSalaryTemporalEntity : PrEmploymentExtraSalaryTemporalEntity;

    return {
        ...getTemporalPropertyFieldDefinition({
            propName: TemporalEntity.LeaveDays,
            definition: {
                width: BasicInputSizes.M,
                additionalProperties: [{
                    id: `/${createPath(PrEmploymentTemplateEntity.TemporalPropertyBag, PrEmploymentTemplateTemporalEntity.WorkingPattern, PrWorkingPatternEntity.TypeCode)}`
                }, {
                    id: `/${createPath(PrEmploymentTemplateEntity.TemporalPropertyBag, PrEmploymentTemplateTemporalEntity.WorkingPattern, PrWorkingPatternEntity.Days, PrWorkingPatternDayEntity.WorkingHours)}`
                }, {
                    id: `/${createPath(PrEmploymentTemplateEntity.CurrentTemporalPropertyBag, PrEmploymentTemplateTemporalEntity.WorkingPattern, PrWorkingPatternEntity.TypeCode)}`
                }, {
                    id: `/${createPath(PrEmploymentTemplateEntity.CurrentTemporalPropertyBag, PrEmploymentTemplateTemporalEntity.WorkingPattern, PrWorkingPatternEntity.Days, PrWorkingPatternDayEntity.WorkingHours)}`
                }],
                fieldSettings: {
                    unit: ({ storage, bindingContext }): string => {
                        const workingPattern = storage.getValue(bindingContext.getParent())?.WorkingPattern as IPrWorkingPatternEntity;
                        if (workingPattern?.TypeCode !== PrWorkingPatternTypeCode.Weekly) {
                            return DASH_CHARACTER;
                        }

                        let workDaysCounter = 0;
                        const workHours = workingPattern.Days.reduce((hours, day): number => {
                            const workingHours = getHoursFromTimeStamp(day.WorkingHours);
                            if (workingHours > 0) {
                                workDaysCounter++;
                                return new Big(hours).add(workingHours).toNumber();
                            }
                            return hours;
                        }, 0);

                        if (!workDaysCounter) {
                            return DASH_CHARACTER;
                        }
                        const leaveDays = storage.getValue(bindingContext) ?? 0;
                        const hours = isNaN(leaveDays) ? DASH_CHARACTER : roundToDecimalPlaces(1, new Big(workHours).div(workDaysCounter).mul(leaveDays).toNumber());
                        return storage.t("Employment:TemplateForm.LeaveDaysUnit", { hours }).toString();
                    },
                    temporalDialog: {
                        ...leaveDaysTemporalDialogSettings,
                        dialogTitle: i18next.t("Employment:TemplateForm.PlanLeaveDaysChange"),
                        extractHistory: getExtractHistoryFromTemplateFn(TemporalEntity.LeaveDays)
                    }
                }
            },
            isSynchronized: true
        }),
        ...getTemporalPropertyFieldDefinition({
            propName: TemporalEntity.SickDays,
            definition: {
                width: BasicInputSizes.S,
                fieldSettings: {
                    unit: getDaysUnit,
                    temporalDialog: {
                        dialogTitle: i18next.t("Employment:TemplateForm.PlanSickDaysChange"),
                        extractHistory: getExtractHistoryFromTemplateFn(TemporalEntity.SickDays)
                    }
                }
            },
            isSynchronized: true
        }),
        ...getTemporalPropertyFieldDefinition({
            propName: TemporalEntity.WorkingPattern,
            definition: {
                type: FieldType.ComboBox,
                width: BasicInputSizes.XL,
                fieldSettings: {
                    displayName: "Name",
                    shouldDisplayAdditionalColumns: true,
                    preloadItems: true,
                    temporalDialog: {
                        ...leaveDaysTemporalDialogSettings,
                        dialogTitle: i18next.t("Employment:TemplateForm.PlanWorkingPatternChange"),
                        extractHistory: getExtractHistoryFromTemplateFn(TemporalEntity.WorkingPattern)
                    },
                    additionalProperties: [{
                        id: PrWorkingPatternEntity.TypeCode
                    }, {
                        id: createPath(PrWorkingPatternEntity.Days, PrWorkingPatternDayEntity.WorkingHours)
                    }],
                    localDependentFields: [
                        {
                            from: { id: PrWorkingPatternEntity.Days }, to: { id: PrWorkingPatternEntity.Days },
                            navigateFrom: NavigationSource.Itself
                        },
                        {
                            from: { id: PrWorkingPatternEntity.TypeCode }, to: { id: PrWorkingPatternEntity.TypeCode },
                            navigateFrom: NavigationSource.Itself
                        }
                    ]
                },
                columns: [
                    { id: "Name" },
                    { id: "Type/Name" }
                ]
            },
            isSynchronized: !isTemplate
        }),
        ...getTemporalPropertyLineItemFieldDefinition({
            collectionName: MainEntity.SalaryComponents,
            propName: SalaryComponentTemporalEntity.Name,
            definition: {
                width: FastEntryInputSizes.M,
                fieldSettings: {
                    temporalDialog: getSalaryComponentTemporalDialogSettings()
                }
                // isRequired: isSalaryComponentWithoutDefault
            },
            isSynchronized: true
        }),
        ...getTemporalPropertyLineItemFieldDefinition({
            collectionName: MainEntity.SalaryComponents,
            propName: SalaryComponentTemporalEntity.Type,
            definition: {
                type: FieldType.ComboBox,
                width: FastEntryInputSizes.S,
                defaultValue: PrSalaryComponentTypeCode.Monthly,
                fieldSettings: {
                    displayName: PrSalaryComponentTypeEntity.Name
                }
                // isRequired: isSalaryComponentWithoutDefault
            },
            withOpeningButton: false,
            isSynchronized: true
        }),
        ...getTemporalPropertyLineItemFieldDefinition({
            collectionName: MainEntity.SalaryComponents,
            propName: SalaryComponentTemporalEntity.Amount,
            definition: {
                width: FastEntryInputSizes.S,
                fieldSettings: {
                    unit: args => CurrencyType.getCurrencyUnit(getCompanyCurrency(args.context)),
                    temporalDialog: getSalaryComponentTemporalDialogSettings()
                },
                additionalProperties: [
                    { id: `/${MainEntity.SalaryComponents}/${PrSalaryComponentEntity.TemporalPropertyBag}/${SalaryComponentTemporalEntity.Name}` },
                    { id: `/${MainEntity.SalaryComponents}/${PrSalaryComponentEntity.TemporalPropertyBag}/${SalaryComponentTemporalEntity.Type}` },
                    { id: `/${MainEntity.SalaryComponents}/${PrSalaryComponentEntity.TemporalPropertyBag}/${SalaryComponentTemporalEntity.Amount}` }
                ]
                // isRequired: isSalaryComponentWithoutDefault
            },
            withOpeningButton: true,
            isSynchronized: true
        }),
        ...getTemporalPropertyLineItemFieldDefinition({
            collectionName: MainEntity.ExtraSalaries,
            propName: ExtraSalaryTemporalEntity.Name,
            definition: {
                width: BasicInputSizes.L,
                fieldSettings: {
                    temporalDialog: getExtraSalaryTemporalDialogSettings()
                },
                isReadOnly: isPartOfLegislativeExtraSalaryItem
                // isRequired: isExtraSalaryWithoutDefault
            },
            isSynchronized: true
        }),
        ...getTemporalPropertyLineItemFieldDefinition({
            collectionName: MainEntity.ExtraSalaries,
            propName: ExtraSalaryTemporalEntity.ComputationType,
            definition: {
                type: FieldType.ComboBox,
                width: BasicInputSizes.XL,
                defaultValue: PrExtraSalaryComputationTypeCode.AverageHourlySalaryPercentage,
                fieldSettings: {
                    displayName: PrExtraSalaryComputationTypeEntity.Name,
                    temporalDialog: getExtraSalaryTemporalDialogSettings()
                },
                isReadOnly: isPartOfLegislativeExtraSalaryItem
            },
            isSynchronized: true
        }),
        ...getTemporalPropertyLineItemFieldDefinition({
            collectionName: MainEntity.ExtraSalaries,
            propName: ExtraSalaryTemporalEntity.Value,
            definition: {
                width: BasicInputSizes.S,
                fieldSettings: {
                    unit: getExtraSalaryValueUnit,
                    temporalDialog: getExtraSalaryTemporalDialogSettings()
                },
                additionalProperties: [
                    { id: `/${MainEntity.ExtraSalaries}/${PrExtraSalaryEntity.TemporalPropertyBag}/${ExtraSalaryTemporalEntity.Name}` },
                    { id: `/${MainEntity.ExtraSalaries}/${PrExtraSalaryEntity.TemporalPropertyBag}/${ExtraSalaryTemporalEntity.ComputationType}` },
                    { id: `/${MainEntity.ExtraSalaries}/${PrExtraSalaryEntity.TemporalPropertyBag}/${ExtraSalaryTemporalEntity.Value}` }
                ]
                // isRequired: isExtraSalaryWithoutDefault
            },
            withOpeningButton: true,
            isSynchronized: true
        }),
        [LegislativeExtraSalaryLocalPath]: {
            type: FieldType.Custom,
            label: i18next.t("Employment:TemplateForm.LegislativeExtraSalaries"),
            labelStatus: LabelStatus.Removed,
            render: ({ props }: IFieldDefFn) => {
                return (
                        <LegislativeExtraSalariesSmartFastEntryList {...props} />
                );
            },
            customizationData: {
                useForCustomization: false
            }
        },
        [ExtraSalaryTogglePath]: {
            type: FieldType.Custom,
            label: i18next.t("Employment:TemplateForm.OwnExtraSalaries"),
            render: ({ props }: IFieldDefFn) => (
                    <CollapsibleSectionToggleField {...props} />
            ),
            customizationData: {
                useForCustomization: false
            }
        },
        ...tmpFieldsDef
    };
};

export const getCommonEmploymentGroupDef = (isTemplate: boolean): IFormGroupDef[] => {

    const TemporalEntity = isTemplate ? PrEmploymentTemplateTemporalEntity : PrEmploymentTemporalEntity;
    const MainEntity = isTemplate ? PrEmploymentTemplateEntity : PrEmploymentEntity;
    const SalaryComponentEntity = isTemplate ? PrEmploymentTemplateSalaryComponentEntity : PrEmploymentSalaryComponentEntity;
    const SalaryComponentTemporalCurrentEntity = isTemplate ? PrEmploymentTemplateSalaryComponentTemporalCurrentEntity : PrEmploymentSalaryComponentTemporalCurrentEntity;
    const ExtraSalaryEntity = isTemplate ? PrEmploymentTemplateExtraSalaryEntity : PrEmploymentExtraSalaryEntity;
    const ExtraSalaryTemporalCurrentEntity = isTemplate ? PrEmploymentTemplateExtraSalaryTemporalCurrentEntity : PrEmploymentExtraSalaryTemporalCurrentEntity;

    return [{
            id: SalaryComponentGrpId,
            title: i18next.t("Employment:TemplateForm.SalaryComponents"),
            showGroupDivider: false,
            lineItems: {
                collection: MainEntity.SalaryComponents,
                order: "Id",
                temporalDialog: getSalaryComponentTemporalDialogSettings(),
                columns: [
                    { id: createPath(SalaryComponentEntity.CurrentTemporalPropertyBag, SalaryComponentTemporalCurrentEntity.Name) },
                    { id: createPath(SalaryComponentEntity.CurrentTemporalPropertyBag, SalaryComponentTemporalCurrentEntity.Type) },
                    { id: createPath(SalaryComponentEntity.CurrentTemporalPropertyBag, SalaryComponentTemporalCurrentEntity.Amount) }
                ],
                canReorder: false,
                isItemCloneable: false,
                isAddButtonTransparent: true,
                isTheOnlyItemRemovable: true,
                customActionButtons: [{
                    id: DialogType.SalaryComponent,
                    title: i18next.t("Employment:TemplateForm.Pick"),
                    position: ActionButtonPosition.Before,
                    isTransparent: false
                }]
            }
        }, {
            id: ExtraSalaryGrpId,
            title: i18next.t("Employment:TemplateForm.ExtraSalaries"),
            showGroupDivider: false,
            rows: [
                [{ id: LegislativeExtraSalaryLocalPath }],
                [{ id: ExtraSalaryTogglePath }]
            ],
            lineItems: {
                collection: MainEntity.ExtraSalaries,
                order: "Id",
                temporalDialog: getExtraSalaryTemporalDialogSettings(),
                columns: [
                    { id: createPath(ExtraSalaryEntity.CurrentTemporalPropertyBag, ExtraSalaryTemporalCurrentEntity.Name) },
                    { id: createPath(ExtraSalaryEntity.CurrentTemporalPropertyBag, ExtraSalaryTemporalCurrentEntity.ComputationType) },
                    { id: createPath(ExtraSalaryEntity.CurrentTemporalPropertyBag, ExtraSalaryTemporalCurrentEntity.Value) }
                ],
                additionalFields: [
                    { id: createPath(ExtraSalaryEntity.Default, PrExtraSalaryEntity.BaseTypeCode) }
                ],
                canAdd: areExtraSalariesToggled,
                canReorder: false,
                isItemCloneable: false,
                // LegislativeExtraSalaries are rendered separately, so they can be collapsed
                isItemVisible: ifAll(not(isLegislativeExtraSalary), areExtraSalariesToggled),
                isAddButtonTransparent: true,
                isTheOnlyItemRemovable: true,
                customActionButtons: [{
                    id: DialogType.ExtraSalary,
                    title: i18next.t("Employment:TemplateForm.Pick"),
                    position: ActionButtonPosition.Before,
                    isTransparent: false,
                    isVisible: areExtraSalariesToggled
                }]
            }
        }, {
            id: "workingTime",
            title: i18next.t("Employment:TemplateForm.WorkingTime"),
            rows: [[
                { id: createPath(MainEntity.CurrentTemporalPropertyBag, TemporalEntity.WorkingPattern) }
            ]]
        }, {
            id: "absence",
            title: i18next.t("Employment:TemplateForm.AbsenceValues"),
            rows: [[
                { id: createPath(MainEntity.CurrentTemporalPropertyBag, TemporalEntity.LeaveDays) },
                { id: createPath(MainEntity.CurrentTemporalPropertyBag, TemporalEntity.SickDays) }
            ]]
        }
    ];
};

const hasOwnExtraSalariesPath = BindingContext.localContext("hasOwnExtraSalaries");
const monthAmountSumPath = BindingContext.localContext("monthAmountSum");
const hourAmountSumPath = BindingContext.localContext("hourAmountSum");

const tableAmountFormatter = (typeCode: PrSalaryComponentTypeCode) => {
    return (val: TValue, args: IFormatOptions) => {
        const value = (args.entity as IPrEmploymentTemplateEntity).SalaryComponents?.reduce((acc, curr) => {
            if (curr.CurrentTemporalPropertyBag?.TypeCode === typeCode) {
                acc += curr.CurrentTemporalPropertyBag.Amount;
            }
            return acc;
        }, 0);
        return formatCurrency(value ?? 0, getCompanyCurrency(args.context));
    };
};

export const getDefinitions: IGetDefinition = (): IDefinition => {
    const table: ISplitPageTableDef = {
        id: `${EntityTypeName.PrEmploymentTemplate}Table`,
        additionalProperties: [{
            id: createPath(PrEmploymentTemplateEntity.ExtraSalaries, PrEmploymentTemplateExtraSalaryEntity.Default, PrExtraSalaryEntity.TypeCode)
        }, {
            id: createPath(PrEmploymentTemplateEntity.SalaryComponents, PrSalaryComponentEntity.CurrentTemporalPropertyBag, PrEmploymentSalaryComponentTemporalCurrentEntity.Amount)
        }, {
            id: createPath(PrEmploymentTemplateEntity.SalaryComponents, PrSalaryComponentEntity.CurrentTemporalPropertyBag, PrEmploymentSalaryComponentTemporalCurrentEntity.TypeCode)
        }],
        filterBarDef: [{
            ...getDefaultFilterGroupDef(FilterBarGroup.Filters),
            defaultFilters: [
                PrEmploymentTemplateEntity.NumberOurs,
                PrEmploymentTemplateEntity.Name,
                createPath(PrEmploymentTemplateEntity.CurrentTemporalPropertyBag, PrEmploymentTemplateTemporalEntity.WorkingPattern)
            ],
            filterDefinition: {
                [PrEmploymentTemplateEntity.NumberOurs]: {},
                [PrEmploymentTemplateEntity.Name]: {},
                [createPath(PrEmploymentTemplateEntity.CurrentTemporalPropertyBag, PrEmploymentTemplateTemporalEntity.WorkingPattern)]: {
                    fieldSettings: {
                        displayName: PrWorkingPatternEntity.Name
                    }
                }
            },
            isValueHelp: true
        }],
        initialSortBy: [{ id: PrEmploymentTemplateEntity.NumberOurs, sort: Sort.Asc }],
        columns: [
            PrEmploymentTemplateEntity.NumberOurs,
            PrEmploymentTemplateEntity.Name,
            createPath(PrEmploymentTemplateEntity.CurrentTemporalPropertyBag, PrEmploymentTemplateTemporalEntity.WorkingPattern),
            hasOwnExtraSalariesPath,
            monthAmountSumPath,
            hourAmountSumPath
        ],
        columnDefinition: {
            [PrEmploymentTemplateEntity.NumberOurs]: {},
            [PrEmploymentTemplateEntity.Name]: {},
            [createPath(PrEmploymentTemplateEntity.CurrentTemporalPropertyBag, PrEmploymentTemplateTemporalEntity.WorkingPattern)]: {
                fieldSettings: {
                    displayName: PrWorkingPatternEntity.Name
                }
            },
            [hasOwnExtraSalariesPath]: {
                label: i18next.t("Employment:TemplateTable.OwnExtraSalaries"),
                formatter: (val: TValue, args: IFormatOptions) => {
                    const hasOwnExtraSalary = (args.entity as IPrEmploymentTemplateEntity).ExtraSalaries?.some(salary => !isLegislativeExtraSalaryItem(salary)) ?? false;
                    return i18next.t(`Common:General.${hasOwnExtraSalary ? "Yes" : "No"}`).toString();
                }
            },
            [monthAmountSumPath]: {
                label: i18next.t("Employment:TemplateTable.MonthAmountSum"),
                formatter: tableAmountFormatter(PrSalaryComponentTypeCode.Monthly)
            },
            [hourAmountSumPath]: {
                label: i18next.t("Employment:TemplateTable.HourAmountSum"),
                formatter: tableAmountFormatter(PrSalaryComponentTypeCode.Hourly)
            }
        },
        title: i18next.t("Employment:Template.Title")
    };

    const summary: ISummaryItem[] = [{
        ...getNumberOursSummaryDef("Employment")
    }];
    const form: IFormDef = {
        id: `${EntityTypeName.PrEmploymentTemplate}Form`,
        translationFiles: getDefinitions.translationFiles,
        getItemBreadCrumbText: (storage: Model) =>
                getItemBreadCrumbsText(storage, i18next.t("Employment:Template.New"), i18next.t("Employment:Template.FormTitle")),
        isDeletable: true,
        formControl: EmploymentTemplateFormView,
        summary,
        additionalProperties: [
            ...getTemporalAdditionalProperties(PrEmploymentTemplateTemporalEntity, PrEmploymentTemplateEntity),
            ...NumberRangeAdditionalProperties,
            {
                id: PrEmploymentTemplateEntity.ExtraSalaries,
                additionalProperties: [
                    {
                        id: PrEmploymentTemplateExtraSalaryEntity.Default,
                        additionalProperties: [
                            { id: PrExtraSalaryEntity.TypeCode },
                            { id: PrExtraSalaryEntity.BaseTypeCode }
                        ]
                    }
                ]
            },
            {
                id: PrEmploymentTemplateEntity.SalaryComponents,
                additionalProperties: [
                    { id: PrEmploymentTemplateSalaryComponentEntity.Default }
                ]
            }
        ],
        fieldDefinition: {
            ...getNumberRangeFieldDefs("Employment"),
            [PrEmploymentTemplateEntity.Name]: {
                width: BasicInputSizes.XL
            },
            ...getCommonEmploymentDefs(true)
        },
        groups: [
            {
                id: "main",
                rows: [[
                    { id: PrEmploymentTemplateEntity.Name }
                ]]
            },
            ...getCommonEmploymentGroupDef(true)
        ]
    };
    return {
        entitySet: EntitySetName.PrEmploymentTemplates,
        table,
        form
    };
};
getDefinitions.translationFiles = [...EmploymentTranslationFiles];
setDefByEntityType(EntityTypeName.PrEmploymentTemplate, getDefinitions);