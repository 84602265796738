import styled, { css } from "styled-components/macro";
import { getFocusBorderElement, LayoutStackOrder, T_TITLE_4 } from "../../global.style";

export const Navigation = styled.div`
    height: 100%;

    display: flex;
    min-width: 20px;
    overflow: visible;
    z-index: ${LayoutStackOrder.Menu};
`;
export const NavList = styled.nav`
    width: calc(100% - 8px);
    min-width: 202px;
    padding-left: 8px;
    user-select: none;
    padding-top: 40px;
    max-height: 100%;
    overflow: auto;
`;

export const MenuContent = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

export const Menu = styled.div.attrs((props) => ({
    // moved wave background here from root to prevent image reload on every styled-component change
    // this doesn't happen when the image is set via style attribute
    // https://github.com/styled-components/styled-components/issues/1593
    style: {
        backgroundImage: `url(${props.theme.F_wave})`,
        backgroundPosition: "left bottom",
        backgroundSize: "2000px 445px",
        backgroundRepeat: "no-repeat"
    }
}))<{
    isFloating: boolean;
    _width: number;
}>`
    flex-grow: 1;
    height: 100%;

    width: ${props => props._width ? `${props._width}px` : "auto"};
    transition: width 300ms ease-in-out;

    display: flex;
    flex-direction: column;

    position: ${props => props.isFloating ? "absolute" : "relative"};

    padding-left: 20px;
    box-sizing: border-box;

    background-color: ${props => props.theme.C_BG_menu};
    overflow: visible;
`;

export const Underline = css<{
    selected?: boolean
}>`
    &::after {
        content: "";
        bottom: -2px;
        height: 2px;
        margin-left: auto;
        margin-right: auto;
        position: absolute;

        border-radius: 1px;
        background-color: ${props => props.theme.C_ACT_thick_line};

        transition: 0.2s ease-out all .01s;

        left: ${props => props.selected ? "0" : "50%"};
        width: ${props => props.selected ? "100%" : "1%"};
        opacity: ${props => props.selected ? 1 : 0};
    }

    &:hover::after, &:focus-visible::after {
        opacity: 1;
        background-color: ${props => props.theme.C_ACT_thick_line_hover};
        width: 100%;
        left: 0;
    }
`;

export const NavListGroupTitle = styled.a`
    display: block;
    margin-bottom: 20px;
    position: relative;
    width: fit-content;
    cursor: pointer;
    outline: none;

    ${getFocusBorderElement({ offset: 3, keyboardOnly: true })};
    
    ${T_TITLE_4} ${Underline}
`;