import React from "react";
import { TableWrapper } from "../../../views/table/TableView.styles";
import View from "../../../views/View";
import TableWithAutoSizedColumns from "../../../components/table/TableWithAutoSizedColumns";
import ViewHeader from "../../../components/smart/smartHeader/SmartHeader";
import { withTranslation, WithTranslation } from "react-i18next";
import { IColumn, IRow, IRowValues, ISort, TCellValue } from "@components/table";
import { Sort, TextAlign } from "../../../enums";
import { BreadCrumbProvider } from "../../../components/breadCrumb";
import { ROUTE_FISCAL_YEAR_CLOSE, ROUTE_HOME } from "../../../routes";
import { AppContext } from "../../../contexts/appContext/AppContext.types";
import { EntityTypeName, ICompanyEntity } from "@odata/GeneratedEntityTypes";
import memoizeOne from "../../../utils/memoizeOne";
import { getFYcloseOverviewData, IFyCloseOverviewData, TFyCloseOverviewData } from "../Home.utils";
import { ColoredText } from "../../../global.style";
import { DefaultTheme } from "styled-components/macro";
import i18next from "i18next";
import { getEnumNameSpaceName } from "@odata/GeneratedEnums.utils";
import DateType, { getUtcDayjs } from "../../../types/Date";
import { DASH_CHARACTER } from "../../../constants";
import { RouteComponentProps } from "react-router-dom";
import { getTableDrillDownLink } from "@components/drillDown/DrillDown.utils";
import { getSortedRows, ILinkedPagesState } from "./linkedPagesUtils";
import { IToString, TValue } from "../../../global.types";

interface IProps extends WithTranslation, RouteComponentProps {
}

interface IState extends ILinkedPagesState {
    loading: boolean;
    data?: TFyCloseOverviewData;
}

function formatAccountingType(value: string): string {
    return i18next.t(`${getEnumNameSpaceName(EntityTypeName.Accounting)}:${value}`).toString();
}

class FiscalYearCloseOverview extends React.Component<IProps, IState> {
    static contextType = AppContext;

    _breadcrumbsAreSet = false;

    state: IState = {
        loading: true,
        sort: [{ id: "Agenda", sort: Sort.Asc }],
    };

    componentDidMount() {
        this.load();
        this.setBreadcrumbs();
    }

    componentDidUpdate() {
        this.setBreadcrumbs();
    }

    setBreadcrumbs() {
        if (!this.props.tReady || this._breadcrumbsAreSet) {
            return;
        }
        this._breadcrumbsAreSet = true;

        this.context.setViewBreadcrumbs({
            items: [{
                key: "Home",
                title: this.props.t("Home:Title"),
                link: ROUTE_HOME
            }, {
                key: "FiscalYearCloseOverview",
                title: this.props.t("Home:FiscalYearCloses.Heading"),
            }],
            lockable: false
        });
    }

    getColumnsMemoized = memoizeOne((): IColumn[] => {
        const { t } = this.props;

        const columns = [
            "Agenda",
            "State",
            "AccountingType",
            "LastFY",
            "Year",
            "FromTo"
        ];
        return columns.map(name => ({
            id: name,
            label: t(`Home:FiscalYearCloses.${name}`),
            textAlign: TextAlign.Left
        }));
    });


    load = async (): Promise<void> => {
        const data = await getFYcloseOverviewData();
        const allCompanies = this.context.getData().companies as ICompanyEntity[];
        const companies = allCompanies.filter(c => data.has(c.Id));

        this.setState({ loading: false, companies, data });
    };

    handleSort = (sort: ISort[]): void => {
        this.setState({ sort });
    };

    formatState = (done: number, count: number): TCellValue => {
        let t = "Started";
        let color: keyof DefaultTheme = "C_SEM_text_warning";
        if (done === count) {
            t = "Finished";
            color = "C_SEM_text_good";
        } else if (done === 0) {
            t = "NotStarted";
            color = "C_SEM_text_bad";
        }

        const text = i18next.t(`Home:FiscalYearCloses.${t}`, { done, count });
        return {
            value: <ColoredText color={color}>{text}</ColoredText>,
            tooltip: text
        };
    };

    getFYCloseDrilldown = (dateStart: Date, dateEnd: Date, fiscalYearNumber: string, companyId: number): TCellValue => {
        const text = `${DateType.format(dateStart)} ${DASH_CHARACTER} ${DateType.format(dateEnd)}`;
        return getTableDrillDownLink(text, {
            route: `${ROUTE_FISCAL_YEAR_CLOSE}/${fiscalYearNumber}`,
            context: this.context,
            companyId
        });
    };

    getValueForSort = (company: ICompanyEntity, key: IToString): TValue => {
        const values = this.state.data?.get(company.Id);

        switch (key) {
            case "State":
                return values?.SectionCount ? values.SectionDone / values.SectionCount : 0;
            case "AccountingType":
                return formatAccountingType(values.AccountingCode);
            case "LastFY":
                return values.FiscalYearNumber;
            case "Year":
                return getUtcDayjs(values.DateStart).get("year");
            case "FromTo":
                return values.DateStart;
            default:
                return values[key.toString() as keyof IFyCloseOverviewData];
        }
    }

    getFormattedValues(companyId: number) {
        const values = this.state.data.get(companyId);

        if (!values) {
            // should not happen, just for safety
            return {};
        }

        const formatted: IRowValues = {};

        for (const col of this.getColumnsMemoized()) {
            const key = col.id;
            switch (key) {
                case "Agenda":
                    formatted[key] = values.Company;
                    break;
                case "State":
                    formatted[key] = this.formatState(values.SectionDone, values.SectionCount);
                    break;
                case "AccountingType":
                    formatted[key] = formatAccountingType(values.AccountingCode);
                    break;
                case "LastFY":
                    formatted[key] = values.FiscalYearNumber;
                    break;
                case "Year":
                    const startYear = getUtcDayjs(values.DateStart).get("year");
                    const endYear = getUtcDayjs(values.DateEnd).get("year");
                    formatted[key] = startYear !== endYear ? `${startYear}/${endYear}` : startYear;
                    break;
                case "FromTo":
                    formatted[key] = this.getFYCloseDrilldown(values.DateStart, values.DateEnd, values.FiscalYearNumber, values.CompanyId);
                    break;
            }
        }

        return formatted;
    }

    getSortedRows(): IRow[] {
        const { data, sort, companies } = this.state;

        if (!data || !companies) {
            return [];
        }

        return getSortedRows({
            companyColumnId: "Agenda",
            companies,
            sort,
            getValueForSort: this.getValueForSort.bind(this),
            getFormattedValues: this.getFormattedValues.bind(this)
        });
    }

    render() {
        if (!this.props.tReady) {
            return null;
        }

        return (
                <>
                    <BreadCrumbProvider back={ROUTE_HOME}/>
                    <View hotspotContextId={"fiscalYearCloseOverview"}>
                        <ViewHeader title={this.props.t("Home:FiscalYearCloses.Heading")}
                                    shouldHideVariant/>
                        <TableWrapper>
                            <TableWithAutoSizedColumns busy={this.state.loading}
                                                       rows={this.getSortedRows()}
                                                       onSortChange={this.handleSort}
                                                       sort={this.state.sort}
                                                       columns={this.getColumnsMemoized()}/>
                        </TableWrapper>
                    </View>
                </>
        );
    }
}

export default withTranslation(["Home", getEnumNameSpaceName(EntityTypeName.Accounting)])(FiscalYearCloseOverview);