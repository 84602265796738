import { WithBusyIndicator, withBusyIndicator } from "@components/busyIndicator/withBusyIndicator";
import { IGroupSubItemProps, ISubItemProps } from "@components/configurationList/Item";
import { getInfoValue, IFieldDef, isRequiredForCustomization } from "@components/smart/FieldInfo";
import { IFormGroupDef, IFormLineItemsDef } from "@components/smart/smartFormGroup/SmartFormGroup";
import { IFieldInfo } from "@odata/FieldInfo.utils";
import { forEachKey, isDefined, uuidv4 } from "@utils/general";
import { isEqual } from "lodash";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import ConfigurationList, {
    COPY_SIGN,
    IConfigList,
    IGroupListGroupDef,
    IGroupListItemDef,
    isCopy
} from "../../components/configurationList/ConfigurationList";
import Dialog from "../../components/dialog/Dialog";
import Switch from "../../components/inputs/switch";
import Tabs, { ITabsProps } from "../../components/tabs/Tabs";
import { DASH_CHARACTER } from "../../constants";
import { ConfigListItemBoundType, FastEntryInputSizes, FieldType, GroupedField } from "../../enums";
import { TRecordAny, TRecordType } from "../../global.types";
import BindingContext, { createPath } from "../../odata/BindingContext";
import ConfirmationButtons from "../table/ConfirmationButtons";
import { FormStorage } from "./FormStorage";

interface IProps extends WithTranslation, WithBusyIndicator {
    storage: FormStorage;
    title: string;
}

interface IState {
    data?: IConfigList;
    itemsData?: IConfigList[];
    selectedTabId: string;
    showItemsSummary?: boolean;
}

interface IConvertDataToList {
    allowNewLine?: boolean;
    isFirstColumnMultiGroup?: boolean;
    groups: IFormGroupDef[];
    processedGroups?: IFormGroupDef[];
    processCollection?: boolean;
}

const USED_FIELDS_COLUMN_ID = "used";
const AVAILABLE_FIELDS_COLUMN_ID = "available";
const AVAILABLE_FIELDS_GROUP_ID = "_availableFieldsGroup_";
const NEW_LINE_ID = "_newline_";
const COLLAPSED_ID = "_collapsed_";
const COMBINED_ID = "_combined_";
const MAIN_TAB_ID = "##MAIN_TAB_ID##";

class FormCustomizationDialog extends React.Component<IProps, IState> {
    state: IState = {
        selectedTabId: MAIN_TAB_ID,
        showItemsSummary: false
    };

    tabGroup: IFormGroupDef[] = [];

    async componentDidMount() {
        const withoutItems = [], itemGroups = [];

        for (const group of this.props.storage.getVariant() || []) {
            if (group.tabs || group.table) {
                this.tabGroup.push(group);
            } else if (this.isSpecialGroup(group)) {
                itemGroups.push(group);
                // push also items group to all groups, so we can keep order
                withoutItems.push(group);
            } else {
                withoutItems.push(group);
            }
        }

        const itemsDataPromises = itemGroups.filter(group => {
            return !((!group?.lineItems && !group?.collection) || group.customizationData?.useForCustomization === false);
        }).map(group => this.convertItemsDataToList(group));
        
        const [data, ...itemsData] = await Promise.all([this.convertDataToList(withoutItems, itemGroups), ...itemsDataPromises]);
        this.setState({
            data,
            itemsData,
            showItemsSummary: this.getInitialItemsSummarySwitchValue()
        });
    }

    isSpecialGroup = (group: IFormGroupDef) => {
        return !!group.lineItems || !!group.collection;
    };

    isCustomGroup = (group: IFormGroupDef) => {
        const definedGroups = this.props.storage.data.definition.groups;

        if (!definedGroups) {
            return false;
        }

        return !definedGroups.find(origGroup => origGroup.id === group.id);
    };

    /** If first group in definition is without title,
     * it is supposed to look 'transparent' in the config dialog */
    isGroupFirstDefaultNonGroup = (group: IFormGroupDef) => {
        const definedGroups = this.props.storage.data.definition.groups;

        if (!definedGroups) {
            return false;
        }

        return definedGroups[0]?.id === group.id && !definedGroups[0].title;
    };

    convertDataFromList = (data: IConfigList) => {
        const { storage } = this.props;
        const { definition } = storage.data;

        const groups = [];
        for (const groupId of data.columns.used.groupIds) {
            const defGroup = definition.groups.find(group => group.id === groupId);
            const groupList = data.groups[groupId];
            const items = groupList.itemIds;
            let baseBc = storage.data.bindingContext;

            const group: IFormGroupDef = {
                ...(defGroup ?? {}),
                id: groupList.id
            };

            if (groupList.label && groupList.isLabelEditable) {
                group.title = groupList.label;
            }

            if (defGroup?.lineItems) {
                // just initialization, skip type check for mandatory props
                group.lineItems = {} as IFormLineItemsDef;
                baseBc = baseBc.navigate(defGroup.lineItems.collection);
            }
            if (defGroup?.collection) {
                baseBc = baseBc.navigate(defGroup.collection);
            }

            const _isBlockField = (info: IFieldInfo) => {
                // block fields are automatically wrapped/on separate row (new line is added before and after)
                return info.type === FieldType.EditableText || info.type === FieldType.TextArea;
            };

            const _addRow = () => {
                if (row.length > 0) {
                    currentRows.push(row);
                }
                row = [];
            };

            let rows: IFieldDef[][] = [];
            let row: IFieldDef[] = [];
            const currentRows = rows;

            if (defGroup?.customizationData?.isLocked) {
                rows = defGroup.rows;
                // in case collection group would be locked
                row = defGroup.lineItems?.columns;
            } else {
                let skipNewLine = false;

                const _addField = (id: string) => {
                    const info = storage.getInfo(baseBc.navigate(id));
                    const isBlockElement = _isBlockField(info);

                    if (isBlockElement && row.length > 0) {
                        // add new line before block element if it's not first item on row
                        _addRow();
                    }

                    row.push({ id });

                    if (info?.customizationData?.dependents) {
                        for (const dependentId of info?.customizationData?.dependents) {
                            row.push({ id: dependentId });
                        }
                    }
                    if (info?.collapsedRows || isBlockElement) {
                        // automatically add new rows after field with collapsible group
                        _addRow();
                        skipNewLine = true;
                    }
                };

                for (const id of items) {
                    if (id.startsWith(COMBINED_ID)) {
                        const item = data.items[id];
                        for (const combinedItem of item.combinedItems) {
                            _addField(combinedItem.id);
                        }

                        continue;
                    }

                    if (id.startsWith(NEW_LINE_ID)) {
                        if (!skipNewLine) {
                            _addRow();
                        }
                        skipNewLine = false;
                        continue;
                    }
                    skipNewLine = false;

                    _addField(id);
                }

                if (row.length > 0) {
                    currentRows.push(row);
                }
            }

            if (group.lineItems) {
                group.lineItems.columns = row;
            } else {
                group.rows = rows;
            }
            groups.push(group);

            // add dependent groups from original definitions
            defGroup?.customizationData?.dependents?.forEach(depGroupId => {
                const origGroup = definition.groups.find(group => group.id === depGroupId);
                groups.push(origGroup);
            });
        }

        return groups;
    };

    convertItemsDataToList = async (group: IFormGroupDef) => {
        const { rows, ...groupDef } = group;
        const updatedGroup: IFormGroupDef = groupDef;

        // if "lineItems" group includes rows, they are converted in standard way, skip it here
        // but keep it for "collection"
        if (group?.collection) {
            updatedGroup.rows = rows;
        }

        const isCollection = !!group.collection;
        return this._convertDataToList({
            groups: [updatedGroup],
            allowNewLine: isCollection,
            isFirstColumnMultiGroup: false,
            processCollection: true
        });
    };

    createAvailableItemsGroup = () => {
        return this.createGroup(AVAILABLE_FIELDS_GROUP_ID, null, {
            isTransparent: true,
            isGrowing: true,
            shouldSort: true
        });
    };

    createGroup = (id: string, label?: string, additionalProps?: TRecordAny) => {
        return {
            id,
            label,
            itemIds: [] as string[],
            ...additionalProps
        };
    };

    getItemRightIcon = (info: IFieldInfo): string => {
        return null;
        // switch (info.type) {
        //     case FieldType.CheckboxGroup:
        //         return "CheckboxGroup";
        //     case FieldType.Checkbox:
        //         return "Checkbox";
        //     case FieldType.Switch:
        //         return "Switch";
        //     case FieldType.TextArea:
        //     case FieldType.EditableText:
        //         return null;
        //     case FieldType.RadioButtonGroup:
        //         return "RadioButtonGroup";
        //     case FieldType.LabelSelect:
        //         return "Label";
        //     default:
        //         return null;
        // }
    };

    _convertDataToList = async (args: IConvertDataToList): Promise<IConfigList> => {
        let newLineIndex = 0;
        const { storage } = this.props;
        let name: string;
        let id: string;
        const rootBc = storage.data.bindingContext;
        let groupBc = storage.data.bindingContext;
        const listGroup: TRecordType<IGroupListGroupDef> = {};
        const listItems: TRecordType<IGroupListItemDef> = {};
        const listGroupIds: string[] = [];
        const usedFields: Record<string, boolean> = {};
        const availableFieldsGroup = this.createAvailableItemsGroup();

        const usedSubItems: TRecordType<boolean> = {};
        const dependentGroups: TRecordType<boolean> = {};
        // we process separately items and other groups. Fields in items groups should not be processed as available fields,
        // so we need to skip all fields starting with one of these group prefixes
        const processedGroups = args.processedGroups || [];

        let currentItem: IGroupListItemDef;
        let isCombiningItem: boolean;

        const _addNewLine = (isCopyOnly: boolean, group: IGroupListGroupDef) => {
            if (args.allowNewLine) {
                const newLineId = `${NEW_LINE_ID}${isCopyOnly ? "" : `${COPY_SIGN}${newLineIndex}`}`;
                const newLineItem = {
                    id: newLineId,
                    value: this.props.t("Components:VersionSelect.NewLine"),
                    icon: "Enter",
                    isCopyOnly,
                    width: FastEntryInputSizes.XS
                };

                newLineIndex++;
                group.itemIds.push(newLineId);
                listItems[newLineId] = newLineItem;
            }
        };

        const _createItem = (id: string, info: IFieldInfo, bc: BindingContext): IGroupListItemDef => {
            const required = isRequiredForCustomization({
                info,
                bindingContext: info.bindingContext,
                storage
            });
            // required fields that have defaultValue can be removed
            const canBeRemoved = !required || isDefined(info.defaultValue);

            const subItems: ISubItemProps[] = [];
            info.customizationData?.dependents?.forEach(id => {
                usedSubItems[id] = true;
                const label = storage.getInfo(storage.data.bindingContext.navigate(id))?.label ?? id;
                subItems.push({
                    id, value: label
                });
            });

            if (info.collapsedRows) {
                info.collapsedRows?.flat().forEach(field => {
                    usedSubItems[field.id] = true;
                    const label = field.label ?? storage.getInfo(storage.data.bindingContext.navigate(field.id))?.label ?? field.id;
                    subItems.push({
                        id: field.id, value: label
                    });
                });
            }

            return {
                id,
                value: info?.label,
                isRequired: !canBeRemoved,
                boundTo: !canBeRemoved ? ConfigListItemBoundType.Column : null,
                subItems,
                rightIcon: this.getItemRightIcon(info),
                description: info.description ? `(${info.description})` : ""
            };
        };

        const _processField = (id: string, group: IGroupListGroupDef, info?: IFieldInfo) => {
            const bc = info?.bindingContext ?? groupBc.navigate(id);
            info = info ? info : storage.getInfo(bc);
            const { prefix } = group;

            // flag for ignoring fields -- for available group it is easy, just skip
            // we can't just remove it from definition tho, as it has it purpose to be there
            // set special flag for list to hide it but not remove it from data
            const shouldIgnore = getInfoValue(info?.customizationData, "useForCustomization", {
                storage,
                context: storage.context
            }) === false;

            if (shouldIgnore) {
                return false;
            }

            if (info?.groupedField === GroupedField.MultiStart || info?.groupedField === GroupedField.WrappedMultiStart || info?.groupedField === GroupedField.NoWrapStart) {
                // "bound" items (with sharp edges) --- create one FAKE parent and put them all inside combineItems
                // keep flag we are adding combined items
                // IMPORTANT ---- they have to be in a row in the fieldDefinition !!!!!!
                isCombiningItem = true;
                usedFields[createPath(prefix, id)] = true;
                currentItem = {
                    id: `${COMBINED_ID}-${id}`,
                    value: info?.label,
                    combinedItems: [_createItem(id, info, bc)]
                };
            } else if (isCombiningItem) {
                // item bound to another item (with sharp edges) in info via property groupedField
                currentItem.combinedItems.push(_createItem(id, info, bc));
                usedFields[createPath(prefix, id)] = true;

                if (info?.groupedField === GroupedField.MultiEnd || info?.groupedField === GroupedField.WrappedMultiEnd || info?.groupedField === GroupedField.NoWrapEnd) {
                    isCombiningItem = false;
                    currentItem.isRequired = currentItem.combinedItems.some(item => item.isRequired);
                    currentItem.boundTo = currentItem.isRequired ? ConfigListItemBoundType.Column : null;
                }

                return false;
            } else {
                currentItem = _createItem(id, info, bc);
            }

            usedFields[createPath(prefix, currentItem.id)] = true;
            group.itemIds.push(currentItem.id);
            listItems[currentItem.id] = currentItem;

            return false;
        };

        const _processRows = (rows: IFieldDef[][], group: IGroupListGroupDef) => {
            let skipAddNew = false;
            for (const [i, row] of (rows || []).entries()) {
                if (i > 0 && !skipAddNew) {
                    _addNewLine(false, group);
                }

                for (const field of row || []) {
                    skipAddNew = _processField(field.id, group);
                }
            }
        };

        const _processColumns = (columns: IFieldDef[], group: IGroupListGroupDef) => {
            for (const field of columns || []) {
                _processField(field.id, group);
            }
        };

        const _getGroupSubItems = (grpDef: IFormGroupDef, prefix: string, rowsOnly = false): IGroupSubItemProps[] => {
            const subItems: IGroupSubItemProps[] = [];

            grpDef.rows?.flat().forEach(item => {
                usedSubItems[createPath(prefix, item.id)] = true;
                const bc = item.isCollectionField === false ? rootBc.navigate(item.id) : groupBc.navigate(item.id);
                const info = storage.getInfo(bc);
                subItems.push({ id: item.id, value: info?.label });
            });

            // process also line items and store them in usedSubItems, so they are not displayed in the available items
            //  it's necessary for localContext fields in line items as they cannot be tested by bc.isAnyPartOfCollection,
            //  e.g. UsersForm->COMPANY_ROLE_LINES_PATH
            grpDef.lineItems?.columns.forEach(column => {
                usedSubItems[createPath(prefix, column.id)] = true;
            });

            if (rowsOnly) {
                return subItems;
            }
            return [{
                id: grpDef.id,
                value: getInfoValue(grpDef, "title", { storage }),
                subItems
            }];
        };

        const _getGroupPrefix = (group: IFormGroupDef) => group.lineItems?.collection ?? group.collection;

        for (const group of args.groups || []) {
            groupBc = storage.data.bindingContext;
            const prefix = _getGroupPrefix(group);
            if (prefix) {
                groupBc = groupBc.navigate(prefix);
            }

            const useForCustomization = getInfoValue(group?.customizationData, "useForCustomization", {
                storage,
                context: storage.context
            });
            const { isLocked } = group.customizationData ?? {};
            const isCollection = !!(group.collection || group.lineItems?.collection);
            const isGroupLockedForCustomization = isLocked || (isCollection && !args.processCollection);
            const subItems: IGroupSubItemProps[] = [];

            if (dependentGroups[group.id] || isGroupLockedForCustomization) {
                // add fields of dependent groups to usedSubItems, so they are not added to different group. They are
                // bound to the dependent group and cannot be customized
                subItems.push(..._getGroupSubItems(group, prefix, true));
            }

            if (useForCustomization === false || dependentGroups[group.id]) {
                continue;
            }

            group.customizationData?.dependents?.forEach((id) => {
                dependentGroups[id] = true;
                const depGroupDef = args.groups.find(grp => grp.id === id);
                subItems.push(..._getGroupSubItems(depGroupDef, prefix));
            });

            const groupTitle = getInfoValue(group, "title", { storage });
            if (args.processCollection && !id) {
                id = group.id;
                name = groupTitle;
            }
            const newGroup = this.createGroup(group.id, groupTitle, {
                isDraggable: args.isFirstColumnMultiGroup,
                isTransparent: this.isGroupFirstDefaultNonGroup(group) || !args.isFirstColumnMultiGroup,
                isLabelEditable: typeof group.title !== "function" && !isGroupLockedForCustomization,
                isRemovable: this.isCustomGroup(group),
                isDropDisabled: isGroupLockedForCustomization,
                prefix,
                // we need to process subitems to remove them from available items, but we don't want to display
                // them in the toolip most likely as they are editable in collection tab
                subItems: isCollection ? [] : subItems,
                isLocked: isGroupLockedForCustomization
            });

            if (isGroupLockedForCustomization) {
                // create subItems to be displayed in tooltip of the locked group
            } else {
                if (group.rows) {
                    _processRows(group.rows, newGroup);
                } else if (group.lineItems?.columns) {
                    _processColumns(group.lineItems.columns, newGroup);
                }
            }

            listGroup[group.id] = newGroup;
            listGroupIds.push(group.id);

            if (group.lineItems?.collection) {
                usedFields[group.lineItems.collection] = true;
            }
        }

        forEachKey(listGroup, (key) => {
            // remove used subfields for each group
            const grp = listGroup[key];
            grp.itemIds = grp.itemIds.filter(id => !usedSubItems[createPath(grp.prefix, id)]);
        });

        // rest available fields
        _addNewLine(true, availableFieldsGroup);

        const availableEntities = this.props.storage.data.definition.fieldDefinition;

        const groupPath = groupBc.removeKey().getPath(true);
        const processedGroupPrefixes = args.processedGroups?.map(group => _getGroupPrefix(group))?.filter(collection => !!collection) ?? [];

        for (const [key, item] of Object.entries(availableEntities)) {
            const id = item.id || key;

            if (!usedFields[id] && !usedSubItems[id]) {
                const bc = rootBc.navigate(id);
                const shouldProcessField = args.processCollection ? id.startsWith(groupPath) : !processedGroupPrefixes.some(path => id.startsWith(path));
                if (shouldProcessField) {
                    const path = args.processCollection ? id.substring(groupPath.length + 1) : id;
                    const info = await this.props.storage.loadInfo({
                        path: id,
                        fieldDef: {
                            ...item,
                            id
                        }
                    }, bc);

                    // todo: multi start and multi end fields are not one after another...
                    // either there have to be property that aligns them or there have to be set of right ordered available items
                    _processField(path, availableFieldsGroup, info);
                }
            }
        }

        listGroup[AVAILABLE_FIELDS_GROUP_ID] = availableFieldsGroup;

        return {
            id, name, // collection id
            items: listItems,
            groups: listGroup,
            columns: {
                [USED_FIELDS_COLUMN_ID]: {
                    id: USED_FIELDS_COLUMN_ID,
                    label: this.props.t(`Common:Form.${args.isFirstColumnMultiGroup ? "VisibleFields" : "VisibleItemsFields"}`),
                    shouldShowAddButton: args.isFirstColumnMultiGroup,
                    groupIds: listGroupIds,
                    afterContent: args.processCollection ? this.renderItemsSummarySwitch(this.getInitialItemsSummarySwitchValue(), listGroupIds) : null
                },
                [AVAILABLE_FIELDS_COLUMN_ID]: {
                    id: AVAILABLE_FIELDS_COLUMN_ID,
                    isDropDisabled: true,
                    label: this.props.t(`Common:Form.${args.isFirstColumnMultiGroup ? "AvailableFields" : "AvailableItemsFields"}`),
                    groupIds: [AVAILABLE_FIELDS_GROUP_ID],
                    // there are edge cases which can cause field that is required on backend, to be in the AVAILABLE_FIELDS_GROUP_ID
                    // (e.g. for non Vat payer, VatClassification is not supposed to be shown in the form, even though it is technically required)
                    // => fields in the available column has to always be allowed to be moved
                    cannotBeBoundTo: true
                }
            }
        };
    };

    convertDataToList = async (groups?: IFormGroupDef[], processedGroups?: IFormGroupDef[]): Promise<IConfigList> => {
        return this._convertDataToList({
            groups,
            processedGroups,
            allowNewLine: true,
            isFirstColumnMultiGroup: true
        });
    };

    handleClose = () => {
        this.props.storage.setCustomData({
            isCustomizationDialogOpen: false
        });
        this.props.storage.refresh();
    };

    handleItemsDataChange = (newData: IConfigList) => {
        this.setState((currState) => ({
            itemsData: currState.itemsData.map((config) => config.id === currState.selectedTabId ? newData : config)
        }));
    };

    handleDataChange = (newData: IConfigList) => {
        this.setState({
            data: newData
        });
    };

    handleConfirmDialog = async () => {
        let groups = this.convertDataFromList(this.state.data);
        if (this.state.itemsData?.length) {
            this.state.itemsData.forEach(config => {
                const alreadyContainedGroup = groups.find(grp => grp.id === config.id);
                const data = this.convertDataFromList(config)[0];

                if (alreadyContainedGroup) {
                    if (data.lineItems) {
                        alreadyContainedGroup.lineItems.columns = data.lineItems.columns;
                    } else if (data.rows) {
                        alreadyContainedGroup.rows = data.rows;
                    }
                } else {
                    groups.push(data);
                }
            });
        }

        if (this.tabGroup) {
            groups = groups.concat(this.tabGroup);
        }

        if (this.shouldShowDisplayItemsSummarySwitch()) {
            const lineItemsGroup = groups.find(group => this.isSpecialGroup(group));

            lineItemsGroup.lineItems.shouldShowItemsSummary = this.state.showItemsSummary;
        }

        const currentVariant = this.props.storage.getVariant();
        const isVariantSame = isEqual(groups, currentVariant);

        if (!isVariantSame) {
            this.props.storage.setLocalStorageVariant(groups);

            this.props.setBusy(true);
            await this.props.storage.reload({
                preserveInfos: false,
                preserveData: false,
                withoutBusy: true
            });
            this.props.setBusy(false);
        }

        this.handleClose();
    };

    handleAddGroup = () => {
        this.setState(state => {
            const data = state.data;
            const id = uuidv4();
            const newGroup = this.createGroup(id, this.props.t("Components:VersionSelect.NewGroup"), {
                isLabelEditable: true,
                isDraggable: true,
                isRemovable: true
            });

            const groups = { ...data.groups, [id]: newGroup };
            const items = { ...data.items };

            return {
                data: {
                    ...data,
                    groups,
                    items,
                    columns: {
                        ...data.columns,
                        [USED_FIELDS_COLUMN_ID]: {
                            ...data.columns[USED_FIELDS_COLUMN_ID],
                            groupIds: data.columns[USED_FIELDS_COLUMN_ID].groupIds.concat(id)
                        }
                    }
                }
            };
        });
    };

    handleRemoveGroup = (groupId: string) => {
        const oldGroup = this.state.data.groups[groupId];
        const newGroups: TRecordType<IGroupListGroupDef> = {
            ...this.state.data.groups,
            [AVAILABLE_FIELDS_GROUP_ID]: {
                ...this.state.data.groups[AVAILABLE_FIELDS_GROUP_ID],
                itemIds: [
                    ...this.state.data.groups[AVAILABLE_FIELDS_GROUP_ID].itemIds,
                    ...oldGroup.itemIds.filter(itemId => !isCopy(itemId) && !itemId.startsWith(COLLAPSED_ID))
                ]
            }
        };

        delete newGroups[groupId];

        const newData: IConfigList = {
            ...this.state.data,
            columns: {
                ...this.state.data.columns
            },
            groups: newGroups
        };

        for (const columnId of Object.keys(newData.columns)) {
            newData.columns[columnId].groupIds = newData.columns[columnId].groupIds.filter(group => group !== groupId);
        }

        this.setState({
            data: newData
        });
    };

    renderItemsList = (selectedTabId: string) => {
        const data = this.state.itemsData.find(config => config.id === selectedTabId);
        return <ConfigurationList data={data} onDataChange={this.handleItemsDataChange}/>;
    };

    renderMainList = () => {
        return <ConfigurationList onGroupAdd={this.handleAddGroup}
                                  onGroupRemove={this.handleRemoveGroup}
                                  data={this.state.data}
                                  onDataChange={this.handleDataChange}/>;
    };

    getInitialItemsSummarySwitchValue = () => {
        const currentVariant = this.props.storage.getVariant();
        const lineItemGroup = currentVariant.find(group => this.isSpecialGroup(group));

        return !!lineItemGroup && !!lineItemGroup.lineItems?.shouldShowItemsSummary;
    };

    handleItemsSummarySwitchChange = (checked: boolean) => {
        const getUpdatedConfig = (config: IConfigList) => {
            return {
                ...config,
                columns: {
                    ...config.columns,
                    [USED_FIELDS_COLUMN_ID]: {
                        ...config.columns[USED_FIELDS_COLUMN_ID],
                        afterContent: this.renderItemsSummarySwitch(checked, config.columns[USED_FIELDS_COLUMN_ID].groupIds)
                    }
                }
            };
        };

        this.setState({
            showItemsSummary: checked,
            // rerender the switch
            data: getUpdatedConfig(this.state.data),
            itemsData: this.state.itemsData.map(config => getUpdatedConfig(config))
        });
    };

    renderItemsSummarySwitch = (checked: boolean, groupIds: string[]): React.ReactElement => {
        if (!this.shouldShowDisplayItemsSummarySwitch(groupIds)) {
            return null;
        }

        return (
                <Switch label={this.props.t("Common:Form.ItemsSummary")}
                        checked={checked}
                        onChange={this.handleItemsSummarySwitchChange}
                />
        );
    };

    getTabData = () => {
        const tabs = [{
            id: MAIN_TAB_ID, // something which don't collide with any collection group id
            title: this.props.t("Components:VersionSelect.Header"),
            content: (props: ITabsProps) => {
                return this.renderMainList();
            }
        }];

        this.state.itemsData?.forEach(config => {
            tabs.push({
                id: config.id,
                title: config.name ?? this.props.t("Components:VersionSelect.Items"),
                content: (props: ITabsProps) => {
                    return this.renderItemsList(props.selectedTabId);
                }
            });
        });

        return tabs;
    };

    handleTabChange = (selectedTab: string) => {
        this.setState({
            selectedTabId: selectedTab
        });
    };

    shouldDisplayItemsTab = () => {
        const lineItemGroup = this.props.storage.data.definition.groups.find(group => this.isSpecialGroup(group));
        return !!lineItemGroup;
    };

    shouldShowDisplayItemsSummarySwitch = (renderedGroups?: string[]) => {
        const lineItemGroup = this.props.storage.data.definition.groups.find(group => (!renderedGroups || renderedGroups.includes(group.id)) && this.isSpecialGroup(group));

        return !!lineItemGroup && !!lineItemGroup.lineItems?.itemsSummaryRenderer;
    };

    render() {
        if (!this.state.data) {
            return null;
        }

        const shouldDisplayItemsTab = this.shouldDisplayItemsTab();
        return (
                <Dialog
                        title={`${this.props.title} ${DASH_CHARACTER} ${this.props.t("Common:Form.Customization")}`}
                        height={"9999px"}
                        onClose={this.handleClose}
                        onConfirm={this.handleConfirmDialog}
                        footer={<ConfirmationButtons onCancel={this.handleClose} onConfirm={this.handleConfirmDialog}
                                                     useWrapper={false}/>}>
                    {shouldDisplayItemsTab && <Tabs data={this.getTabData()}
                                                    selectedTabId={this.state.selectedTabId}
                                                    onChange={this.handleTabChange}
                                                    height={"100%"}/>}
                    {this.props.busyIndicator}
                    {!shouldDisplayItemsTab && this.renderMainList()}
                </Dialog>
        );
    }
}

export default withBusyIndicator({ passBusyIndicator: true })(withTranslation(["Common"])(FormCustomizationDialog));