import SmartCollapsedBase, { ISmartCollapsedBaseProps } from "./SmartCollapsedBase";
import { CreatingTitle, ExpandIconBackground, ExpandIconParent, ExpandIconWrapper } from "../../formGroup";
import { IconButton } from "../../button";
import { MoreOptionsIcon } from "../../icon";
import React from "react";

interface IProps extends ISmartCollapsedBaseProps {
    isCollapsedGroupDisabled?: boolean;
}

class SmartCollapseToggle extends SmartCollapsedBase<IProps> {

    render() {
        const { isCollapsedGroupDisabled, def } = this.props;
        const status = this.getStatus();

        return (
                <ExpandIconParent>
                    <ExpandIconWrapper>
                        <ExpandIconBackground
                                _isCreating={status.isCreating}
                                _isDisabled={isCollapsedGroupDisabled}
                                onClick={this.handleToggle}
                                expanded={status?.isExpanded}>
                            <IconButton title={this.props.storage.t("Common:General.Expand")}
                                        hotspotId={"formGroupExpand"}
                                        isLight
                                        isTransparent>
                                <MoreOptionsIcon isLight={!status.isCreating}/>
                            </IconButton>
                        </ExpandIconBackground>
                    </ExpandIconWrapper>
                    {status.isCreating &&
                            <CreatingTitle>{def.creatingTitle}</CreatingTitle>
                    }
                </ExpandIconParent>
        );
    }
}

export default SmartCollapseToggle;