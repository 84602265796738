import React, { ReactElement } from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import { IconSize } from "../../enums";
import { AuditTrailLineComparison, IAuditTrailData } from "../../model/Model";
import TestIds from "../../testIds";
import { MoreIcon } from "../icon";
import {
    AuditTrailLayer,
    Body,
    ExpandIconBackgroundFormGroupAction,
    FormGroupActionsStyled,
    IconButtonStyledOptionsButton,
    StyledFormGroup
} from "./FormGroup.styles";
import { IFormGroupAction } from "./FormGroupActions";
import { GroupHeader } from "./GroupHeader";

export interface IProps extends WithTranslation {
    id: string;
    title: string;
    isLightText?: boolean;
    isWithoutMargin?: boolean;
    tooltip?: React.ReactElement | string;
    headerContent?: React.ReactElement;
    isSimple?: boolean;
    showLine?: boolean;
    auditTrailData?: IAuditTrailData;
    isSmallBottomMargin?: boolean;
    options?: IFormGroupAction[];
    optionsDisabled?: boolean;
    selectedOption?: string;
    isOptionsOpened?: boolean;
    onOptionsButtonToggle?: () => void;
    onOptionClick?: (optionId: string) => void;
    isFlex?: boolean;
    style?: React.CSSProperties;
    className?: string;
}

class FormGroup extends React.PureComponent<IProps> {
    get optionsOpened(): boolean {
        return !this.props.optionsDisabled && this.props.isOptionsOpened;
    }

    shouldRenderAuditTrailLayer = (): boolean => {
        const type = this.props.auditTrailData?.lineData?.type;
        return type === AuditTrailLineComparison.MissingRow || type === AuditTrailLineComparison.AdditionalRow;
    };

    handleOptionsButtonClick = (): void => {
        this.props.onOptionsButtonToggle?.();
    };

    renderOptionsButton = (): ReactElement => {
        if (this.props.isSimple || !this.props.options?.length) {
            return null;
        }

        return (
            <ExpandIconBackgroundFormGroupAction
                expanded={this.optionsOpened}
                style={{ marginLeft: "6px", height: "38px" }}>
                <IconButtonStyledOptionsButton
                    hotspotId={"formGroupOptions"}
                    title={this.props.t(`Components:FormGroup.${this.optionsOpened ? "Close" : "Open"}Options`)}
                    onClick={this.handleOptionsButtonClick}
                    isLight={this.optionsOpened}
                    isTransparent
                    isDisabled={!!this.props.selectedOption || this.props.optionsDisabled}>
                    <MoreIcon ignoreTheme={this.optionsOpened} width={IconSize.M} height={IconSize.M}/>
                </IconButtonStyledOptionsButton>
            </ExpandIconBackgroundFormGroupAction>
        );
    };

    renderOptions = (): ReactElement => {
        return (
            <FormGroupActionsStyled
                    selectedAction={this.props.selectedOption}
                // we want to use only one background gradient (from group Body)
                // otherwise, thar would be ugly visual bump
                    isTransparent={!!this.props.selectedOption}
                    ignoreTheme={true}
                    onActionClick={this.props.onOptionClick}
                    actions={this.props.options}/>
        );
    };

    render(): ReactElement {
        return (
            <StyledFormGroup
                data-groupid={this.props.id}
                auditTrailType={this.props.auditTrailData?.lineData?.type}
                data-testid={TestIds.FormGroup}
                isSmallBottomMargin={this.props.isSmallBottomMargin}
                isFlex={this.props.isFlex}
                style={this.props.style}
                className={this.props.className}>
                {this.shouldRenderAuditTrailLayer() &&
                    <AuditTrailLayer data-testid={TestIds.FormGroupAuditTrailLayer}/>
                }
                <GroupHeader title={this.props.title}
                             isLightText={this.props.isLightText}
                             showLine={this.props.showLine}
                             tooltip={this.props.tooltip}>
                    {this.props.options && this.renderOptionsButton()}
                    {this.props.headerContent}
                </GroupHeader>
                <Body
                        isWithoutMargin={this.optionsOpened || this.props.isWithoutMargin}
                    isDarkBackground={!!this.props.selectedOption}
                    isFlex={this.props.isFlex}
                    data-testid={TestIds.FormGroupBody}>
                    {this.optionsOpened && this.renderOptions()}
                    {this.props.children}
                </Body>
            </StyledFormGroup>
        );
    }
}

export default withTranslation(["Common", "Components"])(FormGroup);