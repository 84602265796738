import {
    EntitySetName,
    IPrEmploymentExtraSalaryEntity,
    IPrEmploymentTemplateExtraSalaryEntity,
    IPrExtraSalaryEntity,
    IPrExtraSalaryLegislativeValueEntity,
    PrExtraSalaryEntity,
    PrExtraSalaryLegislativeValueEntity,
    PrExtraSalaryTemporalCurrentEntity,
    PrExtraSalaryTemporalEntity
} from "@odata/GeneratedEntityTypes";
import { BatchRequest, isBatchResultOk } from "@odata/OData";
import {
    PrBaseTypeCode,
    PrEntityValueSourceCode,
    PrExtraSalaryComputationTypeCode
} from "@odata/GeneratedEnums";
import { createNewTemporalLineItem, TTemporal } from "@odata/TemporalUtils";
import { FormStorage } from "../../../views/formView/FormStorage";
import { ODataQueryResult } from "@odata/ODataParser";
import { IGetValueArgs, TTemporalDialogSettings } from "@components/smart/FieldInfo";
import CurrencyType, { formatCurrency } from "../../../types/Currency";
import { TValue } from "../../../global.types";
import { IFormatOptions } from "@odata/OData.utils";
import NumberType from "../../../types/Number";
import { getCompanyCurrency } from "@utils/CompanyUtils";

export async function createLegislativeExtraSalaries(storage: FormStorage, settings: TTemporalDialogSettings): Promise<(IPrEmploymentTemplateExtraSalaryEntity | IPrEmploymentExtraSalaryEntity)[]> {
    const batch: BatchRequest = storage.oData.batch();
    batch.beginAtomicityGroup("group1");

    // legislative values
    batch.getEntitySetWrapper(EntitySetName.PrExtraSalaryLegislativeValues).query()
            .select(
                    PrExtraSalaryLegislativeValueEntity.ExtraSalaryTypeCode,
                    PrExtraSalaryLegislativeValueEntity.DateValidFrom,
                    PrExtraSalaryLegislativeValueEntity.DateValidTo,
            );
    // preparedExtraSalaries, which are bound to legislative values
    batch.getEntitySetWrapper(EntitySetName.PrExtraSalaries)
            .query()
            .select(PrExtraSalaryEntity.Id, PrExtraSalaryEntity.TypeCode)
            .expand(PrExtraSalaryEntity.CurrentTemporalPropertyBag, (q) => {
                q.expand(PrExtraSalaryTemporalCurrentEntity.ComputationType);
            })
            .filter(`${PrExtraSalaryEntity.BaseTypeCode} eq '${PrBaseTypeCode.Legislative}'`);

    const results = await batch.execute();

    const templateExtraSalaries: IPrEmploymentTemplateExtraSalaryEntity[] = [];

    if (results.every(res => isBatchResultOk(res))) {
        const legislativeValues = (results[0].body as ODataQueryResult).value as IPrExtraSalaryLegislativeValueEntity[];
        const extraSalaries = (results[1].body as ODataQueryResult).value as IPrExtraSalaryEntity[];

        legislativeValues?.forEach((val, idx) => {
            // Each company can have custom setting of legislative values, configured through extraSalaty entity with
            const matchingSalary = extraSalaries
                    .find(salary => salary.TypeCode === val.ExtraSalaryTypeCode);

            const templateExtraSalaryDefault: IPrEmploymentTemplateExtraSalaryEntity = {
                Default: {
                    Id: matchingSalary.Id,
                    BaseTypeCode: PrBaseTypeCode.Legislative
                },
                // copy current property bag, so there are visible current values on the form
                CurrentTemporalPropertyBag: {
                    ...matchingSalary.CurrentTemporalPropertyBag,
                    NameSourceCode: PrEntityValueSourceCode.Default,
                    ComputationTypeSourceCode: PrEntityValueSourceCode.Default,
                    ValueSourceCode: PrEntityValueSourceCode.Default,
                },
            };
            const defaultRange: TTemporal = {
                DateValidFrom: val.DateValidFrom,
                DateValidTo: val.DateValidTo
            };
            const templateExtraSalary = createNewTemporalLineItem(settings, templateExtraSalaryDefault, defaultRange);

            templateExtraSalaries.push(templateExtraSalary);
        });
    }

    return templateExtraSalaries;
}

export const getExtraSalaryValueUnit = (args: IGetValueArgs): string => {
    const parentBc = args.bindingContext.getParent();
    const typeBc = parentBc.navigate(PrExtraSalaryTemporalEntity.ComputationType);
    const type = args.storage.getValue(typeBc);
    return type?.Code === PrExtraSalaryComputationTypeCode.AverageHourlySalaryPercentage ? "%" : CurrencyType.getCurrencyUnit(getCompanyCurrency(args.context));
};

export const extraSalaryValueFormatter = (val: TValue, args: IFormatOptions): string => {
    if (args.entity?.CurrentTemporalPropertyBag?.ComputationTypeCode === PrExtraSalaryComputationTypeCode.AverageHourlySalaryPercentage) {
        return `${NumberType.format(val as number, { maximumFractionDigits: 0 })} %`;
    }
    return formatCurrency(val, getCompanyCurrency(args.context));
};
