import { IFieldDefFn, IGetValueArgs } from "@components/smart/FieldInfo";
import {
    FilterBarGroup,
    getDefaultFilterGroupDef,
    IFilterGroupDef
} from "@components/smart/smartFilterBar/SmartFilterBar.types";
import { ISummaryItem } from "@components/smart/smartSummaryItem/SmartSummaryItem";
import {
    CompanyPermissionEntity,
    CompanyRoleEntity,
    CompanyRolePermissionEntity,
    EntitySetName,
    EntityTypeName
} from "@odata/GeneratedEntityTypes";
import { CompanyRoleTypeCode } from "@odata/GeneratedEnums";
import { getEnumSelectItems } from "@odata/GeneratedEnums.utils";
import i18next from "i18next";
import React from "react";

import { BasicInputSizes, FieldType, LabelStatus, Sort } from "../../../enums";
import { TValue } from "../../../global.types";
import { Model } from "../../../model/Model";
import BindingContext from "../../../odata/BindingContext";
import { IFormDef } from "../../../views/formView/Form";
import { FormStorage } from "../../../views/formView/FormStorage";
import FormView from "../../../views/formView/FormView";
import { ISplitPageTableDef } from "../../../views/table/TableView.utils";
import { setDefByEntityType } from "../../getDefByEntityType";
import { getItemBreadCrumbsText, IDefinition, IGetDefinition } from "../../PageUtils";
import AssignedCompanyUsersTable from "./AssignedCompanyUsersTable";
import CompanyPermissionsTable from "./CompanyPermissionsTable";

const isSystemRole = (args: IGetValueArgs) => {
    return args.storage?.data.entity?.Id < 0;
};

export const getDefinitions: IGetDefinition = (): IDefinition => {
    const filterBarDef: IFilterGroupDef[] = [{
        ...getDefaultFilterGroupDef(FilterBarGroup.Filters),
        defaultFilters: [
            CompanyRoleEntity.CompanyRoleName,
            CompanyRoleEntity.UserCount
        ],
        filterDefinition: {
            CompanyRoleName: {},
            UserCount: {}
        },
        isValueHelp: true,
        allowCustomFilters: false
    }];

    const table: ISplitPageTableDef = {
        filterBarDef,
        id: `${EntityTypeName.CompanyRole}Table`,
        columns: [
            CompanyRoleEntity.CompanyRoleName,
            CompanyRoleEntity.UserCount
        ],
        columnDefinition: {
            CompanyRoleName: {
                formatter: (val: TValue) => {
                    return {
                        value: <b>{val}</b>,
                        tooltip: val as string
                    };
                }
            },
            UserCount: {}
        },
        title: i18next.t("CompanyRoles:Title"),
        initialSortBy: [{ id: CompanyRoleEntity.CompanyRoleName, sort: Sort.Asc }]
    };

    const summary: ISummaryItem[] = [{
        id: CompanyRoleEntity.CompanyRoleName
    }];

    const form: IFormDef = {
        id: `${EntityTypeName.CompanyRole}Form`,
        getItemBreadCrumbText: (storage: Model) =>
                getItemBreadCrumbsText(storage, i18next.t("CompanyRoles:NewRole"), storage.data.entity?.CompanyRoleName),
        summary,
        title: i18next.t("CompanyRoles:FormTitle"),
        translationFiles: getDefinitions.translationFiles,
        formControl: FormView,
        additionalProperties: [],
        isReadOnly: isSystemRole,
        isDeletable: true,
        fieldDefinition: {
            [CompanyRoleEntity.CompanyRoleName]: {
                width: BasicInputSizes.L
            },
            [CompanyRoleEntity.Type]: {
                type: FieldType.SegmentedButton,
                labelStatus: LabelStatus.Removed,
                defaultValue: CompanyRoleTypeCode.UserRole,
                fieldSettings: {
                    displayName: "Name",
                    items: getEnumSelectItems(EntityTypeName.CompanyRoleType)
                },
                isVisible: (args) => false, // todo: args.storage.data.bindingContext.isNew(),
                clearIfInvisible: false,
                customizationData: {
                    useForCustomization: false // or according to todo above
                }
            },
            [BindingContext.localContext("AssignedUsers")]: {
                type: FieldType.Custom,
                render: (args: IFieldDefFn) => {
                    return (
                        <AssignedCompanyUsersTable storage={args.storage as FormStorage}/>
                    );
                }
            },
            [BindingContext.localContext("Permissions")]: {
                type: FieldType.Custom,
                additionalProperties: [
                    {
                        id: CompanyRoleEntity.CompanyRolePermissions,
                        additionalProperties: [{
                            id: CompanyRolePermissionEntity.CompanyPermission,
                            additionalProperties: [{ id: CompanyPermissionEntity.Name }, { id: CompanyPermissionEntity.Code }, { id: CompanyPermissionEntity.Description }]
                        }, {
                            id: CompanyRolePermissionEntity.IsEnabled
                        }, {
                            id: CompanyRolePermissionEntity.OwnOnly
                        }]
                    }],
                render: (args: IFieldDefFn) => {
                    return <CompanyPermissionsTable storage={args.storage as FormStorage}
                                                    roleId={args.storage.data.entity.Id}/>;
                }
            },
        },
        groups: [{
            id: "general",
            rows: [[{ id: CompanyRoleEntity.CompanyRoleName }]]
        }, {
            id: "users",
            title: i18next.t("CompanyRoles:AssignedUsers"),
            rows: [[{ id: BindingContext.localContext("AssignedUsers") }]],
            customizationData: {
                isLocked: true
            }
        }, {
            id: "permissions",
            title: i18next.t("CompanyRoles:Permissions"),
            rows: [[{ id: BindingContext.localContext("Permissions") }]],
            customizationData: {
                isLocked: true
            }
        }]
    };

    return {
        entitySet: EntitySetName.CompanyRoles,
        table,
        form
    };
};

getDefinitions.translationFiles = ["CompanyRoles"];
setDefByEntityType(EntityTypeName.CompanyRole, getDefinitions);