import Tooltip from "@components/tooltip";
import { isDefined } from "@utils/general";
import React, { PropsWithChildren, ReactElement } from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import { ToolbarItemType } from "../../enums";
import { ColoredText } from "../../global.style";
import TestIds from "../../testIds";
import { Theme } from "../../theme";
import { Image } from "../avatar";
import CustomResizeObserver from "../customResizeObserver/CustomResizeObserver";
import { getIcon } from "../icon";
import { SvgImage } from "../svgImage/SvgImage";
import { IToolbarItem } from "../toolbar";
import { VerticalToolbar } from "../toolbar/VerticalToolbar";
import {
    ActiveBar,
    ActiveTitle,
    ColumnContent,
    ColumnTitle,
    ColumnWrapper,
    ContentColumn,
    ContentWrapper,
    ControlsWrapper,
    CustomImageWrapper,
    DefaultTitle,
    IconWrapper,
    ItemContentRowType,
    ListItemButton,
    ListItemContentLabel,
    ListItemContentRowStyled,
    ListItemContentValue,
    ListItemDelimiter,
    ListItemDescription,
    ListItemMoreRowLabel,
    ListItemStatus,
    ListItemValueNewRow,
    ListItemValueNewRowWrapper,
    StyledListItem,
    Title,
    TitleColumn,
    TitleWrapper,
    ToolbarWrapper
} from "./ListItem.styles";

export interface IColumnContent {
    title?: string;
    content: any;
    highlighted?: boolean;
}

export interface IListItem {
    id: string;
    key?: string;
    name?: string;
    /** Only one action is expected to be 'main' action - ToolbarItemType.Button */
    actions?: IToolbarItem[];
    /** Icon shown as the main image on the left. Use either iconName or customImageSrc.*/
    iconName?: string;
    iconTitle?: string;
    /** URL/other img tag acceptable src of custom image. Use either iconName or customImageSrc.*/
    customImageSrc?: string;
    contents: IColumnContent[];
    className?: string;
    isDefault?: boolean;
    status?: ListItemStatus;
    isCustomImageSvg?: boolean;
    appliedText?: string;
}

interface IProps extends IListItem {
    onTriggerAction?: (itemId: string, action: string) => void;
}

interface IState {
    isSmall?: boolean;
}

class ListItem extends React.Component<IProps & WithTranslation, IState> {
    _refStyledItem = React.createRef<HTMLDivElement>();
    _refContent = React.createRef<HTMLDivElement>();
    _refButtonWrap = React.createRef<HTMLDivElement>();

    state: IState = {
        isSmall: false
    };

    componentDidMount(): void {
        this.update();
    }

    get mainIconAction() {
        return this.getToolbarActions()?.[0];
    }

    handleResize = () => {
        this.update();
    };

    handleAction = (action: string) => {
        this.props.onTriggerAction?.(this.props.id, action);
    };

    getMainActions = () => {
        return this.props.actions?.filter(item => item.itemType === ToolbarItemType.Button || item.itemType === ToolbarItemType.TransparentButton);
    };

    getToolbarActions = () => {
        return this.props.actions?.filter(item => item.itemType === ToolbarItemType.Icon) || [];
    };

    update = () => {
        let isSmall = false;
        if (this._refStyledItem.current && this._refContent.current && this._refButtonWrap.current) {
            isSmall = this._refStyledItem.current.offsetWidth - this._refContent.current.offsetWidth < this._refButtonWrap.current.offsetWidth;
        }
        // mark layout small vs. big
        this.setState({
            isSmall
        });
    };

    shouldIconBeClickable = (): boolean => {
        return this.getToolbarActions().length > 0;
    };

    handleIconClick = () => {
        const toolbarActions = this.getToolbarActions();
        if (toolbarActions?.length) {
            this.handleAction(toolbarActions[0]?.id);
        }
    };

    getImage = (): ReactElement => {
        const props = {
            src: this.props.customImageSrc,
            alt: this.props.t("Common:General.Logo"),
            title: this.props.name
        };
        return this.props.isCustomImageSvg ? <SvgImage {...props} /> : <Image {...props} />;
    };

    render() {
        const Icon = this.props.iconName && getIcon(this.props.iconName);

        const toolbarIcons = this.getToolbarActions(),
            mainButtons = this.getMainActions();
        const isIconClickable = this.shouldIconBeClickable();
        const appliedText = this.props.appliedText ?? this.props.t("Components:ObjectList.Applied");

        return (
            <StyledListItem
                ref={this._refStyledItem}
                _small={this.state.isSmall}
                data-testid={TestIds.ObjectListItem}
                data-listitemid={this.props.id}
                className={this.props.className}>

                <CustomResizeObserver onResize={this.handleResize}/>
                <ContentWrapper ref={this._refContent} _small={this.state.isSmall}>
                    {isDefined(this.props.status) && <ActiveBar status={this.props.status}/>}
                    <TitleColumn data-testid={TestIds.TitleColumn}>
                        {this.props.iconName &&
                            <IconWrapper onClick={isIconClickable ? this.handleIconClick : null}
                                         isClickable={isIconClickable}>
                                <Icon title={this.props.iconTitle} preventHover/>
                            </IconWrapper>
                        }
                        {this.props.customImageSrc &&
                            <CustomImageWrapper onClick={this.handleIconClick}>
                                {this.getImage()}
                            </CustomImageWrapper>
                        }
                        {this.props.isDefault &&
                            <DefaultTitle
                                data-testid={TestIds.PrimarySubTitle}>/{this.props.t("Components:ObjectList.Default")}/</DefaultTitle>}
                        {isDefined(this.props.status) &&
                            <ActiveTitle
                                data-testid={TestIds.AppliedSubTitle}
                                status={this.props.status}>/{this.props.status === ListItemStatus.Active ? appliedText :
                                this.props.t("Components:ObjectList.Working")}/</ActiveTitle>}

                        <ToolbarWrapper data-testid={TestIds.Toolbar}>
                            <VerticalToolbar onItemChange={this.handleAction} items={toolbarIcons}
                                             visibleItemsCount={4}/>
                        </ToolbarWrapper>
                    </TitleColumn>
                    <ColumnContent>
                        <Tooltip
                            content={this.props.name}
                            onlyShowWhenChildrenOverflowing
                        >
                            {ref => {
                                return <TitleWrapper ref={ref}>
                                    <Title data-testid={TestIds.Title}>{this.props.name}</Title>
                                </TitleWrapper>;
                            }}
                        </Tooltip>
                        <ColumnWrapper _small={this.state.isSmall}>

                            {this.props.contents.map((col: IColumnContent, idx) =>
                                <ContentColumn data-testid={TestIds.ContentColumn} key={idx}
                                               _isLast={idx === this.props.contents.length - 1}
                                               _small={this.state.isSmall}>
                                    {col.title && <ColumnTitle>{col.title}</ColumnTitle>}
                                    <div>{col.content}</div>
                                </ContentColumn>
                            )}
                        </ColumnWrapper>
                    </ColumnContent>
                </ContentWrapper>
                <ControlsWrapper data-testid={TestIds.ButtonGroup} _small={this.state.isSmall}>
                    {mainButtons &&
                        <div ref={this._refButtonWrap}>
                            {mainButtons.map(btn => (<ListItemButton key={btn.id}
                                                                     hotspotId={btn.id}
                                                                     isSmall={this.state.isSmall}
                                                                     isTransparent={btn.itemType === ToolbarItemType.TransparentButton}
                                                                     isDisabled={btn.isDisabled}
                                                                     onClick={() => this.handleAction(btn.id)}>{btn.label}</ListItemButton>))}
                        </div>}
                </ControlsWrapper>
            </StyledListItem>
        );
    }
}

interface ListItemContentRowProps {
    label?: string;
    hasSpaceAfter?: boolean;
    hasEllipsis?: boolean;
    type?: ItemContentRowType;
    description?: string;
    delimiter?: string;
    text?: string;
    color?: keyof Theme;
}

export const ListItemContentRow: React.FunctionComponent<ListItemContentRowProps> = (args: PropsWithChildren<ListItemContentRowProps>) => {
    const type = args.type ?? ItemContentRowType.OneRow;
    const isOneRow = type === ItemContentRowType.OneRow;

    const _renderText = () => {
        const text = args.color ?
            <ColoredText color={args.color}>{args.text ?? args.children}</ColoredText> : args.text ?? args.children;
        return (
            <>
                {text}
                {args.description &&
                    <ListItemDescription type={type}>
                        <ListItemDelimiter>{args.delimiter ?? '/'}</ListItemDelimiter>
                        {args.description}
                    </ListItemDescription>}

            </>
        );

    };

    return (
        <ListItemContentRowStyled hasSpaceAfter={args.hasSpaceAfter} data-testid={TestIds.ListItemRow}>
            {isOneRow &&
                <>
                    {args.label &&
                        <ListItemContentLabel
                            data-testid={TestIds.ListItemRowLabel}>{args.label}:</ListItemContentLabel>}
                    <ListItemContentValue
                        data-testid={TestIds.ListItemRowValue}>{_renderText()}</ListItemContentValue>
                </>
            }
            {!isOneRow &&
                <>
                    {args.label &&
                        <ListItemMoreRowLabel
                            data-testid={TestIds.ListItemRowLabel}>{args.label}:</ListItemMoreRowLabel>}

                    <ListItemValueNewRowWrapper data-testid={TestIds.ListItemRowValue}>
                        <ListItemValueNewRow isLarge={args.type === ItemContentRowType.Large}>
                            {_renderText()}
                        </ListItemValueNewRow>
                    </ListItemValueNewRowWrapper>
                </>
            }
        </ListItemContentRowStyled>
    );
};

export default withTranslation(["Components"])(ListItem);
