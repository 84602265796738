import styled, { css } from "styled-components/macro";
import { transparentize } from "polished";
import { ORDER_BACKGROUND_RIGHT_MARGIN, ORDER_BACKGROUND_WIDTH } from "../sortableList/SortableList.styles";

export const StyledFastEntryList = styled.div<{
    hasPrimary?: boolean;
    isCollapsible: boolean;
    onlyPrimary: boolean;
    useVirtualization: boolean;
}>`
    margin-top: ${props => props.hasPrimary ? "40px" : ""};
    position: relative;
    ${props => !props.useVirtualization && css`width: fit-content;`}
`;

export const LabelsOverflowWrapper = styled.div`
    position: relative;
    overflow: hidden;
    height: 19px;
`;

export const LabelsWrapper = styled.div<{
    _lpad?: number;
}>`
    display: flex;
    position: relative;
    left: ${props => props._lpad ? `${props._lpad}px` : null};
`;

export const PrimaryContent = styled.div<{ showLineNumbers: boolean }>`
    width: calc(100% - 12px);
    height: 80px;
    top: -8px;
    left: ${props => props.showLineNumbers ? `${ORDER_BACKGROUND_WIDTH + ORDER_BACKGROUND_RIGHT_MARGIN}px` : "0"};
    position: absolute;
    border-radius: 3px;

    pointer-events: none;
    background: ${props => transparentize(0.95, props.theme.C_ACT_main)};
`;

export const PrimaryContentTitle = styled.span`
    position: absolute;
    top: -15px;
    color: ${props => props.theme.C_ACT_main};
    font-size: 12px;
    text-transform: uppercase;
`;