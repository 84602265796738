import { isBooleanType, isDateType, isNumericType, isStringType } from "./Metadata.utils";
import { Metadata } from "./Metadata";

function getTypeFromEdmType(edmType: string) {
    switch (true) {
        case (isNumericType(edmType)):
            return "number";
        case (isDateType(edmType)):
            return "Date";
        case (isBooleanType(edmType)):
            return "boolean";
        case (isStringType(edmType)):
            return "string";
        default:
            // navigation property to another entity type
            return null;

    }
}

function getEntityInterfaceName(entityName: string) {
    return `I${entityName}Entity`;
}

function getEntityBaseInterface() {
    return `export interface IEvalaMetadataRule {
    ErrorCode: string;
    Message: string;
    LinkedEntities: {
        DisplayName: string;
        Id: number;
        TypeName: string;
    }[];
    // entity related
    // cannot be deleted - applicable for collection item, e.g. Items(4)
    CannotBeDeleted?: boolean;
    
    // collections related
    // cannot add item in collection - applicable for whole collection, e.g. Items
    CannotAdd?: boolean;
    // cannot remove item from collection - applicable for whole collection, e.g. Items, Attachments
    CannotDelete?: boolean;
}

export interface ODataEntityMetadata {
    count?: number;
    context?: string;
    type?: string;
    etag?: string;
    metadata?: {
        DisabledPropertyRules?: Record<string, IEvalaMetadataRule[]>
        EnabledPropertyRules?: Record<string, IEvalaMetadataRule[]>
    };
}

export interface IEntityBase {
    _metadata?: Record<string, ODataEntityMetadata>;
    "#id"?: number | string;
}

`;
}

function genEntityInterfacesAndEnums(metadata: Metadata): string {
    let interfaces = "";
    let entityTypeEnum = "export enum EntityTypeName {\n";

    interfaces += getEntityBaseInterface();

    for (const fullName of Object.keys(metadata.entities).sort()) {
        const entity = metadata.entities[fullName];
        const entityName = entity.getName();
        let intrfc = `export interface ${getEntityInterfaceName(entityName)} extends IEntityBase {\n`;
        let propEnum = `export enum ${entityName}Entity {\n`;

        const appliedEntityName = fullName.startsWith("Solitea.Evala.DomainModel") ? entityName : `${fullName.split(".")[2]}${entityName}`;
        entityTypeEnum += `    ${appliedEntityName} = "${entityName}",\n`;

        for (const property of Object.values(entity.getProperties())) {
            const type = property.getType();
            const name = property.getName();
            const typeName = type.getName();

            intrfc += `    ${name}?: `;
            propEnum += `    ${name} = "${name}",\n`;

            if (!property.isNavigation()) {
                intrfc += getTypeFromEdmType(typeName);
            } else {
                intrfc += getEntityInterfaceName(typeName);

                if (type.isCollection()) {
                    intrfc += "[]";
                }
            }

            intrfc += ";\n";
        }

        intrfc += "}\n\n";
        propEnum += "}\n\n";

        interfaces += intrfc;
        interfaces += propEnum;
    }

    entityTypeEnum += "}\n\n";
    // generate EntityType enum for all entities
    interfaces += entityTypeEnum;

    return interfaces;
}

function genEntitySetEnum(metadata: Metadata): string {
    let entitySetEnum = "export enum EntitySetName {\n";

    for (const entitySet of Object.keys(metadata.entitySets)) {
        entitySetEnum += `    ${entitySet} = "${entitySet}",\n`;
    }

    entitySetEnum += "}\n\n";

    return entitySetEnum;
}

function genOdataActionNameEnum(metadata: Metadata): string {
    // enum that is used to connect interfaces with odata parameters
    let actionsEnum = "export enum OdataActionName {\n";
    // const that is used to connect the OdataActionName with the actual backend path of the call
    // one enum is not enough,
    // because the backend can have multiple actions with the same name on different entities
    let actionsEnumPathMapping = "export const ODataActionPath = {\n";

    for (const actionExplicitName of Object.keys(metadata.actions)) {
        actionsEnum += `    ${actionExplicitName} = "${actionExplicitName}",\n`;
        actionsEnumPathMapping += `    [OdataActionName.${actionExplicitName}]: "${metadata.actions[actionExplicitName].getName()}",\n`;
    }

    actionsEnum += "}\n\n";
    actionsEnumPathMapping += "};\n\n";

    return actionsEnum + actionsEnumPathMapping;
}

function genActionInterfaces(metadata: Metadata): string {
    let actionsInterfaces = "";
    let actionsParameters = "export interface IOdataActionParameters {\n";

    for (const actionExplicitName of Object.keys(metadata.actions)) {
        const action = metadata.actions[actionExplicitName];
        const hasNoParameters = action.getParameters().length === 0;

        if (hasNoParameters) {
            actionsParameters += `    [OdataActionName.${actionExplicitName}]: null;\n`;
            continue;
        }

        const interfaceName = `I${actionExplicitName}Parameters`;

        actionsInterfaces += `export interface ${interfaceName} {\n`;
        actionsParameters += `    [OdataActionName.${actionExplicitName}]: ${interfaceName};\n`;

        for (const param of action.getParameters()) {
            const edmType = param.type.getName();
            let type = getTypeFromEdmType(edmType);

            if (!type) {
                type = getEntityInterfaceName(edmType);
            } else if (type === "Date") {
                // force caller to use correct formatter like formatDateToDateString for date
                type = "string";
            }

            actionsInterfaces += `    ${param.name}${param.optional ? "?" : ""}: ${type}`;

            if (param.type.isCollection()) {
                actionsInterfaces += "[]";
            }

            actionsInterfaces += ";\n";
        }

        actionsInterfaces += "}\n\n";
    }

    actionsParameters += "}";

    return actionsInterfaces + actionsParameters;
}

/**
 * Generates typescript interfaces for all entities in given Metadata
 *
 * @param metadata
 *
 * @returns {string} typescript interfaces in string form
 */
export function generateInterfaces(metadata: Metadata) {
    let output = genEntityInterfacesAndEnums(metadata);

    output += genEntitySetEnum(metadata);
    output += genOdataActionNameEnum(metadata);
    output += genActionInterfaces(metadata);

    return output;
}
