import React from "react";
import { ColoredIconStyled, Description, Header, SearchResultStyled, SubTitle, Title } from "./Search.styles";
import Tooltip from "../tooltip";
import { ArrowIcon } from "../icon";
import { IconSize } from "../../enums";


interface IProps {
    title: React.ReactNode;
    subtitle: React.ReactNode;
    description: React.ReactNode;
    url?: string;
    onClick?: () => void;
}

const SearchResult: React.FC<IProps> = (props) => {
    const { title, subtitle, description, url, onClick } = props;

    return (
        <Tooltip onlyShowWhenChildrenOverflowing
                 content={(<>
                     <div>{title} {subtitle}</div>
                     <div>{description}</div>
                 </>)}>
            {(ref, overflowRef) => (
                <SearchResultStyled ref={ref} to={url} onClick={onClick}>
                    <Header>
                        <Title>{title}</Title>
                        <SubTitle>{subtitle}</SubTitle>
                    </Header>
                    <Description ref={overflowRef}>{description}</Description>
                    <ColoredIconStyled>
                        <ArrowIcon width={IconSize.S} height={IconSize.S}/>
                    </ColoredIconStyled>
                </SearchResultStyled>
            )}
        </Tooltip>
    );
};

export default SearchResult;
