import React from "react";
import { IInputProps, ISharedInputProps } from "../input";
import { FastEntryInputSizes } from "../../../enums";
import TestIds from "../../../testIds";
import { StyledTokenInputWrapper, StyledTokenInput } from "./TokenInput.styles";
import { KeyName } from "../../../keyName";

interface IProps extends IInputProps, ISharedInputProps {
    color: string;
}

interface IState {
    width: FastEntryInputSizes;
}

export default class TokenInput extends React.Component<IProps, IState> {
    private inputRef: React.RefObject<HTMLInputElement> = React.createRef();

    handleClick = (): void => {
        this.inputRef.current?.focus();
    };

    handleOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        this.props?.onChange({
            value: e.target.value,
            origEvent: e
        });
    };

    handleKeyDown = (e: React.KeyboardEvent): void => {
        switch (e.key) {
            case KeyName.Enter:
                this.inputRef.current?.blur();
                break;
        }
    };

    render = () => {
        return (
            <StyledTokenInputWrapper
                data-testid={TestIds.TokenInput}
                onClick={this.handleClick}
                _cursor={this.props.cursor}
                _width={this.props.width}
                auditTrailType={this.props.auditTrailData?.type}
                _height={this.props.height}>
                <StyledTokenInput
                    color={this.props.color}
                    value={this.props.value} data-value={this.props.value}>
                    <input
                        ref={this.inputRef}
                        type={"text"}
                        onChange={this.handleOnChange}
                        onKeyDown={this.handleKeyDown}
                        value={this.props.value}/>
                </StyledTokenInput>
            </StyledTokenInputWrapper>
        );
    };
}