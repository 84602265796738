import { formatDateToTimeString } from "@components/inputs/date/utils";
import { AttachmentsAdditionalProperties, withDisplayName } from "@components/smart/GeneralFieldDefinition";
import {
    FilterBarGroup,
    getDefaultFilterGroupDef,
    IFilterGroupDef
} from "@components/smart/smartFilterBar/SmartFilterBar.types";
import { DocumentDeleteLogEntity, EntitySetName, EntityTypeName } from "@odata/GeneratedEntityTypes";
import { DocumentTypeCode } from "@odata/GeneratedEnums";
import { IFormatOptions } from "@odata/OData.utils";
import i18next from "i18next";

import { Sort, TextAlign } from "../../enums";
import { TValue } from "../../global.types";
import BindingContext from "../../odata/BindingContext";
import { ISplitPageTableDef } from "../../views/table/TableView.utils";
import { setDefByEntityType } from "../getDefByEntityType";
import { IDefinition, IGetDefinition } from "../PageUtils";

export const getDefinitions: IGetDefinition = (): IDefinition => {
    const filterBarDef: IFilterGroupDef[] = [{
        ...getDefaultFilterGroupDef(FilterBarGroup.Filters),
        allowCustomFilters: false,
        createQuery: false,
        isValueHelp: true,
        defaultFilters: [DocumentDeleteLogEntity.NumberOurs, DocumentDeleteLogEntity.DocumentType, DocumentDeleteLogEntity.DateCreated,
            DocumentDeleteLogEntity.InteractionType],
        filterDefinition: {
            [DocumentDeleteLogEntity.NumberOurs]: {},
            [DocumentDeleteLogEntity.DateCreated]: {
                label: i18next.t("Document:DeleteLog.DeleteTime")
            },
            ...withDisplayName(DocumentDeleteLogEntity.DocumentType),
            ...withDisplayName(DocumentDeleteLogEntity.InteractionType),
            ...withDisplayName(DocumentDeleteLogEntity.LastModifiedBy, {
                label: i18next.t("Document:DeleteLog.User")
            })
        }
    }];

    const table: ISplitPageTableDef = {
        id: `${EntityTypeName.DocumentDeleteLog}Table`,
        filterBarDef,
        additionalProperties: [{ id: "DocumentId" }, ...AttachmentsAdditionalProperties],
        columns: [DocumentDeleteLogEntity.DocumentType, DocumentDeleteLogEntity.NumberOurs, DocumentDeleteLogEntity.DateCreated,
            BindingContext.localContext("HoursCreated"),
            DocumentDeleteLogEntity.InteractionType, DocumentDeleteLogEntity.LastModifiedBy],
        columnDefinition: {
            [DocumentDeleteLogEntity.NumberOurs]: {},
            [DocumentDeleteLogEntity.DocumentType]: {
                fieldSettings: {
                    displayName: "Name"
                },
                formatter: (val: TValue, args: IFormatOptions): string => {
                    const type = args.item.DocumentType?.Code;
                    if ([DocumentTypeCode.ProformaInvoiceReceived, DocumentTypeCode.ProformaInvoiceIssued].includes(type)) {
                        return i18next.t(`Common:DocumentTypeName.${type === DocumentTypeCode.ProformaInvoiceReceived ? "Received" : "Issued"}DDOPP`).toString();
                    }

                    return val as string;
                }
            },
            [DocumentDeleteLogEntity.DateCreated]: {
                label: i18next.t("Document:DeleteLog.DeleteTime")
            },
            [BindingContext.localContext("HoursCreated")]: {
                label: i18next.t("Common:General.DeleteTime"),
                textAlign: TextAlign.Right,
                fieldSettings: {
                    disableSort: true
                },
                formatter: (val: TValue, args: IFormatOptions) => {
                    const date = args.item[DocumentDeleteLogEntity.DateCreated];
                    return formatDateToTimeString(date);
                }
            },
            ...withDisplayName(DocumentDeleteLogEntity.LastModifiedBy, {
                label: i18next.t("Document:DeleteLog.User")
            }),
            ...withDisplayName(DocumentDeleteLogEntity.InteractionType)
        },
        title: i18next.t("Document:DeleteLog.Title"),
        initialSortBy: [{ id: DocumentDeleteLogEntity.DateCreated, sort: Sort.Desc }]
    };


    return {
        entitySet: EntitySetName.DocumentDeleteLogs,
        table
    };
};

getDefinitions.translationFiles = ["Common", "Document"];
setDefByEntityType(EntityTypeName.DocumentDeleteLog, getDefinitions);
