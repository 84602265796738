import { getInfoValue, IFieldDef, isVisible } from "@components/smart/FieldInfo";
import { FieldVisibility } from "../../enums";
import { TableStorage } from "../../model/TableStorage";
import { IConfigList, IGroupListItemDef } from "@components/configurationList";
import { TRecordType } from "../../global.types";
import { prepareColumns } from "@components/smart/smartTable/SmartTable.utils";
import { TFieldsDefinition } from "@pages/PageUtils";

export const USED_FIELDS_COLUMN_ID = "used";
export const USED_FIELDS_GROUP_ID = "usedGroup";
export const AVAILABLE_FIELDS_COLUMN_ID = "available";
export const AVAILABLE_FIELDS_GROUP_ID = "_availableFieldsGroup_";

export const isCustomizableColumn = (storage: TableStorage, column: IFieldDef): boolean => {
    const useForCustomization = getInfoValue(column?.customizationData, "useForCustomization", {
        storage: storage,
        context: storage.context
    });
    // hide not visible columns in the customization dialog as well,
    // user should not be able to see and modify invisible columns, but they have to stay in the variant
    const visible = isVisible({
        info: column,
        storage: storage,
        bindingContext: storage.data.bindingContext,
        context: storage.context
    });

    const shouldIgnore = useForCustomization === false || visible === false;

    if (shouldIgnore) {
        return false;
    }

    return column.fieldVisibility !== FieldVisibility.ExportOnly;
};

export const convertDefinitionToConfigList = async (storage: TableStorage): Promise<IConfigList> => {
    const items: TRecordType<IGroupListItemDef> = {};

    const fnPrepareGroupItems = async (columns: IFieldDef[]) => {
        const preparedColumns = await prepareColumns({
            columns: columns,
            bindingContext: storage.data.bindingContext,
            context: storage.context
        });


        const groupItemsIds = [];

        for (const column of preparedColumns) {
            items[column.id] = {
                id: column.id,
                value: column.label,
                description: column.description ? `(${column.description})` : "",
                isRequired: !!column.isRequired,
                isDisabled: !!column.isDisabled
            };
            groupItemsIds.push(column.id);
        }

        return groupItemsIds;
    };

    const currentItems = await fnPrepareGroupItems(storage.data.mergedColumns.filter(column => {
        return isCustomizableColumn(storage, column);
    }));

    // all columns from "columnDefinition" without the already used columns
    const availableColumns: IFieldDef[] = [];

    if (storage.data.definition.columnDefinition) {
        const _add = (def: IFieldDef) => {
            if (currentItems.includes(def.id) || !isCustomizableColumn(storage, def)) {
                return;
            }

            availableColumns.push(def);
        };
        const _process = async (columnDefinition: TFieldsDefinition) => {
            for (const [columnName, columnDef] of Object.entries(columnDefinition)) {

                if (typeof columnDef.factory === "function") {
                    const extractedDefinitions = await columnDef.factory(storage);
                    extractedDefinitions.forEach(def => _add(def));
                    continue;
                }

                _add({
                    id: columnName,
                    ...columnDef
                });
            }
        };

        await _process(storage.data.definition.columnDefinition);
    }

    const availableItems = await fnPrepareGroupItems(availableColumns);

    return {
        items: items,
        groups: {
            [USED_FIELDS_GROUP_ID]: {
                id: USED_FIELDS_GROUP_ID,
                itemIds: currentItems,
                isTransparent: true
            },
            [AVAILABLE_FIELDS_GROUP_ID]: {
                id: AVAILABLE_FIELDS_GROUP_ID,
                itemIds: availableItems,
                isTransparent: true,
                shouldSort: true
            }
        },
        columns: {
            used: {
                id: USED_FIELDS_COLUMN_ID,
                label: storage.t("Common:Form.VisibleColumns"),
                groupIds: [USED_FIELDS_GROUP_ID]
            },
            available: {
                id: AVAILABLE_FIELDS_COLUMN_ID,
                label: storage.t("Common:Form.AvailableColumns"),
                groupIds: [AVAILABLE_FIELDS_GROUP_ID]
            }
        }
    };
};

export const convertConfigListToDefinition = (storage: TableStorage, configList: IConfigList): IFieldDef[] => {
    const items = configList.items;
    const usedColumnsGroup = configList.groups[USED_FIELDS_GROUP_ID];
    const usedColumnsItems = usedColumnsGroup.itemIds.map(itemId => items[itemId]);
    const newColumnsDef: IFieldDef[] = usedColumnsItems.map(item => {
        return {
            id: item.id
        };
    });

    // put back fields from original columns, that weren't used in the customization dialog
    for (let i = 0; i < storage.data.mergedColumns.length; i++) {
        const column = storage.data.mergedColumns[i];

        if (!isCustomizableColumn(storage, column)) {
            newColumnsDef.splice(i, 0, column);
        }
    }

    return newColumnsDef;
};