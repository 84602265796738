import { BatchRequest, getOData, IBatchResult, OData } from "./OData";
import { logger } from "@utils/log";
import { EntityType, IType, Metadata, Property } from "@evala/odata-metadata/src";
import { ODATA_API_URL } from "../constants";
import { EntitySetName, IPropertyTranslationEntity } from "./GeneratedEntityTypes";
import i18next from "i18next";
import { asyncDebounced } from "@utils/general";
import { TRecordString } from "../global.types";

class PropertyTranslationCache {
    _propertyTranslations: Record<string, TRecordString>;
    _pendingEntities: Record<string, Promise<any>>;
    batch: BatchRequest = null;
    execute: any = null;

    constructor() {
        this._propertyTranslations = {};
        this._pendingEntities = {};
    }

    static async getTranslation(property: Property, type: EntityType): Promise<string> {
        const cache = getCache();
        return await cache.getPropertyTranslation(i18next.language, property, type);
    }

    static getCachedTranslation(property: Property): string {
        const cache = getCache();
        return cache.getCachedPropertyTranslation(i18next.language, property);
    }

    createEntityKey(locale: string, type: IType): string {
        return `${locale};${type.getNamespace()};${type.getName()}`;
    }

    createEntityKeyFromResult(entity: IPropertyTranslationEntity): string {
        return `${entity.Language};${entity.Namespace};${entity.EntityType}`;
    }

    prepareNewBatch = (odata: OData): void => {
        this.batch = odata.batch();
        this.batch.beginAtomicityGroup("group1");

        // new async execution has to be prepared for every batch
        // so that correct latest results are always returned
        this.execute = asyncDebounced(async (): Promise<IBatchResult[]> => {
            const batch = this.batch;

            // set batch to null, so that future requests are grouped into new batch
            this.batch = null;

            const res = await batch.execute();

            return res;
        }, 0);
    };

    async fetchEntityTypeTranslation(locale: string, type: IType, forType: EntityType): Promise<TRecordString> {
        const odata = await getOData(ODATA_API_URL);
        const metadata = odata.getMetadata();

        const key = this.createEntityKey(locale, type);
        if (this._pendingEntities[key]) {
            await this._pendingEntities[key];
        }
        if (this._propertyTranslations[key]) {
            return this._propertyTranslations[key];
        }
        const types = this.getAllPropertyTypes(forType, metadata);
        const namespace = types[0].getNamespace(); //So far all entities in inheritance are in the same namespace
        const typeKeys = types.map(t => this.createEntityKey(locale, t));
        let fetchResolve: (value: unknown) => void;
        const fetchPromise = new Promise(function(resolve, reject) {
            fetchResolve = resolve;
        });

        const typesToLoad = [];
        const pendingEntities = [];
        for (let i = 0; i < types.length; i++) {
            if (this._pendingEntities[typeKeys[i]]) {
                pendingEntities.push(this._pendingEntities[typeKeys[i]]);
            } else if (!this._propertyTranslations[typeKeys[i]]) {
                this._pendingEntities[typeKeys[i]] = fetchPromise;
                typesToLoad.push({
                    key: typeKeys[i],
                    type: types[i]
                });
            }
        }

        if (typesToLoad.length > 0) {
            const entityFilter = typesToLoad.map(p => `'${p.type.getName()}'`).join(", ");

            if (!this.batch) {
                this.prepareNewBatch(odata);
            }

            const resultIndex = this.batch.getRequests()?.length ?? 0;

            this.batch.getEntitySetWrapper(EntitySetName.PropertyTranslations).query().filter(`language eq '${locale}' and namespace eq '${namespace}' and entityType in (${entityFilter})`);

            const results = await this.execute();
            const result = results[resultIndex]?.body;

            result.value.forEach((tr: IPropertyTranslationEntity) => {
                const entityKey = this.createEntityKeyFromResult(tr);
                if (!this._propertyTranslations[entityKey]) {
                    this._propertyTranslations[entityKey] = {};
                }
                this._propertyTranslations[entityKey][tr.Property] = tr.Translation;
            });

            for (const t of typesToLoad) {
                if (this._pendingEntities[t.key]) {
                    delete this._pendingEntities[t.key];
                }
            }
        }

        if (fetchResolve) {
            fetchResolve(null);
        }

        if (pendingEntities.length > 0) {
            await Promise.all(pendingEntities);
        }

        return this._propertyTranslations[key];
    }

    getAllPropertyTypes(type: IType, metadata: Metadata): EntityType[] {
        const types = [];
        let currentType = metadata.entities[type.getFullName()];
        while (currentType) {
            types.push(currentType);
            currentType = currentType.getBaseType();
        }
        return types;
    }

    /**
     * Downloads translations for given EntityType and returns translation of given property if it exists.
     * Translations are cached for EntityType.
     * In case property translanslation dosn't exist, its name is returned instead.
     * @param locale
     * @param type
     * @param property
     * @returns {Promise<*>}
     */
    async getPropertyTranslation(locale: string, property: Property, type: EntityType): Promise<string> {
        const entityTranslation = await this.fetchEntityTypeTranslation(locale, property.getEntityType(), type);

        if (!entityTranslation || !entityTranslation[property.getName()]) {
            if (!property.isKey()) {
                logger.warn(`Unable to find a translation for ${property.getEntityType().getName()}.${property.getName()} in PropertyTranslation ${locale}`);
            }

            return property.getName();
        }

        return entityTranslation[property.getName()];
    }

    /** Returns already cached translation without promise. Expects that the translation has been loaded previously */
    getCachedPropertyTranslation(locale: string, property: Property): string {
        const key = this.createEntityKey(locale, property.getEntityType());
        const propertyTranslations = this._propertyTranslations[key];

        if (!propertyTranslations) {
            logger.warn(`tried to access ${key} from translation cache, but it was not found`);

            return null;
        }

        return propertyTranslations[property.getName()];
    }
}

let cache: PropertyTranslationCache = undefined;

function getCache(): PropertyTranslationCache {
    if (!cache) {
        cache = new PropertyTranslationCache();
    }
    return cache;
}

export { getCache, PropertyTranslationCache };