import SelectionTableViewDialog from "@components/smart/SelectionTableViewDialog";
import { ActionType, addNewLineItem, ISmartFastEntriesActionEvent } from "@components/smart/smartFastEntryList";
import BindingContext from "@odata/BindingContext";
import {
    IPrEmployeeEntity,
    IPrGroupDeductionEntity,
    PrGroupDeductionEmployeeEntity,
    PrGroupDeductionEntity
} from "@odata/GeneratedEntityTypes";
import { ADD_EMPLOYEE_ITEM } from "@pages/payroll/deduction/Deduction.utils";
import DeductionFormView from "@pages/payroll/deduction/DeductionFormView";
import PairEmployeeTableView from "@pages/payroll/deduction/groupDeductions/PairEmployeeTableView";
import { getDefinitions as getEmloyeesDef } from "@pages/payroll/employee/Employee.def";
import React, { ReactElement } from "react";

import { withPermissionContext } from "../../../../contexts/permissionContext/withPermissionContext";
import { DATE_MAX } from "../../../../types/Date";
import { IFormStorageDefaultCustomData } from "../../../../views/formView/FormStorage";
import { IFormViewProps } from "../../../../views/formView/FormView";

interface IGroupDeductionCustomData extends IFormStorageDefaultCustomData {
    pairedDialogOpened?: boolean;
}

class GroupDeductionFormView extends DeductionFormView<IPrGroupDeductionEntity, IFormViewProps<IPrGroupDeductionEntity, IGroupDeductionCustomData>> {
    static defaultProps = {
        title: "GroupDeduction:Form.Title"
    };

    handleCustomLineItemAction = async (args: ISmartFastEntriesActionEvent): Promise<void> => {
        if (args.customActionType === ADD_EMPLOYEE_ITEM) {
            this.props.storage.setCustomData({ pairedDialogOpened: true });
            this.props.storage.refresh();
        }
    };

    handleLineItemsAction = async (args: ISmartFastEntriesActionEvent): Promise<void> => {
        if (args.actionType === ActionType.Custom) {
            await this.handleCustomLineItemAction(args);
            return;
        }
        this.props.storage.handleLineItemsAction(args);
        this.props.storage.refresh();
    };

    get readOnlyListTitle(): string {
        return this.props.storage.t("GroupDeduction:Pair.Employees");
    }

    handleEmployeesSelection = (selected: BindingContext[], entities: IPrEmployeeEntity[]): void => {
        const { storage } = this.props;

        entities.forEach((entity) => {
            addNewLineItem(storage, PrGroupDeductionEntity.Employees, PrGroupDeductionEntity.Employees, [], {
                [PrGroupDeductionEmployeeEntity.Employee]: entity,
                [PrGroupDeductionEmployeeEntity.DateValidFrom]: this.entity.DateStart,
                [PrGroupDeductionEmployeeEntity.DateValidTo]: this.entity.DateEnd ?? DATE_MAX
            });
        });
        storage.refresh();
    };

    renderPairingDialog = (): ReactElement => {
        const storage = this.props.storage;

        if (!storage.getCustomData().pairedDialogOpened) {
            return null;
        }
        return <SelectionTableViewDialog
                storage={storage}
                tableView={PairEmployeeTableView}
                getDefinition={getEmloyeesDef}
                title={this.readOnlyListTitle}
                onSelection={this.handleEmployeesSelection}
                onClose={this.handleClosePairing}
                isSingleSelect={false}/>;
    };

    handleClosePairing = (): void => {
        this.props.storage.setCustomData({ pairedDialogOpened: false });
        this.props.storage.refresh();
    };

    render(): JSX.Element {
        return <>
            {super.render()}
            {this.renderPairingDialog()}
        </>;
    }
}

export default withPermissionContext(GroupDeductionFormView);