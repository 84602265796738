import React, { Fragment, KeyboardEvent, MouseEvent, PureComponent, ReactElement } from "react";
import {
    Action,
    ActionsWrapper,
    ActionTitle,
    Circle,
    DayIndex,
    DayWrapper,
    MoreButton,
    SmartSpace,
    WorkTimeSaldo
} from "./Calendar.styles";
import { KeyName } from "../../keyName";
import { IFocusableItemProps } from "../focusManager";
import { IDayAction, TileSize } from "./Calendar.utils";
import TextEllipsis from "../textEllipsis/TextEllipsis";
import { FieldValidationWarningIcon } from "../icon";
import { formatHoursToTimeFormat } from "../inputs/date/utils";
import Tooltip from "../tooltip";

interface IProps {
    dayNumber: number;
    isSelected: boolean;
    isToday: boolean;
    isHoliday: boolean;
    onMouseDown: (e: MouseEvent, dayNumber: number) => void;
    onMouseEnter: (e: MouseEvent, dayNumber: number) => void;
    onMouseUp: (e: MouseEvent) => void;
    onEnter: (e: KeyboardEvent, dayNumber: number) => void;
    focusableProps: IFocusableItemProps;
    actions?: IDayAction[];
    size?: number;
    hoursSaldo?: number;
}

export default class Day extends PureComponent<IProps> {
    get isBig(): boolean {
        return this.props.size >= TileSize.L;
    }

    get isSmall(): boolean {
        return this.props.size < TileSize.M;
    }

    get isMedium(): boolean {
        return this.props.size < TileSize.L && this.props.size >= TileSize.M;
    }

    get countActionsToRender(): number {
        const size = this.props.size;
        if (this.isBig) {
            // action has 5px padding + min 20px body
            const actionsThatFitLength = Math.floor((size - 5) / 25);
            if (this.props.actions?.length > actionsThatFitLength) {
                return Math.floor((size - 26) / 25); // + 16 for MoreButton and 5 it's padding
            }
            return actionsThatFitLength;
        }

        // small variant action has 5px padding + min 10px body
        const actionsThatFitLength = Math.floor((size - 5) / 15);
        if (this.props.actions?.length > actionsThatFitLength) {
            return Math.floor((size - 26) / 15); // + 16 for MoreButton and 5 it's padding
        }
        return actionsThatFitLength;
    }

    handleMouseDown = (e: MouseEvent): void => {
        this.props.onMouseDown?.(e, this.props.dayNumber);
    };

    handleMouseUp = (e: MouseEvent): void => {
        e.preventDefault();
        this.props.onMouseUp?.(e);
    };

    handleMouseEnter = (e: MouseEvent): void => {
        e.preventDefault();
        this.props.onMouseEnter?.(e, this.props.dayNumber);
    };

    handleKeyDown = (e: KeyboardEvent): void => {
        if (e.key === KeyName.Enter) {
            e.preventDefault();
            this.props.onEnter?.(e, this.props.dayNumber);
        }
    };

    renderActionContent = (action: IDayAction, hideTitle: boolean): ReactElement => {
        if (action.hasConflict) {
            return <FieldValidationWarningIcon width={this.isBig ? "100%" : "13px"}
                                               height={this.isBig ? "20px" : "13px"}/>;
        }

        return <>
            <Circle
                    $isHalf={!action.isFullDay}
                    $size={this.props.size}
            />
            {!hideTitle && <ActionTitle>
                <TextEllipsis text={action.title}/>
            </ActionTitle>}
        </>;
    };

    render() {
        const actionsCnt = this.props.actions.length;
        const hasMoreActions = actionsCnt > 1;
        const saldoSign = this.props.hoursSaldo < 0 ? "-" : this.props.hoursSaldo > 0 ? "+" : "";
        const actionsToRender = this.props.actions?.slice(0, this.countActionsToRender);
        const tooltipActions = this.props.actions?.slice(this.countActionsToRender);

        return <DayWrapper
                $size={this.props.size}
                $isSelected={this.props.isSelected}
                $isHoliday={this.props.isHoliday}
                onMouseDown={this.handleMouseDown}
                onMouseEnter={this.handleMouseEnter}
                onMouseUp={this.handleMouseUp}
                onKeyDown={this.handleKeyDown}
                {...this.props.focusableProps}
        >
            <SmartSpace
                    key={"space_1"}
                    $max={this.isBig ? 10 : this.isMedium ? 8 : 6}
                    $basis={this.isBig ? 8 : 6}/>
            <DayIndex
                    $size={this.props.size}
                    $isToday={this.props.isToday}
            >
                {this.props.dayNumber}
            </DayIndex>
            <SmartSpace
                    key={"space_2"}
                    $max={this.isBig ? 7 : this.isMedium ? 5 : TileSize.S}
                    $basis={this.isBig ? 5 : this.isMedium ? 4 : 1}/>
            {!this.isSmall && <>
                <WorkTimeSaldo $isBig={this.isBig}>
                    {saldoSign}{formatHoursToTimeFormat(Math.abs(this.props.hoursSaldo ?? 0))}
                </WorkTimeSaldo>
                <SmartSpace
                        key={"space_3"}
                        $max={this.isBig ? 8 : 10}
                        $basis={this.isBig ? 6 : 1}/>
            </>}
            <ActionsWrapper $isBig={this.isBig}>
                {actionsToRender.map((action, index) => {
                    const hideTitle = !this.isBig || !action.isStart || action.hasConflict || hasMoreActions || action.hideTitle;
                    return <Tooltip key={action.title + index} content={action.title} isHidden={!hideTitle}>
                        {(ref) => <Action
                                key={action.title + index}
                                ref={ref}
                                $color={action.color}
                                $isStart={action.isStart || actionsCnt !== 1}
                                $isEnd={action.isEnd || actionsCnt !== 1}
                                $size={this.props.size}
                                $isBig={this.isBig}
                                $hasConflict={action.hasConflict}
                                $dayHasMoreActions={actionsCnt > 1}
                        >
                            {this.renderActionContent(action, hideTitle)}
                        </Action>
                        }
                    </Tooltip>;
                })}
                {!!tooltipActions?.length && <Tooltip content={<>
                    {tooltipActions.map(action => <Fragment key={action.title}>{`${action.title}\n`}</Fragment>)}
                </>}>
                    {ref => <MoreButton $isSmall={this.isSmall} ref={ref}>
                        {`+${tooltipActions.length}`}
                    </MoreButton>}
                </Tooltip>}
            </ActionsWrapper>
            {this.isBig && <SmartSpace $max={TileSize.L} $basis={2}/>}
        </DayWrapper>;
    }
}