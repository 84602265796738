import styled from "styled-components/macro";
import { themes } from "../../../../theme";
import { StyledDatePickerPopup } from "./Calendar.styles";
import { transparentize } from "polished";

export const StyledDateRangePickerPopup = styled.div`
    display: flex;
    height: fit-content;
    min-width: fit-content;
    background: ${themes.light.C_BG_dialogue};
    overflow: auto;

    & ${StyledDatePickerPopup}:not(:last-child)::after {
        content: "";
        position: absolute;
        right: 0;
        top: 65px;
        height: 245px;
        width: 1px;
        background-color: ${props => transparentize(0.75, props.theme.C_ACT_hover_without_opacity)};
    }
`;