import { FormStorage } from "../formView/FormStorage";
import { BasicInputSizes, FieldType, LabelStatus, TextAlign } from "../../enums";
import { getFileNameOnly } from "@components/fileUploader/File.utils";
import { TFunction } from "i18next";
import { IFieldInfoProperties } from "@components/smart/FieldInfo";

export const APPLY_ISDOC_ACTION = "applyIsDoc";
export const APPLY_ROSSUM_ACTION = "applyRossum";

export const getFiles = (formStorage: FormStorage) => {
    const formDefinition = formStorage?.data?.definition;
    if (!formDefinition?.files || !formStorage.data.bindingContext) {
        return [];
    }

    const filesBc = formStorage.data.bindingContext.navigate(formDefinition.files.collection);

    return formStorage.getValue(filesBc);
};

export const getFileNameWriteLineDef = (t: TFunction): IFieldInfoProperties => ({
    type: FieldType.WriteLine,
    width: BasicInputSizes.L,
    labelStatus: LabelStatus.Removed,
    textAlign: TextAlign.Left,
    isConfirmable: true,
    isRequired: false,
    fieldSettings: {
        isExtending: true,
        formatter: getFileNameOnly,
        placeholder: t("Components:FileUploader.Name")
    }
});