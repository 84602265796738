import styled, { css } from "styled-components/macro";
import { CActDetBGStyles, getDisabledStyle } from "../../global.style";

const centered = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
`;

export const CheckboxFilterStyled = styled.div<{
    isDisabled: boolean;
}>`
    background-color: ${props => props.theme.C_ACT_der};
    ${props => getDisabledStyle(props.isDisabled)};
    ${CActDetBGStyles};

    width: 280px;
    height: 100px;
    display: flex;
    align-items: stretch;
    border-radius: 3px;

    margin-right: 20px;
    margin-bottom: 15px;
`;

export const CheckboxWrapper = styled.div`
    ${centered};
    background-color: rgba(255, 255, 255, 0.1);
    flex: 0 0 30px;
    cursor: pointer;
`;

export const LogoWrapper = styled.div`
    ${centered};
    flex: 0 0 80px;

    rect {
        fill: none;
    }

    path {
        fill: ${props => props.theme.C_BG_menu};
    }
`;

export const ContentWrapper = styled.div`
    padding: 15px 10px 0 5px;
    flex: 1 1 auto;
    width: 0; // shrinks this column if needed
`;