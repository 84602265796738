import styled from "styled-components/macro";
import { T_HEADER_small } from "../../global.style";
import WorkDatePicker from "../../components/workDatePicker";

export const StyledTransactionCurrencyAmountText = styled.span`
    ${T_HEADER_small};
`;

export const PaymentOrderGrid = styled.div`
    display: grid;
    grid-template-columns: auto auto auto auto;
    row-gap: 12px;

    & > div:nth-child(4n + 1) {
        padding-left: 0;
    }
    
    & > div:nth-child(4n + 4) {
        margin-right: 0;
    }
`;

export const StatusIconsWrapper = styled.span`
    display: flex;
    align-items: center;
`;

export const DocumentHeader = styled.div `
    display: flex;
`;

export const WorkDatePickerDocumentStyled = styled(WorkDatePicker)`
    margin-bottom: 18px;
`;

export const InlineIconWrapper = styled.span`
    display: inline-block;
    vertical-align: middle;
    margin-right: 7px;
`;

export const DocumentNameStyled = styled.span`
    vertical-align: middle;
`;