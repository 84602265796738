import React from "react";
import { LeftContentWrapper, RightFormWrapper, Wrapper } from "./DialogTwoColumnContentView.styles";
import { DialogSeparatorLine } from "./Dialog.styles";

interface IProps {
    wrapperContent?: React.ReactElement;
    leftContent: React.ReactElement;
    rightContent: React.ReactElement;
    rightWidth: string;
    passRef?: React.RefObject<HTMLDivElement>;
}

export default class DialogTwoColumnContentView extends React.PureComponent<IProps> {
    render() {
        return (
            <Wrapper ref={this.props.passRef}>
                {this.props.wrapperContent}
                <LeftContentWrapper>
                    {this.props.leftContent}
                </LeftContentWrapper>
                <DialogSeparatorLine/>
                <RightFormWrapper _width={this.props.rightWidth}>
                    {this.props.rightContent}
                </RightFormWrapper>
            </Wrapper>
        );
    }
}