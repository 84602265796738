import React, { Component, ReactElement } from "react";
import { StyledDraftTimeStamp } from "./DraftTimestamp.styles";
import { WithTranslation, withTranslation } from "react-i18next";
import { FormStorage } from "../../views/formView/FormStorage";
import DateType from "../../types/Date";
import { FloppyDiskIcon } from "../icon";
import TestIds from "../../testIds";

interface IProps extends WithTranslation {
    storage: FormStorage;

    ref?: React.RefObject<ReactElement>;
}

class DraftTimestamp extends Component<IProps> {
    render() {
        const draftProp = this.props.storage?.data.definition.draftDef?.draftProperty;
        // todo: solve overlapping with breadcrumbs
        const dateLastModified = this.props.storage?.data.entity?.[draftProp]?.DateLastModified;
        const format = DateType.isToday(dateLastModified) ? DateType.defaultTimeFormat : DateType.defaultDateFormat;
        return !!dateLastModified ? <StyledDraftTimeStamp data-testid={TestIds.DraftTimeStamp}>
            <FloppyDiskIcon color={"C_ACT_thick_line"} preventHover={true} width={"15px"} height={"15px"}/>
            {this.props.t("Components:DraftTimestamp.Saved")}&nbsp;{DateType.localFormat(dateLastModified, format)}
        </StyledDraftTimeStamp> : null;
    }
}

export default withTranslation(["Components"], { withRef: true })(DraftTimestamp);