import { ButtonGroup, StyledButton } from "@components/button";
import styled from "styled-components/macro";

export const ContentWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    align-self: center;
    max-width: 705px;

`;

export const AccountDisabledMainText = styled.h1`
    all: unset;
    font-size: 42px;
    font-weight: bold;
    margin-bottom: 80px;
`;

export const AccountDisabledDescription = styled.div`
    font-size: 32px;
    margin-bottom: 30px;
`;


export const DaysRemainingText = styled.span<{ isVisible: boolean; }>`
    font-size: 62px;
    font-weight: bold;
    margin-bottom: 20px;
    visibility: ${props => props.isVisible ? "visible" : "hidden"};
`;

export const WillBeRemovedText = styled.span`
    font-size: 14px;
    margin-bottom: 40px;
    max-width: 215px;
`;

export const HorizontalButtonGroupInVerticalStyled = styled(ButtonGroup)`
    margin-top: 27px;

    ${StyledButton} {
        // override default flex: 0 from VerticalButtonGroup
        flex: 1;
    }
`;
