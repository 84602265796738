import React from "react";
import { Transition } from "react-transition-group";

const duration = 100;


export enum SlideInDirection {
    Down = "Down",
    Up = "Up"
}

const defaultStyle = {
    // transition: `opacity ${duration}ms ease-in-out`,
    // opacity: 0,
    [SlideInDirection.Down]: {
        transition: `transform ${duration}ms ease-in-out`,
        transform: "translateY(-100%)"
    },
    [SlideInDirection.Up]: {
        transition: `transform ${duration}ms ease-in-out`,
        transform: "translateY(100%)"
    }
};

const transitionStyles: any = {
    [SlideInDirection.Down]: {
        entering: { transform: "translateY(-100%)" },
        entered: { transform: "translateY(0%)" },
        exiting: { transform: "translateY(-100%)" },
        exited: { transform: "translateY(-100%)" }
    },
    [SlideInDirection.Up]: {
        entering: { transform: "translateY(100%)" },
        entered: { transform: "translateY(0%)" },
        exiting: { transform: "translateY(100%)" },
        exited: { transform: "translateY(100%)" }
    }
};

interface IProps {
    in: boolean;
    /* Called before the transition starts
    *  this is needed so that popper js can correctly
    * set data-popper-placement based on the content size*/
    onFirstHiddenRender?: () => void;
    innerProps?: any;
}

interface IState {
    isOpen: boolean;
}

export default class SlideIn extends React.Component<IProps, IState> {
    state: IState = {
        isOpen: false
    };

    componentDidUpdate(prevProps: IProps, prevState: IState) {
        if (prevProps.in !== this.props.in) {
            if (!this.state.isOpen) {
                this.props.onFirstHiddenRender?.();
            }

            if (this.props.in) {
                // this micro delay is probably slightly noticeable,
                // but it will let popper js update placement
                setTimeout(() => {
                    this.setState({
                        isOpen: this.props.in
                    });
                });
            } else {
                this.setState({
                    isOpen: this.props.in
                });
            }
        }
    }

    renderInvisibleChildren = () => {
        return (
            <div {...this.props.innerProps} style={{ visibility: "hidden" }}>
                {this.props.children}
            </div>
        );
    };

    render = () => {
        if (this.props.in && !this.state.isOpen) {
            return this.renderInvisibleChildren();
        }

        let outerStyle = { overflow: "hidden" };

        if (this.props.innerProps?.style) {
            outerStyle = { ...this.props.innerProps.style, ...outerStyle };
        }

        const direction = this.props.innerProps?.["data-popper-placement"]?.startsWith("top") ? SlideInDirection.Up : SlideInDirection.Down;

        return (
            <Transition in={this.state.isOpen}
                        timeout={{
                            appear: 0,
                            exit: duration
                        }}
                        appear mountOnEnter unmountOnExit>
                {state => {
                    return (
                        <div {...this.props.innerProps}
                             style={outerStyle}>
                            <div style={{
                                ...defaultStyle[direction],
                                ...transitionStyles[direction][state]
                            }}>
                                {this.props.children}
                            </div>
                        </div>
                    );
                }}
            </Transition>
        );
    };
}