import { IEntity } from "@odata/BindingContext";
import { Query } from "@odata/OData";
import { isObjectEmpty } from "@utils/general";

export enum AuditTrailChangeType {
    Entity = "Entity",
    Attachment = "Attachment"
}

export enum AuditAttachmentOperation {
    New = "New",
    Update = "Update",
    Delete = "Delete"
}

export interface IAuditEntity {
    AuthorId: number;
    AuthorName: string;
    ChangedOn: string;
    Type: AuditTrailChangeType;
    Operation: AuditAttachmentOperation;
    EntityId: number;
    CurrentValue: string;
    PreviousValue: string;
}

export interface IAuditEntityAtTime {
    AuthorId: number;
    AuthorName: string;
    ChangedOn: string;
    Entity: IEntity;
}

export enum AuditTrailTableColumn {
    ChangedOnDate = "ChangedOnDate",
    ChangedOnTime = "ChangedOnTime",
    AuthorName = "AuthorName",
    Type = "Type"
}

export const prepareExpandPaths = (query: Query): string[] => {
    const paths: string[] = [];

    if (isObjectEmpty(query._expand)) {
        return paths;
    }

    for (const navigation of Object.keys(query._expand)) {
        const innerPaths = prepareExpandPaths(query._expand[navigation]);

        if (innerPaths.length > 0) {
            for (const innerPath of innerPaths) {
                paths.push(`${navigation}.${innerPath}`);
            }
        } else {
            paths.push(navigation);
        }
    }

    return paths;
};