import React, { useCallback, useEffect, useRef, useState } from "react";
import { CheckboxStyled, PureTextAreaStyled, StyledTodoListItem } from "./TodoList.styles";
import { ICheckboxChange } from "../../inputs/checkbox";
import { IInputOnChangeEvent } from "../../inputs/input";
import { KeyName } from "../../../keyName";
import { isDefined } from "@utils/general";

export interface IEditEndArgs {
    hasChanged: boolean;
    addNext?: boolean;
    remove?: boolean;
}

export interface ITodoListItemProps {
    id: string;
    isActive?: boolean;
    isCompleted?: boolean;
    isDisabled?: boolean;
    text: string;
    onEditEnd?: (id: string, value: string, args?: IEditEndArgs) => void;
    onComplete?: (id: string, isCompleted: boolean) => void;
    onNavigate?: (id: string, dir: number) => void;
    removeAnimationDelay?: number;
    onAnimationEnd?: (id: string) => void;
}

const TodoListItem: React.FC<ITodoListItemProps> = (props) => {
    const { id, isActive, isCompleted, text, onEditEnd, onComplete, onNavigate } = props;
    const textarea = useRef<HTMLTextAreaElement>();

    const [currentValue, setCurrentValue] = useState<string>(text);

    const handleComplete = useCallback((args: ICheckboxChange) => {
        onComplete(id, args.value);
    }, [onComplete, id]);

    const handleChange = useCallback((e: IInputOnChangeEvent<string>) => {
        setCurrentValue(e.value);
    }, [setCurrentValue]);

    const handleBlur = useCallback(() => {
        // trigger the callback only in case there is a change or there is no value (newItem)
        if (text !== currentValue || !text) {
            onEditEnd(id, currentValue, { hasChanged: true, remove: !currentValue });
        }
    }, [onEditEnd, id, currentValue, text]);

    const handleKeyDown = useCallback((event: React.KeyboardEvent) => {
        const addNext = event.key === KeyName.Enter;
        const remove = event.key === KeyName.Backspace && !currentValue;
        const hasChanged = text !== currentValue;
        if (addNext || remove) {
            event.preventDefault();
            onEditEnd(id, currentValue, { addNext, remove, hasChanged });
        } else if (event.key === KeyName.ArrowUp || event.key === KeyName.ArrowDown) {
            event.preventDefault();
            onNavigate(id, event.key === KeyName.ArrowUp ? 1 : -1);
        }
    }, [onNavigate, onEditEnd, id, currentValue, text]);

    const handleAnimationEnd = useCallback(() => {
        if (isDefined(props.removeAnimationDelay)) {
            props.onAnimationEnd(id);
        }
    }, [props, id]);

    useEffect(() => {
        if (isActive) {
            textarea.current?.focus();
        }
    }, [isActive]);

    return (
        <StyledTodoListItem $hideAnimationDelay={props.removeAnimationDelay}
                            onAnimationEnd={handleAnimationEnd}>
            <CheckboxStyled onChange={handleComplete}
                            checked={isCompleted}
                            isDisabled={props.isDisabled}
                            decorative/>

            <PureTextAreaStyled value={currentValue}
                                passRef={textarea}
                                minRows={1}
                                isDisabled={props.isDisabled}
                                onKeyDown={handleKeyDown}
                                onBlur={handleBlur}
                                onChange={handleChange}/>
        </StyledTodoListItem>
    );
};

export default TodoListItem;