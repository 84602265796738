import BindingContext from "../../odata/BindingContext";
import { REST_API_URL } from "../../constants";
import customFetch, { getDefaultPostParams } from "../../utils/customFetch";
import { ProductCode } from "@odata/GeneratedEnums";
import { isDefined } from "@utils/general";
import { addCompanyIdToUrl } from "../../contexts/appContext/AppContext.utils";

export enum PurchaseWizardDialogScreen {
    SelectProductType,
    InvoicingInfo,
    PaymentType
}

export enum PurchaseDialogPaymentType {
    Card = "Card",
    Wire = "Wire"
}

export const consentPath = BindingContext.localContext("consent");
export const individualSettingsTextPath = BindingContext.localContext("individualSettingsText");
export const individualSettingsCompanyPath = BindingContext.localContext("individualSettingsCompany");

const PURCHASE_API = `${REST_API_URL}/Subscription/Purchase`;

interface IPurchaseEvalaArgs {
    productCode: ProductCode;
    paymentType: PurchaseDialogPaymentType;
    phoneNumber?: string;
    individualCompanyId?: number;
}

export interface IPurchaseEvalaResponseData {
    Url: string;
}

export const purchaseEvala = async (args: IPurchaseEvalaArgs) => {
    let url = `${PURCHASE_API}/${args.paymentType}`;

    if (isDefined(args.individualCompanyId)) {
        url = addCompanyIdToUrl(url, args.individualCompanyId);
    }

    return await customFetch(url, {
        ...getDefaultPostParams(),
        body: JSON.stringify({
            PhoneNumber: args.phoneNumber,
            ProductCode: args.productCode
        })
    });
};