import React from "react";
import { DashboardBackgroundStyled } from "./NavDashboard.styles";
import { Theme } from "../../theme";

export enum BackgroundType {
    WelcomeScreen,
    ConnectWithIDoklad,
    GoodbyeScreen
}

interface IProps {
    type?: BackgroundType;
    isOverContent?: boolean;
    isTransparent?: boolean;
    hasShellBar?: boolean;
}

function getBackgroundImageNameFromType(type: BackgroundType): keyof Theme {
    switch (type) {
        case BackgroundType.WelcomeScreen:
            return "F_wave_mountain";
        case BackgroundType.ConnectWithIDoklad:
            return "F_wave_mountain_panorama";
        case BackgroundType.GoodbyeScreen:
            return "F_wave_mountain_complex";
        default:
            return "F_wave";
    }
}

const DashboardBackground: React.FC<IProps> = ({ type, isTransparent, isOverContent, hasShellBar }) => {
    // todo image maps to prevent click on specific area, disabled when not on foreground
    return (
        <DashboardBackgroundStyled
            className="dashboard-background"
            $type={getBackgroundImageNameFromType(type)}
            $hasShellBar={hasShellBar !== false}
            $isTransparent={isTransparent}
            $isBehindContent={!isOverContent}/>
    );
};

export default DashboardBackground;