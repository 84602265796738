import { IValueInterval } from "@components/conditionalFilterDialog/ConditionalFilterDialog.utils";
import { getTableDrillDownLink } from "@components/drillDown/DrillDown.utils";
import { formatDateToDateString } from "@components/inputs/date/utils";
import { IReportRowDef } from "@components/smart/smartTable";
import { ICellValueObject } from "@components/table";
import { IFiscalYearEntity } from "@odata/GeneratedEntityTypes";
import { IFormatOptions } from "@odata/OData.utils";
import { getCompanyCurrency, isCashBasisAccountingCompany } from "@utils/CompanyUtils";
import { isNotDefined } from "@utils/general";

import { REST_API_URL } from "../../../constants";
import { TRecordAny, TValue } from "../../../global.types";
import BindingContext, { IEntity } from "../../../odata/BindingContext";
import { ROUTE_ACCOUNT_ANALYSIS } from "../../../routes";
import { formatCurrency } from "../../../types/Currency";
import customFetch, { getDefaultPostParams } from "../../../utils/customFetch";
import { getFiscalYears } from "../../fiscalYear/FiscalYear.utils";
import { AccountAnalysisProps } from "../accountAnalysis/AccountAnalysisDef";
import { CommonReportProps } from "../CommonDefs";
import { CUSTOM_BY_DATE_ID, CUSTOM_DATE_RANGE_ID } from "../customFilterComponents/ComposedDateRange";
import { ReportStorage } from "../ReportStorage";

export interface IAssetAnalysisAssetData {
    Asset_NumberOurs: string;
    Asset_Name: string;
    InUseAccount_Number: string;
}

// for drilldown, we need total rows to contain every asset and assets account from that group
export const enhanceTotalsWithGroupAssetsData = (rowsData: IReportRowDef[]): Record<string, IAssetAnalysisAssetData> => {
    const assets: Record<string, IAssetAnalysisAssetData> = {};

    for (const row of rowsData) {
        if (row.Rows?.length === 0) {
            if (row.Value.Asset_NumberOurs) {
                // value row
                assets[row.Value.Asset_NumberOurs as string] = {
                    Asset_NumberOurs: row.Value.Asset_NumberOurs as string,
                    Asset_Name: row.Value.Asset_Name as string,
                    InUseAccount_Number: row.Value.InUseAccount_Number as string
                };
            } else {
                // total row
                row.Value.AssetsData = assets as any;
            }

        } else {
            const groupAssets = enhanceTotalsWithGroupAssetsData(row.Rows);

            for (const key of Object.keys(groupAssets)) {
                if (!assets[key]) {
                    assets[key] = groupAssets[key];
                }
            }
        }
    }

    return assets;
};

export const getAmountDrilldownFormatter = (useSelectedFiscalYear?: boolean) => {
    return (val: TValue, args: IFormatOptions): ICellValueObject => {
        if (isNotDefined(val)) {
            return null;
        }

        const formattedVal = formatCurrency(val, getCompanyCurrency(args.context));

        if (isCashBasisAccountingCompany(args.storage.context)) {
            // in cash basis accounting there is no adequate report for drilldown
            return {
                value: formattedVal,
                tooltip: formattedVal
            };
        }

        const getAssetFilter = (asset: IAssetAnalysisAssetData) => {
            return `${asset["Asset_NumberOurs"]} - ${asset["Asset_Name"]}`;
        };
        const getAccountFilter = (asset: IAssetAnalysisAssetData) => {
            return asset["InUseAccount_Number"];
        };

        // use Assets for total rows (enhanced on FE), or default values returned from BE for value rows
        const assetFilters = args.entity["AssetsData"] ? Object.values(args.entity["AssetsData"]).map((asset) => getAssetFilter(asset as IAssetAnalysisAssetData)) : [getAssetFilter(args.entity as IAssetAnalysisAssetData)];
        const accountFilters = args.entity["AssetsData"] ? Object.values(args.entity["AssetsData"]).map((asset) => getAccountFilter(asset as IAssetAnalysisAssetData)) : [getAccountFilter(args.entity as IAssetAnalysisAssetData)];
        const filters: Record<string, TValue> = {
            [BindingContext.localContext("Asset")]: assetFilters,
            [AccountAnalysisProps.accounts]: accountFilters,
            [CommonReportProps.dateRange]: useSelectedFiscalYear ? CUSTOM_DATE_RANGE_ID : CUSTOM_BY_DATE_ID,
            [AccountAnalysisProps.sumAmounts]: true
        };

        if (useSelectedFiscalYear) {
            const settings = (args.storage as ReportStorage).settings;
            const fiscalYearId = settings?.[CommonReportProps.fiscalYear];
            const fiscalYear: IFiscalYearEntity = getFiscalYears(args.storage.context).find(fy => fy.Id === fiscalYearId);
            const dateRangeParam: IValueInterval = {
                from: formatDateToDateString(fiscalYear.DateStart),
                to: formatDateToDateString(fiscalYear.DateEnd)
            };

            filters[CommonReportProps.dateRangeCustomValue] = dateRangeParam;
        }

        return getTableDrillDownLink(formattedVal, {
            route: ROUTE_ACCOUNT_ANALYSIS,
            filters,
            context: args.storage.context,
            storage: args.storage,
        });
    };
};

export async function getAssetAnalysisData(hasAccounting: boolean, fiscalYearId: number): Promise<IEntity> {
    let columns = [{
        "ColumnAlias": "Asset_NumberOurs"
    }, {
        "ColumnAlias": "Asset_CalculatedPrice"
    }, {
        "ColumnAlias": "TaxDepreciationPolicyItemReportView_DepreciationExpense"
    }];

    if (hasAccounting) {
        columns = [
            ...columns,
            {
                "ColumnAlias": "AccountingDepreciationPolicyItemReportView_CalculatedExpense"
            },
            {
                "ColumnAlias": "DepreciationDiff"
            }
        ];
    }


    const assetAnalysisProps: TRecordAny = {
        "FiscalYearId": fiscalYearId,
        "ReportHierarchy": {
            "Aggregate": true,
            "Groups": [
                {
                    "ColumnAlias": "TaxDepreciationCategory_Name"
                }
            ],
            "Columns": columns,
            "Aggregations": []
        }
    };

    const response = await customFetch(`${REST_API_URL}/AssetAnalysis`, {
        ...getDefaultPostParams(),
        body: JSON.stringify(assetAnalysisProps)
    });

    return response.json();

}