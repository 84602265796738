import React, { useCallback } from "react";
import { CollapseAllIcon, ColoredIcon, ExpandAllIcon } from "../icon";
import { IconSize } from "../../enums";
import { IconButton } from "../button";
import { GroupHeader, GroupTitle, HeaderLine, ResultCount } from "./Search.styles";
import NumberType from "../../types/Number";


interface IProps {
    id: string;
    label: string;
    count: number;
    isExpandable: boolean;
    isExpanded: boolean;
    onToggle: (id: string, isExpanded: boolean) => void;
}

const SearchResultGroup: React.FC<IProps> = (props) => {
    const { id, label, count, isExpanded, isExpandable, onToggle } = props;

    const handleToggle = useCallback(() => {
        onToggle(id, !isExpanded);
    }, [onToggle, id, isExpanded]);

    const Icon = isExpanded ? CollapseAllIcon : ExpandAllIcon;
    return (
        <>
            <GroupHeader>
                <GroupTitle>
                    {label}
                    <ResultCount>/{NumberType.format(count)}/</ResultCount>
                </GroupTitle>
                <HeaderLine/>
                {isExpandable &&
                    <IconButton title={""}
                                onClick={handleToggle}
                                isLight
                                isDecorative>
                        <ColoredIcon>
                            <Icon width={IconSize.M} height={IconSize.M}/>
                        </ColoredIcon>
                    </IconButton>}
            </GroupHeader>
            {props.children}
        </>
    );
};

export default SearchResultGroup;
