import { LocalSettingsFactory } from "@utils/LocalSettings";

/* First icon should be aligned with agenda name's (select in menu) text top */
export const ShellBarPadding = 51;
export const ShellBarWidth = "4em";

const MainSettingsId = "MainLocalSettings";
export const VariantId = ""; // Local settings support more variants of settings, but we don't need it here

export interface IMainSettings {
    SubscriptionNotificationClosedAt?: Date;
}

export const MainLocalSettings = LocalSettingsFactory<IMainSettings>(MainSettingsId);