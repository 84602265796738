import { ICompanyBankAccountEntity } from "@odata/GeneratedEntityTypes";
import { generalBankAccountFormatter } from "@utils/BankUtils";
import React, { MouseEvent } from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import { FILES_API_URL } from "../../constants";
import { IconSize } from "../../enums";
import { KeyName } from "../../keyName";
import TestIds from "../../testIds";
import { formatCurrency } from "../../types/Currency";
import DateType from "../../types/Date";
import { IconButton } from "../button";
import { getDroppedFiles } from "../fileUploader/File.utils";
import HiddenFileInput from "../fileUploader/HiddenFileInput";
import {
    ColoredIcon,
    CreateStatementIcon,
    LastUpdatedSimpleIcon,
    RefreshSimpleIcon,
    UploadIcon,
    UploadSimpleIcon
} from "../icon";
import Checkbox, { IProps as ICheckboxProps } from "../inputs/checkbox";
import { SvgImage } from "../svgImage/SvgImage";
import Tooltip from "../tooltip";
import {
    AccountNumber,
    ActionsWrapper,
    Balance,
    BankAccountFilterWrapper,
    BankAccountLogoWrapper,
    CheckboxFilterWrapper,
    CheckboxWrapper,
    DropArea,
    DropAreaText,
    DropHandler,
    InfoWrapper,
    LastImport,
    Title
} from "./BankAccountCheckbox.styles";

export type IBankAccountFilterData = Pick<ICompanyBankAccountEntity, "Name" | "AccountNumber" | "BankCode" | "Balance" | "Logo" | "TransactionCurrencyCode" | "Id">;

interface IProps extends IBankAccountFilterData, ICheckboxProps, WithTranslation {
    className?: string;
    isActive: boolean;
    onUploadFile?: (file: FileList, id: number) => Promise<void>;
    onCreateStatement?: () => void;
    onRefresh?: (id: number) => Promise<void>;
    lastUpdateDate?: Date;
    hasApiToken: boolean;
}

interface IState {
    isDragging: boolean;
}

class BankAccountCheckbox extends React.PureComponent<IProps, IState> {
    _fileInput = React.createRef<HTMLInputElement>();

    state = {
        isDragging: false
    };

    handleRefresh = (e?: React.MouseEvent) => {
        this.props.onRefresh(this.props.Id);
        e?.preventDefault();
    };

    handleDrop = async (event: React.DragEvent | DragEvent) => {
        event?.preventDefault();

        this.setState({ isDragging: false });
        const f = await getDroppedFiles(event);

        await this.props.onUploadFile(f, this.props.Id);
    };

    handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.props.onUploadFile(event.target.files, this.props.Id);
    };


    handleUpload = (e?: React.MouseEvent) => {
        e.preventDefault();

        this._fileInput.current.click();
    };

    createStatement = () => {
        this.props.onCreateStatement();
    };

    handleKeyDown = (callback: (e?: React.MouseEvent) => void, e: React.KeyboardEvent) => {
        if (e.key === KeyName.Space || e.key === KeyName.Enter) {
            e.preventDefault();
            callback();
        }
    };

    getFormattedAccount = () => {
        return generalBankAccountFormatter(null, {
            entity: null,
            item: this.props
        });
    };

    handleDragOver = (e: React.MouseEvent) => {
        e.preventDefault();
    };

    startDragging = (e: React.DragEvent) => {
        if (!this.state.isDragging) {
            this.setState({
                isDragging: true
            });
        }
    };

    endDragging = (e: React.DragEvent) => {
        if (this.state.isDragging) {
            this.setState({
                isDragging: false
            });
        }
    };

    handleWrapperClick = (e: MouseEvent<HTMLElement>) => {
        this.props.onChange?.({
            origEvent: e as unknown as Event,
            value: !this.props.checked
        });
    };

    render() {
        const { isDisabled, isActive, hasApiToken } = this.props;
        return (
            <>
                <HiddenFileInput passRef={this._fileInput}
                                 onChange={this.handleFileInputChange}/>
                <BankAccountFilterWrapper isDisabled={isDisabled}
                                          data-testid={TestIds.BankAccountFilterTile}>
                    <CheckboxFilterWrapper onDragEnter={this.startDragging} onDrop={this.handleDrop}
                                           onDragOver={this.handleDragOver}>
                        {isActive &&
                            <DropArea isDragging={this.state.isDragging}>
                                <DropHandler onDragLeave={this.endDragging}/>
                                <UploadIcon preventHover isLight
                                            width={IconSize.XL} height={IconSize.XL}/>
                                <DropAreaText>{this.props.t("Banks:ConfirmationDialog.DragFileHere")}</DropAreaText>
                            </DropArea>}

                        <CheckboxWrapper onClick={this.handleWrapperClick}>
                            <Checkbox isDisabled={isDisabled} checked={this.props.checked}
                                      onChange={this.props.onChange}/>
                        </CheckboxWrapper>
                        <BankAccountLogoWrapper>
                            <SvgImage
                                src={`${FILES_API_URL}/${this.props.Logo?.Id}`}
                                title={this.props.Name}
                            />
                        </BankAccountLogoWrapper>
                        <InfoWrapper>
                            <Tooltip content={this.props.Name}
                                     onlyShowWhenChildrenOverflowing={true}>
                                {(ref) => {
                                    return (<Title ref={ref}>{this.props.Name}</Title>);
                                }}
                            </Tooltip>
                            <AccountNumber>{this.getFormattedAccount()}</AccountNumber>
                            <Balance>{formatCurrency(this.props.Balance?.TransactionBalance, this.props.TransactionCurrencyCode)}</Balance>
                            <LastImport>
                                {!isActive ? this.props.t("Components:BankAccountCheckbox.Inactive") : (
                                    <>
                                        <ColoredIcon><LastUpdatedSimpleIcon width={IconSize.XS}/></ColoredIcon>
                                        {DateType.localFormat(this.props.lastUpdateDate, DateType.defaultDateTimeFormat)}
                                    </>
                                )}
                            </LastImport>
                        </InfoWrapper>
                        <ActionsWrapper>
                            {isActive &&
                                <>
                                    <IconButton title={this.props.t("Components:BankAccountCheckbox.Upload")}
                                                onClick={this.handleUpload}
                                                onKeyDown={this.handleKeyDown.bind(this, this.handleUpload)}
                                                isLight
                                                isDecorative>
                                        <UploadSimpleIcon width={IconSize.S}/>
                                    </IconButton>
                                    <IconButton title={this.props.t("Components:BankAccountCheckbox.CreateStatement")}
                                                onClick={this.createStatement}
                                                onKeyDown={this.handleKeyDown.bind(this, this.createStatement)}
                                                isLight
                                                isDecorative>
                                        <CreateStatementIcon width={IconSize.S}/>
                                    </IconButton>
                                </>
                            }

                            {hasApiToken &&
                                <IconButton title={this.props.t("Components:BankAccountCheckbox.Refresh")}
                                            onClick={this.handleRefresh}
                                            onKeyDown={this.handleKeyDown.bind(this, this.handleRefresh)}
                                            isLight
                                            isDecorative>
                                    <RefreshSimpleIcon width={IconSize.S}/>
                                </IconButton>}
                        </ActionsWrapper>
                    </CheckboxFilterWrapper>
                </BankAccountFilterWrapper>
            </>
        );
    }
}

export default withTranslation(["Components"])(BankAccountCheckbox);