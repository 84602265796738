import { SizeUnit } from "../../enums";

export enum PrintType {
    SinglePage = "SinglePage",
    MultiplePages = "MultiplePages"
}

export enum PrintOrientation {
    Portrait = "Portrait",
    Landscape = "Landscape"
}

export enum PaperSize {
    A3 = "A3",
    A4 = "A4",
    A5 = "A5",
    B4 = "B4",
    B5 = "B5",
    Letter = "Letter",
    Legal = "Legal",
    Tabloid = "Tabloid",
    Custom = "Custom"
}

export enum PrintPageSelection {
    All = "All",
    Custom = "Custom"
}

export enum PrintMargin {
    None = "None",
    Custom = "Custom"
}

export interface IPaperSize {
    // in mm
    width: number;
    // in mm
    height: number;
} // usual screen DPI standard
export const PaperSizes: Record<PaperSize, IPaperSize> = {
    [PaperSize.A5]: {
        width: 148,
        height: 210
    },
    [PaperSize.A4]: {
        width: 210,
        height: 297
    },
    [PaperSize.A3]: {
        width: 297,
        height: 420
    },
    [PaperSize.B5]: {
        width: 176,
        height: 250
    },
    [PaperSize.B4]: {
        width: 250,
        height: 353
    },
    [PaperSize.Letter]: {
        width: 215.9,
        height: 279.4
    },
    [PaperSize.Legal]: {
        width: 215.9,
        height: 355.6
    },
    [PaperSize.Tabloid]: {
        width: 279.4,
        height: 431.8
    },
    [PaperSize.Custom]: {
        width: null,
        height: null
    }
};
// DPI used for export - bigger DPI => more quality => bigger files
export const DPI = 200;
export const STANDARD_DPI = 96;
export const ONE_INCH_IN_MM = 25.4;

export interface IPrintDialogSettings {
    printType: PrintType;
    orientation: PrintOrientation;
    paperSize: PaperSize;
    paperWidth: number;
    paperHeight: number;
    sizeUnit: SizeUnit;
    scale: number;
    pageSelection: PrintPageSelection;
    customPageSelection: string;
    printFilterSettings: boolean;
    printPageNumbers: boolean;
    printHeader: boolean;
    header: string;
    printFooter: boolean;
    footer: string;
    printMargin: PrintMargin;
    marginTop: number;
    marginBottom: number;
    marginLeft: number;
    marginRight: number;
    printCropMarks: boolean;
    cropMarksIndentation: number;
    cropMarksThickness: number;
}

export const PRINT_DIALOG_DEFAULT_SETTINGS: IPrintDialogSettings = {
    // number values are always stored in current unit and converted to px on demand
    printType: PrintType.MultiplePages,
    orientation: PrintOrientation.Portrait,
    paperSize: PaperSize.A4,
    paperWidth: PaperSizes[PaperSize.A4].width,
    paperHeight: PaperSizes[PaperSize.A4].height,
    sizeUnit: SizeUnit.Millimeters,
    scale: 100,
    pageSelection: PrintPageSelection.All,
    customPageSelection: "",
    printFilterSettings: true,
    printPageNumbers: false,
    printHeader: false,
    header: "",
    printFooter: false,
    footer: "",
    printMargin: PrintMargin.None,
    marginTop: null, marginBottom: null,
    marginLeft: null, marginRight: null,
    printCropMarks: false,
    cropMarksIndentation: 3,
    cropMarksThickness: 0.25
};