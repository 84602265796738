import { TTemporalDialogSettings } from "@components/smart/FieldInfo";
import { FilterBarGroup, getDefaultFilterGroupDef } from "@components/smart/smartFilterBar/SmartFilterBar.types";
import { ISummaryItem } from "@components/smart/smartSummaryItem/SmartSummaryItem";
import { createPath } from "@odata/BindingContext";
import {
    EntitySetName,
    EntityTypeName,
    PrSalaryComponentEntity,
    PrSalaryComponentTemporalEntity,
    PrSalaryComponentTypeEntity
} from "@odata/GeneratedEntityTypes";
import { PrSalaryComponentTypeCode } from "@odata/GeneratedEnums";
import { getEnumNameSpaceName } from "@odata/GeneratedEnums.utils";
import { transformToODataString } from "@odata/OData.utils";
import {
    getTemporalAdditionalProperties,
    getTemporalPropertyFieldDefinition,
    TemporalValidityFieldsDef
} from "@odata/TemporalUtils";
import { getCompanyCurrency } from "@utils/CompanyUtils";
import i18next from "i18next";

import { BasicInputSizes, FieldType, GroupedField, Sort, ValueType } from "../../../enums";
import { Model } from "../../../model/Model";
import CurrencyType, { formatCurrency } from "../../../types/Currency";
import { IFormDef } from "../../../views/formView/Form";
import { ISplitPageTableDef, ITableDefTabData } from "../../../views/table/TableView.utils";
import { setDefByEntityType } from "../../getDefByEntityType";
import { getItemBreadCrumbsText, IDefinition, IGetDefinition } from "../../PageUtils";
import SalaryComponentFormView from "./SalaryComponentFormView";


export const getDefinitionsFactory = (inEditableWindow?: boolean, selectedIds?: number[]): IGetDefinition => {
    const getDefinitions: IGetDefinition = (): IDefinition => {

        const preventStoreVariant = inEditableWindow;

        let filter: string;
        if (selectedIds?.length) {
            filter = `not(${PrSalaryComponentEntity.Id} in (${transformToODataString(selectedIds, ValueType.Number)}))`;
        }

        const defaultTabs: ITableDefTabData[] = [
            { id: "1", title: i18next.t("SalaryComponent:Tabs.Active") },
            { id: "2", title: i18next.t("SalaryComponent:Tabs.Inactive") },
            { id: null, title: i18next.t("SalaryComponent:Tabs.All") }
        ];
        const tabs: ITableDefTabData[] = inEditableWindow ? null : defaultTabs;

        const table: ISplitPageTableDef = {
            id: `${EntityTypeName.PrSalaryComponent}Table`,
            preventStoreVariant,
            filterBarDef: [{
                ...getDefaultFilterGroupDef(FilterBarGroup.Filters),
                defaultFilters: [
                    createPath(PrSalaryComponentEntity.CurrentTemporalPropertyBag, PrSalaryComponentTemporalEntity.Name),
                    createPath(PrSalaryComponentEntity.CurrentTemporalPropertyBag, PrSalaryComponentTemporalEntity.Type),
                    createPath(PrSalaryComponentEntity.CurrentTemporalPropertyBag, PrSalaryComponentTemporalEntity.Amount)
                ],
                filterDefinition: {
                    [createPath(PrSalaryComponentEntity.CurrentTemporalPropertyBag, PrSalaryComponentTemporalEntity.Type)]: {
                        fieldSettings: {
                            displayName: PrSalaryComponentTypeEntity.Name
                        }
                    },
                    [createPath(PrSalaryComponentEntity.CurrentTemporalPropertyBag, PrSalaryComponentTemporalEntity.Name)]: {},
                    [createPath(PrSalaryComponentEntity.CurrentTemporalPropertyBag, PrSalaryComponentTemporalEntity.Amount)]: {}
                },
                isValueHelp: true
            }],
            filter,
            initialSortBy: [{
                id: createPath(PrSalaryComponentEntity.CurrentTemporalPropertyBag, PrSalaryComponentTemporalEntity.Type),
                sort: Sort.Desc
            }],
            columns: [
                createPath(PrSalaryComponentEntity.CurrentTemporalPropertyBag, PrSalaryComponentTemporalEntity.Name),
                createPath(PrSalaryComponentEntity.CurrentTemporalPropertyBag, PrSalaryComponentTemporalEntity.Type),
                createPath(PrSalaryComponentEntity.CurrentTemporalPropertyBag, PrSalaryComponentTemporalEntity.Amount)
            ],
            columnDefinition: {
                [createPath(PrSalaryComponentEntity.CurrentTemporalPropertyBag, PrSalaryComponentTemporalEntity.Type)]: {
                    fieldSettings: {
                        displayName: PrSalaryComponentTypeEntity.Name
                    }
                },
                [createPath(PrSalaryComponentEntity.CurrentTemporalPropertyBag, PrSalaryComponentTemporalEntity.Name)]: {},
                [createPath(PrSalaryComponentEntity.CurrentTemporalPropertyBag, PrSalaryComponentTemporalEntity.Amount)]: {
                    formatter: (val, args) => {
                        return formatCurrency(val, getCompanyCurrency(args.context));
                    }
                }
            },
            title: i18next.t("SalaryComponent:Title"),
            tabs,
            tabsSettings: {}
        };
        const summary: ISummaryItem[] = [];

        const temporalDialogSettings: TTemporalDialogSettings = {
            dialogTitle: i18next.t("SalaryComponent:Form.PlanSalaryComponentTitle"),
            columns: [
                PrSalaryComponentTemporalEntity.Name,
                PrSalaryComponentTemporalEntity.Type,
                PrSalaryComponentTemporalEntity.Amount
            ]
        };

        const form: IFormDef = {
            id: `${EntityTypeName.PrSalaryComponent}Form`,
            translationFiles: getDefinitions.translationFiles,
            getItemBreadCrumbText: (storage: Model) =>
                    getItemBreadCrumbsText(storage, i18next.t("SalaryComponent:New"), i18next.t("SalaryComponent:FormTitle")),
            isDeletable: true,
            formControl: SalaryComponentFormView,
            summary,
            additionalProperties: [
                ...getTemporalAdditionalProperties(PrSalaryComponentTemporalEntity, PrSalaryComponentEntity)
            ],
            fieldDefinition: {
                ...getTemporalPropertyFieldDefinition({
                    propName: PrSalaryComponentTemporalEntity.Name,
                    definition: {
                        groupedField: GroupedField.MultiStart,
                        fieldSettings: {
                            temporalDialog: temporalDialogSettings
                        }
                    },
                    withOpeningButton: false
                }),
                ...getTemporalPropertyFieldDefinition({
                    propName: PrSalaryComponentTemporalEntity.Type,
                    definition: {
                        width: BasicInputSizes.S,
                        type: FieldType.ComboBox,
                        defaultValue: PrSalaryComponentTypeCode.Monthly,
                        fieldSettings: {
                            displayName: PrSalaryComponentTypeEntity.Name
                        }
                    },
                    withOpeningButton: false
                }),
                ...getTemporalPropertyFieldDefinition({
                    propName: PrSalaryComponentTemporalEntity.Amount,
                    definition: {
                        groupedField: GroupedField.MultiEnd,
                        fieldSettings: {
                            temporalDialog: temporalDialogSettings,
                            unit: args => CurrencyType.getCurrencyUnit(getCompanyCurrency(args.context))
                        }
                    }
                }),
                ...TemporalValidityFieldsDef
            },
            groups: [
                {
                    id: "main",
                    rows: [
                        [
                            { id: createPath(PrSalaryComponentEntity.CurrentTemporalPropertyBag, PrSalaryComponentTemporalEntity.Name) },
                            { id: createPath(PrSalaryComponentEntity.CurrentTemporalPropertyBag, PrSalaryComponentTemporalEntity.Type) },
                            { id: createPath(PrSalaryComponentEntity.CurrentTemporalPropertyBag, PrSalaryComponentTemporalEntity.Amount) }
                        ]

                    ]
                }
            ]
        };
        return {
            entitySet: EntitySetName.PrSalaryComponents,
            table,
            form
        };
    };

    getDefinitions.translationFiles = ["SalaryComponent", "Common", getEnumNameSpaceName(EntityTypeName.PrSalaryComponentType)];

    return getDefinitions;
};

export const getDefinitions = getDefinitionsFactory();

setDefByEntityType(EntityTypeName.PrSalaryComponent, getDefinitions);