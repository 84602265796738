import React from "react";
import {
    FileToolbarItem,
    getFileNameFromSrc,
    getSharedToolbarDefinition,
    IFileViewerProps
} from "../FileViewers.utils";
import { WithTranslation, withTranslation } from "react-i18next";
import { Image, ImageWrapper } from "./ImgViewer.styles";
import { StyledFileViewer } from "../FileViewers.styles";
import { Header } from "../pdfViewer/PDFViewer.style";
import TestIds from "../../../testIds";
import { Toolbar } from "../../toolbar/Toolbar";
import BusyIndicator from "../../busyIndicator/BusyIndicator";
import { TValue } from "../../../global.types";
import NoPreviewSquirrel from "../NoPreviewSquirrel";
import { saveAs } from "file-saver";

interface IProps extends IFileViewerProps, WithTranslation {

}

interface IState {
    loaded: boolean;
    error: string;
}

class ImgViewer extends React.PureComponent<IProps, IState> {
    static defaultProps: Partial<IProps> = {
        hiddenItems: []
    };

    state = {
        loaded: false,
        error: ""
    };

    componentDidUpdate(prevProps: IProps, prevState: IState) {
        if (this.props.src !== prevProps.src) {
            this.setState({
                loaded: false,
                error: ""
            });
        }
    }

    handleImgLoad = () => {
        this.setState({
            loaded: true
        });
    };

    handleImgError = () => {
        this.setState({
            error: this.props.t("Components:FileViewers.FetchFailed"),
            loaded: true
        });
    };

    renderError = () => {
        return (
            <NoPreviewSquirrel text={this.state.error}/>
        );
    };

    handleItemChange = (id: string, value?: TValue) => {
        switch (id) {
            case FileToolbarItem.Dashboard:
                this.props.onGoToList?.();
                break;
            case FileToolbarItem.Download:
                saveAs(this.props.src, this.props.fileName ?? getFileNameFromSrc(this.props.src));
                break;
            case FileToolbarItem.Remove:
                this.props.onFileRemove?.(this.props.src);
                break;
            default:
                this.props.onCustomFileAction?.(id);
        }
    };

    getToolbarDefinition = () => {
        const definition = getSharedToolbarDefinition(this.props.isReadOnly, this.props.hiddenItems, this.props.isRemoveDisabled, this.props.t);

        if (this.props.customFileActions) {
            definition.items = [
                ...this.props.customFileActions,
                ...definition.items
            ];
        }

        return definition;
    };

    render() {
        const def = this.getToolbarDefinition();

        return (
            <StyledFileViewer data-testid={TestIds.FileViewer}>
                <Header>
                    <Toolbar
                        onItemChange={this.handleItemChange}
                        staticItems={def.staticItems}>
                        {def.items}
                    </Toolbar>
                </Header>
                {!this.state.loaded &&
                    <BusyIndicator/>
                }
                {!this.state.error &&
                    <ImageWrapper data-testid={TestIds.ImageWrapper}>
                        <Image src={this.props.src} onLoad={this.handleImgLoad} onError={this.handleImgError}/>
                    </ImageWrapper>
                }
                {this.state.error && this.renderError()}
            </StyledFileViewer>
        );
    }
}

export default withTranslation(["Components"])(ImgViewer);