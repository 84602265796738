import DialogFormView, { IDialogFormViewProps } from "../../../views/formView/DialogFormView";
import { IFormStorageSaveResult, IGetCorrectErrorBc } from "../../../views/formView/FormStorage";
import { DisposalAccountPath, DisposalDatePath, disposeAsset, IFixedAssetDisposalCustomData } from "./FixedAsset.utils";
import { ISmartFieldChange } from "@components/smart/smartField/SmartField";
import { getFiscalDataCorrespondingToDateAccountingTransaction } from "../../documents/Document.utils";
import BindingContext, { TEntityKey } from "../../../odata/BindingContext";
import { fetchAccountingDepreciationPolicyItems, getLastDepreciationBeforeDate } from "../Asset.utils";
import memoizeOne from "../../../utils/memoizeOne";
import Currency from "../../../types/Currency";
import { IAssetEntity } from "@odata/GeneratedEntityTypes";
import { isAccountAssignmentCompany, isCashBasisAccountingCompany } from "@utils/CompanyUtils";
import { fetchSelectItems } from "@components/smart/smartSelect/SmartSelectAPI";
import { AppContext } from "../../../contexts/appContext/AppContext.types";
import { forEachKey } from "@utils/general";
import { withPermissionContext } from "../../../contexts/permissionContext/withPermissionContext";
import { Dayjs } from "dayjs";
import { reloadAccounts } from "../../accountAssignment/Account.utils";

export interface IFixedAssetDisposalDialogEntity extends IAssetEntity {
    AssetId?: TEntityKey;
}

class FixedAssetDisposalDialogFormView extends DialogFormView<IFixedAssetDisposalDialogEntity, IDialogFormViewProps<IFixedAssetDisposalDialogEntity, IFixedAssetDisposalCustomData>> {
    static contextType = AppContext;
    _lastDisposalDate: Date | Dayjs;

    onAfterLoad = async () => {

        this._lastDisposalDate = this.props.storage.getValueByPath("DisposalDate");
        if (!isCashBasisAccountingCompany(this.props.storage.context)) {
            await this.updateDisposalAccountInfo();
            await this.setDefaultDisposalAccount();
        }

        return super.onAfterLoad();
    };

    handleDisposalDateOrReasonChange = async (e: ISmartFieldChange): Promise<void> => {
        const eventPath = e.bindingContext.getPath();
        if (["Reason", "DisposalDate"].some(path => eventPath === BindingContext.localContext(path)) && e.triggerAdditionalTasks) {
            const { storage } = this.props;
            const DisposalDate = storage.getValueByPath(DisposalDatePath) as Dayjs;
            const previousFiscalData = getFiscalDataCorrespondingToDateAccountingTransaction(storage, this._lastDisposalDate);
            const { fiscalYear } = getFiscalDataCorrespondingToDateAccountingTransaction(storage, DisposalDate);
            this._lastDisposalDate = DisposalDate;
            const promises = [];

            const reasonHasChanged = eventPath.includes("Reason");
            const hasChanged = previousFiscalData?.fiscalYear?.Id !== fiscalYear?.Id || reasonHasChanged;

            if (isAccountAssignmentCompany(storage.context)) {
                if (hasChanged) {
                    promises.push(reloadAccounts(storage, !!fiscalYear, DisposalAccountPath));
                }
                if (!reasonHasChanged) {
                    promises.push(this.updateDisposalAccountInfo());
                }
            }

            if (promises.length) {
                storage.setBusy(true);
                await Promise.all(promises);
                storage.setBusy(false);
            }
            storage.refresh();
        }
    };

    fetchAccountingDepreciationPolicyItemsMemoized = memoizeOne(fetchAccountingDepreciationPolicyItems);

    updateDisposalAccountInfo = async (): Promise<void> => {
        const { storage } = this.props;
        const assetStorage = storage.getCustomData().assetStorage;
        const info = storage.getInfo(storage.data.bindingContext.navigate(DisposalAccountPath));

        const items = await this.fetchAccountingDepreciationPolicyItemsMemoized(assetStorage);

        const disposalDate = storage.getValueByPath(DisposalDatePath) as Dayjs;
        const lastPolicyItem = getLastDepreciationBeforeDate(items, disposalDate);
        const currency = assetStorage.data.entity.Currency?.Code;

        info.tooltip = (lastPolicyItem && lastPolicyItem.EndValue > 0) ? Currency.format(lastPolicyItem.EndValue, { currency }) : null;
        info.isDisabled = !info.tooltip;
        info.isRequired = !!info.tooltip;

        // refresh field to show correct tooltip
        storage.addActiveField(info.bindingContext);
        storage.refreshFields();
    };

    setDefaultDisposalAccount = async (): Promise<void> => {
        const { storage } = this.props;
        const bc = storage.data.bindingContext.navigate(DisposalAccountPath);
        const fieldInfo = storage.getInfo(bc);
        // if not visible, we don't need to load items...
        if (!fieldInfo.isDisabled) {
            const items = await fetchSelectItems({
                fieldInfo,
                oData: storage.oData,
                appContext: this.context,
                storage
            });
            fieldInfo.fieldSettings.items = items;
            if (items?.length === 1) {
                const item = items[0];
                forEachKey(item.additionalData, (key) => {
                    const val = item.additionalData[key];
                    if (key === "Id") {
                        storage.setValue(bc, val);
                    } else {
                        storage.setValueByPath(`${DisposalAccountPath}_${key}`, val);
                    }
                });
            } else {
                storage.clearAndSetValue(bc, null);
            }
            storage.refresh();
        }
    };

    handleChange(e: ISmartFieldChange): void {
        super.handleChange(e);
        this.handleDisposalDateOrReasonChange(e);
    }

    getCorrectErrorBc = (args: IGetCorrectErrorBc): BindingContext => {
        const path = args.matchedBindingContext.getPath();
        const { bindingContext } = this.props.storage.data;
        if (path === BindingContext.localContext("Date")) {
            // BE validates Date field, but locally we have DisposalDate
            return bindingContext.navigate(DisposalDatePath);
        }

        return args.matchedBindingContext;
    };

    save = async (): Promise<IFormStorageSaveResult> => {
        const { storage } = this.props;
        if (await storage.validate(true)) {
            this.forceUpdate();
            return null;
        }

        const { bindingContext, entity, origEntity } = storage.data;

        storage.setBusy();
        this.forceUpdate();

        try {
            await disposeAsset(storage, origEntity.AssetId as number);
        } catch (error) {
            await storage.handleOdataError({
                error,
                bindingContext,
                getCorrectErrorBc: this.getCorrectErrorBc,
                fieldValidationWithoutErrorAlert: true
            });
            storage.setBusy(false);
            this.forceUpdate();
            return null;
        }

        return {
            bindingContext,
            data: entity
        };
    };
}

export default withPermissionContext(FixedAssetDisposalDialogFormView);