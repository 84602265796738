import React from "react";
import { AvatarSize, Direction, IconSize, TextAlign } from "../../enums";
import { CloseIcon, ImageIcon, LikeFilledIcon, LikeIcon } from "../icon";
import {
    BottomOuterWrapper,
    BottomWrapper,
    CommentTextArea,
    CommentWrapper,
    HotspotTooltipHeader,
    HotspotTooltipHeaderWrapper,
    HotspotTooltipText,
    IconButtonCloseIcon,
    LikeWrapper,
    StyledHotspotTooltipContent
} from "./HotspotTooltipContent.styles";
import { Button, ButtonGroup, IconButton } from "../button";
import { WithTranslation, withTranslation } from "react-i18next";
import Avatar from "../avatar";
import EmojiPicker from "./EmojiPicker";
import { ScrollBar } from "../scrollBar";
import CommentImage from "./CommentImage";
import { TImage } from "../imageUploader";
import { WithBusyIndicator, withBusyIndicator } from "../busyIndicator/withBusyIndicator";
import Alert, { IBaseAlertProps } from "../alert/Alert";
import { debounce } from "lodash";
import Tooltip from "../tooltip";

export interface ILikeSettings {
    showLikeButtons?: boolean;
    isLikeActive?: boolean;
    isDislikeActive?: boolean;
    onDislike?: (id: string, value: boolean) => void;
    onLike?: (id: string, value: boolean) => void;
    showComment?: boolean;
    onSendCommentClick?: (id: string, comment: string) => void;
    commentText?: string;
    onCommentTextChange?: (id: string, comment: string) => void;
    avatarSrc?: string;
    image?: TImage;
    onImageInsert?: (id: string, images: File[]) => void;
    onImageClick?: (id: string, image: TImage) => void;
    alert?: Pick<IBaseAlertProps, "status" | "title">;
    onAlertFadeEnd?: (id: string) => void;
    busy?: boolean;
}

export interface IHotspotTooltipContentProps extends ILikeSettings {
    id: string;
    width?: string;
    header?: string;
    headerTextAlign?: TextAlign;
    text?: string;
    textAlign?: TextAlign;
    onClose?: (id: string) => void;
    emojiPickerDomPortalParent?: React.RefObject<HTMLElement>;
}

class HotspotTooltipContent extends React.PureComponent<IHotspotTooltipContentProps & WithTranslation & WithBusyIndicator> {
    textAreaRef = React.createRef<HTMLTextAreaElement>();

    static defaultProps: Partial<IHotspotTooltipContentProps> = {
        commentText: ""
    };

    componentDidMount() {
        if (this.props.showComment) {
            this.focusTextArea();
        }
    }

    componentDidUpdate(prevProps: IHotspotTooltipContentProps) {
        if (this.props.showComment && !prevProps.showComment) {
            this.focusTextArea();
        }
    }

    focusTextArea = debounce(() => {
        this.textAreaRef.current?.focus();
    });

    handleClose = () => {
        this.props.onClose?.(this.props.id);
    };

    handleLikeClick = () => {
        this.props.onLike?.(this.props.id, !this.props.isLikeActive);
    };

    handleDislikeClick = () => {
        this.props.onDislike?.(this.props.id, !this.props.isDislikeActive);
    };

    handleSendCommentClick = () => {
        this.props.onSendCommentClick?.(this.props.id, this.props.commentText);
    };

    handleCommentChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        this.props.onCommentTextChange?.(this.props.id, event.target.value);
    };

    handleEmojiSelect = (emoji: string) => {
        this.props.onCommentTextChange?.(this.props.id, `${this.props.commentText}${emoji}`);
    };

    handleImageChange = (images: File[]) => {
        this.props.onImageInsert?.(this.props.id, images);
    };

    handleImageClick = (image: TImage) => {
        this.props.onImageClick?.(this.props.id, image);
    };

    handleFadeEnd = () => {
        this.props.onAlertFadeEnd?.(this.props.id);
    };

    renderLikeButtons = () => {
        const LikeIconComponent = this.props.isLikeActive ? LikeFilledIcon : LikeIcon;
        const DislikeIconComponent = this.props.isDislikeActive ? LikeFilledIcon : LikeIcon;

        return (
            <LikeWrapper>
                <IconButton title={this.props.t("Components:Hotspots.Dislike")}
                            isLight
                            isDecorative
                            onClick={this.handleDislikeClick}
                            style={{ transform: "scale(1, -1)" }}>
                    <DislikeIconComponent width={IconSize.S} height={IconSize.S}/>
                </IconButton>
                <IconButton title={this.props.t("Components:Hotspots.Like")}
                            isLight
                            isDecorative
                            onClick={this.handleLikeClick}>
                    <LikeIconComponent width={IconSize.S} height={IconSize.S}/>
                </IconButton>
            </LikeWrapper>
        );
    };

    renderImageUploaderCustomButton = (onClick: () => void, isDisabled: boolean) => {
        return (
            <IconButton title={this.props.t("Components:Hotspots.InsertImage")}
                        onClick={onClick}
                        isDisabled={isDisabled}
                        isTransparent
                        isLight>
                <ImageIcon width={IconSize.S}/>
            </IconButton>
        );
    };

    renderImage = () => {
        return <CommentImage key={0}
                             image={this.props.image}
                             onClick={this.handleImageClick}/>;

    };

    renderFooterButtons = () => {
        return (
            <ButtonGroup direction={Direction.Horizontal}
                         align={TextAlign.Right}
                         style={{ marginTop: "10px" }}>
                <EmojiPicker onEmojiSelect={this.handleEmojiSelect}
                             domPortalParent={this.props.emojiPickerDomPortalParent}/>
                {/*<FileUploadButton onFileChange={this.handleImageChange}*/}
                {/*                  accept={"image/*"}*/}
                {/*                  isDisabled={!!this.props.image}*/}
                {/*                  renderCustomButton={this.renderImageUploaderCustomButton}*/}
                {/*/>*/}
                <Button onClick={this.handleSendCommentClick}
                        isDisabled={!(this.props.commentText || this.props.image)}
                        isLight>
                    {this.props.t("Components:Hotspots.Send")}
                </Button>
            </ButtonGroup>
        );
    };

    renderAlert = () => {
        return (
            <Alert {...this.props.alert}
                   isFullWidth
                   isSmall
                   isInverseTheme
                   useFade
                   onFadeEnd={this.handleFadeEnd}
                   style={{
                       marginTop: "15px"
                   }}
            />
        );
    };

    renderComment = () => {
        return (
            <BottomOuterWrapper hasImage={!!this.props.image}>
                {this.props.busy && this.props.busyIndicator}
                <BottomWrapper>
                    <ScrollBar isInFlex
                               style={{
                                   opacity: this.props.alert ? 0.5 : 1,
                                   pointerEvents: this.props.alert ? "none" : "auto"
                               }}>
                        <CommentWrapper>
                            <Avatar src={this.props.avatarSrc}
                                    size={AvatarSize.S}
                                    isRound
                            />
                            <CommentTextArea placeholder={this.props.t("Components:Hotspots.CommentPlaceholder")}
                                             rows={2}
                                             value={this.props.commentText ?? ""}
                                             onChange={this.handleCommentChange}
                                             ref={this.textAreaRef}
                            />
                        </CommentWrapper>
                        {this.props.image && this.renderImage()}
                    </ScrollBar>
                    {!this.props.alert && this.renderFooterButtons()}
                    {this.props.alert && this.renderAlert()}
                </BottomWrapper>
            </BottomOuterWrapper>
        );
    };

    render() {
        return (
            <StyledHotspotTooltipContent $width={this.props.width}>
                <HotspotTooltipHeaderWrapper textAlign={this.props.headerTextAlign}>
                    {this.props.header &&
                        <HotspotTooltipHeader title={this.props.header}>
                            {this.props.header}
                        </HotspotTooltipHeader>
                    }
                    <IconButtonCloseIcon title={this.props.t("Common:General.Cancel")}
                                         isLight
                                         isDecorative
                                         onClick={this.handleClose}>
                        <CloseIcon width={IconSize.XS} height={IconSize.XS}/>
                    </IconButtonCloseIcon>
                </HotspotTooltipHeaderWrapper>
                {this.props.text &&
                        <ScrollBar isInFlex
                                   style={{
                                       marginBottom: "6px"
                                   }}>
                            <Tooltip content={this.props.text}
                                     onlyShowWhenChildrenOverflowing={true}>
                                {(ref) => {
                                    return <HotspotTooltipText ref={ref} textAlign={this.props.textAlign}>
                                        {this.props.text}
                                    </HotspotTooltipText>;
                                }}
                            </Tooltip>
                        </ScrollBar>

                }
                {this.props.showLikeButtons && this.renderLikeButtons()}
                {this.props.showComment && this.renderComment()}
            </StyledHotspotTooltipContent>
        );
    }
}

export default withTranslation(["Common", "Components"])(withBusyIndicator({
    passBusyIndicator: true,
    isInverse: true
})(HotspotTooltipContent));