import { BadgeStyled } from "@components/badge";
import { StyledHeader, TitleWrapper } from "@components/header/Header.styles";
import { Separator } from "@components/separator/Separator";
import { ellipsis, transparentize } from "polished";
import styled, { css } from "styled-components/macro";

import { AnimationSize, BearAnim } from "../animations/Animations";
import Avatar from "../components/avatar";
import { Button, IconButton } from "../components/button";
import Clickable from "../components/clickable";
import {
    fadeOutAnimation,
    getFocusBorderElement,
    LayoutStackOrder,
    multiline_ellipsis,
    T_BOLD_tiny,
    T_HEADER_small,
    T_PLAIN_big,
    T_PLAIN_big_hig,
    T_PLAIN_small,
    T_TITLE_2,
    T_TITLE_4,
    Title
} from "../global.style";

export const StyledNotifications = styled.div<{
    $isEmpty: boolean;
}>`
    display: flex;
    flex-direction: column;
    color: ${props => props.theme.C_BTN_4L_emph_text};
    margin-right: ${props => props.$isEmpty ? "0" : "55px"};

    ${props => props.$isEmpty && css`
        height: 100%;
    `};
`;

export const MarkAllAsReadButton = styled(Button)`
    align-self: flex-end;
    flex-basis: 0;
    margin-bottom: 7px;
    margin-right: -19px;
`;

export const NotificationsHeader = styled.div`
    position: relative;
    ${StyledHeader} {
        padding: 46px 44px 65px; // https://app.zeplin.io/project/649578ac78ff3747de9169ac/screen/62beb5804f131c1d69526d69
        ${TitleWrapper} {
            padding-top: 10px;
            ${Title} {
                ${T_TITLE_2};
                color: ${props => props.theme.C_TEXT_dialogue};
                text-transform: uppercase;
            }
        }
    }
`;

export const StyledBackButton = styled(IconButton)`
    position: absolute;
    top: 50px;
    left: 4px;
`;

export const HeaderLeftPart = styled.div`
    display: flex;
    flex: 1 1 auto;
`;

export const BearAnimStyled = styled(BearAnim)`
    max-width: ${AnimationSize.L}px;
    max-height: ${AnimationSize.L}px;
    min-height: ${AnimationSize.L / 2}px;
    width: 100%;
    height: 100%;
`;

interface IStyledNotification {
    $isUnread: boolean;
}

export const MarkAsReadWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 44px;
`;

const MarkAsReadBorder = css`
    border: 1px solid ${props => props.theme.C_ACT_hover};
`;

export const MarkAsRead = styled.div<IStyledNotification>`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 13px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    cursor: pointer;
    ${getFocusBorderElement({ isLight: true, keyboardOnly: true })};

    &:hover {
        background-color: ${props => transparentize(0.8, props.theme.C_BTN_4L_emph_text_hover)};
        ${MarkAsReadBorder};
    }

    ${props => props.$isUnread && css`
        &::after {
            content: "";
            position: relative;
            width: 10px;
            height: 10px;
            background-color: ${props => props.theme.C_SEM_el_bad};
            border-radius: 50%;
        }
    `}
`;

export const NotificationWrapper = styled.div`
    display: flex;

    &:hover {
        ${MarkAsRead} {
            ${MarkAsReadBorder};
        }
    }
`;

export const StyledNotification = styled(Clickable)<IStyledNotification>`
    display: flex;
    position: relative;
    width: 400px;
    padding: 12px;
    margin-bottom: 20px;
    background-color: ${props => props.$isUnread ? transparentize(0.8, props.theme.C_BTN_hover_light) : "none"};
    cursor: pointer;
    border-radius: ${props => props.theme.borderRadius};

    ${getFocusBorderElement({ isLight: true, keyboardOnly: true })};

    &:hover {
        background-color: ${props => props.theme.C_ACT_hover};
    }
`;

export const NotificationAvatar = styled(Avatar)`
    top: -2px;
    left: -2px;
    margin-right: 10px;
`;

export const NotificationContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 100%;
    // magical fix to prevent overflowing from parent caused by max-width 100% 
    // https://stackoverflow.com/a/66689926/3352544
    min-width: 0;
`;

const NotificationTitleStyles = css`
    ${T_BOLD_tiny};
    ${ellipsis()};
    opacity: 0.75;
    text-transform: uppercase;
`;

export const NotificationTitle = styled.div`
    ${NotificationTitleStyles};
`;

export const NotificationSubtitle = styled.div`
    ${T_PLAIN_big_hig};
    ${ellipsis()};
    opacity: 0.75;
    margin-top: 2px;
`;

export const NotificationInfo = styled.div`
    ${T_HEADER_small};
    ${ellipsis()};
    margin-top: 8px;
`;

export const DescriptionWrapper = styled.div`
    display: flex;
    gap: 6px;
`;

export const NotificationDescription = styled.div`
    ${T_PLAIN_big};
    font-weight: 300;
    ${multiline_ellipsis("100%", 2)};
    margin-top: 4px;
    position: relative;
    max-width: fit-content;
`;

export const NotificationTime = styled.div`
    ${T_PLAIN_small};
    font-weight: 300;
    position: absolute;
    top: 12px;
    right: 12px;
`;

export const NotificationGroup = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 12px;
    margin-left: 44px;
`;

export const SeparatorStyledNotificationGroup = styled(Separator)`
    ${T_TITLE_4};
`;

export const StyledNotificationAlert = styled.div`
    position: relative;
    display: flex;
    width: 210px;
    height: 74px;
    padding: 10px 22px 10px 12px;
    background-image: ${props => props.theme.C_BG_shellbar_notification_alert};
    border-radius: 8px;
    color: ${props => props.theme.C_TEXT_dialogue};
    z-index: ${LayoutStackOrder.Menu};

    ${fadeOutAnimation()}
`;

export const NotificationTitlesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 10px;
`;

export const NotificationAlertTitle = styled.div`
    ${NotificationTitleStyles};
`;

export const NotificationAlertSubtitle = styled.div`
    ${T_PLAIN_big};
    ${multiline_ellipsis("100%", 2)};
    margin-top: 8px;
`;

export const NotificationsCount = styled(BadgeStyled)`
    top: -6px;
    right: 6px;
    background-color: ${props => props.theme.C_SEM_el_bad};
    padding: 1px 5.5px 0 5.5px;
    height: 18px;
    pointer-events: none;
`;