import styled, { css } from "styled-components/macro";

import Clickable from "../../components/clickable";
import { T_HEADER_bold, T_HEADER_small, T_PLAIN_small } from "../../global.style";

const ROW_HEIGHT = 53;
const HEADER_SPACING = 28;

export const Page = styled.div`
    padding-top: 45px;
    height: 100%;
`;

export const Body = styled.div`
    padding: 0 38px 38px 38px;
`;

export const Content = styled.div`
    padding: 30px 0;
`;

export const Title = styled.div``;

export const HeaderTile = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const IconWrapper = styled.div`
    height: 143px;
    width: 143px;
    margin: 0 auto;
`;

export const TileTitle = styled.div`
    ${T_HEADER_small};
    font-weight: normal;
`;

export const ElCol = styled.div`
    align-self: end;
    padding-bottom: ${HEADER_SPACING}px;
`;

export const HeaderCol = styled.div`
    padding-bottom: ${HEADER_SPACING}px;
`;

export const GridStyled = styled.div`
    display: grid;
    grid-template-columns: max-content repeat(3, minmax(150px, 260px));
    column-gap: 19px;
    row-gap: 4px;
`;

interface IIsSelected {
    $isSelected?: boolean;
}

interface IGridCell {
    $rowIndex: number;
    $colIndex: number;
    $rowSpan: number;
}

export const SelectIconWrapper = styled.div<IIsSelected>`
    display: flex;
    justify-content: center;
    width: 38px;
    visibility: ${props => props.$isSelected ? "visible" : "hidden"};
    margin-left: auto;
`;

export const ElectronicSubmissionCellStyled = styled.div<IIsSelected & IGridCell>`
    position: relative;
    box-shadow: ${props => props.$isSelected ? "0 0 18px 0 rgba(0, 0, 0, 0.15)" : "0 0 5px 0 rgba(0, 0, 0, 0.05)"};
    background-color: ${props => props.theme.C_BG_row_field};
    padding: 0;
    border-radius: 3px;

    ${props => props.$isSelected && css`
        position: relative;
        z-index: 1;
    `}

    display: flex;
    grid-row: ${props => `${props.$rowIndex} / span ${props.$rowSpan}`};
    grid-column: ${props => props.$colIndex};
`;

export const CellContentWrapper = styled(Clickable)`
    position: relative;
    display: flex;
    cursor: pointer;
    color: ${props => props.theme.C_TEXT_primary};
    width: 100%;
    height: 100%;


    &:hover {
        color: ${props => props.theme.C_TEXT_primary};
        background-color: ${props => props.theme.C_ACT_hover};

        ${SelectIconWrapper} {
            visibility: visible;
            color: ${props => props.theme.C_ACT_der};
        }
    }
`;

export const ClickableContentWrapper = styled.div`
    position: absolute;
    width: 100%;
    bottom: 14px;
    display: flex;
    align-items: center;
`;

export const LockIconWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 45px;
`;

export const TitleWrapper = styled.div`
    ${T_PLAIN_small};
`;

export const RowHeaderCell = styled.div`
    ${T_HEADER_bold};
    text-align: right;
    height: ${ROW_HEIGHT}px;
    margin-right: 13px;
    display: flex;
    align-items: center;
    justify-content: end;
    grid-column: 1;
`;
