import styled, { css } from "styled-components/macro";
import DashboardTileBackground from "../../dashboard/DashboardTileBackground";
import { DashboardTileVerticalPadding } from "../../dashboard/Dashboard.styles";

export const ChartWrapperStyled = styled.div<{ $editMode: boolean }>`
    flex: 1 1 auto;
    width: 100% !important;
    height: 100%;
    margin-top: ${DashboardTileVerticalPadding}px;
    ${props => props.$editMode && css`pointer-events: none`};
`;

export const BackgroundStyled = styled(DashboardTileBackground)`
    // Chart legend has it's own little padding, so keep this small
    padding-bottom: 8px;
`;
