import styled, { css } from "styled-components/macro";
import { Theme } from "../../theme";
import { LocalStackOrder, T_BOLD_tiny, T_TITLE_4, T_TITLE_4_NORMAL } from "../../global.style";
import { ButtonGroup, IconButton } from "../button";
import EditableText from "../inputs/editableText";

export const ICON_SIZE = 38;
export const HALF_ICON_SIZE = ICON_SIZE / 2;

function getChatBorder(theme: Theme, borderStyle: "solid" | "dashed", bottom = 0) {
    return css`
        &::before {
            content: " ";
            display: block;
            border-left: 2px ${borderStyle} ${theme.C_ACT_hover_without_opacity};
            position: absolute;
            top: 18px;
            bottom: ${bottom}px;
            left: ${HALF_ICON_SIZE}px;
            z-index: ${LocalStackOrder.BelowContent};
        }
    `;
}

interface IBorder {
    _hasBorder?: boolean;
}

export const StyledChat = styled.div<{ _bottomLine: number } & IBorder>`
    position: relative;
    z-index: ${LocalStackOrder.Base};
    width: 100%;
    padding-top: 18px;

    ${props => props._hasBorder && css`
        padding-top: 36px;

        ${getChatBorder(props.theme, "dashed", props._bottomLine)}
    `};
`;

export const ToggleButton = styled(IconButton)<{ _isCollapsed: boolean }>`
    position: absolute;
    z-index: ${LocalStackOrder.AboveContent};
    top: 10px;
    left: ${HALF_ICON_SIZE + 0.5}px;
    transform-origin: center center;
    transform: translate(-50%, -50%) ${props => props._isCollapsed ? "scaleY(-1)" : ""};
`;

export const Header = styled.div<{ isVisible: boolean }>`
    position: absolute;
    top: -1px;
    left: ${1.5 * ICON_SIZE}px;
    ${T_BOLD_tiny};
    color: ${props => props.theme.C_ACT_main};
    visibility: ${props => props.isVisible ? 'visible' : 'hidden'};
`;

export const StyledMessage = styled.div<IBorder & { align?: "bottom" }>`
    position: relative;
    display: flex;
    cursor: default;
    flex: 0 0 auto;
    align-items: ${props => props.align === "bottom" ? "flex-end" : "flex-start"};
    justify-content: flex-start;

    ${props => props._hasBorder && getChatBorder(props.theme, "solid")};
`;

export const MessageStreamWrapper = styled.div<IBorder>`
    position: relative;

    ${StyledMessage} {
        padding-bottom: ${HALF_ICON_SIZE}px;

        :last-child {
            min-height: ${props => props._hasBorder ? 2 * ICON_SIZE : 0}px;
        }
    }
`;

export const MessageIconWrapper = styled.div<{ _isDisabled?: boolean } & IBorder>`
    position: relative;
    flex: 0 0 auto;
    width: ${ICON_SIZE}px;
    height: ${ICON_SIZE}px;
    margin-right: ${ICON_SIZE / 2}px;
    overflow: hidden;
    ${props => props._isDisabled && css`opacity: .5`};

    ${props => props._hasBorder && css`
        border: 2px solid ${props.theme.C_ACT_hover_without_opacity};
        border-radius: 50%;
        background-color: ${props.theme.C_BG_floorplan};
    `};

    svg {
        background-color: ${props => props.theme.C_BG_floorplan};
    }
`;

export const CenteredIcon = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    // in case 
    ${T_TITLE_4};
    text-transform: uppercase;
    color: ${props => props.theme.C_TEXT_primary};
`;

export const MessageContentWrapper = styled.div`
    ${T_TITLE_4_NORMAL};
    padding: 10px 0;
`;

export const NewMessageWrapper = styled.div`
    flex: 1 1 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`;

export const EditableTextStyled = styled(EditableText)`
    min-height: ${ICON_SIZE}px;
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    margin-right: 25px;
    position: relative;
    left: -13px;
`;

export const ButtonGroupStyled = styled(ButtonGroup)`
    margin-left: auto;
`;
