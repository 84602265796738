import React from 'react';
import { ColoredIcon, SynchronizationIcon } from "../../icon";
import { IconSize } from "../../../enums";
import { SyncSymbolStyled } from "./Field.styles";

interface IProps {
}

const SyncSymbol: React.FC<IProps> = () => {
    return (
            <SyncSymbolStyled>
                <ColoredIcon>
                    <SynchronizationIcon width={IconSize.XS} height={IconSize.XS}/>
                </ColoredIcon>
            </SyncSymbolStyled>
    );
};

export default SyncSymbol;