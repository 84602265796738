import { IPrIndividualDeductionEntity, } from "@odata/GeneratedEntityTypes";
import DeductionFormView from "@pages/payroll/deduction/DeductionFormView";

import { withPermissionContext } from "../../../../contexts/permissionContext/withPermissionContext";
import { IFormViewProps } from "../../../../views/formView/FormView";

class IndividualDeductionFormView extends DeductionFormView<IPrIndividualDeductionEntity, IFormViewProps<IPrIndividualDeductionEntity>> {
    static defaultProps = {
        title: "IndividualDeduction:Form.Title"
    };
}

export default withPermissionContext(IndividualDeductionFormView);