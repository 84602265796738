import { getDrillDownNavParams, THistoryLocation } from "@components/drillDown/DrillDown.utils";
import { IHeaderIcon } from "@components/header/Header";
import { getInfoValue, isFieldDisabled } from "@components/smart/FieldInfo";
import { ISmartFastEntriesActionEvent } from "@components/smart/smartFastEntryList";
import {
    ISmartFieldBlur,
    ISmartFieldChange,
    ISmartFieldTempDataActionArgs
} from "@components/smart/smartField/SmartField";
import { ISmartFormGroupActionEvent } from "@components/smart/smartFormGroup/SmartFormGroup";
import { IGetSmartHeaderCustomInfo } from "@components/smart/smartHeader/SmartHeader.utils";
import { ICachedFieldState } from "@odata/Data.utils";
import { getRouteByEntityType } from "@odata/EntityTypes";
import { EntityTypeName } from "@odata/GeneratedEntityTypes";
import { CompanyPermissionCode, GeneralPermissionCode } from "@odata/GeneratedEnums";
import { isPermissionErrorCode } from "@odata/ODataParser";
import { TTemporal } from "@odata/TemporalUtils";
import { tWithFallback } from "@pages/documents/Document.utils";
import { CustomerPortal } from "@pages/home/CustomerPortal.utils";
import { DRAFT_BC_PREFIX } from "@pages/PageUtils";
import { composeRefHandlers, isDefined } from "@utils/general";
import { KeyboardShortcut } from "@utils/keyboardShortcutsManager/KeyboardShorcutsManager.utils";
import { logger } from "@utils/log";
import { capitalize } from "@utils/string";
import React, { Component } from "react";

import Alert from "../../components/alert/Alert";
import BusyIndicator from "../../components/busyIndicator";
import { Button, ButtonGroup } from "../../components/button";
import SmartFormDeleteButton from "../../components/smart/smartFormDeleteButton/SmartFormDeleteButton";
import { NEW_ITEM_DETAIL } from "../../constants";
import { WithPermissionContext, withPermissionContext } from "../../contexts/permissionContext/withPermissionContext";
import { FieldType, FormMode, PageViewMode, QueryParam, Status } from "../../enums";
import { AwaitedArray } from "../../global.types";
import { ModelEvent } from "../../model/Model";
import BindingContext from "../../odata/BindingContext";
import SmartTemporalPropertyDialog from "../../pages/payroll/SmartTemporalPropertyDialog";
import { AUDIT_TRAIL } from "../../routes";
import { getQueryParameters } from "../../routes/Routes.utils";
import DateType from "../../types/Date";
import KeyboardShortcutsManager from "../../utils/keyboardShortcutsManager/KeyboardShortcutsManager";
import { NoPermission } from "../notFound";
import Form, { generateComparisonProps, IProps } from "./Form";
import { focusFirstInputField, getAlertFromError, setBreadCrumbs } from "./Form.utils";
import {
    FormStorage,
    IContextInitArgs,
    IFormStorageDefaultCustomData,
    IFormStorageSaveResult,
    ISaveArgs
} from "./FormStorage";

enum SpecialHeaderIcon {
    Settings = "Settings",
    AuditTrail = "AuditTrail"
}

export interface ITableRefreshNeeded {
    refreshRowOnly?: boolean;
    rowBc?: BindingContext;
    modelRefreshOnly?: boolean;
}

export interface IFormViewProps<E, C extends IFormStorageDefaultCustomData = IFormStorageDefaultCustomData> extends WithPermissionContext {
    storage: FormStorage<E, C>;
    style?: React.CSSProperties;
    onCancel?: () => void;
    onBeforeSave?: () => void;
    onSaveFail?: () => void;
    onAfterSave?: (refresh: boolean, preventTableViewRefresh?: boolean) => void;
    onGroupExpand?: (isExpanded: boolean, id: string) => void;
    onTableRefreshNeeded?: (args?: ITableRefreshNeeded) => void;
    onOpenNextDraft?: () => void;
    // switch page to "new" entity state, just like when "+" button is clicked above table
    onAdd?: (type?: string) => void;
    passRef?: React.Ref<HTMLDivElement>;
    ref?: React.Ref<FormView<any>>;
    title?: string;
    hideButtons?: boolean;
    pageViewMode?: PageViewMode;
    formProps?: Partial<IProps>;
    skipLoad?: boolean;
    // FormView rendered in dialog has bit different alert behavior
    // - should not be shown for field validations
    // - isSmall should be used
    isInDialog?: boolean;
}

class FormView<E, P extends IFormViewProps<E> = IFormViewProps<E>, S = Record<string, unknown>> extends Component<P, S> {
    _refScroll = React.createRef<HTMLElement>();
    _refFormContent = React.createRef<HTMLElement>();
    _refHeader = React.createRef<any>();
    _refView = React.createRef<HTMLDivElement>();
    _isMounted: boolean;
    _hideBusyIndicator = false;

    _unsubscribeKeyboardShortcuts: () => void;

    constructor(props: P) {

        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleLineItemsChange = this.handleLineItemsChange.bind(this);
        this.handleFieldStateChange = this.handleFieldStateChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.save = this.save.bind(this);
        this.onAfterLoad = this.onAfterLoad.bind(this);
        this.onAfterSave = this.onAfterSave.bind(this);
        this.onBeforeSave = this.onBeforeSave.bind(this);
        this.renderForm = this.renderForm.bind(this);
        this.renderButtons = this.renderButtons.bind(this);
        this.shouldHideButtons = this.shouldHideButtons.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleKeyboardShortcut = this.handleKeyboardShortcut.bind(this);
        this.getFormSubtitle = this.getFormSubtitle.bind(this);
        this.renderTemporalPropertyDialog = this.renderTemporalPropertyDialog.bind(this);
        this.handleSmartTemporalPropertyDialogChange = this.handleSmartTemporalPropertyDialogChange.bind(this);

        this._unsubscribeKeyboardShortcuts = KeyboardShortcutsManager.subscribe({
            shortcuts: [KeyboardShortcut.ALT_D, KeyboardShortcut.ALT_C, KeyboardShortcut.ALT_S, KeyboardShortcut.ALT_SHIFT_S],
            callback: this.handleKeyboardShortcut
        });
    }

    shouldHideButtons(): boolean {
        return CustomerPortal.isActive;
    }

    componentDidMount(): void {
        this._isMounted = true;
        this.props.storage.emitter.on(ModelEvent.AfterLoad, this.onAfterLoad);
        this.props.storage.getAdditionalLoadPromise = this.getAdditionalLoadPromise;
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.props.storage.emitter.off(ModelEvent.AfterLoad, this.onAfterLoad);
        this._unsubscribeKeyboardShortcuts();
    }

    shouldComponentUpdate(nextProps: Readonly<P>, nextState: Readonly<S>): boolean {
        // this form is updated only directly using force update
        return false;
    }

    get shouldShowBreadCrumbs(): boolean {
        return !this.props.formProps?.hideBreadcrumbs;
    }

    get entity(): E {
        return this.props.storage.getEntity<E>();
    }

    get isEntityReady(): boolean {
        return this.props.storage.loaded && !this.props.storage.loading && this.props.storage.afterLoaded;
    }

    /** Can be overriden*/
    getFormProps = (): Partial<IProps> => {
        return this.props.formProps;
    };

    getReadOnlyNavButtonText = (): string => {
        return tWithFallback(this.props.storage.data.definition?.translationFiles?.[0], "Form.OpenFormAction", "Common");
    };

    // render Edit button for FormReadOnly
    // otherwise call renderButtons method, which can be overridden by inherited form views
    renderButtonsByContext = (): React.ReactElement => {
        const viewMode = this.props.storage.pageViewMode;

        if (this.shouldHideButtons()) {
            return null;
        }

        const hasPermission = (permissions: string[]) => {
            return permissions.some(p => this.props.permissionContext.companyPermissions.has(p as CompanyPermissionCode) || this.props.permissionContext.generalPermissions.has(p as GeneralPermissionCode));
        };

        if (viewMode === PageViewMode.FormReadOnly) {
            // try to find route instead of just reusing the current one
            // (FormView could be inside DialogPage)
            // add more routes into getRouteByEntityType if not working for your case
            const route = getRouteByEntityType(this.props.storage.data.bindingContext.getEntityType().getName() as EntityTypeName);
            const search = getQueryParameters({ exclude: [QueryParam.ViewMode] });

            const key = this.props.storage.data.bindingContext.getKey();
            const entityKey = key.toString().startsWith(DRAFT_BC_PREFIX) ? NEW_ITEM_DETAIL : key;
            let path = route ? `${route}/${entityKey}` : window.location.pathname;

            if (search) {
                path += "?" + new URLSearchParams(search).toString();
            }

            if (this.props.storage.data.definition?.permissions && !hasPermission(this.props.storage.data.definition?.permissions)) {
                return null;
            }

            return (
                <ButtonGroup wrap={"wrap"}>
                    <Button
                        isTransparent
                        link={path}
                    >
                        {this.getReadOnlyNavButtonText()}
                    </Button>
                </ButtonGroup>
            );
        }

        return this.renderButtons();
    };

    handleFormCancel = (): void => {
        this.props.onCancel?.();
    };

    canProcessShortcuts = () => {
        if (!this.isEntityReady) {
            return false; // no shortcuts when entity is not yet ready
        }

        if (this.props.storage.pageViewMode === PageViewMode.FormReadOnly) {
            return false;
        }

        return true;
    };

    handleKeyboardShortcut(shortcut: KeyboardShortcut, event: KeyboardEvent): boolean {
        const { storage } = this.props;

        if (!this.canProcessShortcuts()) {
            return false;
        }

        switch (shortcut) {
            // copy date from focused date to all other date fields in the same form group
            case KeyboardShortcut.ALT_D:
                // 1.find field definition by the data-name
                // 2.if the field is a date field, find all other non-disabled date fields in the form group
                // 3.propagate field value to those fields
                if (document.activeElement.tagName.toUpperCase() !== "INPUT") {
                    return true;
                }

                const fieldName = document.activeElement.getAttribute("data-name");
                const fieldInfo = storage.getInfo(storage.data.bindingContext.navigate(fieldName));

                if (!fieldInfo || fieldInfo.type !== FieldType.Date) {
                    return true;
                }

                const value = storage.getValueByPath(fieldName);

                if (!DateType.isValid(value)) {
                    return true;
                }

                const formGroup = storage.getVariant().find(group => group.rows.find(row => row.find(def => def.id === fieldName)));
                const otherDateFields: string[] = [];

                for (const row of formGroup.rows) {
                    for (const def of row) {
                        const otherFieldBc = storage.data.bindingContext.navigate(def.id);
                        const otherFieldInfo = storage.getInfo(otherFieldBc);

                        if (otherFieldInfo.type === FieldType.Date && otherFieldInfo.id !== fieldName && !isFieldDisabled(otherFieldInfo, storage, otherFieldBc)) {
                            otherDateFields.push(otherFieldInfo.id);
                        }
                    }
                }

                for (const otherDateField of otherDateFields) {
                    this.handleChange({
                        bindingContext: this.props.storage.data.bindingContext.navigate(otherDateField),
                        value,
                        parsedValue: value,
                        groupId: formGroup.id,
                        triggerAdditionalTasks: true,
                        type: FieldType.Date
                    });
                }

                return true;
            // make a copy, handled on DocumentFormView, the only form with copy functionality
            case KeyboardShortcut.ALT_C:
                return false;
            // "press" save button
            case KeyboardShortcut.ALT_S:
                if (!this.shouldDisableSaveButton()) {
                    this.handleSaveClick();
                    return true;
                }
                return false;
        }

        return false;
    }

    get isDeletable(): boolean {
        return !this.props.storage.data.bindingContext.isNew() && !!getInfoValue(this.props.storage.data.definition, "isDeletable", {
            storage: this.props.storage,
            context: this.context
        });
    }

    /**
     * Can be used to stop user from deleting entity, e.g. by showing confirmation dialog.
     * to be overridden in extended classes
     * @returns boolean shouldAllow - true to allow removal, false to prevent it
     * */
    async onBeforeDelete(): Promise<boolean> {
        return true;
    }

    async handleDelete(): Promise<void> {
        const allowDelete = await this.onBeforeDelete();

        if (!allowDelete) {
            return;
        }

        const storage = this.props.storage;
        const isNew = storage.data.bindingContext.isNew();
        let isOk = true;

        if (!isNew) {
            storage.setBusy(true);

            try {
                const editOverride = storage.getEditOverride();
                const setWrapper = storage.oData.fromPath(storage.data.bindingContext.removeKey().getFullPath());

                if (editOverride) {
                    setWrapper.queryParameters({
                        [editOverride.id]: "true"
                    });
                }

                await setWrapper.delete(storage.data.bindingContext.getKey());
            } catch (e) {
                isOk = false;
                storage.setFormAlert(getAlertFromError(e));
                this.scrollPageUp();
            }

            storage.setBusy(false);
        }

        if (isOk) {
            this.props.onCancel?.();
            !isNew && this.props.onTableRefreshNeeded();
        }
    }

    shouldDisableSaveButton = (): boolean => {
        return this.props.storage.isDisabled;
    };

    shouldDisableCancelButton = (): boolean => {
        return this.props.storage.isDisabled || (!this.props.storage.data.status?.isChanged && !this.props.storage.data.bindingContext.isNew());
    };

    renderButtons(): React.ReactElement {
        const isFormReadOnly = this.props.storage.isReadOnly;

        return (
            <ButtonGroup wrap={"wrap"}>
                <Button hotspotId={"formCancel"}
                        isDisabled={this.props.storage.isDisabled || this.shouldDisableCancelButton()}
                        onClick={this.handleFormCancel}
                        isTransparent>{this.props.storage.t("Common:General.Cancel")}</Button>
                {this.isDeletable &&
                    <SmartFormDeleteButton storage={this.props.storage}
                                           onClick={this.handleDelete}
                                           key={this.props.storage.data.uuid}/>
                }
                {!isFormReadOnly &&
                    <Button hotspotId={"formSave"}
                            onClick={this.handleSaveClick}
                            isDisabled={this.shouldDisableSaveButton()}>
                        {this.props.storage.t("Common:General.Save")}
                    </Button>
                }
            </ButtonGroup>
        );
    }

    scrollPageDown = (): void => {
        if (!this._refScroll.current) {
            return;
        }

        this._refScroll.current.scrollTop = this._refScroll.current.scrollHeight;
    };

    scrollPageUp = (): void => {
        if (!this._refScroll.current) {
            return;
        }

        this._refScroll.current.scrollTop = 0;
        // when we are scrolling up, set also focus to first element, so user has correct context even if works with keyboard
        focusFirstInputField(this._refFormContent.current);
    };

    /* Can be overridden in inherited form views */
    setBreadcrumbs = (): void => {
        if (!this.shouldShowBreadCrumbs) {
            return;
        }
        this.props.storage.data.definition.getItemBreadCrumbText && setBreadCrumbs(this.props.storage as unknown as FormStorage);
    };

    fireFormLoadedEvent = (): void => {
        // fire event that can be easily handled in cypress tests
        // to wait for form to be loaded
        setTimeout(() => {
            this._refView.current?.dispatchEvent(
                new CustomEvent("formLoaded", { bubbles: true })
            );
        });
    };

    onAfterLoad(): Promise<void> {
        this.setBreadcrumbs();
        // call refreshSync to ensure "refreshPage" is going to be used,
        // otherwise, _.debounce will call refresh with the last parameters,
        // and there can be some inherited form views that calls refresh without "refreshPage"
        this.props.storage.refreshSync(true);
        this.fireFormLoadedEvent();

        return Promise.resolve();
    }

    getAdditionalLoadPromise = (args: IContextInitArgs): (Promise<unknown> | void)[] => {
        return undefined;
    };

    // todo this returns FormView interface, meaning the return type has to overidden in inheriting classes
    // can the return value be defined in generic way?
    getAdditionalResults(): AwaitedArray<ReturnType<FormView<E, P, S>["getAdditionalLoadPromise"]>> {
        return this.props.storage.data.additionalResults;
    }

    // for overload in heirs
    onBeforeSave(data?: E): E {
        return undefined;
    }

    // todo rework "save" methods in extended form views to use super.save instead of handling everything redundantly
    async save(saveArgs?: Partial<ISaveArgs>): Promise<IFormStorageSaveResult> {
        let data;
        try {
            this.props.onBeforeSave?.();
            data = this.onBeforeSave();
        } catch (e) {
            this.props.storage.setFormAlert({
                status: Status.Error,
                title: this.props.storage.t("Common:General.FormValidationErrorTitle"),
                subTitle: e.message
            });
            this.props.onSaveFail?.();
            this.forceUpdate(this.scrollPageUp);
            return null;
        }

        const isNew = this.props.storage.data.bindingContext.isNew();

        const result = await this.props.storage.save({
            data,
            successSubtitle: tWithFallback(this.props.storage.data.definition?.translationFiles?.[0], "Validation.Saved", "Common"),
            fieldValidationWithoutErrorAlert: !!this.props.isInDialog,
            skipLoad: this.props.skipLoad,
            ...saveArgs
        });

        if (!this._isMounted) {
            return null;
        }

        if (!result) {
            this.props.onSaveFail?.();
            this.forceUpdate(this.scrollPageUp);
            return result;
        }

        this.onAfterSave(isNew, false);
        this.forceUpdate(isNew ? this.scrollPageDown : undefined);

        return result;
    }

    handleSaveClick = (): void => {
        this.save();
    };

    onAfterSave(isNew: boolean, preventTableViewRefresh: boolean): void {
        this.props.onAfterSave?.(isNew, preventTableViewRefresh);
    }

    async handleBlur(args: ISmartFieldBlur): Promise<void> {
        await this.props.storage.handleBlur(args);
        this.props.storage.refreshFields();
    }

    handleRemove = (bc: BindingContext): void => {
        this.props.storage.handleRemove(bc);
        this.props.storage.refresh();
    };

    handleTemporalChange = (e: ISmartFieldChange): void => {
        this.props.storage.handleTemporalChange(e);
        this.props.storage.refreshFields();
    };

    handleConfirm = (args: ISmartFieldTempDataActionArgs): void => {
        this.props.storage.handleConfirm(args, this.handleChange)
            .then(() => this.props.storage.refreshFields());
    };

    handleCancel = (args: ISmartFieldTempDataActionArgs): void => {
        this.props.storage.handleCancel(args)
            .then(() => this.props.storage.refreshFields());
    };

    handleChange(e: ISmartFieldChange): void {
        this.props.storage.handleChange(e);

        // triggerAdditionalTasks === true for select like components has same
        // meaning as triggerAdditionalTasks === undefined for the rest types
        const shouldUpdate = e.triggerAdditionalTasks !== false;
        this.props.storage.refreshFields(shouldUpdate);
    }

    handleFieldStateChange(bc: BindingContext, state: ICachedFieldState, prevState: ICachedFieldState): void {
        this.props.storage.handleFieldStateChange(bc, state, prevState);
    }

    handleLineItemsChange(args: ISmartFieldChange): void {
        this.props.storage.handleLineItemsChange(args);
        this.props.storage.refreshFields();
    }

    handleLineItemsAction = (args: ISmartFastEntriesActionEvent): void => {
        this.props.storage.handleLineItemsAction(args);
        this.props.storage.refresh();
    };

    handleGroupAction = (args: ISmartFormGroupActionEvent): void => {
        // Custom implementation is needed for group actions
    };


    get isAuditTrail(): boolean {
        return this.props.storage.formMode === FormMode.AuditTrail;
    }

    shouldShowAuditTrailIcon = (): boolean => {
        return !this.getFormProps()?.shouldHideAuditTrail
            && this.props.storage instanceof FormStorage && !this.isAuditTrail;
    };

    getAuditTrailLink = (): THistoryLocation => {
        const link = `${window.location.pathname}/${AUDIT_TRAIL}`;

        // use getDrillDownNavParams to propagate correct breadcrumbs via browser state
        // used e.g. in CompanySetupWizard
        return getDrillDownNavParams({ route: link, context: this.props.storage.context, storage: this.props.storage });
    };

    handleSettingsClick = (): void => {
        this.props.storage?.setCustomData({
            isCustomizationDialogOpen: true
        });
        this.props.storage?.refresh();
    };

    // can be override in extended classes
    async handleCustomHeaderAction(actionId: string): Promise<void> {
        return undefined;
    }

    getHeaderIcons(): IHeaderIcon[] {
        const isNew = this.props.storage?.data?.bindingContext?.isNew();
        const icons: IHeaderIcon[] = [];

        if (this.props.storage instanceof FormStorage && !this.getFormProps()?.shouldHideVariant) {
            icons.push({
                // hotspotId is built from id and we need it to be different from table settings button
                id: `${SpecialHeaderIcon.Settings}-form`,
                label: capitalize(this.props.storage.t("Common:Form.Customize")),
                iconName: "Settings",
                onClick: this.handleSettingsClick,
                ignoreMasterDisabled: true
            });
        }

        if (this.shouldShowAuditTrailIcon()) {
            if (!this.props.permissionContext) {
                logger.error("permissionContext missing in FormView, wrap current form view in withPermissionContext");
            }
            icons.push({
                id: SpecialHeaderIcon.AuditTrail,
                label: this.props.storage.t("Common:Form.AuditTrail"),
                iconName: "AuditTrail",
                isDisabled: isNew || !this.props.permissionContext?.generalPermissions?.has(GeneralPermissionCode.AuditTrail),
                link: this.getAuditTrailLink(),
                ignoreMasterDisabled: true
            });
        }

        if (!this.getFormProps()?.isSimple) {
            const customHeaderActions = this.props.storage.data.definition.customHeaderActions ?? [];

            for (const customHeaderAction of customHeaderActions) {
                const infoValArgs = { storage: this.props.storage };

                if (!isDefined(customHeaderAction.isVisible) || getInfoValue(customHeaderAction, "isVisible", infoValArgs)) {
                    const { isVisible, ...passProps } = customHeaderAction;
                    icons.push({
                        ...passProps,
                        onClick: this.handleCustomHeaderAction.bind(this, customHeaderAction.id),
                        isDisabled: (isDefined(customHeaderAction.isDisabled) && getInfoValue(customHeaderAction, "isDisabled", infoValArgs))
                            || this.props.storage.data.disabled
                    });
                }
            }
        }

        return icons;
    }

    // to be overridden in extended FormView
    getCustomHeaderInfo = (): IGetSmartHeaderCustomInfo => {
        return null;
    };

    handleGroupExpand = (isExpanded: boolean, id: string): void => {
        this.props.onGroupExpand?.(isExpanded, id);
    };

    isCopy = () => {
        return this.props.storage.getCustomData().isCopy;
    };

    getFormTitle = (): string => {
        return this.props.storage.t(this.props.title);
    };

    getFormSubtitle(): string {
        if (this.isCopy()) {
            return this.props.storage.t("Common:General.CopyOf");
        }

        return "";
    }

    getFormSubtitleStatus = (): Status => {
        if (this.isCopy()) {
            return Status.Warning;
        }

        return null;
    };

    /** Can be overridden */
    handleSmartTemporalPropertyDialogChange(args: ISmartFieldChange): void {
        //
    }

    renderTemporalPropertyDialog(onClose?: (current?: TTemporal) => void): React.ReactElement {
        const temporalPropertyDialogBc = this.props.storage.getCustomData().temporalPropertyDialogBc;

        if (!temporalPropertyDialogBc) {
            return null;
        }
        // usually it's same as storage.data.bindingContext, but for temporal LineItems it is BC of current item
        const temporalItemBaseBc = temporalPropertyDialogBc.getParent().getParent();

        return (
            <>
                {temporalPropertyDialogBc &&
                    <SmartTemporalPropertyDialog storage={this.props.storage as unknown as FormStorage}
                                                 onChange={this.handleSmartTemporalPropertyDialogChange}
                                                 onClose={onClose}
                                                 bindingContext={temporalPropertyDialogBc}
                                                 temporalItemBindingContext={temporalItemBaseBc}/>
                }
            </>
        );
    }

    renderFooter = (): React.ReactElement => {
        const isComparison = this.props.storage?.formMode === FormMode.AuditTrail;

        return <>
            {!isComparison && !this.props.hideButtons && this.renderButtonsByContext()}
        </>;
    };

    renderForm(): React.ReactElement {

        return (<>
            <Form
                refHeader={this._refHeader}
                storage={this.props.storage.getThis()}
                // busy indicator is rendered on Dialog instead
                hideBusyIndicator={this.props.isInDialog}
                addRootElementContext={!this.props.isInDialog}
                refScroll={this._refScroll}
                refContent={this._refFormContent}
                headerIcons={this.getHeaderIcons()}
                getCustomHeaderInfo={this.getCustomHeaderInfo}
                {...generateComparisonProps(this.props)}
                passRef={composeRefHandlers(this._refView, this.props.passRef)}
                title={this.getFormTitle()}
                subtitle={this.getFormSubtitle()}
                subtitleStatus={this.getFormSubtitleStatus()}
                onGroupExpand={this.handleGroupExpand}
                onBlur={this.handleBlur}
                onCancel={this.handleCancel}
                onRemove={this.handleRemove}
                onLineItemChange={this.handleLineItemsChange}
                onLineItemAction={this.handleLineItemsAction}
                onGroupAction={this.handleGroupAction}
                onConfirm={this.handleConfirm}
                {...this.getFormProps()}
                onChange={this.handleChange}
                onFieldStateChange={this.handleFieldStateChange}
                onTemporalChange={this.handleTemporalChange}
                smallErrorAlert={!!this.props.isInDialog}
                editOverride={this.props.storage.getEditOverride()}>
                    {this.renderFooter()}
            </Form>
            {this.renderTemporalPropertyDialog()}
        </>);
    }

    isReady = (): boolean => {
        return this.props.storage.loaded && !!this.props.storage.data.bindingContext;
    };

    renderCustomDialogs(): React.ReactElement {
        return null;
    }

    render() {
        // in case storage is not loaded, we can't render form, but it's not loading either,
        // so there was some error during the load
        if (!this.props.storage.loaded && !this.props.storage.loading && this.props.storage.data?.alert) {
            const { position, ...alertProps } = this.props.storage.data.alert;
            if (isPermissionErrorCode(alertProps?.detailData?._code)) {
                return (<NoPermission/>);
            }
            // possibly error happened during the load, present it to the user
            // not nice, but better than infinite loading
            return (<Alert {...alertProps} />);
        }

        if (!this.isReady()) {
            if (this._hideBusyIndicator) {
                // hide busy indicator if needed eg. in dialog or elsewhere where is already handled by parent component
                return null;
            }
            return <BusyIndicator isDelayed={this.props.storage.shouldDelayInitialBusy}/>;
        }

        return (<>
            {this.renderForm()}
            {this.renderCustomDialogs()}
        </>);
    }
}

export default withPermissionContext(FormView);
export { FormView as FormViewForExtend };