import { withConfirmationDialog, WithConfirmationDialog } from "@components/dialog/withConfirmationDialog";
import { ISmartFieldChange } from "@components/smart/smartField/SmartField";
import { AppContext } from "../../../../contexts/appContext/AppContext.types";
import {
    EntitySetName,
    IDeferredPlanEntity,
    IDeferredPlanOriginalDocumentItemEntity,
    IJournalEntryEntity
} from "@odata/GeneratedEntityTypes";
import { cloneDeep } from "lodash";
import BindingContext from "../../../../odata/BindingContext";
import { getDeferredItems } from "./TimeResolution.utils";
import DialogFormView, { IDialogFormViewProps } from "../../../../views/formView/DialogFormView";
import { ValidationErrorType } from "../../../../enums";
import { getEntitySetByDocumentType } from "@odata/EntityTypes";
import { DocumentTypeCode, VatStatementStatusCode } from "@odata/GeneratedEnums";
import { getActiveChartOfAccountsId } from "@pages/fiscalYear/FiscalYear.utils";
import { withPermissionContext } from "../../../../contexts/permissionContext/withPermissionContext";
import { getUtcDayjs } from "../../../../types/Date";

interface IProps extends IDialogFormViewProps<IDeferredPlanEntity>, WithConfirmationDialog {

}

class TimeResolutionDialogFormView extends DialogFormView<IDeferredPlanEntity, IProps> {
    static contextType = AppContext;

    onAfterLoad = async () => {

        const items: IDeferredPlanOriginalDocumentItemEntity[] = this.props.storage.data.entity.OriginalDocumentItems;

        const deferredDocuments = (await this.props.storage.oData.getEntitySetWrapper(EntitySetName.JournalEntries).query()
            .filter(`DocumentItem/Id in (${items.map(item => item.OriginalDocumentItem.Id).join(", ")}) AND not(startswith(CreditAccount/Number, '343')) AND not(startswith(DebitAccount/Number, '343'))`)
            .fetchData<IJournalEntryEntity[]>()).value;
        const fullAmount = deferredDocuments.reduce((sum, item) => {
            return sum + item.Amount;
        }, 0);

        this.props.storage.setValue(this.props.storage.data.bindingContext.navigate(BindingContext.localContext("fullAmount")), fullAmount);
        this.props.storage.refresh();
        return super.onAfterLoad();
    };


    handleChange = (e: ISmartFieldChange) => {
        super.handleChange(e);

        this.handleDateChange(e);
    };

    handleDateChange = (e: ISmartFieldChange) => {
        if (!["DateStart", "DateEnd"].includes(e.bindingContext.getPath())) {
            return;
        }

        const dateStart = this.props.storage.data.entity.DateStart;
        const dateEnd = this.props.storage.data.entity.DateEnd;

        if (dateStart && dateEnd && getUtcDayjs(dateStart).isSameOrAfter(dateEnd, "date")) {
            this.props.storage.setErrorByPath("DateEnd", {
                errorType: ValidationErrorType.Field,
                message: this.props.storage.t("TimeResolution:Form.DateEndSmall")
            });
        } else if (dateEnd) {
            this.props.storage.clearErrorByPath("DateEnd");
        }
    };

    onBeforeSave = (): IDeferredPlanEntity => {
        const deferredPlan = cloneDeep(this.props.storage.data.entity as IDeferredPlanEntity);
        const items = getDeferredItems(deferredPlan, this.props.storage.context.getCompany());
        const entitySet = getEntitySetByDocumentType(deferredPlan.OriginalDocument.DocumentTypeCode as DocumentTypeCode);
        const entityTypeName = this.props.storage.oData.getMetadata().getTypeForPath(entitySet).getName();

        deferredPlan.ChartOfAccounts = { Id: getActiveChartOfAccountsId(this.context) };
        deferredPlan.Company = { Id: this.context.getCompany().Id };
        deferredPlan.DeferredDocuments = items.map(item => {
            return {
                Amount: item.amount,
                DateAccountingTransaction: item.dateAccountingTransaction
            };
        });

        // prevent saving problem - OriginalDocument looks like Document, not InvoiceReceived => bc navigation errors
        // only keep necessary properties
        deferredPlan.OriginalDocument = {
            Id: deferredPlan.OriginalDocument.Id,
            _metadata: {
                "": { type: `#Solitea.Evala.DomainModel.${entityTypeName}` }
            }
        };

        const originalDocument = this.props.storage.data.entity.OriginalDocument;
        const withoutVat = originalDocument.VatStatementStatusCode === VatStatementStatusCode.N_A;

        deferredPlan.OriginalDocumentItems = deferredPlan.OriginalDocumentItems.map((item, index) => {
            return BindingContext.createNewEntity(index, {
                OriginalDocumentItem: {
                    Id: item.OriginalDocumentItem.Id,
                    _metadata: {
                        "": { type: `#Solitea.Evala.DomainModel.${entityTypeName === DocumentTypeCode.InternalDocument ? "Internal" : "Regular"}DocumentItem` }
                    }
                },
                Amount: withoutVat ? item?.OriginalDocumentItem?.Amount : item?.OriginalDocumentItem?.AmountNet
            });
        });

        return deferredPlan;
    };
}

export default withConfirmationDialog(withPermissionContext(TimeResolutionDialogFormView));