import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import { AppContext } from "../../../contexts/appContext/AppContext.types";
import { IDashboardTileComponentProps } from "../../dashboard";
import { EvalaPlainIcon } from "../../icon";
import { Heading, Role, StyledWelcomeTile, UserName, WelcomeAvatar, WelcomeAvatarSize } from "./Welcome.styles";

const Welcome: React.FC<IDashboardTileComponentProps> = ({ inEditMode }) => {
    const { t } = useTranslation("Home");
    const context = useContext(AppContext);

    const { Name, GeneralRoles } = context.getData().userSettings;
    const defaultRole = (GeneralRoles ?? []).find(role => role.GeneralRole.IsDefaultRole) ?? GeneralRoles?.[0];

    return (
        <StyledWelcomeTile inEditMode={inEditMode}>
            <WelcomeAvatar>
                <EvalaPlainIcon width={`${WelcomeAvatarSize}px`} height={`${WelcomeAvatarSize}px`}/>
            </WelcomeAvatar>
            <Heading>{t("Home:Welcome.Heading")},</Heading>
            <UserName>{Name}</UserName>
            <Role>{defaultRole?.GeneralRole?.GeneralRoleName}</Role>
        </StyledWelcomeTile>
    );
};

export default Welcome;