import { Status } from "../../enums";
import { BackgroundJobStatusCode } from "@odata/GeneratedEnums";
import { IBackgroundJobEntity } from "@odata/GeneratedEntityTypes";

export enum ProgressStatus {
    Progress = "Progress"
}

export type TProgressStatus = Status | ProgressStatus;

export const convertJobStatusToProgressStatus = (jobStatus: BackgroundJobStatusCode): TProgressStatus => {
    switch (jobStatus) {
        case BackgroundJobStatusCode.Finished:
            return Status.Success;
        case BackgroundJobStatusCode.PendingUserInput:
        case BackgroundJobStatusCode.FinishedWithWarning:
            return Status.Warning;
        case BackgroundJobStatusCode.Error:
            return Status.Error;
        case BackgroundJobStatusCode.Running:
            return ProgressStatus.Progress;
        default:
            return null;
    }
};

export const isJobFinished = (job: IBackgroundJobEntity) => {
    return job.StatusCode === BackgroundJobStatusCode.Finished || job.StatusCode === BackgroundJobStatusCode.Error
        || job.StatusCode === BackgroundJobStatusCode.Canceled;
}