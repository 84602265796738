import styled from "styled-components/macro";
import Checkbox from "../checkbox/Checkbox";
import { CheckboxLabel } from "../checkbox/Checkbox.styles";

interface ICheckboxGroup {
    numColumns: number;
    numItems: number;
    canHaveOrphans: boolean;
}

export const CheckboxGroupWrapper = styled.div`
    position: relative;
    width: 100%;
`;

export const StyledCheckboxGroup = styled.div.attrs((props: ICheckboxGroup) => ({}))<ICheckboxGroup>`
    width: fit-content;
    display: flex;

    & > div:last-child {
        margin-right: 0;
    }
`;

export const CheckboxGroupColumn = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 24px;

    & > div:last-child {
        margin-bottom: 0;
    }
`;

export const CheckboxStyled = styled(Checkbox)<{
    _isBold?: boolean;
    bigBottomMargin?: boolean;
}>`
    margin-bottom: ${props => props.bigBottomMargin ? "42px" : "12px"};
    
    ${CheckboxLabel} {
        font-weight: ${props => props._isBold ? "bold" : "normal"};
    }
`;