import { IRow, IRowValues, ISort } from "@components/table";
import { IToString, TValue } from "../../../global.types";
import { ICompanyEntity } from "@odata/GeneratedEntityTypes";
import { sortCompareFn } from "@utils/general";
import Clickable from "@components/clickable";
import React from "react";
import { getCompanyLogoUrl } from "@utils/CompanyUtils";
import styled from "styled-components/macro";
import { ROUTE_HOME } from "../../../routes";

export interface ILinkedPagesState {
    sort: ISort[];
    companies?: ICompanyEntity[];
}

export interface ILinkedPagesSortArgs {
    companyColumnId: string;
    companies: ICompanyEntity[];
    sort: ISort[];
    getValueForSort: (company: ICompanyEntity, column: IToString) => TValue;
    getFormattedValues: (companyId: number) => IRowValues;
}

const ImageRoundWrapper = styled.div`
    border-radius: 50%;
    background-color: ${props => props.theme.C_AVATAR_BG_LIGHT};
    width: 20px;
    height: 20px;
    display: flex;

    align-items: center;
    justify-content: center;
`;

const IconCompanyLink = styled.span`
    margin-left: 5px;
    font-weight: bold;
`;

const LinkBody = styled.div`
    display: flex;
    align-items: center;
`;


const getCompanyLink = (company: ICompanyEntity) => {
    return (
        <Clickable
            companyId={company.Id}
            link={ROUTE_HOME}>
            <LinkBody>
                <ImageRoundWrapper>
                    <img width="16" src={getCompanyLogoUrl(company)} alt={company.Name}/>
                </ImageRoundWrapper>
                <IconCompanyLink>{company.Name}</IconCompanyLink>
            </LinkBody>
        </Clickable>
    );
};

export const getSortedRows = (args: ILinkedPagesSortArgs): IRow[] => {
    const { companyColumnId, companies, sort, getFormattedValues, getValueForSort } = args;
    const sortBy = sort[0];

    const key = sortBy.id;

    const _getValueForSort = (company: ICompanyEntity): TValue => {
        if (companyColumnId === key) {
            return company.Name;
        }
        return getValueForSort(company, key);
    };

    const sortedCompanies = [...companies].sort((compA, compB) => {
        const valueA = _getValueForSort(compA);
        const valueB = _getValueForSort(compB);
        return sortCompareFn(valueA, valueB, sortBy.sort);
    });

    const rows: IRow[] = sortedCompanies.map(company => ({
        id: company.Id,
        values: {
            company: {
                tooltip: company.Name,
                value: getCompanyLink(company)
            },
            ...getFormattedValues(company.Id)
        }
    }));

    return rows;
};
