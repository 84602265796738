import React, { ReactElement } from "react";
import {
    ActionLabel,
    StyledFormGroupAction,
    StyledFormGroupActions,
    StyledFormGroupActionWrapper
} from "./FormGroupActions.style";
import { getIcon } from "../icon";
import { IconSize } from "../../enums";
import { IconButton } from "../button";
import TestIds from "../../testIds";

export interface IFormGroupAction {
    id: string;
    iconName: string;
    label: string;
    isDisabled?: boolean;
    ignoreTheme?: boolean;
}

interface IProps {
    actions: IFormGroupAction[]
    selectedAction?: string;
    isTransparent?: boolean;
    onActionClick?: (id: string) => void;
    className?: string;
    ignoreTheme?: boolean;
}

interface IState {
    maxWidths: Record<string, number>;
    isMouseOver: Record<string, boolean>;
}

class FormGroupActions extends React.PureComponent<IProps, IState> {
    state: IState = {
        maxWidths: {},
        isMouseOver: {}
    };

    rootRef = React.createRef<HTMLDivElement>();

    componentDidMount() {
        this.initMaxWidths();
    }

    // we need to get scrollWidth of each item
    // because transition/animation on width: auto doesn't work
    initMaxWidths = (): void => {
        const maxWidths: Record<string, number> = {};

        for (let i = 0; i < this.props.actions.length; i++) {
            const action = this.props.actions[i];
            const element = this.rootRef.current.children[i];

            maxWidths[action.id] = element.clientWidth + element.children[1].scrollWidth + 12 /*for margin*/;
        }

        this.setState({ maxWidths });
    };

    handleMouseEnter = (actionId: string): void => {
        this.setState({
            isMouseOver: {
                [actionId]: true
            }
        });
    };

    handleMouseLeave = (actionId: string): void => {
        this.setState({
            isMouseOver: {
                ...this.state.isMouseOver,
                [actionId]: false
            }
        });
    };

    renderActions = (): ReactElement[] => {
        return this.props.actions.map((action, index) => {
            const Icon = getIcon(action.iconName);
            const isSelected = this.props.selectedAction === action.id;
            const isOtherSelected = this.props.selectedAction && !isSelected;
            const isDisabled = action.isDisabled || isOtherSelected;
            const onClickFn = () => {
                const div = this.rootRef.current.children[index] as HTMLDivElement;
                // stop the current transition to enforce transitionDelay change
                div.style.maxWidth = getComputedStyle(div).width;
                div.style.transition = "none";
                div.style.transitionDelay = "0ms";

                setTimeout(() => {
                    div.style.maxWidth = null;
                    div.style.transition = null;
                    div.style.transitionDelay = null;
                });

                this.props.onActionClick(action.id);
            };

            return (
                <StyledFormGroupActionWrapper
                    key={action.id}
                    isSelected={isSelected}
                    isDisabled={isDisabled}
                    isMouseOver={this.state.isMouseOver[action.id]}
                    maxWidth={this.state.maxWidths[action.id]}
                    data-testid={TestIds.FormGroupAction}>
                    <StyledFormGroupAction
                        onMouseEnter={() => this.handleMouseEnter(action.id)}
                        onMouseLeave={() => this.handleMouseLeave(action.id)}>
                        <IconButton isLight
                                    isDecorative
                                    isDisabled={isDisabled}
                                    onClick={onClickFn}
                                    ignoreTheme={this.props.ignoreTheme}
                                    // no label, because we show it already on mouse over
                                    title={""}>
                            <Icon isLight width={IconSize.M} height={IconSize.M}/>
                        </IconButton>
                    </StyledFormGroupAction>
                    <ActionLabel onClick={onClickFn}>
                        {action.label}
                    </ActionLabel>
                </StyledFormGroupActionWrapper>
            );
        });
    };

    render() {
        return (
            <StyledFormGroupActions ref={this.rootRef}
                                    isTransparent={this.props.isTransparent}
                                    className={this.props.className}
                                    data-testid={TestIds.FormGroupActions}>
                {this.renderActions()}
            </StyledFormGroupActions>
        );
    }
}

export default FormGroupActions;