import styled from "styled-components/macro";
import { T_TITLE_2, T_TITLE_4, T_TITLE_4_NORMAL } from "../../global.style";
import { ListType } from "./ReadOnlyListItem";


export const TitleStyled = styled.div`
    ${T_TITLE_2};
    margin-bottom: 20px;
`;

export const ListStyled = styled.div<{ $withoutPadding?: boolean }>`
    display: flex;
    flex-wrap: wrap;
    padding-left: ${props => props.$withoutPadding ? 0 : "12px"};
`;

export const ItemStyled = styled.div<{ type: ListType }>`
    width: fit-content;
    margin-right: 32px;
    align-items: flex-start;
    margin-bottom: ${props => props.type === ListType.Condensed ? "17px" : "24px"};
`;

export const ItemLabel = styled.div<{ type: ListType }>`
    ${T_TITLE_4};
    margin-bottom: ${props => props.type === ListType.Condensed ? "9px" : "12px"};
`;

export const ItemValue = styled.div`
    position: relative; // to support drilldown link in read-only list with correct caret position
    ${T_TITLE_4_NORMAL};
    white-space: nowrap;
`;

export const ItemDescription = styled.span`
    color: ${props => props.theme.C_ACT_thick_line};
`;
