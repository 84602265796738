import { EntitySetName, EntityTypeName } from "@odata/GeneratedEntityTypes";
import { CompanyPermissionCode, DocumentTypeCode } from "@odata/GeneratedEnums";
import { isCashBasisAccountingCompany } from "@utils/CompanyUtils";
import i18next from "i18next";
import { cloneDeep } from "lodash";

import { IAppContext } from "../../../contexts/appContext/AppContext.types";
import { Model } from "../../../model/Model";
import { AssetTranslations } from "../../asset/fixedAsset/FixedAsset.utils";
import { setDefByEntityType } from "../../getDefByEntityType";
import { getItemBreadCrumbsText, IDefinition, IGetDefinition } from "../../PageUtils";
import { commonDocumentTranslations } from "../Document.utils";
import { addCorrectiveDocument, addPaymentOrderTableDefs } from "../DocumentCommonDefs";
import { clearEmptyDateGroupDateFields, getDefinitions as getDocumentDefinitions } from "../DocumentDef";
import { addItemsSummaryDef } from "../extensions/itemsSummary/ItemsSummary.utils";
import { enhanceReceivedDocDefinition } from "../invoicesReceived/InvoicesReceivedDef";
import OtherLiabilityFormView from "./OtherLiabilityFormView";

export const getDefinitions: IGetDefinition = (context: IAppContext): IDefinition => {
    const hasAccountAssignment = !isCashBasisAccountingCompany(context);

    const definition = cloneDeep(getDocumentDefinitions({
        entitySet: EntitySetName.OtherLiabilities,
        documentType: DocumentTypeCode.OtherLiability,
        tableId: `${EntityTypeName.OtherLiability}Table`,
        formId: `${EntityTypeName.OtherLiability}Form`,
        formControl: OtherLiabilityFormView,
        translationFiles: getDefinitions.translationFiles,
        getItemBreadCrumbText: (storage: Model) =>
            getItemBreadCrumbsText(storage, i18next.t("OtherLiabilities:Breadcrumbs.NewLiability"),
                storage.data.entity?.NumberOurs && i18next.t("OtherLiabilities:Breadcrumbs.LiabilityWithNumber", { number: storage.data.entity.NumberOurs })),
        permissions: [CompanyPermissionCode.Reports],
        context
    }));

    enhanceReceivedDocDefinition(definition, {
        docType: DocumentTypeCode.OtherLiability,
        transFile: "OtherLiabilities",
        isBusinessPartnerOptional: true,
        hasAccountAssignment,
        context
    });

    addCorrectiveDocument(definition, hasAccountAssignment);
    addItemsSummaryDef(definition, context);
    addPaymentOrderTableDefs(definition);

    return clearEmptyDateGroupDateFields(definition);
};

getDefinitions.translationFiles = ["OtherLiabilities", "InvoicesReceived", ...AssetTranslations, ...commonDocumentTranslations];
setDefByEntityType(EntityTypeName.OtherLiability, getDefinitions);