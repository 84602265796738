import React from "react";
import TooltipIcon, { ITooltipIconProps } from "../TooltipIcon";

class TooltipIconInfo extends React.PureComponent<ITooltipIconProps> {
    render() {
        return (
            <TooltipIcon iconName={"Info"} {...this.props}/>
        );
    }
}

export default TooltipIconInfo;