import React from "react";
import { getOData, OData } from "./OData";
import { ODATA_API_URL } from "../constants";
import { WithAuthContext, withAuthContext } from "../contexts/authContext/withAuthContext";

export const ODataContext = React.createContext<OData>(undefined);

interface IProps extends WithAuthContext {
    onODataLoaded: (odata: OData) => void;
}

interface IState {
    oData: OData;
}

class ODataProvider extends React.Component<IProps, IState> {
    state: IState = {
        oData: null
    };

    componentDidMount(): void {
        if (this.props.authContext.isAuthenticated) {
            this.init();
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (!prevProps.authContext.isAuthenticated && this.props.authContext.isAuthenticated) {
            this.init();
        } else if (prevProps.authContext.isAuthenticated && !this.props.authContext.isAuthenticated) {
            this.reset();
        }
    }

    init = async () => {
        await this.fetchOData();
    };

    reset = async () => {
        this.setState({
            oData: null
        });
    };

    fetchOData = async () => {
        // todo error handling (or is error boundary enough)
        const oData = await getOData(ODATA_API_URL);

        this.setState({
            oData
        });
        this.props.onODataLoaded(oData);
    };

    render = () => {
        return (
            <ODataContext.Provider value={this.state.oData}>
                {this.props.children}
            </ODataContext.Provider>
        );
    };
}

export default withAuthContext(ODataProvider);