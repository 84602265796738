import { IReportTableDefinition } from "../Report.utils";
import i18next from "i18next";
import { getDocumentTypeFilterDef } from "../CommonDefs";
import { IReportHierarchy } from "@components/smart/smartTable";
import BindingContext from "../../../odata/BindingContext";
import { ReportId } from "../ReportIds";
import {
    FilterBarGroup,
    getDefaultFilterGroupDef,
    IFilterGroupDef
} from "@components/smart/smartFilterBar/SmartFilterBar.types";
import { ReportStorage } from "../ReportStorage";
import { fetchAndSetExchangeRateDays } from "./ExchangeRate.utils";
import { ISort } from "@components/table";
import { Sort } from "../../../enums";

export const ExchangeRateProps = {
    exchangeRateDate: BindingContext.localContext("ExchangeRateDate"),
    forceToday: BindingContext.localContext("ForceToday")
};

export const getDefinition = (): IReportTableDefinition => {
    const tableId = ReportId.ExchangeRate;
    const title = i18next.t("Reporting:ExchangeRate.Title");
    const path = "ExchangeRateList";
    const initialSortBy: ISort[] = [{ id: "NotTable_CountryName", sort: Sort.Asc }];
    const filterBarDef: IFilterGroupDef[] = [
        {
            ...getDefaultFilterGroupDef(FilterBarGroup.Filters),
            allowCustomFilters: false,
            defaultFilters: [
                ExchangeRateProps.exchangeRateDate,
                ExchangeRateProps.forceToday
            ],
            filterDefinition: {
                [BindingContext.localContext("DocumentType_Name")]: getDocumentTypeFilterDef(),
                // hidden parameter - not shown in filter bar
                // instead, it is filled from selected Tab
                [ExchangeRateProps.exchangeRateDate]: {
                    // needs to be true for table storage to load info
                    // set to false onAfterLoad
                    isVisible: true
                },
                // another hidden parameter
                [ExchangeRateProps.forceToday]: {
                    isVisible: true
                }
            }
        }
    ];
    const onBeforeLoad = async (storage: ReportStorage) => {
        await fetchAndSetExchangeRateDays(storage);
    };
    const parameters: string[] = [ExchangeRateProps.exchangeRateDate];
    const defaultReportHierarchy: IReportHierarchy = {
        "Aggregate": false,
        "Groups": [],
        "Columns": [
            { "ColumnAlias": "NotTable_ExchangeRateTypeName" },
            { "ColumnAlias": "NotTable_CountryName" },
            { "ColumnAlias": "NotTable_FromCurrencyName" },
            { "ColumnAlias": "NotTable_FromCurrencyCode" },
            { "ColumnAlias": "NotTable_Amount" },
            { "ColumnAlias": "NotTable_ExchangeRateDirect" }
        ],
        "Aggregations": []
    };

    const columnOverrides = {
        NotTable_ExchangeRateIndirect: {
            tooltip: i18next.t("ExchangeRate:IndirectRateTooltip")
        }
    };

    return {
        title, path, id: tableId, initialSortBy, filterBarDef, columnOverrides,
        onBeforeLoad, parameters, defaultReportHierarchy,
        disableAggregateButton: true
    };
};