import styled from "styled-components/macro";
import { mix } from "polished";
import { InnerLabel } from "../inputs/field/Label.styles";
import { FieldGroupPaddingType } from "./FieldGroup";

interface IStyledFieldGroup {
    isLight?: boolean;
    _paddingType?: FieldGroupPaddingType;
    _opacity: number;
    // there can be multiple connected groups inside filter bar
    isFirst?: boolean;
    isLast?: boolean;
}

const getBoxShadow = (props: IStyledFieldGroup) => {
    let boxShadow = "";

    if (props.isLast) {
        boxShadow += "0 0 4.9px 0.2px rgba(0, 0, 0, 0.05)";
    }

    if (!props.isFirst) {
        if (boxShadow) {
            boxShadow += ", ";
        }

        boxShadow += "inset 0px 79px 55px -86px rgb(0 0 0 / 35%)";
    }

    return boxShadow;
};

export const StyledFieldGroup = styled.div<IStyledFieldGroup>`
    // prevent children from overflowing FieldGroup by using fit-content as width instead of 100%
    min-width: fit-content;
    border-bottom-left-radius: ${props => props.isLast ? "3px" : "0"};
    border-bottom-right-radius: ${props => props.isLast ? "3px" : "0"};
    border-top-right-radius: ${props => props.isFirst ? "3px" : "0"};
    box-shadow: ${props => getBoxShadow(props)};
    background-color: ${props => props.isLight ? mix(props.theme.lightFieldGroupOpacity, props.theme.C_BG_filterbar, props.theme.C_BG_floorplan) : mix(props._opacity, props.theme.C_BG_filterbar, props.theme.C_BG_floorplan)};
    box-sizing: border-box;

    position: relative;
    padding: ${props => props._paddingType === FieldGroupPaddingType.None ? 0 : props._paddingType === FieldGroupPaddingType.Default ? "18px 38px 30px 38px" : "25px 38px 30px 33px"};

    display: flex;
    flex-wrap: wrap;


    & ${InnerLabel} {
        ${props => props.isLight && `
        color: ${props.theme.C_TEXT_primary};
     `}
    }
`;

export const FieldGroupIconWrapper = styled.div`
    position: absolute;
    right: 8px;
    top: 8px;
    display: flex;

    & > button:not(:first-child) {
        margin-left: 5px;
    }
`;


