import { PredefinedFilter } from "@components/conditionalFilterDialog/ConditionalFilterDialog.utils";
import {
    FilterBarGroup,
    getDefaultFilterGroupDef,
    IFilterGroupDef
} from "@components/smart/smartFilterBar/SmartFilterBar.types";
import { IReportHierarchy } from "@components/smart/smartTable";
import { IFormatOptions } from "@odata/OData.utils";
import { isAccountAssignmentCompany } from "@utils/CompanyUtils";
import i18next from "i18next";
import React from "react";

import { IAppContext } from "../../../contexts/appContext/AppContext.types";
import { Sort, TextAlign } from "../../../enums";
import { ColoredText } from "../../../global.style";
import { TValue } from "../../../global.types";
import { getThemeColorForPaymentStatus } from "../../banks/Payments.utils";
import { CommonReportProps, getDocumentNumberOursOverride } from "../CommonDefs";
import {
    composedDateRangeOnBeforeLoadCallback,
    DEFAULT_RANGE,
    getComposedDateRangeFieldsDefs
} from "../customFilterComponents/ComposedDateRange";
import { IReportTableDefinition, TReportColumnOverrides } from "../Report.utils";
import { ReportId } from "../ReportIds";
import { ReportStorage } from "../ReportStorage";

export const PAYMENT_JOURNAL_PATH = "PaymentJournal";

export const getDefinition = (context: IAppContext): IReportTableDefinition => {
    const tableId = ReportId.PaymentJournal;
    const title = i18next.t("Reporting:PaymentJournal.Title");
    const path = PAYMENT_JOURNAL_PATH;
    const initialSortBy = [{ id: "Document_DateCreated", sort: Sort.Desc }];
    const parameters: string[] = [CommonReportProps.dateRange, CommonReportProps.dateRangeCustomValue];
    const hasAccounting = isAccountAssignmentCompany(context);

    const columnOverrides: TReportColumnOverrides = {
        PaymentStatus_Name: {
            formatter: (value: TValue, args: IFormatOptions) => {
                const color = getThemeColorForPaymentStatus(args.entity["PaymentDocument_PaymentStatusCode"]);
                return {
                    value: <b><ColoredText color={color}> {value} </ColoredText></b>,
                    tooltip: value ?? ""
                };
            },
            textAlign: TextAlign.Center
        },
        PaymentDocument_NumberOurs: getDocumentNumberOursOverride({
            documentTypeProperty: "PaymentDocument_DocumentTypeCode",
            documentIdProperty: "PaymentDocument_Id"
        })
    };

    const dateRangeFieldDefs = getComposedDateRangeFieldsDefs(tableId);
    const filterBarDef: IFilterGroupDef[] = [
        {
            ...getDefaultFilterGroupDef(FilterBarGroup.Parameters),
            defaultFilters: [
                CommonReportProps.dateRange, CommonReportProps.dateRangeCustomValue
            ],
            filterDefinition: {
                ...dateRangeFieldDefs,
                [CommonReportProps.dateRange]: {
                    ...dateRangeFieldDefs[CommonReportProps.dateRange],
                    defaultValue: hasAccounting ? DEFAULT_RANGE : PredefinedFilter.ThisYear
                }
            }
        },
        {
            ...getDefaultFilterGroupDef(FilterBarGroup.Filters),
            allowCustomFilters: false,
            defaultFilters: [],
            filterDefinition: {}
        }
    ];

    const onBeforeLoad = async (storage: ReportStorage) => {
        await composedDateRangeOnBeforeLoadCallback(storage);
    };

    const defaultReportHierarchy: IReportHierarchy = {
        "Aggregate": false,
        "Groups": [],
        "Columns": [
            { "ColumnAlias": "PaymentDocument_NumberOurs" },
            { "ColumnAlias": "DocumentType_Name" },
            { "ColumnAlias": "PaymentStatus_Name" },
            { "ColumnAlias": "PaymentDocument_TransactionAmount" },
            { "ColumnAlias": "PaymentDocument_TransactionAmountDue" },
            { "ColumnAlias": "PaymentDocument_Note" },
            { "ColumnAlias": "PaymentDocument_PaymentDate" },
            { "ColumnAlias": "PaymentDocument_Amount" }
        ],
        "Aggregations": []
    };

    return {
        title, path, id: tableId, initialSortBy, filterBarDef, parameters,
        columnOverrides, onBeforeLoad, defaultReportHierarchy
    };
};
