import styled, { css } from "styled-components/macro";
import { AnimationSize, AntAnim, SquirrelAnim } from "../../animations/Animations";
import { T_TITLE_2 } from "../../global.style";
import { StyledToolbar } from "../toolbar/Toolbar.styles";

export const PDF_PAGE_MARGIN = 10;

export const StyledFileViewer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    width: 100%;
    // counter 10px from Toolbar margin
    height: calc(100% - 10px);
    flex-shrink: 0;

    & .react-pdf__Page:not(:last-child) {
        margin-bottom: ${`${PDF_PAGE_MARGIN}px`};
    }

    ${StyledToolbar} {
        margin-bottom: 10px;
    }
`;

export const NoPreviewStyled = styled.div<{ isWithoutBackground?: boolean; }>`
    width: 100%;
    // fill rest of the space without 48px for header
    height: calc(100% - 48px);
    flex: 1 1 auto;
    background-color: ${props => props.isWithoutBackground ? null : props.theme.C_BG_menu};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const NoPreviewAnimStyled = css`
    width: ${AnimationSize.M}px;
    height: ${AnimationSize.M}px;
    // 60px space for two lines of text below
    max-height: calc(100% - 60px);
    max-width: 100%;
    flex: 0 1 auto;
`;

export const SquirrelAnimStyled = styled(SquirrelAnim)`
    ${NoPreviewAnimStyled};
`;

export const AntAnimStyled = styled(AntAnim)`
    ${NoPreviewAnimStyled};
`;

export const NoPreviewTextStyled = styled.div`
    max-width: 80%;
    flex: 0 0 auto;

    color: ${props => props.theme.C_ACT_thick_line};
    ${T_TITLE_2};
    text-align: center;
    white-space: pre-wrap;
`;
