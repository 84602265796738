import styled, { css } from "styled-components/macro";
import { IconButton } from "../button";
import { T_PLAIN_small, textAlignToJustify } from "../../global.style";
import { TextAlign } from "../../enums";
import ImageUploaderImage from "../imageUploader/ImageUploaderImage";
import { ellipsis, transparentize } from "polished";
import { TOOLTIP_PADDING } from "../tooltipIcon/TooltipIcon.styles";

export const HOTSPOT_TOOLTIP_WIDTH = 252;

export const StyledHotspotTooltipContent = styled.div<{ $width?: string }>`
    position: relative;
    display: flex;
    flex-direction: column;
    width: ${props => props.$width ?? `${HOTSPOT_TOOLTIP_WIDTH}px`};
    max-height: 276px; // 300 - 2*12px padding
    ${T_PLAIN_small};
`;

export const HotspotTooltipHeaderWrapper = styled.div<{ textAlign?: TextAlign }>`
    display: flex;
    justify-content: ${props => textAlignToJustify(props.textAlign ?? TextAlign.Center)};
    margin-bottom: 12px;
`;

export const HotspotTooltipHeader = styled.span`
    position: relative;
    left: 15px;
    margin-right: 30px;
    font-weight: bold;
    ${ellipsis()};
`;

export const IconButtonCloseIcon = styled(IconButton)`
    position: absolute;
    top: -2px;
    right: -2px;
`;

export const HotspotTooltipText = styled.div<{ textAlign?: TextAlign }>`
    white-space: pre-wrap;
    text-align: ${props => props.textAlign ?? TextAlign.Center};
`;

export const LikeWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    column-gap: 6px;
`;

export const HotspotTooltipLineStyles = css`
    height: 1px;
    width: 100%;
    border-top: 1px solid ${props => props.theme.C_FIELD_line};
    opacity: 0.5;
`;

export const HotspotTooltipLine = styled.div`
    ${HotspotTooltipLineStyles};
`;

export const BottomOuterWrapper = styled.div<{ hasImage: boolean }>`
    position: relative;
    margin-top: 6px;
    flex: 1 1 auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    min-height: ${props => props.hasImage ? "150px" : "100px"};

    // busy indicator is supposed to get inside the padding of the Tooltip
    // => make BottomOuterWrapper larger and add padding to it instead
    width: calc(100% + 2 * ${TOOLTIP_PADDING}px);
    margin-bottom: -${TOOLTIP_PADDING}px;
    left: -${TOOLTIP_PADDING}px;
    padding: 0 ${TOOLTIP_PADDING}px ${TOOLTIP_PADDING}px;
`;

export const BottomWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    padding-top: 10px;
    overflow: hidden;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        ${HotspotTooltipLineStyles};
    }
`;

export const CommentWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: flex-start;
    overflow: hidden;
`;

export const CommentImageOverlay = styled.div`
    background: ${props => transparentize(0.5, props.theme.C_BTN_hover_light)};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: hidden;
`;

export const StyledCommentImage = styled.div`
    position: relative;
    margin-top: 15px;
    border-radius: 3px;
    overflow: hidden;
    width: fit-content;

    &:hover {
        ${CommentImageOverlay} {
            visibility: visible;
        }
    }
`;

export const CommentImageUploaderImage = styled(ImageUploaderImage)`
    max-width: 100%;
    height: 70px;
    border: none;
`;

const CommentStyle = css`
    background: transparent;
    border: none;
    resize: none;
    color: ${props => props.theme.C_BTN_4L_emph_text};
    outline: none;
    margin-left: 12px;
    flex: 1 1 auto;
    padding: 0;
    ${T_PLAIN_small};

    &::placeholder {
        color: ${props => props.theme.C_BTN_4L_emph_text};
        font-style: italic;
    }
`;

export const NewHotspotTextArea = styled.textarea.attrs((props) => ({
    rows: props.rows ?? 1
}))`
    ${CommentStyle};
`;

export const CommentTextArea = styled.textarea`
    ${CommentStyle};
    position: relative;
    top: 5px;
`;

export const StyledNewHotspotTooltipContent = styled(StyledHotspotTooltipContent)`
    font-size: 14px;
`;