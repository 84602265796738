import styled from "styled-components/macro";
import CollapsibleSection from "../../../components/collapsibleSection/CollapsibleSection";
import { TopWrapper } from "@components/collapsibleSection/CollapsibleSection.styles";


export const CollapsibleSectionStyled = styled(CollapsibleSection)`
    ${TopWrapper} {
        margin-bottom: 0;
    }
`;

export const LegislativeExtraSalariesSmartFastEntryListStyled = styled.div`
    display: block;
    width: 100%;
`;