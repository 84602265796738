import React from "react";
import {
    BackgroundJobClickable,
    BackgroundJobDeleteAll,
    DeleteAllText,
    StyledBackgroundJobs
} from "./BackgroundJobsPopup.styles";
import { ScrollBar } from "../scrollBar";
import { IconButton } from "../button";
import { IconSize } from "../../enums";
import { CheckIcon, CloseIcon } from "../icon";
import { WithTranslation, withTranslation } from "react-i18next";
import { BackgroundJob, IBackgroundJobProps } from "./BackgroundJob";
import TestIds from "../../testIds";
import i18next from "i18next";

interface IProps {
    backgroundJobs: IBackgroundJobProps[];
    showCloseButton?: boolean;
    onClose?: () => void;
    onCloseAll: () => void;

    hasMassClosableJob: boolean;

    passRef?: React.Ref<HTMLDivElement>;
}

const CloseButtonStyles: React.CSSProperties = {
    position: "absolute",
    right: "2px",
    top: "2px"
};

interface IPropsExtended extends IProps, WithTranslation {

}

class BackgroundJobsPopup extends React.PureComponent<IPropsExtended> {
    renderBackgroundJob = (backgroundJob: IBackgroundJobProps): React.ReactElement => {
        return (
            <BackgroundJob key={backgroundJob.id}
                           {...backgroundJob}/>
        );
    };

    renderCloseButton = (): React.ReactElement => {
        if (!this.props.showCloseButton) {
            return null;
        }

        return (
            <IconButton title={this.props.t("Common:General.Close")}
                        isDecorative
                        style={CloseButtonStyles}>
                <CloseIcon
                    width={IconSize.S}
                    height={IconSize.S}/>
            </IconButton>
        );
    };

    render() {
        return (
            <StyledBackgroundJobs ref={this.props.passRef}
                // for HideOnBlur to work
                                  tabIndex={-1}
                                  data-testid={TestIds.BackgroundJobsItemsList}>

                <BackgroundJobDeleteAll>
                    <BackgroundJobClickable isDisabled={!this.props.hasMassClosableJob} onClick={this.props.onCloseAll}>
                        <DeleteAllText>{i18next.t("Common:BackgroundJob.DeleteAll")}</DeleteAllText>
                    </BackgroundJobClickable>
                </BackgroundJobDeleteAll>


                <ScrollBar isInFlex style={{
                    maxHeight: "inherit"
                }}>
                    {this.props.backgroundJobs.map(this.renderBackgroundJob)}
                </ScrollBar>
                {this.renderCloseButton()}
            </StyledBackgroundJobs>
        );
    }
}

export default withTranslation(["Common"])(BackgroundJobsPopup);

export type { IProps as IBackgroundJobsProps };