import SelectionTableViewBase, { ISelectionTableViewProps } from "@components/smart/SelectionTableViewBase";
import { withBusyIndicator, WithBusyIndicator } from "@components/busyIndicator/withBusyIndicator";

export interface IPairingDocumentTableViewProps extends ISelectionTableViewProps, WithBusyIndicator {
}

class PairEmployeeTableView extends SelectionTableViewBase<IPairingDocumentTableViewProps> {
    get selectionConfirmText(): string {
        return this.props.storage.t("GroupDeduction:Pair.Select");
    }

    get successMessage(): string {
        return this.props.storage.t("GroupDeduction:Pair.Success");
    }
}

export default withBusyIndicator({ passBusyIndicator: true })(PairEmployeeTableView);
