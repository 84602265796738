import { ColoredIcon, EmailIcon } from "@components/icon";
import React from "react";

import Message from "../../components/chat/Message";
import LinkWithTextInDialog from "./LinkWithTextInDialog";

interface IProps {
    text: string;
    createdBy?: string;
    created?: Date;
}

const EmailBodyMessage: React.FC<IProps> = ({ text, created,createdBy }) => {
    return (
        <Message icon={<ColoredIcon><EmailIcon/></ColoredIcon>} createdBy={createdBy} created={created}>
            <LinkWithTextInDialog text={text}/>
        </Message>
    );
};

export default EmailBodyMessage;