import { ifAll, not } from "@components/smart/FieldInfo";
import { createPath } from "@odata/BindingContext";
import {
    BankAccountEntity,
    EntityTypeName,
    IPrIndividualDeductionEntity,
    IPrSalaryAdvanceEntity,
    PrDeductionBusinessPartnerEntity,
    PrDeductionTemporalEntity,
    PrEmployeeEntity,
    PrIndividualDeductionDebtPaymentEntity,
    PrIndividualDeductionEntity
} from "@odata/GeneratedEntityTypes";
import {
    PrChildSupportDeductionTypeCode,
    PrDeductionTypeCode,
    PrDistrainmentDeductionTypeCode
} from "@odata/GeneratedEnums";
import { getEnumDisplayValue } from "@odata/GeneratedEnums.utils";
import { IFormatOptions } from "@odata/OData.utils";
import { userNameWithAvatarTableFormatter } from "@pages/admin/users/UsersDef";
import { paymentDetailFields } from "@pages/banks/bankAccounts/BankAccounts.utils";
import { setDefByEntityType } from "@pages/getDefByEntityType";
import { IDefinition, IGetDefinition } from "@pages/PageUtils";
import { getCommonDeductionDefinitions } from "@pages/payroll/deduction/commonDeduction.def";
import {
    DEBT_BALANCE_PATH,
    DeductionType,
    isAmongTypes,
    isDebtTypeOfChildSupport
} from "@pages/payroll/deduction/Deduction.utils";
import { employeeSelectDef } from "@pages/payroll/employee/Employee.utils";
import { getCompanyCurrency } from "@utils/CompanyUtils";
import Big from "big.js";
import i18next from "i18next";

import { DASH_CHARACTER } from "../../../../constants";
import { IAppContext } from "../../../../contexts/appContext/AppContext.types";
import { FieldType } from "../../../../enums";
import { TValue } from "../../../../global.types";
import CurrencyType from "../../../../types/Currency";
import { currencyScaleFormatter, isNumber } from "../../../../types/Number";


export const getDefinitions: IGetDefinition = (context: IAppContext): IDefinition => {
    const definition = getCommonDeductionDefinitions(context, DeductionType.IndividualDeduction);

    definition.table.filterBarDef[0].defaultFilters = [
        PrIndividualDeductionEntity.Employee,
        ...definition.table.filterBarDef[0].defaultFilters
    ];

    definition.table.filterBarDef[0].filterDefinition = {
        [PrIndividualDeductionEntity.Employee]: {
            formatter: (val: TValue, args: IFormatOptions) => {
                return userNameWithAvatarTableFormatter(val, {
                    ...args,
                    entity: (args.entity as IPrSalaryAdvanceEntity).Employee
                });
            },
            additionalProperties: [
                { id: PrEmployeeEntity.FirstName },
                { id: PrEmployeeEntity.LastName }
            ]
        },
        ...definition.table.filterBarDef[0].filterDefinition
    };

    definition.table.columns = [
        PrIndividualDeductionEntity.Employee,
        ...definition.table.columns
    ];

    definition.table.columnDefinition = {
        [PrIndividualDeductionEntity.Employee]: {
            formatter: (val: TValue, args: IFormatOptions) => {
                return userNameWithAvatarTableFormatter(val, {
                    ...args,
                    entity: (args.entity as IPrSalaryAdvanceEntity).Employee
                });
            },
            additionalProperties: [
                { id: PrEmployeeEntity.FirstName },
                { id: PrEmployeeEntity.LastName }
            ]
        },
        ...definition.table.columnDefinition
    };

    definition.form.fieldDefinition = {
        ...definition.form.fieldDefinition,
        [PrIndividualDeductionEntity.Employee]: {
            ...employeeSelectDef
        },
        [PrIndividualDeductionEntity.DateReceived]: {
            isRequired: ifAll(isDebtTypeOfChildSupport, isAmongTypes([PrDeductionTypeCode.Distrainment, PrDeductionTypeCode.ChildSupport])),
            isVisible: isAmongTypes([PrDeductionTypeCode.Distrainment, PrDeductionTypeCode.ChildSupport])
        },
        [PrIndividualDeductionEntity.DecreeNumber]: {
            isRequired: isAmongTypes([PrDeductionTypeCode.Distrainment, PrDeductionTypeCode.Insolvency]),
            isVisible: ifAll(isDebtTypeOfChildSupport, isAmongTypes([PrDeductionTypeCode.Insolvency, PrDeductionTypeCode.Distrainment, PrDeductionTypeCode.ChildSupport])),
        },
        [PrIndividualDeductionEntity.AmountDebtTotal]: {
            isRequired: isAmongTypes([PrDeductionTypeCode.Distrainment]),
            isVisible: ifAll(isDebtTypeOfChildSupport, isAmongTypes([PrDeductionTypeCode.Distrainment, PrDeductionTypeCode.ChildSupport])),
            fieldSettings: {
                formatter: currencyScaleFormatter,
                unit: CurrencyType.getCurrencyUnit(getCompanyCurrency(context))
            }
        },
        [PrIndividualDeductionEntity.DistrainmentType]: {
            type: FieldType.SegmentedButton,
            defaultValue: PrDistrainmentDeductionTypeCode.Priority,
            isVisible: isAmongTypes([PrDeductionTypeCode.Distrainment]),
            fieldSettings: {
                items: [
                    {
                        id: PrDistrainmentDeductionTypeCode.Priority,
                        label: getEnumDisplayValue(EntityTypeName.PrDistrainmentDeductionType, PrDistrainmentDeductionTypeCode.Priority)
                    }, {
                        id: PrDistrainmentDeductionTypeCode.Standard,
                        label: getEnumDisplayValue(EntityTypeName.PrDistrainmentDeductionType, PrDistrainmentDeductionTypeCode.Standard)
                    }
                ]
            }
        },
        [PrIndividualDeductionEntity.ChildSupportType]: {
            type: FieldType.SegmentedButton,
            defaultValue: PrChildSupportDeductionTypeCode.Regular,
            isVisible: isAmongTypes([PrDeductionTypeCode.ChildSupport]),
            fieldSettings: {
                items: [
                    {
                        id: PrChildSupportDeductionTypeCode.Regular,
                        label: getEnumDisplayValue(EntityTypeName.PrChildSupportDeductionType, PrChildSupportDeductionTypeCode.Regular)
                    },
                    {
                        id: PrChildSupportDeductionTypeCode.Owed,
                        label: getEnumDisplayValue(EntityTypeName.PrChildSupportDeductionType, PrChildSupportDeductionTypeCode.Owed)
                    },
                    {
                        id: PrChildSupportDeductionTypeCode.Substitute,
                        label: getEnumDisplayValue(EntityTypeName.PrChildSupportDeductionType, PrChildSupportDeductionTypeCode.Substitute)
                    },
                ]
            }
        },
        [DEBT_BALANCE_PATH]: {
            label: i18next.t("IndividualDeduction:Form.DebtBalance"),
            isVisible: ifAll(isDebtTypeOfChildSupport, isAmongTypes([PrDeductionTypeCode.Distrainment, PrDeductionTypeCode.Insolvency, PrDeductionTypeCode.ChildSupport])),
            additionalProperties: [{
                id: PrIndividualDeductionEntity.AmountDebtTotal
            }, {
                id: PrIndividualDeductionEntity.DebtPayments,
                additionalProperties: [{
                    id: PrIndividualDeductionDebtPaymentEntity.Amount
                }]
            }],
            isReadOnly: true,
            formatter: (val: TValue, args: IFormatOptions) => {
                const entity = args.entity as IPrIndividualDeductionEntity;
                const totalDebt = entity.AmountDebtTotal;

                if (!isNumber(totalDebt)) {
                    return DASH_CHARACTER;
                }

                const paidAmount = entity.DebtPayments?.reduce((acc, payment) => {
                    acc.add(payment.Amount ?? 0);
                    return acc;
                }, new Big(0)) ?? 0;

                const balance = new Big(totalDebt).sub(paidAmount).toNumber();
                return currencyScaleFormatter(balance);
            },
            fieldSettings: {
                unit: CurrencyType.getCurrencyUnit(getCompanyCurrency(context))
            }
        },
    };

    definition.form.groups = [
        {
            id: "main",
            rows: [
                [
                    { id: PrIndividualDeductionEntity.Name },
                    { id: PrIndividualDeductionEntity.Employee }
                ], [
                    { id: createPath(PrIndividualDeductionEntity.CurrentTemporalPropertyBag, PrDeductionTemporalEntity.CalculationType) },
                    { id: createPath(PrIndividualDeductionEntity.CurrentTemporalPropertyBag, PrDeductionTemporalEntity.Amount) },
                    { id: createPath(PrIndividualDeductionEntity.CurrentTemporalPropertyBag, PrDeductionTemporalEntity.PercentageEmployer) },
                    { id: createPath(PrIndividualDeductionEntity.CurrentTemporalPropertyBag, PrDeductionTemporalEntity.PercentageEmployee) },
                ],
                [
                    { id: PrIndividualDeductionEntity.DateStart },
                    { id: PrIndividualDeductionEntity.DateEnd }
                ]
            ]
        }, {
            id: "detail",
            title: i18next.t("IndividualDeduction:Form.DeductionDetail"),
            rows: [
                [
                    { id: PrIndividualDeductionEntity.DistrainmentType },
                    { id: PrIndividualDeductionEntity.ChildSupportType }
                ],
                [
                    { id: PrIndividualDeductionEntity.DecreeNumber },
                    { id: PrIndividualDeductionEntity.DateReceived },
                    { id: PrIndividualDeductionEntity.AmountDebtTotal },
                    { id: DEBT_BALANCE_PATH }
                ]
            ]
        }, {
            id: "receiver",
            title: i18next.t("IndividualDeduction:Form.Receiver"),
            isVisible: not(isAmongTypes([PrDeductionTypeCode.MealVoucher])),
            rows: [
                [
                    { id: createPath(PrIndividualDeductionEntity.Recipient, PrDeductionBusinessPartnerEntity.Name) },
                    { id: createPath(PrIndividualDeductionEntity.Recipient, PrDeductionBusinessPartnerEntity.LegalNumber) }
                ]
            ]
        },
        {
            // id "payment" is expected in the change handlers
            id: "payment",
            title: i18next.t("Common:Form.BankAccount"),
            isVisible: not(isAmongTypes([PrDeductionTypeCode.MealVoucher])),
            rows: [
                [
                    { id: createPath(PrIndividualDeductionEntity.BankAccount, BankAccountEntity.Country) },
                    { id: createPath(PrIndividualDeductionEntity.BankAccount, BankAccountEntity.AbaNumber) },
                    { id: createPath(PrIndividualDeductionEntity.BankAccount, BankAccountEntity.AccountNumber) },
                    { id: createPath(PrIndividualDeductionEntity.BankAccount, BankAccountEntity.Bank) },
                    { id: createPath(PrIndividualDeductionEntity.BankAccount, BankAccountEntity.IBAN) },
                    { id: createPath(PrIndividualDeductionEntity.BankAccount, BankAccountEntity.SWIFT) }
                ],
                [...paymentDetailFields]
            ]
        }
    ];
    return definition;
};

getDefinitions.translationFiles = [
    ...getCommonDeductionDefinitions.translationFiles,
    "IndividualDeduction"
];

setDefByEntityType(EntityTypeName.PrIndividualDeduction, getDefinitions);