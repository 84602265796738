import Clickable from "@components/clickable";
import { lighten } from "polished";
import styled from "styled-components/macro";

import { T_PLAIN_small } from "../../../../global.style";

export const StyledItemsSummary = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`;

export const StyledRoundText = styled(Clickable)`
    ${T_PLAIN_small};
    text-align: right;
    font-style: italic;
    color: ${props => props.theme.C_ACT_main};
    padding: 0 12px;
    cursor: pointer;
    line-height: 30px;
`;

export const UnderTableText = styled.div`
    ${T_PLAIN_small};
    text-align: right;
    white-space: pre-wrap;
    padding: 12px 0;
    line-height: 30px;
    color: ${props => lighten(0.15, props.theme.C_TEXT_primary)};
`;