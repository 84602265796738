import styled, { css } from "styled-components/macro";
import Token from "./Token";
import Tokenizer from "./Tokenizer";

export const StyledUserInitial = styled(Token)<{ $isBig?: boolean; }>`
    text-transform: uppercase;
    border: none;
    padding: 2px 6px;
    font-size: 10px;
    font-weight: 600;

    ${props => props.$isBig && css`
        height: 38px;
        font-size: 18px;
        border-radius: 18px;
    `};

    overflow: visible;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    ${props => props.title.length === 2 && css<{ $isBig?: boolean; }>`
        width: ${props => props.$isBig ? 38 : 22}px;
    `};
`;

export const StyledUserInitialTokenizer = styled(Tokenizer)`
    ${StyledUserInitial}:not(:last-child) {
        margin-right: 3px;
    }
`;