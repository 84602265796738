import React from "react";

import memoizeOne from "../../utils/memoizeOne";
import { ConfirmationDialog } from "./ConfirmationDialog";
import { IDialogProps } from "./Dialog";

export const ConfirmationDialogContext = React.createContext<WithConfirmationDialogContext>(undefined);

export interface IConfirmationDialogOptions {
    content: React.ReactNode;
    confirmText?: string;
    onClose?: () => void;
    onConfirm?: () => void;
    dialogProps?: Partial<IDialogProps>;
    allowCancel?: boolean;
}

export interface WithConfirmationDialogContext {
    /** Promise is resolved when the dialog is closed. False for cancel, true for confirmation. */
    open: (args: IConfirmationDialogOptions) => Promise<boolean>;
    close: () => void;
    getConfirmationPromise: () => Promise<boolean>;
    isOpened: boolean;
}

interface IProps {
}

interface IState {
    isOpened: boolean;
}

// todo use for all dialogs, not just confirmations?

/** Provides API for opening dialog. Don't forget to only open one dialog at a time.
 * To handle dialog result either use onClose/onConfirm method which can be passed as options to "open" method
 * or await the promise which is returned by the "open" method and resolves in either true (confirmed) or close (canceled).*/
export default class ConfirmationDialogProvider extends React.Component<IProps, IState> {
    state: IState = {
        isOpened: false
    };

    dialogOptions: IConfirmationDialogOptions = null;

    confirmationPromise: Promise<boolean>;
    fnConfirmationPromiseResolve: (confirmed: boolean) => void;

    getConfirmationDialogContext = memoizeOne(
            (): WithConfirmationDialogContext => {
                return {
                    open: this.open,
                    close: this.close,
                    getConfirmationPromise: this.getConfirmationPromise,
                    isOpened: this.state.isOpened
                };
            }
            , () => [this.state.isOpened]);

    open = (args: IConfirmationDialogOptions): Promise<boolean> => {
        // we need to wait for confirmation from user
        // this promise is returned from the "open" method, so that the caller can wait until the dialog is closed
        // to resolve the promise from outside its context, we need to store it in "fnConfirmationPromiseResolve"
        // which can then be called from dialog close handler methods
        this.confirmationPromise = new Promise((resolve) => {
            this.fnConfirmationPromiseResolve = resolve;
        });

        this.dialogOptions = args;

        this.setState({
            isOpened: true
        });

        return this.confirmationPromise;
    };

    close = (): void => {
        this.dialogOptions = null;
        this.confirmationPromise = null;
        this.fnConfirmationPromiseResolve = null;

        this.setState({
            isOpened: false
        });
    };

    getConfirmationPromise = (): Promise<boolean> => {
        return this.confirmationPromise;
    };

    handleConfirm = (): void => {
        this.fnConfirmationPromiseResolve(true);
        this.dialogOptions?.onConfirm?.();

        this.close();
    };

    handleClose = ():void => {
        this.fnConfirmationPromiseResolve(false);
        this.dialogOptions?.onClose?.();

        this.close();
    };

    renderConfirmationDialog = (): React.ReactElement => {
        return (
            <ConfirmationDialog confirmText={this.dialogOptions.confirmText}
                                onConfirm={this.handleConfirm}
                                onClose={this.dialogOptions.allowCancel !== false && this.handleClose}
                                {...this.dialogOptions.dialogProps}>
                {this.dialogOptions?.content}
            </ConfirmationDialog>
        );
    };

    render = () => {
        return (
            <ConfirmationDialogContext.Provider value={this.getConfirmationDialogContext()}>
                {this.state.isOpened && this.renderConfirmationDialog()}
                {this.props.children}
            </ConfirmationDialogContext.Provider>
        );
    };
}

