import React from "react";
import { AppContext } from "../../contexts/appContext/AppContext.types";
import { WithTranslation, withTranslation } from "react-i18next";
import { WithOData, withOData } from "@odata/withOData";
import { ExtendedShell, IExtendedShellPropsForExtend } from "./ExtendedShell";
import SearchWriteline from "../../components/search/SearchWriteline";
import { getOneFetch } from "@utils/oneFetch";
import { isDefined } from "@utils/general";
import { fetchAndParseSearchResults, ISearchGroup } from "./GlobalSearch.utils";
import { debounce } from "lodash";
import GlobalSearchResults from "./GlobalSearchResults";
import SearchIllustration, { SearchAnimationState } from "../../components/search/SearchIllustration";

interface IProps extends IExtendedShellPropsForExtend, WithOData, WithTranslation {
}

interface IState {
    search: string;
    status: SearchAnimationState;
    highlighted?: string;
    results?: ISearchGroup[];
    resultCount?: number;
}

class GlobalSearch extends React.PureComponent<IProps, IState> {
    static contextType = AppContext;
    //sadly, breaks typescript type checking
    //context: React.ContextType<typeof AppContext>;
    oneFetch = getOneFetch();

    _searchInputRef = React.createRef<HTMLInputElement>();

    state: IState = {
        search: "",
        status: SearchAnimationState.Empty
    };

    componentDidMount() {
        this._searchInputRef.current?.focus();
    }

    componentWillUnmount() {
        this.cancelSearch(false);
    }

    isSearchable(value: string): boolean {
        return isDefined(value) && value.length >= 3;
    }

    fetchResults = debounce(async (value: string) => {
        const { results, count } = await fetchAndParseSearchResults(value, this.oneFetch.fetch);

        this.setState({
            highlighted: value,
            results,
            resultCount: count,
            status: SearchAnimationState.Finished
        });
    }, 500);

    cancelSearch(clearResults = true) {
        this.fetchResults.cancel();
        this.oneFetch.abort();
        if (clearResults) {
            this.setState(({ search }) => ({
                results: null,
                resultCount: null,
                status: search ? SearchAnimationState.Waiting : SearchAnimationState.Empty
            }));
        }
    }

    handleItemClick = () => {
        this.props.onHide(true);
    };

    handleChange = (value: string) => {
        this.setState({
            search: value
        }, () => {
            if (this.isSearchable(value)) {
                // sets the status actually before the real (debounced) search,
                // so there is more time for the animation
                this.setState({ status: SearchAnimationState.Searching });
                this.fetchResults(value);
            } else {
                this.cancelSearch();
            }
        });
    };

    renderHeader() {
        let hint: string;
        const { search, resultCount } = this.state;

        if (!this.isSearchable(search)) {
            hint = this.props.t("Common:GlobalSearch.typeThreeChars");
        } else if (isDefined(resultCount)) {
            hint = this.props.t("Common:GlobalSearch.resultCount", { count: resultCount });
        }

        return (
            <SearchWriteline passRef={this._searchInputRef}
                             value={search}
                             hint={hint}
                             onChange={this.handleChange}/>
        );
    }

    renderResults() {
        return (
            <GlobalSearchResults searchString={this.state.highlighted}
                                 results={this.state.results}
                                 onResultClick={this.handleItemClick}
                                 t={this.props.t}/>
        );
    }

    renderIllustration() {
        let text: string;

        switch (this.state.status) {
            case SearchAnimationState.Finished:
                text = this.props.t("Common:GlobalSearch.nothingFound");
                break;
            case SearchAnimationState.Searching:
                text = this.props.t("Common:GlobalSearch.searchInProgress");
                break;
        }

        return (
            <SearchIllustration text={text} status={this.state.status}/>
        );
    }

    render() {
        return (
            <ExtendedShell header={this.renderHeader()}
                           hideScrollbar={!this.state.results?.length}
                           hotspotContextId={"globalSearch"}
                           {...this.props}>
                {this.state.results?.length
                    ? this.renderResults()
                    : this.renderIllustration()}
            </ExtendedShell>
        );
    }
}

export default withTranslation(["Common"])(withOData(GlobalSearch));
