import { NumericInput } from "@components/inputs/numericInput/NumericInput";
import { ISelectionChangeArgs } from "@components/inputs/select/Select.types";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAlert } from "../../components/alert/withAlert";
import Dialog from "../../components/dialog/Dialog";
import Field from "../../components/inputs/field";
import FieldsWrapper from "../../components/inputs/field/FieldsWrapper";
import { IAppContext } from "../../contexts/appContext/AppContext.types";
import { BasicInputSizes, TextAlign } from "../../enums";
import { ICompanyVatReducedCoefficientEntity } from "../../odata/GeneratedEntityTypes";
import { OData } from "../../odata/OData";
import NumberType from "../../types/Number";
import { getAlertFromError } from "../../views/formView/Form.utils";
import ConfirmationButtons from "../../views/table/ConfirmationButtons";
import { changeVatSubmissionClearingRatio } from "./VatSubmission.utils";

interface IProps {
    initialCoefficient: ICompanyVatReducedCoefficientEntity;
    oData: OData;
    context: IAppContext;
    onConfirm: (coefficient: ICompanyVatReducedCoefficientEntity) => void;
    onCancel: () => void;
}

const ClearingRatioDialog: React.FunctionComponent<IProps> = React.memo((props) => {
    const { initialCoefficient, onConfirm, oData, context } = props;
    const { t, ready: tReady } = useTranslation(["ElectronicSubmission", "Common"]);

    const [ratio, setRatio] = useState<number>(initialCoefficient?.VatReducedCoefficient ?? 0);
    const [error, setError] = useState<string>(null);
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const { alert, setAlert } = useAlert({});

    const handleChange = useCallback((e: ISelectionChangeArgs) => {
        setRatio(e.value as number);
        setError(null);
    }, []);

    const valid = ratio && NumberType.isValid(ratio) && ratio >= 0 && ratio <= 100;

    const handleBlur = useCallback(() => {
        if (!valid) {
            setError(t("ElectronicSubmission:VatSubmission.ValidationMessage"));
        }
    }, [t, valid]);

    const handleConfirm = useCallback(async () => {
        if (valid) {
            const coefficient = {
                ...(initialCoefficient ?? {}),
                VatReducedCoefficient: ratio
            };

            setIsBusy(true);

            try {
                await changeVatSubmissionClearingRatio(oData, context, coefficient);
                setIsBusy(false);
                onConfirm(coefficient);
            } catch (e) {
                setIsBusy(false);
                setAlert(getAlertFromError(e));
            }
        }
    }, [onConfirm, ratio, context, oData, initialCoefficient, valid]);

    return tReady && (
        <Dialog title={t("ElectronicSubmission:VatSubmission.ChangeDialogTitle")}
                onConfirm={handleConfirm}
                onClose={props.onCancel}
                busy={isBusy}
                footer={<ConfirmationButtons onConfirm={handleConfirm}
                                             onCancel={props.onCancel}
                                             useWrapper={false}
                                             isDisabled={!valid}/>}
        >
            {alert}
            <FieldsWrapper>
                <FieldsWrapper>
                    <Field label={t("ElectronicSubmission:VatSubmission.ClearingRatio")}
                           isRequired>
                        <NumericInput width={BasicInputSizes.M}
                                      error={error ? { message: error } : null}
                                      textAlign={TextAlign.Right}
                                      unit={"%"}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      value={ratio}/>
                    </Field>
                </FieldsWrapper>
            </FieldsWrapper>
        </Dialog>
    );
});

export default ClearingRatioDialog;