import { composeRefHandlers } from "@utils/general";
import React, { ReactElement, RefObject } from "react";

import { PaneStatus } from "../../enums";
import TestIds from "../../testIds";
import animationFrameThrottle from "../../utils/animationFrameThrottle";
import FocusManager, { FOCUSABLE_ATTR, FocusDirection } from "../focusManager";
import { IPaneProps } from "./Pane";
import { StyledSplitLayout } from "./SplitLayout.styles";

interface ISplitLayoutProps {
    paneStatus: PaneStatus[];
    onPaneStatusChanged: (paneStatus: PaneStatus[]) => void;
}

interface ISplitLayoutState {
    paneStatus: PaneStatus[];
}

const SPLIT_LAYOUT_FMID = "split-layout";

export class SplitLayout extends React.Component<ISplitLayoutProps, ISplitLayoutState> {
    mouseOffset: number;
    movingPane: HTMLElement;
    layoutRef = React.createRef<HTMLDivElement>();

    componentDidUpdate(prevProps: ISplitLayoutProps, prevState: ISplitLayoutState) {
        if (this.props.paneStatus !== prevProps.paneStatus && this.props.paneStatus.filter(status => status === PaneStatus.Normal).length === 2) {
            // if paneStatus changed to two panes with normal status,
            // we have to reset their widths to 50% to prevent them from having wrong width
            const panes = this.layoutRef.current.children;

            if(panes.length > 1) {
                for (let i = 0; i < this.props.paneStatus.length; i++) {
                    if (this.props.paneStatus[i] === PaneStatus.Normal) {
                        const pane = (panes[i] as HTMLDivElement);

                        // fixed panes doesn't have width -> do not reset it if it's not set (use case: inbox -> unselect folder)
                        if (pane && pane.style.width) {
                            pane.style.width = "50%";
                        }
                    }
                }
            }
        }
    }

    handlePaneMove = (e: React.MouseEvent, ref: RefObject<HTMLElement>): void => {
        this.mouseOffset = e.clientX - ref.current.getBoundingClientRect().right;
        this.movingPane = ref.current;

        // this.movingPane.parentElement.childNodes.forEach(node => {
        //     node.style.transition = "none";
        // });

        document.addEventListener("mousemove", this.handleMouseMove);
        document.addEventListener("mouseup", this.handleMouseUp);
        e.preventDefault();

        // because default is prevented, manually steal focus from where it is right now
        // TODO either this will remove current focus (is this ok from ux point?)
        // or will have to emit resize event
        if (document.activeElement instanceof HTMLElement) {
            document.activeElement.blur();
        }
    };

    handleMouseUp = (): void => {
        // this.movingPane.parentElement.childNodes.forEach(node => {
        //     node.style.transition = "width 0.5s";
        // });

        document.removeEventListener("mousemove", this.handleMouseMove);
        document.removeEventListener("mouseup", this.handleMouseUp);
    };

    handleMouseMove = animationFrameThrottle((e: MouseEvent): void => {
        const newWidth = (e.clientX - this.mouseOffset) - this.movingPane.getBoundingClientRect().x,
            parentWidth = (this.movingPane.parentNode as Element).getBoundingClientRect().width;

        const currentWidthPerc = (newWidth) / parentWidth * 100;

        // let siblingWidth = parentWidth - this_pane_width - newWidth;
        // let nextWidthPerc = siblingWidth / parentWidth * 100;

        if (currentWidthPerc < 20 || currentWidthPerc > 80) {
            return;
        }

        this.movingPane.style.width = `${currentWidthPerc}%`;
        (this.movingPane.nextSibling as HTMLElement).style.width = `${100 - currentWidthPerc}%`;

        e.preventDefault();
    });

    handleExpandPane = (ref: RefObject<HTMLElement>, index: number): void => {
        const paneStatus = [...this.props.paneStatus],
            newValue = paneStatus[index] === PaneStatus.Collapsed ? PaneStatus.Normal : PaneStatus.Expanded;

        switch (index) {
            case 2:
                if (paneStatus[1] === PaneStatus.Collapsed) {
                    paneStatus[0] = newValue === PaneStatus.Expanded ? PaneStatus.Collapsed : PaneStatus.Normal;
                    break;
                }
                paneStatus[0] = PaneStatus.Collapsed;
                paneStatus[1] = newValue === PaneStatus.Expanded ? PaneStatus.Collapsed : PaneStatus.Normal;
                break;

            case 1:
                if (paneStatus[2] === PaneStatus.Expanded) {
                    paneStatus[2] = PaneStatus.Normal;
                    break;
                }
                paneStatus[0] = newValue === PaneStatus.Normal ? PaneStatus.Normal : PaneStatus.Collapsed;
                paneStatus[2] = PaneStatus.Collapsed;
                break;

            case 0:
                if (newValue === PaneStatus.Expanded) {
                    paneStatus[1] = paneStatus[2] = PaneStatus.Collapsed;
                    break;
                }

                paneStatus[1] = paneStatus[2] === PaneStatus.Expanded ? PaneStatus.Collapsed : PaneStatus.Normal;
                paneStatus[2] = paneStatus[2] === PaneStatus.Expanded ? PaneStatus.Normal : PaneStatus.Collapsed;
                break;
        }

        paneStatus[index] = newValue;

        this.props.onPaneStatusChanged(paneStatus);
    };

    handleCollapsePane = (ref: RefObject<HTMLElement>, index: number): void => {
        const paneStatus = [...this.props.paneStatus],
            newStatus = PaneStatus.Collapsed;

        if (React.Children.toArray(this.props.children).filter(item => item).length === 2) {
            paneStatus[index === 0 ? 1 : 0] = PaneStatus.Expanded;
        } else {
            switch (index) {
                case 2 :
                    paneStatus[0] = paneStatus[1] === PaneStatus.Collapsed ? PaneStatus.Expanded : PaneStatus.Collapsed;
                    paneStatus[1] = paneStatus[1] === PaneStatus.Collapsed ? PaneStatus.Collapsed : PaneStatus.Expanded;
                    break;

                case 1:
                    if (paneStatus[1] === PaneStatus.Normal && paneStatus[2] === PaneStatus.Normal) {
                        paneStatus[0] = PaneStatus.Collapsed;
                        paneStatus[2] = PaneStatus.Expanded;
                    } else {
                        paneStatus[0] = paneStatus[1] === PaneStatus.Normal ? PaneStatus.Expanded : PaneStatus.Normal;
                        paneStatus[2] = paneStatus[1] === PaneStatus.Normal ? PaneStatus.Collapsed : PaneStatus.Normal;
                    }
                    break;
                case 0:
                    paneStatus[2] = paneStatus[1] === PaneStatus.Collapsed ? PaneStatus.Expanded : PaneStatus.Collapsed;
                    paneStatus[1] = paneStatus[1] === PaneStatus.Collapsed ? PaneStatus.Collapsed : PaneStatus.Expanded;
                    break;
            }
        }

        paneStatus[index] = newStatus;
        this.props.onPaneStatusChanged(paneStatus);
        this.focusFirstExpandedPane();
    };

    handlePaneClick = (ref: RefObject<HTMLElement>, index: number): void => {
        this.handleExpandPane(ref, index);
    };

    handleSplitterClick = (ref: RefObject<HTMLElement>, index: number): void => {
        if (this.state.paneStatus[index + 1] === PaneStatus.Collapsed) {
            this.handleExpandPane(ref, index + 1);
        }
    };

    focusFirstExpandedPane(): void {
        const layoutFocusableItemsSelector = `[${FOCUSABLE_ATTR}="${SPLIT_LAYOUT_FMID}"]`;
        const visibleStatuses = [PaneStatus.Expanded, PaneStatus.Normal];
        const selector = visibleStatuses
            .map(status => `${layoutFocusableItemsSelector}[data-status="${status}"]`)
            .join(", ");
        const visiblePanes = (this.layoutRef.current.querySelectorAll(selector) ?? []) as HTMLElement[];
        visiblePanes[0]?.focus?.();
    }

    render() {
        const children = React.Children.toArray(this.props.children) as ReactElement<IPaneProps>[];
        const visiblePaneCount = children.reduce((accumulator: number, item: ReactElement<IPaneProps>) => {
            return accumulator + +item.props.visible;
        }, 0);

        return (
            <FocusManager direction={FocusDirection.Horizontal} focusableId={SPLIT_LAYOUT_FMID}
                          allowTabbingToChildren>
                {({ itemProps, wrapperProps }) => (
                    <StyledSplitLayout data-testid={TestIds.Accordion} {...wrapperProps}
                                       ref={composeRefHandlers(this.layoutRef, wrapperProps.ref)}>
                        {children.map((pane: ReactElement<IPaneProps>, index) => {
                            const allowResize = this.props.paneStatus[index] === PaneStatus.Normal &&
                                this.props.paneStatus[index + 1] === PaneStatus.Normal;

                            if (!pane.props.visible) {
                                return null;
                            }

                            return React.cloneElement(pane, {
                                key: index,
                                index: index,
                                isSolo: visiblePaneCount === 1,
                                isResizable: allowResize,
                                status: this.props.paneStatus[index],
                                secondaryBookmark: pane.props.secondaryBookmark,
                                onSplitterClick: (ref: RefObject<HTMLElement>) => {
                                    this.handleSplitterClick(ref, index);
                                },
                                onPaneClick: (ref: React.RefObject<HTMLDivElement>) => {
                                    this.handlePaneClick(ref, index);
                                },
                                onMouseDown: (e: React.MouseEvent, ref: React.RefObject<HTMLDivElement>) => {
                                    this.handlePaneMove(e, ref);
                                },
                                onExpandPane: (ref: React.RefObject<HTMLDivElement>) => {
                                    this.handleExpandPane(ref, index);
                                },
                                onCollapsePane: (ref: React.RefObject<HTMLDivElement>) => {
                                    this.handleCollapsePane(ref, index);
                                },
                                ...itemProps
                            });
                        })}
                    </StyledSplitLayout>
                )}
            </FocusManager>
        );
    }
}