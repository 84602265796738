import { withDisplayName } from "@components/smart/GeneralFieldDefinition";
import {
    FilterBarGroup,
    getDefaultFilterGroupDef,
    IFilterGroupDef
} from "@components/smart/smartFilterBar/SmartFilterBar.types";
import { createPath } from "@odata/BindingContext";
import {
    EntitySetName,
    EntityTypeName,
    IInboxItemLogEntity,
    InboxItemLogEntity,
    UserEntity
} from "@odata/GeneratedEntityTypes";
import { InboxFileSourceCode, InboxItemLogStateCode } from "@odata/GeneratedEnums";
import { IFormatOptions, transformToODataString } from "@odata/OData.utils";
import i18next from "i18next";

import { Sort, ValueType } from "../../enums";
import { TValue } from "../../global.types";
import DateType, { DateFormat } from "../../types/Date";
import { ISplitPageTableDef } from "../../views/table/TableView.utils";
import { universalPersonNameFormatter } from "../admin/users/Users.utils";

/**
 * On customer approval screen, we want only to see log of actions done on that screen.
 */
function getCustomerApprovalLogFilter(): string {
    const states = [
        InboxItemLogStateCode.ApprovedByUser, InboxItemLogStateCode.RejectedByUser,
        InboxItemLogStateCode.ApprovementCanceledByUser, InboxItemLogStateCode.ApprovementResumedByUser,
    ];
    return `${InboxItemLogEntity.InboxItemLogStateCode} in (${transformToODataString(states, ValueType.String)})`;
}

export function getInboxItemLogDefinition(isCustomerApproval: boolean): ISplitPageTableDef {

    const filter = isCustomerApproval ? getCustomerApprovalLogFilter() : null;

    const filterBarDef: IFilterGroupDef[] = [{
        ...getDefaultFilterGroupDef(FilterBarGroup.Filters),
        isValueHelp: true,
        defaultFilters: [
            InboxItemLogEntity.Name,
            InboxItemLogEntity.DateReceived,
            InboxItemLogEntity.InboxFileSource,
            InboxItemLogEntity.InboxItemLogState,
            InboxItemLogEntity.Sender,
            InboxItemLogEntity.Subject,
            InboxItemLogEntity.DateCreated,
            InboxItemLogEntity.CreatedBy
        ],
        filterDefinition: {
            [InboxItemLogEntity.Name]: {},
            [InboxItemLogEntity.DateReceived]: {},
            [InboxItemLogEntity.InboxFileSource]: {
                fieldSettings: {
                    displayName: "Name"
                }
            },
            [InboxItemLogEntity.InboxItemLogState]: {
                fieldSettings: {
                    displayName: "Name"
                }
            },
            [InboxItemLogEntity.Sender]: {},
            [InboxItemLogEntity.Subject]: {},
            DateCreated: {
                label: i18next.t("Inbox:Audit.DateCreated")
            },
            ...withDisplayName(InboxItemLogEntity.CreatedBy, "Name", {
                label: i18next.t("Inbox:Audit.CreatedBy")
            })
        }
    }];

    return {
        filterBarDef,
        additionalProperties: [
            { id: createPath(InboxItemLogEntity.User, UserEntity.FirstName) },
            { id: createPath(InboxItemLogEntity.User, UserEntity.LastName) },
            { id: InboxItemLogEntity.EntityType }
        ],
        initialSortBy: [{
            id: InboxItemLogEntity.DateCreated,
            sort: Sort.Desc
        }],
        filter,
        columns: [
            InboxItemLogEntity.Name,
            InboxItemLogEntity.DateReceived,
            InboxItemLogEntity.InboxFileSource,
            InboxItemLogEntity.InboxItemLogState,
            InboxItemLogEntity.Sender,
            InboxItemLogEntity.Subject,
            InboxItemLogEntity.DateCreated,
            InboxItemLogEntity.CreatedBy
        ],
        columnDefinition: {
            [InboxItemLogEntity.Name]: {},
            [InboxItemLogEntity.DateReceived]: {},
            [InboxItemLogEntity.InboxItemLogState]: {
                fieldSettings: {
                    displayName: "Name"
                },
                formatter: (val: TValue, options: IFormatOptions) => {
                    const entity = options.entity as IInboxItemLogEntity;
                    const logState = entity.InboxItemLogStateCode;
                    const text = val as string;
                    const regex = /\{0}/;

                    switch (logState) {
                        case InboxItemLogStateCode.ApprovedByUser:
                        case InboxItemLogStateCode.RejectedByUser:
                        case InboxItemLogStateCode.IncludedByUser:
                        case InboxItemLogStateCode.ApprovementCanceledByUser:
                        case InboxItemLogStateCode.ApprovementResumedByUser:
                        case InboxItemLogStateCode.DeletedByUser:
                            return text.replace(regex, universalPersonNameFormatter(entity.User));
                        case InboxItemLogStateCode.IncludedIn:
                            return text.replace(regex, entity.EntityType?.Name ?? i18next.t("Inbox:DotsLabel"));
                        case InboxItemLogStateCode.ApprovedAutomaticallyDeliveredFromTrustedAddress:
                            return text.replace(regex, entity.Sender);
                        case InboxItemLogStateCode.ApprovedAutomaticallyDeliveredFromCustomerPortal:
                            return text.replace(regex, "");
                    }

                    return val as string;
                }
            },
            [InboxItemLogEntity.Sender]: {
                formatter: (val: TValue, options: IFormatOptions) => {
                    const entity = options.entity as IInboxItemLogEntity;

                    if (entity.InboxFileSourceCode === InboxFileSourceCode.Email) {
                        return val as string;
                    }

                    return "";
                }
            },
            [InboxItemLogEntity.Subject]: {},
            ...withDisplayName(InboxItemLogEntity.InboxFileSource, "Name"),
            DateCreated: {
                label: i18next.t("Inbox:Audit.DateCreated"),
                formatter: (val: TValue) => {
                    return DateType.localFormat(val as Date, DateFormat.dateAndTime);
                }
            },
            ...withDisplayName(InboxItemLogEntity.CreatedBy, "Name", {
                label: i18next.t("Inbox:Audit.CreatedBy")
            })
        },
        id: `${EntityTypeName.InboxItemLog}Table`,
        title: i18next.t("Audit:AuditTrail"),
        entitySet: EntitySetName.InboxItemLogs,
        preventStoreVariant: true
    };
}