import React, { PureComponent } from "react";
import { FormStorage } from "../../../views/formView/FormStorage";
import {
    EntitySetName,
    GeneralPermissionEntity,
    ICompanyPermissionEntity,
    IGeneralPermissionEntity,
    IGeneralRolePermissionEntity
} from "@odata/GeneratedEntityTypes";
import Switch, { SwitchType } from "../../../components/inputs/switch/Switch";
import { LeftColumn, PermissionGroup, PermissionsGrid } from "./GeneralRoles.styles";
import TestIds from "../../../testIds";
import BindingContext from "../../../odata/BindingContext";
import Checkbox, { ICheckboxChange } from "../../../components/inputs/checkbox";
import { CompanyPermissionCode, GeneralPermissionCode } from "@odata/GeneratedEnums";
import memoizeOne from "../../../utils/memoizeOne";

type PermissionCode = CompanyPermissionCode | GeneralPermissionCode;
const PermissionsWithOwnOnlyFlag: PermissionCode[] = [
    CompanyPermissionCode.InvoicesIssued,
    CompanyPermissionCode.DocumentPosting,
    CompanyPermissionCode.InvoicesReceived,
    CompanyPermissionCode.InternalAndCorrectiveDocuments
];

interface IProps {
    storage: FormStorage;
    roleId: number;
}

interface IPermissionSwitchProps {
    permission: IGeneralPermissionEntity | ICompanyPermissionEntity;
    onChange: (code: string, checked: boolean) => void;
    checked: boolean;
    onOwnOnlyChange?: (code: string, ownOnly: boolean) => void;
    ownOnly?: boolean;
    isReadOnly?: boolean;
    storage: FormStorage;
}

export class PermissionSwitch extends PureComponent<IPermissionSwitchProps> {
    handleSwitchChange = (checked: boolean): void => {
        this.props.onChange(this.props.permission.Code, checked);
    };

    handleOwnOnlyChange = (e: ICheckboxChange): void => {
        this.props.onOwnOnlyChange?.(this.props.permission.Code, e.value);
    };

    render() {
        const code = this.props.permission.Code as PermissionCode;
        const isReadOnly = this.props.isReadOnly || code === GeneralPermissionCode.OrganizationManagement;

        return <PermissionGroup data-testid={TestIds.PermissionComponent}>
            <LeftColumn>
                <h5 data-testid={TestIds.Name}>{this.props.permission.Name}</h5>
                <span data-testid={TestIds.Description}>{this.props.permission.Description}</span>
                {PermissionsWithOwnOnlyFlag.includes(code) &&
                    <Checkbox
                        isDisabled={!this.props.checked}
                        checked={this.props.ownOnly}
                        label={this.props.storage.t("CompanyRoles:OwnOnlyLabel")}
                        onChange={this.handleOwnOnlyChange}/>}
            </LeftColumn>
            <Switch
                checked={this.props.checked}
                onChange={this.handleSwitchChange}
                isReadOnly={isReadOnly}
                type={SwitchType.Icons}/>
        </PermissionGroup>;
    }
}

class PermissionsTable extends PureComponent<IProps> {
    componentDidMount = async (): Promise<void> => {
        const storage = this.props.storage as FormStorage;

        if (storage.data.bindingContext.isNew() && !storage.data.entity?.GeneralRolePermissions) {
            await this.init();
        }
    };

    // componentDidMount can be called during storage.init call
    // and CompanyRolePermissions would get deleted from the storage later
    // => we need to wait for the storage to be loaded
    componentDidUpdate = async (): Promise<void> => {
        const storage = this.props.storage as FormStorage;

        if (storage.data.bindingContext.isNew() && !storage.data.entity?.GeneralRolePermissions) {
            await this.init();
        }
    };

    init = async (): Promise<void> => {
        const permissions = await this.fetchPermissions();

        this.props.storage.data.entity.GeneralRolePermissions = permissions?.map((perm: IGeneralPermissionEntity) => ({
            Id: BindingContext.NEW_ENTITY_ID_PROP,
            PermissionCode: perm.Code,
            Permission: perm
        }));
        this.forceUpdate();
    };

    fetchPermissions = memoizeOne(async (): Promise<IGeneralPermissionEntity[]> => {
        const result = await this.props.storage.oData.getEntitySetWrapper(EntitySetName.GeneralPermissions)
            .query()
            .select(GeneralPermissionEntity.Code, GeneralPermissionEntity.Name, GeneralPermissionEntity.Description)
            .fetchData<IGeneralPermissionEntity[]>();

        return result.value;
    });

    handleChange = (code: string, checked: boolean): void => {
        const permissions = this.props.storage.data.entity.GeneralRolePermissions;
        const permission = permissions.find((role: IGeneralRolePermissionEntity) => role.Permission.Code === code);
        permission.IsEnabled = checked;
        this.forceUpdate();
    };

    render() {
        return <PermissionsGrid data-testid={TestIds.PermissionsGrid}>
            {this.props.storage.data.entity.GeneralRolePermissions?.map((role: IGeneralRolePermissionEntity) => {
                return <PermissionSwitch
                    storage={this.props.storage}
                    key={role.Permission.Code}
                    permission={role.Permission}
                    isReadOnly={this.props.storage.isReadOnly}
                    checked={role.IsEnabled}
                    onChange={this.handleChange}/>;
            })}
        </PermissionsGrid>;
    }
}

export default PermissionsTable;