import React, { useCallback, useContext } from "react";
import { IDashboardTileComponentProps } from "../../dashboard";
import { useTranslation } from "react-i18next";
import { BuyEvalaTitle, DaysLeftText, StyledPurchaseTile } from "./PurchaseTile.styles";
import { usePromisedComponent } from "../../dialog/withPromisedComponent";
import PurchaseWizardDialog from "../../purchaseWizardDialog/PurchaseWizardDialog";
import { getRemainingTrialPeriodDays } from "@pages/admin/subscriptions/Subscriptions.utils";
import { AppContext } from "../../../contexts/appContext/AppContext.types";

const PurchaseTile: React.FC<IDashboardTileComponentProps> = ({ inEditMode }) => {
    const { t } = useTranslation("Home");
    const { component, openComponent } = usePromisedComponent();
    const context = useContext(AppContext);
    const handleTileClick = useCallback(async () => {
        await openComponent<boolean>((onFinish) => {
            return (
                <PurchaseWizardDialog onClose={onFinish}/>
            );
        });
    }, [openComponent]);

    return (
        <>
            <StyledPurchaseTile onClick={handleTileClick}>
                <BuyEvalaTitle>
                    {t("Home:Purchase.Title")}
                </BuyEvalaTitle>
                <DaysLeftText>
                    {t("Home:Purchase.DemoTimeLeft", { count: getRemainingTrialPeriodDays(context.getData().subscription) })}
                </DaysLeftText>
            </StyledPurchaseTile>
            {component}
        </>
    );
};

export default PurchaseTile;