import { IDashboardDefinition, IDashboardLayoutConfig, TDashboardLayout } from "@components/dashboard";
import {
    createLayout,
    createLayoutFromDefinition,
    createTileFromDefinition,
    getOrderFromLayout,
    insertTileToLayout
} from "@components/dashboard/Dashboard.utils";
import { getInfoValue } from "@components/smart/FieldInfo";
import { forEachKey } from "@utils/general";
import { logger } from "@utils/log";
import i18next from "i18next";
import React from "react";

import { ContextEvents, IAppContext } from "../../contexts/appContext/AppContext.types";
import { P13nType } from "../../enums";
import { TRecordType } from "../../global.types";

export interface IDashboardManager {
    companyId: number;
    name: string;
    definition: IDashboardDefinition;
    config: IDashboardLayoutConfig;
    originalConfig: IDashboardLayoutConfig;
    availableTiles: Set<string>;

    subscribe: (ref: React.Component) => (() => void);
    setOriginalConfig: () => void;
    restoreOriginalConfig: () => void;
    updateLayout: (layout: IDashboardLayoutConfig) => void;
    toggleTile: (id: string, isVisible: boolean) => void;
    sync: () => void;
    removeConfig: () => void;
    language: string;
}

export function removeAllSavedDashboards(context: IAppContext): Promise<boolean> {
    return context.p13n.delete(P13nType.SavedDashboards);
}

export class DashboardManager {
    static managers: TRecordType<IDashboardManager> = {};

    static get(name: string): IDashboardManager {
        return DashboardManager.managers[name];
    }

    static register(name: string, manager: IDashboardManager): void {
        DashboardManager.managers[name] = manager;
    }

    /**
     * Clears the DashboardManager's managers object (cache).
     *
     * @returns {void}
     */
    static clear(): void {
        DashboardManager.managers = {};
    }
}

function getDashboardId(name: string, companyId?: number) {
    const suffix = companyId ? `(${companyId})` : "";
    return `${name}${suffix}`;
}

export async function DashboardManagerFactory(name: string, getDef: () => IDashboardDefinition, context: IAppContext): Promise<IDashboardManager> {
    let manager = DashboardManager.get(name);

    if (manager && i18next.language === manager.language) {
        return manager;
    }

    const definition = getDef();

    const subscribedComponents: React.Component[] = [];

    const subscribe = (ref: React.Component) => {
        subscribedComponents.push(ref);
        return /*unsubscribe*/() => {
            const index = subscribedComponents.findIndex(item => item === ref);
            subscribedComponents.splice(index, 1);
        };
    };

    const refresh = () => {
        subscribedComponents.forEach(component => component.forceUpdate());
    };

    manager = {
        companyId: null,
        name,
        definition,
        config: null,
        originalConfig: null,
        restoreOriginalConfig: null,
        setOriginalConfig: null,
        subscribe,
        availableTiles: null,
        updateLayout: null,
        toggleTile: null,
        sync: null,
        removeConfig: null,
        language: i18next.language
    };

    const loadConfig = async () => {
        const companyId = context.getCompanyId();

        const getVisibleTiles = () => {
            const visibleSet = new Set<string>();
            forEachKey(definition.tileDefinition, (id) => {
                try {
                    if (getInfoValue(definition.tileDefinition[id], "isVisible", { context }) !== false) {
                        visibleSet.add(id);
                    }
                } catch (e) {
                    // fall silently -> in case there is error in "isVisible" callback,
                    // we should not fail to initialize whole dashboard, just consider the tile as invisible
                    logger.error("DashboardTile misconfiguration: " + e.toString());
                }
            });
            return visibleSet;
        };

        // tiles, which are available for current user
        const availableTiles = getVisibleTiles();

        let savedConfig: IDashboardLayoutConfig;
        try {
            savedConfig = await context.p13n.get<IDashboardLayoutConfig>(P13nType.SavedDashboards, getDashboardId(name, companyId));

            if (savedConfig) {
                // filter out only tiles, which are present in the definition in case definition has changed since last save
                savedConfig.layout = savedConfig.layout?.filter(item => availableTiles.has(item.i));
            }
        } catch (e) { /*fall silently*/
        }

        manager.config = savedConfig ?? {
            cols: 0,
            layout: createLayoutFromDefinition(definition, availableTiles)
        };
        manager.availableTiles = availableTiles;
        manager.companyId = companyId;
    };

    manager.setOriginalConfig = () => {
        manager.originalConfig = {
            ...manager.config
        };
    };

    manager.restoreOriginalConfig = () => {
        manager.config = {
            ...manager.originalConfig
        };
    };

    manager.sync = () => {
        try {
            const layoutToSave = manager.config.layout.map(({ i, w, h, x, y }) => ({ i, w, h, x, y }));
            const configToSave = {
                cols: manager.config.cols,
                layout: layoutToSave
            };
            context.p13n.update<TRecordType<IDashboardLayoutConfig>>(P13nType.SavedDashboards, getDashboardId(name, manager.companyId), configToSave);
        } catch (e) { /*fall silently*/
        }
    };

    manager.removeConfig = () => {
        context.p13n.update<TRecordType<IDashboardLayoutConfig>>(P13nType.SavedDashboards, getDashboardId(name, manager.companyId), null);
    };

    manager.updateLayout = (newConfig: IDashboardLayoutConfig) => {
        manager.config = {
            ...newConfig
        };
        refresh();
    };

    manager.toggleTile = (id: string, isVisible: boolean) => {
        let layout: TDashboardLayout;
        let { cols } = manager.config;
        if (isVisible) {
            if (!manager.config.layout.find(item => item.i === id)) {
                layout = [...manager.config.layout];
                const tile = createTileFromDefinition(id, definition.tileDefinition[id]);
                if (tile.w > cols) {
                    cols = tile.w;
                }
                insertTileToLayout(layout, cols, tile);
            }
        } else {
            layout = manager.config.layout.filter(item => item.i !== id);
            const orderedTiles = getOrderFromLayout(layout);
            const recalculated = createLayout(orderedTiles, cols);
            layout = recalculated.layout;
            cols = recalculated.cols;
        }
        manager.config = {
            cols,
            layout
        };
        refresh();
    };

    context.eventEmitter.on(ContextEvents.CompanyChanged, async () => {
        const currentCompanyId = context.getCompanyId();
        if (currentCompanyId !== manager.companyId) {
            // reload tile config on dashboard when company changes
            await loadConfig();
            refresh();
        }
    });

    // initialLoad...
    await loadConfig();

    DashboardManager.register(name, manager);
    return manager;
}