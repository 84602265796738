import React from "react";
import { ActionState, RowAction, Sort, TextAlign, ToggleState } from "../../../enums";
import {
    EntitySetName,
    IBackgroundJobEntity,
    IBankStatementEntity,
    IBankTransactionEntity,
    ICompanyBankAccountEntity
} from "@odata/GeneratedEntityTypes";
import DateType from "../../../types/Date";
import { SmartTableBase } from "@components/smart/smartTable/SmartTableBase";
import { formatBankAccountWithoutType } from "@utils/BankUtils";
import { Button, ButtonGroup } from "../../../components/button/Button";
import { BatchRequest } from "@odata/OData";
import Tabs, { ITabsProps } from "../../../components/tabs/Tabs";
import { IRow, ISort, TId } from "@components/table";
import {
    ConfirmationHeader,
    ConfirmationHeaderAccount,
    ConfirmationHeaderAccountNumber,
    ConfirmationHeaderName,
    SmartTableWrapper,
    Subtitle
} from "./BankTransactions.styles";
import Dialog from "../../../components/dialog/Dialog";
import { bankAmountFormatter } from "./BankTransactionsDef";
import { BankTransactionTypeCode, CompanyBankAccountTypeCode } from "@odata/GeneratedEnums";
import { BANK_STATEMENTS } from "../../../constants";
import { getConfirmationActionText } from "../../../views/table/TableView.render.utils";
import { bankTransactionTypeFormatter } from "./BankTransactions.utils";
import { sortCompareFn } from "@utils/general";
import { ODataQueryResult } from "@odata/ODataParser";
import { getDefaultPostParams } from "@utils/customFetch";
import { WithBusyIndicator, withBusyIndicator } from "@components/busyIndicator/withBusyIndicator";
import TestIds from "../../../testIds";
import { WithOData, withOData } from "@odata/withOData";
import { WithTranslation, withTranslation } from "react-i18next";

interface IProps extends WithBusyIndicator, WithTranslation, WithOData {
    backgroundJob: IBackgroundJobEntity;
    onClose: () => void;
}

interface IState {
    duplicates: IBankTransactionEntity[];
    validItems: IBankTransactionEntity[];
    sort: ISort[];
    bankStatement?: IBankStatementEntity;
}

const accountFormatter = (acc: ICompanyBankAccountEntity) => {
    return formatBankAccountWithoutType(acc);
};

class ConfirmationTransactionsDialog extends React.Component<IProps, IState> {
    checkedDuplicates: Record<string, boolean> = {};
    checkedValid: Record<string, boolean> = {};

    state: IState = {
        validItems: [],
        duplicates: [],
        sort: [{
            id: "DateBankTransaction",
            sort: Sort.Asc
        }]
    };

    async componentDidMount() {
        const bankStatementId = this.bankStatementId;
        const batch: BatchRequest = this.props.oData.batch();

        batch.getEntitySetWrapper(EntitySetName.BankTransactions).query()
            .filter(`BankStatement/Id eq ${bankStatementId} AND IsDuplicate eq true`)
            .queryParameters({ CompanyId: this.props.backgroundJob.Company.Id.toString() });
        batch.getEntitySetWrapper(EntitySetName.BankStatements).query(bankStatementId.toString())
            .expand("BankAccount")
            .expand("Transactions", q => {
                q.expand("BankAccount");
            })
            .select("Id")
            .queryParameters({ CompanyId: this.props.backgroundJob.Company.Id.toString() });

        const result = await batch.execute();
        const duplicates: IBankTransactionEntity[] = (result[0]?.body as ODataQueryResult<IBankTransactionEntity[]>)?.value;
        const bankStatement = (result[1]?.body as ODataQueryResult<IBankStatementEntity>)?.value;

        if (duplicates && bankStatement) {
            const validItems = bankStatement.Transactions.filter((item: IBankTransactionEntity) => {
                return !duplicates.find((duplicate: IBankTransactionEntity) => duplicate.Id === item.Id);
            });

            for (const item of duplicates) {
                this.checkedDuplicates[item.Id] = true;
            }
            for (const item of validItems) {
                this.checkedValid[item.Id] = true;
            }

            this.setState({
                duplicates,
                validItems,
                bankStatement
            });
        }
    }

    get bankStatementId(): number {
        const bankStatement = JSON.parse(this.props.backgroundJob.Result) as IBankStatementEntity[];

        return bankStatement[0].Id;
    }

    getRows = (rows: IBankTransactionEntity[]) => {
        return rows.map(item => ({
            customData: {
                origData: {
                    Amount: item.Amount,
                    BankAccount: item.BankAccount,
                    RemittanceInformation: item.RemittanceInformation,
                    BankTransactionType: item.BankTransactionTypeCode,
                    PaymentInformation: item.PaymentInformation,
                    DateBankTransaction: item.DateBankTransaction
                }
            },
            values: {
                Amount: bankAmountFormatter(item.Amount, { item }),
                BankAccount: accountFormatter(item.BankAccount),
                RemittanceInformation: item.RemittanceInformation,
                PaymentInformation: item.PaymentInformation,
                BankTransactionType: bankTransactionTypeFormatter(item.BankTransactionTypeCode as BankTransactionTypeCode),
                DateBankTransaction: DateType.format(item.DateBankTransaction)
            },
            id: item.Id
        })).sort((item1: IRow, item2: IRow) => {
            const col = (this.state.sort[0]?.id?.toString() ?? "DateBankTransaction") as keyof IBankTransactionEntity;
            const order = this.state.sort[0]?.sort ?? Sort.Asc;

            let val1 = item1.customData.origData[col];
            let val2 = item2.customData.origData[col];

            if (col === "BankAccount") {
                // for BankAccount we want to used formatted data (as it is formatted from multiple values and object)
                val1 = item1.values[col];
                val2 = item2.values[col];
            }

            return sortCompareFn(val1, val2, order);
        });
    };

    toggleMainCheckbox = (items: IBankTransactionEntity[], checkCollection: Record<string, boolean>) => {
        const count = items?.length || 0;
        const checkedCount = Object.keys(checkCollection).length;

        if (count === checkedCount) {
            for (const key of Object.keys(checkCollection)) {
                delete checkCollection[key];
            }
        } else {
            for (const duplicate of items) {
                checkCollection[duplicate.Id] = true;
            }
        }

        this.forceUpdate();
    };

    getRowAction = (checkCollection: Record<string, boolean>, items: IBankTransactionEntity[]) => {
        const isToggled = Object.keys(checkCollection).length === items.length;


        return {
            actionType: RowAction.Check,
            getActionState: (rowId: TId) => {
                return checkCollection[rowId as string] ? ActionState.Active : ActionState.Inactive;
            },
            onClick: (rowId: TId) => {
                const key = rowId as string;
                if (checkCollection[key]) {
                    delete checkCollection[key];
                } else {
                    checkCollection[key] = true;
                }

                this.forceUpdate();
            },
            onToggleChange: this.toggleMainCheckbox.bind(null, items, checkCollection),
            toggleState: isToggled ? ToggleState.AllChecked : ToggleState.AllUnchecked
        };
    };

    handleSort = (sort: ISort[]) => {
        this.setState({
            sort: sort
        });
    };

    renderTable = (items: IBankTransactionEntity[], checkCollection: Record<string, boolean>, noDataText: string) => {
        const columns = [
            {
                id: "BankTransactionType",
                label: this.props.t("Banks:ConfirmationDialog.Type"),
                textAlign: TextAlign.Center
            },
            { id: "DateBankTransaction", label: this.props.t("Banks:ConfirmationDialog.Date") },
            {
                id: "Amount",
                textAlign: TextAlign.Right,
                label: this.props.t("Banks:ConfirmationDialog.Amount")
            },
            {
                id: "BankAccount",
                label: this.props.t("Banks:ConfirmationDialog.AccountNumber"),
                additionalProperties: [{ id: "BankCode" }, { id: "AbaNumber" }, { id: "IBAN" }, { id: "AccountNumber" }]
            },
            {
                id: "PaymentInformation",
                label: this.props.t("Banks:ConfirmationDialog.PaymentInformation")
            },
            {
                id: "RemittanceInformation",
                label: this.props.t("Banks:ConfirmationDialog.Note")
            }
        ];

        return (
            <SmartTableWrapper>
                <SmartTableBase
                    rows={this.getRows(items)}
                    sort={this.state.sort}
                    onSortChange={this.handleSort}
                    tableId="Pairing"
                    rowAction={this.getRowAction(checkCollection, items)}
                    columns={columns}
                    noDataText={noDataText}
                    loaded={true}/>
            </SmartTableWrapper>
        );
    };

    renderHeader = () => {
        const acc = this.state.bankStatement.BankAccount;
        const name = acc?.Name;
        const number = acc.CompanyBankAccountTypeCode === CompanyBankAccountTypeCode.PaymentService ? acc.PaymentServiceID : accountFormatter(acc);

        return (
            <ConfirmationHeader data-testid={TestIds.ConfirmationHeader}>
                <ConfirmationHeaderAccount>{this.props.t("Banks:Pairing.FromAccount")}:</ConfirmationHeaderAccount>
                <ConfirmationHeaderName>{name}</ConfirmationHeaderName>
                <ConfirmationHeaderAccountNumber>{`| ${number}`}</ConfirmationHeaderAccountNumber>
            </ConfirmationHeader>
        );
    };

    renderContent = () => {
        return (<>
            {this.renderHeader()}
            {this.renderTabs()}
        </>);
    };

    handleClose = () => {
        this.props.onClose();
    };

    renderTabs = () => {
        const data = [{
            id: "valid", title: this.props.t("Banks:ConfirmationDialog.OtherTransactions"),
            content: (props: ITabsProps) => {
                return this.renderTable(this.state.validItems, this.checkedValid, this.props.t("Banks:ConfirmationDialog.NoRecordTextValid"));
            }
        }, {
            id: "duplicates", title: this.props.t("Banks:ConfirmationDialog.Duplicite"),
            content: (props: ITabsProps) => {
                return this.renderTable(this.state.duplicates, this.checkedDuplicates, this.props.t("Banks:ConfirmationDialog.NoRecordTextDuplicate"));
            }
        }];

        const values = [{
            id: "valid",
            value: this.state.validItems.length
        }, {
            id: "duplicates",
            value: this.state.duplicates.length
        }];

        return (
            <Tabs height="calc(100% - 49px)"
                  data={data} values={values}/>
        );
    };

    getConfirmAmount = () => {
        return (Object.keys(this.checkedDuplicates).length || 0) + (Object.keys(this.checkedValid).length || 0);
    };

    getConfirmText = () => {
        const sum = this.getConfirmAmount();

        return getConfirmationActionText(this.props.t("Banks:Pairing.Confirm"), sum);
    };


    handleCancelAll = async () => {
        this.props.setBusy(true);
        const items = this.state.validItems.concat(this.state.duplicates);

        await this.handleItems([], items);
        this.handleClose();
    };

    handleItems = async (toProcess: IBankTransactionEntity[], toDel: IBankTransactionEntity[]) => {
        if (toProcess?.length > 0 || toDel?.length > 0) {
            // use fetch instead of customFetch to inject custom company Id
            await fetch(`${BANK_STATEMENTS}/SubmitDuplicateCheck?CompanyId=${this.props.backgroundJob.Company.Id}`, {
                ...getDefaultPostParams(),
                body: JSON.stringify({
                    ApprovedTransactionIds: toProcess?.map(item => item.Id),
                    RejectedTransactionIds: toDel?.map(item => item.Id),
                    BackgroundJobId: this.props.backgroundJob.Id,
                    BankAccountId: this.state.bankStatement.BankAccount.Id
                })
            });
        }
    };

    handleConfirm = async () => {
        this.props.setBusy(true);
        const toProcessDuplicates = this.state.duplicates.filter((dupl: IBankTransactionEntity) => !!this.checkedDuplicates[dupl.Id]);
        const toDelDuplicates = this.state.duplicates.filter((dupl: IBankTransactionEntity) => !this.checkedDuplicates[dupl.Id]);

        const toProcessValid = this.state.validItems.filter((dupl: IBankTransactionEntity) => !!this.checkedValid[dupl.Id]);
        const toDelValid = this.state.validItems.filter((dupl: IBankTransactionEntity) => !this.checkedValid[dupl.Id]);

        const toProcessItems = toProcessDuplicates.concat(toProcessValid);
        const toDelItems = toDelDuplicates.concat(toDelValid);

        await this.handleItems(toProcessItems, toDelItems);
        this.handleClose();
    };

    handleCloseWithoutCancel = () => {
        this.handleClose();
    };

    renderSubtitle = () => {
        return (
            <Subtitle>
                {this.state.duplicates?.length > 0 ? this.props.t("Banks:ConfirmationDialog.Duplicates") : this.props.t("Banks:ConfirmationDialog.NotDuplicates")}
            </Subtitle>
        );
    };

    render() {
        // ALWAYS render dialog immediately (with busy state) instead of returning null,
        // data fetching can take dozens of seconds, and user cant wait with blank screen..
        const isBusy = (this.state.validItems.length === 0 && this.state.duplicates.length === 0) || !this.state.bankStatement.BankAccount?.Id;
        const isConfirmDisabled = this.getConfirmAmount() === 0;

        return (
            <Dialog
                height="80%"
                width="1000px"
                busy={isBusy}
                title={this.props.t("Banks:ConfirmationDialog.Title")}
                onConfirm={isConfirmDisabled ? null : this.handleConfirm}
                onClose={this.handleCloseWithoutCancel}
                isEditableWindow={false}
                footer={
                    <ButtonGroup>
                        <Button onClick={this.handleCloseWithoutCancel} isTransparent>
                            {this.props.t("Common:General.Cancel")}
                        </Button>
                        <Button onClick={this.handleCancelAll} isTransparent>
                            {this.props.t("Banks:Pairing.Cancel")}
                        </Button>
                        <Button onClick={this.handleConfirm} isDisabled={isConfirmDisabled}>
                            {this.getConfirmText()}
                        </Button>
                    </ButtonGroup>
                }>
                {isBusy ? null : this.renderContent()}
                {this.props.busyIndicator}
            </Dialog>
        );
    }
}

export default withTranslation(["Common", "Banks"])(withOData(withBusyIndicator({ passBusyIndicator: true })(ConfirmationTransactionsDialog)));