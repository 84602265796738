import styled, { css } from "styled-components/macro";

import { LocalStackOrder } from "../global.style";

export const VIEW_PADDING_VALUE = 38;

interface IView {
    withoutPadding: boolean;
    withoutMinWidth?: boolean;
    isFullscreen?: boolean;
}

export const FullScreenViewZIndex = LocalStackOrder.AboveOverlay + 1;

// use wrapper div to push down the content and scrollbar
// but keep the scrollbar on the edge of the page and only use padding for the content in StyledView
export const StyledViewWrapper = styled.div<IView>`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;

    ${props => !props.withoutPadding && css`
        padding-top: ${VIEW_PADDING_VALUE}px;
    `}
    ${props => props.isFullscreen && css`
        position: fixed;
        top: 0;
        left: 0;
        z-index: ${FullScreenViewZIndex};
        background-color: ${props => props.theme.C_BG_floorplan};
    `}
`;

export const StyledView = styled.div<IView>`
    position: relative;
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    height: 100%;
    ${props => !props.withoutMinWidth && css`
        min-width: 300px;
    `};
    ${props => !props.withoutPadding && css`
        padding: 0 ${VIEW_PADDING_VALUE}px ${VIEW_PADDING_VALUE}px;
    `};
`;

export const FixedFooter = styled.div<IView>`

    ${props => !props.withoutPadding && css`
        padding: 0 ${VIEW_PADDING_VALUE}px ${VIEW_PADDING_VALUE}px;
    `};
`;