import { DefaultTheme } from "styled-components/macro";

export interface IDayAction {
    color: keyof DefaultTheme;
    title: string;
    isFullDay: boolean;
    isStart: boolean;
    isEnd: boolean;
    hasConflict?: boolean;
    hideTitle?: boolean;
}

export const TileSize = {
    MIN: 51,
    S: 51,
    M: 60,
    L: 81,
    MAX: 104
};
