import React from "react";
import { AnimationItem, AnimationSegment } from "lottie-web";
import { handleRefHandlers } from "@utils/general";
import { AppContext } from "../contexts/appContext/AppContext.types";

export interface IProps {
    path: string;
    darkModePath: string;
    animationRef?: React.Ref<AnimationItem>;
    className?: string;
    style?: React.CSSProperties;
    autoplay?: boolean;
    loop?: boolean;
    initialSegment?: AnimationSegment;
    "data-testid"?: string;
}

/** Wraps the lottie library into simple React component */
export default class LottieWrapper extends React.PureComponent<IProps> {
    static contextType = AppContext;

    rootRef = React.createRef<HTMLDivElement>();
    animRef = React.createRef<AnimationItem>();

    componentDidMount() {
        this.init();
    }

    componentWillUnmount() {
        this.animRef.current?.destroy();
    }

    get isDarkMode(): boolean {
        return this.context?.getData()?.userSettings?.ColourThemeCode === "Dark";
    }

    get path(): string {
        return this.isDarkMode ? this.props.darkModePath : this.props.path;
    }

    async init(): Promise<void> {
        try {
            // smaller bundle, only have 'svg' renderer
            const { default: lottie } = await import("lottie-web/build/player/lottie_light");
            const { path } = this;

            const animationRef = lottie.loadAnimation({
                container: this.rootRef.current,
                renderer: "svg",
                initialSegment: this.props.initialSegment,
                loop: this.props.loop ?? true,
                autoplay: this.props.autoplay ?? true,
                path
            });

            if (this.props.animationRef) {
                handleRefHandlers(animationRef, this.props.animationRef, this.animRef);
            }
        } catch (e) {
            /* in case animation can't be rendered on some device, just ignore it... e.g. in unit tests */
        }
    }

    render() {
        return (
            <div className={this.props.className}
                 style={this.props.style}
                 ref={this.rootRef}
                 data-testid={this.props["data-testid"]}>
            </div>
        );
    }
}