import React from "react";
import { FormStorage } from "../../../../../views/formView/FormStorage";
import {
    ICorrectiveInvoiceIssuedEntity,
    ICorrectiveInvoiceReceivedEntity,
    IRegularDocumentItemEntity
} from "@odata/GeneratedEntityTypes";
import { IColumn, IRow } from "@components/table";
import { RowType, TextAlign } from "../../../../../enums";
import SimpleTable from "../../../../../components/simpleTable/SimpleTable";
import { withTranslation, WithTranslation } from "react-i18next";
import { DASH_CHARACTER } from "../../../../../constants";
import { formatCurrency } from "../../../../../types/Currency";
import BindingContext from "../../../../../odata/BindingContext";
import { IDocumentExtendedEntity } from "../../../DocumentInterfaces";

export const CorrectiveItemsSummaryPath = BindingContext.localContext("CorrectiveItemsSummary");

interface IProps extends WithTranslation {
    storage: FormStorage;
}

class CorrectiveItemsSummary extends React.Component<IProps> {
    constructor(props: IProps) {
        super(props);

        // register CorrectiveItemsSummary on storage, so that it can be refreshed as any other field
        props.storage.addRef(this, props.storage.data.bindingContext.navigate(CorrectiveItemsSummaryPath));
    }

    componentWillUnmount() {
        this.props.storage.removeRef(this, this.props.storage.data.bindingContext.navigate(CorrectiveItemsSummaryPath));
    }

    render() {
        const document = this.props.storage.data.entity as (ICorrectiveInvoiceReceivedEntity | ICorrectiveInvoiceIssuedEntity);
        const originalDocuments: IDocumentExtendedEntity[] = document.CorrectedDocuments ?? [];
        const allOriginalItems = originalDocuments.flatMap(document => document.DocumentItems ?? document.Items).filter(v => v);
        const correctionItems = document.Items as IRegularDocumentItemEntity[];
        const currency = document.TransactionCurrency?.Code ?? document.TransactionCurrencyCode;

        let taxBaseOriginal = 0;
        let taxBaseCorrection = 0;
        let vatOriginal = 0;
        let vatCorrection = 0;
        const isEmpty = !allOriginalItems || allOriginalItems.length === 0;

        if (!isEmpty) {
            for (const origItem of allOriginalItems) {
                // skip items with wrong values
                if (Number.isNaN(origItem.TransactionAmountNet) || Number.isNaN(origItem.TransactionAmountVat)) {
                    continue;
                }

                taxBaseOriginal += origItem.TransactionAmountNet ?? 0;
                vatOriginal += origItem.TransactionAmountVat ?? 0;
            }

            for (const correctiveItem of (correctionItems ?? [])) {
                // skip items with wrong values
                if (Number.isNaN(correctiveItem.TransactionAmountNet) || Number.isNaN(correctiveItem.TransactionAmountVat)) {
                    continue;
                }

                taxBaseCorrection += correctiveItem.TransactionAmountNet ?? 0;
                vatCorrection += correctiveItem.TransactionAmountVat ?? 0;
            }
        }

        const columns: IColumn[] = [
            { id: "Special", label: "", textAlign: TextAlign.Right, isBold: true, ignoreDividers: true },
            {
                id: "Original",
                label: this.props.t("Document:CorrectiveItemsSummary.Original"),
                textAlign: TextAlign.Right
            },
            {
                id: "Correction",
                label: this.props.t("Document:CorrectiveItemsSummary.Correction"),
                textAlign: TextAlign.Right
            },
            {
                id: "AfterCorrection",
                label: this.props.t("Document:CorrectiveItemsSummary.AfterCorrection"),
                textAlign: TextAlign.Right
            }
        ];

        const taxBaseSum = taxBaseOriginal + taxBaseCorrection;
        const vatSum = vatOriginal + vatCorrection;
        const rows: IRow[] = [];

        rows.push(
            {
                id: "taxBase",
                values: {
                    Special: this.props.t("Document:CorrectiveItemsSummary.TaxBase") as string,
                    Original: isEmpty ? DASH_CHARACTER : formatCurrency(taxBaseOriginal, currency),
                    Correction: isEmpty ? DASH_CHARACTER : formatCurrency(taxBaseCorrection, currency),
                    AfterCorrection: isEmpty ? DASH_CHARACTER : formatCurrency(taxBaseSum, currency)
                }
            },
            {
                id: "vat",
                values: {
                    Special: this.props.t("Document:CorrectiveItemsSummary.Vat") as string,
                    Original: isEmpty ? DASH_CHARACTER : formatCurrency(vatOriginal, currency),
                    Correction: isEmpty ? DASH_CHARACTER : formatCurrency(vatCorrection, currency),
                    AfterCorrection: isEmpty ? DASH_CHARACTER : formatCurrency(vatSum, currency)
                }
            },
            {
                id: "sum",
                type: RowType.Aggregation,
                values: {
                    Special: "",
                    Original: isEmpty ? DASH_CHARACTER : formatCurrency(taxBaseOriginal + vatOriginal, currency),
                    Correction: isEmpty ? DASH_CHARACTER : formatCurrency(taxBaseCorrection + vatCorrection, currency),
                    AfterCorrection: isEmpty ? DASH_CHARACTER : formatCurrency(taxBaseSum + vatSum, currency)
                }
            }
        );

        return <SimpleTable columns={columns} rows={rows}/>;
    }

}

export default withTranslation(["Document"])(CorrectiveItemsSummary);