import { IFieldDef, IGetValueArgs } from "@components/smart/FieldInfo";
import {
    getAddressFields,
    hasOptionalPostalCode,
    PhoneNumberDef,
    phoneNumberInputFormatter,
    SingleBusinessPartnerDef
} from "@components/smart/GeneralFieldDefinition";
import { getCollapsedGroupId } from "@components/smart/Smart.utils";
import { ISmartFieldBlur, ISmartFieldChange } from "@components/smart/smartField/SmartField";
import { IFormGroupDef } from "@components/smart/smartFormGroup/SmartFormGroup";
import BindingContext, { createPath, IEntity } from "@odata/BindingContext";
import { setNestedValue } from "@odata/Data.utils";
import {
    AccountAssignmentSelectionEntity,
    BankTransactionEntity,
    BusinessPartnerBankAccountEntity,
    BusinessPartnerContactEntity,
    BusinessPartnerEntity,
    BusinessPartnerIssuedDocumentDefaultEntity,
    DocumentBusinessPartnerEntity,
    DocumentEntity,
    EntitySetName,
    IBankAccountEntity,
    IBankTransactionBusinessPartnerEntity,
    IBusinessPartnerBankAccountEntity,
    IBusinessPartnerContactEntity,
    IBusinessPartnerEntity,
    IBusinessPartnerIssuedDocumentDefaultEntity,
    IBusinessPartnerReceivedDocumentDefaultEntity,
    ICompanyBankAccountEntity,
    ICountryEntity,
    IDocumentBusinessPartnerEntity,
    IDocumentItemEntity,
    IPrDeductionBusinessPartnerEntity
} from "@odata/GeneratedEntityTypes";
import {
    CountryCode,
    CurrencyCode,
    DocumentTypeCode,
    PaymentMethodCode,
    SelectionCode,
    VatStatusCode
} from "@odata/GeneratedEnums";
import { BatchRequest, OData } from "@odata/OData";
import { isAccountAssignmentCompany } from "@utils/CompanyUtils";
import { areObjectsEqual, isDefined, isObjectEmpty } from "@utils/general";
import { logger } from "@utils/log";
import i18next from "i18next";

import { ARES_GET_API } from "../../constants";
import {
    BasicInputSizes,
    CacheStrategy,
    FieldType,
    GroupedField,
    NavigationSource,
    TextAlign,
    ValidatorType
} from "../../enums";
import { setGroupStatus } from "../../views/formView/Form.utils";
import { FormStorage, IFormStorageDefaultCustomData } from "../../views/formView/FormStorage";
import {
    convertAccountAssignmentIntoSelection,
    loadAccountAssignments,
    rewriteAccountAssignment
} from "../accountAssignment/AccountAssignment.utils";
import {
    appendAdditionalItemsToSavedBankAccounts,
    BankAccountDependentFields,
    hasEmptyBankAccount,
    IBankAccountArgs,
    IBankAccountsCustomData,
    prepareSavedAccounts,
    TBankAccountRelatedEntity,
    updateAllSavedAccountsOnPartnerChange
} from "../banks/bankAccounts/BankAccounts.utils";
import { getCompanyCountryCode, getCompanyCurrencyCode, getCompanyUsedCurrencyCodes } from "../companies/Company.utils";
import { isReceived, refreshExchangeRate } from "../documents/Document.utils";
import { IDocumentExtendedEntity } from "../documents/DocumentInterfaces";
import { TFieldDefinition, TFieldsDefinition } from "../PageUtils";
import { invalidateItems } from "@components/smart/smartSelect/SmartSelectAPI";


export const ReceivedBankAccountDefaultsPath = "##ReceivedBankAccount##";
export const ShowReceivedDefaultsPath = "##ShowReceivedDefaults##";
export const ShowIssuedDefaultsPath = "##ShowIssuedDefaults##";

export const BusinessPartnerSaveErrors = [
    "BusinessPartnerCombinationOfNameAndLegalNumberAlreadyExists",
    "BusinessPartnerCombinationOfNameAndAddressAlreadyExists"
];
export const BusinessPartnerLegalNumberPath = "BusinessPartner/LegalNumber";

export interface IBusinessPartnerEntityExtended extends IBusinessPartnerEntity {
    [ReceivedBankAccountDefaultsPath]?: number;
    [ShowReceivedDefaultsPath]?: boolean;
    [ShowIssuedDefaultsPath]?: boolean;
}

export interface IBusinessPartnerCustomData extends IFormStorageDefaultCustomData {
    businessPartnerContacts?: IBusinessPartnerContactEntity[];
    // true if the current business partner was selected from ARES select
    isBusinessPartnerFromAres?: boolean;
}

export const BankCoreBusinessPartnerAdditionalProperties = [{
    id: createPath(DocumentEntity.BusinessPartner, BusinessPartnerEntity.Name)
}, {
    id: createPath(DocumentEntity.BusinessPartner, BusinessPartnerEntity.LegalNumber)
}, {
    id: createPath(DocumentEntity.BusinessPartner, BusinessPartnerEntity.VatStatus)
}, {
    id: createPath(DocumentEntity.BusinessPartner, DocumentBusinessPartnerEntity.TaxNumber)
}, {
    id: createPath(DocumentEntity.BusinessPartner, DocumentBusinessPartnerEntity.FirstName)
}, {
    id: createPath(DocumentEntity.BusinessPartner, DocumentBusinessPartnerEntity.LastName)
}, {
    id: createPath(DocumentEntity.BusinessPartner, DocumentBusinessPartnerEntity.PhoneNumber)
}, {
    id: createPath(DocumentEntity.BusinessPartner, DocumentBusinessPartnerEntity.Email)
}, {
    id: createPath(DocumentEntity.BusinessPartner, DocumentBusinessPartnerEntity.Street)
}, {
    id: createPath(DocumentEntity.BusinessPartner, DocumentBusinessPartnerEntity.City)
}, {
    id: createPath(DocumentEntity.BusinessPartner, DocumentBusinessPartnerEntity.Country)
}, {
    id: createPath(DocumentEntity.BusinessPartner, DocumentBusinessPartnerEntity.PostalCode)
}];


export const getBusinessPartnerBankAccountAdditionalProperties = (prefix = "", withoutIsDefaultForRecDocs?: boolean): IFieldDef[] => {
    const fields = [
        BusinessPartnerBankAccountEntity.CountryCode, BusinessPartnerBankAccountEntity.AccountNumber,
        BusinessPartnerBankAccountEntity.BankCode, BusinessPartnerBankAccountEntity.IBAN,
        BusinessPartnerBankAccountEntity.SWIFT, BusinessPartnerBankAccountEntity.AbaNumber
    ];

    if (!withoutIsDefaultForRecDocs) {
        fields.push(BusinessPartnerBankAccountEntity.IsDefaultForReceivedDocuments);
    }

    return fields.map(field => {
        if (prefix) {
            return {
                id: `${prefix}/${field}`
            };
        } else {
            return {
                id: field
            };
        }
    });
};

export const DocumentBusinessPartnerAdditionalProperties = {
    id: createPath(DocumentEntity.BusinessPartner, DocumentBusinessPartnerEntity.BusinessPartner),
    additionalProperties: [
        {
            id: BusinessPartnerEntity.BankAccounts,
            additionalProperties: getBusinessPartnerBankAccountAdditionalProperties()
        },
        {
            id: BusinessPartnerEntity.Contacts,
            additionalProperties: [{
                id: BusinessPartnerContactEntity.FirstName
            }, {
                id: BusinessPartnerContactEntity.LastName
            }, {
                id: BusinessPartnerContactEntity.Email
            }, {
                id: BusinessPartnerContactEntity.PhoneNumber
            }]
        },
        {
            id: createPath(BusinessPartnerEntity.IssuedDocumentDefault, BusinessPartnerIssuedDocumentDefaultEntity.DaysDue)
        }
    ]
};

export const getBusinessPartnerGroup = (file?: string): IFormGroupDef => {
    return {
        id: "partner",
        title: i18next.t(file ? `${file}:FormGroup.BusinessPartner` : "Common:General.BusinessPartner"),
        rows: [[{ id: "BusinessPartner/Name" }, { id: "BusinessPartner/LegalNumber" }]]
    };
};
export const businessPartnerDependentFields = (bpPath = "BusinessPartner") => ([
    {
        from: { id: "Id" },
        to: { id: `${bpPath}/BusinessPartner` }
    }, {
        from: { id: "LegalNumber" },
        to: { id: `${bpPath}/LegalNumber` }
    }, {
        from: { id: "Name" },
        to: { id: `${bpPath}/Name` }
    }, {
        from: { id: "TaxNumber" },
        to: { id: `${bpPath}/TaxNumber` }
    }, {
        from: { id: "VatStatus" },
        to: { id: `${bpPath}/VatStatus` }
    }, {
        from: { id: "Contacts/0/FirstName" },
        to: { id: `${bpPath}/FirstName` }
    }, {
        from: { id: "Contacts/0/LastName" },
        to: { id: `${bpPath}/LastName` }
    }, {
        from: { id: "Contacts/0/PhoneNumber" },
        to: { id: `${bpPath}/PhoneNumber` }
    }, {
        from: { id: "Contacts/0/Email" },
        to: { id: `${bpPath}/Email` }
    }, {
        from: { id: "BillingAddress/Street" },
        to: { id: `${bpPath}/Street` }
    }, {
        from: { id: "BillingAddress/City" },
        to: { id: `${bpPath}/City` }
    }, {
        from: { id: "BillingAddress/Country" },
        to: { id: `${bpPath}/Country` }
    }, {
        from: { id: "BillingAddress/PostalCode" },
        to: { id: `${bpPath}/PostalCode` }
    }
]);

export const accountPartnerDepFields = businessPartnerDependentFields().map(field => ({
    ...field,
    from: { id: `BusinessPartner/${field.from.id}` }
}));

export const BusinessPartnerDef = (bpPath = "BusinessPartner"): TFieldDefinition => ({
    ...SingleBusinessPartnerDef,
    fieldSettings: {
        ...SingleBusinessPartnerDef.fieldSettings,
        localDependentFields: businessPartnerDependentFields(bpPath),
        loadFromDb: true
    },
    columns: [{ id: BusinessPartnerEntity.Name }, { id: BusinessPartnerEntity.LegalNumber, textAlign: TextAlign.Right }]
});

const isSubFieldRequired = (args: IGetValueArgs, isBPRequired: boolean, bpPath = "BusinessPartner") => {
    const entity = args.storage.data.entity; // some document or bankTransaction
    if (entity[bpPath]?.VatStatus?.Code !== VatStatusCode.NotVATRegistered || args.bindingContext.getPath() === BusinessPartnerEntity.VatStatus) {
        return isBPRequired || !!(entity[bpPath]?.Name || entity[bpPath]?.LegalNumber);
    }
    return false;
};

export const createContactsItems = (storage: FormStorage, labelProp: string) => {
    const contacts: IBusinessPartnerContactEntity[] = storage.data.entity.BusinessPartner?.BusinessPartner?.Contacts;
    return contacts?.map(contact => {
        const id = contact[labelProp as keyof typeof contact] as string;
        const formattedPhone = phoneNumberInputFormatter(contact.PhoneNumber);

        return {
            id,
            label: id,
            additionalData: {
                Email: contact.Email,
                PhoneNumber: contact.PhoneNumber,
                FirstName: contact.FirstName,
                LastName: contact.LastName
            },
            tabularData: [contact.Email, formattedPhone, contact.FirstName, contact.LastName]
        }
    });
}

const bpLocalDependentFields = [{
    from: { id: BusinessPartnerContactEntity.PhoneNumber },
    to: { id: BusinessPartnerContactEntity.PhoneNumber },
    navigateFrom: NavigationSource.Parent
}, {
    from: { id: BusinessPartnerContactEntity.FirstName },
    to: { id: BusinessPartnerContactEntity.FirstName },
    navigateFrom: NavigationSource.Parent
}, {
    from: { id: BusinessPartnerContactEntity.LastName },
    to: { id: BusinessPartnerContactEntity.LastName },
    navigateFrom: NavigationSource.Parent
}, {
    from: { id: BusinessPartnerContactEntity.Email },
    to: { id: BusinessPartnerContactEntity.Email },
    navigateFrom: NavigationSource.Parent
}];

const createBpFieldSettings = (fieldName: string) => {
    return {
        localDependentFields: bpLocalDependentFields?.filter(field => field.from.id !== fieldName),
        itemsFactory: (args: IGetValueArgs) => {
            return Promise.resolve(createContactsItems(args.storage as FormStorage, fieldName));
        }
    };
};

export const getBusinessPartnerFieldDef = (isRequired: boolean, file?: string, businessPartnerPath?: string): TFieldsDefinition => {
    const bpPath = businessPartnerPath ?? "BusinessPartner";
    const addressFields = getAddressFields(bpPath);
    const creatingKey = file && i18next.exists(`${file}:FormGroup.NewPartner`) ? `${file}:FormGroup.NewPartner` : "Common:General.NewPartner";

    return {
        [`${bpPath}/Name`]: {
            groupedField: GroupedField.MultiStart,
            ...BusinessPartnerDef(businessPartnerPath),
            width: BasicInputSizes.XL,
            isRequired
        },
        [`${bpPath}/LegalNumber`]: {
            groupedField: GroupedField.MultiEnd,
            ...BusinessPartnerDef(businessPartnerPath),
            width: BasicInputSizes.S,
            creatingTitle: i18next.t(creatingKey),
            collapsedRows: [
                [
                    { id: `${bpPath}/VatStatus` },
                    { id: `${bpPath}/TaxNumber` },
                    { id: `${bpPath}/Street` },
                    { id: `${bpPath}/City` },
                    { id: `${bpPath}/PostalCode` },
                    { id: `${bpPath}/Country` }
                ],
                [
                    { id: `${bpPath}/Email` },
                    { id: `${bpPath}/PhoneNumber` },
                    { id: `${bpPath}/FirstName` },
                    { id: `${bpPath}/LastName` }
                ]
            ]
        },
        [`${bpPath}/TaxNumber`]: {},
        [`${bpPath}/Street`]: {
            ...addressFields[`${bpPath}/Street`],
            isRequired: (args) => isSubFieldRequired(args, isRequired, bpPath)
        },
        [`${bpPath}/City`]: {
            ...addressFields[`${bpPath}/City`],
            isRequired: (args) => isSubFieldRequired(args, isRequired, bpPath)
        },
        [`${bpPath}/PostalCode`]: {
            ...addressFields[`${bpPath}/PostalCode`],
            isRequired: (args) => isSubFieldRequired(args, isRequired, bpPath) && !hasOptionalPostalCode(args)
        },
        [`${bpPath}/Country`]: {
            ...addressFields[`${bpPath}/Country`],
            isRequired: (args) => isSubFieldRequired(args, isRequired, bpPath)
        },
        [`${bpPath}/Email`]: {
            type: FieldType.Autocomplete,
            width: BasicInputSizes.L,
            fieldSettings: createBpFieldSettings(BusinessPartnerContactEntity.Email),
            validator: {
                type: ValidatorType.Email
            }
        },
        [`${bpPath}/PhoneNumber`]: {
            ...PhoneNumberDef,
            // TODO: For now disable the settings as select changes need to be done
            // fieldSettings: createBpFieldSettings(BusinessPartnerContactEntity.PhoneNumber),
            // type: FieldType.Autocomplete
        },
        [`${bpPath}/FirstName`]: {
            type: FieldType.Autocomplete,
            fieldSettings: createBpFieldSettings(BusinessPartnerContactEntity.FirstName),
        },
        [`${bpPath}/LastName`]: {
            type: FieldType.Autocomplete,
            fieldSettings: createBpFieldSettings(BusinessPartnerContactEntity.LastName),
        },
        [`${bpPath}/VatStatus`]: {
            type: FieldType.ComboBox,
            isRequired: (args) => isSubFieldRequired(args, isRequired, bpPath),
            cacheStrategy: CacheStrategy.EndOfTime,
            fieldSettings: {
                displayName: "Name"
            }
        }
    };
};

export const isBusinessPartnerField = (name: string): boolean => {
    return name === "BusinessPartner/Name" || name === "BusinessPartner/LegalNumber";
};

export const loadPartner = async (partnerId: string, oData: OData): Promise<IBusinessPartnerEntity> => {
    const response = await oData
        .getEntitySetWrapper(EntitySetName.BusinessPartners)
        .query(partnerId)
        .expand("BankAccounts", (q) => {
            q.select("Id", "AccountNumber", "AbaNumber", "BankCode", "IBAN", "SWIFT", "IsDefaultForReceivedDocuments");
            q.expand("Country");
        })
        .expand("Contacts", (q) => {
            q.select("Id", "FirstName", "LastName", "Email", "PhoneNumber").orderBy("Order");
        })
        .expand("ReceivedDocumentDefault", q => {
            q.expand("AccountAssignment");
            q.expand("VatClassification");
        })
        .expand("BillingAddress", q => {
            q.expand("Country");
        })
        .expand("IssuedDocumentDefault", q => {
            q.expand("AccountAssignment");
            q.expand("VatClassification");
            q.expand("CompanyBankAccount", qq => {
                qq.expand("Country");
            });
        })
        .expand("VatStatus")
        .fetchData<IBusinessPartnerEntity>();

    return response?.value ?? {};
};

export const fetchAresDetail = async (legalNumber: string, countryCode: CountryCode.CzechRepublic | CountryCode.Slovakia): Promise<IBusinessPartnerEntity & {
    MainActivity: string
}> => {
    const response = await fetch(`${ARES_GET_API}?country=${countryCode}&identificationNumber=${legalNumber}`);

    if (!response.ok) {
        logger.error("couldn't fetch from ARES API");
        return null;
    }

    const item = await response.json();
    return {
        Id: null, // clears businessPartner relation as this is a new one
        BillingAddress: {
            City: item.Record.Address.City,
            CountryCode: item.Record.Address.Country,
            Country: {
                Code: item.Record.Address.Country,
                IsEuMember: true, // we support only SK and CZ now...
                IsEEA: true
            },
            PostalCode: item.Record.Address.PostalCode,
            Street: `${item.Record.Address.Street || item.Record.Address.City || ""} ${item.Record.Address.HouseNumber || ""}`.trim()
        },
        TaxNumber: item.Record.VatInfo?.VatIdentificationNumber,
        VatStatus: {
            Code: item.Record.VatInfo?.VatPayer ? VatStatusCode.VATRegistered : item.Record.IsIdentifiedPersonForVat ? VatStatusCode.IdentifiedPerson : VatStatusCode.NotVATRegistered
        },
        MainActivity: item.Record.MainNaceActivity
    };
};


export function setBusinessPartnerGroupStatus(storage: FormStorage, isCreating: boolean, bpPath = "BusinessPartner"): void {
    if (storage.isReadOnly) {
        // when storage is in read-only mode, we don't want to show group status as creating, even there is no BP ID
        isCreating = false;
    }
    setGroupStatus(getCollapsedGroupId({ id: `${bpPath}/LegalNumber` }), storage, isCreating);
}

interface IBusinessPartnerChangeArgs {
    showsAllBankAccounts?: boolean;
    allowCreate?: boolean;
    currencyDatePropPath?: string;
}

type TEntityBusinessPartner =
    IBankTransactionBusinessPartnerEntity
    | IDocumentBusinessPartnerEntity
    | IPrDeductionBusinessPartnerEntity;

const resetContactItems = (storage: FormStorage) => {
    const fields = [BusinessPartnerContactEntity.Email, BusinessPartnerContactEntity.PhoneNumber,
        BusinessPartnerContactEntity.FirstName, BusinessPartnerContactEntity.LastName];
    const bcRoot = storage.data.bindingContext.navigate("BusinessPartner");

    invalidateItems(fields.map(field => bcRoot.navigate(field)), storage);
}

export const handleBusinessPartnerChange = async (args: IBankAccountArgs, e: ISmartFieldChange, opts?: IBusinessPartnerChangeArgs): Promise<void> => {
    const { showsAllBankAccounts, allowCreate, currencyDatePropPath } = opts ?? {};
    const isBPMainField = isBusinessPartnerField(e.bindingContext.getNavigationPath(true));
    const bpPath = (args.businessPartnerPath ?? "BusinessPartner") as keyof TBankAccountRelatedEntity;
    const bpEntity = args.storage.data.entity[bpPath] as TEntityBusinessPartner;

    if (isBPMainField) {
        if (e.triggerAdditionalTasks) {
            const { Id } = e.additionalData ?? {};

            if (allowCreate !== false) {
                setBusinessPartnerGroupStatus(args.storage, !Id, args.businessPartnerPath);
            }

            appendAdditionalItemsToSavedBankAccounts(args);
            setSavedContactItems(args.storage, e.additionalData.Contacts);
            setSavedBankAccounts(args.storage, e.additionalData.BankAccounts);
            if (!bpEntity.BusinessPartner) {
                bpEntity.BusinessPartner = {};
            }
            bpEntity.BusinessPartner.Contacts = e.additionalData.Contacts;
            bpEntity.BusinessPartner.BankAccounts = e.additionalData.BankAccounts;

            if (showsAllBankAccounts) {
                updateAllSavedAccountsOnPartnerChange({ ...args, businessPartner: e.additionalData });
            }

            resetContactItems(args.storage);
            args.storage.refresh();
        } else if (bpEntity?.BusinessPartner) {
            // User changes only Name/LegalNumber without picking from list -> remove the relation
            bpEntity.BusinessPartner = undefined;
        }
    }
    const isBPCountry = e.bindingContext.getPath() === DocumentBusinessPartnerEntity.Country && e.bindingContext.getParent().getPath(true) === BankTransactionEntity.BusinessPartner;
    if (opts.currencyDatePropPath && (isBPMainField || isBPCountry) && e.triggerAdditionalTasks) {
        // change currency if there are no changed items
        if (!(args.storage.data.entity as IDocumentExtendedEntity).Items?.some((item: IDocumentItemEntity) => item.TransactionAmount)) {
            await setDefaultBPCurrency(args, e, currencyDatePropPath);
        }
    }

    if (e.bindingContext.getPath() === DocumentBusinessPartnerEntity.VatStatus) {
        args.storage.refreshGroupByKey("partner");
    }
};


interface ISetDefaultsFromBusinessPartner {
    storage: FormStorage<IDocumentExtendedEntity>;
    type?: DocumentTypeCode;
    businessPartner?: IBusinessPartnerEntity;
}

export const setDefaultsFromBusinessPartner = async ({
                                                         storage,
                                                         businessPartner,
                                                         type
                                                     }: ISetDefaultsFromBusinessPartner, refreshDateDue?: (dueDays?: number) => void): Promise<void> => {
    let defaultBankAccount;
    const isReceivedDoc = isReceived(type);
    const propName = isReceivedDoc ? BusinessPartnerEntity.ReceivedDocumentDefault : BusinessPartnerEntity.IssuedDocumentDefault;

    // common properties for both ReceivedDocumentDefault and IssuedDocumentDefault
    if (businessPartner[propName] && !isObjectEmpty(businessPartner[propName])) {
        if (businessPartner[propName].DefaultPaymentMethodCode) {
            storage.clearAndSetValueByPath("PaymentMethod", businessPartner[propName].DefaultPaymentMethodCode);
        }

        const props: (keyof (IBusinessPartnerIssuedDocumentDefaultEntity | IBusinessPartnerReceivedDocumentDefaultEntity))[] =
            ["SymbolSpecific", "SymbolVariable", "SymbolConstant", "RemittanceInformation"];

        props.forEach(prop => {
            if (businessPartner[propName][prop]) {
                storage.setValueByPath(prop, businessPartner[propName][prop]);
            }
        });

        if (isDefined(businessPartner[propName].DaysDue)) {
            // DaysDue depends on DateAccountingTransaction -> call common method if present
            refreshDateDue?.(businessPartner[propName].DaysDue);
        }

        if (isAccountAssignmentCompany(storage.context)) {
            const accountAssignment = businessPartner[propName]?.AccountAssignment;

            if (!isObjectEmpty(businessPartner[propName].AccountAssignment)) {
                storage.setValueByPath(DocumentEntity.AccountAssignmentSelection, convertAccountAssignmentIntoSelection(accountAssignment));
                storage.setValueByPath(`${DocumentEntity.AccountAssignmentSelection}/${AccountAssignmentSelectionEntity.Selection}`, SelectionCode.Copy);

                const fiscalYear = storage.data.entity.FiscalYear;
                const items = fiscalYear ? (await loadAccountAssignments(storage)) : [];

                await rewriteAccountAssignment({
                    storage,
                    choaId: fiscalYear?.ChartOfAccounts?.Id,
                    itemBc: storage.data.bindingContext,
                    accountAssignments: items
                });
            }
        }
    }


    // some other things?
    if (isReceivedDoc) {
        defaultBankAccount = businessPartner.BankAccounts?.find(
            (account: IBusinessPartnerBankAccountEntity) => account.IsDefaultForReceivedDocuments
        );
    } else if (businessPartner.IssuedDocumentDefault?.CompanyBankAccount?.IsActive) {
        defaultBankAccount = businessPartner.IssuedDocumentDefault?.CompanyBankAccount;
    } else if (businessPartner.IssuedDocumentDefault?.DefaultPaymentMethodCode === PaymentMethodCode.PaymentService) {
        const defAcc = storage.context.getCompanyBankAccounts()?.filter((acc: ICompanyBankAccountEntity) => acc.IsDefault)?.[0];
        if (defAcc && defAcc.PaymentServiceID) {
            // as we can't store default acc for payment service in business partner we use default one if it is the case
            defaultBankAccount = defAcc;
        }
    }

    if (defaultBankAccount && !defaultBankAccount?.PaymentServiceID) {
        storage.processDependentField(BankAccountDependentFields, defaultBankAccount);
    }

    prepareSavedAccounts({
        storage: storage,
        type: type,
        businessPartner,
        account: defaultBankAccount
    });

    storage.refresh();
};

export function isNewPartnerWithData(storage: FormStorage, path = "BusinessPartner"): boolean {
    const value = storage.getValueByPath(path);
    return !value?.BusinessPartner?.Id && !!(value?.Name || value?.LegalNumber);
}

const setDefaultBPCurrency = async (args: IBankAccountArgs, e: ISmartFieldChange, datePropPath?: string): Promise<void> => {
    const currencyBc = args.storage.data.bindingContext.navigate("TransactionCurrency");
    // country or BP change
    const countryCode = e.bindingContext.getPath() === "Country" ? e.additionalData.Code : e.additionalData.Country?.Code;
    if (countryCode) {
        let currencyCode: CurrencyCode;
        if (countryCode === getCompanyCountryCode(args.storage.context)) {
            // Czech republic is default, we don't have it "currenciesUsedByCompany" and always switch it back
            currencyCode = getCompanyCurrencyCode(args.storage.context);
        } else {
            const res = await args.storage.oData.getEntitySetWrapper(EntitySetName.Countries).query(`'${countryCode}'`).select("DefaultCurrencyCode").fetchData<ICountryEntity>();
            const defaultCurrency = res.value?.DefaultCurrencyCode as CurrencyCode;
            const usedCurrencies = getCompanyUsedCurrencyCodes(args.storage.context);
            const isUsed = usedCurrencies.includes(defaultCurrency);
            if (isUsed) {
                currencyCode = defaultCurrency;
            }
        }
        if (currencyCode) {
            args.storage.setValue(currencyBc, currencyCode);
            await refreshExchangeRate(args.storage, currencyCode, datePropPath);
            args.storage.addActiveGroupByKey("Items");
            args.storage.refresh();
        }
    }
};

export const handleBusinessPartnerBlur = async (args: IBankAccountArgs, e: ISmartFieldBlur, showsAllBankAccounts?: boolean, allowCreateBP = true): Promise<void> => {
    const isBusinessPartnerField = e.bindingContext.getParent().getPath(true) === (args.businessPartnerPath ?? "BusinessPartner");

    if (isBusinessPartnerField && e.wasChanged) {
        // no id is set, but there are some data in inputs => we expect creating new item
        const isNewWithData = isNewPartnerWithData(args.storage, args.businessPartnerPath);
        if (allowCreateBP) {
            setBusinessPartnerGroupStatus(args.storage, isNewWithData, args.businessPartnerPath);
        }

        const bp = args.storage.getValue(e.bindingContext.getParent());
        // TODO: not sure about this, in troubles we need further investigation
        // const isEmpty = !bp?.Id && !bp?.BusinessPartner?.Id;
        const isNew = !bp?.BusinessPartner?.Id;

        if (isNew) {
            // clear accounts and BP related data -> businessPartner relation was removed
            if (showsAllBankAccounts) {
                updateAllSavedAccountsOnPartnerChange({ ...args, businessPartner: bp as IBusinessPartnerEntity });
            } else if (!showsAllBankAccounts) {
                prepareSavedAccounts({ ...args, businessPartner: {} });
            }

            setSavedContactItems(args.storage);
        }

        args.storage.refreshGroup(e.bindingContext);
        return;
    }
};

export const addBankAccountToBusinessPartner = (storage: FormStorage, data: IEntity, preparedData: IEntity): void => {
    let canAddAccount;

    if (storage.data.bindingContext.getPath(true) === EntitySetName.BankTransactions) {
        // for bank transaction
        canAddAccount = true;
    } else {
        // for documents
        const hasPaymentMethod = storage.data.bindingContext.getEntityType().getProperty("PaymentMethod");
        const paymentMethod = hasPaymentMethod && storage.getValueByPath("PaymentMethod", { useDirectValue: false });

        canAddAccount = paymentMethod === PaymentMethodCode.WireTransfer;
    }

    if (canAddAccount) {
        const isEmpty = hasEmptyBankAccount(storage);
        if (!isEmpty) {
            const currentBankAccount = preparedData.BankAccount;

            const newBankAccount = { ...currentBankAccount };
            delete newBankAccount.Id;

            data.BankAccounts = [newBankAccount];
        }
    }
};

export function clearBPIfNotSet(data: IEntity, path = "BusinessPartner"): boolean {
    if (!data[path] || (!data[path].Name && !data[path].LegalNumber)) {
        // not a new BusinessPartner
        // BusinessPartner can be optional, but there can be some default values in its object, like Currency
        // => remove it completely, so that it isn't send to BE at all
        data.BusinessPartner = null;
        return true;
    }
    return false;
}

export const prepareNewBusinessPartner = (storage: FormStorage, data: IEntity, addBankAccount?: boolean, path = "BusinessPartner"): void => {
    if (data[path]?.BusinessPartner?.Id) {
        // BusinessPartner already exists
        return;
    }

    if (clearBPIfNotSet(data, path)) {
        // if BP is cleared, just don't proceed further
        return;
    }

    const newBusinessPartner: IBusinessPartnerEntity = BindingContext.createNewEntity(-1, {
        ...storage.extractFields(businessPartnerDependentFields()),
        Company: {
            Id: storage.context.getCompany().Id
        }
    });

    // have to make sure there is no undefined contacts
    if (isEmptyContact(newBusinessPartner.Contacts?.[0])) {
        newBusinessPartner.Contacts = [];
    }

    delete newBusinessPartner.Id;

    if (addBankAccount) {
        addBankAccountToBusinessPartner(storage, newBusinessPartner, data);
    }

    if (!hasValidBillingAddress(data.BusinessPartner)) {
        delete newBusinessPartner.BillingAddress;
    }

    setNestedValue(newBusinessPartner, "BusinessPartner", data.BusinessPartner);
};

const compareContact = (c1: IBusinessPartnerContactEntity, c2: IBusinessPartnerContactEntity) => {
    return areObjectsEqual<IBusinessPartnerContactEntity>(c1, c2,
        ["FirstName", "LastName", "PhoneNumber", "Email"]);
};

export const setSavedContactItems = (storage: FormStorage<unknown, IBusinessPartnerCustomData>, contacts: IBusinessPartnerContactEntity[] = []): void => {
    storage.setCustomData({ businessPartnerContacts: contacts });
};

export const setSavedBankAccounts = (storage: FormStorage<unknown, IBankAccountsCustomData>, bankAccounts: IBankAccountEntity[] = []): void => {
    storage.setCustomData({ bankAccounts: bankAccounts });
};


export const hasBusinessPartner = (storage: FormStorage, partner?: IBusinessPartnerEntity, path = "BusinessPartner"): boolean => {
    partner = !isObjectEmpty(partner) ? partner : storage.data.entity[path];
    return !!(partner?.Id || partner?.Name || partner?.LegalNumber);
};


export const hasMatchingBusinessPartnerContact = (storage: FormStorage<any, IBusinessPartnerCustomData>, currentContact: IBusinessPartnerContactEntity, path: string): boolean => {
    const findSameContact = (contacts: IBusinessPartnerContactEntity[]) => {
        return contacts.find((contact: IBusinessPartnerContactEntity) => compareContact(currentContact, contact));
    };

    return !!findSameContact(storage.getCustomData().businessPartnerContacts) || !!findSameContact(storage.data.entity[path]?.BusinessPartner?.Contacts ?? []);
};

const isEmptyContact = (contact: IBusinessPartnerContactEntity): boolean => {
    return contact && !contact.FirstName && !contact.LastName && !contact.Email && !contact.PhoneNumber;
};

export const addBusinessPartnerContactRequest = (storage: FormStorage, isNewBusinessPartner: boolean, batch: BatchRequest, path = "BusinessPartner"): void => {
    const extractedBusinessPartner = storage.extractFields<IBusinessPartnerEntity>(businessPartnerDependentFields());
    const newContact = extractedBusinessPartner.Contacts[0];

    if (isEmptyContact(newContact) || isNewBusinessPartner || hasMatchingBusinessPartnerContact(storage, newContact, path)) {
        return null;
    }

    delete newContact.Id;
    // Put to the end
    newContact.Order = 0;

    batch.fromPath(`BusinessPartners(${storage.data.entity[path].BusinessPartner.Id})/Contacts`).create(newContact);

    return null;
};

export const hasValidBillingAddress = (bp: IDocumentBusinessPartnerEntity): boolean => {
    return !!bp && !!bp.Street && !!bp.City && !isObjectEmpty(bp.Country);
};
