import React from "react";
import { ISmartFieldBlur, ISmartFieldChange } from "@components/smart/smartField/SmartField";
import { FormStorage } from "../../../views/formView/FormStorage";
import SmartFastEntryList from "../../../components/smart/smartFastEntryList";
import {
    GovernmentInstitutionBankAccountEntity,
    IPrPayrollSettingEntity,
    PrGovernmentInstitutionBankAccountDetailEntity,
    PrGovernmentInstitutionDetailEntity,
    PrPayrollSettingEntity
} from "@odata/GeneratedEntityTypes";
import { createPath } from "@odata/BindingContext";

interface IProps {
    storage: FormStorage<IPrPayrollSettingEntity>;
    isReadOnly: boolean;
    groupId: string;
    institution: PrPayrollSettingEntity;
}

export class BankAccountsFastEntry extends React.Component<IProps> {

    handleLineItemsChange = (event: ISmartFieldChange): void => {
        this.props.storage.handleLineItemsChange(event);
        this.props.storage.refreshFields();
    };

    render() {
        const columns = [{
            id: createPath(PrGovernmentInstitutionBankAccountDetailEntity.GovernmentInstitutionBankAccount, GovernmentInstitutionBankAccountEntity.Name)
        }, {
            id: createPath(PrGovernmentInstitutionBankAccountDetailEntity.GovernmentInstitutionBankAccount, GovernmentInstitutionBankAccountEntity.AccountNumber)
        }, {
            id: createPath(PrGovernmentInstitutionBankAccountDetailEntity.GovernmentInstitutionBankAccount, GovernmentInstitutionBankAccountEntity.BankCode)
        }, {
            id: PrGovernmentInstitutionBankAccountDetailEntity.SymbolVariable
        }, {
            id: PrGovernmentInstitutionBankAccountDetailEntity.SymbolSpecific
        }, {
            id: PrGovernmentInstitutionBankAccountDetailEntity.SymbolConstant
        }, {
            id: PrGovernmentInstitutionBankAccountDetailEntity.RemittanceInformation
        }];

        const bc = this.props.storage.data.bindingContext.navigate(this.props.institution)?.navigate(PrGovernmentInstitutionDetailEntity.BankAccountDetails);

        return (
                <SmartFastEntryList
                        isDisabled={this.props.storage?.isDisabled}
                        isReadOnly={this.props.isReadOnly}
                        useLabelWrapping={true}
                        isLight={true}
                        groupId={this.props.groupId}
                        isCollapsible={false}
                        storage={this.props.storage as FormStorage}
                        bindingContext={bc}
                        canAdd={false}
                        isItemCloneable={false}
                        isItemRemovable={false}
                        searchInChildProps={[PrGovernmentInstitutionBankAccountDetailEntity.GovernmentInstitutionBankAccount]}
                        columns={columns}
                        onChange={this.handleLineItemsChange}
                        onBlur={this.handleBlur}
                        order={null}
                />
        );
    }

    handleBlur = async (args: ISmartFieldBlur): Promise<void> => {
        await this.props.storage.handleBlur(args);
        this.props.storage.refreshFields();
    };
}