import styled, { css } from "styled-components/macro";
import { T_PLAIN_big_hig, T_PLAIN_small_hig } from "../../global.style";
import { IconButton } from "../button";
import { ellipsis } from "polished";
import { CollapsibleSectionTitleLook } from "./CollapsibleSection";

interface IProps {
    _isToggled?: boolean;
    hasPadding?: boolean
    usedAsField?: boolean;
}

export const StyledCollapsibleSection = styled.div<IProps>`
    position: relative;
    max-height: 100%;

    & + & {
        // if usedAsField, subtract 17px that are already added by FieldsWrapper
        margin-top: ${props => props.usedAsField ? "14px" : "31px"};
    }
`;

export const Title = styled.h3<{look: CollapsibleSectionTitleLook}>`
    cursor: pointer;
    ${props => props.look === CollapsibleSectionTitleLook.Colored && css`
      color: ${props => props.theme.C_ACT_main}
    `};
    ${props => props.look === CollapsibleSectionTitleLook.Colored ? T_PLAIN_small_hig : T_PLAIN_big_hig};
    
    margin-left: 6px;
    ${ellipsis()};
`;

export const IconButtonStyledRemove = styled(IconButton)`
    margin-left: auto;
`;

export const IconButtonStyledToggle = styled(IconButton)<IProps>`
    position: relative;

    ${props => !props._isToggled && css`
        transform: rotate(-90deg);
    `};
`;

export const TopWrapper = styled.div<IProps>`
    display: flex;
    align-items: center;
    height: 26px;
    margin-bottom: ${props => props._isToggled ? "17px" : "0"};
`;

export const CollapsibleBody = styled.div<IProps>`
    padding: ${props => props.hasPadding ? "0 32px" : "0"};
`;