import styled, { css } from "styled-components/macro";
import {
    getDisabledStyle,
    getFocusBorderElement,
    T_PLAIN_big,
    T_PLAIN_small,
    T_TITLE_2,
    T_TITLE_3_normal,
    T_TITLE_4_NORMAL
} from "../../global.style";
import { StyledSeparator } from "../separator/Separator.styles";
import { StyledView, StyledViewWrapper } from "../../views/View.styles";

export const DialogHeader = styled.div`
    ${T_TITLE_2};
    margin-bottom: 32px;
`;

export const ProductTypeSelectionScreenWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: fit-content;
`;

export const PurchaseWizardTileFlex = styled.div`
    display: flex;
    gap: 17px;
    justify-content: center;
    margin: 0 51px 16px;
    min-width: fit-content;
`;

interface IStyledProductTypeItem {
    $isSelected: boolean;
    $isDisabled: boolean;
    $isCentered: boolean;
    $isStandaloneUse: boolean;
}

export const StyledProductTypeItem = styled.div<IStyledProductTypeItem>`
    ${T_PLAIN_small};
    position: relative;
    width: 420px;
    min-width: 390px;

    ${props => !props.$isStandaloneUse && css<IStyledProductTypeItem>`
    height: 450px;
    padding: 38px;
    border-radius: 15px;
    box-shadow: 0 0 18px 0 rgba(0, 0, 0, ${props => props.theme.dashboard_tile_shadow_opacity});
    background-color: ${props => props.$isSelected ? props.theme.C_BG_row_field_hover : props.theme.C_BG_menu};
    border: 1px solid ${props => props.$isSelected ? props.theme.C_ACT_main : "transparent"};
    cursor: ${props => props.$isDisabled ? "default" : "pointer"};
`};

    display: flex;
    flex-direction: column;

    ${props => props.$isCentered && css`
        align-items: center;
    `};

    & ${StyledSeparator} {
        flex: 0 0 auto;
        margin: 17px 0 12px;
    }

    ${props => props.$isDisabled && getDisabledStyle(props.$isDisabled)};
    ${getFocusBorderElement({ keyboardOnly: true, offset: -5 })};
`;

export const SubItemWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;


export const ItemTitle = styled.div<{ $isStandaloneUse: boolean; }>`
    ${T_TITLE_2};
    margin-bottom: 5px;

    ${props => !props.$isStandaloneUse && css`
        min-width: max-content;
    `};

    ${props => props.$isStandaloneUse && css`
        max-width: 254px;
        text-align: center;
    `};
`;

export const ItemDescription = styled.div`
    ${T_PLAIN_small};
    height: 14px;
    margin-bottom: 55px;
    opacity: 0.5;
    color: ${props => props.theme.C_TEXT_primary};
`;

export const SubItemTitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
`;

export const SubItemTitle = styled.div`
    ${T_TITLE_3_normal};
`;

export const SubItemPriceWrapper = styled.div`
    display: flex;
    flex-direction: column;
    text-align: right;
`;

export const SubItemPrice = styled.div`
    ${T_TITLE_2};
    font-weight: 800;
`;

export const SubItemDescription = styled.div`
    ${T_PLAIN_big};
    font-weight: 300;
    margin-top: 5px;
`;

export const SmallDescription = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    white-space: pre-wrap;
    text-align: center;
    ${T_PLAIN_small};

    & {
        svg {
            margin-right: 2px;
            margin-top: -2px;
        }
    }
`;

export const PlugAndPlayFormWrapper = styled.div`
    ${StyledViewWrapper}, ${StyledView} {
        height: unset;
    }
`;

export const ConsentCheckboxWrapper = styled.div`
    margin-top: 30px;
`;

export const PaymentTypeImageWrapper = styled.div`
    position: relative;
    width: 198px;
    height: 198px;
    top: -10px
`;

export const PaymentTypeDescription = styled.div`
    position: relative;
    max-width: 305px;
    text-align: center;
    ${T_PLAIN_small};
    white-space: pre-wrap;
`;

export const PurchaseFinishedContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    overflow: hidden;
`;

export const PurchaseFinishedTitle = styled.div`
    ${T_TITLE_2};
    margin-bottom: 15px;
`;

export const PurchaseFinishedDescription = styled.div`
    ${T_TITLE_4_NORMAL};
    margin-bottom: 15px;
`;

export const PurchaseFinishedImageWrapper = styled.div`
    position: relative;
    top: -35px;
    width: 200px;
    height: 200px;
`;