import styled, { css } from "styled-components/macro";
import Dialog from "../../components/dialog";
import { StyledButton } from "@components/button";

export const DialogStyledTemporalPropertyDialog = styled(Dialog)<{ showTimeLine: boolean; }>`
    ${props => props.showTimeLine && css`
        // move SmartFastEntryList add button
        ${StyledButton}:first-of-type {
            margin-left: 30px;
        }
    `};
`;