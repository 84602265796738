import { Switch } from "react-router-dom";
import { RouteProps } from "react-router";
import React from "react";
import { IFormSwitchProps } from "./FormRoute";
import PrivateRoute from "./PrivateRoute";
import { AUDIT_TRAIL } from "../routes";
import { NoPermission } from "../views/notFound";
import AuditTrailView from "../components/auditTrail/AuditTrailView";
import { WithPermissionContext, withPermissionContext } from "../contexts/permissionContext/withPermissionContext";
import { GeneralPermissionCode } from "@odata/GeneratedEnums";

class FormDynamicEntityRoute extends React.PureComponent<RouteProps & WithPermissionContext & Pick<IFormSwitchProps, "page" | "permission">> {
    render() {
        const Form = this.props.page;

        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.path}`}
                              permission={this.props.permission}
                              render={() => {
                                  return (
                                      <Form/>
                                  );
                              }}/>
                <PrivateRoute exact path={`${this.props.path}/:ParentId/:Id`}
                              permission={this.props.permission}
                              render={() => {
                                  return (
                                      <Form/>
                                  );
                              }}/>
                <PrivateRoute path={`${this.props.path}/:ParentId/:Id/${AUDIT_TRAIL}`}
                              permission={this.props.permission} render={() => {
                    const hasAuditTrailPermission = this.props.permissionContext.generalPermissions.has(GeneralPermissionCode.AuditTrail);
                    if (!hasAuditTrailPermission) {
                        return <NoPermission/>;
                    }
                    return (
                        <AuditTrailView/>
                    );
                }}/>

            </Switch>
        );
    }
}

export default withPermissionContext(FormDynamicEntityRoute);