import { ICachedFieldState } from "@odata/Data.utils";
import { getPageViewMode } from "@pages/PageUtils";
import { isDefined } from "@utils/general";
import React from "react";

import { FieldType, PageViewMode } from "../../../enums";
import BindingContext from "../../../odata/BindingContext";
import { FormStorage } from "../../../views/formView/FormStorage";
import { getInfoValue, IFieldDef, isFieldDisabledWithoutLock, isVisible } from "../FieldInfo";
import { ISmartFieldBlur, ISmartFieldChange, ISmartFieldTempDataActionArgs } from "../smartField/SmartField";
import { IFormGroupDef } from "./SmartFormGroup";

export interface ISmartFormGroupBaseProps {
    bindingContext: BindingContext;
    def: IFormGroupDef;
    title?: string;

    storage: FormStorage;

    isSimple?: boolean;

    onChange: (args: ISmartFieldChange) => void;
    onTemporalChange?: (args: ISmartFieldChange) => void;
    onCancel?: (args: ISmartFieldTempDataActionArgs) => void;
    onConfirm?: (args: ISmartFieldTempDataActionArgs) => void;
    onFieldStateChange?: (bc: BindingContext, state: ICachedFieldState, prevState: ICachedFieldState) => void;
    onBlur?: (args: ISmartFieldBlur) => void;
}


export default abstract class SmartFormGroupBase<T extends ISmartFormGroupBaseProps, S = {}> extends React.Component<T, S> {

    getFieldDefinition(rowItem: IFieldDef): IFieldDef {
        let path = rowItem.id;

        if (this.props.def.collection) {
            path = `${this.props.def.collection}/${path}`;
        }

        return this.props.storage.data.mergedDefinition?.[path]?.fieldDef || rowItem;
    }

    handleBlur = async (args: ISmartFieldBlur): Promise<void> => {
        args.groupId = this.props.def.id;
        await this.props.onBlur?.(args);
    };

    handleChange = (e: ISmartFieldChange): void => {
        e.groupId = this.props.def.id;
        this.props.onChange?.(e);
    };

    handleTemporalChange = (e: ISmartFieldChange): void => {
        e.groupId = this.props.def.id;
        this.props.onTemporalChange?.(e);
    };

    handleCancel = (args: ISmartFieldTempDataActionArgs): void => {
        this.props.onCancel?.({
            bindingContext: args.bindingContext,
            groupId: this.props.def.id
        });
    };

    handleFieldStateChange = (bc: BindingContext, state: ICachedFieldState, prevState: ICachedFieldState): void => {
        this.props.onFieldStateChange?.(bc, state, prevState);
    };

    handleConfirm = (args: ISmartFieldTempDataActionArgs): void => {
        this.props.onConfirm?.({
            bindingContext: args.bindingContext,
            groupId: this.props.def.id
        });
    };

    getCollapsedFieldsVisibility(def: IFieldDef): {
        hasVisibleCollapsedFields: boolean,
        hasEnabledCollapsedFields: boolean,
        hasEditableFields: boolean
    } {
        const modelBc = this.props.storage.data.bindingContext;

        const hasVisibleCollapsedFields = !!def.collapsedRows?.find(collapsedRow => {
            return !!collapsedRow.find(field => {
                const fieldInfo = this.props.storage.getInfo(modelBc.navigate(field.id));
                return isVisible({
                    info: fieldInfo,
                    storage: this.props.storage,
                    bindingContext: fieldInfo.bindingContext
                });
            });
        });
        const hasEnabledCollapsedFields = hasVisibleCollapsedFields && !!def.collapsedRows?.find(collapsedRow => {
            return !!collapsedRow.find(field => {
                const bc = modelBc.navigate(field.id);
                const fieldInfo = this.props.storage.getInfo(bc);
                return !isFieldDisabledWithoutLock(fieldInfo, this.props.storage, bc);
            });
        });

        const hasEditableFields = !!def.collapsedRows?.find(collapsedRow => {
            return !!collapsedRow.find(field => {
                const fieldInfo = this.props.storage.getInfo(modelBc.navigate(field.id));
                return !getInfoValue(fieldInfo, "isReadOnly", {
                    info: fieldInfo,
                    storage: this.props.storage,
                    bindingContext: fieldInfo.bindingContext
                });
            });
        });

        return {
            hasVisibleCollapsedFields,
            hasEnabledCollapsedFields,
            hasEditableFields
        };
    }

    getFieldsVisibility(): boolean {
        const modelBc = this.props.storage.data.bindingContext;
        const rootBc = this.props.def.collection ? modelBc.navigate(this.props.def.collection) : modelBc;

        const pageViewMode = getPageViewMode();
        return !!this.props.def.togglePropPath
            || (!!this.props.def.lineItems && (pageViewMode !== PageViewMode.FormReadOnly || this.props.storage.getValueByPath(this.props.def.lineItems.collection)?.length > 0))
            || (!this.props.def.lineItems && !this.props.def.rows) || !!this.props.def.rows?.find(row => {
                return !!row.find(field => {
                    const bc = rootBc.navigate(field.id);
                    const fieldInfo = this.props.storage.getInfo(bc);
                    const visible = isVisible({
                        bindingContext: bc,
                        info: fieldInfo,
                        storage: this.props.storage,
                        context: this.props.storage.context
                    });

                    if (fieldInfo.type !== FieldType.EditableText) {
                        return visible;
                    }

                    // hide group, if PageViewMode.FormReadOnly and only empty EditableText is inside
                    return visible && (pageViewMode !== PageViewMode.FormReadOnly || isDefined(this.props.storage.getValue(bc)));
                });
            });
    }
}
