import { OData } from "@odata/OData";
import { EntitySetName, IChartOfAccountsTemplateEntity } from "@odata/GeneratedEntityTypes";

export const getUniqName = async (oData: OData, name: string): Promise<string> => {
    const templates = await getTemplateNames(oData),
        names = templates.map(item => item.Name);

    // replace previous number if present
    name = name.replace(/\W\([0-9]+\)$/, "");

    let newName = name,
        i = 1;
    while (names.includes(newName)) {
        newName = `${name} (${i})`;
        ++i;
    }
    return newName;
};

export const getTemplateNames = async (oData: OData): Promise<IChartOfAccountsTemplateEntity[]> => {
    const query = oData.getEntitySetWrapper(EntitySetName.ChartOfAccountsTemplates)
        .query()
        .select("Name");

    const result = await query.fetchData<IChartOfAccountsTemplateEntity[]>();

    // returns template names with ids
    return result.value;
};