import React from "react";
import { AppContext } from "../../contexts/appContext/AppContext.types";
import { WithTranslation, withTranslation } from "react-i18next";
import { ExtendedShell, IExtendedShellPropsForExtend } from "./ExtendedShell";
import { EvalaAppIcon, IDokladAppIcon, IProps as IIconProps } from "../../components/icon";
import { Heading } from "./AppsList.style";
import AppButton from "../../components/appButton/AppButton";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import { ROUTE_CONNECT_WITH_IDOKLAD } from "../../routes";

interface IProps extends IExtendedShellPropsForExtend, WithTranslation, RouteComponentProps {
}

interface IState {
}

enum App {
    iDoklad,
    Evala
}

interface IAppButtonConfig {
    id: number;
    name: string;
    icon: React.ComponentType<IIconProps>;
}

const Apps: IAppButtonConfig[] = [
    {
        id: App.Evala,
        name: "Evala",
        icon: EvalaAppIcon
    },
    // not ready, hidden
    {
        id: App.iDoklad,
        name: "iDoklad",
        icon: IDokladAppIcon
    }
];

class AppsList extends React.PureComponent<IProps, IState> {
    static contextType = AppContext;

    componentDidMount() {
    }

    handleAppClick = (id: App) => {
        switch (id) {
            case App.Evala:
                this.props.onHide(true);
                break;
            case App.iDoklad:
                this.props.history.push(ROUTE_CONNECT_WITH_IDOKLAD);
                break;
        }
    };

    renderAppList() {
        return Apps.map(app => (<AppButton key={app.id} {...app} onClick={this.handleAppClick}/>));
    }

    render() {
        return (
            <ExtendedShell {...this.props}
                           hotspotContextId="AppsList"
                           padding={"85px"}>
                <Heading>{this.props.t("Common:AppsList.Heading")}</Heading>
                {this.renderAppList()}
            </ExtendedShell>
        );
    }
}

export default withRouter(withTranslation(["Common"])(AppsList));
