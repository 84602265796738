import React from "react";
import styled from "styled-components/macro";

import { IProps as IIconProps } from "./Icon";

const ColoredIconWrapper = React.memo((props: React.PropsWithChildren<IIconProps>) => {
    return React.cloneElement(props.children as React.ReactElement, {
        preventHover: true, ...props
    });
});

export const DarkIcon = styled(ColoredIconWrapper)`
    .IC_4L_main, .IC_4L_sub, .IC_4L_dot {
        stroke: ${props => props.theme.C_ICON_done};
    }

    .IC_4L_filled {
        stroke: ${props => props.theme.C_ICON_done};
        fill: ${props => props.theme.C_ICON_done};
    }
`;

/**
 * Monochromatic icon styles, allows to use color: #xyz to style any icon
 */
export const ColoredIcon = styled(ColoredIconWrapper)`
    .IC_4L_main, .DI_4L_main, .IC_4L_sub {
        stroke: currentColor !important;
    }

    .IC_4L_filled, .IC_4L_filled_no_stroke {
        stroke: currentColor !important;
        fill: currentColor;
    }

    .C_TEXT_primary {
        stroke: currentColor !important;
    }

    .C_TEXT_primary_outline {
        fill: none;
        stroke: currentColor !important;
    }
`;