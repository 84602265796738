import { EntitySetName, IHotspotEntity } from "@odata/GeneratedEntityTypes";
import { ODataQueryResult } from "@odata/ODataParser";
import { WithOData, withOData } from "@odata/withOData";
import { getCompanyLogoUrl } from "@utils/CompanyUtils";
import { saveAs } from "file-saver";
import { isEqual } from "lodash";
import React from "react";
import ReactDOM from "react-dom";
import { WithTranslation, withTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";

import { AppContext, IAppContext } from "../../contexts/appContext/AppContext.types";
import { Status } from "../../enums";
import { KeyName } from "../../keyName";
import ourOrganizationTileSvg from "../../pages/companies/ourOrganizationTile.svg";
import customFetch, { getDefaultPostParams } from "../../utils/customFetch";
import memoizeOne from "../../utils/memoizeOne";
import { IBaseAlertProps } from "../alert/Alert";
import { TImage } from "../imageUploader";
import Hotspots, { HotspotsMode, IHotspotDef, THotspotTooltipDef } from "./Hotspots";
import {
    buildHotspotId,
    GENERAL_HOTSPOT_VIEW,
    HOTSPOT_TITLE_SUFFIX,
    HOTSPOT_VIEW_ATTR,
    HOTSPOTS_INTERACTION_URL,
    IToolHotspotDefinition,
    splitHotspotId
} from "./Hotspots.utils";

export const HotspotsContext = React.createContext<IHotspotsContext>(undefined);

export interface IHotspotsContext {
    isOpen: boolean;
    setOpen: (isOpen: boolean) => void;
}

type TContextHotspot = (IHotspotDef & {
    originalEntity: IHotspotEntity;
    originalInteraction: IHotspotInteraction;
});

interface IHotspotInteraction {
    ViewId: string;
    ElementId: string;
    IsLiked: boolean;
    IsCommented: boolean;
}

interface IState extends Pick<IHotspotsContext, "isOpen"> {
    hotspots: TContextHotspot[];
    mode: HotspotsMode;
    lastRoute: string;
    // we don't want to load data for the same view multiple times
    downloadedViews?: string[];
}

class HotspotsProvider extends React.PureComponent<WithOData & WithTranslation & RouteComponentProps, IState> {
    static contextType = AppContext;

    // keep all downloaded hotspots, so that we can compare them with edited hotspots when exporting
    originalHotspots: TContextHotspot[] = [];

    state: IState = {
        isOpen: false,
        hotspots: [],
        mode: HotspotsMode.View,
        lastRoute: "",
        downloadedViews: []
    };

    componentDidMount() {
        document.addEventListener("keydown", this.handleKeyDown);
    }

    componentDidUpdate(prevProps: WithOData & WithTranslation & RouteComponentProps) {
        if (this.props.location !== prevProps.location) {
            this.handleRouteChange();
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyDown);
    }

    init = async (viewIds: string[]) => {
        // todo can we use routes as unique id or do we need to add another id prop to every route?
        // with route used as an id, mappings on BE would break every time route is changed

        // multiple requests has to be fired at once, to get all necessary data
        // one to EntitySetName.Hotspots to get list of all hotspots
        // one to HOTSPOTS_INTERACTION_URL/{viewId} for each view, to get hotspot interactions
        const filter = viewIds.map(viewId => `ViewId eq '${viewId}'`).join(" OR ");

        const promises: [Promise<any>] = [this.props.oData.getEntitySetWrapper(EntitySetName.Hotspots).query().filter(filter).fetchData<IHotspotEntity[]>()];

        for (const viewId of viewIds) {
            promises.push(customFetch(`${HOTSPOTS_INTERACTION_URL}/${viewId}`));
        }


        const [hotspots, ...viewResponses] = await Promise.all(promises);
        const interactions: Record<string, IHotspotInteraction[]> = {};

        for (let i = 0; i < viewResponses.length; i++) {
            const viewResponse = viewResponses[i];
            const viewInteractions = await (viewResponse as Response).json();

            interactions[viewIds[i]] = viewInteractions;
        }

        const hotspotsDefs: TContextHotspot[] = (hotspots as ODataQueryResult<IHotspotEntity[]>).value
            // each hotspot has two entities in database, one description and for the title,
            // we only need the main id to build the tooltip
            // TODO db could only store the main id, the rest should be removed and not added for new hotspots
            .filter((hotspotEntity: IHotspotEntity) => !hotspotEntity.ElementId.endsWith(HOTSPOT_TITLE_SUFFIX))
            .map((hotspotEntity: IHotspotEntity): TContextHotspot => {
                const hotspotInteraction = interactions[hotspotEntity.ViewId]?.find(interaction => interaction.ElementId === hotspotEntity.ElementId);
                const isDisliked = hotspotInteraction && !hotspotInteraction.IsLiked;
                const isCommented = !hotspotInteraction || hotspotInteraction.IsCommented;

                return {
                    id: buildHotspotId(hotspotEntity.ViewId, hotspotEntity.ElementId),
                    tooltipDef: {
                        text: this.props.t(`Hotspots:${hotspotEntity.ViewId}:${hotspotEntity.ElementId}`),
                        header: this.props.t(`Hotspots:${hotspotEntity.ViewId}:${hotspotEntity.ElementId}${HOTSPOT_TITLE_SUFFIX}`),
                        isLikeActive: hotspotInteraction?.IsLiked,
                        isDislikeActive: isDisliked,
                        showLikeButtons: true,
                        showComment: !isCommented && isDisliked
                    },
                    originalEntity: hotspotEntity,
                    originalInteraction: hotspotInteraction
                };
            });

        this.originalHotspots = [...this.originalHotspots, ...hotspotsDefs];

        this.setState({
            hotspots: [...this.state.hotspots, ...hotspotsDefs]
        });
    };

    getBeToolDefinitions = (): IToolHotspotDefinition[] => {
        const definitions: IToolHotspotDefinition[] = [];
        const newAndUpdated = this.state.hotspots.filter(hotspot => {
            const originalHotspot = this.originalHotspots.find(h => h.id === hotspot.id);

            return !originalHotspot || !isEqual(originalHotspot, hotspot);
        });
        const removed = this.originalHotspots.filter(hotspot => !this.state.hotspots.find(h => h.id === hotspot.id));

        for (const hotspot of newAndUpdated) {
            const { viewId, elementId } = splitHotspotId(hotspot.id);
            const headerDef: IToolHotspotDefinition = {
                Type: "UpsertHotspotAndTranslations",
                ViewId: viewId,
                ElementId: `${elementId}${HOTSPOT_TITLE_SUFFIX}`,
                Translations: [
                    {
                        Language: "cs-CZ",
                        Text: hotspot.tooltipDef?.header
                    },
                    {
                        Language: "en",
                        Text: ""
                    }
                ]
            };

            const textDef: IToolHotspotDefinition = {
                Type: "UpsertHotspotAndTranslations",
                ViewId: viewId,
                ElementId: elementId,
                Translations: [
                    {
                        Language: "cs-CZ",
                        Text: hotspot.tooltipDef?.text
                    },
                    {
                        Language: "en",
                        Text: ""
                    }
                ]
            };

            definitions.push(headerDef);
            definitions.push(textDef);
        }

        for (const hotspot of removed) {
            const { viewId, elementId } = splitHotspotId(hotspot.id);

            definitions.push({
                Type: "DeleteHotspot",
                ViewId: viewId,
                ElementId: elementId
            });
        }

        return definitions;
    };

    setStateFromBeToolDefinitionToHotspots = (definition: IToolHotspotDefinition[]) => {
        const newHotspots = [...this.state.hotspots];
        let i = 0;

        while (i < definition.length) {
            const headerDef = definition[i];

            if (headerDef.Type === "DeleteHotspot") {
                const originalHotspotIndex = newHotspots.findIndex(h => h.id === buildHotspotId(headerDef.ViewId, headerDef.ElementId));

                if (originalHotspotIndex >= 0) {
                    newHotspots.splice(originalHotspotIndex, 1);
                }

                i += 1;
                continue;
            }

            const textDef = definition[i + 1];

            const hotspot: TContextHotspot = {
                id: buildHotspotId(textDef.ViewId, textDef.ElementId),
                tooltipDef: {
                    header: headerDef.Translations[0].Text,
                    text: textDef.Translations[0].Text
                },
                originalInteraction: null,
                originalEntity: null
            };

            const originalHotspotIndex = newHotspots.findIndex(h => h.id === hotspot.id);

            if (originalHotspotIndex >= 0) {
                const originalHotspot = newHotspots[originalHotspotIndex];
                newHotspots[originalHotspotIndex] = {
                    ...hotspot,
                    originalInteraction: originalHotspot.originalInteraction,
                    originalEntity: originalHotspot.originalEntity
                };
            } else {
                newHotspots.push(hotspot);
            }

            i += 2;
        }

        this.setState({
            hotspots: newHotspots
        });
    };

    getContext = memoizeOne((): IHotspotsContext => {
        return {
            isOpen: this.state.isOpen,
            setOpen: this.setOpen
        };
    }, () => [this.state.isOpen]);

    handleRouteChange = () => {
        this.handleClose();
    };

    handleKeyDown = (event: KeyboardEvent): void => {
        if (this.state.isOpen && event.key === KeyName.Escape) {
            this.handleClose();
        }
    };

    // todo how to wait for the page to be loaded (and the components to be shown)
    setOpen = async (isOpen: boolean) => {
        const route = this.props.history.location.pathname;
        const viewIds: string[] = [];

        if (isOpen) {
            const availableViews = document.querySelectorAll(`[${HOTSPOT_VIEW_ATTR}]`);

            if (!this.state.downloadedViews.includes(GENERAL_HOTSPOT_VIEW)) {
                viewIds.push(GENERAL_HOTSPOT_VIEW);
            }

            for (const elem of availableViews) {
                const viewId = elem.getAttribute(HOTSPOT_VIEW_ATTR);

                if (!this.state.downloadedViews.includes(viewId) && !viewIds.includes(viewId)) {
                    viewIds.push(viewId);
                }
            }

            if (viewIds.length > 0) {
                this.init(viewIds);
            }
        }

        this.setState({
            isOpen,
            lastRoute: route,
            mode: HotspotsMode.View,
            downloadedViews: [...this.state.downloadedViews, ...viewIds]
        });
    };

    handleModeChange = (mode: HotspotsMode) => {
        this.setState({
            mode
        });
    };

    handleClose = () => {
        // remove empty (unconfirmed) hotspots
        this.setState({
            hotspots: this.state.hotspots.filter(hotspot => hotspot.tooltipDef?.header || hotspot.tooltipDef?.text)
        });

        this.setOpen(false);
    };

    updateHotspotDef = (hotspotId: string, newValues: Partial<THotspotTooltipDef>) => {
        const hotspotIndex = this.state.hotspots.findIndex(hotspotDef => hotspotDef.id === hotspotId);
        const updatedHotspots = [...this.state.hotspots];

        updatedHotspots[hotspotIndex] = {
            ...updatedHotspots[hotspotIndex],
            tooltipDef: {
                ...updatedHotspots[hotspotIndex].tooltipDef,
                ...newValues
            }
        };

        this.setState({
            hotspots: updatedHotspots
        });
    };

    /** Clears like/dislike */
    clearHotspotLikeness = async (hotspotDef: TContextHotspot) => {
        customFetch(`${HOTSPOTS_INTERACTION_URL}/clear`, {
            ...getDefaultPostParams(),
            body: JSON.stringify({
                Hotspot: hotspotDef.originalEntity
            })
        });

        const newValues: Partial<THotspotTooltipDef> = {
            isLikeActive: false,
            isDislikeActive: false,
            showComment: false
        };

        this.updateHotspotDef(hotspotDef.id, newValues);
    };

    handleHotspotLike = async (hotspotId: string, value: boolean) => {
        const hotspotDef = this.state.hotspots.find(hotspot => hotspot.id === hotspotId);

        if (hotspotDef.tooltipDef.isLikeActive) {
            return this.clearHotspotLikeness(hotspotDef);
        }

        customFetch(`${HOTSPOTS_INTERACTION_URL}/like`, {
            ...getDefaultPostParams(),
            body: JSON.stringify({
                Hotspot: hotspotDef.originalEntity
            })
        });

        const newValues: Partial<THotspotTooltipDef> = {
            isLikeActive: value,
            showComment: false
        };

        if (value) {
            newValues.isDislikeActive = false;
        }

        this.updateHotspotDef(hotspotId, newValues);
    };

    handleHotspotDislike = async (hotspotId: string, value: boolean) => {
        const hotspotDef = this.state.hotspots.find(hotspot => hotspot.id === hotspotId);

        if (hotspotDef.tooltipDef.isDislikeActive) {
            return this.clearHotspotLikeness(hotspotDef);
        }

        customFetch(`${HOTSPOTS_INTERACTION_URL}/dislike`, {
            ...getDefaultPostParams(),
            body: JSON.stringify({
                Hotspot: hotspotDef.originalEntity
            })
        });

        const newValues: Partial<THotspotTooltipDef> = {
            isDislikeActive: value,
            showComment: true,
            alert: null
        };

        if (value) {
            newValues.isLikeActive = false;
        }

        this.updateHotspotDef(hotspotId, newValues);
    };

    handleHotspotCommentChange = (hotspotId: string, comment: string) => {
        this.updateHotspotDef(hotspotId, { commentText: comment });
    };

    handleHotspotCommentSend = async (hotspotId: string, comment = "") => {
        const hotspotDef = this.state.hotspots.find(hotspot => hotspot.id === hotspotId);

        this.updateHotspotDef(hotspotId, {
            busy: true
        });

        const formData = new FormData();

        formData.set("comment", comment);
        formData.set("viewId", hotspotDef.originalEntity.ViewId);
        formData.set("elementId", hotspotDef.originalEntity.ElementId);

        if (hotspotDef.tooltipDef.image) {
            formData.set("attachment", hotspotDef.tooltipDef.image);
        }

        let alert: Pick<IBaseAlertProps, "status" | "title">;
        const errorAlert = {
            status: Status.Error,
            title: this.props.t("Components:Hotspots.SaveError")
        };

        try {
            const res = await customFetch(`${HOTSPOTS_INTERACTION_URL}/comment`, {
                method: "POST",
                body: formData
            });

            if (res.ok) {
                alert = {
                    status: Status.Success,
                    title: this.props.t("Components:Hotspots.SaveSuccess")
                };
            } else {
                alert = errorAlert;
            }

        } catch (e) {
            alert = errorAlert;
        }

        this.updateHotspotDef(hotspotId, {
            busy: false,
            alert
        });
    };

    handleHotspotImageInsert = async (hotspotId: string, images: File[]) => {
        this.updateHotspotDef(hotspotId, {
            image: images[0]
        });
    };

    handleHotspotImageClick = async (hotspotId: string, image: TImage) => {
        this.updateHotspotDef(hotspotId, {
            image: null
        });
    };

    handleHotspotAlertFadeEnd = (hotspotId: string) => {
        const hotspotDef = this.state.hotspots.find(hotspot => hotspot.id === hotspotId);

        if (hotspotDef.tooltipDef.alert?.status === Status.Error) {
            this.updateHotspotDef(hotspotId, {
                alert: null
            });
        } else {
            const hotspotIndex = this.state.hotspots.findIndex(hotspotDef => hotspotDef.id === hotspotId);
            const updatedHotspots = [...this.state.hotspots];

            updatedHotspots[hotspotIndex] = {
                ...updatedHotspots[hotspotIndex],
                tooltipDef: {
                    ...updatedHotspots[hotspotIndex].tooltipDef,
                    showComment: false,
                    image: null,
                    commentText: "",
                    alert: null
                },
                originalInteraction: {
                    ...updatedHotspots[hotspotIndex].originalInteraction,
                    IsCommented: true
                }
            };

            this.setState({
                hotspots: updatedHotspots
            });
        }
    };

    handleHotspotsChange = (newHotspots: IHotspotDef[]) => {
        this.setState({
            hotspots: newHotspots.map((hotspot) => {
                const h = this.state.hotspots.find(h => h.id === hotspot.id);

                return {
                    ...hotspot,
                    originalEntity: h?.originalEntity,
                    originalInteraction: h?.originalInteraction
                };
            })
        });
    };

    handleHotspotsExport = () => {
        const createdDefinitions = this.getBeToolDefinitions();

        // create json file for the BE processing tool
        const file = new Blob([JSON.stringify(createdDefinitions)], { type: "application/json" });

        saveAs(file, "hotspots.json");
    };

    handleHotspotsLoad = (files: File[]) => {
        const file = files[0];

        const fileReader = new FileReader();

        fileReader.onload = () => {
            const text = fileReader.result;

            this.setStateFromBeToolDefinitionToHotspots(JSON.parse(text as string));
        };

        fileReader.readAsText(file);

    };

    renderHotspots = () => {
        const context = this.context as IAppContext;
        const hotspots = (
            <Hotspots hotspots={this.state.hotspots}
                      avatarSrc={context.getCompany() ? getCompanyLogoUrl(context.getCompany()) : ourOrganizationTileSvg}
                      mode={this.state.mode}
                      onModeChange={this.handleModeChange}
                      onExportClick={this.handleHotspotsExport}
                      onFileLoad={this.handleHotspotsLoad}
                      onClose={this.handleClose}
                      onLike={this.handleHotspotLike}
                      onDislike={this.handleHotspotDislike}
                      onCommentTextChange={this.handleHotspotCommentChange}
                      onSendCommentClick={this.handleHotspotCommentSend}
                      onImageInsert={this.handleHotspotImageInsert}
                      onImageClick={this.handleHotspotImageClick}
                      onAlertFadeEnd={this.handleHotspotAlertFadeEnd}
                      onHotspotsChange={this.handleHotspotsChange}/>
        );

        const modalRoot = document.getElementById("hotspots-root");

        if (modalRoot) {
            return ReactDOM.createPortal(
                hotspots,
                modalRoot
            );
        } else {
            return hotspots;
        }
    };

    render() {
        return (
            <HotspotsContext.Provider value={this.getContext()}>
                {this.state.isOpen && this.renderHotspots()}
                {this.props.children}
            </HotspotsContext.Provider>
        );
    }
}

// TODO when to download translations, we dont need them immediately
// and we don't need all the pages at once.. but whatever
const WrappedHotspotsContext = withRouter(withOData(withTranslation(["Hotspots", "Components"])(HotspotsProvider)));

export { WrappedHotspotsContext as HotspotsProvider };