import React from "react";
import {
    BasicSelect,
    BasicSelectClean
} from "./BasicSelect";
import { WithTranslation, withTranslation } from "react-i18next";
import { IconButton, IIconButtonProps } from "../../button";
import * as PopperJS from "@popperjs/core";
import { composeRefHandlers } from "@utils/general";
import memoizeOne from "../../../utils/memoizeOne";
import { IFieldComponentProps, ISelectionChangeArgs, ISelectPropsBase } from "@components/inputs/select/Select.types";

export interface IIconSelectProps extends Omit<ISelectPropsBase, "ref"> {
    icon: React.ReactElement;
    iconButtonProps?: Partial<IIconButtonProps>;
    title?: string;
    /** Renders html data-hotspotid attribute*/
    hotspotId: string;
    isDecorative?: boolean;
    isActive?: boolean;
    dontDisplayNoDataFound?: boolean;
    render?: (sharedProps: any) => React.ReactElement;
    selectRef?: React.RefObject<BasicSelectClean>;
    ref?: React.Ref<IconSelect>;
}

class IconSelect extends React.PureComponent<IIconSelectProps & WithTranslation> {
    _refSelect = React.createRef<BasicSelectClean>();
    _refButton = React.createRef<HTMLButtonElement>();

    public open = (): void => {
        this._refButton.current?.focus();
        this._refSelect.current?.open();
    };

    getHandleKeyDown = memoizeOne((onKeyDown: (e: React.KeyboardEvent) => void) => {
        return (event: React.KeyboardEvent): void => {
            // only call select.onKeyDown if the select is opened
            // it doesn't make sense to change select item by pressing arrow up/down on Button, without the select menu opened
            if (this._refSelect.current?.isOpen()) {
                onKeyDown(event);
            }
        };
    });

    renderFieldComponent = (props: IFieldComponentProps): React.ReactElement => {
        return (
            <IconButton
                isTransparent
                isDecorative={this.props.isDecorative}
                title={this.props.title ?? this.props.t("Common:General.Open")}
                hotspotId={this.props.hotspotId}
                // prevent from opening while the button is already active
                onClick={this.props.isActive ? null : props.onIconClick}
                onKeyDown={this.getHandleKeyDown(props.onKeyDown)}
                onBlur={props.onBlur}
                isActive={props.isOpen || this.props.isActive}
                isDisabled={props.isDisabled || this.props.isDisabled}
                passRef={composeRefHandlers(this._refButton, props.openerRef as React.Ref<HTMLButtonElement>)}
                {...this.props.iconButtonProps}
            >
                {this.props.icon}
            </IconButton>
        );
    };

    handleChange = (e: ISelectionChangeArgs): void => {
        // if we ever need to remove this,
        // this check needs to be moved to every usage of IconSelect
        e.triggerAdditionalTasks && this.props.onChange?.(e);
    };

    renderContent = (): React.ReactElement => {
        const sharedProps = {
            onChange: this.handleChange,
            placement: "bottom" as PopperJS.Placement,
            displayArrow: true,
            fieldComponent: this.renderFieldComponent,
            closeOnSelection: true,
            openOnClick: true,
            inputIsReadOnly: true,
            isSharpLeft: true,
            isSharpRight: true,
            isIconSelect: true
        };

        return this.props.render ? this.props.render(sharedProps) :
            (<BasicSelect
                {...this.props}
                ref={composeRefHandlers(this._refSelect, this.props.selectRef)}
                {...sharedProps} />);
    };

    render() {
        return this.renderContent();
    }
}

export default withTranslation("Common", { withRef: true })(IconSelect);
export { IconSelect as IconSelectClean };