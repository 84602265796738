import { EMPTY_DASH } from "@components/readOnlyList/ReadOnlyList";
import { IColumn, IRow } from "@components/table";
import { IFiscalYearEntity, ITaxDepreciationPolicyItemEntity, ITaxPriceLimitEntity } from "@odata/GeneratedEntityTypes";
import { isDefined } from "@utils/general";
import React from "react";
import { useTranslation } from "react-i18next";

import SimpleTable from "../../../components/simpleTable/SimpleTable";
import { RowType, Status, TextAlign } from "../../../enums";
import { formatCurrency } from "../../../types/Currency";
import { getUtcDayjs } from "../../../types/Date";

export interface ITaxDepreciationPolicyItemEntityExtended extends ITaxDepreciationPolicyItemEntity {
    FiscalYear: IFiscalYearEntity;
}

interface IFiscalYearEntityExtended extends IFiscalYearEntity {
    isFakeOne?: boolean;
}

interface IProps {
    header?: React.ReactElement;
    data: ITaxDepreciationPolicyItemEntityExtended[];
    FYs: IFiscalYearEntity[];
    putInUseDate: Date;
    currentFY: IFiscalYearEntity;
    disableBeforeDate: Date;
    currency: string;
    TaxPriceLimits?: ITaxPriceLimitEntity[];
}

export const TaxDepreciationPolicyTable: React.FunctionComponent<IProps> = (props) => {
    const { t } = useTranslation(["FixedAsset"]);
    const reductionId = "reduction";
    const usedExpenseId = "usedExpense";
    const partialExpenseId = "partialExpense";
    const commonExpenseSumId = "depreciated";
    const usedExpenseSumId = "usedExpenseSum";

    let columns: IColumn[] = [
        { id: "year", label: `${t("FixedAsset:DepreciationTable.Year")}:` },
        { id: "depreciation", label: `${t("FixedAsset:DepreciationTable.CommonDepreciation")}:`, textAlign: TextAlign.Right },
        { id: reductionId, label: `${t("FixedAsset:DepreciationTable.ReductionRate")}:`, textAlign: TextAlign.Right },
        { id: usedExpenseId, label: `${t("FixedAsset:DepreciationTable.UsedExpense")}:`, textAlign: TextAlign.Right },
        { id: partialExpenseId, label: `${t("FixedAsset:DepreciationTable.PartialExpense")}:`, textAlign: TextAlign.Right },
        { id: "balance", label: `${t("FixedAsset:DepreciationTable.Balance")}:`, textAlign: TextAlign.Right },
        {
            id: commonExpenseSumId,
            label: `${t("FixedAsset:DepreciationTable.CommonDepreciationSum")}:`,
            textAlign: TextAlign.Right
        },
        {
            id: usedExpenseSumId,
            label: `${t("FixedAsset:DepreciationTable.UsedExpenseSum")}:`,
            textAlign: TextAlign.Right
        },
    ];

    const _createDayjs = (date: Date) => date && getUtcDayjs(date);

    const rows: IRow[] = [];
    const { currency, currentFY: highlightedFY, disableBeforeDate, data, TaxPriceLimits, FYs } = props;
    const disabledDate = _createDayjs(disableBeforeDate);
    let showReductionRate = false;
    let showLimitedExpenses = false;
    let showPartialExpense = false;

    if (data?.length > 0) {
        // process data, prepare rows to be shown
        let id = 1;

        const _addMergedRow = (text: string): void => {
            rows.push({
                id: id++,
                values: {},
                type: RowType.Merged,
                isDivider: true,
                content: (<em>{text}</em>)
            });
        };

        const _addInterruptionRow = (interruptedYears: IFiscalYearEntity[], hasNextRow?: boolean) => {
            const from = parseInt(interruptedYears[0].Number);
            const to = interruptedYears?.length > 1 ? parseInt(interruptedYears[interruptedYears.length - 1].Number) : null;
            // Adds row with info about interruption
            const key = !hasNextRow ? "InterruptedFrom" : (to ? "InterruptedBetween" : "InterruptedIn");
            _addMergedRow(t(`FixedAsset:DepreciationTable.${key}`, { year: from, toYear: to }));
        };

        const _addRegularRow = (item: ITaxDepreciationPolicyItemEntityExtended, FY?: IFiscalYearEntity) => {
            const year = FY?.Number ?? "";
            const TaxLimit = item.TaxPriceLimitCode && TaxPriceLimits?.find(tpl => tpl.Code === item.TaxPriceLimitCode);
            const ReductionRate = item.ReductionRate ? `${item.ReductionRate} %` : null;
            const reduction = [TaxLimit?.ShortName, ReductionRate].filter(isDefined).join(", ");
            rows.push({
                id: id++,
                values: {
                    year,
                    depreciation: formatCurrency(item.CalculatedExpense, currency),
                    [reductionId]: reduction ? reduction : EMPTY_DASH,
                    [usedExpenseId]: formatCurrency(item.DepreciationExpense, currency),
                    [partialExpenseId]: item.PartialExpense ? formatCurrency(item.PartialExpense, currency) : EMPTY_DASH,
                    balance: formatCurrency(item.EndValue, currency),
                    [commonExpenseSumId]: formatCurrency(item.AccumulatedDepreciation, currency),
                    [usedExpenseSumId]: formatCurrency(item.AccumulatedDepreciationExpense, currency),
                },
                type: RowType.Value,
                isDisabled: !!(FY?.DateStart && disabledDate?.isSameOrAfter(_createDayjs(FY?.DateStart), "day")),
                statusHighlight: year === highlightedFY?.Number ? Status.Success : null
            });
        };

        // creates fake FY before/after the given one (+/- diff years)
        const _createFakeFY = (FY: IFiscalYearEntity, diff: number): IFiscalYearEntityExtended => {
            const baseProp = diff > 0 ? "DateEnd" : "DateStart";
            const secondProp = diff > 0 ? "DateStart" : "DateEnd";
            const baseDate = getUtcDayjs(FY?.[baseProp]);
            const plusMinusOne = diff / Math.abs(diff); // make from any numeric diff 1 or -1 based on sign
            const invPlusMinusOne = plusMinusOne * -1;
            return {
                [baseProp]: baseDate.add(diff, "year").toDate(),
                [secondProp]: baseDate.add(diff + invPlusMinusOne, "year").add(plusMinusOne, "day").toDate(),
                Number: (parseInt(FY.Number) + diff).toString(),
                isFakeOne: true
            };
        };

        const _getFYidx = (date: Date): number => FYs.findIndex(fy => getUtcDayjs(fy.DateStart).isSameOrBefore(date, "day") && getUtcDayjs(fy.DateEnd).isSameOrAfter(date, "day"));
        const _isDateSameOrBeforeFY = (date: Date, FY: IFiscalYearEntity): boolean => date && getUtcDayjs(date).isSameOrBefore(getUtcDayjs(FY.DateEnd), "day");

        // find FY according to putInUseDate or take first one for imported asset (putInUseDate is before the first FY)
        let currFYIdx = Math.max(_getFYidx(props.putInUseDate), 0);
        let lastFY: IFiscalYearEntityExtended = null;
        let currFY: IFiscalYearEntityExtended = FYs[currFYIdx];

        let interruptionYears: IFiscalYearEntity[] = [];
        let dataIdx = 0;
        let interruptedFromAdded = false;

        const _nextFY = (diff = 1) => {
            lastFY = currFY;
            currFYIdx += diff;
            currFY = FYs[currFYIdx];
            if (!currFY) {
                currFY = _createFakeFY(lastFY, diff);
            }
        };

        while (dataIdx < data.length) {
            const dataItem = data[dataIdx];

            const { DateDepreciation } = dataItem;

            // check general conditions to show columns
            showLimitedExpenses = showLimitedExpenses || !!(dataItem.TaxPriceLimitCode || dataItem.ReductionRate);
            showReductionRate = showReductionRate || !!dataItem.ReductionRate || showLimitedExpenses; // if expenses are limited, always show why
            showPartialExpense = showPartialExpense || !!dataItem.PartialExpense;

            if (!DateDepreciation) {
                if (!interruptedFromAdded) {
                    interruptedFromAdded = true;
                    _addInterruptionRow([currFY], false);
                }
                // if DateDepreciation is not set, the asset is interrupted and we show just row without FY info
                _addRegularRow(dataItem, null);
                dataIdx++;
                continue;
            }

            // if DateDepreciation is different than FY, there is some interruption in the data -> add interruption year and continue with next FY;
            if (!_isDateSameOrBeforeFY(DateDepreciation, currFY)) {
                interruptionYears.push(currFY);
                // continue with next FY...
                _nextFY();
                continue;
            }

            // in case we collect some interruption years in previous cycles, create interruption row and continue with regular row
            if (interruptionYears.length > 0) {
                // first matched data row after interruption -> add interruption row
                _addInterruptionRow(interruptionYears, true);
                interruptionYears = [];
            }

            const shouldSkipPartialExpenseRow = currFY.isFakeOne && dataItem.PartialExpense && getUtcDayjs(DateDepreciation).diff(getUtcDayjs(), "years") > 5;
            const diffToLastRow = data.length - dataIdx - 1;
            const isLastRow = diffToLastRow === 0;
            if (shouldSkipPartialExpenseRow && !isLastRow) {
                // add row with partial expense info and move to the last data row
                _addMergedRow(t("FixedAsset:DepreciationTable.PartialExpenseUntilEnd", { amount: formatCurrency(dataItem.PartialExpense, currency) }));
            } else {
                // create regular row and move to next data row and FY
                _addRegularRow(dataItem, currFY);
            }
            const diff = shouldSkipPartialExpenseRow && !isLastRow ? diffToLastRow : 1;
            dataIdx += diff;
            _nextFY(diff);
        }
    }

    columns = columns.filter(c => {
        switch(c.id) {
            case reductionId:
                return showReductionRate;
            case usedExpenseId:
                return showLimitedExpenses;
            case partialExpenseId:
                return showPartialExpense;
                // summaries: If no special conditions, only commonExpense is visible.
                // If limited expenses, both are visible.
                // If partial expenses, only used is visible
            case commonExpenseSumId:
                return !showPartialExpense || showLimitedExpenses;
            case usedExpenseSumId:
                return showPartialExpense || showLimitedExpenses;
            default:
                return true;
        }
    });

    return (<>
        {props.header}
        <SimpleTable rows={rows} columns={columns} width={"100%"}/>
    </>);
};