export enum ExtendedShellContent {
    Add = "add",
    Applications = "applications",
    DashboardSettings = "dashboardSettings",
    Help = "help",
    None = "none",
    Notifications = "notifications",
    Search = "search",
    Settings = "settings",
    UserSettings = "usersettings"
}

export const SHELL_NOTIFICATION_ID = "ShellNotification";