import React from "react";
import TestIds from "../../testIds";
import { StyledGroupedFields } from "./GroupedFields.styles";

/** Expects array of Field components as children. Renders them with correct sharpLeft/right prop */
export default class GroupedField extends React.PureComponent {
    render() {
        return (
            <StyledGroupedFields data-testid={TestIds.GroupedFields}>
                {
                    React.Children.map(this.props.children, (child, i) => {
                        const field = child as React.ReactElement;
                        const sharpLeft = i > 0;
                        const sharpRight = i < React.Children.count(this.props.children) - 1;

                        return React.cloneElement(field, {
                            isSharpLeft: sharpLeft,
                            isSharpRight: sharpRight,
                            children: React.cloneElement(field.props.children, {
                                isSharpLeft: sharpLeft,
                                isSharpRight: sharpRight
                            })
                        });
                    })
                }
            </StyledGroupedFields>
        );
    }
}