import React, { Component } from "react";
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from "react-router-dom";
import Login from "./Login";
import Register from "./Register";
import LoginPage from "./LoginPage";
import TenantPicker from "./TenantPicker";
import Verify from "./Verify";
import DevicePicker from "./DevicePicker";
import TenantRegister from "./TenantRegister";
import ResetPasswordRequest from "./ResetPasswordRequest";
import ResetPassword from "./ResetPassword";
import { themes } from "../theme";
import { ThemeProvider } from "styled-components/macro";
import { GlobalStyle } from "../global.style";
import { WithTranslation, withTranslation } from "react-i18next";
import {
    ROUTE_EMAIL_CONFIRM,
    ROUTE_LOGIN,
    ROUTE_LOGIN_DEVICE,
    ROUTE_LOGIN_INVITATION,
    ROUTE_LOGIN_REGISTER,
    ROUTE_LOGIN_RESET_PASS,
    ROUTE_LOGIN_RESET_PASS_REQUEST,
    ROUTE_LOGIN_TENANT,
    ROUTE_LOGIN_TENANT_REGISTER,
    ROUTE_LOGIN_VERIFY
} from "../routes";
import Invitation from "./Invitation";
import EmailConfirm from "./EmailConfirm";
import store from "./state/store";
import { Provider } from "react-redux";
import { DomManipulatorProvider } from "../contexts/domManipulator/DomManipulatorProvider";


interface IProps extends RouteComponentProps, WithTranslation {
}

class Router extends Component<IProps> {
    render() {
        if (!this.props.tReady) {
            return null;
        }
        return (
            <Provider store={store}>
                <DomManipulatorProvider>
                    <ThemeProvider theme={themes.light}>
                        <GlobalStyle/>
                        <LoginPage>
                            <Switch>
                                <Route exact path={ROUTE_LOGIN} component={Login}/>
                                <Route path={ROUTE_LOGIN_REGISTER} component={Register}/>
                                <Route path={ROUTE_EMAIL_CONFIRM} component={EmailConfirm}/>
                                <Route path={`${ROUTE_LOGIN_VERIFY}/:code`} component={Verify}/>
                                <Route path={ROUTE_LOGIN_TENANT} component={TenantPicker}/>
                                <Route path={`${ROUTE_LOGIN_INVITATION}/:code`} component={Invitation}/>
                                <Route path={ROUTE_LOGIN_TENANT_REGISTER} component={TenantRegister}/>
                                <Route path={ROUTE_LOGIN_DEVICE} component={DevicePicker}/>
                                <Route path={`${ROUTE_LOGIN_RESET_PASS}/:code`} component={ResetPassword}/>
                                <Route path={ROUTE_LOGIN_RESET_PASS_REQUEST} component={ResetPasswordRequest}/>
                                {/*wrong url, redirect to log in */}
                                <Redirect to={ROUTE_LOGIN}/>
                            </Switch>
                        </LoginPage>
                    </ThemeProvider>
                </DomManipulatorProvider>
            </Provider>
        );
    }
}

export default withRouter(withTranslation(["Login", "Common", "Components"])(Router));
