import ChartOfAccountsTableView from "./ChartOfAccountsTableView";
import { getDefinitions } from "./ChartOfAccountsDef";
import { withTranslation } from "react-i18next";
import ParentChildPage, { IParentPageProps } from "../ParentChildPage";
import { withRouter } from "react-router-dom";
import { withOData } from "@odata/withOData";
import { getCorrectPath } from "../../routes";
import { IEntity } from "@odata/BindingContext";
import { FiscalYearStatusCode } from "@odata/GeneratedEnums";

class ChartOfAccounts extends ParentChildPage<IParentPageProps> {
    static defaultProps = {
        tableView: ChartOfAccountsTableView,
        getDef: getDefinitions
    };

    get defaultParentKey(): string {
        return this.parents.find(parent => parent.FiscalYear.StatusCode === FiscalYearStatusCode.Active)?.Id ?? this.parents?.[0]?.Id?.toString() ?? "";
    }
}

export default withRouter(withTranslation(getDefinitions.translationFiles)(withOData(ChartOfAccounts)));
