import { Optional, TRecordAny, TValue } from "../../../global.types";
import { IFieldDef, TInfoValue } from "../FieldInfo";
import i18next from "i18next";
import { TFieldDefinitionFactory } from "@pages/PageUtils";
import { IFieldInfo } from "@odata/FieldInfo.utils";
import { ISelectItem } from "@components/inputs/select/Select.types";

export interface IFieldData extends IFieldInfo {
    value?: TValue;
    selectedItems?: ISelectItem[];
    currentValue?: string;
    additionalData?: TRecordAny;
}

// complete filter definition
export interface IFilterDef extends Omit<IFieldDef, "factory"> {
    isValueHelp?: boolean;
    /** Filter, that stores its value in variants per company. */
    isCompanyDependent?: boolean;
    factory?: TFieldDefinitionFactory<IFilterDef & IFieldInfo>;
}

// filter definition without the 'id' property, used in IFilterGroupDef.filterDefinition for less redundancy
export type TFilterDef = Optional<IFilterDef, "id">;

/** We only use two different groups in filter bar so far.
 * The id is sometimes used directly, better to keep them in enum. */
export enum FilterBarGroup {
    Parameters = "Parameters",
    Filters = "Filters"
}

export interface IFilterBarButtonDef {
    id: string;
    title: string;
    iconName: string;
}

export interface IFilterGroupDef {
    id: FilterBarGroup;
    /** Add icon button with possibility of adding custom filters*/
    allowCustomFilters: boolean;
    /** Add button with 'create query' text ---> allows user to create custom query*/
    createQuery: boolean;

    /** Indicates whether group is expanded by default */
    isExpanded?: boolean;
    icon: string;
    title: string;

    /** Removes inner padding from Field group -> should be part of custom rendered content */
    removePadding?: boolean;

    /** Definitions for all available filters,
     * those that are not used in defaultFilters will be available in the filter add/remove dialog */
    filterDefinition: Record<string, TFilterDef>;
    /** List of default filters */
    defaultFilters: string[];

    /** Custom buttons rendered in top right corner (before clearFilters, etc.. buttons)*/
    customButtons?: IFilterBarButtonDef[];

    /** Only filter parameters are cleared by default. Set true for Parameters groups, which should be cleared on variant change */
    clearOnVariantChange?: boolean;

    isValueHelp?: TInfoValue<boolean>;
}

export const getDefaultFilterGroupDef = (filterGroup: FilterBarGroup): Pick<IFilterGroupDef, "id" | "allowCustomFilters" | "createQuery" | "title" | "icon"> => {
    return {
        id: filterGroup,
        allowCustomFilters: filterGroup === FilterBarGroup.Filters,
        createQuery: filterGroup === FilterBarGroup.Filters,
        icon: filterGroup === FilterBarGroup.Filters ? "Filter" : "Parameters",
        title: i18next.t(`Common:General.${filterGroup}`)
    };
};