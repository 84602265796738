import React from "react";
import { FormStorage } from "../../views/formView/FormStorage";
import { createPath, IEntity } from "@odata/BindingContext";
import { IBankCustomData } from "../banks/bankTransactions/BankTransactions.utils";
import { ISmartFieldChange } from "@components/smart/smartField/SmartField";
import { _getId } from "../documents/Document.utils";
import { DocumentItemCbaCategoryEntity, PaymentDocumentItemEntity } from "@odata/GeneratedEntityTypes";
import TemporalFormDialog from "../../views/formView/TemporalFormDialog";

interface IProps {
    storage: FormStorage<IEntity, IBankCustomData>;
    onTemporalChange: (args: ISmartFieldChange) => void;
    onClose: (isConfirmed: boolean) => void;
}

const CustomCategoryTemporalDialog: React.FC<IProps> = ({ storage, onTemporalChange, onClose }) => {
    if (!storage.getCustomData().isCbaCategoryDialogOpen) {
        return null;
    }
    const lineId = _getId(storage.getCustomData().dialogCurrentLineItem);
    const group = [[
        createPath(`Items(${lineId})`, PaymentDocumentItemEntity.CbaCategory, DocumentItemCbaCategoryEntity.Category)
    ], [
        createPath(`Items(${lineId})`, PaymentDocumentItemEntity.CbaCategory, DocumentItemCbaCategoryEntity.TaxImpact),
        createPath(`Items(${lineId})`, PaymentDocumentItemEntity.CbaCategory, DocumentItemCbaCategoryEntity.IsAssetAcquisition),
        createPath(`Items(${lineId})`, PaymentDocumentItemEntity.CbaCategory, DocumentItemCbaCategoryEntity.TaxPercentage)
    ]];
    return (
        <TemporalFormDialog storage={storage}
                            fieldPaths={group}
                            title={storage.t("Document:General.CustomCategory")}
                            onTemporalChange={onTemporalChange}
                            onClose={onClose}/>
    );
};

export default CustomCategoryTemporalDialog;