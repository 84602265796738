import { WebSocketMessageTypeCode } from "@odata/GeneratedEnums";
import { throttle } from "lodash";

import { ENV_DISABLE_WEBSOCKET } from "../../constants";
import customFetch from "../customFetch";
import { logger } from "../log";
import {
    ILoginWebsocketMessage,
    ISystemWebsocketMessage,
    IWebsocketSubscriber,
    TWebsocketMessage
} from "./Websocket.types";

export const IsWebsocketDisabled = process.env[ENV_DISABLE_WEBSOCKET] === "1";

interface ITokenResponse {
    NotificationDomain: string;
    Token: string;
}

class WebsocketManager {
    #subscribers: IWebsocketSubscriber[] = [];
    socket: WebSocket;
    isFirstConnect = true;
    token: string;

    private connectToWs = async (companyId = customFetch.getCompanyId()): Promise<void> => {
        if (!this.#subscribers?.length || this.socket || IsWebsocketDisabled) {
            return; // already connected, no subscribers or websocket disabled, do not connect
        }

        try {
            const tokenResponse = await fetch("/api/rest/Notification/token");
            if (!tokenResponse.ok) {
                throw new Error("Failed to fetch notification token");
            }
            const response = await tokenResponse.json() as ITokenResponse;
            this.token = response.Token;

            const wsUrl = `${window.location.protocol === "https:" ? "wss" : "ws"}://${response.NotificationDomain}/api/ws/Notification`;

            this.socket = new WebSocket(wsUrl);

            this.socket.onopen = this.onOpen;
            this.socket.onmessage = this.onMessage;
            this.socket.onclose = this.onClose;
            this.socket.onerror = this.onError;
        } catch (e) {
            this.socket = null;
            this.connectWithTimeout();
            // fail silently
            logger.error("websocket connect failed", e);
        }
    };

    // there is weird behavior in _.throttle, that allows the throttled function to be called more often than the set timeout
    // https://github.com/lodash/lodash/issues/3051
    // if both leading and trailing is set to true => leading call of new interval is called immediately after the previous trailing invocation
    // => FIRST EVER CALL make directly connectToWs and every subsequent call make to connectWithTimeout with leading set to false
    private connectWithTimeout = throttle((): void => {
        this.connectToWs();
    }, 3000, { leading: false });

    public close = (): void => {
        this.socket?.close();
    };

    private onOpen = (event: Event): void => {
        const loginMessage : ILoginWebsocketMessage = {
            Parameters: {
                Token: this.token
            },
            WebSocketMessageType: WebSocketMessageTypeCode.Login
        };
        this.socket.send(JSON.stringify(loginMessage));
    };

    private onMessage = (event: MessageEvent): void => {
        const parsedMessage = this.parseMessage(event.data);

        if (parsedMessage.WebSocketMessageType === WebSocketMessageTypeCode.System) {
            const systemMessage = parsedMessage as ISystemWebsocketMessage;
            if (systemMessage.Parameters.IsError === "True") {
                logger.error("Websocket error: " + systemMessage.Parameters.Message);
            }
        } else {
            this.notifySubscribers(parsedMessage);
        }
    };

    // always called on WebSocket close
    private onClose = (event: CloseEvent): void => {
        // if we lost connection for some reason, try to regain it
        this.socket = null;
        this.connectWithTimeout();
    };

    // called before onClose in case of error
    private onError = (event: Event): void => {
    };


    public subscribe = (subscriber: IWebsocketSubscriber): () => void => {
        this.#subscribers.push(subscriber);

        if (this.isFirstConnect) {
            this.isFirstConnect = false;
            this.connectToWs();
        } else {
            this.connectWithTimeout();
        }

        return /*unsubscribe*/() => {
            const index = this.#subscribers.findIndex(s => s === subscriber);

            this.#subscribers.splice(index, 1);
        };
    };

    private notifySubscribers = (message: TWebsocketMessage): void => {
        for (const subscriber of this.#subscribers) {
            if (subscriber.types.includes(message.WebSocketMessageType)) {
                subscriber.callback(message);
            }
        }
    };

    private parseMessage = (message: string): TWebsocketMessage => {
        return JSON.parse(message) as TWebsocketMessage;
    };

}

const defaultWebsocketManager = new WebsocketManager();

export default defaultWebsocketManager;