import { TFetchFn } from "@utils/oneFetch";
import { REST_API_URL } from "../../../constants";
import { IReportHierarchy, IReportRowDef, IReportSortDef } from "./SmartReportTable";
import { ICellValueObject, ISort } from "../../table";
import {
    cleanSettings,
    findColumnInHierarchy,
    getColumnFullAlias,
    getMatchingColumn,
    IReportSettings,
    labelChildrenSuffix,
    labelColumnNamePrefix,
    TReportColumnOverrides
} from "@pages/reports/Report.utils";
import { ReportStorage } from "@pages/reports/ReportStorage";
import customFetch, { getDefaultPostParams } from "../../../utils/customFetch";
import { isDefined } from "@utils/general";
import React from "react";
import { IFieldInfoProperties } from "../FieldInfo";
import { LabelParent, LabelParentDescription } from "./SmartReportTable.styles";
import i18next from "i18next";

export const COLUMN_INDEX = "-i-";
export const COLUMN_INDEX_REGEX = new RegExp(`${COLUMN_INDEX}\\d+`);

/** Adds index to column id */
export const addIndexToDuplicateColumnId = (columnId: string, index: number) => {
    return isDefined(index) ? `${columnId}${COLUMN_INDEX}${index}` : columnId;
};

/** Strips the index from column id */
export const getCleanColumnId = (columnId: string) => {
    return columnId?.replace(COLUMN_INDEX_REGEX, "");
};

export interface IFetchReportTableDataArgs {
    path: string;
    settings: IReportSettings;
    reportHierarchy?: IReportHierarchy;
    sort?: ISort[];
    storage?: ReportStorage;
}

export const fetchReportTableData = async (args: IFetchReportTableDataArgs, fetchFn?: TFetchFn): Promise<Response> => {
    const fetchFunction = fetchFn ?? customFetch;

    const url = `${REST_API_URL}/${args.path}`;
    const settings = cleanSettings(args.settings);

    const sortColumns: ISort[] = [];

    for (let index = 0; index < args.sort?.length; index++) {
        const sortCol = args.sort[index];

        const id = sortCol.id.toString();
        const cleanId = getCleanColumnId(id);
        const alreadyUsedSort = sortColumns.find(sort => sort.id === cleanId);

        if (!alreadyUsedSort) {
            sortColumns.push({
                ...sortCol,
                id: cleanId
            });
        } else {
            // use sort value of the last column with same id
            alreadyUsedSort.sort = sortCol.sort;
        }

        // if we're sorting by the first column, and the column is grouped,
        // sort over all the columns!
        // when we support multi-column sorting, this will have to change a bit
        if (args.reportHierarchy.Aggregate && index === 0 && cleanId === getColumnFullAlias(args.reportHierarchy.Groups[0])) {
            for (let i = 1; i < args.reportHierarchy.Groups.length; i++) {
                const id = getColumnFullAlias(args.reportHierarchy.Groups[i]);

                if (!sortColumns.find(sort => sort.id === id)) {
                    sortColumns.push({
                        id: id,
                        sort: sortCol.sort
                    });
                }
            }
        }
    }

    if (sortColumns?.length > 0) {
        settings["Sort"] = sortColumns.map((sortColumn: ISort) => {
            let sortSettings: IReportSortDef;

            if (args.reportHierarchy && !sortColumn.id.toString().includes("COMPUTED")) {
                const column = findColumnInHierarchy(args.reportHierarchy, sortColumn.id.toString())
                    ?? getMatchingColumn(args.storage?.additionalFilters, sortColumn.id.toString());

                if (!column) {
                    return null;
                }
            } // else - for reports without reportHierarchy definition, we believe that the sort column exists

            sortSettings = {
                ColumnAlias: sortColumn.id.toString(),
                Order: sortColumn.sort
            };

            return sortSettings;
        }).filter(col => col);
    }

    if (args.reportHierarchy) {
        settings["ReportHierarchy"] = args.reportHierarchy;
    }

    const response = await fetchFunction(url, {
        ...getDefaultPostParams(),
        body: JSON.stringify(settings)
    });

    return response;
};

export const labelParentFormatter = (value: string): ICellValueObject => {
    const description = `(${i18next.t("Reporting:Common.WithChildren")})`;
    const title = `${value} ${description}`;

    return {
        tooltip: title,
        value: <>
            <LabelParent>{value}</LabelParent><LabelParentDescription>{description}</LabelParentDescription>
        </>
    };
};

export function getColumnOverride(overrides: TReportColumnOverrides, columnId: string): IFieldInfoProperties {
    if (typeof overrides === "function") {
        return overrides(columnId);
    }

    return overrides?.[columnId];
}

/** propagate group values (and other related label columns) from parents, so that we can build drilldown links with all necessary data */
export const propagateParentValues = (firstColumnGroup: string[], row: IReportRowDef, parent: IReportRowDef): void => {
    if (!parent) {
        return;
    }

    const propagateColumns = [...firstColumnGroup];

    for (const col of firstColumnGroup) {
        if (col.startsWith(labelColumnNamePrefix)) {
            const childrenKey = `${col}${labelChildrenSuffix}`;

            // we need to propagate children list to the Total rows
            // but not to the MergedGroup rows of the children themselves
            if (parent.Value.hasOwnProperty(childrenKey) && !row.Value[col]) {
                propagateColumns.push(childrenKey);
            }

            propagateColumns.push(col);
        }
    }

    for (const key of propagateColumns) {
        if (parent.Value.hasOwnProperty(key) && !row.Value.hasOwnProperty(key)) {
            row.Value[key] = parent.Value[key];
        }
    }
};