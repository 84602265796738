import styled from "styled-components/macro";
import TestIds from "../../testIds";
import { StyledTooltip } from "../tooltipIcon/TooltipIcon.styles";

export const StyledPopperTooltip = styled(StyledTooltip)`
    // styles specific for components used inside PopperTooltip
    [data-testid="${TestIds.WriteLine}"] input {
        color: ${props => props.theme.C_BTN_4L_emph_text};
    }
    
    [data-testid="${TestIds.WriteLine}"] input::placeholder {
        color: ${props => props.theme.C_ACT_thick_line};
    }

    [data-testid="${TestIds.Line}"] {
        background-color: ${props => props.theme.C_BTN_4L_emph_text};
    }

    [data-testid="${TestIds.CheckboxLabel}"] {
        color: ${props => props.theme.C_BTN_4L_emph_text};
    }

    [data-testid="${TestIds.Checkbox}"] {
        border: solid 1px rgba(255, 255, 255, 0.5);
        background-color: rgba(255, 255, 255, 0.5);
    }
`;

export const Background = styled.div`
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    background-image: ${props => `linear-gradient(to bottom, ${props.theme.C_BG_info})`};

    [data-popper-placement^="bottom"] & {
    background-image: ${props => `linear-gradient(to top, ${props.theme.C_BG_info})`};
    }
`;