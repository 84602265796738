import { getFilterBarItemRenderValue } from "@components/filterBar/FilterBar.utils";
import { IFieldDef, TInfoValue } from "@components/smart/FieldInfo";
import { IFilterGroupDef } from "@components/smart/smartFilterBar/SmartFilterBar.types";
import { TSmartODataTableStorage } from "@components/smart/smartTable/SmartODataTableBase";
import { prepareColumns } from "@components/smart/smartTable/SmartTable.utils";
import { ICellValueObject, IColumn, ISort } from "@components/table";
import { ITabData, ITabsProps } from "@components/tabs";
import { IToolbarItem } from "@components/toolbar";
import { getNestedValue } from "@odata/Data.utils";
import { getFieldInfo, IFieldInfo } from "@odata/FieldInfo.utils";
import { EntitySetName, EntityTypeName } from "@odata/GeneratedEntityTypes";
import { ActionTypeCode } from "@odata/GeneratedEnums";
import {
    getDefaultLogActionDetail,
    getFilterSentence,
    IFormatOptions,
    IPrepareQuerySettings,
    logAction,
    prepareQuery
} from "@odata/OData.utils";
import { IFetchDefinition, TFieldsDefinition } from "@pages/PageUtils";
import { ReportId } from "@pages/reports/ReportIds";
import { isNotDefined } from "@utils/general";
import { ITextListGroup, ITextListSubGroup } from "@utils/pdfPrinting/PdfPrinting";
import { saveAs } from "file-saver";
import { TFunction } from "i18next";

import {
    ConditionType,
    IComplexFilter,
    isComplexFilterArr,
    isValueHelperField
} from "../../components/smart/smartValueHelper";
import { IAppContext } from "../../contexts/appContext/AppContext.types";
import { LogicOperator, RowAction, TableViewMode, ToolbarItemType } from "../../enums";
import { TRecordAny, TValue } from "../../global.types";
import { StorageModel } from "../../model/StorageModel";
import { IFilterQuery, TableStorage } from "../../model/TableStorage";
import BindingContext, { IEntity } from "../../odata/BindingContext";
import ExcelExport, { ExportType } from "../../utils/ExcelExport";
import LocalSettings from "../../utils/LocalSettings";
import memoizeOne from "../../utils/memoizeOne";
import { TableButtonsAction } from "./TableToolbar.utils";

export const SECONDARY_FILTERS_ALL: string = null;
export const SECONDARY_FILTER_ALL_URL_NAME = "%00";

export interface IChangedFilter {
    info: IFieldInfo;
    value: TValue;
    bindingContext: BindingContext;
}

export interface ISplitPageTableDef<E extends IEntity = IEntity> extends ITableDef {
    filterBarDef?: IFilterGroupDef[];
    parentDefinition?: IFetchDefinition;
    // definition for tabs shown above table
    tabs?: ITableDefTabData[];
    // additional settings for tabs
    tabsSettings?: TITableDefTabSettings;
    massEditableDef?: TFieldsDefinition;
    lockProperty?: string;
    // disable variant load and storing localStorage variant, mainly for pairing tableViews
    preventStoreVariant?: boolean;
    draftDef?: IDraftTableSettings<E>;
}

interface IDraftTableSettings<E extends IEntity = IEntity> {
    draftEntitySet: EntitySetName;
    draftProperty: keyof E;
    draftFilter?: string;
    draftPropsBlacklist?: string[];
    draftAdditionalProps?: IFieldDef[];
}

export interface ITableDefTabData extends ITabData {
    // adds multiple ids into filter instead of just the one tab id
    filterNames?: string[];
}

export type TTableViewTabSettings =
    Partial<Pick<ITabsProps, "additionalData" | "additionalTabPrefix" | "showSearchBoxInMenu" | "selectWidth">>
    & {
    data?: ITableDefTabData[];
    // if present, tabs are automatically used for filtering
    filterFieldName?: string;
    // use instead of filterFieldName to build custom filter for selected tab
    customFilter?: (selectedTab: string, storage: StorageModel) => string;
    withoutCounts?: boolean;
};
export type TITableDefTabSettings = Omit<TTableViewTabSettings, "data">;

export interface ITableDef {
    // used for personalization service and local storage
    // string template is used to enforce that EntityType of the definition can be inferred from the id
    id: `${EntityTypeName}Table` | `${string}SpecialTable` | ReportId;
    // those properties which are always needed without dependence on columns
    additionalProperties?: IFieldDef[];
    // definition for all possible columns for this table
    columnDefinition?: TFieldsDefinition;
    // columns that are shown, based on current configuration
    columns?: string[];
    childColumns?: IFieldDef[];
    hierarchy?: string;
    groupBy?: string;
    initialSortBy?: ISort[];
    filterOperator?: LogicOperator;
    // filter that will be applied in every table request (e.g. to specify company)
    filter?: TInfoValue<string | IFilterQuery>;
    // custom settings passed to prepareQuery
    querySettings?: IPrepareQuerySettings;
    title?: string;
    entitySet?: EntitySetName;
    // used in PureForm to construct a filter for the table, based on the current selected row
    parentKey?: string;
}

export const handleExportButton = async (storage: TSmartODataTableStorage, exportType: ExportType, filter?: string): Promise<void> => {
    const columnDefs = storage.getMergedColumns(false);
    const columns = await prepareColumns({
        bindingContext: storage.data.bindingContext,
        columns: columnDefs,
        context: storage.context
    });

    const sortColumns = storage.tableAPI?.getSort() ?? storage.data.definition.initialSortBy;
    const sort: ISort[] = [];

    for (const sortColumn of sortColumns) {
        const sortColumnInfo = columns.find((col) => col.id === sortColumn.id);
        const sortId = sortColumnInfo?.bindingContext.getNavigationBindingContext(sortColumnInfo.fieldSettings?.displayName).getEntityPath();

        sort.push({
            id: sortId,
            sort: sortColumn.sort
        });
    }

    const query = prepareQuery({
        oData: storage.oData,
        bindingContext: storage.data.bindingContext,
        fieldDefs: [...columnDefs, ...(storage.data.definition.additionalProperties ?? [])],
        settings: {
            "": {
                filter,
                sort: sort.length > 0 ? sort : undefined
            }
        }
    });
    const res = await query.fetchData() as TRecordAny;
    const { rows, columns: preparedColumns } = await prepareDataForExport({
        rows: res.value, columns, bindingContext: storage.data.bindingContext,
        context: storage.context, translate: storage.t,
        hierarchy: storage.data.definition.hierarchy,
        storage
    });

    const file = await ExcelExport.export({
        columns: preparedColumns as unknown as IColumn[],
        tableName: storage.data.definition.title,
        rows: rows,
        type: exportType
    });

    // CompanyId is automatically added to OData request and thus is not part of 'filter'
    // but we still want Company Id to be part of the log
    const companyFilter = getDefaultLogActionDetail(storage);
    const logDetail = filter ? `${filter} AND ${companyFilter}` : companyFilter;

    logAction({
        actionId: storage.data.bindingContext.getFullPath(),
        actionType: exportType === ExportType.CSV ? ActionTypeCode.CsvExport : ActionTypeCode.ExcelExport,
        detail: logDetail
    });

    saveAs(file);
};

export interface IPrepareDataForExport {
    rows: IEntity[];
    columns: IFieldInfo[];
    bindingContext: BindingContext;
    context: IAppContext;
    translate: TFunction;
    storage?: TableStorage;
    hierarchy?: string;
}

export const prepareDataForExport = async ({
                                               rows,
                                               columns,
                                               bindingContext,
                                               context,
                                               hierarchy,
                                               translate,
                                               storage
                                           }: IPrepareDataForExport): Promise<{ rows: IEntity[], columns: IFieldInfo[] }> => {
    let preparedColumns = [...columns];

    // add currency as standalone columns
    for (let i = 0; i < preparedColumns.length; i++) {
        const column = preparedColumns[i];
        const currency = column.bindingContext.getProperty()?.getCurrency();

        if (currency) {
            const currencyColumn = await getFieldInfo({
                bindingContext: bindingContext.navigate(currency),
                context,
                fieldDef: { id: currency }
            });

            currencyColumn.label = `${currencyColumn.label} (${column.label})`;
            i += 1;
            preparedColumns.splice(i, 0, currencyColumn);
        }
    }

    const { rows: allRows, maxLevel } = unrollRows({
        rows, hierarchy,
        firstColumn: preparedColumns[0]?.id
    });

    for (let i = 0; i < maxLevel; i++) {
        preparedColumns.splice(i + 1, 0, {
            ...preparedColumns[0],
            id: `${preparedColumns[0].id}${i + 1}`,
            label: `${preparedColumns[0].label} - ${i + 1}.${translate("Components:Table.Level")}`
        });
    }

    const _getFormattedRowValue = (column: IFieldInfo, row: IEntity, value: TValue) => {
        const formattedValue = column.formatter?.(value, {
            entity: row,
            item: row,
            info: { id: column.id, bindingContext: column.bindingContext },
            storage
        });
        return typeof formattedValue !== "object" ? formattedValue : (formattedValue as ICellValueObject)?.tooltip;
    };

    const _exportedColumnId = (column: { id: string }, exportedCol: { id: string }): string =>
        `${column.id}_${exportedCol.id}`;

    const preparedRows = allRows.map((row: IEntity) => {
        const newRow: IEntity = {};

        for (const column of preparedColumns) {
            if (column.exportFormatter) {
                column.exportFormatter({
                    entity: row,
                    info: { id: column.id, bindingContext: column.bindingContext },
                    storage
                }).forEach(exportedCol => {
                    newRow[_exportedColumnId(column, exportedCol)] = exportedCol.value;
                });
            } else if (column.bindingContext.isLocal()) {
                newRow[column.id] = _getFormattedRowValue(column, row, null);
            } else {
                let value;
                if (column.bindingContext.isNavigation()) {
                    const prop = column.fieldSettings?.displayName ? column.fieldSettings.displayName : column.bindingContext.getKeyPropertyName();
                    value = getNestedValue(column.id, row)?.[prop];
                } else {
                    value = getNestedValue(column.id, row);
                }
                if (column.formatter && !column.bindingContext.getProperty()?.getCurrency()) {
                    newRow[column.id] = _getFormattedRowValue(column, row, value);
                } else {
                    newRow[column.id] = value;
                }
            }
        }

        return newRow;
    });

    // columns with export formatter may multiply itself to more columns -> duplicate also preparedColumns
    preparedColumns = preparedColumns.reduce((prepared, column) => {
        if (column.exportFormatter) {
            column.exportFormatter({
                info: { id: column.id, bindingContext: column.bindingContext },
                storage
            }).forEach(exportedCol => prepared.push({
                ...column,
                id: _exportedColumnId(column, exportedCol),
                label: exportedCol.label
            }));
        } else {
            prepared.push(column);
        }
        return prepared;
    }, []);

    return {
        rows: preparedRows, columns: preparedColumns
    };
};

interface IUnrollRows {
    rows: IEntity[];
    allRows?: IEntity[];
    level?: number;
    hierarchy?: string;
    firstColumn?: string;
    parentValues?: TValue[];
}

const unrollRows = ({ rows, allRows = [], level = 0, hierarchy, firstColumn, parentValues = [] }: IUnrollRows) => {
    let maxLevel = level;

    for (let row of rows) {
        const newParentValues = [...parentValues];
        row = { ...row };
        allRows.push(row);

        if (firstColumn) {
            if (parentValues.length > 0) {
                row[`${firstColumn}${parentValues.length}`] = row[firstColumn];

                for (let i = 0; i < parentValues.length; i++) {
                    let colName;

                    if (i === 0) {
                        colName = firstColumn;
                    } else {
                        colName = `${firstColumn}${i}`;
                    }

                    row[colName] = parentValues[i];
                }
            }

            newParentValues.push(row[firstColumn]);
        }

        if (hierarchy && row[hierarchy]) {
            for (const hierarchyRow of row[hierarchy]) {
                for (const [property, value] of Object.entries(row)) {
                    if (property !== hierarchy && !hierarchyRow.hasOwnProperty(property)) {
                        hierarchyRow[property] = value;
                    }
                }
            }
            const { maxLevel: innerMaxLevel } = unrollRows({
                rows: row[hierarchy], allRows, level: level + 1,
                hierarchy, firstColumn, parentValues: newParentValues
            });

            maxLevel = innerMaxLevel;
        }
    }

    return { rows: allRows, maxLevel };
};

export const getPrintDialogFilterList = (storage: TableStorage): ITextListGroup[] => {
    const changedFilters = storage.getChangedFilters();
    const subGroups: ITextListSubGroup[] = [];

    changedFilters.changedFields
        ?.filter(filter => isNotDefined(filter.info.isVisible) || filter.info.isVisible)
        ?.forEach((filter) => {
            const label = filter.info.label;
            let values: string[] = [];

            // for value helper complex values, display values are supposed to be different from filter bar read only values
            // and has to be split into two subgroups based on exclusion/inclusion
            if (isValueHelperField(filter.info, storage) && isComplexFilterArr(filter.value)) {
                const includedValues: string[] = [];
                const excludedValues: string[] = [];
                const complexValues = filter.value as IComplexFilter[];

                const options: IFormatOptions = {
                    entity: storage.data.entity
                };

                for (const complexValue of complexValues) {
                    const stringValue = getFilterSentence(complexValue, filter.info, options);

                    if (complexValue.type === ConditionType.Included) {
                        includedValues.push(stringValue);
                    } else {
                        excludedValues.push(stringValue);
                    }
                }

                if (includedValues.length > 0) {
                    subGroups.push({
                        label: `${label} (${storage.t("Components:ValueHelper.Include").toLowerCase()}):`,
                        values: includedValues
                    });
                }

                if (excludedValues.length > 0) {
                    subGroups.push({
                        label: `${label} (${storage.t("Components:ValueHelper.Exclude").toLowerCase()}):`,
                        values: excludedValues
                    });
                }
            } else {
                const { value } = getFilterBarItemRenderValue({
                    bindingContext: filter.bindingContext,
                    value: filter.value,
                    storage: storage
                });

                if (Array.isArray(value)) {
                    values = [...values, ...((value as string[]).map((val) => val.toString()))];

                } else {
                    values.push(value.toString());
                }

                subGroups.push({
                    label: `${label}:`,
                    values
                });
            }
        });

    if (subGroups.length === 0) {
        subGroups.push({
            label: storage.t("Components:PrintDialog.NoFilters"),
            values: []
        });
    }

    return [
        {
            label: storage.t("Components:PrintDialog.AppliedFilters"),
            subGroups: subGroups
        }
    ];
};

interface IAddToFilters {
    bc: BindingContext;
    tableId: string;
    filterName: string;
}

export const addToTableFilters = (args: IAddToFilters): void => {
    const filters = LocalSettings.get(args.tableId)?.filters;
    const currentFilters = filters?.[args.filterName] as string[];
    if (currentFilters?.length > 0) {
        currentFilters.push(args.bc.getKey() as string);
        LocalSettings.set(args.tableId, {
            filters: {
                ...filters,
                [args.filterName]: currentFilters as string[]
            }
        });
    }
};

export function getRowActionFromTableAction(tableAction: TableButtonsAction | string): RowAction {
    switch (tableAction) {
        case TableButtonsAction.Lock:
            return RowAction.Lock;
        case TableButtonsAction.Remove:
            return RowAction.Remove;
        case TableButtonsAction.MassEdit:
            return RowAction.MassEdit;
        default:
            return RowAction.Custom;
    }
}

interface ICreateCustomTableToolbarItem {
    tableAction: TableButtonsAction | string;
    id: string;
    iconName: string;
    label: string;
    forceDisabled?: boolean;
}

export const createCustomTableToolbarItem = (args: ICreateCustomTableToolbarItem): IToolbarItem => {
    const isDisabled = (!!args.tableAction && args.tableAction !== args.id) || args.forceDisabled;
    const isActive = args.tableAction === args.id;
    return {
        itemType: ToolbarItemType.Icon,
        id: args.id,
        iconName: args.iconName,
        label: args.label,
        isDisabled,
        isActive
    };
};

/** Prevent rerendering by keeping the same filter object, if possible */
export const getCachedFilter = memoizeOne((currentQuery: IFilterQuery, secondaryQuery: string) => {
    const query = { ...currentQuery };

    if (secondaryQuery) {
        if (query.query) {
            query.query += " AND ";
        }

        query.query += secondaryQuery;
    }

    return query;
});

export const isDraftView = (storage: TSmartODataTableStorage): boolean => {
    return storage.data.tableViewMode === TableViewMode.Draft;
};

export const handleSortChange = (storage: TSmartODataTableStorage, sort: ISort[]): void => {
    const variant = storage.getVariant();
    const variantColumns = variant?.columns;

    storage.setLocalStorageVariant({
        ...variant,
        columns: storage.createVariantColumns(variantColumns?.map(col => col.id), sort)
    });

};