import React from "react";
import { TObject } from "../../global.types";
import { CollapseWrapper, Level, Row, TriangleDown, TriangleRight } from "./ObjectExplorer.styles";
import { KeyName } from "../../keyName";

interface IProps {
    data: TObject;
}

interface IState {
    collapsedKeys: Map<string, boolean>;
}

export class ObjectExplorer extends React.PureComponent<IProps, IState> {
    state = {
        collapsedKeys: new Map()
    };

    handleKeyDown = (event: React.KeyboardEvent, key: string) => {
        if (event.key === KeyName.Enter || event.key === KeyName.Space) {
            this.expandClick(key);
        }
    };

    expandClick = (key: string) => {
        this.setState(({ collapsedKeys }) => {
            const isCollapsed = collapsedKeys.get(key);
            collapsedKeys.set(key, !isCollapsed);

            return {
                collapsedKeys: new Map(collapsedKeys)
            };
        });
    };

    renderExpandIcon = (key: string) => {
        const isCollapsed = this.state.collapsedKeys.get(key);
        const Triangle = isCollapsed ? <TriangleRight/> : <TriangleDown/>;
        return (
            <CollapseWrapper tabIndex={0} role="button" onClick={this.expandClick.bind(null, key)}
                             onKeyDown={(event: React.KeyboardEvent) => {
                                 this.handleKeyDown(event, key);
                             }}>
                {Triangle}
            </CollapseWrapper>
        );
    };

    renderCollapsedObject = (obj: TObject) => {
        const keys = Object.keys(obj);
        if (keys.length < 4) {
            const text = keys.sort((key1: string, key2: string) => {
                const item1 = obj[key1];
                const item2 = obj[key2];

                return typeof item1 === "object" && typeof item2 !== "object" ? -1 : 1;
            }).map(key => {
                const item = obj[key];
                if (Array.isArray(item)) {
                    return `${key}: [...]`;
                }

                if (typeof item === "object") {
                    return `${key}: {...}`;
                }

                return `${key}: "${item?.toString().substring(0, 20)}"`;
            }).slice(0, 3).join(", ");

            return ` {${text}} `;
        }

        return ` {...}`;
    };

    renderCollapsedArray = (arr: TObject[]) => {
        const length = arr.length;
        return (
            <>
                {` (${length}) `}
                {arr.map(it => {
                    return ` {...} `;
                })}
            </>
        );

    };

    getCompleteKey = (parentKey: string, key: string) => {
        return `${parentKey ? `${parentKey}.` : ""}${key}`;
    };

    renderCollapsed = (obj: TObject | TObject[], parentKey: string, key: string) => {
        const completeKey = this.getCompleteKey(parentKey, key);
        const isTop = parentKey === "";
        const isArray = Array.isArray(obj);

        return (
            <Level key={completeKey}
                   isTop={isTop}>
                <Row>
                    {this.renderExpandIcon(completeKey)}
                    {key}:
                    {!isArray && this.renderCollapsedObject(obj)}
                    {isArray && this.renderCollapsedArray(obj)}
                </Row>
            </Level>
        );
    };


    renderObject = (obj: TObject, parentKey: string): React.ReactNode => {
        const single: string[] = [];
        const isTop = parentKey === "";

        const objects = Object.keys(obj).map(key => {
            const completeKey = this.getCompleteKey(parentKey, key);
            const isCollapsed = this.state.collapsedKeys.get(completeKey);
            if (isCollapsed) {
                return this.renderCollapsed(obj[key] as TObject | TObject[], parentKey, key);
            }

            if (typeof obj[key] === "object" && obj[key] !== null) {
                return (
                    <Level key={key}
                           isTop={isTop}>
                        <Row>
                            {this.renderExpandIcon(completeKey)}
                            {key}:
                        </Row>
                        {this.renderObject(obj[key] as TObject, completeKey)}
                    </Level>
                );
            }
            single.push(key);
            return null;
        });

        const singles = single.map(key => {
            return (
                <Level key={key}
                       isTop={isTop}>
                    {key}: {`"${obj[key]}"`}
                </Level>
            );
        });

        return objects.concat(singles);
    };

    render() {
        return this.renderObject(this.props.data, "");
    }
}