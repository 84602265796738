import Dialog from "@components/dialog";
import Field from "@components/inputs/field";
import FieldsWrapper from "@components/inputs/field/FieldsWrapper";
import { IMultiSelectionChangeArgs, MultiSelect } from "@components/inputs/select/MultiSelect";
import { ISelectItem } from "@components/inputs/select/Select.types";
import { ISmartODataTableAPI } from "@components/smart/smartTable/SmartODataTableBase";
import { EntitySetName, ICompanyEntity } from "@odata/GeneratedEntityTypes";
import { WithOData, withOData } from "@odata/withOData";
import i18next from "i18next";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import { BasicInputSizes } from "../../../enums";
import { TableStorage } from "../../../model/TableStorage";
import ConfirmationButtons from "../../../views/table/ConfirmationButtons";
import { IUserTableCustomData } from "./UsersTableView";

interface IProps extends WithOData, WithTranslation {
    storage: TableStorage<ISmartODataTableAPI ,IUserTableCustomData>;
    onClose: () => void;
    onConfirm: () => Promise<void>;
}

interface IState {
    companyItems: ISelectItem[];
    value: number[];
    busy: boolean;
}

class SelectCompanyDialog extends React.Component<IProps, IState> {
    state: IState = {
        companyItems: [],
        value: [],
        busy: false
    };

    async componentDidMount(): Promise<void> {
        const res = await this.props.storage.oData.getEntitySetWrapper(EntitySetName.Companies).query().fetchData<ICompanyEntity[]>();
        const companies = res.value;
        const companyItems = companies.map(company => {
            return {
                id: company.Id,
                label: company.Name
            };
        });
        this.setState({ companyItems });
    }

    handleChange = (args: IMultiSelectionChangeArgs) => {
        const value = args.value as number[];
        this.props.storage.setCustomData({
            selectedCompaniesIds: value
        });
        this.setState({ value });
    };

    handleConfirm = async () => {
        this.setState({ busy: true });
        await this.props.onConfirm();
        this.setState({ busy: false });
    }

    render() {
        return (
                <Dialog
                        title={this.props.storage.t("Users:AssignRole")}
                        isEditableWindow={false}
                        onConfirm={this.handleConfirm}
                        onClose={this.props.onClose}
                        busy={false}
                        footer={
                            <ConfirmationButtons
                                    confirmText={this.props.storage.t("Users:Assign")}
                                    onCancel={this.props.onClose}
                                    onConfirm={this.handleConfirm}
                                    useWrapper={false}
                            >
                            </ConfirmationButtons>
                        }>
                    <FieldsWrapper>
                        <Field label={i18next.t("Users:ForAgenda")}>
                            <MultiSelect
                                    width={BasicInputSizes.XL}
                                    value={this.state.value}
                                    items={this.state.companyItems}
                                    onChange={this.handleChange}
                            />
                        </Field>
                    </FieldsWrapper>
                </Dialog>

        );
    }
}



export default withOData(withTranslation(["Users"])(SelectCompanyDialog));
