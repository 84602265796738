import React from "react";
import { IRow, ISort, TId } from "@components/table";
import memoizeOne from "../../../utils/memoizeOne";
import { EntitySetName, IUserEntity, UserEntity } from "@odata/GeneratedEntityTypes";
import { userNameWithAvatarFormatter } from "../users/UsersDef";
import AssignedUsersTable from "../generalRoles/AssignedUsersTable";
import { AvatarSize, Sort } from "../../../enums";
import { CompanyNameWithAvatar } from "../users/Users.styles";
import Tokenizer from "../../../components/token/Tokenizer";
import { getCompanyLogoUrl } from "@utils/CompanyUtils";
import Avatar from "../../../components/avatar";
import MoreItems from "../../../components/moreItems/MoreItems";

class AssignedCompanyUsersTable extends AssignedUsersTable {
    columns = [
        { id: "FirstName", label: this.props.storage.t("CompanyRoles:User"), width: 200 },
        { id: "Company", label: this.props.storage.t("CompanyRoles:Agenda"), width: 300 , disableSort: true }
    ];
    tableId = "companyRoleAssignedUsers";

    getRows = memoizeOne(async (id: TId, sort: ISort[]): Promise<IRow[]> => {
        const query = this.props.storage.oData.getEntitySetWrapper(EntitySetName.Users).query()
                .select(UserEntity.Id, UserEntity.FirstName, UserEntity.LastName)
                .filter(`CompanyRoles/any(user: user/CompanyRole/Id eq ${id})`)
                .expand("CompanyRoles",
                        g => g.filter(`CompanyRole/Id eq ${id}`)
                                .select("Id")
                                .expand("Company",
                                        q => q.select("Name")
                                                .expand("Logo"))
                                .expand("CompanyRole", q => q.select("Id")));

        (sort ?? this.state.sort).forEach((sort) => {
            query.orderBy(sort.id, sort.sort === Sort.Asc);
        });

        const users = await query.fetchData<IUserEntity[]>();
        return users.value.map((user: IUserEntity) => {
            const companies = user.CompanyRoles.filter(role => role.CompanyRole.Id === id);
            const companyNames = companies.map(role => role.Company.Name);
            const logoUrl = getCompanyLogoUrl(companies[0].Company);
            const getTooltipText = () => {
                return <Tokenizer isWrappable
                                  tokens={companyNames.slice(1).map((id) => ({
                                      id,
                                      color: "#fff",
                                      title: id
                                  }))}/>;
            };
            return {
                id: user.Id,
                values: {
                    FirstName: userNameWithAvatarFormatter(user.FirstName, { entity: user }, {
                        isBold: false,
                        withLink: true
                    }),
                    Company: {
                        tooltip: companyNames.join(", "),
                        value: <CompanyNameWithAvatar>
                            <Avatar src={logoUrl} isRound size={AvatarSize.S}/>&nbsp;&nbsp;
                            {companyNames[0]}&nbsp;
                            <MoreItems
                                    count={user.CompanyRoles.length - 1}
                                    tooltipContent={getTooltipText()}
                            />
                        </CompanyNameWithAvatar>
                    }
                }
            };
        });
    });
}

export default AssignedCompanyUsersTable;