import { ISmartODataTableAPI } from "./SmartODataTableBase";
import { ISmartReportTableAPI } from "./index";

export type TSmartTableAPI = ISmartODataTableAPI | ISmartReportTableAPI;

/** Registers and exposes table API for all SmartOdataTable and SmartReportTable components */
class SmartTableManager {
    tables: Record<string, TSmartTableAPI> = {};

    registerTable = (tableId: string, tableAPI: TSmartTableAPI) => {
        this.tables[tableId] = tableAPI;
    };

    unregisterTable = (tableId: string) => {
        delete this.tables[tableId];
    };

    getTable = (tableId: string): TSmartTableAPI => {
        return this.tables[tableId];
    };
}

const SmartTableManagerSingleton = new SmartTableManager();

export default SmartTableManagerSingleton;