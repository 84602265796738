import React from "react";

import TestIds from "../../testIds";
import { TooltipIconInfo } from "../tooltipIcon";
import { Header, SeparatorStyledFormGroup, Title } from "./FormGroup.styles";

interface IGroupHeaderProps {
    title?: string;
    showLine?: boolean;
    tooltip?: React.ReactElement | string;
    className?: string;
    style?: React.CSSProperties;
    isLightText?: boolean;
}

export class GroupHeader extends React.Component<IGroupHeaderProps> {
    render() {
        if (!this.props.title) {
            return null;
        }

        return (
            <Header data-testid={TestIds.FormGroupHeader}
                    className={this.props.className}
                    style={this.props.style}>
                {/*todo move title, tooltip and children to Separator*/}
                <Title data-testid={TestIds.Title}
                       isLight={this.props.isLightText}
                >
                    {this.props.title}
                </Title>
                {this.props.tooltip && <TooltipIconInfo>{this.props.tooltip}</TooltipIconInfo>}
                {this.props.children}
                {this.props.showLine && <SeparatorStyledFormGroup/>}
            </Header>
        );
    }
}