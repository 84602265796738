import React from 'react';
import { LabelRow, LoginFieldStyled, StyledLabel, TooltipIconInfoStyled } from "../LoginPage.styles";
import { TextAlign } from "../../enums";
import { StyledTooltipContent } from "@components/tooltipIcon/TooltipIcon.styles";
import TestIds from "../../testIds";

interface IProps {
    name: string;
    label: string;
    tooltip?: string;
    textAlign?: TextAlign;
    afterContent?: React.ReactNode;
}

const LoginField: React.FC<IProps> = ({ children, name, label, tooltip, textAlign, afterContent }) => {
    const input = children as React.ReactElement;
    const passProps = {
        id: name,
        name,
        textAlign: textAlign ?? TextAlign.Left,
        maxWidth: "100%",
        minWidth: "0px"
    };
    return (
            <LoginFieldStyled data-testid={TestIds.LoginInputField} data-name={name}>
                <LabelRow>
                    <StyledLabel htmlFor={name} data-testid={TestIds.FieldLabel}>
                        {label}
                    </StyledLabel>
                    {tooltip && (
                            <TooltipIconInfoStyled>
                                <StyledTooltipContent>{tooltip}</StyledTooltipContent>
                            </TooltipIconInfoStyled>
                    )}
                </LabelRow>
                {React.cloneElement(input, passProps)}
                {afterContent}
            </LoginFieldStyled>
    );
};

export default LoginField;