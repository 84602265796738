import styled, { css, keyframes } from "styled-components/macro";
import { mix, transparentize } from "polished";
import { LocalStackOrder } from "../../global.style";
import { PropsWithTheme } from "../../theme";
import { BusyIndicatorSize } from "./BusyIndicator.utils";

const XS_ANIMATION_LENGTH = "1s";
const M_ANIMATION_LENGTH = "0.88s";
const XL_ANIMATION_LENGTH = "0.96s";

const rotationXSAnimation = keyframes`
    0% {
        transform: rotate(0deg);
    }

    52% {
        transform: rotate(240deg);
    }

    100% {
        transform: rotate(360deg);
    }
`;

const rotationMAnimation = keyframes`
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(240deg);
    }

    100% {
        transform: rotate(360deg);
    }
`;

const rotationLOuterAnimation = keyframes`
    0% {
        transform: rotate(0deg);
    }

    42% {
        transform: rotate(240deg);
    }

    100% {
        transform: rotate(360deg);
    }
`;

const rotationLInnerAnimation = keyframes`
    0% {
        transform: rotate(0deg);
    }

    58% {
        transform: rotate(240deg);
    }

    100% {
        transform: rotate(360deg);
    }
`;

// clip-path seems to have bad performance
// keep as possible solution, but for now, only use rotation animation
// const trimXSAnimation = keyframes`
//     0% {
//         clip-path: circle(100%);
//     }
//
//     40% {
//         clip-path: circle(70%);
//     }
//
//     76% {
//         clip-path: circle(100%);
//     }
// `;
//
// const trimMAnimation = keyframes`
//     0% {
//         clip-path: circle(100%);
//     }
//
//     50% {
//         clip-path: circle(40%);
//     }
//
//     100% {
//         clip-path: circle(100%);
//     }
// `;

const getDimensions = (size: BusyIndicatorSize) => {
    switch (size) {
        case BusyIndicatorSize.L:
            return "200px";
        case BusyIndicatorSize.M:
            return "150px";
        case BusyIndicatorSize.XS:
            return "30px";
    }
};

const getBackgroundColor = (props: PropsWithTheme & { $isInverse?: boolean }) => {
    if (props.$isInverse) {
        return props.theme.C_ACT_main ?? "#690048";
    }

    return props.theme.C_ACT_hover_without_opacity ?? "#f2e1eb";
};

const getBackgroundColorWithTransparency = (props: PropsWithTheme & { $isInverse?: boolean }) => {
    return transparentize(0.25, getBackgroundColor(props));
};

const getBackgroundColorWithAppliedTransparency = (props: PropsWithTheme & { $isInverse?: boolean }) => {
    return mix(0.25, getBackgroundColor(props), props.theme.C_BG_floorplan ?? "#fafafa");
};

const getStrokeColor = (props: PropsWithTheme & { $isInverse?: boolean }) => {
    if (props.$isInverse) {
        return props.theme.C_BUSY_INVERSE;
    }

    return props.theme.C_ACT_main ?? "#690048";
};

const getStrokeColorWithAppliedTransparency = (props: PropsWithTheme & { $isInverse?: boolean }) => {
    if (props.$isInverse) {
        return getStrokeColor(props);
    }

    return mix(0.3, getStrokeColor(props), mix(0.75, getBackgroundColor(props), props.theme.C_BG_floorplan ?? "#fafafa"));
};

export const StyledBusyIndicatorWrapper = styled.div<{
    $isOverComponent?: boolean;
}>`
    display: block;
    width: ${props => props.$isOverComponent ? "calc(100% + 6px)" : "100%"};
    height: ${props => props.$isOverComponent ? "calc(100% + 6px)" : "100%"};
    position: absolute;
    top: ${props => props.$isOverComponent ? "-3px" : "0"};
    left: ${props => props.$isOverComponent ? "-3px" : "0"};
    z-index: ${LocalStackOrder.Overlay};
    border-radius: inherit;
`;

export const StyledBusyIndicator = styled.div<{
    $size?: BusyIndicatorSize;
    $isInverse?: boolean;
    $isWithoutBackground?: boolean;
    $isOpaque: boolean;
}>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    // BusyIndicator can be used outside of ThemeProvider => provide default color as string
    background-color: ${props => props.$isWithoutBackground ? null : getBackgroundColorWithTransparency(props)};
    ${props => props.$isOpaque && css`
        background-color: ${getBackgroundColorWithAppliedTransparency(props)};
    `};
    border-radius: inherit;

    // todo add theme colors
    // remove inline svg styles, just like in icons
    svg {
        width: ${props => getDimensions(props.$size)};
        height: ${props => getDimensions(props.$size)};

        & * {
            transform-origin: center;
        }

        .BUSY_main {
            fill: none;
            stroke: ${props => getStrokeColor(props)};
            stroke-width: 3;
            stroke-linecap: round;
            stroke-miterlimit: 10;
        }

        .BUSY_main_transparent {
            opacity: 1;
            fill: none;
            stroke: ${props => getStrokeColorWithAppliedTransparency(props)};
            stroke-width: 3;
            stroke-linecap: round;
            stroke-miterlimit: 10;
        }

        .BUSY_main_fill_transparent {
            opacity: 1;
            fill: ${props => getStrokeColorWithAppliedTransparency(props)};
        }

        .BUSY_special {
            fill: transparent;
            stroke: ${props => getStrokeColor(props)};
            stroke-width: 3;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-miterlimit: 10;
        }
    }

    svg.busyXS {
        path {
            animation: ${rotationXSAnimation} ${XS_ANIMATION_LENGTH} infinite linear;
        }
    }

    svg.busyM {
        transform: scaleX(-1);

        .mainPart {
            animation: ${rotationMAnimation} ${M_ANIMATION_LENGTH} infinite linear;
        }

        .otherLines path {
            animation: ${rotationMAnimation} ${M_ANIMATION_LENGTH} infinite linear;
        }
    }

    svg.busyL {
        .outerPart {
            animation: ${rotationLOuterAnimation} ${XL_ANIMATION_LENGTH} infinite linear;
        }

        .innerPart {
            animation: ${rotationLInnerAnimation} ${XL_ANIMATION_LENGTH} infinite linear;
        }
    }
`;

export const BusyWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    // just reset z-index root
    z-index: ${LocalStackOrder.Base};
`;
