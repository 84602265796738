import React, { useState } from "react";

import useHideOnBlur from "../hooks/HideOnBlurHook";

type TSetHideOptions = (refs: React.RefObject<HTMLElement>[], onHide?: () => void) => void;

export interface WithHideOnBlur {
    isVisible: boolean;
    setIsVisible: (isVisible: boolean) => void;
    isContained: (elem: HTMLElement) => boolean;
    setHideOnBlurOptions: TSetHideOptions;
}

export interface IWithHideOnBlurSettings {
    refs: React.RefObject<HTMLElement>[];
    initialIsVisible: boolean;
    onHide?: () => void;
}

export const withHideOnBlur = <P extends WithHideOnBlur>(Component: React.ComponentType<P>, initialIsVisible = false): React.ComponentType<Omit<P, keyof WithHideOnBlur>> => {
    return React.forwardRef((props: Omit<P, keyof WithHideOnBlur>, ref) => {
        const [refs, setRefs] = useState([]);
        const [onHide, setOnHide] = useState(null);
        const { isVisible, setIsVisible, isContained } = useHideOnBlur({
            refs, initialIsVisible, onHide
        });
        const setHideOnBlurOptions: TSetHideOptions = (refs, onHideFn) => {
            setRefs(refs);
            setOnHide(() => onHideFn);
        };

        return (
            <Component ref={ref}
                       isVisible={isVisible}
                       setIsVisible={setIsVisible}
                       isContained={isContained}
                       setHideOnBlurOptions={setHideOnBlurOptions}
                // cast props as per https://github.com/Microsoft/TypeScript/issues/28938#issuecomment-450636046
                       {...(props as P)}/>
        );
    }) as unknown as React.ComponentType<Omit<P, keyof WithHideOnBlur>>;
};