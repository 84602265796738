import Field from "@components/inputs/field";
import { Select } from "@components/inputs/select";
import { ISelectionChangeArgs, ISelectItem } from "@components/inputs/select/Select.types";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { getUtcDayjs } from "../../types/Date";
import memoize from "../../utils/memoize";

const getInitialYearSelectItems = memoize((): ISelectItem[] => {
    const thisYear = getUtcDayjs().get("year");
    const items: ISelectItem[] = [];

    for (let i = thisYear - 5; i < thisYear + 2; i++) {
        items.push({
            id: i,
            label: i.toString()
        });
    }

    return items;
});

export const CbaInitialYearSelection: React.FunctionComponent<{
    initialYear: number;
    onChange: (value: number) => void;
}> = React.memo((props) => {
    const { initialYear } = props;
    const { t } = useTranslation(["Companies", "Common"]);
    const handleYearChange = useCallback((e: ISelectionChangeArgs) => {
        props.onChange(e.value as number);
    }, []);

    return (
            <Field label={t("Companies:Wizard.InitialYear")}
                   isRequired>
                <Select
                        onChange={handleYearChange}
                        value={initialYear}
                        items={getInitialYearSelectItems()}
                />
            </Field>
    );
});