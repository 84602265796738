import React from "react";
import TestIds from "../../testIds";
import { IconButton } from "../button";
import { CaretIcon, CloseIcon } from "../icon";
import { BasicInputSizes, IconSize } from "../../enums";
import { WithTranslation, withTranslation } from "react-i18next";
import { Select } from "../inputs/select";
import { DragTransferId } from "./ImportSettings";
import {
    DropItemColumnWrapper,
    DropItemFirstColumn,
    DropItemSecondColumn,
    DropItemThirdColumn,
    ItemSelectorStyled,
    RequiredMarkStyled,
    SelectedItemLabelStyled,
    ValueWrapper
} from "./ImportSettings.styles";
import {
    IFieldComponentProps,
    ISelectionChangeArgs,
    ISelectItem,
    TSelectItemId
} from "@components/inputs/select/Select.types";

export interface IProps {
    id: string;
    title: string;
    value?: string;
    isRequired?: boolean;
    // cannot be changed by other item nor removed
    isDisabled?: boolean;

    selectItems?: ISelectItem[];
    selectValue?: TSelectItemId;
    onSelectChange?: (e: ISelectionChangeArgs, id: string) => void;

    // adds background color and padding
    isInGroup?: boolean;
    // manually add top margin if the item is next to a group
    addTopMargin?: boolean;

    onDragEnter: (event: React.DragEvent<HTMLDivElement>, id: string) => void;
    onDragLeave: (event: React.DragEvent<HTMLDivElement>, id: string) => void;
    onDrop: (event: React.DragEvent<HTMLDivElement>, id: string, value: string) => void;

    onRemove: (id: string) => void;
}

interface IPropsExtended extends IProps, WithTranslation {
}

const CloseButtonStyle: React.CSSProperties = {
    position: "absolute",
    right: "5px",
    top: "6px"
};

class ImportSettingsDropItem extends React.PureComponent<IPropsExtended> {
    allowDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
    };

    handleDrop = (e: React.DragEvent<HTMLDivElement>): void => {
        const val = e.dataTransfer.getData(DragTransferId);
        const target = (e.target as HTMLDivElement);

        target.setAttribute("style", "");

        this.props.onDrop?.(e, this.props.id, val);
        e.stopPropagation();
    };

    handleDragEnter = (e: React.DragEvent<HTMLDivElement>): void => {
        this.props.onDragEnter?.(e, this.props.id);
    };

    handleDragLeave = (e: React.DragEvent<HTMLDivElement>): void => {
        this.props.onDragLeave?.(e, this.props.id);
    };

    handleRemoveClick = (): void => {
        this.props.onRemove(this.props.id);
    };

    handleSelectChange = (e: ISelectionChangeArgs): void => {
        this.props.onSelectChange?.(e, this.props.id);
    };

    renderSelectFieldComponent = (props: IFieldComponentProps): React.ReactElement => {
        const item = this.props.selectItems.find(item => item.id === this.props.selectValue);

        // eslint-disable-next-line react/jsx-no-undef
        return <ItemSelectorStyled
                ref={props.openerRef as React.Ref<HTMLDivElement>}
                data-testid={TestIds.ImportSettingsDropItemSelector}>
            <SelectedItemLabelStyled>
                {item.label}
            </SelectedItemLabelStyled>
            <IconButton
                    title={this.props.t("Common:General.Open")}
                    isDecorative
                    onClick={props.onClick}
                    onKeyDown={props.onKeyDown}
                    onBlur={props.onBlur}
            >
                <CaretIcon width={IconSize.M} height={IconSize.M}/>
            </IconButton>
        </ItemSelectorStyled>;
    };

    render() {
        const hasValue = !!this.props.value;
        const value = this.props.value;

        return (
            <>
                <DropItemColumnWrapper isInGroup={this.props.isInGroup}
                                       addTopMargin={this.props.addTopMargin}
                                       data-testid={TestIds.ImportSettingsDropItemLabel}>
                    <DropItemFirstColumn isInGroup={this.props.isInGroup}>
                        {this.props.isRequired && <RequiredMarkStyled data-testid={TestIds.RequiredMark}/>}
                        {this.props.title}
                    </DropItemFirstColumn>
                </DropItemColumnWrapper>
                <DropItemColumnWrapper isInGroup={this.props.isInGroup} addTopMargin={this.props.addTopMargin}>
                    <DropItemSecondColumn isInGroup={this.props.isInGroup}>=</DropItemSecondColumn>
                </DropItemColumnWrapper>
                <DropItemColumnWrapper isInGroup={this.props.isInGroup}
                                       addTopMargin={this.props.addTopMargin}
                                       data-testid={TestIds.ImportSettingsDropItemDroppable}>
                    <DropItemThirdColumn
                        isInGroup={false}
                        hasValue={hasValue}
                        onDrop={this.props.isDisabled ? null : this.handleDrop}
                        onDragEnter={this.props.isDisabled ? null : this.handleDragEnter}
                        onDragLeave={this.props.isDisabled ? null : this.handleDragLeave}
                        onDragOver={this.props.isDisabled ? null : this.allowDrop}>
                        {hasValue && <ValueWrapper data-testid={TestIds.ValueWrapper}>{value}</ValueWrapper>}
                        {hasValue && this.props.selectItems &&
                            <Select
                                onChange={this.handleSelectChange}
                                width={BasicInputSizes.M}
                                isSharpRight={true}
                                value={this.props.selectValue}
                                items={this.props.selectItems}
                                fieldComponent={this.renderSelectFieldComponent}
                            />
                        }
                        {hasValue && !this.props.isDisabled &&
                            <IconButton
                                title={this.props.t("Common:Remove")}
                                onClick={this.handleRemoveClick}
                                isDecorative
                                style={CloseButtonStyle}>
                                <CloseIcon
                                    width={IconSize.S}
                                    height={IconSize.S}/>
                            </IconButton>}
                    </DropItemThirdColumn>
                </DropItemColumnWrapper>
            </>
        );
    }
}

export type { IProps as IImportSettingsItemProps };
export default withTranslation(["Common"])(ImportSettingsDropItem);