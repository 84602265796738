import { IconSize, PaneStatus } from "../../enums";
import React, { ReactElement, RefObject } from "react";
import {
    Bubble,
    BubbleIconWrapper,
    BubbleWrapper,
    Content,
    IconsWrapper,
    LastPaneIcon,
    PaneIconButton,
    Resizer,
    SecondaryBubbleIconWrapper,
    SecondaryTabPane,
    Shadow,
    Splitter,
    SplitterBackground,
    StyledPane
} from "./SplitLayout.styles";

import { CloseIcon, ExpandIcon, getIcon } from "../icon";
import { WithTranslation, withTranslation } from "react-i18next";
import TestIds from "../../testIds";
import { FOCUSABLE_ATTR, IFocusableItemProps } from "../focusManager";
import { KeyName } from "../../keyName";
import BusyIndicator from "../busyIndicator";
import { BusyIndicatorType } from "../busyIndicator/BusyIndicator.utils";

export interface IPaneBookmark {
    id: string;
    icon: string;
    isActive?: boolean;
}

export interface IPaneProps extends WithTranslation, IFocusableItemProps {
    className?: string;
    isResizable?: boolean;
    status?: PaneStatus;
    /** Indicator of the last pane*/
    isLast?: boolean;
    /** Indicates there is only one visible pane in the split layout*/
    isSolo?: boolean;
    /** Fixed pane doesn't display icons, it's width is according to content and is not resized nor hidden */
    isFixed?: boolean;
    /** Index for the pane */
    index?: number;
    icon?: string;
    visible?: boolean;
    width?: string;
    order?: number; // allow change order in the DOM, supports fixed panes to be at the end, so it doesn't affect the status
    showPaneGradient?: boolean;
    showSplitterGradient?: boolean;
    isHighlighted?: boolean;
    paneAdditionalInfo?: ReactElement;

    isBusy?: boolean;
    isBusyDelayed?: boolean;

    showFakeBubble?: boolean; // forces to show right buble even if it's solo pane (used at inbox page)

    secondaryBookmark?: IPaneBookmark;
    onBookmarkChange?: (bookmarkId: string, isActive: boolean) => void;

    onMouseDown?: (e: React.MouseEvent, refPane: RefObject<HTMLDivElement>) => void;
    onCollapsePane?: (refPane: RefObject<HTMLDivElement>) => void;
    onExpandPane?: (refPane: RefObject<HTMLDivElement>) => void;
    onPaneClick?: (refPane: RefObject<HTMLDivElement>) => void;
    onSplitterClick?: (refPane: RefObject<HTMLDivElement>) => void;
}

interface IPaneState {
    collapsedHovered: boolean;
}

class Pane extends React.Component<IPaneProps, IPaneState> {
    refPane = React.createRef<HTMLDivElement>();

    constructor(props: IPaneProps) {
        super(props);

        this.refPane = React.createRef();
        this.state = {
            collapsedHovered: false
        };
    }

    get isResizable() {
        return this.props.isResizable && !this.props.isLast && !this.props.showFakeBubble;
    }

    handleClick = (e: React.MouseEvent) => {
        if (this.isResizable) {
            this.props.onMouseDown(e, this.refPane);
        }
    };

    handleCollapsePane = () => {
        this.props.onCollapsePane(this.refPane);
    };

    handleExpandPane = () => {
        this.props.onExpandPane(this.refPane);
    };

    handlePaneClick = () => {
        if (this.props.status === PaneStatus.Collapsed) {
            this.props.onPaneClick(this.refPane);
        }
    };

    handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === KeyName.Enter) {
            this.handlePaneClick();
        }
    };

    handleSecondaryTabPaneClick = () => {
        this.props.onBookmarkChange?.(this.props.secondaryBookmark.id, !this.props.secondaryBookmark.isActive);
    };

    handleBubbleMouseOver = () => {
        if (this.props.status === PaneStatus.Collapsed) {
            this.setState({
                collapsedHovered: true
            });
        }
    };

    handleBubbleMouseOut = () => {
        this.setState({
            collapsedHovered: false
        });
    };

    getPaneWidth = (isCollapsed: boolean, isExpanded: boolean) => {
        if (isCollapsed) {
            return this.props.isLast ? "60px" : "38px";
        }


        return isExpanded || this.props.isSolo ? "100%" : this.props.width;
    };

    getMainBubbleIcon = () => {
        const isSecondaryBookmarkActive = !!this.props.secondaryBookmark?.isActive;

        return getIcon(!isSecondaryBookmarkActive ? this.props.icon : this.props.secondaryBookmark.icon);
    };

    getSecondaryBubbleIcon = () => {
        const isSecondaryBookmarkActive = !!this.props.secondaryBookmark?.isActive;

        return getIcon(!isSecondaryBookmarkActive ? this.props.secondaryBookmark.icon : this.props.icon);
    };

    renderSecondaryBookmarkPane = (isGrayBubble: boolean) => {
        const Icon = this.getSecondaryBubbleIcon();

        return (
                <SecondaryTabPane onClick={this.handleSecondaryTabPaneClick}>
                    <BubbleWrapper
                            isSecond={!this.props.secondaryBookmark.isActive}>
                        <Bubble isGrayBubble={isGrayBubble}
                                isWider/>
                    </BubbleWrapper>
                    <SecondaryBubbleIconWrapper
                            isSecond={!this.props.secondaryBookmark.isActive}>
                        {Icon &&
                                <Icon width={IconSize.S} preventHover/>
                        }
                    </SecondaryBubbleIconWrapper>
                </SecondaryTabPane>
        );
    };

    render() {
        const isCollapsed = this.props.status === PaneStatus.Collapsed;
        const isExpanded = this.props.status === PaneStatus.Expanded;
        const width = this.getPaneWidth(isCollapsed, isExpanded);
        const isLastCollapsed = this.props.isLast && isCollapsed && !this.props.showFakeBubble;
        const isLastOpen = this.props.isLast && !isCollapsed;

        const isGrayBubble = false; // this.props.index === 1 && isCollapsed;
        const isSecondaryBookmark = !!this.props.secondaryBookmark;

        const Icon = this.getMainBubbleIcon();

        return (
                <StyledPane
                        className={this.props.className}
                        isCollapsedHovered={this.state.collapsedHovered}
                        isHighlighted={this.props.isHighlighted}
                        onClick={this.handlePaneClick}
                        onKeyDown={this.handleKeyDown}
                        ref={this.refPane}
                        isCollapsed={isCollapsed}
                        isFixed={this.props.isFixed}
                        data-focusable={this.props[FOCUSABLE_ATTR]}
                        data-focusable-order={this.props.order ?? 0}
                        data-status={this.props.status}
                        tabIndex={this.props.tabIndex}
                        isLastCollapsed={isLastCollapsed}
                        style={{
                            width: width,
                            order: this.props.order
                        }}
                        shouldShowGradient={this.props.showPaneGradient}
                        data-testid={TestIds.AccordionPanel}>
                    <Content
                            isCollapsed={isCollapsed}
                            aria-hidden={isCollapsed}
                            isFixed={this.props.isFixed}
                            hasSecondaryTab={!!this.props.secondaryBookmark}
                            data-testid={TestIds.AccordionPanelContent}>
                        {this.props.children}
                    </Content>
                    {((!this.props.isSolo && !this.props.isFixed) || this.props.showFakeBubble) &&
                            <>
                                <Splitter
                                        isResizable={this.isResizable}
                                        hasSecondaryTab={!!this.props.secondaryBookmark}>
                                    {this.props.isLast &&
                                            <LastPaneIcon>
                                                <Icon width={IconSize.S}/>
                                            </LastPaneIcon>
                                    }
                                    {(!this.props.isLast || !isCollapsed || !this.props.isSolo || this.props.showFakeBubble) &&
                                            <>
                                                <SplitterBackground
                                                        isLastOpen={isLastOpen}
                                                        isCollapsed={isCollapsed}>
                                                    <>
                                                        <Shadow/>
                                                        {isSecondaryBookmark && this.renderSecondaryBookmarkPane(isGrayBubble)}
                                                    </>
                                                </SplitterBackground>
                                                {(!this.props.isLast || !isCollapsed || !this.props.isSolo) && (
                                                        <Resizer
                                                                isResizable={this.isResizable}
                                                                onMouseDown={this.handleClick}/>
                                                )}
                                            </>
                                    }

                                    {(!isLastOpen || this.props.showFakeBubble) &&
                                            <BubbleWrapper
                                                    isSecond={!!this.props.secondaryBookmark?.isActive}>
                                                <Bubble onMouseOver={this.handleBubbleMouseOver}
                                                        onMouseOut={this.handleBubbleMouseOut}
                                                        isGrayBubble={isGrayBubble}
                                                        shouldShowGradient={this.props.showSplitterGradient}
                                                        isWider={isSecondaryBookmark}
                                                        isCollapsed={isCollapsed}
                                                >
                                                    {this.props.isBusy &&
                                                            <BusyIndicator type={BusyIndicatorType.BackgroundOnly}
                                                                           isDelayed={this.props.isBusyDelayed}/>
                                                    }
                                                    <BubbleIconWrapper>
                                                        {Icon &&
                                                                <Icon width={IconSize.S} preventHover/>
                                                        }
                                                    </BubbleIconWrapper>
                                                </Bubble>
                                            </BubbleWrapper>
                                    }
                                </Splitter>
                                <IconsWrapper isLastOpen={isLastOpen}
                                              hasSecondaryTab={!!this.props.secondaryBookmark}
                                              data-testid={TestIds.AccordionPanelIconsWrapper}
                                >
                                    {!isCollapsed && this.props.paneAdditionalInfo}
                                    {!isExpanded && !isCollapsed &&
                                            <PaneIconButton title={this.props.t("General.Expand")}
                                                            hotspotId={"paneExpand"}
                                                            onClick={this.handleExpandPane}
                                                            isDecorative>
                                                <ExpandIcon width={IconSize.XS} height={IconSize.XS}/>
                                            </PaneIconButton>
                                    }
                                    {!isCollapsed && !isExpanded &&
                                            <PaneIconButton title={this.props.t("General.Close")}
                                                            hotspotId={"paneCollapse"}
                                                            onClick={this.handleCollapsePane}
                                                            isDecorative>
                                                <CloseIcon width={IconSize.XS}/>
                                            </PaneIconButton>
                                    }
                                </IconsWrapper>
                            </>
                    }
                </StyledPane>
        );
    }
}

const PaneWithTranslation = withTranslation("Common")(Pane);
export { PaneWithTranslation as Pane };