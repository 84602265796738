import React from "react";

import BindingContext from "../../../odata/BindingContext";
import { IPureFormProps } from "../../../views/formView/PureForm.types";
import { isVisible } from "../FieldInfo";
import { IFormGroupDef } from "../smartFormGroup/SmartFormGroup";
import SmartBulkChangeFormGroup from "./SmartBulkChangeFormGroup";

interface IProps extends Pick<IPureFormProps,
    "storage" | "onCancel" | "onConfirm" | "onTemporalChange" | "onChange" | "onBlur"> {
}

export class BulkChangeForm extends React.Component<IProps> {

    renderStandardGroup = (group: IFormGroupDef, bindingContext: BindingContext, key: string, title?: string): React.ReactElement => {
        const { storage } = this.props;
        const _handleRef = (ref: any) => {
            storage.addGroupRef(ref, group.id);
        };
        return (
            <SmartBulkChangeFormGroup
                key={key}
                ref={_handleRef}
                title={title}
                bindingContext={bindingContext}
                def={group}
                storage={storage}
                onBlur={this.props.onBlur}
                onCancel={this.props.onCancel}
                onConfirm={this.props.onConfirm}
                onChange={this.props.onChange}
                onTemporalChange={this.props.onTemporalChange}
            />
        );
    };

    renderGroup = (group: IFormGroupDef, index: number, title?: string, isInTab?: boolean): React.ReactElement => {
        const { tooltip, ...info } = group;
        if (!this.props.storage.data.fieldsInfo
            || !isVisible({ info, storage: this.props.storage })
        ) {
            return null;
        }

        if (group.tabs || group.table || group.collection) {
            return null;
        }

        return this.renderStandardGroup(group, this.props.storage.data.bindingContext, index.toString(), title);
    };

    render() {
        // BulkChangeDialog reduces the form group definition to only massEditable fields,
        // which are defined in ISplitPageTableDef->massEditableDef
        const massEditableGroups = this.props.storage.data.definition.groups;
        return (
            <>
                {massEditableGroups.map((item: IFormGroupDef, i: number) => {
                    return this.renderGroup(item, i);
                })}
            </>
        );
    }
}