import { PredefinedFilter } from "@components/conditionalFilterDialog/ConditionalFilterDialog.utils";
import { IFieldInfoProperties } from "@components/smart/FieldInfo";
import {
    FilterBarGroup,
    getDefaultFilterGroupDef,
    IFilterGroupDef
} from "@components/smart/smartFilterBar/SmartFilterBar.types";
import { IReportHierarchy, IReportVariantDef } from "@components/smart/smartTable";
import { isAccountAssignmentCompany } from "@utils/CompanyUtils";
import { arrayInsert } from "@utils/general";
import i18next from "i18next";

import { IAppContext } from "../../../contexts/appContext/AppContext.types";
import { FieldType, Sort, TextAlign, ValidatorType } from "../../../enums";
import { TRecordType } from "../../../global.types";
import BindingContext from "../../../odata/BindingContext";
import { getUtcDate } from "../../../types/Date";
import {
    getReportViewDocumentStatusExportFormatter,
    getReportViewDocumentStatusFormatter
} from "../../documents/DocumentDef";
import {
    byDateOptionalValidationSchema,
    CommonReportProps,
    DocumentStatusLocalPath,
    getDocumentNumberOursOverride,
    getDocumentStatusFilterDef,
    getDocumentTypeFilterDef
} from "../CommonDefs";
import {
    composedDateRangeOnBeforeLoadCallback,
    DEFAULT_RANGE,
    getComposedDateRangeFieldsDefs
} from "../customFilterComponents/ComposedDateRange";
import { IReportTableDefinition, TReportColumnOverrides } from "../Report.utils";
import { ReportId } from "../ReportIds";
import { ReportStorage } from "../ReportStorage";

export enum DocumentJournalVariant {
    Evala = "-53",                      // default variant
    ReceivableAdjustment = "recadj",    // FE only variant - used just in drilldown
}

export const DocumentJournalProps = {
    dateClearingStatusTo: BindingContext.localContext("DateClearingStatusTo")
};


export const DOCUMENT_JOURNAL_PATH = "DocumentJournal";

/**
 * Returns common report document status definition, prefix of dependent columns is different per report ->
 *   "Document" for documentJournal", "ReceivableAdjustment" for ReceivableAdjustments report
 * @param prefix
 */
export const getReportDocumentStatusDef = (prefix: string): IFieldInfoProperties => ({
    fieldSettings: {
        disableSort: true
    },
    formatter: getReportViewDocumentStatusFormatter(prefix),
    exportFormatter: getReportViewDocumentStatusExportFormatter(prefix),
    textAlign: TextAlign.Center,
    // use fixed width to prevent huge auto calculated width caused by long tooltip
    width: "95px"
});

export const getDefinition = (context: IAppContext): IReportTableDefinition => {
    const tableId = ReportId.DocumentJournal;
    const title = i18next.t("Reporting:DocumentJournal.Title");
    const path = DOCUMENT_JOURNAL_PATH;
    const initialSortBy = [{ id: "Document_DateCreated", sort: Sort.Desc }];
    const parameters: string[] = [CommonReportProps.dateRange, CommonReportProps.dateRangeCustomValue, DocumentJournalProps.dateClearingStatusTo];
    const hasAccounting = isAccountAssignmentCompany(context);

    const columnOverrides: TReportColumnOverrides = {
        DocumentStatus: getReportDocumentStatusDef("Document"),
        Document_NumberOurs: getDocumentNumberOursOverride({
            documentTypeProperty: "Document_DocumentTypeCode",
            documentIdProperty: "Document_Id"
        })
    };

    const dateRangeFieldDefs = getComposedDateRangeFieldsDefs(tableId);
    const filterBarDef: IFilterGroupDef[] = [
        {
            ...getDefaultFilterGroupDef(FilterBarGroup.Parameters),
            defaultFilters: [
                CommonReportProps.dateRange, CommonReportProps.dateRangeCustomValue,
                DocumentJournalProps.dateClearingStatusTo
            ],
            filterDefinition: {
                ...dateRangeFieldDefs,
                [CommonReportProps.dateRange]: {
                    ...dateRangeFieldDefs[CommonReportProps.dateRange],
                    defaultValue: hasAccounting ? DEFAULT_RANGE : PredefinedFilter.ThisYear
                },
                [DocumentJournalProps.dateClearingStatusTo]: {
                    id: DocumentJournalProps.dateClearingStatusTo,
                    label: i18next.t("Reporting:DocumentJournal.DateClearingStatusTo"),
                    type: FieldType.Date,
                    defaultValue: () => (getUtcDate()),
                    validator: {
                        type: ValidatorType.Custom,
                        settings: {
                            customSchema: () => byDateOptionalValidationSchema
                        }
                    }
                }
            }
        },
        {
            ...getDefaultFilterGroupDef(FilterBarGroup.Filters),
            allowCustomFilters: false,
            defaultFilters: [],
            filterDefinition: {
                [BindingContext.localContext("Document_NumberOurs")]: {},
                [BindingContext.localContext("BusinessPartner_Name")]: {},
                [BindingContext.localContext("DocumentType_Name")]: getDocumentTypeFilterDef(),
                [DocumentStatusLocalPath]: getDocumentStatusFilterDef(context)
            }
        }
    ];

    const onBeforeLoad = async (storage: ReportStorage) => {
        await composedDateRangeOnBeforeLoadCallback(storage);
    };

    const defaultReportVariants: TRecordType<IReportVariantDef> = {
        [DocumentJournalVariant.Evala]: {
            ReportHierarchy: {
                "Aggregate": false,
                "Groups": [],
                "Columns": [
                    { "ColumnAlias": "Document_NumberOurs" },
                    { "ColumnAlias": "DocumentType_Name" },
                    { "ColumnAlias": "BusinessPartner_Name" },
                    { "ColumnAlias": "Document_DateCreated" },
                    { "ColumnAlias": "DocumentStatus" },
                    { "ColumnAlias": "CreatedByUser_Name" },
                    { "ColumnAlias": "Document_Amount" },
                    { "ColumnAlias": "Document_TransactionAmountDue" }
                ],
                "Aggregations": []
            }
        },
        [DocumentJournalVariant.ReceivableAdjustment]: {
            ReportHierarchy: {
                "Aggregate": false,
                "Groups": [],
                "Columns": [
                    { "ColumnAlias": "Document_NumberOurs" },
                    { "ColumnAlias": "DocumentType_Name" },
                    { "ColumnAlias": "Document_Amount" },
                    { "ColumnAlias": "Document_Explanation" },
                    { "ColumnAlias": "Document_Note" }
                ],
                "Aggregations": []
            }
        },
    };

    if (hasAccounting) {
        defaultReportVariants[DocumentJournalVariant.Evala].ReportHierarchy["Columns"] = arrayInsert(defaultReportVariants[DocumentJournalVariant.Evala].ReportHierarchy["Columns"], { "ColumnAlias": "Document_DateAccountingTransaction" }, 4);
        defaultReportVariants[DocumentJournalVariant.ReceivableAdjustment].ReportHierarchy["Columns"] = arrayInsert(defaultReportVariants[DocumentJournalVariant.ReceivableAdjustment].ReportHierarchy["Columns"], { "ColumnAlias": "Document_DateAccountingTransaction" }, 2);
    }

    const defaultReportHierarchy: IReportHierarchy = defaultReportVariants[DocumentJournalVariant.Evala].ReportHierarchy;

    return {
        title, path, id: tableId, initialSortBy, filterBarDef, parameters,
        columnOverrides, onBeforeLoad, defaultReportHierarchy, defaultReportVariants
    };
};
