import { IGetValueArgs, isVisibleByPath } from "@components/smart/FieldInfo";
import BindingContext, { createPath } from "@odata/BindingContext";
import {
    EntityTypeName,
    IntegrationApiImportFrequencyEntity,
    InvoiceServiceIntegrationEntity,
    InvoiceServiceIntegrationSettingsEntity
} from "@odata/GeneratedEntityTypes";
import {
    FiscalYearStatusCode,
    IntegrationApiImportFrequencyCode,
    IntegrationApiImportFromWhenCode
} from "@odata/GeneratedEnums";
import { getEnumNameSpaceName, getEnumSelectItems } from "@odata/GeneratedEnums.utils";
import { getActiveFiscalYears, getFiscalYearByDate } from "@pages/fiscalYear/FiscalYear.utils";
import { StyledDocTypesLabels } from "@pages/integrations/Integration.styles";
import { IDefinition, IGetDefinition } from "@pages/PageUtils";
import i18next from "i18next";
import React from "react";
import { ValidationError } from "yup";

import { BasicInputSizes, FieldType, LabelStatus, ValidatorType } from "../../enums";
import { IFormDef } from "../../views/formView/Form";
import { FormStorage } from "../../views/formView/FormStorage";

const documentTypesPath = BindingContext.localContext("documentTypes");
const documentTypesLabelsPath = BindingContext.localContext("documentTypesLabels");

export const getSettingsPath = (path: string) => {
    return createPath(InvoiceServiceIntegrationEntity.Settings, path);
};

export const docTypesPropsPaths = [
    getSettingsPath(InvoiceServiceIntegrationSettingsEntity.IsInvoiceIssuedImported),
    // getSettingsPath(InvoiceServiceIntegrationSettingsEntity.IsOtherLiabilityImported),
    getSettingsPath(InvoiceServiceIntegrationSettingsEntity.IsProformaInvoiceIssuedImported),
    getSettingsPath(InvoiceServiceIntegrationSettingsEntity.IsTaxDocumentIssuedImported),
    // getSettingsPath(InvoiceServiceIntegrationSettingsEntity.IsInvoiceReceivedImported),
    // getSettingsPath(InvoiceServiceIntegrationSettingsEntity.IsOtherReceivableImported),
    // getSettingsPath(InvoiceServiceIntegrationSettingsEntity.IsProformaInvoiceReceivedImported),
    // getSettingsPath(InvoiceServiceIntegrationSettingsEntity.IsTaxDocumentReceivedImported)
];

const documentTypeFieldDefs = docTypesPropsPaths.map(prop => ({ id: prop }));

export const getDefinition: IGetDefinition = (): IDefinition => {
    const form: IFormDef = {
        id: `${EntityTypeName.InvoiceServiceIntegrationSettings}Form`,
        translationFiles: getDefinition.translationFiles,
        additionalProperties: [
            ...documentTypeFieldDefs
        ],
        fieldDefinition: {
            [getSettingsPath(InvoiceServiceIntegrationSettingsEntity.IntegrationApiImportedDocuments)]: {
                type: FieldType.Checkbox,
            },
            [getSettingsPath(InvoiceServiceIntegrationSettingsEntity.IntegrationApiImportFrequency)]: {
                type: FieldType.ComboBox,
                width: BasicInputSizes.XL,
                fieldSettings: {
                    displayName: IntegrationApiImportFrequencyEntity.Name
                },
                defaultValue: IntegrationApiImportFrequencyCode.Daily
            },
            [documentTypesLabelsPath]: {
                type: FieldType.Custom,
                labelStatus: LabelStatus.Removed,
                render: () => {
                    return (
                            <StyledDocTypesLabels>
                                <span>{i18next.t("Integrations:Issued")}</span>
                                {/*<span>{i18next.t("Integrations:Received")}</span>*/}
                            </StyledDocTypesLabels>
                    );
                }
            },
            [documentTypesPath]: {
                type: FieldType.CheckboxGroup,
                labelStatus: LabelStatus.Removed,
                width: "100%",
                fieldSettings: {
                    items: [
                        ...documentTypeFieldDefs
                    ]
                }
            },
            [getSettingsPath(InvoiceServiceIntegrationSettingsEntity.IntegrationApiImportFromWhen)]: {
                type: FieldType.ComboBox,
                label: i18next.t("Integrations:ImportRangeLabel"),
                width: BasicInputSizes.XL,
                isRequired: true,
                defaultValue: IntegrationApiImportFromWhenCode.ForThisFiscalYear,
                fieldSettings: {
                    displayName: "Name",
                    items: getEnumSelectItems(EntityTypeName.IntegrationApiImportFromWhen)
                },
            },
            [getSettingsPath(InvoiceServiceIntegrationSettingsEntity.DateCustom)]: {
                type: FieldType.Date,
                label: i18next.t("Integrations:FromDateLabel"),
                isRequired: true,
                width: BasicInputSizes.M,
                validator: {
                    type: ValidatorType.Date,
                    settings: {
                        customValidator: (value, args) => {
                            const { storage } = args;
                            if (isVisibleByPath(storage as FormStorage, getSettingsPath(InvoiceServiceIntegrationSettingsEntity.DateCustom))) {
                                const fiscalYear = getFiscalYearByDate(args.storage.context, value as Date);
                                if (fiscalYear?.StatusCode !== FiscalYearStatusCode.Active) {
                                    return new ValidationError(i18next.t("Banks:Validation.ActiveFY"), value, args.bindingContext.getNavigationPath(true));
                                }
                            }
                            return true;
                        }
                    }
                },
                fieldSettings: {
                    minDate: (args: IGetValueArgs) => {
                        const fiscalYears = getActiveFiscalYears(args.storage.context);
                        if (!fiscalYears?.length) {
                            return null;
                        }
                        return fiscalYears[0].DateStart;
                    },
                    maxDate: (args: IGetValueArgs) => {
                        const fiscalYears = getActiveFiscalYears(args.storage.context);
                        if (!fiscalYears?.length) {
                            return null;
                        }
                        return fiscalYears[fiscalYears.length - 1].DateEnd;
                    }
                },
                isVisible: args => {
                    const range = args.storage.getValueByPath(getSettingsPath(InvoiceServiceIntegrationSettingsEntity.IntegrationApiImportFromWhen));
                    return range?.Code === IntegrationApiImportFromWhenCode.FromDate;
                }
            }
        },
        groups: [
            {
                id: "main",
                showGroupDivider: false,
                rows: [[
                    { id: getSettingsPath(InvoiceServiceIntegrationSettingsEntity.IntegrationApiImportFrequency) }
                ], [
                    { id: getSettingsPath(InvoiceServiceIntegrationSettingsEntity.IntegrationApiImportFromWhen) },
                    { id: getSettingsPath(InvoiceServiceIntegrationSettingsEntity.DateCustom) }
                ], [
                    { id: documentTypesLabelsPath },
                    { id: documentTypesPath }
                ]]
            }
        ]
    };

    return {
        form
    };
};

getDefinition.translationFiles = [
    "Integrations",
    "Banks",
    getEnumNameSpaceName(EntityTypeName.BankApiStatementImportRange),
    getEnumNameSpaceName(EntityTypeName.IntegrationApiImportFromWhen)
];
