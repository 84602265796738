import { ActionType, ISmartFastEntriesActionEvent } from "@components/smart/smartFastEntryList";
import { ISmartFieldBlur, ISmartFieldChange } from "@components/smart/smartField/SmartField";
import { getNestedValue } from "@odata/Data.utils";
import { BusinessPartnerEntity, IBusinessPartnerBankAccountEntity } from "@odata/GeneratedEntityTypes";
import { CountryCode, PaymentMethodCode } from "@odata/GeneratedEnums";
import { companyIsSecondaryAddressPath } from "@pages/companies/Company.shared.utils";
import { handleBankAccountOrIBANBlur } from "@utils/BankUtils";
import { isObjectEmpty } from "@utils/general";
import { cloneDeep } from "lodash";

import { AppContext } from "../../contexts/appContext/AppContext.types";
import { withPermissionContext } from "../../contexts/permissionContext/withPermissionContext";
import BindingContext, { IEntity } from "../../odata/BindingContext";
import { FormViewForExtend, IFormViewProps } from "../../views/formView/FormView";
import {
    getBankBlurArgs,
    handleBankFieldChangeWithoutSavedAccount,
    isBankAccount
} from "../banks/bankAccounts/BankAccounts.utils";
import {
    fetchAresDetail,
    IBusinessPartnerCustomData,
    IBusinessPartnerEntityExtended,
    ReceivedBankAccountDefaultsPath,
    ShowIssuedDefaultsPath,
    ShowReceivedDefaultsPath
} from "./BusinessPartner.utils";
import { partnerAresFields } from "./BusinessPartnerDef";

class BusinessPartnerFormView extends FormViewForExtend<IBusinessPartnerEntityExtended, IFormViewProps<IBusinessPartnerEntityExtended, IBusinessPartnerCustomData>> {
    static contextType = AppContext;
    //sadly, breaks typescript type checking
    //context: React.ContextType<typeof AppContext>;
    static defaultProps = {
        title: "BusinessPartner:FormTitle"
    };

    componentDidMount() {
        super.componentDidMount();
        this.registerHeader();
    }

    componentDidUpdate(): void {
        this.registerHeader();
    }

    registerHeader(): void {
        const bc = this.props.storage.data.bindingContext?.navigate(BusinessPartnerEntity.VatStatus);
        if (bc) {
            this.props.storage.addCustomRef(this._refHeader.current, bc);
        }
    }

    onAfterLoad = async () => {
        const storage = this.props.storage;

        storage.setCustomData({
            isBusinessPartnerFromAres: false
        });

        if (!storage.data.bindingContext.isNew()) {
            // find out if the BusinessPartner is synchronizable with (exists in) ARES
            const bpCountryCode = this.entity.BillingAddress?.CountryCode as (CountryCode.CzechRepublic | CountryCode.Slovakia);

            if ([CountryCode.CzechRepublic, CountryCode.Slovakia].includes(bpCountryCode)) {
                fetchAresDetail(this.entity.LegalNumber, bpCountryCode).then((aresDetail) => {
                    if (aresDetail) {
                        storage.setCustomData({
                            isBusinessPartnerFromAres: true
                        });
                        storage.refresh();
                    }
                });
            }

            // set groups visibility
            storage.setValueByPath(ShowReceivedDefaultsPath, Object.keys(this.entity.ReceivedDocumentDefault ?? {}).length > 0);
            storage.setValueByPath(ShowIssuedDefaultsPath, Object.keys(this.entity.IssuedDocumentDefault ?? {}).length > 0);
            storage.refreshFields();
        }

        this.setDefaultReceivedBankAccount();
        this.handleInitialIsSecondaryAddress();
        return super.onAfterLoad();
    };

    setDefaultReceivedBankAccount = (force?: boolean): void => {
        const receivedAccount = this.entity.BankAccounts?.find((account: IBusinessPartnerBankAccountEntity) => account.IsDefaultForReceivedDocuments);
        const isRecDefaultsVisible = !!this.props.storage.getValueByPath(ShowReceivedDefaultsPath);

        if (receivedAccount?.Id) {
            this.props.storage.setValueByPath(ReceivedBankAccountDefaultsPath, receivedAccount?.Id);
        } else if (!isRecDefaultsVisible || force) {
            // by default use the first bank account as the default account
            // => set the IsDefaultForReceivedDocuments flag of the account to true, so that other fields are correctly visible
            // in case user won't open the ReceivedDefaults group, the flag will be reset onBeforeSave in clearDefaultAccounts
            this.props.storage.setValueByPath(ReceivedBankAccountDefaultsPath, this.entity.BankAccounts?.[0]?.Id);

            if (this.entity.BankAccounts?.[0]?.Id) {
                this.entity.BankAccounts[0].IsDefaultForReceivedDocuments = true;
            }
        }
    };

    setDefaultIssuedBankAccount = (): void => {
        const defaultCompanyAcc = this.props.storage.context.getCompanyBankAccounts().find(acc => acc.IsDefault);
        this.props.storage.setValueByPath("IssuedDocumentDefault/CompanyBankAccount", defaultCompanyAcc);
    };

    handleInitialIsSecondaryAddress = (): void => {
        const isSecondaryAddress = !(this.props.storage.data.bindingContext.isNew() || isObjectEmpty(this.entity.CorrespondenceAddress));
        this.props.storage.clearAndSetValue(this.props.storage.data.bindingContext.navigate(companyIsSecondaryAddressPath), isSecondaryAddress);
    };

    handleBlur = async (args: ISmartFieldBlur): Promise<void> => {
        const { storage } = this.props;
        handleBankAccountOrIBANBlur(getBankBlurArgs(storage.getThis(), args.bindingContext));

        await storage.handleBlur(args);
        storage.refreshFields();
    };

    handlePartnerChange = (e: ISmartFieldChange): void => {
        const { storage } = this.props;

        const path = e.bindingContext.getPath(true) as BusinessPartnerEntity;
        if ([BusinessPartnerEntity.Name, BusinessPartnerEntity.LegalNumber].includes(path) && e.triggerAdditionalTasks) {
            for (const partnerField of Object.values(partnerAresFields)) {
                let value = getNestedValue(partnerField.from, e.additionalData) ?? (e.additionalData?.isAllowCreateItem ? null : undefined);

                if (typeof value === "string") {
                    value = value.trim();
                }

                if (value !== undefined) {
                    const fieldBc = storage.data.bindingContext.navigate(partnerField.to ?? partnerField.from);
                    storage.clearAndSetValue(fieldBc, value, true);
                }
            }

            storage.setValueByPath(BusinessPartnerEntity.IsSynchronizedWithAres, e.additionalData.isAres);
            storage.setCustomData({
                isBusinessPartnerFromAres: e.additionalData.isAres
            });
            storage.refresh();
        }
    };

    handleIsSynchronizedWithAresChange = (e: ISmartFieldChange): void => {
        if (e.bindingContext.getPath() === BusinessPartnerEntity.IsSynchronizedWithAres) {
            this.props.storage.refreshGroupByKey("basic");
            this.props.storage.refreshGroupByKey("Address");
        }
    };

    handleShowReceivedDefaultsPathChange = (e: ISmartFieldChange): void => {
        if (e.bindingContext.getPath() === ShowReceivedDefaultsPath && e.value) {
            this.setDefaultReceivedBankAccount(true);
            // default value of bank account could've been changed to wire,
            // refresh form to show otherwise hidden fields
            this.props.storage.refresh(true);
        }
    };

    handleShowIssuedDefaultsPathChange = (e: ISmartFieldChange): void => {
        if (e.bindingContext.getPath() === ShowIssuedDefaultsPath && e.value) {
            this.setDefaultIssuedBankAccount();
            this.props.storage.refresh(true);
        }
    };

    handleChange = async (e: ISmartFieldChange): Promise<void> => {
        this.handleDefaultAccountChange(e);

        this.props.storage.handleChange(e);

        this.handleShowReceivedDefaultsPathChange(e);
        this.handleShowIssuedDefaultsPathChange(e);

        // after change to replace stuff !
        this.handlePartnerChange(e);
        this.handleIsSynchronizedWithAresChange(e);
        this.props.storage.refreshFields(e.triggerAdditionalTasks);
    };

    handleLineItemsAction = (args: ISmartFastEntriesActionEvent): void => {
        if (args.actionType === ActionType.Remove && args.affectedItems?.find(item => item.IsDefaultForReceivedDocuments)) {
            this.props.storage.clearAndSetValueByPath(ReceivedBankAccountDefaultsPath, "");
        }
        this.props.storage.handleLineItemsAction(args);
        this.props.storage.refresh();
    };

    handleDefaultAccountChange = (e: ISmartFieldChange): void => {
        const path = e.bindingContext.getPath();
        if (path === ReceivedBankAccountDefaultsPath && e.triggerAdditionalTasks) {
            // do not set current value for this field -> it helps when original account will change, the current value
            // will be set from selected item label, so we don't need to maintain it manually on every change.
            e.currentValue = null;

            for (const account of this.entity.BankAccounts || []) {
                account.IsDefaultForReceivedDocuments = account.Id === e.value || `#${account[BindingContext.NEW_ENTITY_ID_PROP]}` === e.value;
            }
        }
    };

    handleSetDefaultReceivedBankAccount = (e: ISmartFieldChange): void => {
        if (!this.props.storage.getValueByPath(ReceivedBankAccountDefaultsPath) &&
            ["Bank", "AccountNumber"].includes(e.bindingContext.getPath())) {
            const bankAccount = this.entity.BankAccounts[0];
            const id = bankAccount.Id ?? `#${bankAccount[BindingContext.NEW_ENTITY_ID_PROP]}`;
            this.props.storage.setValueByPath(ReceivedBankAccountDefaultsPath, id);
        }
    };

    handleLineItemsChange = (args: ISmartFieldChange) => {
        const { storage } = this.props;

        storage.handleLineItemsChange(args);

        if (isBankAccount(args.bindingContext.getParent())) {
            handleBankFieldChangeWithoutSavedAccount({ storage: storage.getThis() }, args);
            this.handleSetDefaultReceivedBankAccount(args);
            // refresh bank account select in received document defaults as its content might have changed
            const receivedAccountsBc = storage.data.bindingContext.navigate(ReceivedBankAccountDefaultsPath);
            storage.addActiveField(receivedAccountsBc);
        }

        if (args.triggerAdditionalTasks) {
            storage.refresh();
        } else {
            storage.refreshFields();
        }
    };

    clearSecondaryAddress = (saveData: IEntity): void => {
        const isSecondaryAddress = this.props.storage.getValueByPath(companyIsSecondaryAddressPath);
        if (!isSecondaryAddress) {
            saveData.CorrespondenceAddress = {};
        }
    };

    clearBankAccountFlag = (): void => {
        for (const account of this.entity.BankAccounts || []) {
            account.IsDefaultForReceivedDocuments = false;
        }
    };

    clearDefaultAccounts = (): void => {
        const _check = (name: string) => {
            const rec = this.props.storage.getValueByPath(`${name}/DefaultPaymentMethod`);

            if (rec?.Code !== PaymentMethodCode.WireTransfer) {
                if (name === "ReceivedDocumentDefault") {
                    this.clearBankAccountFlag();
                }

                if (name === "IssuedDocumentDefault") {
                    this.props.storage.clearAndSetValueByPath("IssuedDocumentDefault/CompanyBankAccount", null);
                }

                this.props.storage.setValueByPath(`${name}/SymbolVariable`, null);
                this.props.storage.setValueByPath(`${name}/SymbolSpecific`, null);
                this.props.storage.setValueByPath(`${name}/SymbolConstant`, null);
            }
        };

        _check("ReceivedDocumentDefault");
        _check("IssuedDocumentDefault");

        const recBankAccountDefault = this.props.storage.getValueByPath(ReceivedBankAccountDefaultsPath);

        if (!recBankAccountDefault) {
            this.clearBankAccountFlag();
        }
    };

    onBeforeSave = (): IBusinessPartnerEntityExtended => {
        this.clearDefaultAccounts();
        const { storage } = this.props;

        // type C -> user should fill the item or "close" the whole group
        storage.clearEmptyLineItems(BusinessPartnerEntity.Contacts, true);
        storage.clearEmptyLineItems(BusinessPartnerEntity.BankAccounts, true);

        const saveData = cloneDeep(storage.data.entity);

        this.clearSecondaryAddress(saveData);

        const isNew = storage.data.bindingContext.isNew();

        if (isNew) {
            saveData.Company = { Id: this.context.getCompany().Id };
        }

        if (!saveData[ShowReceivedDefaultsPath]) {
            saveData.ReceivedDocumentDefault = null;

            for (const bankAccount of (saveData.BankAccounts ?? [])) {
                bankAccount.IsDefaultForReceivedDocuments = false;
            }
        }

        if (!saveData[ShowIssuedDefaultsPath]) {
            saveData.IssuedDocumentDefault = null;
        }

        return saveData;
    };
}

export default withPermissionContext(BusinessPartnerFormView);