import { AppContext } from "../../../contexts/appContext/AppContext.types";
import { FormViewForExtend, IFormViewProps } from "../../../views/formView/FormView";
import { IPrExtraSalaryEntity } from "@odata/GeneratedEntityTypes";
import { ISmartFieldChange } from "@components/smart/smartField/SmartField";
import { handleCurrentTemporalPropChange } from "@odata/TemporalUtils";
import { withPermissionContext } from "../../../contexts/permissionContext/withPermissionContext";
import { cloneDeep } from "lodash";
import { PrBaseTypeCode, PrExtraSalaryTypeCode } from "@odata/GeneratedEnums";

interface IProps extends IFormViewProps<IPrExtraSalaryEntity, null> {
}

class ExtraSalaryFormView extends FormViewForExtend<IPrExtraSalaryEntity, IProps> {
    static contextType = AppContext;
    static defaultProps = {
        title: "ExtraSalary:FormTitle"
    };

    handleChange = (e: ISmartFieldChange): void => {
        this.props.storage.handleChange(e);
        handleCurrentTemporalPropChange(e, this.props.storage);
        this.props.storage.refresh();
    };

    onBeforeSave(): IPrExtraSalaryEntity {
        const entity = cloneDeep(this.entity);

        if (entity.BaseTypeCode === PrBaseTypeCode.Custom) {
            entity.TypeCode = PrExtraSalaryTypeCode.Custom;
        }

        return entity;
    }
}

export default withPermissionContext(ExtraSalaryFormView);