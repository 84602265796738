import styled from "styled-components/macro";
import { StyledProgressBar } from "@components/progressBar/ProgressBar.styles";
import { StyledField, FIELD_HOR_MARGIN } from "@components/inputs/field/Field.styles";

export const StyledWrapper = styled.div<{ hasCarryForwardPrefix: boolean; }>`{
    display: grid;
    margin-right: ${FIELD_HOR_MARGIN};
    margin-bottom: 27px;

    ${StyledProgressBar} {
        grid-column: ${props => `2 / span ${!props.hasCarryForwardPrefix ? 3 : 4}`};
        grid-row: 2;
        padding: 0 12px;
    }

    ${StyledField} {
        grid-row: 1;
        margin-bottom: 8px;

        &:nth-child(${props => !props.hasCarryForwardPrefix ? 4 : 5}) {
            margin-right: 0;
        }
    }
;
}`;

export const NumRangeHelpTooltipStyled = styled.div`{
    table {
        width: 100%;
        border-collapse: collapse;
    }

    th {
        font-weight: bold;
        text-align: left;
    }

    th:first-child {
        padding-right: 24px;
    }

    th, td {
        min-width: 55px;
    }

    td {
        padding-top: 5px;
        padding-bottom: 0;
    }

    hr {
        border: 1px solid ${props => props.theme.C_FIELD_line_der};
        opacity: 0.3;
        margin: 7px 0;
    }
}`;

export const MandatoryForDocumentsText = styled.div`
    max-width: 260px;
`;