import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";

import { ApplicationSessionTypes, getInitialAppRedirectUrl } from "../contexts/authContext/Auth.utils";
import { ROUTE_LOGIN_TENANT } from "../routes";
import { useLoginDispatch, useLoginSelector } from "./state/hooks";
import { selectLoginOption, selectNextURL } from "./state/loginSlice";
import { selectSessionData } from "./state/sessionSlice";
import { prepareAndSwitchToTenant } from "./TenantPicker.utils";

interface IProps {
}

const TenantRedirect: React.FC<IProps> = () => {
    const option = useLoginSelector(selectLoginOption);
    const session = useLoginSelector(selectSessionData);
    const nextURL = useLoginSelector(selectNextURL);
    const dispatch = useLoginDispatch();

    const [redirect, setRedirect] = useState<string>(null);

    useEffect(() => {
        dispatch(prepareAndSwitchToTenant(setRedirect));
    }, [dispatch]);

    switch (true) {
        case !!redirect:
            return (
                    <Redirect to={redirect}/>
            );
        case option?.isResolved && ApplicationSessionTypes.includes(session?.Type):
            return (
                    <Redirect to={getInitialAppRedirectUrl(session, nextURL)}/>
            );
        case !option:
            return (
                    <Redirect to={ROUTE_LOGIN_TENANT}/>
            );
        default:
            return null;
    }
};

export default TenantRedirect;
