import React from "react";
import { IFieldDef } from "../FieldInfo";
import BindingContext, { IEntity } from "../../../odata/BindingContext";
import SimpleTableWithPaginator from "../../simpleTable/SimpleTableWithPaginator";
import { IColumn, ILoadMoreItemsEvent, IRow, ISort } from "../../table";
import { AppContext } from "../../../contexts/appContext/AppContext.types";
import { getFormattedValues, prepareColumns } from "../smartTable/SmartTable.utils";
import { FieldVisibility } from "../../../enums";
import { IPrepareQuerySettings, prepareQuery } from "@odata/OData.utils";
import { WithOData, withOData } from "@odata/withOData";
import { StorageModel } from "../../../model/StorageModel";
import { IFilterQuery } from "../../../model/TableStorage";
import { ISimpleTableProps } from "../../simpleTable";
import { IFieldInfo } from "@odata/FieldInfo.utils";

interface IProps extends WithOData, Pick<ISimpleTableProps, "hasBigFont" | "showHeaderBorder" | "highlightRowsHover"> {
    columns: IFieldDef[];
    bindingContext: BindingContext;
    pageSize?: number;
    storage?: StorageModel;
    renderScrollbar?: boolean;
    initialSortBy?: ISort[];
    filter?: IFilterQuery;
}

interface IState {
    currentPage: number;
    rowCount: number;
    columns: IFieldInfo[];
    rows: IRow[];
    busy: boolean;
    width: string;
}

class SmartSimpleTable extends React.PureComponent<IProps, IState> {
    static contextType = AppContext;
    //sadly, breaks typescript type checking
    //context: React.ContextType<typeof AppContext>;
    static defaultProps = {
        pageSize: 10
    };

    tableRef = React.createRef<HTMLDivElement>();

    state: IState = {
        currentPage: 0,
        rowCount: null,
        columns: [],
        rows: [],
        busy: true,
        width: null
    };

    componentDidMount() {
        this.init();
    }

    componentDidUpdate(prevProps: IProps, prevState: IState) {
        if (prevState.busy && !this.state.busy && this.tableRef?.current) {
            // fixate width to prevent jumping during loading
            this.setState({
                width: `${this.tableRef?.current.clientWidth + 1}px`
            });
        }
    }

    init = async () => {
        const columns = await prepareColumns({
            bindingContext: this.props.bindingContext,
            context: this.context,
            columns: this.props.columns.filter(column => ![FieldVisibility.ExportOnly].includes(column.fieldVisibility))
        });

        this.setState({
            columns
        }, this.fetchData);
    };

    get sort() {
        return this.props.initialSortBy;
    }

    fetchData = async (skip = 0, top = this.props.pageSize) => {
        this.setState({
            busy: true
        });

        const sort = this.sort;
        const settings: IPrepareQuerySettings = {};

        if (sort?.length > 0) {
            // orderBy is always done on the root level, not in the expands
            settings[""] = { // "" for top level settings
                sort
            };
        }

        if (this.props.filter?.collectionQueries) {
            for (const key of Object.keys(this.props.filter.collectionQueries)) {
                settings[key] = {
                    filter: this.props.filter.collectionQueries[key].query
                };
            }
        }

        let query = prepareQuery({
            oData: this.props.oData,
            bindingContext: this.props.bindingContext,
            fieldDefs: this.state.columns,
            settings
        });

        query = query.count().skip(skip).top(top);

        if (this.props.filter?.query) {
            query.filter(this.props.filter.query);
        }

        const result = await query.fetchData<IEntity[]>();
        const rows = result.value.map((row: IEntity) => this.createRowProperties(row));
        const newRows = [...this.state.rows];

        for (let i = skip; i < skip + top; i++) {
            // for non existing rows, pass null explicitly instead of undefined
            // so that we know that onLoadMoreItems isn't supposed to be fired for those rows
            newRows[i] = rows[i - skip] ?? null;
        }

        this.setState({
            rowCount: result._metadata.count,
            rows: newRows,
            busy: false,
            width: null
        });
    };

    createRowProperties = (row: IEntity) => {
        const idBindingContext = this.props.bindingContext.addKey(row[this.props.bindingContext.getKeyPropertyName()]);

        return {
            id: idBindingContext,
            values: getFormattedValues({
                columns: this.state.columns,
                values: row,
                valuesBindingContext: this.props.bindingContext,
                storage: this.props.storage
            })
        };
    };

    handlePageChange = (page: number) => {
        this.setState({
            currentPage: page
        });
    };

    handleLoadMoreItems = (args: ILoadMoreItemsEvent) => {
        this.fetchData(args.startIndex, args.stopIndex - args.startIndex);
    };

    getColumns = (): IColumn[] => {
        return this.state.columns.map(column => ({
            id: column.id,
            textAlign: column.textAlign,
            label: column.label
        }));
    };

    render() {
        return (
            <SimpleTableWithPaginator rows={this.state.rows}
                                      columns={this.getColumns()}
                                      width={this.state.width}
                                      passRef={this.tableRef}
                                      rowCount={this.state.rowCount}
                                      pageSize={this.props.pageSize}
                                      currentPage={this.state.currentPage}
                                      onPageChange={this.handlePageChange}
                                      onLoadMoreItems={this.handleLoadMoreItems}
                                      renderScrollbar={this.props.renderScrollbar}
                                      showHeaderBorder={this.props.showHeaderBorder}
                                      highlightRowsHover={this.props.highlightRowsHover}
                                      hasBigFont={this.props.hasBigFont}
                                      busy={this.state.busy}/>
        );
    }
}

export default withOData(SmartSimpleTable);