import { createMinorAssetStorage } from "./MinorAsset.utils";
import MinorAssetFormView from "./MinorAssetFormView";
import React, { useCallback, useEffect, useRef, useState } from "react";
import BindingContext from "../../../odata/BindingContext";
import { FormStorage } from "../../../views/formView/FormStorage";
import { IDocumentItemEntity, IMinorAssetItemEntity } from "@odata/GeneratedEntityTypes";
import ReadOnlyList from "../../../components/readOnlyList/ReadOnlyList";
import { FormViewForExtend } from "../../../views/formView/FormView";
import { IAssetFormCustomData } from "../Asset.utils";


interface IProps {
    rootStorage: FormStorage;
    onClose?: () => void;
    onCreateItem?: (bindingContext: BindingContext, assetItems: IMinorAssetItemEntity[]) => void;
    items?: IDocumentItemEntity[];
}

export function MinorAssetFormPage({ rootStorage, onClose, onCreateItem, items }: IProps): React.ReactElement {

    const _formRef = useRef<FormViewForExtend<IMinorAssetItemEntity>>();
    const [storage, setStorage] = useState<FormStorage>(null);

    useEffect(() => {
        createMinorAssetStorage(rootStorage, {
            refresh: () => {
                _formRef.current?.forceUpdate();
            }
        }, false, items)
            .then((newStorage: FormStorage<unknown, IAssetFormCustomData>) => {
                // simplified formView mode - only one line item, simplified header, etc...
                newStorage.setCustomData({ isSimplified: true });
                setStorage(newStorage);
            });
    }, [items, rootStorage]);

    const handleBeforeSave = useCallback(() => {
        storage.setBusy(true);
        storage.refresh();
    }, [storage]);

    const handleSaveFail = useCallback(() => {
        storage.setBusy(false);
    }, [storage]);

    const handleAfterSave = useCallback((isNew: boolean) => {
        storage.setBusy(false);
        // reload data to get new IDs
        // note: if the binding context is not new, storage reloads itself automagically
        (isNew ? storage.loadDataAfterSave() : Promise.resolve())
            .then(() => {
                // take the first item as the newly created -> resolves the dialog with it
                onCreateItem(storage.data.bindingContext, storage.data.entity.Items);
            });
    }, [storage, onCreateItem]);

    const documentData = [{
        label: rootStorage.t("MinorAsset:NumberOurs"),
        value: rootStorage.data.entity.NumberOurs
    }];

    return storage && (
        <>
            <MinorAssetFormView ref={_formRef}
                                storage={storage}
                                onBeforeSave={handleBeforeSave}
                                onSaveFail={handleSaveFail}
                                onAfterSave={handleAfterSave}
                                onCancel={onClose}
                                isPairing={true}
                                formProps={{
                                    hideBreadcrumbs: true,
                                    shouldHideVariant: true,
                                    shouldHideAuditTrail: true,
                                    prependContent: (
                                        <ReadOnlyList title={storage.t(rootStorage?.data?.definition?.title)}
                                                      data={documentData}
                                        />
                                    )
                                }}
            />
        </>
    );
}