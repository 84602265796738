import { BackendErrorCode } from "../enums";
import i18n from "../i18n";
import { logger } from "./log";

import { ODataError } from "@odata/Data.types";

let companyId: number;

export function isNetworkError(error: unknown): boolean {
    if (error instanceof TypeError) {
        if (error.message.includes('Failed to fetch') ||
            error.message.includes('NetworkError') ||
            error.message.includes('Fetch event timeout') ||
            error.message.includes('The network connection was lost')) {
            return true;
        }
    }
    return false;
}

/** Injects CompanyId param to every request*/
const customFetch = (url: RequestInfo, options?: RequestInit): Promise<Response> => {
    let newUrl = typeof url === "string" ? url : url.url;

    if (companyId) {
        const [baseUrl, search] = newUrl.split("?");
        const searchParams = new URLSearchParams(search);

        if (!searchParams.has("CompanyId")) {
            searchParams.set("CompanyId", companyId.toString());
        }

        newUrl = `${baseUrl}?${searchParams.toString()}`;
    }

    try {
        return fetch(newUrl, {
            ...options
        });
    } catch (e) {
        if (isNetworkError(e)) {
            // todo: may be some common handling + retry logic
            throw new ODataError(BackendErrorCode.NoConnection, i18n.t("Common:Error.NoConnection"));
        }
        // rethrow if unknown error
        logger.error("Unknown fetch error", e);
        throw e;
    }
};

customFetch.setCompanyId = (newCompanyId: number) => {
    companyId = newCompanyId;
};

customFetch.getCompanyId = (): number => {
    return companyId;
};

export const getDefaultHeaders = () => {
    return {
        "Content-Type": "application/json"
    };
};

export const getDefaultPostParams = () => {
    return {
        method: "POST",
        headers: getDefaultHeaders()
    };
};

export default customFetch;