import React from "react";
import { CheckboxGroup, ICheckboxGroupProps } from "./CheckboxGroup";
import ColumnAutoSizer, { IColumnAutoSizerChildren } from "../../columnAutoSizer/ColumnAutoSizer";

interface IProps extends Omit<ICheckboxGroupProps, "numColumns"> {

}

/** CheckBox group that automatically changes number of columns based on available space and number of children.
 * Keeping as separate component if there was a need for a CheckBoxGroup with manually set numColumns. */
class AutoSizedCheckboxGroup extends React.PureComponent<IProps> {
    static defaultProps: Partial<IProps> = {
        items: [],
        subgroups: []
    }

    getMaxNumColumns = () => {
        const childrenCount = this.props.items?.length + this.props.subgroups.length;
        let numColumns;

        // put back (and behind some props) if UX come up with case that they actually need this variant
        // if (childrenCount === 2) {
        //     numColumns = 2;
        // } else

        if (childrenCount <= 5) {
            numColumns = 1;
        } else if (childrenCount <= 14) {
            numColumns = 2;
        } else if (childrenCount <= 27) {
            numColumns = 3;
        } else {
            numColumns = 4;
        }

        return numColumns;
    };

    render() {
        return (
            <ColumnAutoSizer maxNumColumns={this.getMaxNumColumns()}>
                {(args: IColumnAutoSizerChildren) => {
                    return (
                        <CheckboxGroup {...this.props}
                                       passRef={args.observeRef}
                                       numColumns={args.numColumns}/>
                    );
                }}
            </ColumnAutoSizer>
        );
    }
}

export { AutoSizedCheckboxGroup };