import { getEntitySetByDocumentType, getRouteByDocumentType } from "@odata/EntityTypes";
import { IDocumentEntity } from "@odata/GeneratedEntityTypes";
import { getIntentNavParams } from "../../drillDown/DrillDown.utils";
import { IGetValueArgs } from "../FieldInfo";
import { DocumentTypeCode } from "@odata/GeneratedEnums";
import { ISelectItem } from "@components/inputs/select/Select.types";

// enhance items with intent links
export const transformPairingSelectItems = (items: ISelectItem[], args: IGetValueArgs) => {
    return items.map(item => {
        const docType = (item.additionalData as IDocumentEntity).DocumentTypeCode as DocumentTypeCode;
        const entitySetName = getEntitySetByDocumentType(docType);
        const entityType = args.storage.oData.getMetadata().entitySets[entitySetName].getType();
        const route = getRouteByDocumentType(docType);
        const params = getIntentNavParams({
            route: `${route}/${item.id}`,
            context: args.storage.context,
            storage: args.storage
        });

        return {
            ...item,
            additionalData: {
                ...item.additionalData,
                _metadata: {
                    "": { type: `#${entityType.getFullName()}` }
                }
            },
            link: params
        };
    });
};