import React from "react";
import PrivateRoute from "./PrivateRoute";
import { AppContext, ContextEvents, IAppContext } from "../contexts/appContext/AppContext.types";
import { RouteProps } from "react-router-dom";
import { CompanyPermissionCode, GeneralPermissionCode } from "@odata/GeneratedEnums";

interface IProps extends RouteProps {
    permission: CompanyPermissionCode | GeneralPermissionCode;
}

/** Re-renders content on company change */
class CompanyDependentPrivateRoute extends React.PureComponent<IProps> {
    static contextType = AppContext;

    constructor(props: IProps, context: IAppContext) {
        super(props, context);

        context.eventEmitter.on(ContextEvents.CompanyChanged, this.companyChange);
    }

    companyChange = () => {
        this.forceUpdate();
    };

    componentWillUnmount(): void {
        this.context.eventEmitter.off(ContextEvents.CompanyChanged, this.companyChange);
    }

    render() {
        return <PrivateRoute  {...this.props} key={this.context.getCompany().Id}/>;
    }
}

export default CompanyDependentPrivateRoute;