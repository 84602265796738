import { IUserEntity } from "@odata/GeneratedEntityTypes";
import { IAuthDevice } from "../../contexts/authContext/Auth.utils";
import { IFormStorageDefaultCustomData } from "../../views/formView/FormStorage";
import { REST_API_URL, SESSION_DEMO_STATUS_URL, SESSION_DEMO_URL } from "../../constants";
import BindingContext from "../../odata/BindingContext";
import { getDefaultPostParams } from "../../utils/customFetch";
import { logger } from "../../utils/log";
import { switchToTenant } from "../../login/Login.utils";
import { ROUTE_HOME } from "../../routes";
import { handleRedirectResponse } from "../../utils/RedirectUtils";

export enum UserSettingDialogType {
    PasswordChange = "PasswordChange",
    GoogleRemove = "GoogleRemove",
    FacebookRemove = "FacebookRemove",
    DeviceRemove = "DeviceRemove"
}

export const loginEmailPath = "##LoginEmail##";
export const changePasswordPath = "##ChangePassword##";
export const companyRolesPath = "##CompanyRoles##";
export const pairedAccountsPath = "##pairedAccounts##";
export const googleIsConnectedPath = "##googleIsConnected##";
export const facebookIsConnectedPath = "##facebookIsConnected##";
export const authDevicesPath = "##authDevices##";
export const allowDataCollection = "##allowDataCollection##";
export const allowDataCollectionMarketing = "##allowDataCollectionMarketing##";
export const allowDataCollectionMarketingOwner = "##allowDataCollectionMarketingOwner##";
export const dataCollectionCheckboxGroupPath = BindingContext.localContext("DataCollection");


export interface IUserEntityExtended extends IUserEntity {
    [loginEmailPath]?: string;
    [changePasswordPath]?: string;
    [companyRolesPath]?: string;
    [pairedAccountsPath]?: string;
    [googleIsConnectedPath]?: string;
    [facebookIsConnectedPath]?: string;
    [authDevicesPath]?: IAuthDevice[];
    [allowDataCollection]?: boolean;
    [allowDataCollectionMarketing]?: boolean;
    [allowDataCollectionMarketingOwner]?: boolean;
}

export interface IUserSettingCustomData extends IFormStorageDefaultCustomData {
    userSettingDialogTypeKey?: UserSettingDialogType;
    removeAuthDevice?: string;
}

export interface IUserSettingsDataCollection {
    IsAgreedToCollectData: boolean;
    IsAgreedToCollectPersonalDataForMarketing: boolean;
    IsAgreedToCollectPersonalDataForMarketingOwner: boolean;
}

const USER_SETTINGS_DATA_COLLECTION_ROUTE = `${REST_API_URL}/UserSettings`;

export const fetchUserSettingsDataCollection = async (): Promise<IUserSettingsDataCollection> => {
    const res = await fetch(USER_SETTINGS_DATA_COLLECTION_ROUTE);

    return await res.json() as IUserSettingsDataCollection;
};

export const saveUserSettingsDataCollection = async (dataCollection: IUserSettingsDataCollection): Promise<void> => {
    await fetch(USER_SETTINGS_DATA_COLLECTION_ROUTE, {
        ...getDefaultPostParams(),
        body: JSON.stringify(dataCollection)
    });
};

export enum DemoTenantStatusCode {
    NotCreated = "NotCreated",
    Creating = "Creating",
    Created = "Created"
}

export interface IDemoTenantInfo {
    BackgroundJobId?: number;
    DemoTenantId?: number;
    DemoTenantStatus: DemoTenantStatusCode;
}

async function fetchDemoTenantRequest(url: string, message = "Failed to fetch", method = "GET"): Promise<false | IDemoTenantInfo> {
    try {
        const response = await fetch(url, method === "GET" ? undefined : getDefaultPostParams());

        if (response.ok) {
            return response.json();
        } else {
            logger.error(`${message}; response status: ${response.status}`);
        }
    } catch (e) {
        logger.error(message, e);
    }
    return false;
}

export function getDemoTenantInfo(): Promise<false | IDemoTenantInfo> {
    return fetchDemoTenantRequest(SESSION_DEMO_STATUS_URL, "Failed to get demo tenant status");
}

export async function createDemoTenant(): Promise<false | IDemoTenantInfo> {
    return fetchDemoTenantRequest(SESSION_DEMO_URL, "Failed to create demo tenant", "POST");
}

export async function switchToDemoTenant(tenantId: number): Promise<boolean> {
    try {
        const response = await switchToTenant({ TenantId: tenantId });

        if (response.ok) {
            window.location.assign(ROUTE_HOME);
            return true;
        } else if (response.status === 403) {
            await handleRedirectResponse(response, true);
            return true;
        } else {
            logger.error(`Failed to select demo tenant; response status: ${response.status}`);
        }
    } catch (e) {
        logger.error("Failed to select demo tenant", e);
    }
    return false;
}
