import styled from "styled-components/macro";
import { ellipsis } from "polished";
import { T_BOLD_tiny } from "../../../global.style";


export const StyledError = styled.div`
    position: absolute;

    width: 100%;
    margin-left: 0;
    background-color: ${props => props.theme.C_SEM_el_bad};
    height: 3px;
    border-bottom-right-radius: 1.5px;
    border-top-right-radius: 1.5px;
    z-index: 5;
`;

export const Circle = styled.div`
    position: absolute;
    top: 0; 
    left: 1px;
    width: 16px;
    height: 16px;
    background-color: ${props => props.theme.C_SEM_el_bad};

    // we will position center of the circle by top/left position
    transform: translate(-50%, -50%);
    border-radius: 50%;

    display: flex;
    justify-content: center;
`;

export const ErrorText = styled.div`
    position: absolute;
    width: 100%;
    top: 3px;
    left: 0;
    ${ellipsis("100%")}
    ${T_BOLD_tiny};
    user-select: none;
    pointer-events: none;
`;