import { DefaultTheme } from "styled-components";
import styled, { css } from "styled-components/macro";

import { fadeOutAnimation, LayoutStackOrder, LocalStackOrder, T_PLAIN_small } from "../../global.style";

export const TooltipArrow = styled.div<{color: string}>`
    width: 14px;
    height: 14px;
    border-radius: inherit;
    z-index: -1;

    &::before {
        content: "";
        background-color: ${props => props.color ? (props.theme[props.color as keyof DefaultTheme] ?? props.color) : props.theme.C_BG_info_arrow};
        width: 100%;
        height: 100%;
        border-radius: inherit;
        transform: rotate(45deg);
        position: absolute;
    }
`;

const ARROW_OFFSET = "-6px";
export const TOOLTIP_PADDING = 12;

export const TooltipArrowStyles = css`
    &[data-popper-placement^="right"] {
        ${TooltipArrow} {
            left: ${ARROW_OFFSET};
            // clip the left half of the square
            clip-path: polygon(-50% -50%, -50% 150%, 50% 150%, 50% -50%);
        }
    }

    &[data-popper-placement^="left"] {
        ${TooltipArrow} {
            right: ${ARROW_OFFSET};
            // clip the right half of the square
            clip-path: polygon(50% -50%, 150% -50%, 150% 150%, 50% 150%);
        }
    }

    &[data-popper-placement^="top"] {
        ${TooltipArrow} {
            bottom: ${ARROW_OFFSET};
            // clip the top half of the square
            clip-path: polygon(-50% 150%, 150% 150%, 150% 50%, -50% 50%);
        }
    }

    &[data-popper-placement^="bottom"] {
        ${TooltipArrow} {
            top: ${ARROW_OFFSET};
            // clip the bottom half of the square
            clip-path: polygon(-50% -50%, 150% -50%, 150% 50%, -50% 50%);
        }
    }
`;

export const StyledTooltip = styled.div<{ useFade: boolean; isGlobal?: boolean }>`
    padding: ${TOOLTIP_PADDING}px;
    color: ${props => props.theme.C_BTN_4L_emph_text};
    box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    z-index: ${props => props.isGlobal ? LayoutStackOrder.Tooltips : LocalStackOrder.Tooltips};

    ${T_PLAIN_small};

    ${TooltipArrowStyles};
  
  ${props => props.useFade && css`
    ${fadeOutAnimation()}
  `};
`;

export const Background = styled.div<{color: string}>`
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 3px;

    ${props => !props.color && css`
        background-image: ${props => `linear-gradient(to bottom, ${props.theme.C_BG_info})`};

        [data-popper-placement^="bottom"] & {
        background-image: ${props => `linear-gradient(to top, ${props.theme.C_BG_info})`};
        }
    `}

    ${props => props.color && css<{ color: string }>`
        background-color: ${props => props.theme[props.color as keyof DefaultTheme] ?? props.color};
    `}
`;

export const StyledTooltipContent = styled.div`
    max-width: 260px;
    word-break: break-word;
`;