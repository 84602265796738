import React from "react";
import SmartField, { ISmartFieldBlur, ISmartFieldChange } from "../../components/smart/smartField/SmartField";
import ProgressBar from "../../components/progressBar/ProgressBar";
import { WithTranslation, withTranslation } from "react-i18next";
import { StyledWrapper } from "./numRangeWithProgressBar.styles";
import { FormStorage } from "../../views/formView/FormStorage";
import { generateNumRangePreviews } from "./NumberRangeFormView";
import { getMaxNumericCount, isDocumentNumberRange, numericSignsLeft } from "./NumberRange.utils";
import { FormMode, GroupedField } from "../../enums";
import { FieldAdditionalData } from "../../model/Model";
import { INumberRangeDefinitionEntity, NumberRangeDefinitionEntity } from "@odata/GeneratedEntityTypes";
import BindingContext from "../../odata/BindingContext";
import { getInfoValue } from "@components/smart/FieldInfo";

interface IProps extends WithTranslation {
    storage: FormStorage;
}

const NUM_RANGE_FIELDS = ["NumberRangeTypePrefix", "CarryForwardPrefix", "Prefix", "NumberOfDigits", "Suffix"];

class NumRangeWithProgressBar extends React.Component<IProps> {
    handleNumberOfDigits = (e: ISmartFieldChange): void => {
        if (e.bindingContext.getPath(true) === "NumberOfDigits") {
            const fieldsBc: BindingContext[] = [];

            for (const {
                entity,
                bindingContext
            } of this.props.storage.data.bindingContext.iterateNavigation("NumberRanges", this.props.storage.data.entity.NumberRanges)) {
                fieldsBc.push(bindingContext.navigate("InitialValue"));
                fieldsBc.push(bindingContext.navigate("NextValue"));
            }

            if (fieldsBc.length > 0) {
                this.props.storage.validateFields(fieldsBc);
            }
        }
    };

    handleChange = (e: ISmartFieldChange) => {
        this.props.storage.handleChange(e);
        this.handleNumberOfDigits(e);
        const signsLeft = numericSignsLeft(this.props.storage);
        if (signsLeft >= 0 && signsLeft <= this.getMaxNumericCount()) {
            generateNumRangePreviews(this.props.storage.data.entity, this.props.storage);
        }
        this.props.storage.refreshFields();
    };

    handleBlur = (e: ISmartFieldBlur) => {
        const path = e.bindingContext.getPath(true);
        if (NUM_RANGE_FIELDS.includes(path)) {
            const remainingFields = NUM_RANGE_FIELDS.filter(part => part !== path);
            for (const field of remainingFields) {
                this.props.storage.getValueByPath(field) && this.props.storage.validateField(this.props.storage.data.bindingContext.navigate(field));
            }
        }
        this.props.storage.handleBlur(e)
                .then(() => this.props.storage.refreshFields());
    };

    _renderField(fieldId: string) {
        const storage = this.props.storage;
        const fieldBc = storage.data.bindingContext.navigate(fieldId);
        const fieldInfo = storage.getInfo(fieldBc);
        const groupedField = getInfoValue(fieldInfo, "groupedField", { storage });
        const auditTrailData = storage.getAdditionalFieldData(fieldBc, FieldAdditionalData.AuditTrailData);
        const isTypePrefix = fieldId === NumberRangeDefinitionEntity.NumberRangeTypePrefix;
        return <SmartField
                key={fieldId}
                bindingContext={storage.data.bindingContext.navigate(fieldId)}
                storage={storage}
                fieldProps={{
                    isSharpLeft: !isTypePrefix && groupedField !== GroupedField.MultiStart,
                    isSharpRight: !isTypePrefix && groupedField !== GroupedField.MultiEnd,
                    auditTrailData
                }}
                onBlur={this.handleBlur}
                onChange={this.handleChange}
        />;
    }

    getMaxNumericCount = () => {
        return getMaxNumericCount(this.props.storage);
    };

    render() {
        const entity = this.props.storage.data.entity as INumberRangeDefinitionEntity;
        const numSignsLeft = numericSignsLeft(this.props.storage);
        const hasCarryForwardPrefix = isDocumentNumberRange(this.props.storage) && entity.CarryForward;

        return (
                <StyledWrapper hasCarryForwardPrefix={hasCarryForwardPrefix}>
                    {NUM_RANGE_FIELDS.map((fieldId) => {
                        return this._renderField(fieldId);
                    })}
                    {this.props.storage.formMode !== FormMode.AuditTrail &&
                            <ProgressBar
                                    value={numSignsLeft > 0 ? numSignsLeft : 0}
                                    parts={this.getMaxNumericCount()}
                                    showMarks={true}
                                    customDescription={this.props.t("NumberRange:NumericSignsLeft")}/>}
                </StyledWrapper>
        );
    }
}

export default withTranslation(["NumberRange"])(NumRangeWithProgressBar);