export enum HotspotViewIds {
    DashboardCustomization = "dashboardCustomization",
    AuditTrailView = "auditTrailView",
    PairingDocTableView = "pairingDocTableView",
    SwitchableFeatures = "switchableFeatures",
    FileIntegrationMockId = "FileIntegrationMockId",
    GoPayMockView = "goPayMockView",
    GoPayMockItemView = "goPayMockItemView",
    PdfViewerDevtools = "pdfViewerDevtools",
    ExecuteScheduledTask = "executeScheduledTask",
    TimeTravel = "timeTravel",
    SubscriptionPlan = "subscriptionPlan",
    BillingPaymentHistoryTable = "billingPaymentHistoryTable",
    CompanyDashboard = "companyDashboard",
    FileView = "fileView",
    HomeView = "homeView",
    ChartOfAccountsTemplatesTable = "chartOfAccountsTemplatesTable",
    Notifications = "notifications",
    PairingWithAssetTableView = "pairingWithAssetTableView",
    SubscriptionExtensions = "subscriptionExtensions",
    VatSubmissionsDetail = "vatSubmissionsDetail",
}