import React, { lazy } from "react";
import FormRoute from "../routes/FormRoute";
import {
    ROUTE_CORRECTIVE_INVOICE_ISSUED,
    ROUTE_CORRECTIVE_INVOICE_RECEIVED,
    ROUTE_INTERNAL_DOCUMENT,
    ROUTE_INVOICE_ISSUED,
    ROUTE_INVOICE_RECEIVED, ROUTE_OTHER_LIABILITY, ROUTE_OTHER_RECEIVABLE,
    ROUTE_PROFORMA_INVOICE_ISSUED,
    ROUTE_PROFORMA_INVOICE_RECEIVED, ROUTE_RECURRING_TASKS, ROUTE_RECURRING_TASKS_EXECUTIONS
} from "../routes";
import { CompanyPermissionCode } from "@odata/GeneratedEnums";
import { EntityTypeName } from "@odata/GeneratedEntityTypes";
import PrivateRoute from "../routes/PrivateRoute";

const InvoicesReceived = lazy(() => import(/* webpackChunkName: "documents" */"../pages/documents/invoicesReceived/InvoicesReceived"));
const InvoiceIssued = lazy(() => import(/* webpackChunkName: "documents" */"../pages/documents/invoicesIssued/InvoicesIssued"));
const ProformaInvoicesReceived = lazy(() => import(/* webpackChunkName: "documents" */"../pages/documents/proformaInvoices/received/ProformaInvoicesReceived"));
const ProformaInvoicesIssued = lazy(() => import(/* webpackChunkName: "documents" */"../pages/documents/proformaInvoices/issued/ProformaInvoicesIssued"));
const OtherLiability = lazy(() => import(/* webpackChunkName: "documents" */"../pages/documents/otherLiability/OtherLiability"));
const OtherReceivable = lazy(() => import(/* webpackChunkName: "documents" */"../pages/documents/otherReceivable/OtherReceivable"));
const InternalDocument = lazy(() => import(/* webpackChunkName: "documents" */"../pages/documents/internalDocument/InternalDocument"));
const CorrectiveInvoicesReceived = lazy(() => import(/* webpackChunkName: "documents" */"../pages/documents/corrective/correctiveInvoiceReceived/CorrectiveInvoicesReceived"));
const CorrectiveInvoicesIssued = lazy(() => import(/* webpackChunkName: "documents" */"../pages/documents/corrective/correctiveInvoiceIssued/CorrectiveInvoicesIssued"));
const GeneratedDraftsView = lazy(() => import(/* webpackChunkName: "documents" */"../pages/recurringTasks/executions/Executions"));
const RecurringTasks = lazy(() => import(/* webpackChunkName: "documents" */"../pages/recurringTasks/RecurringTask"));

export default function documentsBundle() {
    return [
        <FormRoute
                path={ROUTE_INVOICE_RECEIVED}
                key={ROUTE_INVOICE_RECEIVED}
                permission={CompanyPermissionCode.InvoicesReceived}
                entityType={EntityTypeName.InvoiceReceived}
                page={InvoicesReceived}/>,
        <FormRoute
                path={ROUTE_INVOICE_ISSUED}
                key={ROUTE_INVOICE_ISSUED}
                permission={CompanyPermissionCode.InvoicesIssued}
                entityType={EntityTypeName.InvoiceIssued}
                page={InvoiceIssued}/>,
        <FormRoute
                path={ROUTE_PROFORMA_INVOICE_RECEIVED}
                key={ROUTE_PROFORMA_INVOICE_RECEIVED}
                permission={CompanyPermissionCode.InvoicesReceived}
                entityType={EntityTypeName.ProformaInvoiceReceived}
                page={ProformaInvoicesReceived}/>,
        <FormRoute
                path={ROUTE_PROFORMA_INVOICE_ISSUED}
                key={ROUTE_PROFORMA_INVOICE_ISSUED}
                permission={CompanyPermissionCode.InvoicesIssued}
                entityType={EntityTypeName.ProformaInvoiceIssued}
                page={ProformaInvoicesIssued}/>,
        <FormRoute
                path={ROUTE_OTHER_LIABILITY}
                key={ROUTE_OTHER_LIABILITY}
                permission={CompanyPermissionCode.InvoicesReceived}
                entityType={EntityTypeName.OtherLiability}
                page={OtherLiability}/>,
        <FormRoute
                path={ROUTE_OTHER_RECEIVABLE}
                key={ROUTE_OTHER_RECEIVABLE}
                permission={CompanyPermissionCode.InvoicesIssued}
                entityType={EntityTypeName.OtherReceivable}
                page={OtherReceivable}/>,
        <FormRoute
                path={ROUTE_INTERNAL_DOCUMENT}
                key={ROUTE_INTERNAL_DOCUMENT}
                entityType={EntityTypeName.InternalDocument}
                permission={CompanyPermissionCode.InternalAndCorrectiveDocuments}
                page={InternalDocument}/>,
        <FormRoute
                path={ROUTE_CORRECTIVE_INVOICE_RECEIVED}
                key={ROUTE_CORRECTIVE_INVOICE_RECEIVED}
                entityType={EntityTypeName.CorrectiveInvoiceReceived}
                permission={CompanyPermissionCode.InternalAndCorrectiveDocuments}
                page={CorrectiveInvoicesReceived}/>,
        <FormRoute
                path={ROUTE_CORRECTIVE_INVOICE_ISSUED}
                key={ROUTE_CORRECTIVE_INVOICE_ISSUED}
                entityType={EntityTypeName.CorrectiveInvoiceIssued}
                permission={CompanyPermissionCode.InternalAndCorrectiveDocuments}
                page={CorrectiveInvoicesIssued}/>,

        <PrivateRoute
                path={`${ROUTE_RECURRING_TASKS}/:ParentId${ROUTE_RECURRING_TASKS_EXECUTIONS}`}
                key={`${ROUTE_RECURRING_TASKS}/:ParentId${ROUTE_RECURRING_TASKS_EXECUTIONS}`}
                exact
                permission={CompanyPermissionCode.RecurringTask}
                component={GeneratedDraftsView}/>,

        <FormRoute
                path={ROUTE_RECURRING_TASKS}
                key={ROUTE_RECURRING_TASKS}
                entityType={EntityTypeName.RecurringTask}
                permission={CompanyPermissionCode.RecurringTask}
                page={RecurringTasks}/>
    ];
}