import React, { useCallback, useEffect, useRef, useState } from "react";
import { ITicketMessage } from "./TicketMessage.utils";
import TicketMessage from "./TicketMessage";
import { TicketMessageStreamStyled, TicketMessagesWrapper } from "./TicketMessage.styles";
import CustomResizeObserver from "../customResizeObserver";
import { ScrollBar } from "../scrollBar";
import TicketMessageInput from "./TicketMessageInput";
import SimpleBar from "simplebar-react";
import { VIEW_PADDING_VALUE } from "../../views/View.styles";
import TestIds from "../../testIds";

interface IProps {
    messages: ITicketMessage[];
    isDone?: boolean;
    placeholder?: string;
    onCreateMessage: (message: ITicketMessage, files: File[]) => Promise<boolean>;
    canClose?: boolean;
    isCustomer?: boolean;
    onSwitch?: () => Promise<void>;
}

const TicketMessageStream: React.FC<IProps> = ({
                                                   messages,
                                                   isDone,
                                                   placeholder,
                                                   onCreateMessage,
                                                   isCustomer,
                                                   onSwitch,
                                                   canClose
                                               }) => {
    const _scrollRef = useRef<SimpleBar>();
    const _messageWrapperRef = useRef<HTMLDivElement>();

    const [maxH, setMaxH] = useState<number>(0);

    const handleResize = useCallback(() => {
        const { height } = _messageWrapperRef.current?.getBoundingClientRect();
        setMaxH(height);
    }, []);

    const handleConfirm = useCallback((message: ITicketMessage, files: File[]) => {
        return onCreateMessage(message, files);
    }, [onCreateMessage]);

    useEffect(() => {
        handleResize();
        const scrollEl = _scrollRef.current.getScrollElement();
        scrollEl.scrollTop = maxH;
    });

    let lastRenderedMessage: ITicketMessage = null;

    return (
            <TicketMessageStreamStyled data-testid={TestIds.TicketMessageStream}>
                <ScrollBar
                        ref={_scrollRef}
                        style={{
                            overflowX: "hidden",
                            overflowY: "auto",
                            padding: `0 ${VIEW_PADDING_VALUE}px`,
                            maxHeight: `${maxH}px`,
                            flex: "1 1 0"
                        }}>
                    <CustomResizeObserver onResize={handleResize}/>
                    <TicketMessagesWrapper ref={_messageWrapperRef}>
                        {messages?.map((m, idx) => {
                            const groupWithPrevious = lastRenderedMessage?.createdById && lastRenderedMessage?.createdById === m.createdById;
                            lastRenderedMessage = m;
                            return (
                                    <TicketMessage {...m} isGrouped={groupWithPrevious} key={idx}/>
                            );
                        })}
                    </TicketMessagesWrapper>
                </ScrollBar>
                {(!isCustomer || !isDone) && <TicketMessageInput showSubject={!messages?.length}
                                                                 onConfirm={handleConfirm}
                                                                 isDone={isDone}
                                                                 showSwitch={canClose}
                                                                 onSwitch={onSwitch}/>}
            </TicketMessageStreamStyled>
    );
};

export default TicketMessageStream;