import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import {
    Condition,
    defaultValue,
    getComplexFilterValue,
    IComplexFilter,
    isInterval,
    IValueInterval,
    PredefinedFilter,
    TFilterValue,
    ValueTypeAndConditionToPredefinedFilter,
    ValueTypeToCondition
} from "./ConditionalFilterDialog.utils";
import { StyledConditionalFilterRow, Title, ValueInput } from "./ConditionalFilterDialog.styles";
import { BasicInputSizes, IconSize, TextAlign, ValidationErrorType, ValueType } from "../../enums";
import { IInputOnChangeEvent, ISharedInputProps } from "../inputs/input";
import WriteLine from "../inputs/writeLine";
import NumericWriteLine from "../inputs/writeLine/NumericWriteLine";
import { DatePicker, DateRangePicker } from "../inputs/date";
import { IDayInterval, isValidDateInterval } from "../inputs/date/utils";
import TestIds from "../../testIds";
import { IValidationErrorInterval, NumericRange } from "../inputs/numericRange";
import { IValidationError, ValidationMessage } from "../../model/Validator.types";
import { isDefined, isObjectEmpty } from "@utils/general";
import memoizeOne from "../../utils/memoizeOne";
import { IconButton } from "../button";
import { BasicSelect } from "../inputs/select/BasicSelect";
import { CaretIcon, CloseIcon } from "../icon";
import { GroupDividerSize, ISelectGroup } from "@components/inputs/select/Select.types";

export type TFilterValueError = IValidationError | IValidationErrorInterval;
export type TGetCustomValueInput = (row: IComplexFilter, onChange: (value: IComplexFilter) => void) => React.ReactElement;

export interface IProps extends WithTranslation {
    label: string;
    row: IComplexFilter;
    error: TFilterValueError;
    type: ValueType;
    canRemove?: boolean;
    isLabelVisible?: boolean;
    isEnum?: boolean;
    getCustomValueInput?: TGetCustomValueInput;
    validator?: (value: TFilterValue) => void;

    onChange: (val: IComplexFilter) => void;
    onRemove: (id: string) => void;
    onValueError: (rowId: string, error: TFilterValueError) => void;
}

class ConditionalFilterRow extends React.PureComponent<IProps> {
    prepareItems = memoizeOne((type: ValueType, condition: Condition) => {
        const _createItem = (key: any) => {
            return {
                id: key,
                label: this.props.t(`Components:ValueHelper.${key}`)
            };
        };

        const conditions = this.props.isEnum ? [_createItem(Condition.Equals)] : ValueTypeToCondition[type].map(_createItem);
        const filters = ValueTypeAndConditionToPredefinedFilter[type]?.[condition]?.map(_createItem);

        return { conditions, filters };
    }, () => [this.props.tReady]);

    get isValueVisible() {
        return !!this.props.row.filter;
    }

    /**
     * Filter values could be 2 value interval or Array, otherwise it will be simple value...
     * @param v1
     * @param v2
     */
    valueTypeDiffers(v1: TFilterValue, v2: TFilterValue) {
        return isInterval(v1) !== isInterval(v2) || Array.isArray(v1) !== Array.isArray(v2);
    }


    changeRow(newRow: IComplexFilter) {
        const prevRow = this.props.row,
            prevRealValue = getComplexFilterValue(prevRow),
            defVal = defaultValue(newRow, this.props.type);

        if (newRow.filter !== PredefinedFilter.Value) {
            // new row value has predefined filter -> there will be no value at all
            delete newRow.value;
        } else if (prevRow.filter !== PredefinedFilter.Value && !this.valueTypeDiffers(prevRealValue, defVal)) {
            // previous row value type was predefined and is of the same type
            //  -> keep that value to be edited by user
            newRow.value = prevRealValue;
        } else if (this.valueTypeDiffers(newRow.value, defVal)) {
            // new value type differs from the defaultOne -> reinitiate
            newRow.value = defVal;
        }
        this.props.onChange(newRow);
    }

    validateRow(row: IComplexFilter) {
        let error: TFilterValueError;
        // We need to validate Number range separately as two fields are used
        if (isInterval(row.value)) {
            let errorInterval = {} as IValidationErrorInterval;
            // Validate both interval values (DatePicker), fall on first error
            const value = row.value as IValueInterval;
            (["from", "to"] as (keyof IValueInterval)[]).forEach((k) => {
                try {
                    this.props.validator?.(value[k] as TFilterValue);
                } catch (e) {
                    errorInterval[k] = e;
                }
            });
            if (isObjectEmpty(errorInterval) && isDefined(value.from) && isDefined(value.to)) {
                const isValidInterval = this.props.type === ValueType.Date
                    ? isValidDateInterval(value as IDayInterval)
                    : value.from < value.to;
                if (!isValidInterval) {
                    const invRangeError = {
                        errorType: ValidationErrorType.Field,
                        message: ValidationMessage.NotARange
                    };
                    errorInterval = {
                        from: invRangeError, to: invRangeError
                    };
                }
            }
            error = errorInterval;
        } else {
            try {
                // validate single value
                this.props.validator?.(row.value);
            } catch (e) {
                error = e;
            }
        }
        // empty error object means no error actually (we might delete the last one from interval)
        if (isObjectEmpty(error)) {
            error = null;
        }
        this.props.onValueError(row.id, error);
    }

    handleConditionChange = (args: any) => {
        if (args.triggerAdditionalTasks) {
            const newRow = {
                ...this.props.row,
                filter: PredefinedFilter.Value,
                condition: args.value
            };
            this.changeRow(newRow);
            this.validateRow(newRow);
        }
    };

    handleFilterChange = (args: any) => {
        if (args.triggerAdditionalTasks) {
            const newRow = {
                ...this.props.row,
                filter: args.value
            };
            this.changeRow(newRow);
            if (args.value === PredefinedFilter.Value) {
                this.validateRow(newRow);
            } else {
                // it's filter with predefined value -> it's always valid (value is not defined)
                //  -> clear error
                this.props.onValueError(newRow.id, null);
            }
        }
    };

    handleValueChange = (value: TFilterValue) => {
        const newRow = {
            ...this.props.row,
            value
        };

        this.changeRow(newRow);
        this.validateRow(newRow);
    };

    handleInputChange = (event: IInputOnChangeEvent) => {
        this.handleValueChange(event.value);
    };

    handleBlur = () => {
        if (this.props.error) {
            // rerender components to show error on focus out
            this.forceUpdate();
        }
    };

    handleRemove = () => {
        this.props.onRemove(this.props.row.id);
    };

    renderValueInput(row: IComplexFilter) {
        const value = getComplexFilterValue(row);

        const _singleInputByType = (value: TFilterValue, type: ValueType, props: ISharedInputProps) => {
            if (this.props.getCustomValueInput) {
                return this.props.getCustomValueInput(row, this.props.onChange);
            }

            switch (type) {
                case ValueType.Date:
                    return (
                        <DatePicker {...props}
                                    value={value as Date}
                                    error={this.props.error as IValidationError}
                                    onChange={this.handleInputChange}
                        />
                    );
                case ValueType.Number:
                    return (
                        <NumericWriteLine {...props}
                                          value={value as number}
                                          error={this.props.error as IValidationError}
                                          onChange={this.handleInputChange}
                        />
                    );
                default:
                    return (
                        <WriteLine {...props}
                                   value={value as string}
                                   error={this.props.error as IValidationError}
                                   onChange={this.handleInputChange}
                        />
                    );
            }
        };

        const _doubleInputByType = (value: TFilterValue, type: ValueType, props: ISharedInputProps) => {
            const error = this.props.error as IValidationErrorInterval;
            switch (type) {
                case ValueType.Date:
                    return (
                        <DateRangePicker {...props}
                                         value={value as IDayInterval}
                                         error={(error?.from ?? error?.to) as IValidationError}
                                         onChange={this.handleInputChange}
                        />
                    );
                case ValueType.Number:
                    return (
                        <NumericRange {...props}
                                      value={value as IValueInterval<number>}
                                      error={error}
                                      onChange={this.handleValueChange}
                        />
                    );
                default:
                    // We don't have double input for other types, fallback to single one
                    return _singleInputByType(value, type, props);
            }
        };

        const sharedProps = {
            width: BasicInputSizes.L,
            textAlign: TextAlign.Left,
            onBlur: this.handleBlur,
            isDisabled: row.filter !== PredefinedFilter.Value
        };

        const getValueControls = isInterval(value) ? _doubleInputByType : _singleInputByType;

        return (
            <ValueInput>{getValueControls(value, this.props.type, sharedProps)}</ValueInput>
        );
    }

    render() {
        const selectProps = {
            width: BasicInputSizes.L,
            inputIcon: <CaretIcon width={IconSize.M}/>,
            closeOnSelection: true,
            openOnClick: true,
            inputIsReadOnly: true
        };

        const defaultValueGroup = {
                id: "Default"
            },
            itemsGroup: ISelectGroup = { id: undefined, dividerSize: GroupDividerSize.Small },
            defaultValue = {
                id: PredefinedFilter.Value,
                label: this.props.t(`Components:ValueHelper.${this.props.type === ValueType.Date ? "DateValue" : "Value"}`),
                groupId: defaultValueGroup.id
            };

        const items = this.prepareItems(this.props.type, this.props.row.condition);

        return (
            <StyledConditionalFilterRow data-testid={TestIds.ValueHelperRow}>
                <Title _visible={this.props.isLabelVisible}>{this.props.label}</Title>

                {items.conditions?.length &&
                    <BasicSelect {...selectProps}
                                 isDisabled={items.conditions?.length === 1}
                                 items={items.conditions}
                                 value={this.props.row.condition}
                                 onChange={this.handleConditionChange}
                    />}

                {items.filters?.length &&
                    <BasicSelect {...selectProps}
                                 groups={[defaultValueGroup, itemsGroup]}
                                 items={[defaultValue, ...items.filters]}
                                 value={this.props.row.filter}
                                 onChange={this.handleFilterChange}
                    />}

                {this.isValueVisible && this.renderValueInput(this.props.row)}

                <IconButton title={this.props.t("Components:ValueHelper.Remove")}
                            onClick={this.handleRemove}
                            isDisabled={!this.props.canRemove}
                            isDecorative>
                    <CloseIcon/>
                </IconButton>
            </StyledConditionalFilterRow>
        );
    }
}

export default withTranslation(["Components"])(ConditionalFilterRow);