import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { IconSize } from "../../../enums";
import { BottomStepper, TopStepper } from "./NumericValueInputBase.styles";
import { StyledStepper } from "./Stepper.styles";
import TestIds from "../../../testIds";

export interface IStepperProps {
    isDisabled?: boolean;
    onNextMouseDown?: () => void;
    onPrevMouseDown?: () => void;
    isNextDisabled?: boolean;
    isPrevDisabled?: boolean;
    isLight?: boolean;
    style?: React.CSSProperties;
}

export const Stepper: React.FunctionComponent<IStepperProps> = (props) => {
    const { t } = useTranslation(["Components"]);
    const handleMouseDown = (e: React.MouseEvent, callback: () => void) => {
        if (e.button === 0) {
            callback();
        }
    };

    return (
        <StyledStepper isDisabled={props.isDisabled}
                       style={props.style}
                       data-testid={TestIds.Stepper}>
            <TopStepper
                onMouseDown={useCallback((e: React.MouseEvent) => handleMouseDown(e, props.onNextMouseDown), [])}
                title={t("Components:NumericInput.Increase")}
                isDisabled={props.isDisabled || props.isNextDisabled}
                isLight={props.isLight}
                size={IconSize.XS}/>
            <BottomStepper
                onMouseDown={useCallback((e: React.MouseEvent) => handleMouseDown(e, props.onPrevMouseDown), [])}
                title={t("Components:NumericInput.Decrease")}
                isDisabled={props.isDisabled || props.isPrevDisabled}
                isLight={props.isLight}
                size={IconSize.XS}
            />
        </StyledStepper>
    );
};