import ConnectionDialog, { ConnectionStatus } from "@components/connectionDialog/ConnectionDialog";
import { IEntity } from "@odata/BindingContext";
import {
    EntitySetName,
    IInvoiceServiceIntegrationEntity,
    InvoiceServiceIntegrationEntity,
    InvoiceServiceIntegrationSettingsEntity
} from "@odata/GeneratedEntityTypes";
import { IIntegrationsCustomData } from "@pages/integrations/Integration.types";
import { INTEGRATIONS_API_URL } from "@pages/integrations/Integration.utils";
import IntegrationSettingsFormView from "@pages/integrations/IntegrationSettingsFormView";
import customFetch from "@utils/customFetch";
import { isObjectEmpty } from "@utils/general";
import { logger } from "@utils/log";
import i18next from "i18next";
import React, { Component, ReactElement } from "react";
import { Trans } from "react-i18next";

import { QueryParam } from "../../enums";
import { getQueryParameters, removeQueryParam } from "../../routes/Routes.utils";
import { FormStorage } from "../../views/formView/FormStorage";
import PlugAndPlayForm from "../../views/formView/PlugAndPlayForm";
import { docTypesPropsPaths, getDefinition as getSettingsDefinition, getSettingsPath } from "./IntegrationSettings.def";

const GET_AUTH_URL = `${INTEGRATIONS_API_URL}/GetOauthUrl`;

interface IProps {
    storage: FormStorage;
    onAfterConfirm: (savedDate: IEntity, isConnected: boolean) => void;
    onClose: () => void;
}

interface IState {
    status: ConnectionStatus;
    isBusy: boolean;
    error?: string;
}

class IntegrationSettingsDialog extends Component<IProps, IState> {
    _formRef = React.createRef<any>();

    state: IState = {
        isBusy: true,
        status: null
    }

    getCurrentState = async (storage: FormStorage<IInvoiceServiceIntegrationEntity, IIntegrationsCustomData>): Promise<void> => {
        const queryParams = getQueryParameters();
        let status: ConnectionStatus;
        let error: string = null;

        if (queryParams[QueryParam.integrationCallbackState]) {
            if (queryParams[QueryParam.integrationCallbackState]?.toLowerCase() === "ok") {
                status = ConnectionStatus.WaitingForConfiguration;
            } else {
                status = ConnectionStatus.ConnectionError;
                const errorCode = queryParams[QueryParam.integrationCallbackState];
                const errorKey = `Error:${errorCode}`;
                error = i18next.exists(errorKey) ? storage.t(errorKey).toString() : errorCode;
            }
            removeQueryParam(this.props.storage.history, QueryParam.integrationCallbackState, true);
        } else {
            const settings = storage.getValueByPath(InvoiceServiceIntegrationEntity.Settings);
            status = isObjectEmpty(settings) ? ConnectionStatus.Disconnected : ConnectionStatus.Connected;
        }

        if (status === ConnectionStatus.WaitingForConfiguration) {
            docTypesPropsPaths.forEach(path => storage.setValueByPath(path, true));

            storage.setDefaultValueByPath(getSettingsPath(InvoiceServiceIntegrationSettingsEntity.IntegrationApiImportFromWhen));
            storage.setDefaultValueByPath(getSettingsPath(InvoiceServiceIntegrationSettingsEntity.IntegrationApiImportFrequency));
        }

        storage.setCustomData({ connectionStatus: status });

        this.setState({
            isBusy: false,
            status,
            error
        });
    };

    handleAfterConfirmDialog = (savedData: IEntity): void => {
        this.props.onAfterConfirm(savedData, true);
    };

    handleCloseDialog = (): void => {
        this.props.onClose();
    };

    handleRedirect = async (): Promise<void> => {
        const storage = this.props.storage;
        try {
            const res = await customFetch(`${GET_AUTH_URL}/${storage.data.entity.Id}`);
            const url = await res.text();
            window.location.href = url;
        } catch (e) {
            logger.error(e);
        }
    };

    handleDisconnect = async (): Promise<void> => {
        const { storage } = this.props;
        const entity = storage.data.entity;
        this.setState({ isBusy: true });
        await storage.oData.getEntitySetWrapper(EntitySetName.InvoiceServiceIntegrations).update(entity.Id, {
            [InvoiceServiceIntegrationEntity.Settings]: null
        });
        this.setState({ isBusy: false });
        this.props.onAfterConfirm(entity, false);
    }

    handleConfirm = async (): Promise<void> => {
        this.setState({ isBusy: true });
        const storage = this.props.storage;
        const status = this.state.status;
        const isSavingSettings = [ConnectionStatus.WaitingForConfiguration, ConnectionStatus.Connected].includes(status);

        if (ConnectionStatus.ConnectionError === status) {
            this.setState({ isBusy: false, status: ConnectionStatus.Disconnected });
            return;
        }

        if (isSavingSettings) {
            const result = await this._formRef.current?.save({ skipLoad: true });
            if (!!result) {
                await this._formRef.current?.props.storage?.init({
                    preserveInfos: true,
                    bindingContext: storage.data.bindingContext
                });
                this.props.onAfterConfirm(storage.data.entity, true);
            }
        } else {
            await this.handleRedirect();
        }
        this.setState({ isBusy: false });
    };

    getCustomSubtitle = (): string | ReactElement => {
        const { storage } = this.props;
        const name = storage.getValueByPath(InvoiceServiceIntegrationEntity.Name);
        const type = storage.getValueByPath(InvoiceServiceIntegrationEntity.InvoiceServiceIntegrationType)?.Name;

        if (this.state.status === ConnectionStatus.Disconnected) {
            return <Trans i18nKey={"Integrations:DisconnectedSubtitle"}
                          values={{ name, type }}
                          components={{
                              1: <strong/>
                          }}/>;
        } else if (this.state.status === ConnectionStatus.WaitingForConfiguration) {
            return <Trans i18nKey={"Integrations:WaitingForConfigurationSubTitle"}
                          values={{ name, type }}
                          components={{
                              1: <strong/>
                          }}/>;
        } else if (this.state.status === ConnectionStatus.ConnectionError) {
            return this.state.error;
        }

        return null;
    };

    getContent = (): ReactElement => {
        const storage = this.props.storage;

        switch (this.state.status) {
            case ConnectionStatus.ConnectionError:
            case ConnectionStatus.Disconnected:
                return null;
            default:
                return <PlugAndPlayForm
                        getDef={getSettingsDefinition}
                        t={storage.t}
                        formRef={this._formRef}
                        bindingContext={storage.data.bindingContext}
                        onAfterLoad={this.getCurrentState}
                        formView={IntegrationSettingsFormView}
                        formViewProps={{
                            isInDialog: true,
                            dialogProps: {
                                title: null,
                                aretateWidth: true
                            },
                            formProps: {
                                renderScrollbar: false,
                                withoutPaddingForAlert: true
                            },
                            onAfterConfirm: this.handleAfterConfirmDialog,
                            onClose: this.handleCloseDialog
                        }}
                />;
        }
    };

    render() {
        const { storage } = this.props;
        const child = this.getContent();
        const isConnected = this.state.status === ConnectionStatus.Connected;

        return <ConnectionDialog
                accountName={storage.getValueByPath(InvoiceServiceIntegrationEntity.Name)}
                isBusy={this.state.isBusy}
                onDisconnect={isConnected ? this.handleDisconnect : null}
                onConfirm={this.handleConfirm}
                onClose={this.handleCloseDialog}
                subtitle={this.getCustomSubtitle()}
                // width={[ConnectionStatus.WaitingForConfiguration, ConnectionStatus.Connected].includes(this.state.status) ? "409px" : null}
                status={this.state.status}>
            {child}
        </ConnectionDialog>;
    }
}

export default IntegrationSettingsDialog;