import styled from "styled-components/macro";
import { getFocusBorderElement, T_TITLE_2_2 } from "../../global.style";
import { transparentize } from "polished";


export const AppButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;

    border-radius: 8px;
    background-color: ${props => props.theme.C_ACT_hover};
    padding: 0 16px;
    height: 70px;
    margin-bottom: 12px;

    background-color: ${props => transparentize(0.8, props.theme.C_BTN_hover_light)};
    cursor: pointer;

    ${getFocusBorderElement({ isLight: true, keyboardOnly: true })};

    &:hover {
        background-color: ${props => transparentize(0.8, props.theme.C_BTN_4L_trans_line_color_hover)};
    }
`;

export const Title = styled.div`
    ${T_TITLE_2_2};
    color: ${props => props.theme.C_TEXT_dialogue};
    margin-left: 18px;
`;
