import { ellipsis } from "polished";
import styled, { css } from "styled-components/macro";

import {
    CActDetBGStyles,
    getDisabledStyle,
    LocalStackOrder,
    T_PLAIN_med_hig,
    T_PLAIN_small,
    T_PLAIN_small_hig
} from "../../global.style";
import { StyledIconButton } from "../button";
import { ColoredIcon } from "../icon";


const centered = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
`;

export const SmartBankAccountFilterStyledWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    position: relative;
    padding: 18px 38px 18px 18px;
    gap: 18px;
    align-items: flex-end;
`;

export const DropAreaText = styled.span`
    color: ${props => props.theme.C_BG_menu};
    font-weight: bold;
    font-size: 24px;
`;

export const DropHandler = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

export const DropArea = styled.div<{
    isDragging?: boolean;
}>`
    position: absolute;
    border-radius: 4px;
    border: 2px dashed ${props => props.theme.C_BG_menu};
    top: -2px;
    left: -2px;
    width: calc(100% + 4px);
    height: calc(100% + 4px);

    display: ${props => props.isDragging ? "flex" : "none"};

    //TODO: COLOR
    background: #7E2562;
    z-index: ${LocalStackOrder.Overlay};
    opacity: 0.85;

    justify-content: center;
    align-items: center;
    flex-direction: column;
}
`;

export const CheckboxWrapper = styled.div`
    ${centered};
    background-color: rgba(255, 255, 255, 0.1);
    flex: 0 0 30px;
    cursor: pointer;
`;

export const BankAccountLogoWrapper = styled.div`
    ${centered};
    flex: 0 0 80px;

    polygon {
        fill: ${props => props.theme.C_BG_selected_color};
        opacity: 1;
    }

    rect {
        fill: ${props => props.theme.C_BG_selected_color};
        opacity: 1;
    }

    path {
        opacity: 1;
        fill: ${props => props.theme.C_BG_selected_color};
    }
`;

export const InfoWrapper = styled.div`
    padding: 15px 10px 0 5px;
    flex: 1 1 auto;
    width: 0; // shrinks this column if needed
    color: ${props => props.theme.C_BTN_4L_emph_text};
`;

export const Title = styled.div`
    ${ellipsis()};
    ${T_PLAIN_small_hig};
    text-transform: uppercase;
    display: block;
`;

export const AccountNumber = styled.div<{
}>`
    ${ellipsis()};
    ${T_PLAIN_small};
    display: block;
    opacity: 0.6;
    margin-bottom: 10px;
`;

export const Balance = styled.div`
    ${ellipsis()};
    ${T_PLAIN_med_hig};
    display: block;
`;

export const LastImport = styled.div`
    ${ellipsis()};
    ${T_PLAIN_small};
    display: flex;
    align-items: center;

    ${ColoredIcon} {
        display: inline-block;
        vertical-align: text-bottom;
    }
`;

export const ActionsWrapper = styled.div`
    padding: 10px 10px 10px 0;
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;

    ${StyledIconButton}:not(:last-child) {
        margin-bottom: 8px;
    }
`;

export const CheckboxFilterWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
`;

export const BankAccountFilterWrapper = styled.div <{
    isDisabled?: boolean;
}>`
    background-color: ${props => props.theme.C_ACT_der};
    ${CActDetBGStyles};
    width: 280px;
    height: 100px;
    display: flex;
    align-items: stretch;
    border-radius: 3px;
    ${props => getDisabledStyle(props.isDisabled)};
    pointer-events: ${props => props.isDisabled ? "none" : "all"};
    position: relative;
`;