import { AlertPosition } from "@components/alert/Alert";
import { WithAlert, withAlert } from "@components/alert/withAlert";
import { WithBusyIndicator, withBusyIndicator } from "@components/busyIndicator/withBusyIndicator";
import { ButtonSize } from "@components/button/Button.utils";
import { WithPromisedComponent, withPromisedComponent } from "@components/dialog/withPromisedComponent";
import { ODataError } from "@odata/Data.types";
import {
    EntitySetName,
    IInvoiceEntity,
    InvoiceEntity,
    ISubscriptionEntity,
    ITenantEntity,
    SubscriptionEntity
} from "@odata/GeneratedEntityTypes";
import { PaymentMethodCode, SubscriptionStatusCode } from "@odata/GeneratedEnums";
import { parseResponse } from "@odata/ODataParser";
import { WithOData, withOData } from "@odata/withOData";
import { saveFileFromUrl } from "@utils/FileStorage";
import { logger } from "@utils/log";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";

import BusyIndicator from "../../components/busyIndicator";
import { Button, ButtonGroup } from "../../components/button";
import DashboardBackground, { BackgroundType } from "../../components/navigation/DashboardBackground";
import PurchaseWizardDialog from "../../components/purchaseWizardDialog/PurchaseWizardDialog";
import { FILES_API_URL } from "../../constants";
import { AppContext, IAppContext } from "../../contexts/appContext/AppContext.types";
import { Direction, QueryParam, TextAlign } from "../../enums";
import { ROUTE_HOME } from "../../routes";
import { getQueryParameters } from "../../routes/Routes.utils";
import { getUtcDayjs } from "../../types/Date";
import { getAlertFromError } from "../../views/formView/Form.utils";
import View from "../../views/View";
import {
    cancelSubscription,
    confirmSubscription,
    createMissingPayment,
    isEduLicenceWaitingForConfirmation,
    isNotYetPayedForSubscription,
    restoreSubscription
} from "../admin/subscriptions/Subscriptions.utils";
import { isUserOwner } from "../admin/users/Users.utils";
import { createSingleFileExportOfAllData } from "../dataExport/DataExport.utils";
import { LogoutButton } from "../userSetting/LogoutButton";
import {
    AccountDisabledDescription,
    AccountDisabledMainText,
    ContentWrapper,
    DaysRemainingText,
    HorizontalButtonGroupInVerticalStyled,
    WillBeRemovedText
} from "./GoodbyeScreen.styles";

interface IProps {

}

interface IExtendedProps extends IProps, WithAlert, WithTranslation, WithOData, RouteComponentProps, WithBusyIndicator, WithPromisedComponent {

}

enum GoodbyeScreenType {
    Default = "Default",
    AwaitsRenewal = "AwaitsRenewal"
}

interface IState {
    screen: GoodbyeScreenType;
    invoices: IInvoiceEntity[];
    keepCancelledStatus: boolean;
}

export class GoodbyeScreen extends React.PureComponent<IExtendedProps, IState> {
    static contextType = AppContext;

    constructor(props: IExtendedProps, context: IAppContext) {
        super(props, context);

        const screen = context.getData().subscription.SubscriptionStatusCode === SubscriptionStatusCode.AwaitsRenewal
            ? GoodbyeScreenType.AwaitsRenewal
            : GoodbyeScreenType.Default;

        this.state = {
            screen,
            invoices: [],
            keepCancelledStatus: false
        };
    }

    componentDidMount() {
        const context = this.context as IAppContext;

        if (!context.hasLimitedAccess()) {
            this.props.history.replace(ROUTE_HOME);
            return;
        }

        if (getQueryParameters()?.[QueryParam.PurchaseAfterTrial]) {
            this.purchaseAfterTrial();
        }

        this.fetchInvoices();
    }

    get isEdu(): boolean {
        return isEduLicenceWaitingForConfirmation(this.context.getData().subscription);
    }

    purchaseAfterTrial = async (): Promise<void> => {
        if (!isNotYetPayedForSubscription(this.subscription)) {
            return;
        }

        await this.handleRestoreAccountClick();
        await this.handlePaymentClick();
    };

    fetchInvoices = async (): Promise<void> => {
        const res = await this.props.oData.getEntitySetWrapper(EntitySetName.Subscriptions)
            .query()
            .select(SubscriptionEntity.Id)
            .expand(SubscriptionEntity.Invoices, (q) => {
                q.orderBy(InvoiceEntity.DateStart);
                q.filter(`${InvoiceEntity.DateOfPayment} eq null and ${InvoiceEntity.FileMetadataId} ne null`);
            })
            .fetchData<ISubscriptionEntity[]>();

        if (!res.value) {
            return;
        }

        const invoices = res.value[0]?.Invoices ?? [];

        this.setState({
            invoices
        });
    };

    get tenant(): ITenantEntity {
        const { tenant } = (this.context as IAppContext).getData();

        return tenant;
    }

    get subscription(): ISubscriptionEntity {
        const { subscription } = (this.context as IAppContext).getData();

        return subscription;
    }

    get remainingDays(): number {
        if (!this.tenant?.DateDataDeletion) {
            return null;
        }

        return getUtcDayjs(this.tenant?.DateDataDeletion).diff(getUtcDayjs(), "day");
    }

    get unpaidInvoices(): IInvoiceEntity[] {
        const invoicesLength = this.state?.invoices?.length;

        if (!invoicesLength) {
            return [];
        }

        return this.state.invoices.filter(invoice => !invoice.DateOfPayment && invoice.FileMetadataId);
    }

    setErrorAlert = async (res: Response): Promise<void> => {
        const error: ODataError = await parseResponse(res);

        logger.error(`GoodbyeScreen request failed: ${error._message}`);
        this.props.setAlert({
            ...getAlertFromError(error),
            isSmall: false,
            position: AlertPosition.CenteredBottom
        });
    };

    reloadApp = (): void => {
        // just hardcore reload the whole page, just to be sure that everything will be reloaded correctly
        // otherwise, we should call context.refreshAppData, PermissionContext.refreshUserPermissions and maybe something else also
        window.location.reload();
    };

    updateTenantAndReload = async (): Promise<boolean> => {
        const context = this.context as IAppContext;

        if (this.subscription.SubscriptionStatusCode === SubscriptionStatusCode.Cancelled) {
            this.setState({
                keepCancelledStatus: true
            });
        }

        await context.updateTenantAndSubscription();

        if (this.subscription.SubscriptionStatusCode === SubscriptionStatusCode.Active) {
            this.reloadApp();
            return true;
        }

        this.setState({
            keepCancelledStatus: false
        });

        return false;
    };

    handleRestoreAccountClick = async (): Promise<void> => {

        this.props.setBusy(true);

        const res = await restoreSubscription();

        if (!res.ok) {
            this.setErrorAlert(res);
            this.props.setBusy(false);
            return;
        } else {
            this.props.setAlert(null);
        }

        const keepCancelledStatus = await this.updateTenantAndReload();

        if (!keepCancelledStatus) {
            this.props.setBusy(false);

            this.setState({
                screen: GoodbyeScreenType.AwaitsRenewal
            });
        }
    };

    handlePaymentClick = async (): Promise<void> => {
        const trialEnded = isNotYetPayedForSubscription(this.subscription);

        // user has not yet paid at all => show purchase wizard
        if (trialEnded) {
            await this.props.promisedComponent.openComponent<boolean>((onFinish) => {
                return (
                    <PurchaseWizardDialog onClose={onFinish}/>
                );
            });
        } else {
            this.props.setBusy(true);
            // run one time payment
            const res = await createMissingPayment();

            if (!res.ok) {
                this.setErrorAlert(res);
            }
        }
    };

    cancelRestoring = async (): Promise<void> => {
        this.props.setBusy(true);

        const res = await cancelSubscription();

        if (!res.ok) {
            this.setErrorAlert(res);
            this.props.setBusy(false);
            return;
        } else {
            this.props.setAlert(null);
        }

        this.props.setBusy(false);

        this.setState({
            screen: GoodbyeScreenType.Default
        });
    };

    handleDownloadUnpaidInvoices = (): void => {
        for (const unpaidInvoice of this.unpaidInvoices) {
            saveFileFromUrl(`${FILES_API_URL}/${unpaidInvoice.FileMetadataId}`);
        }
    };

    handleDownloadData = async (): Promise<void> => {
        createSingleFileExportOfAllData();
    };

    handleOnAfterTenantSave = async (): Promise<void> => {
        const res = await confirmSubscription();

        if (!res.ok) {
            const errorData = await res.json();

            throw (errorData);
        }
    };

    handleEduConfirmation = async (): Promise<void> => {
        const res = await this.props.promisedComponent.openComponent<boolean>((onFinish) => {
            return (
                <PurchaseWizardDialog onClose={onFinish} onAfterTenantSave={this.handleOnAfterTenantSave}/>
            );
        });

        if (res) {
            this.reloadApp();
        }
    };

    renderEduConfirmation = (): JSX.Element => {
        return (
            <>
                <AccountDisabledMainText style={{ marginBottom: "30px" }}>
                    {this.props.t("Goodbye:EDU.EduConfirmation")}
                </AccountDisabledMainText>
                <AccountDisabledDescription style={{ marginBottom: "60px" }}>
                    {this.props.t("Goodbye:EDU.EduConfirmDescription")}
                </AccountDisabledDescription>
                <ButtonGroup direction={Direction.Vertical}>
                    <Button size={ButtonSize.Big}
                            onClick={this.handleEduConfirmation}>
                        {this.props.t("Goodbye:EDU.ConfirmEdu")}
                    </Button>
                </ButtonGroup>
            </>
        );
    };

    renderGoodbyeScreen = (): JSX.Element => {
        const context = this.context as IAppContext;
        const isOwner = !!isUserOwner(context.getData()?.userSettings);
        const remainingDays = this.remainingDays;
        const trialEnded = isNotYetPayedForSubscription(this.subscription);
        const unpaidInvoices = this.unpaidInvoices;
        let transKey: string;

        switch (true) {
            case this.state.keepCancelledStatus || this.subscription.SubscriptionStatusCode === SubscriptionStatusCode.Cancelled:
                transKey = "TenantCancelled";
                break;
            case trialEnded:
                transKey = "TrialEnded";
                break;
            case this.state.screen === GoodbyeScreenType.AwaitsRenewal:
                transKey = "Restoring";
                break;
            case this.subscription.PaymentMethodCode === PaymentMethodCode.WireTransfer:
                transKey = "Wire";
                break;
            case this.subscription.PaymentMethodCode === PaymentMethodCode.Card:
                transKey = "Card";
                break;
        }

        return (
            <>
                <AccountDisabledMainText>
                    {this.props.t(`Goodbye:${transKey}.MainText`)}
                </AccountDisabledMainText>
                <AccountDisabledDescription>
                    {this.props.t("Goodbye:RemainingUntilRemoved")}
                </AccountDisabledDescription>
                <DaysRemainingText isVisible={!!remainingDays}>
                    {remainingDays} {this.props.t("Common:Time.Days", { count: remainingDays })}
                </DaysRemainingText>
                <WillBeRemovedText>
                    {this.props.t("Goodbye:WillBeRemovedAfterThat")}
                </WillBeRemovedText>
                <ButtonGroup direction={Direction.Vertical}>
                    {isOwner && this.state.screen === GoodbyeScreenType.Default &&
                        <Button size={ButtonSize.Big}
                                onClick={this.handleRestoreAccountClick}>
                            {this.props.t(`Goodbye:${transKey}.ButtonText`)}
                        </Button>
                    }
                    {isOwner && this.state.screen === GoodbyeScreenType.AwaitsRenewal &&
                        <Button size={ButtonSize.Big}
                                onClick={this.handlePaymentClick}>
                            {this.props.t(`Goodbye:${transKey}.PaymentText`)}
                        </Button>
                    }
                    <HorizontalButtonGroupInVerticalStyled direction={Direction.Horizontal}>
                        {unpaidInvoices?.length > 0 &&
                            <Button isTransparent
                                    onClick={this.handleDownloadUnpaidInvoices}>
                                {this.props.t("Goodbye:DownloadLatestInvoice")}
                            </Button>
                        }
                        {this.state.screen === GoodbyeScreenType.AwaitsRenewal &&
                            <Button isTransparent
                                    onClick={this.cancelRestoring}>
                                {this.props.t("Goodbye:Restoring.CancelRestoring")}
                            </Button>
                        }
                        {/*hidden, not ready*/}
                        {/*<Button isTransparent*/}
                        {/*        onClick={this.handleDownloadData}>*/}
                        {/*    {this.props.t("Goodbye:DownloadData")}*/}
                        {/*</Button>*/}
                    </HorizontalButtonGroupInVerticalStyled>
                </ButtonGroup>
            </>
        );
    };

    render() {
        if (!this.subscription) {
            return <BusyIndicator/>;
        }

        return (
            <>
                <View hotspotContextId={"goodbyeScreen"}>
                    {this.props.alert}
                    <DashboardBackground type={BackgroundType.GoodbyeScreen}/>
                    <ButtonGroup align={TextAlign.Right}>
                        <LogoutButton/>
                    </ButtonGroup>
                    <ContentWrapper>
                        {this.isEdu ? this.renderEduConfirmation() : this.renderGoodbyeScreen()}
                    </ContentWrapper>
                </View>
                {this.props.busyIndicator}
            </>
        );
    }
}

export default withAlert()((withPromisedComponent(withBusyIndicator({ passBusyIndicator: true })(withTranslation(["Goodbye", "Common"])(withRouter(withOData(GoodbyeScreen)))))));