import React from "react";
import { debounce } from "lodash";
import { isOverflowing } from "@utils/general";
import CustomResizeObserver from "../customResizeObserver/CustomResizeObserver";

interface IProps {
    maxNumColumns: number;
    children: (args: IColumnAutoSizerChildren) => React.ReactElement;
}

interface IState {
    numColumns: number;
    shouldCheckWidth: boolean;
    lastWidth: number;
}

export interface IColumnAutoSizerChildren {
    observeRef: React.RefObject<HTMLDivElement>;
    numColumns: number;
}

/** Use in components that need to resize to different number of columns, based on current number of items and available space */
export default class ColumnAutoSizer extends React.PureComponent<IProps, IState> {
    observeRef = React.createRef<HTMLDivElement>();
    shouldForceRefresh = false;

    constructor(props: IProps) {
        super(props);

        this.state = {
            numColumns: this.props.maxNumColumns,
            shouldCheckWidth: true,
            lastWidth: null
        };
    }

    componentDidMount() {
        this.fitColumns();
    }

    componentDidUpdate(prevProps: IProps) {
        if (prevProps.maxNumColumns !== this.props.maxNumColumns) {
            // using local variable instead of method parameter
            // otherwise debounce would pass the last arguments of the refresh call
            // and that could be 'undefined' instead of 'true'
            this.shouldForceRefresh = true;
            this.refresh();
        } else if (this.state.shouldCheckWidth) {
            this.fitColumns();
        }
    }

    refresh = () => {
        const lastWidth = this.state.lastWidth;
        const currentWidth = this.observeRef.current?.scrollWidth;

        if (!currentWidth) {
            return;
        }

        this.setState((state) => {
            return {
                numColumns: this.shouldForceRefresh || !lastWidth || currentWidth > lastWidth ? this.props.maxNumColumns : state.numColumns,
                shouldCheckWidth: true
            };
        });
    };

    // use debounce to prevent lags - causes a small delay but the performance is significantly increased
    handleResize = debounce((entries: readonly ResizeObserverEntry[]) => {
        this.refresh();
    }, 100);

    fitColumns = () => {
        this.setState((state) => {
            const overflowing = isOverflowing(this.observeRef.current);
            const numColumns = Math.max(1, overflowing ? state.numColumns - 1 : state.numColumns);

            return {
                numColumns: numColumns,
                shouldCheckWidth: numColumns !== 1 && overflowing,
                lastWidth: this.observeRef.current.scrollWidth
            };
        });
    };

    render() {
        return (
            <>
                {
                    this.props.children?.({
                        observeRef: this.observeRef,
                        numColumns: this.state.numColumns
                    })
                }
                <CustomResizeObserver onResize={this.handleResize}/>
            </>
        );
    }
}