import { IEntity } from "@odata/BindingContext";
import React, { ReactElement } from "react";

import Dialog, { IDialogProps } from "../../components/dialog";
import ConfirmationButtons from "../table/ConfirmationButtons";
import { IProps } from "./Form";
import { IFormStorageDefaultCustomData } from "./FormStorage";
import { FormViewForExtend, IFormViewProps } from "./FormView";

export interface IDialogFormViewProps<E, C extends IFormStorageDefaultCustomData = IFormStorageDefaultCustomData> extends IFormViewProps<E, C> {
    dialogProps?: Omit<IDialogProps, "isOpen" | "onConfirm" | "onClose"> & { title?: string };
    // called after successful form save, with data received from the save method
    onAfterConfirm?: (savedData: IEntity) => void;
    onClose?: () => void;
    getCustomFooter?: (args: IGetCustomFooterArgs) => React.ReactElement;
    confirmText?: string;
}

interface IGetCustomFooterArgs {
    onConfirm: () => void;
    onClose: () => void;
}

class DialogFormView<E, P extends IDialogFormViewProps<E> = IDialogFormViewProps<E>, S = Record<string, unknown>> extends FormViewForExtend<E, P, S> {
    static defaultProps: Partial<IDialogFormViewProps<IEntity>> = {
        isInDialog: true,
        hideButtons: true
    };

    // override to pass props necessary for DialogFormView.
    // defaultProps are not enough, because PlugAndPlay form passes some formProps as well
    getFormProps = (): Partial<IProps> => {
        return {
            hideHeader: true,
            hideBreadcrumbs: true,
            withoutPadding: true,
            withoutPaddingForAlert: true,
            renderScrollbar: false,
            ...this.props.formProps
        };
    };

    handleDialogConfirm = async (): Promise<void> => {
        this.props.storage.setBusy(true);

        const result = await this.save();

        this.props.storage.setBusy(false);

        if (result) {
            this.props.onAfterConfirm?.(result.data);
        }
    };

    getFooter = (): ReactElement => {
        if (this.props.getCustomFooter) {
            return this.props.getCustomFooter({
                onConfirm: this.handleDialogConfirm,
                onClose: this.props.onClose
            });
        }

        return (
                <ConfirmationButtons
                        confirmText={this.props.confirmText}
                        onConfirm={this.handleDialogConfirm}
                        onCancel={this.props.onClose}
                        useWrapper={false}/>
        );
    };

    render() {
        const { aretateWidth, aretateHeight, ...passProps } = this.props.dialogProps ?? {};
        return (
                <Dialog {...passProps}
                        footer={this.getFooter()}
                        busy={!this.props.storage.loaded || this.props.storage.isBusy()}
                        onConfirm={this.handleDialogConfirm}
                        aretateHeight={!!(this.props.storage.loaded && aretateHeight)}
                        aretateWidth={!!(this.props.storage.loaded && aretateWidth)}
                        onClose={this.props.onClose}>
                    {this.isReady() && this.renderForm()}
                </Dialog>
        );
    }
}

export default DialogFormView;