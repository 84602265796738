import React, { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormStorage } from "../../../../views/formView/FormStorage";
import DialogPage from "../../../DialogPage";
import { PageViewMode } from "../../../../enums";
import Dialog from "../../../../components/dialog/Dialog";
import PairingAccrualsTableView from "./PairingAccrualsTableView";
import { getDefinitions } from "./AccrualsDef";
import {
    AccrualDialogType,
    CONNECT_ACCRUALS_ACTION,
    CONNECT_EXPENSES_ACTION,
    CONNECT_REVENUES_ACTION,
    getAccrualDialogTypeFromHeaderAction
} from "./Accruals.utils";

export interface WithAccruals {
    onCustomHeaderAction?: (actionId: string, storage: FormStorage) => Promise<boolean>;
}

interface WithAccrualsProps {
    onCustomHeaderAction?: (actionId: string, storage: FormStorage) => Promise<boolean>;
}

type TWithAccruals<P> = Omit<P, keyof WithAccruals> & WithAccrualsProps;


export interface WithAccrualsSettings {
    isExpense?: boolean;
}

export const withAccruals = (settings: WithAccrualsSettings = {}) => (
    <P extends WithAccruals>(Component: React.ComponentType<P>): React.ComponentType<TWithAccruals<P>> => {
        return React.forwardRef((props: TWithAccruals<P>, ref) => {
            const { t, ready: tReady } = useTranslation(getDefinitions.translationFiles);
            const [dialogType, setDialogType] = useState<AccrualDialogType>(null);
            const formStorage = useRef<FormStorage>(null);

            const handleCustomHeaderAction = useCallback(async (actionId: string, storage: FormStorage) => {
                formStorage.current = storage;

                if ([CONNECT_ACCRUALS_ACTION, CONNECT_REVENUES_ACTION, CONNECT_EXPENSES_ACTION].includes(actionId)) {
                    setDialogType(getAccrualDialogTypeFromHeaderAction(actionId));
                    return null;
                } else {
                    return props.onCustomHeaderAction?.(actionId, storage);
                }
            }, []);

            const handleClose = useCallback(() => {
                setDialogType(null);
            }, []);

            return (
                <>
                    <Component {...props as P}
                               onCustomHeaderAction={handleCustomHeaderAction}
                               ref={ref}/>
                    {dialogType &&
                        <Dialog isEditableWindow={true}
                                onConfirm={null}
                                onClose={handleClose}>
                            <DialogPage
                                    pageViewMode={PageViewMode.FormReadOnly}
                                    rootStorage={formStorage.current}
                                    tableView={PairingAccrualsTableView}
                                    tableViewProps={{
                                        onClose: handleClose,
                                        dialogType
                                    }}
                                getDef={getDefinitions.bind(null, formStorage.current?.context, !!settings.isExpense)}
                            />
                        </Dialog>
                    }
                </>
            );
        }) as any;
    }
);