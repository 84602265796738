import styled, { css } from "styled-components/macro";
import { TableSizes } from "../../enums";
import SmartFilterBar from "../../components/smart/smartFilterBar/SmartFilterBar";
import Alert from "../../components/alert/Alert";
import { T_TITLE_2 } from "../../global.style";
import Tabs from "../../components/tabs";


export const TableWrapper = styled.div<{
    isInTab?: boolean;
    hasMinHeight?: boolean;
}>`
    min-height: ${props => props.hasMinHeight === false ? 0 : "100px"};
    max-height: 100%;
    margin-top: 5px;
    flex: 1;
    position: relative;
        // width: calc(100% + ${`${TableSizes.ShadowPadding}px`});
        // left: ${`${-TableSizes.ShadowPadding}px`};
`;

export const FormTableWrapper = styled(TableWrapper) <{
    isInTab?: boolean;
}>`
    ${props => props.isInTab
        ? css`
            margin-top: 20px;
        `
        : css`
            margin-bottom: 10px;
            min-height: unset;
        `
    }
`;

export const TopWrapper = styled.div<{
    _loaded: boolean
}>`
    opacity: ${props => props._loaded ? "1" : "0"}
`;

export const SmartFilterBarStyled = styled(SmartFilterBar)`
    margin-bottom: 36px;
`;

export const TableAlert = styled(Alert)`
    margin-top: -10px;
    margin-bottom: 40px;
`;

export const SecondaryTableViewTitle = styled.div`
    ${T_TITLE_2};
    margin-bottom: 20px;
`;
export const StyledTableTabs = styled(Tabs)`
    margin-top: 24px;
`;