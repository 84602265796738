import React from "react";
import { FixedPart, ShortenPart, TextEllipsisStyled } from "./TextEllipsis.styles";
import Tooltip from "../tooltip";

interface IProps {
    fixedPattern?: RegExp;
    fixedChars?: number;
    text: string;
}

const TextEllipsis: React.FC<IProps> = ({ fixedChars, fixedPattern, text }) => {
    let shortenPart: string, fixedPart: string;

    if (fixedChars > 0) {
        fixedPart = text.slice(-1 * fixedChars);
        shortenPart = text.slice(0, text.length - fixedChars);
    } else if (fixedPattern) {
        shortenPart = text.replace(fixedPattern, (match: string) => {
            fixedPart = match;
            return "";
        });
    } else {
        shortenPart = text;
    }

    return (

        <Tooltip content={text}
                 onlyShowWhenChildrenOverflowing>
            {(ref, overflowRef) => (
                <TextEllipsisStyled ref={ref}>
                    <ShortenPart ref={overflowRef}>{shortenPart}</ShortenPart>
                    {fixedPart && <FixedPart>{fixedPart}</FixedPart>}
                </TextEllipsisStyled>
            )}
        </Tooltip>
    );
};

export default TextEllipsis;