import styled from "styled-components/macro";
import { T_PLAIN_small } from "../../../global.style";
import { TOP_PADDING } from "../../header/Header.styles";

export const StyledVariantVersionWrapper = styled.div `
    padding-top: ${TOP_PADDING}px;
    margin-left: 18px;
`;

export const HeaderInfo = styled.div`
    flex-basis: 100%;
    display: flex;
    align-items: center;
`;

export const HeaderInfoText = styled.div<{ hasTooltip: boolean }>`
    margin-left: ${props => props.hasTooltip ? "5px" : "0"};
    ${T_PLAIN_small};
`;
