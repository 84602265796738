import styled, { css } from "styled-components/macro";
import { getFocusBorderElement, T_PLAIN_small, T_PLAIN_tiny } from "../../global.style";
import { transparentize } from "polished";

interface ISlider {
    pos?: string;
    width?: string;
    hoverOnly?: boolean;
    isFirst?: boolean;
}

export const StyledSlider = styled.div.attrs<ISlider>(props => ({
    style: { width: props.width }
}))<ISlider>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;   
    position: relative;   
`;

export const Line = styled.div`
    position: relative;
    width: 100%;
    height: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    // space for labels
    margin-top: 19px;
`;

export const Background = styled.div`
    position: relative;
    width: 100%;
    height: 3px;    
    border-radius: ${props => props.theme.borderLargeRadius};
    background-color: ${props => props.theme.C_ACT_hover_without_opacity};
`;

interface IValueRange {
    left?: string;
    right?: string;
}

export const ValueRange = styled.div.attrs<IValueRange>(props => ({
    style: {
        clipPath: `polygon(${props.left} 0, calc(100% - ${props.right}) 0, calc(100% - ${props.right}) 100%, ${props.left} 100%)`
    }
}))<IValueRange>`
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
    border-radius: ${props => props.theme.borderLargeRadius};
    background: ${props => props.theme.C_BG_progressbar};
`;

export const LabelWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Label = styled.div<ISlider>`
    position: absolute;
    top: -5px;
    left: 50%;
    transform: translate(-50%, -100%);
    white-space: nowrap;
    ${T_PLAIN_small};
    
    ${props => props.hoverOnly && css`
        visibility: hidden;
    `}    
`;

export const StyledThumb = styled.div.attrs<ISlider>(props => ({
    style: { left: props.pos }
}))<ISlider>`
    position: absolute;
    
    width: 13px;
    height: 16px;
    
    top: 50%;    
    transform: translate(-50%, -50%);
    
    ${getFocusBorderElement({ keyboardOnly: true, offset: 2 })};
    
    .slider_x0020_styl {
        stroke: ${props => transparentize(0.5, props.theme.C_ACT_main)};
        fill: ${props => props.theme.C_ACT_hover_without_opacity};
    }
    
    &:hover, &:focus {
        .slider_x0020_styl {
            stroke: ${props => props.theme.C_ACT_main};
        }
        
        ${Label}{
            visibility: visible;
        }
    }
    
    &:active {
        .slider_x0020_styl {
            stroke: ${props => props.theme.C_ACT_main};
            fill: ${props => props.theme.C_ACT_main};
        }
    }
`;

export const Mark = styled.div.attrs<ISlider>(props => ({
    style: { left: props.pos }
}))<ISlider>`
    position: absolute;
    height: 7px;
    width: 1px;
    transform: translateX(-50%);
    background-color: ${props => transparentize(0.5, props.theme.C_ACT_main)};
`;

export const MarksLabels = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    margin-top: 3px;
`;

export const MarkLabel = styled.span.attrs<ISlider>(props => ({
    style: { left: props.pos }
}))<ISlider>`
    position: ${props => props.isFirst ? "relative" : "absolute"};
    transform: translateX(-50%);
    ${T_PLAIN_tiny};
`;