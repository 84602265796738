import { FormViewForExtend, IFormViewProps } from "../../views/formView/FormView";
import { withPermissionContext } from "../../contexts/permissionContext/withPermissionContext";
import { ITenantEntity } from "@odata/GeneratedEntityTypes";
import { individualSettingsCompanyPath } from "./PurchaseWizardDialog.utils";

interface IProps extends IFormViewProps<ITenantEntity> {
}

class PurchaseWizardDialogFormView extends FormViewForExtend<ITenantEntity, IProps> {
    onAfterLoad() {
        const companies = this.props.storage.context?.getData()?.companies;

        if (companies?.length > 1) {
            const fieldInfo = this.props.storage.getInfo(this.props.storage.data.bindingContext.navigate(individualSettingsCompanyPath));

            fieldInfo.fieldSettings.items = companies.map(company => {
                return {
                    id: company.Id,
                    label: company.Name
                };
            });
        }

        return super.onAfterLoad();
    }
}

export default withPermissionContext(PurchaseWizardDialogFormView);
