import styled from "styled-components/macro";
import { transparentize } from "polished";
import { T_PLAIN_small, T_PLAIN_small_hig, T_TITLE_5 } from "../../global.style";
import { ProgressBarColor } from "./ProgressBar.utils";
import { Theme } from "../../theme";

interface IProgressBar {
    $value?: number;
    $color?: ProgressBarColor;
}

const getBackgroundColor = (color: ProgressBarColor, theme: Theme): string => {
    switch (color) {
        case ProgressBarColor.Gray:
            return theme.C_BG_progresswheeel_gray;
        default:
            return transparentize(0.5, theme.C_ACT_hover_without_opacity);
    }
};

const getFillBackgroundColor = (color: ProgressBarColor, theme: Theme): string => {
    switch (color) {
        case ProgressBarColor.Semantic:
            return theme.C_BG_progressbar_semantic;
        case ProgressBarColor.Gray:
            return theme.C_ACT_thick_line;
        default:
            return theme.C_BG_progressbar;
    }
};

const getBorderColor = (color: ProgressBarColor, theme: Theme): string => {
    switch (color) {
        case ProgressBarColor.Gray:
            return theme.C_ACT_thick_line;
        default:
            return transparentize(0.5, theme.C_ACT_main);
    }
};

export const StyledProgressBar = styled.div`
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
`;

export const ProgressWrapper = styled.div<IProgressBar>`
    height: 8px;
    border-radius: ${props => props.theme.borderLargeRadius};

    background-color: ${props => getBackgroundColor(props.$color, props.theme)};
    border: 1px solid ${props => getBorderColor(props.$color, props.theme)};

    display: flex;
    position: relative;
`;

export const ProgressValue = styled.div.attrs((props: IProgressBar) => ({
    style: {
        clipPath: `inset(0 ${100 - props.$value}% 0 0)`
    }
}))<IProgressBar>`
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    position: absolute;
    top: -1px;
    left: -1px;
    border-radius: ${props => props.theme.borderLargeRadius};
    background: ${props => getFillBackgroundColor(props.$color, props.theme)};
    //transition: clip-path 0.7s ease-in-out;
`;

export const StyledCanvas = styled.canvas.attrs({ height: "8px" })`
    position: absolute;
    width: calc(100% + 2px);
    top: -1px;
    left: -1px;
    border-radius: ${props => props.theme.borderLargeRadius};
`;

export const Description = styled.div<{ flexDirection: "row" | "column" }>`
    display: flex;
    flex-direction: ${props => props.flexDirection};
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    white-space: pre;

    span {
        ${T_PLAIN_small};

        &:last-child {
            ${T_PLAIN_small_hig};
        }
    }
`;

export const StatusDescriptionFormatterWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3px;
    ${T_TITLE_5};
    text-transform: none;
`;