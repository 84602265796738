import styled from "styled-components/macro";

export const DimmedValue = styled.span`
    opacity: 0.3;
`;

export const LabelParent = styled.span`
    text-transform: uppercase;
`;

export const LabelParentDescription = styled.span`
    font-weight: normal;
    margin-left: 6px;
`;