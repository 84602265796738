import React, { ReactElement } from "react";
import { StyledErrorPage } from "./NotFound.styles";
import { useTranslation } from "react-i18next";
import { BeaverAnim, MoleAnim } from "../../animations/Animations";

export function NotFound(): ReactElement {
    const { t } = useTranslation("Common");
    return (
        <StyledErrorPage>
            <BeaverAnim/>
            <h2>{t("Common:Errors.Error404")}</h2>
        </StyledErrorPage>
    );
}

export function NoPermission(): ReactElement {
    const { t } = useTranslation("Common");
    return (
        <StyledErrorPage>
            <MoleAnim/>
            <h2>{t("Common:Errors.Error403")}</h2>
        </StyledErrorPage>
    );
}