import styled from "styled-components/macro";
import {T_HEADER_bold} from "../../global.style";

export const StyledObjectList = styled.div`
    margin: 33px 0 36px;
`;

export const NoDataWrapper = styled.div`
    text-align: center;
    padding: 15px 0;
    ${T_HEADER_bold};
`;