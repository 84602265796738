import { getInfoValue } from "@components/smart/FieldInfo";
import React from "react";

import { RadioButtonGroupLayout } from "../../../enums";
import { IAuditTrailData, Model } from "../../../model/Model";
import BindingContext from "../../../odata/BindingContext";
import RadioButtonGroup from "../../inputs/radioButtonGroup";
import { IRadioButtonDefinition } from "../../inputs/radioButtonGroup/RadioButtonGroup";

export interface ISmartRadioGroupChange {
    value: string;
    bindingContext: BindingContext;
}

interface IProps {
    value: string;
    bindingContext: BindingContext;
    storage: Model;
    definition: IRadioButtonDefinition[];
    defaultChecked?: string;
    isReadOnly?: boolean;
    layout?: RadioButtonGroupLayout;
    onChange: (args: ISmartRadioGroupChange) => void;
    auditTrailData?: IAuditTrailData;
}

export class SmartRadioButtonGroup extends React.Component<IProps> {
    handleChange = (value: string): void => {
        this.props.onChange({
            value: value,
            bindingContext: this.props.bindingContext
        });
    };

    render() {
        const definition = this.props.definition.filter(def => getInfoValue(def, "isVisible", { storage: this.props.storage }) !== false);

        return (
            <RadioButtonGroup
                checkedButton={this.props.value}
                auditTrailData={this.props.auditTrailData}
                definition={this.props.definition}
                isReadOnly={this.props.isReadOnly}
                defaultChecked={this.props.defaultChecked}
                layout={this.props.layout}
                onChange={this.handleChange}
                style={{ marginTop: this.props.layout === RadioButtonGroupLayout.Column ? "10px" : 0 }}
            />
        );
    }
}