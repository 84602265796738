import { BatchRequest } from "@odata/OData";
import { WithOData, withOData } from "@odata/withOData";
import { isAbortException } from "@utils/oneFetch";
import React from "react";

import { NavDashboard } from "../../components/navigation/NavDashboard";
import { AppContext, ContextEvents } from "../../contexts/appContext/AppContext.types";
import { WithPermissionContext, withPermissionContext } from "../../contexts/permissionContext/withPermissionContext";
import { filterMenu, getMainMenuDefinition, IMenuDefGroup, OrganizationSettingsMenuDefinition } from "../../menu-def";
import { NoPermission, NotFound } from "../notFound";

/** Wrapper around NavDashboard that loads "count" property for individual items */
interface IPropsTest extends WithOData, WithPermissionContext {
}

interface IState {
    groupWithItemsWithCounts: IMenuDefGroup;
    group: IMenuDefGroup;
}

class SmartNavDashboard extends React.Component<IPropsTest, IState> {
    static contextType = AppContext;

    batch: BatchRequest;

    state: IState = {
        groupWithItemsWithCounts: null,
        group: null
    };

    componentDidMount() {
        this.context.eventEmitter.on(ContextEvents.CompanyChanged, this.handleCompanyChanged);
        this.context.eventEmitter.on(ContextEvents.SelectedMenuChanged, this.handleSelectedMenuChanged);
        this.initMenuGroup();
    }

    componentDidUpdate(prevProps: IPropsTest) {
        this.initMenuGroup();
    }

    componentWillUnmount() {
        this.context.eventEmitter.off(ContextEvents.CompanyChanged, this.handleCompanyChanged);
        this.context.eventEmitter.off(ContextEvents.SelectedMenuChanged, this.handleSelectedMenuChanged);
        this.batch?.abort();
    }

    handleSelectedMenuChanged = (): void => {
        // https://solitea-cz.atlassian.net/browse/DEV-29540
        // sometimes, SmartNavDashboard is rendered before the selectedMenu is set
        // and won't re-render when it is set => it stays empty
        // ==> force re-render
        this.forceUpdate();
    };

    handleCompanyChanged = () => {
        this.initMenuGroup(true);
    };

    get groupKey(): string {
        return this.context.getSelectedMenu()?.group?.key;
    }

    initMenuGroup = (force?: boolean) => {
        const currentGroup = this.groupKey;

        if (currentGroup !== this.state.group?.key || force) {
            const group = this.getAllViews()?.find(view => view.key === currentGroup);

            if (group) {
                this.loadCounts(group);
                this.setState({ group });
            }
        }
    };

    loadCounts = async (group: IMenuDefGroup): Promise<void> => {
        const isAnyGetter = group?.items && group?.items.find(item => item.countGetter);

        if (!isAnyGetter) {
            this.setState({
                groupWithItemsWithCounts: null
            });
            return;
        }

        this.batch?.abort();

        this.batch = this.props.oData.batch();

        const countPromises: Record<string, Promise<number>> = {};

        for (const item of group.items) {
            countPromises[item.key] = item.countGetter?.(this.batch);
        }

        if (!this.batch.isEmpty()) {
            // requests are handled in countGetter
            // => only call batch.execute() and then retrieve the counts from countGetter promises
            try {
                await this.batch.execute();
            } catch (e) {
                if (isAbortException(e)) {
                    return null;
                }
            }

            const groupWithItemsWithCounts = {
                ...this.state.group,
                items: await Promise.all(
                    this.state.group.items.map(async (item) => {
                        return {
                            ...item,
                            count: countPromises[item.key] ? await countPromises[item.key] : null
                        };
                    })
                )
            };

            this.setState({
                groupWithItemsWithCounts
            });
        }
    };

    appendIsDisabled = (group: IMenuDefGroup): IMenuDefGroup => {
        if (!group) {
            return null;
        }

        return {
            ...group,
            items: group.items?.map((item) => {
                const isDisabled = item.isDisabledFn?.(this.context);

                return {
                    ...item,
                    isDisabled: !!isDisabled,
                    tooltip: isDisabled?.reason
                };
            })
        };
    };

    getAllViews = () => {
        const allViews = [...getMainMenuDefinition(this.context), ...OrganizationSettingsMenuDefinition];
        return filterMenu(allViews, this.context, this.props.permissionContext);
    };

    render() {
        const groupWithDisabled = this.appendIsDisabled(this.state.groupWithItemsWithCounts ?? this.state.group);

        if (groupWithDisabled?.items?.length === 0) {
            return <NoPermission/>;
        }

        // TODO only render NotFound if there really is no group,
        // otherwise whole app would get re-rendered pointlessly.
        // problem seems to be that selectedMenu is set asynchronously so there is a time when groupKey is null for a while
        // => causing the re-render
        if (!this.groupKey) {
            return <NotFound/>;
        }

        return (
                <NavDashboard group={groupWithDisabled}/>
        );
    }
}

export default withPermissionContext(withOData(SmartNavDashboard));