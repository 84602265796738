import React, { useCallback } from "react";
import { ISmartFieldChange } from "@components/smart/smartField/SmartField";
import { FormStorage } from "../../views/formView/FormStorage";
import TemporalFormDialog from "../../views/formView/TemporalFormDialog";
import { AccountDialogFields, IAccAssDialogCustomData } from "./AccountAssignment.utils";

interface IProps {
    storage: FormStorage<unknown, IAccAssDialogCustomData>;
    onConfirm?: () => void;
    onChange: (e: ISmartFieldChange) => void;
    onTemporalChange: (e: ISmartFieldChange) => void;
}

export const AccountAssignmentDialog: React.FC<IProps> = ({ storage, onChange, onTemporalChange, onConfirm }) => {
    const bc = storage.getCustomData().AccountAssignmentDialogBc;

    const onClose = useCallback(async (isConfirmed: boolean) => {
        storage.setCustomData({
            isDialogOpen: false
        });
        const selectionFields = [bc, bc.getParent().navigate("Selection/Code")];
        // cancels the temporal value from storage, which was previously set, so fields in dialog
        // works well, (e.g. isRequired is correctly calculated)
        storage.cancelFields(selectionFields);
        storage.refresh();
        if (isConfirmed) {
            onConfirm?.();
        }
    }, [storage, bc, onConfirm]);

    const isOpened = storage.getCustomData().isDialogOpen;
    if (!isOpened) {
        return null;
    }

    const accountAssignmentPath = bc.getNavigationPath();
    const fields = AccountDialogFields.map(field => `${accountAssignmentPath}/${field}`);
    const rows = [fields.slice(0, 2), fields.slice(2, fields.length)];

    return (
        <TemporalFormDialog storage={storage}
                            fieldPaths={rows}
                            onChange={onChange}
                            onTemporalChange={onTemporalChange}
                            title={storage.t("Document:General.CustomAccAssignment")}
                            onClose={onClose}/>
    );
};
