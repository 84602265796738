import styled from "styled-components/macro";
import { T_PLAIN_big_hig, T_TITLE_2, T_TITLE_3 } from "../../global.style";
import { Underline } from "@components/navigation/NavMenu.styles";

export const StyledTitle = styled.div`
    ${T_TITLE_2};
    color: ${props => props.theme.C_TEXT_dialogue};
    margin-bottom: 75px;
    text-transform: uppercase;
`;

export const StyledGroup = styled.div`
    &:not(:first-child) {
        margin-top: 55px;
    }
`;

export const StyledGroupTitle = styled.div`
    ${T_TITLE_3};
    color: ${props => props.theme.C_TEXT_dialogue};
    margin-bottom: 18px;
`;

export const GroupTitleWrapper = styled.div`
    margin-bottom: 12px;
`;

export const GroupItemTitle = styled.span<{
    selected: boolean

}>`
    ${T_PLAIN_big_hig};
    ${Underline};

    &::after {
        background-color: #fff;
    }

    &:hover::after, &:focus::after {
        background-color: #fff;
    }

    position: relative;
    color: ${props => props.theme.C_TEXT_dialogue};
    text-transform: uppercase;
    font-weight: 800;
    letter-spacing: 0.7px;
    cursor: pointer;
`;

export const StyledImage = styled.div`
    width: 60px;
`;
export const Icons = styled.div`
    display: flex;

    align-items: flex-end;
    margin-bottom: 12px;
`;
