import styled from "styled-components/macro";
import { NumericInput } from "../numericInput/NumericInput";
import { InputHoverContainer, StyledHtmlInput } from "../input/Input.styles";

export const StyledNumericInput = styled(NumericInput)<{
    $isSelected?: boolean;
    $hasError?: boolean;
}>`
    background: ${props => props.$isSelected ? props.theme.C_ACT_hover_without_opacity : ""};
    border: 1px solid ${props => props.$hasError ? props.theme.C_SEM_el_bad : "inherit"};

    &:focus-within {
        background: ${props => props.theme.C_ACT_hover_without_opacity};
    }

    ${StyledHtmlInput} {
        text-align: center;
        padding: 0;
        margin: 0;
        background: inherit;
        
        &:focus::placeholder {
            color: transparent;
        }
    }
    
    ${InputHoverContainer} {
        padding: 5px;
        &:after {
            display: ${props => props.$isSelected ? "block" : "none"};
            width: calc(100% + 2px);
            height: calc(100% + 2px);
            top: -1px;
            left: -1px;
        }
    }
    
    width: 34px;
`;