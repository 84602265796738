import BindingContext, { IEntity } from "../../../odata/BindingContext";
import { FormStorage } from "../../../views/formView/FormStorage";
import { getInfoValue, IFieldDef } from "../FieldInfo";
import { IAppContext } from "../../../contexts/appContext/AppContext.types";
import { getBoundValue, getNewItemsMaxId, setBoundValue, updateDataOnChange } from "@odata/Data.utils";
import { ValueType } from "../../../enums";

export enum ActionType {
    Add = "Add",
    Clone = "Clone",
    Remove = "Remove",
    Reorder = "Reorder",
    Custom = "Custom"
}

export interface ISmartFastEntriesActionEvent<T = IEntity> {
    items: T[];
    actionType: ActionType;
    customActionType?: string;
    bindingContext?: BindingContext;
    affectedItems?: T[];
    groupId?: string;
}

interface IAddNewLineItemArgs {
    storage: FormStorage;
    newItemsCount: number;
    columns: IFieldDef[];
    bindingContext: BindingContext;
    context: IAppContext,
    order?: string;
    index?: number; // order index for new item
}

export function addNewLineItem<T = IEntity>(storage: FormStorage, path: string, grpId?: string, columns?: IFieldDef[], defaultData?: IEntity): Partial<T> {
    const { bindingContext, entity } = storage.data;
    const fieldBc = bindingContext.navigate(path);
    if (!grpId) {
        grpId = path;
    }
    const itemsGroup = storage.getVariant().find(group => group.id === grpId);

    const currentItems = getBoundValue({
        bindingContext: fieldBc,
        data: entity,
        dataBindingContext: bindingContext
    }) ?? [];

    const orderProps = itemsGroup?.lineItems?.order ? {
        order: itemsGroup.lineItems.order,
        // index from 1 as "zero" means "put it at the end", at least for minor asset BE
        index: (currentItems?.length ?? 0) + 1
    } : {};

    const newItemsMax = getNewItemsMaxId(currentItems);

    let newItem = addEmptyLineItem<T>({
        storage,
        bindingContext: fieldBc,
        newItemsCount: newItemsMax + 1,
        columns: columns ?? itemsGroup.lineItems.columns,
        context: storage.context,
        ...orderProps
    });

    if (defaultData) {
        newItem = {
            ...newItem,
            ...defaultData
        };
    }

    storage.store({
        entity: setBoundValue({
            bindingContext: fieldBc,
            data: entity,
            newValue: [...currentItems, newItem],
            dataBindingContext: bindingContext,
            preventCloning: true
        })
    });

    return newItem;
}

export function addEmptyLineItem<T = IEntity>(args: IAddNewLineItemArgs): Partial<T> {
    let newItem = BindingContext.createNewEntity(args.newItemsCount);
    const parentBc = args.bindingContext.addKey(newItem);
    for (const column of args.columns) {
        const columnBc = parentBc.navigate(column.id);
        const info = args.storage.getInfo(columnBc);

        if (info && info.defaultValue) {
            const defVal = getInfoValue(info, "defaultValue", {
                data: args.storage.data.entity,
                storage: args.storage,
                info: info,
                bindingContext: info.bindingContext,
                context: args.context
            });

            newItem = updateDataOnChange({
                storage: args.storage,
                bindingContext: columnBc,
                dataBindingContext: parentBc,
                data: newItem,
                newValue: defVal
            }, true);
        }
    }

    if (args.order && args.order !== "Id") {
        newItem[args.order] = args.index;
    }

    return newItem as Partial<T>;
}
