import styled, { css } from "styled-components/macro";
import { transparentize } from "polished";
import { getFocusBorderElement, T_PLAIN_big, T_PLAIN_big_hig } from "../../global.style";
import { ButtonGroup, IconButton } from "../button";
import Clickable from "../clickable/Clickable";
import { StyledFieldsWrapper } from "../inputs/field/FieldsWrapper.styles";
import FieldGroup from "../fieldGroup";
import { StyledLabel } from "../inputs/field/Label.styles";

export const FilterBarWrapper = styled.div<{
    expanded: boolean
}>`
    width: 100%;
    min-width: fit-content;
    position: relative;
    display: flex;
    flex-shrink: 0;
    align-items: ${props => props.expanded ? "" : "center"};
    flex-direction: column;

    ${StyledLabel} {
        color: ${props => props.theme.C_TEXT_purple_bg};
    }
`;

export const FilterBarCollapsedWrapper = styled.div`
    display: flex;
    //align-items: center;
    align-self: baseline;
`;

export const ExpandIconWrapper = styled(IconButton)<{
    _opacity: number,
    _index: number,
    expanded: boolean,
    changing: boolean,
    animateButton: boolean
}>`
    background-color: ${props => transparentize(1 - props._opacity, props.theme.C_BG_filterbar)};
    border-radius: 50% 50% 0 0;
    border-radius: ${props => props.expanded || props.changing ? "" : "50%"};
    transition: ${props => !props.expanded && props.animateButton ? "border-radius 0.1s;" : ""};
    margin-left: ${props => props._index !== 0 ? "11px" : 0};

    &:before {
        z-index: 1;
    }

    ${getFocusBorderElement({
        offset: -2,
        borderRadius: "50%",
        isLight: true
    })};
`;


export const FilterReadOnlyBody = styled.div`
    ${T_PLAIN_big};
    position: relative;
    margin-left: 18px;
`;

export const ControlButtonsGroup = styled(ButtonGroup)`
    position: absolute;
    right: 0;
    top: -6px;
`;

export const FilterBarCloseIconWrapper = styled.div`
    position: absolute;
    right: 6px;
    top: 5px;
`;

export const ReadOnlyGroups = styled.div`
    position: relative;
`;

const getLineHeight = (fontSize: number) => {
    // readOnlyGroup line height in pixels
    return 1.2 * fontSize;
};

export const StyledReadOnlyGroup = styled.div<{
    _maxRows: number;
    fontSize: number;
}>`
    position: relative;
    margin-top: 11px;
    ${T_PLAIN_big};
    line-height: 1.2;
    font-size: ${props => `${props.fontSize}px`};
    // normal line height * font-size * numRows
    ${props => props._maxRows && css`max-height: ${props._maxRows * getLineHeight(props.fontSize)}px`};
    overflow: hidden;

    & + & {
        margin-top: ${props => getLineHeight(props.fontSize)}px;
    }

    &.empty {
        display: none;
    }

    :not(.empty) + &.empty {
        display: block;
        margin-top: ${props => getLineHeight(props.fontSize) / 2}px;
    }
`;

export const ReadOnlyGroupToggle = styled.span`
    display: inline-block;
    bottom: 0;
    right: 0;
    background: ${props => `linear-gradient(to right, transparent 0, ${props.theme.C_BG_floorplan} 1em)`};
`;

export const GroupItem = styled.div<{ groupWithPrevious: boolean }>`
    display: flex;
    align-items: center;

    ${props => props.groupWithPrevious && css`
        & > * + * {
            margin-left: -33px;
        }
    `}
`;

export const CollapseWrapper = styled.div`
    width: 100%;
    position: relative;
    background-color: ${props => props.theme.C_BG_floorplan};
    top: -4px;
    // TODO doesn't work in storybook without this explicit style
    &.collapse:not(.show) {
        height: 0;
        overflow: hidden;
    }
`;

export const EmptyFieldGroup = styled(FieldGroup)`
    ${StyledFieldsWrapper} {
        width: 100%;
    }
`;

export const NoDataWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100px;
    color: ${props => props.theme.C_BTN_4L_emph_text};

    ${T_PLAIN_big_hig};
`;

export const ClickableStyled = styled(Clickable)`
    padding-left: 5px;
`;
