import { FormViewForExtend, IFormViewProps } from "../../views/formView/FormView";
import { CustomerPortalPaymentEntity, ICustomerPortalPaymentEntity } from "@odata/GeneratedEntityTypes";
import { WithAlert } from "@components/alert/withAlert";
import { withPermissionContext } from "../../contexts/permissionContext/withPermissionContext";
import { ISmartFieldChange } from "@components/smart/smartField/SmartField";
import React from "react";

interface IProps extends IFormViewProps<ICustomerPortalPaymentEntity>, WithAlert {
}

class CustomerPortalPaymentFormView extends FormViewForExtend<ICustomerPortalPaymentEntity, IProps> {
    static defaultProps = {
        hideButtons: true,
        formProps: {
            shouldHideAuditTrail: true,
            shouldHideVariant: true
        }
    };

    handleChange(e: ISmartFieldChange) {
        super.handleChange(e);

        if (e.bindingContext.getPath() === CustomerPortalPaymentEntity.IsPaid) {
            this.save();
        }
    }
}

export default withPermissionContext(CustomerPortalPaymentFormView);