import { IAlertProps } from "@components/alert/Alert";
import { IInboxFileEntity, IUserEntity } from "@odata/GeneratedEntityTypes";
import { ApprovalStatusTypeCode } from "@odata/GeneratedEnums";
import { isDefined } from "@utils/general";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../../components/button";
import Chat from "../../components/chat/Chat";
import Message from "../../components/chat/Message";
import { AppContext } from "../../contexts/appContext/AppContext.types";
import { Status } from "../../enums";
import { isAgendaCustomer } from "../admin/users/Users.utils";
import { getMessageIcon } from "../tickets/Tickets.utils";
import EmailBodyMessage from "./EmailBodyMessage";
import { getApprovalStatus } from "./Inbox.utils";


interface IProps {
    isAgendaCustomer?: boolean;
    file: IInboxFileEntity;
    maxHeight?: number;
    showEmailText?: boolean;
    onCreateMessage?: (message: string, status: ApprovalStatusTypeCode) => Promise<boolean>;
}

function getName(user: IUserEntity) {
    return user.Name ?? [user.FirstName, user.LastName].filter(isDefined).join(' ');
}

const InboxChat: React.FC<IProps> = ({
                                         file,
                                         maxHeight,
                                         onCreateMessage,
                                         isAgendaCustomer: _isAgendaCustomer,
                                         showEmailText
                                     }) => {
    const [newMessage, setNewMessage] = useState<string>("");
    const [busy, setBusy] = useState<boolean>(false);
    const { t, ready: tReady } = useTranslation("Inbox");

    const context = useContext(AppContext);

    const handleSendMessage = useCallback(async (status?: ApprovalStatusTypeCode) => {
        setBusy(true);
        const success = await onCreateMessage(newMessage, status);
        if (success) {
            setNewMessage("");
        }
        setBusy(false);
    }, [onCreateMessage, newMessage]);

    const handleAskForApproval = useCallback(() => {
        handleSendMessage(ApprovalStatusTypeCode.Pending);
    }, [handleSendMessage]);

    const handleReject = useCallback(() => {
        handleSendMessage(ApprovalStatusTypeCode.Rejected);
    }, [handleSendMessage]);

    const handleApprove = useCallback(async () => {
        handleSendMessage(ApprovalStatusTypeCode.Approved);
    }, [handleSendMessage]);

    const hoverAlert = useMemo((): IAlertProps => ({
        status: Status.Warning,
        title: t("Inbox:Chat.RejectReason"),
        isSmall: true
    }), [t, tReady]);

    const status = getApprovalStatus(file);
    let customButtons;
    switch (status) {
        case ApprovalStatusTypeCode.Pending:
            customButtons = _isAgendaCustomer && (<>
                <Button onClick={handleReject}
                        status={Status.Error}
                        hoverAlert={newMessage ? null : hoverAlert}
                        isDisabled={!newMessage || busy}>{t("Inbox:Chat.Reject")}</Button>
                <Button onClick={handleApprove}
                        status={Status.Success}
                        isDisabled={busy}>{t("Inbox:Chat.Approve")}</Button>
            </>);
            break;
        case ApprovalStatusTypeCode.Rejected:
            customButtons = !_isAgendaCustomer && (
                    <Button onClick={handleAskForApproval}
                            status={Status.Success}
                            isDisabled={!newMessage || busy}>{t("Inbox:Chat.AskForApproval")}</Button>
            );
            break;
    }

    const hideNewMessage = !customButtons;

    return (
            <Chat currentValue={newMessage}
                  onChange={setNewMessage}
                  onSendMessage={handleSendMessage}
                  maxHeight={maxHeight}
                  isDisabled={busy}
                  hideNewMessage={hideNewMessage}
                  customButtons={customButtons}>
                {showEmailText && <EmailBodyMessage text={file.Body} created={file.FileMetadata?.DateCreated} createdBy={file.Sender} />}
                {file.Communications?.map((message, idx) => {
                    const _isAgendaMessage = isAgendaCustomer(context, message.CreatedBy);
                    return (
                            <Message key={message.Id}
                                     icon={getMessageIcon(context, _isAgendaMessage)}
                                     text={message.Body}
                                     hasIconBorder={true}
                                     hasSolidBorder={idx < file.Communications.length - 1}
                                     created={message.DateCreated}
                                     createdBy={getName(message.CreatedBy)}/>
                    );
                })}
            </Chat>
    );
};

export default InboxChat;
