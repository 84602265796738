import React from "react";
import {
    CollapsibleBody,
    IconButtonStyledRemove,
    IconButtonStyledToggle,
    StyledCollapsibleSection,
    Title,
    TopWrapper
} from "./CollapsibleSection.styles";
import TestIds from "../../testIds";
import { BinIcon, CaretIcon } from "../icon";
import { IconSize } from "../../enums";
import { withTranslation, WithTranslation } from "react-i18next";

export enum CollapsibleSectionTitleLook {
    // default, purpleish
    Colored = "Colored",
    // looks like any other text (black)
    Basic = "Basic"
}

interface IProps extends WithTranslation {
    title?: string | React.ReactElement;
    titleLook?: CollapsibleSectionTitleLook;
    defaultIsOpen?: boolean;
    onToggle?: (isExpanded: boolean) => void;
    isExpanded?: boolean;
    removeBodyPadding?: boolean;
    // if used inside FieldsWrapper
    usedAsField?: boolean;
    testid?: string;
    className?: string;
    style?: React.CSSProperties;
    onRemove?: () => void;
    header?: React.ReactElement;
    // this can be used to render children outside the collapsible section,
    // this is useful when you want to have proper height on the children
    // to use scroll bar
    renderChildrenOutside?: boolean;
}

interface IState {
    isOpen: boolean;
}

class CollapsibleSection extends React.PureComponent<IProps, IState> {
    static defaultProps = {
        defaultIsOpen: true,
        titleLook: CollapsibleSectionTitleLook.Colored,
    };

    constructor(props: IProps) {
        super(props);

        this.state = {
            isOpen: !!props.defaultIsOpen
        };
    }

    get isControlled() {
        return !!this.props.onToggle;
    }

    get isOpen() {
        return this.isControlled ? this.props.isExpanded : this.state.isOpen;
    }

    handleToggle = () => {
        if (this.isControlled) {
            this.props.onToggle(this.isOpen);
        } else {
            this.setState({ isOpen: !this.state.isOpen });
        }
    };

    render() {
        return (
            <>
                <StyledCollapsibleSection data-testid={this.props.testid ?? TestIds.CollapsibleSection}
                                          _isToggled={this.isOpen}
                                          usedAsField={this.props.usedAsField}
                                          className={this.props.className}
                                          style={this.props.style}>
                    <TopWrapper _isToggled={this.isOpen}>
                        <IconButtonStyledToggle
                            title={this.props.t(`Components:CollapsibleSection.${this.isOpen ? "Hide" : "Show"}`)}
                            isDecorative
                            onClick={this.handleToggle}
                            _isToggled={this.isOpen}>
                        <CaretIcon width={IconSize.M} height={IconSize.M}/>
                    </IconButtonStyledToggle>
                    {this.props.title &&
                    <Title look={this.props.titleLook}
                           onClick={this.handleToggle}
                           data-testid={TestIds.Title}>
                        {this.props.title}
                    </Title>
                    }
                        {this.props.header}
                        {this.props.onRemove && (
                            <IconButtonStyledRemove
                                title={this.props.t("Components:CollapsibleSection.Remove")}
                                onClick={this.props.onRemove}
                                isDecorative>
                                <BinIcon width={IconSize.M}/>
                            </IconButtonStyledRemove>
                        )}
                    </TopWrapper>
                    {!this.props.renderChildrenOutside && this.isOpen &&
                        <CollapsibleBody data-testid={TestIds.CollapsibleSectionBody}
                                         hasPadding={!this.props.removeBodyPadding}>
                            {this.props.children}
                        </CollapsibleBody>}
                </StyledCollapsibleSection>
                {this.props.renderChildrenOutside && this.isOpen && this.props.children}
            </>
        );
    }
}

export default withTranslation(["Components"])(CollapsibleSection);