import {
    EntitySetName,
    GovernmentInstitutionBankAccountEntity,
    GovernmentInstitutionEntity,
    IGovernmentInstitutionBankAccountEntity,
    IGovernmentInstitutionEntity,
    PrGovernmentInstitutionBankAccountDetailEntity,
    PrGovernmentInstitutionDetailEntity,
    PrPayrollSettingEntity
} from "@odata/GeneratedEntityTypes";
import BindingContext, { createPath } from "../../../odata/BindingContext";
import { TFieldDefinition, TFieldsDefinition } from "../../PageUtils";
import {
    countryDef,
    PhoneNumberDef,
    SymbolConstantDef,
    SymbolSpecificDef,
    SymbolVariableDef
} from "@components/smart/GeneralFieldDefinition";
import { BasicInputSizes, CacheStrategy, FieldType, ValidatorType } from "../../../enums";
import { GovernmentInstitutionTypeCode } from "@odata/GeneratedEnums";
import { logger } from "@utils/log";
import { FormStorage } from "../../../views/formView/FormStorage";
import React from "react";
import { BankAccountsFastEntry } from "./BankAccountsFastEntry";
import { IFieldDefFn } from "@components/smart/FieldInfo";
import i18next from "i18next";
import { capitalize } from "@utils/string";
import { TValue } from "../../../global.types";

export const GOVERNMENT_INSTITUTIONS = [PrPayrollSettingEntity.FinancialAdministration, PrPayrollSettingEntity.CzechSocialSecurityAdministration, PrPayrollSettingEntity.LabourOffice, PrPayrollSettingEntity.StatutoryInsuranceCompany];

type TGovernmentInstitution = typeof GOVERNMENT_INSTITUTIONS[number];

export const BankAccountsFastEntryPath = BindingContext.localContext("governmentFastEntry");

const getTypeCodeFromInstitutionProp = (institution: TGovernmentInstitution): GovernmentInstitutionTypeCode => {
    switch (institution) {
        case PrPayrollSettingEntity.FinancialAdministration:
            return GovernmentInstitutionTypeCode.FinancialAdministration;
        case PrPayrollSettingEntity.CzechSocialSecurityAdministration:
            return GovernmentInstitutionTypeCode.CzechSocialSecurityAdministration;
        case PrPayrollSettingEntity.LabourOffice:
            return GovernmentInstitutionTypeCode.LabourOffice;
        case PrPayrollSettingEntity.StatutoryInsuranceCompany:
            return GovernmentInstitutionTypeCode.StatutoryInsuranceCompany;
        default:
            logger.error(`unknown institution navigation path: ${institution}`);
            return null;
    }
};

export function formatGovernmentInstitutionName(governmentInstitution: IGovernmentInstitutionEntity): string {
    return governmentInstitution?.ShortName?.replace("ÚP ", "") ?? "";
}

export function formatGovernmentInstitutionUfoName(name: string): string {
    let formattedStr = name?.replace("Finanční úřad pro ", "");
    formattedStr = formattedStr?.replace("Prahu", "Praha").trim();

    return capitalize(formattedStr);
}

export function getFinancialAdministrationDef(): TFieldDefinition {
    return {
        type: FieldType.ComboBox,
        cacheStrategy: CacheStrategy.EndOfTime,
        filter: {
            select: `${GovernmentInstitutionEntity.GovernmentInstitutionTypeCode} eq '${GovernmentInstitutionTypeCode.FinancialAdministration}'`
        },
        formatter: (val: TValue, args) => {
            return formatGovernmentInstitutionName(args.item);
        },
        fieldSettings: {
            displayName: "ShortName",
            shouldDisplayAdditionalColumns: true,
            preloadItems: true
        },
        columns: [{
            id: "ShortName",
            formatter: (val: TValue, args) => formatGovernmentInstitutionName(args.item)
        }, {
            id: "UfoName",
            formatter: (val: TValue, args) => formatGovernmentInstitutionUfoName(val as string)
        }]
    };
}

export const getContactsRowsDef = (): TFieldsDefinition => {
    const def = {} as TFieldsDefinition;
    for (const institution of GOVERNMENT_INSTITUTIONS) {
        def[createPath(institution, PrGovernmentInstitutionDetailEntity.ContactName)] = {
            width: BasicInputSizes.L
        };
        def[createPath(institution, PrGovernmentInstitutionDetailEntity.ContactEmail)] = {
            width: BasicInputSizes.XL,
            validator: {
                type: ValidatorType.Email
            }
        };
        def[createPath(institution, PrGovernmentInstitutionDetailEntity.ContactPhone)] = {
            ...PhoneNumberDef
        };
        const defaultFieldDef = institution === PrPayrollSettingEntity.FinancialAdministration ? getFinancialAdministrationDef() : {};
        def[createPath(institution, PrGovernmentInstitutionDetailEntity.GovernmentInstitution)] = {
            type: FieldType.ComboBox,
            cacheStrategy: CacheStrategy.EndOfTime,
            columns: [{ id: GovernmentInstitutionEntity.ShortName }, { id: GovernmentInstitutionEntity.Name }],
            ...defaultFieldDef,
            width: BasicInputSizes.XL,
            fieldSettings: {
                ...defaultFieldDef.fieldSettings,
                entitySet: EntitySetName.GovernmentInstitutions,
                displayName: GovernmentInstitutionEntity.ShortName,
                shouldDisplayAdditionalColumns: true,
                showTabularHeader: false,
                preloadItems: true,
                localDependentFields: [{
                    from: { id: GovernmentInstitutionEntity.Street },
                    to: {
                        id: createPath(institution, PrGovernmentInstitutionDetailEntity.GovernmentInstitution, GovernmentInstitutionEntity.Street)
                    }
                }, {
                    from: { id: GovernmentInstitutionEntity.City },
                    to: {
                        id: createPath(institution, PrGovernmentInstitutionDetailEntity.GovernmentInstitution, GovernmentInstitutionEntity.City)
                    }
                }, {
                    from: { id: GovernmentInstitutionEntity.PostalCode },
                    to: {
                        id: createPath(institution, PrGovernmentInstitutionDetailEntity.GovernmentInstitution, GovernmentInstitutionEntity.PostalCode)
                    }
                }, {
                    from: { id: GovernmentInstitutionEntity.Country },
                    to: {
                        id: createPath(institution, PrGovernmentInstitutionDetailEntity.GovernmentInstitution, GovernmentInstitutionEntity.Country)
                    }
                }, {
                    from: { id: GovernmentInstitutionEntity.DataBoxId },
                    to: {
                        id: createPath(institution, PrGovernmentInstitutionDetailEntity.GovernmentInstitution, GovernmentInstitutionEntity.DataBoxId)
                    }
                }],
                additionalProperties: [{
                    id: GovernmentInstitutionEntity.Street
                }, {
                    id: GovernmentInstitutionEntity.City
                }, {
                    id: GovernmentInstitutionEntity.PostalCode
                }, {
                    id: GovernmentInstitutionEntity.Country
                }, {
                    id: GovernmentInstitutionEntity.DataBoxId
                }, {
                    id: GovernmentInstitutionEntity.BankAccounts,
                    additionalProperties: [{
                        id: GovernmentInstitutionBankAccountEntity.BankCode
                    }, {
                        id: GovernmentInstitutionBankAccountEntity.AccountNumber
                    }, {
                        id: GovernmentInstitutionBankAccountEntity.AccountPrefix
                    }, {
                        id: GovernmentInstitutionBankAccountEntity.IBAN
                    }, {
                        id: GovernmentInstitutionBankAccountEntity.Name
                    }]
                }]
            },
            filter: {
                select: `${GovernmentInstitutionEntity.GovernmentInstitutionTypeCode} eq '${getTypeCodeFromInstitutionProp(institution)}'`
            }
        };
        def[createPath(institution, PrGovernmentInstitutionDetailEntity.GovernmentInstitution, GovernmentInstitutionEntity.Street)] = {
            isReadOnly: true,
        };
        def[createPath(institution, PrGovernmentInstitutionDetailEntity.GovernmentInstitution, GovernmentInstitutionEntity.City)] = {
            isReadOnly: true
        };
        def[createPath(institution, PrGovernmentInstitutionDetailEntity.GovernmentInstitution, GovernmentInstitutionEntity.PostalCode)] = {
            isReadOnly: true
        };
        def[createPath(institution, PrGovernmentInstitutionDetailEntity.GovernmentInstitution, GovernmentInstitutionEntity.Country)] = {
            ...countryDef,
            isReadOnly: true,
            defaultValue: null
        };
        def[createPath(institution, PrGovernmentInstitutionDetailEntity.GovernmentInstitution, GovernmentInstitutionEntity.DataBoxId)] = {
            isReadOnly: true,
        };
        def[createPath(institution, PrGovernmentInstitutionDetailEntity.BankAccountDetails, PrGovernmentInstitutionBankAccountDetailEntity.GovernmentInstitutionBankAccount, GovernmentInstitutionBankAccountEntity.Name)] = {
            label: i18next.t("GovernmentInstitution:Payment"),
            isReadOnly: true,
            isRequired: false
        };
        def[createPath(institution, PrGovernmentInstitutionDetailEntity.BankAccountDetails, PrGovernmentInstitutionBankAccountDetailEntity.GovernmentInstitutionBankAccount, GovernmentInstitutionBankAccountEntity.AccountNumber)] = {
            isReadOnly: true,
            formatter: (value, args) => {
                const bankAccount = args.storage.getValue(args.bindingContext.getParent()) as IGovernmentInstitutionBankAccountEntity;
                return `${bankAccount?.AccountPrefix ? bankAccount.AccountPrefix + "-" : ""}${value}`;
            }
        };
        def[createPath(institution, PrGovernmentInstitutionDetailEntity.BankAccountDetails, PrGovernmentInstitutionBankAccountDetailEntity.GovernmentInstitutionBankAccount, GovernmentInstitutionBankAccountEntity.BankCode)] = {
            isReadOnly: true,
        };
        def[createPath(institution, PrGovernmentInstitutionDetailEntity.BankAccountDetails, PrGovernmentInstitutionBankAccountDetailEntity.SymbolVariable)] = {
            ...SymbolVariableDef
        };
        def[createPath(institution, PrGovernmentInstitutionDetailEntity.BankAccountDetails, PrGovernmentInstitutionBankAccountDetailEntity.SymbolSpecific)] = {
            ...SymbolSpecificDef
        };
        def[createPath(institution, PrGovernmentInstitutionDetailEntity.BankAccountDetails, PrGovernmentInstitutionBankAccountDetailEntity.SymbolConstant)] = {
            ...SymbolConstantDef
        };
        def[createPath(institution, PrGovernmentInstitutionDetailEntity.BankAccountDetails, PrGovernmentInstitutionBankAccountDetailEntity.RemittanceInformation)] = {};
        def[createPath(institution, BankAccountsFastEntryPath)] = {
            type: FieldType.Custom,
            render: (args: IFieldDefFn) => {
                return <BankAccountsFastEntry
                        groupId="BankAccounts"
                        institution={institution}
                        storage={args.storage as FormStorage}
                        isReadOnly={args.props.isReadOnly}
                />;
            }
        };
    }

    return def;
};
