import React from "react";
import { formatTimeVariants, longDateFormat } from "./utils";
import DateType, { getDateFormat, getUtcDayjs } from "../../../types/Date";
import { withFormatter } from "../input/WithFormatter";
import { IInputProps, InputWithErrorAndTooltip } from "../input";
import { WithErrorAndTooltipProps } from "../formError/WithErrorAndTooltip";
import { BasicInputSizes, TextAlign } from "../../../enums";
import { Stepper } from "../numericValueInputBase/Stepper";
import { isDefined, isNotDefined, mouseDownRepeat } from "@utils/general";
import { KeyName } from "../../../keyName";

const getFormat = (): string => {
    return longDateFormat(getDateFormat(DateType.defaultTimeFormat));
};

export const TimeInput = withFormatter<IInputProps & WithErrorAndTooltipProps, Date>({
    parser: (value: string, strict: boolean): Date => {
        return DateType.parse({
            date: value?.padStart(4, "0"),
            format: formatTimeVariants(longDateFormat(getDateFormat(DateType.defaultTimeFormat))),
            strictMode: strict
        });
    },
    formatter: (value: Date): string => {
        return DateType.format(value, getFormat());
    },
    isValid: (value: Date): boolean => {
        return DateType.isValid(value);
    },
    isSame: (date1: Date, date2: Date): boolean => {
        if (date1 instanceof Date) {
            return date1.getMinutes() === date2?.getMinutes() && date1.getHours() === date2?.getHours();
        }

        return date1 === date2;
    }
}, InputWithErrorAndTooltip);


interface IProps extends IInputProps<Date>, WithErrorAndTooltipProps {
    min?: Date;
    max?: Date;
    showSteppers?: boolean;
}

export class TimePicker extends React.PureComponent<IProps> {
    handleNextMouseDown = (): void => {
        mouseDownRepeat(this.increment);
    };

    handlePrevMouseDown = (): void => {
        mouseDownRepeat(this.decrement);
    };

    handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
        if (event.key === KeyName.ArrowDown) {
            event.preventDefault();
            this.decrement();
        } else if (event.key === KeyName.ArrowUp) {
            event.preventDefault();
            this.increment();
        }

        this.props.onKeyDown?.(event);
    };


    increment = (): void => {
        this.updateValue(getUtcDayjs(this.props.value).add(1, "minute").toDate());
    };

    decrement = (): void => {
        this.updateValue(getUtcDayjs(this.props.value).subtract(1, "minute").toDate());
    };

    canIncrement = (): boolean => {
        if (!DateType.isValid(this.props.value)) {
            return false;
        }

        const date = getUtcDayjs(this.props.value);

        return isNotDefined(this.props.max) || date.isBefore(this.props.max);
    };

    canDecrement = (): boolean => {
        if (!DateType.isValid(this.props.value)) {
            return false;
        }

        const date = getUtcDayjs(this.props.value);

        return isNotDefined(this.props.max) || date.isAfter(this.props.min);
    };

    updateValue = (value: Date): void => {
        let newVal = getUtcDayjs(value);

        if (isDefined(this.props.max) && newVal.isAfter(this.props.max)) {
            newVal = getUtcDayjs(this.props.max);
        }

        if (isDefined(this.props.min) && newVal.isBefore(this.props.min)) {
            newVal = getUtcDayjs(this.props.min);
        }

        // always focus the input before changing the value, so validations and _wasChangedSinceBlur flag works correctly
        // this.inputRef.current?.focus();

        this.props.onChange?.({
            value: newVal.toDate(),
            triggerAdditionalTasks: true
        });
    };

    renderSteppers = (): React.ReactElement => {
        return (
                <Stepper
                        isLight={this.props.isLight}
                        isDisabled={this.props.isDisabled}
                        onNextMouseDown={this.handleNextMouseDown}
                        onPrevMouseDown={this.handlePrevMouseDown}
                        isNextDisabled={!this.canIncrement()}
                        isPrevDisabled={!this.canDecrement()}
                />
        );
    };

    render() {
        return (
                <TimeInput {...this.props}
                           value={this.props.value}
                           textAlign={TextAlign.Right}
                           width={this.props.width ?? BasicInputSizes.S}
                           content={this.props.showSteppers ? this.renderSteppers() : null}
                           onKeyDown={this.handleKeyDown}
                           placeholder={getFormat()?.toLowerCase()}/>
        );
    }
}