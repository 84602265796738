import styled from "styled-components/macro";
import { T_TITLE_2 } from "../../global.style";

export const StyledErrorPage = styled.div`
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transform: translateY(-120px);
    align-items: center;
    & > div {
        width: 400px;
        height: 400px;
    }
    & > h2 {
        ${T_TITLE_2};
        color: ${props => props.theme.C_ACT_thick_line};
        white-space: pre-wrap;
        text-align: center;
    }
`;