import styled from "styled-components/macro";

export const ImageWrapper = styled.div`
    flex: 1 1 0;
    height: 0;
    width: 100%;
    overflow: hidden;
`;

export const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
`;