export enum DeductionType {
    CzDisBasic2024 = "CzDisBasic2024", // Základní sleva na invaliditu
    CzDisExtended2024 = "CzDisExtended2024", // Rozšířená sleva na invaliditu
    CzDisZtpP2024 = "CzDisZtpP2024", // Sleva na držitele průkazu ZTP/P
    CzPayer2024 = "CzPayer2024" // Sleva na poplatníka
}

export interface IDeductionTypePaths {
    switchPath: string;
    dateValidFrom: string;
    dateValidTo: string;
}