// todo better error interface, mby rename to BackendError - custom endpoints returns this error as well
import { BackendErrorCode } from "../enums";

export class ODataInnerError {
    _type: string;
    _message: string;
    _stackTrace: string | string[];

    constructor(type: string, message: string, stackTrace: string | string[]) {
        this._type = type;
        this._message = message;
        this._stackTrace = stackTrace;
    }
}

export type ODataPropertyValue = string | number | boolean | Date;

export interface IEntityReference {
    entityType: string;
    id?: number;
    temporaryGuid?: string;
}

export interface IValidationMessage {
    code: string;
    entity: IEntityReference;
    message: string;
    messageParameters?: Record<string, string | number>;
    property?: string;
}

export function isODataError(error: unknown): error is ODataError {
    return error instanceof ODataError;
}

export class ODataError {
    _code: BackendErrorCode;
    _message: string;
    _innerError: ODataInnerError;
    _validationMessages: IValidationMessage[];

    constructor(code: BackendErrorCode, message: string, innerError?: ODataInnerError, validationMessages?: IValidationMessage[]) {
        this._code = code;
        this._message = message;
        this._innerError = innerError;
        this._validationMessages = validationMessages;
    }

    toString(): string {
        return `ODataError: ${this._code} - ${this._message}`;
    }
}