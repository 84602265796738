import styled from "styled-components/macro";
import { transparentize } from "polished";
import { T_BOLD_tiny } from "../../global.style";

export const StyledProgressWheel = styled.div<{ isSemantic?: boolean; }>`
    width: 25px;
    height: 25px;
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    & > svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        // move the start of the stroke to 12 o'clock
        transform: rotate(270deg);
        shape-rendering: geometricPrecision;
    }

    & #fillCircleBase {
        fill: none;
        // transition causes weird flickers every second change, dunno why
        //transition: stroke-dashoffset 0.7s ease-in-out;
    }

    & #fakeCircle {
        width: 100%;
        height: 100%;
        background-image: ${props => props.isSemantic ? props.theme.C_BG_progresswheel_semantic : props.theme.C_BG_progresswheel};
    }

    & .borderCircle {
        fill: none;
        stroke: ${props => transparentize(0.5, props.theme.C_ACT_main)};
    }

    & #nonFiledMarks {
        fill: none;
        stroke: ${props => transparentize(0.5, props.theme.C_ACT_main)};
    }

    & #filledMarks {
        fill: none;
        stroke: ${props => props.theme.C_ACT_light_line};
    }
`;

export const ProgressWheelCount = styled.div`
    position: relative;
    // there is some weird sub pixel inaccuracy when placed inside ProgressBanner
    // => shift half pixel down
    top: 0.5px;

    ${T_BOLD_tiny};
`;