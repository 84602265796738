import React from "react";
import { BasicSelect, IProps as ISelectProps } from "../../inputs/select/BasicSelect";
import { CaretIcon } from "../../icon";
import { IconSize } from "../../../enums";
import SmartSelectBase, { ISmartSelectBaseProps } from "./SmartSelectBase";

export interface ISmartSelectProps extends ISmartSelectBaseProps, Omit<ISelectProps, "onClick" | "onIconActivate" | "customTabularData" | "trailingTextWithoutFocus"> {
}

const icon = <CaretIcon width={IconSize.M}/>;

class SmartSelect extends React.Component<ISmartSelectProps> {

    render() {
        const passSelectProps = {
            closeOnSelection: true,
            openOnClick: true,
            inputIcon: this.props.inputIcon ?? icon
        };

        return (
            <SmartSelectBase
                select={BasicSelect}
                selectProps={passSelectProps}
                {...this.props}
            />
        );
    }
}

export { SmartSelect };