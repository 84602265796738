import { ButtonSize } from "@components/button/Button.utils";
import { IInputOnChangeEvent } from "@components/inputs/input";
import i18next from "i18next";
import { isEmpty } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../../components/button";
import PasswordWriteLine from "../../components/inputs/writeLine/PasswordWriteLine";
import WriteLine from "../../components/inputs/writeLine/WriteLine";
import { ROUTE_LOGIN_RESET_PASS_REQUEST } from "../../routes";
import TestIds from "../../testIds";
import { AnimationPlayStatus, LoginTranslationNamespaces, RequestStatus } from "../Login.utils";
import { LoginFormStyled, LostPass } from "../LoginPage.styles";
import { useLoginDispatch, useLoginSelector } from "../state/hooks";
import { selectAnimationStatus, selectLoginEmail, setLoginEmail } from "../state/loginSlice";
import {
    clearError,
    loadSessionFailure,
    login,
    selectSessionError,
    selectSessionRequestStatus
} from "../state/sessionSlice";
import LoginButtonGroup from "./LoginButtonGroup";
import LoginField from "./LoginField";

interface IProps {
}

const LoginForm: React.FC<IProps> = ({ children }) => {

    const { t } = useTranslation([...LoginTranslationNamespaces]);

    const dispatch = useLoginDispatch();
    const error = useLoginSelector(selectSessionError);
    const status = useLoginSelector(selectSessionRequestStatus);
    const animationStatus = useLoginSelector(selectAnimationStatus);
    const email = useLoginSelector(selectLoginEmail);

    const [password, setPassword] = useState<string>("");

    const handleEmailChanged = useCallback((args: IInputOnChangeEvent<string>): void => {
        dispatch(setLoginEmail(args.value));
        dispatch(clearError());
    }, [dispatch]);

    const handlePasswordChange = useCallback((args: IInputOnChangeEvent<string>): void => {
        setPassword(args.value);
        dispatch(clearError());
    }, [dispatch]);

    const handleLoginPressed = useCallback(() => {
        if (isEmpty(email)) {
            // don't send unnecessary request when no email is filled
            dispatch(loadSessionFailure(i18next.t("Login:Login.WrongCredentials")));
        } else {
            dispatch(login(email, password));
        }
    }, [dispatch, email, password]);

    useEffect(() => {
        // clear error on route mount
        dispatch(clearError());
    }, [dispatch]);

    const isBusy = status === RequestStatus.Pending || animationStatus === AnimationPlayStatus.Playing;

    return (
        <LoginFormStyled>
            <LoginField name={"username"} label={t("Login:Login.Email")}>
                <WriteLine error={error}
                           isExtending
                           value={email}
                           autocomplete={"username"}
                           onChange={handleEmailChanged}/>
            </LoginField>

            <LoginField name={"password"}
                        label={t("Login:Login.Password")}
                        afterContent={(
                            <LostPass testId={TestIds.LostPass} link={ROUTE_LOGIN_RESET_PASS_REQUEST}>
                                {t("Login:Login.ForgottenPass")}
                            </LostPass>
                        )}>
                <PasswordWriteLine
                    autocomplete={"current-password"}
                    error={error}
                    isExtending
                    value={password}
                    onChange={handlePasswordChange}/>
            </LoginField>

            <LoginButtonGroup additionalText={children}>
                <Button size={ButtonSize.Big}
                    // enable form submitting by enter press
                        type={"submit"}
                        isDisabled={isBusy}
                        onClick={handleLoginPressed}>{t("Login:Login.SubmitLogin")}</Button>
            </LoginButtonGroup>
        </LoginFormStyled>
    );
};

export default LoginForm;