// todo create enum for all the tables and forms, not only reports
// used as definition.tableId and as key in LocalSettings
export enum ReportId {
    AccountingJournal = "AccountingJournalReport",
    DocumentJournal = "DocumentJournalReport",
    PaymentJournal = "PaymentJournalReport",
    AgingAP = "AgingAPReport",
    AgingAR = "AgingARReport",
    BalanceSheet = "BalanceSheetReport",
    GeneralLedger = "GeneralLedgerReport",
    IncomeStatement = "IncomeStatementReport",
    Saldo = "SaldoReport",
    ReceivableAdjustments = "ReceivableAdjustmentsReport",
    AccountAnalysis = "AccountAnalysisReport",
    AssetAnalysis = "AssetAnalysisReport",
    ExchangeRate = "ExchangeRateReport",
    CbaIncomeExpense = "CbaIncomeExpenseReport",
    CbaEntryLedger = "CbaEntryLedgerReport",
    VatStatement = "VatStatementReport",
    VatControlStatement = "VatControlStatementReport",
    VatVIESStatement = "VatVIESStatementReport"
}