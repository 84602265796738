import styled, { css } from "styled-components/macro";
import { IconButton } from "../button";
import { hoverColors, MoreOptionsIcon } from "../icon";
import { LocalStackOrder } from "../../global.style";

export const BASIC_HEIGHT = 100;
export const MIN_HEIGHT_FOR_COLLAPSIBLE = BASIC_HEIGHT * 2;
export const MOUSE_CATCHER_HEIGHT = 30;

export const StyledCollapsibleContent = styled.div`
    position: relative;
    width: 100%;
    height: fit-content;
`;

export const Content = styled.div`
    position: relative;
`;

export const ContentWrapper = styled.div<{
    _maxHeight: number;
    isCollapseStateChanging: boolean;
}>`
    max-height: ${props => props._maxHeight ? `${props._maxHeight}px` : ""};
    overflow: hidden;
    
    // only add transition during collapsing, otherwise it would be applied even when the content changed 
    ${props => props.isCollapseStateChanging && css`
        transition: max-height 0.2s;
    `};
`;

export const ExpandArea = styled.div<{
    _isCollapsed: boolean;
}>`
    height: 34px;
    position: relative;
    margin-top: ${props => props._isCollapsed ? "" : "8px"};
`;

export const Body = styled.div``;

export const Line = styled.div`
    position: absolute;
    height: 1px;
    width: 100%;
    top: 14px;
    background: ${props => props.theme.C_BG_stripes};
`;

export const HalfCircle = styled.div`
    width: 40px;
    height: 20px;
    border-bottom-left-radius: 41px;
    border-bottom-right-radius: 41px;

    position: absolute;
    left: 50%;
    top: 14px;
    border: 1px solid ${props => props.theme.C_BG_stripes};
    border-top: 0;

    background-color: ${props => props.theme.C_BG_floorplan};

    transform: translateX(-50%);
`;

const LineHover = css`
    background: ${props => props.theme.C_ACT_hover_without_opacity};
    height: 2px;
`;

const HalfCircleHover = css`
    border-color: ${props => props.theme.C_ACT_hover_without_opacity};
    border-width: 2px;
`;
export const ExpandAreaLine = styled.div`
    height: 34px;
    cursor: pointer;

    &:hover ${Line} {
        ${LineHover};
    }

    &:hover ${HalfCircle} {
        ${HalfCircleHover};
    }

    &:hover svg {
        ${props => hoverColors({
            theme: props.theme,
            isLightHover: true
        })};
    }
`;

export const MouseCatcher = styled.div`
    position: absolute;
    height: ${`${MOUSE_CATCHER_HEIGHT}px`};
    bottom: ${`${MOUSE_CATCHER_HEIGHT}px`};
    width: 100%;
    cursor: pointer;
    z-index: ${LocalStackOrder.Overlay};

    &:hover + div ${Line} {
        ${LineHover};
    }

    &:hover + div ${HalfCircle} {
        ${HalfCircleHover};
    }
`;

export const StyledIconButton = styled(IconButton)`
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
`;

export const StyledMoreOptionsIcon = styled(MoreOptionsIcon) <{
    _isCollapsed: boolean;
}>`
    transform: ${props => props._isCollapsed ? "" : "rotate(180deg)"};
`;

export const Overlay = styled.div`
    width: 100%;
    height: ${`${BASIC_HEIGHT}px`};
    bottom: 34px;
    right: 0;
    pointer-events: none;
    background-image: linear-gradient(to bottom, rgba(250, 250, 250, 0), ${props => props.theme.C_BG_floorplan} 85%, ${props => props.theme.C_BG_floorplan});

    z-index: ${LocalStackOrder.Tooltips};
    position: absolute;
`;