import { EntitySetName } from "@odata/GeneratedEntityTypes";
import { IFormatOptions } from "@odata/OData.utils";
import { setDefForTesting } from "@pages/getDefByEntityType";
import { IDefinition, IGetDefinition } from "@pages/PageUtils";
import { getCompanyCurrency } from "@utils/CompanyUtils";
import i18next from "i18next";

import { IAppContext } from "../../../../contexts/appContext/AppContext.types";
import { TValue } from "../../../../global.types";
import BindingContext from "../../../../odata/BindingContext";
import Currency from "../../../../types/Currency";
import { getUtcDate } from "../../../../types/Date";
import { IFormDef } from "../../../../views/formView/Form";
import { getTimeResAccountFieldDef } from "./TimeResolution.utils";

export const getDefinition: IGetDefinition = (context: IAppContext, receivable: boolean): IDefinition => {
    const entitySet = EntitySetName.DeferredPlans;

    const form: IFormDef = {
        id: "cancelTimeResolutionSpecialForm",
        translationFiles: getDefinition.translationFiles,
        fieldDefinition: {
            Account: getTimeResAccountFieldDef(receivable),
            [BindingContext.localContext("fullAmount")]: {
                label: i18next.t("TimeResolution:EndForm.Amount"),
                isReadOnly: true,
                formatter: (val: TValue, args: IFormatOptions) => {
                    return Currency.format(val, { currency: getCompanyCurrency(context) });
                },
                useForComparison: false
            },
            DateStart: {
                label: i18next.t("TimeResolution:EndForm.AccountingTransaction"),
                defaultValue: getUtcDate()
            },
            Description: {
                isReadOnly: true,
                defaultValue: i18next.t("TimeResolution:Form.Description")
            }
        },
        groups: [
            {
                id: "accounting",
                title: "",
                rows: [
                    [
                        { id: "DateStart" },
                        { id: "Account" }
                    ]
                ],
                isSmallBottomMargin: true
            },
            {
                id: "bottom",
                rows: [
                    [
                        { id: BindingContext.localContext("fullAmount") },
                        { id: "Description" }
                    ]
                ]
            }
        ]
    };

    return {
        entitySet,
        form
    };
};

getDefinition.translationFiles = ["TimeResolution", "Documents", "Common"];
setDefForTesting("CancelTimeResolution", getDefinition);