import { composeRefHandlers } from "@utils/general";
import React from "react";

import { WithDomManipulator, withDomManipulator } from "../../contexts/domManipulator/withDomManipulator";
import { IconSize, Status } from "../../enums";
import { IAuditTrailData } from "../../model/Model";
import TestIds from "../../testIds";
import FocusManager from "../focusManager/FocusManager";
import { HOTSPOT_ID_ATTR } from "../hotspots/Hotspots.utils";
import { getIcon } from "../icon";
import { AuditTrailFieldType } from "../smart/FieldInfo";
import {
    AuditTrailBorder,
    SegmentedButtonStatus,
    SegmentedButtonText,
    StyledSegmentedButton,
    StyledSegmentedButtonItem
} from "./SegmentedButton.styles";

export interface ISegmentedButton {
    /** Label is rendered as text directly into the button */
    label?: string;
    /** Title is used for browser "title" attribute and as label in "more" select for Toolbars with not enough space*/
    title?: string;
    iconName?: string;
    isDisabled?: boolean;
    id: string;
}

interface IProps extends WithDomManipulator {
    /** Segmented buttons definition*/
    def: ISegmentedButton[];
    name?: string;
    /** Renders html data-hotspotid attribute*/
    hotspotId?: string;
    isDisabled?: boolean;
    isReadOnly?: boolean;
    /** Defines whether the button should be rendered in light colors, suitable for dark backgrounds. */
    isLight?: boolean;
    /** First setup of selected button, will be applied only on mount*/
    defaultSelectedButtonId?: string;

    /** Id if you want to manage selected button. You will have to manually handle it using on change event*/
    selectedButtonId?: string;
    className?: string;
    style?: React.CSSProperties;

    onChange?: (key: string) => void;
    auditTrailData?: IAuditTrailData;

    showChange?: boolean;
}

interface IState {
    selectedButtonId: string;
    maxItemWidth: number;
}

class SegmentedButton extends React.PureComponent<IProps, IState> {
    _segmentedButton = React.createRef<HTMLDivElement>();

    constructor(props: IProps) {
        super(props);

        this.state = {
            selectedButtonId: props.defaultSelectedButtonId,
            maxItemWidth: null
        };
    }

    componentDidMount(): void {
        this.updateWidths();
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>): void {
        this.updateWidths();
    }

    updateWidths = (): void => {
        this.props.domManipulatorOrchestrator.registerCallback(
                () => {
                    const items = this._segmentedButton.current.getElementsByTagName("button");

                    let max = 0;
                    for (const item of items) {
                        if (!item.offsetWidth) {
                            // probably invisible, don't recalculate
                            continue;
                        }

                        let itemWidth = 0;
                        const styles = getComputedStyle(item);

                        if (styles.paddingLeft) {
                            itemWidth += parseInt(styles.paddingLeft);
                        }

                        if (styles.paddingRight) {
                            itemWidth += parseInt(styles.paddingRight);
                        }

                        for (const child of item.children) {
                            if (child.tagName === "svg") {
                                itemWidth += (child as SVGElement).getBoundingClientRect().width;
                            } else {
                                itemWidth += (child as HTMLDivElement).offsetWidth;
                            }
                        }

                        if (itemWidth > max) {
                            max = itemWidth;
                        }
                    }

                    return max;
                },
                (max: number) => {
                    if (max > 0) {
                        this.setState({
                            maxItemWidth: max
                        });
                    }
                }
                , [this._segmentedButton]);
    };

    handleButtonClick = (id: string): void => {
        this.selectItem(id);
    };

    selectItem = (selectedButtonId: string): void => {
        this.setState({ selectedButtonId });
        this.props.onChange && this.props.onChange(selectedButtonId);
    };

    _isSelected = (id: string): boolean => {
        return this._getSelectedKey() === id;
    };

    _getSelectedKey = (): string => {
        return this.props.selectedButtonId ?? this.state.selectedButtonId;
    };

    render() {
        const isDisabledOrReadOnly = this.props.isDisabled || this.props.isReadOnly;
        return (
                <FocusManager isDisabled={!!this.props.auditTrailData}>
                    {({ itemProps, wrapperProps }) => (
                            <>
                                <StyledSegmentedButton {...wrapperProps}
                                                       data-testid={TestIds.SegmentedButton}
                                                       {...{ [HOTSPOT_ID_ATTR]: this.props.hotspotId }}
                                                       role="tab"
                                                       ref={composeRefHandlers(wrapperProps.ref, this._segmentedButton)}
                                                       auditTrailType={this.props.auditTrailData?.type}
                                                       isDisabled={isDisabledOrReadOnly}
                                                       tabIndex={isDisabledOrReadOnly ? -1 : 0}
                                                       style={this.props.style}
                                                       className={this.props.className}>
                                    {(this.props.auditTrailData?.type === AuditTrailFieldType.Difference || this.props.auditTrailData?.type === AuditTrailFieldType.HoveredDifference) &&
                                            <AuditTrailBorder/>}
                                    {this.props.def?.map((button: ISegmentedButton, index: number) => {
                                        const Icon = button.iconName ? getIcon(button.iconName) : null;
                                        const isSelected = this._isSelected(button.id);
                                        return (
                                                <StyledSegmentedButtonItem
                                                        {...itemProps}
                                                        auditTrailType={this.props.auditTrailData?.type}
                                                        key={index}
                                                        title={button.title ?? button.label}
                                                        name={this.props.name}
                                                        onClick={(event: React.MouseEvent) => {
                                                            event.preventDefault();
                                                            this.handleButtonClick(button.id);
                                                        }}
                                                        $width={this.state.maxItemWidth}
                                                        type={"button"}
                                                        data-testid={TestIds.Button}
                                                        aria-selected={isSelected}
                                                        isSelected={isSelected}
                                                        isDisabled={button.isDisabled && !isDisabledOrReadOnly /*Disabled style only if whole segmented button is not disabled*/}
                                                        isIcon={!!button.iconName}
                                                        hasText={!!button.label}
                                                        disabled={isDisabledOrReadOnly || button.isDisabled}
                                                        isFirst={index === 0}
                                                        isLight={this.props.isLight}
                                                        isLast={index === this.props.def.length - 1}>
                                                    {Icon &&
                                                            <Icon width={IconSize.M} height={IconSize.M}
                                                                  isLight={this.props.isLight}/>}
                                                    {button.label && <SegmentedButtonText
                                                            isLight={this.props.isLight}>{button.label}</SegmentedButtonText>}
                                                </StyledSegmentedButtonItem>
                                        );
                                    })}
                                </StyledSegmentedButton>
                                {this.props.showChange &&
                                        <SegmentedButtonStatus status={Status.Warning}
                                                               data-testid={TestIds.InputStatus}/>}
                            </>
                    )}
                </FocusManager>
        );
    }
}

const ExtendedSegmentedButton = withDomManipulator(SegmentedButton);

export { ExtendedSegmentedButton as SegmentedButton };