import React from "react";
import { withTheme } from "styled-components/macro";

import { DASH_CHARACTER } from "../../constants";
import TestIds from "../../testIds";
import { PropsWithTheme } from "../../theme";
import { ListStyled, TitleStyled } from "./ReadOnlyList.styles";
import { IProps as IListItemProps, IReadOnlyListItem, ReadOnlyListItem } from "./ReadOnlyListItem";

// Empty characted
export const EMPTY_DASH = "-";

/**
 * Formats value with "empty" fallback
 * @param value
 * @param formatter
 */
export function formatHeaderValue<T>(value: T, formatter?: ((value: T) => string)): string {
    return value ? (formatter ? formatter(value) : value as string) : EMPTY_DASH;
}

export interface IProps extends PropsWithTheme, Pick<IListItemProps, "type"> {
    title?: string;
    data: IReadOnlyListItem[];
    withoutPadding?: boolean;
}

class ReadOnlyList extends React.PureComponent<IProps> {
    render() {
        return (
            <>
                {this.props.title && (<TitleStyled>{this.props.title}</TitleStyled>)}
                <ListStyled data-testid={TestIds.ReadOnlyList} $withoutPadding={this.props.withoutPadding}>
                    {this.props.data.map((item, idx) => (
                        <ReadOnlyListItem key={idx}
                                          type={this.props.type}
                                          {...item}
                                          value={item.value ?? DASH_CHARACTER}
                        />
                    ))}
                </ListStyled>
            </>
        );
    }
}

export default withTheme(ReadOnlyList);