import { BackArrowStyled } from "../LoginPage.styles";
import i18next from "i18next";
import { ArrowIcon } from "@components/icon";
import { IconSize } from "../../enums";
import React, { useCallback } from "react";
import { useLoginDispatch } from "../state/hooks";
import { ROUTE_LOGIN, ROUTE_LOGOUT } from "../../routes";
import { useHistory } from "react-router-dom";
import { logout } from "../../contexts/authContext/Auth.utils";

interface IProps {
    url?: string;
    onClick?: () => void;
}

export const BackArrow: React.FC<IProps> = ({ url, onClick }) => {
    const dispatch = useLoginDispatch();
    const history = useHistory();

    const shouldLogout = url === ROUTE_LOGOUT;

    const handleClick = useCallback(async () => {
        onClick?.();
        if (shouldLogout) {
            await logout();
            history.push(ROUTE_LOGIN);
        }
    }, [dispatch, history, shouldLogout, onClick]);

    const props = url && !shouldLogout ? { link: url } : {};

    return (
            <BackArrowStyled isDecorative
                             title={i18next.t("Login:Login.Back")}
                             onClick={handleClick}
                             {...props}>
                <ArrowIcon width={IconSize.M}/>
            </BackArrowStyled>
    );
};