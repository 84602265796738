import React from "react";
import { StyledBreadCrumb } from "./BreadCrumb.styles";

interface IProps {
    className?: string;
    passRef?: React.Ref<HTMLUListElement>;
}

class BreadCrumb extends React.PureComponent<IProps> {
    getTitle = (): string => {
        return React.Children.map(this.props.children, ((child) => (child as React.ReactElement).props.children as string)).join(" / ");
    };

    getChildren = () => {
        // we are using bunch of rtl related styles to get truncation from left instead of right
        // => we need to render the items in opposite direction
        return React.Children.toArray(this.props.children).reverse();
    }

    render = () => {
        return (
            <StyledBreadCrumb
                title={this.getTitle()}
                className={this.props.className}
                ref={this.props.passRef}>
                {this.getChildren()}
                {/* https://stackoverflow.com/a/42551367/3352544
                    add special Left-to-right mark to prevent "direction: rtl;" from moving "." or other punctuation to the start of the breadcrumbs */}
                &lrm;
            </StyledBreadCrumb>
        );
    };
}

export { BreadCrumb };