import { FormStorage } from "../../views/formView/FormStorage";
import BindingContext from "../../odata/BindingContext";
import React, { ReactElement } from "react";
import { TEntityKey } from "@evala/odata-metadata/src";
import { isDefined, isNotDefined, roundToDecimalPlaces } from "@utils/general";
import {
    calculateExchangeRate,
    getTransactionTypeFromEntity,
    hasAdditionalExRate,
    hasExchangeGain,
    hasExchangeRate,
    IBankCustomData,
    isPairingActive,
    isPairingRowReadOnly
} from "./bankTransactions/BankTransactions.utils";
import { BasicInputSizes, TextAlign, ValidationErrorType } from "../../enums";
import { IInputOnChangeEvent } from "@components/inputs/input";
import { IValidationError } from "../../model/Validator.types";
import {
    AmountRow,
    ExchangeRateDiffLbl,
    ExchangeRateDiffValue,
    GainRow,
    InputColumn,
    LabelColumn,
    NumericInputStyled,
    TableStyled,
    ValueColumn,
    ValuePadding
} from "./bankTransactions/BankTransactions.styles";
import CurrencyType, { formatCurrency } from "../../types/Currency";
import { getExpenseTitle } from "@utils/PairTableUtils";
import Dialog from "../../components/dialog/Dialog";
import ConfirmationButtons from "../../views/table/ConfirmationButtons";
import { IPaymentDocumentItemEntityExpanded } from "./PairTableView";
import {
    getCorrectExchangeRate,
    getCorrectTranAmount,
    IValidateMaxArgs,
    validateMaxData,
    ValidateMaxType
} from "./Pair.utils";
import NumberType, { currencyScaleFormatter, IParserArgs } from "../../types/Number";
import i18next from "i18next";
import { IInternalDocumentItemEntity } from "@odata/GeneratedEntityTypes";
import TestIds from "../../testIds";
import { TSmartODataTableStorage } from "@components/smart/smartTable/SmartODataTableBase";
import { Label } from "@components/inputs/field";
import { Button } from "../../components/button";
import { getCompanyCurrency, isAccountAssignmentCompany } from "@utils/CompanyUtils";
import { fourDigitsFormatter } from "@components/smart/GeneralFieldDefinition";
import { DocumentTypeCode } from "@odata/GeneratedEnums";
import { getUtcDate } from "../../types/Date";
import { DASH_CHARACTER } from "../../constants";

interface IAmountDialogData {
    currentAmount?: number;
    currentAmountError?: IValidationError;
    currentExRate?: number;
    currentExRateError?: IValidationError;
    currentRatedAmount?: number;
    currentRatedAmountError?: IValidationError;
    totalAmount?: number;

    documentType?: DocumentTypeCode;

    hasAdditionalExRate?: boolean;
    hasExchangeGain?: boolean;

    docAmount?: number;
    currentDocAmount?: number;
    docCurrency?: string;
    docMinorUnit?: number;
    docExchangeRate?: number;
    docId?: number;

    tranCurrency?: string;
    tranExRate?: number;
}


interface IAmountProps {
    needConfirmDialog: boolean;
    clearingByID?: boolean; // clearing by internal document, same functions, different looks

    storage: TSmartODataTableStorage<IBankCustomData>;
    // todo what entities should be there instead of any?
    rootStorage: FormStorage<any, IBankCustomData>;

    onClose: () => void;
    onUpdateColumn?: (bc: BindingContext) => void;
}

export class AmountDialog extends React.Component<IAmountProps> {
    data: IAmountDialogData = {};
    isReady = false;
    isReadOnly = false;

    async setupAmountDialogContent(): Promise<void> {
        const row = this.props.storage.getCustomData().amountDialogRow;
        const link = this.getPairedDocuments()[row.customData.entity.Id];
        this.isReadOnly = !isPairingActive(this.props.storage) || isPairingRowReadOnly(link, this.props.storage, this.props.rootStorage);

        this.data.totalAmount = this.props.clearingByID ? link.LinkedDocument.TransactionAmount : Math.abs(link.LinkedDocument.TransactionAmount);
        this.data.currentAmount = link.TransactionAmount;

        this.data.docCurrency = row?.customData?.entity?.TransactionCurrency?.Code;
        this.data.docMinorUnit = row?.customData?.entity.TransactionCurrency?.MinorUnit;
        this.data.docId = row?.customData?.entity?.Id;
        this.data.documentType = row?.customData?.entity?.DocumentTypeCode;

        this.data.tranCurrency = this.getTransactionCurrency();
        this.data.tranExRate = this.getTransactionExchangeRate();

        this.data.hasAdditionalExRate = !this.props.clearingByID && hasAdditionalExRate(this.data.docCurrency, this.data.tranCurrency, this.props.storage.context);
        this.data.hasExchangeGain = !this.props.clearingByID && hasExchangeGain(this.data.docCurrency, this.data.documentType, this.props.storage.context);

        const rate = link.ExchangeRate;
        if (isDefined(rate) && this.data.hasAdditionalExRate) {
            if (rate === 0) {
                this.data.currentExRate = this.data.currentRatedAmount = 0;
            } else {
                this.data.currentExRate = rate;
                this.data.currentRatedAmount = link.ClearedAmount;
            }
        }

        // special case handling when you pair already paired document
        const tranDue = this.props.clearingByID ? row?.customData?.entity?.TransactionAmountDue : Math.abs(row?.customData?.entity?.TransactionAmountDue);
        this.data.docAmount = getCorrectTranAmount(this.props.rootStorage, this.data.docId, tranDue, this.data.docCurrency);

        // clearing -> get from DOC, otherwise try transaction entity. DateBankTransaction == BankTransaction, DateIssued = CashReceipt
        const date = this.props.clearingByID ? row.customData.entity.DateAccountingTransaction :
            this.props.rootStorage.data.entity.DateBankTransaction ?? this.props.rootStorage.data.entity.DateIssued ?? getUtcDate();

        this.data.docExchangeRate = await getCorrectExchangeRate({
            id: row.customData?.entity?.Id,
            date: date,
            currency: this.data.docCurrency
        }, this.props.storage.context);

        this.calculateNewCurrentDocAmount(this.data.hasAdditionalExRate ? this.data.currentRatedAmount : this.data.currentAmount);

        this.isReady = true;
    }

    componentDidMount() {
        this.setupAmountDialogContent().then(() => {
            this.forceUpdate();
        });
    }

    getPairedDocuments(): Record<TEntityKey, IPaymentDocumentItemEntityExpanded> {
        return this.props.storage.getCustomData().pairedDocuments || {};
    }

    getTransactionExchangeRate = (): number => {
        return this.props.rootStorage.data.entity.ExchangeRatePerUnit || 1;
    };

    getTransactionCurrency = (): string => {
        const entity = this.props.rootStorage.data.entity;
        return entity?.TransactionCurrency?.Code ?? entity?.TransactionCurrencyCode;
    };

    hasAdditionalExRate = (): boolean => {
        const row = this.props.storage.getCustomData().amountDialogRow;
        const docCurrency = row?.customData?.entity?.TransactionCurrency?.Code;
        const tranCurrency = this.getTransactionCurrency();

        return hasAdditionalExRate(docCurrency, tranCurrency, this.props.storage.context);
    };

    handleConfirmAmountDialog = (): void => {
        const { storage } = this.props;
        const row = storage.getCustomData().amountDialogRow;
        const id = row.customData.entity.Id;
        const link = this.getPairedDocuments()[id];

        const bc = storage.data.bindingContext.addKey(id);

        const currentAmount = this.getCurrentAmount();
        const docCurrency = row?.customData?.entity?.TransactionCurrency?.Code;

        link.TransactionAmount = currentAmount;
        if (this.props.clearingByID) {
            const hasExRate = this.data.docCurrency !== getCompanyCurrency(storage.context);
            link.Amount = roundToDecimalPlaces(2, hasExRate ? currentAmount * this.data.docExchangeRate : currentAmount);
            (link as IInternalDocumentItemEntity).TransactionCurrencyCode = docCurrency;
            link.ExchangeRate = this.data.docExchangeRate;
        } else {
            link.Amount = roundToDecimalPlaces(2, link.TransactionAmount);

            const tranCurrency = this.getTransactionCurrency();
            if (tranCurrency !== getCompanyCurrency(storage.context)) {
                const transactionExRate = this.getTransactionExchangeRate();
                link.Amount = link.TransactionAmount * transactionExRate;
            }

            const currentExRate = this.data.currentExRate ? Number(this.data.currentExRate) : null;

            const exchangeRateDiff = calculateExchangeRate({
                docCurrency,
                tranCurrency,
                docExchangeRate: this.data.docExchangeRate,
                tranExRate: this.getTransactionExchangeRate(),
                docType: this.data.documentType,
                currentExRate,
                currentAmount,
                ratedAmount: this.data.currentRatedAmount,
                type: getTransactionTypeFromEntity(this.props.rootStorage),
                context: storage.context
            });

            if (isDefined(exchangeRateDiff) || isDefined(link.ExchangeGain)) {
                link.ExchangeGain = exchangeRateDiff;
            }

            link.ExchangeRate = currentExRate;
            link.ClearedAmount = this.data.currentRatedAmount;
        }

        this.props.onUpdateColumn?.(bc);

        this.clearAmountDialogFields();
        this.forceUpdate();
        this.props.onClose();
    };

    getCurrentAmount = (): number => {
        return this.data.currentAmount || 0;
    };

    isAmountConfirmAvailable = (): boolean => {
        const hasExRate = this.props.clearingByID ? false : this.hasAdditionalExRate();
        const hasError = !!this.data.currentAmountError || !!this.data.currentExRateError || !!this.data.currentRatedAmountError;
        const hasValue = hasExRate ? !!this.data.currentAmount && !!this.data.currentExRate && !!this.data.currentRatedAmount : !!this.data.currentAmount;

        return hasValue && !hasError;
    };

    clearAmountDialogFields = (): void => {
        this.data.currentAmount = null;
        this.data.currentExRate = null;
        this.data.currentRatedAmount = null;

        this.data.currentAmountError = null;
        this.data.currentExRateError = null;
        this.data.currentRatedAmountError = null;

        this.props.storage.setCustomData({
            defaultExchangeRate: null,
            amountDialogRow: null
        });
    };

    handleCancelAmountDialog = (): void => {
        if (this.props.needConfirmDialog) {
            const pairedDoc = this.getPairedDocuments();
            const row = this.props.storage.getCustomData().amountDialogRow;
            const id = row?.customData?.entity?.Id;
            if (pairedDoc) {
                delete pairedDoc[id];
                this.props.onUpdateColumn?.(row.id as BindingContext);
            }
        }

        this.props.storage.setCustomData({
            amountDialogRow: null
        });

        this.clearAmountDialogFields();
        this.props.onClose();
    };


    validateField = (value: number): IValidationError => {
        if (isNaN(value)) {
            return {
                message: this.props.storage.t("Common:Validation.NotaNumber"),
                errorType: ValidationErrorType.Field
            };
        }

        if (this.props.clearingByID) {
            if (this.data.totalAmount < 0 && value >= 0) {
                return {
                    message: this.props.storage.t("Common:Validation.NegativeNumber"),
                    errorType: ValidationErrorType.Field
                };
            }
            if (this.data.totalAmount > 0 && value <= 0) {
                return {
                    message: this.props.storage.t("Common:Validation.PositiveNumber"),
                    errorType: ValidationErrorType.Field
                };
            }
        } else if (value <= 0) {
            return {
                message: this.props.storage.t("Common:Validation.PositiveNumber"),
                errorType: ValidationErrorType.Field
            };
        }

        if (!value) {
            return {
                message: this.props.storage.t("Common:Validation.Required"),
                errorType: ValidationErrorType.Field
            };
        }

        return null;
    };


    createMaxValidationData = (type: ValidateMaxType, amount: number): IValidateMaxArgs => {
        return {
            type,
            amount,
            clearingByID: this.props.clearingByID,
            docAmount: this.data.docAmount,
            docExRate: this.data.docExchangeRate,
            docCurrency: this.data.docCurrency,
            tranCurrency: this.data.tranCurrency,
            tranExRate: this.data.tranExRate,
            tranAmount: Math.abs(this.props.rootStorage.data.entity.TransactionAmount),
            t: this.props.storage.t
        };
    };

    validateGainFields = (): void => {
        this.data.currentRatedAmountError = this.validateField(this.data.currentRatedAmount)
            || validateMaxData(this.createMaxValidationData(ValidateMaxType.Gain, this.data.currentRatedAmount), this.props.storage.context);
        this.data.currentExRateError = this.validateField(this.data.currentExRate);
    };

    handleAmountChange = (e: IInputOnChangeEvent<number>): void => {
        this.data.currentAmount = e.value;

        let error = this.validateField(this.data.currentAmount);
        if (!error) {
            error = validateMaxData(this.createMaxValidationData(ValidateMaxType.Amount, this.data.currentAmount), this.props.storage.context);

            // must be set for 'calculateNewCurrentDocAmount'
            this.data.currentAmountError = error;
            if (!error) {
                if (this.data.hasAdditionalExRate) {
                    const amount = this.data.currentAmount;
                    const tranAmount = amount * this.data.tranExRate;
                    if (this.data.currentRatedAmount) {
                        this.data.currentExRate = roundToDecimalPlaces(4, tranAmount / this.data.currentRatedAmount);
                        this.data.currentExRateError = this.validateField(this.data.currentExRate);
                    }
                } else {
                    this.calculateNewCurrentDocAmount(this.data.currentAmount);
                }
            }
        }

        this.data.currentAmountError = error;
        this.forceUpdate();
    };

    handleExRateChange = (e: IInputOnChangeEvent): void => {
        const value = e.value as number;
        if (isNaN(value) || isNotDefined(value) || value === 0) {
            this.data.currentExRate = value;
            this.data.currentExRateError = this.validateField(this.data.currentExRate);
        } else {
            const currentAmount = this.getCurrentAmount() ?? 0;
            const tranExRate = this.getTransactionExchangeRate();

            this.data.currentExRate = value;
            this.data.currentRatedAmount = roundToDecimalPlaces(2, ((currentAmount * tranExRate) / value));

            this.validateGainFields();

            this.calculateNewCurrentDocAmount(this.data.currentRatedAmount);
        }
        this.forceUpdate();
    };

    calculateNewCurrentDocAmount = (amount: number): void => {
        if (!this.data.currentRatedAmountError && !this.data.currentExRateError && !this.data.currentAmountError) {
            let exRate = 1;
            if (!this.data.hasAdditionalExRate && this.data.tranCurrency !== this.data.docCurrency) {
                exRate = this.data.tranExRate;
            }

            this.data.currentDocAmount = this.data.docAmount - ((amount ?? 0) * exRate);
        }
    };

    handleRatedAmountChange = (e: IInputOnChangeEvent): void => {
        const value = e.value as number;
        if (isNaN(value) || isNotDefined(value) || value === 0) {
            this.data.currentRatedAmount = value;
            this.data.currentRatedAmountError = this.validateField(this.data.currentRatedAmount);
        } else {
            const currentAmount = this.getCurrentAmount() ?? 0;
            const tranExRate = this.getTransactionExchangeRate();

            const paid = currentAmount * tranExRate;

            this.data.currentRatedAmount = value;
            this.data.currentExRate = paid / value;

            this.validateGainFields();

            this.calculateNewCurrentDocAmount(this.data.currentRatedAmount);
        }

        this.forceUpdate();
    };

    getTitle = (): string => {
        const row = this.props.storage.getCustomData().amountDialogRow;
        const title = this.props.clearingByID ? this.props.storage.t("InternalDocument:Form.Clearing") : this.props.storage.t("Document:Pair.Payment");
        if (row) {
            return `${title} ${row.customData.entity.NumberOurs}`;
        }

        return "";
    };

    renderBaseCurrencyDialog = (): ReactElement => {
        const totalAmount = formatCurrency(this.data.totalAmount, this.data.docCurrency);
        const dueAmount = formatCurrency(this.data.currentDocAmount, this.data.docCurrency);
        const secondColPadding = this.isReadOnly ? ValuePadding.None : ValuePadding.Input;
        return (
            <TableStyled>
                <tbody>
                <AmountRow>
                    <LabelColumn>
                        <Label>{i18next.t("Banks:Pairing.Total")}</Label>
                    </LabelColumn>
                    <ValueColumn padding={secondColPadding}>{totalAmount}</ValueColumn>
                </AmountRow>
                <AmountRow>
                    <LabelColumn>
                        <Label>{i18next.t("Banks:Transactions.AccountingBalance")}</Label></LabelColumn>
                    <ValueColumn padding={secondColPadding}>{dueAmount}</ValueColumn>
                </AmountRow>
                <AmountRow>
                    <LabelColumn>
                        <Label isRequired>{i18next.t("Banks:Pairing.AmountPaid")}</Label>
                    </LabelColumn>
                    <InputColumn isLast>
                        <NumericInputStyled
                            onChange={this.handleAmountChange}
                            isReadOnly={this.isReadOnly}
                            unit={CurrencyType.getCurrencyUnit(this.getTransactionCurrency())}
                            width={BasicInputSizes.S}
                            textAlign={TextAlign.Right}
                            parser={this.amountParser}
                            formatter={this.amountFormatter}
                            error={this.data.currentAmountError}
                            value={this.data.currentAmount}/>
                    </InputColumn>
                </AmountRow>
                </tbody>
            </TableStyled>
        );
    };

    amountParser = (value: string, strict?: boolean, args?: IParserArgs): number => {
        // for internal document use document currency, for transaction use root storage currency
        const scale = this.props.clearingByID ? this.data.docMinorUnit : this.props.rootStorage.data.entity.TransactionCurrency?.MinorUnit ?? 2;

        return NumberType.parse(value, strict, {
            ...args,
            maximumFractionDigits: scale
        });
    };

    amountFormatter = (value: number): string => {
        return currencyScaleFormatter(value);
    };

    exRateParser = (value: string, strict?: boolean, args?: IParserArgs): number => {
        return NumberType.parse(value, strict, {
            ...args,
            maximumFractionDigits: 4
        });
    };

    amountFormatterWithRounding = (val: number, currency: string): string => {
        // round before formatting so there won't be glitches like -0.002 -> -0.00
        const isZero = roundToDecimalPlaces(2, val) === 0;
        return formatCurrency(isZero ? 0 : val, currency);
    };

    exRateFormatter = (val: number): string => {
        return fourDigitsFormatter(val);
    };

    renderWithExRate = (): ReactElement => {
        const _hideExchangeRate = (value: number | string) => {
            return this.data.documentType === DocumentTypeCode.ProformaInvoiceIssued || this.data.documentType === DocumentTypeCode.ProformaInvoiceReceived ? DASH_CHARACTER : value;
        };

        const { storage } = this.props;
        const showDialogRow = storage.getCustomData().amountDialogRow;
        const clearingByID = this.props.clearingByID;
        if (!showDialogRow || !this.isReady) {
            return null;
        }

        const currentAmount = this.getCurrentAmount();
        const currentExRate = this.data.currentExRate;
        const ratedAmount = this.data.currentRatedAmount;

        const hasAccounting = isAccountAssignmentCompany(storage.context);
        const hasSameCurrencies = this.data.docCurrency === this.data.tranCurrency;

        const exchangeRateDiff = calculateExchangeRate({
            docCurrency: this.data.docCurrency,
            tranCurrency: this.data.tranCurrency,
            tranExRate: this.getTransactionExchangeRate(),
            docExchangeRate: this.data.docExchangeRate,
            docType: this.data.documentType,
            currentExRate,
            currentAmount,
            ratedAmount,
            type: getTransactionTypeFromEntity(this.props.rootStorage),
            context: storage.context
        });

        const companyCurrency = getCompanyCurrency(storage.context);
        const formattedDiff = isNaN(exchangeRateDiff) ? "" : this.amountFormatterWithRounding(Math.abs(exchangeRateDiff), companyCurrency);
        const formattedBalance = this.amountFormatterWithRounding(this.data.currentDocAmount * this.data.docExchangeRate, companyCurrency);

        const hasDocExRate = this.data.docCurrency !== companyCurrency;
        const hasTranExRate = this.data.tranCurrency !== companyCurrency;
        const dueAmount = this.amountFormatterWithRounding(this.data.currentDocAmount, this.data.docCurrency);
        const totalAmount = this.amountFormatterWithRounding(this.data.totalAmount, this.data.docCurrency);
        const totalAmountCZK = this.amountFormatterWithRounding(this.data.totalAmount * this.data.docExchangeRate, companyCurrency);

        const currentAmountUnit = CurrencyType.getCurrencyUnit(clearingByID ? this.data.docCurrency : this.data.tranCurrency);
        const rate = clearingByID ? this.data.docExchangeRate : this.data.tranExRate;
        const formattedRate = clearingByID || hasTranExRate ? this.exRateFormatter(rate) : "";
        const formattedCurrentAmount = isNaN(currentAmount) ? "" : this.amountFormatterWithRounding(currentAmount * rate, companyCurrency);

        const formattedExchaneRate = this.exRateFormatter(this.data.docExchangeRate);

        const hasThirdColumn = hasAccounting || hasDocExRate || !hasSameCurrencies;
        const secondColPadding = hasThirdColumn ? this.isReadOnly ? ValuePadding.Gap : null : this.isReadOnly ? ValuePadding.Gap : ValuePadding.Input;
        const thirdColPadding = hasAccounting ? this.isReadOnly ? ValuePadding.Gap : null : this.data.hasAdditionalExRate ? this.isReadOnly ? ValuePadding.Gap : ValuePadding.Input : ValuePadding.None;
        return (
            <>
                <TableStyled>
                    <tbody>
                    <AmountRow>
                        <LabelColumn></LabelColumn>
                        <ValueColumn
                            padding={secondColPadding}>{i18next.t("Banks:ConfirmationDialog.Amount")}</ValueColumn>
                        <ValueColumn
                            padding={thirdColPadding}>{(!hasSameCurrencies || hasAccounting) && i18next.t("Banks:Form.ExchangeRate")}</ValueColumn>
                        <ValueColumn>{hasAccounting && i18next.t("Banks:Transactions.Accounted")}</ValueColumn>
                    </AmountRow>
                    <AmountRow>
                        <LabelColumn><Label>{i18next.t("Banks:Pairing.Total")}</Label></LabelColumn>
                        <ValueColumn
                            padding={secondColPadding}>{totalAmount}</ValueColumn>
                        <ValueColumn
                            padding={thirdColPadding}>{hasAccounting && hasDocExRate && _hideExchangeRate(formattedExchaneRate)}</ValueColumn>
                        <ValueColumn>{hasAccounting && _hideExchangeRate(totalAmountCZK)}</ValueColumn>
                    </AmountRow>

                    <AmountRow>
                        <LabelColumn><Label>{i18next.t("Banks:Transactions.AccountingBalance")}</Label></LabelColumn>
                        <ValueColumn padding={secondColPadding}>{dueAmount}</ValueColumn>
                        <ValueColumn
                            padding={thirdColPadding}>{hasAccounting && hasDocExRate && _hideExchangeRate(formattedExchaneRate)}</ValueColumn>
                        <ValueColumn>{hasAccounting && _hideExchangeRate(formattedBalance)}</ValueColumn>
                    </AmountRow>
                    <AmountRow>
                        <LabelColumn>
                            <Label isRequired>
                                {this.data.hasAdditionalExRate ? i18next.t("Banks:Pairing.TransactionValue") : i18next.t("Banks:Pairing.AmountPaid")}
                            </Label>
                        </LabelColumn>
                        <InputColumn isLast={!hasThirdColumn}>
                            <NumericInputStyled
                                onChange={this.handleAmountChange}
                                isReadOnly={this.isReadOnly}
                                unit={currentAmountUnit}
                                width={this.isReadOnly ? null : BasicInputSizes.S}
                                textAlign={TextAlign.Right}
                                parser={this.amountParser}
                                formatter={this.amountFormatter}
                                error={this.data.currentAmountError}
                                value={this.data.currentAmount}/>
                        </InputColumn>
                        <ValueColumn
                            padding={thirdColPadding}>{(!hasSameCurrencies || hasAccounting) && formattedRate}</ValueColumn>
                        <ValueColumn>{hasAccounting && formattedCurrentAmount}</ValueColumn>
                    </AmountRow>
                    {this.data.hasAdditionalExRate &&
                        <AmountRow>
                            <LabelColumn>
                                <Label isRequired>
                                    {i18next.t("Banks:Pairing.AmountPaid")}
                                </Label>
                            </LabelColumn>
                            <InputColumn>
                                <NumericInputStyled
                                    unit={this.data.docCurrency ? CurrencyType.getCurrencyUnit(this.data.docCurrency) : null}
                                    width={this.isReadOnly ? null : BasicInputSizes.S}
                                    isReadOnly={this.isReadOnly}
                                    error={this.data.currentRatedAmountError}
                                    textAlign={TextAlign.Right}
                                    parser={this.amountParser}
                                    formatter={this.amountFormatter}
                                    onChange={this.handleRatedAmountChange}
                                    value={this.data.currentRatedAmount}/>
                            </InputColumn>
                            <InputColumn isLast={!hasAccounting}>
                                <NumericInputStyled
                                    isReadOnly={this.isReadOnly}
                                    width={this.isReadOnly ? null : BasicInputSizes.S}
                                    error={this.data.currentExRateError}
                                    parser={this.exRateParser}
                                    formatter={this.exRateFormatter}
                                    textAlign={TextAlign.Right}
                                    onChange={this.handleExRateChange}
                                    value={this.data.currentExRate}/>
                            </InputColumn>
                            <ValueColumn></ValueColumn>
                        </AmountRow>
                    }
                    </tbody>
                </TableStyled>

                {hasAccounting && this.data.hasExchangeGain && exchangeRateDiff !== 0 && !isNaN(exchangeRateDiff) &&
                    <GainRow data-testid={TestIds.GainRow}>
                        <ExchangeRateDiffLbl>
                            {getExpenseTitle(exchangeRateDiff < 0)}
                        </ExchangeRateDiffLbl>
                        <ExchangeRateDiffValue
                            data-testid={TestIds.GainRowValue}>{formattedDiff}</ExchangeRateDiffValue>
                    </GainRow>
                }
            </>
        );
    };


    renderContent = (): ReactElement => {
        const hasExRate = hasExchangeRate(this.data.docCurrency, this.data.tranCurrency, this.props.storage.context);
        return hasExRate ? this.renderWithExRate() : this.renderBaseCurrencyDialog();
    };

    getFooterButtons = (): ReactElement => {
        if (this.isReadOnly) {
            return (
                <Button onClick={this.handleCancelAmountDialog}>
                    {this.props.storage.t("Common:General.Close")}
                </Button>
            );
        }

        return <ConfirmationButtons
            isDisabled={!this.isAmountConfirmAvailable()}
            onCancel={this.handleCancelAmountDialog}
            onConfirm={this.handleConfirmAmountDialog}
            useWrapper={false}/>;
    };

    render() {
        return <Dialog
            busy={!this.isReady}
            onConfirm={!this.isAmountConfirmAvailable() ? null : this.handleConfirmAmountDialog}
            onClose={this.handleCancelAmountDialog}
            footer={this.getFooterButtons()}
            title={this.getTitle()}
            testid={TestIds.PencilDialog}>
            {this.renderContent()}
        </Dialog>;
    }
}