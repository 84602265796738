import styled from "styled-components/macro";
import { LocalStackOrder, T_PLAIN_big, T_PLAIN_big_hig } from "../../global.style";
import { Label } from "@components/inputs/switch/Switch.styles";

export const InitialBalancesColumns = 4;

export const InitialAccountBalanceGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(${InitialBalancesColumns}, auto) 1fr;
    row-gap: 15px;
    column-gap: 20px;
    margin: 35px 0 30px 0;
    position: relative;
`;

export const StickyHeader = styled.div<{
    _isInactiveCompany: boolean;
}>`
    position: sticky;
    top: 0;
    background-color: ${props => props._isInactiveCompany ? props.theme.C_BG_floorplan_inactive_company : props.theme.C_BG_floorplan};
    z-index: ${LocalStackOrder.Overlay};
    padding-bottom: 15px;
`;

export const HeadingWrapper = styled.div`
    display: flex;
    align-items: center;
    & > span {
        ${T_PLAIN_big_hig};
        margin-right: 28px;
        white-space: nowrap;
    }
`;

export const HeaderCell = styled.div`
    ${T_PLAIN_big_hig};
`;

export const GridCell = styled.div`
    ${T_PLAIN_big};
    display: flex;
    align-items: center;
    ${Label} {
        white-space: nowrap;
    }
`;

export const RateWrapper = styled.div`
    ${T_PLAIN_big};
    display: flex;
    align-items: center;
    height: 100%;
`;

export const AmountOverview = styled.div`
    margin: 0 40px;
    & > span {
        ${T_PLAIN_big};
        & > b {
            font-weight: 800; 
        }
    }
`;

export const Separator = styled.span`
    opacity: 0.5;
    ${T_PLAIN_big};
    margin: 0 8px;
`;