import { getWorkDate, isValidDate } from "@components/inputs/date/utils";
import { SwitchType } from "@components/inputs/switch/Switch";
import {
    getInfoValue,
    getSimpleBoolSelectItems,
    ifAll,
    ifAny,
    IFieldInfoProperties,
    IGetValueArgs,
    not
} from "@components/smart/FieldInfo";
import { ISmartFieldBlur, ISmartFieldChange } from "@components/smart/smartField/SmartField";
import { IFormGroupDef } from "@components/smart/smartFormGroup/SmartFormGroup";
import { IToolbarItem } from "@components/toolbar";
import { getEntitySetByDocumentType } from "@odata/EntityTypes";
import {
    ClearingDocumentLinkEntity,
    DocumentAccountAssignmentEntity,
    DocumentDraftEntity,
    DocumentEntity,
    DocumentItemAccountAssignmentEntity,
    EntityTypeName,
    IClearingDocumentLinkEntity,
    IDocumentEntity,
    IProformaInvoiceIssuedEntity,
    IProformaInvoiceReceivedEntity,
    ProformaInvoiceIssuedEntity,
    ProformaInvoiceReceivedEntity,
    RegularDocumentItemEntity
} from "@odata/GeneratedEntityTypes";
import { ClearedStatusCode, DocumentTypeCode, SelectionCode, VatStatusCode } from "@odata/GeneratedEnums";
import { getEnumDisplayValue } from "@odata/GeneratedEnums.utils";
import { IFormatOptions } from "@odata/OData.utils";
import {
    hasEverBeenVatRegisteredCompany,
    isAccountAssignmentCompany,
    isCashBasisAccountingCompany,
    isVatRegisteredCompany
} from "@utils/CompanyUtils";
import { forEachKey, getValue } from "@utils/general";
import { logger } from "@utils/log";
import { endsWith } from "@utils/string";
import i18next from "i18next";
import { cloneDeep } from "lodash";
import React from "react";

import { IconSelectClean } from "../../../components/inputs/select/IconSelect";
import { IAppContext } from "../../../contexts/appContext/AppContext.types";
import { BasicInputSizes, FieldType, LabelStatus, QueryParam, ToolbarItemType, ValueType } from "../../../enums";
import { TRecordAny, TValue } from "../../../global.types";
import { Model } from "../../../model/Model";
import { TableStorage } from "../../../model/TableStorage";
import BindingContext, { createPath } from "../../../odata/BindingContext";
import { getQueryParameters } from "../../../routes/Routes.utils";
import DateType from "../../../types/Date";
import { FormStorage, IFormStorageDefaultCustomData } from "../../../views/formView/FormStorage";
import { ICustomButtonDefArgs, TableActionOrder } from "../../../views/table/TableToolbar.utils";
import {
    CreateCustomAssignmentPath,
    ItemCreateCustomAssignmentBc,
    processAccountAssignmentSelection,
    setDefaultAccountAssignment
} from "../../accountAssignment/AccountAssignment.utils";
import {
    correctVatSelectionFields,
    ITEMS_VAT_DEDUCTION_PATH,
    IVatClassificationExtendedEntity,
    SAVED_VATS_PATH,
    VAT_REVERSE_CHARGE_SELECT_PATH,
    VatSelectionFields
} from "../../admin/vatRules/VatRules.utils";
import { CREATE_RECEIPT_PATH } from "../../banks/bankAccounts/BankAccounts.utils";
import {
    FiscalPeriodForNumberRangePath,
    FiscalYearForNumberRangePath,
    NumberOursSummaryBindingContext,
    replaceWildcardsAfterDateChange
} from "../../numberRange/NumberRange.utils";
import { getItemBreadCrumbsText, IDefinition, TFieldsDefinition } from "../../PageUtils";
import {
    enhancedDocumentTypeWithDDOPP,
    getDocumentClearingTableDef,
    getDocumentPairedTable,
    getFiscalDataCorrespondingToDateAccountingTransaction,
    getFormDefGroup,
    hasMetadataRule,
    MetadataLockType
} from "../Document.utils";
import {
    addBankAccountTableDefs,
    addBusinessPartnerFieldDefs,
    addBusinessPartnerTableDefs,
    addCommonDocumentDefs,
    addTransactionAmountDue
} from "../DocumentCommonDefs";
import { addDateGroupDateField, dateAccountingCustomValidator } from "../DocumentDef";
import { DocumentFormViewForExtend } from "../DocumentFormView";
import { addItemsSummaryDef } from "../extensions/itemsSummary/ItemsSummary.utils";
import { PROFORMA_DOC_LINK_FILTER } from "../extensions/proforma/Proforma.utils";
import { addDateTaxableSupplyForAR } from "../invoicesIssued/InvoicesIssuedDef";
import { addDateIssued, addDateTaxableSupplyForAP } from "../invoicesReceived/InvoicesReceivedDef";

export type IProformaEntity = (IProformaInvoiceReceivedEntity | IProformaInvoiceIssuedEntity) & {
    [SAVED_VATS_PATH]?: TValue
};

export function isDDOP({ data, storage }: IGetValueArgs): boolean {
    const entity = (data ?? (storage as FormStorage).data?.entity) as IProformaEntity;
    return !!entity.IsTaxDocument;
}

export function isConvertingToDDOPP({ data, storage }: IGetValueArgs): boolean {
    const entity = (data ?? (storage as FormStorage).data?.entity) as IProformaEntity;
    const originalEntity = (storage as FormStorage).data?.origEntity as IProformaEntity;
    // isTaxDocument is set and wasn't set before -> converting to DDOPP
    return entity.IsTaxDocument && !originalEntity?.IsTaxDocument && !storage.data.bindingContext.isNew();
}

export const shouldNotAllowDDOPP = (storage: FormStorage): boolean => {
    // only care about new documents,
    // user could've switched company to vat payer, create document, and revert it back..
    // the created document should still be accessible
    const isNew = storage.data?.bindingContext?.isNew();
    // VatDeductionDate has to be set unless we can't make a decision...
    return isNew && isValidDate(storage.data.entity.DateVatDeduction) &&
        isDDOP({ storage }) && !hasEverBeenVatRegisteredCompany(storage.context);
};

export function isCleared({ storage }: IGetValueArgs): boolean {
    const doc = storage.data.entity as IProformaEntity;
    return [ClearedStatusCode.PartiallyCleared, ClearedStatusCode.Cleared].includes(doc.ClearedStatusCode as ClearedStatusCode);
}

// proformas, which are already linked to real invoice, can't be converted to DDOPP anymore
export function hasLinkedDocumentLock({ storage }: IGetValueArgs): boolean {
    return hasMetadataRule(storage as FormStorage, MetadataLockType.CannotEditLinkedProforma);
}

export function canConvertToDDOP(args: IGetValueArgs): boolean {
    if (!isVatRegisteredCompany(args.storage.context)) {
        return false;
    }
    const document = args.storage.data.entity as IProformaEntity;
    if (document.DocumentTypeCode === DocumentTypeCode.ProformaInvoiceReceived && document.BusinessPartner.VatStatusCode === VatStatusCode.NotVATRegistered) {
        return false;
    }
    const isCleared = (args.storage.data.entity as IProformaEntity).ClearedStatusCode === ClearedStatusCode.Cleared;
    return !isDDOP(args) && !hasLinkedDocumentLock(args) && isCleared && !args.storage.isDirty();
}

export enum ProformaType {
    Proforma = "Proforma",
    DDOP = "DDOP"
}

export const PROFORMA_ADD_BUTTON = "ProformaAddButton";

export function getToolbarAddButtonConfig(isReceived: boolean, storage: TableStorage, ref: React.RefObject<IconSelectClean>, args?: ICustomButtonDefArgs): IToolbarItem {
    const transFile = isReceived ? "ProformaInvoicesReceived" : "ProformaInvoicesIssued";
    const iconName = isReceived ? "ProformaInvoicesReceivedPlain" : "ProformaInvoicesIssuedPlain";
    return {
        id: PROFORMA_ADD_BUTTON,
        itemType: ToolbarItemType.IconSelect,
        iconName: "Add",
        label: i18next.t(`${transFile}:Table.NewDocument`),
        order: TableActionOrder.Add,
        itemProps: {
            isTransparent: true,
            headerText: i18next.t(`${transFile}:Table.NewDocument`),
            selectProps: {
                isActive: storage.data.addingRow || args?.isActive,
                isDisabled: !!storage.tableAction || args?.isDisabled,
                ref
            },
            items: [
                {
                    id: ProformaType.Proforma,
                    label: i18next.t(`${transFile}:Table.Proforma`),
                    iconName
                },
                {
                    id: ProformaType.DDOP,
                    label: i18next.t(`${transFile}:Table.DDOP`),
                    iconName: "TaxDocument"
                }
            ]
        }
    };
}

/**
 * Cleared documents is opposite relation to Clearing documents, so we display invoices,
 * which are cleared by current document
 */
export const CLEARED_DOCUMENTS_TAB = "ClearedDocuments";

function addClearedDocumentsTab(definition: IDefinition, hasAccountAssignment: boolean): void {
    const clearedDocumentsTab = {
        id: CLEARED_DOCUMENTS_TAB,
        title: i18next.t("Document:FormTab.ClearedDocuments"),
        table: getDocumentPairedTable({
            selectQuery: PROFORMA_DOC_LINK_FILTER,
            addAmountColumn: true,
            isCbaCompany: !hasAccountAssignment
        })
    };

    const tabsGroup: IFormGroupDef = definition.form.groups.find(group => group.id === "Tabs");
    tabsGroup.tabs.push(clearedDocumentsTab);
}

function addDateProcessed(definition: IDefinition): void {
    definition.table.columnDefinition.DateProcessed = {};
    definition.table.filterBarDef[0].filterDefinition = {
        ...definition.table.filterBarDef[0].filterDefinition,
        DateProcessed: {}
    };

    definition.form.fieldDefinition["DateProcessed"] = {
        isRequired: true, // todo: move to BE flag
        defaultValue: () => {
            return getWorkDate();
        }
    };
}

const DDOPP_NUMBER_SUFFIX = "-DD";

export function changeNumberOursSummary(definition: IDefinition): void {
    const numberOursSummaryDef = definition.form.summary.find(item => item.id === NumberOursSummaryBindingContext);

    const _origFormatter = numberOursSummaryDef.formatter;

    numberOursSummaryDef.formatter = (val: TValue, data?: IFormatOptions) => {
        const { storage } = data ?? {};
        const doc = (data?.entity ?? {}) as IDocumentEntity;
        if (isDDOP({
            data: doc,
            storage
        }) && doc.NumberRange?.Id && doc.NumberOurs && !endsWith(doc.NumberOurs, DDOPP_NUMBER_SUFFIX)) {
            return `${doc.NumberOurs}${DDOPP_NUMBER_SUFFIX}`;
        }
        return _origFormatter?.(val, data);
    };
}

function enhanceProformaItemsGroupDef(definition: IDefinition, context: IAppContext): void {
    addItemsSummaryDef(definition, context);
    const itemsGroup = getFormDefGroup(definition.form, "Items");

    // stores original summaryRenderer to local prop and wraps it to render the summary conditionally ->
    // if Vat fields are not visible (Vat is not calculated on proforma), we don't want to show the summary
    const summaryRenderer = itemsGroup.lineItems.itemsSummaryRenderer;
    itemsGroup.lineItems.itemsSummaryRenderer = (args, ...rest) => {
        if (hasVatFieldsVisible(args) || isDDOP(args)) {
            return getValue<React.ReactElement>(summaryRenderer, args, ...rest);
        }
        return null;
    };

    // it seems to work (not needed to be disabled) DEV-26258
    // itemsGroup.lineItems.isItemDisabled = ifAll(isDDOP, isCleared);
    // itemsGroup.lineItems.isAddDisabled = ifAll(isDDOP, isCleared);
}

function setCorrectBreadcrumbs(definition: IDefinition): void {
    definition.form.getItemBreadCrumbText = (storage: Model) => {
        const proforma = storage.data.entity as IProformaEntity;
        const prefix = proforma.IsTaxDocument ? "DDOPP" : "Proforma";
        return getItemBreadCrumbsText(storage, i18next.t(`Proforma:Breadcrumbs.${prefix}_NewInvoice`),
            storage.data.entity?.NumberOurs && i18next.t(`Proforma:Breadcrumbs.${prefix}_InvoiceWithNumber`, { number: storage.data.entity.NumberOurs }));
    };
}

async function getOldestClearingDate(storage: FormStorage) {
    let clearingDate: Date;
    try {
        const def = getDocumentClearingTableDef();
        const filter = getInfoValue(def, "filter", { storage });
        const query = storage.oData.getEntitySetWrapper(def.entitySet).query()
            .filter(filter)
            .select(ClearingDocumentLinkEntity.DateClearing)
            .orderBy(ClearingDocumentLinkEntity.DateClearing)
            .top(1);

        const result = await query.fetchData<IClearingDocumentLinkEntity[]>();
        clearingDate = result.value[0]?.DateClearing;
    } catch (e) {
        // fall silently
        logger.error("Clearing date fetch failed", e);
    }
    return clearingDate;
}

// Adds DateProcessed and DateIssued to date group
function rearrangeDateGroup(definition: IDefinition, docType: DocumentTypeCode): void {
    if (docType === DocumentTypeCode.ProformaInvoiceReceived) {
        addDateGroupDateField(definition.form, ProformaInvoiceReceivedEntity.DateIssued);
    }

    addDateGroupDateField(definition.form, ProformaInvoiceReceivedEntity.DateProcessed);

    const dateAccountingTransactionField = definition.form.fieldDefinition[DocumentEntity.DateAccountingTransaction];
    if (dateAccountingTransactionField) {
        dateAccountingTransactionField.defaultValue = () => getWorkDate();

        // proforma invoice doesn't have visible DateAccountingTransaction, so skip the validation
        definition.form.fieldDefinition[DocumentEntity.DateAccountingTransaction].validator.settings.customValidator = (value: TValue, args: IGetValueArgs) => {
            if (!isDDOP(args)) {
                return true;
            }
            return dateAccountingCustomValidator(value, args);
        };
    }
}


const accountAssignmentPath = "AccountAssignmentSelection/AccountAssignment";
// this fields are visible only for DDOP and are editable in this state (along with shared fields)
const DDOPP_FIELDS = [
    // AccountAssignment related fields
    accountAssignmentPath,
    createPath(accountAssignmentPath, DocumentAccountAssignmentEntity.DebitAccount),
    createPath(accountAssignmentPath, DocumentAccountAssignmentEntity.CreditAccount),
    createPath(accountAssignmentPath, DocumentAccountAssignmentEntity.Name),
    CreateCustomAssignmentPath,
    SAVED_VATS_PATH,
    VAT_REVERSE_CHARGE_SELECT_PATH,
    createPath(ProformaInvoiceIssuedEntity.Items, SAVED_VATS_PATH),
    createPath(ProformaInvoiceIssuedEntity.Items, ITEMS_VAT_DEDUCTION_PATH),
    createPath(ProformaInvoiceIssuedEntity.Items, accountAssignmentPath),
    createPath(ProformaInvoiceIssuedEntity.Items, accountAssignmentPath, ItemCreateCustomAssignmentBc),
    createPath(ProformaInvoiceIssuedEntity.Items, accountAssignmentPath, DocumentItemAccountAssignmentEntity.DebitAccount),
    createPath(ProformaInvoiceIssuedEntity.Items, accountAssignmentPath, DocumentItemAccountAssignmentEntity.CreditAccount),
    // Date fields
    ProformaInvoiceIssuedEntity.DateTaxableSupply,
    ProformaInvoiceIssuedEntity.DatePaymentReceived,
    ProformaInvoiceIssuedEntity.DateAccountingTransaction,
    ProformaInvoiceIssuedEntity.FiscalPeriod,
    ProformaInvoiceIssuedEntity.DateVatDeduction,
    // Vat selection fields
    ...VatSelectionFields.map(id => `VatClassificationSelection/VatClassification/${id}`)
];

// this fields are visible only for proforma
const PROFORMA_FIELDS: string[] = [
    ProformaInvoiceIssuedEntity.DateDue,
    ProformaInvoiceIssuedEntity.DateProcessed
];

// this fields are editable in both states DDOP and ProformaInvoice
const SHARED_FIELDS: string[] = [
    ProformaInvoiceIssuedEntity.DateIssued,
    ProformaInvoiceReceivedEntity.DateReceived
];

// Fields, which are hidden when we don't calculate VAT on Proforma
const VAT_RELATED_FIELDS = [
    createPath(ProformaInvoiceIssuedEntity.Items, RegularDocumentItemEntity.Vat),
    createPath(ProformaInvoiceIssuedEntity.Items, RegularDocumentItemEntity.TransactionAmountNet),
    createPath(ProformaInvoiceIssuedEntity.Items, RegularDocumentItemEntity.TransactionAmountVat)
];


function setFieldFlags(definition: IDefinition) {
    const { fieldDefinition } = definition.form;

    forEachKey(fieldDefinition, (id) => {
        switch (true) {
            case DDOPP_FIELDS.includes(id):
                fieldDefinition[id].isVisible = ifAll(isDDOP, fieldDefinition[id].isVisible);
                // todo: useForCustomization cannot be conditional - it's same form, if user customize DDOPP,
                //  these fields will be then missing on proforma form and vice versa
                // if (isNotDefined(fieldDefinition[id].customizationData?.useForCustomization)) {
                //     fieldDefinition[id].customizationData = {
                //         useForCustomization: isDDOP
                //     };
                // }
                break;
            case PROFORMA_FIELDS.includes(id):
                fieldDefinition[id].isVisible = ifAll(not(isDDOP), fieldDefinition[id].isVisible);
                // if (isNotDefined(fieldDefinition[id].customizationData?.useForCustomization)) {
                //     fieldDefinition[id].customizationData = {
                //         useForCustomization: not(isDDOP)
                //     };
                // }
                break;
            case VAT_RELATED_FIELDS.includes(id):
                // VAT related fields are visible on DDOP or Proforma if Vat calculation is turned on
                fieldDefinition[id].isVisible = ifAll(fieldDefinition[id].isVisible, ifAny(hasVatFieldsVisible, isDDOP));
                if (id !== "Items/Vat") {
                    // User can change only Vat. Amounts should be automatically calculated when DDOPP is created
                    // from Proforma, because the whole paid amount must remain same.
                    fieldDefinition[id].isReadOnly = ifAny(fieldDefinition[id].isReadOnly, isConvertingToDDOPP);
                }
                break;
            case SHARED_FIELDS.includes(id):
                // shared fields are visible/read-only as they are originally defined, no change here
                break;
            default:
                // rest of the fields are visible and editable on Proforma, read-only on DDOPP
                fieldDefinition[id].isReadOnly = ifAny(fieldDefinition[id].isReadOnly, isConvertingToDDOPP);
                break;
        }
    });
}

/**
 * Function to prepare common proforma (received/issued) definitions
 * @param definition
 * @param docType
 * @param transFile
 * @param context
 */
export function enhanceWithCommonProformaDefinitions(definition: IDefinition, docType: DocumentTypeCode, transFile: string, context: IAppContext): void {
    addCommonDocumentDefs(definition, docType);
    const hasAccountAssignment = isAccountAssignmentCompany(context);
    addBankAccountTableDefs(definition);
    addBusinessPartnerTableDefs(definition);
    addBusinessPartnerFieldDefs(definition, docType, transFile);

    addDateIssued(definition, true);
    addDateProcessed(definition);

    definition.table.filterBarDef[0].filterDefinition = {
        ...definition.table.filterBarDef[0].filterDefinition,
        IsTaxDocument: {
            type: FieldType.MultiSelect,
            valueType: ValueType.Boolean,
            isValueHelp: false,
            label: i18next.t("Proforma:DDOPP"),
            fieldSettings: {
                items: getSimpleBoolSelectItems()
            }
        }
    };

    if (docType === DocumentTypeCode.ProformaInvoiceReceived) {
        addDateTaxableSupplyForAP(definition, hasAccountAssignment, context, true);
    } else {
        addDateTaxableSupplyForAR(definition, context, false, true);
        definition.form.fieldDefinition[ProformaInvoiceIssuedEntity.DatePaymentReceived] = {
            defaultValue: () => (getWorkDate()),
            isRequired: true
        };
        addDateGroupDateField(definition.form, ProformaInvoiceIssuedEntity.DatePaymentReceived);
    }

    rearrangeDateGroup(definition, docType);
    // todo: check if addAmountsTableDefs should not be called instead
    addTransactionAmountDue(definition, true);

    enhanceProformaItemsGroupDef(definition, context);
    setCorrectBreadcrumbs(definition);

    // proforma fields
    addInvoiceTypeSwitch(definition, context);
    addIsTaxDocumentFlag(definition);
    addIsTaxDocumentTableDefs(definition);

    changeNumberOursSummary(definition);

    setFieldFlags(definition);

    // Proforma has different definition of Cleared status than CREATE_RECEIPT_PATH expects
    // update isVisible callback
    const origIsVisible = definition.form.fieldDefinition[CREATE_RECEIPT_PATH].isVisible;
    definition.form.fieldDefinition[CREATE_RECEIPT_PATH].isVisible = (args: IGetValueArgs) => {
        if (isCleared(args)) {
            return false;
        }

        return getValue(origIsVisible, args);
    };

    if (hasAccountAssignment) {
        // set default account assignment for DDOPP
        let Number: string, Name: string;
        if (docType === DocumentTypeCode.ProformaInvoiceIssued) {
            Number = "324";
            Name = "Přijaté provozní zálohy";
        } else {
            Number = "314";
            Name = "Poskytnuté zálohy - dlouhodobé a krátkodobé";
        }
        definition.form.fieldDefinition["AccountAssignmentSelection/AccountAssignment"].defaultValue = {
            DebitAccount: { Number },
            CreditAccount: { Number },
            Name
        };
    }

    addClearedDocumentsTab(definition, hasAccountAssignment);
}

export const CALCULATE_VAT_PATH = ProformaInvoiceIssuedEntity.CalculateVat;
const VAT_INFO_PATH = BindingContext.localContext("VatInfo");

function addInvoiceTypeSwitch(definition: IDefinition, context: IAppContext): void {
    if (!isVatRegisteredCompany(context)) {
        return;
    }

    const { fieldDefinition } = definition.form;

    // switch definition
    fieldDefinition[CALCULATE_VAT_PATH] = {
        type: FieldType.Switch,
        label: i18next.t("Proforma:Form.CalculateVat"),
        isVisible: not(isDDOP),
        fieldSettings: {
            type: SwitchType.YesNo
        },
        customizationData: {
            useForCustomization: false
        }
    };

    fieldDefinition[VAT_INFO_PATH] = {
        labelStatus: LabelStatus.Removed,
        isReadOnly: true,
        isVisible: ifAll(hasVatFieldsVisible, not(isDDOP)),
        formatter: () => i18next.t("Proforma:Form.VatInfo").toString(),
        customizationData: {
            useForCustomization: false
        }
    };

    const itemsGroup = getFormDefGroup(definition.form, "Items");
    itemsGroup.rows = [[
        { id: CALCULATE_VAT_PATH, isCollectionField: false },
        { id: VAT_INFO_PATH, isCollectionField: false }
    ]];
}

export interface IIsTaxDocumentCustomData extends IFormStorageDefaultCustomData {
    IsDDOP?: boolean;
}

function addIsTaxDocumentTableDefs(definition: IDefinition): void {
    const commonSettings: IFieldInfoProperties = {
        label: i18next.t("Proforma:ProformaType"),
        formatter: (val: TValue, args: IFormatOptions) => {
            return i18next.t(`Proforma:${val ? "DDOPP" : "Proforma"}`).toString();
        }
    };
    definition.table.columnDefinition = {
        ...definition.table.columnDefinition,
        IsTaxDocument: {
            id: "IsTaxDocument",
            ...commonSettings
        }
    };

    definition.table.filterBarDef[0].filterDefinition = {
        ...definition.table.filterBarDef[0].filterDefinition,
        "IsTaxDocument": {
            ...commonSettings,
            width: BasicInputSizes.M
        }
    };
}

function addIsTaxDocumentFlag(definition: IDefinition): void {
    const { fieldDefinition, additionalProperties } = definition.form;

    fieldDefinition.IsTaxDocument = {
        isVisible: false,
        clearIfInvisible: false,
        defaultValue: (args: IGetValueArgs) => {
            const isDDOP = (args.storage as FormStorage<unknown, IIsTaxDocumentCustomData>).getCustomData().IsDDOP;
            return isDDOP || getQueryParameters()[QueryParam.Type] === ProformaType.DDOP;
        },
        customizationData: {
            useForCustomization: false
        }
    };

    additionalProperties.push({ id: "IsTaxDocument" });
}

export function hasVatFieldsVisible(args: IGetValueArgs): boolean {
    const { storage } = args;
    return !!storage.getValue(storage.data.bindingContext.navigate(CALCULATE_VAT_PATH));
}


/**
 * ---- FormView callbacks ----
 */

export function handleInvoiceTypeSwitchChange(storage: FormStorage, e: ISmartFieldChange): void {
    if (e.bindingContext.getPath() === CALCULATE_VAT_PATH) {
        // refresh all items to recalculate fields visibility
        storage.refresh();
    }
}

export async function handleDateProcessedChange(formView: DocumentFormViewForExtend, e: ISmartFieldChange): Promise<void> {
    const path = e.bindingContext.getPath();
    if (formView.datePropForCurrencyExchangeRate === DocumentEntity.DateAccountingTransaction) {
        // DateAccountingTransaction change is handled in base DocumentFormView
        return;
    }
    if (path === formView.datePropForCurrencyExchangeRate && e.triggerAdditionalTasks) {
        const { storage } = formView.props;

        const isValid = !await storage.validateField(e.bindingContext);
        if (!isValid || !e.value) {
            return;
        }

        await formView.refreshCurrencyExchangeRateAfterDateChange();

        storage.refreshFields();
    }
}

export async function handleCalculateVatChange(formView: DocumentFormViewForExtend, e: ISmartFieldChange): Promise<void> {
    if (e.bindingContext.getPath() === CALCULATE_VAT_PATH) {
        // refresh all items to recalculate fields visibility
        formView.recalculateAllItems(RegularDocumentItemEntity.TransactionAmount, true, true);
    }
}

export function handleDocumentSpecificBlurHandlers(formView: DocumentFormViewForExtend, e: ISmartFieldBlur, isIssued: boolean): void {
    const { storage } = formView.props;
    const path = e.bindingContext.getPath();
    const value = storage.getValue(e.bindingContext);
    const _isDDOPP = isDDOP({ storage });
    const ProformaEntity = isIssued ? ProformaInvoiceIssuedEntity : ProformaInvoiceReceivedEntity;

    const shouldSyncDateProcessed = storage.data.bindingContext.isNew();
    if (e.wasChanged && DateType.isValid(value)) {
        if (path === formView.datePropForNumberRange && _isDDOPP && shouldSyncDateProcessed) {
            // keep dateProcessed in sync
            storage.setValueByPath(ProformaEntity.DateProcessed, value);
        }
    }
}

export function getSuccessMessage(storage: FormStorage, isPosted: boolean, isClosed: boolean): string {
    const namespace = storage.data.definition.translationFiles[0];
    const prefix = isDDOP({ storage }) ? "DDOP" : "Proforma";
    let key: string;
    if (isPosted) {
        key = isClosed ? "SuccessSubtitlePostedClosedFiscalYear" : "SuccessSubtitlePosted";
    } else {
        key = "Saved";
    }
    return storage.t(`${namespace}:Validation.${prefix}_${key}`).toString();
}

export function getProformaFormTitle(storage: FormStorage): string {
    const _isDDOP = isDDOP({ storage });
    return storage.t(`${storage.data.definition.translationFiles[0]}:${_isDDOP ? "DDOP" : "Proforma"}_TitleSingular`).toString();
}

export function setFiscalYearForNumberRangeWithoutFiscalYear(storage: FormStorage, date: Date): boolean {
    const fiscalData = getFiscalDataCorrespondingToDateAccountingTransaction(storage, date);
    const currentFY = storage.getValueByPath(FiscalYearForNumberRangePath);
    // Period may change even if FY hasn't, keep it out of the if
    storage.setValueByPath(FiscalPeriodForNumberRangePath, fiscalData.fiscalPeriod ?? {});
    storage.setValueByPath(FiscalYearForNumberRangePath, fiscalData.fiscalYear ?? {});

    return currentFY?.Id !== fiscalData.fiscalYear?.Id;
}

export const setMatchingNumberRangeForDocumentWithoutFiscalYear = (storage: FormStorage, date: Date, forceReload?: boolean): void => {
    // set FY for NumberRange
    // set actual FY specific for NumberRange according to DateProcessed instead on DateAccountingTransaction
    const didChange = setFiscalYearForNumberRangeWithoutFiscalYear(storage, date);

    if (!didChange && !forceReload) {
        replaceWildcardsAfterDateChange(storage);
    }
};


export function onBeforeSave(storage: FormStorage, data: IProformaEntity, formView: DocumentFormViewForExtend): IProformaEntity {
    data = data ?? cloneDeep(storage.data.entity);

    if (!data.IsTaxDocument) {
        data.VatClassificationSelection = null;
        (data as IVatClassificationExtendedEntity)[SAVED_VATS_PATH] = null;
        data.AccountAssignmentSelection = null;
        data.DateTaxableSupply = null;
        data.DateAccountingTransaction = null;
        data.DateVatDeduction = null;
        data.FiscalPeriod = null;

        data.Items.forEach(item => {
            if (!data.CalculateVat) {
                // clear Vat fields if not calculated
                item.Vat = null;
                item.VatCode = null;
                item.TransactionAmountNet = item.TransactionAmount;
                item.TransactionAmountVat = 0;
                item.TransactionUnitPriceNet = item.TransactionUnitPrice;
                item.TransactionUnitPriceVat = 0;
            }
            item.VatClassificationSelection = null;
            (item as IVatClassificationExtendedEntity)[SAVED_VATS_PATH] = null;
            item.AccountAssignmentSelection = null;
        });
    } else {
        // https://solitea-cz.atlassian.net/wiki/spaces/IRIS/pages/2314764398/F0023.1+-+pravy+datumov+ch+field
        // DateDue is supposed to be hidden, but BE fails if it is empty
        if (!data?.DateDue) {
            data.DateDue = data.DateIssued;
        }
        // DateProcessed might not be set when DDOPP is created as draft on BE,
        // so we must fill it on save as it is required (and not visible on DDOPP form)
        if (!data?.DateProcessed) {
            data.DateProcessed = storage.getValueByPath(formView.datePropForNumberRange);
        }
    }

    return data;
}

export async function onAfterLoad(storage: FormStorage, formView: DocumentFormViewForExtend): Promise<void> {
    if (!storage.data.bindingContext.isNew()) {
        // For new entities, setMatchingNumberRangeForDocument is called which also sets the FY for NumberRange.
        // Call it here just for the old ones.
        setFiscalYearForNumberRangeWithoutFiscalYear(storage, storage.getEntity()[formView.datePropForNumberRange]);
    } else {
        // init new document
        if (isDDOP({ storage }) && isAccountAssignmentCompany(storage.context)) {
            await setDefaultAccountAssignment(storage, getEntitySetByDocumentType(formView.documentTypeCode));
        }
    }
}

export async function handleConvertToDDOP(formView: DocumentFormViewForExtend): Promise<void> {
    const { storage } = formView.props;
    storage.setBusy(true);
    // mark the storage as dirty, so there is "change" button in the form footer
    storage.setDirty(storage.data.bindingContext);

    const clearingDate = (await getOldestClearingDate(storage)) ?? getWorkDate();

    // set correct data to storage for DDOPP
    storage.setValueByPath(ProformaInvoiceIssuedEntity.IsTaxDocument, true);
    if (isAccountAssignmentCompany(storage.context)) {
        storage.setDefaultValueByPath(ProformaInvoiceIssuedEntity.DateAccountingTransaction);
        for (const item of storage.data.entity.Items) {
            processAccountAssignmentSelection(item, true, true);
        }

        await setDefaultAccountAssignment(storage, getEntitySetByDocumentType(formView.documentTypeCode), true);
    }

    storage.setDefaultValueByPath(DocumentDraftEntity.DateVatDeduction);
    storage.setDefaultValueByPath(DocumentDraftEntity.DateTaxableSupply);
    if (storage.data.entity.DocumentTypeCode === DocumentTypeCode.ProformaInvoiceIssued) {
        storage.setValueByPath(ProformaInvoiceIssuedEntity.DatePaymentReceived, clearingDate);
    }
    const dateProp = isCashBasisAccountingCompany(storage.context) ? ProformaInvoiceIssuedEntity.DateCbaDocument : ProformaInvoiceIssuedEntity.DateAccountingTransaction;
    storage.setValueByPath(dateProp, clearingDate);

    for (const item of storage.data.entity.Items) {
        item[SAVED_VATS_PATH] = SelectionCode.Default;
        item[ITEMS_VAT_DEDUCTION_PATH] = SelectionCode.Default;
    }

    const reDefaultVat = storage.getValueByPath(CALCULATE_VAT_PATH) !== true;
    formView.recalculateAllItems(formView.defaultItemLastChangedProp, true, reDefaultVat);

    await Promise.all([
        formView.refreshFormAfterDecisiveDateChange(formView.datePropForNumberRange, true),
        correctVatSelectionFields({
            storage,
            documentTypeCode: formView.documentTypeCode,
            forceDefaultValue: true
        })
    ]);

    // validate all fields in the form to show possible errors (or hide previous ones, which are not valid anymore)
    await storage.validateAll();

    storage.handleFirstChange();

    // refresh also footer buttons
    storage.setBusy(false, true);
}

export function enhancedDocumentTypeByDDOPPFilterFormatter(val: TValue, args: IFormatOptions): string {
    // val can be either object {Code, Name} or direct code
    const code = (val as TRecordAny)?.Code ?? val;

    if ([DocumentTypeCode.ProformaInvoiceReceived, DocumentTypeCode.ProformaInvoiceIssued].includes(code as DocumentTypeCode)) {
        const ddoppSuffix = isVatRegisteredCompany(args.context) ? "OrDDOPP" : "";
        return i18next.t(`Document:Types.${code}${ddoppSuffix}`, { count: 1 }).toString();
    }
    return getEnumDisplayValue(EntityTypeName.DocumentType, code);
}

export function enhancedDocumentTypeByDDOPPFormatter(val: TValue, args: IFormatOptions): string {
    const entity = args.item as IDocumentEntity;
    return enhancedDocumentTypeWithDDOPP(entity, val as string);
}

export const getEnhancedDocumentTypeByDDOPPDef = (): TFieldsDefinition => ({
    DocumentType: {
        fieldSettings: {
            displayName: "Name"
        },
        formatter: enhancedDocumentTypeByDDOPPFormatter
    }
});