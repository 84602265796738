import { ISelectItem } from "@components/inputs/select/Select.types";
import { IFieldDefFn, IGetValueArgs } from "@components/smart/FieldInfo";
import { withDisplayName } from "@components/smart/GeneralFieldDefinition";
import {
    FilterBarGroup,
    getDefaultFilterGroupDef,
    IFilterGroupDef
} from "@components/smart/smartFilterBar/SmartFilterBar.types";
import { ISummaryItem } from "@components/smart/smartSummaryItem/SmartSummaryItem";
import { EntitySetName, EntityTypeName, ICommunicationEntryEntity } from "@odata/GeneratedEntityTypes";
import { CommunicationEntryChannelCode, CommunicationEntryTypeCode } from "@odata/GeneratedEnums";
import { getEnumDisplayValue, getEnumNameSpaceName } from "@odata/GeneratedEnums.utils";
import { IFormatOptions } from "@odata/OData.utils";
import { removeWhiteSpace } from "@utils/string";
import i18next from "i18next";
import React, { lazy, Suspense } from "react";

import BusyIndicator from "../../components/busyIndicator";
import { FieldType, LabelStatus, Sort } from "../../enums";
import { TValue } from "../../global.types";
import { Model } from "../../model/Model";
import BindingContext from "../../odata/BindingContext";
import TestIds from "../../testIds";
import { formatCurrency } from "../../types/Currency";
import { IFormDef } from "../../views/formView/Form";
import { ISplitPageTableDef } from "../../views/table/TableView.utils";
import { setDefByEntityType } from "../getDefByEntityType";
import { IDefinition, IGetDefinition } from "../PageUtils";
import { StyledMessageContent } from "./Messages.styles";
import MessagesFormView from "./MessagesFormView";

const Linkify = lazy(() => import("linkify-react"));

export const isEmail = (args: IGetValueArgs): boolean => {
    return args.storage.data.entity.ChannelCode === CommunicationEntryChannelCode.Email;
};

export const isDataBox = (args: IGetValueArgs): boolean => {
    return args.storage.data.entity.ChannelCode === CommunicationEntryChannelCode.DataMessage;
};

export const isBankApi = (args: IGetValueArgs): boolean => {
    return args.storage.data.entity.ChannelCode === CommunicationEntryChannelCode.BankAPI;
};

const messageIdFormatter = (entity: ICommunicationEntryEntity) => {
    return `${getEnumDisplayValue(EntityTypeName.CommunicationEntryChannel, entity.ChannelCode)} ${entity.MessageId ?? ""}`;
};

export const getDefinitions: IGetDefinition = (): IDefinition => {
    const filterBarDef: IFilterGroupDef[] = [{
        ...getDefaultFilterGroupDef(FilterBarGroup.Filters),
        defaultFilters: [
            "DateSent",
            "Recipient",
            "Sender",
            "Channel"
        ],
        filterDefinition: {
            ...withDisplayName("User"),
            DateSent: {},
            Recipient: {},
            Sender: {},
            ...withDisplayName("Channel"),
            RecipientAddress: {
                filterName: ["", "/SenderAddress"]
            },
            RecipientDataboxId: {
                filterName: ["", "/SenderDataboxId"]
            },
            RecipientEmail: {
                filterName: ["", "/SenderEmail"]
            },
            RecipientLegalNumber: {
                filterName: ["", "/SenderLegalNumber"]
            },
            DateLastModified: {},
            MessageId: {},
            Subject: {}
        },
        isValueHelp: true
    }];

    const table: ISplitPageTableDef = {
        id: `${EntityTypeName.CommunicationEntry}Table`,
        filterBarDef,
        filter: `TypeCode eq '${CommunicationEntryTypeCode.Sent}'`,
        columns: [
            "DateSent",
            "Channel",
            "Subject",
            "Recipient"
        ],
        columnDefinition: {
            DateSent: { id: "DateSent" },
            Recipient: { id: "Recipient" },
            Subject: { id: "Subject" },
            Sender: { id: "Sender" },
            ...withDisplayName("Channel")
        },
        title: i18next.t("Messages:Title"),
        initialSortBy: [{ id: "DateSent", sort: Sort.Desc }]
    };

    const summary: ISummaryItem[] = [{
        id: "MessageId",
        additionalData: "ChannelCode",
        formatter: (val, args) => {
            return messageIdFormatter(args.entity);
        }
    }, {
        id: "Subject",
        label: i18next.t("Messages:Subject")
    }];

    const files = {
        collection: "Attachments",
        property: "File"
    };

    const form: IFormDef = {
        id: `${EntityTypeName.CommunicationEntry}Form`,
        title: i18next.t("Messages:SentMessage"),
        summary,
        files,
        isReadOnly: true,
        getItemBreadCrumbText: (storage: Model) => messageIdFormatter(storage.data.entity),
        translationFiles: getDefinitions.translationFiles,
        formControl: MessagesFormView,
        additionalProperties: [
            { id: "TypeCode" },
            { id: "ChannelCode" }, {
                id: "Attachments",
                additionalProperties: [{
                    id: "File",
                    additionalProperties: [
                        { id: "Name" },
                        { id: "Size" }, { id: "DateCreated" }, { id: "DateLastModified" }]
                }]
            }],
        fieldDefinition: {
            "User/Name": {
                isVisible: (args: IGetValueArgs) => {
                    return args.storage.data.entity.ChannelCode === CommunicationEntryChannelCode.DataMessage;
                }
            },
            Recipient: {
                isVisible: isDataBox
            },
            RecipientAddress: {
                isVisible: isDataBox
            },
            RecipientEmail: {
                isVisible: isEmail,
                formatter: (val: TValue) => {
                    return (val as string)?.split(";")?.[0];
                }
            },
            [BindingContext.localContext("EmailCopies")]: {
                label: i18next.t("Messages:Copies"),
                isVisible: (args: IGetValueArgs) => {
                    const entity = args.storage.data.entity;
                    return entity.RecipientEmail?.split(";").length > 1 && entity.ChannelCode === CommunicationEntryChannelCode.Email;
                },
                formatter: (val: TValue, args) => {
                    const copies = removeWhiteSpace(args.entity.RecipientEmail)?.split(";");
                    copies?.shift();
                    return copies?.join("\n");
                }
            },
            RecipientDataboxId: {
                isVisible: isDataBox
            },
            RecipientLegalNumber: {
                isVisible: isDataBox
            },
            Sender: {},
            SenderAddress: {
                isVisible: isDataBox
            },
            SenderDataboxId: {
                isVisible: isDataBox
            },
            SenderEmail: {
                isVisible: isEmail
            },
            SenderLegalNumber: {
                isVisible: isDataBox
            },
            DateSent: {},
            Text: {
                type: FieldType.Custom,
                labelStatus: LabelStatus.Removed,
                render: (args: IFieldDefFn) => {
                    const val = args.storage.getValueByPath("Text");
                    return (<StyledMessageContent data-testid={TestIds.MessageContent}>
                        <Suspense fallback={<BusyIndicator/>}>
                            <Linkify options={{ attributes: { target: "blank" } }}>
                                {(val as string)?.split("\\n").map((row, idx) => {
                                    return (
                                        <span key={idx}>
                                            {row}
                                            <br/>
                                        </span>
                                    );
                                })}
                            </Linkify>
                        </Suspense>
                    </StyledMessageContent>);
                }
            },
            RecipientBankName: {
                isVisible: isBankApi
            },
            TimeSent: {
                isVisible: isBankApi
            },
            PaymentOrderAmount: {
                additionalProperties: [{ id: "/PaymentOrderCurrency" }],
                formatter: (val: TValue, args: IFormatOptions) => {
                    return formatCurrency(val, args.entity.PaymentOrderCurrency);
                },
                isVisible: isBankApi
            },
            [BindingContext.localContext("RecipientBankAccount")]: {
                label: i18next.t("Messages:AccountNumber"),
                type: FieldType.ComboBox,
                additionalProperties: [{
                    id: "RecipientBankAccountNumber"
                }, {
                    id: "RecipientBankAccountName"
                }, {
                    id: "RecipientBankAccountCurrency"
                }],
                fieldSettings: {
                    shouldDisplayAdditionalColumns: true,
                    itemsFactory: async (args: IGetValueArgs): Promise<ISelectItem[]> => {
                        const {
                            RecipientBankAccountNumber,
                            RecipientBankAccountName,
                            RecipientBankAccountCurrency
                        } = args.storage.data.entity;
                        return [{
                            id: 1,
                            label: RecipientBankAccountNumber,
                            tabularData: [RecipientBankAccountCurrency, RecipientBankAccountName]
                        }];
                    }
                },
                columns: [
                    { id: "RecipientBankAccountNumber" },
                    { id: "RecipientBankAccountCurrency" },
                    { id: "RecipientBankAccountName" }
                ],
                isVisible: isBankApi
            }
        },
        groups: [{
            id: "sender",
            title: i18next.t("Messages:SenderGroupTitle"),
            rows: [[{ id: "SenderEmail" }], [{ id: "Sender" }, { id: "SenderLegalNumber" }, { id: "SenderDataboxId" }, { id: "SenderAddress" },
                { id: "User/Name" }, { id: "DateSent" }, { id: "TimeSent" }]]
        }, {
            id: "recipient",
            title: i18next.t("Messages:ReceiverGroupTitle"),
            rows: [[{ id: "RecipientBankName" }, { id: "Recipient" }, { id: "RecipientLegalNumber" }, { id: "RecipientDataboxId" }, { id: "RecipientEmail" },
                { id: BindingContext.localContext("EmailCopies") }, { id: "RecipientAddress" }]]
        }, {
            id: i18next.t("Messages:Messages"),
            title: i18next.t("Messages:Message"),
            rows: [[{ id: BindingContext.localContext("RecipientBankAccount") }, { id: "PaymentOrderAmount" }, { id: "Text" }]]
        }]
    };

    return {
        entitySet: EntitySetName.CommunicationEntries,
        table,
        form
    };
};

getDefinitions.translationFiles = [
    "Messages",
    "Error",
    getEnumNameSpaceName(EntityTypeName.CommunicationEntryChannel)
];
setDefByEntityType(EntityTypeName.CommunicationEntry, getDefinitions);
