import React, { useContext } from "react";
import {
    AddButtonWrapper,
    ColumnContentWrapper,
    ColumnLabelWrapper,
    GroupList,
    GroupPlaceholder,
    Spacer,
    StyledConfigurationColumn
} from "./ConfigurationList.styles";
import { Label } from "../inputs/field";
import { Droppable, DroppableStateSnapshot } from "react-beautiful-dnd";
import { GroupListDropType } from "../../enums";
import {
    connectToContext,
    CustomDnDContext,
    IGroupListGroupDef,
    IGroupListItemDef,
    IPlaceholderPos,
    isCopy,
    trimCopyId
} from "./ConfigurationList";
import { TRecordType } from "../../global.types";
import { Group } from "./Group";
import TestIds from "../../testIds";
import { composeRefHandlers, isObjectEmpty } from "@utils/general";
import { ScrollBar } from "../scrollBar";
import { WithDomManipulator, withDomManipulator } from "../../contexts/domManipulator/withDomManipulator";
import { useTranslation } from "react-i18next";
import { PlusIcon } from "../icon";
import { Button } from "../button";
import i18next from "i18next";

export interface IProps {
    id: string;
    index: number;
    label: string;
    isDropDisabled?: boolean;
    groups: IGroupListGroupDef[];
    items: TRecordType<IGroupListItemDef>;
    shouldShowAddButton?: boolean;
    // Allows items that has "boundTo: ConfigListItemBoundType.Column" to be moved away from this Column.
    // Used for the available fields in form customization dialog
    cannotBeBoundTo?: boolean;
    onGroupAddClick?: (columnId: string) => void;
    onGroupRemoveClick?: (groupId: string) => void;
    onGroupLabelChange?: (groupId: string, label: string) => void;
    /** Position of custom group placeholder */
    placeholder?: IPlaceholderPos;
    /** Custom content rendered at the bottom of the group */
    afterContent?: React.ReactElement;
}

class Column extends React.PureComponent<IProps & WithDomManipulator> {
    itemsCache: TRecordType<IGroupListItemDef>;
    groupsCache: IGroupListGroupDef[];
    groupsItemsCache: TRecordType<IGroupListItemDef[]> = {};

    _columnRef = React.createRef<HTMLDivElement>();
    _groupListRef = React.createRef<HTMLDivElement>();

    componentDidMount() {
        this.updateCache();

        this.updateScrollWidth();
    }

    componentDidUpdate(prevProps: IProps & WithDomManipulator) {
        this.updateCache();

        this.updateScrollWidth();
    }

    updateScrollWidth = () => {
        // SimpleBar doesn't update its width properly (browser scroll works ok...)
        // => update manually
        this.props.domManipulatorOrchestrator.registerCallback(
                () => {
                    if (this._groupListRef.current) {
                        return this._groupListRef.current.clientWidth;
                    }

                    return null;
                },
                (groupListWidth: number) => {
                    if (groupListWidth) {
                        (this._columnRef.current.children[1] as HTMLDivElement).style.width = `${groupListWidth}px`;
                    }
                },
                [this._groupListRef, this._columnRef]
        );
    };

    updateCache = () => {
        this.itemsCache = this.props.items;
        this.groupsCache = this.props.groups;
    };

    getGroupItems = (group: IGroupListGroupDef) => {
        if (
                !this.groupsItemsCache[group.id]
                || this.itemsCache !== this.props.items
                || this.groupsCache?.find(g => g.id === group.id) !== group
        ) {
            this.groupsItemsCache[group.id] = this.getMemoizedGroupsItems(group);
        }

        return this.groupsItemsCache[group.id];
    };

    getMemoizedGroupsItems = (group: IGroupListGroupDef) => {
        return group.itemIds.map((itemId) => {
            // we can either give ConfigurationList standalone item object for the clone item (in some custom handler)
            // or the original item is copied automatically (with isCopyOnly set to false)
            if (isCopy(itemId) && !this.props.items[itemId]) {
                return {
                    ...this.props.items[trimCopyId(itemId)],
                    isCopyOnly: false,
                    id: itemId
                };
            } else {
                return this.props.items[itemId];
            }
        });
    };


    handleAddClick = () => {
        this.props.onGroupAddClick?.(this.props.id);
    };

    renderCustomPlaceholder = (snapshot: DroppableStateSnapshot) => {
        if (!this.props.placeholder) {
            return null;
        }
        return (
                <>
                    {!isObjectEmpty(this.props.placeholder) && snapshot.isDraggingOver && (
                            <GroupPlaceholder
                                    style={{
                                        top: this.props.placeholder.clientY,
                                        left: this.props.placeholder.clientX,
                                        height: this.props.placeholder.clientHeight,
                                        width: this.props.placeholder.clientWidth
                                    }}/>
                    )}
                </>
        );
    };

    render() {
        return (
                <StyledConfigurationColumn ref={this._columnRef}>
                    <ColumnLabelWrapper isFirstGroupSmall={this.props.groups[0]?.isTransparent}>
                        <Label>{this.props.label}</Label>
                    </ColumnLabelWrapper>

                    <Droppable droppableId={this.props.id}
                               type={GroupListDropType.Group}
                               isDropDisabled={this.props.isDropDisabled}>
                        {(provided, snapshot) => {
                            return (
                                    <ScrollBar
                                            style={{
                                                // subtract 28px for the column label wrapper and its margin
                                                height: "calc(100% - 28px)",
                                                overflowX: "visible",
                                                minWidth: "276px"
                                            }}
                                    >
                                        <ColumnContentWrapper>
                                            <GroupList
                                                    {...provided.droppableProps}
                                                    ref={composeRefHandlers(provided.innerRef, this._groupListRef)}
                                                    data-testid={TestIds.ConfigurationColumn}>
                                                {this.props.groups.map((group, index) => {
                                                    const items = this.getGroupItems(group);
                                                    return (
                                                            <Group key={group.id} index={index}
                                                                   items={items}
                                                                   onRemoveClick={this.props.onGroupRemoveClick}
                                                                   onLabelChange={this.props.onGroupLabelChange}
                                                                   {...group}
                                                            />
                                                    );
                                                })}
                                                {provided.placeholder}
                                                {this.renderCustomPlaceholder(snapshot)}
                                            </GroupList>
                                            {this.props.shouldShowAddButton &&
                                                    <AddButtonWrapper>
                                                        <AddButton onClick={this.handleAddClick}
                                                                   label={i18next.t("Components:ConfigurationList.AddGroup")}/>
                                                    </AddButtonWrapper>
                                            }
                                            {this.props.afterContent}
                                            <Spacer/>
                                        </ColumnContentWrapper>
                                    </ScrollBar>
                            );
                        }}
                    </Droppable>
                </StyledConfigurationColumn>
        );
    }
}

const SelectGroupPlaceholderFromCustomDnDContext = (props: IProps) => {
    const { placeholder } = useContext(CustomDnDContext);

    return placeholder?.droppableId === props.id ? { placeholder } : null;
};

const WrappedColumn = withDomManipulator(connectToContext(Column, SelectGroupPlaceholderFromCustomDnDContext));
export { WrappedColumn as Column };

export const AddButton = (props: {
    isLight?: boolean;
    label?: string;
    onClick: () => void
}) => {
    const { t } = useTranslation("Components");
    return (
            <Button onClick={props.onClick}
                    isTransparent
                    isLight={props.isLight}
                    icon={<PlusIcon isLight={props.isLight}/>}
            >
                {props.label ?? t("Components:ConfigurationList.Add")}
            </Button>
    );
};