import styled from "styled-components/macro";
import { ellipsis } from "polished";
import { T_TITLE_4_NORMAL } from "../../global.style";


export const TextEllipsisStyled = styled.div`
    display: inline-flex;
    max-width: 100%;
    ${T_TITLE_4_NORMAL};
`;

export const ShortenPart = styled.span`
    ${ellipsis()};
    flex: 0 1 auto;
    display: block;
`;

export const FixedPart = styled.span`
    flex: 0 0 auto;
    display: block;
`;