import styled from "styled-components/macro";
import { T_PLAIN_small, T_TITLE_5, T_TITLE_6 } from "../../../global.style";
import { ellipsis } from "polished";

export const CheckboxWrapper = styled.div``;

export const Title = styled.div`
    ${T_TITLE_5};
    margin-bottom: 5px;
    ${ellipsis()}
`;

export const CashBoxBalance = styled.div`
    ${T_PLAIN_small};
`;

export const CashBoxAccountNumber = styled.div`
    ${T_TITLE_6};
    opacity: 0.6;
    margin-bottom: 10px;
`;

export const SmartCashBoxFilterStyled = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
`;