import { DrillDownFiltersWrapper } from "@pages/reports/ReportView.styles";
import React from "react";

import { TableStorage } from "../../model/TableStorage";
import TestIds from "../../testIds";
import ReadOnlyGroup from "../filterBar/ReadOnlyGroup";
import { isVisible } from "../smart/FieldInfo";

interface IProps {
    storage: TableStorage;
}

class SmartDrillDownFilters extends React.Component<IProps> {
    // expects that filter definitions (labels) are already loaded in storage info
    render() {
        const { storage } = this.props;
        const { predefinedFilters } = storage.data;

        if (!predefinedFilters) {
            return null;
        }

        const filterItems = Object.keys(predefinedFilters)
            .filter(filterName => {
                const bc = storage.data.bindingContext.navigate(filterName);
                const info = storage.getInfo(bc);

                return isVisible({ info, storage, bindingContext: bc });
            })
            .map((filterName) => {
                const info = storage.getInfo(storage.data.bindingContext.navigate(filterName));

                return {
                    label: info.label,
                    // value: drillDownFilters[filterName]
                    bindingContext: info.bindingContext,
                    storage
                };
            });

        return (
            <DrillDownFiltersWrapper data-testid={TestIds.DrilldownWrapper}>
                <ReadOnlyGroup
                    isBig
                    groups={[filterItems]}
                    maxRows={2}
                />
            </DrillDownFiltersWrapper>
        );
    }
}

export default SmartDrillDownFilters;