import { ClearButton } from "@components/inputs/writeLine/WriteLine.style";
import React from "react";

import { IconSize } from "../../../enums";
import TestIds from "../../../testIds";
import { SelectInputWrapper, StyledInput, StyledSearchIcon } from "./Select.styles";

interface IProps {
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
}

interface IState {
    value: string;
}

export default class SelectInput extends React.Component<IProps, IState> {
    state: IState = {
        value: ""
    };
    _refInput = React.createRef<HTMLInputElement>();

    componentDidMount(): void {
        this._refInput.current.focus();
    }

    handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            value: e.target.value
        });
        this.props.onChange(e);
    };

    handleClear = (): void => {
        this.setState({
            value: ""
        });
        // todo this component has weird props and state handling,
        // would be nice to rewrite..
        this.props.onChange({
            originalEvent: null,
            target: {
                value: ""
            } as unknown as EventTarget
        } as unknown as React.ChangeEvent<HTMLInputElement>);
        // return focus back to input so user can continue typing
        this._refInput.current.focus();
    };

    render() {
        return (
            <SelectInputWrapper>
                <StyledSearchIcon width={IconSize.S} isLight ignoreTheme preventHover/>
                <StyledInput
                    data-testid={TestIds.SelectSearchInput}
                    ref={this._refInput}
                    value={this.state.value}
                    onBlur={this.props.onBlur}
                    onKeyDown={this.props.onKeyDown}
                    onChange={this.handleChange}/>
                {this.state.value &&
                    <ClearButton onCancel={this.handleClear}
                                 onConfirm={null}
                                 isLight
                                 style={{ right: "6px", bottom: "6px" }}/>
                }
            </SelectInputWrapper>
        );
    }
}