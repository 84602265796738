import React, { memo, useContext, useEffect, useRef, useState } from "react";
import { getTileData, IChartTileInfo, IDashboardTileComponentProps } from "../../dashboard";
import { WithOData, withOData } from "@odata/withOData";
import { AppContext } from "../../../contexts/appContext/AppContext.types";
import DashboardTileHeader from "../../dashboard/DashboardTileHeader";
import { BackgroundStyled, ChartWrapperStyled } from "./ChartTile.styles";
import DoughnutChart, { IDoughnutChartComponentData } from "../../charts/DoughnutChart";
import BarChart, { IBarChartComponentData } from "../../charts/BarChart";
import { ChartType } from "../../charts";
import LineChart, { ILineChartComponentData } from "../../charts/LineChart";
import { getInfoValue } from "../../smart/FieldInfo";
import { Link } from "react-router-dom";
import { addCompanyIdToUrl } from "../../../contexts/appContext/AppContext.utils";
import { NoData } from "../../table/NoData";
import { useTranslation } from "react-i18next";

interface IChartTileTypeRegistry {
    doughnut: IDoughnutChartComponentData;
    bar: IBarChartComponentData;
    line: ILineChartComponentData;
}

type ChartTileType = keyof IChartTileTypeRegistry;

export interface IChartTileData<Type extends ChartTileType = ChartTileType> {
    type: Type;
    chartProps: IChartTileTypeRegistry[Type];
}

function getChartComponent<T extends ChartTileType = ChartTileType>(type: T): any {
    switch (type) {
        case ChartType.Doughnut:
            return DoughnutChart;
        case ChartType.Bar:
            return BarChart;
        case ChartType.Line:
            return LineChart;
        default:
            return null;
    }
}

interface IProps extends IDashboardTileComponentProps<IChartTileInfo>, WithOData {
}

const ChartTile: React.FC<IProps> = ({ inEditMode, info, oData }) => {
    const context = useContext(AppContext);
    const chartWrapper = useRef<HTMLDivElement>();

    const [tileData, setTileData] = useState<IChartTileData>(null);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [hasError, setError] = useState<boolean>(false);
    const [size, setSize] = useState<{ w: number; h: number; }>(null);

    const { t } = useTranslation("Home");

    useEffect(() => {
        let mounted = true;
        try {
            getTileData(info.chartData, { context, oData })
                .then((data) => mounted && setTileData(data))
                .finally(() => mounted && setLoading(false));
        } catch (e) {
            if (mounted) {
                setError(true);
                setTileData(null);
            }
        }

        return () => {
            mounted = false;
        };
    }, [context, info.chartData, oData]);

    // calculate exact size for the chart
    useEffect(() => {
        const { height, width } = chartWrapper.current?.getBoundingClientRect() ?? {};
        setSize({ w: width, h: height });
    }, []);

    const { type, chartProps } = tileData ?? {};
    const Chart = getChartComponent(type);

    const isEmpty = chartProps?.values?.some((v) => {
        if (Array.isArray(v)) {
            return v.length > 0;
        }
        return !!v;
    }) === false;
    const renderChart = !isEmpty && size && Chart;

    let content = (
        <BackgroundStyled backgroundColor={info.backgroundColor}
                          isLoading={isLoading || hasError}>
            <DashboardTileHeader tooltip={info.tooltip}>
                {info.title}
            </DashboardTileHeader>
            <ChartWrapperStyled ref={chartWrapper} $editMode={inEditMode}>
                {isEmpty && (<NoData noDataText={t("NoData")}
                                     isSimplified/>)}
                {renderChart && (<Chart width={size.w} height={size.h} formatter={info.formatter} responsive={false}
                                          data={chartProps}/>)}
            </ChartWrapperStyled>
        </BackgroundStyled>
    );

    if (info.link && !inEditMode) {
        const to = getInfoValue(info, "link", { context });

        if (to) {
            content = (
                <Link to={addCompanyIdToUrl(to, context)}>{content}</Link>
            );
        }
    }

    return content;
};

export default memo(withOData(ChartTile));