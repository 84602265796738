export enum TableButtonsAction {
    Add = "Add",
    CSVExport = "CSVExport",
    ExpandCollapseAll = "ExpandCollapseAll",
    FullscreenOpen = "FullscreenOpen",
    FullscreenClose = "FullscreenClose",
    Lock = "Lock",
    MassEdit = "MassEdit",
    PdfExport = "PdfExport",
    PdfExportReportFull = "PdfExportReportFull",
    PdfExportReportShort = "PdfExportReportShort",
    Remove = "Remove",
    Sorting = "Sorting",
    Settings = "Settings",
    ToggleDraftView = "ToggleDraftView",
    XLSXExport = "XLSXExport",
}

export enum TableActionOrder {
    Add = 10,
    ExpandCollapseAll = 30,
    Remove = 40,
    Lock = 50,
    MassEdit = 60,
    Fullscreen = 70,
    Export = 80,
    Sort = 90,
    Settings = 100,
    ToggleDraftView = 110
}

export type TableButtonsActionType = TableButtonsAction | string;
export const exportButtonActionGroup = [
    TableButtonsAction.XLSXExport,
    TableButtonsAction.CSVExport,
    TableButtonsAction.PdfExport,
    TableButtonsAction.PdfExportReportShort,
    TableButtonsAction.PdfExportReportFull
];

export interface ICustomButtonDefArgs {
    isActive: boolean;
    isDisabled: boolean;
}