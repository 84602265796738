import { AvatarStyle } from "../../enums";

export interface ISystemAvatarConfig {
    id: number;
    style: AvatarStyle;
}

const definitions = [
    {
        id: -1,
        style: AvatarStyle.Light
    },
    {
        id: -2,
        style: AvatarStyle.Light
    },
    {
        id: -3,
        style: AvatarStyle.Light
    },
    {
        id: -4,
        style: AvatarStyle.Light
    },
    {
        id: -5,
        style: AvatarStyle.Light
    },
    {
        id: -6,
        style: AvatarStyle.Light
    },
    {
        id: -7,
        style: AvatarStyle.Light
    },
    {
        id: -8,
        style: AvatarStyle.Light
    },
    {
        id: -9,
        style: AvatarStyle.Light
    },
    {
        id: -10,
        style: AvatarStyle.Light
    },
    {
        id: -11,
        style: AvatarStyle.Light
    },
    {
        id: -12,
        style: AvatarStyle.Light
    },
    {
        id: -13,
        style: AvatarStyle.Light
    },
    {
        id: -14,
        style: AvatarStyle.Light
    },
    {
        id: -15,
        style: AvatarStyle.Light
    },
    {
        id: -16,
        style: AvatarStyle.Light
    },
    {
        id: -17,
        style: AvatarStyle.Light
    },
    {
        id: -18,
        style: AvatarStyle.Light
    },
    {
        id: -19,
        style: AvatarStyle.Light
    },
    {
        id: -20,
        style: AvatarStyle.Light
    },
    {
        id: -21,
        style: AvatarStyle.Light
    },
    {
        id: -22,
        style: AvatarStyle.Light
    },
    {
        id: -23,
        style: AvatarStyle.Light
    },
    {
        id: -24,
        style: AvatarStyle.Light
    },
    {
        id: -25,
        style: AvatarStyle.Light
    },
    {
        id: -26,
        style: AvatarStyle.Light
    },
    {
        id: -27,
        style: AvatarStyle.Light
    },
    {
        id: -28,
        style: AvatarStyle.Light
    },
    {
        id: -29,
        style: AvatarStyle.Light
    },
    {
        id: -30,
        style: AvatarStyle.Light
    },
    {
        id: -31,
        style: AvatarStyle.Light
    },
    {
        id: -32,
        style: AvatarStyle.Light
    },
    {
        id: -33,
        style: AvatarStyle.Light
    },
    {
        id: -34,
        style: AvatarStyle.Light
    },
    {
        id: -35,
        style: AvatarStyle.Light
    },
    {
        id: -36,
        style: AvatarStyle.Light
    },
    {
        id: -37,
        style: AvatarStyle.Light
    },
    {
        id: -38,
        style: AvatarStyle.Light
    },
    {
        id: -39,
        style: AvatarStyle.Light
    },
    {
        id: -40,
        style: AvatarStyle.Light
    },
    {
        id: -41,
        style: AvatarStyle.Light
    },
    {
        id: -42,
        style: AvatarStyle.Light
    },
    {
        id: -43,
        style: AvatarStyle.Light
    },
    {
        id: -44,
        style: AvatarStyle.Light
    },
    {
        id: -45,
        style: AvatarStyle.Light
    },
    {
        id: -46,
        style: AvatarStyle.Light
    },
    {
        id: -47,
        style: AvatarStyle.Light
    },
    {
        id: -48,
        style: AvatarStyle.Light
    },
    {
        id: -49,
        style: AvatarStyle.Light
    },
    {
        id: -50,
        style: AvatarStyle.Light
    },
    {
        id: -51,
        style: AvatarStyle.Dark
    },
    {
        id: -52,
        style: AvatarStyle.Dark
    },
    {
        id: -53,
        style: AvatarStyle.Dark
    },
    {
        id: -54,
        style: AvatarStyle.Dark
    },
    {
        id: -55,
        style: AvatarStyle.Dark
    },
    {
        id: -56,
        style: AvatarStyle.Dark
    },
    {
        id: -57,
        style: AvatarStyle.Dark
    },
    {
        id: -58,
        style: AvatarStyle.Dark
    },
    {
        id: -59,
        style: AvatarStyle.Dark
    },
    {
        id: -60,
        style: AvatarStyle.Dark
    },
    {
        id: -61,
        style: AvatarStyle.Dark
    },
    {
        id: -62,
        style: AvatarStyle.Dark
    },
    {
        id: -63,
        style: AvatarStyle.Dark
    },
    {
        id: -64,
        style: AvatarStyle.Dark
    },
    {
        id: -65,
        style: AvatarStyle.Dark
    },
    {
        id: -66,
        style: AvatarStyle.Dark
    },
    {
        id: -67,
        style: AvatarStyle.Dark
    },
    {
        id: -68,
        style: AvatarStyle.Dark
    },
    {
        id: -69,
        style: AvatarStyle.Dark
    },
    {
        id: -70,
        style: AvatarStyle.Dark
    },
    {
        id: -71,
        style: AvatarStyle.Dark
    },
    {
        id: -72,
        style: AvatarStyle.Dark
    },
    {
        id: -73,
        style: AvatarStyle.Dark
    },
    {
        id: -74,
        style: AvatarStyle.Dark
    },
    {
        id: -75,
        style: AvatarStyle.Dark
    },
    {
        id: -76,
        style: AvatarStyle.Dark
    },
    {
        id: -77,
        style: AvatarStyle.Dark
    },
    {
        id: -78,
        style: AvatarStyle.Dark
    },
    {
        id: -79,
        style: AvatarStyle.Dark
    },
    {
        id: -80,
        style: AvatarStyle.Dark
    },
    {
        id: -81,
        style: AvatarStyle.Dark
    },
    {
        id: -82,
        style: AvatarStyle.Dark
    },
    {
        id: -83,
        style: AvatarStyle.Dark
    },
    {
        id: -84,
        style: AvatarStyle.Dark
    },
    {
        id: -85,
        style: AvatarStyle.Dark
    },
    {
        id: -86,
        style: AvatarStyle.Dark
    },
    {
        id: -87,
        style: AvatarStyle.Dark
    },
    {
        id: -88,
        style: AvatarStyle.Dark
    },
    {
        id: -89,
        style: AvatarStyle.Dark
    },
    {
        id: -90,
        style: AvatarStyle.Dark
    },
    {
        id: -91,
        style: AvatarStyle.Dark
    },
    {
        id: -92,
        style: AvatarStyle.Dark
    },
    {
        id: -93,
        style: AvatarStyle.Dark
    },
    {
        id: -94,
        style: AvatarStyle.Dark
    },
    {
        id: -95,
        style: AvatarStyle.Dark
    },
    {
        id: -96,
        style: AvatarStyle.Dark
    },
    {
        id: -97,
        style: AvatarStyle.Dark
    },
    {
        id: -98,
        style: AvatarStyle.Dark
    },
    {
        id: -99,
        style: AvatarStyle.Dark
    },
    {
        id: -100,
        style: AvatarStyle.Dark
    }
];

export const SystemAvatarDefs : {[key: number]: ISystemAvatarConfig} = Object.fromEntries(definitions.map(e => [e.id, e]));