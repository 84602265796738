import React from "react";
import { ColoredText } from "../../../global.style";
import { ButtonGroup, IconButton } from "../../button";
import { DownIcon, UpIcon } from "../../icon";
import BindingContext from "../../../odata/BindingContext";
import { StyledErrorStepper } from "./SmartFastEntryList.styles";
import { IconSize } from "../../../enums";
import { FormStorage } from "../../../views/formView/FormStorage";
import TestIds from "../../../testIds";

interface IProps {
    storage: FormStorage;
    bindingContext: BindingContext;
    groupId: string;
    isLight: boolean;
    onErrorFocusChange?: (bindingContext: BindingContext, index: number) => void;
}

interface IState {
    currentIndex: number;
}

class ErrorStepper extends React.Component<IProps, IState> {
    state: IState = {
        currentIndex: 0
    };

    currentErrors: BindingContext[];

    getAllErrors = (): BindingContext[] => {
        const currentVariantColumns = this.props.storage.getVariant().find(group => group.id === this.props.groupId)?.lineItems?.columns;
        const data = this.props.storage.getValue(this.props.bindingContext);
        const propName = this.props.bindingContext.getPath();
        const errors: BindingContext[] = [];

        if (!currentVariantColumns) {
            return errors;
        }

        for (const item of this.props.storage.data.bindingContext.iterateNavigation(propName, data)) {
            for (const column of currentVariantColumns) {
                const error = this.props.storage.getError(item.bindingContext.navigate(column.id));

                if (error) {
                    errors.push(item.bindingContext);
                }
            }
        }

        return errors;
    };

    changeError = (newIndex: number) => {
        const newErrorKey = this.currentErrors[newIndex].getKey();

        const data = this.props.storage.getValue(this.props.bindingContext);
        let realItemIndex: number;

        for (let i = 0; i < data.length; i++) {
            const item = data[i];
            const itemBc = this.props.bindingContext.addKey(item);

            if (itemBc.getKey() === newErrorKey) {
                realItemIndex = i;
                break;
            }
        }

        this.props.onErrorFocusChange?.(this.currentErrors[newIndex], realItemIndex);

        this.setState({
            currentIndex: newIndex
        });
    };

    handlePreviousClick = () => {
        let newIndex = this.state.currentIndex - 1;

        if (newIndex < 0) {
            newIndex = this.currentErrors.length - 1;
        }

        this.changeError(newIndex);
    };

    handleNextClick = () => {
        let newIndex = this.state.currentIndex + 1;

        if (newIndex >= this.currentErrors.length) {
            newIndex = 0;
        }

        this.changeError(newIndex);
    };

    render() {
        const errors = this.getAllErrors();

        this.currentErrors = errors;

        if (errors.length === 0) {
            return null;
        }

        return (
            <StyledErrorStepper data-testid={TestIds.ErrorStepper}>
                <ColoredText color={"C_SEM_text_bad"}
                             style={{ marginRight: "14px" }}>
                    {this.props.storage.t("Components:SmartFastEntryList.WrongFields")}: {this.state.currentIndex + 1}/{errors.length}
                </ColoredText>
                <ButtonGroup>
                    <IconButton title={this.props.storage.t("Components:SmartFastEntryList.PreviousError")}
                                onClick={this.handlePreviousClick}
                                isLight={this.props.isLight}
                                isDecorative>
                        <UpIcon width={IconSize.S}/>
                    </IconButton>
                    <IconButton title={this.props.storage.t("Components:SmartFastEntryList.NextError")}
                                onClick={this.handleNextClick}
                                isLight={this.props.isLight}
                                isDecorative>
                        <DownIcon width={IconSize.S}/>
                    </IconButton>
                </ButtonGroup>
            </StyledErrorStepper>
        );
    }
}

export default ErrorStepper;