import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { INVITATION_URL } from "../../constants";
import { AppThunk, TRootLoginState } from "./store";
import { getDefaultPostParams } from "@utils/customFetch";
import { handleError, setLoginEmail } from "./loginSlice";
import { parseError } from "@odata/ODataParser";

export enum InvitationStatus {
    Loading = "Loading",
    Idle = "Idle",
    Expired = "Expired",
    Accepted = "Accepted",
    Error = "Error"
}

export interface IInvitationInfo {
    FirstName: string;
    LastName: string;
    Email: string;
    TenantName: string;
}

interface IInvitationState {
    code: string;
    data: IInvitationInfo | null;
    status: InvitationStatus;
}

const initialState: IInvitationState = {
    code: null,
    data: null,
    status: null
};

const invitationSlice = createSlice({
    name: "invitation",
    initialState,
    reducers: {
        loadStart(state) {
            state.status = InvitationStatus.Loading;
        },
        setCode(state, action: PayloadAction<string>) {
            state.code = action.payload;
        },
        setInvitationData(state, action: PayloadAction<IInvitationInfo>) {
            state.data = action.payload;
            state.status = InvitationStatus.Idle;
        },
        setInvitationAccepted(state) {
            state.data = null;
            state.code = null;
            state.status = InvitationStatus.Accepted;
        },
        loadFailure(state, action: PayloadAction<InvitationStatus>) {
            state.code = null;
            state.status = action.payload;
        }
    }
});

export const {
    loadStart, setCode, setInvitationData, loadFailure, setInvitationAccepted
} = invitationSlice.actions;

export default invitationSlice.reducer;

const getInvitationError = ({ status }: Response) => {
    return status === 409 ? InvitationStatus.Expired : InvitationStatus.Error;
};

// Thunk action to load invitation data from the API
export const loadInvitation = (code: string): AppThunk => async dispatch => {
    try {
        dispatch(loadStart());
        dispatch(setCode(code));

        const response = await fetch(`${INVITATION_URL}/${code}`);

        if (response.ok) {
            const data: IInvitationInfo = await response.json();
            dispatch(setInvitationData(data));
            dispatch(setLoginEmail(data.Email));
        } else {
            dispatch(loadFailure(getInvitationError(response)));
        }
    } catch (error) {
        dispatch(loadFailure(InvitationStatus.Error));
    }
};

// Thunk action to load invitation data from the API
export const registerInvitation = (code: string): AppThunk<Promise<boolean>> => async (dispatch): Promise<boolean> => {
    try {
        const response = await fetch(INVITATION_URL, {
            ...getDefaultPostParams(),
            body: JSON.stringify({
                InvitationGuid: code
            })
        });

        if (response.ok) {
            return true;
        } else {
            const error = parseError(await response.json());

            if (error._validationMessages[0].message) {
                dispatch(handleError(error._validationMessages[0].message));
            } else {
                dispatch(loadFailure(getInvitationError(response)));
            }
        }
    } catch (error) {
        dispatch(loadFailure(error.message));
    }

    return false;
};

// Selector to get the invitation data from the state
export const selectInvitationData = (state: TRootLoginState): IInvitationInfo => state.invitation.data;
export const selectInvitationCode = ({ invitation }: TRootLoginState): string => invitation.status !== InvitationStatus.Accepted ? invitation.code : null;
export const selectInvitationStatus = (state: TRootLoginState): InvitationStatus => state.invitation.status;
export const selectIsInvitationLoaded = (state: TRootLoginState): boolean => state.invitation.status && state.invitation.status !== InvitationStatus.Loading;
