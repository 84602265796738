import styled, { css } from "styled-components/macro";
import { InputWithErrorAndTooltip } from "../input";
import { ErrorText, StyledError } from "../formError/FormError.styles";

export const InputWithErrorAndTooltipStyled = styled(InputWithErrorAndTooltip)<{ showSteppers: boolean }>`
    // prevent error text from overflowing over steppers
    ${props => props.showSteppers && css`
        ${StyledError} > ${ErrorText} {
            padding-right: 25px;
        }
    `}
`;

/* Stepper is position absolutely to hold position even when there is error in the field,
* => use relatively positioned placeholder to keep the space between field edge and its content  */
export const StepperPlaceholder = styled.div`
    width: 10px;
`;
