import React from "react";
import { Autocomplete } from "../../inputs/select/Autocomplete";
import SmartSelectBase from "../../smart/smartSelect/SmartSelectBase";
import { ISmartSelectProps } from "./SmartSelect";

class SmartAutocomplete extends React.Component<ISmartSelectProps> {

    render = (): React.ReactElement => {
        return (
            <SmartSelectBase
                select={Autocomplete}
                {...this.props}
            />
        );
    };
}

export default SmartAutocomplete;