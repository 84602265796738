import { AppContext } from "../../../contexts/appContext/AppContext.types";
import { FormViewForExtend, IFormViewProps } from "../../../views/formView/FormView";
import {
    IPrHealthInsuranceCompanyEntity,
    IPrInsuranceCompanyPredefinedEntity,
    PrHealthInsuranceCompanyEntity,
    PrInsuranceCompanyEntity
} from "@odata/GeneratedEntityTypes";
import { ISmartFieldBlur, ISmartFieldChange } from "@components/smart/smartField/SmartField";
import { predefinedHealthInsuranceCompanyPath } from "./HealthInsuranceCompany.def";
import { getPredefinedCompanies, handleInsuranceCompanyBankChange, validateDataBox } from "./insuranceCompanies.utils";
import {
    BankAccountDependentFields,
    clearBankFields,
    SAVED_ACCOUNTS_PATH,
    setSavedBankAccount,
    setSavedBankAccountItems
} from "../../banks/bankAccounts/BankAccounts.utils";
import { PrInsuranceCompanyTypeCode } from "@odata/GeneratedEnums";
import { isObjectEmpty } from "@utils/general";
import { withPermissionContext } from "../../../contexts/permissionContext/withPermissionContext";

interface IProps extends IFormViewProps<IPrHealthInsuranceCompanyEntity, null> {
}

class HealthInsuranceCompanyFormView extends FormViewForExtend<IPrHealthInsuranceCompanyEntity, IProps> {
    static contextType = AppContext;

    static defaultProps = {
        title: "InsuranceCompanies:HealthInsurance.FormTitle"
    };

    async onAfterLoad() {
        const storage = this.props.storage;


        if (!storage.data.bindingContext.isNew()) {
            validateDataBox(storage);

            const companies = await getPredefinedCompanies(storage, PrInsuranceCompanyTypeCode.HealthInsuranceCompany);
            const company = companies.find(c => c.ShortName === this.entity.ShortName);
            this.props.storage.setValueByPath(predefinedHealthInsuranceCompanyPath, company.Code);
            const bankAccounts = company?.BankAccounts?.length ? company.BankAccounts : [storage.data.entity.BankAccount];
            setSavedBankAccountItems(storage, bankAccounts);
            setSavedBankAccount({
                        storage,
                        isEntityWithoutBP: true,
                        account: storage.data.entity.BankAccount
                    }
                    , bankAccounts);

            storage.addActiveField(storage.data.bindingContext.navigate(predefinedHealthInsuranceCompanyPath));
            storage.addActiveField(storage.data.bindingContext.navigate(SAVED_ACCOUNTS_PATH));
            this.props.storage.refreshFields();
        }

        return super.onAfterLoad();
    }

    handlePredefinedCompanyChange = (e: ISmartFieldChange): void => {
        if (e.bindingContext.getPath() === predefinedHealthInsuranceCompanyPath &&
                e.triggerAdditionalTasks) {
            const predefinedData = e.additionalData as IPrInsuranceCompanyPredefinedEntity;
            const storage = this.props.storage;

            const bankAccounts = predefinedData.BankAccounts ?? [];

            if (!isObjectEmpty(bankAccounts[0])) {
                storage.processDependentField(BankAccountDependentFields, bankAccounts[0]);
                setSavedBankAccountItems(storage, bankAccounts);
                setSavedBankAccount({
                            storage,
                            isEntityWithoutBP: true,
                            account: storage.data.entity.BankAccount
                        }
                        , bankAccounts);
            } else if (storage.data.bindingContext.isNew()) {
                // e.triggerAdditionalTasks means company was selected but is without bank account, therefore clear fields
                storage.clearAndSetValue(storage.data.bindingContext.navigate(SAVED_ACCOUNTS_PATH), null);
                setSavedBankAccountItems(storage, []);
                clearBankFields(storage, storage.data.bindingContext.navigate(PrInsuranceCompanyEntity.BankAccount));
            }


            storage.addActiveField(storage.data.bindingContext.navigate(SAVED_ACCOUNTS_PATH));

            storage.setValueByPath(PrHealthInsuranceCompanyEntity.HealthInsuranceCompanyCode, predefinedData?.HealthInsuranceCompanyCode?.toString()); // todo: remove to string once types are unified in metadata
            storage.setValueByPath(PrHealthInsuranceCompanyEntity.DataBoxId, predefinedData?.DataBoxId);
            storage.setValueByPath(PrHealthInsuranceCompanyEntity.ShortName, predefinedData?.ShortName);
            storage.setValueByPath(PrHealthInsuranceCompanyEntity.Name, predefinedData?.Name);
            this.props.storage.refreshFields();
            validateDataBox(this.props.storage);
        }
    };

    handleChange(e: ISmartFieldChange): void {
        super.handleChange(e);
        handleInsuranceCompanyBankChange(e, this.props.storage);
        this.handlePredefinedCompanyChange(e);
    }

    async handleBlur(e: ISmartFieldBlur): Promise<void> {
        await this.props.storage.handleBlur(e);
        if (e.bindingContext.getPath() === PrHealthInsuranceCompanyEntity.DataBoxId) {
            await validateDataBox(this.props.storage);
        }
        this.props.storage.refreshFields();
    }
}

export default withPermissionContext(HealthInsuranceCompanyFormView);