import { AlertAction } from "@components/alert/Alert";
import { ISelectItem } from "@components/inputs/select/Select.types";
import { SmartTable } from "@components/smart/smartTable";
import { TCustomRowAction } from "@components/smart/smartTable/SmartTable.utils";
import { IActionRendererArgs } from "@components/table";
import { ActionCheckBoxStyled } from "@components/table/Table.styles";
import { IToolbarItem } from "@components/toolbar";
import BindingContext, { createBindingContext } from "@odata/BindingContext";
import { ODataError } from "@odata/Data.types";
import { updateEntity } from "@odata/Data.utils";
import {
    EntitySetName,
    EntityTypeName,
    ICompanyRoleEntity,
    IUserCompanyRoleEntity,
    IUserEntity
} from "@odata/GeneratedEntityTypes";
import { isBatchResultOk } from "@odata/OData";
import { isObjectEmpty } from "@utils/general";
import { logger } from "@utils/log";
import memoizeOne, { IMemoized } from "@utils/memoizeOne";
import i18next from "i18next";
import { cloneDeep } from "lodash";
import React, { ReactElement } from "react";

import { BreadCrumbProvider } from "../../../components/breadCrumb";
import { ActionState, Status, ToolbarItemType } from "../../../enums";
import { ITableStorageDefaultCustomData } from "../../../model/TableStorage";
import { getAlertFromError } from "../../../views/formView/Form.utils";
import TableView from "../../../views/table";
import {
    ICustomButtonDefArgs,
    TableActionOrder,
    TableButtonsAction,
    TableButtonsActionType
} from "../../../views/table/TableToolbar.utils";
import { IHandleCustomActionArgs, ITableViewBaseProps } from "../../../views/table/TableView";
import { getConfirmationActionText } from "../../../views/table/TableView.render.utils";
import { TableWrapper, TopWrapper } from "../../../views/table/TableView.styles";
import View from "../../../views/View";
import SelectCompanyDialog from "./SelectCompanyDialog";
import { CustomerCompanyRoleId } from "./Users.utils";

export interface IUserTableCustomData extends ITableStorageDefaultCustomData {
    roleItems?: ISelectItem[];
    roleToAddId?: number;
    selectedCompaniesIds?: number[];
    selectCompanyDialogOpened?: boolean;
}

interface IProps extends ITableViewBaseProps<IUserTableCustomData> {
    customFooter?: ReactElement;
    customHeader?: ReactElement;
}

const MASS_ADD_ROLE_ACTION = "massAddRole";

class UsersTableView extends TableView<IProps> {

    constructor(props: IProps) {
        super(props);

        this.getConfirmText = this.getConfirmText.bind(this);
        this.getToolbarButtons = this.getToolbarButtons.bind(this);
        this.getCustomButtonDef = this.getCustomButtonDef.bind(this);
        this.renderSmartHeader = this.renderSmartHeader.bind(this);
        this.getTableSharedProps = this.getTableSharedProps.bind(this);
        this.render = this.render.bind(this);
    }

    componentDidMount() {
        this.loadRoles().then(() => this.forceUpdate());
        super.componentDidMount();
    }

    loadRoles = async (): Promise<void> => {
        try {
            const query = this.props.storage.oData.getEntitySetWrapper(EntitySetName.CompanyRoles).query();

            if (this.isAddingNewCompany) {
                query.filter(`Id ne ${CustomerCompanyRoleId}`);
            }

            const res = await query.fetchData<ICompanyRoleEntity[]>();

            const roles = res.value;
            const roleItems = roles.map(role => {
                return {
                    id: `${MASS_ADD_ROLE_ACTION}_${role.Id}`,
                    label: role.CompanyRoleName,
                    additionalData: role
                };
            });

            this.props.storage.setCustomData({
                roleItems
            });
        } catch (e) {
            logger.error(e.toString());
        }
    };

    get isAddingNewCompany(): boolean {
        return this.context.getAddingNewCompany();
    }

    getCustomButtonDef(type: TableButtonsActionType, args: ICustomButtonDefArgs): IToolbarItem {
        if (type === MASS_ADD_ROLE_ACTION) {
            const roleItems = this.props.storage.getCustomData().roleItems;
            const isActive = this.getTableAction()?.startsWith(MASS_ADD_ROLE_ACTION);
            const isAnotherActionActive = this.isTableAction() && !isActive;
            const isDisabled = isAnotherActionActive || args?.isDisabled;

            return {
                id: MASS_ADD_ROLE_ACTION,
                itemType: ToolbarItemType.IconSelect,
                iconName: "AddUserRole",
                label: this.props.storage.t("Users:AssignRole"),
                order: TableActionOrder.Remove + 1,
                isActive,
                isDisabled,
                itemProps: {
                    isTransparent: true,
                    headerText: i18next.t("Users:AssignRole"),
                    selectProps: {
                        isActive,
                        isDisabled: !roleItems?.length || isDisabled
                    },
                    items: roleItems
                }
            };
        }
        return super.getCustomButtonDef(type, args);
    }

    getInitialActiveRows(): BindingContext[] {
        const rows = Object.values(this.props.storage.tableAPI?.getState().rows ?? []);
        const roleToAddId = this.props.storage.getCustomData().roleToAddId;
        // rows that already have the selected role
        return rows.filter(row => {
            return row?.customData?.entity.CompanyRoles?.some((role: IUserCompanyRoleEntity) => {
                return role.CompanyRole?.Id === roleToAddId && role.Company.Id === this.context.getCompanyId();
            });
        }).map(row => row.id as BindingContext);
    }

    getRowAction: IMemoized<[], TCustomRowAction> = memoizeOne((): TCustomRowAction => {
        if (!this.isTableAction()) {
            return null;
        }

        let render: (args: IActionRendererArgs) => React.ReactNode = null;

        if (this.getTableAction()?.startsWith(MASS_ADD_ROLE_ACTION) && this.isAddingNewCompany) {
            render = (args) => {
                const isCheckedDisabled = args.actionState === ActionState.Disabled && this.props.storage.tableAPI?.getState().activeRows.has(args.rowId?.toString());
                return <ActionCheckBoxStyled checked={args.actionState === ActionState.Active || isCheckedDisabled}
                                             isDisabled={args.isDisabled}
                                             onChange={args.onClick}/>;
            };
        }

        return {
            actionType: this.getRowActionType(),
            isRowWithoutAction: this.isRowWithoutAction,
            ...this.getCustomRowAction(),
            render
        };
    }, () => [this.getCustomRowAction(), this.getTableAction()]);

    getConfirmText(tableAction: TableButtonsAction | string): string | React.ReactElement {
        if (tableAction?.startsWith(MASS_ADD_ROLE_ACTION)) {
            const count = this.props.storage.tableAPI?.getState().activeRows.size ?? 0;
            return getConfirmationActionText(this.props.storage.t("Users:Assign"), count);
        }
        return super.getConfirmText(tableAction);
    }

    handleCustomAction(action: string, args: IHandleCustomActionArgs = {}): void {
        if (action?.startsWith(MASS_ADD_ROLE_ACTION)) {
            const roleToAddId = parseInt(action.split("_")[1]);
            this.props.storage.setCustomData({ roleToAddId });
        }
        super.handleCustomAction(action, args);
    }

    getToolbarButtons(): TableButtonsActionType[] {
        const buttons = super.getToolbarButtons();
        buttons.push(MASS_ADD_ROLE_ACTION);
        return buttons;
    }

    assignRolesToUsers = async (): Promise<void> => {
        const storage = this.props.storage;
        const { roleToAddId, selectedCompaniesIds } = storage.getCustomData();
        const users = storage.tableAPI?.getState().activeRows;
        const rows = storage.tableAPI?.getState().rows;

        const batch = storage.oData.batch();

        const removedUsers = this.getInitialActiveRows().filter(bc => !users.has(bc.toString()));

        for (const user of users) {
            const userEntity = cloneDeep(rows[user]?.customData.entity) as IUserEntity;

            for (const companyId of selectedCompaniesIds) {
                const userRole = userEntity.CompanyRoles.find(role => role.Company.Id === companyId);
                if (userRole) {
                    userRole.CompanyRole = {
                        Id: roleToAddId,
                    };
                } else {
                    userEntity.CompanyRoles.push({
                        Company: {
                            Id: companyId
                        },
                        CompanyRole: {
                            Id: roleToAddId
                        }
                    });
                }
            }

            updateEntity({
                batch,
                changesOnly: true,
                bindingContext: createBindingContext(EntitySetName.Users, storage.oData.getMetadata()).addKey(userEntity.Id),
                entity: userEntity,
                originalEntity: rows[user]?.customData.entity
            });
        }

        for (const user of removedUsers) {
            const userEntity = cloneDeep(rows[user.toString()]?.customData.entity) as IUserEntity;
            const userRole = userEntity.CompanyRoles.find(role => role.Company.Id === this.context.getCompanyId());
            if (userRole) {
                userEntity.CompanyRoles = userEntity.CompanyRoles.filter(role => role.Company.Id !== this.context.getCompanyId());
            }

            updateEntity({
                batch,
                changesOnly: true,
                bindingContext: createBindingContext(EntitySetName.Users, storage.oData.getMetadata()).addKey(userEntity.Id),
                entity: userEntity,
                originalEntity: rows[user.toString()]?.customData.entity
            });
        }

        let errors: string[] = [];
        if (!batch.isEmpty()) {
            const batchResponse = await batch.execute();

            for (const res of batchResponse) {
                if (!isBatchResultOk(res)) {
                    if (res.body instanceof ODataError || typeof res.body === "string") {
                        const resSubtitle = getAlertFromError(res.body).subTitle as string[];
                        const entityReference = res.body._validationMessages?.[0]?.entity;
                        if (entityReference.entityType === EntityTypeName.User && entityReference.id) {
                            const bc = createBindingContext(EntitySetName.Users, storage.oData.getMetadata()).addKey(entityReference.id);
                            const userEntity = rows[bc.toString()]?.customData?.entity as IUserEntity;
                            if (!isObjectEmpty(userEntity)) {
                                errors.push(`${userEntity.FirstName} ${userEntity.LastName} - ${resSubtitle}`);
                            }
                        } else {
                            errors = [...errors, ...resSubtitle];
                        }
                    }
                }
            }
        }

        if (errors.length) {
            storage.data.alert = {
                action: AlertAction.Close,
                status: Status.Error,
                useFade: false,
                title: storage.t("Users:AssignRoleFailed"),
                subTitle: errors?.join("\n")
            };
        } else {
            storage.data.alert = {
                status: Status.Success,
                title: storage.t("Components:Table.UpdateOk"),
                subTitle: storage.t("Users:AssignRoleSuccess"),
                useFade: true
            };
        }

        await this.handleCloseSelectCompanyDialog();
    };

    handleToolbarConfirm = async (): Promise<void> => {
        const tableAction = this.getTableAction();
        if (tableAction.startsWith(MASS_ADD_ROLE_ACTION)) {
            if (this.context.getAddingNewCompany()) {
                this.props.storage.setCustomData({
                    selectedCompaniesIds: [this.context.getCompanyId()]
                });
                await this.assignRolesToUsers();
            } else {
                this.props.storage.setCustomData({ selectCompanyDialogOpened: true });
            }
            await this.props.storage.tableAPI.reloadTable();
        } else {
            await super.handleToolbarConfirm();
        }
    };

    handleCloseSelectCompanyDialog = async (): Promise<void> => {
        this.props.storage.setCustomData({ selectCompanyDialogOpened: false });
        await this.setTableAction(null);
        this.forceUpdate();
    };

    renderSmartHeader(): React.ReactNode {
        if (this.isAddingNewCompany) {
            return this.props.customHeader;
        }
        return super.renderSmartHeader();
    }

    renderCustomFooter = (): ReactElement => {
        if (!this.isAddingNewCompany) {
            return null;
        }
        return this.props.customFooter;
    };

    renderSelectCompanyDialog = (): ReactElement => {
        if (!this.props.storage.getCustomData().selectCompanyDialogOpened) {
            return null;
        }

        return (
                <SelectCompanyDialog
                        storage={this.props.storage}
                        onClose={this.handleCloseSelectCompanyDialog}
                        onConfirm={this.assignRolesToUsers}
                />
        );
    };

    renderTable(): React.ReactNode {
        return (
                <TableWrapper>
                    <SmartTable {...this.getTableSharedProps()}
                                initialActiveRows={this.getInitialActiveRows()}
                    />
                </TableWrapper>
        );
    }

    render() {
        if (!this.isAddingNewCompany) {
            super.render();
        }

        return (
                <>
                    {this.shouldRenderBreadcrumbs && (
                            <BreadCrumbProvider/>
                    )}
                    <View scrollProps={{ref: this._scrollBarInstanceRef}}
                          {...this.getViewProps()}
                          style={{ paddingBottom: 0 }}
                          hotspotContextId={this.props.storage.id}>
                        <TopWrapper
                                _loaded={this.isLoaded()}>
                            {this.renderSmartHeader()}
                            {this.renderFilterBar()}
                            {this.renderAlert()}
                            {this.hasTabs && this.renderTabs()}
                            {this.renderToolbar()}
                        </TopWrapper>
                        {this.renderTable()}
                        {this.renderCustomFooter()}
                        {this.renderDefaultDialogs()}
                        {this.renderSelectCompanyDialog()}
                    </View>
                </>
        );
    }
}

export default UsersTableView;