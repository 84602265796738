import styled, { css } from "styled-components/macro";
import { mix, transparentize } from "polished";
import { getFocusBorderElement, getSemanticElColor, LocalStackOrder } from "../../global.style";
import { Status } from "../../enums";
import { TooltipArrow, TooltipArrowStyles } from "../tooltipIcon/TooltipIcon.styles";

import { TProgressStatus } from "./BackgroundJobs.utils";
import ProgressWheel from "../progressWheel/ProgressWheel";

interface IStyledProgressBanner {
    $status?: TProgressStatus;
    $isActive?: boolean;
    $isClickable?: boolean;
    $isClosing?: boolean;
}

export const StyledProgressBanner = styled.button<IStyledProgressBanner>`
    all: unset;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 38px;
    height: 38px;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, ${props => props.theme.shadow_opacity});
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    background-color: ${props => props.theme.C_BG_menu};
    cursor: ${props => props.$isClickable ? "pointer" : "default"};

    ${props => props.$isClosing && css`
        transition: opacity 0.5s 3s;
        opacity: 0;
    `};

    ${getFocusBorderElement({ offset: -2, keyboardOnly: true })};

    &:after {
        content: "";
        position: absolute;
        width: 32px;
        height: 32px;
        background-color: ${props => props.$status === "Progress" && props.$isActive ? props.theme.C_ACT_hover_without_opacity : "transparent"};
        border-radius: 50%;
        top: 50%;
        transform: translate(0, -50%);
    }

    &:hover:after {
        box-shadow: inset 0 0 0 2px ${props => props.$isClickable ? props.$status === "Progress" ? props.theme.C_ACT_hover_without_opacity : transparentize(0.8, getSemanticElColor(props.theme, props.$status as Status)) : "transparent"};
    }
`;

export const ProgressWheelInBanner = styled(ProgressWheel)`
    z-index: ${LocalStackOrder.AboveContent};
`;

export const StatusCircle = styled.div<IStyledProgressBanner>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    color: ${props => getSemanticElColor(props.theme, props.$status as Status)};
    background-color: ${props => props.$isActive ? mix(0.2, getSemanticElColor(props.theme, props.$status as Status), props.theme.C_BG_menu) : null};
    border-radius: 50%;
`;

export const BannerPopperWrapper = styled.div`
    ${TooltipArrowStyles};

    & ${TooltipArrow} {
        z-index: unset;
    }
`;
