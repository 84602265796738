import React from "react";
import { AddFilterIcon, CleanFilterIcon, getIcon } from "../icon";
import Collapse from "react-bootstrap/Collapse";
import { CustomClasses, IconSize } from "../../enums";
import { IconButton } from "../button";
import TestIds from "../../testIds";
import {
    CollapseWrapper,
    ControlButtonsGroup,
    EmptyFieldGroup,
    ExpandIconWrapper,
    FilterBarCollapsedWrapper,
    FilterBarWrapper,
    FilterReadOnlyBody,
    GroupItem,
    NoDataWrapper,
    ReadOnlyGroups
} from "./FilterBar.styles";
import ReadOnlyGroup from "./ReadOnlyGroup";
import { WithTranslation, withTranslation } from "react-i18next";
import { TableStorage } from "../../model/TableStorage";
import BindingContext from "../../odata/BindingContext";
import FieldGroup, { FieldGroupPaddingType } from "../fieldGroup/FieldGroup";
import { TValue } from "../../global.types";
import FieldsWrapper from "../inputs/field/FieldsWrapper";
import { FilterBarGroup, IFilterBarButtonDef } from "../smart/smartFilterBar/SmartFilterBar.types";

export interface IFilterBarReadOnlyItem {
    label?: string;
    description?: string;
    /** Filters without value are hidden in collapsed mode */
    value?: TValue;
    storage?: TableStorage;
    bindingContext?: BindingContext;
}

export interface IFilterBarItem extends IFilterBarReadOnlyItem {
    field: {
        props: any,
        component: React.ComponentType<any>
    };
    groupWithPrevious?: boolean;
}

export interface IFilterBarTab {
    id: string;
    icon: string;
    groups: IFilterBarItem[][];
    removePadding?: boolean;
    customButtons?: IFilterBarButtonDef[];
    title: string;
    showEditFiltersButton?: boolean;
    createQuery?: boolean;
}

export interface IProps extends WithTranslation {
    tabs: IFilterBarTab[];
    expandedGroupKey: string;
    expanded?: boolean;
    onExpand?: (id: string) => void;
    onExpandFinish?: () => void;
    onClear?: () => void;
    isClearDisabled?: boolean;
    onEditFiltersClick?: (tabId: string) => void;
    onCustomButtonClick?: (tabId: string, buttonId: string) => void;
    className?: string;
    isDisabled?: boolean;
    hideReadOnlyFilters?: string[];
}


interface IState {
    changing: boolean;
    animateButton: boolean;
}

class FilterBar extends React.Component<IProps, IState> {
    state: IState = {
        changing: false,
        animateButton: false
    };

    _filterBarRef = React.createRef<HTMLDivElement>();

    get tabIdx() {
        return this.props.tabs.findIndex(tab => tab.id === this.props.expandedGroupKey);
    }

    componentDidUpdate(prevProps: IProps, prevState: IState): void {
        // set focus on last opened tab after clicking on close icon
        if (!this.props.expanded && this.props.expanded !== prevProps.expanded) {
            const groupIconButton = this._filterBarRef.current.getElementsByClassName(CustomClasses.FilterBarGroupIcon)[this.tabIdx];
            groupIconButton && (groupIconButton as HTMLButtonElement).focus();
        }
    }

    getOpacity = (index = this.tabIdx) => {
        return 1 - (this.props.tabs.length - 1 - index) * 0.15;
    };

    getReadOnlyFields = () => {
        const maxRowsPerTab = this.props.tabs.length > 1 ? 3 : 7;

        const allFields = this.props.tabs.map((tab, i) => {
            return (
                <ReadOnlyGroup key={tab.id}
                               groups={tab.groups}
                               hideFilters={this.props.hideReadOnlyFilters}
                               maxRows={maxRowsPerTab}/>
            );
        });

        return (
            <ReadOnlyGroups>
                {allFields}
            </ReadOnlyGroups>
        );
    };

    renderGroup = (group: IFilterBarItem[]) => {
        const groupItems: IFilterBarItem[][] = [];

        for (const item of group) {
            if (item.groupWithPrevious) {
                groupItems[groupItems.length - 1].push(item);
            } else {
                groupItems.push([item]);
            }
        }

        return (
            <FieldsWrapper>
                {
                    groupItems.map((items, j) => {
                        return (
                            <GroupItem key={j}
                                       groupWithPrevious={!!items.find(item => item.groupWithPrevious)}>
                                {items.map((item, index) => {
                                    const Component = item.field.component;

                                    return <Component key={index} {...item.field.props}/>;
                                })}
                            </GroupItem>
                        );
                    })
                }
            </FieldsWrapper>
        );
    };

    renderGroups = () => {
        const expandedTab = this._getExpandedTab();
        const isEmpty = expandedTab && !expandedTab.groups.find(group => group.length > 0);

        if (isEmpty) {
            return (
                <EmptyFieldGroup opacity={this.getOpacity(0)}
                                 paddingType={FieldGroupPaddingType.None}
                                 onCloseClick={this.props.onExpand.bind(this, this.props.expandedGroupKey)}
                                 displayCloseIcon
                                 isFirst
                                 isLast>
                    {isEmpty &&
                        <NoDataWrapper>
                            {this.props.t("Components:Table.NoData")}
                        </NoDataWrapper>
                    }
                </EmptyFieldGroup>
            );
        }


        return expandedTab?.groups.map((group, i) => {
            const isFirst = i === 0;
            const isLast = i === expandedTab.groups.length - 1;

            return (
                <FieldGroup key={i}
                            opacity={this.getOpacity()}
                            paddingType={expandedTab.removePadding ? FieldGroupPaddingType.None : FieldGroupPaddingType.Default}
                            onCloseClick={isFirst ? this.props.onExpand.bind(this, this.props.expandedGroupKey) : null}
                            displayCloseIcon={isFirst}
                            isFirst={isFirst}
                            isLast={isLast}>
                    {this.renderGroup(group)}
                </FieldGroup>
            );
        });
    };

    handleCollapseStarted = () => {
        this.setState({
            changing: true,
            animateButton: false
        });
    };

    handleCollapseEnd = (isExit: boolean) => {
        this.setState({
            changing: false,
            animateButton: isExit
        });
        this.props.onExpandFinish?.();
    };

    _getExpandedTab = () => {
        return this.props.tabs[this.tabIdx];
    };

    handleEditFiltersClick = () => {
        this.props.onEditFiltersClick?.(this._getExpandedTab().id);
    };

    handleCustomButtonClick(id: string) {
        this.props.onCustomButtonClick?.(this._getExpandedTab().id, id);
    }

    renderControlButtons = () => {
        const expandedTab = this._getExpandedTab();

        return (
            <ControlButtonsGroup>
                {expandedTab?.id === FilterBarGroup.Filters && (
                    <IconButton isTransparent
                                isDisabled={this.props.isClearDisabled}
                                onClick={this.props.onClear}
                                title={this.props.t("Components:FilterBar.ClearFilters")}
                                key={"clear"}
                                hotspotId={"clearFilters"}>
                        <CleanFilterIcon width={IconSize.M}/>
                    </IconButton>
                )}
                {expandedTab?.showEditFiltersButton && (
                    <IconButton isTransparent
                                onClick={this.handleEditFiltersClick}
                                title={this.props.t("Components:FilterBar.Add")}
                                hotspotId={"editFilters"}>
                        <AddFilterIcon/>
                    </IconButton>
                )}
                {/*not ready, hidden*/}
                {/*{expandedTab?.createQuery && (*/}
                {/*    <NotInBeta>*/}
                {/*        <IconButton isTransparent*/}
                {/*                    title={this.props.t("Components:FilterBar.CreateQuery")}*/}
                {/*                    hotspotId={"createQuery"}>*/}
                {/*            <QueryIcon/>*/}
                {/*        </IconButton>*/}
                {/*    </NotInBeta>*/}
                {/*)}*/}
                {expandedTab?.customButtons?.map((button) => {
                    const Icon = getIcon(button.iconName);
                    return (
                        <IconButton isTransparent
                                    key={button.id}
                                    onClick={this.handleCustomButtonClick.bind(this, button.id)}
                                    title={button.title}
                                    hotspotId={button.id}>
                            <Icon/>
                        </IconButton>
                    );
                })}
            </ControlButtonsGroup>
        );
    };

    render() {
        return (
            <FilterBarWrapper className={this.props.className} expanded={this.props.expanded}
                              data-testid={TestIds.FilterBar}
                              ref={this._filterBarRef}>
                <FilterBarCollapsedWrapper>
                    {this.props.tabs.map((tab, index) => {
                        const Icon = getIcon(tab.icon);
                        return (
                            <ExpandIconWrapper
                                className={CustomClasses.FilterBarGroupIcon}
                                title={tab.title}
                                _opacity={this.getOpacity(index)}
                                _index={index}
                                key={index}
                                hotspotId={tab.id}
                                isDisabled={this.props.isDisabled}
                                expanded={this.props.expanded}
                                onClick={this.props.onExpand.bind(this, tab.id)}
                                changing={this.state.changing}
                                animateButton={this.state.animateButton}
                                data-testid={TestIds.FilterBarToggle}>
                                <Icon width={IconSize.M} height={IconSize.M} isLight/>
                            </ExpandIconWrapper>
                        );
                    })}
                    {!this.props.expanded && !this.state.changing &&
                        <FilterReadOnlyBody>
                            {this.getReadOnlyFields()}
                        </FilterReadOnlyBody>
                    }
                </FilterBarCollapsedWrapper>
                {this.props.expanded && this.renderControlButtons()}
                <Collapse
                    in={this.props.expanded}
                    onEnter={this.handleCollapseStarted}
                    onExit={this.handleCollapseStarted}
                    onExited={this.handleCollapseEnd.bind(this, true)}
                    onEntered={this.handleCollapseEnd.bind(this, false)}>
                    <CollapseWrapper>
                        {this.renderGroups()}
                    </CollapseWrapper>
                </Collapse>
            </FilterBarWrapper>
        );
    }
}

export default withTranslation(["Common", "Components"])(FilterBar);