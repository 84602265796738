import { EntityTypeName, IBackgroundJobEntity } from "@odata/GeneratedEntityTypes";
import { BackgroundJobTypeCode } from "@odata/GeneratedEnums";
import { getEnumNameSpaceName } from "@odata/GeneratedEnums.utils";
import i18next from "i18next";
import React from "react";

import { IconSize, Status } from "../../enums";
import { CloseIcon, FieldValidationPositiveIcon, FieldValidationWarningIcon } from "../icon";
import { TProgressBarDescFormatter } from "./ProgressBar";
import { StatusDescriptionFormatterWrapper } from "./ProgressBar.styles";

export enum ProgressBarColor {
    Default = "Default",
    Semantic = "Semantic",
    Gray = "Gray"
}

const progressStatusFormatter = (status: Status, value: number, parts: number): React.ReactElement => {
    const Icon = status === Status.Success ? FieldValidationPositiveIcon : status === Status.Warning ? FieldValidationWarningIcon : CloseIcon;

    return (
        <StatusDescriptionFormatterWrapper>
            <Icon width={IconSize.XS}
                  color={status === Status.Error ? "C_SEM_el_bad" : null}
                  preventHover/>
            {i18next.t(`Components:ProgressBar.Description${status}`)}
        </StatusDescriptionFormatterWrapper>
    );
};

export const progressSuccessFormatter: TProgressBarDescFormatter = (value: number, parts: number): React.ReactElement => {
    return progressStatusFormatter(Status.Success, value, parts);
};

export const progressWarningFormatter: TProgressBarDescFormatter = (value: number, parts: number): React.ReactElement => {
    return progressStatusFormatter(Status.Warning, value, parts);
};

export const progressErrorFormatter: TProgressBarDescFormatter = (value: number, parts: number): React.ReactElement => {
    return progressStatusFormatter(Status.Error, value, parts);
};

export interface IPostMultipleDraftsParameters {
    DraftIds: number[];
}

export interface IPostMultipleDraftsFailedWithWarningResult {
    FailedDraftsCount: number;
    TotalDraftsCount: number;
    SuccessfullyPostedDraftsIds: number[];
}

export interface IPostMultipleDraftsParameters {
    EntityTypeCode: EntityTypeName;
}

export const getProgressFinishedWithWarningFormatter = (job: IBackgroundJobEntity): TProgressBarDescFormatter => {
    const result = JSON.parse(job.Result) as IPostMultipleDraftsFailedWithWarningResult;
    const failedDraftCounts = result.FailedDraftsCount;

    return (value: number, parts: number): React.ReactElement => {
        return (
            <StatusDescriptionFormatterWrapper>
                <FieldValidationWarningIcon width={IconSize.XS}
                                            preventHover/>
                {i18next.t("Components:ProgressBar.PostMultipleDraftsFailedWithWarning", { count: failedDraftCounts })}
            </StatusDescriptionFormatterWrapper>
        );
    };
};

export const getBackgroundJobTitle = (job: IBackgroundJobEntity): string => {
    if (job.TypeCode === BackgroundJobTypeCode.PostMultipleDrafts && job.Parameters) {
        try {
            const parameters = JSON.parse(job.Parameters) as IPostMultipleDraftsParameters;
            const key = `BackgroundJobs:PostMultipleDrafts.Title${parameters.EntityTypeCode}`;

            return i18next.exists(key) ? i18next.t(key) : i18next.t("BackgroundJobs:PostMultipleDrafts.Title");
        } catch (e) {
            return i18next.t("BackgroundJobs:PostMultipleDrafts.Title");
        }
    }

    return i18next.t(`${getEnumNameSpaceName(EntityTypeName.BackgroundJobType)}:${job.TypeCode}`);
};