import { WithBusyIndicator, withBusyIndicator } from "@components/busyIndicator/withBusyIndicator";
import { ButtonSize } from "@components/button/Button.utils";
import { CloseIcon, MobileIcon, PcIcon, TabletIcon } from "@components/icon";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, IconButton } from "../components/button";
import { ScrollBar } from "../components/scrollBar";
import { BePollingInterval } from "../contexts/appContext/AppContext.utils";
import { DeviceType, IAuthDevice } from "../contexts/authContext/Auth.utils";
import { IconSize } from "../enums";
import { ROUTE_LOGOUT } from "../routes";
import TestIds from "../testIds";
import { BackArrow } from "./components/BackArrow";
import LoginButtonGroup from "./components/LoginButtonGroup";
import Redirects from "./components/Redirects";
import ResendLink from "./components/ResendLink";
import { AnimationType } from "./Login.utils";
import {
    BasicLoginTextBlock,
    DeviceBody,
    DeviceName,
    DeviceSeparator,
    DeviceWrapper,
    LoginTitle,
    StyledDeviceSelector
} from "./LoginPage.styles";
import {
    addDevice,
    deleteDevice,
    loadDevices,
    resendDeviceEmail,
    selectCurrentInfo,
    selectDevices,
    selectEmailSent
} from "./state/deviceSlice";
import { useLoginDispatch, useLoginSelector } from "./state/hooks";
import { setAnimationType } from "./state/loginSlice";
import { loadSession, selectSessionData } from "./state/sessionSlice";

interface IProps extends WithBusyIndicator {
}

const DevicePicker: React.FC<IProps> = () => {

    const { t } = useTranslation("Login");

    const dispatch = useLoginDispatch();
    const currentDevice = useLoginSelector(selectCurrentInfo);
    const emailSent = useLoginSelector(selectEmailSent);
    const devices = useLoginSelector(selectDevices);
    const session = useLoginSelector(selectSessionData);

    const [forceDeviceList, setForceDeviceList] = useState<boolean>(false);

    const showEmailSent = emailSent && !forceDeviceList;

    useEffect(() => {
        dispatch(loadDevices());
        dispatch(setAnimationType(AnimationType.StaticBiker));
    }, [dispatch]);

    // poll BE so that we can automatically redirect the page after user has confirmed email
    useEffect(() => {
        if (showEmailSent) {
            const int = setInterval(() => {
                dispatch(loadSession());
            }, BePollingInterval);

            return () => clearInterval(int);
        }

        return undefined;
    }, [dispatch, showEmailSent]);

    // const handleBackToDevices = useCallback((): boolean => {
    //     setForceDeviceList(true);
    //     return false;
    // }, []);

    const handleAdd = (oneTime: boolean) => {
        dispatch(addDevice(currentDevice.Name, oneTime));
        setForceDeviceList(false);
    };

    const handleResendEmail = useCallback(async () => {
        dispatch(resendDeviceEmail(currentDevice));
    }, [currentDevice, dispatch]);

    const deviceAdded = !currentDevice?.OneTime;

    if (showEmailSent) {
        return (<>
            <Redirects/>
            <LoginTitle data-testid={TestIds.Title}>
                <BackArrow url={ROUTE_LOGOUT}/>
                {t(deviceAdded ? "DeviceRegister.NewDevice" : "PassReset.IdentityConfirmation")}
            </LoginTitle>

            <div data-testid={TestIds.Text}>
                <BasicLoginTextBlock>{t("DeviceRegister.ValidationText", { email: session.LoginEmail })}</BasicLoginTextBlock>

                {deviceAdded && (<BasicLoginTextBlock>{t("DeviceRegister.DeviceAdded")}</BasicLoginTextBlock>)}

                <BasicLoginTextBlock>{t("DeviceRegister.ToContinueClick")}</BasicLoginTextBlock>
            </div>

            <LoginButtonGroup>
                <ResendLink text={t("Common.ResendTextConfirm")} onResend={handleResendEmail}/>
            </LoginButtonGroup>
        </>);
    }
    const hasDevices = false; // this.state.devices?.length > 0;
    return (<>
        <Redirects/>
        <LoginTitle data-testid={TestIds.Title}>
            <BackArrow url={ROUTE_LOGOUT}/>
            {t("DeviceRegister.Title")}
        </LoginTitle>
        <BasicLoginTextBlock data-testid={TestIds.Text}>{t("DeviceRegister.SubTitle")}</BasicLoginTextBlock>
        <DeviceWrapper>
            <DeviceSelector isCurrent device={currentDevice}/>
        </DeviceWrapper>
        {hasDevices && (<>
            <DeviceSeparator/>
            <ScrollBar isInFlex>
                <DeviceWrapper>
                    {devices.map(d => (
                            <DeviceSelector device={d} key={d.Id} isCurrent={false}/>
                    ))}
                </DeviceWrapper>
            </ScrollBar>
        </>)}
        <LoginButtonGroup>
            <Button
                    type={"submit"}
                    size={ButtonSize.Big}
                    onClick={() => handleAdd(false)}>
                {t("DeviceRegister.Add")}
            </Button>
            <Button isTransparent
                    size={ButtonSize.Big}
                    onClick={() => handleAdd(true)}>
                {t("DeviceRegister.DoNotAdd")}
            </Button>
        </LoginButtonGroup>
    </>);
};

export default withBusyIndicator({ isDelayed: true })(DevicePicker);

interface IDeviceSelectorProps {
    device: IAuthDevice;
    isCurrent: boolean;
}

const DeviceSelector: React.FunctionComponent<IDeviceSelectorProps> = ({ device, isCurrent }) => {
    const dispatch = useLoginDispatch();

    const deviceId = (device as IAuthDevice)?.Id;
    const renderDeleteButton = !isCurrent && deviceId; /* todo: check if the device Id not equals to session deviceId */

    const renderIcon = useCallback(() => {
        const Icon = device.Type === DeviceType.PC ? PcIcon : device.Type === DeviceType.Tablet ? TabletIcon : MobileIcon;

        return <Icon width="90px" height="90px"/>;
    }, [device]);

    const { t } = useTranslation(["Login"]);

    return (
            <StyledDeviceSelector>
                <DeviceBody isCurrent={isCurrent} data-testid={TestIds.DeviceBody}>
                    {renderIcon()}
                    <DeviceName data-testid={TestIds.Name}>{device.Name}</DeviceName>
                </DeviceBody>
                {renderDeleteButton &&
                        <IconButton isDecorative
                                    title={t("Login:DeviceRegister.Remove")}
                                    onClick={() => dispatch(deleteDevice(deviceId))}>
                            <CloseIcon width={IconSize.M}/>
                        </IconButton>
                }
            </StyledDeviceSelector>
    );
};

