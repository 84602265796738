import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./dayjsConfig";
import App from "./App";
import "./i18n.ts";
import { ROUTE_LOGIN, ROUTE_LOGOUT } from "./routes";
import LoginRouter from "./login/Router";
import Logout from "./login/Logout";

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

const getUserConfirmation = (message: string, callback: (ok: boolean) => void) => {
    // use "message" as Symbol-based key

    //@ts-ignore
    const dialogTrigger = window[Symbol.for(message)];

    if (dialogTrigger) {
        // pass the callback to delegate
        // to the invoked dialog
        return dialogTrigger(callback);
    }

    // Fallback to allowing navigation
    callback(true);
};

ReactDOM.render(
    <BrowserRouter basename={baseUrl} getUserConfirmation={getUserConfirmation}>
        <Switch>
            <Route path={ROUTE_LOGIN} component={LoginRouter}/>
            <Route path={ROUTE_LOGOUT} component={Logout}/>
            <Route path="*" component={App}/>
        </Switch>
    </BrowserRouter>,
    rootElement
);
export { ClickableWithAlertBase } from "./components/clickableWithAlert/ClickableWithAlert";