import React from "react";
import { Space } from "./Summary.styles";
import { CustomClasses } from "../../enums";

export default class Summary extends React.PureComponent {
    render() {
        return (
                <>
                    <Space className={CustomClasses.SummarySpace}/>
                    {React.Children.map(this.props.children, (child, index) => {
                        return (
                                <>
                                    {child}
                                    {index < React.Children.count(this.props.children) - 1 &&
                                            <Space className={CustomClasses.SummarySpace}/>
                            }
                        </>
                    );
                })}
            </>
        );
    }
}