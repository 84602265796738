import { getHourDifference, longDateFormat } from "@components/inputs/date/utils";
import { isNotDefined } from "@utils/general";
import i18next from "i18next";

import { DASH_CHARACTER } from "../../../constants";
import DateType, { getDateFormat, getUtcDayjs } from "../../../types/Date";

export const WORKING_HOURS_DECIMAL_PLACES = 3;

export const getHoursDiffFormatted = (start: Date, end: Date): string => {
    const diff = getHourDifference(getUtcDayjs(start), getUtcDayjs(end));
    if (isNotDefined(start) || isNotDefined(end) || isNaN(diff)) {
        return DASH_CHARACTER;
    }
    const dateDiff = getUtcDayjs().startOf("day").add(diff, "hours");
    const formattedValue = DateType.format(dateDiff, longDateFormat(getDateFormat(DateType.defaultTimeFormat)));
    return `${formattedValue} ${i18next.t("Components:Calendar.HourPlaceholder")}`;
};