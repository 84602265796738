import React from "react";
import { ISmartFieldChange } from "@components/smart/smartField/SmartField";
import { WithTranslation, withTranslation } from "react-i18next";
import { getStoredRanges, loadAndStoreRanges, replaceWildcards } from "../numberRange/NumberRange.utils";
import { INumberRangeEntity } from "@odata/GeneratedEntityTypes";
import TemporalFormDialog, { ITemporalFormDialogProps } from "../../views/formView/TemporalFormDialog";

interface IProps extends ITemporalFormDialogProps, WithTranslation {
}

interface IState {
    loaded: boolean;
}

class NumberRangeTemporalFormDialog extends React.Component<IProps, IState> {
    state: IState = {
        loaded: false
    };

    get isLoading(): boolean {
        return !getStoredRanges(this.props.storage) || !this.props.tReady;
    }

    componentDidMount() {
        this.init();
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>) {
        this.init();
    }

    async init(): Promise<void> {
        if (this.props.storage.loaded) {
            if (!this.state.loaded) {
                await loadAndStoreRanges(this.props.storage, true);

                this.setState({
                    loaded: true
                });
            }
        }
    }

    handleTemporalChange = (args: ISmartFieldChange): void => {
        const { storage } = this.props;
        const { bindingContext, additionalData, value } = args;

        if (bindingContext.getPath() === "NumberRange" && value) {
            const currentNumberRangeId = storage.getTemporalData(bindingContext)?.value;
            if (currentNumberRangeId === value) {
                // no change
                return;
            }

            const range = additionalData as INumberRangeEntity;
            const numberOurs = replaceWildcards(range?.NextNumber, storage.data.entity);

            storage.handleTemporalChange({
                bindingContext: storage.data.bindingContext.navigate("NumberOurs"),
                value: numberOurs,
                currentValue: numberOurs
            });
        } else if (bindingContext.getPath() === "NumberOurs") {
            // note: keep if here, because the FormDialog is used also in CreateNewAssetDialog,
            // where are some additionalFields, which triggers also handleTemporalChange

            // todo: user is changing Number directly. If it has match any NumberRange format, we should preselect it
            let obj: ISmartFieldChange = {
                bindingContext: storage.data.bindingContext.navigate("NumberRange"),
                value: additionalData?.Id ?? null,
                triggerAdditionalTasks: true
            };

            if (obj.value) {
                obj.additionalData = {
                    NextNumber: additionalData?.NextNumber
                };
            }

            storage.handleTemporalChange(obj);
        }
    };

    render() {
        const { onTemporalChange, ...restProps } = this.props;
        return (
            <TemporalFormDialog {...restProps}
                                onTemporalChange={this.handleTemporalChange}
                                onChange={this.props.onChange}
                                isLoading={this.isLoading}>
                {this.props.children}
            </TemporalFormDialog>
        );
    }
}

export default withTranslation(["Common", "NumberRange"], { withRef: true })(NumberRangeTemporalFormDialog);