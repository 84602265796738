import React from "react";
import { ProgressWheelInBanner, StatusCircle, StyledProgressBanner } from "./ProgressBanner.styles";
import { CloseIcon, ColoredIcon, FieldValidationPositiveIcon, FieldValidationWarningIcon } from "../icon";
import { IconSize, Status } from "../../enums";
import { ProgressStatus, TProgressStatus } from "./BackgroundJobs.utils";
import TestIds from "../../testIds";

export interface IProps {
    value?: number;
    parts?: number;
    count?: number;
    status: TProgressStatus;
    isActive?: boolean;
    onClick?: (event: React.MouseEvent) => void;

    /** Triggers hide transition */
    isClosing?: boolean;
    /** Called on transition end */
    onCloseFinish?: () => void;

    passRef?: React.Ref<HTMLButtonElement>;
    className?: string;
}

export default class ProgressBanner extends React.PureComponent<IProps> {
    renderProgress = (): React.ReactElement => {
        return (
            <ProgressWheelInBanner value={this.props.value}
                                   parts={this.props.parts}
                                   count={this.props.count}/>
        );
    };

    renderStatus = (): React.ReactElement => {
        if (!this.props.status || this.props.status === ProgressStatus.Progress) {
            return this.renderProgress();
        }

        const Icon = this.props.status === Status.Success ? FieldValidationPositiveIcon : this.props.status === Status.Warning ? FieldValidationWarningIcon : CloseIcon;

        return (
            <StatusCircle $status={this.props.status}
                          $isActive={this.props.isActive}>
                <ColoredIcon width={IconSize.M}>
                    <Icon/>
                </ColoredIcon>
            </StatusCircle>
        );
    };

    render() {
        return (
            <StyledProgressBanner
                ref={this.props.passRef}
                onClick={this.props.onClick}
                onTransitionEnd={this.props.onCloseFinish}
                $status={this.props.status}
                $isActive={this.props.isActive}
                $isClickable={true}
                $isClosing={this.props.isClosing}
                className={this.props.className}
                data-testid={TestIds.BackgroundJobsStatus}>
                {this.renderStatus()}
            </StyledProgressBanner>
        );
    }
}

export type { IProps as IProgressBannerProps };