import styled, { css } from "styled-components/macro";
import { LocalStackOrder, textAlignToJustify } from "../../../global.style";
import { TextAlign } from "../../../enums";
import { StyledText } from "../../text/Text.styles";
import { StyledInput, SyncSymbolDefaultLeft } from "../input/Input.styles";

export const FIELD_HOR_MARGIN = "32px";
export const FIELD_VER_MARGIN = "17px";
// for fast entry
export const FIELD_VER_MARGIN_SMALL = "12px";
// used on fields wrappers to compensate for the field margin
export const FIELD_VER_NEG_MARGIN = "-17px";
export const CONNECTED_FIELD_MARGIN = "5px";

interface IStyledField {
    isReadOnly?: boolean;
    hasPadding?: boolean;
    isWithoutRightMargin?: boolean;
    isSharpRight?: boolean;
    isSharpLeft?: boolean;
    isAuditTrailIgnored?: boolean;
    $width?: string;
    textAlign?: TextAlign;
}

export const getFieldPaddingValues = (args: IStyledField) => {
    let paddingLeft, paddingRight;

    if (args.isReadOnly && args.hasPadding) {
        switch (args.textAlign) {
            case TextAlign.Right:
                paddingLeft = 20;
                paddingRight = 12;
                break;
            case TextAlign.Center:
                paddingLeft = 12;
                paddingRight = 12;
                break;
            case TextAlign.Left:
            default:
                paddingLeft = 12;
                paddingRight = 20;
        }
    }

    return { paddingLeft, paddingRight };
};

export const SyncSymbolStyled = styled.div`
    position: absolute;
    height: 34px;
    // center vertically
    top: 50%;
    transform: translate(0, -50%);
    width: 34px;
    color: ${props => props.theme.C_TEXT_primary};
    display: flex;
    align-items: center;
    justify-content: center;

    & svg {
        position: absolute;
        left: 3px
    }
`;

export const getFieldPadding = (args: IStyledField) => {
    const { paddingLeft, paddingRight } = getFieldPaddingValues(args);

    return css`
        padding-left: ${paddingLeft && `${paddingLeft}px`};
        padding-right: ${paddingRight && `${paddingRight}px`};

        ${args.isReadOnly && css`
            & ${SyncSymbolStyled} {
                left: calc(${SyncSymbolDefaultLeft}px - ${paddingLeft ?? 0}px);
            }
        `};
    `;
};

export const StyledField = styled.div<IStyledField>`
    position: relative;
    display: flex;
    width: ${props => props.$width};
    flex-direction: column;
    opacity: ${props => props.isAuditTrailIgnored ? props.theme.C_AUDIT_TRAIL_DIFF_VISIBLE_OPACITY : ""};

    margin-right: ${props => props.isReadOnly || props.isWithoutRightMargin ? 0 : props.isSharpRight ? CONNECTED_FIELD_MARGIN : FIELD_HOR_MARGIN};
    ${props => getFieldPadding(props)};

    ${SyncSymbolStyled} {
        background-color: ${props => props.theme.C_FIELD_sync};
        left: ${SyncSymbolDefaultLeft}px;
        border-top-left-radius: ${props => props.theme.borderLargeRadius};
        border-bottom-left-radius: ${props => props.theme.borderLargeRadius};
    }

    // those styles should work even when the fields have wrapper around them (like LastFieldWrapper or GroupedKey)
    //  => use multiple selectors
    ${props => !props.isSharpLeft && css<IStyledField>`
        [data-isreadonly="true"][data-textalign="left"]:has(+ &[data-isreadonly="false"]),
        [data-isreadonly="true"][data-textalign="left"]:has(+ div > &[data-isreadonly="false"]) {
            margin-right: 12px;
        }

        [data-isreadonly="false"]:has(+ &[data-isreadonly="true"]),
        [data-isreadonly="false"]:has(+ div > &[data-isreadonly="true"]),
        [data-isreadonly="true"][data-textalign="right"]:has(+ &[data-isreadonly="false"]),
        [data-isreadonly="true"][data-textalign="right"]:has(+ div > &[data-isreadonly="false"]) {
            margin-right: 20px;
        }
    `};
`;

export const FIELD_CONTENT_PADDING = 12;

interface IStyledFieldContent {
    hasPadding: boolean;
    hasMinHeight: boolean;
    hasExtraTopMargin: boolean;
    textAlign: TextAlign;
    isSharpLeft: boolean;
    isSharpRight: boolean;
    isSynchronized: boolean;
}

const getSyncBackgroundWidth = (props: IStyledFieldContent): number => {
    if (!props.isSharpRight && !props.isSharpLeft) {
        return 0;
    }

    if (props.isSharpLeft && !props.isSharpRight) {
        return 20;
    }

    // 12px + 20px for possible field paddings
    return 12 + 20 + parseInt(CONNECTED_FIELD_MARGIN);
};

export const StyledFieldContent = styled.div<IStyledFieldContent & { minWidth?: string }>`
    min-height: ${props => props.hasMinHeight ? "34px" : "0"};
    min-width: ${props => props.minWidth ? props.minWidth : "0"};
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding: 0 ${props => props.hasPadding ? `${FIELD_CONTENT_PADDING}px` : "0"};
    margin-top: ${props => props.hasExtraTopMargin ? "6px" : "0"};
    justify-content: ${props => textAlignToJustify(props.textAlign)};
    position: relative;

    // we often add some fixed (readonly) text information before input. Use common rule here.
    ${StyledText} + ${StyledInput} {
        margin-left: ${CONNECTED_FIELD_MARGIN};
    }
;

    ${props => props.isSynchronized && css<IStyledFieldContent>`
        &::before {
            content: "";
            position: absolute;
            width: ${props => `calc(100% + ${getSyncBackgroundWidth(props)}px)`};
            height: 100%;
            left: ${props => {
                const width = getSyncBackgroundWidth(props);

                if (width > 0) {
                    return props.isSharpLeft && !props.isSharpRight ? `${-width}px` : `-${width / 2}px`;
                }

                return 0;
            }};
            background-color: ${props => props.theme.C_FIELD_sync};
            border-top-left-radius: ${props => !props.isSharpLeft ? props.theme.borderLargeRadius : "unset"};
            border-bottom-left-radius: ${props => !props.isSharpLeft ? props.theme.borderLargeRadius : "unset"};
            border-top-right-radius: ${props => !props.isSharpRight ? props.theme.borderLargeRadius : "unset"};
            border-bottom-right-radius: ${props => !props.isSharpRight ? props.theme.borderLargeRadius : "unset"};
            z-index: ${LocalStackOrder.BelowContent};
        }
    `};
`;

export const FieldMouseCapture = styled.div`
    position: absolute;
    left: -5px;
    top: 0;
    width: calc(100% + 10px);
    height: 100%;
    z-index: 5000;
`;