import React, { useCallback } from "react";
import { StyledTextInDialog } from "./Inbox.styles";
import { useConfirmationDialog } from "@components/dialog/withConfirmationDialog";
import Clickable from "../../components/clickable";
import { useTranslation } from "react-i18next";

interface IProps {
    text: string;
}

const LinkWithTextInDialog: React.FC<IProps> = ({ text }) => {
    const dialog = useConfirmationDialog();
    const { t } = useTranslation("Inbox");

    const title = t("EmailText.Title");

    const handleOpen = useCallback(() => {
        const hasText = !!text;
        const contents = hasText ? text : t("EmailText.Empty");
        dialog.open({
            content: (
                    <StyledTextInDialog $isPlaceholder={!hasText}>{contents}</StyledTextInDialog>
            ),
            allowCancel: false,
            confirmText: t("EmailText.Close"),
            dialogProps: {
                title,
                removePadding: true
            }
        });
    }, [dialog, text, title, t]);

    return (
        <Clickable onClick={handleOpen} isLink>{title}</Clickable>
    );
};

export default LinkWithTextInDialog;
