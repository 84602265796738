import styled, { css } from "styled-components/macro";
import { HorizontalLineWithoutBubbles } from "./ConfigurationList.styles";

export const PlaceholderBubble = css`
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${props => props.theme.C_ACT_main};
    position: absolute;
`;

export const ItemPlaceholderWrapper = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ItemPlaceholderLine = styled.div`
    ${HorizontalLineWithoutBubbles};

    &:before {
        ${PlaceholderBubble};
        top: 50%;
        transform: translateY(-50%);
        left: 0;
    }

    &:after {
        ${PlaceholderBubble};
        top: 50%;
        transform: translateY(-50%);
        right: 0;
    }
`;