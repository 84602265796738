import { AuditTrailFieldType } from "@components/smart/FieldInfo";
import { transparentize } from "polished";
import styled, { css } from "styled-components/macro";

import { RadioButtonGroupLayout } from "../../../enums";
import { getFocusBorderDefinition, T_HEADER_small, T_TITLE_4_NORMAL } from "../../../global.style";


export const StyledRadioButton = styled.div<{
    _disabled: boolean;
}>`
    display: flex;
    align-items: center;
    position: relative;
    opacity: ${props => props._disabled ? 0.5 : 1};
    cursor: ${props => props._disabled ? "default" : "pointer"};
    outline: none;
`;

export const Circle = styled.div<{
    _checked: boolean;
    _disabled: boolean;
}>`
    position: relative;
    width: 16px;
    min-width: 16px;
    height: 16px;
    background-color: ${props => props._checked ? props.theme.C_ACT_hover_without_opacity : props.theme.C_BG_menu};
    border: 1px solid;
    border-color:  ${props => transparentize(0.5, props.theme.C_ACT_main)};
    border-radius: 50%;
    &:hover{
      border-color:  ${props => props._disabled ? "" : props.theme.C_ACT_main};
    }
    
    ${(props) => !props._disabled && css`
        ${StyledRadioButton}:focus & {
            outline: none;
            &:before {
                ${getFocusBorderDefinition({offset: 2, borderRadius: "50%" })}
            }
        }
    `
    };
`;

export const Label = styled.div`
    margin-left: 12px;
    color: ${props => props.theme.C_TEXT_primary};
    ${T_TITLE_4_NORMAL};
`;

export const InnerCircle = styled.div`
    width: 8px;
    height: 8px;
    margin: 3px;
    background-color:  ${props => props.theme.C_ACT_main};
    border-radius: 50%;
`;

export const StyledRadioButtonGroup = styled.div<{
    _layout: RadioButtonGroupLayout
    auditTrailType?: AuditTrailFieldType;
}>`
    & > div {
        margin-right: ${props => props._layout !== RadioButtonGroupLayout.Column ? "24px" : ""};
        margin-bottom: ${props => props._layout === RadioButtonGroupLayout.Column ? "12px" : ""};
    }
    display: flex;
    flex-direction: ${props => props._layout === RadioButtonGroupLayout.Column ? "column" : "row"};

    ${props => props._layout === RadioButtonGroupLayout.Row && `
        & > ${StyledRadioButton}:last-child {
            margin-right: 0;
        }
    `}

    ${props => (props.auditTrailType === AuditTrailFieldType.NoDifference || props.auditTrailType === AuditTrailFieldType.HoveredNoDifference) && `
        border-radius: 17px;
        background: ${props.theme.C_AUDIT_TRAIL_NO_DIFF};
        padding: 5px;
        & > ${StyledRadioButton} {
            opacity: ${props.auditTrailType === AuditTrailFieldType.NoDifference ? 0 : 1};
        } 
    `}

    ${props => (props.auditTrailType === AuditTrailFieldType.Difference || props.auditTrailType === AuditTrailFieldType.HoveredDifference) && `
        border: solid 3px ${props.theme.C_AUDIT_TRAIL_DIFF};
        border-radius: 17px;
        padding: 5px;
    `};
`;

export const StyledRadioListItem = styled.div`
    display: flex;
    min-height: 70px;
    margin: 7px 0;
    & > svg {
        margin-right: 25px;
        transform: scale(1.3);
    }
    & > div {
        & > h4 {
            font-weight: normal;
            ${T_HEADER_small}
        }
        display: flex;
        flex-direction: column;
        & > p {
            margin: 8px 0 0 0;
        }
    }
`;