import { AppContext } from "../../contexts/appContext/AppContext.types";
import { loadChartsOfAccountsMemoized } from "@odata/Data.utils";
import { IContextInitArgs, IFormStorageSaveResult } from "../../views/formView/FormStorage";
import {
    getAccountUsage,
    getMandatoryAccountsPromise,
    IAccountUsage,
    isMandatoryAccount
} from "./ChartOfAccounts.utils";
import { IAccountEntity } from "@odata/GeneratedEntityTypes";
import { withPermissionContext } from "../../contexts/permissionContext/withPermissionContext";
import ChartOfAccountsBaseFormView from "./ChartOfAccountsBaseFormView";
import { toTuple } from "../../global.types";


class ChartOfAccountsFormView extends ChartOfAccountsBaseFormView {
    static contextType = AppContext;
    //sadly, breaks typescript type checking
    //context: React.ContextType<typeof AppContext>;
    static defaultProps = {
        title: "ChartsOfAccounts:Account"
    };

    getAccountUsageLoadPromise(args: IContextInitArgs): Promise<IAccountUsage> {
        if (!args.bindingContext.isNew()) {
            const parentId = args.bindingContext.getParent().getKey();
            const id = args.bindingContext.getKey();
            return getAccountUsage(parentId, id);
        }
        return null;
    }

    getChartOfAccountsPromise(args: IContextInitArgs): Promise<IAccountEntity[]> {
        const promise = loadChartsOfAccountsMemoized(this.context);
        promise.then(chartsOfAccounts => this.props.storage.setCustomData({ chartOfAccounts: chartsOfAccounts }));
        return promise;
    }

    getAdditionalLoadPromise = (args: IContextInitArgs): Promise<unknown>[] => {
        return [
            this.getAccountUsageLoadPromise(args),
            this.getChartOfAccountsPromise(args),
            getMandatoryAccountsPromise(this.props.storage)
        ];
    };

    setCustomAccountStorageData = async (): Promise<void> => {
        const [journalData] = await Promise.all(toTuple<[Promise<IAccountUsage>]>(this.props.storage.data.additionalResults));

        const isInvertibleWithPostedChildren = this.props.storage.data.entity.IsInvertible && journalData?.DescendantsHaveJournalEntries;
        const isMandatory = isMandatoryAccount(this.props.storage, this.props.storage.data.entity);
        const isPosted = journalData?.HasJournalEntries || isInvertibleWithPostedChildren;

        const isParentInvertible = this.props.storage.data.entity?.Parent?.IsInvertible;

        this.props.storage.setCustomData({
            journalData,
            isParentInvertible,
            isPosted,
            isMandatory
        });
    };

    async onAfterLoad(){

        await this.setCustomAccountStorageData();
        return super.onAfterLoad();
    }

    async save(): Promise<IFormStorageSaveResult> {
        return this._save(this.props.storage.t("ChartsOfAccounts:Validation.Saved"));
    }
}

export default withPermissionContext(ChartOfAccountsFormView);
export { ChartOfAccountsFormView as ChartOfAccountsFormViewForExtend };