import { getTableIntentLink } from "@components/drillDown/DrillDown.utils";
import { IGetValueArgs } from "@components/smart/FieldInfo";
import { FilterBarGroup, getDefaultFilterGroupDef } from "@components/smart/smartFilterBar/SmartFilterBar.types";
import { ISummaryItem } from "@components/smart/smartSummaryItem/SmartSummaryItem";
import {
    EntitySetName,
    EntityTypeName,
    IPrSalaryAdvanceEntity,
    OtherLiabilityEntity,
    PrEmployeeEntity,
    PrSalaryAdvanceEntity
} from "@odata/GeneratedEntityTypes";
import { IFormatOptions } from "@odata/OData.utils";
import { getCompanyCurrency } from "@utils/CompanyUtils";
import i18next from "i18next";

import { BasicInputSizes, Sort } from "../../../enums";
import { TValue } from "../../../global.types";
import { Model } from "../../../model/Model";
import { ROUTE_OTHER_LIABILITY } from "../../../routes";
import Currency from "../../../types/Currency";
import { getUtcDate } from "../../../types/Date";
import { IFormDef } from "../../../views/formView/Form";
import { ISplitPageTableDef } from "../../../views/table/TableView.utils";
import { userNameWithAvatarTableFormatter } from "../../admin/users/UsersDef";
import { documentTableAmountFormatter } from "../../documents/Document.utils";
import { setDefByEntityType } from "../../getDefByEntityType";
import { getNumberOursSummaryDef, getNumberRangeFieldDefs } from "../../numberRange/NumberRange.utils";
import { getItemBreadCrumbsText, IDefinition, IGetDefinition } from "../../PageUtils";
import { employeeSelectDef } from "../employee/Employee.utils";
import SalaryAdvanceFormView from "./SalaryAdvanceFormView";


export const getDefinitions: IGetDefinition = (): IDefinition => {
    const table: ISplitPageTableDef = {
        id: `${EntityTypeName.PrSalaryAdvance}Table`,
        filterBarDef: [{
            ...getDefaultFilterGroupDef(FilterBarGroup.Filters),
            defaultFilters: [
                PrSalaryAdvanceEntity.NumberOurs
            ],
            filterDefinition: {
                [PrSalaryAdvanceEntity.NumberOurs]: {}
            },
            isValueHelp: true
        }],
        initialSortBy: [{ id: PrSalaryAdvanceEntity.NumberOurs, sort: Sort.Desc }],
        columns: [
            PrSalaryAdvanceEntity.NumberOurs,
            PrSalaryAdvanceEntity.Employee,
            PrSalaryAdvanceEntity.Amount,
            PrSalaryAdvanceEntity.DateAccountingTransaction,
            PrSalaryAdvanceEntity.PairedDocument
        ],
        columnDefinition: {
            [PrSalaryAdvanceEntity.NumberOurs]: {},
            [PrSalaryAdvanceEntity.Employee]: {
                formatter: (val: TValue, args: IFormatOptions) => {
                    return userNameWithAvatarTableFormatter(val, {
                        ...args,
                        entity: (args.entity as IPrSalaryAdvanceEntity).Employee
                    });
                },
                additionalProperties: [
                    { id: PrEmployeeEntity.FirstName },
                    { id: PrEmployeeEntity.LastName }
                ]
            },
            [PrSalaryAdvanceEntity.Amount]: {
                formatter: (val: TValue, args: IFormatOptions) => {
                    const entity = args.entity as IPrSalaryAdvanceEntity;

                    return Currency.format(val, { currency: entity.CurrencyCode });
                }
            },
            [PrSalaryAdvanceEntity.DateAccountingTransaction]: {},
            [PrSalaryAdvanceEntity.PairedDocument]: {
                fieldSettings: {
                    displayName: OtherLiabilityEntity.NumberOurs
                }
            }
        },
        title: i18next.t("SalaryAdvances:Title"),
        additionalProperties: [
            { id: PrSalaryAdvanceEntity.Currency }
        ],
        // tabs needs to be present so that TableView renders them
        // but are filled dynamically inside SalaryAdvanceTableView
        tabs: [],
        tabsSettings: {}
    };
    const summary: ISummaryItem[] = [
        getNumberOursSummaryDef("SalaryAdvances")
    ];

    // enhance select def with SalaryCurrency and store it automatically into SalaryAdvance.Currency on selection
    const employeeSelectDefExtended = employeeSelectDef;

    employeeSelectDefExtended.additionalProperties.push(
        {
            id: PrEmployeeEntity.SalaryCurrency
        }
    );
    employeeSelectDefExtended.fieldSettings.additionalProperties = [
        ...(employeeSelectDefExtended.fieldSettings.additionalProperties ?? []),
        { id: PrEmployeeEntity.SalaryCurrency }
    ];
    employeeSelectDefExtended.fieldSettings.localDependentFields.push(
        {
            from: { id: PrEmployeeEntity.SalaryCurrency },
            to: { id: PrSalaryAdvanceEntity.Currency }
        }
    );
    employeeSelectDefExtended.affectedFields = [
        ...(employeeSelectDefExtended.affectedFields ?? []),
        { id: PrSalaryAdvanceEntity.Amount }
    ];

    const form: IFormDef = {
        id: `${EntityTypeName.PrSalaryAdvance}Form`,
        translationFiles: getDefinitions.translationFiles,
        getItemBreadCrumbText: (storage: Model) =>
                getItemBreadCrumbsText(storage, i18next.t("SalaryAdvances:New"), i18next.t("SalaryAdvances:FormTitle")),
        isDeletable: true,
        formControl: SalaryAdvanceFormView,
        summary,
        fieldDefinition: {
            ...getNumberRangeFieldDefs("SalaryAdvances"),
            [PrSalaryAdvanceEntity.Employee]: { ...employeeSelectDefExtended },
            [PrSalaryAdvanceEntity.Amount]: {
                width: BasicInputSizes.S,
                fieldSettings: {
                    unit: (args: IGetValueArgs) => {
                        const entity = args.storage.data.entity as IPrSalaryAdvanceEntity;

                        return Currency.getCurrencyUnit(entity.CurrencyCode ?? getCompanyCurrency(args.context));
                    }
                }
            },
            [PrSalaryAdvanceEntity.DateAccountingTransaction]: {
                width: BasicInputSizes.M,
                defaultValue: getUtcDate()
            }
        },
        additionalProperties: [
            { id: PrSalaryAdvanceEntity.PairedDocument },
            { id: PrSalaryAdvanceEntity.Currency }
        ],
        groups: [
            {
                id: "main",
                rows: [
                    [
                        { id: PrSalaryAdvanceEntity.Employee },
                        { id: PrSalaryAdvanceEntity.Amount },
                        { id: PrSalaryAdvanceEntity.DateAccountingTransaction }
                    ]
                ]
            },
            {
                id: "Tabs",
                isVisible: (args: IGetValueArgs) => {
                    return !args.storage.data.bindingContext.isNew();
                },
                tabs: [
                    {
                        id: "PairedDocuments",
                        title: i18next.t("SalaryAdvances:FormTab.PairedDocuments"),
                        table: {
                            id: "pairedDocuments",
                            entitySet: EntitySetName.OtherLiabilities,
                            filter: (args: IGetValueArgs) => {
                                return `Id eq ${(args.storage.data.entity as IPrSalaryAdvanceEntity).PairedDocument?.Id}`;
                            },
                            initialSortBy: [
                                { id: "DateAccountingTransaction", sort: Sort.Asc }
                            ],
                            columns: [
                                { id: "DateAccountingTransaction" },
                                {
                                    id: "DocumentType",
                                    fieldSettings: {
                                        displayName: "Name"
                                    },
                                    label: i18next.t("Document:PairedDocuments.DocumentType")
                                },
                                {
                                    id: "NumberOurs",
                                    label: i18next.t("Document:PairedDocuments.Document"),
                                    formatter: (val, args) => {
                                        return getTableIntentLink(val as string, {
                                            route: `${ROUTE_OTHER_LIABILITY}/${(args.storage.data.entity as IPrSalaryAdvanceEntity).PairedDocument?.Id}`,
                                            context: args.storage.context,
                                            storage: args.storage
                                        });
                                    }
                                },
                                {
                                    id: "Amount",
                                    formatter: (val, args) => documentTableAmountFormatter(args.entity, args.context),
                                    additionalProperties: [
                                        { id: "TransactionAmount" }, { id: "TransactionCurrency" }
                                    ]
                                }
                            ]
                        }
                    }
                ]
            }
        ]
    };
    return {
        entitySet: EntitySetName.PrSalaryAdvances,
        table,
        form
    };
};
getDefinitions.translationFiles = ["SalaryAdvances", "Common", "NumberRange", "Document"];
setDefByEntityType(EntityTypeName.PrSalaryAdvance, getDefinitions);