import { LogoutIcon } from "@components/icon";
import React, { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../contexts/authContext/AuthContext";
import { Button } from "../../components/button";

interface IProps {
    onClick?: () => void;
    className?: string;
    style?: React.CSSProperties;
}

export const LogoutButton: React.FunctionComponent<IProps> = React.memo((props) => {
    const { onClick } = props;
    const { t } = useTranslation(["UserSetting"]);
    const authContext = useContext(AuthContext);
    const handleLogoutClick = useCallback(() => {
        authContext.logout();
        onClick?.();
    }, [onClick, authContext]);

    return (
        <Button
            hotspotId={"LogoutButton"}
            isTransparent
            icon={<LogoutIcon/>}
            onClick={handleLogoutClick}
            className={props.className}
            style={props.style}
        >
            {t("UserSetting:Logout")}
        </Button>
    );
});