import i18next from "i18next";
import React, { PureComponent } from "react";

import { Button } from "../../button";
import { IDialogProps } from "../../dialog";
import { AutoSizedCheckboxGroup } from "../../inputs/checkboxGroup/AutoSizedCheckboxGroup";
import { ICheckboxGroupChange, ICheckboxGroupItem, ICheckboxSubgroup } from "../../inputs/checkboxGroup/CheckboxGroup";
import { StyledDialog } from "./SmartFilterBar.styles";


export interface IProps extends IDialogProps {
    title: string;
    items: ICheckboxGroupItem[];
    selectedItems: string[];
    itemsSubgroups?: ICheckboxSubgroup[];
    onChange?: (args: ICheckboxGroupChange) => void;
    customPreContent?: React.ReactElement;
}

export default class CustomizationDialog extends PureComponent<IProps> {

    render() {
        const { items, selectedItems, onChange, ...passProps } = this.props;

        return (
            <StyledDialog {...passProps}
                          footer={(
                              <>
                                  <Button isTransparent
                                          onClick={this.props.onClose}>
                                      {i18next.t("Common:General.Cancel")}
                                  </Button>
                                  <Button onClick={this.props.onConfirm}>
                                      {i18next.t("Common:General.Confirm")}
                                  </Button>
                              </>
                          )}>
                {this.props.customPreContent}
                {items &&
                    <AutoSizedCheckboxGroup
                        values={this.props.selectedItems}
                        onChange={this.props.onChange}
                        items={this.props.items}
                        subgroups={this.props.itemsSubgroups}
                        hasMasterCheckbox/>}
            </StyledDialog>
        );
    }
}