import React from "react";
import { Button, ButtonGroup } from "../../components/button";
import { IFormViewProps } from "../../views/formView/FormView";
import { ChartOfAccountTemplateContext, ICOATemplateContext } from "./ChartOfAccountsTemplatesContext";
import { withPermissionContext } from "../../contexts/permissionContext/withPermissionContext";
import ChartOfAccountsBaseFormView from "../chartOfAccounts/ChartOfAccountsBaseFormView";
import { IFormStorageSaveResult } from "../../views/formView/FormStorage";
import { IAccountUsage, isMandatoryAccount } from "../chartOfAccounts/ChartOfAccounts.utils";
import { toTuple } from "../../global.types";
import { IAccountEntity } from "@odata/GeneratedEntityTypes";
import SmartFormDeleteButton from "../../components/smart/smartFormDeleteButton/SmartFormDeleteButton";

interface IProps extends IFormViewProps<IAccountEntity> {
}

class ChartOfAccountsTemplatesFormView extends ChartOfAccountsBaseFormView {
    static contextType = ChartOfAccountTemplateContext;

    static defaultProps = {
        title: "ChartsOfAccountsTemplates:AccountTemplate",
        formProps: {
            shouldHideAuditTrail: true,
            shouldHideVariant: true,
            hideBreadcrumbs: true
        }
    };

    constructor(props: IProps, context: ICOATemplateContext) {
        super(props);
    }

    async onAfterLoad(){

        await Promise.all(toTuple<[Promise<IAccountUsage>]>(this.props.storage.data.additionalResults));
        await this.setCustomAccountStorageData();

        return super.onAfterLoad();
    }

    setCustomAccountStorageData = async (): Promise<void> => {
        // note: this behavior is similar as for CoA, except journalData as CoAT has no journal entries.
        // But we need to set same flags as for CoA, so the common defs are valid and
        // some fields are not editable for mandatory accounts.
        const isMandatory = isMandatoryAccount(this.props.storage, this.props.storage.data.entity);
        // templates cannot be posted, only accounts
        const isPosted = false;
        const isParentInvertible = this.props.storage.data.entity?.Parent?.IsInvertible;

        this.props.storage.setCustomData({
            isParentInvertible,
            isPosted, isMandatory
        });
    };

    async save(): Promise<IFormStorageSaveResult> {
        const result = await this._save(this.props.storage.t("ChartsOfAccountsTemplates:Validation.Saved"));

        if (result?.bindingContext) {
            this.context.isChanged = true;
        }

        return result;
    }

    renderButtons = (): React.ReactElement => {
        const isReadOnly = this.props.storage.data.entity.Id < 0;

        return (
            <ButtonGroup wrap={"wrap"}>
                <Button onClick={this.props.onCancel}
                        isTransparent>{this.props.storage.t("Common:General.Cancel")}</Button>
                {!isReadOnly && this.isDeletable &&
                    <SmartFormDeleteButton storage={this.props.storage}
                                           onClick={this.handleDelete}/>
                }
                {!isReadOnly &&
                    <Button
                        onClick={this.save}>{this.props.storage.t("Common:General.Save")}</Button>
                }
            </ButtonGroup>
        );
    };
}

export default withPermissionContext(ChartOfAccountsTemplatesFormView);