import { formatDateToDateString } from "@components/inputs/date/utils";
import { ISmartFieldBlur, ISmartFieldChange } from "@components/smart/smartField/SmartField";
import {
    CurrencyUsedByCompanyEntity,
    EntitySetName,
    ExchangeRateEntity,
    ICompanyFixedExchangeRateEntity,
    ICurrencyUsedByCompanyEntity,
    IExchangeRateEntity
} from "@odata/GeneratedEntityTypes";
import { ExchangeRateTypeCode } from "@odata/GeneratedEnums";
import { IBatchResult } from "@odata/OData";
import { ODataQueryResult } from "@odata/ODataParser";
import { getCompanyCurrencyCode } from "@pages/companies/Company.utils";
import { getCurrencyInfos } from "@pages/currencyUsedByCompany/CurrencyUsedByCompany.utils";
import { isAccountAssignmentCompany } from "@utils/CompanyUtils";
import { isObjectEmpty } from "@utils/general";
import { logger } from "@utils/log";
import { cloneDeep } from "lodash";

import { withPermissionContext } from "../../contexts/permissionContext/withPermissionContext";
import { FormMode, Status } from "../../enums";
import BindingContext, { IEntity } from "../../odata/BindingContext";
import { getUtcDate, getUtcDayjs } from "../../types/Date";
import { IFormStorageDefaultCustomData, IFormStorageSaveResult } from "../../views/formView/FormStorage";
import { FormViewForExtend, IFormViewProps } from "../../views/formView/FormView";
import { getActiveFiscalYears, getOldestActiveFY } from "../fiscalYear/FiscalYear.utils";
import { CURRENT_CNB_RATE_PATH, FISCAL_YEAR_PATH } from "./CurrencyUsedByCompanyDef";

export interface ICurrencyUsedByCompanyFormCustomData extends IFormStorageDefaultCustomData {
    selectedFYId?: number;
    IsCurrencyExchangeRateProvidedByCentralBank?: boolean;
    IsCurrencyExchangeRateProvidedByCentralBankDaily?: boolean;
}

class CurrencyUsedByCompanyFormView extends FormViewForExtend<ICurrencyUsedByCompanyEntity, IFormViewProps<ICurrencyUsedByCompanyEntity, ICurrencyUsedByCompanyFormCustomData>> {
    async handleChange(e: ISmartFieldChange): Promise<void> {
        const path = e.bindingContext.getPath(true);
        const storage = this.props.storage;
        if (path === FISCAL_YEAR_PATH && e.triggerAdditionalTasks) {
            // todo: how to handle invalid data before switching to another fiscal year? Currently the data are not saved.
            //  We may need to show an alert or validate the date before switching and copy them from localContext to the entity.
            this.props.storage.setCustomData({
                selectedFYId: e.value as number,
            });
        }

        if (path === CurrencyUsedByCompanyEntity.ExchangeRateType) {
            storage.setValueByPath(CurrencyUsedByCompanyEntity.FixedExchangeRates, []);
            if (e.value === ExchangeRateTypeCode.ByBank) {
                await this.setCurrentCNBInfo();
            } else {
                this.fillFixedRatesForActiveFYs();
                this.getDefaultRatesForPeriods().then(() => {
                    this.forceUpdate();
                });
            }
        }

        if (path === CurrencyUsedByCompanyEntity.SetFixedExchangeRate) {
            if (e.value) {
                await this.setCurrentCNBInfo();
                this.getDefaultRatesForPeriods().then(() => {
                    this.forceUpdate();
                });
            }
        }
        this.props.storage.handleChange(e);

        if (path === CurrencyUsedByCompanyEntity.FromCurrency && e.triggerAdditionalTasks) {
            await this.refreshCurrencyInfo();
            const {
                IsCurrencyExchangeRateProvidedByCentralBank,
                IsCurrencyExchangeRateProvidedByCentralBankDaily
            } = this.props.storage.getCustomData() ?? {};
            storage.setValueByPath(CurrencyUsedByCompanyEntity.ExchangeRateType, IsCurrencyExchangeRateProvidedByCentralBankDaily ? ExchangeRateTypeCode.ByBank : ExchangeRateTypeCode.Fixed);
            storage.setValueByPath(CurrencyUsedByCompanyEntity.SetFixedExchangeRate, IsCurrencyExchangeRateProvidedByCentralBank);
            if (!IsCurrencyExchangeRateProvidedByCentralBankDaily) {
                this.fillFixedRatesForActiveFYs();
                this.getDefaultRatesForPeriods().then(() => {
                    this.forceUpdate();
                });
            }
            if (IsCurrencyExchangeRateProvidedByCentralBank) {
                await this.setCurrentCNBInfo();
            } else {
                storage.setValueByPath(CURRENT_CNB_RATE_PATH, null);
                storage.setDefaultValueByPath(CurrencyUsedByCompanyEntity.Amount);
            }
        }
        this.forceUpdate();
    }

    async handleBlur(args: ISmartFieldBlur): Promise<void> {
        await this.props.storage.handleBlur(args);
        this.props.storage.refresh(); // to refresh summary item
    }

    refreshCurrencyInfo = async () => {
        const storage = this.props.storage;
        const currencyInfo = await getCurrencyInfos(storage.oData, storage.getValueByPath(CurrencyUsedByCompanyEntity.FromCurrencyCode));
        const { IsCurrencyExchangeRateProvidedByCentralBank, IsCurrencyExchangeRateProvidedByCentralBankDaily } = currencyInfo ?? {};
        storage.setCustomData({
            IsCurrencyExchangeRateProvidedByCentralBank,
            IsCurrencyExchangeRateProvidedByCentralBankDaily
        });
        storage.refresh();
    }

    setCurrentCNBInfo = async () => {
        const storage = this.props.storage;
        const rateRes = await storage.oData.getEntitySetWrapper(EntitySetName.ExchangeRates).query()
                .filter(`${ExchangeRateEntity.FromCurrencyCode} eq '${storage.data.entity.FromCurrencyCode}' and ${ExchangeRateEntity.DateIssued} le ${formatDateToDateString(getUtcDate())}`)
                .select(ExchangeRateEntity.FromCurrencyCode, ExchangeRateEntity.ExchangeRatePerUnit, ExchangeRateEntity.DateIssued, ExchangeRateEntity.Amount)
                .orderBy(ExchangeRateEntity.DateIssued, false)
                .top(1)
                .fetchData<IExchangeRateEntity[]>();

        const { ExchangeRatePerUnit, Amount } = rateRes.value[0] ?? {};
        storage.setValueByPath(CURRENT_CNB_RATE_PATH, ExchangeRatePerUnit);
        storage.setValueByPath(CurrencyUsedByCompanyEntity.Amount, Amount);
    };

    getDefaultRatesForPeriods = async () => {
        const storage = this.props.storage;
        const batch = storage.oData.batch();
        batch.beginAtomicityGroup("getDefaultRatesForPeriods");

        try {
            for (const rate of this.entity.FixedExchangeRates) {
                const periodEnd = !!rate.FiscalPeriod ? getUtcDayjs(rate.FiscalPeriod.DateStart).subtract(1, "day") : getUtcDayjs(rate.FiscalYear.DateEnd);
                if (!rate.ExchangeRateDirect && getUtcDayjs().isAfter(periodEnd)) {
                    batch.getEntitySetWrapper(EntitySetName.ExchangeRates).query()
                            .filter(`${ExchangeRateEntity.FromCurrencyCode} eq '${this.entity.FromCurrencyCode}' and ${ExchangeRateEntity.DateIssued} le ${formatDateToDateString(periodEnd)}`)
                            .select(ExchangeRateEntity.ExchangeRateDirect, ExchangeRateEntity.DateIssued, ExchangeRateEntity.Amount)
                            .orderBy(ExchangeRateEntity.DateIssued, false)
                            .top(1);
                }
            }

            const result = await batch.execute<IExchangeRateEntity[]>() as IBatchResult<ODataQueryResult>[];
            const CNBRates: IExchangeRateEntity[] = result.map(r => r.body.value[0]);
            for (const CBNRate of CNBRates.filter(r => !!r)) {
                const rate = this.entity.FixedExchangeRates.find(r => {
                    if (r.IsClosingFiscalYear || isObjectEmpty(r.FiscalPeriod)) {
                        if (!!r.ExchangeRateDirect) {
                            return false;
                        }
                        return getUtcDayjs(r.FiscalYear.DateEnd).isSame(getUtcDayjs(CBNRate.DateIssued), "year");
                    }
                    const periodStart = getUtcDayjs(CBNRate.DateIssued).add(1, "month");
                    return getUtcDayjs(r.FiscalPeriod?.DateStart).isSame(periodStart, "month");
                });

                if (rate) {
                    rate.ExchangeRateDirect = CBNRate.ExchangeRateDirect;
                    rate.Amount = CBNRate.Amount;
                }
            }
        } catch (e) { /* fall silently */
            logger.error(e.toString());
        }
    };

    fillFixedRatesForActiveFYs = () => {
        const storage = this.props.storage;
        const { entity } = storage.data;
        const activeFYs = getActiveFiscalYears(storage.context);
        const fixedRates = entity.FixedExchangeRates ?? [];

        const newRates: ICompanyFixedExchangeRateEntity[] = [];

        activeFYs.forEach(fy => {
            fy.Periods?.forEach(period => {
                const rate = fixedRates.find(r => r.FiscalYear?.Id === fy.Id && r.FiscalPeriod?.Id === period.Id);
                if (!rate) {
                    newRates.push(BindingContext.createNewEntity<ICompanyFixedExchangeRateEntity>(newRates.length, {
                        FiscalYear: { ...fy },
                        FiscalPeriod: { ...period },
                        ExchangeRateDirect: null,
                        Amount: 1
                    }));
                } else {
                    newRates.push(rate);
                }
            });

            if (isAccountAssignmentCompany(storage.context)) {
                const closingRate = fixedRates.find(r => r.FiscalYear?.Id === fy.Id && r.IsClosingFiscalYear);
                if (!closingRate) {
                    newRates.push(BindingContext.createNewEntity<ICompanyFixedExchangeRateEntity>(newRates.length, {
                        FiscalYear: { ...fy },
                        FiscalPeriod: null,
                        ExchangeRateDirect: null,
                        IsClosingFiscalYear: true,
                        Amount: 1
                    }));
                } else {
                    newRates.push({
                        ...closingRate,
                        FiscalPeriod: null
                    });
                }
            }
        });

        storage.setValueByPath(CurrencyUsedByCompanyEntity.FixedExchangeRates, newRates);
        storage.refresh();
    }

    onAfterLoad = async () => {
        const storage = this.props.storage;

        const activeFYId = storage.getCustomData().selectedFYId ?? getOldestActiveFY(storage.context)?.Id;

        if (storage.formMode !== FormMode.AuditTrail) {
            if (!storage.data.bindingContext.isNew() && storage.getValueByPath(CurrencyUsedByCompanyEntity.ExchangeRateTypeCode) === ExchangeRateTypeCode.Fixed) {
                this.fillFixedRatesForActiveFYs();
            }
        }

        await Promise.all([
            this.refreshCurrencyInfo(),
            this.setCurrentCNBInfo()
        ]);

        storage.setValueByPath(FISCAL_YEAR_PATH, activeFYId);

        storage.refresh();
        return super.onAfterLoad();
    };

    onBeforeSave = (): IEntity => {
        const storage = this.props.storage;
        const entity = cloneDeep(this.entity);

        if (storage.data.bindingContext.isNew()) {
            // TODO: this seems unnecessary, remove once BE doesnt require it
            entity.ToCurrencyCode = getCompanyCurrencyCode(storage.context);
            entity.Company = { Id: storage.context.getCompanyId() };
        }

        entity.FixedExchangeRates = entity.FixedExchangeRates?.filter(rate => rate.ExchangeRateDirect !== null);
        return entity;
    };

    async save(): Promise<IFormStorageSaveResult> {
        const storage = this.props.storage;

        if (storage.data.bindingContext.isNew()) {
            const res = await storage.oData.getEntitySetWrapper(EntitySetName.CurrenciesUsedByCompany).query()
                    .filter(`${CurrencyUsedByCompanyEntity.FromCurrencyCode} eq '${storage.getValueByPath(CurrencyUsedByCompanyEntity.FromCurrencyCode)}'`)
                    .top(0)
                    .count()
                    .fetchData<ICurrencyUsedByCompanyEntity[]>();
            if (res._metadata.count) {
                this.props.storage.setFormAlert({
                    status: Status.Error,
                    title: this.props.storage.t("ExchangeRate:Duplicity"),
                    subTitle: this.props.storage.t("ExchangeRate:DuplicitySubTitle", { code: storage.data.entity.FromCurrencyCode })
                });
                this.props.onSaveFail?.();
                this.forceUpdate(this.scrollPageUp);
                return null;
            }
        }
        return super.save();
    }
}

export default withPermissionContext(CurrencyUsedByCompanyFormView);