import styled from "styled-components/macro";
import { getFocusBorderElement, LayoutStackOrder, T_BTN_main } from "../../global.style";
import { Button, ButtonGroup, IconButton } from "../button";
import { SegmentedButton } from "../button/SegmentedButton";
import FileUploadButton from "../fileUploadButton/FileUploadButton";

export const StyledHotspots = styled.div`
    position: absolute;
    z-index: ${LayoutStackOrder.Hotspots};
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
`;

export const HotspotsFooter = styled.div`
    position: absolute;
    width: fit-content;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
    padding-right: 19px;
    padding-bottom: 19px;
`;

interface IStyledHotspotProps {
    $isActive?: boolean;
    $isEditing?: boolean;
    $top?: number;
    $left?: number;
    $width?: number;
    $height?: number;
    $isBig?: boolean;
}

export const StyledHotspot = styled.div.attrs((props: IStyledHotspotProps) => ({
    style: {
        top: props.$top ? `${props.$top}px` : null,
        left: props.$left ? `${props.$left}px` : null
    }
}))<IStyledHotspotProps>`
    position: absolute;
    width: ${props => props.$isBig ? "28px" : "20px"};
    height: ${props => props.$isBig ? "28px" : "20px"};
    box-shadow: ${props => props.$isEditing ? "none" : `0 0 15px 0 ${props.theme.C_BTN_hover_dark}`};
    background-color: ${props => props.theme.C_BTN_hover_dark};
    border-radius: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;

    ${getFocusBorderElement({ offset: -1, keyboardOnly: true })};
`;

export const HotspotInnerCircle = styled.div<IStyledHotspotProps>`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: ${props => props.$isBig ? "17px" : "9px"};
    height: ${props => props.$isBig ? "17px" : "9px"};
    background-color: ${props => props.$isEditing ? props.theme.C_ACT_thick_line : props.$isActive ? props.theme.C_BG_active_hotspot : props.theme.C_GRD_pink};
    border-radius: 50%;
    
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${props => props.theme.C_BTN_hover_dark};
    ${T_BTN_main};

    ${StyledHotspot}:hover & {
        outline: 1px solid rgba(255, 255, 255, 0.25);
        outline-offset: 10px;
    }
`;

export const PossibleHotspot = styled.div.attrs((props: IStyledHotspotProps) => ({
    style: {
        top: props.$top ? `${props.$top}px` : null,
        left: props.$left ? `${props.$left}px` : null,
        width: props.$width ? `${props.$width}px` : null,
        height: props.$height ? `${props.$height}px` : null
    }
}))<IStyledHotspotProps>`
    position: absolute;
    border: ${props => props.$isActive ? "2px" : "1px"} solid #b1e928;
    cursor: pointer;

    &:hover {
        border-width: 2px;
    }
`;

export const HotspotsFooterButtons = styled(ButtonGroup)`
`;

export const HotspotsFooterButton = styled(Button)`
`;

export const HotspotsFooterIconButton = styled(IconButton)`
`;

export const HotspotsFooterSegmentedButton = styled(SegmentedButton)`
`;

export const HotspotsFooterFileUploadButton = styled(FileUploadButton)`
`;