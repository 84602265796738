import { withConfirmationDialog } from "@components/dialog/withConfirmationDialog";
import { withPermissionContext } from "../../../../contexts/permissionContext/withPermissionContext";
import { DocumentTypeCode } from "@odata/GeneratedEnums";
import { withTimeResolution } from "../../extensions/timeResolution/withTimeResolution";
import { withDomManipulator } from "../../../../contexts/domManipulator/withDomManipulator";
import { withAccruals } from "../../extensions/accruals/withAccruals";
import { IEntity } from "@odata/BindingContext";
import {
    handleClearOriginalDocumentSwitchChange,
    onAfterCorrectiveInvoiceCorrectedDocumentLoad,
    onAfterCorrectiveInvoiceInitialLoad,
    onBeforeCorrectiveFormSave
} from "../CommonCorrectiveSharedUtils";
import ReceivedDocumentBaseFormView from "../../ReceivedDocumentBaseFormView";
import { ISmartFieldChange } from "@components/smart/smartField/SmartField";
import { withPromisedComponent } from "@components/dialog/withPromisedComponent";


class CorrectiveInvoicesReceivedFormView extends ReceivedDocumentBaseFormView {
    documentTypeCode = DocumentTypeCode.CorrectiveInvoiceReceived;

    async onAfterLoad(hasPreloadedData?: boolean): Promise<void> {
        await super.onAfterLoad(hasPreloadedData);
        const { storage } = this.props;

        if (!hasPreloadedData) {
            onAfterCorrectiveInvoiceInitialLoad(storage);
        } else {
            onAfterCorrectiveInvoiceCorrectedDocumentLoad(storage, this.refreshCurrencyExchangeRateAfterDateChange);
        }
        storage.setBusy(false);
    }

    documentSpecificChangeHandlers(e: ISmartFieldChange): void {
        super.documentSpecificChangeHandlers(e);

        handleClearOriginalDocumentSwitchChange(e, this.props.storage);
    }

    onBeforeSave = (): IEntity => {
        return onBeforeCorrectiveFormSave(this.props.storage);
    };
}

export default withPromisedComponent(withConfirmationDialog(withTimeResolution(withAccruals({ isExpense: true })(withPermissionContext(withDomManipulator(CorrectiveInvoicesReceivedFormView))))));