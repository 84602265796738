import styled from "styled-components/macro";
import { T_PLAIN_small_hig, T_TITLE_4_NORMAL } from "../../global.style";

const pagesControlsHeight = 26;

export const CanvasWrapper = styled.div`  
    flex: 1 1 auto;
    position: relative;
    margin-bottom: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const PreviewCanvas = styled.canvas<{ isVisible: boolean }>`
    position: absolute;
    border: 1px solid ${props => props.theme.C_AVATAR_BG_DARK};
    visibility: ${props => props.isVisible ? "visible" : "hidden"};
`;

export const PagesControls = styled.div<{ isVisible: boolean }>`
    height: ${`${pagesControlsHeight}px`};
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: ${props => props.isVisible ? "visible" : "hidden"};
`;

export const PagesCount = styled.div`
    margin: 0 15px;
    white-space: nowrap;
    ${T_TITLE_4_NORMAL};
`;

export const SeparatedInputs = styled.div`
    display: flex;
    align-items: center;
`;

export const Delimiter = styled.span`
    margin: 0 5px;
    ${T_TITLE_4_NORMAL};
`;

export const StyledPrintDialogVariantSelect = styled.div`
    display: flex;
    align-items: center;
    height: 38px;
`;

export const VariantLabel = styled.span`
  ${T_PLAIN_small_hig};
  color: ${props => props.theme.C_ACT_main};
`;