import { PrWorkingPatternTypeCode } from "@odata/GeneratedEnums";
import { repeatArray } from "@utils/general";
import { Dayjs } from "dayjs";
import { range } from "lodash";
import React, { Component, ReactElement } from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import { getUtcDate, getUtcDayjs } from "../../types/Date";
import memoizeOne from "../../utils/memoizeOne";
import {
    formatDateToDateString,
    getDaysInterval,
    getMonthDays,
    getMonthFirstDayWeekOffset,
    getNextMonday,
    getWeekdays,
    isSameDay
} from "../inputs/date/utils";
import DayInput from "../inputs/dayInput/DayInput";
import { IInputOnChangeEvent } from "../inputs/input";
import {
    CalendarGrid,
    DayGridPlaceholder,
    DayLabel,
    MonthYearSelectorStyled,
    Wrapper
} from "./CalendarWithInputs.styles";

export interface IProps extends WithTranslation {
    startDate?: Dayjs;
    onChange: (val: number, day: Dayjs) => void;
    onSelect?: (day: Dayjs) => void;
    onMonthChange?: (day: Dayjs) => void;
    type: PrWorkingPatternTypeCode;
    rotationLength?: number;
    isDifferentOddAndEvenWeek?: boolean;
    daysValuesMap?: Record<string, number>;
    daysWithError?: string[];
}

interface IState {
    startDate: Dayjs;
    selectedDay: string;
}

class CalendarWithInputs extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            startDate: props.startDate ?? getUtcDayjs(),
            selectedDay: null
        };
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>) {
        if (!isSameDay(this.props.startDate, prevProps.startDate)) {
            this.setCorrectStartDate();
        }
    }

    get isRotation(): boolean {
        return this.props.type === PrWorkingPatternTypeCode.Rotation;
    }

    get isMonthly(): boolean {
        return this.props.type === PrWorkingPatternTypeCode.Monthly;
    }

    setCorrectStartDate = (): void => {
        const startDate = this.props.startDate ?? this.state.startDate ?? getUtcDayjs();
        this.setState({ startDate });
    };

    changeMonth = (startDate: Dayjs): void => {
        this.setState({ startDate, selectedDay: null });
        this.props.onSelect?.(null);
        this.props.onMonthChange?.(startDate);
    };

    handleMonthChange = (e: IInputOnChangeEvent<Date>): void => {
        this.changeMonth(getUtcDayjs(e.value));
    };

    renderMonthYearPicker = (): ReactElement => {
        if (!this.isMonthly) {
            return null;
        }

        const value = this.state.startDate?.toDate() ?? getUtcDate();

        return (
            <MonthYearSelectorStyled value={value} onChange={this.handleMonthChange}/>
        );
    };

    handleChange = (val: number, date: Dayjs) => {
        if (val <= 48) {
            this.props.onChange(val, date);
        }
    };

    handleDaySelect = (date: Dayjs) => {
        this.setState({
            selectedDay: formatDateToDateString(date)
        });
        this.props.onSelect(date);
    };

    get intervalLength(): number {
        return this.isRotation ? this.props.rotationLength : this.props.isDifferentOddAndEvenWeek ? 14 : 7;
    }

    getDays = memoizeOne(() => {
        if (this.isMonthly) {
            return getMonthDays(this.state.startDate);
        }
        return getDaysInterval(this.isRotation ? this.state.startDate : getNextMonday(this.state.startDate, this.props.isDifferentOddAndEvenWeek), this.intervalLength);
    }, () => [formatDateToDateString(this.state.startDate), this.props.type, this.props.isDifferentOddAndEvenWeek, this.intervalLength]);


    render() {
        const weekdays = getWeekdays();
        const weekdayOffset = getMonthFirstDayWeekOffset(this.state.startDate);
        const length = this.intervalLength;
        const weeks = this.isRotation && length ? Math.ceil(length / 7) + 1 : 1;
        let weekdayLabels = repeatArray<string>(weekdays, weeks);
        const days = this.getDays();

        if (this.isRotation) {
            if (!length || !this.state.startDate) {
                weekdayLabels = [];
            } else {
                const offset = (this.state.startDate.day() + 6) % 7;
                weekdayLabels = weekdayLabels.slice(offset, Math.min(length, 7) + offset);
            }
        }

        return <Wrapper>
            {this.renderMonthYearPicker()}
            <CalendarGrid rowLength={this.isRotation ? Math.min(length, 7) : 7}>
                {weekdayLabels.map((day, index) => <DayLabel key={day + index}>{day}</DayLabel>)}
                {this.isMonthly && range(weekdayOffset).map((index) => <DayGridPlaceholder key={index}/>)}
                {days.map((day) => {
                    const id = formatDateToDateString(day);
                    return <DayInput
                        key={id}
                        hasError={this.props.daysWithError?.includes(id)}
                        value={this.props.daysValuesMap[id]}
                        onChange={this.handleChange}
                        onDaySelect={this.handleDaySelect}
                        isSelected={id === this.state.selectedDay}
                        date={day}
                    />;
                })}
            </CalendarGrid>
        </Wrapper>;
    }
}

export default withTranslation(["Components"])(CalendarWithInputs);