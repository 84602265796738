import React from "react";
import { DarkIcon, getIcon } from "../icon";
import { IconSize } from "../../enums";
import Tooltip from "../tooltip";
import { DarkIconStyled } from "./ConfigurationList.styles";

interface IProps {
    iconName: string;
    tooltipRows?: string[];
}

const CustomizationIcon: React.FC<IProps> = ({ tooltipRows, iconName }) => {
    const Icon = getIcon(iconName);
    const content = (
            <Icon width={IconSize.S} height={IconSize.S} preventHover/>
    );

    if (tooltipRows?.length) {
        return (
                <Tooltip onlyShowWhenChildrenOverflowing={false}
                         content={<>{tooltipRows.map((item, idx) => (
                                 <React.Fragment key={idx}>{item}<br/></React.Fragment>))}</>}>
                    {(ref) => (
                            // eslint-disable-next-line react/jsx-no-undef
                            <DarkIconStyled hasTooltip passRef={ref}>
                                {content}
                            </DarkIconStyled>
                    )}
                </Tooltip>
        );
    }
    return (
            <DarkIcon>{content}</DarkIcon>
    );
};

export default CustomizationIcon;