import styled, { css } from "styled-components/macro";
import { T_HEADER_small } from "../../global.style";

export const ActionLabel = styled.span`
  position: relative;
  top: 1px;
  color: ${props => props.theme.C_TEXT_dialogue};
  white-space: nowrap;
  overflow: hidden;

  ${T_HEADER_small};
`;

export const StyledFormGroupActions = styled.div<{ isTransparent: boolean }>`
  display: flex;
  background: ${props => props.isTransparent ? "transparent" : props.theme.C_BG_popover};
  border-radius: ${props => props.theme.borderRadius};
  padding: 8px 24px;
`;

export const StyledFormGroupAction = styled.div`
  width: 38px;
  height: 38px;
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface IAction {
    maxWidth: number;
    isSelected: boolean;
    isDisabled: boolean;
    isMouseOver: boolean;
}

export const StyledFormGroupActionWrapper = styled.div<IAction>`
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all;
  transition-duration: 0.1s;
  max-width: ${props => props.isSelected ? "auto" : "38px"};

  &:not(:last-child) {
    margin-right: ${props => props.isSelected ? "12px" : "2px"};
  }

  &:not(:first-child) {
    padding-left: ${props => props.isSelected ? "10px" : "0"};
  }


  ${props => props.isSelected && css<IAction>`
    max-width: ${props => `${props.maxWidth}px`};
  `};

  ${props => !props.isSelected && !props.isDisabled && props.isMouseOver && css<IAction>`
    transition-duration: 0.3s;
    transition-delay: 500ms;
    max-width: ${props => `${props.maxWidth}px`};
  `}
`;