import React, { lazy } from "react";
import FormRoute from "../routes/FormRoute";
import {
    ROUTE_DELETE_LOG, ROUTE_MESSAGES
} from "../routes";
import { EntityTypeName } from "@odata/GeneratedEntityTypes";
import { CompanyPermissionCode } from "@odata/GeneratedEnums";

const Messages = lazy(() => import(/* webpackChunkName: "audit" */"@pages/messages/Messages"));
const DeleteLog = lazy(() => import(/* webpackChunkName: "audit" */"@pages/deletedItems/DeleteLog"));

export default function auditBundle() {
    return [
        <FormRoute path={ROUTE_MESSAGES}
                   key={ROUTE_MESSAGES}
                   permission={CompanyPermissionCode.Audit}
                   entityType={EntityTypeName.CommunicationEntry}
                   page={Messages}/>,
        <FormRoute
                path={ROUTE_DELETE_LOG}
                key={ROUTE_DELETE_LOG}
                permission={CompanyPermissionCode.Audit}
                entityType={EntityTypeName.DocumentDeleteLog}
                page={DeleteLog}/>
    ];
}