import styled from "styled-components/macro";
import { transparentize } from "polished";
import { CheckIcon } from "../../icon";
import { getDisabledStyle } from "../../../global.style";
import { AuditTrailFieldType } from "../../smart/FieldInfo";
import { DefaultTheme } from "styled-components";

interface ISelected {
    focused?: boolean;
    selected?: boolean;
}

export const StyledMenu = styled.div`{
    max-width: 208px;
    padding: 5px;
    line-height: 0;
    position: relative;
    z-index: 1;
}`;

export const Circle = styled.div<ISelected>`
    width: 23px;
    height: 23px;
    margin: 5px;
    border-radius: 23px;
    display: inline-block;
    background: ${props => props.theme[props.color as keyof DefaultTheme] || props.color};
    position: relative;
   
    &:before {
        content: "";
        width: 27px;
        height: 27px;  
        border-radius: 27px;
        position: absolute;
        top:-2px;
        left: -2px;
        z-index: -1;
        display: ${props => props.selected || props.focused ? "block" : "none"};
        background: ${props => props.selected ? props.theme.C_BG_selected_color 
            : transparentize(0.5, props.theme.C_ACT_hover_without_opacity)};
    }
       
    &:hover {
        cursor: pointer;
        &:before {
            display: block;
        }
    }   
    
    ${CheckIcon} {
        position: relative;
        left: 1px;
        opacity: 0.75;
    }
`;

export const StyledSelect = styled.div<{
    isDisabled: boolean;
    auditTrailType?: AuditTrailFieldType;
}>` 
    position: relative;
    width: 68px;
    height: 34px;
    border-radius: ${props => props.theme.borderLargeRadius};
    border: solid 1px ${props => props.theme.C_FIELD_line};
    background: ${props => props.theme.C_FIELD_fill};
    
    &:hover {
        cursor: pointer;
    } 
    
    &:hover:after,
    &:focus-within:after {
      content: '';
      position: absolute;
      width: 62px;
      height: 28px;
      border: 1px solid ${props => props.theme.C_ACT_main} ;
      left: 2px;
      top: 2px;
      border-radius: ${props => props.theme.borderLargeRadius};
      pointer-events: none;
    }
    
    ${props => (props.auditTrailType === AuditTrailFieldType.NoDifference || props.auditTrailType === AuditTrailFieldType.HoveredNoDifference) && {
        background: props.theme.C_AUDIT_TRAIL_NO_DIFF
    }}
            
    ${props => (props.auditTrailType === AuditTrailFieldType.HoveredNoDifference) && {
        opacity: props.theme.C_AUDIT_TRAIL_DIFF_VISIBLE_OPACITY
    }}
  
    border: ${props => (props.auditTrailType === AuditTrailFieldType.NoDifference || props.auditTrailType === AuditTrailFieldType.HoveredNoDifference) ?
    `1px solid ${props.theme.C_AUDIT_TRAIL_NO_DIFF}` : `1px solid ${props.theme.C_FIELD_line}`};    
    
    ${props => (props.auditTrailType === AuditTrailFieldType.Difference || props.auditTrailType === AuditTrailFieldType.HoveredDifference) && {
    border: `3px solid ${props.theme.C_AUDIT_TRAIL_DIFF}`}}
        
    ${props => props.auditTrailType === AuditTrailFieldType.NoDifference && `
        & svg {
            display: none !important
        }
    `}
    
     ${props => props.auditTrailType === AuditTrailFieldType.HoveredNoDifference && `
        & svg {
            opacity: ${props.theme.C_AUDIT_TRAIL_DIFF_VISIBLE_OPACITY}
        }
    `}    
        
    & > input {
        height: 0;
        border: none;
        padding: 0;
        margin: 0;
    }
    
    & > ${Circle} {
        position: absolute;
        width: 22px;
        height: 22px;
        top: ${props => (props.auditTrailType === AuditTrailFieldType.Difference || props.auditTrailType === AuditTrailFieldType.HoveredDifference) ? "3px" : "5px"};
        left: ${props => (props.auditTrailType === AuditTrailFieldType.Difference || props.auditTrailType === AuditTrailFieldType.HoveredDifference) ? "4px" : "6px"};
        margin: 0;
        opacity: ${props => (props.auditTrailType === AuditTrailFieldType.NoDifference ? 0 : 1)}
    }
    
    
    ${props => getDisabledStyle(props.isDisabled)};
`;