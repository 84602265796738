import React, { Component } from "react";
import { Menu, MenuContent, Navigation, NavList, NavListGroupTitle } from "./NavMenu.styles";
import { WithTranslation, withTranslation } from "react-i18next";
import { AppContext, ContextEvents, IAppContext } from "../../contexts/appContext/AppContext.types";
import TestIds from "../../testIds";
import { CompanySelector } from "./CompanySelector";
import LocalSettings, { ISplitPageSettings } from "../../utils/LocalSettings";
import { HOTSPOT_ID_ATTR } from "../hotspots/Hotspots.utils";
import Resizer from "../resizer/Resizer";
import { composeRefHandlers, getValue, isNotDefined } from "@utils/general";
import { addCompanyIdToUrl } from "../../contexts/appContext/AppContext.utils";
import { TInfoValue } from "../smart/FieldInfo";
import FocusManager, { FocusDirection } from "../focusManager";

export interface IMenuSelected {
    group: IMenuGroup;
    item: IMenuItem;
}

export interface IMenuItem {
    key: string;
    url: string;
    title: string | (() => string);
    // path to the icon svg
    iconPath?: string;
    /** Number shown above the icon */
    count?: number;

    isDisabled?: boolean;
    tooltip?: string;
}

export interface IMenuGroup {
    key: string;
    title: string;
    url?: string;
    items: IMenuItem[];
    isVisible?: TInfoValue<boolean>;
}

interface INavMenuProps extends WithTranslation {
    views: IMenuGroup[];
    onMenuClick: (key: string, width: number) => void;
    onCompanySelectorClick: () => void;
}

const DEFAULT_MENU_WIDTH = 250;
const COLLAPSE_TRESHOLD = DEFAULT_MENU_WIDTH - 38;

class NavMenu extends Component<INavMenuProps> {
    static contextType = AppContext;
    //sadly, breaks typescript type checking
    //context: React.ContextType<typeof AppContext>;
    static defaultProps: Partial<INavMenuProps> = {
        views: []
    };

    refContent = React.createRef<HTMLDivElement>();

    settings: ISplitPageSettings;
    ignoreNextSelectedMenuChange = false;

    constructor(props: INavMenuProps) {
        super(props);

        this.settings = LocalSettings.get("App")?.splitPageSettings || {
            width: DEFAULT_MENU_WIDTH
        };
    }

    componentDidMount = () => {
        this.context.eventEmitter.on(ContextEvents.SelectedMenuChanged, this.handleSelectedMenuChange);
    };

    componentWillUnmount() {
        this.context.eventEmitter.off(ContextEvents.SelectedMenuChanged, this.handleSelectedMenuChange);
    }

    handleResize = (width: number, isCollapsed: boolean) => {
        const splitPageSettings = {
            width: Math.max(DEFAULT_MENU_WIDTH, width),
            isCollapsed
        };

        LocalSettings.set("App", { splitPageSettings });
    };

    handleSelectedMenuChange = () => {
        if (!this.ignoreNextSelectedMenuChange) {
            this.forceUpdate();
        }

        this.ignoreNextSelectedMenuChange = false;
    };

    isSelected = (key: string) => {
        return this.context.getSelectedMenu()?.group?.key === key;
    };

    handleMenuClick = (e: React.MouseEvent, groupKey: string) => {
        if (e.ctrlKey) {
            return;
        }

        e.preventDefault();

        // don't rerender second time, when the change is caused from here
        this.ignoreNextSelectedMenuChange = true;
        this.props.onMenuClick(groupKey, 0);
    };

    render() {
        const context = this.context as IAppContext;

        return (
            <Resizer initialWidth={this.settings.width}
                     minWidth={DEFAULT_MENU_WIDTH}
                     collapseThreshold={COLLAPSE_TRESHOLD}
                     initialCollapsed={this.settings.isCollapsed}
                     onResize={this.handleResize}>
                {(resizerProps) => (
                    <Navigation onMouseOver={resizerProps.onMouseOver}
                                onMouseLeave={resizerProps.onMouseLeave}
                                data-testid="navigation-pane">
                        <Menu ref={composeRefHandlers(resizerProps.resizeRef, this.refContent)}
                              _width={resizerProps.width}
                              isFloating={resizerProps.isCollapsed}
                              data-testid={TestIds.Menu}>
                            <MenuContent>
                                <CompanySelector onClick={this.props.onCompanySelectorClick}
                                                 isDisabled={context.hasLimitedAccess()}/>
                                <FocusManager direction={FocusDirection.Vertical}>
                                    {({ itemProps, wrapperProps }) => (
                                        <NavList {...wrapperProps}>
                                            {this.props.views
                                                .filter(group => isNotDefined(group.isVisible) ? true : getValue(group.isVisible, { context: this.context }))
                                                .map((group) => {
                                                    return <NavListGroupTitle {...itemProps}
                                                                              selected={this.isSelected(group.key)}
                                                                              href={addCompanyIdToUrl(group.url, context)}
                                                                              {...{ [HOTSPOT_ID_ATTR]: group.key }}
                                                                              onClick={(e) => {
                                                                                  this.handleMenuClick(e, group.key);
                                                                              }}
                                                                              key={group.key}
                                                                              data-testid={TestIds.MenuItem}> {this.props.t(group.title).toUpperCase()}
                                                    </NavListGroupTitle>;
                                                })}
                                        </NavList>)}
                                </FocusManager>
                            </MenuContent>
                            {resizerProps.resizeHandle}
                        </Menu>
                    </Navigation>
                )}
            </Resizer>
        );
    }
}

const NavMenuWithTranslation = withTranslation("Common")(NavMenu);
export { NavMenuWithTranslation as NavMenu };