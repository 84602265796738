import React, { lazy } from "react";
import FormRoute from "../routes/FormRoute";
import { ROUTE_FIXED_ASSET, ROUTE_MINOR_ASSET, ROUTE_UNORGANIZED_ASSET } from "../routes";
import { EntityTypeName } from "@odata/GeneratedEntityTypes";
import { CompanyPermissionCode } from "@odata/GeneratedEnums";

const MinorAsset = lazy(() => import(/* webpackChunkName: "assets" */"../pages/asset/minorAsset/MinorAsset"));
const FixedAsset = lazy(() => import(/* webpackChunkName: "assets" */"../pages/asset/fixedAsset/FixedAsset"));
const UnorganizedAsset = lazy(() => import(/* webpackChunkName: "assets" */"../pages/asset/unorganizedAsset/UnorganizedAsset"));

export default function assetBundle() {
    return [
        <FormRoute
                path={ROUTE_MINOR_ASSET}
                key={ROUTE_MINOR_ASSET}
                entityType={EntityTypeName.MinorAsset}
                permission={CompanyPermissionCode.Asset}
                page={MinorAsset}/>,

        <FormRoute
                path={ROUTE_FIXED_ASSET}
                key={ROUTE_FIXED_ASSET}
                entityType={EntityTypeName.Asset}
                permission={CompanyPermissionCode.Asset}
                page={FixedAsset}/>,
        <FormRoute
                path={ROUTE_UNORGANIZED_ASSET}
                key={ROUTE_UNORGANIZED_ASSET}
                entityType={EntityTypeName.UnorganizedAsset}
                permission={CompanyPermissionCode.Asset}
                page={UnorganizedAsset}/>
    ];
}