import { WithOData, withOData } from "@odata/withOData";

import { TableButtonsAction, TableButtonsActionType } from "../../views/table/TableToolbar.utils";
import TableView, { ITableViewBaseProps } from "../../views/table/TableView";

class BackgroundJobsHistory extends TableView<ITableViewBaseProps & WithOData> {
    getToolbarButtons(): TableButtonsActionType[] {
        return [
            TableButtonsAction.Sorting,
            TableButtonsAction.Settings
        ];
    }
}

export default withOData(BackgroundJobsHistory);
