import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { DateLabelButton, StyledRelativeDateValues } from "./Calendar.styles";
import { PredefinedFilter } from "../../../conditionalFilterDialog/ConditionalFilterDialog.utils";
import { capitalize } from "@utils/string";
import FocusManager, { FocusDirection, IFocusableItemProps } from "../../../focusManager";
import memoize from "../../../../utils/memoize";

const PossibleValue = [
    PredefinedFilter.ThisWeek,
    PredefinedFilter.LastWeek,
    PredefinedFilter.ThisMonth,
    PredefinedFilter.LastMonth,
    PredefinedFilter.ThisYear,
    PredefinedFilter.LastYear
];

interface IProps extends WithTranslation {
    value: PredefinedFilter;
    onChange: (value: PredefinedFilter) => void;
    embedded?: boolean;
}

class RelativeDateValues extends React.PureComponent<IProps> {
    getOnClick = memoize((value: PredefinedFilter) => {
        return () => {
            this.props.onChange(value);
        };
    }, (value) => value.toString());

    renderValue = (value: PredefinedFilter, itemProps: IFocusableItemProps): React.ReactElement => {
        return (
            <DateLabelButton {...itemProps}
                             key={value}
                             $isSelected={this.props.value === value}
                             onClick={this.getOnClick(value)}>
                {capitalize(this.props.t(`Components:ValueHelper.${value}`))}
            </DateLabelButton>
        );
    };

    render() {
        return (
            <FocusManager direction={FocusDirection.Vertical}>
                {({ itemProps, wrapperProps }) => (
                    <StyledRelativeDateValues {...wrapperProps}
                                              ref={wrapperProps.ref as React.RefObject<HTMLDivElement>}
                                              embedded={this.props.embedded}>
                        {PossibleValue.map(val => this.renderValue(val, itemProps))}
                    </StyledRelativeDateValues>
                )}
            </FocusManager>
        );
    }
}


export default withTranslation(["Common"])(RelativeDateValues);