import { API_URL } from "../../constants";

export const isExternalLink = (link: string, location = window.location, includeApi?: boolean): boolean => {
    const { host, protocol } = location;
    try {
        const url = new URL(link, `${protocol}//${host}`);
        return url.host !== host || (url.pathname.startsWith(API_URL) && !includeApi);
    } catch (e) {
        return true;
    }
};