import React, { useRef, useState } from "react";
import ProgressBanner, { IProgressBannerProps } from "./ProgressBanner";
import { usePopper } from "react-popper";
import { TooltipArrow } from "../tooltipIcon/TooltipIcon.styles";
import BackgroundJobsPopup, { IBackgroundJobsProps } from "./BackgroundJobsPopup";
import { BannerPopperWrapper } from "./ProgressBanner.styles";
import { useTheme } from "styled-components";
import { composeRefHandlers } from "@utils/general";

interface IProps {
    bannerProps: IProgressBannerProps;
    popupProps: IBackgroundJobsProps;
    isOpen?: boolean;
    onClose?: () => void;
    className?: string;
}

const ProgressBannerWithBackgroundJobsPopup = React.memo<IProps>((props) => {
    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const [arrowElement, setArrowElement] = useState(null);
    const usedPopper = usePopper(referenceElement, popperElement, {
        modifiers: [
            { name: "arrow", options: { element: arrowElement } },
            { name: "offset", options: { offset: [0, 8] } }
        ]
    });

    const theme = useTheme();
    const popupRef = useRef(null);
    // https://solitea-cz.atlassian.net/browse/DEV-23574
    // popup is not supposed to autoclose, at least for now
    // const _ = useHideOnBlur({
    //     refs: [popupRef, arrowElement, referenceElement],
    //     isVisible: props.isOpen,
    //     onHide: props.onClose
    // });

    return (
        <>
            <ProgressBanner {...props.bannerProps}
                            isActive={props.isOpen}
                            passRef={setReferenceElement}
                            className={props.className}/>
            {props.isOpen &&
                <BannerPopperWrapper ref={setPopperElement}
                                     style={usedPopper.styles.popper} {...usedPopper.attributes.popper}>
                    <BackgroundJobsPopup {...props.popupProps}
                                         passRef={composeRefHandlers(popupRef, props.popupProps?.passRef)}/>
                    <TooltipArrow ref={setArrowElement}
                        // for HideOnBlur to work
                                  tabIndex={-1}
                                  color={theme.C_BG_menu}
                                  style={usedPopper.styles.arrow}
                                  {...usedPopper.attributes.arrow}/>
                </BannerPopperWrapper>
            }
        </>
    );
});

export default ProgressBannerWithBackgroundJobsPopup;