import React from "react";
import SimpleTable, { ISimpleTableProps } from "./SimpleTable";
import { ILoadMoreItemsEvent, IRow } from "../table";
import { StyledSimpleTableWithPaginator } from "./SimpleTableWithPaginator.styles";
import Paginator from "../paginator/Paginator";
import { isNotDefined } from "@utils/general";
import { ScrollBar } from "../scrollBar";

interface IProps extends ISimpleTableProps {
    pageSize: number;
    currentPage: number;
    rowCount?: number;
    onPageChange?: (page: number) => void;
    onLoadMoreItems?: (props: ILoadMoreItemsEvent) => void;
    passRef?: React.Ref<HTMLDivElement>;
    renderScrollbar?: boolean;
}

export default class SimpleTableWithPaginator extends React.PureComponent<IProps> {
    static defaultProps = {
        renderScrollbar: true
    };

    componentDidMount() {
        this.checkRows();
    }

    componentDidUpdate() {
        this.checkRows();
    }

    checkRows = (): void => {
        if (this.props.busy) {
            return;
        }

        const { from, to } = this.getCurrentPageRange();

        for (let i = from; i < to; i++) {
            if (this.props.rows[i] === undefined) {
                // load rows up the end of this page
                this.props.onLoadMoreItems?.({
                    startIndex: i,
                    stopIndex: to
                });

                break;
            }
        }
    };

    getRowCount = (): number => {
        return this.props.rowCount ?? this.props.rows.length;
    };

    getPageCount = (): number => {
        return Math.ceil(this.getRowCount() / this.props.pageSize);
    };

    getCurrentPageRange = (): { from: number, to: number } => {
        const pageStart = this.props.currentPage * this.props.pageSize;
        const pageEnd = Math.min(pageStart + this.props.pageSize, this.getRowCount());

        return { from: pageStart, to: pageEnd };
    };

    getRows = (): IRow[] => {
        if (this.props.busy && isNotDefined(this.props.rowCount)) {
            // empty rows to keep table height while loading
            return new Array(this.props.pageSize).fill(null);
        }

        const rows: IRow[] = [];
        const { from, to } = this.getCurrentPageRange();

        for (let i = from; i < to; i++) {
            rows.push(this.props.rows[i]);
        }

        return rows;
    };

    render() {
        const { rows, ...passProps } = this.props;
        const rowCount = this.props.rowCount ?? this.props.rows.length;

        if (this.props.currentPage !== 0) {
            delete passProps.onSplitLineMoveEnd;
            delete passProps.splitLinePosition;
        }

        const table = (
                <SimpleTable {...passProps}
                             rows={this.getRows()}/>
        );

        return (
                <StyledSimpleTableWithPaginator>
                    {this.props.renderScrollbar ? (
                            <ScrollBar
                        style={{
                            overflowX: "visible",
                            width: "100%",
                            height: "auto"
                        }}
                    >
                        {table}
                    </ScrollBar>
                ) : table}
                {rowCount > this.props.pageSize &&
                    <Paginator page={this.props.currentPage}
                               pagesCount={this.getPageCount()}
                               onPageChange={this.props.onPageChange}/>
                }
            </StyledSimpleTableWithPaginator>
        );
    }
}