import styled from "styled-components/macro";
import { AuditTrailInputCss, StyledInput } from "../input/Input.styles";
import { T_PLAIN_small, T_PLAIN_small_hig } from "../../../global.style";
import { DefaultTheme } from "styled-components";

export const StyledTokenInput = styled.span<{
    color: string;
    value: string | number;
}>`{
    display: inline-grid;
    position: relative;
    left: 11px;
    color: ${props => props.theme.C_TEXT_primary};
    padding: 3px;
    height: 22px;
    min-width: 1em;
    max-width: calc(100% - 57px);
    white-space: nowrap;
    border: 1px solid transparent;
    border-radius: 3px;
    background-color: ${props => props.value ? props.theme[props.color as keyof DefaultTheme] || props.color : "transparent"};    
    
    & > input {
        width: 100%;
        position: absolute;
        left:0; right:0;
        border: none;
        background: transparent;
        outline: none;
        grid-area: 1 / 1;
        padding: 0;
        ${T_PLAIN_small_hig};
        text-transform: uppercase;

        &:focus-within {
            font-weight: normal;
        }
    }
    &:after {
        display: inline-block;
        content: attr(data-value) ' ';
        visibility: hidden;
        grid-area: 1 / 1;
        text-transform: uppercase;
        white-space: nowrap;
        ${T_PLAIN_small};
    }
}`;

export const StyledTokenInputWrapper = styled(StyledInput)`
    ${StyledTokenInput} {
        ${AuditTrailInputCss};
        padding: 4px;
    } 
`;