import React, { Component } from "react";
import { DomManipulatorOrchestrator } from "./DomManipulatorOrchestrator";
import memoizeOne from "../../utils/memoizeOne";

const DomManipulatorContext = React.createContext<DomManipulatorOrchestrator>(undefined);

class DomManipulatorProvider extends Component {

    getContext = memoizeOne((): DomManipulatorOrchestrator => {
        return new DomManipulatorOrchestrator();
    });

    render() {
        return (
            <DomManipulatorContext.Provider value={this.getContext()}>
                {this.props.children}
            </DomManipulatorContext.Provider>
        );
    }
}

export { DomManipulatorContext, DomManipulatorProvider };