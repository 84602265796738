import React from "react";
import { DragTransferId } from "./ImportSettings";
import { StyledDragItem } from "./ImportSettings.styles";
import TestIds from "../../testIds";

interface IProps {
    id: string;
    value: string;
    onClick: (index: string) => void;
    isSelected: boolean;
}

class ImportSettingsDragItem extends React.PureComponent<IProps> {
    handleDragStart = (e: React.DragEvent<HTMLDivElement>): void => {
        e.dataTransfer.setData(DragTransferId, this.props.id);
        e.stopPropagation();
    };

    handleDragEnd = (e: React.DragEvent<HTMLDivElement>): void => {
        e.stopPropagation();
    };

    handleDrag = (e: React.DragEvent<HTMLDivElement>): void => {
        e.stopPropagation();
    };

    handleMouseDown = (): void => {
        this.props.onClick(this.props.id);
    };

    render() {
        return (
            <StyledDragItem
                onDragStart={this.handleDragStart}
                onDragEnd={this.handleDragEnd}
                onDrag={this.handleDrag}
                draggable={true}
                selected={this.props.isSelected}
                onMouseDown={this.handleMouseDown}
                key={this.props.id}
                data-testid={TestIds.ImportSettingsDragItem}>
                {this.props.value}
            </StyledDragItem>
        );
    }
}

export default ImportSettingsDragItem;