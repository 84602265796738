import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";

import { IconSize } from "../../../enums";
import { IconButton } from "../../button";
import { CheckIcon, CloseIcon } from "../../icon";

export interface IFieldConfirmButtons {
    onCancel: () => void;
    onConfirm: () => void;
    withoutConfirm?: boolean;
    iconSize?: string;
    isLight?: boolean;
    onBlur?: (e: React.FocusEvent) => void;
    passRef?: React.Ref<HTMLDivElement>;
    style?: React.CSSProperties;
    className?: string;
}

const StyledFieldConfirmButtons = styled.div`
  display: flex;
  z-index: 1;
`;

export const FieldConfirmButtons = React.memo(
    ({ onCancel, onConfirm, withoutConfirm, onBlur, passRef, className, style, iconSize, isLight }: React.PropsWithChildren<IFieldConfirmButtons>) => {
        const { t } = useTranslation(["Common"]);
        const iconSizeValue: string = iconSize ?? IconSize.S;

        return (
            <StyledFieldConfirmButtons ref={passRef} className={className} style={style}>
                <IconButton title={t("Common:General.Cancel")} onClick={onCancel} onBlur={onBlur}
                            isLight={isLight}
                            isDecorative>
                    <CloseIcon width={iconSizeValue} height={iconSizeValue}/>
                </IconButton>
                {!withoutConfirm &&
                    <IconButton title={t("Common:General.Save")} onClick={onConfirm} onBlur={onBlur}
                                isLight={isLight}
                                isDecorative>
                        <CheckIcon width={iconSizeValue} height={iconSizeValue}/>
                    </IconButton>
                }
            </StyledFieldConfirmButtons>
        );
});