import styled, { css } from "styled-components/macro";
import { transparentize } from "polished";
import { getDisabledStyle, getFocusBorderElement, T_PLAIN_big } from "../../../../global.style";
import { IDaySettingsProps } from "./Day";
import { themes } from "../../../../theme";

export const DayLabel = styled.div`
    position: relative;
    // the label seems somehow not in center, even with line-heigth 1
    top: 1px;
    pointer-events: none;
`;

const getBackgroundColor = (isInActiveRange: boolean, isInSelectedRange: boolean): string => {
    if (isInActiveRange) {
        return themes.light.C_ACT_hover;
    }

    if (isInSelectedRange) {
        return transparentize(0.6, themes.light.C_ACT_main);
    }

    return "transparent";
};

export const StyledDay = styled.div<IDaySettingsProps>`
    width: 36px;
    height: 34px;

    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${props => getBackgroundColor(props.isInActiveRange, props.isInSelectedRange)};

    ${props => props.isFirst && !props.isLast && (props.isInActiveRange || props.isInSelectedRange) && css<IDaySettingsProps>`
        ${props => props.isSelected && css`
            background: ${`linear-gradient(90deg, transparent 52%, ${getBackgroundColor(props.isInActiveRange, props.isInSelectedRange)} 52%)`};
        `};
        ${props => !props.isSelected && css`
            border-top-left-radius: 50%;
            border-bottom-left-radius: 50%;
        `};
    `}
    ${props => props.isLast && !props.isFirst && (props.isInActiveRange || props.isInSelectedRange) && css<IDaySettingsProps>`
        ${props => props.isSelected && css`
            background: ${`linear-gradient(90deg, ${getBackgroundColor(props.isInActiveRange, props.isInSelectedRange)} 52%, transparent 52%)`};
        `};
        ${props => !props.isSelected && css`
            border-top-right-radius: 50%;
            border-bottom-right-radius: 50%;
        `};
    `}
    &:last-child {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
    }

    &:first-child {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
    }

    ${props => getDisabledStyle(props.isDisabled)}
`;

export const DayInner = styled.div<IDaySettingsProps>`
    width: 33px;
    height: 33px;
    border-radius: 50%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: ${props => props.isOutsideMonth ? transparentize(0.5, themes.light.C_TEXT_dialogue) : themes.light.C_TEXT_dialogue};
    background-color: ${props => props.isSelected ? themes.light.C_BTN_4L_emph_bg_hover : "transparent"};
    font-weight: ${props => props.isSelected ? "bold" : "normal"};
    ${T_PLAIN_big};

    &:hover, &:focus {
        ${props => !props.preventHover && css<IDaySettingsProps>`
            background-color: ${props => props.isSelected ? themes.light.C_BTN_4L_emph_bg_hover : transparentize(0.7, themes.light.C_BTN_hover_dark)};
        `};
        outline: none;
    }

    ${props => props.isSelected && getFocusBorderElement({ offset: -1, keyboardOnly: true, isLight: true })};

    &:active {
        background-color: ${themes.light.C_BTN_4L_emph_bg_hover};
    }

    ${props => props.isCurrent && css`
        font-weight: bold;

        & ${DayLabel}::after {
            position: absolute;
            content: "";
            width: 100%;
            height: 2px;
            background: ${themes.light.C_ACT_hover_without_opacity};
            bottom: 0;
            left: 0;
            opacity: 50%;
        }
    `}
`;
