import { useEffect, useState } from 'react';

type UseCounterReturnType = [count: number, resetCounter: (newValue?: number) => void];

function useCounter(initialValue: number): UseCounterReturnType {
    const [count, setCount] = useState(initialValue);

    useEffect(() => {
        let interval: ReturnType<typeof setInterval>;

        if (count > 0) {
            interval = setInterval(() => {
                setCount((prevCount) => prevCount - 1);
            }, 1000);
        }

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [count]);

    const resetCounter = (newValue?: number) => {
        setCount(typeof newValue === 'number' && newValue >= 0 ? newValue : initialValue);
    };

    return [count, resetCounter];
}

export default useCounter;