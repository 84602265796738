import React from "react";
import WriteLine from "../inputs/writeLine";
import { NewHotspotTextArea, HotspotTooltipLine, StyledNewHotspotTooltipContent } from "./HotspotTooltipContent.styles";
import Checkbox, { ICheckboxChange } from "../inputs/checkbox";
import { Button, ButtonGroup } from "../button";
import { BasicInputSizes, TextAlign } from "../../enums";
import { IInputOnChangeEvent } from "../inputs/input";
import { WithTranslation, withTranslation } from "react-i18next";

interface IProps {
    id: string;
    header?: string;
    text?: string,
    isOnlyForThisPage?: boolean;
    isOnlyForThisPageDisabled?: boolean;

    onHeaderChange?: (id: string, header: string) => void;
    onTextChange?: (id: string, text: string) => void;
    onIsOnlyForThisPageChange?: (id: string, checked: boolean) => void;
    onConfirm?: (id: string) => void;
    onRemove?: (id: string) => void;
}

class NewHotspotTooltipContent extends React.PureComponent<IProps & WithTranslation> {
    handleHeaderChange = (args: IInputOnChangeEvent<string>) => {
        this.props.onHeaderChange?.(this.props.id, args.value);
    };

    handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        this.props.onTextChange?.(this.props.id, event.target.value);
    };

    handleIsOnlyForThisPageChange = (args: ICheckboxChange) => {
        this.props.onIsOnlyForThisPageChange?.(this.props.id, args.value);
    };

    handleConfirm = () => {
        this.props.onConfirm?.(this.props.id);
    };

    handleRemove = () => {
        this.props.onRemove?.(this.props.id);
    };

    isConfirmDisabled = () => {
        return !this.props.header || !this.props.text;
    };

    render() {
        return (
            <StyledNewHotspotTooltipContent>
                <WriteLine value={this.props.header}
                           placeholder={this.props.t("Components:Hotspots.HeaderPlaceholder")}
                           textAlign={TextAlign.Left}
                           width={BasicInputSizes.M}
                           onChange={this.handleHeaderChange}/>
                <NewHotspotTextArea value={this.props.text}
                                    placeholder={this.props.t("Components:Hotspots.TextPlaceholder")}
                                    onChange={this.handleTextChange}
                                    rows={4}
                                    style={{ marginTop: "18px" }}/>
                <HotspotTooltipLine/>
                <Checkbox label={this.props.t("Components:Hotspots.IsOnlyForThisPage")}
                          checked={this.props.isOnlyForThisPage}
                          isDisabled={this.props.isOnlyForThisPageDisabled}
                          onChange={this.handleIsOnlyForThisPageChange}
                          style={{ marginTop: "7px", marginBottom: "10px" }}/>
                <ButtonGroup>
                    <Button isLight
                            isTransparent
                            onClick={this.handleRemove}>{this.props.t("Common:General.ThrowAway")}</Button>
                    <Button isLight
                            isDisabled={this.isConfirmDisabled()}
                            onClick={this.handleConfirm}>{this.props.t("Common:General.Confirm")}</Button>
                </ButtonGroup>
            </StyledNewHotspotTooltipContent>
        );
    }
}

export default withTranslation(["Common", "Components"])(NewHotspotTooltipContent);