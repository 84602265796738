import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import TestIds from "../../testIds";
import { AppButtonWrapper, Title } from "./AppButton.styles";
import { IconSize } from "../../enums";
import { IProps as IIconProps } from "../icon";

interface IProps extends WithTranslation {
    id: number;
    name: string;
    icon: React.ComponentType<IIconProps>;
    onClick?: (id: number) => void;
}

class AppButton extends React.PureComponent<IProps> {
    handleOnClick = () => {
        this.props.onClick?.(this.props.id);
    };

    render() {
        const Icon = this.props.icon;
        return (
            <AppButtonWrapper data-testid={TestIds.Button}
                              onClick={this.handleOnClick}>
                <Icon width={IconSize.XL} height={IconSize.XL}/>
                <Title>{this.props.name}</Title>
            </AppButtonWrapper>
        );
    }
}

export default withTranslation(["Components"])(AppButton);