import { EntitySetName, EntityTypeName } from "@odata/GeneratedEntityTypes";
import { CompanyPermissionCode, DocumentTypeCode } from "@odata/GeneratedEnums";
import { setDefByEntityType } from "@pages/getDefByEntityType";
import { getItemBreadCrumbsText, IDefinition, IGetDefinition } from "@pages/PageUtils";
import { isCashBasisAccountingCompany } from "@utils/CompanyUtils";
import i18next from "i18next";
import { cloneDeep } from "lodash";

import { IAppContext } from "../../../../contexts/appContext/AppContext.types";
import { Model } from "../../../../model/Model";
import { commonDocumentTranslations } from "../../Document.utils";
import { addPaymentOrderTableDefs } from "../../DocumentCommonDefs";
import { clearEmptyDateGroupDateFields, getDefinitions as getDocumentDefinitions } from "../../DocumentDef";
import { addItemsSummaryDef } from "../../extensions/itemsSummary/ItemsSummary.utils";
import { enhanceReceivedDocDefinition } from "../../invoicesReceived/InvoicesReceivedDef";
import { addCorrectionDefinition } from "../CommonCorrectiveSharedUtils";
import CorrectiveInvoicesReceivedFormView from "./CorrectiveInvoicesReceivedFormView";

export const getDefinitions: IGetDefinition = (context: IAppContext): IDefinition => {
    const hasAccountAssignment = !isCashBasisAccountingCompany(context);
    const definition = cloneDeep(getDocumentDefinitions({
        entitySet: EntitySetName.CorrectiveInvoicesReceived,
        documentType: DocumentTypeCode.CorrectiveInvoiceReceived,
        tableId: `${EntityTypeName.CorrectiveInvoiceReceived}Table`,
        formId: `${EntityTypeName.CorrectiveInvoiceReceived}Form`,
        formControl: CorrectiveInvoicesReceivedFormView,
        translationFiles: getDefinitions.translationFiles,
        getItemBreadCrumbText: (storage: Model) =>
                getItemBreadCrumbsText(storage, i18next.t("CorrectiveInvoicesReceived:Breadcrumbs.NewInvoice"),
                        storage.data.entity?.NumberOurs && i18next.t("CorrectiveInvoicesReceived:Breadcrumbs.InvoiceWithNumber", { number: storage.data.entity.NumberOurs })),
        permissions: [CompanyPermissionCode.InvoicesReceived],
        context
    }));

    enhanceReceivedDocDefinition(definition, {
        docType: DocumentTypeCode.CorrectiveInvoiceReceived,
        transFile: "CorrectiveInvoicesReceived",
        hasAccountAssignment,
        withoutAccruals: true,
        context
    });

    addItemsSummaryDef(definition, context, true);
    addCorrectionDefinition(definition, context, true);
    addPaymentOrderTableDefs(definition);

    return clearEmptyDateGroupDateFields(definition);
};

getDefinitions.translationFiles = ["CorrectiveInvoicesReceived", "CorrectiveInvoicesShared", "InvoicesReceived", ...commonDocumentTranslations];
setDefByEntityType(EntityTypeName.CorrectiveInvoiceReceived, getDefinitions);