import { IDialogProps } from "../dialog";
import React, { useCallback, useState } from "react";
import { BasicInputSizes, LabelStatus } from "../../enums";
import { useTranslation } from "react-i18next";
import Dialog from "../dialog/Dialog";
import Input from "../inputs/input/Input";
import Field from "../inputs/field";
import ConfirmationButtons from "../../views/table/ConfirmationButtons";
import { WithBusyIndicator, withBusyIndicator } from "../busyIndicator/withBusyIndicator";
import { splitFileName } from "./File.utils";
import FieldsWrapper from "../inputs/field/FieldsWrapper";

interface IProps extends Pick<IDialogProps, "onClose">, WithBusyIndicator {
    currentName: string;
    onConfirm: (newName: string) => void;
}


const FileRenameDialog: React.FC<IProps> = ({ onConfirm, currentName, onClose, setBusy, busyIndicator }) => {
    const { t } = useTranslation(["Components", "Common"]);

    const { prefix, extension } = splitFileName(currentName);

    const [name, setName] = useState<string>(prefix);
    const [error, setError] = useState<string>(null);

    const commonProps = {
        width: BasicInputSizes.XL,
        height: "100%"
    };

    const handleConfirm = useCallback(async () => {
        const newName = `${name}.${extension}`;
        if (!name) {
            setError(t("Components:FileUploader.FileNameCannotBeEmpty"));
            return;
        }
        setBusy(true);
        await onConfirm(newName);
        setBusy(false);
    }, [name, extension, onConfirm, setBusy, setError, t]);

    const handleChange = useCallback(({ value }) => {
        setError(null);
        setName(value);
    }, [setName]);

    return (
            <Dialog title={t("Components:FileUploader.Rename")}
                    onConfirm={handleConfirm}
                    onClose={onClose}
                    footer={(
                            <ConfirmationButtons onConfirm={handleConfirm}
                                                 onCancel={onClose}
                                                 useWrapper={false}/>
                    )}
            >
                <FieldsWrapper>
                    <Field {...commonProps}
                           label={t("Components:FileUploader.Name")}
                           labelStatus={LabelStatus.Visible}
                           isRequired>
                        <Input {...commonProps}
                               error={error && { message: error }}
                               value={name} onChange={handleChange}/>
                    </Field>
                </FieldsWrapper>
                {busyIndicator}
            </Dialog>
    );
};

export default withBusyIndicator({ passBusyIndicator: true, isDelayed: true })(FileRenameDialog);