import styled from "styled-components/macro";
import View from "../../views/View";
import { SeparatorLine, StyledSeparator } from "@components/separator/Separator.styles";
import WriteLine from "../../components/inputs/writeLine";

export const ChartOfAccountsTemplatesWriteLine = styled(WriteLine)`
    font-size: 30px;
    font-weight: bold;
    font-style: italic;
    letter-spacing: 0.3px;
`;

export const ChartOfAccountsTemplatesViewStyled = styled(View)<{
    isFirstDefault: boolean
}>`
    ${props => props.isFirstDefault && `
        ${StyledSeparator} {
            &:nth-of-type(2) ${SeparatorLine} {
                background-color: ${props.theme.C_ACT_thick_line};
            }
        }
    `}
`;