import { withOData } from "@odata/withOData";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import TableView from "../../../views/table";
import Page, { IProps } from "../../Page";
import { getDefinitions } from "./ItemTemplatesDef";

interface IState {
}

class ItemTemplates extends Page<IProps, IState> {
    static defaultProps = {
        getDef: getDefinitions,
        tableView: TableView
    };
}

export default withRouter(withTranslation(getDefinitions.translationFiles)(withOData(ItemTemplates)));
