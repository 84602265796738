import styled, { css } from "styled-components/macro";
import DashboardTileBackground from "../../dashboard/DashboardTileBackground";
import {
    DashboardTileHeading,
    DashboardTileHorizontalPadding,
    DashboardTileRadiusPx,
    DashboardTileVerticalPadding
} from "../../dashboard/Dashboard.styles";
import { T_BOLD_tiny, T_PLAIN_big, T_PLAIN_small } from "../../../global.style";
import { ellipsis } from "polished";
import { Button } from "../../button";

export const ItemSpacing = 12;


export const StyledTaxCalendarTile = styled(DashboardTileBackground)`
    position: relative;
    padding: 0;
`;

export const SubHeading = styled(DashboardTileHeading)`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 27px 0 22px;
`;

export const HeaderListItemStyledButton = styled(Button)`
    // it would be nice to just use Button,
    // but for some reason, this components uses different text size and color
    ${T_PLAIN_big};

    ${props => props.isTransparent && css`
        color: ${props.theme.C_TEXT_primary};
    `}
`;

export const HeaderListGroup = styled.div`
    display: flex;
    justify-content: flex-start;

    // weird custom button styles,
    // it would be nicer to use ButtonGroup and support ButtonSize.Small margin on it

    button + button {
        margin-left: 8px;
    }
`;

export const TaxCalendarHeader = styled.div`
    position: relative;
    z-index: 1;
    padding: ${DashboardTileVerticalPadding}px ${DashboardTileHorizontalPadding}px;
    background-color: ${props => props.theme.C_ACT_hover_without_opacity};
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.12);
    border-top-left-radius: ${DashboardTileRadiusPx};
    border-top-right-radius: ${DashboardTileRadiusPx};
`;

export const Label = styled.dt`
    ${T_PLAIN_small};
    float: left;
    clear: left;
    ${ellipsis()};
    max-width: 107px;
`;

export const Content = styled.dd`
    margin-left: 112px;
    padding-left: 5px;
    border-left: 2px solid ${props => props.theme.C_ACT_hover_without_opacity};
`;

export const BodyWrapper = styled.div`
    flex: 1 1 auto;
    height: 0;
`;

export const Body = styled.dl`
    z-index: 0;
    padding: ${DashboardTileVerticalPadding}px ${DashboardTileHorizontalPadding}px;

    ${Content} + ${Content} {
        padding-top: ${ItemSpacing}px;
    }

    ${Content} + ${Label}, ${Label}:not(:first-child) + ${Content} {
        margin-top: ${ItemSpacing}px;
    }
`;

export const ContentHeading = styled.h4`
    ${T_BOLD_tiny};
    color: ${props => props.theme.C_ACT_main};
`;

export const ContentText = styled.div`
    ${T_PLAIN_big};
`;
