import React from "react";
import { DateIcon } from "../../icon";
import { IconSize } from "../../../enums";
import { DatePickerClean } from "./DatePicker";
import MonthYearPopup from "./popup/MonthYearPopup";
import { DateFormat, getDateFormat, getUtcDayjs } from "../../../types/Date";
import { longDateFormat } from "./utils";
import { withTranslation } from "react-i18next";
import { capitalize } from "@utils/string";

class MonthYearPicker extends DatePickerClean {
    static defaultProps = {
        format: DateFormat.monthAndYear
    };

    get pickerPopup() {
        return MonthYearPopup;
    }

    get icon() {
        return <DateIcon width={IconSize.M}/>;
    }

    get format() {
        const format = this.props.format;

        return longDateFormat(getDateFormat(format));
    }

    formatter(value: Date): string {
        const formattedValue = super.formatter(value);

        // first letter of month should be capitalized
        return formattedValue ? capitalize(formattedValue) : formattedValue;
    }

    isSame = (date1: Date, date2: Date): boolean => {
        if (date1 instanceof Date) {
            return getUtcDayjs(date1).isSame(date2, "month");
        }

        return date1 === date2;
    };
}

const MonthYearPickerExtended = withTranslation(["Components"])(MonthYearPicker);

export { MonthYearPickerExtended as MonthYearPicker };