import React from "react";
import { Trans, useTranslation } from "react-i18next";
import DashboardBackground, { BackgroundType } from "../../components/navigation/DashboardBackground";
import { WithPermissionContext, withPermissionContext } from "../../contexts/permissionContext/withPermissionContext";
import { BackButtonWrapper, ButtonGroupStyled, LayoutContainer, Paragraph, Title } from "./ConnectIDokladScreen.styles";
import { Button, IconButton } from "../../components/button";
import { ButtonSize } from "@components/button/Button.utils";
import { iDokladLoginURL, iDokladRegistrationURL } from "./idoklad.utils";
import { ArrowIcon } from "@components/icon";
import { IconSize } from "../../enums";
import { ROUTE_HOME } from "../../routes";

interface IProps extends WithPermissionContext {
}

const ConnectIDokladScreen: React.FC<IProps> = (props) => {
    const { t, ready: tReady } = useTranslation("ConnectIDokladScreen");

    return (
        <LayoutContainer>
            {tReady && (
                <React.Fragment>
                    <Title>
                        <Trans i18nKey={"ConnectIDokladScreen:Title"}></Trans>
                    </Title>
                    <Paragraph>
                        <Trans i18nKey={"ConnectIDokladScreen:Paragraph"}></Trans>
                    </Paragraph>
                    <ButtonGroupStyled>
                        <Button size={ButtonSize.Big} link={iDokladLoginURL}>
                            {t("Connect")}
                        </Button>
                        <Button isTransparent link={iDokladRegistrationURL}>
                            {t("Register")}
                        </Button>
                    </ButtonGroupStyled>

                    <BackButtonWrapper>
                        <IconButton title={t("Back")}
                                    link={ROUTE_HOME}
                                    isTransparent isDecorative>
                            <ArrowIcon width={IconSize.M} height={IconSize.M}/>
                        </IconButton>
                    </BackButtonWrapper>
                </React.Fragment>
            )}

            <DashboardBackground type={BackgroundType.ConnectWithIDoklad} hasShellBar={false}/>
        </LayoutContainer>
    );
};

export default withPermissionContext(ConnectIDokladScreen);
