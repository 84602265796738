import React from "react";
import { TextAlign } from "../../enums";
import { IInputOnChangeEvent } from "@components/inputs/input";
import { ChartOfAccountTemplateContext, ICOATemplateContext } from "./ChartOfAccountsTemplatesContext";
import { TableStorage } from "../../model/TableStorage";
import { IEntity } from "@odata/BindingContext";
import { IValidationError } from "../../model/Validator.types";
import { ChartOfAccountsTemplatesWriteLine } from "./ChartOfAccountsTemplates.styles";
import { EntitySetName } from "@odata/GeneratedEntityTypes";

import { isODataError } from "@odata/Data.types";

interface IState {
    originalName: string;
    changingName: string;
    error: IValidationError;
}

interface IProps {
    parentEntity: IEntity;
    storage: TableStorage;
}

class CoAEditableHeader extends React.Component<IProps, IState> {
    static contextType = ChartOfAccountTemplateContext;

    constructor(props: IProps, context: ICOATemplateContext) {
        super(props, context);

        this.state = {
            originalName: props.parentEntity.Name,
            changingName: props.parentEntity.Name,
            error: null
        };
    }

    handleChangeTemplateName = (e: IInputOnChangeEvent): void => {
        this.context.isChangingName = true;
        this.setState({
            changingName: (e.value as string) ?? "",
            error: null
        });
    };

    saveTemplate = async (): Promise<boolean> => {
        const templateId = this.props.parentEntity.Id;
        const { changingName } = this.state;

        try {
            await this.props.storage.oData.getEntitySetWrapper(EntitySetName.ChartOfAccountsTemplates)
                .update(templateId, {
                    Name: changingName
                });
        } catch (error) {
            let title;
            if (isODataError(error)) {
                const code = error._validationMessages?.[0]?.code ?? error._code;
                title = this.props.storage.t(`Error:${code}`);
            } else {
                title = error.toString?.();
            }
            this.setState({ error: { message: title } });
            this.context.onTemplateNameChangeFailed?.();
            return false;
        }
        this.context.isChangingName = false;
        this.context.isChanged = true;
        this.context.onTemplateNameChange?.(this.props.parentEntity, changingName);
        this.props.storage.data.definition.title = changingName;

        this.setState({
            originalName: changingName
        });

        return true;
    };

    handleCancel = (): void => {
        this.context.isChangingName = false;
        this.setState({
            changingName: this.state.originalName,
            error: null
        });
    };

    render() {
        return (
            <ChartOfAccountsTemplatesWriteLine
                value={this.state.changingName !== null ? this.state.changingName : this.state.originalName}
                textAlign={TextAlign.Left}
                placeholder={this.props.storage.t("ChartsOfAccountsTemplates:Name")}
                isConfirmable
                isExtending
                isRequired={true}
                onChange={this.handleChangeTemplateName}
                onConfirm={this.saveTemplate}
                onCancel={this.handleCancel}
                error={this.state.error}
            />
        );
    }
}

export default CoAEditableHeader;
