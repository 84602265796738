import { SwitchType } from "@components/inputs/switch/Switch";
import { withDisplayName } from "@components/smart/GeneralFieldDefinition";
import { FilterBarGroup, getDefaultFilterGroupDef } from "@components/smart/smartFilterBar/SmartFilterBar.types";
import { CbaCategoryEntity, EntitySetName, EntityTypeName, ICbaCategoryEntity } from "@odata/GeneratedEntityTypes";
import { CbaCategoryTaxImpactCode } from "@odata/GeneratedEnums";
import i18next from "i18next";

import { BasicInputSizes, FieldType, Sort, TextAlign } from "../../enums";
import { Model } from "../../model/Model";
import { IFormDef } from "../../views/formView/Form";
import { ISplitPageTableDef } from "../../views/table/TableView.utils";
import { getCbaCategoriesCommonFieldsDef } from "../cashBasisAccounting/CashBasisAccounting.utils";
import { activeFormatter } from "../chartOfAccounts/ChartOfAccountsDef";
import { setDefByEntityType } from "../getDefByEntityType";
import { getItemBreadCrumbsText, IDefinition, IGetDefinition } from "../PageUtils";
import CbaCategoriesFormView from "./CbaCategoriesFormView";

export const getDefinitions: IGetDefinition = (): IDefinition => {
    const table: ISplitPageTableDef = {
        filterBarDef: [{
            ...getDefaultFilterGroupDef(FilterBarGroup.Filters),
            defaultFilters: [
                CbaCategoryEntity.Name,
                CbaCategoryEntity.TaxImpact,
                CbaCategoryEntity.IsActive
            ],
            filterDefinition: {
                Name: {},
                ...withDisplayName(CbaCategoryEntity.TaxImpact),
                IsActive: {}
            },
            isValueHelp: true
        }],
        id: `${EntityTypeName.CbaCategory}Table`,
        initialSortBy: [{ id: CbaCategoryEntity.Name, sort: Sort.Asc }],
        columns: [
            CbaCategoryEntity.Name,
            CbaCategoryEntity.TaxImpact,
            CbaCategoryEntity.IsActive
        ],
        columnDefinition: {
            Name: {},
            TaxImpact: {
                formatter: (val, args) => {
                    const entity = args.entity as ICbaCategoryEntity;
                    if (entity.TaxImpact?.Code === CbaCategoryTaxImpactCode.Partial) {
                        return `${val} | ${entity.TaxPercentage} %`;
                    }
                    return val as string;
                },
                fieldSettings: {
                    displayName: "Name"
                },
                additionalProperties: [{ id: `/${CbaCategoryEntity.TaxPercentage}` }]
            },
            IsActive: {
                id: CbaCategoryEntity.IsActive,
                textAlign: TextAlign.Center,
                formatter: activeFormatter
            }
        },
        title: i18next.t("Categories:Title")
    };

    const form: IFormDef = {
        id: `${EntityTypeName.CbaCategory}Form`,
        translationFiles: getDefinitions.translationFiles,
        isDeletable: true,
        getItemBreadCrumbText: (storage: Model) =>
                getItemBreadCrumbsText(storage, i18next.t("Categories:NewCategory"), storage.data.entity?.Name ?? storage.data.entity?.Number),
        formControl: CbaCategoriesFormView,
        additionalProperties: [],
        fieldDefinition: {
            Name: {
                width: BasicInputSizes.L
            },
            IsActive: {
                type: FieldType.Switch,
                fieldSettings: {
                    type: SwitchType.Icons
                },
                defaultValue: true
            },
            ...getCbaCategoriesCommonFieldsDef("", false, false)
        },
        groups: [
            {
                id: "main",
                rows: [
                    [{ id: CbaCategoryEntity.Name }, { id: CbaCategoryEntity.IsActive }]
                ]
            }, {
                id: "defaults",
                title: i18next.t("Categories:Defaults"),
                rows: [
                    [{ id: CbaCategoryEntity.TaxImpact }, { id: CbaCategoryEntity.IsAssetAcquisition }, { id: CbaCategoryEntity.TaxPercentage }]
                ]
            }
        ]
    };

    return {
        entitySet: EntitySetName.CbaCategories,
        table,
        form
    };
};

getDefinitions.translationFiles = ["Categories", "Common"];
setDefByEntityType(EntityTypeName.CbaCategory, getDefinitions);