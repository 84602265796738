import React, { useCallback, useContext } from "react";
import { CloseCustomerPortalButtonStyled } from "./Home.style";
import { ExitIcon } from "@components/icon";
import { useTranslation } from "react-i18next";
import { CustomerPortal } from "./CustomerPortal.utils";
import { currentUserIsCustomer } from "../admin/users/Users.utils";
import { AppContext } from "../../contexts/appContext/AppContext.types";
import { useHistory } from "react-router-dom";
import { ROUTE_COMPANIES } from "../../routes";

interface IProps {
}

const CloseCustomerPortalButton: React.FC<IProps> = (props) => {
    const { t } = useTranslation("Home");
    const context = useContext(AppContext);
    const history = useHistory();

    const handleClose = useCallback(() => {
        CustomerPortal.isActive = false;
        history.push(ROUTE_COMPANIES);
    }, [history]);

    const isCustomer = currentUserIsCustomer(context);

    // only non-customer user can switch back to Evala, customer can only logout from his profile
    return isCustomer ? null : (
            <CloseCustomerPortalButtonStyled icon={<ExitIcon/>}
                                             onClick={handleClose}
                                             isTransparent>
                {t("Home:CloseCustomerPortal")}
            </CloseCustomerPortalButtonStyled>
    );
};

export default CloseCustomerPortalButton;