import { IFieldDefFn } from "@components/smart/FieldInfo";
import {
    CompanySettingEntity,
    EntitySetName,
    EntityTypeName,
    TrustedSenderEntity,
    UserEntity
} from "@odata/GeneratedEntityTypes";
import i18next from "i18next";
import React from "react";

import Field from "../../components/inputs/field";
import { IAppContext } from "../../contexts/appContext/AppContext.types";
import { BasicInputSizes, FieldType, ValidatorType } from "../../enums";
import BindingContext, { createPath } from "../../odata/BindingContext";
import { IFormDef } from "../../views/formView/Form";
import { CustomerGeneralRoleId } from "../admin/users/Users.utils";
import { setDefForTesting } from "../getDefByEntityType";
import { IDefinition, IGetDefinition } from "../PageUtils";
import InboxCompanyEmailAddress from "./InboxCompanyEmailAddress";

const InboxAddressPath = BindingContext.localContext("Box");

export const getDefinition: IGetDefinition = (context: IAppContext): IDefinition => {

    const additionalUserProps = [
        { id: UserEntity.FirstName },
        { id: UserEntity.LastName },
    ];

    const currentCompanyId = context.getCompanyId();

    const form: IFormDef = {
        id: `${EntityTypeName.CompanySetting}Form`,
        translationFiles: getDefinition.translationFiles,
        fieldDefinition: {
            [InboxAddressPath]: {
                render: (args: IFieldDefFn) => (
                    <Field label={i18next.t("Inbox:Settings.Address")} isReadOnly name={"Address"}>
                        <InboxCompanyEmailAddress storage={args.storage}/>
                    </Field>
                )
            },
            [CompanySettingEntity.InboxApprovers]: {
                type: FieldType.MultiSelect,
                width: BasicInputSizes.XL,
                label: i18next.t("Inbox:Settings.Name"),
                fieldSettings: {
                    keyPath: UserEntity.Id,
                    displayName: UserEntity.Name,
                    additionalProperties: additionalUserProps,
                    preloadItems: true,
                    localDependentFields: [
                        {
                            from: { id: UserEntity.Name },
                            to: { id: UserEntity.Name }
                        },
                    ]
                },
                filter: {
                    select: `GeneralRoles/any(x: x/GeneralRole/Id eq ${CustomerGeneralRoleId}) and CompanyRoles/any(x: x/Company/Id eq ${currentCompanyId}) and Id ge 0`
                },
                additionalProperties: additionalUserProps
            },
            [createPath(CompanySettingEntity.TrustedSenders, TrustedSenderEntity.Email)]: {
                width: BasicInputSizes.L,
                validator: {
                    type: ValidatorType.Email
                }
            }
        },
        groups: [
            {
                id: "Box",
                title: i18next.t(`Inbox:Settings.Box`),
                rows: [[{ id: InboxAddressPath }]]
            }, {
                id: "ApproverList",
                tooltip: i18next.t(`Inbox:Settings.ListOfApproversTooltip`),
                title: i18next.t(`Inbox:Settings.ListOfApprovers`),
                rows: [[{ id: CompanySettingEntity.InboxApprovers }]]
            }, {
                id: "TrustedSenders",
                title: i18next.t(`Inbox:Settings.WithoutApproval`),
                lineItems: {
                    collection: CompanySettingEntity.TrustedSenders,
                    order: "Id",
                    canReorder: false,
                    isGroupToggleable: true,
                    isItemCloneable: false,
                    columns: [{ id: TrustedSenderEntity.Email }]
                }
            }
        ]
    };

    return {
        entitySet: EntitySetName.CompanySettings,
        form
    };
};

getDefinition.translationFiles = ["Inbox", "Common"];
setDefForTesting("InboxSettingsDef", getDefinition);