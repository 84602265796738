import styled from "styled-components/macro";
import { IconButton } from "../../button";
import { CaretIcon } from "../../icon";
import { getFocusBorderElement, T_HEADER_small } from "../../../global.style";

export const MonthNavButton = styled(IconButton)`
    margin: 0 10px;
    visibility: hidden;
`;

export const StyledMonthYearSelector = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 270px;

    :hover ${MonthNavButton} {
        visibility: visible;
    }
`;

export const MonthAndYearValue = styled.div`
    position: relative;
    color: ${props => props.theme.C_ACT_main};
    cursor: pointer;

    ${T_HEADER_small};
    ${getFocusBorderElement({ offset: 2, keyboardOnly: true })};

    :hover {
        color: ${props => props.theme.C_BTN_4L_emph_text_hover};
    }
`;

export const CaretIconLeft = styled(CaretIcon)`
    transform: rotate(90deg);
`;

export const CaretIconRight = styled(CaretIcon)`
    transform: rotate(-90deg);
`;
