import React from "react";
import { FormViewForExtend, IFormViewProps } from "../../views/formView/FormView";
import BindingContext, { IEntity } from "../../odata/BindingContext";
import { FormStorage } from "../../views/formView/FormStorage";
import { cloneDeep, debounce } from "lodash";
import { ISmartFieldChange } from "@components/smart/smartField/SmartField";
import { DASH_CHARACTER } from "../../constants";
import {
    CARRY_FORWARD_PREFIX,
    CARRY_FORWARD_PREFIX_CBA,
    INumberRangeFormCustomData,
    isDocumentNumberRange,
    NumberRangePreviewPath,
    replaceWildcards,
    VarSymbolPreviewPath
} from "./NumberRange.utils";
import {
    EntitySetName,
    INumberRangeDefinitionEntity,
    INumberRangeEntity,
    INumberRangeWildcardEntity,
    NumberRangeDefinitionEntity,
    NumberRangeEntity
} from "@odata/GeneratedEntityTypes";
import { AppContext } from "../../contexts/appContext/AppContext.types";
import { withPermissionContext } from "../../contexts/permissionContext/withPermissionContext";
import { getOpenFiscalYears } from "../fiscalYear/FiscalYear.utils";
import { isAccountAssignmentCompany } from "@utils/CompanyUtils";

const generateNumRangePreviewsSync = (numberRangeDefinition: INumberRangeDefinitionEntity, numberRange: INumberRangeEntity) => {
    const zeros = numberRangeDefinition.PadWithZeros && numberRangeDefinition.NumberOfDigits > 0 ? "0".repeat((numberRangeDefinition.NumberOfDigits - 1)) : "";
    const numericPart = numberRangeDefinition.NumberOfDigits > 0 ? zeros + "1" : "";
    const previewEntity = {
        FiscalYear: numberRange.FiscalYear,
        DateStart: numberRange.FiscalYear?.DateStart,
        FiscalPeriod: numberRange.FiscalYear?.Periods?.[0]
    };

    const varSymbol = [
        numberRangeDefinition.CarryForwardPrefix ? replaceWildcards(numberRangeDefinition.CarryForwardPrefix, previewEntity, {
            previewString: "2001",
            decisiveDateProp: "DateStart"
        }) : null,
        replaceWildcards(numberRangeDefinition.Prefix, previewEntity, {
            previewString: "2001",
            decisiveDateProp: "DateStart"
        },),
        numericPart,
        replaceWildcards(numberRangeDefinition.Suffix, previewEntity, {
            previewString: "2001",
            decisiveDateProp: "DateStart"
        })
    ].filter(val => val).join(" ");

    const numberRangePreview = numberRangeDefinition.NumberRangeType ? ((numberRangeDefinition.NumberRangeTypePrefix ?? "") + " " + varSymbol).trim() : "";
    const varSymbolPreview = numberRangeDefinition.NumberRangeType ? (numberRangeDefinition.NumberRangeTypePrefix?.replace(/\D/g, "") ?? "") + varSymbol.replace(/\D/g, "") : "";

    // local binding context is not part of generated entity types, for now we cannot think of better solution
    (numberRange as IEntity)[NumberRangePreviewPath] = numberRangePreview || DASH_CHARACTER;
    (numberRange as IEntity)[VarSymbolPreviewPath] = varSymbolPreview || DASH_CHARACTER;
};

export const generateNumRangeDefinitionPreviewsSync = (numRangeDefinition: INumberRangeDefinitionEntity, storage: FormStorage, withoutRefresh?: boolean): void => {
    if (numRangeDefinition.NumberRanges) {
        for (const child of numRangeDefinition.NumberRanges) {
            generateNumRangePreviewsSync(numRangeDefinition, child);
        }
    }

    if (!withoutRefresh) {
        storage.refreshGroupByKey("number");
    }
};

export const generateNumRangePreviews = debounce(generateNumRangeDefinitionPreviewsSync, 150);

class NumberRangeFormView extends FormViewForExtend<INumberRangeDefinitionEntity, IFormViewProps<INumberRangeDefinitionEntity, INumberRangeFormCustomData>> {
    static contextType = AppContext;
    static defaultProps = {
        title: "NumberRange:Range"
    };

    _refScroll = React.createRef<HTMLElement>();

    getAdditionalLoadPromise = () => {
        const { oData } = this.props.storage;
        const promises: Promise<unknown>[] = [];

        if (!this.props.storage.getCustomData().allWildcards) {
            // we want to fill the wildcards tooltip even when no number range type is selected
            promises.push(
                oData.getEntitySetWrapper(EntitySetName.NumberRangeWildcards).query().fetchData<INumberRangeWildcardEntity[]>()
            );
        }

        return promises;
    };

    onAfterLoad = async () => {

        const storage = this.props.storage;
        const isNew = storage.data.bindingContext.isNew();

        if (!this.props.storage.getCustomData().allWildcards) {
            this.props.storage.setCustomData({
                allWildcards: this.props.storage.data.additionalResults[0].value
            });
            this.props.storage.refreshGroupByKey("number");
        }

        !isNew && generateNumRangePreviews(storage.data.entity, storage);
        this.updateCarryForwardPrefix();

        if (isNew) {
            this.updateChildren();
        }

        return super.onAfterLoad();
    };

    get isNested(): boolean {
        return isDocumentNumberRange(this.props.storage) && this.entity.CarryForward;
    }

    updateCarryForwardPrefix = async (): Promise<void> => {
        if (this.props.storage.data.origEntity.CarryForwardPrefix) {
            return;
        }

        const cfPrefix = isAccountAssignmentCompany(this.context) ? CARRY_FORWARD_PREFIX : CARRY_FORWARD_PREFIX_CBA;

        this.props.storage.setValueByPath(NumberRangeDefinitionEntity.CarryForwardPrefix, this.isNested ? cfPrefix : null);

        const fields = [NumberRangeDefinitionEntity.Prefix, NumberRangeDefinitionEntity.NumberOfDigits, NumberRangeDefinitionEntity.Suffix];

        for (const path of fields) {
            const bc = this.props.storage.data.bindingContext.navigate(path);
            const value = this.props.storage.getValue(bc);

            if (value) {
                await this.props.storage.validateField(bc);
                this.props.storage.addActiveField(bc);
            }
        }

        this.props.storage.refreshFields();
    };

    updateChildren = (): void => {
        if (this.isNested) {
            const fiscalYears = getOpenFiscalYears(this.context);

            if (this.entity.NumberRanges?.length !== fiscalYears.length) {
                const children: INumberRangeEntity[] = [];

                for (const fiscalYear of fiscalYears) {
                    children.push(BindingContext.createNewEntity<INumberRangeEntity>(fiscalYear.Id, {
                        FiscalYear: fiscalYear,
                        InitialValue: 1,
                        NextValue: 1
                    }));
                }

                this.props.storage.setValueByPath(NumberRangeDefinitionEntity.NumberRanges, children);
            }
        } else {
            if (this.entity.NumberRanges.length !== 1) {
                this.props.storage.setValueByPath(NumberRangeDefinitionEntity.NumberRanges, [
                    BindingContext.createNewEntity<INumberRangeEntity>(1, {
                        FiscalYear: null,
                        InitialValue: 1,
                        NextValue: 1
                    })]);
            } else if (this.entity.NumberRanges[0].FiscalYear) {
                this.entity.NumberRanges[0].FiscalYear = null;
            }
        }

        generateNumRangeDefinitionPreviewsSync(this.entity, this.props.storage);
    };

    handleInitialValueChange = (e: ISmartFieldChange): void => {
        // initial number can be changed just when number range is not used
        if (e.bindingContext.getPath(true) === NumberRangeEntity.InitialValue) {
            const nextValBc = e.bindingContext.getParent().navigate(NumberRangeEntity.NextValue);
            this.props.storage.setValue(nextValBc, e.value);
            this.props.storage.validateField(nextValBc);
        }
    };

    handleNumberRangeTypeChange = (e: ISmartFieldChange): void => {
        if ((e.bindingContext.getPath(true) === NumberRangeDefinitionEntity.NumberRangeType && e.triggerAdditionalTasks)) {
            const storage = this.props.storage;
            storage.setValue(storage.data.bindingContext.navigate(NumberRangeDefinitionEntity.NumberRangeTypePrefix),
                e.additionalData?.Prefix, storage.data.bindingContext.navigate(BindingContext.localContext("numRangeWithProgress")));
            // update directly Name to refresh summary item
            storage.setValueByPath("NumberRangeType/Name", e.additionalData.Name);
            storage.setValueByPath("NumberRangeType/Wildcards", e.additionalData.Wildcards);
            storage.validateFields([storage.data.bindingContext.navigate(NumberRangeDefinitionEntity.Prefix), storage.data.bindingContext.navigate(NumberRangeDefinitionEntity.Suffix)]);
            this.updateCarryForwardPrefix();
            this.updateChildren();
            generateNumRangeDefinitionPreviewsSync(this.entity, this.props.storage);
            storage.refresh();
        }
    };

    handlePadWithZeros = (e: ISmartFieldChange): void => {
        if (e.bindingContext.getPath(true) === NumberRangeDefinitionEntity.PadWithZeros) {
            generateNumRangePreviews(this.entity, this.props.storage);
        }
    };

    handleCarryForwardChange = (e: ISmartFieldChange): void => {
        if (e.bindingContext.getPath(true) === NumberRangeDefinitionEntity.CarryForward) {
            this.props.storage.setValueByPath(NumberRangeDefinitionEntity.IsDefault, false);
            this.updateCarryForwardPrefix();
            this.updateChildren();

            this.props.storage.refresh();
        }
    };

    handleChange = async (e: ISmartFieldChange): Promise<void> => {
        this.props.storage.handleChange(e);
        this.handleNumberRangeTypeChange(e);
        this.handleInitialValueChange(e);
        this.handlePadWithZeros(e);

        this.props.storage.refreshFields(e.triggerAdditionalTasks);

        this.handleCarryForwardChange(e);
    };

    onBeforeSave = (): INumberRangeDefinitionEntity => {
        const entity = cloneDeep(this.entity) as INumberRangeDefinitionEntity;

        const isSimple = !isDocumentNumberRange(this.props.storage);

        if (isSimple) {
            entity.CarryForward = false;
            entity.CarryForwardPrefix = null;
        }


        return entity;
    };
}

export default withPermissionContext(NumberRangeFormView);
