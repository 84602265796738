import React from "react";
import ConfirmationButtons from "../../views/table/ConfirmationButtons";
import Field from "../../components/inputs/field";
import { BasicInputSizes, FieldType } from "../../enums";
import { IInputOnBlurEvent, IInputOnChangeEvent, InputWithErrorAndTooltip } from "@components/inputs/input";
import Dialog from "../../components/dialog/Dialog";
import { withTranslation, WithTranslation } from "react-i18next";
import { IValidationError } from "../../model/Validator.types";
import { IFieldToUpdate, IImportFieldDef } from "./CsvImport.utils";
import { AppContext } from "../../contexts/appContext/AppContext.types";
import FieldsWrapper from "../../components/inputs/field/FieldsWrapper";
import { DatePicker } from "../../components/inputs/date/DatePicker";
import DateType, { parseDayjs } from "../../types/Date";
import NumberType from "../../types/Number";
import TestIds from "../../testIds";

interface IProps extends WithTranslation {
    fieldToUpdate?: IFieldToUpdate;
    selectedDateFormat: string;
    selectedNumberLocalization: string;
    onClose?: () => void;
    onConfirm?: (value: string) => void;
}

interface IState {
    value: string;
    valueChangeError: IValidationError;
}

class ChangeValueDialog extends React.PureComponent<IProps, IState> {
    static contextType = AppContext;

    constructor(props: IProps) {
        super(props);
        this.state = {
            value: this.props.fieldToUpdate.value,
            valueChangeError: null
        };
    }

    get fieldDef(): IImportFieldDef {
        return this.props.fieldToUpdate.fieldDef;
    }

    get validationError(): IValidationError {
        const validatorResult = this.fieldDef?.validator?.(this.state.value, this.context, this.props.selectedDateFormat);

        if (this.fieldDef.validator && validatorResult !== true) {
            return { message: validatorResult as string };
        }
        return null;
    }

    handleInputBlur = (e: IInputOnBlurEvent) => {
        const parsedValue = NumberType.parse(this.state.value as string, true, {
            maximumFractionDigits: 2
        });

        if (!isNaN(parsedValue)) {
            this.setState({ value: parsedValue?.toString() });
        }
    };

    handleChange = (e: IInputOnChangeEvent) => {
        let value = e.value;
        if (this.fieldDef?.type === FieldType.Date) {
            if (!e.triggerAdditionalTasks) {
                this.setState({ value: "" });
            }
            value = DateType.format(e.value as Date, this.props.selectedDateFormat);
        }
        this.setState({ value: value as string });
    };

    handleConfirm = () => {
        if (this.validationError) {
            return;
        }

        const parser = this.fieldDef.parser;
        const parsedValue = parser ? parser(this.state.value as string, { localization: this.props.selectedNumberLocalization }) : this.state.value;
        this.props.onConfirm(parsedValue);
    };

    dateFormatter = (value: string): string => {
        if (!DateType.isValid(value as unknown as Date) || !value) {
            return this.state.value;
        }
        return value;
    };

    renderDateValue = (): React.ReactElement => {
        const date = parseDayjs({ date: this.state.value, format: this.props.selectedDateFormat, strictMode: true });

        return (
            <DatePicker
                    value={date?.isValid() ? date.toDate() : this.state.value as unknown as Date}
                onChange={this.handleChange}
                formatter={this.dateFormatter}
                error={this.validationError}
            />
        );
    };

    render() {
        return <Dialog
                title={this.props.t("CsvImport:ChangeValue")}
                testid={TestIds.ChangeValueDialog}
                footer={<ConfirmationButtons
                        onCancel={this.props.onClose}
                        onConfirm={this.handleConfirm}
                        useWrapper={false}
                />}
                onConfirm={this.handleConfirm}
                onClose={this.props.onClose}>
            <FieldsWrapper isWithoutWrap>
                <Field label={this.props.fieldToUpdate.label}
                       isRequired={this.fieldDef?.required}
                       width={BasicInputSizes.M}>
                    {this.fieldDef?.type === FieldType.Date && this.renderDateValue()}
                    {this.fieldDef?.type !== FieldType.Date && <InputWithErrorAndTooltip
                            value={this.state.value as string}
                            error={this.validationError}
                            onBlur={this.handleInputBlur}
                            onChange={this.handleChange}
                    />}
                </Field>
                <Field label={this.props.t("CsvImport:OriginalValue")}
                       isReadOnly>
                    <InputWithErrorAndTooltip value={this.props.fieldToUpdate.originalValue}
                                              isReadOnly/>
                </Field>
            </FieldsWrapper>
        </Dialog>;
    }
}

export default withTranslation(["CsvImport"])(ChangeValueDialog);