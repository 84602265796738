import { dateAccountingCustomValidator } from "@pages/documents/DocumentDef";
import i18next from "i18next";

import { ifAll, IFieldDef, IGetValueArgs, not, TInfoValue } from "../../../components/smart/FieldInfo";
import { getLabelsDef } from "../../../components/smart/GeneralFieldDefinition";
import { DASH_CHARACTER } from "../../../constants";
import { IAppContext } from "../../../contexts/appContext/AppContext.types";
import { BasicInputSizes, FieldType, NavigationSource, ValidatorType, ValueType } from "../../../enums";
import { TValue } from "../../../global.types";
import { createPath } from "../../../odata/BindingContext";
import {
    DocumentAccountAssignmentSelectionDraftEntity,
    DocumentCbaCategoryDraftEntity,
    DocumentDraftEntity,
    DocumentEntity,
    DocumentItemVatClassificationEntity,
    DocumentItemVatClassificationSelectionDraftEntity,
    EntitySetName,
    ICbaCategoryTaxImpactEntity,
    VatClassificationEntity
} from "../../../odata/GeneratedEntityTypes";
import {
    CbaCategoryTaxImpactCode,
    DocumentTypeCode,
    MultiplePostingDateAccountingTypeCode,
    PayableReceivableTypeCode,
    VatReverseChargeCode
} from "../../../odata/GeneratedEnums";
import { IFormatOptions } from "../../../odata/OData.utils";
import { getUtcDate } from "../../../types/Date";
import { hasEverBeenVatRegisteredCompany, isCashBasisAccountingCompany } from "../../../utils/CompanyUtils";
import { IFormDef } from "../../../views/formView/Form";
import { getAccountSelectionFieldsDef } from "../../accountAssignment/AccountAssignment.utils";
import {
    getVatDependentFields,
    getVatFieldsDef,
    getVatGroupRowsDef,
    VAT_REVERSE_CHARGE_SELECT_PATH
} from "../../admin/vatRules/VatRules.utils";
import { getCashBasisAccountingFieldsDef } from "../../cashBasisAccounting/CashBasisAccounting.utils";
import { IDefinition, IGetDefinition, TFieldDefinition } from "../../PageUtils";
import { isIssued, isReceived, validateDecisiveDate } from "../Document.utils";
import { DateAccountingTypePath, DateReceivedTypePath, NoVatItem } from "./DraftMassAccountingDialog.utils";
import { ISelectItem, SelectGroups } from "@components/inputs/select/Select.types";

const isCashBasis: TInfoValue<boolean> = (args: IGetValueArgs): boolean => {
    return isCashBasisAccountingCompany(args.context);
};

const isReceivedCallback: TInfoValue<boolean> = (args: IGetValueArgs): boolean => {
    return !isIssued(args.storage.data.entity[DocumentDraftEntity.DocumentTypeCode]);
};

// todo: may be add it to BE enum
export const TODAY = "today";

export const getDefinitionFnWithDocType = (docType: DocumentTypeCode): IGetDefinition => {
    const _isIssued = isIssued(docType);
    const payableReceivableTypeCode = docType === DocumentTypeCode.InternalDocument ? PayableReceivableTypeCode.InternalDocument : isReceived(docType) ? PayableReceivableTypeCode.Payable : PayableReceivableTypeCode.Receivable;

    const cbaFieldsDef = getCashBasisAccountingFieldsDef(DocumentEntity.CbaCategory, false, _isIssued);

    const getDefinition: IGetDefinition = (context: IAppContext): IDefinition => {
        let groupRows: (IFieldDef[])[];

        if (!isCashBasisAccountingCompany(context)) {
            groupRows = [
                [{ id: DateAccountingTypePath }, { id: DocumentDraftEntity.DateAccountingTransaction }],
                // temporarily removed
                // [{ id: DocumentDraftEntity.Labels }],
                // has to be in solo row, because we use collapsed rows for bothAccountAssignment and VatClassification
                [
                    { id: createPath(DocumentDraftEntity.AccountAssignmentSelection, DocumentAccountAssignmentSelectionDraftEntity.AccountAssignment) }
                ],
                [
                    { id: createPath(DocumentDraftEntity.VatClassificationSelection, DocumentItemVatClassificationSelectionDraftEntity.VatClassification) }
                ]
            ];
        } else {
            if (_isIssued) {
                groupRows = [
                    // temporarily removed
                    // [{ id: DocumentDraftEntity.Labels }],
                    [{ id: createPath(DocumentDraftEntity.CbaCategory, DocumentCbaCategoryDraftEntity.Category) }],
                    [{ id: createPath(DocumentDraftEntity.VatClassificationSelection, DocumentItemVatClassificationSelectionDraftEntity.VatClassification) }]
                ];
            } else {
                groupRows = [
                    [{ id: DateReceivedTypePath }, { id: DocumentDraftEntity.DateAccountingTransaction }],
                    // temporarily removed
                    // [{ id: DocumentDraftEntity.Labels }],
                    [
                        { id: createPath(DocumentDraftEntity.CbaCategory, DocumentCbaCategoryDraftEntity.Category) }
                    ],
                    [
                        { id: createPath(DocumentDraftEntity.CbaCategory, DocumentCbaCategoryDraftEntity.TaxImpact) }
                    ],
                    [
                        { id: createPath(DocumentDraftEntity.CbaCategory, DocumentCbaCategoryDraftEntity.IsAssetAcquisition) },
                        { id: createPath(DocumentDraftEntity.CbaCategory, DocumentCbaCategoryDraftEntity.TaxPercentage) }
                    ],
                    [
                        { id: createPath(DocumentDraftEntity.VatClassificationSelection, DocumentItemVatClassificationSelectionDraftEntity.VatClassification) }
                    ]
                ];
            }
        }

        if (!hasEverBeenVatRegisteredCompany(context) || docType === DocumentTypeCode.InternalDocument) {
            // filter out VatClassification field
            groupRows = groupRows.map(row => row.filter(field => field.id !== createPath(DocumentDraftEntity.VatClassificationSelection, DocumentItemVatClassificationSelectionDraftEntity.VatClassification)));
            groupRows = groupRows.filter(v => v.length > 0);
        }

        const sharedDateDef: TFieldDefinition = {
            type: FieldType.ComboBox,
            isRequired: true,
            width: BasicInputSizes.M,
            fieldSettings: {
                items: [
                    {
                        label: i18next.t("Components:DraftMassAccountingDialog.Today"),
                        id: TODAY
                    },
                    {
                        label: i18next.t("Components:DraftMassAccountingDialog.DateTypeDateIssued"),
                        id: MultiplePostingDateAccountingTypeCode.IssuedDate
                    },
                    {
                        label: i18next.t("Components:DraftMassAccountingDialog.Own"),
                        id: MultiplePostingDateAccountingTypeCode.ExplicitDate
                    }
                ]
            },
            defaultValue: TODAY,
            affectedFields: [{ id: DocumentDraftEntity.DateAccountingTransaction }]
        };

        const prefix = "VatClassificationSelection/VatClassification/";
        const vatFieldsDef = getVatFieldsDef(true, payableReceivableTypeCode, prefix);

        for (const fieldDef of Object.values(vatFieldsDef)) {
            // todo this is also temporary fix,
            // because apparently we want the fields to be editable
            // => meaning the behavior has to be a bit different from document form and
            // all the callbacks that are used in VatRules.utils will have to be modified somehow to support this case as well

            fieldDef.isVisible = (args: IGetValueArgs): boolean => {
                const value = args.storage.getValue(args.bindingContext);

                // special condition for reverse charge select field
                if (args.bindingContext.getPath() === VAT_REVERSE_CHARGE_SELECT_PATH) {
                    const val = args.storage.getValue(args.storage.data.bindingContext.navigate(`${prefix}${VatClassificationEntity.VatReverseChargeCode}`));

                    return !!val && val !== VatReverseChargeCode.Ne;
                }
                // for other values,
                // set isVisible to true when value exists
                // WILL HAVE TO BE CHANGED once the fields are made editable
                return !!value;
            };
            // all of the fields should always be disabled
            // fieldDef.isDisabled = true;
            fieldDef.isReadOnly = true;
        }

        // TODO this is a temporary fix,
        // once we remove isReadOnly from the vat fields, we should only show relevant items in the dropdown..
        // problem is all the vat field callbacks expects document entity structure
        // and things like BusinessPartner present, to be able to tell whether to show VATRegistered related fields etc...
        vatFieldsDef[`${prefix}${VatClassificationEntity.VatStatementSection}`].fieldSettings.itemsForRender = (items) => {
            return items;
        };
        vatFieldsDef[`${prefix}${VatClassificationEntity.VatControlStatementSection}`].fieldSettings.itemsForRender = (items) => {
            return items;
        };

        const accountAssignmentFieldsDef = getAccountSelectionFieldsDef(docType, {
            skipNoneItem: true
        });
        const accAssFieldDef = accountAssignmentFieldsDef["AccountAssignmentSelection/AccountAssignment"];

        accountAssignmentFieldsDef["AccountAssignmentSelection/AccountAssignment"] = {
            ...accAssFieldDef,
            isRequired: true,
            width: BasicInputSizes.XL,
            isVisible: not(isCashBasis),
            defaultValue: null
        };

        const form: IFormDef = {
            id: "DraftMassAccountingDialogSpecialForm",
            translationFiles: getDefinition.translationFiles,
            fieldDefinition: {
                [DateAccountingTypePath]: {
                    ...sharedDateDef,
                    label: i18next.t("Components:DraftMassAccountingDialog.DateAccounting"),
                    isVisible: not(isCashBasis)
                },
                [DateReceivedTypePath]: {
                    ...sharedDateDef,
                    label: i18next.t("Components:DraftMassAccountingDialog.DateReceived"),
                    isVisible: ifAll(isCashBasis, isReceivedCallback)
                },
                // use DateAccountTransaction as the main date field,
                // it is used internally by some of the account assignment util functions,
                // and it's just easier to work with it
                [DocumentDraftEntity.DateAccountingTransaction]: {
                    label: i18next.t("Components:DraftMassAccountingDialog.DateTypeDate"),
                    type: FieldType.Date,
                    valueType: ValueType.Date,
                    validator: {
                        type: ValidatorType.Custom,
                        settings: {
                            customValidator: (value: TValue, args: IGetValueArgs) => {
                                return !isCashBasis(args) ? dateAccountingCustomValidator(value, args) : validateDecisiveDate(value, args);
                            }
                        }
                    },
                    isRequired: true,
                    width: BasicInputSizes.M,
                    defaultValue: () => getUtcDate(),
                    isVisible: (args: IGetValueArgs): boolean => {
                        return (args.storage.getValueByPath(DateAccountingTypePath) === MultiplePostingDateAccountingTypeCode.ExplicitDate)
                            || args.storage.getValueByPath(DateReceivedTypePath) === MultiplePostingDateAccountingTypeCode.ExplicitDate;
                    }
                },
                ...accountAssignmentFieldsDef,
                ...vatFieldsDef,
                [DocumentDraftEntity.Labels]: {
                    ...getLabelsDef("Document")
                },
                [createPath(DocumentDraftEntity.VatClassificationSelection, DocumentItemVatClassificationSelectionDraftEntity.VatClassification)]: {
                    label: i18next.t("Components:DraftMassAccountingDialog.DefaultVatRule"),
                    type: FieldType.ComboBox,
                    isRequired: true,
                    width: BasicInputSizes.XL,
                    fieldSettings: {
                        entitySet: EntitySetName.VatClassifications,
                        displayName: "Name",
                        shouldDisplayAdditionalColumns: true,
                        noRecordText: i18next.t("Common:Select.NoRecord"),
                        showTabularHeader: false,
                        additionalItems: [
                            {
                                id: NoVatItem,
                                label: i18next.t("Document:Vat.None"),
                                groupId: SelectGroups.Default
                            }
                        ],
                        localDependentFields: getVatDependentFields(NavigationSource.Root),
                        // merge system items that differs only in the VatStatus
                        transformFetchedItems: (items: ISelectItem[]) => {
                            const propertiesToCheck = [
                                VatClassificationEntity.PayableReceivableTypeCode, VatClassificationEntity.CountryClassificationCode,
                                VatClassificationEntity.VatStatementSectionCode, VatClassificationEntity.VatControlStatementSectionCode,
                                VatClassificationEntity.Name
                            ];
                            const fnIsSameSystemItem = (item1: ISelectItem, item2: ISelectItem): boolean => {
                                for (const prop of propertiesToCheck) {
                                    if (item1.additionalData[prop] !== item2.additionalData[prop]) {
                                        return false;
                                    }
                                }

                                return true;
                            };

                            for (let i = 0; i < items.length; i++) {
                                const item = items[i];
                                const isSystemItem = item.additionalData.Id < 0;

                                if (isSystemItem) {
                                    for (let j = i + 1; j < items.length; j++) {
                                        const nextItem = items[j];

                                        if (fnIsSameSystemItem(item, nextItem)) {
                                            item.tabularData[1] = DASH_CHARACTER;
                                            item.additionalData[VatClassificationEntity.PayableReceivableType] = null;
                                            item.additionalData[VatClassificationEntity.PayableReceivableTypeCode] = null;
                                            items.splice(j, 1);
                                            j--;
                                        }
                                    }
                                }
                            }

                            // sort system items to the start
                            items.sort((a, b) => {
                                if (a.additionalData.Id < 0) {
                                    return -1;
                                } else if (b.additionalData.Id < 0) {
                                    return 1;
                                }

                                return 0;
                            });

                            return items;
                        }
                    },
                    isVisible: (args: IGetValueArgs): boolean => {
                        const docTypeCode = args.storage.data.entity[DocumentDraftEntity.DocumentTypeCode] as DocumentTypeCode;

                        return docTypeCode !== DocumentTypeCode.InternalDocument && hasEverBeenVatRegisteredCompany(args.storage.context);
                    },
                    defaultValue: null,
                    filter: {
                        select: (args: IGetValueArgs): string => {
                            return `${VatClassificationEntity.PayableReceivableTypeCode} eq '${payableReceivableTypeCode}'`;
                        }
                    },
                    columns: [
                        {
                            id: VatClassificationEntity.Name
                        },
                        {
                            id: VatClassificationEntity.IsVatRegistered,
                            formatter: (val: TValue, args?: IFormatOptions): string => {
                                return i18next.t(`Components:DraftMassAccountingDialog.${val ? "VatRegistered" : "NotVatRegistered"}`);
                            }
                        },
                        {
                            id: VatClassificationEntity.CountryClassification,
                            fieldSettings: {
                                displayName: "Name"
                            },
                            // take everything, just to be sure...
                            additionalProperties: Object.values(DocumentItemVatClassificationEntity)
                                .map(prop => ({ id: `/${prop}` }))
                        }
                    ],
                    collapsedRows: getVatGroupRowsDef({
                        prefix: "VatClassificationSelection/VatClassification/",
                        addNonTaxAccount: !_isIssued
                    })
                },
                [createPath(DocumentDraftEntity.CbaCategory, DocumentCbaCategoryDraftEntity.Category)]: {
                    ...cbaFieldsDef[createPath(DocumentDraftEntity.CbaCategory, DocumentCbaCategoryDraftEntity.Category)],
                    width: BasicInputSizes.XL,
                    isVisible: isCashBasis
                },
                [createPath(DocumentDraftEntity.CbaCategory, DocumentCbaCategoryDraftEntity.TaxImpact)]: {
                    ...cbaFieldsDef[createPath(DocumentDraftEntity.CbaCategory, DocumentCbaCategoryDraftEntity.TaxImpact)],
                    label: i18next.t("Components:DraftMassAccountingDialog.TaxImpact"),
                    width: BasicInputSizes.XL,
                    isVisible: isCashBasis,
                    affectedFields: [
                        { id: createPath(DocumentDraftEntity.CbaCategory, DocumentCbaCategoryDraftEntity.IsAssetAcquisition) }
                    ]
                },
                [createPath(DocumentDraftEntity.CbaCategory, DocumentCbaCategoryDraftEntity.IsAssetAcquisition)]: {
                    ...cbaFieldsDef[createPath(DocumentDraftEntity.CbaCategory, DocumentCbaCategoryDraftEntity.IsAssetAcquisition)],
                    isVisible: (args: IGetValueArgs) => {
                        const taxImpact = args.storage.getValueByPath(createPath(DocumentDraftEntity.CbaCategory, DocumentCbaCategoryDraftEntity.TaxImpact)) as ICbaCategoryTaxImpactEntity;

                        return isCashBasis(args) && taxImpact?.Code === CbaCategoryTaxImpactCode.Nontax;
                    }
                },
                [createPath(DocumentDraftEntity.CbaCategory, DocumentCbaCategoryDraftEntity.TaxPercentage)]: {
                    ...cbaFieldsDef[createPath(DocumentDraftEntity.CbaCategory, DocumentCbaCategoryDraftEntity.TaxPercentage)],
                    isVisible: (args: IGetValueArgs) => {
                        const taxImpact = args.storage.getValueByPath(createPath(DocumentDraftEntity.CbaCategory, DocumentCbaCategoryDraftEntity.TaxImpact)) as ICbaCategoryTaxImpactEntity;

                        return isCashBasis(args) && taxImpact?.Code === CbaCategoryTaxImpactCode.Partial;
                    }
                }
            },
            groups: [
                {
                    id: "Common",
                    rows: groupRows
                }
            ]
        };


        return {
            form
        };
    };

    getDefinition.translationFiles = ["Components", "Common", "AccountAssignment", "Document"];

    return getDefinition;
};