import React, { useCallback, useState } from "react";
import { ClickableStyled, TextExpandableStyled } from "./TextExpandable.styles";
import { useTranslation } from "react-i18next";

interface IProps {
    maxLength?: number;
    text: string;
    onMouseEnter?: (event: React.MouseEvent) => void;
    onMouseLeave?: (event: React.MouseEvent) => void;
}

const TextExpandable = React.forwardRef<HTMLDivElement, IProps>(({ maxLength, text, ...pass }, ref) => {
    const { t } = useTranslation("Components");
    const [isExpanded, expand] = useState<boolean>(false);
    const ellipsis = "...";
    const isExpandable = text.length > maxLength;

    const isShortened = isExpandable && !isExpanded;
    const renderedText = isShortened ? `${text.slice(0, maxLength)}${ellipsis}` : text;

    const handleToggle = useCallback(() => {
        expand(!isExpanded);
    }, [expand, isExpanded]);

    return (
        <TextExpandableStyled ref={ref} {...pass}>
            {renderedText}
            {isExpandable &&
                <ClickableStyled onClick={handleToggle}>
                    {t(`Components:TextExpandable.${isExpanded ? "HideText" : "ShowWholeText"}`)}
                </ClickableStyled>
            }
        </TextExpandableStyled>
    );
});

export default TextExpandable;