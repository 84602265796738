import { Chart as ChartJS, LegendOptions, Tick, TooltipOptions } from "chart.js";
import { ScaleChartOptions } from "chart.js/dist/types";
import { transparentize } from "polished";

import { DeepPartial } from "../../global.types";
import { Theme } from "../../theme";

/**
 * Set ChartJS default theme
 * todo: create chart provider to setup charts globally instead of calling this in every chart component
 * @param theme
 */
export function setChartTheme(theme: Theme): void {
    ChartJS.defaults.font = {
        ...ChartJS.defaults.font,
        size: 8,
        family: "Lato"
    };

    ChartJS.defaults.color = theme["C_TEXT_primary"];
}

export enum ChartType {
    Line = "line",
    Bar = "bar",
    Doughnut = "doughnut"
}

export type TChartValueFormatterFn = (axis: "x" | "y" | null, value: number | string, idx?: number) => string;

export interface IChartProps<T> {
    className?: string;
    width?: number;
    height?: number;
    responsive?: boolean;
    data: T;
    formatter?: TChartValueFormatterFn;
}

export interface IChartComponentData<T = number> {
    colors: (keyof Theme)[];
    labels: string[];
    values: T[];
}

/**
 * Basic configuration for ChartJS Legend object
 */
export const legend: DeepPartial<LegendOptions<ChartType>> = {
    position: "bottom" as const,
    labels: {
        pointStyle: "rectRounded",
        usePointStyle: true
    }
};
export const legendHeight = 28;

export const getChartTooltipOptions = <TType extends ChartType = ChartType>(formatter: TChartValueFormatterFn): DeepPartial<TooltipOptions<TType>> => ({
    cornerRadius: 3,
    usePointStyle: true,
    boxPadding: 4,
    callbacks: {
        title() {
            return null;
        },
        label(item) {
            return formatter(null, item.raw as number);
        }
    }
});

export const getDefaultScalesOptions = (theme: Theme, tickFormatter?: (value: number | string, index: number, ticks: Tick[]) => string): DeepPartial<ScaleChartOptions<ChartType.Bar>["scales"]> => ({
    x: {
        grid: {
            display: false
        },
        border: {
            display: false
        }
    },
    y: {
        grid: {
            color: transparentize(0.5, theme["C_ACT_thick_line"]),
            tickBorderDash: [2, 3]
        },
        border: {
            display: false,
            dash: [2, 3]
        },
        ticks: tickFormatter ? {
            callback: tickFormatter
        } : {}
    }
});