import React from "react";
import { isDefined } from "@utils/general";
import { ScrollBar } from "../scrollBar";
import {
    Column,
    ColumnHeader,
    ColumnHeaderRow,
    Grid,
    StyledImportSettings,
    ValuePreview
} from "./ImportSettings.styles";
import { withTheme } from "styled-components/macro";
import { PropsWithTheme } from "../../theme";
import ImportSettingsDragItem from "./ImportSettingsDragItem";
import { IImportSettingsItemProps } from "./ImportSettingsDropItem";
import TestIds from "../../testIds";

export const DragTransferId = "itemId";

export type TImportSettingsMappedColumnsArgs = Pick<IImportSettingsItemProps, "onDragEnter" | "onDragLeave" | "onDrop" | "onRemove">;

interface IProps {
    mappedColumns: (args: TImportSettingsMappedColumnsArgs) => React.ReactElement | React.ReactElement[];
    availableColumns: { id: string; value: string; }[];

    onChange: (mappedColumnId: string, availableColumnId: string) => void;
    onRemove: (mappedColumnId: string) => void;

    previewValues?: string[];
    onPreviewValuesNeeded?: (availableColumnId: string) => void;

    // if any group item is used, this will add left-padding to the first column
    isGroupUsed?: boolean;

    firstColumnHeader: string;
    secondColumnHeader: string;
    thirdColumnHeader: string;
    fourthColumnHeader?: string;
    style?: React.CSSProperties;
}

interface IState {
    selectedPreview: string;
}

interface IExtendedProps extends IProps, PropsWithTheme {

}

class ImportSettings extends React.PureComponent<IExtendedProps, IState> {
    static defaultProps: Partial<IProps> = {
        previewValues: []
    };

    state: IState = {
        selectedPreview: null
    };

    firstColumnHeaderRef = React.createRef<HTMLDivElement>();
    gridRef = React.createRef<HTMLDivElement>();

    componentDidMount() {
        // update the width of the first column header
        // to width of the widest grid first column item
        let max = 0;

        for (let i = 0; i < Array.from(this.gridRef.current.children).length; i++) {
            if (i % 3 === 0) {
                const child = this.gridRef.current.childNodes.item(i) as HTMLDivElement;

                max = Math.max(max, child.clientWidth);
            }
        }
        this.firstColumnHeaderRef.current.style.width = `${max}px`;
        this.firstColumnHeaderRef.current.style.marginRight = `15px`;

        if (this.props.isGroupUsed) {
            this.firstColumnHeaderRef.current.style.paddingLeft = "20px";
        }
    }

    handleRemove = (id: string) => {
        this.props.onRemove(id);
    };


    handleDrop = (event: React.DragEvent<HTMLDivElement>, id: string, value: string) => {
        this.props.onChange(id, value);
    };

    handleDragEnter = (event: React.DragEvent<HTMLDivElement>, id: string) => {
        (event.target as HTMLDivElement).setAttribute("style",
            `background-color: ${this.props.theme.C_FIELD_line_der}; border: 1px dashed ${this.props.theme.C_BTN_border_hover};`);
        event.stopPropagation();
    };

    handleDragLeave = (event: React.DragEvent<HTMLDivElement>, id: string) => {
        (event.target as HTMLDivElement).setAttribute("style", "");
        event.stopPropagation();
    };

    handleDragItemClick = (id: string): void => {
        this.props.onPreviewValuesNeeded?.(id);
        this.setState({ selectedPreview: id });

    };

    getMappedColumns = () => {
        return this.props.mappedColumns({
            onDragEnter: this.handleDragEnter,
            onDragLeave: this.handleDragLeave,
            onDrop: this.handleDrop,
            onRemove: this.handleRemove
        });
    };

    render() {
        return (
            <StyledImportSettings data-testid={TestIds.ImportSettings} style={this.props.style}>
                <Column data-testid={TestIds.ImportSettingsColumn}>
                    <ColumnHeaderRow>
                        {/*headers has to be outside of grid, so that only content has scrollbar*/}
                        {/*==> we need to update first column header width after render*/}
                        <ColumnHeader ref={this.firstColumnHeaderRef}
                                      data-testid={TestIds.ImportSettingsColumnHeader}>{this.props.firstColumnHeader}</ColumnHeader>
                        <ColumnHeader
                            data-testid={TestIds.ImportSettingsColumnHeader}>{this.props.secondColumnHeader}</ColumnHeader>
                    </ColumnHeaderRow>
                    {/*todo many random positioning problems with SimpleBar -_-*/}
                    {/*UX won't like overflow: "auto" on Grid*/}
                    {/*<ScrollBar>*/}
                    <Grid ref={this.gridRef} style={{ overflow: "auto" }} data-testid={TestIds.ImportSettingsGrid}>
                        {this.getMappedColumns()}
                    </Grid>
                    {/*</ScrollBar>*/}
                </Column>

                <Column withBorder={true} data-testid={TestIds.ImportSettingsColumn}>
                    <ColumnHeader data-testid={TestIds.ImportSettingsColumnHeader}>{this.props.thirdColumnHeader}</ColumnHeader>
                    <ScrollBar style={{ paddingRight: "25px" }}>
                        {this.props.availableColumns.map((column, index) => {
                            return (
                                <ImportSettingsDragItem
                                    id={column.id}
                                    key={index}
                                    value={column.value}
                                    isSelected={column.id === this.state.selectedPreview}
                                    onClick={this.handleDragItemClick}
                                />
                            );
                        })}
                    </ScrollBar>
                </Column>
                {isDefined(this.state.selectedPreview) && this.props.previewValues &&
                    <Column data-testid={TestIds.ImportSettingsColumn}>
                        <ColumnHeader data-testid={TestIds.ImportSettingsColumnHeader}>{this.props.fourthColumnHeader}</ColumnHeader>
                        <ScrollBar>
                            {this.props.previewValues.map((value, index) => {
                                return value ? <ValuePreview key={index} data-testid={TestIds.ImportSettingsValuePreview}>{value}</ValuePreview> : null;
                            })}
                        </ScrollBar>
                    </Column>
                }
            </StyledImportSettings>
        );
    }
}

export type { IProps as IImportSettingsProps };
export default withTheme(ImportSettings);