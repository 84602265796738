import styled from "styled-components/macro";
import { T_PLAIN_big } from "../../global.style";

export const StyledItemsList = styled.div`
    display: grid;
    grid-template-columns: max-content auto;
    column-gap: 20px;
    row-gap: 10px;
`;

export const SimpleItem = styled.div`
    display: flex;
    align-items: center;
    ${T_PLAIN_big}
`;

export const CloseIconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: -1px;
`;