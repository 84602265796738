import { isDateTimeType, isDateType } from "@evala/odata-metadata/src";
import { IFilterDef } from "../smartFilterBar/SmartFilterBar.types";
import { formatValue, isEmptyValue } from "@odata/OData.utils";
import { TableStorage } from "../../../model/TableStorage";
import { getInfoValue } from "../FieldInfo";
import BindingContext, { IEntity } from "../../../odata/BindingContext";
import { getCachedFilter, IChangedFilter, isDraftView } from "../../../views/table/TableView.utils";
import { EntitySetWrapper, ODataQueryBuilder } from "@odata/OData";
import { TRecordAny, TValue } from "../../../global.types";
import { formatDateToDateString } from "../../inputs/date/utils";
import { getBoundValue } from "@odata/Data.utils";
import { formatPercent, isNotDefined } from "@utils/general";
import { EMPTY_VALUE } from "../../../constants";
import { IOneFetch } from "@utils/oneFetch";
import { IAppContext } from "../../../contexts/appContext/AppContext.types";
import { IFieldInfo } from "@odata/FieldInfo.utils";
import { TSmartODataTableStorage } from "../smartTable/SmartODataTableBase";
import { ISelectItem } from "@components/inputs/select/Select.types";
import i18next from "i18next";

export function isValueHelperField(info: IFilterDef | IFieldInfo, storage: TableStorage): boolean {
    // todo: refactor this
    const group = storage.getFilterFieldGroup?.(info.id);
    return (info as IFilterDef).isValueHelp ?? getInfoValue(group, "isValueHelp", { info });
}

export interface IFetchValueHelperItemsOptions {
    context?: IAppContext;
    storage: TableStorage;
    bindingContext: BindingContext;
    fieldInfo: IFieldInfo;
    isEnum: boolean;
}

export const getValueHelpFilterGroupByProps = (bc: BindingContext, info: IFieldInfo): string[] => {
    const props: string[] = [];
    // Check if property has referenced currency property - add to group, so we don't group different currencies
    const currency = bc.getProperty()?.getCurrency();


    if (currency) {
        props.push(`${currency}${BindingContext.ENUM_KEY_PROP}`);
    }

    if (info?.filter?.groupByProperties) {
        props.push(...info.filter.groupByProperties);
    }

    return props;
};

export const createCompositeFilterId = (entity: IEntity, groupByProps: string[]): string => {
    return JSON.stringify(entity);
};

export const parseCompositeFilterId = (id: string): IEntity => {
    return JSON.parse(id);
};

export const filterPercentFormatter = (val: TValue): string => isEmptyValue(val) ? i18next.t("Common:General.Empty").toString() : formatPercent(val);

export async function fetchValueHelperData(opts: IFetchValueHelperItemsOptions, oneFetch: IOneFetch): Promise<IEntity[]> {
    const { bindingContext: bc, fieldInfo: info, storage, isEnum } = opts;
    const isDateFilter = isDateType(bc.getProperty()),
        displayName = info?.fieldSettings?.displayName,
        isLocal = bc.isLocal(),
        bindingContext = isLocal ? bc :
            (isEnum ? bc.navigate(BindingContext.ENUM_KEY_PROP) : bc.getNavigationBindingContext(displayName)),
        dataBindingContext = storage.data.bindingContext,
        currentFilterPath = bc.getFullPath(),
        filters = storage.getChangedFilters(),
        applicableFilters = filters.changedFields.filter((info: IChangedFilter) => info.bindingContext.getFullPath() !== currentFilterPath);

    const filterQuery = storage.createFilterQuery(applicableFilters);

    if (filterQuery?.isInvalid) {
        // todo: if filter query is not valid, should we keep the previous value or set empty items?
        return [];
    }

    const isDraft = isDraftView(storage as TSmartODataTableStorage);

    const entitySetWrapper: EntitySetWrapper = storage.oData.fromPath(!isDraft ? dataBindingContext.toString() : storage.data.definition.draftDef.draftEntitySet);
    const query = entitySetWrapper.query() as ODataQueryBuilder;

    if (filterQuery) {
        query.filter(!isDraft ? getCachedFilter(filterQuery, storage.data.customSecondaryFilter).query : filterQuery.query);
    }

    const groupByProperties: string[] = [
        ...getValueHelpFilterGroupByProps(bindingContext, info)
    ];

    if (!isLocal) {
        let propName = info.id;
        if (displayName) {
            propName += `/${displayName}`;
        }
        groupByProperties.push(propName);
    }

    const property = bindingContext.getProperty();

    if (!isLocal && isEnum && BindingContext.ENUM_KEY_PROP !== displayName) {
        groupByProperties.push(`${info.id}/${BindingContext.ENUM_KEY_PROP}`);
    }

    if (info.additionalProperties) {
        groupByProperties.push(...info.additionalProperties?.map(prop => `${info.id}/${prop.id}`));
    }

    query.groupBy(...groupByProperties);
    groupByProperties.forEach((propName, index) => {
        if (index < 5) {
            // BE limits order by to 5 different props maximum,
            // probably doesn't matter, but change if needed
            query.orderBy(propName, !isDateFilter);
        }
    });

    const result = await query.fetchData<IEntity[]>(oneFetch.fetch);
    const data = result?.value;

    if (isDateTimeType(property)) {
        const uniqData: TRecordAny = {};
        for (const item of data) {
            uniqData[formatDateToDateString(item[info.id])] = item;
        }

        return Object.keys(uniqData)?.map(key => uniqData[key]);
    }

    return data ?? [];
}

export function createSelectItem(entity: IEntity, opts: IFetchValueHelperItemsOptions, dataBindingContext?: BindingContext): ISelectItem {
    const { fieldInfo, storage, bindingContext, isEnum } = opts;
    const isDateFilter = isDateType(bindingContext.getProperty());
    const displayName = fieldInfo.fieldSettings?.displayName;

    const rootBc = dataBindingContext ?? storage.data.bindingContext;

    const bc = isEnum ? bindingContext : bindingContext.getNavigationBindingContext(displayName);
    const value = getBoundValue({
        bindingContext: bc,
        data: entity,
        dataBindingContext: rootBc
    });
    let id = isEnum ? value?.[BindingContext.ENUM_KEY_PROP] : value;

    const groupByProps = getValueHelpFilterGroupByProps(bindingContext, fieldInfo);

    if (groupByProps?.length > 0) {
        id = createCompositeFilterId(entity, groupByProps);
    }

    if (isNotDefined(id)) {
        id = EMPTY_VALUE;
    } else if (isDateFilter) {
        // uses Dates as strings, so we can compare ids easily
        id = id.toString();
    }

    return {
        id,
        label: (isEnum && !fieldInfo.formatter) ? value?.[displayName] ?? value?.Name ?? storage.t("Common:General.Empty") : formatValue(value, fieldInfo, {
            entity,
            readonly: true,
            placeholder: storage.t("Common:General.Empty"),
            context: opts.context ?? opts?.storage?.context
        }),
        additionalData: entity
    };
}