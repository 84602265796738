import React, { useCallback, useRef } from "react";
import TestIds from "../../testIds";
import {
    CaretColoredIcon,
    CenteredLayout,
    FolderColoredIcon,
    LinearLayout,
    ShortcutIconWrapper,
    ShortcutText
} from "./FileStrip.styles";
import Tooltip from "../tooltip";
import { CaretIcon, ColoredIcon, getIcon, InboxFolderIcon } from "../icon";
import {
    FileStripItemSize,
    getBadgeOffsetRightByFileStripItemSize,
    getBadgeOffsetTopByFileStripItemSize,
    getFileStripItemConfig,
    IFileStripItem
} from "./FileStrip.utils";
import { composeRefHandlers, doesElementContainsElement } from "@utils/general";
import { KeyName } from "../../keyName";
import Badge from "../badge";

interface IProps extends IFileStripItem {
    isSelected?: boolean;
    isHighlighted?: boolean;
    onSelect?: (id: string) => void;
    onMouseEnter?: (id: string) => void;
    onMouseLeave?: (id: string) => void;
    isDragging?: boolean;
    isDragOver?: boolean;
    onDragEnter?: (id: string) => void;
    onDragLeave?: (id: string) => void;
    onDrop?: (id: string, event: React.DragEvent) => void;
    size?: FileStripItemSize;
    passProps?: Record<string, any>;
}

export default function FileStripItem(props: IProps): React.ReactElement {
    const {
        id,
        size,
        iconName,
        onSelect,
        isDragging,
        onDrop,
        onDragEnter,
        onDragLeave,
        onMouseEnter,
        onMouseLeave
    } = props;

    const layoutRef = useRef<HTMLDivElement>();

    const handleMouseEnter = useCallback((): void => {
        onMouseEnter?.(id);
    }, [onMouseEnter, id]);

    const handleMouseLeave = useCallback((event: React.MouseEvent): void => {
        if (!doesElementContainsElement(layoutRef.current, event.relatedTarget as Element)) {
            onMouseLeave?.(id);
        }
    }, [onMouseLeave, id]);

    const handleDragLeave = useCallback((event: React.DragEvent): void => {
        if (!doesElementContainsElement(layoutRef.current, event.relatedTarget as Element)) {
            onDragLeave?.(id);
        }
    }, [onDragLeave, id]);

    const handleDragEnter = useCallback((): void => {
        onDragEnter?.(id);
    }, [onDragEnter, id]);

    const handleClick = useCallback((): void => {
        onSelect?.(id);
    }, [onSelect, id]);

    const handleKeyDown = useCallback((event: React.KeyboardEvent): void => {
        if (event.key === KeyName.Enter || event.key === KeyName.Space) {
            handleClick();
        }
    }, [onSelect, id]);

    const handleDrop = useCallback((event: React.DragEvent): void => {
        onDrop?.(id, event);
    }, [id, onDrop]);

    const handleDragOver = useCallback((event: React.DragEvent): void => {
        if (isDragging) {
            event.preventDefault();
        }
    }, [isDragging]);

    const cfg = getFileStripItemConfig(size);
    const folderIconSize = cfg.iconSize ?? cfg.height;
    const folderIconSizeStr = `${folderIconSize}px`;
    const caretIconSize = `${cfg.caretIconSize}px`;
    const shortcutIconSize = `${cfg.shortcutIconSize ?? cfg.height}px`;
    const Icon = iconName && getIcon(iconName);

    const Layout = props.size === FileStripItemSize.S ? LinearLayout : CenteredLayout;

    return (
            <Tooltip content={props.label}>
                {(ref: React.Ref<HTMLDivElement>) => {
                    return (
                            <Layout ref={composeRefHandlers(ref, layoutRef)}
                                    tabIndex={0}
                                    _folderIconSize={folderIconSize}
                                    isSelected={!props.isDragOver && props.isSelected}
                                    isHighlighted={props.isDragOver || props.isHighlighted}
                                    {...cfg}
                                    size={props.size}
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}
                                    onDragEnter={handleDragEnter}
                                    onDragLeave={handleDragLeave}
                                    onDragOver={handleDragOver}
                                    onDrop={handleDrop}
                                    onClick={handleClick}
                                    onKeyDown={handleKeyDown}
                                    data-testid={TestIds.FileStripItem}
                                    {...props.passProps}>
                                <FolderColoredIcon>
                                    <InboxFolderIcon width={folderIconSizeStr}
                                                     height={folderIconSizeStr}/>
                                </FolderColoredIcon>
                                {props.iconName
                                        ? (
                                                <ShortcutIconWrapper>
                                                    <ColoredIcon><Icon height={shortcutIconSize}/></ColoredIcon>
                                                </ShortcutIconWrapper>
                                        ) : <ShortcutText size={size}>{props.shortcut}</ShortcutText>}
                                <CaretColoredIcon isHighlighted={props.isHighlighted}
                                                  isSelected={props.isSelected}>
                                    <CaretIcon width={caretIconSize} height={caretIconSize}/>
                                </CaretColoredIcon>
                                {props.count &&
                                        <Badge count={props.count}
                                               isSmall={cfg.hasSmallBadge}
                                               offsetTop={getBadgeOffsetTopByFileStripItemSize(props.size)}
                                               offsetRight={getBadgeOffsetRightByFileStripItemSize(props.size)}
                                               max={props.size === FileStripItemSize.S ? 99 : 9999}/>
                                }
                            </Layout>
                    );
                }}
            </Tooltip>
    );
}
