import { REST_API_URL } from "../../constants";
import { isDefined } from "@utils/general";

export const HOTSPOTS_INTERACTION_URL = `${REST_API_URL}/HotspotInteraction`;
export const GENERAL_HOTSPOT_VIEW = "General";
export const HOTSPOT_ID_ATTR = "data-hotspotid";
export const HOTSPOT_VIEW_ATTR = "data-hotspotviewid";
// we are reusing same element id for two translations - one for title, one for the main text
export const HOTSPOT_TITLE_SUFFIX = "_title";

type THotspotCommand = "UpsertHotspotAndTranslations" | "DeleteHotspotTranslations" | "DeleteHotspot";

export interface IToolHotspotDefinition {
    Type: THotspotCommand,
    ViewId: string,
    ElementId: string,
    "Translations"?: { "Language": string, "Text": string }[]
}

export const buildHotspotId = (viewId: string, elementId: string): string => {
    return `${viewId};${elementId}`;
};

export const splitHotspotId = (hotspotId: string): { viewId: string, elementId: string } => {
    const [viewId, elementId] = hotspotId.split(";");

    return { viewId, elementId };
};

export const getClosestViewId = (element: HTMLElement): string => {
    let parent = element.parentElement;
    let viewId = "";

    // find first parent element with HOTSPOT_VIEW_ATTR or use GENERAL_HOTSPOT_VIEW
    while (true) {
        const viewAttr = parent.getAttribute(HOTSPOT_VIEW_ATTR);

        if (viewAttr) {
            viewId = viewAttr;
            break;
        } else if (parent === document.body) {
            viewId = GENERAL_HOTSPOT_VIEW;
            break;
        }

        parent = parent.parentElement;
    }

    return viewId;
};

const DUPLICATE_HOTSPOT_ID_REGEX = new RegExp("-(\\d+)");

export const addIndexToDuplicateHotspotID = (hotspotId: string, index: number): string => {
    return isDefined(index) && index > 0 ? `${hotspotId}-${index}` : hotspotId;
};

export const getCleanHotspotId = (hotspotId: string): string => {
    return hotspotId.replace(DUPLICATE_HOTSPOT_ID_REGEX, "");
};

export const isSameHotspotDefId = (hotspotId1: string, hotspotId2: string): boolean => {
    return getCleanHotspotId(hotspotId1) === getCleanHotspotId(hotspotId2);
};