import React from "react";
import DraftForm from "./DraftForm";
import { AppContext } from "../../contexts/appContext/AppContext.types";
import { FormStorage } from "../../views/formView/FormStorage";
import { createBindingContext } from "@odata/BindingContext";
import { NEW_ITEM_DETAIL } from "../../constants";
import { getDefinitions } from "./Draft.def";
import { DocumentTypeCode } from "@odata/GeneratedEnums";
import { DocumentFormViewForExtend } from "../documents/DocumentFormView";
import { cloneDeep } from "lodash";
import { IBankAccountEntity, IDocumentDraftEntity } from "@odata/GeneratedEntityTypes";
import { setSavedBankAccountItems } from "../banks/bankAccounts/BankAccounts.utils";
import { IRecurringTaskCustomData } from "./RecurringTask.utils";
import { hasSavedDraft } from "@pages/documents/Document.utils";

interface IProps {
    storage: FormStorage<any, IRecurringTaskCustomData>
}

const DraftFormStyles = { padding: 0 };

export default class DraftPreviewInsideForm extends React.Component<IProps> {
    static contextType = AppContext;
    formStorage: FormStorage<IDocumentDraftEntity, IRecurringTaskCustomData>;
    formViewRef = React.createRef<DocumentFormViewForExtend>();

    componentDidMount() {
        if (this.shouldInitStorage) {
            this.init();
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps>) {
        if (this.shouldInitStorage) {
            this.init();
        } else if (this.formStorage) {
            this.formStorage.setCustomData({ DateNextExecution: this.props.storage.data.entity.DateNextExecution });
            if (this.props.storage.getCustomData().copyDraftEntity) {
                this.formStorage.data.entity = cloneDeep(this.props.storage.data.entity?.DocumentDraft);
                this.props.storage.setCustomData({ copyDraftEntity: false });
            }
            if (this.props.storage.data.entity?.DocumentDraft?.DocumentTypeCode === DocumentTypeCode.InvoiceIssued) {
                const bankAccounts = this.formStorage.context.getCompanyBankAccounts(true);
                setSavedBankAccountItems(this.formStorage, bankAccounts as IBankAccountEntity[]);
            }
            this.formStorage.refresh();
        }
    }

    get shouldInitStorage(): boolean {
        return (this.props.storage.data.bindingContext.isNew() || hasSavedDraft(this.props.storage)) && this.formStorage === undefined;
    }

    onAfterDraftLoad = (draft: IDocumentDraftEntity): void => {
        this.props.storage.data.entity.DocumentDraft = draft;
    }

    init = (): void => {
        const draftId = this.props.storage.data.entity?.DocumentDraft?.Id;
        const bindingContext = createBindingContext("DocumentDrafts", this.props.storage.oData.metadata)
            .addKey(draftId ?? NEW_ITEM_DETAIL, !draftId);

        this.formStorage = new FormStorage({
            id: "recurringTaskDraft",
            oData: this.props.storage.oData,
            theme: this.props.storage.theme,
            context: this.context,
            t: this.props.storage.t,
            definition: getDefinitions(this.context, this.props.storage.data.entity.TypeCode ?? DocumentTypeCode.InvoiceIssued, true).form,
            bindingContext,
            refresh: () => {
                this.formViewRef?.current?.forceUpdate();
            }
        });

        this.formStorage.setCustomData({ DateNextExecution: this.props.storage.data.entity.DateNextExecution });
        this.forceUpdate();
    }

    get draftData(): IDocumentDraftEntity {
        return this.props.storage.data.bindingContext.isNew() ? this.props.storage.data.entity.DocumentDraft : null;
    }

    render() {
        return <>
            {this.formStorage &&
                <DraftForm
                    passRef={this.formViewRef}
                    storage={this.formStorage}
                    draftData={this.draftData}
                    page={3}
                    style={DraftFormStyles}
                    onAfterDraftLoad={this.onAfterDraftLoad}
                    typeCode={this.props.storage.data.entity.TypeCode}
                />}
        </>;
    }
}