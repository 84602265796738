import { SwitchType } from "@components/inputs/switch/Switch";
import { IGetValueArgs } from "@components/smart/FieldInfo";
import { ISummaryItem } from "@components/smart/smartSummaryItem/SmartSummaryItem";
import { EntitySetName, EntityTypeName, LabelEntity, LabelHierarchyEntity } from "@odata/GeneratedEntityTypes";
import i18next from "i18next";

import { BasicInputSizes, FieldType } from "../../enums";
import { Model } from "../../model/Model";
import BindingContext from "../../odata/BindingContext";
import { IFormDef } from "../../views/formView/Form";
import { setDefByEntityType } from "../getDefByEntityType";
import { getItemBreadCrumbsText, IDefinition, IGetDefinition, TFieldDefinition } from "../PageUtils";
import LabelsFormView from "./LabelFormView";
import { labelColors } from "./labelsHierarchyDef";

// fake field just to show disabled color of the parent hierarchy
export const LabelColorPath = BindingContext.localContext("LabelColor");

export const isActiveDef: TFieldDefinition = {
    type: FieldType.Switch,
    fieldSettings: {
        type: SwitchType.Icons
    },
    defaultValue: true
};

export const getDefinitions: IGetDefinition = (): IDefinition => {

    const auditTrailSummary: ISummaryItem[] = [{
        id: LabelEntity.Name,
        label: i18next.t("Labels:Label")
    }];

    const summary: ISummaryItem[] = [];

    const form: IFormDef = {
        id: `${EntityTypeName.Label}Form`,
        summary,
        translationFiles: getDefinitions.translationFiles,
        formControl: LabelsFormView,
        isDeletable: true,
        auditTrailSummary,
        getItemBreadCrumbText: (storage: Model) =>
                getItemBreadCrumbsText(storage, i18next.t("Labels:New"),
                        storage.data.entity?.Name && i18next.t("Labels:Breadcrumbs.Label", { name: storage.data.entity?.Name })),
        title: "Labels:Title",
        fieldDefinition: {
            [LabelColorPath]: {
                label: i18next.t("Labels:Form.Color"),
                type: FieldType.ColorPicker,
                width: BasicInputSizes.XL,
                fieldSettings: {
                    colors: labelColors
                },
                isDisabled: true
            },
            Parent: {
                type: FieldType.HierarchyComboBox,
                fieldSettings: {
                    displayName: LabelEntity.Name,
                    items: []
                },
                additionalProperties: [{ id: LabelEntity.IsActive }]
            },
            Name: {
                type: FieldType.TokenInput,
                width: BasicInputSizes.XL
            },
            IsActive: isActiveDef

        },
        groups: [{
            id: "mainGroup",
            rows: [
                [{ id: LabelEntity.Parent }, { id: LabelColorPath }],
                [{ id: LabelEntity.Name }, { id: LabelEntity.IsActive }]
            ]
        }]
    };

    const getEntitySet = (args: IGetValueArgs) => {
        const parentId = args.data?.parentId;
        return `${EntitySetName.LabelHierarchies}(${parentId})/${LabelHierarchyEntity.Labels}`;
    };

    return {
        entitySet: getEntitySet,
        form
    };
};

getDefinitions.translationFiles = ["Common", "Labels"];
setDefByEntityType(EntityTypeName.Label, getDefinitions);