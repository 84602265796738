import styled from "styled-components/macro";

export const ResizerWrapper = styled.div`
    width: 100%;
    flex: 1 1 auto;
    position: relative;
    overflow: hidden;
`;

export const Scroller = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    overflow: auto;
    position: relative;
    justify-content: center;
`;

interface IPdfWrapper {
    isDraggable?: boolean;
}

export const PdfWrapper = styled.div<IPdfWrapper>`
    // apparently, pdf should not be centered https://solitea-cz.atlassian.net/browse/DEV-10176 
    //margin: auto;
    outline: none;
    cursor: ${props => props.isDraggable ? "grab" : "default"};
`;

export const Header = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`;

