import { Layout } from "react-grid-layout";
import { TFunctionResult } from "i18next";
import { Theme } from "../../theme";
import React from "react";
import { OData } from "@odata/OData";
import { Status } from "../../enums";
import { LinkProps } from "react-router-dom";
import { IColumn, IRow } from "../table";
import { IAppContext } from "../../contexts/appContext/AppContext.types";
import { TInfoValue } from "../smart/FieldInfo";
import { IChartTileData } from "../tiles/chartTile/ChartTile";
import { TChartValueFormatterFn } from "../charts/Charts.types";


const GridTileBaseSize = 125;
export const GridGap = 32;
export const GridTileSize = GridTileBaseSize + GridGap;

export type TLayout = Layout;

export type TDashboardTileConfig = Pick<TLayout, "i" | "w" | "h">;
export type TDashboardLayout = TLayout[];

/**
 * TileTypes:
 *  - Custom - complete custom render with component
 *  - Info - text content with simple information, one or more cells with severity indicator
 *  - Table - renders table according to definition
 */
export enum DashboardTileType {
    Info = "Info",
    Custom = "Custom",
    Table = "Table",
    Link = "Link",
    Chart = "Chart"
}

export interface IDashboardLayoutConfig {
    cols?: number;
    layout?: TDashboardLayout;
}

interface ITileSize {
    w: number;
    h: number;
}

export interface IDashboardTileComponentProps<InfoType extends IDashboardTileCommonInfo = IDashboardTileCommonInfo> {
    inEditMode?: boolean;
    info: InfoType;
}

/**
 * ---- Methods and interfaces to obtain Dashboard Tile data ----
 */
export interface IGetTileDataArgs {
    oData: OData;
    context?: IAppContext;
    signal?: AbortSignal;
}

export type TGetDataFn<T> = (args: IGetTileDataArgs) => Promise<T>;
export type TDataValue<T> = T | TGetDataFn<T>;

function isGetDataFn<T = unknown>(prop: unknown | TGetDataFn<T>): prop is TGetDataFn<T> {
    return typeof prop === "function";
}

export function getTileData<T>(prop: TDataValue<T>, args: IGetTileDataArgs): Promise<T> {
    if (isGetDataFn(prop)) {
        return prop(args);
    }
    return Promise.resolve(prop);
}

type TInfoTileDataValue = number | string;

export interface IInfoTileDataCell {
    value: TInfoTileDataValue;
    severity?: Status;
    label?: TFunctionResult;
    unit?: TFunctionResult;
    iconName?: string;
    link?: TInfoValue<LinkProps["to"]>;
    formatter?: TDashboardInfoTileFormatter;
    size?: number; // number of dashboard base tiles, which it should take, default 1
}

export interface ITableTileData {
    rows: IRow[];
    columns: IColumn[];
}

interface IInfoTileFormatterOptions {

}

export type TDashboardInfoTileFormatter = (value: TInfoTileDataValue) => React.ReactNode;

/**
 * ---- Dashboard Tile Definition interfaces
 */
interface IDashboardTileCommonInfo {
    // for config rendering
    title: string;
    subtitle?: string;
    tooltip?: string;
    link?: TInfoValue<LinkProps["to"]>;

    type: DashboardTileType;
    size: ITileSize;
    backgroundColor?: keyof Theme;
    isVisible?: TInfoValue<boolean>;
    // user cannot remove the tile from dashboards by unchecking it in settings
    // used for PurchaseTile
    cannotBeRemovedInSettings?: boolean;
}

export interface ICustomTileInfo extends IDashboardTileCommonInfo {
    type: DashboardTileType.Custom;
    component: React.ComponentType<IDashboardTileComponentProps>;
}

export interface IInfoTileInfo extends IDashboardTileCommonInfo {
    type: DashboardTileType.Info;
    infoData: TDataValue<IInfoTileDataCell[]>;
    formatter?: TDashboardInfoTileFormatter;
}

export interface IChartTileInfo extends IDashboardTileCommonInfo {
    type: DashboardTileType.Chart;
    chartData: TDataValue<IChartTileData>;
    formatter?: TChartValueFormatterFn;
}

export interface ITableTileInfo extends IDashboardTileCommonInfo {
    type: DashboardTileType.Table;
    tableData?: TDataValue<ITableTileData>;
}

export interface ILinkTileInfo extends IDashboardTileCommonInfo {
    type: DashboardTileType.Link;
    iconName: string;
    count?: TDataValue<number>;
}

export type TDashboardTileInfo = ICustomTileInfo | IInfoTileInfo | ITableTileInfo | ILinkTileInfo | IChartTileInfo;
export type TTileDefinition<T extends string = string> = Record<T, TDashboardTileInfo>;

export interface IDashboardGroupDef<Keys extends string = string> {
    id: string;
    title: string;
    tiles: Keys[];
    allTiles: Keys[];
}

export interface IDashboardDefinition<R extends string = string> {
    title?: string;
    tileDefinition: TTileDefinition<R>;
    groups: IDashboardGroupDef<R>[];
    isVisible?: TInfoValue<boolean>;
}
