import { getTableIntentLink } from "@components/drillDown/DrillDown.utils";
import { TFilterDef } from "@components/smart/smartFilterBar/SmartFilterBar.types";
import { ICellValueObject } from "@components/table";
import { getRouteByEntityType } from "@odata/EntityTypes";
import { EntityTypeName } from "@odata/GeneratedEntityTypes";
import { VatStatementStatusCode } from "@odata/GeneratedEnums";
import { getEnumSelectItems } from "@odata/GeneratedEnums.utils";
import { IFormatOptions } from "@odata/OData.utils";
import i18next from "i18next";

import { BasicInputSizes, FieldType } from "../../enums";
import { TValue } from "../../global.types";
import BindingContext from "../../odata/BindingContext";
import { TReportColumnOverrides } from "./Report.utils";

export const VatReportProps = {
    vatStatementPeriod: BindingContext.localContext("DateRange"),
    vatStatementStatus: BindingContext.localContext("VatStatementStatusCodes")
};

export const getVatReportColumnOverrides = (): TReportColumnOverrides => {
    return {
        Document_NumberOurs: {
            formatter: (val: TValue, args: IFormatOptions): ICellValueObject => {
                if (!val) {
                    return null;
                }

                const docId = args.entity["Document_DocumentId"] ?? args.entity["Document_Id"];
                const docType = args.entity["Document_DocumentTypeCode"] ?? args.entity["BusinessPartner_DocumentTypeCode"];
                const url = `${getRouteByEntityType(docType)}/${docId}`;

                return getTableIntentLink(val as string, {
                    route: url,
                    context: args.storage.context,
                    storage: args.storage
                });
            }
        }
    };
};

export const getVatStatementStatusFilterDef = (): TFilterDef => {
    const items = getEnumSelectItems(EntityTypeName.VatStatementStatus)
        .filter(item => ![VatStatementStatusCode.Undefined, VatStatementStatusCode.PartiallyFiled].includes(item.id as VatStatementStatusCode));
    return {
        id: VatReportProps.vatStatementStatus,
        label: i18next.t("Reporting:VatReports.VatStatementStatus"),
        type: FieldType.MultiSelect,
        width: BasicInputSizes.M,
        fieldSettings: {
            items: items
        },
        defaultValue: items.map(item => item.id)
    };
};