import React from "react";
import { TValue } from "../../global.types";
import NumberType from "../../types/Number";
import DateType, { getUtcDate } from "../../types/Date";
import { getDetailRouteByEntityType } from "@odata/EntityTypes";
import { EntityTypeName } from "@odata/GeneratedEntityTypes";
import { getValueTypeFromColumnType, ReportColumnType } from "@pages/reports/Report.utils";
import { formatCurrency } from "../../types/Currency";
import { CurrencyCode } from "@odata/GeneratedEnums";
import { joinReactNodes } from "@utils/domUtils";
import { GLOBAL_SEARCH_URL } from "../../constants";
import customFetch from "../../utils/customFetch";
import { TFetchFn } from "@utils/oneFetch";
import { splitStringToSearchedParts } from "@utils/string";
import { ValueType } from "../../enums";

export type TColumnAlias = string;

export type TGroupName = EntityTypeName.CorrectiveInvoiceIssued
    | EntityTypeName.CorrectiveInvoiceReceived
    | EntityTypeName.InvoiceIssued
    | EntityTypeName.InvoiceReceived
    | EntityTypeName.OtherReceivable
    | EntityTypeName.OtherLiability
    | EntityTypeName.ProformaInvoiceIssued
    | EntityTypeName.ProformaInvoiceReceived
    | EntityTypeName.InternalDocument
    | EntityTypeName.CashReceiptReceived
    | EntityTypeName.CashReceiptIssued
    | EntityTypeName.BankTransaction
    | EntityTypeName.BankStatement
    | EntityTypeName.BusinessPartner
    | EntityTypeName.Asset
    | EntityTypeName.MinorAsset;

export interface ISearchResultColumn {
    ColumnAlias: TColumnAlias;
    DependentColumn?: ISearchResultColumn;
    Label: string;
    Type: ReportColumnType;
}

interface ISearchMatch {
    ColumnAlias: TColumnAlias;
    MatchIndex: 0;
}

export interface ISearchResult {
    Rank: number;
    Values: Record<TColumnAlias, TValue>;
    Matches: ISearchMatch[];
}

export interface ISearchResultGroup {
    Columns: ISearchResultColumn[];
    EntityTypeColumn: ISearchResultColumn;
    FirstLineColumnAliases: TColumnAlias[];
    SecondLineColumnAliases: TColumnAlias[];
    GroupRank: number;
    IdColumn: ISearchResultColumn;
    Name: TGroupName;
    Results: ISearchResult[];
}

export interface ISearchGroup extends Omit<ISearchResultGroup, "FirstLineColumnAliases" | "SecondLineColumnAliases"> {
    def: Record<TColumnAlias, ISearchResultColumn>;
    titleColumn: ISearchResultColumn;
    subtitleColumns: ISearchResultColumn[];
    descriptionColumns: ISearchResultColumn[];
    defaultColumns: Set<TColumnAlias>;
}

export interface IGetColumnArgs {
    result: ISearchResult;
    group: ISearchGroup;
    search: string;
}

function formatValue(value: TValue, {
    Type,
    DependentColumn
}: ISearchResultColumn, values: Record<TColumnAlias, TValue>): string {
    switch (Type) {
        case ReportColumnType.Integer:
        case ReportColumnType.Number:
            return NumberType.format(value as string);

        case ReportColumnType.Currency:
            const currency = (DependentColumn?.ColumnAlias && values[DependentColumn?.ColumnAlias] as CurrencyCode) ?? CurrencyCode.CzechKoruna;
            return formatCurrency(value, currency);

        case ReportColumnType.Date:
        case ReportColumnType.DateTimeOffset:
            const date = getUtcDate(value as string);
            const format = Type === ReportColumnType.DateTimeOffset ? DateType.defaultDateTimeFormat : DateType.defaultDateFormat;
            // todo: localFormat ??
            return DateType.format(date, format);

        default:
            return `${value}`;
    }
}

function highlightSearch(value: string, search: string, type: ValueType): React.ReactNode {
    const res = splitStringToSearchedParts(value, search, type);
    if (res) {
        const { prefix, root, suffix } = res;

        return (<React.Fragment key={value}>
            {prefix}
            <b>{root}</b>
            {suffix}
        </React.Fragment>);
    }
    return value;
}

function getResult(result: ISearchResult, column: ISearchResultColumn, search: string): React.ReactNode {
    const value = result.Values[column.ColumnAlias];
    if (value) {
        const formattedValue = formatValue(value, column, result.Values);
        return highlightSearch(formattedValue, search, getValueTypeFromColumnType(column));
    }
    return null;
}

function getResults(result: ISearchResult, columns: ISearchResultColumn[], search: string): React.ReactNode {
    const results = columns.map<React.ReactNode>(c => getResult(result, c, search));
    return joinReactNodes(results, " | ");
}

export function getResultTitle({ result, group, search }: IGetColumnArgs): React.ReactNode {
    return group.titleColumn && getResult(result, group.titleColumn, search);
}

export function getResultSubtitle({ result, group, search }: IGetColumnArgs): React.ReactNode {
    return getResults(result, group.subtitleColumns, search);
}

export function getResultDescription({ result, group, search }: IGetColumnArgs): React.ReactNode {
    const columns = [...group.descriptionColumns];
    result.Matches.forEach((match) => {
        if (group.def[match.ColumnAlias] && !group.defaultColumns.has(match.ColumnAlias)) {
            columns.push(group.def[match.ColumnAlias]);
        }
    });
    return getResults(result, columns, search);
}

export function getResultUrl({ result, group }: IGetColumnArgs): string {
    const { EntityTypeColumn, IdColumn, Name } = group;
    const entityType = (EntityTypeColumn ? result.Values[EntityTypeColumn.ColumnAlias] : Name) as EntityTypeName;
    return getDetailRouteByEntityType(entityType, result.Values[IdColumn.ColumnAlias], true);
}

interface IFetchSearchResultRetVal {
    results: ISearchGroup[];
    count: number;
}

export async function fetchAndParseSearchResults(value: string, fetchFn: TFetchFn = customFetch): Promise<IFetchSearchResultRetVal> {
    const url = `${GLOBAL_SEARCH_URL}/${encodeURIComponent(value)}`;

    const results: ISearchGroup[] = [];
    let resultCount = 0;

    try {
        const response = await fetchFn(url, { method: "GET" });

        if (response.ok) {
            const res = (await response.json()) as ISearchResultGroup[];

            res.forEach(group => {
                if (group.Results?.length) {
                    const { FirstLineColumnAliases, SecondLineColumnAliases, ...passProps } = group;
                    const def: Record<TColumnAlias, ISearchResultColumn> = {};
                    group.Columns?.forEach(col => {
                        def[col.ColumnAlias] = col;
                    });
                    const _getDefs = (aliases: TColumnAlias[]): ISearchResultColumn[] => {
                        return aliases.reduce((prev, current) => {
                            if (def[current]) {
                                prev.push(def[current]);
                            }
                            return prev;
                        }, []);
                    };
                    const titleColumn = def[FirstLineColumnAliases[0]];
                    const subtitleColumns = _getDefs(FirstLineColumnAliases.slice(1));
                    const descriptionColumns = _getDefs(SecondLineColumnAliases);
                    const defaultColumns = new Set<TColumnAlias>();
                    FirstLineColumnAliases.forEach((alias) => defaultColumns.add(alias));
                    SecondLineColumnAliases.forEach((alias) => defaultColumns.add(alias));

                    results.push({
                        def,
                        titleColumn,
                        subtitleColumns,
                        descriptionColumns,
                        defaultColumns,
                        ...passProps
                    });
                    resultCount += group.Results.length;
                }
            });
        }
    } catch (e) {
        // Fall silently
    }

    return { results, count: resultCount };
}
