import React, { ReactElement } from "react";
import BindingContext from "../../../odata/BindingContext";
import SummaryItem from "../../summary/SummaryItem";
import { ISmartFieldChange } from "../smartField/SmartField";
import { FormStorage } from "../../../views/formView/FormStorage";
import { getInfoValue, IFieldInfoProperties, isVisible, TInfoValue } from "../FieldInfo";
import { TValue } from "../../../global.types";
import { TFormatterFn } from "../smartTable/SmartTable.utils";
import { DefaultTheme } from "styled-components";
import { Status, TextAlign } from "../../../enums";
import { isDateType, isNumericType } from "@evala/odata-metadata/src";
import { FormattingTarget, IFormatOptions } from "@odata/OData.utils";
import { getDisabledFormPageAlert } from "../../../views/formView/Form.utils";
import { AppContext } from "../../../contexts/appContext/AppContext.types";
import { IAlertProps } from "../../alert/Alert";
import { IProps as IIconProps } from "../../icon";

interface IProps {
    item: ISummaryItem;
    // take everything from storage, so that automatic force update via ref can work properly
    storage: FormStorage;
    onChange?: (args: ISmartFieldChange) => void;
    ref?: any;
}

export interface ISummaryItemEditProps extends Pick<IProps, "storage" | "onChange" | "item"> {
    bindingContext: BindingContext;
    onClose: () => void;
}

export interface ISummaryItem extends Omit<IFieldInfoProperties, "formatter"> {
    id: string;
    // Summary items are usually localContext because of different styling and other fieldInfoProperties,
    //  but we need to know, which fields are "included" (in some editable dialog, etc...) to e.g. propagate
    //  errors from these fields
    containedFields?: string[];
    value?: TValue;
    color?: string;
    colorFormatter?: TFormatterFn;
    additionalData?: any;
    renderEdit?: (props: ISummaryItemEditProps) => ReactElement;
    link?: TInfoValue<string>;
    updateFromLiveValue?: boolean;
    // use different formatter from FieldInfo, so that we can return anything in summary item
    formatter?: (val: TValue, args?: IFormatOptions) => any;
    icon?: React.ComponentType<IIconProps>;
}

interface IState {
    isInEditMode: boolean;
}

class SmartSummaryItem extends React.Component<IProps, IState> {
    static contextType = AppContext;

    state: IState = {
        isInEditMode: false
    };

    constructor(props: IProps) {
        super(props);

        props.storage.addRef(this, this.bindingContext);
    }

    get bindingContext(): BindingContext {
        return this.props.storage.data.bindingContext?.navigate(this.props.item.id);
    }

    componentWillUnmount() {
        this.props.storage.removeRef(this);
    }

    handleEdit = (): void => {
        this.setState({
            isInEditMode: true
        });
    };

    handleCloseEditMode = (): void => {
        this.setState({
            isInEditMode: false
        });
    };

    isDisabled = (onlyStorageDisabled = false): boolean => {
        const isDisabled = !onlyStorageDisabled && getInfoValue(this.props.item, "isDisabled", {
            storage: this.props.storage,
            data: this.props.storage.data.entity,
            context: this.context
        });
        return isDisabled || this.props.storage?.isDisabled;
    };

    isReadOnly = (): boolean => {
        const entityIsReadOnly = getInfoValue(this.props.storage?.data?.definition, "isReadOnly", {
            storage: this.props.storage,
            data: this.props.storage.data.entity
        });
        const itemIsReadOnly = getInfoValue(this.props.item, "isReadOnly", {
            storage: this.props.storage,
            data: this.props.storage.data.entity
        });
        return itemIsReadOnly || entityIsReadOnly || this.props.storage.isReadOnly;
    };

    isVisible = (): boolean => {
        return isVisible({
            info: this.props.item,
            bindingContext: this.bindingContext,
            storage: this.props.storage,
            data: this.props.storage.data.entity
        });
    };

    textAlign = (): TextAlign => {
        const fieldInfo = this.props.storage?.getInfo(this.bindingContext);
        const property = this.bindingContext.getProperty();
        return fieldInfo?.textAlign ?? (isNumericType(property) || isDateType(property) ? TextAlign.Right : TextAlign.Left);
    };

    getSummaryProps = (item: ISummaryItem, bc: BindingContext) => {
        const { storage } = this.props;
        const { entity, origEntity, bindingContext } = storage.data;

        let value = storage.getValue(bc, {
            // don't live update (non editable) summary items, only show saved values
            dataStore: item.renderEdit || item.updateFromLiveValue ? entity : origEntity,
            skipTemporaryValue: true
        });
        let color;

        if (item.colorFormatter) {
            color = item.colorFormatter(value, { entity, storage }) as string;
        } else if (item.color) {
            color = item.color;
        }

        if (color) {
            color = storage.theme[color as keyof DefaultTheme] || color;
        }

        if (item.formatter) {
            value = item.formatter(value, { entity, storage, target: FormattingTarget.Summary }) as string;
        }

        // check error
        const containedFieldsBc = (item.containedFields ?? []).map(path => bindingContext.navigate(path));
        const errors = [bc, ...containedFieldsBc]
            .map(fieldBc => storage.getError(fieldBc))
            .filter(error => !!error); // filter out undefined error

        return {
            value,
            color,
            error: errors[0]
        };
    };

    getDisabledAlert = (): IAlertProps => {
        if (!this.isDisabled()) {
            return null;
        }

        if (this.isDisabled(true)) {
            return getDisabledFormPageAlert();
        }

        const disabledText = getInfoValue(this.props.item, "disabledText", {
            storage: this.props.storage,
            data: this.props.storage.data.entity,
            context: this.context
        });

        return disabledText ? {
            status: Status.Warning,
            title: disabledText,
            isSmall: true,
            useFade: true
        } : null;
    };

    render() {
        if (!this.isVisible()) {
            return null;
        }

        const { item, storage, onChange } = this.props;
        const bindingContext = this.bindingContext;
        const data = this.getSummaryProps(item, bindingContext);

        const link = getInfoValue(item, "link", { storage: this.props.storage });
        return (
            <>
                <SummaryItem id={bindingContext}
                             hotspotId={bindingContext.getNavigationPath(true)}
                             value={data.value}
                             label={item.label}
                             color={data.color}
                             error={data.error}

                             textAlign={this.textAlign()}
                             isDisabled={this.isDisabled()}
                             disabledAlert={this.getDisabledAlert()}
                             isReadOnly={this.isReadOnly()}
                             editable={!!item.renderEdit}
                             onEdit={this.handleEdit}
                             link={link}
                             icon={item.icon}
                             dataName={bindingContext.getNavigationPath(true)}
                />
                {this.state.isInEditMode && item.renderEdit?.({
                    bindingContext, storage, onChange, item,
                    onClose: this.handleCloseEditMode
                })}
            </>
        );
    }
}

export default SmartSummaryItem;