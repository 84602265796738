import { mix, transparentize } from "polished";
import styled, { css } from "styled-components/macro";

import {
    getDisabledStyle,
    getFocusBorderElement,
    LocalStackOrder,
    T_BTN_main,
    T_PLAIN_big
} from "../../../global.style";
import { IAuditTrailData } from "../../../model/Model";
import { PropsWithTheme } from "../../../theme";
import { AuditTrailFieldType } from "../../smart/FieldInfo";

export const StyledSwitch = styled.div<{
    isDisabled: boolean;
    isReadOnly: boolean;
}>`
    position: relative;
    cursor: ${props => props.isReadOnly ? "default" : "pointer"};
    display: flex;
    align-items: center;

    ${props => getDisabledStyle(props.isDisabled)};
    pointer-events: ${props => props.isDisabled || props.isReadOnly ? "none" : "all"};
`;

interface IChecked {
    _isChecked: boolean;
}

interface ILight {
    _isLight: boolean;
}

interface ISwitchStyleProps extends IChecked {
    _isSemantic: boolean;
    _isWithoutHover?: boolean;
}

enum SwitchColorType {
    Text,
    LightText,
    LightTextHover,
    LightBackground,
    SubtleBorder,
    Background,
    OuterCircleBackground,
    OuterCircleBackgroundHover,
    InnerCircle
}

const getThemeValue = (props: ISwitchStyleProps & PropsWithTheme, type: SwitchColorType): string => {
    const { theme } = props;
    switch (type) {
        case SwitchColorType.Text:
            return theme.C_ACT_main;
        case SwitchColorType.LightText:
            return theme.C_BTN_4L_emph_text;
        case SwitchColorType.LightTextHover:
            return theme.C_BTN_4D_emph_text_hover;
        case SwitchColorType.LightBackground:
            return props._isChecked ? theme.C_ACT_der : "transparent";
        case SwitchColorType.SubtleBorder:
            return transparentize(0.5, theme.C_ACT_main);
        case SwitchColorType.Background:
            if (props._isChecked) {
                return props._isSemantic ? mix(0.15, theme.C_SEM_el_good, theme.C_BG_menu) : theme.C_ACT_hover_without_opacity;
            }
            return theme.C_BG_menu;
        case SwitchColorType.InnerCircle:
            if (props._isSemantic) {
                return props._isChecked ? theme.C_SEM_el_good : theme.C_SEM_el_bad;
            }
            return props._isChecked ? theme.C_ACT_main : theme.C_ACT_thick_line;
        case SwitchColorType.OuterCircleBackground:
            return getThemeValue({ _isChecked: false, _isSemantic: false, theme }, SwitchColorType.Background);
        case SwitchColorType.OuterCircleBackgroundHover:
            return props._isChecked && props._isSemantic ? getThemeValue(props, SwitchColorType.Background) : theme.C_ACT_hover_without_opacity;
    }
};

const getPaddingValue = (isNextToCircle: boolean, hasIcon: boolean) => {
    return hasIcon ? (isNextToCircle ? "30px" : "8px")
        : (isNextToCircle ? "34px" : "12px");
};

export const Base = styled.div<ISwitchStyleProps & ILight & {
    _hasIcon: boolean;
}>`
  position: relative;
  height: 26px;
  min-width: 53px;
  padding-left: ${props => getPaddingValue(!props._isChecked, props._hasIcon)};
  padding-right: ${props => getPaddingValue(props._isChecked, props._hasIcon)};
  background-color: ${props => getThemeValue(props, props._isLight ? SwitchColorType.LightBackground : SwitchColorType.Background)};
  border: 1px solid ${props => getThemeValue(props, props._isLight ? SwitchColorType.LightText : SwitchColorType.SubtleBorder)};
  border-radius: 13px;

    ${props => !props._isWithoutHover && css<ISwitchStyleProps & ILight>`
        ${StyledSwitch}:hover & {
            border-color: ${props => getThemeValue(props, props._isLight ? SwitchColorType.LightTextHover : SwitchColorType.Text)};
    `};
  
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export const Circle = styled.div<ISwitchStyleProps & ILight>`
  position: absolute;
  width: 32px;
  height: 32px;
  top: 50%;
  transform: translate(0, -50%);
  right: ${props => props._isChecked ? "-4px" : ""};
  left: ${props => props._isChecked ? undefined : "-4px"};

  border: 1px solid ${props => getThemeValue(props, props._isLight ? SwitchColorType.LightText : SwitchColorType.Text)};
  background-color: ${props => getThemeValue(props, props._isLight ? SwitchColorType.LightText : SwitchColorType.OuterCircleBackground)};
  
  border-radius: 50%;
  
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${LocalStackOrder.AboveContent}; // needs to be over focus border

    ${props => !props._isWithoutHover && css<ISwitchStyleProps & ILight>`
        ${StyledSwitch}:hover & {
            border: 1px solid ${props => getThemeValue(props, props._isLight ? SwitchColorType.LightTextHover : SwitchColorType.Text)};
            background-color: ${props => getThemeValue(props, props._isLight ? SwitchColorType.LightTextHover : SwitchColorType.OuterCircleBackgroundHover)};
        }
    `};
`;

export const InnerCircle = styled.div<ISwitchStyleProps>`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${props => getThemeValue(props, SwitchColorType.InnerCircle)};
`;

export const Body = styled.div<ILight & {
    isReadOnly: boolean;
    isDisabled: boolean;
}>`
    position: relative;
    cursor: ${props => props.isDisabled ? "default" : "pointer"};
    ${props => getFocusBorderElement({ offset: 2, isLight: props._isLight, keyboardOnly: true })};
    ${props => getDisabledStyle(props.isReadOnly)};
    pointer-events: ${props => props.isDisabled || props.isReadOnly ? "none" : "all"};
`;

export const Label = styled.div<{
    auditTrailData: IAuditTrailData;
    isLight: boolean;
}>`
  margin: 0 9px 0 -5px;
  padding: 0 5px;
  border-radius: 3px;
  color: ${props => !props.isLight ? props.theme.C_TEXT_primary : props.theme.C_TEXT_dialogue};
  ${T_PLAIN_big};
  ${props => (props.auditTrailData?.type === AuditTrailFieldType.NoDifference || props.auditTrailData?.type === AuditTrailFieldType.HoveredNoDifference) && {
      background: props.theme.C_AUDIT_TRAIL_NO_DIFF,
      color: `${props.theme.C_TEXT_primary} !important`
}}
`;

export const InnerSpan = styled.span <{
    auditTrailData: IAuditTrailData
}>`
  ${props => (props.auditTrailData?.type === AuditTrailFieldType.NoDifference) && {
    opacity: 0
}}
    ${props => (props.auditTrailData?.type === AuditTrailFieldType.HoveredNoDifference) && {
    opacity: props.theme.C_AUDIT_TRAIL_DIFF_VISIBLE_OPACITY
}}

`;

export const InnerLabel = styled.div<ISwitchStyleProps & ILight & {
    _isPositive: boolean
}>`
  ${T_BTN_main};
  color: ${props => getThemeValue(props, props._isLight ? SwitchColorType.LightText : SwitchColorType.Text)};
  height: ${props => props._isPositive === props._isChecked ? "24px" : 0};
  line-height: 24px;
  text-align: ${props => props._isPositive ? "right" : "left"};
  overflow: hidden;
  ${StyledSwitch}:hover & {
    color: ${props => getThemeValue(props, props._isLight ? SwitchColorType.LightTextHover : SwitchColorType.Text)};
  }
`;

export const AuditTrailLayer = styled.div <{
    auditTrailData: IAuditTrailData;
    _isChecked: boolean;
}>`
    width: calc(100% + 11px);
    height: 36px;
    position: absolute;
    top: -5px;
    left: ${props => props._isChecked ? "-5px" : "-6px"};
    border-radius: ${props => props.theme.borderLargeRadius};
    z-index: ${LocalStackOrder.AboveContent};
    ${props => (props.auditTrailData?.type === AuditTrailFieldType.NoDifference || props.auditTrailData?.type === AuditTrailFieldType.HoveredNoDifference) && {
    background: props.theme.C_AUDIT_TRAIL_NO_DIFF,
    zIndex: 150
}}
    ${props => (props.auditTrailData?.type === AuditTrailFieldType.Difference || props.auditTrailData?.type === AuditTrailFieldType.HoveredDifference) && {
    border: `3px solid ${props.theme.C_AUDIT_TRAIL_DIFF}`
    }}
    ${props => (props.auditTrailData?.type === AuditTrailFieldType.HoveredNoDifference) && {
    opacity: props.theme.C_AUDIT_TRAIL_DIFF_VISIBLE_OPACITY
    }}

`;