import { EntitySetName, EntityTypeName, InvoiceServiceIntegrationEntity } from "@odata/GeneratedEntityTypes";
import { getEnumNameSpaceName } from "@odata/GeneratedEnums.utils";
import { setDefByEntityType } from "@pages/getDefByEntityType";
import { IDefinition, IGetDefinition } from "@pages/PageUtils";

import { IAppContext } from "../../contexts/appContext/AppContext.types";
import { BasicInputSizes, FieldType } from "../../enums";
import { IFormDef } from "../../views/formView/Form";

export const getDefinitions: IGetDefinition = (context: IAppContext): IDefinition => {
    const form: IFormDef = {
        id: `${EntityTypeName.InvoiceServiceIntegration}Form`,
        translationFiles: getDefinitions.translationFiles,
        additionalProperties: [],
        fieldDefinition: {
            [InvoiceServiceIntegrationEntity.Name]: {
                width: BasicInputSizes.XL
            },
            [InvoiceServiceIntegrationEntity.InvoiceServiceIntegrationType]: {
                type: FieldType.ComboBox,
                width: BasicInputSizes.XL,
                isReadOnly: (args) => {
                    return !args.storage.data.bindingContext.isNew();
                },
                fieldSettings: {
                    displayName: "Name"
                }
            },
        },
        groups: [
            {
                id: "main",
                rows: [
                    [
                        { id: InvoiceServiceIntegrationEntity.Name }
                    ],
                    [
                        { id: InvoiceServiceIntegrationEntity.InvoiceServiceIntegrationType }
                    ]
                ]
            }
        ]
    };

    return {
        entitySet: EntitySetName.InvoiceServiceIntegrations,
        form
    };
};


getDefinitions.translationFiles = [
    "Integrations",
    getEnumNameSpaceName(EntityTypeName.InvoiceServiceIntegrationType)
];

setDefByEntityType(EntityTypeName.InvoiceServiceIntegration, getDefinitions);