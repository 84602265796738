import React, { memo, useContext, useEffect, useMemo, useState } from "react";
import { getTileData, IDashboardTileComponentProps, ITableTileData, ITableTileInfo } from "../../dashboard";
import TableWithAutoSizedColumns from "../../table/TableWithAutoSizedColumns";
import DashboardTileBackground from "../../dashboard/DashboardTileBackground";
import { withTheme } from "styled-components/macro";
import { PropsWithTheme } from "../../../theme";
import { ICustomTableColors } from "../../table";
import { transparentize } from "polished";
import { withOData, WithOData } from "@odata/withOData";
import { TableTileTableWrapper } from "./TableTile.styles";
import { AppContext } from "../../../contexts/appContext/AppContext.types";
import DashboardTileHeader from "../../dashboard/DashboardTileHeader";
import { getInfoValue } from "../../smart/FieldInfo";
import { useTranslation } from "react-i18next";

interface IProps extends IDashboardTileComponentProps<ITableTileInfo>, WithOData, PropsWithTheme {
}

const TableTile: React.FC<IProps> = (props) => {
    const context = useContext(AppContext);
    const [data, setData] = useState<ITableTileData>(null);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [hasError, setError] = useState<boolean>(false);

    const { t } = useTranslation("Home");

    useEffect(() => {
        let mounted = true;

        try {
            getTileData(props.info.tableData, {
                oData: props.oData,
                context
            })
                    .then((data) => mounted && setData(data))
                    .finally(() => mounted && setLoading(false));
        } catch (e) {
            if (mounted) {
                setError(true);
                setData(null);
            }
        }

        return () => {
            mounted = false;
        };
    }, [props.info.tableData, props.oData, context]);

    const customTableColors = useMemo((): ICustomTableColors => {
        return {
            rowBackgroundColor: transparentize(0.7, props.theme.C_ACT_hover_without_opacity),
            rowBorderColor: props.theme.C_BG_menu
        };
    }, [props.theme.C_ACT_hover_without_opacity, props.theme.C_BG_menu]);

    const isWithoutHeader = data?.columns && !data.columns.find(col => col.label);

    const to = getInfoValue(props.info, "link", { context });

    return (
            <DashboardTileBackground backgroundColor={props.info.backgroundColor}
                                     inEditMode={props.inEditMode}
                                     isLoading={isLoading || hasError/*todo: may be different look for error state*/}
                                     to={to}>
                <DashboardTileHeader tooltip={props.info.tooltip}>{props.info.title}</DashboardTileHeader>
                <TableTileTableWrapper $isWithoutHeader={isWithoutHeader}>
                    <TableWithAutoSizedColumns
                            columns={data?.columns ?? []}
                            rows={data?.rows ?? []}
                            noDataText={t("NoData")}
                            hasSimplifiedNoData
                            customColors={customTableColors}
                            disableSort
                            disableVirtualization
                            disableColumnResize
                            isHoverDisabled
                            useStatusHighlight={false}
                            withoutShadows
                            assignRemainingSpaceToFirstColumn
                    />
                </TableTileTableWrapper>
            </DashboardTileBackground>
    );
};

export default memo(withOData(withTheme(TableTile)));