import React from "react";
import Resizer from "./Resizer";
import { Theme } from "../../theme";

export interface WithResizer {
    // props passed to component
    width: number;
    isCollapsed: boolean;
    resizeRef: React.RefObject<HTMLDivElement>;
    onMouseOver?: (e: React.MouseEvent) => void;
    onMouseLeave?: (e: React.MouseEvent) => void;
    resizeHandle: React.ReactElement;
}

export interface IWithResizerSettings {
    borderColor?: keyof Theme;
    minWidth?: number;
    maxWidth?: number;
    initialWidth?: number;
    initialCollapsed?: boolean;
    collapseThreshold?: number; // threshold between collapsing and going back to minWidth
}

export const withResizer = (settings: IWithResizerSettings = {}) => (
    <P extends WithResizer>(Component: React.ComponentType<P>): React.ComponentType<Omit<P, keyof WithResizer>> => {
        return React.forwardRef((props: Omit<P, keyof WithResizer>, ref) => {
            return (
                <Resizer {...settings}>
                    {(resizerProps) => (
                        <Component ref={ref}
                            // cast props as per https://github.com/Microsoft/TypeScript/issues/28938#issuecomment-450636046
                                   {...props as P}
                                   {...resizerProps}/>
                    )}
                </Resizer>
            );
        }) as any;
    }
);
