import React from "react";
import { withConfirmationDialog, WithConfirmationDialog } from "@components/dialog/withConfirmationDialog";
import { isObjectEmpty } from "@utils/general";
import { AppContext } from "../../../../contexts/appContext/AppContext.types";
import DialogFormView, { IDialogFormViewProps } from "../../../../views/formView/DialogFormView";
import Alert from "../../../../components/alert";
import { Status } from "../../../../enums";
import { EntitySetName, IDeferredDocumentEntity, IDeferredPlanEntity } from "@odata/GeneratedEntityTypes";
import { WithTranslation, withTranslation } from "react-i18next";
import { REST_API_URL } from "../../../../constants";
import customFetch, { getDefaultPostParams } from "../../../../utils/customFetch";
import { IFormStorageSaveResult } from "../../../../views/formView/FormStorage";
import { getActiveChartOfAccountsId } from "@pages/fiscalYear/FiscalYear.utils";
import BindingContext from "../../../../odata/BindingContext";
import { parseResponse } from "@odata/ODataParser";
import { withPermissionContext } from "../../../../contexts/permissionContext/withPermissionContext";

import { ODataError } from "@odata/Data.types";

interface IProps extends IDialogFormViewProps<IDeferredPlanEntity>, WithConfirmationDialog, WithTranslation {

}

const CLOSE_PLANS_URL = `${REST_API_URL}/DeferredPlanProcessing/ClosePlans`;

class CloseTimeResDialogFormView extends DialogFormView<IDeferredPlanEntity, IProps> {
    static contextType = AppContext;

    onAfterLoad = async () => {


        // load DeferredDocuments which are not by default loaded with the document
        // we need them to compute fullAmount
        const deferredDocuments = await this.props.storage.oData.getEntitySetWrapper(EntitySetName.DeferredDocuments).query()
                .expand("TargetDocument")
                .filter(`OriginalDocument/Id eq ${this.props.storage.data.entity.OriginalDocument.Id}`)
                .fetchData<IDeferredDocumentEntity[]>();

        // take amount of items that don't have TargetDocument created for them yet
        const fullAmount = deferredDocuments.value.reduce((amount, doc) => {
            let sum = amount;

            if (isObjectEmpty(doc.TargetDocument)) {
                sum += doc.Amount;
            }

            return sum;

        }, 0);

        this.props.storage.setValue(this.props.storage.data.bindingContext.navigate(BindingContext.localContext("fullAmount")), fullAmount);
        this.props.storage.refreshFields();
        return super.onAfterLoad();
    };

    async save(): Promise<IFormStorageSaveResult> {
        const data = this.props.storage.data.entity as IDeferredPlanEntity;
        const body: any = {
            DateAccountingTransaction: data.DateStart,
            AccountId: data.Account.Id,
            ChartOfAccountsId: getActiveChartOfAccountsId(this.context)
        };

        const res = await customFetch(`${CLOSE_PLANS_URL}/${data.OriginalDocument.Id}`, {
            ...getDefaultPostParams(),
            body: JSON.stringify(body)
        });

        if (!res.ok) {
            const error: ODataError = await parseResponse(res);

            this.props.storage.handleOdataError({
                error,
                bindingContext: this.props.storage.data.bindingContext
            });

            return null;
        }

        return {
            bindingContext: this.props.storage.data.bindingContext,
            data: res,
            response: res
        };
    }

    renderForm() {
        return (
            <>
                <Alert status={Status.Warning}
                       title={this.props.t("TimeResolution:EndForm.AlertTitle")}
                       subTitle={this.props.t("TimeResolution:EndForm.AlertText")}
                       isOneLiner
                       isFullWidth
                       shouldAddBottomMargin
                />
                {super.renderForm()}
            </>
        );
    }
}

export default withConfirmationDialog(withTranslation(["TimeResolution"], { withRef: true })(withPermissionContext(CloseTimeResDialogFormView)));