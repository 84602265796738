import { EditSimpleIcon, FacebookIcon, GoogleIcon } from "@components/icon";
import { IFieldDef, IFieldDefFn, IGetValueArgs, not, TGetValueFn } from "@components/smart/FieldInfo";
import { PhoneNumberDef } from "@components/smart/GeneralFieldDefinition";
import { TSmartODataTableStorage } from "@components/smart/smartTable/SmartODataTableBase";
import { EntityTypeName, IUserCompanyRoleEntity } from "@odata/GeneratedEntityTypes";
import { getEnumNameSpaceName, getEnumSelectItems } from "@odata/GeneratedEnums.utils";
import { IFormatOptions } from "@odata/OData.utils";
import i18next from "i18next";
import React from "react";

import { Button } from "../../components/button";
import { CollapsibleContent } from "../../components/collapsibleContent";
import Field from "../../components/inputs/field";
import SimpleItemsList, { ISimpleItem } from "../../components/simpleItemsList/SimpleItemsList";
import { SmartTable } from "../../components/smart/smartTable";
import { IAppContext } from "../../contexts/appContext/AppContext.types";
import { IAuthDevice } from "../../contexts/authContext/Auth.utils";
import { BasicInputSizes, FieldType, GroupedField, IconSize, LabelStatus, Sort, ValidatorType } from "../../enums";
import { TValue } from "../../global.types";
import BindingContext from "../../odata/BindingContext";
import memoizeOne from "../../utils/memoizeOne";
import { IFormDef } from "../../views/formView/Form";
import { FormStorage } from "../../views/formView/FormStorage";
import { isUserOwner } from "../admin/users/Users.utils";
import { companyCellFormatter } from "../companies/Company.utils";
import { setDefForTesting } from "../getDefByEntityType";
import { IDefinition, IGetDefinition } from "../PageUtils";
import {
    allowDataCollection,
    allowDataCollectionMarketing,
    allowDataCollectionMarketingOwner,
    authDevicesPath,
    changePasswordPath,
    companyRolesPath,
    dataCollectionCheckboxGroupPath,
    facebookIsConnectedPath,
    googleIsConnectedPath,
    IUserEntityExtended,
    IUserSettingCustomData,
    loginEmailPath,
    pairedAccountsPath,
    UserSettingDialogType
} from "./UserSetting.utils";

const getCompanyRolesColumns = memoizeOne((): IFieldDef[] => {
    return [
        {
            id: "Company/Name",
            formatter: (val: TValue, args?: IFormatOptions) => {
                const companyRole = args.entity as IUserCompanyRoleEntity;
                return companyCellFormatter(companyRole.Company);
            },
            additionalProperties: [
                { id: "Logo" }
            ]
        },
        {
            id: "CompanyRole/CompanyRoleName",
            label: i18next.t("UserSetting:Form.MyRole")
        }
    ];
}, () => [i18next.hasLoadedNamespace("UserSetting")]);

const isOwner: TGetValueFn<boolean> = (args): boolean => {
    return isUserOwner(args.storage.data.entity);
};

export const getDefinition: IGetDefinition = (context: IAppContext): IDefinition => {
    const entitySet = "Users";

    const form: IFormDef = {
        id: "userSettingSpecialForm",
        translationFiles: getDefinition.translationFiles,
        summary: [
            {
                id: "GeneralRoles",
                formatter: (val, args) => {
                    const orgName = args.storage.context.getData().tenant?.Name;
                    const roleName = args.storage.data.entity.GeneralRoles?.[0]?.GeneralRole?.GeneralRoleName;

                    return (
                        <>
                            {orgName} | <span style={{ fontWeight: "normal" }}>{roleName}</span>
                        </>
                    );
                }
            }
        ],
        fieldDefinition: {
            FirstName: {
                isReadOnly: not(isOwner)
            },
            LastName: {
                isReadOnly: not(isOwner)
            },
            Email: {
                width: BasicInputSizes.XL,
                isReadOnly: not(isOwner),
                validator: {
                    type: ValidatorType.Email
                }
            },
            PhoneNumber: {
                ...PhoneNumberDef,
                isReadOnly: not(isOwner)
            },
            Language: {
                type: FieldType.ComboBox,
                fieldSettings: {
                    displayName: "Name"
                }
            },
            ColourTheme: {
                type: FieldType.SegmentedButton,
                fieldSettings: {
                    displayName: "Name",
                    items: getEnumSelectItems(EntityTypeName.ColourTheme)
                }
            },
            TimeFormat: {
                type: FieldType.ComboBox,
                fieldSettings: {
                    displayName: "Name",
                    // additionalProperties has to be both in fieldSettings and in main object
                    // once for form loader and once form SmartSelect loader
                    additionalProperties: [
                        { id: "Format" }
                    ]
                },
                additionalProperties: [
                    { id: "Format" }
                ]
            },
            DateFormat: {
                type: FieldType.ComboBox,
                fieldSettings: {
                    displayName: "Name",
                    additionalProperties: [
                        { id: "Format" }
                    ]
                },
                additionalProperties: [
                    { id: "Format" }
                ]
            },
            [companyRolesPath]: {
                type: FieldType.Custom,
                render: (args: IFieldDefFn) => {
                    return (
                        <CollapsibleContent topMargin={90}
                                            defaultIsCollapsed>
                            <SmartTable
                                tableId={"userSettingsCompanyRoles"}
                                storage={args.storage as TSmartODataTableStorage}
                                bindingContext={args.storage.data.bindingContext.navigate("CompanyRoles")}
                                hideDrilldown
                                disableVirtualization
                                initialSortBy={[
                                    { id: "Company/Name", sort: Sort.Asc }
                                ]}
                                columns={getCompanyRolesColumns()}/>
                        </CollapsibleContent>
                    );
                }
            },
            [loginEmailPath]: {
                label: i18next.t("UserSetting:Form.LoginEmail"),
                groupedField: GroupedField.NoWrapStart,
                isReadOnly: true

            },
            [changePasswordPath]: {
                type: FieldType.Custom,
                groupedField: GroupedField.NoWrapEnd,
                render: (args: IFieldDefFn) => {
                    return (
                        <Button isTransparent
                                onClick={() => {
                                    (args.storage as FormStorage<unknown, IUserSettingCustomData>).setCustomData({
                                        userSettingDialogTypeKey: UserSettingDialogType.PasswordChange
                                    });
                                    args.storage.refresh();
                                }}
                                icon={<EditSimpleIcon/>}>
                            {i18next.t("UserSetting:Form.ChangePassword")}
                        </Button>
                    );
                }
            },
            [pairedAccountsPath]: {
                type: FieldType.Custom,
                label: i18next.t("UserSetting:Form.PairedAccounts"),
                isVisible: (args: IGetValueArgs) => {
                    return false; // not yet working on BE https://solitea-cz.atlassian.net/browse/DEV-22268
                    // return !!(args.storage.data.entity[googleIsConnectedPath] || args.storage.data.entity[facebookIsConnectedPath]);
                },
                render: (args: IFieldDefFn) => {
                    const items: ISimpleItem[] = [];

                    if (args.storage.data.entity[googleIsConnectedPath]) {
                        items.push(
                            {
                                id: "google",
                                label: "Google",
                                contentBefore: <GoogleIcon width={IconSize.S}
                                                           style={{
                                                               marginRight: "10px",
                                                               position: "relative",
                                                               top: "-1px"
                                                           }}/>
                            }
                        );
                    }

                    if (args.storage.data.entity[facebookIsConnectedPath]) {
                        items.push(
                            {
                                id: "facebook",
                                label: "Facebook",
                                contentBefore: <FacebookIcon width={IconSize.S}
                                                             style={{
                                                                 marginRight: "10px",
                                                                 position: "relative",
                                                                 top: "-1px"
                                                             }}/>
                            }
                        );
                    }

                    const onRemoveFn = (id: string) => {
                        const storage = args.storage as FormStorage<IUserEntityExtended, IUserSettingCustomData>;

                        storage.setCustomData({
                            userSettingDialogTypeKey: id === "google" ? UserSettingDialogType.GoogleRemove : UserSettingDialogType.FacebookRemove
                        });
                        storage.refresh();
                    };

                    return (
                        <Field label={args.props.info.label}
                               name="PairedAccounts"
                               isReadOnly>
                            <SimpleItemsList items={items}
                                             onRemove={onRemoveFn}
                                             style={{ alignSelf: "flex-start", marginTop: "10px" }}
                            />
                        </Field>
                    );
                }
            },
            [authDevicesPath]: {
                type: FieldType.Custom,
                label: i18next.t("UserSetting:Form.AuthDevices"),
                isVisible: ({ storage, data }: IGetValueArgs) => {
                    const authDevices: IAuthDevice[] = (data ?? storage.data.entity)[authDevicesPath];

                    return authDevices?.length > 0;
                },
                render: (args: IFieldDefFn) => {
                    const authDevices: IAuthDevice[] = args.props.value;

                    const items: ISimpleItem[] = authDevices.map(device => ({
                        id: device.Id,
                        label: device.Name
                    }));

                    return (
                        <Field label={args.props.info.label}
                               name="AuthDevices"
                               isReadOnly>
                            <SimpleItemsList items={items}
                                             onRemove={(id) => {
                                                 const storage = args.storage as FormStorage<IUserEntityExtended, IUserSettingCustomData>;
                                                 // TODO better approach to communication between definiton a and FormView
                                                 // should we add something like customAction propagated from SmartField to FormView?
                                                 storage.setCustomData({
                                                     userSettingDialogTypeKey: UserSettingDialogType.DeviceRemove,
                                                     removeAuthDevice: id
                                                 });
                                                 storage.refresh();
                                             }}
                                             style={{ alignSelf: "flex-start", marginTop: "10px" }}
                            />
                        </Field>
                    );
                }
            },
            [dataCollectionCheckboxGroupPath]: {
                type: FieldType.CheckboxGroup,
                labelStatus: LabelStatus.Removed,
                // use 100% to ensure that the items can wrap when form is small
                width: "100%",
                fieldSettings: {
                    items: [
                        {
                            id: allowDataCollection,
                            label: i18next.t("UserSetting:Form.AllowDataCollection")
                        },
                        {
                            id: allowDataCollectionMarketing,
                            label: i18next.t("UserSetting:Form.AllowDataCollectionMarketing")
                        },
                        {
                            id: allowDataCollectionMarketingOwner,
                            label: i18next.t("UserSetting:Form.AllowDataCollectionMarketingOwner")
                        }
                    ]
                }
            }
        },
        groups: [
            {
                id: "info",
                rows: [
                    [{ id: "FirstName" }, { id: "LastName" }, { id: "Email" }, { id: "PhoneNumber" }]
                ]
            },
            {
                id: "customization",
                title: i18next.t("UserSetting:Form.CustomizationGroup"),
                rows: [
                    [{ id: "Language" }, { id: "ColourTheme" }],
                    [{ id: "TimeFormat" }, { id: "DateFormat" }]
                ]
            },
            {
                id: "organization",
                title: i18next.t("UserSetting:Form.OrganizationGroup"),
                rows: [[{ id: companyRolesPath }]]
            },
            {
                id: "login",
                title: i18next.t("UserSetting:Form.LoginGroup"),
                rows: [
                    [{ id: loginEmailPath }, { id: changePasswordPath }],
                    [{ id: pairedAccountsPath }],
                    [{ id: authDevicesPath }]
                ]
            },
            {
                id: "dataCollection",
                title: i18next.t("UserSetting:Form.DataCollectionGroup"),
                rows: [
                    [{ id: BindingContext.localContext("DataCollection") }]
                ]
            }
        ],
        additionalProperties: [
            { id: "Name" },
            {
                id: "GeneralRoles",
                additionalProperties: [{ id: "GeneralRole/GeneralRoleName" }]
            }
        ]
    };

    return {
        entitySet,
        form
    };
};

getDefinition.translationFiles = ["UserSetting", "Common", getEnumNameSpaceName(EntityTypeName.ColourTheme)];
setDefForTesting("UserSettingDef", getDefinition);