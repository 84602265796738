import React from "react";
import TestIds from "../../testIds";
import { SeparatorLabel, SeparatorLine, StyledSeparator } from "./Separator.styles";
import { Theme } from "../../theme";

interface IProps {
    label?: string;
    isEmphasised?: boolean;
    isBold?: boolean;
    color?: keyof Theme;
    className?: string;
    style?: React.CSSProperties;
}

export const Separator: React.FC<IProps> = ({ label, isEmphasised, isBold, color, className, style }) => {
    return (
        <StyledSeparator data-testid={TestIds.Separator} className={className} style={style}>
            {label && (<SeparatorLabel>{label}</SeparatorLabel>)}
            <SeparatorLine $isEmphasised={isEmphasised} $isBold={isBold} $color={color}/>
        </StyledSeparator>
    );
};
