import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import XHR from "i18next-xhr-backend";
import toml from "toml";
import { TRANSLATIONS_API_URL } from "./constants";
import { getEvalaVersion } from "./global.utils";

const evalaVersion = getEvalaVersion();

const options = {
    lng: "cs-CZ",
    fallbackLng: "cs-CZ",
    debug: false,
    defaultNS: "Common",
    ns: ["Common"],
    preload: ["cs-CZ"],
    load: "currentOnly",

    react: {
        // Prevents blocking of rendering react components when translations are not ready.
        useSuspense: false
    },
    interpolation: { escapeValue: false },

    backend: {
        loadPath: `${TRANSLATIONS_API_URL}/{{lng}}/{{ns}}.toml${evalaVersion ? `?v=${evalaVersion}` : ""}`,

        // path to post missing resources
        //addPath: 'locales/add/{{lng}}/{{ns}}',

        allowMultiLoading: false,

        // parse data after it has been fetched
        parse: toml.parse,

        // allow cross domain requests
        crossDomain: false,

        // allow credentials on cross domain requests
        withCredentials: false,

        // overrideMimeType sets request.overrideMimeType("application/json")
        overrideMimeType: false
    }
};

export const i18nInitPromise = i18n.use(XHR).use(initReactI18next).init(options as any);

export default i18n;
