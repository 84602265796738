import { getInfoByPath, not, TValidatorFn } from "@components/smart/FieldInfo";
import { IFormGroupDef } from "@components/smart/smartFormGroup/SmartFormGroup";
import { getNewItemsMaxId, setDirtyFlag } from "@odata/Data.utils";
import {
    DocumentBusinessPartnerEntity,
    DocumentEntity,
    DocumentLinkEntity,
    EntitySetName,
    EntityTypeName,
    IDocumentCbaCategoryEntity,
    IDocumentEntity,
    IDocumentLabelEntity,
    IDocumentLinkEntity,
    IDocumentVatClassificationSelectionEntity,
    IRegularDocumentItemEntity,
    ProformaInvoiceIssuedEntity
} from "@odata/GeneratedEntityTypes";
import { DocumentLinkTypeCode, SelectionCode } from "@odata/GeneratedEnums";
import { getEnumDisplayValue } from "@odata/GeneratedEnums.utils";
import { isBatchResultOk, OData } from "@odata/OData";
import { prepareQueryForBatch } from "@odata/OData.utils";
import { refreshAccountIdsForActualFiscalYear } from "@pages/accountAssignment/AccountAssignment.utils";
import {
    getDefaultPropName,
    getDefaultVatRuleFromBP,
    getDefaultVatRuleFromItems,
    getVatDependentFields,
    isVatRuleSame,
    ITEMS_VAT_DEDUCTION_PATH,
    SAVED_VATS_PATH
} from "@pages/admin/vatRules/VatRules.utils";
import { isSameCbaCategory } from "@pages/cashBasisAccounting/CashBasisAccounting.utils";
import { DOCUMENT_TABLES_GROUP_ID } from "@pages/documents/DocumentDef";
import { compareLabels } from "@pages/labelsHierarchy/Labels.utils";
import { IDefinition } from "@pages/PageUtils";
import { isAccountAssignmentCompany, isCashBasisAccountingCompany } from "@utils/CompanyUtils";
import { forEachKey, isDefined, isNotDefined, isObjectEmpty, sortCompareFn } from "@utils/general";
import { logger } from "@utils/log";
import { TFetchFn } from "@utils/oneFetch";
import i18next from "i18next";
import { cloneDeep } from "lodash";

import { ValidatorType } from "../../../../enums";
import { ModelEvent } from "../../../../model/Model";
import BindingContext, { createPath, IEntity } from "../../../../odata/BindingContext";
import { isFormReadOnly } from "../../../../views/formView/Form.utils";
import { FormStorage } from "../../../../views/formView/FormStorage";
import {
    calcLineItemValues,
    getDocumentPairedTable,
    isDocumentLocked,
    isProformaBc,
    isReceivedBc
} from "../../Document.utils";
import { IProformaEntity } from "../../proformaInvoices/ProformaInvoice.utils";
import { getRenderedItemsByInfo } from "@components/smart/smartSelect/SmartSelectAPI";


export const DEDUCT_DEPOSIT_ACTION = "deductDeposit";
export const PROFORMA_FORM_TAB = "Proformas";
export const PROFORMA_DOC_LINK_FILTER = `TypeCode eq '${DocumentLinkTypeCode.ProformaInvoiceDeduction}'`;
export const RELATED_PROFORMA_ADDITIONAL_RESULT_IDX = 0;

const hasSameCurrencyAsProforma: TValidatorFn = (value, args) => {
    const storage = args.storage as FormStorage;
    return !storage.data?.additionalResults?.[RELATED_PROFORMA_ADDITIONAL_RESULT_IDX]
        .find((row: IProformaEntity) => row.TransactionCurrencyCode !== value);
};

const getProformaTab = (isCbaCompany: boolean): IFormGroupDef => {
    return {
        id: PROFORMA_FORM_TAB,
        title: i18next.t("Document:FormTab.Proforma"),
        table: getDocumentPairedTable({
            selectQuery: PROFORMA_DOC_LINK_FILTER,
            addTransactionAmountColumn: true,
            isCbaCompany
        })
    };
};

export const addProformaDef = (definition: IDefinition, hasAccountAssignment: boolean): void => {

    definition.form.customHeaderActions = definition.form.customHeaderActions ?? [];

    definition.form.customHeaderActions.push({
        id: DEDUCT_DEPOSIT_ACTION,
        label: i18next.t("Proforma:Pairing.DeductDeposit"),
        iconName: "PairingDeposit",
        isVisible: not(isFormReadOnly),
        isDisabled: isDocumentLocked
    });

    definition.form.additionalProperties.push({
        id: DocumentEntity.DocumentLinks,
        additionalProperties: [
            { id: DocumentLinkEntity.Amount },
            { id: DocumentLinkEntity.TransactionAmount },
            { id: DocumentLinkEntity.TypeCode },
            { id: createPath(DocumentLinkEntity.TargetDocument, DocumentEntity.Id) }
        ]
    });

    const currencyDef = definition.form.fieldDefinition.TransactionCurrency;
    if (currencyDef.validator) {
        logger.error("Resolve validator conflict on TransactionCurrency with Proforma Currency validator");
    }

    currencyDef.validator = {
        type: ValidatorType.Custom,
        settings: {
            customValidator: [{
                validator: hasSameCurrencyAsProforma,
                message: i18next.t("Error:ProformaInvoiceShouldHaveSameTransactionCurrencyAsInvoice")
            }]
        }
    };

    const tabsGroup: IFormGroupDef = definition.form.groups.find(group => group.id === DOCUMENT_TABLES_GROUP_ID);
    tabsGroup.tabs.push(getProformaTab(!hasAccountAssignment));
};

export async function loadRelatedProformas(bindingContext: BindingContext, oData: OData, proformaBcs?: BindingContext[], oneFetch?: TFetchFn): Promise<IProformaEntity[]> {
    if ((bindingContext.isNew() || isProformaBc(bindingContext)) && !proformaBcs) {
        return [];
    }
    const isExpense = isReceivedBc(bindingContext);
    const entitySet = isExpense ? EntitySetName.ProformaInvoicesReceived : EntitySetName.ProformaInvoicesIssued;
    let filter: string;

    if (!isDefined(proformaBcs)) {
        // load all saved links according to invoice BC
        const docId = bindingContext.getKey();
        filter = `OppositeDocumentLinks/any(link: link/TypeCode in ('${DocumentLinkTypeCode.ProformaInvoiceDeduction}') AND link/SourceDocument/Id eq ${docId})`;
    } else if (proformaBcs?.length) {
        // load explicit Ids specified in 3rd parameter
        const ids = proformaBcs.map(bc => bc.getKey());
        filter = `Id in (${ids.join(",")})`;
    } else {
        // nothing to load (proformaBcs was specified, but is empty)
        return [];
    }

    const res = await oData.getEntitySetWrapper(entitySet).query()
        .filter(filter)
        .select("Id", "IsTaxDocument", "TransactionCurrencyCode", "TransactionAmount", "TransactionAmountNet", "TransactionAmountVat")
        .fetchData<IProformaEntity[]>(oneFetch);

    return res.value;
}

export function createProformaDocumentLink(bc: BindingContext, entity: IProformaEntity): IDocumentLinkEntity {
    const type = bc.getEntitySet().getType().getFullName();
    return {
        TargetDocument: {
            _metadata: {
                "": { type: `#${type}` }
            },
            Id: parseInt(bc.getKey() as string)
        },
        TypeCode: DocumentLinkTypeCode.ProformaInvoiceDeduction,
        Amount: -1 * entity.Amount,
        TransactionAmount: -1 * entity.TransactionAmount
    };
}


export const fetchAndApplyProformaInvoice = async (storage: FormStorage, proformaBcs: BindingContext[]): Promise<void> => {
    const SUM_FIELDS = [ProformaInvoiceIssuedEntity.TransactionAmount, ProformaInvoiceIssuedEntity.TransactionAmountNet, ProformaInvoiceIssuedEntity.AmountNet, ProformaInvoiceIssuedEntity.Amount] as const;
    // fields to copy from proforma to invoice - just prefixes, so all fields with these prefixes from definition will be copied
    const COPY_FIELDS = [
        "Items",
        DocumentEntity.TransactionCurrency,
        DocumentEntity.BusinessPartner,
        DocumentEntity.VatClassificationSelection,
        DocumentEntity.Labels,
        ...SUM_FIELDS
    ];

    const isCBACompany = isCashBasisAccountingCompany(storage.context);
    const hasAccountAssignment = isAccountAssignmentCompany(storage.context);

    if (isCBACompany) {
        // in case of CBA company, we don't want to copy the cashbox
        COPY_FIELDS.push(DocumentEntity.CbaCategory);
    }

    const invoiceMergedDef = cloneDeep((storage as FormStorage).data.mergedDefinition);

    forEachKey(invoiceMergedDef, (key) => {
        if (BindingContext.isLocalContextPath(key) || !COPY_FIELDS.find(name => key.toString().startsWith(name))) {
            delete invoiceMergedDef[key];
        }
    });

    // don't copy any of the date fields
    const dateGroup = storage.data.definition.groups.find(group => group.id === "date");

    for (const row of dateGroup.rows) {
        for (const field of row) {
            delete invoiceMergedDef[field.id];
        }
    }

    // columns prepared from document mergedDef, so all configured fields in the variant are populated
    const mergedDefColumns = storage.convertMergedDefToColumns(invoiceMergedDef);
    const documentTypeCode = storage.data.entity.DocumentTypeCode;
    const bpDefaultPropName = getDefaultPropName(documentTypeCode);
    // add extra columns, which is needed to do the calculations, but are not copied to the document
    const extraColumns = [
        { id: ProformaInvoiceIssuedEntity.CalculateVat },
        { id: ProformaInvoiceIssuedEntity.IsTaxDocument },
        { id: ProformaInvoiceIssuedEntity.DateIssued },
        { id: createPath(DocumentEntity.BusinessPartner, DocumentBusinessPartnerEntity.BusinessPartner, bpDefaultPropName) }
    ];

    const columns = [...mergedDefColumns, ...extraColumns];
    const batch = storage.oData.batch();
    batch.beginAtomicityGroup("queryInvoices");

    proformaBcs.forEach(proformaBc => {
        prepareQueryForBatch({
            batch,
            bindingContext: proformaBc,
            fieldDefs: columns
        });
    });
    const results = await batch.execute<IProformaEntity[]>();

    const invoices: IProformaEntity[] = [];
    let businessPartnerId: number | null = undefined,
        hasSameBP = true;
    let labels: IDocumentLabelEntity[] = undefined,
        hasSameLabels = true;
    let cbaCategory: IDocumentCbaCategoryEntity = undefined,
        hasSameCBACategory = isCBACompany;

    const _compareData = (data: IEntity, allowEmpty = false) => {
        const currentBPId = data.BusinessPartner?.BusinessPartner?.Id;
        const currentLabels = data.Labels;
        const currentCBACategory = data.CbaCategory;

        // There is different logic for BP. If empty, it's skipped in comparison.
        if (businessPartnerId === undefined) {
            businessPartnerId = isDefined(currentBPId) ? currentBPId : undefined;
        } else {
            hasSameBP = hasSameBP && (isNotDefined(currentBPId) || businessPartnerId === currentBPId);
        }

        // we cannot skip not set labels of proformas, because even empty labels may change value of item with "Default" selection
        // the only labels, which can be skipped are on main invoice document without filled items
        if (labels === undefined) {
            labels = (!currentLabels || currentLabels?.length === 0) ? (allowEmpty ? undefined : []) : currentLabels;
        } else {
            hasSameLabels = hasSameLabels && compareLabels(labels, currentLabels ?? null);
        }

        if (cbaCategory === undefined) {
            // we ignore first (defaulted) CBA category, because there are no items, which would be affected by it
            cbaCategory = allowEmpty ? undefined : (currentCBACategory ?? null);
        } else {
            hasSameCBACategory = hasSameCBACategory && isSameCbaCategory(cbaCategory, currentCBACategory);
        }
    };

    const _canReplaceHeaderItemsData = storage.data.bindingContext.isNew() && !storage.isDirtyPath("Items");
    _compareData(storage.data.entity, _canReplaceHeaderItemsData);

    results.forEach((result, idx) => {
        if (isBatchResultOk(result)) {
            const proformaInvoice = result.body.value ?? {};
            invoices.push(proformaInvoice);

            _compareData(proformaInvoice);
        }
    });

    // sort according to issuedDate
    invoices.sort((a: IProformaEntity, b: IProformaEntity) => {
        const diff = sortCompareFn(a.DateIssued, b.DateIssued);
        if (diff === 0) {
            return sortCompareFn(a.Id, b.Id);
        }
        return diff;
    });

    // prefer to copy data from tax document, if available, because it has more data to copy (e.g. VAT rule)
    const invoiceToCopy: IProformaEntity = cloneDeep(invoices.find(invoice => invoice.IsTaxDocument) ?? invoices[0]);
    const isCopyOfTaxDocument = invoiceToCopy.IsTaxDocument;

    extraColumns.forEach(col => {
        delete invoiceToCopy[col.id as keyof IProformaEntity];
    });

    if (!hasSameLabels) {
        delete invoiceToCopy.Labels;
    }
    if (!hasSameCBACategory || isObjectEmpty(cbaCategory)) {
        delete invoiceToCopy.CbaCategory;
    }

    // if all documents has same BP, we:
    //  1. apply it on the document
    //  2. get available vat rules for that BP
    //  3. get default rule from the BP or default rule from available rules
    //  4. we can check if all documents has same rule, if not, we can't assume the correct one -> clear it
    // if all documents don't have same BP, we get the available rules from current formView and check the default rule from it:
    const info = getInfoByPath(storage, SAVED_VATS_PATH);
    const canReplaceVatRule = _canReplaceHeaderItemsData && !storage.isDirtyPath(SAVED_VATS_PATH);

    if (hasSameBP) {
        // set BP data to storage entity, so we can obtain available VAT rules
        storage.setValueByPath(DocumentEntity.BusinessPartner, invoiceToCopy.BusinessPartner);
        // invalidate items, so we get new VAT rules according to the BP
        if (info.fieldSettings.items) {
            delete info.fieldSettings.items;
        }
        // default VAT rule according to BP in case there are no items changed locally or saved and rule is not Saved
        if (canReplaceVatRule) {
            const availableVatRules = await getRenderedItemsByInfo(storage, info);

            const BPDefaultRule = hasSameBP ? getDefaultVatRuleFromBP(invoiceToCopy.BusinessPartner, documentTypeCode) : null;
            const defaultVatRule = getDefaultVatRuleFromItems(availableVatRules, BPDefaultRule);
            const savedVatsBc = storage.data.bindingContext.navigate(SAVED_VATS_PATH);
            storage.setValue(savedVatsBc, defaultVatRule?.id);
            if (defaultVatRule) {
                storage.processDependentField(getVatDependentFields(), defaultVatRule.additionalData, savedVatsBc);
            } else {
                storage.setValueByPath("VatClassificationSelection", {});
            }
        }
    } else {
        // do not
        delete invoiceToCopy.BusinessPartner;
    }

    let vatRule: IDocumentVatClassificationSelectionEntity = undefined,
        hasSameVatRule = true;

    const _compareVatData = (data: IEntity, isMainInvoice = false): void => {
        if (!isMainInvoice && !data.IsTaxDocument) {
            // skip comparison for non-tax documents as they don't have VAT rules
            return;
        }
        const currentRule = data.VatClassificationSelection;
        if (vatRule === undefined) {
            // first proforma, nothing to compare, just fill values
            vatRule = (isMainInvoice && canReplaceVatRule) ? undefined : (currentRule ?? null);
        } else {
            // comparison
            hasSameVatRule = hasSameVatRule && isVatRuleSame(vatRule, currentRule);
        }
    };

    _compareVatData(storage.data.entity, true);

    invoices.forEach((proformaInvoice) => {
        _compareVatData(proformaInvoice);
    });

    if (!hasSameVatRule) {
        // when user picks proformas with different VAT rules, we can't assume the correct one,
        // so set it empty to force user to select some and think about it
        invoiceToCopy.VatClassificationSelection = {};
        storage.setValueByPath(SAVED_VATS_PATH, null);
    } else if (isCopyOfTaxDocument) {
        // delete just IDs, so new objects are created on BE
        // correct item ID is added in onAfterLoad
        delete invoiceToCopy.VatClassificationSelection?.Id;
        delete invoiceToCopy.VatClassificationSelection?.VatClassification?.Id;
    } else {
        // keep default vat rule on the invoice document
        delete invoiceToCopy.VatClassificationSelection;
    }
    // set SAVED_VATS_PATH dirty, so it's not redefaulted in onAfterLoad
    storage.setDirty(storage.data.bindingContext.navigate(SAVED_VATS_PATH));

    storage.clearEmptyLineItems("Items");
    const originalItemsLength = storage.data.entity.Items?.length ?? 0;
    invoiceToCopy.Items = [...storage.data.entity.Items];

    const itemToOriginalProforma: IProformaEntity[] = [];

    let maxNewId = getNewItemsMaxId(storage.data.entity.Items);
    let maxOrder = originalItemsLength ? storage.data.entity.Items?.[originalItemsLength - 1].Order : 0;

    const _applyItemFn = (invoice: IProformaEntity, { Id, ...itemProps }: IRegularDocumentItemEntity) => {
        itemToOriginalProforma.push(invoice);
        itemProps.Order = ++maxOrder;
        // remove ID from items, so new IDs are created on BE
        return BindingContext.createNewEntity(++maxNewId, itemProps);
    };

    const summaryFields: Partial<IDocumentEntity> = {};
    SUM_FIELDS.forEach(field => {
        summaryFields[field] = storage.data.entity[field] ?? 0;
    });

    // concatenate items from all Proformas
    for (let i = 0; i < invoices.length; i++) {
        SUM_FIELDS.forEach(field => {
            summaryFields[field] += (invoices[i][field] ?? 0);
        });
        const copiedItems = invoices[i].Items?.map(_applyItemFn.bind(null, invoices[i])) ?? [];
        invoiceToCopy.Items = [...invoiceToCopy.Items, ...copiedItems];
    }

    // delete original proforma ID
    delete invoiceToCopy.Id;

    storage.data.entity = {
        ...storage.data.entity,
        ...invoiceToCopy,
        ...summaryFields
    };

    const bc = storage.data.bindingContext.navigate("Items");
    const items = (storage.data.entity.Items ?? []) as IRegularDocumentItemEntity[];

    items.forEach((item, idx) => {
        if (idx < originalItemsLength) {
            // we do not process items, which was already in the invoice
            return;
        }
        const itemBc = bc.addKey(item);
        const origProforma = itemToOriginalProforma[idx - originalItemsLength];
        let newItemValues: IEntity = {};

        // remove AccountAssignment as it does not make sense to copy it from Proforma
        delete item.AccountAssignmentSelection;
        if (hasAccountAssignment) {
            newItemValues.AccountAssignmentSelection = { Selection: { Code: SelectionCode.Default }, SelectionCode: SelectionCode.Default };
        }

        if (!origProforma.IsTaxDocument) {
            if (!origProforma.CalculateVat) {
                const itemVatBc = itemBc.navigate("Vat");
                storage.setDefaultValue(itemVatBc);
                const vat = storage.getValue(itemVatBc, { useDirectValue: true });
                const decimalPlaces = storage.data.entity.Currency?.MinorUnit ?? 2;
                newItemValues = {
                    ...newItemValues,
                    ...calcLineItemValues({
                        Quantity: item.Quantity,
                        TransactionUnitPrice: item.TransactionUnitPrice,
                        Vat: (vat?.Code && vat?.Rate) ?? 0
                    }, "TransactionUnitPrice", null, decimalPlaces)
                };

                delete newItemValues.Vat;
            }

            // always set default selection for Items, if not taxDocument, where selection is already selected
            newItemValues[ITEMS_VAT_DEDUCTION_PATH] = SelectionCode.Default;
        } else {
            // handle VatRule for tax documents
            if (!hasSameVatRule && item.VatClassificationSelection.SelectionCode === SelectionCode.Default) {
                // in case VatRule is not same on all documents and item is using default, transfer possible setting to the item rule
                const origRule = origProforma.VatClassificationSelection.VatClassification;
                if (origRule.VatDeductionTypeCode) {
                    newItemValues.VatClassificationSelection = {
                        SelectionCode: SelectionCode.Own,
                        VatClassification: BindingContext.createNewEntity(++maxNewId, {
                            VatDeductionTypeCode: origRule.VatDeductionTypeCode,
                            VatDeductionType: {
                                Code: origRule.VatDeductionTypeCode,
                                Name: getEnumDisplayValue(EntityTypeName.VatDeductionType, origRule.VatDeductionTypeCode)
                            },
                            VatProportionalDeduction: origRule.VatProportionalDeduction,
                            NonTaxAccount: origRule.NonTaxAccount
                        })
                    };
                    newItemValues[ITEMS_VAT_DEDUCTION_PATH] = origRule.VatDeductionTypeCode;
                } else {
                    // nothing to transfer, just set Default selection
                    newItemValues.VatClassificationSelection = {
                        SelectionCode: SelectionCode.Default,
                        VatClassification: null
                    };
                    newItemValues[ITEMS_VAT_DEDUCTION_PATH] = SelectionCode.Default;
                }
            }
        }

        // handle CbaCategory
        if (isCBACompany && !hasSameCBACategory && isObjectEmpty(item.CbaCategory)) {
            const { Id, ...origCategory } = origProforma.CbaCategory;
            newItemValues.CbaCategory = BindingContext.createNewEntity(++maxNewId, origCategory);
        }

        // handle labels
        let labelsCode = item.LabelSelection?.SelectionCode;
        if (!hasSameLabels && item.LabelSelection.SelectionCode === SelectionCode.Default) {
            labelsCode = SelectionCode.Own;
        }
        newItemValues.LabelSelection = labelsCode ? {
            SelectionCode: labelsCode,
            Labels: item.LabelSelection.Labels?.map(({ Id, ...labelProps }) =>
                BindingContext.createNewEntity(++maxNewId, { ...labelProps }))
        } : null;

        storage.setValue(itemBc, {
            ...item,
            ...(newItemValues ?? {}),
        });

        storage.setDirty(itemBc.navigate(ITEMS_VAT_DEDUCTION_PATH));
    });

    // in case proforma is from different FY than the new invoice, we need to correct IDs of account
    // assignments to match the new FY (NonTaxAccount on VatRule)
    await refreshAccountIdsForActualFiscalYear(storage, storage.data.entity.FiscalYear);

    // set fields as dirty to work correctly
    BindingContext.each(storage.data.entity, storage.data.bindingContext, (_, bc) => {
        // only set for fields, not their "parent" bc
        if (!bc.getKey()) {
            setDirtyFlag(storage, bc);
        }

        return true;
    });

    // call DocumentFormView.onAfterLoad to call all necessary handlers
    // it should remove the storage busy state as well
    storage.emitter.emit(ModelEvent.AfterLoad, true);
};