import React from "react";
import { EmojiIcon } from "../icon";
import { IconSize } from "../../enums";
import { IconButton } from "../button";
import FocusManager, { FocusDirection } from "../focusManager";
import { ScrollBar } from "../scrollBar";
import { KeyName } from "../../keyName";
import { WithHideOnBlur, withHideOnBlur } from "../../HOC/withHideOnBlur";
import { composeRefHandlers } from "@utils/general";
import { PopperTooltipStyled, StyledEmojiPicker } from "./EmojiPicker.styles";
import { IProps as IPopperTooltipProps } from "../popperTooltip/PopperTooltip";

const EMOJI_LIST =
    "😀 😃 😄 😁 😆 😅 😂 🤣 😊 😇 🙂 🙃 😉 😌 😍 🥰 😘 😗 😙 😚 😋 😛 😝 😜 🤪 🤨 🧐 🤓 😎 🤩 🥳 😏 😒 😞 😔 😟 😕 🙁 😣 😖 😫 😩 🥺 😢 😭 😤 😠 😡 🤬 🤯 😳 🥵 🥶 😱 😨 😰 😥 😓 🤗 🤔 🤭 🤫 🤥 😶 😐 😑 😬 🙄 😯 😦 😧 😮 😲 🥱 😴 🤤 😪 😵 🤐 🥴 🤢 🤮 🤧 😷 🤒 🤕 🤑 🤠 😈 👿 👹 👺 🤡 💩 👻 💀 👽 👾 🤖 🎃 😺 😸 😹 😻 😼 😽 🙀 😿 😾"
        .split(" ");

interface IProps extends Pick<IPopperTooltipProps, "inPlace" | "domPortalParent"> {
    onEmojiSelect?: (emoji: string) => void;
}

class EmojiPicker extends React.PureComponent<IProps & WithHideOnBlur> {
    tooltipRef = React.createRef<HTMLDivElement>();
    buttonRef = React.createRef<HTMLDivElement>();

    componentDidUpdate(prevProps: Readonly<IProps & WithHideOnBlur>, prevState: Readonly<{}>, snapshot?: any) {
        if (!prevProps.isVisible && this.props.isVisible) {
            this.props.setHideOnBlurOptions([this.buttonRef, this.tooltipRef]);
        }
    }

    handleClick = () => {
        this.props.setIsVisible(!this.props.isVisible);
    };

    renderEmojis = () => {
        return (
            <ScrollBar>
                <FocusManager direction={FocusDirection.Grid} columnsCount={8}>
                    {({ itemProps, wrapperProps }) => (
                        <StyledEmojiPicker {...wrapperProps as any}>
                            {EMOJI_LIST.map(emoji => {
                                return (
                                    // suppress warning - focus handled by FocusManager
                                    // eslint-disable-next-line jsx-a11y/interactive-supports-focus
                                    <span role={"button"}
                                          key={emoji}
                                          {...itemProps}
                                          style={{ cursor: "pointer", fontSize: "16px" }}
                                          onClick={(event) => {
                                              event.preventDefault();
                                              this.props.onEmojiSelect?.(emoji);
                                          }}
                                          onKeyDown={(event) => {
                                              if (event.key === KeyName.Enter) {
                                                  this.props.onEmojiSelect?.(emoji);
                                              }
                                          }}
                                    >
                                        {emoji}
                                    </span>
                                );
                            })}
                        </StyledEmojiPicker>
                    )}
                </FocusManager>
            </ScrollBar>
        );
    };

    render() {
        return (
            <PopperTooltipStyled content={this.renderEmojis()}
                                 isHidden={!this.props.isVisible}
                                 placement={"bottom"}
                                 passRef={this.tooltipRef}
                                 domPortalParent={this.props.domPortalParent}
                                 inPlace={this.props.inPlace}
                                 offsetY={5}
                                 color={"white"}>
                {(ref) => {
                    return (
                        <IconButton title={"emoji"}
                                    passRef={composeRefHandlers(this.buttonRef, ref)}
                                    onClick={this.handleClick}
                                    isTransparent
                                    isLight>
                            <EmojiIcon width={IconSize.S}/>
                        </IconButton>
                    );
                }}
            </PopperTooltipStyled>
        );
    }
}

export default withHideOnBlur(EmojiPicker);