import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import { BasicInputSizes, TextAlign } from "../../../enums";
import { IInputOnChangeEvent } from "../../inputs/input";
import WriteLine from "../../inputs/writeLine/WriteLine";

interface IProps {
    isLight?: boolean;
    onChange?: (val: string) => void;
    // otherwise doesn't work for typescript, because of withTranslation HOC
    ref?: React.RefObject<React.Component<IProps, IState>>;
}

export type { IProps as ISearchFieldProps };

interface IState {
    searchValue: string;
}

export type { IState as ISearchFieldState };

// Render as standalone component instead of adding this as part of SmartFastEntryList.
// Re-rendering of SmartFastEntryList on every SearchField would be too slow.
class SearchField extends React.PureComponent<IProps & WithTranslation, IState> {
    state: IState = {
        searchValue: ""
    };

    handleSearchChange = (args: IInputOnChangeEvent<string>) => {
        this.setState({
            searchValue: args.value
        });

        this.props.onChange?.(args.value);
    };

    render() {
        return (
            <WriteLine placeholder={this.props.t("Components:SmartFastEntryList.Search")}
                       value={this.state.searchValue}
                       isLight={this.props.isLight}
                       onChange={this.handleSearchChange}
                       withClearButton
                       textAlign={TextAlign.Left}
                       width={BasicInputSizes.L}
                       style={{ marginBottom: "20px" }}
            />
        );
    }
}

export default withTranslation(["Components"], { withRef: true })(SearchField);