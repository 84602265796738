import { getDetailRouteByEntityType } from "@odata/EntityTypes";
import { EntityTypeName } from "@odata/GeneratedEntityTypes";
import { MetadataLockType, metadataRuleTypes } from "@pages/documents/Document.utils";
import { isCashBasisAccountingCompany } from "@utils/CompanyUtils";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import { FormMode, IconSize, Status } from "../../../enums";
import { StorageModel } from "../../../model/StorageModel";
import TestIds from "../../../testIds";
import memoizeOne from "../../../utils/memoizeOne";
import { getDisabledFormPageAlert, getSummaryItems } from "../../../views/formView/Form.utils";
import { FormStorage } from "../../../views/formView/FormStorage";
import Clickable from "../../clickable";
import { getDrillDownVariant } from "../../drillDown/DrillDown.utils";
import Header, { IHeaderIcon } from "../../header/Header";
import Summary from "../../summary/Summary";
import { TooltipIconInfo } from "../../tooltipIcon";
import VariantSelector from "../../variantSelector/VariantSelector";
import { ISmartFieldChange } from "../smartField/SmartField";
import SmartSummaryItem, { ISummaryItem } from "../smartSummaryItem/SmartSummaryItem";
import { HeaderInfo, HeaderInfoText, StyledVariantVersionWrapper } from "./SmartHeader.styles";
import { IGetSmartHeaderCustomInfo } from "./SmartHeader.utils";

interface IProps extends WithTranslation {
    title?: string;
    subtitle?: string;
    subtitleStatus?: Status;
    storage?: StorageModel;
    className?: string;
    shouldHideVariant?: boolean;
    customHeaderContent?: React.ReactElement;
    hotspotId?: string;

    icons?: IHeaderIcon[],

    // can be used to define custom header info from extended FormView
    getCustomHeaderInfo?: (storage: StorageModel) => IGetSmartHeaderCustomInfo;

    onChange?: (args: ISmartFieldChange) => void;
    ref?: React.Ref<React.ReactElement>;
}

class SmartHeader extends React.Component<IProps> {
    /** The purpose of the info text is to provide an explanation for why certain form fields are disabled.
     * It does so by checking for presence of particular error messages for disabled fields from the evala.metadata. */
    getInfo = memoizeOne(() => {
        if (!this.props.storage) {
            return {};
        }
        const foundCodes = new Set<string>();
        const storage = this.props.storage as FormStorage;
        let infoText = "";
        const infoTooltipLines: React.ReactNode[] = [];

        for (const ruleType of metadataRuleTypes) {
            for (const rules of Object.values(storage?.data?.metadata?.metadata?.[ruleType] ?? {})) {
                for (const rule of rules) {
                    if (!foundCodes.has(rule.ErrorCode)) {

                        const isProcessed = [
                            MetadataLockType.DocumentCannotBeUpdatedInClosedFiscalYear,
                            MetadataLockType.InternalDocumentCanBeEditedOnlyByInternalFeatureProcessing
                        ].includes(rule.ErrorCode as MetadataLockType) || this.props.i18n.exists(`Common:DisabledFieldsInfoTooltip.Start${rule.ErrorCode}`);
                        if (!isProcessed) {
                            console.warn(`SmartHeader.getInfo: Evala metadata error code (${rule.ErrorCode}) with "Start" prefix missing in translations. Add it into Common.toml DisabledFieldsInfo section`);
                            continue;
                        }
                        foundCodes.add(rule.ErrorCode);

                        if (foundCodes.size > 1) {
                            infoTooltipLines.push(<br key={`br-${rule.ErrorCode}`}/>);
                        }

                        const uniqLinkedEntities = rule.LinkedEntities?.filter((linkedEntity, index, self) =>
                                self.findIndex(t => t.Id === linkedEntity.Id) === index);

                        if (rule.ErrorCode === MetadataLockType.DocumentCannotBeUpdatedInClosedFiscalYear && isCashBasisAccountingCompany(storage.context)) {
                            infoTooltipLines.push(this.props.t(`Common:DisabledFieldsInfoTooltip.CbaDocumentCannotBeUpdatedInClosedYear`, { year: rule.LinkedEntities?.[0]?.DisplayName }));
                        } else if (rule.ErrorCode === MetadataLockType.InternalDocumentCanBeEditedOnlyByInternalFeatureProcessing) {
                            infoTooltipLines.push(this.props.t(`Common:DisabledFieldsInfoTooltip.InternalDocumentCanBeEditedOnlyByInternalFeatureProcessing`));
                        } else {
                            infoTooltipLines.push((
                                <React.Fragment key={rule.ErrorCode}>
                                    {`${this.props.t(`Common:DisabledFieldsInfoTooltip.Start${rule.ErrorCode}`)}`}
                                    {uniqLinkedEntities.map((linkedEntity, index) => {
                                        let route = "";

                                        route = getDetailRouteByEntityType(linkedEntity.TypeName as EntityTypeName, linkedEntity.Id, true);

                                        return (
                                            <React.Fragment key={`${linkedEntity.TypeName}-${linkedEntity.Id}`}>
                                                <Clickable link={route} isLight>
                                                    {` ${linkedEntity.DisplayName}`}
                                                </Clickable>
                                                {index < uniqLinkedEntities.length - 1 ? ", " : ""}
                                            </React.Fragment>
                                        );
                                    })}
                                    {`. ${this.props.t(`Common:DisabledFieldsInfoTooltip.End${rule.ErrorCode}`)}`}
                                </React.Fragment>
                            ));
                        }
                    }
                }
            }
        }

        for (const errorCode of foundCodes) {
            const tKey = `Common:DisabledFieldsInfo.${errorCode}`;

            if (this.props.i18n.exists(tKey)) {
                if (!infoText) {
                    infoText += `${this.props.t("Common:DisabledFieldsInfo.InfoStart")} `;
                } else if (infoText) {
                    infoText += " / ";
                }

                infoText += this.props.t(tKey);
            } else {
                console.warn(`SmartHeader.getInfo: Evala metadata error code (${errorCode}) missing in translations. Add it into Common.toml DisabledFieldsInfo section`);
            }
        }

        return { infoText, infoTooltip: infoTooltipLines };
    }, () => {
        const storage = this.props.storage as FormStorage;

        return [storage?.data?.metadata?.metadata?.EnabledPropertyRules, storage?.data?.metadata?.metadata?.DisabledPropertyRules];
    });

    renderInfoText = () => {
        if (this.props.storage && (this.props.storage.isReadOnly || (this.props.storage as FormStorage).formMode === FormMode.AuditTrail)) {
            return null;
        }

        const {
            infoText,
            infoTooltip
        } = this.props.getCustomHeaderInfo?.(this.props.storage) ?? this.getInfo();


        if (!infoText) {
            return null;
        }

        return (
            <HeaderInfo>
                {infoTooltip &&
                    <TooltipIconInfo size={IconSize.XS}>
                        {infoTooltip}
                    </TooltipIconInfo>
                }
                <HeaderInfoText data-testid={TestIds.HeaderInfoText} hasTooltip={!!infoTooltip}>
                    {infoText}
                </HeaderInfoText>
            </HeaderInfo>
        );
    };

    render() {
        const variantSelector = (
            <StyledVariantVersionWrapper>
                <VariantSelector
                    isDisabled={!!getDrillDownVariant()}
                    hotspotId={this.props.hotspotId ? `${this.props.hotspotId}-variantSelector` : undefined}
                    storage={this.props.storage}/>
            </StyledVariantVersionWrapper>
        );
        const beforeIconsContent = this.props.shouldHideVariant ? this.props.customHeaderContent : variantSelector;
        const summaryItems: ISummaryItem[] = getSummaryItems(this.props.storage as FormStorage);
        const summaryContent = (summaryItems || []).length > 0 ? (
            <Summary>
                {summaryItems.map(summaryItem => {
                    return (
                        <SmartSummaryItem key={summaryItem.id}
                                          storage={this.props.storage as FormStorage}
                                          item={summaryItem}
                                          onChange={this.props.onChange}/>
                    );
                })}
            </Summary>
        ) : null;
        const infoTextContent = this.renderInfoText();
        const afterIconsContent = (summaryContent || infoTextContent) ? (
            <>
                {summaryContent}
                {infoTextContent}
            </>
        ) : null;

        return (
            <Header
                title={this.props.title}
                subtitle={this.props.subtitle}
                subtitleStatus={this.props.subtitleStatus}
                hotspotId={this.props.hotspotId}
                isDisabled={(this.props.storage as FormStorage)?.data?.disabled}
                disabledAlert={this.props.storage?.isDisabled && getDisabledFormPageAlert()}
                icons={this.props.icons}
                beforeIconsContent={beforeIconsContent}
                afterIconsContent={afterIconsContent}
                className={this.props.className}
            />
        );
    }
}

export default withTranslation(["Common"], { withRef: true })(SmartHeader);