import styled from "styled-components/macro";
import { T_ALERT_SUBTITLE } from "../../global.style";
import { isDefined } from "@utils/general";
import { themes } from "../../theme";

const getOffsetValue = (offset: number) => {
    return isDefined(offset) ? `${offset}px` : null;
};

export const BadgeStyled = styled.div<{
    $offset?: number;
    $offsetTop?: number;
    $offsetRight?: number;
    $ignoreTheme?: boolean;
    $small?: boolean;
}>`
    position: absolute;
    top: ${props => getOffsetValue(props.$offsetTop ?? props.$offset)};
    right: ${props => getOffsetValue(props.$offsetRight ?? props.$offset)};
    height: ${props => props.$small ? 17 : 20}px;
    padding: 1px 6.5px 0 6.5px;
    line-height: 0;
    border-radius: 12px;
    display: flex;
    align-items: center;
    color: ${props => props.$ignoreTheme ? themes.light.C_TEXT_purple_bg : props.theme.C_TEXT_purple_bg};
    background-color: ${props => props.$ignoreTheme ? themes.light.C_ACT_der : props.theme.C_ACT_der};

    ${T_ALERT_SUBTITLE};
`;