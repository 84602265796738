import React, { memo, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { IDashboardTileComponentProps } from "../../dashboard";
import { useTranslation } from "react-i18next";
import {
    Body,
    BodyWrapper,
    Content,
    ContentHeading,
    ContentText,
    HeaderListGroup,
    HeaderListItemStyledButton,
    ItemSpacing,
    Label,
    StyledTaxCalendarTile,
    SubHeading,
    TaxCalendarHeader
} from "./TaxCalendar.styles";
import { DashboardTileVerticalPadding } from "../../dashboard/Dashboard.styles";
import { ScrollBar } from "../../scrollBar";
import { CountryCode } from "@odata/GeneratedEnums";
import { withOData, WithOData } from "@odata/withOData";
import { ITaxCalendarEntity } from "@odata/GeneratedEntityTypes";
import { TRecordType } from "../../../global.types";
import DateType, { getUtcDayjs } from "../../../types/Date";
import { Dayjs } from "dayjs";
import { scrollIntoView } from "@utils/domUtils";
import DashboardTileHeader from "../../dashboard/DashboardTileHeader";
import { getTaxCalendarData } from "./TaxCalendar.utils";
import { ButtonSize } from "../../button/Button.utils";
import MonthYearSelector from "../../inputs/date/MonthYearSelector";
import { IInputOnChangeEvent } from "../../inputs/input";

interface IProps extends IDashboardTileComponentProps, WithOData {
}

function getDayLabelId(date: Dayjs): string {
    return `D_${date.get("date")}`;
}

interface IHeaderListItemProps {
    firstVisibleId: string;
    date: Dayjs;
    onClick: (id: string) => void;
}

const HeaderListItem: React.FC<IHeaderListItemProps> = ({ date, onClick, firstVisibleId }) => {

    const handleClick = useCallback(() => {
        onClick(getDayLabelId(date));
    }, [date, onClick]);
    const isActive = firstVisibleId === getDayLabelId(date);

    return (
            <HeaderListItemStyledButton onClick={handleClick}
                                        size={ButtonSize.Small}
                                        isTransparent={!isActive}>
                {date.get("date")}
            </HeaderListItemStyledButton>
    );
};

interface IMappedData {
    key: string;
    data?: ITaxCalendarEntity[];
    date: Dayjs;
}

const TaxCalendar: React.FC<IProps> = ({ inEditMode, info, oData }) => {
    const { t } = useTranslation("Home");
    const wrapperRef = useRef<HTMLDivElement>();
    const bodyRef = useRef<HTMLDListElement>();
    const scrollRef = useRef<HTMLDivElement>();

    const [loadedData, setData] = useState<ITaxCalendarEntity[]>(null);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [hasError, setError] = useState<boolean>(false);
    const [firstVisible, setFirstVisible] = useState<string>(null);
    const [currentDate, setCurrentDate] = useState<Dayjs>(getUtcDayjs());

    const Country = CountryCode.CzechRepublic;

    const [data, first, last] = useMemo(() => {
        let first: Dayjs, last: Dayjs;
        const monthStart = currentDate.startOf("month");
        const monthEnd = currentDate.endOf("month");
        const data: ITaxCalendarEntity[] = [];

        loadedData?.forEach(item => {
            const d = getUtcDayjs(item.Date);
            if (!first || first.isAfter(d, "day")) {
                first = d;
            }
            if (!last || last.isBefore(d, "day")) {
                last = d;
            }
            if (d.isBetween(monthStart, monthEnd, "day", "[]")) {
                data.push(item);
            }
        });

        return [data, first, last];
    }, [loadedData, currentDate]);

    useEffect(() => {
        let mounted = true;

        try {
            getTaxCalendarData(oData, { country: Country })
                    .then(data => mounted && setData(data))
                    .finally(() => mounted && setLoading(false));
        } catch (e) {
            if (mounted) {
                setError(true);
                setData(null);
            }
        }

        return () => {
            mounted = false;
        };
    }, [Country, oData]);

    const dataMap = useMemo(() => {
        const dataMap: TRecordType<IMappedData> = {};

        data?.forEach(item => {
            const key = item.Date.toString();
            if (!dataMap[key]) {
                dataMap[key] = {
                    key,
                    data: [],
                    date: getUtcDayjs(key)
                };
            }
            dataMap[key].data.push(item);
        });

        return dataMap;
    }, [data]);

    const Labels = useMemo(() => Object.keys(dataMap), [dataMap]);

    const handleNavClick = useCallback((id: string) => {
        const label = document.getElementById(id);
        if (label) {
            scrollIntoView(scrollRef.current, label, { behavior: "smooth" }, { top: -1 * ItemSpacing });
        }
    }, []);

    const handleChange = useCallback((e: IInputOnChangeEvent<Date>) => {
        setCurrentDate(getUtcDayjs(e.value));
    }, []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleScroll = useCallback(() => {
        const { scrollTop } = scrollRef.current;
        const labels = bodyRef.current.getElementsByTagName("dt");
        const currentLabel = Array.from(labels)?.find(label => label.offsetTop > scrollTop);
        setFirstVisible(currentLabel?.id);
    }, []);

    const scrollbarProps = useMemo(() => ({
        ref: scrollRef,
        onScroll: handleScroll
    }), [handleScroll]);

    useLayoutEffect(() => {
        const body = bodyRef.current;
        if (body?.children?.length && wrapperRef.current) {
            body.style.paddingBottom = "0";
            const wrapperHeight = wrapperRef.current.getBoundingClientRect().height;
            const labels = body.getElementsByTagName("dt");
            const addPadding = wrapperHeight - (body.getBoundingClientRect().height + DashboardTileVerticalPadding + ItemSpacing - labels[labels.length - 1].offsetTop);
            if (!isNaN(addPadding) && addPadding > 0) {
                body.style.paddingBottom = `${addPadding}px`;
            }
            handleScroll();
        }
    }, [data, handleScroll]);

    return (
            <StyledTaxCalendarTile inEditMode={inEditMode}
                                   overflowed
                                   isLoading={isLoading || hasError/*todo: may be different look for error state*/}>
                <TaxCalendarHeader>
                    <DashboardTileHeader>{t("Home:TaxCalendar.Heading")}</DashboardTileHeader>
                    <SubHeading centered>
                        <MonthYearSelector value={currentDate?.toDate()}
                                           minDate={first?.toDate()}
                                           maxDate={last?.toDate()}
                                           onChange={handleChange}/>
                    </SubHeading>
                    <HeaderListGroup>
                        {Labels.map(l => (
                                <HeaderListItem key={dataMap[l].key}
                                                firstVisibleId={firstVisible}
                                                onClick={handleNavClick}
                                                date={dataMap[l].date}/>
                        ))}
                    </HeaderListGroup>
                </TaxCalendarHeader>
                <BodyWrapper ref={wrapperRef}>
                    <ScrollBar scrollableNodeProps={scrollbarProps}>
                        <Body ref={bodyRef}>
                            {Labels.map(l => (
                                    <React.Fragment key={dataMap[l].key}>
                                        <Label id={getDayLabelId(dataMap[l].date)}>
                                            {DateType.format(dataMap[l].date, DateType.defaultDateFormat)}
                                        </Label>
                                        {dataMap[l].data.map(d => (
                                                <Content key={d.Id}>
                                                    <ContentHeading>{d.TaxType.Name}</ContentHeading>
                                                    <ContentText>{d.Description}</ContentText>
                                                </Content>
                                        ))}
                                    </React.Fragment>
                            ))}
                        </Body>
                    </ScrollBar>
                </BodyWrapper>
            </StyledTaxCalendarTile>
    );
};

export default memo(withOData(TaxCalendar));