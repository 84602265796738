import { HotspotViewIds } from "@components/hotspots/HotspotViewIds";
import { WithResizer, withResizer } from "@components/resizer/withResizer";
import { forEachKey } from "@utils/general";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import { AppContext } from "../../contexts/appContext/AppContext.types";
import { ExtendedShell, IExtendedShellPropsForExtend } from "../../views/main/ExtendedShell";
import { SHELL_WIDTH } from "../../views/main/ExtendedShell.style";
import DashboardCustomizationSection from "./DashboardCustomizationSection";
import { DashboardManager, IDashboardManager } from "./DashboardManager";
import { HomeDashboards } from "./Home";
import { ConfigurationHeader } from "./Home.style";
import { getVisibleDashboards } from "./Home.utils";

interface IProps extends Omit<IExtendedShellPropsForExtend, "width">, WithTranslation, WithResizer {
}

interface IState {
}

class DashboardCustomization extends React.PureComponent<IProps, IState> {
    static contextType = AppContext;
    //sadly, breaks typescript type checking
    //context: React.ContextType<typeof AppContext>;

    state: IState = {};
    _managers: IDashboardManager[];

    constructor(props: IProps) {
        super(props);

        this._managers = [];
        forEachKey(HomeDashboards, (id) => {
            this._managers.push(DashboardManager.get(id));
        });
    }

    renderHeader() {
        return (
            <ConfigurationHeader>
                {this.props.t("Home:Tiles")}
            </ConfigurationHeader>
        );
    }

    render() {
        const { onHide, ...passProps } = this.props;
        return (
            <ExtendedShell header={this.renderHeader()}
                           width={this.props.width}
                           panelRef={this.props.resizeRef}
                           padding={"56px 85px 66px"}
                           extraContent={this.props.resizeHandle}
                           hotspotContextId={HotspotViewIds.DashboardCustomization}
                           {...passProps}>
                {getVisibleDashboards(this._managers, this.context).map(manager => (
                    <DashboardCustomizationSection key={manager.name} manager={manager}/>
                ))}
            </ExtendedShell>
        );
    }
}

export default withTranslation(["Home"])(withResizer({
    initialWidth: SHELL_WIDTH,
    initialCollapsed: true,
    maxWidth: SHELL_WIDTH,
    minWidth: 120,
    borderColor: "C_ACT_thick_line"
})(DashboardCustomization));
