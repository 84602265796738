import { getOneFetch } from "@utils/oneFetch";
import i18next from "i18next";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import { FETCH_ABORT_ERROR } from "../../../constants";
import { AppContext } from "../../../contexts/appContext/AppContext.types";
import { Status } from "../../../enums";
import BindingContext, { areBindingContextsDifferent } from "../../../odata/BindingContext";
import memoizeOne from "../../../utils/memoizeOne";
import { IEditOverride } from "../../../views/formView/Form.utils";
import { FormStorage } from "../../../views/formView/FormStorage";
import { IAlertProps } from "../../alert/Alert";
import { Button } from "../../button";
import { getInfoValue, TInfoValue } from "../FieldInfo";
import { IValidateDeleteArgs, validateDelete } from "./SmartDelete.utils";
import { WarningList } from "./SmartFormDeleteButton.styles";

interface IProps {
    storage: FormStorage<any, any>;
    isDisabled?: TInfoValue<boolean>;
    onClick?: () => void;
}

interface IState {
    isDeletable: boolean;
    errors: string[];
}

export interface TDeleteCheckServiceResponse {
    EntityId: number;
    ErrorCodes: string[];
}

export interface TDeleteDependentCheckServiceResponse {
    DependentEntityId: number;
    ErrorCodes: string[];
}

class SmartFormDeleteButton extends React.Component<IProps & WithTranslation, IState> {
    static contextType = AppContext;
    lastBindingContext: BindingContext;
    lastFormUuid: string;
    ignoreNextUuidChange: boolean;
    lastFormEditOverride: IEditOverride;
    oneFetch = getOneFetch();

    state: IState = {
        isDeletable: false,
        errors: null
    };

    componentDidMount() {
        this.init();
    }

    componentDidUpdate(prevProps: IProps & WithTranslation, prevState: IState) {
        const bcChanged = areBindingContextsDifferent(this.lastBindingContext, this.props.storage.data.bindingContext);
        const uuIdChanged = this.lastFormUuid !== this.props.storage.data.uuid;

        if (
            bcChanged
            // reload if the form has been saved (changed)
            || (!this.ignoreNextUuidChange && uuIdChanged)
            || this.lastFormEditOverride !== this.props.storage.getEditOverride()
        ) {
            this.init();
        }

        // ignore first form load (uuid change) after bc has changed
        // to prevent validateDelete from calling two times
        if (bcChanged) {
            this.ignoreNextUuidChange = true;
        }

        if (uuIdChanged) {
            this.ignoreNextUuidChange = false;
            this.lastFormUuid = this.props.storage.data.uuid;
        }
    }

    componentWillUnmount() {
        this.oneFetch?.abort();
    }

    init = async () => {
        if (this.state.isDeletable) {
            this.setState({
                isDeletable: false,
                errors: null
            });
        }

        const bc = this.props.storage.data.bindingContext;

        if (!bc || bc.isNew()) {
            return;
        }

        this.lastBindingContext = bc;
        this.lastFormUuid = this.props.storage.data.uuid;
        this.lastFormEditOverride = (this.props.storage as FormStorage).getEditOverride();

        const args: IValidateDeleteArgs = {
            bindingContext: bc,
            ids: [bc.getKey()],
            fetchFn: this.oneFetch.fetch
        };

        // use editOverride (currently only used for, FORCE_VAT_LOCK_OVERRIDE_PARAM),
        // but can be changed to something more universal if needed
        const editOverride = this.lastFormEditOverride;

        if (editOverride) {
            // query param will change the validation logic on the backend
            args.queryParams = {
                [editOverride.id]: true
            };
        }

        try {
            const errors = await validateDelete(args);

            this.setState({
                // same behavior as in the table -> if no errors are returned for given
                // entity or first response is without errors, manage the entity as deletable
                isDeletable: !errors?.length || errors[0]?.ErrorCodes?.length === 0,
                errors: errors?.[0]?.ErrorCodes ?? []
            });
        } catch (e) {
            if (e.name !== FETCH_ABORT_ERROR) {
                throw (e);
            }
        }

    };

    getAlert = memoizeOne((): IAlertProps => {
        if (!this.state.errors || this.state.errors.length === 0
            || this.props.storage.isDisabled || this.state.isDeletable) {
            return null;
        }

        return {
            status: Status.Warning,
            isSmall: true,
            title: (
                <>
                    <span>{this.props.t("Common:Errors.CannotDeleteTitle")}</span>
                    <WarningList>
                        {this.state.errors.map(error => {
                            const key = `Error:${error}`;

                            return (
                                // sometimes, the message is already translated
                                <li key={error}>• {i18next.exists(key) ? this.props.t(key) : error}</li>
                            );
                        })}
                    </WarningList>
                </>
            )
        };
    }, () => [this.state.errors, this.props.storage.isDisabled, this.props.storage.data.bindingContext.toString(), this.props.tReady]);

    isDisabled = (): boolean => {
        return getInfoValue(this.props, "isDisabled", {
            storage: this.props.storage,
            bindingContext: this.props.storage.data.bindingContext,
            context: this.context
        }) || this.props.storage.isDisabled || !this.state.isDeletable;
    };

    isBusy = (): boolean => {
        return !!this.props.storage.getCustomData()?.isDeleteButtonBusy;
    };

    render() {
        return (
            <Button hotspotId={"formDelete"}
                    onClick={this.props.onClick}
                    hoverAlert={this.getAlert()}
                    isDisabled={this.isDisabled()}
                    isBusy={this.isBusy()}
                    isTransparent>{this.props.storage.t("Common:General.Delete")}</Button>
        );
    }
}

export default withTranslation(["Common", "Document", "Error"])(SmartFormDeleteButton);