import React from "react";
import { IInboxFileEntity } from "@odata/GeneratedEntityTypes";
import { fileNameToMime } from "@components/fileUploader/File.utils";
import {
    createInboxFileMessage,
    handleDeleteFile,
    handleRenameInboxFile,
    IInboxTableCustomData,
    InboxFilter,
    loadInboxFileDetail
} from "./Inbox.utils";
import BindingContext from "../../odata/BindingContext";
import { TableStorage } from "../../model/TableStorage";
import { FileToolbarItem, getFileViewer, IFileViewerProps } from "@components/fileViewers/FileViewers.utils";
import { FILES_API_URL } from "../../constants";
import FileRenameWriteLine from "../../views/fileView/FileRenameWriteLine";
import InboxFileDetailLayout from "./InboxFileDetailLayout";
import EmailBodyMessage from "./EmailBodyMessage";
import InboxChat from "./InboxChat";
import { ISmartODataTableAPI } from "@components/smart/smartTable/SmartODataTableBase";
import memoizeOne from "../../utils/memoizeOne";
import { withConfirmationDialog, WithConfirmationDialog } from "@components/dialog/withConfirmationDialog";
import BusyIndicator from "../../components/busyIndicator";
import { currentUserIsCustomer } from "../admin/users/Users.utils";
import { ApprovalStatusTypeCode, InboxFileSourceCode } from "@odata/GeneratedEnums";
import { WithAlert, withAlert } from "@components/alert/withAlert";
import { AlertPosition } from "@components/alert/Alert";
import { Status } from "../../enums";

export interface IProps extends WithConfirmationDialog, WithAlert {
    storage: TableStorage<ISmartODataTableAPI, IInboxTableCustomData>;
    bindingContext: BindingContext;
    onAfterRemoveFiles?: (bcs: BindingContext[]) => void;
}

interface IState {
    fileData: IInboxFileEntity;
}

class InboxFileDetail extends React.PureComponent<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.loadFileDetail();
    }

    componentDidUpdate(prevProps: IProps) {
        if (prevProps.bindingContext?.getKey() !== this.props.bindingContext?.getKey()) {
            this.loadFileDetail();
        }
    }

    get type(): InboxFilter {
        return this.props.storage.getCustomData().type;
    }

    get inboxFile(): IInboxFileEntity {
        return this.state?.fileData;
    }

    get selectedFileHasContents(): boolean {
        return !!this.inboxFile?.FileMetadata?.Id;
    }

    currentUserIsCustomer = memoizeOne(() => {
        return currentUserIsCustomer(this.props.storage.context);
    });

    loadFileDetail = async (): Promise<void> => {
        const fileData = await this.getFileDetail();
        this.setState({ fileData });
    };

    handleSelectedFileRemove = async (): Promise<void> => {
        const bc = this.props.bindingContext;
        if (await handleDeleteFile(this.props.storage, this.props.confirmationDialog, bc)) {
            this.props.onAfterRemoveFiles?.([bc]);
        }
    };

    // inboxFileData enhanced with detail information, communication, etc...
    getFileDetail(): Promise<IInboxFileEntity> {
        return loadInboxFileDetail(this.props.bindingContext, this.props.storage.oData);
    }

    getFileViewer = memoizeOne((): React.ReactElement => {
        const mime = this.inboxFile.FileMetadata?.Name && fileNameToMime(this.inboxFile.FileMetadata.Name);
        const src = this.inboxFile.FileMetadata?.Id && `${FILES_API_URL}/${this.inboxFile.FileMetadata?.Id}`;
        const hiddenItems = [FileToolbarItem.Dashboard];
        if (!src) {
            hiddenItems.push(FileToolbarItem.Download);
        }

        const props: IFileViewerProps = {
            src,
            hiddenItems,
            isReadOnly: false,
            onFileRemove: this.handleSelectedFileRemove
        };

        return (
                getFileViewer(mime, props)
        );
    }, () => [this.inboxFile]);

    handleFileNameChangeConfirm = async (newName: string): Promise<boolean> => {
        if (!newName) {
            return false;
        }

        if (await handleRenameInboxFile(this.props.storage, this.props.bindingContext, newName)) {
            this.inboxFile.FileMetadata.Name = newName;
            return true;
        }
        return false;
    };

    handleCreateMessage = async (message: string, status?: ApprovalStatusTypeCode): Promise<boolean> => {
        const { storage, bindingContext } = this.props;
        const newStatus = (status ?? this.state.fileData.ApprovalStatusTypeCode) as ApprovalStatusTypeCode;
        const result = await createInboxFileMessage(storage.oData, bindingContext, message, newStatus);
        if (!result) {
            // show error message
            // todo: ...
            this.props.setAlert({
                status: Status.Error,
                title: this.props.storage.t("Common:General.Error")
            });
            return false;
        }
        const promises = [storage.tableAPI.reloadTable()];
        if (status && [ApprovalStatusTypeCode.Approved, ApprovalStatusTypeCode.Rejected].includes(status)) {
            this.props.onAfterRemoveFiles([bindingContext]);
        } else {
            await this.loadFileDetail();
        }
        await Promise.all(promises);
        return true;
    };

    renderFileNameChange(): React.ReactNode {
        return (
                <FileRenameWriteLine name={this.inboxFile.FileMetadata.Name}
                                     onChange={this.handleFileNameChangeConfirm}/>
        );
    }

    render() {
        const isUnsorted = this.type === InboxFilter.Unsorted;

        if (!this.inboxFile) {
            return (
                    <BusyIndicator/>
            );
        }

        const showEmailText = this.inboxFile.InboxFileSourceCode === InboxFileSourceCode.Email;

        return (<>
            <InboxFileDetailLayout>
                {(width: number, height: number) => width && height && (<>
                    {this.selectedFileHasContents && this.renderFileNameChange()}
                    {this.getFileViewer()}
                    {isUnsorted
                            ? (showEmailText && <EmailBodyMessage text={this.inboxFile.Body}/>)
                            : <InboxChat file={this.inboxFile}
                                         showEmailText={showEmailText}
                                         isAgendaCustomer={this.currentUserIsCustomer()}
                                         maxHeight={height / 2}
                                         onCreateMessage={this.handleCreateMessage}/>
                    }
                </>)}
            </InboxFileDetailLayout>
            {this.props.alert}
        </>);
    }
}

export default withAlert({
    autoHide: true,
    position: AlertPosition.CenteredBottom
})(withConfirmationDialog(InboxFileDetail));
