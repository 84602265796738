import React, { useCallback, useRef, useState } from "react";
import TestIds from "../../testIds";
import {
    CenteredIcon,
    ContentWrapper,
    Header,
    MessageIconWrapper,
    MessageSubject,
    StyledMessage
} from "./TicketMessage.styles";
import { ITicketMessage } from "./TicketMessage.utils";
import TicketMessageAttachments from "./TicketMessageAttachments";
import DateType from "../../types/Date";
import { delay } from "lodash";
import { doesElementContainsElement } from "@utils/general";

interface IProps extends ITicketMessage {
    isGrouped?: boolean;
}

const TicketMessage: React.FC<IProps> = (props) => {
    const {
        icon,
        created, createdBy,
        subject, text,
        attachments,
        type,
        isGrouped
    } = props;

    const [isVisible, show] = useState<boolean>(false);
    const _messageRef = useRef<HTMLDivElement>();
    const timer = useRef<number>();

    const handleMouseEnter = useCallback((event: React.MouseEvent) => {
        timer.current = delay(show, 1000, true);
    }, [show]);

    const handleMouseLeave = useCallback((event: React.MouseEvent) => {
        if (!doesElementContainsElement(_messageRef.current, event.relatedTarget as Element)) {
            clearTimeout(timer.current);
            show(false);
        }
    }, [show]);

    return (
            <StyledMessage ref={_messageRef}
                           $type={type}
                           $grouped={isGrouped}
                           onMouseEnter={handleMouseEnter}
                           onMouseLeave={handleMouseLeave}
                           data-testid={TestIds.Message}>
                {!isGrouped && (
                        <MessageIconWrapper $type={type}>
                            <CenteredIcon>{icon}</CenteredIcon>
                        </MessageIconWrapper>
                )}
                <ContentWrapper $type={type} data-testid={TestIds.MessageContent}>
                    {subject && <MessageSubject>{subject}</MessageSubject>}
                    {text}
                    <TicketMessageAttachments attachments={attachments}/>
                    {(created || createdBy) &&
                            <Header isVisible={isVisible} $type={type}>
                                {DateType.localFormat(created, DateType.defaultDateTimeFormat)} ({createdBy})
                            </Header>}
                </ContentWrapper>
            </StyledMessage>
    );
};

export default TicketMessage;