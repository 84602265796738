import { getTableIntentLink } from "@components/drillDown/DrillDown.utils";
import { getLabelsFilterDef, LabelsTableColumnDef } from "@components/smart/GeneralFieldDefinition";
import { FilterBarGroup, getDefaultFilterGroupDef } from "@components/smart/smartFilterBar/SmartFilterBar.types";
import { TCellValue } from "@components/table";
import { ITabData } from "@components/tabs";
import { createPath } from "@odata/BindingContext";
import { getRouteByDocumentType } from "@odata/EntityTypes";
import {
    DocumentBusinessPartnerEntity,
    DocumentEntity,
    DocumentItemEntity,
    DocumentTypeEntity,
    EntitySetName,
    EntityTypeName,
    IUnorganizedAssetEntity,
    UnorganizedAssetEntity
} from "@odata/GeneratedEntityTypes";
import { AssetItemTypeCode, AssetTypeCode, DocumentTypeCode } from "@odata/GeneratedEnums";
import { getEnumDisplayValue, getEnumNameSpaceName } from "@odata/GeneratedEnums.utils";
import { IFormatOptions } from "@odata/OData.utils";
import i18next from "i18next";

import { IAppContext } from "../../../contexts/appContext/AppContext.types";
import { Sort } from "../../../enums";
import { TValue } from "../../../global.types";
import { ISplitPageTableDef, ITableDef } from "../../../views/table/TableView.utils";
import { setDefByEntityType } from "../../getDefByEntityType";
import { IDefinition, IGetDefinition } from "../../PageUtils";
import { AssetTranslations } from "../fixedAsset/FixedAsset.utils";

const getUnorganizedAssetTabs = (): ITabData[] => [
    {
        id: AssetTypeCode.Tangible,
        title: getEnumDisplayValue(EntityTypeName.AssetType, AssetTypeCode.Tangible)
    }, {
        id: AssetTypeCode.Intangible,
        title: getEnumDisplayValue(EntityTypeName.AssetType, AssetTypeCode.Intangible)
    }
];

export const getDefinitionsFactory = (tableId: ITableDef["id"] = `${EntityTypeName.UnorganizedAsset}Table`, preventStoreVariant = false, itemType?: AssetItemTypeCode): IGetDefinition => {
    const defaultFilters: string[] = [];

    if (itemType) {
        // show only unorganized assets related to item type (technical improvement, reduction or increase of price)
        const isReduction = itemType === AssetItemTypeCode.ReductionOfPrice;
        const operator = isReduction ? "lt" : "ge";
        defaultFilters.push(`${UnorganizedAssetEntity.Amount} ${operator} 0`);
    }

    const getDefinitions: IGetDefinition = (context: IAppContext): IDefinition => {

        const table: ISplitPageTableDef = {
            filterBarDef: [{
                ...getDefaultFilterGroupDef(FilterBarGroup.Filters),
                isValueHelp: true,
                defaultFilters: [
                    UnorganizedAssetEntity.EntryDate,
                    createPath(UnorganizedAssetEntity.Document, DocumentEntity.NumberOurs),
                    createPath(UnorganizedAssetEntity.Document, DocumentEntity.DocumentType),
                    createPath(UnorganizedAssetEntity.Document, DocumentEntity.BusinessPartner, DocumentBusinessPartnerEntity.Name),
                    createPath(UnorganizedAssetEntity.DocumentItem, DocumentItemEntity.Description),
                    UnorganizedAssetEntity.Amount
                ],
                filterDefinition: {
                    [UnorganizedAssetEntity.EntryDate]: {},
                    [UnorganizedAssetEntity.Currency]: {
                        fieldSettings: {
                            displayName: DocumentTypeEntity.Name
                        },
                    },
                    [createPath(UnorganizedAssetEntity.Document, DocumentEntity.NumberOurs)]: {
                        label: i18next.t("FixedAsset:NumberOurs"),
                        description: null
                    },
                    [createPath(UnorganizedAssetEntity.Document, DocumentEntity.DocumentType)]: {
                        fieldSettings: {
                            displayName: DocumentTypeEntity.Name
                        },
                        label: i18next.t("FixedAsset:Unorganized.DocumentType"),
                        description: null
                    },
                    [createPath(UnorganizedAssetEntity.Document, DocumentEntity.BusinessPartner, DocumentBusinessPartnerEntity.Name)]: {
                        label: i18next.t("FixedAsset:Unorganized.BusinessPartner"),
                        description: null
                    },
                    [createPath(UnorganizedAssetEntity.Document, DocumentEntity.Note)]: {
                        label: i18next.t("FixedAsset:Unorganized.DocumentNote"),
                        description: null
                    },
                    [createPath(UnorganizedAssetEntity.DocumentItem, DocumentItemEntity.Description)]: {
                        label: i18next.t("FixedAsset:Unorganized.ItemDescription"),
                        description: null
                    },
                    [UnorganizedAssetEntity.Amount]: {},
                    ...getLabelsFilterDef("Labels")
                }
            }],
            preventStoreVariant,
            id: tableId,
            tabs: getUnorganizedAssetTabs(),
            tabsSettings: {
                filterFieldName: UnorganizedAssetEntity.AssetTypeCode
            },
            columns: [
                UnorganizedAssetEntity.EntryDate,
                createPath(UnorganizedAssetEntity.Document, DocumentEntity.NumberOurs),
                createPath(UnorganizedAssetEntity.Document, DocumentEntity.DocumentType, DocumentTypeEntity.Name),
                createPath(UnorganizedAssetEntity.Document, DocumentEntity.BusinessPartner, DocumentBusinessPartnerEntity.Name),
                createPath(UnorganizedAssetEntity.DocumentItem, DocumentItemEntity.Description),
                UnorganizedAssetEntity.Amount
            ],
            columnDefinition: {
                [UnorganizedAssetEntity.EntryDate]: {},
                [createPath(UnorganizedAssetEntity.Document, DocumentEntity.NumberOurs)]: {
                    label: i18next.t("FixedAsset:NumberOurs"),
                    formatter: (val: TValue, {
                        entity,
                        storage
                    }: IFormatOptions<IUnorganizedAssetEntity>): TCellValue => {
                        const { Id, DocumentType: { Code } } = entity.Document;
                        return getTableIntentLink(val as string, {
                            route: `${getRouteByDocumentType(Code as DocumentTypeCode)}/${Id}`,
                            context: storage.context,
                            storage: storage
                        });
                    }
                },
                [createPath(UnorganizedAssetEntity.Document, DocumentEntity.DocumentType, DocumentTypeEntity.Name)]: {
                    label: i18next.t("FixedAsset:Unorganized.DocumentType")
                },
                [createPath(UnorganizedAssetEntity.Document, DocumentEntity.BusinessPartner, DocumentBusinessPartnerEntity.Name)]: {
                    label: i18next.t("FixedAsset:Unorganized.BusinessPartner")
                },
                [createPath(UnorganizedAssetEntity.Document, DocumentEntity.Note)]: {
                    label: i18next.t("FixedAsset:Unorganized.DocumentNote")
                },
                [createPath(UnorganizedAssetEntity.DocumentItem, DocumentItemEntity.Description)]: {
                    label: i18next.t("FixedAsset:Unorganized.ItemDescription")
                },
                [UnorganizedAssetEntity.Amount]: {},
                ...LabelsTableColumnDef,
            },
            additionalProperties: [
                // mandatory fields for Asset actions, have to be always loaded
                { id: UnorganizedAssetEntity.EntryDate },
                { id: createPath(UnorganizedAssetEntity.Document, DocumentEntity.Id) },
                { id: createPath(UnorganizedAssetEntity.Document, DocumentEntity.NumberOurs) },
                { id: createPath(UnorganizedAssetEntity.DocumentItem, DocumentItemEntity.Id) },
                // additional property for NumberOurs formatter
                { id: createPath(UnorganizedAssetEntity.Document, DocumentEntity.DocumentType, DocumentTypeEntity.Code) }
            ],
            title: i18next.t("FixedAsset:Unorganized.Title"),
            filter: defaultFilters.length ? defaultFilters.join(" AND ") : undefined,
            initialSortBy: [
                { id: UnorganizedAssetEntity.EntryDate, sort: Sort.Desc }
            ]
        };

        return {
            entitySet: EntitySetName.UnorganizedAssets,
            table
        };
    };
    getDefinitions.translationFiles = [
        ...AssetTranslations, getEnumNameSpaceName(EntityTypeName.AssetType)
    ];

    return getDefinitions;
};
setDefByEntityType(EntityTypeName.UnorganizedAsset, getDefinitionsFactory());
