import React from "react";
import SearchResultGroup from "../../components/search/SearchResultGroup";
import SearchResult from "../../components/search/SearchResult";
import {
    getResultDescription,
    getResultSubtitle,
    getResultTitle,
    getResultUrl,
    ISearchGroup
} from "./GlobalSearch.utils";
import { WithT } from "i18next";

interface IProps extends WithT {
    searchString: string;
    results: ISearchGroup[];
    onResultClick?: () => void;
}

interface IState {
    expandedGroups: Set<string>;
}

const COLLAPSED_RESULT_COUNT = 3;

export default class GlobalSearchResults extends React.PureComponent<IProps, IState> {

    state: IState = {
        expandedGroups: new Set<string>()
    };

    handleToggle = (id: string, isExpanded: boolean): void => {
        const expandedGroups = new Set(this.state.expandedGroups);
        expandedGroups[isExpanded ? "add" : "delete"](id);
        this.setState({ expandedGroups });
    };

    render() {
        return this.props.results?.map((group) => {
            const { Results, Name } = group;
            const isExpanded = this.state.expandedGroups.has(Name);
            const filteredResults = isExpanded ? Results : Results.slice(0, COLLAPSED_RESULT_COUNT);
            return (
                <SearchResultGroup key={Name}
                                   id={Name}
                                   label={this.props.t(`Common:GlobalSearchGroups.${Name}`)}
                                   count={Results.length}
                                   onToggle={this.handleToggle}
                                   isExpandable={Results.length > COLLAPSED_RESULT_COUNT}
                                   isExpanded={isExpanded}>
                    {filteredResults.map((result, idx) => {
                        const args = { result, group, search: this.props.searchString };
                        const url = getResultUrl(args);
                        return (
                            <SearchResult key={url ?? idx}
                                          url={url}
                                          onClick={this.props.onResultClick}
                                          title={getResultTitle(args)}
                                          subtitle={getResultSubtitle(args)}
                                          description={getResultDescription(args)}/>
                        );
                    })}
                </SearchResultGroup>
            );
        });
    }
}
