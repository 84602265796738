import React, { useRef } from "react";

export type TPortalRootElementContext = React.RefObject<HTMLDivElement>;
const PortalRootElementContext = React.createContext<TPortalRootElementContext>(undefined);

interface IProps {
    useGlobalRoot?: boolean;
}

const PortalRootElementProvider: React.FC<IProps> = ({ children, useGlobalRoot }) => {
    // ref to parent element to be autocomplete menus, context menus, etc... appended to
    const ref = useRef<HTMLDivElement>();

    return (
            <PortalRootElementContext.Provider value={ref}>
                {children}
                {!useGlobalRoot && <div ref={ref}></div>}
            </PortalRootElementContext.Provider>
    );
};

export { PortalRootElementContext, PortalRootElementProvider };