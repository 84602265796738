import React, { useCallback, useContext } from "react";
import { BusinessCardAvatar, Contact, Heading, StyledBusinessCardTile } from "./BusinessCardTile.styles";
import { IDashboardTileComponentProps } from "../../dashboard";
import { initials } from "@utils/string";
import { AppContext } from "../../../contexts/appContext/AppContext.types";
import { phoneNumberInputFormatter } from "../../smart/GeneralFieldDefinition";

const BusinessCardTile: React.FC<IDashboardTileComponentProps> = ({ inEditMode }) => {
    const context = useContext(AppContext);

    const { FirstName, LastName, Email, PhoneNumber } = context.getCompany()?.CommunicationContact ?? {};
    const Name = `${FirstName} ${LastName}`;

    const preventEventInEditMode = useCallback((e) => {
        if (inEditMode) {
            e.preventDefault();
        }
    }, [inEditMode]);

    return (
        <StyledBusinessCardTile>
            <BusinessCardAvatar>
                {initials(Name)}
            </BusinessCardAvatar>
            <Heading>{Name}</Heading>
            <Contact href={`tel:${PhoneNumber}`}
                     onDragStart={preventEventInEditMode}
                     onClick={preventEventInEditMode}>
                {phoneNumberInputFormatter(PhoneNumber)}
            </Contact>
            <Contact href={`mailto://${Email}`}
                     onDragStart={preventEventInEditMode}
                     onClick={preventEventInEditMode}>
                {Email}
            </Contact>
        </StyledBusinessCardTile>
    );
};

export default BusinessCardTile;