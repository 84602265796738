/** Accounts are not send from BE for some rows (merged label groups that contain with_children value)
 * =>  propagate rows from leaves*/
import { IReportRowDef } from "@components/smart/smartTable";

export const enhanceRowsWithAccountIds = (rowsData: IReportRowDef[], propNames: string[]): Record<string, Set<string>> => {
    const groupedAccounts: Record<string, Set<string>> = {};

    for (const row of rowsData) {
        if (row.Rows?.length === 0) {
            for (const key of Object.keys(row.Value)) {
                if (propNames.some(prop => key.startsWith(prop))) {
                    if (!groupedAccounts[key]) {
                        groupedAccounts[key] = new Set<string>();
                    }

                    for (const account of (row.Value[key] as string[])) {
                        groupedAccounts[key].add(account);
                    }
                }
            }
        } else {
            const accIds = enhanceRowsWithAccountIds(row.Rows, propNames);

            for (const key of Object.keys(accIds)) {
                if (!groupedAccounts[key]) {
                    groupedAccounts[key] = new Set<string>();
                }

                if (!row.Value[key]) {
                    row.Value[key] = [];

                    for (const account of accIds[key].values()) {
                        (row.Value[key] as string[]).push(account);
                        groupedAccounts[key].add(account);
                    }
                }
            }
        }
    }

    return groupedAccounts;
};