import React, { useCallback, useRef, useState } from "react";

export interface WithPromisedComponent {
    promisedComponent: ReturnType<typeof usePromisedComponent>;
}

/** Render component on the fly and wait for the result * */
export const withPromisedComponent = <P extends WithPromisedComponent>(Component: React.ComponentType<P>): React.ComponentType<Omit<P, keyof WithPromisedComponent>> => {
    return React.forwardRef((props: Omit<P, keyof WithPromisedComponent>, ref) => {
        const { component, ...rest } = usePromisedComponent();

        return (
            <>
                {component}
                <Component promisedComponent={rest}
                           ref={ref}
                           {...props as P}/>
            </>
        );
    }) as any;
};

export function usePromisedComponent<T = unknown>() {
    const [component, setComponent] = useState<React.ReactNode>(null);
    const resultPromiseRef = useRef<Promise<T>>();
    const promiseResolveFnRef = useRef<(value: T) => void>();
    const open = useCallback(
        <R extends T>(getComponent: (onFinish: (result: R) => void) => React.ReactNode) => {
            const renderedComponent = getComponent((result: R) => {
                promiseResolveFnRef.current(result);
                setComponent(null);
            });

            setComponent(renderedComponent);

            resultPromiseRef.current = new Promise((resolve) => {
                promiseResolveFnRef.current = resolve;
            });

            return resultPromiseRef.current as Promise<R>;
        },
        []);
    const getResultPromise = useCallback(() => {
        return resultPromiseRef.current;
    }, [resultPromiseRef]);

    return {
        openComponent: open,
        getResultPromise,
        component
    };
}