import React from "react";
import { FileToolbarItem, getFileNameFromSrc, getSharedToolbarDefinition, IFileViewerProps } from "./FileViewers.utils";
import { WithTranslation, withTranslation } from "react-i18next";
import { NoPreviewStyled, StyledFileViewer } from "./FileViewers.styles";
import { Header } from "./pdfViewer/PDFViewer.style";
import TestIds from "../../testIds";
import { Toolbar } from "../toolbar/Toolbar";
import { TValue } from "../../global.types";
import NoPreviewSquirrel from "./NoPreviewSquirrel";
import { saveAs } from "file-saver";
import { NoData } from "../table/NoData";

interface IProps extends IFileViewerProps, WithTranslation {
    isEmpty?: boolean;
}

class NoPreview extends React.PureComponent<IProps> {
    static defaultProps: Partial<IProps> = {
        hiddenItems: []
    };

    handleItemChange = (id: string, value?: TValue) => {
        switch (id) {
            case FileToolbarItem.Dashboard:
                this.props.onGoToList?.();
                break;
            case FileToolbarItem.Download:
                saveAs(this.props.src, this.props.fileName ?? getFileNameFromSrc(this.props.src));
                break;
            case FileToolbarItem.Remove:
                this.props.onFileRemove?.(this.props.src);
                break;
        }
    };

    render() {
        const def = getSharedToolbarDefinition(this.props.isReadOnly, this.props.hiddenItems, this.props.isRemoveDisabled, this.props.t);

        return (
            <StyledFileViewer data-testid={TestIds.FileViewer}>
                <Header>
                    <Toolbar
                        onItemChange={this.handleItemChange}
                        staticItems={def.staticItems}>
                        {def.items}
                    </Toolbar>
                </Header>
                {/* If file is defined => show squirrel ,
                    If file is not defined at all => show NoData (spider) */}
                {!this.props.isEmpty &&
                    <NoPreviewSquirrel text={this.props.t("Components:FileViewers.NoPreviewText")}/>}
                {this.props.isEmpty &&
                    <>
                        <NoPreviewStyled>
                            <NoData/>
                        </NoPreviewStyled>
                    </>}
            </StyledFileViewer>
        );
    }
}

export default withTranslation(["Components"])(NoPreview);