import React from "react";
import { THistoryLocation } from "./DrillDown.utils";
import { AppContext } from "../../contexts/appContext/AppContext.types";
import Clickable from "../clickable";

interface IDrillDownLinkProps {
    id?: string;
    to: THistoryLocation;
    onClick?: (id: string) => void;
    companyId?: number
}

/** Used in Table to navigate to other routes.
 * Can be combined with util methods "getDrillDownNavParams" and "getIntentNavParams" */
export default class DrillDownLink extends React.Component<IDrillDownLinkProps> {
    static contextType = AppContext;

    linkRef = React.createRef<HTMLAnchorElement>();

    render() {
        return (
                <>
                    <Clickable
                            companyId={this.props.companyId}
                            passRef={this.linkRef}
                            link={this.props.to}
                >
                    {this.props.children}
                </Clickable>
            </>
        );
    }
}
