import styled, { css } from "styled-components/macro";
import { getDisabledStyle, T_PLAIN_big } from "../../../global.style";
import { StyledError } from "../formError/FormError.styles";
import TestIds from "../../../testIds";
import { transparentize } from "polished";

export interface ITextArea {
    maxRows?: number;
    disabled?: boolean;
}

export const textAreaErrorStyles = css`
    ${StyledError} {
        top: -3px;
        left: 0;

        [data-testid="${TestIds.ErrorText}"] {
            left: 13px;
            width: calc(100% - 13px);
        }

        & + textarea, & + input {
            padding-top: 13px; // space for error message
        }
    }
`;

export const Wrapper = styled.div`
    position: relative;
    width: 100%;

    ${textAreaErrorStyles};
`;

const styles = css<ITextArea>`
    min-height: 28px;
    min-width: 30px;
    // using 'em' can lead to problems (check Input.styles), but we can't use exact pixels,
    // because font-size can be controlled from outside TextArea component 
    max-height: ${props => props.maxRows ? `${props.maxRows * 1.3}em` : "14.4em"};
    outline: none;
    ${T_PLAIN_big};
    resize: none;
    border-radius: 3px ;
    width: 100%;
    text-transform: inherit;
    background-color: ${props => props.theme.C_FIELD_fill};
    border: 1px solid ${props => props.theme.C_FIELD_line};
    padding: 6px 12px 4px 12px;

    &:hover {
        border-color: ${props => props.theme.C_ACT_hover_without_opacity};
    }

    &:focus {
        border-color: ${props => props.theme.C_ACT_hover_without_opacity};
    }

    &::placeholder {
        color: ${props => transparentize(0.5, props.theme.C_TEXT_primary)};
        font-style: italic;
        letter-spacing: 0.14px;
    }

    ${props => getDisabledStyle(props.disabled)};
`;

export const StyledTextArea = styled.textarea.attrs({
    // set minimum number of rows (browser default is 2)
    rows: 1
})<ITextArea>`
    ${styles};
`;

export const StyledInput = styled.input<ITextArea>`
    ${styles};
`;