import React, { useCallback } from "react";
import { AttachmentIcon, CloseIcon, ColoredIcon } from "../icon";
import { IconSize } from "../../enums";
import { ClickableStyled, MessageFileStyled } from "./TicketMessage.styles";
import { IconButton } from "../button";
import { useTranslation } from "react-i18next";

interface IProps {
    id: number;
    name: string;
    onClick: (id: number) => void;
    onRemove?: (id: number) => void;
}

const MessageFile: React.FC<IProps> = ({ id, name, onClick, onRemove }) => {
    const { t } = useTranslation("Components");

    const handleClick = useCallback(() => {
        onClick(id);
    }, [id, onClick]);

    const handleRemove = useCallback(() => {
        onRemove(id);
    }, [id, onRemove]);

    return (
        <MessageFileStyled key={id}>
            <ClickableStyled onClick={handleClick} isLink>
                <ColoredIcon><AttachmentIcon width={IconSize.XS} height={IconSize.XS}/></ColoredIcon> {name}
            </ClickableStyled>
            {onRemove && (
                <IconButton title={t("Components:TicketMessageInput.RemoveFile")}
                            onClick={handleRemove}
                            isDecorative>
                    <CloseIcon width={IconSize.XS} height={IconSize.XS}/>
                </IconButton>
            )}
        </MessageFileStyled>
    );
};

export default MessageFile;