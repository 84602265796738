import React from "react";
import { SimpleTimeLinePoint, StyledSimpleTimeline, StyledSimpleTimelineItem } from "./SimpleTimelline.styles";
import { SimpleTimeLineStatus } from "./SimpleTimeline.utils";

export interface ISimpleTimelineProps {
    items: ISimpleTimelineItemProps[];
}

const SimpleTimeline = React.memo((props: React.PropsWithChildren<ISimpleTimelineProps>) => {
    return (
        <StyledSimpleTimeline>
            {props.items.map((item, index) => {
                return <SimpleTimelineItem key={index}
                                           isFirst={index === 0}
                                           isLast={index === props.items.length - 1}
                                           isNextFuture={props.items[index + 1]?.status === SimpleTimeLineStatus.Future}
                                           {...item}/>;
            })}
        </StyledSimpleTimeline>
    );
});

export interface ISimpleTimelineItemProps {
    status: SimpleTimeLineStatus;
    isFirst?: boolean;
    isLast?: boolean;
    isNextFuture?: boolean;
    /** Item has 100% height, this will specify where the point should be placed. */
    topOffset?: string;
    /** The top and bottom line automatically fill the height of the timeline item.
     * Item can be used in components that has some margin between them (e.g. FastEntry).
     * bottomLineOverflow will stretch the bottom line so that it connects with the next one. */
    bottomLineOverflow?: string;
    className?: string;
    style?: React.CSSProperties;
}

export const SimpleTimelineItem = React.memo((props: React.PropsWithChildren<ISimpleTimelineItemProps>) => {
    const topOffset = props.topOffset ?? "50%";

    return (
        <StyledSimpleTimelineItem status={props.status}
                                  topOffset={topOffset}
                                  isFirst={props.isFirst} isLast={props.isLast}
                                  isNextFuture={props.isNextFuture}
                                  bottomLineOverflow={props.bottomLineOverflow ?? "0px"}>
            <SimpleTimeLinePoint status={props.status}
                                 topOffset={topOffset}/>
        </StyledSimpleTimelineItem>
    );
});

export default SimpleTimeline;