import { GroupListDropType } from "../../enums";
import { ITEM_MARGIN } from "./ConfigurationList.styles";
import { IPlaceholderPos } from "./ConfigurationList";

export const draggableAttr = "data-rbd-drag-handle-draggable-id";
export const droppableAttr = "data-rbd-droppable-id";

export const getDragedElement = (draggableId: string) => {
    const domQuery = `[${draggableAttr}='${draggableId}']`;
    const draggedDOM = document.querySelector(domQuery);

    return draggedDOM as HTMLElement;
};

export const getDroppableElement = (droppableId: string) => {
    const domQuery = `[${droppableAttr}='${droppableId}']`;
    const draggedDOM = document.querySelector(domQuery);

    return draggedDOM as HTMLElement;
};

export const computePlaceholderPos = (element: HTMLElement, elements: HTMLElement[], index: number, droppableId: string, type: GroupListDropType): IPlaceholderPos => {
    let { clientHeight } = element;
    const { clientWidth }: HTMLElement = (type === GroupListDropType.Item && (element.children?.[0] as HTMLElement)) || element;
    const nodeStyle = window.getComputedStyle(element);
    const paddingTop = parseFloat(nodeStyle.paddingTop);
    const paddingBottom = parseFloat(nodeStyle.paddingBottom);

    clientHeight -= (paddingTop + paddingBottom);

    const clientY =
        paddingTop +
        elements.slice(0, index)
            .reduce((total: number, curr: HTMLElement) => {
                const style = window.getComputedStyle(curr);
                const marginBottom = parseFloat(style.marginBottom);
                return total + curr.clientHeight + marginBottom;
            }, 0);
    const clientX = type === GroupListDropType.Item ? ITEM_MARGIN : 0;

    return {
        clientHeight,
        clientWidth,
        clientY,
        clientX,
        // store droppableId of the current placeholderPos to prevent placeholder flickering
        // when old placeholderPos was rendered in another group
        droppableId
    };
};