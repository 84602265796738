import { Dayjs } from "dayjs";
import { getNextMonth, getNextYear, getPreviousMonth, getPreviousYear, IDayInterval } from "../utils";
import React from "react";
import { useTranslation } from "react-i18next";
import { StyledBottomButtons } from "./Calendar.styles";
import { Button, IconButton, IIconButtonProps } from "../../../button";
import { CaretIcon } from "../../../icon";
import { PredefinedFilter } from "../../../conditionalFilterDialog/ConditionalFilterDialog.utils";

export enum DateRangeSpecialValue {
    WithoutStart = "WithoutStart",
    WithoutEnd = "WithoutEnd"
}

export const WEEK_LENGTH = 7;
export const NUM_WEEKS = 6;
export const MIN_YEAR = 100;
export const MAX_YEAR = 10000;
export const MONTH_COLS = 3;
export const MONTH_ROWS = 4;
export const YEAR_COLS = 3;
export const YEAR_ROWS = 7;

export type TDisabledDateUnit = "day" | "month" | "year";

export interface IDatePopupSharedProps {
    // minimum date allowed to be picked
    minDate?: Date;
    // maxDate date allowed to be picked
    maxDate?: Date;
    // custom callback to disable arbitrary values in the popup
    isDateDisabled?: (date: Date, unit: TDisabledDateUnit) => boolean;
    showSpecialValue?: DateRangeSpecialValue;
    embedded?: boolean;
    disableAutoFocus?: boolean;
}

export interface IDateSingleValuePopupSharedProps extends IDatePopupSharedProps {
    value?: Date;
    onChange?: (day: Date) => void;
}

export type TDateRangePickerValue = IDayInterval | PredefinedFilter;


export interface IDateRangeValuePopupSharedProps extends IDatePopupSharedProps {
    value?: TDateRangePickerValue;
    onChange?: (value: TDateRangePickerValue) => void;
}


export const setNextMonth = (previewDay: Dayjs, onPreviewChange: (day: Dayjs) => void): void => {
    const nextMonth = getNextMonth(previewDay);

    if (nextMonth.year() <= MAX_YEAR) {
        onPreviewChange(nextMonth);
    }
};

export const setPreviousMonth = (previewDay: Dayjs, onPreviewChange: (day: Dayjs) => void): void => {
    const previousMonth = getPreviousMonth(previewDay);

    if (previousMonth.year() >= MIN_YEAR) {
        onPreviewChange(previousMonth);
    }
};

export const setNextYear = (previewDay: Dayjs, onPreviewChange: (day: Dayjs) => void): void => {
    const nextYear = getNextYear(previewDay);

    if (nextYear.year() <= MAX_YEAR) {
        onPreviewChange(nextYear);
    }
};

export const setPreviousYear = (previewDay: Dayjs, onPreviewChange: (day: Dayjs) => void): void => {
    const previousYear = getPreviousYear(previewDay);

    if (previousYear.year() >= MIN_YEAR) {
        onPreviewChange(previousYear);
    }
};

interface IBottomButtons {
    onOk: () => void;
    onCancel: () => void;
    embedded?: boolean;
    disabledOk?: boolean;
    style?: React.CSSProperties;
}

export const BottomButtons: React.FunctionComponent<IBottomButtons> = (
    { onCancel, onOk, disabledOk }: IBottomButtons
) => {
    const { t } = useTranslation("Common");

    return (
        <StyledBottomButtons>
            <Button isTransparent isLight ignoreTheme onClick={onCancel}>{t("General.Cancel")}</Button>
            <Button isLight ignoreTheme onClick={onOk}
                    isDisabled={disabledOk}>{t("General.Ok")}</Button>
        </StyledBottomButtons>
    );
};
export type TCaretIconButtonArg = Pick<IIconButtonProps, "title" | "onClick" | "onMouseDown" | "onKeyDown" | "style" | "testid">

export function CaretIconButton({
                                    title,
                                    onClick,
                                    onMouseDown,
                                    onKeyDown,
                                    style,
                                    testid
                                }: TCaretIconButtonArg): React.ReactElement {
    return (
        <IconButton title={title}
                    testid={testid}
                    onClick={onClick}
                    onMouseDown={onMouseDown}
                    onKeyDown={onKeyDown}
                    style={style}
                    isLight
                    isDecorative>
            <CaretIcon ignoreTheme/>
        </IconButton>
    );
}
