import styled from "styled-components/macro";
import { GroupHeader } from "../../components/formGroup/GroupHeader";
import Switch from "../../components/inputs/switch";
import { T_PLAIN_big } from "../../global.style";
import { Label } from "@components/inputs/switch/Switch.styles";

export const NotificationsSettingsName = styled.div`
    display: flex;
    align-items: center;
    ${T_PLAIN_big}
`;

export const GroupHeaderStyled = styled(GroupHeader)`
    margin-bottom: 20px;
`;

export const SwitchBannerDisabled = styled(Switch)`
    margin-top: 70px;

    ${Label} {
        color: ${props => props.theme.C_TEXT_dialogue};
        ${T_PLAIN_big}
    }
`;

export const NotificationsSettingsWrapper = styled.div`
    color: ${props => props.theme.C_TEXT_dialogue};
    padding: 10px 55px 0 44px;
`;

export const NotificationLabel = styled.div`
    text-align: right;
    margin-bottom: 20px;
    ${T_PLAIN_big}
`;

export const NotificationRow = styled.div`
    display: flex;
    column-gap: 24px;
    margin-bottom: 32px;
    & > div:nth-child(1) { // name
        flex-grow: 1;
    }
    & > div:nth-child(3) { // checkbox
        padding: 0 11px 0 8px;
    }
`;
