import styled from "styled-components/macro";

import { T_TITLE_4 } from "../../global.style";

export const StyledDocTypesLabels = styled.div`
    display: flex;
    justify-items: flex-start;
    width: 100%;
    margin-bottom: -12px;

    & > span {
        &:first-of-type {
            padding-left: 12px;
        }

        ${T_TITLE_4};
        width: 50%;
    }
`;