import React from "react";
import { HotspotInnerCircle, StyledHotspot } from "./Hotspots.styles";
import PopperTooltip from "../popperTooltip/PopperTooltip";
import { KeyName } from "../../keyName";
import { composeRefHandlers, doesElementContainsElement } from "@utils/general";
import { debounce } from "lodash";
import { ThemeProvider } from "styled-components/macro";
import { themes } from "../../theme";

export interface IHotspotProps {
    id: string;
    isActive?: boolean;
    isEditing?: boolean;
    top?: number;
    left?: number;

    // for multiple grouped hotspots
    isGrouped?: boolean;
    groupCount?: number;

    onClick?: (id: string) => void;
    onClose?: (id: string) => void;
    content: React.ReactNode | (() => React.ReactNode);
}

class Hotspot extends React.PureComponent<IHotspotProps> {
    _hotspotRef = React.createRef<HTMLDivElement>();

    componentDidMount() {
        if (this.props.isActive) {
            this.attachOutsideClickHandler();
        }
    }

    componentDidUpdate(prevProps: Readonly<IHotspotProps>, prevState: Readonly<{}>, snapshot?: any) {
        if (prevProps.isActive && !this.props.isActive) {
            this.removeOutsideClickHandler();
        } else if (!prevProps.isActive && this.props.isActive) {
            this.attachOutsideClickHandler();
        }
    }

    componentWillUnmount() {
        this.removeOutsideClickHandler();
    }

    // add debounce, to prevent Hotspot from calling the function immediately after it is opened (thus subsequently closed)
    attachOutsideClickHandler = debounce((): void => {
        document.addEventListener("click", this.handleClickOutside);
    });

    removeOutsideClickHandler = (): void => {
        document.removeEventListener("click", this.handleClickOutside);
    };

    handleClickOutside = (event: MouseEvent): void => {
        if (!doesElementContainsElement(this._hotspotRef.current, event.target as HTMLElement)) {
            this.props.onClose?.(this.props.id);
        }
    };

    handleClick = () => {
        this.props.onClick?.(this.props.id);
    };

    handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === KeyName.Enter) {
            this.handleClick();
        } else if (event.key === KeyName.Escape) {
            this.props.onClose?.(this.props.id);
        }
    };


    render() {
        const isActive = this.props.isActive;

        return (
            <PopperTooltip content={this.props.content}
                           inPlace
                           offsetY={10}
                           placement={"bottom"}
                           isHidden={!isActive}>
                {(ref) => {
                    return (
                        // always use light theme, otherwise hotspots are difficult to see in dark theme
                        <ThemeProvider theme={themes.light}>
                            <StyledHotspot $isActive={isActive}
                                           $isEditing={this.props.isEditing}
                                           $top={this.props.top}
                                           $left={this.props.left}
                                           $isBig={this.props.isGrouped}
                                           onClick={this.handleClick}
                                           onKeyDown={this.handleKeyDown}
                                           tabIndex={0}
                                           ref={composeRefHandlers(this._hotspotRef, ref)}>
                                <HotspotInnerCircle
                                    $isActive={isActive}
                                    $isEditing={this.props.isEditing}
                                    $isBig={this.props.isGrouped}>
                                    {this.props.groupCount}
                                </HotspotInnerCircle>
                            </StyledHotspot>
                        </ThemeProvider>
                    );
                }}
            </PopperTooltip>
        );
    }
}

export default Hotspot;