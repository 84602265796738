import { AlertPosition } from "@components/alert/Alert";
import { WithAlert, withAlert } from "@components/alert/withAlert";
import { WithBusyIndicator, withBusyIndicator } from "@components/busyIndicator/withBusyIndicator";
import { withOData } from "@odata/withOData";
import { getDefinitions } from "@pages/chartOfAccountsTemplates/ChartOfAccountsTemplatesDef";
import ChartOfAccountsTemplatesTableView from "@pages/chartOfAccountsTemplates/ChartOfAccountsTemplatesTableView";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import { AppContext } from "../../contexts/appContext/AppContext.types";
import ParentChildPage, { IParentPageProps } from "../ParentChildPage";

interface IProps extends IParentPageProps, WithAlert, WithBusyIndicator {
}

class TemplateEditPage extends ParentChildPage<IProps> {
    static contextType = AppContext;
    static defaultProps = {
        tableView: ChartOfAccountsTemplatesTableView,
        getDef: getDefinitions,
        renderScrollBar: true
    };
}

export default withAlert({
    autoHide: true,
    position: AlertPosition.CenteredBottom
})(withRouter(withOData(withTranslation(["ChartsOfAccountsTemplates", "ChartsOfAccounts", "Error", "Common"])(withBusyIndicator()(TemplateEditPage)))));
