/** Adds time resolution definitions to document definition */
import { IDefinition, TFieldDefinition } from "@pages/PageUtils";
import i18next from "i18next";
import memoizeOne from "../../../../utils/memoizeOne";
import { isNotDefined, roundToDecimalPlaces } from "@utils/general";
import { Sort } from "../../../../enums";
import { IFormGroupDef } from "@components/smart/smartFormGroup/SmartFormGroup";
import {
    DeferredPlanPostingTypeCode,
    DocumentLinkTypeCode,
    DocumentTypeCode,
    PeriodDayCode,
    SelectionCode
} from "@odata/GeneratedEnums";
import {
    EntitySetName,
    ICompanyEntity,
    IDeferredPlanEntity,
    IDocumentAccountAssignmentEntity,
    IDocumentEntity,
    IDocumentItemEntity
} from "@odata/GeneratedEntityTypes";
import { IGetValueArgs } from "@components/smart/FieldInfo";
import { FormStorage } from "../../../../views/formView/FormStorage";
import BindingContext, { IEntity } from "../../../../odata/BindingContext";
import { addLineActionInfoValueCallback } from "../../../../views/formView/Form.utils";
import { getAccountSelectionFieldsDef } from "@pages/accountAssignment/AccountAssignment.utils";
import { isIssued, isReceived } from "../../Document.utils";
import { getUtcDayjs } from "../../../../types/Date";

export const TIME_RES_ACTION = "timeResolution";
export const REMOVE_TIME_RES_ACTION = "removeTimeResolution";
export const DEFERRED_PLANS_LINK_TYPES = [DocumentLinkTypeCode.DeferredExpense, DocumentLinkTypeCode.DeferredRevenue];

const accSelFieldsDef = getAccountSelectionFieldsDef(DocumentTypeCode.InternalDocument, {
    skipCreateCustom: true,
    skipNoneItem: true,
    skipNoRecordText: true,
    isLabelWithoutDefaultPrefix: true
});
export const timeResDebitAccountPath = "AccountAssignmentSelection/AccountAssignment/DebitAccount";

export const getTimeResAccountFieldDef = (receivable: boolean): TFieldDefinition => {
    return {
        ...accSelFieldsDef[timeResDebitAccountPath],
        isRequired: true,
        label: i18next.t(`Document:Form.${receivable ? "DebitAccount" : "CreditAccount"}`),
        filter: {
            // enhance original filter with another filter
            select: (args, other) => {
                const originalFilter = (accSelFieldsDef[timeResDebitAccountPath].filter.select as string);

                return `${originalFilter} AND startswith(Number,'${receivable ? "5" : "6"}')`;
            }
        },
        additionalProperties: accSelFieldsDef[timeResDebitAccountPath].additionalProperties.filter(obj => !obj.id.startsWith(`/${timeResDebitAccountPath}`))
    };
};
const isItemSelectable = (args: IGetValueArgs) => {
    const itemId = args.item.Id;
    const storage = args.storage as FormStorage;
    const documentTypeCode = args.storage.data.entity.DocumentTypeCode as DocumentTypeCode;
    // actions works with already saved forms (origData)
    const origEntity = storage.data.origEntity as IDocumentEntity;
    const origItem = ((origEntity as any).Items)?.find((item: IDocumentItemEntity) => item.Id === itemId);
    let accountAssignment: IDocumentAccountAssignmentEntity;

    // disable if item is already part of any deferred plan
    if (!origItem || (origEntity.DeferredPlans && getDeferredPlanForItem(origEntity.DeferredPlans, itemId))) {
        return false;
    }

    if (origItem.AccountAssignmentSelection?.Selection?.Code === SelectionCode.Default) {
        accountAssignment = origEntity.AccountAssignmentSelection?.AccountAssignment;
    } else {
        accountAssignment = origItem.AccountAssignmentSelection?.AccountAssignment;
    }

    if (isReceived(documentTypeCode)) {
        const debitAccount = accountAssignment?.DebitAccount;

        return debitAccount?.Number?.startsWith("381");
    } else if (isIssued(documentTypeCode)) {
        const creditAccount = accountAssignment?.CreditAccount;

        return creditAccount?.Number?.startsWith("384");
    }

    return false;
};

export function addTimeResolutionDef(definition: IDefinition): void {
    // Add LineItems actions for time resolution
    const itemsGroup = definition.form.groups.find(group => group.id === "Items");

    addLineActionInfoValueCallback(itemsGroup, "isItemSelectable", [TIME_RES_ACTION], isItemSelectable);

    itemsGroup.lineItems.actions = itemsGroup.lineItems.actions ?? [];
    itemsGroup.lineItems.actions.push(
        {
            id: TIME_RES_ACTION,
            label: i18next.t("Document:Form.TimeResolution"),
            iconName: "LastUpdated",
            confirmLabel: i18next.t("Document:Form.TimeResolutionConfirm"),
            isDisabled: (args: IGetValueArgs) => {
                const items = args.storage.getValue(args.bindingContext) ?? [];
                // at least one item has to be selectable for the action to be enabled
                return !items.some((item: IEntity) => {
                    return isItemSelectable({
                        ...args,
                        item
                    });
                });
            }
        }
    );
    itemsGroup.lineItems.customActionButtons = [{
        id: REMOVE_TIME_RES_ACTION,
        title: i18next.t("Document:Form.TimeResolutionConfirmCancel"),
        isVisible: (args) => {
            return !!args.storage.data.entity?.DeferredPlans && args.storage.data.entity.DeferredPlans.length !== 0;
        }
    }];

    if (!definition.form.additionalProperties) {
        definition.form.additionalProperties = [];
    }

    definition.form.additionalProperties.push({
        id: "DeferredPlans",
        additionalProperties: [
            { id: "DateStart" },
            { id: "OriginalDocumentItems", additionalProperties: [{ id: "OriginalDocumentItem" }] }
        ]
    });

    const timeResTab: IFormGroupDef = getTimeResTab(definition.entitySet as string);

    // Add time res tab
    const tabsGroup: IFormGroupDef = definition.form.groups.find(group => group.id === "Tabs");
    tabsGroup.tabs.push(timeResTab);
}

export const getTimeResTab = (formEntitySet: string, isTargetDocument?: boolean): IFormGroupDef => {
    return {
        id: "TimeResolution",
        title: i18next.t("Document:FormTab.TimeResolution"),
        table: {
            id: `timeResolution_${formEntitySet}`,
            entitySet: EntitySetName.DeferredDocuments,
            parentKey: "Document/Id",
            filter: ({ storage }) => (
                `${!isTargetDocument ? "OriginalDocument" : "TargetDocument"}/Id eq ${storage.data?.entity?.Id}`
            ),
            initialSortBy: [{
                id: "DateAccountingTransaction",
                sort: Sort.Asc
            }],
            columns: [
                { id: "DateAccountingTransaction" },
                {
                    id: `${!isTargetDocument ? "TargetDocument" : "OriginalDocument"}/DocumentType`,
                    fieldSettings: {
                        displayName: "Name"
                    },
                    label: i18next.t("Document:PairedDocuments.DocumentType"),
                    formatter: (val, args) => {
                        if (isNotDefined(val)) {
                            return i18next.t("TimeResolution:TimePlanTable.PlannedDocument") as string;
                        }

                        return val as string;
                    }
                },
                {
                    id: !isTargetDocument ? "TargetDocument" : "OriginalDocument",
                    fieldSettings: {
                        displayName: "NumberOurs"
                    }
                },
                {
                    id: "DocumentLinkType",
                    fieldSettings: {
                        displayName: "Name"
                    },
                    label: i18next.t("Document:PairedDocuments.Type")
                },
                { id: "Amount" }
            ]
        }
    };
};

export interface IDeferredItemTemplate {
    dateAccountingTransaction: Date;
    amount: number;
}

export const getFullAmount = (deferredPlan: IDeferredPlanEntity) => {
    return (deferredPlan as any)[BindingContext.localContext("fullAmount")] ?? 0;
};

export const getDeferredItems = (deferredPlan: IDeferredPlanEntity, company: ICompanyEntity): IDeferredItemTemplate[] => {
    const amount = getFullAmount(deferredPlan);
    const items = computeDeferredItems(amount, deferredPlan.DateStart, deferredPlan.DateEnd, deferredPlan.PostingType.Code as DeferredPlanPostingTypeCode, deferredPlan.PeriodDay.Code as PeriodDayCode);

    return items;
};

export const computeDeferredItems = memoizeOne(
    (amount: number, dateStart: Date, dateEnd: Date, postingType: DeferredPlanPostingTypeCode, periodDay: PeriodDayCode): IDeferredItemTemplate[] => {
        const items: IDeferredItemTemplate[] = [];

        if (!dateStart || !dateEnd) {
            return items;
        }

        const start = getUtcDayjs(dateStart).startOf("day");
        const end = getUtcDayjs(dateEnd).startOf("day");
        const periodName = postingType === DeferredPlanPostingTypeCode.Monthly ? "month" : "year";
        const numOfDays = end.diff(start, "days") + 1;
        let periodStart = start;
        let roundedItemsSum = 0;

        while (periodStart.isSameOrBefore(end, "date")) {
            const nextPeriodStart = periodStart.add(1, periodName).startOf(periodName);
            const isLastItem = nextPeriodStart.isAfter(end);
            const thisPeriodEnd = isLastItem ? end : nextPeriodStart.subtract(1, "day");
            const itemAmount = (amount * (thisPeriodEnd.diff(periodStart, "days") + 1)) / numOfDays;
            let dateAccTrans = periodDay === PeriodDayCode.LastDay ? periodStart.endOf(periodName) : periodStart.startOf(periodName);
            let itemAmountRounded = Math.ceil(itemAmount);

            // dateAccTrans cannot be before dateStart, but it can be after dateEnd
            if (dateAccTrans.isBefore(start)) {
                dateAccTrans = start;
            }

            if (!isLastItem) {
                roundedItemsSum += itemAmountRounded;
            } else {
                // round as well, to prevent js funny rounding errors
                itemAmountRounded = roundToDecimalPlaces(2, amount - roundedItemsSum);
            }

            items.push({
                dateAccountingTransaction: dateAccTrans.toDate(),
                amount: itemAmountRounded
            });

            periodStart = nextPeriodStart;
        }

        return items;
    }
);

// returns deferred plan that contains item with given id
export const getDeferredPlanForItem = (deferredPlans: IDeferredPlanEntity[], itemId: number) => {
    return deferredPlans.find((plan: IDeferredPlanEntity) => plan.OriginalDocumentItems.some(item => item.OriginalDocumentItem.Id === itemId));
};