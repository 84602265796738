import React from "react";
import { Dayjs } from "dayjs";
import { Calendar } from "./Calendar";
import { getValidDayjsDate, isMaxDay, isMinDay } from "../utils";
import DateType, { getUtcDayjs } from "../../../../types/Date";
import { DatePickerView } from "../../../../enums";
import { IDateSingleValuePopupSharedProps } from "./Calendar.utils";
import memoizeOne from "../../../../utils/memoizeOne";

interface IProps extends IDateSingleValuePopupSharedProps {
    previewValue?: Date;
    defaultView?: DatePickerView;
}

interface IState {
    previewDay: Dayjs;
    view: DatePickerView;
}

export default class MonthYearPopup extends React.PureComponent<IProps, IState> {
    static defaultProps: Partial<IProps> = {
        disableAutoFocus: true
    };

    constructor(props: IProps) {
        super(props);

        const isSpecialDay = isMinDay(props.value) || isMaxDay(props.value);

        this.state = {
            previewDay: getValidDayjsDate(isSpecialDay ? null : this.props.value ?? this.props.previewValue),
            view: this.props.defaultView ? this.props.defaultView : DatePickerView.Months
        };
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>) {
        if (this.props.value !== prevProps.value && DateType.isValid(this.props.value)) {
            this.setState({ previewDay: getValidDayjsDate(this.props.value) });
        }
    }

    handlePreviewChange = (day: Dayjs): void => {
        this.setState({
            previewDay: day
        });
    };

    handleViewChange = (view: DatePickerView): void => {
        this.setState({
            view: view
        });
    };

    handleCalendarChange = (day: Dayjs): void => {
        this.props.onChange(day.toDate());
    };

    getValue = memoizeOne(() => {
        return !this.props.value ? null : getUtcDayjs(this.props.value);
    }, () => [this.props.value]);


    render = (): React.ReactElement => {
        return (
            <Calendar previewDay={this.state.previewDay}
                      value={this.getValue()}
                      view={this.state.view}
                      mainView={DatePickerView.Months}
                      onChange={this.handleCalendarChange}
                      minDate={this.props.minDate}
                      maxDate={this.props.maxDate}
                      isDateDisabled={this.props.isDateDisabled}
                      embedded={this.props.embedded}
                      showSpecialValue={this.props.showSpecialValue}
                      disableAutoFocus={this.props.disableAutoFocus}
                      onPreviewChange={this.handlePreviewChange}
                      onViewChange={this.handleViewChange}/>
        );
    };
}