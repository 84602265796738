import { ISelectItem } from "@components/inputs/select/Select.types";
import { ISmartFieldBlur, ISmartFieldChange } from "@components/smart/smartField/SmartField";
import { fetchItemsByInfo } from "@components/smart/smartSelect/SmartSelectAPI";
import BindingContext, { createPath, IEntity } from "@odata/BindingContext";
import {
    IItemTemplateEntity,
    ItemTemplateAccountAssignmentEntity,
    ItemTemplateAccountAssignmentSelectionEntity,
    ItemTemplateCbaTaxImpactEntity,
    ItemTemplateEntity,
    ItemTemplateVatClassificationEntity,
    ItemTemplateVatClassificationSelectionEntity
} from "@odata/GeneratedEntityTypes";
import { CbaCategoryTaxImpactCode, SelectionCode, VatDeductionTypeCode } from "@odata/GeneratedEnums";
import { getShortNameFromAccountNumbers } from "@pages/accountAssignment/AccountAssignment.utils";
import {
    DialogType,
    IItemTemplateCustomData,
    loadAccountAssignments,
    nonTaxAssetID
} from "@pages/admin/itemTemplates/ItemTemplates.utils";
import {
    AASelectionCodePath,
    AccAssCreditAccountPath,
    AccAssDebitAccountPath,
    AccAssNamePath,
    AccAssShortNamePath,
    additionalAssignmentSelectionCodes,
    additionalVatClassificationSelectionCodes,
    CbaTaxImpactLocalPath,
    CbaTaxPercentagePath,
    NonTaxAccountPath,
    recalculateTriggerProps,
    selectionAccountPaths,
    vatClassificationAccountPaths,
    VatProportionalDeductionPath
} from "@pages/admin/itemTemplates/ItemTemplatesDef";
import { isProportionalDeduction } from "@pages/admin/vatRules/VatRules.utils";
import { anyPathEquals, calcLineItemValues, loadVats } from "@pages/documents/Document.utils";
import { isDefined } from "@utils/general";
import { cloneDeep } from "lodash";
import React from "react";

import { withPermissionContext } from "../../../contexts/permissionContext/withPermissionContext";
import { TRecordAny } from "../../../global.types";
import { FormViewForExtend, IFormViewProps } from "../../../views/formView/FormView";
import TemporalFormDialog from "../../../views/formView/TemporalFormDialog";

interface IProps extends IFormViewProps<IItemTemplateEntity, IItemTemplateCustomData> {
}

class ItemTemplatesFormView extends FormViewForExtend<IItemTemplateEntity, IProps> {
    static defaultProps = {
        title: "FormTitle"
    };

    constructor(props: IProps) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.save = this.save.bind(this);
        this.onAfterLoad = this.onAfterLoad.bind(this);
        this.onBeforeSave = this.onBeforeSave.bind(this);
    }

    getAdditionalLoadPromise = () => {
        const { storage } = this.props;
        return [loadVats(storage.context, storage), loadAccountAssignments(storage)];
    }

    onAfterLoad = async () => {
        // find correct account assignment ID, correct selection ID, so it shows the correct value
        this.correctAccountAssignmentId();
        // set correct VatClassification ID, so the select shows correct value
        this.correctVatClassificationId();
        // set correct local context value for CbaTaxImpact field
        this.setCbaTaxImpactLocalContext();

        const [items] = await this.preloadFlattenAccounts();
        if (!this.props.storage.data.bindingContext.isNew()) {
            this.setAccountValues(items);
        }

        return super.onAfterLoad();
    };

    async preloadFlattenAccounts(): Promise<ISelectItem[][]> {
        const { storage } = this.props;
        const info = storage.getInfo(storage.data.bindingContext.navigate(AccAssCreditAccountPath));
        const infoNonTax = storage.getInfo(storage.data.bindingContext.navigate(NonTaxAccountPath));
        const [items, nonTaxItems] = await Promise.all([fetchItemsByInfo(storage, info), fetchItemsByInfo(storage, infoNonTax)]);
        storage.setCustomData({ FlattenAccounts: items, FlattenNonTaxAccounts: nonTaxItems });
        return [items, nonTaxItems];
    }

    correctVatClassificationId() {
        const { storage } = this.props;
        const { entity, bindingContext } = storage.data;

        let correctId: string | number;
        const selectionCode = entity.VatClassificationSelection?.SelectionCode as (SelectionCode | undefined);
        if (!selectionCode || additionalVatClassificationSelectionCodes.includes(selectionCode)) {
            correctId = selectionCode;
        } else if (selectionCode === SelectionCode.Own) {
            // matching ID
            correctId = entity?.VatClassificationSelection?.VatClassification?.VatDeductionTypeCode;
        }
        const bc = bindingContext
                .navigate(ItemTemplateEntity.VatClassificationSelection);
        storage.setValue(bc, correctId);
    }

    handleVatClassificationSelectionChange = (e: ISmartFieldChange) => {
        const { storage } = this.props;
        const path = e.bindingContext.getPath() as ItemTemplateEntity;

        if (path === ItemTemplateEntity.VatClassificationSelection) {
            let selectionCode = e.value as SelectionCode;
            let deductionType: VatDeductionTypeCode = null;

            if (selectionCode && !additionalVatClassificationSelectionCodes.includes(selectionCode)) {
                selectionCode = SelectionCode.Own;
                deductionType = e.value as VatDeductionTypeCode;
            }
            const bc = e.bindingContext.navigate(ItemTemplateVatClassificationSelectionEntity.SelectionCode);
            storage.setValue(bc, selectionCode);
            storage.setValue(e.bindingContext, e.value);

            const deductionTypeBc = e.bindingContext
                    .navigate(ItemTemplateVatClassificationSelectionEntity.VatClassification)
                    .navigate(ItemTemplateVatClassificationEntity.VatDeductionTypeCode);
            storage.setValue(deductionTypeBc, deductionType);
            if (!isProportionalDeduction(deductionType)) {
                storage.clearValueByPath(VatProportionalDeductionPath);
                storage.clearValueByPath(NonTaxAccountPath);
            }
        }
    };

    handleVatClassificationDialog = ({
                                         value,
                                         triggerAdditionalTasks,
                                         bindingContext
                                     }: ISmartFieldChange) => {
        const { storage } = this.props;
        const path = bindingContext.getPath() as ItemTemplateEntity;
        if (triggerAdditionalTasks && path === ItemTemplateEntity.VatClassificationSelection && isProportionalDeduction(value)) {
            storage.setCustomData({ openedDialog: DialogType.VatDeduction });
            storage.setTemporalData(bindingContext, { value });
            // needed for isRequired validation
            const deductionTypeBc = bindingContext
                    .navigate(ItemTemplateVatClassificationSelectionEntity.VatClassification)
                    .navigate(ItemTemplateVatClassificationEntity.VatDeductionTypeCode);
            storage.setTemporalData(deductionTypeBc, { value });
            const { entity } = storage.data;
            if (!entity.VatClassificationSelection?.VatClassification) {
                entity.VatClassificationSelection = {
                    ...entity.VatClassificationSelection,
                    VatClassification: {}
                };
            }
            this.setAccountValues(storage.getCustomData().FlattenAccounts);
            storage.refresh();
            // stops processing the change -> just open the dialog
            return true;
        }
        return false;
    };

    setCbaTaxImpactLocalContext = () => {
        const { storage } = this.props;
        const { entity } = storage.data;

        let correctId: string = entity.CbaTaxImpact.TaxImpactCode;
        if (entity.CbaTaxImpact.IsAssetAcquisition) {
            correctId = nonTaxAssetID;
        }
        storage.setValueByPath(CbaTaxImpactLocalPath, correctId);
    };

    handleTaxImpactDialog = ({ value, triggerAdditionalTasks, bindingContext }: ISmartFieldChange) => {
        const { storage } = this.props;
        const path = bindingContext.getPath() as ItemTemplateEntity;
        if (triggerAdditionalTasks && path === CbaTaxImpactLocalPath && value === CbaCategoryTaxImpactCode.Partial) {
            storage.setCustomData({ openedDialog: DialogType.TaxImpact });
            storage.setTemporalData(bindingContext, { value });
            // needed for isRequired validation
            const taxImpactCodeBc = storage.data.bindingContext
                    .navigate(ItemTemplateEntity.CbaTaxImpact)
                    .navigate(ItemTemplateCbaTaxImpactEntity.TaxImpactCode);
            storage.setTemporalData(taxImpactCodeBc, { value });
            storage.refresh();
            // stops processing the change -> just open the dialog
            return true;
        }
        return false;
    };

    correctAccountAssignmentId() {
        // finds matching account assignment ID and correct it in the entity, so the custom select shows correct value
        const { storage } = this.props;
        const { entity } = storage.data;
        const compareProps = [
            ItemTemplateAccountAssignmentEntity.ShortName,
            ItemTemplateAccountAssignmentEntity.Name,
            ItemTemplateAccountAssignmentEntity.CreditAccountName,
            ItemTemplateAccountAssignmentEntity.CreditAccountNumber,
            ItemTemplateAccountAssignmentEntity.DebitAccountName,
            ItemTemplateAccountAssignmentEntity.DebitAccountNumber,
        ];
        let correctId: string | number;
        const selectionCode = entity.AccountAssignmentSelection?.SelectionCode as (SelectionCode | undefined);
        if (!selectionCode || additionalAssignmentSelectionCodes.includes(selectionCode)) {
            correctId = selectionCode;
        } else if (selectionCode === SelectionCode.Copy) {
            // find matching ID
            const { AccountAssignments } = storage.getCustomData();
            const entityAA = entity.AccountAssignmentSelection?.AccountAssignment;
            const matching = AccountAssignments?.find(aa => compareProps.every(propName => aa[propName] === entityAA[propName]));
            correctId = matching?.Id ?? SelectionCode.Own;
        }
        storage.setValue(storage.data.bindingContext.navigate(ItemTemplateEntity.AccountAssignmentSelection), correctId);
    }

    handleAccountAssignmentSelectionChange = (e: ISmartFieldChange) => {
        const { storage } = this.props;
        const path = e.bindingContext.getPath() as ItemTemplateEntity;
        if (path === ItemTemplateEntity.AccountAssignmentSelection) {
            let selectionCode = e.value as SelectionCode;

            if (typeof e.value === "number") {
                selectionCode = SelectionCode.Copy;
            }
            const bc = e.bindingContext.navigate(ItemTemplateAccountAssignmentSelectionEntity.SelectionCode);
            storage.setValue(bc, selectionCode);
            storage.setValue(e.bindingContext, e.value);
        }
    };

    handleTaxImpactChange = (e: ISmartFieldChange) => {
        const { storage } = this.props;
        const path = e.bindingContext.getPath() as ItemTemplateEntity;
        if (path === CbaTaxImpactLocalPath) {
            let impactCode = e.value as CbaCategoryTaxImpactCode;
            const taxImpactBc = storage.data.bindingContext.navigate(ItemTemplateEntity.CbaTaxImpact);

            const isAssetAcquisition = e.value === nonTaxAssetID;
            if (isAssetAcquisition) {
                impactCode = CbaCategoryTaxImpactCode.Nontax;
            }
            storage.setValue(taxImpactBc.navigate(ItemTemplateCbaTaxImpactEntity.IsAssetAcquisition), isAssetAcquisition);
            const bc = taxImpactBc.navigate(ItemTemplateCbaTaxImpactEntity.TaxImpactCode);
            storage.setValue(bc, impactCode);
            storage.setValue(e.bindingContext, e.value);
        }
    };

    setAccountValues = (items: ISelectItem[]): void => {
        const storage = this.props.storage;
        const { entity } = storage.data;
        const { AccountAssignment } = entity.AccountAssignmentSelection ?? {};
        const { VatClassification } = entity.VatClassificationSelection ?? {};
        let creditId, debitId, nonTaxId;

        items.forEach(item => {
            const { Name, Number } = item.additionalData;
            if (VatClassification?.NonTaxAccountNumber && Number === VatClassification.NonTaxAccountNumber && Name === VatClassification.NonTaxAccountName) {
                nonTaxId = item.id;
            }
            if (AccountAssignment?.CreditAccountNumber && Number === AccountAssignment.CreditAccountNumber && Name === AccountAssignment.CreditAccountName) {
                creditId = item.id;
            }
            if (AccountAssignment?.DebitAccountNumber && Number === AccountAssignment.DebitAccountNumber && Name === AccountAssignment.DebitAccountName) {
                debitId = item.id;
            }
        });
        storage.setValueByPath(NonTaxAccountPath, nonTaxId, true);
        storage.setValueByPath(AccAssCreditAccountPath, creditId, true);
        storage.setValueByPath(AccAssDebitAccountPath, debitId, true);
    };

    handleAccountAssignmentDialog = ({ value, triggerAdditionalTasks, bindingContext }: ISmartFieldChange) => {
        const { storage } = this.props;
        const path = bindingContext.getPath() as ItemTemplateEntity;
        if (triggerAdditionalTasks && path === ItemTemplateEntity.AccountAssignmentSelection && value === SelectionCode.Own) {
            storage.setCustomData({ openedDialog: DialogType.AccountAssignment });
            storage.setTemporalData(bindingContext, { value });
            // needed for isRequired validation
            storage.setTemporalData(storage.data.bindingContext.navigate(AASelectionCodePath), { value });
            const { entity } = storage.data;
            if (!entity.AccountAssignmentSelection?.AccountAssignment) {
                entity.AccountAssignmentSelection = {
                    ...entity.AccountAssignmentSelection,
                    AccountAssignment: {}
                };
            }
            this.setAccountValues(storage.getCustomData().FlattenAccounts);
            storage.refresh();
            // stops processing the change -> just open the dialog
            return true;
        }
        return false;
    };

    handleChange = (e: ISmartFieldChange) => {
        const { storage } = this.props;
        if (this.handleAccountAssignmentDialog(e) || this.handleVatClassificationDialog(e) || this.handleTaxImpactDialog(e)) {
            // just open temporal dialog -> no real change
            return;
        }

        storage.handleChange(e);

        if (e.triggerAdditionalTasks) {
            this.handleTaxImpactChange(e);
            this.handleAccountAssignmentSelectionChange(e);
            this.handleVatClassificationSelectionChange(e);
            this.updateAmounts(e.bindingContext);

            const path = e.bindingContext.getPath();
            if (path === ItemTemplateEntity.Accounting || path === ItemTemplateEntity.VatStatus) {
                // remove all selected companies as they can't match the new selection of conditions
                if (!storage.getValueByPath(ItemTemplateEntity.AlwaysOffer)) {
                    storage.setValueByPath(ItemTemplateEntity.AllowedCompanies, []);
                }
                // refresh whole default values group to update field visibility
                storage.refreshGroup(storage.data.bindingContext.navigate(ItemTemplateEntity.Quantity));
            }
        }

        storage.refreshFields();
    };

    handleAccountTemporalChange = (e: ISmartFieldChange): void => {
        const { storage } = this.props;
        const path = e.bindingContext.getPath();
        if ([AccAssCreditAccountPath, AccAssDebitAccountPath, NonTaxAccountPath].includes(path) && e.triggerAdditionalTasks) {
            const cleanPath = BindingContext.cleanLocalContext(path);
            const { Name, Number } = e.additionalData ?? {};

            const basePath = path === NonTaxAccountPath
                    ? createPath(ItemTemplateEntity.VatClassificationSelection, ItemTemplateVatClassificationSelectionEntity.VatClassification)
                    : createPath(ItemTemplateEntity.AccountAssignmentSelection, ItemTemplateAccountAssignmentSelectionEntity.AccountAssignment);
            const bc = storage.data.bindingContext.navigate(basePath);

            storage.setTemporalData(bc.navigate(`${cleanPath}Name`), { value: Name });
            storage.setTemporalData(bc.navigate(`${cleanPath}Number`), { value: Number });
        }
    };

    async handleBlur(args: ISmartFieldBlur): Promise<void> {
        const error = await this.props.storage.handleBlur(args);

        if (args.wasChanged) {
            if (!error) {
                // don't update values if last changed caused validation error
                await this.updateAmounts(args.bindingContext);
            }
        }
        this.props.storage.refreshFields();
    }

    async updateAmounts(bc: BindingContext) {
        const { storage } = this.props;
        const { bindingContext } = storage.data;
        const triggered = anyPathEquals(bc, recalculateTriggerProps, bindingContext.getFullPath(true));

        if (!triggered) {
            return;
        }

        const value = storage.getValue(bc);
        const path = bc.getPath() as ItemTemplateEntity;
        const newData = (isDefined(value) || path === ItemTemplateEntity.Vat) ? this.recalculateAmounts(path) : {};

        recalculateTriggerProps.forEach(prop => {
            if (isDefined(newData[prop]) || !["Quantity", "Vat"].includes(prop)) {
                storage.setValue(storage.data.bindingContext.navigate(prop), newData[prop]);
            }
        });
        storage.refresh();

        await storage.validateFields(recalculateTriggerProps.map(prop => storage.data.bindingContext.navigate(prop)));
        storage.refreshFields(true);
    }

    _lastChangedProp: ItemTemplateEntity | null = null;

    recalculateAmounts(changedProp: ItemTemplateEntity) {
        const nonNumericProps = [ItemTemplateEntity.Vat];
        const { entity } = this.props.storage.data;
        const decimalPlaces = entity.TransactionCurrency?.MinorUnit ?? 2;
        const newData: TRecordAny = {};
        recalculateTriggerProps.forEach(prop => {
            newData[prop] = entity[prop];
        });

        if (!nonNumericProps.includes(changedProp)) {
            newData[changedProp] = parseFloat(newData[changedProp]);

            if (isNaN(newData[changedProp])) {
                newData[changedProp] = 0;
            }
        }

        // todo what to do with big values? causes Infinity and NaN
        if (isNaN(parseFloat(newData.Quantity))) {
            newData.Quantity = 1;
        }

        const lastChangedValue = this._lastChangedProp;

        const newValues = calcLineItemValues({
            Quantity: newData.Quantity,
            TransactionAmount: newData.TransactionAmount,
            TransactionAmountNet: newData.TransactionAmountNet,
            TransactionAmountVat: newData.TransactionAmountVat,
            // if we select 'without VAT' (value is null) we want to calculate with 0 vat rate
            Vat: (newData.Vat?.Code && newData.Vat?.Rate) ?? 0
        }, changedProp, lastChangedValue, decimalPlaces);

        if (changedProp === ItemTemplateEntity.TransactionAmount) {
            this._lastChangedProp = ItemTemplateEntity.TransactionAmount;
        } else if (changedProp === ItemTemplateEntity.TransactionAmountVat) {
            this._lastChangedProp = ItemTemplateEntity.TransactionAmountVat;
        } else {
            // keep the original value, e.g. if user changes Vat twice, it should behave in a same way
        }

        newData.TransactionAmount = newValues.TransactionAmount;
        newData.TransactionAmountNet = newValues.TransactionAmountNet;
        newData.TransactionAmountVat = newValues.TransactionAmountVat;

        return newData;
    }

    onBeforeSave = (): IEntity => {
        const entity = cloneDeep(this.entity);

        const _selAACode = entity.AccountAssignmentSelection?.SelectionCode as (SelectionCode | undefined);
        if (!_selAACode) {
            entity.AccountAssignmentSelection = null;
        } else if (![SelectionCode.Own, SelectionCode.Copy].includes(_selAACode)) {
            entity.AccountAssignmentSelection.AccountAssignment = null;
        }

        const _selVatCode = entity.VatClassificationSelection?.SelectionCode as (SelectionCode | undefined);
        if (!_selVatCode) {
            entity.VatClassificationSelection = null;
        } else if (_selVatCode !== SelectionCode.Own) {
            entity.VatClassificationSelection.VatClassification = null;
        }

        const _taxImpactCode = entity.CbaTaxImpact.TaxImpactCode as (CbaCategoryTaxImpactCode | undefined);
        if (!_taxImpactCode) {
            entity.CbaTaxImpact = null;
        } else {
            if (_taxImpactCode !== CbaCategoryTaxImpactCode.Partial) {
                entity.CbaTaxImpact.TaxPercentage = _taxImpactCode === CbaCategoryTaxImpactCode.Nontax ? 0 : 100;
            }
        }

        return entity;
    };

    renderCustomDialogs() {
        return (<>
            {this.renderAccountAssignmentDialog()}
            {this.renderVatClassificationDialog()}
            {this.renderTaxImpactDialog()}
        </>);
    }

    renderTaxImpactDialog = (): React.ReactElement => {
        const { storage } = this.props;
        if (storage.getCustomData().openedDialog !== DialogType.TaxImpact) {
            return null;
        }

        const paths = [
            [CbaTaxPercentagePath]
        ];

        const { bindingContext } = storage.data;
        const _close = async (isConfirmed: boolean) => {
            const taxImpactBc = bindingContext.navigate(ItemTemplateEntity.CbaTaxImpact);
            const taxImpactLocalBc = bindingContext.navigate(CbaTaxImpactLocalPath);
            const percentageBc = bindingContext.navigate(CbaTaxPercentagePath);
            if (isConfirmed) {
                // confirm additional Fields not visible in the dialog form
                await Promise.all([
                    storage.confirmFields([percentageBc], this.handleChange),
                    storage.confirmFields([taxImpactLocalBc], this.handleTaxImpactChange)
                ]);
            } else {
                storage.cancelFields([percentageBc, taxImpactLocalBc, taxImpactBc]);
            }
            storage.setCustomData({ openedDialog: null });
            this.forceUpdate();
        };

        return (
                <TemporalFormDialog storage={this.props.storage}
                                    width="450px"
                                    onChange={this.handleChange}
                                    fieldPaths={paths}
                                    title={this.props.storage.t("ItemTemplates:Form.TaxImpactDialogTitle")}
                                    onClose={_close}/>
        );
    };

    renderAccountAssignmentDialog = (): React.ReactElement => {
        const { storage } = this.props;
        if (storage.getCustomData().openedDialog !== DialogType.AccountAssignment) {
            return null;
        }

        const paths = [
            [AccAssDebitAccountPath, AccAssCreditAccountPath],
            [AccAssNamePath]
        ];

        const { bindingContext } = storage.data;
        const _close = async (isConfirmed: boolean) => {
            const selectionBc = bindingContext.navigate(ItemTemplateEntity.AccountAssignmentSelection);
            const bcs = selectionAccountPaths.map(path => selectionBc.navigate(path));
            if (isConfirmed) {
                // confirm additional Fields not visible in the dialog form
                await Promise.all([
                    storage.confirmFields(bcs, this.handleChange),
                    storage.confirmFields([selectionBc], this.handleAccountAssignmentSelectionChange)
                ]);
                const { CreditAccountNumber, DebitAccountNumber} = storage.data.entity.AccountAssignmentSelection.AccountAssignment;
                const defaultShortName = getShortNameFromAccountNumbers(DebitAccountNumber, CreditAccountNumber);
                storage.setValueByPath(AccAssShortNamePath, defaultShortName);
            } else {
                storage.cancelFields([...bcs, selectionBc, storage.data.bindingContext.navigate(AASelectionCodePath)]);
            }
            storage.setCustomData({ openedDialog: null });
            this.forceUpdate();
        };

        return (
                <TemporalFormDialog storage={this.props.storage}
                                    width="670px"
                                    onTemporalChange={this.handleAccountTemporalChange}
                                    onChange={this.handleChange}
                                    fieldPaths={paths}
                                    title={this.props.storage.t("ItemTemplates:Form.AccountAssignmentDialogTitle")}
                                    onClose={_close}/>
        );
    };

    renderVatClassificationDialog = (): React.ReactElement => {
        const { storage } = this.props;
        if (storage.getCustomData().openedDialog !== DialogType.VatDeduction) {
            return null;
        }

        const paths = [[VatProportionalDeductionPath, NonTaxAccountPath]];

        const { bindingContext } = storage.data;
        const _close = async (isConfirmed: boolean) => {
            const selectionBc = bindingContext.navigate(ItemTemplateEntity.VatClassificationSelection);
            const bcs = vatClassificationAccountPaths.map(path => selectionBc.navigate(path));
            const deductionTypeBc = selectionBc
                    .navigate(ItemTemplateVatClassificationSelectionEntity.VatClassification)
                    .navigate(ItemTemplateVatClassificationEntity.VatDeductionTypeCode);
            if (isConfirmed) {
                // confirm additional Fields not visible in the dialog form
                await Promise.all([
                    storage.confirmFields(bcs, this.handleChange),
                    storage.confirmFields([selectionBc], this.handleVatClassificationSelectionChange)
                ]);
            } else {
                storage.cancelFields([...bcs, selectionBc, deductionTypeBc]);
            }
            storage.setCustomData({ openedDialog: null });
            this.forceUpdate();
        };

        return (
                <TemporalFormDialog storage={this.props.storage}
                                    width="550px"
                                    onTemporalChange={this.handleAccountTemporalChange}
                                    onChange={this.handleChange}
                                    fieldPaths={paths}
                                    title={this.props.storage.t("ItemTemplates:Form.VatClassificationDialogTitle")}
                                    onClose={_close}/>
        );
    };
}

export default withPermissionContext(ItemTemplatesFormView);
