import React, { lazy } from "react";
import { EntityTypeName } from "@odata/GeneratedEntityTypes";
import { CompanyPermissionCode } from "@odata/GeneratedEnums";
import PrivateRoute from "../routes/PrivateRoute";
import {
    ROUTE_ACCOUNT_ANALYSIS,
    ROUTE_ACCOUNTING_JOURNAL,
    ROUTE_AGING_AP,
    ROUTE_AGING_AR,
    ROUTE_ASSET_ANALYSIS,
    ROUTE_BALANCE_SHEET,
    ROUTE_CBA_ENTRY_LEDGER,
    ROUTE_DOCUMENT_JOURNAL,
    ROUTE_EXCHANGE_RATES,
    ROUTE_GENERAL_LEDGER,
    ROUTE_INCOME_AND_EXPENDITURE,
    ROUTE_INCOME_STATEMENT,
    ROUTE_PAYMENT_JOURNAL, ROUTE_RECEIVABLE_ADJUSTMENTS, ROUTE_SALDO,
    ROUTE_VAT_CONTROL_STATEMENT_REPORT,
    ROUTE_VAT_STATEMENT_REPORT,
    ROUTE_VAT_VIES_STATEMENT_REPORT
} from "../routes";
import CompanyDependentPrivateRoute from "../routes/CompanyDependentPrivateRoute";
import FormRoute from "../routes/FormRoute";

const VatControlStatementReport = lazy(() => import(/* webpackChunkName: "reports" */"../pages/reports/vatControlStatementReport/VatControlStatementReport"));
const VatVIESStatementReport = lazy(() => import(/* webpackChunkName: "reports" */"../pages/reports/vatVIESStatementReport/VatVIESStatementReport"));
const VatStatementReport = lazy(() => import(/* webpackChunkName: "reports" */"../pages/reports/vatStatementReport/VatStatementReport"));
const GeneralLedger = lazy(() => import(/* webpackChunkName: "reports" */"../pages/reports/generalLedger/GeneralLedger"));
const AccountingJournal = lazy(() => import(/* webpackChunkName: "reports" */"../pages/reports/accountingJournal/AccountingJournal"));
const AccountAnalysis = lazy(() => import(/* webpackChunkName: "reports" */"../pages/reports/accountAnalysis/AccountAnalysis"));
const AssetAnalysis = lazy(() => import(/* webpackChunkName: "reports" */"../pages/reports/assetAnalysis/AssetAnalysis"));
const CbaIncomeExpense = lazy(() => import(/* webpackChunkName: "reports" */"../pages/reports/cbaIncomeExpense/cbaIncomeExpense"));
const CbaEntryLedger = lazy(() => import(/* webpackChunkName: "reports" */"../pages/reports/cbaEntryLedger/CbaEntryLedger"));
const DocumentJournal = lazy(() => import(/* webpackChunkName: "reports" */"../pages/reports/documentJournal/DocumentJournal"));
const PaymentJournal = lazy(() => import(/* webpackChunkName: "reports" */"../pages/reports/paymentJournal/PaymentJournal"));
const AgingAP = lazy(() => import(/* webpackChunkName: "reports" */"../pages/reports/aging/AgingAP"));
const AgingAR = lazy(() => import(/* webpackChunkName: "reports" */"../pages/reports/aging/AgingAR"));
const BalanceSheet = lazy(() => import(/* webpackChunkName: "reports" */"../pages/reports/balanceSheet/BalanceSheet"));
const IncomeStatement = lazy(() => import(/* webpackChunkName: "reports" */"../pages/reports/incomeStatement/IncomeStatement"));
const ExchangeRates = lazy(() => import(/* webpackChunkName: "reports" */"../pages/reports/exchangeRates/ExchangeRate"));
const ReceivableAdjustments = lazy(() => import(/* webpackChunkName: "reports" */"../pages/reports/receivableAdjustments/ReceivableAdjustments"));
const Saldo = lazy(() => import(/* webpackChunkName: "reports" */"../pages/reports/saldo/Saldo"));

export default function reportsBundle() {
    return [
        <PrivateRoute
                path={ROUTE_GENERAL_LEDGER}
                key={ROUTE_GENERAL_LEDGER}
                permission={CompanyPermissionCode.Reports}
                component={GeneralLedger}/>,
        <PrivateRoute
                path={ROUTE_ACCOUNTING_JOURNAL}
                key={ROUTE_ACCOUNTING_JOURNAL}
                permission={CompanyPermissionCode.Reports}
                component={AccountingJournal}/>,
        <PrivateRoute
                path={ROUTE_ACCOUNT_ANALYSIS}
                key={ROUTE_ACCOUNT_ANALYSIS}
                permission={CompanyPermissionCode.Reports}
                component={AccountAnalysis}/>,
        <PrivateRoute
                path={ROUTE_ASSET_ANALYSIS}
                key={ROUTE_ASSET_ANALYSIS}
                permission={CompanyPermissionCode.Reports}
                component={AssetAnalysis}/>,
        <PrivateRoute
                path={ROUTE_INCOME_AND_EXPENDITURE}
                key={ROUTE_INCOME_AND_EXPENDITURE}
                permission={CompanyPermissionCode.Reports}
                component={CbaIncomeExpense}/>,
        <PrivateRoute
                path={ROUTE_CBA_ENTRY_LEDGER}
                key={ROUTE_CBA_ENTRY_LEDGER}
                permission={CompanyPermissionCode.Reports}
                component={CbaEntryLedger}/>,
        <PrivateRoute
                path={ROUTE_DOCUMENT_JOURNAL}
                key={ROUTE_DOCUMENT_JOURNAL}
                permission={CompanyPermissionCode.Reports}
                component={DocumentJournal}/>,
        <PrivateRoute
                path={ROUTE_PAYMENT_JOURNAL}
                key={ROUTE_PAYMENT_JOURNAL}
                permission={CompanyPermissionCode.Reports}
                component={PaymentJournal}/>,
        <PrivateRoute
                path={ROUTE_AGING_AP}
                key={ROUTE_AGING_AP}
                permission={CompanyPermissionCode.Reports}
                component={AgingAP}/>,
        <PrivateRoute
                path={ROUTE_AGING_AR}
                key={ROUTE_AGING_AR}
                permission={CompanyPermissionCode.Reports}
                component={AgingAR}/>,
        <PrivateRoute
                path={ROUTE_BALANCE_SHEET}
                key={ROUTE_BALANCE_SHEET}
                permission={CompanyPermissionCode.Reports}
                component={BalanceSheet}/>,
        <PrivateRoute
                path={ROUTE_INCOME_STATEMENT}
                key={ROUTE_INCOME_STATEMENT}
                permission={CompanyPermissionCode.Reports}
                component={IncomeStatement}/>,
        <FormRoute
                path={ROUTE_EXCHANGE_RATES}
                key={ROUTE_EXCHANGE_RATES}
                entityType={EntityTypeName.ExchangeRate}
                permission={CompanyPermissionCode.Reports}
                page={ExchangeRates}/>,
        <PrivateRoute
                path={ROUTE_VAT_CONTROL_STATEMENT_REPORT}
                key={ROUTE_VAT_CONTROL_STATEMENT_REPORT}
                permission={CompanyPermissionCode.Reports}
                component={VatControlStatementReport}/>,
        <PrivateRoute
                path={ROUTE_VAT_VIES_STATEMENT_REPORT}
                key={ROUTE_VAT_VIES_STATEMENT_REPORT}
                permission={CompanyPermissionCode.Reports}
                component={VatVIESStatementReport}/>,
        <PrivateRoute
                path={ROUTE_VAT_STATEMENT_REPORT}
                key={ROUTE_VAT_STATEMENT_REPORT}
                permission={CompanyPermissionCode.Reports}
                component={VatStatementReport}/>,
        <PrivateRoute path={ROUTE_SALDO}
                      key={ROUTE_SALDO}
                      permission={CompanyPermissionCode.Reports}
                      component={Saldo}/>,
        <CompanyDependentPrivateRoute
                path={ROUTE_RECEIVABLE_ADJUSTMENTS}
                key={ROUTE_RECEIVABLE_ADJUSTMENTS}
                permission={CompanyPermissionCode.ReceivableAdjustments}
                component={ReceivableAdjustments}/>
    ];
}