import styled from "styled-components/macro";
import { T_TITLE_4 } from "../../global.style";
import MonthYearSelector from "../inputs/date/MonthYearSelector";

const dayInputWidth = 34;

export const CalendarGrid = styled.div<{
    rowLength: number
}>`
    display: grid;
    grid-gap: 12px;
    grid-template-columns: ${props => `repeat(${props.rowLength}, 1fr)`};
`;

export const DayLabel = styled.div`
    text-transform: uppercase;
    ${T_TITLE_4};
    text-align: center;
    width: ${dayInputWidth} px;
    margin-bottom: -10px;
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const DayGridPlaceholder = styled.div``;


export const MonthYearSelectorStyled = styled(MonthYearSelector)`
    margin-bottom: 20px;
`;