import { withConfirmationDialog } from "@components/dialog/withConfirmationDialog";
import IssuedDocumentBaseFormView from "../IssuedDocumentBaseFormView";
import { withPermissionContext } from "../../../contexts/permissionContext/withPermissionContext";

import { DocumentTypeCode } from "@odata/GeneratedEnums";
import { withTimeResolution } from "../extensions/timeResolution/withTimeResolution";
import { withDomManipulator } from "../../../contexts/domManipulator/withDomManipulator";
import { withAccruals } from "../extensions/accruals/withAccruals";
import { withPromisedComponent } from "@components/dialog/withPromisedComponent";


class OtherReceivableFormView extends IssuedDocumentBaseFormView {
    documentTypeCode = DocumentTypeCode.OtherReceivable;
    documentTypePropName = "ReceivableType";
}

export default withPromisedComponent(withConfirmationDialog(withTimeResolution(withAccruals({ isExpense: false })(withPermissionContext(withDomManipulator(OtherReceivableFormView))))));
