import { getCommonFilterDefs } from "@components/smart/GeneralFieldDefinition";
import { FilterBarGroup, getDefaultFilterGroupDef } from "@components/smart/smartFilterBar/SmartFilterBar.types";
import { ISummaryItem } from "@components/smart/smartSummaryItem/SmartSummaryItem";
import { EntitySetName, EntityTypeName, PrLifeInsuranceCompanyEntity } from "@odata/GeneratedEntityTypes";
import { PrInsuranceCompanyTypeCode } from "@odata/GeneratedEnums";
import { generalBankAccountFormatter } from "@utils/BankUtils";
import i18next from "i18next";

import { Sort } from "../../../enums";
import { IFormDef } from "../../../views/formView/Form";
import { ISplitPageTableDef } from "../../../views/table/TableView.utils";
import { paymentDetailFields, SAVED_ACCOUNTS_PATH } from "../../banks/bankAccounts/BankAccounts.utils";
import { addBankAccountTableDefs } from "../../documents/DocumentCommonDefs";
import { setDefByEntityType } from "../../getDefByEntityType";
import { IDefinition, IGetDefinition } from "../../PageUtils";
import {
    getInsuranceCompaniesCommonColumnDef,
    getInsuranceCompaniesCommonFieldDef,
    getNameFieldDef
} from "../healthInsuranceCompany/insuranceCompanies.utils";
import { insuranceCompanyAccountAdditionalFields } from "../pensionInsuranceCompany/PensionInsuranceCompany.def";
import LifeInsuranceCompanyFormView from "./LifeInsuranceCompanyFormView";

export const getDefinitions: IGetDefinition = (): IDefinition => {
    const table: ISplitPageTableDef = {
        id: `${EntityTypeName.PrLifeInsuranceCompany}Table`,
        title: i18next.t("InsuranceCompanies:LifeInsurance.Title"),
        filterBarDef: [{
            ...getDefaultFilterGroupDef(FilterBarGroup.Filters),
            defaultFilters: [
                PrLifeInsuranceCompanyEntity.Name,
                PrLifeInsuranceCompanyEntity.IsActive
            ],
            filterDefinition: {
                ...getCommonFilterDefs(),
                [PrLifeInsuranceCompanyEntity.Name]: {},
                [PrLifeInsuranceCompanyEntity.IsActive]: {}
            },
            isValueHelp: true
        }],
        initialSortBy: [{ id: PrLifeInsuranceCompanyEntity.Name, sort: Sort.Desc }],
        columns: [
            PrLifeInsuranceCompanyEntity.Name,
            PrLifeInsuranceCompanyEntity.BankAccount,
            PrLifeInsuranceCompanyEntity.IsActive
        ],
        columnDefinition: {
            [PrLifeInsuranceCompanyEntity.Name]: {},
            [PrLifeInsuranceCompanyEntity.BankAccount]: {
                additionalProperties: insuranceCompanyAccountAdditionalFields,
                formatter: generalBankAccountFormatter
            },
            ...getInsuranceCompaniesCommonColumnDef()
        },
    };

    const summary: ISummaryItem[] = [
        { id: PrLifeInsuranceCompanyEntity.Name }
    ];

    const form: IFormDef = {
        id: `${EntityTypeName.PrLifeInsuranceCompany}Form`,
        translationFiles: getDefinitions.translationFiles,
        isDeletable: true,
        formControl: LifeInsuranceCompanyFormView,
        summary,
        additionalProperties: [],
        fieldDefinition: {
            "Name": {
                ...getNameFieldDef(PrInsuranceCompanyTypeCode.LifeInsuranceCompany)
            },
            ...getInsuranceCompaniesCommonFieldDef()
        },
        groups: [
            {
                id: "main",
                rows: [
                    [{ id: PrLifeInsuranceCompanyEntity.Name }, { id: PrLifeInsuranceCompanyEntity.DataBoxId }, { id: PrLifeInsuranceCompanyEntity.IsActive }]
                ]
            },
            {
                id: "bankAccount",
                title: i18next.t("InsuranceCompanies:BankAccounts"),
                rows: [[
                    { id: SAVED_ACCOUNTS_PATH }
                ], [
                    ...paymentDetailFields
                ]],
            }
        ]
    };

    const def = {
        entitySet: EntitySetName.PrLifeInsuranceCompanies,
        table,
        form
    };

    addBankAccountTableDefs(def);

    return def;
};

getDefinitions.translationFiles = ["InsuranceCompanies", "Common"];
setDefByEntityType(EntityTypeName.PrLifeInsuranceCompany, getDefinitions);