import styled from "styled-components/macro";
import { darken, ellipsis } from "polished";
import { DefaultTheme } from "styled-components";

interface ITokenProps {
    selected: boolean;
    _color: string;
    isLabel: boolean;
}

const getColor = (props: ITokenProps & { theme: DefaultTheme }): string => {
    return props.theme[props._color as keyof DefaultTheme] || props._color;
};

const getBorderColor = (props: ITokenProps & { theme: DefaultTheme }): string => {
    if (props.isLabel) {
        return props.selected ? darken(0.1, getColor(props)) : getColor(props);
    }
    return props.selected ? props.theme.C_ACT_main : props.theme.C_FIELD_line;
};

export const StyledToken = styled.div<{
    selected: boolean;
    _color: string;
    isLabel: boolean;
    isSelectable: boolean;
    isRound: boolean;
}>`
    padding: 2px 5px;
    height: 22px;
    border-radius: ${props => props.isRound ? "11px" : props.theme.borderRadius};
    border: solid 1px ${props => getBorderColor(props)};
    background-color: ${props => props.selected ? props.theme.C_FIELD_line : "transparent"};
    color: ${props => props.theme.C_TEXT_primary};

    ${props => props._color && `
        background-color: ${props.selected ? darken(0.1, getColor(props)) : getColor(props)}
    `};
    width: fit-content;
    flex: 0 0 auto;
    user-select: none;
    ${ellipsis()};

    font-weight: ${props => props.isLabel ? "bold" : "normal"};
    text-transform: ${props => props.isLabel ? "uppercase" : null};
    font-size: ${props => props.isLabel ? "12px" : "14px"};

    &:hover {
        border-color: ${props => props.isSelectable ? props.theme.C_ACT_main : ""};
    }
`;

