import { ISelectItem } from "@components/inputs/select/Select.types";
import { isDefined } from "@utils/general";
import { logger } from "@utils/log";
import { compareString } from "@utils/string";
import i18next from "i18next";

import { TRecordAny } from "../global.types";
import memoize from "../utils/memoize";
import { ENUM_DISPLAY_NAME, ENUM_KEY_PROP_NAME } from "./BindingContext";
import { IFieldInfo } from "./FieldInfo.utils";
import * as generatedEnums from "./GeneratedEnums";
import { OData } from "./OData";

export const ENUM_TOML_PREFIX = "Enum-";

interface IBaseEnum {
    [ENUM_KEY_PROP_NAME]: string,
    [ENUM_DISPLAY_NAME]: string
}

export const isEnum = (fieldInfo: IFieldInfo): boolean => {
    return isDefined(fieldInfo.fieldSettings?.isEnum) ? fieldInfo.fieldSettings?.isEnum : fieldInfo.bindingContext.isEnum();
};

export const getEnumName = (fieldInfo: IFieldInfo, oData?: OData): string => {
    if (fieldInfo.bindingContext.isLocal()) {
        return fieldInfo.fieldSettings?.entitySet ? (oData?.metadata.getTypeForPath(fieldInfo.fieldSettings?.entitySet as string).getName() ?? null) : null;
    }
    return fieldInfo.bindingContext.getProperty()?.getType().getName() ?? oData?.metadata.getTypeForPath(fieldInfo.fieldSettings?.entitySet as string).getName();
};

export const getEnumNameSpaceName = (enumName: string): string => {
    return `${ENUM_TOML_PREFIX}${enumName}`;
};

export interface ILoadEnumsResult {
    // list of namespaces that have not yet been loaded
    notLoadedNamespaces: string[];
    // promise that resolves once all the namespaces have been loaded
    namespacesPromise: Promise<void[]>;
}

export function loadEnums(...enumNames: string[]): ILoadEnumsResult {
    const namespaces = enumNames.map(name => getEnumNameSpaceName(name));
    let namespacesPromise: Promise<void[]>;

    // hasLoadedNamespace doesn't have public API
    // @ts-ignore
    const notLoadedNamespaces = namespaces.filter(namespace => !i18next.hasLoadedNamespace([namespace]));

    if (notLoadedNamespaces.length) {
        namespacesPromise = Promise.all(notLoadedNamespaces.map(namespace => i18next.loadNamespaces([namespace])));
    }

    return {
        notLoadedNamespaces,
        namespacesPromise
    };
}

export function getEnumValues(enumName: string): string[] {
    const genEnumName = `${enumName}Code` as keyof (typeof generatedEnums);
    const enumValues = generatedEnums[genEnumName];
    return Object.values(enumValues);
}

function generateEnumEntities<T extends TRecordAny>(enumName: string, keyProp: keyof T, labelProp: keyof T): T[] {
    try {
        return getEnumValues(enumName).map(val => {
            return {
                [keyProp]: val,
                [labelProp]: getEnumDisplayValue(enumName, val)
            } as T;
        });
    } catch (e) {
        logger.error(`${enumName} is missing in GeneratedEnums.ts`);
        return [];
    }
}

const memoizedEnumDeps = (enumName: string) => [
    // hasLoadedNamespace doesn't have public API
    // @ts-ignore
    i18next.hasLoadedNamespace(getEnumNameSpaceName(enumName)), i18next.language
];

export const getEnumEntities = memoize((enumName: string): IBaseEnum[] => {
    return generateEnumEntities(enumName, ENUM_KEY_PROP_NAME, ENUM_DISPLAY_NAME);
}, memoizedEnumDeps);

export const getEnumSelectItems = memoize((enumName: string): ISelectItem<string>[] => {
    return generateEnumEntities<ISelectItem<string>>(enumName, "id", "label")
        .sort((a: ISelectItem, b: ISelectItem) => compareString(a.label, b.label))
        .map(item => ({
            ...item,
            additionalData: {
                [ENUM_KEY_PROP_NAME]: item.id,
                [ENUM_DISPLAY_NAME]: item.label
            }
        }));
}, memoizedEnumDeps);

// Returns enum translation using bindingContext or enumName
export const getEnumDisplayValue = memoize((enumName: string, value: string): string => {
    return i18next.t(value, { nsSeparator: false, ns: getEnumNameSpaceName(enumName) }).toString();
}, (enumName: string, value: string) => {
    return `${i18next.hasLoadedNamespace(getEnumNameSpaceName(enumName))}${i18next.language}${enumName}:${value}`;
});
