import { withConfirmationDialog } from "@components/dialog/withConfirmationDialog";
import ReceivedDocumentBaseFormView from "../ReceivedDocumentBaseFormView";

import { withMinorAssetPairing } from "../../asset/minorAsset/withMinorAssetPairing";
import { withPermissionContext } from "../../../contexts/permissionContext/withPermissionContext";
import { DocumentTypeCode } from "@odata/GeneratedEnums";
import { withTimeResolution } from "../extensions/timeResolution/withTimeResolution";
import { withDomManipulator } from "../../../contexts/domManipulator/withDomManipulator";
import { withAccruals } from "../extensions/accruals/withAccruals";
import { withPromisedComponent } from "@components/dialog/withPromisedComponent";


class OtherLiabilityFormView extends ReceivedDocumentBaseFormView {
    documentTypeCode = DocumentTypeCode.OtherLiability;
    documentTypePropName = "LiabilityType";
}

export default withPromisedComponent(withConfirmationDialog(withMinorAssetPairing(withTimeResolution(withAccruals({ isExpense: true })(withPermissionContext(withDomManipulator(OtherLiabilityFormView)))))));
