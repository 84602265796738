import styled from "styled-components/macro";
import { StyledField } from "../inputs/field/Field.styles";

export const TableSortingDialogContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 10px;
`;

export const TableSortingSortItem = styled.div`
    display: flex;
    align-items: flex-end;

    & > ${StyledField} {
        margin-right: 0;
    }

    & > button:first-child {
        bottom: 8px;
        margin-right: 5px;
    }

    & > button:last-child {
        bottom: 8px;
        margin-left: 5px;
    }
`;