import { withConfirmationDialog } from "@components/dialog/withConfirmationDialog";

import { withMinorAssetPairing } from "../../asset/minorAsset/withMinorAssetPairing";
import ReceivedDocumentBaseFormView from "../ReceivedDocumentBaseFormView";
import { withTimeResolution } from "../extensions/timeResolution/withTimeResolution";
import { withPermissionContext } from "../../../contexts/permissionContext/withPermissionContext";
import { DocumentTypeCode } from "@odata/GeneratedEnums";
import { withAccruals } from "../extensions/accruals/withAccruals";
import { withDomManipulator } from "../../../contexts/domManipulator/withDomManipulator";
import { withProforma } from "../extensions/proforma/withProforma";
import { withPromisedComponent } from "@components/dialog/withPromisedComponent";


class InvoicesReceivedFormView extends ReceivedDocumentBaseFormView {
    documentTypeCode = DocumentTypeCode.InvoiceReceived;
}

export default withPromisedComponent(withConfirmationDialog(withMinorAssetPairing(withTimeResolution(withAccruals({ isExpense: true })(withProforma({ isIssuedType: false })(withPermissionContext(withDomManipulator(InvoicesReceivedFormView))))))));
