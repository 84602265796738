import DashboardTileBackground from "@components/dashboard/DashboardTileBackground";
import { Value } from "@components/tiles/infoTile/InfoTile.styles";
import styled from "styled-components/macro";

export enum TitleSize {
    small = 1,
    medium = 2,
    large = 3,
}

export const StyledGroupedInfoTileBackground = styled(DashboardTileBackground)<{ $fontSize: TitleSize }>`
    position: relative;

    & ${Value} {
        white-space: nowrap;
        font-size: ${props => props.$fontSize === TitleSize.small ? "21px" : props.$fontSize === TitleSize.medium ? "24px" : "30px"};
    }
`;