import { IDashboardGroupDef, TDashboardTileInfo } from "@components/dashboard";
import { Separator } from "@components/separator/Separator";
import React from "react";

import { TRecordType } from "../../global.types";
import memoize from "../../utils/memoize";
import DashboardConfigCheckbox from "./DashboardConfigCheckbox";
import { IDashboardManager } from "./DashboardManager";
import { ConfigurationSectionHeaderStyled } from "./Home.style";


interface IProps {
    manager: IDashboardManager;
}

export default class DashboardCustomizationSection extends React.Component<IProps> {

    _unsubscribe: () => void;

    constructor(props: IProps) {
        super(props);

        this._unsubscribe = props.manager?.subscribe(this);
    }

    componentWillUnmount() {
        this._unsubscribe?.();
    }

    getItems = memoize((group: IDashboardGroupDef) => {
        const { definition: { tileDefinition }, availableTiles } = this.props.manager;

        return group.allTiles.reduce((ret, key) => {
            if (availableTiles.has(key)) {
                ret.push({
                    ...tileDefinition[key],
                    id: key as string
                });
            }
            return ret;
        }, [] as (TDashboardTileInfo & { id: string })[]);
    }, (group) => [group.id, this.props.manager.availableTiles]);

    handleChange = (id: string, val: boolean): void => {
        this.props.manager.toggleTile(id, val);
    };

    renderGroup(group: IDashboardGroupDef, showTitle: boolean): React.ReactElement {
        const { config } = this.props.manager;

        const visibleMap: TRecordType<boolean> = {};
        config.layout.forEach(item => {
            visibleMap[item.i] = true;
        });

        return (
                <React.Fragment key={group.id}>
                    <ConfigurationSectionHeaderStyled>
                        {showTitle && (
                                <Separator label={group.title}
                                           color={"C_ACT_thick_line"}/>
                        )}
                    </ConfigurationSectionHeaderStyled>
                    {this.getItems(group)
                            .filter(item => !item.cannotBeRemovedInSettings)
                            .map(item => (
                                    <DashboardConfigCheckbox key={item.id} {...item} isChecked={visibleMap[item.id]}
                                                             onChange={this.handleChange}/>
                            ))}
                </React.Fragment>
        );
    }

    render() {
        const { definition } = this.props.manager;
        const showTitle = definition.groups.length > 1;
        return definition.groups.map(group => this.renderGroup(group, showTitle));
    }
}
