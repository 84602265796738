import React from "react";
import { IFileMetadataEntity } from "@odata/GeneratedEntityTypes";

export enum TicketMessageType {
    Received = "Received",
    Sent = "Sent"
}

export interface ITicketMessage {
    icon?: React.ReactNode;
    created?: Date;
    createdBy?: string;
    createdById?: number;
    subject?: string;
    text: string;
    type: TicketMessageType;
    attachments?: IFileMetadataEntity[];
}
