import { IconButton } from "@components/button";
import styled, { css } from "styled-components/macro";

import BusyIndicator from "../../components/busyIndicator/BusyIndicator";
import { getFocusBorderElement, LayoutStackOrder, LocalStackOrder } from "../../global.style";
import { ShellBarPadding, ShellBarWidth } from "./Main.utils";

export const LeftPane = styled.div<{
    isDisabled: boolean;
    isFullscreenMode?: boolean;
}>`
    height: 100vh;
    display: ${props => props.isFullscreenMode ? "none" : "flex"};
    position: relative;

    filter: ${props => props.isDisabled ? "grayscale(1)" : ""};
    // filter creates stacking context
    //  => changes z-index stacking when creating new company
    //  => notifications are shown behind RightPane
    //  ==> apply z-index
    z-index: ${props => props.isDisabled ? LocalStackOrder.AboveContent : "unset"};
`;

export const StyledShell = styled.div<{
    isInactiveCompany: boolean;
}>`
    width: ${ShellBarWidth};
    height: 100dvh;
    background-image: ${props => props.isInactiveCompany ? props.theme.C_BG_shellbar_inactive_company : props.theme.C_BG_shellbar};
    overflow: auto;
    padding-top: ${ShellBarPadding}px;
    padding-bottom: ${ShellBarPadding}px;
    flex-shrink: 1;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    
    box-sizing: border-box;
    z-index: ${LayoutStackOrder.ShellBar};
`;

export const ShellIconsGroup = styled.div`
    display: flex;
    flex-direction: column;
`;

export const MenuSeparator = styled.div<{
    isHidden?: boolean;
}>`
    visibility: ${props => !props.isHidden ? "visible" : "hidden"};
    width: 28px;
    height: 0;
    margin: 12px auto 13px;
    opacity: 0.25;
    border: solid 1px #ffffff;
`;

export const MenuIconWrapper = styled(IconButton)<{
    $isSelected?: boolean;
    $isFullCircle?: boolean;
    isHidden?: boolean;
    $preventHover?: boolean;
}>`
    visibility: ${props => !props.isHidden ? "visible" : "hidden"};
    margin: 3px auto 4px;
    position: relative;
    background-color: ${props => props.$isSelected ? "rgba(0,0,0,0.07) !important" : ""};
    height: 50px;
    width: 50px;
    max-width: 50px;

    display: flex;
    align-items: center;
    justify-content: center;

    ${props => props.$preventHover && css`
        pointer-events: none;
    `};

    ${props => !props.$isFullCircle && css`
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;

        &:after {
            content: "";
            display: ${props.$isSelected ? "block" : "none"};
            background-color: rgba(0, 0, 0, 0.07);
            position: absolute;
            left: 100%;
            top: 0;
            height: 100%;
            width: 7px;
            z-index: ${LocalStackOrder.BackgroundLayer};
        }
    `};

    ${props => getFocusBorderElement({ offset: 0, keyboardOnly: true, borderRadius: "50%", isLight: props.isLight })}
`;

export const StyledShellMenuIconTemplate = styled.div`
    position: relative;

    &:first-child > ${MenuIconWrapper} {
        // height is 50, however icon size is just 32 -> we need to align top of the icon with padding line
        margin-top: -9px;
    }

    &:last-child > ${MenuIconWrapper} {
        // @see: &:first-child/margin-top comment
        margin-bottom: -9px;
    }

`;

export const RightPane = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    overflow: hidden;
`;

export const Content = styled.div <{
    _isInactiveCompany: boolean;
}>`
    height: 100%;
    background-color: ${props => props._isInactiveCompany ? props.theme.C_BG_floorplan_inactive_company : props.theme.C_BG_floorplan};
`;

export const FullPageBusyIndicator = styled(BusyIndicator)`
    left: ${ShellBarWidth};
    width: calc(100% - ${ShellBarWidth});
`;
