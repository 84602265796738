import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Dialog from "../dialog";
import { Button, ButtonGroup } from "../button";
import { Description, Header } from "./MobileWarningDialog.styles";
import { getCurrentDeviceType } from "../../login/state/deviceSlice";
import { DeviceType } from "../../contexts/authContext/Auth.utils";

interface IProps {

}

const MobileWarningDialog: React.FC<IProps> = () => {
    const { t } = useTranslation("Components");
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const handleClose = useCallback(() => {
        setIsVisible(false);
    }, []);

    useEffect(() => {
        if (getCurrentDeviceType() === DeviceType.Mobile) {
            setIsVisible(true);
        }
    }, []);

    if (!isVisible) {
        return null;
    }

    return (
        <Dialog onClose={handleClose}
                onConfirm={handleClose}
                isConfirmation
                ignoreShellPadding
                footerJustify={"center"}
                footer={<ButtonGroup>
                    <Button onClick={handleClose}>
                        {t("Components:MobileWarningDialog.Confirm")}
                    </Button>
                </ButtonGroup>}>
            <Header>
                {t("Components:MobileWarningDialog.Header")}
            </Header>
            <Description>
                {t("Components:MobileWarningDialog.Description")}
            </Description>
        </Dialog>
    );
};

export default MobileWarningDialog;