import Dialog from "@components/dialog";
import Field from "@components/inputs/field";
import FieldsWrapper from "@components/inputs/field/FieldsWrapper";
import Switch from "@components/inputs/switch";
import { SwitchType } from "@components/inputs/switch/Switch";
import { exportAssetCard } from "@pages/asset/fixedAsset/FixedAsset.utils";
import { isCashBasisAccountingCompany } from "@utils/CompanyUtils";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import { AppContext, IAppContext } from "../../../contexts/appContext/AppContext.types";
import { LabelStatus } from "../../../enums";
import ConfirmationButtons from "../../../views/table/ConfirmationButtons";

interface IProps {
    assetIds: string[];
    onConfirm: () => void;
    onClose: () => void;
    isMassAction?: boolean;
}

interface IState {
    includeFutureTax: boolean;
    includeFutureAccounting: boolean;
    isBusy: boolean;
}

class FixedAssetMassPdfExportDialog extends React.PureComponent<IProps & WithTranslation, IState> {
    static contextType = AppContext;

    state: IState = {
        includeFutureTax: true,
        includeFutureAccounting: false,
        isBusy: false
    };

    handleConfirm = async (): Promise<void> => {
        this.setState({
            isBusy: true
        });

        await exportAssetCard(this.props.assetIds, this.context, this.state.includeFutureTax, this.state.includeFutureAccounting);

        this.setState({
            isBusy: false
        });

        this.props.onConfirm();
    };

    handleCancel = (): void => {
        this.props.onClose();
    };

    render() {
        const context = this.context as IAppContext;

        return (
            <Dialog onConfirm={this.handleConfirm}
                    onClose={this.handleCancel}
                    busy={this.state.isBusy}
                    title={this.props.t(`FixedAsset:MassExportPdf.${this.props.isMassAction ? "Title" : "TitleSingle"}`)}
                    footer={<ConfirmationButtons onConfirm={this.handleConfirm}
                                                 onCancel={this.handleCancel}
                                                 confirmText={this.props.t("Common:General.Confirm")}/>}
            >
                <FieldsWrapper isColumn>
                    <Field labelStatus={LabelStatus.Removed}>
                        <Switch label={this.props.t("FixedAsset:MassExportPdf.IncludeFutureTaxDepreciations")}
                                checked={this.state.includeFutureTax}
                                onChange={(checked) => this.setState({ includeFutureTax: checked })}
                                type={SwitchType.YesNo}/>
                    </Field>
                    {!isCashBasisAccountingCompany(context) &&
                        <Field labelStatus={LabelStatus.Removed}>
                            <Switch
                                label={this.props.t("FixedAsset:MassExportPdf.IncludeFutureAccountingDepreciations")}
                                checked={this.state.includeFutureAccounting}
                                onChange={(checked) => this.setState({ includeFutureAccounting: checked })}
                                type={SwitchType.YesNo}/>
                        </Field>
                    }
                </FieldsWrapper>
            </Dialog>
        );
    }
}

export default withTranslation(["FixedAsset"])(FixedAssetMassPdfExportDialog);