import { StyledFormGroup } from "@components/formGroup";
import { StyledFieldContent } from "@components/inputs/field/Field.styles";
import { StyledSwitch } from "@components/inputs/switch/Switch.styles";
import styled from "styled-components/macro";

import { T_TITLE_2 } from "../../global.style";
import { SmartHeaderStyled } from "../../views/formView/FormView.styles";
import { StyledViewWrapper } from "../../views/View.styles";

export const StepSubtitle = styled.div`
    ${T_TITLE_2};
    margin-bottom: 38px;
`;

export const HeaderStyled = styled(SmartHeaderStyled)`
    padding-bottom: 28px;
`;

export const NewCustomerFormStyled = styled.div`
    ${StyledFormGroup}:has(${StyledSwitch}) {
        margin-bottom: 13px;
    }
    
    ${StyledFieldContent}:has(${StyledSwitch}) { 
        padding-left: 0;
    }
`;

export const FooterWrapper = styled.div`
    padding: 0 38px 38px 38px;
`;

export const StyledScrollBarWrapper = styled.div`
    height: calc(100% - 100px);
    padding-top: 38px;
    ${StyledViewWrapper} {
        padding-top: 0;
    }
`;