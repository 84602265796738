import React from "react";
import Dashboard from "../../components/dashboard";
import { HomeDashboardHeaderStyled } from "./Home.style";
import { IDashboardManager } from "./DashboardManager";
import { Separator } from "@components/separator/Separator";

interface IProps {
    manager: IDashboardManager;
    inEditMode: boolean;
    showHeader?: boolean;
}

export default class HomeDashboard extends React.Component<IProps> {

    _unsubscribe: () => void;

    constructor(props: IProps) {
        super(props);

        this._unsubscribe = props.manager?.subscribe(this);
    }

    componentWillUnmount() {
        this._unsubscribe?.();
    }

    render() {
        const { definition, config, updateLayout } = this.props.manager;

        return (
            <>
                {this.props.showHeader && (
                    <HomeDashboardHeaderStyled>
                        {definition.title}
                        <Separator isBold/>
                    </HomeDashboardHeaderStyled>
                )}
                <Dashboard definition={definition}
                           config={config}
                           onLayoutConfigChange={updateLayout}
                           inEditMode={this.props.inEditMode}/>
            </>
        );
    }
}
