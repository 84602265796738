import { withTranslation, WithTranslation } from "react-i18next";
import React from "react";
import { ExpandIcon, Slider } from "./Resizer.styles";
import { IconButton } from "../button";
import { IconSize } from "../../enums";
import animationFrameThrottle from "../../utils/animationFrameThrottle";
import { IWithResizerSettings, WithResizer } from "./withResizer";
import { clamp } from "@utils/general";

const COLLAPSED_WIDTH = 20;
const MIN_WIDTH = 200;
const MAX_WIDTH = 950;

interface IProps extends WithTranslation, IWithResizerSettings {
    forceUnCollapsed?: boolean;
    onResize?: (width: number, isCollapsed: boolean) => void;
    children: (props: WithResizer) => React.ReactElement;
}

interface IState {
    isCollapsed: boolean;
    isHovered: boolean;
    width?: number;
    isResizing?: boolean;
}

class Resizer extends React.Component<IProps, IState> {

    refContent = React.createRef<HTMLDivElement>();

    _mouseOffset: number;
    _hoverTimer: number;

    get isCollapsed(): boolean {
        return this.state.isCollapsed && !this.state.isHovered && !this.props.forceUnCollapsed;
    }

    get width(): number {
        return this.isCollapsed ? COLLAPSED_WIDTH : this.state.width;
    }

    get maxWidth(): number {
        return this.props.maxWidth ?? MAX_WIDTH;
    }

    get minWidth(): number {
        return this.props.minWidth ?? MIN_WIDTH;
    }

    constructor(props: IProps) {
        super(props);

        this.state = {
            isCollapsed: this.props.initialCollapsed,
            isHovered: false,
            width: this.props.initialWidth
        };
    }

    componentDidMount() {
        if (!this.state.width) {
            this.setState({
                width: clamp(this.refContent.current.offsetWidth, this.minWidth, this.maxWidth)
            });
        }
    }

    handleMouseMoveThrottled = animationFrameThrottle((e: MouseEvent): void => {
        let newWidth = e.clientX - this._mouseOffset - this.refContent.current.getBoundingClientRect().x;
        newWidth = clamp(newWidth, COLLAPSED_WIDTH, this.maxWidth);

        if (newWidth === this.state.width) {
            return;
        }

        this.setState({ width: newWidth });
    });

    handleMouseMove = (e: MouseEvent): void => {
        // preventDefault has to be called always, to prevent text selection
        e.preventDefault();
        this.handleMouseMoveThrottled(e);
    };

    handleMouseDown = (e: React.MouseEvent) => {
        if (e.button !== 0) {
            return;
        }

        const rect = this.refContent.current.getBoundingClientRect();
        this._mouseOffset = e.clientX - rect.right;
        this.refContent.current.style.transition = "none";

        let newWidth = e.clientX - this._mouseOffset - rect.x;
        newWidth = clamp(newWidth, COLLAPSED_WIDTH, this.maxWidth);

        document.addEventListener("mousemove", this.handleMouseMove);
        document.addEventListener("mouseup", this.handleMouseUp);

        this.setState({
            isCollapsed: false,
            width: newWidth,
            isResizing: true
        });
    };

    handleMouseUp = (e: Event) => {
        this.refContent.current.style.transition = "";
        document.removeEventListener("mousemove", this.handleMouseMove);
        document.removeEventListener("mouseup", this.handleMouseUp);

        clearTimeout(this._hoverTimer);

        this.setState(state => {
            const isCollapsed = state.width < (this.props.collapseThreshold ?? this.minWidth);
            const width = Math.max(state.width, this.minWidth);

            this.props.onResize?.(width, isCollapsed);
            return {
                isCollapsed, width,
                isResizing: false,
                isHovered: false
            };
        });
    };

    handleExpandClick = (e: React.MouseEvent) => {
        this.setState((state: IState) => {
            const isCollapsed = !state.isCollapsed;

            this.props.onResize?.(state.width, isCollapsed);
            return {
                isCollapsed,
                isHovered: false
            };
        });
    };

    handleMouseOverMenu = () => {
        if (this.state.isCollapsed) {
            clearTimeout(this._hoverTimer);

            this._hoverTimer = window.setTimeout(() => {
                this.setState({
                    isHovered: true
                });
            }, 400);
        }
    };

    handleMouseOut = () => {
        if (this.state.isCollapsed) {
            clearTimeout(this._hoverTimer);

            if (this.state.isHovered) {
                this.setState({
                    isHovered: false
                });
            }
        }
    };

    stopPropagation = (e: React.MouseEvent) => {
        e.stopPropagation();
    };

    renderSlider() {
        return !this.props.forceUnCollapsed && (
            <Slider collapsed={this.state.isCollapsed}
                    $borderColor={this.props.borderColor}
                    $isResizing={this.state.isResizing}
                    onMouseDown={this.handleMouseDown}>
                <IconButton
                    isTransparent
                    title={this.props.t("Common:General.Expand")}
                    onMouseOver={this.stopPropagation}
                    onMouseDown={this.stopPropagation}
                    onClick={this.handleExpandClick}>
                    <ExpandIcon isRotated={this.state.isCollapsed}
                                width={IconSize.XS}
                                height={IconSize.XS}/>
                </IconButton>
            </Slider>);
    }

    render() {
        const props: WithResizer = {
            width: this.width,
            isCollapsed: this.state.isCollapsed,
            resizeRef: this.refContent,
            onMouseOver: this.handleMouseOverMenu,
            onMouseLeave: this.handleMouseOut,
            resizeHandle: this.renderSlider()
        };

        return this.props.children(props);
    }
}

export default withTranslation(["Common"])(Resizer);