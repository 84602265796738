import { FormStorage } from "../../views/formView/FormStorage";
import React, { ComponentType } from "react";
import Dialog from "../dialog/Dialog";
import DialogPage from "../../pages/DialogPage";
import { PageViewMode } from "../../enums";
import { IGetDefinition } from "@pages/PageUtils";
import { ISelectionTableViewProps } from "./SelectionTableViewBase";

interface IProps extends Pick<ISelectionTableViewProps, "selectedItems" | "headerData" | "title" | "onClose" | "onSelection" | "isSingleSelect" | "isRowWithoutAction"> {
    storage: FormStorage;

    getDefinition: IGetDefinition;
    tableView: ComponentType<ISelectionTableViewProps>;
}

const SelectionTableViewDialog: React.FC<IProps> = (props) => {
    const {
        storage,
        tableView,
        selectedItems,
        getDefinition,
        title,
        headerData,
        onClose,
        onSelection,
        isSingleSelect,
        isRowWithoutAction
    } = props;
    return (
        <Dialog isEditableWindow
                onConfirm={null}
                onClose={onClose}>

            <DialogPage
                    pageViewMode={PageViewMode.FormReadOnly}
                    rootStorage={storage}
                    tableView={tableView}
                    tableViewProps={{
                        onClose,
                        onSelection,
                    title,
                    selectedItems,
                    headerData,
                    isSingleSelect,
                    isRowWithoutAction
                }}
                getDef={getDefinition}
            />
        </Dialog>
    );
};

export default SelectionTableViewDialog;
