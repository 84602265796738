import React from "react";
import { StyledTooltip } from "./Tooltip.style";
import {
    composeRefHandlers,
    doesElementContainsElement,
    getValue,
    handleRefHandlers,
    isOverflowing
} from "@utils/general";
import TestIds from "../../testIds";
import PopperWrapper from "../popperWrapper";

interface IProps {
    /** Content of the opened tooltip.
     * Can receive content as callback - performance optimization. We don't need to render tooltips that are not opened. */
    content: React.ReactNode | (() => React.ReactNode);
    /** Tooltip is only shown when mouse hovers over the given reference element */
    children?: (ref: React.Ref<any>, overflowRef?: React.Ref<any>) => React.ReactElement;
    /** Only show tooltip if  children content is overflowing.
     * Tooltip takes care of the check for the wrapped components. */
    onlyShowWhenChildrenOverflowing?: boolean;
    ignoreHeightOverflow?: boolean;
    passRef?: React.RefObject<HTMLDivElement>;
    isHidden?: boolean;
    noBackground?: boolean;
    delay?: number;
}

export type { IProps as ITooltipProps };

interface IState {
    showTooltip: boolean;
    mouseX: number;
    mouseY: number;
}

const X_DISTANCE_FROM_MOUSE = 20;
const Y_DISTANCE_FROM_MOUSE = 10;

export default class Tooltip extends React.PureComponent<IProps, IState> {
    state: IState = {
        showTooltip: false,
        mouseX: 0,
        mouseY: 0
    };

    showTooltipTimer: ReturnType<typeof setTimeout>;

    static defaultProps: Partial<IProps> = {
        delay: 650
    };

    _referenceRef = React.createRef<HTMLElement>();
    _overflowRef = React.createRef<HTMLElement>();
    _tooltipRef = React.createRef<HTMLDivElement>();
    _handlersRegistered = false;

    componentDidMount(): void {
        this.registerHandlers();
    }

    componentWillUnmount(): void {
        if (this._handlersRegistered && this._referenceRef.current) {
            this.unregisterHandlers();
            document.removeEventListener("mousemove", this.handleElementMouseMove);
        }
        this.cancelShowTooltip();
    }

    componentDidUpdate(prevProps: IProps, prevState: IState) {
        if (!prevState.showTooltip && this.state.showTooltip) {
            this._tooltipRef.current.addEventListener("mouseleave", this.handleElementMouseLeave);
        }

        if (!prevProps.isHidden && this.props.isHidden && this.state.showTooltip) {
            this.hideTooltip();
        }

        this.registerHandlers();
    }

    registerHandlers = (): void => {
        if (!this._handlersRegistered && this._referenceRef.current) {
            this._referenceRef.current.addEventListener("mouseenter", this.handleElementMouseEnter);
            this._referenceRef.current.addEventListener("mouseleave", this.handleElementMouseLeave);
            this._handlersRegistered = true;
        }
    };

    unregisterHandlers = (): void => {
        if (this._handlersRegistered) {
            this._handlersRegistered = false;
            this._referenceRef.current?.removeEventListener("mouseenter", this.handleElementMouseEnter);
            this._referenceRef.current?.removeEventListener("mouseleave", this.handleElementMouseLeave);
        }
    };

    isMouseOver = (event: MouseEvent): boolean => {
        const target = event.relatedTarget as Element;
        return doesElementContainsElement(this._referenceRef.current, target)
            || doesElementContainsElement(this._tooltipRef.current, target);
    };

    shouldShowTooltip = (): boolean => {
        if (this.props.isHidden) {
            return false;
        }

        if (!this.props.onlyShowWhenChildrenOverflowing) {
            return true;
        }

        const ref = this._overflowRef.current ?? this._referenceRef.current;
        return isOverflowing(ref) || (!this.props.ignoreHeightOverflow && ref.scrollHeight > ref.clientHeight);
    };

    handleElementMouseEnter = (event: MouseEvent): void => {
        this.setState({
            mouseX: event.x,
            mouseY: event.y
        });

        this.showTooltip();
        document.addEventListener("mousemove", this.handleElementMouseMove);
    };

    cancelShowTooltip = (): void => {
        if (this.showTooltipTimer) {
            clearTimeout(this.showTooltipTimer);
            this.showTooltipTimer = null;
        }
    };

    showTooltipSync = (): void => {
        if (this.props.content && this.shouldShowTooltip()) {
            this.showTooltipTimer = null;
            this.setState({
                showTooltip: true
            });
        }
    };

    showTooltip = (): void => {
        this.cancelShowTooltip();

        if (this.props.delay) {
            this.showTooltipTimer = setTimeout(this.showTooltipSync, this.props.delay);
        } else {
            this.showTooltipSync();
        }
    };

    hideTooltip = (): void => {
        this._tooltipRef.current.removeEventListener("mouseleave", this.handleElementMouseLeave);
        this.setState({
            showTooltip: false
        });
    };

    handleElementMouseLeave = (event: MouseEvent): void => {
        if (!this.isMouseOver(event)) {
            if (this.state.showTooltip) {
                this.hideTooltip();
            }

            document.removeEventListener("mousemove", this.handleElementMouseMove);
            this.cancelShowTooltip();
        }
    };

    handleElementMouseMove = (event: MouseEvent): void => {
        this.setState({
            mouseX: event.x,
            mouseY: event.y,
            showTooltip: false
        });

        this.showTooltip();
    };

    handleContentRef = (ref: HTMLDivElement): void => {
        this.unregisterHandlers();
        handleRefHandlers(ref, this._referenceRef);
        this.registerHandlers();
    };

    handleAnimationEnd = (e: React.AnimationEvent): void => {
        // stop propagation here so that this event doesn't affect tooltip wrappers that handles their own animation end
        // e.g. when tooltip used inside alert with fade, which would results in premature closing of the alert when tooltip is shown
        e.stopPropagation();
    };

    getVirtualElementReference = () => {
        // position the popper to mouse instead of reference element
        // https://popper.js.org/docs/v2/virtual-elements/
        return {
            getBoundingClientRect: () => {
                const x = this.state.mouseX;
                const y = this.state.mouseY;

                return {
                    width: 0,
                    height: 0,
                    top: y,
                    left: x,
                    right: 0,
                    bottom: 0,
                    x: 0,
                    y: 0,
                    toJSON: () => {
                    }
                };
            }
        };
    };

    render = (): React.ReactElement => {
        const value = getValue(this.props.content);

        // use portal to body to get out of nested overflow hidden elements
        return (
            <>
                {this.props.children?.(this.handleContentRef, this._overflowRef)}
                {
                    <PopperWrapper placement={"right"}
                                   modifiers={[
                                       {
                                           name: "preventOverflow",
                                           options: {
                                               rootBoundary: "viewport",
                                               padding: 19,
                                               mainAxis: true,
                                               altAxis: true
                                           }
                                       },
                                       {
                                           name: "offset",
                                           options: {
                                               offset: [X_DISTANCE_FROM_MOUSE, Y_DISTANCE_FROM_MOUSE]
                                           }
                                       }
                                   ]}
                                   virtualElementReference={this.getVirtualElementReference()}
                                   withoutPortal={false}
                                   isHidden={!this.state.showTooltip}
                    >
                        <>
                            {(!this.props.children || this.state.showTooltip) &&
                                <StyledTooltip ref={composeRefHandlers(this._tooltipRef, this.props.passRef)}
                                               onAnimationEnd={this.handleAnimationEnd}
                                               noBackground={this.props.noBackground}
                                               data-testid={TestIds.Tooltip}>
                                    {typeof value === "string"
                                        ? (<span>{value}</span>)
                                        : value
                                    }
                                </StyledTooltip>
                            }
                        </>
                    </PopperWrapper>
                }
            </>
        );
    };
}