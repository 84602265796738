import React from "react";
import { BadgeStyled } from "./Badge.styles";
import { getBadgeCount } from "./Badge.utils";
import TestIds from "../../testIds";

interface IProps {
    offset?: number;
    offsetRight?: number;
    offsetTop?: number;
    className?: string;
    count?: number;
    max?: number;
    ignoreTheme?: boolean;
    isSmall?: boolean;
}

const Badge: React.FC<IProps> = ({ offset, offsetTop, offsetRight, className, count, max, ignoreTheme, isSmall }) => {
    if (!count || count <= 0) {
        return null;
    }

    return (
            <BadgeStyled $offset={offset}
                         $offsetTop={offsetTop}
                         $offsetRight={offsetRight}
                         $ignoreTheme={ignoreTheme}
                         $small={isSmall}
                         className={className}
                     data-testid={TestIds.Badge}>
            {getBadgeCount(count, max)}
        </BadgeStyled>
    );
};

export default Badge;