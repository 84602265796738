import styled from "styled-components/macro";

import { BasicSelect } from "./BasicSelect";

const MIN_INPUT_WIDTH = 40;

export const BasicSelectStyled = styled(BasicSelect)<{
    $minWidth?: number;
}>`
    input {
        flex: ${props => `1 0 ${props.$minWidth ?? MIN_INPUT_WIDTH}px`};
    }
`;