import styled from "styled-components/macro";
import { DIALOG_PADDING } from "./Dialog.styles";

export const Wrapper = styled.div`
    height: 100%;
    display: flex;
`;

export const LeftContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    flex: 1 1 auto;
    padding: ${DIALOG_PADDING};
`;


export const RightFormWrapper = styled.div<{ _width: string }>`
    padding: ${DIALOG_PADDING};
    width: ${props => props._width};
    overflow: auto;
`;