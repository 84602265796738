import Dialog from "@components/dialog/Dialog";
import React, { useCallback, useRef, useState } from "react";

import { PageViewMode } from "../../../../enums";
import { FormStorage } from "../../../../views/formView/FormStorage";
import DialogPage from "../../../DialogPage";
import PairingProformaTableView from "./PairingProformaTableView";
import { DEDUCT_DEPOSIT_ACTION } from "./Proforma.utils";
import { getDefinitions } from "./ProformaDef";

export interface WithProforma {
    onCustomHeaderAction?: (actionId: string, storage: FormStorage) => Promise<boolean>;
}

interface WithProformaProps {
    onCustomHeaderAction?: (actionId: string, storage: FormStorage) => Promise<boolean>;
}

type TWithProforma<P> = Omit<P, keyof WithProforma> & WithProformaProps;


export interface WithProformaSettings {
    isIssuedType: boolean;
}

export const withProforma = (settings: WithProformaSettings) => (
    <P extends WithProforma>(Component: React.ComponentType<P>): React.ComponentType<TWithProforma<P>> => {
        let promiseResolve: (value: boolean) => void;

        return React.forwardRef((props: TWithProforma<P>, ref) => {
            const { onCustomHeaderAction } = props;

            const [isOpen, setOpen] = useState<boolean>(false);
            const formStorage = useRef<FormStorage>(null);

            const handleCustomHeaderAction = useCallback(async (actionId: string, storage: FormStorage) => {
                formStorage.current = storage;

                if (DEDUCT_DEPOSIT_ACTION === actionId) {
                    setOpen(true);
                    return new Promise(function (resolve) {
                        promiseResolve = resolve;
                    });
                } else {
                    return onCustomHeaderAction?.(actionId, storage);
                }
            }, [onCustomHeaderAction]);

            const handleClose = useCallback(() => {
                promiseResolve(false);
                setOpen(false);
            }, []);

            const handleConfirm = () => {
                promiseResolve(true);
            };

            return (
                <>
                    <Component {...props as P}
                               onCustomHeaderAction={handleCustomHeaderAction}
                               ref={ref}/>
                    {isOpen &&
                        <Dialog isEditableWindow={true}
                                onConfirm={null}
                                onClose={handleClose}>
                            <DialogPage
                                pageViewMode={PageViewMode.FormReadOnly}
                                rootStorage={formStorage.current}
                                tableView={PairingProformaTableView}
                                tableViewProps={{
                                    onConfirm: handleConfirm,
                                    onClose: handleClose,
                                    isSingleSelect: false
                                }}
                                getDef={getDefinitions.bind(null, formStorage.current?.context, settings.isIssuedType)}
                            />
                        </Dialog>
                    }
                </>
            );
        }) as any;
    }
);