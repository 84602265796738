import React, { useEffect, useMemo, useRef } from "react";
import { StyledSvgImage } from "./SvgImage.styles";
import TestIds from "../../testIds";
import { FILES_API_URL } from "../../constants";

interface IProps {
    src?: string;
    title?: string;
    alt?: string;
    className?: string;
    style?: React.CSSProperties;
}

/** Better than <img> tag for svg usage - places the svg inline, so that its styles can be extended*/
export const SvgImage = ({ src, title, alt = "", className, style }: IProps) => {
    const _imageRef = useRef<HTMLDivElement>();
    const isSafeSrc = useMemo(() => {
        // allow imports from our static folder
        const isStaticFolder = /^\/?static/.test(src);
        const isFileStoragePath = src.startsWith(FILES_API_URL);
        // allow system files (with negative id number) from file storage
        let isSystemFilePath = false;

        if (isFileStoragePath) {
            const fileId = parseInt(src.slice(`${FILES_API_URL}/`.length));

            isSystemFilePath = typeof (fileId) === "number" && fileId < 0;
        }

        return isStaticFolder || isSystemFilePath;
    }, [src]);

    useEffect(() => {
        if (!isSafeSrc) {
            return;
        }

        const isValidSvg = (text: string): boolean => {
            return text.includes("<svg");
        };

        if (_imageRef.current) {
            _imageRef.current.innerText = alt;
        }

        // force-cache seems to actually work and take the svgs from browser cache
        fetch(src, { cache: "force-cache" })
            .then(response => response.status < 300 ? response.text() : null)
            .then(svg => {
                if (svg && _imageRef.current && isValidSvg(svg)) {
                    // don't use this component for xss vulnerable data
                    _imageRef.current.innerHTML = svg;
                }
            });
    }, [src, alt]);

    return isSafeSrc ? (
        <StyledSvgImage ref={_imageRef}
                        data-testid={TestIds.SvgImage}
                        title={title}
                        className={className}
                        style={style}>
            {alt}
        </StyledSvgImage>
    ) : (
        <img src={src}
             data-testid={TestIds.SvgImage}
             alt={alt}
             title={title}
             className={className}
             style={style}/>
    );
};