import React, { useCallback, useEffect } from "react";
import { BasicLoginTextBlock, LoginTitle } from "./LoginPage.styles";
import { AnimationType, LoginTranslationNamespaces, QueueStates } from "./Login.utils";
import TestIds from "../testIds";
import { useTranslation } from "react-i18next";
import { BackArrow } from "./components/BackArrow";
import { loadSession, selectSessionData } from "./state/sessionSlice";
import { useLoginDispatch, useLoginSelector } from "./state/hooks";
import Redirects from "./components/Redirects";
import { resendRegisterEmail, setAnimation } from "./state/loginSlice";
import { ROUTE_LOGOUT } from "../routes";
import LoginButtonGroup from "./components/LoginButtonGroup";
import ResendLink from "./components/ResendLink";
import { BePollingInterval } from "../contexts/appContext/AppContext.utils";

interface IProps {
}

export const EmailConfirm: React.FC<IProps> = () => {

    const { t } = useTranslation([...LoginTranslationNamespaces]);

    const dispatch = useLoginDispatch();
    const data = useLoginSelector(selectSessionData);

    const handleResendEmail = useCallback(async () => {
        dispatch(resendRegisterEmail());
    }, [dispatch]);

    useEffect(() => {
        dispatch(setAnimation({ type: AnimationType.Queue, step: QueueStates.FirstPerson }));
    }, [dispatch]);

    // poll BE so that we can automatically redirect the page after user has confirmed email
    useEffect(() => {
        const int = setInterval(() => {
            dispatch(loadSession());
        }, BePollingInterval);

        return () => clearInterval(int);
    }, [dispatch]);

    return (<>
        <Redirects/>
        <LoginTitle data-testid={TestIds.Title}>
            <BackArrow url={ROUTE_LOGOUT}/>
            {t("Login:PassReset.IdentityConfirmation")}
        </LoginTitle>

        <BasicLoginTextBlock
            data-testid={TestIds.Text}>{t("Login:PassReset.RegConfirmText", { email: data?.LoginEmail ?? "" })}</BasicLoginTextBlock>

        <LoginButtonGroup>
            <ResendLink text={t("Login:Common.ResendTextConfirm")} onResend={handleResendEmail}/>
        </LoginButtonGroup>
    </>);
};

export default EmailConfirm;