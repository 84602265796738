import styled from "styled-components/macro";
import { T_PLAIN_big, T_TITLE_2 } from "../../global.style";
import { TooltipIconInfo } from "../../components/tooltipIcon";

export const DialogHeader = styled.h1`
    ${T_TITLE_2};
    margin-bottom: 32px;
`;

export const TextStyled = styled.span`
    ${T_PLAIN_big};
    margin: 0 12px;
`;

export const InfoIconStyled = styled(TooltipIconInfo)`
    margin-left: 5px;
    margin-bottom: 3px;
`;