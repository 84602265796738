import { ISelectItem } from "@components/inputs/select/Select.types";
import {
    BillingAddressEntity,
    EntitySetName,
    IBillingPricingEntity,
    ICompanyEntity,
    IModuleEntity,
    ISubscriptionEntity,
    SubscriptionEntity,
    TenantEntity
} from "@odata/GeneratedEntityTypes";
import {
    BillingModuleTypeCode,
    CompanyStateCode,
    PurchaseStatusCode,
    SubscriptionStatusCode,
    SubscriptionTypeCode
} from "@odata/GeneratedEnums";
import { OData } from "@odata/OData";
import customFetch, { getDefaultPostParams } from "@utils/customFetch";
import { roundToDecimalPlaces } from "@utils/general";

import { REST_API_URL } from "../../../constants";
import { IAppContext } from "../../../contexts/appContext/AppContext.types";
import { Model } from "../../../model/Model";
import otherSvgPath from "../../../svg/cardProviders/creditCard.svg";
import masterCardSvgPath from "../../../svg/cardProviders/mastercard.svg";
import visaSvgPath from "../../../svg/cardProviders/visa.svg";
import { getUtcDayjs } from "../../../types/Date";
import { MainLocalSettings, VariantId } from "../../../views/main/Main.utils";

export const GO_PAY_URL = `${REST_API_URL}/GoPay`;
export const GET_CARD_URL = `${GO_PAY_URL}/GetCreditCard`;
export const REMOVE_CARD_URL = `${GO_PAY_URL}/RemoveCreditCard`;
export const ADD_CARD_URL = `${GO_PAY_URL}/AddCreditCard`;
export const CREATE_MISSING_PAYMENT_URL = `${GO_PAY_URL}/CreateMissingPayment`;
export const SUBSCRIPTION_MODULES_URL = `${REST_API_URL}/SubscriptionModules`;
export const TENANT_LIFECYCLE_URL = `${REST_API_URL}/TenantLifeCycle`;
export const GET_NEXT_INVOICE_URL = `${REST_API_URL}/Subscription/GetNextInvoice`;

export interface ICreditCard {
    Number: string;
    Brand: string;
    Email: string;
    Expiration: string;
    FirstName: string;
    LastName: string;
}

export interface IPaymentState {
    State: string;
    Url: string;
    CreditCard: ICreditCard;

}

export interface IModuleInfo {
    Module: IModuleEntity;
    BillingPricing: IBillingPricingEntity;
    IsActive: boolean;
    StorageCapacity: number;
    StorageCapacityIncrease: number;
    StorageUsage: number;
}

export const contactFormatter = (firstName: string, lastName: string, email: string) => {
    return `${firstName} ${lastName} (${email})`;
};

export const expirationFormatter = (expiration: string) => {
    if (!expiration) {
        return "";
    }

    return `${expiration.slice(2)}/${expiration.slice(0, 2)}`;
};

export const loadSubscription = async (oData: OData): Promise<ISubscriptionEntity> => {
    const res = await oData.getEntitySetWrapper(EntitySetName.Subscriptions).query()
        .expand(SubscriptionEntity.PaymentMethod)
        .expand(SubscriptionEntity.RequestedPaymentMethod)
        .expand(SubscriptionEntity.Tenant, (q) => {
            q.expand(TenantEntity.BillingAddress,
                (qq) => {
                    qq.expand(BillingAddressEntity.Country);
                })
                .expand(TenantEntity.BillingContacts)
                .expand(TenantEntity.VatStatus);
        })
        // todo co s timhle
        // .expand(SubscriptionEntity.CompanyItems, (q) => {
        //     q.expand(CompanyItemEntity.Currency);
        // })
        // .expand(SubscriptionEntity.ModuleItems, (q) => {
        //     q.expand(ModuleItemEntity.Currency).expand(ModuleItemEntity.Module);
        // })
        .expand(SubscriptionEntity.Invoices)
        .expand(SubscriptionEntity.Currency)
        .fetchData<ISubscriptionEntity[]>();

    return res.value?.[0];
};

enum CreditCardBrand {
    Visa = "visa",
    MasterCard = "mastercard",
    Other = "other"
}

const getCreditCardBrand = (brand: string): CreditCardBrand => {
    const lowerCaseBrand = brand?.toLowerCase();

    switch (true) {
        case (lowerCaseBrand?.startsWith("visa")):
            return CreditCardBrand.Visa;
        case (lowerCaseBrand?.startsWith("mastercard")):
            return CreditCardBrand.MasterCard;
        default:
            return CreditCardBrand.Other;
    }
};

const getCreditCardBrandSvg = (brand: CreditCardBrand) => {
    switch (true) {
        case (brand === CreditCardBrand.Visa):
            return visaSvgPath;
        case (brand === CreditCardBrand.MasterCard):
            return masterCardSvgPath;
        default:
            return otherSvgPath;
    }
};

export const getActiveCompaniesSelectItems = (context: IAppContext) => {
    const companies = context.getData().companies as ICompanyEntity[];

    return companies.filter(company => company.StateCode === CompanyStateCode.Initialized).map((company): ISelectItem => {
        return {
            id: company.Id,
            label: company.Name,
            tabularData: [company.Name, company.LegalNumber]
        };
    });
};

export const GIBIBYTE_IN_BYTES = Math.pow(1024, 3);

export const capacityValueFormatter = (value: number): number => {
    // values are in bytes, and we want to show Gibibytes instead of Gigabytes (1024/1000)
    // use 1 073 741 824 (1024^3) instead of 1 000 000 000
    return roundToDecimalPlaces(1, value / GIBIBYTE_IN_BYTES);
};

/** User has trial version and did not yet set his payment info
 * => we are waiting for him to pay. */
export const isNotYetPayedForSubscription = (subscription: ISubscriptionEntity): boolean => {
    return subscription.SubscriptionTypeCode === SubscriptionTypeCode.Standard
        && subscription.PurchaseStatusCode === PurchaseStatusCode.NotPurchased;
};

export const isEduLicenceWaitingForConfirmation = (subscription: ISubscriptionEntity): boolean => {
    return subscription.SubscriptionTypeCode === SubscriptionTypeCode.EDU && subscription.SubscriptionStatusCode === SubscriptionStatusCode.AwaitsConfirmation;
};

/** How much time is left for the user to pay before the account gets suspended. */
export const getRemainingGracePeriodDays = (subscription: ISubscriptionEntity): number => {
    const remainingDays = getUtcDayjs(subscription.DateGracefulPeriodEnd).diff(getUtcDayjs(), "day");

    if (isNaN(remainingDays) || remainingDays < 0) {
        return 0;
    }

    return remainingDays;
};

export const isInTrial = (subscription: ISubscriptionEntity): boolean => {
    const today = getUtcDayjs();
    const dateTrialEnd = subscription.DateTrialStart && subscription.TrialLength ? getUtcDayjs(subscription.DateTrialStart).add(subscription.TrialLength, "days") : null;

    if (!dateTrialEnd) {
        return false;
    }

    return today.isSameOrBefore(dateTrialEnd, "days");
};

/** How much time is left on the trial version */
export const getRemainingTrialPeriodDays = (subscription: ISubscriptionEntity): number => {
    const diff = getUtcDayjs(subscription.DateTrialStart).diff(getUtcDayjs(), "day");
    const remainingDays = subscription.TrialLength + diff;

    if (isNaN(remainingDays)) {
        return 0;
    }

    return remainingDays;
};

export const isFreeSubscription = (subscription: ISubscriptionEntity): boolean => {
    return [SubscriptionTypeCode.Free, SubscriptionTypeCode.EDU].includes(subscription.SubscriptionTypeCode as SubscriptionTypeCode);
};

export enum BackendCardAction {
    Add = "Add",
    Change = "Change"
}

export const addOrUpdateCard = async (action?: BackendCardAction): Promise<Response> => {
    const res = await customFetch(ADD_CARD_URL, {
        ...getDefaultPostParams()
    });

    if (!res.ok) {
        return res;
    }

    const values = await res.json() as IPaymentState;

    window.location.assign(values.Url);

    return res;
};

export const removeCard = async (): Promise<Response> => {
    const res = await customFetch(`${REMOVE_CARD_URL}`, {
        ...getDefaultPostParams()
    });

    return res;
};

export const createMissingPayment = async (): Promise<Response> => {
    // run one time instant payment
    const res = await customFetch(CREATE_MISSING_PAYMENT_URL, {
        ...getDefaultPostParams()
    });

    if (!res.ok || res.status === 204) {
        return res;
    }

    const values = await res.json() as IPaymentState;

    window.location.assign(values.Url);

    return res;
};

/** Checks for both Cancelled and Expired.
 * Expired is similar to canceled, but caused but not paying for the subscription,
 * while Cancelled is result of user directly selecting the cancel action.*/
export const isSubscriptionCancelled = (subscription: ISubscriptionEntity): boolean => {
    return [SubscriptionStatusCode.Cancelled, SubscriptionStatusCode.Expired].includes(subscription?.SubscriptionStatusCode as SubscriptionStatusCode);
};

export const cancelSubscription = async (): Promise<Response> => {
    return await fetch(`${TENANT_LIFECYCLE_URL}/Cancel`, {
        ...getDefaultPostParams()
    });
};

export const restoreSubscription = async (): Promise<Response> => {
    return await fetch(`${TENANT_LIFECYCLE_URL}/Restore`, {
        ...getDefaultPostParams()
    });
};

export const confirmSubscription = async (): Promise<Response> => {
    return await fetch(`${TENANT_LIFECYCLE_URL}/Confirm`, {
        ...getDefaultPostParams()
    });
};

export const setSubscriptionCheckerSeen = (closedAt: Date): void => {
    MainLocalSettings.set(VariantId, { SubscriptionNotificationClosedAt: closedAt }, true);
};

export interface IInvoiceBillingItem {
    Name: string;
    Price: number;
    Quantity: number;
    Days: number;
    EntityId?: number;
    BillingModuleType: {
        S5articleGuid: string;
        Name: string;
        Code: BillingModuleTypeCode;
    };
}

export interface INextInvoiceData {
    Amount: number;
    AmountVat: number;
    AmountNet: number;
    DateMonthStart: string;
    Items: IInvoiceBillingItem[];
}


export const getNextInvoiceData = async (): Promise<INextInvoiceData> => {
    const res = await fetch(GET_NEXT_INVOICE_URL);

    return await res.json() as INextInvoiceData;
};

export function isRossumModuleActivated(storage: Model): boolean {
    const modulesInfo = storage.context.getData().modulesInfo;

    return !!modulesInfo?.find(module => module.Module?.Code === BillingModuleTypeCode.Rossum)?.IsActive;
}