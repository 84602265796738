import { memoize as _memoize, MemoizedFunction } from "lodash";
import { clearAllMemoizeOneObjs } from "./memoizeOne";

const memoizedFunctions: MemoizedFunction[] = [];

export function clearAllMemoizedData(): void {
    clearAllMemoizeOneObjs();
    memoizedFunctions.forEach(f => f?.cache?.clear?.());
}

/**
 * Lodash memoize wrapper - saves memoized functions to set.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function memoize<T extends (...args: any) => any>(func: T, resolver?: (...args: Parameters<T>) => any): T & MemoizedFunction {
    const memoized = _memoize(func, resolver);
    memoizedFunctions.push(memoized);
    return memoized;
}

export default memoize;