import { WithAlert, withAlert } from "@components/alert/withAlert";
import { BreadCrumbProvider } from "@components/breadCrumb/index";
import BusyIndicator from "@components/busyIndicator";
import { WithBusyIndicator, withBusyIndicator } from "@components/busyIndicator/withBusyIndicator";
import { Button, ButtonGroup } from "@components/button";
import { WithPromisedComponent, withPromisedComponent } from "@components/dialog/withPromisedComponent";
import { ArrowIcon } from "@components/icon";
import { ScrollBar } from "@components/scrollBar";
import BindingContext, { createBindingContext } from "@odata/BindingContext";
import { EntitySetName, ICompanySettingEntity } from "@odata/GeneratedEntityTypes";
import { CompanyStateCode } from "@odata/GeneratedEnums";
import { WithOData, withOData } from "@odata/withOData";
import COATemplateSelection from "@pages/companies/COATemplateSelection";
import { isCashBasisAccountingCompany } from "@utils/CompanyUtils";
import { KeyboardShortcut } from "@utils/keyboardShortcutsManager/KeyboardShorcutsManager.utils";
import KeyboardShortcutsManager from "@utils/keyboardShortcutsManager/KeyboardShortcutsManager";
import i18next from "i18next";
import React, { ReactElement } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";

import { NEW_ITEM_DETAIL } from "../../constants";
import { AppContext, IAppContext, IBreadcrumbItem } from "../../contexts/appContext/AppContext.types";
import { addCompanyIdToUrl } from "../../contexts/appContext/AppContext.utils";
import { WithPermissionContext, withPermissionContext } from "../../contexts/permissionContext/withPermissionContext";
import { IconSize, Status } from "../../enums";
import { ROUTE_COMPANIES, ROUTE_HOME } from "../../routes";
import TestIds from "../../testIds";
import { getUtcDayjs } from "../../types/Date";
import memoizeOne from "../../utils/memoizeOne";
import { FormFooter } from "../../views/formView/FormView.styles";
import PlugAndPlayForm from "../../views/formView/PlugAndPlayForm";
import View from "../../views/View";
import Users from "../admin/users/Users";
import { getDefinitions as getUsersDef } from "../admin/users/UsersDef";
import {
    getDefinitions as getChartOfAccountTemplatesDef
} from "../chartOfAccountsTemplates/ChartOfAccountsTemplatesDef";
import { getFiscalYears } from "../fiscalYear/FiscalYear.utils";
import { getDefinitions as getFiscalYearDef } from "../fiscalYear/FiscalYearDef";
import FiscalYearFormView from "../fiscalYear/FiscalYearFormView";
import { CbaInitialYearSelection } from "./CbaInitialYearSelection";
import { getDefinitions as getCompanyFormDef } from "./CompanyDef";
import CompanyFormView from "./CompanyFormView";
import { FooterWrapper, HeaderStyled, StepSubtitle, StyledScrollBarWrapper } from "./CompanyWizard.styles";
import { getDefinitions as getNewCustomersDef } from "./NewCustomers.def";
import NewCustomersFormView from "./NewCustomersFormView";


interface IProps extends WithBusyIndicator, WithOData, WithTranslation, RouteComponentProps, WithAlert, WithPermissionContext, WithPromisedComponent {
}

interface IState {
    step: WizardStep;
    isBusy: boolean;
    usersActionIsActive: boolean;
}

enum WizardStep {
    CompanyForm,
    FiscalYear,
    CoATemplates,
    UserSettings,
    CustomerUsers,
    SelectYear
}

class CompanyWizard extends React.PureComponent<IProps, IState> {
    static contextType = AppContext;
    formViewRef = React.createRef<any>();
    companySettings: ICompanySettingEntity = undefined;
    companyId: number;
    _scrollRef = React.createRef<HTMLDivElement>();
    _unsubscribeKeyboardShortcuts: () => void;

    constructor(props: IProps, context: IAppContext) {
        super(props);

        this.companyId = context.getCompanyId();
        const step: WizardStep = this.getSavedStep(context);
        this.state = {
            step,
            isBusy: true,
            usersActionIsActive: false
        };
    }

    async componentDidMount() {
        if (this.props.tReady) {
            this.init();
        }
        this._unsubscribeKeyboardShortcuts = KeyboardShortcutsManager.subscribe({
            shortcuts: [KeyboardShortcut.ALT_S],
            callback: this.handleKeyboardShortcut,
            isPrioritized: true
        });
        this.companySettings = await this.getCompanySettings();
        this.forceUpdate();
    }

    async componentDidUpdate(prevProps: IProps) {
        if (this.companyId !== this.context.getCompanyId()) {
            this.companyId = this.context.getCompanyId();
            this.companySettings = await this.getCompanySettings();
            this.setSavedStep();
        }
        if (!prevProps.tReady && this.props.tReady) {
            this.init();
        }
    }

    componentWillUnmount() {
        this._unsubscribeKeyboardShortcuts();
    }

    get isCompanyForm(): boolean {
        return this.state.step === WizardStep.CompanyForm;
    }

    handleKeyboardShortcut = (shortcut: KeyboardShortcut): boolean => {
        if (shortcut === KeyboardShortcut.ALT_S) {
            this.nextStep();
            return true;
        }
        return false;
    };

    setSavedStep = () => {
        this.setState({ step: this.getSavedStep(this.context) });
    };

    get orderedSteps(): WizardStep[] {
        if (isCashBasisAccountingCompany(this.context)) {
            return [WizardStep.CompanyForm, WizardStep.SelectYear, WizardStep.UserSettings, WizardStep.CustomerUsers];
        }
        return [WizardStep.CompanyForm, WizardStep.FiscalYear, WizardStep.CoATemplates, WizardStep.UserSettings, WizardStep.CustomerUsers];
    }

    getSavedStep(context: IAppContext): WizardStep {
        return context.getData()?.personalizationSettings?.companyWizardStep?.[this.companyId] as WizardStep ?? WizardStep.CompanyForm;
    }

    getCompanySettings = async (): Promise<ICompanySettingEntity> => {
        const res = await this.props.oData.getEntitySetWrapper(EntitySetName.CompanySettings)
                .query()
                .top(1)
                .fetchData<ICompanySettingEntity[]>();
        return res.value?.[0] ?? {};
    };

    init = () => {
        this.props.setAlert(null);
        this.context.setViewBreadcrumbs({
            items: this.getViewBreadCrumbs(),
            lockable: false
        });
    };

    getViewBreadCrumbs = memoizeOne((): IBreadcrumbItem[] => {
        return [
            {
                key: "Companies",
                title: this.props.t("Companies:Title"),
                link: ROUTE_COMPANIES
            }, {
                key: "new_company",
                title: this.props.t("Companies:Wizard.Title"),
                link: ""
            }
        ];
    }, () => [this.props.tReady]);


    initializeCompany = async () => {
        const context = this.context as IAppContext;
        await this.props.oData.getEntitySetWrapper(EntitySetName.Companies)
                .update(this.context.getCompany().Id, {
                    StateCode: CompanyStateCode.Initialized
                });

        await this.props.permissionContext.refreshUserPermissions();

        context.updateCompany(this.context.getCompany().Id, {
            StateCode: CompanyStateCode.Initialized
        });
        await Promise.all(context.updateCompanySpecificData());
    };

    saveStep = (step: WizardStep): void => {
        const currentSettings = this.context.getData()?.personalizationSettings?.companyWizardStep ?? {};
        this.context.changePersonalizationSetting("companyWizardStep", {
            ...currentSettings,
            [this.context.getCompanyId()]: step
        });
    };

    setStepByIndex = (index: number) => {
        const step = this.orderedSteps[index];
        this.setState({ step });
        this.saveStep(step);
    };

    handleStepChange = async (isLastStep?: boolean): Promise<boolean> => {
        if (this.state.step === WizardStep.CustomerUsers && !isLastStep) {
            return true;
        }
        if (this.state.step === WizardStep.SelectYear) {
            await this.props.oData.getEntitySetWrapper(EntitySetName.CompanySettings).update(this.companySettings.Id, {
                InitialYear: this.companySettings.InitialYear ?? getUtcDayjs().get("year")
            });
        } else if (this.state.step === WizardStep.CoATemplates) {
            await this.props.oData.getEntitySetWrapper(EntitySetName.CompanySettings).update(this.companySettings.Id, {
                InitialChartOfAccountsTemplateId: this.companySettings.InitialChartOfAccountsTemplateId
            });
        } else if (this.formViewRef.current) {
            const res = await this.formViewRef.current.save({ skipLoad: true });

            if (!res) {
                if (this._scrollRef?.current) {
                    this._scrollRef.current.scrollTop = 0;
                }
                this.setState({ isBusy: false });
                return false;
            }

            if (this.isCompanyForm) {
                const newCompanyId = res.bindingContext?.getKey() as number;
                await this.context.updateCompanies();
                this.context.setCurrentCompanyId(newCompanyId);
                await this.context.getFYPromise();
                this.companySettings = await this.getCompanySettings();
            }
        }
        return true;
    };

    nextStep = async () => {
        this.setState({ isBusy: true });
        const currentIndex = this.orderedSteps.indexOf(this.state.step);
        const nextIndex = currentIndex + 1;
        const isLastStep = this.orderedSteps.indexOf(this.state.step) === this.orderedSteps.length - 1;

        const res = await this.handleStepChange(isLastStep);

        if (!res) {
            return;
        }

        if (nextIndex < this.orderedSteps.length) {
            this.setStepByIndex(nextIndex);
        } else {
            await this.handleActivation();
        }
    };

    previousStep = async () => {
        this.setState({ isBusy: true });
        const currentIndex = this.orderedSteps.indexOf(this.state.step);
        const previousIndex = currentIndex - 1;

        await this.handleStepChange();

        if (previousIndex >= 0) {
            this.setStepByIndex(previousIndex);
        }
    };

    handleActivation = async () => {
        let isOk = false;

        try {
            this.setState({ isBusy: true });
            await this.initializeCompany();
            isOk = true;
        } catch (e) {
            const errorTranslationKey = `Error:${e._validationMessages?.[0]?.code}`;
            this.props.setAlert({
                status: Status.Error,
                title: this.props.t("Common:Errors.ErrorHappened"),
                subTitle: i18next.exists(errorTranslationKey) ? this.props.t(errorTranslationKey) : null
            });
        }

        this.setState({ isBusy: false });

        if (isOk) {
            const url = addCompanyIdToUrl(ROUTE_HOME, this.context.getCompanyId());
            this.props.history.push(url);
        }
    };

    handleAfterLoad = () => {
        this.setState({ isBusy: false });
    };

    renderStep = (step: WizardStep): ReactElement => {
        const commonProps = {
            t: this.props.t,
            history: this.props.history,
            ignoreVariants: true,
            showButtons: false,
            onAfterLoad: this.handleAfterLoad,
            formViewProps: {
                formProps: {
                    hideBreadcrumbs: true,
                    hideHeader: true,
                    withoutPadding: true,
                    renderScrollbar: false,
                    shouldHideVariant: true,
                    shouldHideAuditTrail: true,
                    hideBusyIndicator: true
                }
            }
        };

        const companyId = this.context.getCompanyId();
        switch (step) {
            case WizardStep.CompanyForm:
                const bc = createBindingContext(EntitySetName.Companies, this.props.oData.getMetadata()).addKey(companyId ?? NEW_ITEM_DETAIL, !companyId);

                return <PlugAndPlayForm
                        key={WizardStep.CompanyForm}
                        getDef={getCompanyFormDef}
                        formRef={this.formViewRef}
                        bindingContext={bc}
                        formView={CompanyFormView}
                        {...commonProps}
                />;
            case WizardStep.FiscalYear:
                const FY = getFiscalYears(this.context)?.[0];
                if (!FY) {
                    return null;
                }
                return <PlugAndPlayForm
                        key={WizardStep.FiscalYear}
                        getDef={getFiscalYearDef}
                        formRef={this.formViewRef}
                        bindingContext={createBindingContext(EntitySetName.FiscalYears, this.props.oData.getMetadata()).addKey(FY.Id)}
                        formView={FiscalYearFormView}
                        {...commonProps}
                />;

            case WizardStep.CoATemplates:
                return <COATemplateSelection templateId={this.companySettings?.InitialChartOfAccountsTemplateId}
                                             onChange={this.handleTemplateSelect}/>;

            case WizardStep.CustomerUsers:
                const usersBc = createBindingContext(BindingContext.localContext("NewCustomers"), this.props.oData.getMetadata());

                return <PlugAndPlayForm
                        key={WizardStep.CustomerUsers}
                        getDef={getNewCustomersDef}
                        formRef={this.formViewRef}
                        bindingContext={usersBc}
                        formView={NewCustomersFormView}
                        {...commonProps}
                />;
            case WizardStep.SelectYear:
                return <CbaInitialYearSelection
                        onChange={this.handleInitialYearChange}
                        initialYear={this.companySettings?.InitialYear ?? getUtcDayjs().get("year")}/>;
        }

        return null;
    };

    handleTemplateSelect = async (templateId: number) => {
        this.companySettings.InitialChartOfAccountsTemplateId = templateId;
        this.forceUpdate();
    }

    handleInitialYearChange = (value: number) => {
        this.companySettings.InitialYear = value;
        this.forceUpdate();
    };

    getStepSubtitle = () => {
        let stepTitle = '';
        const order = `${this.orderedSteps.indexOf(this.state.step)}/${this.orderedSteps.length - 1}:`;
        switch (this.state.step) {
            case WizardStep.CompanyForm:
                return this.props.t("Companies:Wizard.BasicInformation");
            case WizardStep.FiscalYear:
                stepTitle = this.props.t("Companies:Wizard.FirstFiscalYear");
                break;
            case WizardStep.CoATemplates:
                stepTitle = this.props.t("Companies:Wizard.ChartOfAccountsTemplates");
                break;
            case WizardStep.UserSettings:
                stepTitle = this.props.t("Companies:Wizard.UserSettings");
                break;
            case WizardStep.CustomerUsers:
                stepTitle = this.props.t("Companies:Wizard.CustomerPortalAccess");
                break;
            case WizardStep.SelectYear:
                stepTitle = this.props.t("Companies:Wizard.SetInitialYear");
                break;
        }

        return `${order} ${stepTitle}`;
    };

    renderHeader = () => {
        const title = this.props.t(`Companies:Wizard.${this.isCompanyForm ? "Title" : "InitialSettings"}`);
        return <>
            <HeaderStyled title={title}
                          shouldHideVariant={true}
            />
            <StepSubtitle data-testid={TestIds.Subtitle}>{this.getStepSubtitle()}</StepSubtitle>
        </>;
    };

    renderFooter = () => {
        const isLastStep = this.orderedSteps.indexOf(this.state.step) === this.orderedSteps.length - 1;
        const cancelText = this.isCompanyForm ? this.props.t("Common:General.Cancel") : this.props.t("Companies:Wizard.SetNextTime");
        const confirmText = this.isCompanyForm ? this.props.t("Common:General.Create") : isLastStep ? this.props.t("Companies:Wizard.Finish") : this.props.t("Common:General.Continue");
        const shouldDisableButtons = this.state.usersActionIsActive;

        return <FormFooter data-testid={TestIds.FormFooter}>
            <ButtonGroup>
                {!this.isCompanyForm && this.orderedSteps.indexOf(this.state.step) !== 1 &&
                        <Button isTransparent
                                isDisabled={shouldDisableButtons}
                                icon={<ArrowIcon width={IconSize.S} height={IconSize.S}/>}
                                onClick={this.previousStep}>{this.props.t("Common:General.Back")}</Button>
                }
                <Button isTransparent
                        isDisabled={shouldDisableButtons}
                        onClick={this.handleWizardCancel}>{cancelText}</Button>
                <Button isDisabled={shouldDisableButtons}
                        onClick={this.nextStep}>{confirmText}</Button>
            </ButtonGroup>
        </FormFooter>;
    };

    stepIsWithoutBusyState = (step: WizardStep): boolean => {
        return [WizardStep.SelectYear, WizardStep.CoATemplates].includes(step);
    };

    handleUsersTableActionChange = (action: string) => {
        this.setState({ usersActionIsActive: !!action });
    };

    dontSaveBeforeCancel = () => {
        return [WizardStep.CompanyForm].includes(this.state.step);
    };

    handleWizardCancel = async () => {
        if (!this.dontSaveBeforeCancel()) {

            const res = await this.handleStepChange();

            if (!res) {
                return;
            }
        }
        this.props.history.push(ROUTE_COMPANIES);
    };

    render() {
        if (!this.props.tReady) {
            return null;
        }

        if (this.state.step === WizardStep.UserSettings) {
            return <Users
                    oData={this.props.oData}
                    t={this.props.t}
                    tReady={this.props.tReady}
                    i18n={this.props.i18n}
                    getDef={getUsersDef}
                    onTableActionChange={this.handleUsersTableActionChange}
                    customHeader={this.renderHeader()}
                    customFooter={this.renderFooter()}
                    dontUseUrlParams={true}/>;
        }

        return (
                <>
                    {this.state.isBusy && !this.stepIsWithoutBusyState(this.state.step) && <BusyIndicator/>}
                    <BreadCrumbProvider/>
                    <StyledScrollBarWrapper>
                        <ScrollBar
                                scrollableNodeProps={{
                                    ref: this._scrollRef
                                }}>
                            <View hotspotContextId={"companyWizard"}
                                  renderScrollbar={false}>
                                {this.renderHeader()}
                                {this.props.alert}
                                {this.renderStep(this.state.step)}
                            </View>
                        </ScrollBar>
                    </StyledScrollBarWrapper>
                    <FooterWrapper>
                        {this.renderFooter()}
                    </FooterWrapper>
                </>
        );
    }
}

export default withTranslation([
    "Common",
    ...getCompanyFormDef.translationFiles,
    ...getFiscalYearDef.translationFiles,
    ...getChartOfAccountTemplatesDef.translationFiles,
    ...getUsersDef.translationFiles,
    ...getNewCustomersDef.translationFiles
])(withPromisedComponent(withPermissionContext(withRouter(withOData(withBusyIndicator()(withAlert()(CompanyWizard)))))));