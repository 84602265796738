import React from "react";
import { Dayjs } from "dayjs";
import { DatePickerView } from "../../../../enums";
import { Calendar } from "./Calendar";
import { getValidDayjsDate, isMaxDay, isMinDay, setTimeToDate } from "../utils";
import DateType, { getUtcDayjs } from "../../../../types/Date";
import memoizeOne from "../../../../utils/memoizeOne";
import { IDateSingleValuePopupSharedProps } from "./Calendar.utils";

interface IState {
    previewDay: Dayjs;
    view: DatePickerView;
}

export interface IProps extends IDateSingleValuePopupSharedProps {
    defaultView?: DatePickerView;
    disableViewChange?: boolean;
    onViewChange?: (view: DatePickerView) => void;
    showTime?: boolean;
    showConfirmButtons?: boolean;
    onOk?: () => void;
    onCancel?: () => void;
    calendarRef?: React.Ref<React.ElementType>;
}

export class DatePickerPopup extends React.PureComponent<IProps, IState> {
    static defaultProps: Partial<IProps> = {
        disableAutoFocus: true
    };

    constructor(props: IProps) {
        super(props);

        const isSpecialDay = isMinDay(props.value) || isMaxDay(props.value);

        this.state = {
            previewDay: getValidDayjsDate(isSpecialDay ? null : this.props.value),
            view: this.props.defaultView ? this.props.defaultView : DatePickerView.Days
        };
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>) {
        if (this.props.value !== prevProps.value && DateType.isValid(this.props.value)) {
            let previewDay: Dayjs = this.state.previewDay;

            if (!this.props.value) {
                previewDay = null;
            } else if (!isMinDay(this.props.value) && !isMaxDay(this.props.value)) {
                previewDay = getValidDayjsDate(this.props.value);
            }

            this.setState({ previewDay });
        }
    }

    handleCalendarChange = (day: Dayjs, isTimeChange: boolean): void => {
        if (!this.props.onChange) {
            return null;
        }

        let value: Date;

        if (!day) {
            value = null;
        } else {
            if (this.props.showTime) {
                value = day.toDate();
            } else {
                value = setTimeToDate(day, getValidDayjsDate(this.props.value)).toDate();
            }
        }

        this.props.onChange(value);
    };

    handlePreviewChange = (day: Dayjs): void => {
        this.setState({
            previewDay: day
        });
    };

    handleViewChange = (view: DatePickerView): void => {
        this.setState({
            view: view
        });

        this.props.onViewChange?.(view);
    };

    getValue = memoizeOne(() => {
        return !this.props.value ? null : getUtcDayjs(this.props.value);
    }, () => [this.props.value]);

    render = (): React.ReactElement => {
        return (
            <Calendar previewDay={this.state.previewDay}
                      value={this.getValue()}
                      view={this.state.view}
                      mainView={DatePickerView.Days}
                      onChange={this.handleCalendarChange}
                      minDate={this.props.minDate}
                      maxDate={this.props.maxDate}
                      isDateDisabled={this.props.isDateDisabled}
                      embedded={this.props.embedded}
                      showTime={this.props.showTime}
                      showConfirmButtons={this.props.showConfirmButtons}
                      onOk={this.props.onOk}
                      onCancel={this.props.onCancel}
                      showSpecialValue={this.props.showSpecialValue}
                      disableAutoFocus={this.props.disableAutoFocus}
                // todo how to solve correct ts types
                      ref={this.props.calendarRef as any}
                      onPreviewChange={this.handlePreviewChange}
                      onViewChange={this.handleViewChange}/>
        );
    };
}