import React from "react";
import TooltipIcon, { ITooltipIconProps } from "../TooltipIcon";
import { WithTranslation, withTranslation } from "react-i18next";
import { IconSize } from "../../../enums";
import { KeyName } from "../../../keyName";

interface IProps extends ITooltipIconProps, WithTranslation {
    link: string;
    title?: string;
    successTitle?: string;
}

class TooltipIconCopyLink extends React.PureComponent<IProps> {
    handleClick = () => {
        // store into system clipboard
        navigator.clipboard.writeText(this.props.link);
    };

    handleKeyDown = (event: React.KeyboardEvent) => {
        const isCopyShortcut = ["c", "C"].includes(event.key) && event.ctrlKey;
        if (isCopyShortcut || event.key === KeyName.Enter) {
            this.handleClick();
        }
    };

    render() {
        return (
            <TooltipIcon iconName={"CopyLink"}
                         onClick={this.handleClick}
                         onKeyDown={this.handleKeyDown}
                         title={this.props.title ?? this.props.t("Components:CopyLink.CopyLink")}
                         autoHide
                         size={IconSize.XS}
                         {...this.props}>
                {this.props.successTitle ?? this.props.t("Components:CopyLink.LinkCopied")}
            </TooltipIcon>
        );
    }
}

export default withTranslation(["Components"])(TooltipIconCopyLink);