import React from "react";
import { AppContext, ContextEvents, IBreadcrumb } from "../../contexts/appContext/AppContext.types";
import { IMenuSelected } from "../navigation";
import { Helmet } from "react-helmet";
import { getValue } from "@utils/general";

class PageTitleSetter extends React.PureComponent {
    static contextType = AppContext;

    componentDidMount() {
        this.context.eventEmitter.on(ContextEvents.SelectedMenuChanged, this.handleMenuOrBreadcrumbChange);
        this.context.eventEmitter.on(ContextEvents.ViewBreadcrumbsChanged, this.handleMenuOrBreadcrumbChange);
    }

    componentWillUnmount() {
        this.context.eventEmitter.off(ContextEvents.SelectedMenuChanged, this.handleMenuOrBreadcrumbChange);
        this.context.eventEmitter.off(ContextEvents.ViewBreadcrumbsChanged, this.handleMenuOrBreadcrumbChange);
    }

    handleMenuOrBreadcrumbChange = () => {
        this.forceUpdate();
    };

    render() {
        const selectedMenu: IMenuSelected = this.context.getSelectedMenu();
        const viewBreadcrumbs: IBreadcrumb = this.context.getViewBreadcrumbs();
        let title = "";

        if (selectedMenu?.group) {
            title += `${selectedMenu.group.title}`;

            if (selectedMenu.item) {
                title += ` / ${getValue(selectedMenu.item.title)}`;
            }
        }

        if (viewBreadcrumbs) {
            for (const viewBreadcrumb of viewBreadcrumbs.items) {
                title += `${title ? " / " : ""}${viewBreadcrumb.title}`;
            }
        }

        if (title) {
            title += " | ";
        }

        title += "Evala";

        return (
            <Helmet>
                <title>
                    {title}
                </title>
            </Helmet>
        );
    }
}

export default PageTitleSetter;