import React, { useCallback } from "react";
import { ICheckboxRenderChildProps } from "@components/inputs/checkbox";
import { ConfigurationCheckboxStyled, StyledSubtitle, StyledTitle } from "./Home.style";

interface IProps {
    id: string;
    title: string;
    subtitle?: string;
    isChecked: boolean;
    onChange: (id: string, val: boolean) => void;
}

const DashboardConfigCheckbox: React.FC<IProps> = (props) => {
    const { id, onChange } = props;

    const handleChange = useCallback(({ value }) => {
        onChange(id, value);
    }, [onChange, id]);

    return (
        <ConfigurationCheckboxStyled checked={!!props.isChecked} onChange={handleChange}>
            {(p: ICheckboxRenderChildProps) => (
                <>
                    {p.checkbox}
                    <StyledTitle>{props.title}</StyledTitle>
                    {props.subtitle && <StyledSubtitle>{props.subtitle}</StyledSubtitle>}
                </>
            )}
        </ConfigurationCheckboxStyled>
    );
};

export default DashboardConfigCheckbox;