import React from "react";
import { useTranslation } from "react-i18next";
import { NoDataStyled, NoDataText } from "../../global.style";
import TestIds from "../../testIds";
import { SpiderAnimStyled } from "./Table.styles";

export const NoData = ({
                           noDataText,
                           isSimplified
                       }: { noDataText?: string; isSimplified?: boolean }): React.ReactElement => {
    const { t } = useTranslation("Components");

    return (
        <NoDataStyled data-testid={TestIds.NoData}>
            <NoDataText $isSimplified={!!isSimplified}
                data-testid={TestIds.Text}>{noDataText ?? t("Components:Table.NoData")}</NoDataText>
            {!isSimplified && <SpiderAnimStyled data-testid={TestIds.SpiderAnim}/>}
        </NoDataStyled>
    );
};