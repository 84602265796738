import React from "react";

import { IconSize } from "../../../enums";
import BindingContext, { createBindingContext } from "../../../odata/BindingContext";
import { PairIcon } from "../../icon";
import { ISmartMultiSelectProps, SmartMultiSelect } from "../smartSelect/SmartMultiSelect";
import SmartPairingSelectBase, { ISmartPairingSelectSharedProps } from "./SmartPairingSelectBase";

interface IProps extends ISmartMultiSelectProps, ISmartPairingSelectSharedProps {

}

/** Combination of MultiSelect and Dialog to pick multiple documents for pairing.
 * Hardcoded for documents, but could be changed to be used with anything. */
export default class SmartPairingMultiSelect extends React.Component<IProps> {
    getSelectedItems = (): BindingContext[] => {
        if (!this.props.value) {
            return [];
        }

        return this.props.value.map(val => {
            return createBindingContext(this.props.fieldInfo.fieldSettings.entitySet as string, this.props.storage.oData.metadata).addKey(val as (string | number));
        });
    };

    renderSelect = (onIconClick: (e: React.MouseEvent) => void): React.ReactElement => {
        const { onPair, dialogTableViewProps, getDefinition, ...selectProps } = this.props;

        return (
            <SmartMultiSelect {...selectProps as any}
                              storage={this.props.storage}
                              bindingContext={this.props.bindingContext}
                              value={this.props.value}
                              onChange={this.props.onChange}
                              iconHasDifferentAction={true}
                              inputIcon={<PairIcon width={IconSize.M}/>}
                              onIconActivate={onIconClick}
                              fieldInfo={this.props.fieldInfo}
                              onKeyDown={this.props.onKeyDown}
            />
        );
    };

    render() {
        return (
            <SmartPairingSelectBase storage={this.props.storage}
                                    selectedItems={this.getSelectedItems()}
                                    onPair={this.props.onPair}
                                    dialogTableViewProps={this.props.dialogTableViewProps}
                                    getDefinition={this.props.getDefinition}
                                    renderSelect={this.renderSelect}
                                    fieldInfo={this.props.fieldInfo}
                                    bindingContext={this.props.bindingContext}
                                    isSingleSelect={false}/>
        );
    }
}