import React, { useCallback, useState } from "react";

import { KeyName } from "../../../keyName";
import Input, { IInputOnBlurEvent, IInputOnChangeEvent, IInputProps } from "../input";
import NumericInput, { IProps as NumericInputProps } from "../numericInput/NumericInput";
import NumericWriteLine, { IProps as NumericWriteLineProps } from "../writeLine/NumericWriteLine";
import WriteLine, { IProps as WriteLineProps } from "../writeLine/WriteLine";

export interface ISubmittedInput<T> {
    value?: T;
    onChange?: (e: IInputOnChangeEvent<T>) => void;
    onBlur?: (e: IInputOnBlurEvent) => void;
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

// TODO remove submittedInput wrapper and instead add some option for debouncing settings directly to Input (or new DebouncedInput)
// because we want to have debouncing on input anyway
// submittedInput case would be handled by debouncing set to infinity => it would only be confirmed by pressing enter or blur

/** Wrapped component will only fire onChange event on enter or blur*/
export const submittedInput = <P extends ISubmittedInput<Type>, Type>(Component: React.ComponentType<P>) => {
    return React.forwardRef((props: P, ref) => {
        const [value, setValue] = useState(null);

        const handleChange = useCallback((e: IInputOnChangeEvent<Type>) => {
            if (e.triggerAdditionalTasks) {
                setValue(props.value);
                props.onChange(e);
            } else {
                setValue(e.value);
            }
        }, [props.onChange]);

        const handleBlur = useCallback(() => {
            if (value !== props.value) {
                props.onChange({ value });
            }
        }, [value, props.value, props.onChange]);

        const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === KeyName.Enter && value !== props.value) {
                props.onChange({ value });
            }
        }, [value, props.value, props.onChange]);

        React.useEffect(() => {
            setValue(props.value);
        }, [props.value]);

        return (
            <Component {...props}
                       value={value}
                       onChange={handleChange}
                       onBlur={handleBlur}
                       onKeyDown={handleKeyDown}
            />
        );
    });
};

export const SubmittedWriteLine = submittedInput<WriteLineProps, string>(WriteLine);
export const SubmittedNumericWriteLine = submittedInput<NumericWriteLineProps, number>(NumericWriteLine);
export const SubmittedInput = submittedInput<IInputProps, string>(Input);
export const SubmittedNumericInput = submittedInput<NumericInputProps, number>(NumericInput);