import React from "react";
import { Bubble, BubbleIconWrapper, BubbleWrapper } from "@components/splitLayout/SplitLayout.styles";
import { IconSize } from "../../enums";
import { getIcon } from "@components/icon";
import { InboxFakeSplitterStyled } from "./Inbox.styles";
import TestIds from "../../testIds";

interface IProps {
    onClick: () => void;
    iconName: string;
}

const InboxFakeSplitter: React.FC<IProps> = ({ iconName, onClick }) => {
    const Icon = getIcon(iconName);
    return (
            <InboxFakeSplitterStyled onClick={onClick} testId={TestIds.Splitter}>
                <BubbleWrapper isSecond={false}>
                    <Bubble isCollapsed>
                        <BubbleIconWrapper>
                            {Icon &&
                                    <Icon width={IconSize.S} preventHover/>
                            }
                        </BubbleIconWrapper>
                    </Bubble>
                </BubbleWrapper>
            </InboxFakeSplitterStyled>
    );
};

export default InboxFakeSplitter;