import styled from "styled-components/macro";
import { T_TITLE_4 } from "../../global.style";
import { StyledSelect } from "../inputs/select/Select.styles";
import { StyledIconButton } from "../button";


export const BodyWrapper = styled.div`
  /* ToDo: move to dialog and use percentage to work well on small screens */
  min-height: 500px;
`;

export const ValueInput = styled.div`
    white-space: nowrap;
`;

export const StyledConditionalFilterRow = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 13px;

    ${StyledSelect}, ${ValueInput} {
        margin-left: 32px;
    }

    ${StyledIconButton} {
        margin-left: 20px;
    }
`;

export const Title = styled.h4<{ _visible: boolean; }>`
    ${T_TITLE_4};

    margin-right: 45px;
    visibility: ${props => props._visible ? 'unset' : 'hidden'};
`;