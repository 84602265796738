import styled from "styled-components/macro";
import { SyncSymbolStyled } from "@components/inputs/field/Field.styles";

export const TemplateSummaryItemStyled = styled.div`
    display: flex;
    gap: 5px;
    align-items: center;
`;

export const TemplateName = styled.div`
    position: relative;

    padding: 0 12px 0 20px;
    background-color: ${props => props.theme.C_FIELD_sync};
    border-radius: 16px;
    margin-left: -20px;

    height: 34px;
    display: flex;
    align-items: flex-end;
    
    ${SyncSymbolStyled} {
        left: 0;
    }
`;