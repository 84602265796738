import React from "react";
import { CheckboxGroupColumn, CheckboxGroupWrapper, CheckboxStyled, StyledCheckboxGroup } from "./CheckboxGroup.styles";
import { IAuditTrailData } from "../../../model/Model";
import { AuditTrailFieldType } from "../../smart/FieldInfo";
import TestIds from "../../../testIds";
import { handleRefHandlers, toggleItemInArray } from "@utils/general";
import { ICheckboxChange } from "../checkbox";
import { withTranslation, WithTranslation } from "react-i18next";
import FocusManager, { FocusDirection, IFocusableItemProps } from "../../focusManager/FocusManager";
import { WithDomManipulator, withDomManipulator } from "../../../contexts/domManipulator/withDomManipulator";
import { ISelectItem } from "@components/inputs/select/Select.types";

export interface ICheckboxGroupProps {
    items: ICheckboxGroupItem[];
    values: string[];
    subgroups?: ICheckboxSubgroup[];
    numColumns?: number;
    isDisabled?: boolean;
    isReadOnly?: boolean;
    onChange: (args: ICheckboxGroupChange) => void;
    auditTrailData?: IAuditTrailData;
    passRef?: React.Ref<HTMLDivElement>;
    hasMasterCheckbox?: boolean;
}

export interface ICheckboxGroupItem extends ISelectItem {
    id: string;
    isReadOnly?: boolean;
}

interface ICheckboxGroupItemInternal extends ICheckboxGroupItem {
    isChecked?: boolean;
    isGroupMaster?: boolean;
    isLastInGroup?: boolean;
}

/** Use when checkboxes are supposed to be divided into multiple groups inside on CheckboxGroup.
 * Each "item" should than have "groupId" set */
export interface ICheckboxSubgroup {
    id: string;
    label: string;
}

export interface ICheckboxGroupChange {
    // all the current values after the change
    values: string[];
    // state for just the one changed checkbox
    // not used when change all button clicked
    id?: string;
    isChecked?: boolean;
}

class CheckboxGroup extends React.Component<ICheckboxGroupProps & WithTranslation & WithDomManipulator> {
    static defaultProps: Partial<ICheckboxGroupProps> = {
        items: [],
        values: [],
        subgroups: [],
        numColumns: 1
    };

    get checkableItems() {
        return this.props.items?.filter(item => !item.isDisabled || this.props.values?.includes(item.id));
    }

    get canHaveOrphans() {
        return this.props.subgroups.length > 0;
    }

    isItemChecked = (itemId: string) => {
        return (this.props.values || []).indexOf(itemId) !== -1;
    };

    handleChange = (id: string) => {
        const values = [...this.props.values || []];
        const isChecked = !values.find(val => val === id);
        toggleItemInArray(values, id);

        this.props.onChange({
            values,
            id,
            isChecked
        });
    };

    handleChangeAll = (args: ICheckboxChange) => {
        let newItems: ICheckboxGroupItem[];
        if (args.value) {
            newItems = this.checkableItems;
        } else {
            newItems = this.props.items?.filter(item => item.isDisabled && this.props.values?.includes(item.id));
        }

        this.props.onChange({ values: [...newItems.map(i => i.id)] });
    };

    handleSubgroupChange = (subgroupId: string, args: ICheckboxChange) => {
        const isChecked = args.value;
        const subgroupItems: ICheckboxGroupItemInternal[] = this.getSubgroupItems(this.props.items, subgroupId);
        const checkableSubgroupItems: ICheckboxGroupItemInternal[] = subgroupItems.filter(subgroupItem => this.checkableItems.find(checkableItem => checkableItem.id === subgroupItem.id));

        const values = [...this.props.values];

        for (const item of checkableSubgroupItems) {
            const isItemChecked = this.isItemChecked(item.id);

            if ((isChecked && !isItemChecked) || (!isChecked && isItemChecked)) {
                toggleItemInArray(values, item.id);
            }
        }

        this.props.onChange({
            values
        });
    };

    getAuditTrailData = (id: string) => {
        if (this.props.auditTrailData) {
            if (this.props.auditTrailData.type === AuditTrailFieldType.HoveredDifference || this.props.auditTrailData.type === AuditTrailFieldType.HoveredNoDifference) {
                return this.props.auditTrailData.missmatchedPaths?.[id] ? {
                    type: AuditTrailFieldType.HoveredDifference
                } : {
                    type: AuditTrailFieldType.HoveredNoDifference
                };
            }

            return this.props.auditTrailData.missmatchedPaths?.[id] ? {
                type: AuditTrailFieldType.Difference
            } : {
                type: AuditTrailFieldType.NoDifference
            };
        }

        return null;
    };

    getItems = (): ICheckboxGroupItemInternal[] => {
        let allItems: ICheckboxGroupItemInternal[];
        const checkableItems = this.checkableItems;

        if (this.props.subgroups.length === 0) {
            allItems = this.props.items.map(item => ({
                ...item,
                isChecked: this.isItemChecked(item.id),
                isDisabled: this.props.isDisabled || item.isDisabled,
                isReadOnly: this.props.isReadOnly
            }));
        } else {
            allItems = [];

            for (const subgroup of this.props.subgroups) {
                const subgroupItems: ICheckboxGroupItemInternal[] = this.getSubgroupItems(this.props.items, subgroup.id);
                const isGroupChecked = subgroupItems.every(item => {
                    const isCheckable = checkableItems.find(checkableItem => checkableItem.id === item.id);

                    return !isCheckable || this.isItemChecked(item.id);
                });

                if (subgroupItems.length > 0) {
                    allItems.push({
                        id: subgroup.id,
                        label: subgroup.label,
                        isGroupMaster: true,
                        isChecked: isGroupChecked,
                        isDisabled: this.props.isDisabled,
                        isReadOnly: this.props.isReadOnly
                    });
                }

                for (let i = 0; i < subgroupItems.length; i++) {
                    const item = subgroupItems[i];
                    const isLastInGroup = i === subgroupItems.length - 1;

                    allItems.push({
                        ...item,
                        isChecked: this.isItemChecked(item.id),
                        isDisabled: this.props.isDisabled || item.isDisabled,
                        isReadOnly: this.props.isReadOnly,
                        isLastInGroup
                    });

                }
            }
        }

        return allItems;
    };

    renderMasterCheckbox = (itemProps: IFocusableItemProps) => {
        const allChecked = this.props.values?.length === this.checkableItems?.length;

        return (
            <>
                <CheckboxStyled
                    bigBottomMargin
                    passProps={itemProps}
                    label={this.props.t("Common:Select.All")}
                    checked={allChecked}
                    isDisabled={this.props.isDisabled}
                    isReadOnly={this.props.isReadOnly}
                    onChange={this.handleChangeAll}
                    _isBold
                />
            </>
        );
    };

    renderItem = (item: ICheckboxGroupItemInternal, itemProps: IFocusableItemProps) => {
        return (
            <CheckboxStyled
                passProps={itemProps}
                key={item.isGroupMaster ? `group-${item.id}` : item.id}
                auditTrailData={this.getAuditTrailData(item.id)}
                checked={item.isChecked}
                label={item.label}
                isDisabled={item.isDisabled}
                isReadOnly={item.isReadOnly}
                onChange={item.isGroupMaster ? this.handleSubgroupChange.bind(this, item.id) : this.handleChange.bind(this, item.id)}
                _isBold={item.isGroupMaster}
                bigBottomMargin={item.isLastInGroup}/>
        );
    };

    getSubgroupItems = (items: ICheckboxGroupItem[], groupId: string) => {
        return items.filter(item => item.groupId === groupId);
    };

    renderColumns = (items: ICheckboxGroupItemInternal[], itemProps: IFocusableItemProps): React.ReactElement[] => {
        const numOfItemsInColumn = Math.ceil((this.canHaveOrphans ? 1 : 0) + (items.length / this.props.numColumns));
        const renderedColumns: React.ReactElement[] = [];
        let itemIndex = 0;


        for (let i = 0; i < this.props.numColumns; i++) {
            const columnItems: React.ReactElement[] = [];
            let isNextItemLast = false;
            let columnItemIndex = 0;

            while (true) {
                const item = items[itemIndex];

                if (!item) {
                    break;
                }

                itemIndex += 1;
                columnItemIndex += 1;

                let shouldBeLastItem = (columnItemIndex >= numOfItemsInColumn && i < this.props.numColumns - 1) || itemIndex === items.length;

                // last item of group cannot be alone in a new column
                // but lone group master has bigger priority
                if (shouldBeLastItem && items[itemIndex]?.isLastInGroup && i < this.props.numColumns - 1 && !items[itemIndex - 2]?.isGroupMaster) {
                    itemIndex -= 1;
                    break;
                }

                columnItems.push(this.renderItem(item, itemProps));

                if (item.isLastInGroup) {
                    // add fake item to account for the bigger margin
                    columnItemIndex += 1;
                }

                if (isNextItemLast) {
                    break;
                }

                // groupMaster cannot be last in column alone
                if (shouldBeLastItem && item.isGroupMaster) {
                    shouldBeLastItem = false;
                    isNextItemLast = true;
                }

                if (shouldBeLastItem) {
                    break;
                }
            }

            isNextItemLast = false;
            renderedColumns.push((
                <CheckboxGroupColumn key={i} data-testid={TestIds.CheckboxGroupColumn}>
                    {columnItems}
                </CheckboxGroupColumn>
            ));
        }

        return renderedColumns;
    };

    render() {
        const items = this.getItems();

        return (
            // with change from grid to flex and the orphans handling, FocusDirection.Grid would no longer work
            // => use FocusDirection.Vertical
            <FocusManager direction={FocusDirection.Vertical}
                          columnsCount={this.props.numColumns}>
                {({ itemProps, wrapperProps }) => {
                    const columns = this.renderColumns(items, itemProps);

                    return (
                        <CheckboxGroupWrapper
                            {...wrapperProps}
                            ref={(ref: HTMLDivElement) => {
                                handleRefHandlers(ref, this.props.passRef, wrapperProps.ref);
                            }}
                            data-testid={TestIds.CheckboxGroupWrapper}>
                            {this.props.hasMasterCheckbox && !!items?.length &&
                                this.renderMasterCheckbox(itemProps)
                            }
                            <StyledCheckboxGroup
                                role={"group"}
                                numColumns={this.props.numColumns}
                                numItems={items.length + Math.max(this.props.subgroups.length - 1, 0)}
                                canHaveOrphans={this.canHaveOrphans}
                                data-testid={TestIds.CheckboxGroup}>
                                {columns}
                            </StyledCheckboxGroup>
                        </CheckboxGroupWrapper>
                    );
                }}
            </FocusManager>
        );
    }
}

const CheckboxGroupExtended = withDomManipulator(withTranslation("Common")(CheckboxGroup));
export { CheckboxGroupExtended as CheckboxGroup } ;