import { IAlertProps } from "@components/alert/Alert";
import { IValueInterval } from "@components/conditionalFilterDialog/ConditionalFilterDialog.utils";
import { getDrillDownLink, getTableIntentLink } from "@components/drillDown/DrillDown.utils";
import { formatDateToDateString } from "@components/inputs/date/utils";
import { IFieldDef } from "@components/smart/FieldInfo";
import { IColumn, IRow } from "@components/table";
import { getEntitySetByDocumentType, getRouteByDocumentType } from "@odata/EntityTypes";
import {
    DocumentEntity,
    EntityTypeName,
    IDocumentEntity,
    IFiscalYearCloseEntity,
    IFiscalYearEntity,
    IInternalDocumentEntity,
    InternalDocumentEntity,
    IOtherLiabilityEntity,
    IOtherReceivableEntity,
    OtherLiabilityEntity
} from "@odata/GeneratedEntityTypes";
import { DocumentTypeCode, FiscalYearCloseSectionTypeCode, LegalEntityCategoryCode } from "@odata/GeneratedEnums";
import { getEnumDisplayValue } from "@odata/GeneratedEnums.utils";
import { OData } from "@odata/OData";
import {
    accruedExpenseFilter,
    accruedRevenueFilter,
    excludeInitialBalancesFilter,
    getOpenAccrualFilter
} from "@utils/accounting";
import { getCompanyCurrency } from "@utils/CompanyUtils";
import { arrayInsert } from "@utils/general";
import i18next, { TFunction } from "i18next";
import React, { Fragment, ReactElement } from "react";

import TableWithAutoSizedColumns from "../../components/table/TableWithAutoSizedColumns";
import Tabs from "../../components/tabs";
import { REST_API_URL } from "../../constants";
import { IAppContext } from "../../contexts/appContext/AppContext.types";
import { Status, TextAlign } from "../../enums";
import { TRecordString } from "../../global.types";
import { ROUTE_ACCOUNT_ANALYSIS, ROUTE_CHARTS_OF_ACCOUNTS } from "../../routes";
import { formatCurrency } from "../../types/Currency";
import DateType, { getUtcDayjs } from "../../types/Date";
import customFetch from "../../utils/customFetch";
import memoize from "../../utils/memoize";
import { AccountAnalysisProps, ClosingAccounts } from "../reports/accountAnalysis/AccountAnalysisDef";
import { CommonReportProps } from "../reports/CommonDefs";
import { CUSTOM_DATE_RANGE_ID } from "../reports/customFilterComponents/ComposedDateRange";
import {
    AccountNamesTableStyled,
    AccountNameStyled,
    AdditionalAccountInfoTable,
    TableWrapper
} from "./FiscalYearClose.styles";
import { ISelectItem } from "@components/inputs/select/Select.types";

export const FISCAL_YEAR_PROCESSING_URL = `${REST_API_URL}/FiscalYearProcessing`;
export const STEP_VALIDATION_URL = `${FISCAL_YEAR_PROCESSING_URL}/ValidateOnClose`;

export const openAccrualsRevenueFilter = `${getOpenAccrualFilter()} AND ${accruedRevenueFilter} AND ${excludeInitialBalancesFilter}`;
export const openAccrualsExpenseFilter = `${getOpenAccrualFilter()} AND ${accruedExpenseFilter} AND ${excludeInitialBalancesFilter}`;

export const getFYDateRangeDrilldownFilter = (fy: IFiscalYearEntity): IValueInterval => {
    const previousYearDateRange: IValueInterval = {
        from: formatDateToDateString(fy?.DateStart),
        to: formatDateToDateString(fy?.DateEnd)
    };

    return previousYearDateRange;
};

export interface IBaseFiscalYearCloseState {
    loading: boolean;
    switchBusy?: boolean;
    rows?: IRow[];
}

type IFYCloseGeneratedDoc = (IInternalDocumentEntity | IOtherLiabilityEntity);

export const renderDocumentsTab = (docs: IFYCloseGeneratedDoc[], t: TFunction, context: IAppContext, isLoading: boolean): ReactElement => {
    const isOnlyID = !docs?.find(d => d.DocumentTypeCode !== DocumentTypeCode.InternalDocument);
    return <>
        <Tabs data={[{ id: "documents", title: t(`FiscalYearClose:${isOnlyID ? "InternalDocs" : "Documents"}`) }]}
              selectedTabId={"documents"}/>
        {renderDocTable(docs, t, context, isLoading, !isOnlyID)}
    </>;
};

export const documentProps = (id: string, documentTypeCode = DocumentTypeCode.InternalDocument, useDateDue = false): IFieldDef => {
    const def: IFieldDef = {
        id,
        additionalProperties: [
            { id: DocumentEntity.DateAccountingTransaction },
            { id: DocumentEntity.NumberOurs },
            { id: documentTypeCode === DocumentTypeCode.InternalDocument ? InternalDocumentEntity.Explanation : DocumentEntity.Note },
            { id: DocumentEntity.DateCreated },
            { id: DocumentEntity.Amount },
            { id: DocumentEntity.DocumentTypeCode },
            { id: DocumentEntity.DateCbaDocument }
        ]
    };

    if (useDateDue) {
        def.additionalProperties = [
            ...def.additionalProperties,
            { id: OtherLiabilityEntity.DateDue }
        ];
    }

    return def;
};


export const renderDocTable = (docs: (IInternalDocumentEntity | IOtherLiabilityEntity)[], t: TFunction, context: IAppContext, isLoading: boolean, renderExtraColumns?: boolean): ReactElement => {
    let columns: IColumn[] = [
        { id: "date", label: t("FiscalYearClose:Final.Date"), textAlign: TextAlign.Left },
        { id: "doc", label: t("FiscalYearClose:Final.Doc"), textAlign: TextAlign.Left },
        { id: "name", label: t("FiscalYearClose:Final.Relation"), textAlign: TextAlign.Left },
        { id: "sum", label: t("FiscalYearClose:Final.Sum"), textAlign: TextAlign.Right }
    ];

    if (renderExtraColumns) {
        columns = arrayInsert(columns, {
            id: "docType",
            label: t("FiscalYearClose:Final.DocumentType"),
            textAlign: TextAlign.Left
        }, 1);
        columns = arrayInsert(columns, {
            id: "dueDate",
            label: t("FiscalYearClose:Final.ProposedDueDate"),
            textAlign: TextAlign.Right
        }, 1);
    }

    const rows = docs?.sort(sortByDate).map(doc => ({
        id: doc.Id,
        values: {
            date: DateType.format(doc.DateAccountingTransaction ?? doc.DateCbaDocument),
            doc: getTableIntentLink(doc.NumberOurs, {
                route: `${getRouteByDocumentType(doc.DocumentTypeCode as DocumentTypeCode)}/${doc.Id}`,
                context
            }),
            dueDate: DateType.format((doc as IOtherLiabilityEntity).DateDue),
            docType: getEnumDisplayValue(EntityTypeName.DocumentType, doc.DocumentTypeCode),
            name: (doc as IInternalDocumentEntity).Explanation ?? doc.Note,
            sum: formatCurrency(doc.Amount, getCompanyCurrency(context))
        }
    }));

    return <TableWrapper>
        <TableWithAutoSizedColumns
                busy={isLoading}
                rows={rows ?? []}
                columns={columns}
                disableSort={true}
                disableVirtualization={true}
        />
    </TableWrapper>;
};

interface AccountInfo {
    AccountName: string,
    AccountNumber: string,
    AccountBalance: string,
    ChartOfAccountsId: number,
    AccountId: number
}

export interface IFYCloseValidationError {
    AccountInfos: AccountInfo[],
    ErrorParameters: TRecordString,
    ErrorCode: string,
    SectionCode: string
}

const renderAdditionalErrorDataTable = (e: IFYCloseValidationError, fiscalYear: IFiscalYearEntity, context: IAppContext): ReactElement => {
    const accountInfosSortFn = (a: AccountInfo, b: AccountInfo) => {
        if (a?.AccountNumber < b?.AccountNumber) {
            return -1;
        }
        if (a?.AccountNumber > b?.AccountNumber) {
            return 1;
        }
        return 0;
    };

    if (e.AccountInfos?.some(info => info.AccountBalance)) {
        const dateRange: IValueInterval = {
            from: formatDateToDateString(fiscalYear?.DateStart),
            to: formatDateToDateString(fiscalYear?.DateEnd)
        };

        return (
                <AdditionalAccountInfoTable>
                    <div>{i18next.t("FiscalYearClose:Account")}</div>
                    <div>{i18next.t("FiscalYearClose:Balance")}</div>
                    {e.AccountInfos.sort(accountInfosSortFn).map((info, i) => {
                        return <Fragment key={i}>
                            <div>{`${info.AccountNumber} - ${info.AccountName}`}</div>
                            <div>
                                {getDrillDownLink(formatCurrency(info.AccountBalance, getCompanyCurrency(context)), {
                                    route: ROUTE_ACCOUNT_ANALYSIS,
                                    context,
                                    filters: {
                                        [AccountAnalysisProps.accounts]: [info.AccountNumber],
                                        [CommonReportProps.dateRange]: CUSTOM_DATE_RANGE_ID,
                                        [CommonReportProps.dateRangeCustomValue]: dateRange,
                                        [AccountAnalysisProps.closingAccounts]: ClosingAccounts.Show,
                                        [AccountAnalysisProps.sumAmounts]: true
                                    }
                                })}
                            </div>
                        </Fragment>;
                    })}
                </AdditionalAccountInfoTable>
        );
    } else if (e.AccountInfos?.length) {
        return (
                <AccountNamesTableStyled>
                    {e.AccountInfos.sort(accountInfosSortFn).map(info => {
                        return <AccountNameStyled key={info.AccountNumber}>
                            {getDrillDownLink(`${info.AccountNumber} - ${info.AccountName}`, {
                                route: `${ROUTE_CHARTS_OF_ACCOUNTS}/${info.ChartOfAccountsId}/Accounts/${info.AccountId}`,
                                context
                            })}
                        </AccountNameStyled>;
                    })
                    }
                </AccountNamesTableStyled>
        );
    }
    return null;
};

export const validateStep = async (type: FiscalYearCloseSectionTypeCode, fy: IFiscalYearEntity, context: IAppContext): Promise<{
    alert: IAlertProps,
    sections?: FiscalYearCloseSectionTypeCode[]
}> => {
    const res = await customFetch(`${STEP_VALIDATION_URL}/${fy.Id}/${type}`);
    const errors: IFYCloseValidationError[] = await res.json();
    if (errors?.length) {
        return {
            alert: {
                status: Status.Error,
                title: i18next.t(`FiscalYearClose:ImpermeableError`, { count: errors.length }),
                isFullWidth: true,
                subTitle: errors.map((e: IFYCloseValidationError) => {
                    return <>
                        {i18next.t(`Error:${e.ErrorCode}`, e.ErrorParameters)}
                        {renderAdditionalErrorDataTable(e, fy, context)}
                    </>;
                })
            },
            sections: errors.map(e => e.SectionCode) as FiscalYearCloseSectionTypeCode[]
        };
    }

    return {
        alert: null
    };
};

const sortByDate = (doc1: IDocumentEntity, doc2: IDocumentEntity) => {
    return getUtcDayjs(doc1.DateAccountingTransaction ?? doc1.DateCbaDocument).diff(doc2.DateAccountingTransaction ?? doc2.DateCbaDocument);
};

export const getTaxStatementDocs = (fyClose: IFiscalYearCloseEntity): IDocumentEntity[] => {
    const { IncomeTaxPaymentOrReturnDocument, IncomeTaxDocument, AdvanceTaxPayments } = fyClose;
    const documents = [IncomeTaxPaymentOrReturnDocument, IncomeTaxDocument]
            .filter(doc => !!doc?.Id);
    for (const payment of (AdvanceTaxPayments ?? [])) {
        if (!!payment.CreatedDocument?.Id) {
            documents.push(payment.CreatedDocument);
        }
    }
    return documents;
};

const sortedLegalEntityCategory = [
    LegalEntityCategoryCode.Micro,
    LegalEntityCategoryCode.Small,
    LegalEntityCategoryCode.Medium,
    LegalEntityCategoryCode.Large
];

export const getLegalEntityCategoryItems = memoize((): ISelectItem[] => {
    return Object.values(sortedLegalEntityCategory).map((val: string) => {
        return {
            id: val,
            label: i18next.t(`FiscalYearClose:TaxStatement.${val}`)
        };
    });
});

export const fycAlertStyles = { style: { marginBottom: "20px" } };

export const sortedFYCTasks = [
    FiscalYearCloseSectionTypeCode.PayableReceivable,
    FiscalYearCloseSectionTypeCode.Bank,
    FiscalYearCloseSectionTypeCode.CashBox,
    FiscalYearCloseSectionTypeCode.Accrued,
    FiscalYearCloseSectionTypeCode.DeferredPlan,
    FiscalYearCloseSectionTypeCode.Asset,
    FiscalYearCloseSectionTypeCode.ReceivableAdjustments,
    FiscalYearCloseSectionTypeCode.Inventory,
    FiscalYearCloseSectionTypeCode.ForeignCurrencies,
    FiscalYearCloseSectionTypeCode.TaxStatement,
    FiscalYearCloseSectionTypeCode.FinalClosure
];

export const sortedFYCTasksForCba = [
    FiscalYearCloseSectionTypeCode.ExchangeRates,
    FiscalYearCloseSectionTypeCode.PayableReceivable,
    FiscalYearCloseSectionTypeCode.Bank,
    FiscalYearCloseSectionTypeCode.CashBox,
    FiscalYearCloseSectionTypeCode.Asset,
    FiscalYearCloseSectionTypeCode.TaxStatement,
    FiscalYearCloseSectionTypeCode.FinalClosure
];

export const getPaymentScreenCommonColumns = (commonCols: IColumn[], fyNumber: string, hasAccounting: boolean, t: TFunction): IColumn[] => {
    const result = [...commonCols];
    if (hasAccounting) {
        result.push({ id: "balance", label: t("FiscalYearClose:AccountingBalance"), textAlign: TextAlign.Right });
    } else {
        result.push({
            id: "cbaBalance",
            label: t("FiscalYearClose:Balance"),
            info: t("FiscalYearClose:CbaBalanceInfo", { number: fyNumber }),
            textAlign: TextAlign.Right
        });
    }
    return result;
};

/*  IncomeTaxPaymentOrReturnDocument has type Document in metadata, because it is either IOtherReceivableEntity or IOtherLiabilityEntity,
*   and those don't have any other common parent model. Yet we need DateDue which is not present in Document model, and normal expand would fail */
export const loadDateDue = async (doc: (IOtherReceivableEntity | IOtherLiabilityEntity), oData: OData) => {
    if (doc?.Id) {
        const docRes = await oData.getEntitySetWrapper(getEntitySetByDocumentType(doc.DocumentTypeCode as DocumentTypeCode))
                .query(doc.Id)
                .select(OtherLiabilityEntity.DateDue)
                .fetchData<(IOtherReceivableEntity | IOtherLiabilityEntity)>();
        doc.DateDue = docRes.value?.DateDue;
    }
};
