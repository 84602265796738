import React from "react";
import SelectionTableViewBase from "../../../components/smart/SelectionTableViewBase";
import { withBusyIndicator } from "@components/busyIndicator/withBusyIndicator";


class PickSalaryComponentTableView extends SelectionTableViewBase {
    get secondaryTitle(): string {
        return this.props.storage.t("SalaryComponent:SelectionTableView.Title");
    }

    get selectionConfirmText(): string {
        return this.props.storage.t("SalaryComponent:SelectionTableView.Complete");
    }

    get successMessage(): string {
        return this.props.storage.t("SalaryComponent:SelectionTableView.Success");
    }
}

export default withBusyIndicator({ passBusyIndicator: true })(PickSalaryComponentTableView);
