import DateType, { getDateFormat, getUtcDayjs } from "../../../types/Date";
import React from "react";
import { DateIcon } from "../../icon";
import { BasicInputSizes, IconSize } from "../../../enums";
import {
    formatDateInterval,
    formatDateVariants,
    IDayInterval,
    isSameInterval,
    isValidDateInterval,
    longDateFormat,
    parseDateInterval
} from "./utils";
import { withFormatter } from "../input/WithFormatter";
import { getSharedInputProps, IInputProps, InputWithErrorAndTooltip } from "../input";
import { WithErrorAndTooltipProps } from "../formError/WithErrorAndTooltip";
import { IPickerProps } from "./DatePicker";
import { DASH_CHARACTER } from "../../../constants";
import DatePickerBase from "./DatePickerBase";
import { DateRangePickerPopup, IDateRangePickerPopupProps } from "./popup/DateRangePickerPopup";
import { isInterval, PredefinedFilter } from "../../conditionalFilterDialog/ConditionalFilterDialog.utils";
import { WithTranslation, withTranslation } from "react-i18next";
import { capitalize } from "@utils/string";
import memoizeOne from "../../../utils/memoizeOne";
import { TDateRangePickerValue } from "./popup/Calendar.utils";


export const DateRangeInput = withFormatter<IInputProps & WithErrorAndTooltipProps, IDayInterval>({
    parser: (value) => parseDateInterval(value),
    formatter: formatDateInterval,
    isValid: isValidDateInterval
}, InputWithErrorAndTooltip);

interface IProps extends IPickerProps<TDateRangePickerValue>, WithTranslation {
    showRelativeValues?: boolean;
}

class DateRangePicker extends React.Component<IProps> {
    static defaultProps = {
        width: BasicInputSizes.L
    };

    get format(): string {
        const format = DateType.defaultDateFormat ?? this.props.format;

        return longDateFormat(getDateFormat(format));
    }

    parser = (value: string): IDayInterval => {
        const interval = parseDateInterval(value, formatDateVariants(this.format));

        return {
            from: interval.from,
            to: getUtcDayjs(interval.to).endOf("day").toDate()
        };
    };

    get pickerPopup(): React.ComponentType<IDateRangePickerPopupProps> {
        return DateRangePickerPopup;
    }

    get icon(): React.ReactElement {
        return <DateIcon width={IconSize.M}/>;
    }

    get placeholder(): string {
        // TODO should we localize placeholder? How?
        return [this.format, this.format].join(` ${DASH_CHARACTER} `).toLowerCase();
    }

    formatter = (value: TDateRangePickerValue): string => {
        if (!value) {
            return "";
        }

        if (isInterval(value)) {
            return formatDateInterval(value, this.format);
        }

        return capitalize(this.props.t(`Components:ValueHelper.${value}`));
    };

    isSame = (val1: TDateRangePickerValue, val2: TDateRangePickerValue) => {
        const val1IsInterval = isInterval(val1);
        const val2IsInterval = isInterval(val2);

        if (val1IsInterval !== val2IsInterval) {
            return false;
        }

        if (val1IsInterval && val2IsInterval) {
            return isSameInterval(val1, val2);
        }

        return val1 === val2;
    };

    isValid = (val: TDateRangePickerValue): boolean => {
        if (isInterval(val)) {
            return isValidDateInterval(val);
        }

        return Object.values(PredefinedFilter).includes(val);
    };

    getPopupProps = memoizeOne((): Partial<IDateRangePickerPopupProps> => {
        return {
            value: this.props.value,
            showRelativeValues: this.props.showRelativeValues,
            minDate: this.props.minDate,
            maxDate: this.props.maxDate
        };
    }, () => [this.props.value, this.props.showRelativeValues, this.props.minDate, this.props.maxDate]);

    getInputProps = memoizeOne(() => {
        return {
            formatter: this.formatter,
            parser: this.parser,
            isSame: this.isSame,
            isValid: this.isValid
        };
    }, () => [this.formatter, this.parser, this.isSame, this.isValid]);

    render = () => {
        return (
            <DatePickerBase {...getSharedInputProps(this.props)}
                            value={this.props.value}
                            popupProps={this.getPopupProps()}
                            width={this.props.width}
                            onChange={this.props.onChange}
                            onBlur={this.props.onBlur}
                            onFocus={this.props.onFocus}
                            onSelectionChange={this.props.onChange}
                            pickerPopup={this.pickerPopup}
                            inputComponent={DateRangeInput}
                            inputProps={this.getInputProps()}
                            icon={this.icon}
                            placeholder={this.placeholder}
            />
        );
    };
}

const DateRangePickerExtended = withTranslation(["Components"])(DateRangePicker);

export { DateRangePickerExtended as DateRangePicker, DateRangePicker as DateRangePickerClean };