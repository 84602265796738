import { CustomerPortal } from "@pages/home/CustomerPortal.utils";
import { logger } from "@utils/log";
import { handleRedirectResponse } from "@utils/RedirectUtils";

import { changeCompany } from "../contexts/appContext/AppContext.utils";
import { SessionType } from "../contexts/authContext/Auth.utils";
import { ROUTE_LOGIN } from "../routes";
import { ILoginOptions, ISessionTenantData, switchToTenant } from "./Login.utils";
import { handleErrorResponse, setLoginOptions } from "./state/loginSlice";
import { ISessionData, setSessionData } from "./state/sessionSlice";
import store, { AppThunk } from "./state/store";

export async function handleTenantSwitch(session: ISessionData, sessionParams: ISessionTenantData, setRedirect: (url: string) => void, option?: ILoginOptions): Promise<boolean> {
    // todo: setup customer/user session according to selected option
    try {
        const response = await switchToTenant(sessionParams);
        if (response.ok) {
            // update sessionData so that Redirects know what to do,
            // instead of having to manually handle redirect here
            store.dispatch(setSessionData({
                ...session,
                Type: option?.isCustomer ? SessionType.Customer : SessionType.Application,
                TenantId: option?.TenantId ?? session.TenantId
            }));

            return true;
        } else if (response.status === 403) {
            await handleRedirectResponse(response);
        } else if (response.status === 401) {
            setRedirect(ROUTE_LOGIN);
        } else {
            store.dispatch(handleErrorResponse(response));
            logger.error("Failed to select tenant.");
        }
        return false;
    } catch (e) {
        logger.error("Failed to select tenant.", e);
        return false;
    }
}

export const prepareAndSwitchToTenant = (setRedirect: (url: string) => void): AppThunk => async (dispatch, getState) => {
    const { login: { loginOption }, session: { sessionData } } = getState();
    try {
        const sessionParams: ISessionTenantData = {
            TenantId: loginOption.TenantId
        };

        CustomerPortal.isActive = loginOption.isCustomer;
        if (loginOption.isCustomer) {
            // we need to set current companyId also
            await changeCompany(loginOption.CompanyId, true);

            sessionParams.LoginToCustomerPortal = true;
            sessionParams.CompanyId = loginOption.CompanyId;
        }
        await handleTenantSwitch(sessionData, sessionParams, setRedirect, loginOption);

        dispatch(setLoginOptions({ ...loginOption, isResolved: true }));
    } catch(e) {
        // todo: how to handle errors??
    }
};
