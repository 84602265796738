import { AppContext } from "../../../contexts/appContext/AppContext.types";
import { IPrEmploymentTemplateEntity, PrEmploymentTemplateEntity } from "@odata/GeneratedEntityTypes";
import { withPermissionContext } from "../../../contexts/permissionContext/withPermissionContext";
import { formatHeaderValue } from "@components/readOnlyList/ReadOnlyList";
import EmploymentBaseFormView from "./EmploymentBaseFormView";
import { IReadOnlyListItem } from "@components/readOnlyList/ReadOnlyListItem";
import { IFormViewProps } from "../../../views/formView/FormView";
import { IEntity } from "@odata/BindingContext";
import { cloneDeep } from "lodash";
import { onBeforeSave } from "../employment/Employment.utils";

interface IProps extends IFormViewProps<IPrEmploymentTemplateEntity, null> {
}

class EmploymentTemplateFormView extends EmploymentBaseFormView<IProps> {
    static contextType = AppContext;
    static defaultProps = {
        title: "Employment:Template.FormTitle"
    };

    getHeaderData = (): IReadOnlyListItem[] => {
        const { storage } = this.props;
        const { bindingContext, entity } = storage.data;

        return [
            {
                label: storage.getInfo(bindingContext.navigate(PrEmploymentTemplateEntity.Name)).label,
                value: formatHeaderValue(entity.Name)
            }
        ];
    };

    get readOnlyListTitle(): string {
        return this.props.storage.t("Employment:Template.TitleSingular");
    }

    onBeforeSave = (): IEntity => {
        const { storage } = this.props;

        const entity = cloneDeep(storage.data.entity);

        return onBeforeSave(storage, entity);
    };
}

export default withPermissionContext(EmploymentTemplateFormView);