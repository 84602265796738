import styled from "styled-components/macro";
import { getFocusBorderElement, T_PLAIN_small } from "../../global.style";

export const StyledPaginator = styled.div`
    display: flex;
    align-items: center;

    & > :first-child, & > :nth-last-child(2) {
        margin-right: 4px;
    }

    & > :not(:first-child):not(:last-child):not(:nth-last-child(2)) {
        margin-right: 6px;
    }

    position: relative;
    ${getFocusBorderElement({ offset: 0, keyboardOnly: true })}
`;

export const EllipsisWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    color: ${props => props.theme.C_ACT_main};
    ${T_PLAIN_small};
`;