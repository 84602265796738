import React from "react";
import { filterMenu } from "../../menu-def";
import { GroupItemTitle, GroupTitleWrapper, StyledGroup, StyledGroupTitle } from "../main/SettingsMenu.style";
import TestIds from "../../testIds";
import { Link } from "react-router-dom";
import { WithTranslation, withTranslation } from "react-i18next";
import { AppContext, ContextEvents } from "../../contexts/appContext/AppContext.types";
import { WithPermissionContext, withPermissionContext } from "../../contexts/permissionContext/withPermissionContext";
import { IMenuGroup, IMenuItem } from "@components/navigation";
import { getValue } from "@utils/general";
import { addCompanyIdToUrl } from "../../contexts/appContext/AppContext.utils";

export interface IMenuProps extends WithTranslation, WithPermissionContext {
    definition: IMenuGroup[];
    onItemClick?: () => void;
    dontSetItemAsSelectedMenu?: boolean;
    isItemSelected?: (key: string) => boolean;
}

class Menu extends React.Component<IMenuProps> {
    static contextType = AppContext;
    //sadly, breaks typescript type checking
    //context: React.ContextType<typeof AppContext>;

    ignoreNextMenuOrBreadcrumbChange = false;

    componentDidMount() {
        this.context.eventEmitter.on(ContextEvents.SelectedMenuChanged, this.handleMenuOrBreadcrumbChange);
        this.context.eventEmitter.on(ContextEvents.ViewBreadcrumbsChanged, this.handleMenuOrBreadcrumbChange);
    }

    componentWillUnmount() {
        this.context.eventEmitter.off(ContextEvents.SelectedMenuChanged, this.handleMenuOrBreadcrumbChange);
        this.context.eventEmitter.off(ContextEvents.ViewBreadcrumbsChanged, this.handleMenuOrBreadcrumbChange);
    }

    handleMenuOrBreadcrumbChange = () => {
        if (!this.ignoreNextMenuOrBreadcrumbChange) {
            this.forceUpdate();
        }

        this.ignoreNextMenuOrBreadcrumbChange = false;
    };

    setItem = (item: IMenuItem, group: IMenuGroup, e: React.MouseEvent) => {
        // don't setItem when ctrlKey was pressed
        // => it means user opened the link in another tab, not this one
        if (!this.props.dontSetItemAsSelectedMenu && !e?.ctrlKey) {
            // don't rerender second time, when the change is caused from here
            this.ignoreNextMenuOrBreadcrumbChange = true;
            this.context.setSelectedMenu({
                group: group,
                item
            });

            this.ignoreNextMenuOrBreadcrumbChange = true;
            this.context.setViewBreadcrumbs({ items: [], lockable: false });
        }

        this.props.onItemClick?.();
    };

    isSelected = (key: string) => {
        return this.props.isItemSelected?.(key) ?? this.context.getSelectedMenu()?.item?.key === key;
    };

    render() {
        const groups = filterMenu(this.props.definition, this.context, this.props.permissionContext);

        return groups.filter(group => group.items?.length).map((group: IMenuGroup, index: number) => {
            return (
                    <StyledGroup key={index}
                                 data-testid={TestIds.ShellbarContentGroup}>
                        <StyledGroupTitle data-testid={TestIds.ShellbarContentGroupTitle}>
                            {this.props.t(`Common:${group.title}`)}
                        </StyledGroupTitle>
                        {group.items.map((item: IMenuItem, index: number) => {
                            return (
                                    <GroupTitleWrapper key={index}>
                                        <GroupItemTitle selected={this.isSelected(item.key)}
                                                data-testid={TestIds.ShellbarContentGroupItem}>
                                    <Link to={addCompanyIdToUrl(item.url, this.context)}
                                          onClick={this.setItem.bind(this, item, group)}>
                                        {this.props.t(`Common:${getValue(item.title)}`)}
                                    </Link>
                                </GroupItemTitle>
                            </GroupTitleWrapper>
                        );
                    })}
                </StyledGroup>
            );
        });
    }
}

export default withPermissionContext(withTranslation(["Common"])(Menu));
