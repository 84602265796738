import { WebSocketMessageTypeCode } from "@odata/GeneratedEnums";
import {
    IBackgroundJobWebsocketMessage,
    INotificationWebsocketMessage,
    ISessionInvalidatedWebsocketMessage,
    TWebsocketMessage
} from "./Websocket.types";

export type {
    IBackgroundJobWebsocketMessage,
    INotificationWebsocketMessage,
    ISessionInvalidatedWebsocketMessage,
    TWebsocketMessage
};

export const WS_URL = `${window.location.protocol === "https:" ? "wss" : "ws"}://${window.location.host}/api/ws/Notification`;

export const isNotificationWebsocketMessage = (message: TWebsocketMessage): message is INotificationWebsocketMessage => {
    return message.WebSocketMessageType === WebSocketMessageTypeCode.Notification;
};

export const isBackgroundJobWebsocketMessage = (message: TWebsocketMessage): message is IBackgroundJobWebsocketMessage => {
    return message.WebSocketMessageType === WebSocketMessageTypeCode.BackgroundJob;
};

export const isSessionInvalidatedWebsocketMessage = (message: TWebsocketMessage): message is ISessionInvalidatedWebsocketMessage => {
    return message.WebSocketMessageType === WebSocketMessageTypeCode.SessionInvalidated;
};

export const isSessionTerminatedWebsocketMessage = (message: TWebsocketMessage): message is ISessionInvalidatedWebsocketMessage => {
    return message.WebSocketMessageType === WebSocketMessageTypeCode.SessionInvalidated;
};