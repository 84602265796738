import styled, { css } from "styled-components/macro";
import { T_TITLE_4_NORMAL, textAlignToJustify } from "../../global.style";
import { TextAlign } from "../../enums";
import { ellipsis } from "polished";

export const StyledSimpleGridTable = styled.div<{ numColumns: number; $width?: string; $height?: string; }>`
    display: grid;
    grid-template-columns: ${props => [...Array(props.numColumns)].map((_, index) => index < props.numColumns - 1 ? "fit-content(300px)" : "auto").join(" ")};
    column-gap: 20px;
    row-gap: 7px;
    padding: 0 5px;
    width: ${props => props.$width ?? null};
    height: ${props => props.$height ?? null};
    ${T_TITLE_4_NORMAL};
`;

export const SimpleGridTableColumn = styled.div<{ textAlign?: TextAlign }>`
    justify-self: ${props => textAlignToJustify(props.textAlign ?? TextAlign.Left)};
    ${ellipsis("100%")};
`;

export const SimpleGridTableLine = styled.div<{ spanColumns: number }>`
    grid-column: 1 / span ${props => props.spanColumns};
    position: relative;
    left: -5px;
    width: calc(100% + 10px);
    height: 1px;
    background-color: ${props => props.theme.C_FORM_GROUP_HEADER_LINE};
`;

const SmallAmountStyles = css`
    font-weight: 300;
`;

export const SimpleGridTableSmallValue = styled(SimpleGridTableColumn)`
    ${SmallAmountStyles};
`;

const FullAmountStyles = css`
    font-size: 20px;
    font-weight: bold;
`;

export const SimpleGridTableBoldValue = styled(SimpleGridTableColumn)`
    ${FullAmountStyles};
`;