import { IAppContext } from "../../contexts/appContext/AppContext.types";
import { CountryCode } from "@odata/GeneratedEnums";

export const companyIsSecondaryAddressPath = "##IsSecondaryAddress##";

/**
 * Returns current agenda country code with optional fallback value
 * @param context
 * @param defVal
 */
export function getAgendaCountryCode(context: IAppContext, defVal = CountryCode.CzechRepublic): CountryCode {
    return (context.getCompany()?.Accounting?.CountryCode ?? defVal) as CountryCode;
}
