import { clamp, getValue, handleRefHandlers, isDefined } from "@utils/general";
import React, { MouseEventHandler } from "react";

import { WithDomManipulator, withDomManipulator } from "../../contexts/domManipulator/withDomManipulator";
import { IconSize, MouseButton, RowType, TextAlign } from "../../enums";
import { KeyName } from "../../keyName";
import TestIds from "../../testIds";
import { WithBusyIndicator, withBusyIndicator } from "../busyIndicator/withBusyIndicator";
import FocusManager, { FocusDirection, IFocusableItemProps } from "../focusManager/FocusManager";
import { ArrowIcon, TableCaretIcon } from "../icon";
import { ScrollBar } from "../scrollBar";
import { getRowFromArray } from "../smart/smartTable/SmartTable.utils";
import { IColumn, IRow, ISticky, TId } from "../table";
import { isRowValueCellValueObject } from "../table/TableUtils";
import { StatusIndicator } from "../tabs/Tabs.styles";
import Tooltip from "../tooltip";
import {
    ContentAfterRow,
    DashedResizeLine,
    DraggableArea,
    DraggableCircle,
    ScrollWrapper,
    SIMPLE_TABLE_ROW_HEIGHT,
    SimpleGroupIconWrapper,
    SimpleHeaderCell,
    SimpleRowCell,
    SimpleRowCellContentWrapper,
    StyledRowOrderCell,
    StyledSelectedDiv,
    StyledSimpleTable
} from "./SimpleTable.styles";

const GROUP_L_PAD = 18;

export interface ISimpleTableRow extends IRow {
    /** colSpan will be propagated to <td> elements as "colspan"
     * key should be column id and number is how many columns it should span across */
    colSpan?: Record<string, number>;
    /** To be used with colSpan, custom column textAlign overwrite for just one row */
    textAlign?: Record<string, TextAlign>;
    isUnderlined?: boolean;
}

export interface ISimpleTableProps extends WithBusyIndicator {
    // isUnderlined adds subtle line under the row
    rows: ISimpleTableRow[];
    // add maxWidth to columns, to prevent table from getting too big
    columns: (IColumn & {
        maxWidth?: number;
    })[];
    width?: string;
    passRef?: React.Ref<HTMLDivElement>;
    hasBigFont?: boolean;
    showHeaderBorder?: boolean;
    highlightRowsHover?: boolean;
    disableFocus?: boolean;
    // can be used to add action after the row
    contentAfter?: (row: IRow, index: number) => React.ReactElement;
    useHorizontalScrollbar?: boolean;
    onGroupToggle?: (id: TId) => void;
    onRowSelect?: (id: TId, linePosition: number) => void;
    showOrder?: boolean;
    selectedHighLightType?: SelectedHighLightType;
    onSplitLineMove?: (e: MouseEvent) => void;
    onSplitLineMoveEnd?: (lineIndex: number) => void;
    splitLinePosition?: number;
    style?: React.CSSProperties;
}

export enum SelectedHighLightType {
    Underline = "underline",
    Bold = "bold"
}

interface IState {
    hasGroups: boolean;
    /** Hybrid approach to allow groups toggling, when onGroupToggle isn't used on parent */
    openedGroups: Record<string, boolean>;
    columnsStickyVals: Record<string, ISticky>;
    linePosition: number;
}

class SimpleTable extends React.PureComponent<ISimpleTableProps & WithDomManipulator, IState> {
    static defaultProps = {
        showHeaderBorder: true,
        highlightRowsHover: false
    };

    _tableRef = React.createRef<HTMLTableElement>();
    _contentAfter = React.createRef<HTMLDivElement>();
    _scrollRef = React.createRef<HTMLDivElement>();
    _splitLineRef = React.createRef<HTMLDivElement>();


    state: IState = {
        hasGroups: false,
        openedGroups: {},
        columnsStickyVals: {},
        linePosition: 2
    };

    componentDidMount() {
        this.checkGroups();
        this.applyStickyColumns();
        this.updateSimpleBarWidth();
        if (this.props.splitLinePosition) {
            this.setDefaultLinePosition();
        }
    }

    componentDidUpdate(prevProps: Readonly<ISimpleTableProps & WithDomManipulator>, prevState: Readonly<IState>) {
        if (prevProps.rows !== this.props.rows) {
            this.checkGroups();
        }
        if (prevProps.columns !== this.props.columns) {
            this.updateSimpleBarWidth();
        }

        if (this.props.splitLinePosition !== prevProps.splitLinePosition) {
            this.setDefaultLinePosition();
        }
    }

    setDefaultLinePosition = () => {
        const lineDiv = this._splitLineRef.current;
        const linePosition = this.props.splitLinePosition + 1; // add one for Excel like header
        if (lineDiv) {
            lineDiv.style.top = `${(linePosition) * SIMPLE_TABLE_ROW_HEIGHT}px`;
        }
        this.setState({ linePosition });

    };

    elementDrag = (e: MouseEvent) => {
        e.preventDefault();
        const lineDiv = this._splitLineRef.current;
        const parentClientRect = this._splitLineRef.current.parentElement.getBoundingClientRect();
        const posY = e.clientY - parentClientRect.top;

        lineDiv.style.top = clamp(posY, 0, parentClientRect.height) + "px";

        this.props.onSplitLineMove?.(e);
    };

    closeDragElement = () => {
        const lineDiv = this._splitLineRef.current;
        const minPos = (this.props.rows?.findIndex(row => row.selected) + 2) * SIMPLE_TABLE_ROW_HEIGHT;
        const maxPos = this.props.rows.length * SIMPLE_TABLE_ROW_HEIGHT;
        const newPos = Math.min(Math.max(lineDiv.offsetTop - (lineDiv.offsetTop % SIMPLE_TABLE_ROW_HEIGHT), minPos), maxPos);
        lineDiv.style.top = `${newPos - 1}px`;
        /* stop moving when mouse button is released:*/
        document.onmouseup = null;
        document.onmousemove = null;

        const linePosition = Math.floor((parseInt(lineDiv.style.top) + 1) / SIMPLE_TABLE_ROW_HEIGHT);
        this.props.onSplitLineMoveEnd?.(linePosition - 1); // we want position without header here, therefore -1
        this.setState({ linePosition });
    };

    dragCircleMouseDown: MouseEventHandler<HTMLDivElement> = (e: React.MouseEvent) => {
        if (e.button === MouseButton.MainButton) {
            e.preventDefault();
            document.onmouseup = this.closeDragElement;
            document.onmousemove = this.elementDrag;
        }
    };

    updateSimpleBarWidth = () => {
        // once again, SimpleBar doesn't work well enough as browser default scroll bar
        // we want it to take width of its content => do it manually
        if (this.props.contentAfter && this._scrollRef.current) {
            this.props.domManipulatorOrchestrator.registerCallback(
                    () => {
                        const tableWrapper = this._scrollRef.current.children[0].children[0].children[0] as HTMLDivElement;

                        return {
                            tableWrapperWidth: tableWrapper.clientWidth,
                            contentAfterWidth: this._contentAfter.current.clientWidth
                        };
                    },
                    ({ tableWrapperWidth, contentAfterWidth }) => {
                        const simpleBarRootDiv = this._scrollRef.current.parentElement.parentElement.parentElement.parentElement as HTMLDivElement;


                        simpleBarRootDiv.style.width = `${tableWrapperWidth}px`;
                        simpleBarRootDiv.style.maxWidth = `calc(100% - ${contentAfterWidth}px)`;
                    },
                    [this._scrollRef, this._contentAfter]
            );
        }
    };

    checkGroups = () => {
        let hasGroups = false;

        for (const row of this.props.rows) {
            if (row?.type === RowType.Group) {
                hasGroups = true;
            }
        }
        this.setState({
            hasGroups
        });
    };

    applyStickyColumns = () => {
        if (!this.props.columns.find(col => col.isSticky)) {
            return;
        }

        const columnsStickyVals: Record<string, ISticky> = {};
        const headerCols = (this._tableRef.current.firstChild.firstChild as HTMLTableRowElement).children;

        for (let i = 0; i < this.props.columns.length; i++) {
            const column = this.props.columns[i];
            let left = 0;
            let right = 0;

            if (column.isSticky) {
                for (let j = 0; j < this.props.columns.length; j++) {
                    if (i === j || !this.props.columns[j].isSticky) {
                        continue;
                    }

                    const otherHeaderCol = headerCols[j] as HTMLTableHeaderCellElement;

                    if (j < i) {
                        left += otherHeaderCol.getBoundingClientRect().width;
                    } else {
                        right += otherHeaderCol.getBoundingClientRect().width;
                    }
                }

                columnsStickyVals[column.id] = {
                    left,
                    right
                };
            }
        }

        this.setState({
            columnsStickyVals
        });
    };

    handleGroupToggle = (rowId: TId) => {
        if (this.props.onGroupToggle) {
            this.props.onGroupToggle(rowId);
        } else {
            const stringId = rowId.toString();
            const newState = isDefined(this.state.openedGroups[stringId]) ? !this.state.openedGroups[stringId] : !getRowFromArray(this.props.rows, rowId).open;

            this.setState({
                openedGroups: {
                    ...this.state.openedGroups,
                    [stringId]: newState
                }
            });
        }
    };

    getGroupOpenState = (row: ISimpleTableRow) => {
        if (this.props.onGroupToggle) {
            return row.open;
        } else {
            const openedState = this.state.openedGroups[row.id.toString()];

            return isDefined(openedState) ? openedState : row.open;
        }
    };

    renderHeader = () => {
        return (
                <tr>
                    {this.props.showOrder && <th>&nbsp;</th>}
                    {this.props.columns.map((column, index) => (
                            <SimpleHeaderCell key={column.id}
                                              data-testid={TestIds.TableHeaderCell}
                                              maxWidth={column.maxWidth}
                                              textAlign={column.textAlign}
                                              isGrey={column.hasGreyHeader}
                                              hasBigFont={this.props.hasBigFont}
                                              hasBottomLine={this.props.showHeaderBorder && !column.ignoreDividers}
                                              isFirst={index === 0}
                                              isLast={index === this.props.columns.length - 1}
                                              hasAfterContent={!!this.props.contentAfter}
                                              sticky={this.state.columnsStickyVals[column.id]}>
                                {column.label}
                            </SimpleHeaderCell>
                    ))}
                </tr>
        );
    };

    renderRow = (row: ISimpleTableRow, focusProps: IFocusableItemProps, path: string, level = 0): React.ReactElement => {
        const isOpen = !!row && this.getGroupOpenState(row);
        const statusIcon = row?.statusHighlight && (<StatusIndicator status={row.statusHighlight}/>);
        let rowContent;
        const rowNumber = this.props.rows.findIndex(r => r && r?.id === row?.id) + 1;

        if (row?.type === RowType.Merged) {
            rowContent = (
                    <SimpleRowCell isDivider={row.isDivider}
                                   colSpan={this.props.columns.length}
                                   isDisabled={row.isDisabled}
                                   hasBigFont={this.props.hasBigFont}
                                   textAlign={TextAlign.Center}
                                   data-testid={TestIds.TableCell}>
                        {row.content}
                    </SimpleRowCell>
            );
        } else {
            let currentColSpan = 0;

            rowContent = row && this.props.columns.map((column, i) => {
                currentColSpan -= 1;

                if (currentColSpan > 0) {
                    return null;
                }

                const colSpan = row.colSpan?.[column.id];
                currentColSpan = colSpan;

                const value = getValue(row.values[column.id]);
                const isObject = isRowValueCellValueObject(value);
                let cellContent: React.ReactNode = isObject ? value.value : value;
                const tooltip = isObject ? value.tooltip : value;
                const hasGroupPadding = (level > 0 || this.state.hasGroups);

                if (hasGroupPadding && i === 0) {
                    cellContent = (
                            <SimpleRowCellContentWrapper _lpad={(level) * GROUP_L_PAD}>
                                {row.type === RowType.Group &&
                                        <SimpleGroupIconWrapper
                                                isOpen={isOpen}
                                                onClick={() => this.handleGroupToggle(row.id)}>
                                            <TableCaretIcon width={IconSize.XS} height={IconSize.XS}/>
                                        </SimpleGroupIconWrapper>
                                }
                                {cellContent}
                            </SimpleRowCellContentWrapper>
                    );
                }

                return (
                        <Tooltip content={tooltip} key={column.id} onlyShowWhenChildrenOverflowing>
                            {(ref) => {
                                const hasPinkBg = !!(this.props.onSplitLineMoveEnd && rowNumber >= this.state.linePosition);
                                return (
                                        <SimpleRowCell key={column.id}
                                                       ref={ref}
                                                       isBold={[RowType.Aggregation, RowType.Group].includes(row.type) || column.isBold}
                                                       isDivider={row.isDivider}
                                                       maxWidth={column.maxWidth}
                                                       hasTopLine={[RowType.Aggregation].includes(row.type) && !column.ignoreDividers}
                                                       hasBottomLine={row.isUnderlined}
                                                       hasGroupPadding={hasGroupPadding}
                                                       isDisabled={row.isDisabled}
                                                       hasBigFont={this.props.hasBigFont}
                                                       hasSplitLine={!!this.props.onSplitLineMoveEnd}
                                                       hasPinkBg={hasPinkBg}
                                                       selectedHighLightType={this.props.selectedHighLightType}
                                                       textAlign={row.textAlign?.[column.id] ?? column.textAlign}
                                                       isSelected={row.selected && !column.ignoreDividers}
                                                       aria-selected={hasPinkBg}
                                                       isFirst={i === 0}
                                                       isLast={i === this.props.columns.length - 1}
                                                       hasAfterContent={!!this.props.contentAfter}
                                                       sticky={this.state.columnsStickyVals[column.id]}
                                                       colSpan={colSpan}
                                                       data-testid={TestIds.TableCell}>
                                            {i === 0 && statusIcon}
                                            {cellContent}
                                        </SimpleRowCell>
                                );
                            }}
                        </Tooltip>
                );
            });
        }

        return (
                <React.Fragment key={row?.id.toString() ?? path}>
                    <tr {...(this.props.disableFocus ? {} : focusProps)}
                        onKeyDown={(e) => e.key === KeyName.Enter && this.props.onRowSelect?.(row.id, this.state.linePosition)}
                        onClick={() => this.props.onRowSelect?.(row.id, this.state.linePosition)}
                        data-testid={TestIds.TableRow}>
                        {this.props.showOrder && <StyledRowOrderCell
                                isSelectable={rowNumber < this.state.linePosition}
                                isSelected={row.selected}>
                            {!row.selected && rowNumber}
                            {row.selected && <StyledSelectedDiv>
                                {rowNumber}
                                <ArrowIcon width={IconSize.S} height={IconSize.S}/>
                            </StyledSelectedDiv>}
                        </StyledRowOrderCell>}
                        {rowContent}
                    </tr>
                    {isOpen && row.rows && row.rows.map((childRow, i) => this.renderRow(childRow, focusProps, `${path}-${i}`, level + 1))}
                </React.Fragment>
        );
    };

    renderRows = (rowFocusProps: IFocusableItemProps) => {
        return (
                <tbody>
                {this.props.rows.map((row, i) => this.renderRow(row, rowFocusProps, i.toString()))}
                </tbody>
        );
    };

    renderContentAfter = () => {
        if (!this.props.contentAfter) {
            return null;
        }

        return (
                <div ref={this._contentAfter}>
                    <ContentAfterRow/>
                    {this.props.rows.map((row, index) => {
                        return (
                                <ContentAfterRow key={row.id.toString()} data-testid={TestIds.ContentAfterRow}>
                                    {this.props.contentAfter(row, index)}
                                </ContentAfterRow>
                        );
                    })}
                </div>
        );
    };

    handleTableRef = (ref: HTMLDivElement, wrapperRef: React.RefObject<HTMLElement>) => {
        handleRefHandlers(ref, this.props.passRef, this._tableRef, wrapperRef);
    };

    render() {
        return (
                <FocusManager direction={FocusDirection.Vertical} isDisabled={this.props.disableFocus}>
                    {({ itemProps, wrapperProps }) => {
                        let result = (
                                <div style={{ position: "relative", display: "flex", width: "fit-content" }}>
                                    {/*todo better solution how to have busyIndicator (which have by default 100% width)*/}
                                    {/*but that can't be inside <table>*/}
                                    {/*without arbitrary div*/}
                                    {this.props.busyIndicator}
                                    {this.props.onSplitLineMoveEnd &&
                                            <DashedResizeLine ref={this._splitLineRef}
                                                              onMouseDown={this.dragCircleMouseDown}
                                                              data-testid={TestIds.DashedResizeLine}>
                                                <DraggableCircle/>
                                                <DraggableArea/>
                                            </DashedResizeLine>}
                                    <StyledSimpleTable _width={this.props.width}
                                                       _hasHighlight={this.props.highlightRowsHover}
                                                       data-testid={TestIds.SimpleTable}
                                                       style={this.props.style}
                                                       {...(this.props.disableFocus ? {} : wrapperProps as any)}

                                                       ref={(ref: HTMLDivElement) => {
                                                           this.handleTableRef(ref, wrapperProps.ref);
                                                       }}>
                                        <thead data-testid={TestIds.TableHeader}>
                                        {this.renderHeader()}
                                        </thead>
                                        {this.renderRows(itemProps)}
                                    </StyledSimpleTable>
                                </div>
                        );

                        // only render scrollbar if afterContent is used, so that we don't always have to deal with the problems it causes
                        if (this.props.contentAfter || this.props.useHorizontalScrollbar) {
                            result = (
                                    <ScrollWrapper>
                                        <ScrollBar
                                                scrollableNodeProps={{
                                                    ref: this._scrollRef
                                                }}
                                        >
                                            {result}
                                        </ScrollBar>
                                        {this.props.contentAfter ? this.renderContentAfter() : null}
                                    </ScrollWrapper>
                            );
                        }

                        return result;
                    }}
                </FocusManager>
        );
    }
}

export default withDomManipulator(withBusyIndicator({ passBusyIndicator: true })(SimpleTable));