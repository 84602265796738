import React from "react";
import TodoListItem, { ITodoListItemProps } from "./TodoListItem";
import { StyledTodoList } from "./TodoList.styles";
import { ITodoListItem } from "./TodoList.utils";

interface IProps extends Pick<ITodoListItemProps, "onNavigate" | "onComplete" | "onEditEnd" | "onAnimationEnd"> {
    items: ITodoListItem[];
    active: string;
    isDisabled?: boolean;
    toBeRemoved: string[];
}

const TodoList: React.FC<IProps> = ({ items, active, toBeRemoved, ...passProps }) => {
    return (
        <StyledTodoList>
            {items?.map(task => (
                <TodoListItem id={task.TemporaryGuid}
                              key={task.TemporaryGuid}
                              isActive={task.TemporaryGuid === active}
                              removeAnimationDelay={toBeRemoved?.includes(task.TemporaryGuid) ? 3 : null}
                              isCompleted={!!task.DateCompleted}
                              text={task.Description}
                              {...passProps} />
            ))}
        </StyledTodoList>
    );
};

export default TodoList;