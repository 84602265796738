import { uuidv4 } from "@utils/general";
import { capitalize } from "@utils/string";
import { OpUnitType, QUnitType } from "dayjs";
import i18next from "i18next";

import { ValueType } from "../../enums";
import { TValue } from "../../global.types";
import { getUtcDayjs } from "../../types/Date";

export enum ConditionType {
    Excluded = "Excluded",
    Included = "Included"
}

export enum Condition {
    Equals = "Equals",
    Between = "Between",
    IsBefore = "IsBefore",
    IsAfter = "IsAfter",
    IsBeforeOrEqualsTo = "IsBeforeOrEqualsTo",
    IsAfterOrEqualsTo = "IsAfterOrEqualsTo",
    GreaterThan = "GreaterThan",
    LesserThan = "LesserThan",
    GreaterOrEqual = "GreaterOrEqual",
    LesserOrEqual = "LesserOrEqual",
    Contains = "Contains",
    BeginsWith = "BeginsWith",
    EndsWith = "EndsWith"
}

export enum PredefinedFilter {
    Value = "Value",
    Today = "Today",
    Yesterday = "Yesterday",
    ThisWeek = "ThisWeek",
    LastWeek = "LastWeek",
    ThisMonth = "ThisMonth",
    LastMonth = "LastMonth",
    ThisQuarter = "ThisQuarter",
    LastQuarter = "LastQuarter",
    ThisFiscalYear = "ThisFiscalYear",
    LastFiscalYear = "LastFiscalYear",
    ThisYear = "ThisYear",
    LastYear = "LastYear",
    YearToDate = "YearToDate"
}

export interface IValueInterval<T = TValue> {
    from: T;
    to: T;
}

export type TValueHelperValue = IComplexFilter | string;
export type TFilterValue = Exclude<TValue, TValueHelperValue[]> | IValueInterval | string[];

export interface IComplexFilter<T extends TFilterValue = TValue> {
    id: string;
    type: ConditionType,
    condition: Condition,
    filter: PredefinedFilter,
    value: T,
}

export const ValueTypeToCondition: Record<ValueType, Condition[]> = {
    [ValueType.Date]: [
        Condition.Equals,
        Condition.Between,
        Condition.IsAfter, Condition.IsAfterOrEqualsTo,
        Condition.IsBefore, Condition.IsBeforeOrEqualsTo
    ],
    [ValueType.Number]: [
        Condition.Equals,
        Condition.GreaterThan, Condition.GreaterOrEqual,
        Condition.LesserThan, Condition.LesserOrEqual,
        Condition.Between
    ],
    [ValueType.String]: [
        Condition.Equals,
        Condition.Contains,
        Condition.BeginsWith,
        Condition.EndsWith
    ],
    [ValueType.Boolean]: [] // Boolean filter doesn't have condition dialog
};

type TConditionToPredefinedFilter = Partial<Record<Condition, PredefinedFilter[]>>;
export const ValueTypeAndConditionToPredefinedFilter: Partial<Record<ValueType, TConditionToPredefinedFilter>> = {
    [ValueType.Date]: {
        [Condition.Equals]: [
            PredefinedFilter.Today,
            PredefinedFilter.Yesterday,
            PredefinedFilter.ThisWeek,
            PredefinedFilter.LastWeek,
            PredefinedFilter.ThisMonth,
            PredefinedFilter.LastMonth,
            PredefinedFilter.ThisQuarter,
            PredefinedFilter.LastQuarter,
            PredefinedFilter.ThisYear,
            PredefinedFilter.LastYear,
            PredefinedFilter.YearToDate
        ],
        [Condition.IsAfter]: [
            PredefinedFilter.Today,
            PredefinedFilter.Yesterday,
            PredefinedFilter.ThisWeek,
            PredefinedFilter.LastWeek,
            PredefinedFilter.ThisMonth,
            PredefinedFilter.LastMonth,
            PredefinedFilter.ThisQuarter,
            PredefinedFilter.LastQuarter,
            PredefinedFilter.ThisYear,
            PredefinedFilter.LastYear
        ],
        [Condition.IsAfterOrEqualsTo]: [
            PredefinedFilter.Today,
            PredefinedFilter.Yesterday,
            PredefinedFilter.ThisWeek,
            PredefinedFilter.LastWeek,
            PredefinedFilter.ThisMonth,
            PredefinedFilter.LastMonth,
            PredefinedFilter.ThisQuarter,
            PredefinedFilter.LastQuarter,
            PredefinedFilter.ThisYear,
            PredefinedFilter.LastYear
        ],
        [Condition.IsBefore]: [
            PredefinedFilter.Today,
            PredefinedFilter.Yesterday,
            PredefinedFilter.ThisWeek,
            PredefinedFilter.LastWeek,
            PredefinedFilter.ThisMonth,
            PredefinedFilter.LastMonth,
            PredefinedFilter.ThisQuarter,
            PredefinedFilter.LastQuarter,
            PredefinedFilter.ThisYear,
            PredefinedFilter.LastYear
        ],
        [Condition.IsBeforeOrEqualsTo]: [
            PredefinedFilter.Today,
            PredefinedFilter.Yesterday,
            PredefinedFilter.ThisWeek,
            PredefinedFilter.LastWeek,
            PredefinedFilter.ThisMonth,
            PredefinedFilter.LastMonth,
            PredefinedFilter.ThisQuarter,
            PredefinedFilter.LastQuarter,
            PredefinedFilter.ThisYear,
            PredefinedFilter.LastYear
        ]
    }
};

export function createFilterRow(data?: Partial<IComplexFilter>, valueType?: ValueType): IComplexFilter {
    const row = {
        id: data.id ?? uuidv4(),
        type: data.type ?? ConditionType.Included,
        condition: data.condition ?? Condition.Equals,
        filter: data.filter ?? PredefinedFilter.Value,
        value: data.value
    };
    if (row.value === undefined) {
        row.value = defaultValue(row, valueType);
    }

    return row;
}

export function defaultValue(row: IComplexFilter, valueType: ValueType): TFilterValue {
    const defaultValue = (valueType === ValueType.Number || valueType === ValueType.Date ? null : "");
    if (row.condition === Condition.Between) {
        return { from: defaultValue, to: defaultValue };
    }
    return defaultValue;
}

export function getComplexFilterValue(row: IComplexFilter): TFilterValue {
    const _relativeDate = (what: OpUnitType | QUnitType, add = 0, method: "startOf" | "endOf" = "startOf"): Date => {
        let d = getUtcDayjs();
        if (add) {
            d = d.add(add, what as any);
        }
        return d[method](what as any).toDate();
    };

    switch (row.filter) {
        case PredefinedFilter.Today:
            return _relativeDate("day");
        case PredefinedFilter.Yesterday:
            return _relativeDate("day", -1);
        case PredefinedFilter.ThisWeek:
            return {
                from: _relativeDate("week"),
                to: _relativeDate("week", 0, "endOf")
            };
        case PredefinedFilter.LastWeek:
            return {
                from: _relativeDate("week", -1),
                to: _relativeDate("week", -1, "endOf")
            };
        case PredefinedFilter.ThisMonth:
            return {
                from: _relativeDate("month"),
                to: _relativeDate("month", 0, "endOf")
            };
        case PredefinedFilter.LastMonth:
            return {
                from: _relativeDate("month", -1),
                to: _relativeDate("month", -1, "endOf")
            };
        case PredefinedFilter.ThisQuarter:
            return {
                from: _relativeDate("quarter"),
                to: _relativeDate("quarter", 0, "endOf")
            };
        case PredefinedFilter.LastQuarter:
            return {
                from: _relativeDate("quarter" as OpUnitType, -1),
                to: _relativeDate("quarter" as OpUnitType, -1, "endOf")
            };
        case PredefinedFilter.ThisYear:
            return {
                from: _relativeDate("year"),
                to: _relativeDate("year", 0, "endOf")
            };
        case PredefinedFilter.LastYear:
            return {
                from: _relativeDate("year", -1),
                to: _relativeDate("year", -1, "endOf")
            };
        case PredefinedFilter.YearToDate:
            return {
                from: _relativeDate("year"),
                to: _relativeDate("day", 0, "endOf")
            };
        default:
            return row.value;
    }
}

export function isInterval(value: unknown): value is IValueInterval {
    return !!(typeof value === "object" && value?.hasOwnProperty("from") && value?.hasOwnProperty("to"));
}

export function isComplexFilter(value: unknown): value is IComplexFilter {
    return !!(typeof value === "object" && value?.hasOwnProperty("type") && value?.hasOwnProperty("condition"));
}

export function isComplexFilterArr<T = IComplexFilter[]>(value: unknown): value is T {
    return Array.isArray(value) && value?.length && isComplexFilter(value[0]);
}


function formatInterval(interval: IValueInterval) {
    return `${interval.from} - ${interval.to}`;
}

function formatEqualsValue(value: TFilterValue) {
    return Array.isArray(value) ? value.join(", ") : value as string;
}

export function formatFilterDisplayCondition(condition: Condition, value: TFilterValue): string {
    switch (condition) {
        case Condition.Between:
            return formatInterval(value as IValueInterval);
        case Condition.IsBefore:
        case Condition.LesserThan:
            return `<${value}`;
        case Condition.IsAfter:
        case Condition.GreaterThan:
            return `>${value}`;
        case Condition.IsBeforeOrEqualsTo:
        case Condition.LesserOrEqual:
            return `≤${value}`;
        case Condition.IsAfterOrEqualsTo:
        case Condition.GreaterOrEqual:
            return `≥${value}`;
        case Condition.Contains:
            return `*${value}*`;
        case Condition.BeginsWith:
            return `${value}*`;
        case Condition.EndsWith:
            return `*${value}`;
        default:
            return formatEqualsValue(value);
    }
}

export function formatFilterAsSentence(condition: Condition, value: TFilterValue): string {
    if (condition === Condition.Equals) {
        return formatEqualsValue(value);
    }

    const simpleValue: TValue = condition !== Condition.Between ? value as TValue
        : formatInterval(value as IValueInterval);

    const sentence = `${i18next.t(`Components:ValueHelper.${condition}`)} ${simpleValue}`;

    return capitalize(sentence);
}

