export interface RedirectType {
    Error: {
        Code: string;
        Message: string;
        RedirectUrl: string;
        Data: string;
    };
}

export async function establishRedirect(response: Response): Promise<string> {
    const redirectJson = await response.json() as RedirectType;
    if (redirectJson.Error.Code !== "Redirect") {
        throw new Error("Unexpected code from redirect status code: " + redirectJson.Error.Code);
    }
    const establishSessionRespons = await fetch(redirectJson.Error.RedirectUrl + "/api/auth/session/establishSession", {
        method: "POST",
        body: redirectJson.Error.Data,
        mode: "cors",
        credentials: "include"
    });
    if (!establishSessionRespons.ok) {
        throw new Error("Session redirection failed.");
    }
    return redirectJson.Error.RedirectUrl;
}

export async function handleRedirectResponse(response: Response, inNewTab?: boolean): Promise<void> {
    const redirectUrl = await establishRedirect(response);
    if (inNewTab) {
        window.open(redirectUrl, "_blank");
    } else {
        window.location.assign(redirectUrl);
    }
}