import { OData } from "./OData";
import React from "react";
import { ODataContext } from "./ODataProvider";

export interface WithOData {
    oData: OData;
}

// TODO try to improve TS on the 'with' providers, 'as any' still has to be used
// https://medium.com/@martin_hotell/react-refs-with-typescript-a32d56c4d315
export const withOData = <P extends WithOData>(Component: React.ComponentType<P>): React.ComponentType<Omit<P, keyof WithOData>> => {
    return React.forwardRef((props: Omit<P, keyof WithOData>, ref) => (
        <ODataContext.Consumer>
            {(context) => {
                // only render component when oData are ready?
                // if (!context) {
                //     return null;
                // }

                return (
                    <Component oData={context}
                               ref={ref}
                               {...props as P}/>
                );
            }}
        </ODataContext.Consumer>
    )) as unknown as React.ComponentType<Omit<P, keyof WithOData>>;
};