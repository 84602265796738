import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import sessionReducer from "./sessionSlice";
import invitationReducer from "./invitationSlice";
import deviceReducer from "./deviceSlice";
import loginReducer from "./loginSlice";

const store = configureStore({
    reducer: {
        session: sessionReducer,
        device: deviceReducer,
        invitation: invitationReducer,
        login: loginReducer
    }
});

export type TRootLoginState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    TRootLoginState,
    unknown,
    Action<string>
>;
export type TLoginDispatch = typeof store.dispatch;

export default store;
