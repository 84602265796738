import React from "react";
import { IProps as IWriteLineProps, WriteLineWithErrorAndTooltip } from "./WriteLine";
import { StyledNumericWriteLine } from "./NumericWriteLine.styles";
import { INumericValueInput, NumericValueInputBase } from "../numericValueInputBase/NumericValueInputBase";
import { Stepper } from "../numericValueInputBase/Stepper";
import { withFormatter } from "../input/WithFormatter";
import NumberType, { IParserArgs } from "../../../types/Number";
import { WithErrorAndTooltipProps } from "../formError/WithErrorAndTooltip";
import { composeRefHandlers } from "@utils/general";

export const NumericWriteLine = withFormatter<IWriteLineProps & WithErrorAndTooltipProps, number>({
    parser: (value: string, strict?: boolean, args?: IParserArgs) => NumberType.parse(value, strict, { maximumFractionDigits: 3, ...(args ?? {}) }),
    formatter: (val) => NumberType.format(val, { maximumFractionDigits: 3 }),
    isValid: NumberType.isValid
}, WriteLineWithErrorAndTooltip);

export interface IProps extends INumericValueInput, Omit<IWriteLineProps, "value" | "onChange" | "icon"> {
}

export default class NumericWriteLineWithStepper extends NumericValueInputBase<IProps> {
    render() {
        const { props } = this;
        const numberValue = this.getNumberValue();

        return (
            <StyledNumericWriteLine>
                <NumericWriteLine {...props}
                                  passRef={composeRefHandlers(this.inputRef, this.inputWrapperRef)}
                                  value={props.value}
                                  onKeyDown={this.handleKeyDown}/>
                {!this.props.isReadOnly && this.props.showSteppers &&
                <Stepper isDisabled={this.props.isDisabled}
                         onNextMouseDown={this.handleNextMouseDown}
                         onPrevMouseDown={this.handlePrevMouseDown}
                         isNextDisabled={!this.canIncrement(numberValue)}
                         isPrevDisabled={!this.canDecrement(numberValue)}
                         isLight={this.props.isLight}/>
                }
            </StyledNumericWriteLine>
        );
    }
}