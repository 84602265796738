import React from "react";
import { PurchaseDialogPaymentType } from "./PurchaseWizardDialog.utils";
import { ConfirmationDialog } from "../dialog/ConfirmationDialog";
import { useTranslation } from "react-i18next";
import {
    PurchaseFinishedContentWrapper,
    PurchaseFinishedDescription,
    PurchaseFinishedImageWrapper,
    PurchaseFinishedTitle
} from "./PurchaseWizard.styles";
import { FireworksAnim } from "../../animations/Animations";

interface IProps {
    type: PurchaseDialogPaymentType;
    onClose: () => void;
}

const PurchaseFinishedDialog: React.FC<IProps> = (props: IProps) => {
    const { type, onClose } = props;
    const { t } = useTranslation(["Subscriptions"]);
    const isCard = type === PurchaseDialogPaymentType.Card;

    return (
        <ConfirmationDialog onConfirm={onClose}
                            onClose={null}
                            confirmText={t(`Subscriptions:ProductSelection.${isCard ? "PaymentCardFinishedConfirmButton" : "PaymentWireFinishedConfirmButton"}`)}
                            width={"400px"}
                            height={"340px"}
        >
            <PurchaseFinishedContentWrapper>
                <PurchaseFinishedTitle>
                    {t(`Subscriptions:ProductSelection.${isCard ? "PaymentCardFinishedTitle" : "PaymentWireFinishedTitle"}`)}
                </PurchaseFinishedTitle>
                <PurchaseFinishedDescription>
                    {t(`Subscriptions:ProductSelection.${isCard ? "PaymentCardFinishedDescription" : "PaymentWireFinishedDescription"}`)}
                </PurchaseFinishedDescription>
                <PurchaseFinishedImageWrapper>
                    <FireworksAnim/>
                </PurchaseFinishedImageWrapper>
            </PurchaseFinishedContentWrapper>
        </ConfirmationDialog>
    );
};

export default PurchaseFinishedDialog;