import React, { useCallback, useEffect, useRef } from "react";
import { NoResultsText } from "./Search.styles";
import { DogAnim } from "../../animations/Animations";
import { AnimationItem } from "lottie-web";


export enum SearchAnimationState {
    Empty,
    Waiting,
    Searching,
    Finished
}

interface IProps {
    text: string;
    status: SearchAnimationState;
}

type TAnimationLoop = { start: number, end: number };
type TAnimationPosition = number | TAnimationLoop;

function getAnimationTime(status: SearchAnimationState): TAnimationPosition {
    switch (status) {
        case SearchAnimationState.Empty:
            return 0;
        case SearchAnimationState.Finished:
        case SearchAnimationState.Waiting:
            return 24;
        case SearchAnimationState.Searching:
            return { start: 39, end: 58 };
    }
}

function isAnimationLoop(pos: unknown): pos is TAnimationLoop {
    return pos?.hasOwnProperty("start") && pos?.hasOwnProperty("end");
}

function addAnimationState(animation: AnimationItem, status: SearchAnimationState, init = false) {
    const config = getAnimationTime(status);
    let nextStop: number;
    const { currentFrame, firstFrame } = animation;
    const currentStop = firstFrame + currentFrame;

    if (!isAnimationLoop(config)) {
        nextStop = config;
    } else if (config.end > currentStop + 1) {
        nextStop = config.end;
    } else {
        nextStop = config.start;
    }

    if (init) {
        animation.goToAndStop(nextStop, true);
    } else if (currentStop !== nextStop) {
        const dir = nextStop > currentStop ? 1 : -1;
        animation.setDirection(dir);
        animation.playSegments([currentStop, nextStop], true);
    }
}

const SearchIllustration: React.FC<IProps> = (props) => {
    const { text, status } = props;
    const animRef = useRef<AnimationItem>();
    const lastStatus = useRef<SearchAnimationState>();
    lastStatus.current = status;

    const handleEndAnimation = useCallback(() => {
        if (lastStatus.current === SearchAnimationState.Searching) {
            // Loop the searching animation over and over until status don't change to another
            addAnimationState(animRef.current, lastStatus.current);
        }
    }, []);

    const setRef = useCallback((ref: AnimationItem) => {
        animRef.current = ref;
        addAnimationState(ref, status, true);
        ref.addEventListener("complete", handleEndAnimation);
    }, [status, handleEndAnimation]);

    useEffect(() => {
        if (animRef.current) {
            addAnimationState(animRef.current, status);
        }
    }, [status]);

    return (
        <NoResultsText>
            <span>{text}</span>
            <DogAnim animationRef={setRef} loop={false} autoplay={false}/>
        </NoResultsText>
    );
};

export default SearchIllustration;
