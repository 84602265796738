import { ConnectionStatus } from "@components/connectionDialog/ConnectionDialog";
import { SwitchType } from "@components/inputs/switch/Switch";
import { IColumnContent } from "@components/objectList";
import { ColumnTitle, ContentColumn } from "@components/objectList/ListItem.styles";
import { IGetValueArgs, not } from "@components/smart/FieldInfo";
import BindingContext from "@odata/BindingContext";
import { DataBoxEntity, EntitySetName, EntityTypeName } from "@odata/GeneratedEntityTypes";
import { StyledDialogListItem } from "@pages/dataBox/DataBox.styles";
import { getListItemContents, IDataBoxCustomData } from "@pages/dataBox/DataBox.utils";
import i18next from "i18next";
import React from "react";

import { BasicInputSizes, FieldType, LabelStatus } from "../../enums";
import TestIds from "../../testIds";
import { IFormDef } from "../../views/formView/Form";
import { setDefForTesting } from "../getDefByEntityType";
import { IDefinition, IGetDefinition } from "../PageUtils";

const visibleForConnectionStatus = (statuses: ConnectionStatus[]) => (args: IGetValueArgs) => {
    return statuses.includes((args.storage?.getCustomData() as IDataBoxCustomData).connectionStatus);
};

export const getDefinitions: IGetDefinition = (): IDefinition => {
    const form: IFormDef = {
        id: `${EntityTypeName.DataBox}Form`,
        translationFiles: getDefinitions.translationFiles,
        additionalProperties: [{ id: DataBoxEntity.UserName, useForValidation: true }, {
            id: DataBoxEntity.PasswordSetter,
            useForValidation: true
        },
            { id: DataBoxEntity.AllowReadingNewMessages }, { id: DataBoxEntity.CanCheckMessages }, { id: DataBoxEntity.CanReadMessages },
            { id: DataBoxEntity.CanSendMessages }, { id: DataBoxEntity.CompanyAddress },
            { id: DataBoxEntity.CompanyName }, { id: DataBoxEntity.DataBoxId }, { id: DataBoxEntity.DataBoxUserName },
            { id: DataBoxEntity.PasswordId }, { id: DataBoxEntity.DateCreated }, { id: DataBoxEntity.DateLastModified },
            { id: DataBoxEntity.IsActive }, { id: DataBoxEntity.IsActive }, { id: DataBoxEntity.LegalNumber }],
        fieldDefinition: {
            UserName: {
                type: FieldType.Input,
                width: BasicInputSizes.XL,
                isDisabled: not(visibleForConnectionStatus([ConnectionStatus.Disconnected])),
                isVisible: not(visibleForConnectionStatus([ConnectionStatus.ConnectionError]))
            },
            PasswordSetter: {
                type: FieldType.Password,
                label: i18next.t("DataBox:Password"),
                isRequired: true,
                width: BasicInputSizes.XL,
                isDisabled: not(visibleForConnectionStatus([ConnectionStatus.Disconnected])),
                isVisible: not(visibleForConnectionStatus([ConnectionStatus.ConnectionError]))
            },
            AllowReadingNewMessages: {
                type: FieldType.Switch,
                label: "",
                labelStatus: LabelStatus.Hidden,
                defaultValue: false,
                fieldSettings: {
                    type: SwitchType.Icons
                }
            },
            IsActive: {
                defaultValue: true
            },
            [BindingContext.localContext("DataBoxInfo")]: {
                type: FieldType.Custom,
                isVisible: visibleForConnectionStatus([ConnectionStatus.Connected, ConnectionStatus.WaitingForConfiguration]),
                render: (args) => {
                    return <StyledDialogListItem data-testid={TestIds.ObjectListItem} isInDialog>
                        {getListItemContents(args.storage.data.entity, true).map((col: IColumnContent, idx) =>
                                <ContentColumn data-testid={TestIds.ContentColumn} key={idx}>
                                    {col.title && <ColumnTitle>{col.title}</ColumnTitle>}
                                    <div>{col.content}</div>
                                </ContentColumn>)}
                    </StyledDialogListItem>;
                }
            }
        },
        groups: [{
            id: "dataBoxConnection",
            rows: [[
                { id: DataBoxEntity.UserName },
                { id: DataBoxEntity.PasswordSetter },
                { id: BindingContext.localContext("DataBoxInfo") }
            ]]
        }]
    };

    return {
        form,
        entitySet: EntitySetName.DataBoxes
    };
};

getDefinitions.translationFiles = ["DataBox", "Common"];
setDefForTesting("DataBoxDef", getDefinitions);