import { EditSimpleIcon } from "@components/icon";
import { IGetValueArgs } from "@components/smart/FieldInfo";
import { getCommonTableColumnsDefs, withDisplayName } from "@components/smart/GeneralFieldDefinition";
import {
    FilterBarGroup,
    getDefaultFilterGroupDef,
    IFilterGroupDef
} from "@components/smart/smartFilterBar/SmartFilterBar.types";
import { TSmartODataTableStorage } from "@components/smart/smartTable/SmartODataTableBase";
import { ICellValueObject } from "@components/table";
import { DocumentEntity, EntitySetName } from "@odata/GeneratedEntityTypes";
import { BankTransactionTypeCode } from "@odata/GeneratedEnums";
import { IFormatOptions } from "@odata/OData.utils";
import { getCompanyCurrency, isAccountAssignmentCompany } from "@utils/CompanyUtils";
import i18next from "i18next";
import React from "react";

import { IAppContext } from "../../../contexts/appContext/AppContext.types";
import { IconSize, LogicOperator, Sort } from "../../../enums";
import { ColoredText } from "../../../global.style";
import { TRecordAny, TValue } from "../../../global.types";
import { Model } from "../../../model/Model";
import { TableStorage } from "../../../model/TableStorage";
import BindingContext from "../../../odata/BindingContext";
import { formatCurrency } from "../../../types/Currency";
import { ISplitPageTableDef } from "../../../views/table/TableView.utils";
import { getDocumentStatusColumnDefinition } from "../../documents/DocumentCommonDefs";
import { getDocumentStatusFilterDefinition } from "../../documents/DocumentDef";
import {
    enhancedDocumentTypeByDDOPPFilterFormatter,
    getEnhancedDocumentTypeByDDOPPDef
} from "../../documents/proformaInvoices/ProformaInvoice.utils";
import { IDefinition } from "../../PageUtils";
import { DocumentStatusLocalPath, StatusEntities } from "../../reports/CommonDefs";
import { createConditionForPairTable } from "../Pair.utils";
import { LinkStyledBasic, LinkText } from "./BankTransactions.styles";
import { IBankCustomData, isPairingActive, isPairingRowReadOnly } from "./BankTransactions.utils";
import { bankAmountDueFormatter, bankAmountPaidFormatter } from "./BankTransactionsDef";

const handleClickAmount = async (e: React.MouseEvent, storage: Model, item: TRecordAny) => {
    e.preventDefault();

    const row = (storage as TSmartODataTableStorage).tableAPI.getRowsArray()?.find(row => row.dataId == item.Id);

    storage.setCustomData({
        amountDialogRow: row
    });

    storage.refresh();
};

export const docOnlyAmountPaidFormatter = (val: TValue, args: IFormatOptions): ICellValueObject => {
    const storage = args.storage as TSmartODataTableStorage<IBankCustomData>;
    const pairedDocuments = storage.getCustomData().pairedDocuments;
    const id = args.item.Id;
    const pairedLink = pairedDocuments?.[id];
    const amount = pairedLink?.Amount ?? 0;

    const formattedAmount = formatCurrency(amount, getCompanyCurrency(args.context));

    const value = <b><ColoredText color={null}> {formattedAmount} </ColoredText></b>;
    return amountDialogLinkFormatter(value, storage, args.item);
};

export const amountDialogLinkFormatter = (value: React.ReactNode, storage: TSmartODataTableStorage<IBankCustomData>, item: TRecordAny): ICellValueObject => {
    const pairedDocuments = storage.getCustomData().pairedDocuments;
    const id = item.Id;
    const pairedLink = pairedDocuments?.[id];
    const isPaired = !!pairedLink;
    const isPairActionActive = isPairingActive(storage as TableStorage);

    const isReadOnly = isPairingRowReadOnly(pairedLink, storage, storage.getCustomData().rootStorage);
    const Icon = !isReadOnly ? EditSimpleIcon: null;

    return {
        tooltip: "",
        value: (
                <LinkStyledBasic
                        isPaired={isPaired}
                        onClick={(e: React.MouseEvent) => {
                            if (isPaired) {
                                handleClickAmount(e, storage, item);
                            }
                }}>
                {isPaired && isPairActionActive && Icon &&
                    <Icon width={IconSize.XS}/>
                }
                <LinkText>
                    {value}
                </LinkText>
            </LinkStyledBasic>
        )
    };
};

export const amountPaidFormatter = (val: TValue, args: IFormatOptions): ICellValueObject => {
    const value = bankAmountPaidFormatter(val, args)?.value;
    return amountDialogLinkFormatter(value, args.storage as TSmartODataTableStorage<IBankCustomData>, args.item);
};

export const getMasDocPairDef = (context: IAppContext): IDefinition => {
    return getCoreDef(context, BankTransactionTypeCode.Other, false);
};

const getCoreDef = (context: IAppContext, type: BankTransactionTypeCode, useAmount = true) => {
    const hasAccountAssignment = isAccountAssignmentCompany(context);

    const filterBarDef: IFilterGroupDef[] = [{
        ...getDefaultFilterGroupDef(FilterBarGroup.Filters),
        isValueHelp: true,
        defaultFilters: [
            "DocumentType",
            "NumberOurs",
            "NumberTheirs",
            "TransactionAmount",
            "TransactionCurrency",
            "BusinessPartner",
            "ClearedStatus",
            "DateDue",
            "TransactionAmountToReceive",
            "TransactionAmountToPay",
            "SymbolVariable",
            DocumentStatusLocalPath
        ],
        filterDefinition: {
            ...getDocumentStatusFilterDefinition(StatusEntities),
            ...withDisplayName("DocumentType", {
                formatter: enhancedDocumentTypeByDDOPPFilterFormatter
            }),
            NumberOurs: {},
            NumberTheirs: {
                isVisible: type === BankTransactionTypeCode.OutgoingPayment
            },
            TransactionAmount: {},
            ...withDisplayName("BusinessPartner"),
            ...withDisplayName("TransactionCurrency", "Name"),
            "BusinessPartner/Street": {},
            "BusinessPartner/City": {},
            "BusinessPartner/PostalCode": {},
            "BusinessPartner/Country": {},
            "BusinessPartner/FirstName": {},
            "BusinessPartner/LastName": {},
            "BusinessPartner/Email": {},
            "BusinessPartner/PhoneNumber": {},
            TransactionAmountToPay: {},
            TransactionAmountToReceive: {},
            DateDue: {},
            SymbolVariable: {}
        }
    }];


    const table: ISplitPageTableDef = {
        filterBarDef,
        filterOperator: LogicOperator.NonSoloOr,
        filter: (args: IGetValueArgs) => {
            return createConditionForPairTable(args.storage as TableStorage);
        },
        preventStoreVariant: true,
        id: "TransactionDocumentPairingSpecialTable",
        additionalProperties: [{ id: "TransactionCurrency" }, { id: "Currency" }, { id: "TransactionAmountToReceive" }, { id: "TransactionAmountToPay" },
            { id: "ExchangeRatePerUnit" }, { id: "Currency" }, { id: "Amount" }, { id: "TransactionAmount" }, { id: "IsTaxDocument" }, { id: "PostedStatus" }],
        columnDefinition: {
            NumberOurs: {},
            NumberTheirs: {},
            DateDue: {},
            PaymentMethodCode: {},
            SymbolVariable: {},
            "BankAccount/AccountNumber": {},
            "BankAccount/BankCode": {},
            TransactionAmountDue: {
                formatter: bankAmountDueFormatter
            },
            Note: { id: "Note" },
            ...getDocumentStatusColumnDefinition(),
            BusinessPartner: {
                id: "BusinessPartner",
                fieldSettings: {
                    displayName: "Name"
                }
            },
            ...getCommonTableColumnsDefs(),
            ...getEnhancedDocumentTypeByDDOPPDef(),
            DateIssued: {}
        },
        columns: [
            DocumentEntity.DocumentType,
            DocumentEntity.NumberOurs,
            DocumentEntity.TransactionAmountDue,
            ...(type === BankTransactionTypeCode.OutgoingPayment ? [DocumentEntity.NumberTheirs] : []),
            DocumentStatusLocalPath,
            DocumentEntity.SymbolVariable,
            DocumentEntity.BusinessPartner,
            DocumentEntity.Note
        ],
        initialSortBy: [{ id: DocumentEntity.DateCreated, sort: Sort.Desc }],
        title: i18next.t("Document:Pair.PairedDocument")
    };

    if (useAmount) {
        table.columnDefinition = {
            ...table.columnDefinition,
            [BindingContext.localContext("AmountPaid")]: {
                label: i18next.t("Banks:Pairing.AmountPaid"),
                fieldSettings: {
                    disableSort: true
                },
                formatter: amountPaidFormatter
            }
        };

        table.columns.splice(2, 0, BindingContext.localContext("AmountPaid"));
    }

    if (hasAccountAssignment) {
        table.filterBarDef[0].defaultFilters.push("DateAccountingTransaction");
        table.filterBarDef[0].filterDefinition = {
            ...table.filterBarDef[0].filterDefinition,
            DateAccountingTransaction: {}
        };

        table.additionalProperties = [
            ...table.additionalProperties,
            { id: "AccountAssignment" },
            { id: "AccountAssignment/ShortName" },
            { id: "AccountAssignment/CreditAccount/Id" },
            { id: "AccountAssignment/DebitAccount/Id" },
            { id: "AccountAssignment/CreditAccount/Name" },
            { id: "AccountAssignment/CreditAccount/Number" },
            { id: "AccountAssignment/DebitAccount/Name" },
            { id: "AccountAssignment/DebitAccount/Number" },
            { id: "DateAccountingTransaction" }
        ];

        table.columnDefinition = {
            ...table.columnDefinition,
            DateAccountingTransaction: { id: "DateAccountingTransaction" },
            AccountAssignment: {
                additionalProperties: [{ id: "ShortName" }, { id: "CreditAccount/Id" }, { id: "DebitAccount/Id" }, { id: "CreditAccount/Name" }, { id: "CreditAccount/Number" }, { id: "DebitAccount/Name" }, { id: "DebitAccount/Number" }],
                formatter: (val: TValue, args: IFormatOptions) => {
                    const value = args.item.AccountAssignment?.ShortName;
                    return {
                        value: <>{value}</>,
                        tooltip: value
                    };
                }
            }
        };

        table.columns = [
            "DateAccountingTransaction",
            ...table.columns
        ];
    }

    return {
        entitySet: EntitySetName.PayingDocuments,
        table
    };

};

export const getDefinitions = (context: IAppContext, type: BankTransactionTypeCode): IDefinition => {
    return getCoreDef(context, type);
};