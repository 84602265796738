import React from "react";
import {
    StyledRadioListItem
} from "./RadioButtonGroup.styles";
import { IProps as IIconProps } from "../../icon";

interface IProps {
    icon: React.ComponentType<IIconProps>,
    title: string,
    description: string
}

export class RadioListItem extends React.Component<IProps> {
    render() {
        const Icon = this.props.icon;
        return <StyledRadioListItem>
            <Icon width={"60px"} height={"60px"}/>
            <div>
                <h4>{this.props.title}</h4>
                <p>{this.props.description}</p>
            </div>
        </StyledRadioListItem>;
    }
}