import { Button, ButtonGroup } from "@components/button";
import { IDayAction } from "@components/calendar/Calendar.utils";
import { getMonthDays, isSameDay } from "@components/inputs/date/utils";
import { ISmartFieldChange } from "@components/smart/smartField/SmartField";
import { IPrAttendanceEntity, PrAttendanceEntity } from "@odata/GeneratedEntityTypes";
import { PrAttendanceStatusCode } from "@odata/GeneratedEnums";
import { viewSwitchPath } from "@pages/payroll/attendance/Attendance.def";
import { cloneDeep } from "lodash";
import React, { ReactElement } from "react";

import { AppContext } from "../../../contexts/appContext/AppContext.types";
import { withPermissionContext } from "../../../contexts/permissionContext/withPermissionContext";
import { getUtcDayjs } from "../../../types/Date";
import { IFormStorageDefaultCustomData } from "../../../views/formView/FormStorage";
import { FormViewForExtend, IFormViewProps } from "../../../views/formView/FormView";
import {
    getDaySaldo,
    getMonthHolidays,
    getWorkingPattern,
    getWorkingPatternDaysMap,
    parseDayActionsFromData,
    refreshBalance,
    transformWorkIntervalsBeforeSave
} from "./Attendance.utils";

export interface IAttendanceCustomData extends IFormStorageDefaultCustomData {
    selectedDays: Set<number>;
    saldoMap: Record<number, number>;
    hoursFundMap: Record<number, number>;
    actions?: Record<number, IDayAction[]>;
    etag?: string;
    holidays?: Date[];
}

class AttendanceFormView extends FormViewForExtend<IPrAttendanceEntity, IFormViewProps<IPrAttendanceEntity, IAttendanceCustomData>> {
    static contextType = AppContext;
    static defaultProps = {
        title: "Attendance:FormTitle"
    };

    async onAfterLoad(){
        const storage = this.props.storage;
        storage.setDefaultValueByPath(viewSwitchPath);

        const monthDate = getUtcDayjs().set("month", this.entity.Month - 1).set("year", this.entity.Year);

        const holidays = await getMonthHolidays(monthDate, this.entity.Year, this.context.getCompany().CountryCode);
        const workingPattern = getWorkingPattern(this.entity);

        const hoursFundMap = getWorkingPatternDaysMap(workingPattern, monthDate, holidays);

        const monthDays = getMonthDays(monthDate);
        const saldoMap = monthDays.reduce((map: Record<number, number>, day) => {
            const dayIndex = day.get("date");
            const dayHoursFund = hoursFundMap[dayIndex] ?? 0;
            const attendanceDay = this.entity.Days?.find(d => isSameDay(day, getUtcDayjs(d.Date)));
            if (attendanceDay) {
                map[dayIndex] = getDaySaldo(attendanceDay, dayHoursFund);
            } else {
                map[dayIndex] = 0;
            }
            return map;
        }, {});

        const actions = parseDayActionsFromData(this.entity.Days);

        storage.setCustomData({
            holidays,
            saldoMap,
            hoursFundMap,
            actions,
            selectedDays: null
        });
        refreshBalance(storage);

        return super.onAfterLoad();
    }

    handleChange = (e: ISmartFieldChange): void => {
        this.props.storage.handleChange(e);
        this.props.storage.refresh();
    };

    handleUnlock = async (): Promise<void> => {
        this.props.storage.setValueByPath(PrAttendanceEntity.StatusCode, PrAttendanceStatusCode.ToProcess);
        await this.save();
    };

    handleLock = async (): Promise<void> => {
        this.props.storage.setValueByPath(PrAttendanceEntity.StatusCode, PrAttendanceStatusCode.Locked);
        await this.save();
    };

    handleSave = async (): Promise<void> => {
        await this.save();
    };

    onBeforeSave = (): IPrAttendanceEntity => {
        const entity = cloneDeep(this.entity);
        entity.Overtime = Math.max(entity.Balance, 0);
        for (const day of entity.Days) {
            day.Intervals = transformWorkIntervalsBeforeSave(day.Intervals);
        }
        return entity;
    };

    renderButtons = (): ReactElement => {
        const isEditable = this.entity.Status?.Code === PrAttendanceStatusCode.ToProcess;
        const isProcessed = this.entity.Status?.Code === PrAttendanceStatusCode.Processed;
        return <ButtonGroup wrap={"wrap"}>
            {isEditable && <>
                <Button onClick={this.handleSave}
                        isTransparent>{this.props.storage.t("Attendance:Form.Save")}</Button>
                <Button onClick={this.handleLock}>{this.props.storage.t("Attendance:Form.SaveAndLock")}</Button>
            </>}
            {!isEditable && <Button onClick={this.handleUnlock}
                                    isDisabled={isProcessed}>{this.props.storage.t("Attendance:Form.Unlock")}</Button>}
        </ButtonGroup>;
    };
}

export default withPermissionContext(AttendanceFormView);