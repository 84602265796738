import styled from "styled-components/macro";
import { getDisabledStyle, T_HEADER_small, T_PLAIN_big } from "../../../../global.style";
import WriteLine from "../../writeLine/WriteLine";

export const StyledValuePicker = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: fit-content;
    padding: 6px 0;
`;

export const StyledWriteLine = styled(WriteLine)`
    ${T_HEADER_small};
    font-weight: bold;
    color: ${props => props.theme.C_TEXT_dialogue};
`;

const OtherValue = styled.div`
    opacity: 0.5;
    cursor: default;
    ${T_PLAIN_big};
    user-select: none;
`;

export const PrevValue = styled(OtherValue)`       
    margin-top: 16px;
    margin-bottom: 14px;
    
`;

export const NextValue = styled(OtherValue)`
    margin-top: 15px;
    margin-bottom: 12px;
`;

export const StyledIconPressButton = styled.div<{
    isDisabled: boolean;
}>`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    ${props => getDisabledStyle(props.isDisabled, 0.3)};
`;