import React from "react";
import { Circle, StyledMenu, StyledSelect } from "./ColorPicker.styles";
import { KeyName } from "../../../keyName";
import TestIds from "../../../testIds";
import { IconSize } from "../../../enums";
import { IValueInputComponentProps } from "../input";
import { CaretIcon, CheckIcon } from "../../icon";
import { IconDelimiter, IconWrapper } from "../input/Input.styles";
import Popover from "../../popover/Popover";
import { parseToHsl } from "polished";
import { DefaultTheme, withTheme } from "styled-components/macro";
import { IAuditTrailData } from "../../../model/Model";
import { PropsWithTheme } from "../../../theme";
import { ReferenceChildrenProps } from "react-popper";
import { POPOVER_INPUT_SHARP_LEFT_OFFSET_X } from "../../popover/Popover.utils";

export interface IState {
    isOpen?: boolean;
    focusedValue?: string;
}

export interface IProps extends IValueInputComponentProps, PropsWithTheme {
    colors: string[];
    value?: string;
    onChange?: (color: string) => void;
    auditTrailData?: IAuditTrailData;
}

class ColorPicker extends React.PureComponent<IProps, IState> {
    state: IState = {
        focusedValue: this.props.value ?? "",
        isOpen: false
    };
    private colorInputRef: React.RefObject<HTMLInputElement> = React.createRef();

    handleMouseDown = () => {
        if (!this.state.isOpen) {
            // set focus after all mouse events are dealt with
            setTimeout(() => {
                this.colorInputRef.current?.focus();
                this.setState({ isOpen: true });
            });
        }
    };

    handleKeyDown = (e: React.KeyboardEvent) => {
        const colorsArr = this.props.colors,
            index = colorsArr.indexOf(this.state.focusedValue),
            maxLineIndex = 6;
        let nextColor = index,
            linePosition;
        switch (e.key) {
            case KeyName.ArrowRight:
                nextColor = index + 1 === colorsArr.length ? 0 : index + 1;
                break;
            case KeyName.ArrowLeft:
                nextColor = index === 0 ? colorsArr.length - 1 : index - 1;
                break;
            case KeyName.ArrowUp:
                if ((colorsArr.length % maxLineIndex) < (index % maxLineIndex)) {
                    linePosition = colorsArr.length - ((colorsArr.length % maxLineIndex) + maxLineIndex - index);
                } else {
                    linePosition = colorsArr.length - ((colorsArr.length % maxLineIndex) - index);
                }
                if (linePosition < index) {
                    linePosition = index;
                }
                nextColor = index - maxLineIndex < 0 ? linePosition : index - maxLineIndex;
                break;
            case KeyName.ArrowDown:
                linePosition = index % maxLineIndex;
                if (linePosition > index) {
                    linePosition = index;
                }
                nextColor = index + maxLineIndex >= colorsArr.length ? linePosition : index + maxLineIndex;
                break;
            case KeyName.Enter:
                if (this.state.isOpen) {
                    this.select(this.state.focusedValue);
                }
                this.setState({ isOpen: !this.state.isOpen });
                break;
            case KeyName.Space:
                this.setState({ isOpen: !this.state.isOpen });
                break;
            case KeyName.Escape:
                this.setState({ isOpen: false });
                break;
        }

        this.setState({ focusedValue: this.props.colors[nextColor] });
    };

    select = (color: string) => {
        this.setState({ focusedValue: color });
        this.props.onChange?.(color);
        this.forceUpdate();
    };

    isDisabled = () => {
        return this.props.isDisabled || this.props.isReadOnly;
    };

    isHex(color: string) {
        return !!color.match(/^#([0-9a-f]{3}){1,2}$/i);
    }

    // get dark checkIcon for light colors and vice versa
    getCheckIconColor = (color: string) => {
        if (!this.isHex(color)) {
            color = this.props.theme[color as keyof DefaultTheme];
        }
        const HslColor = parseToHsl(color);
        return (HslColor.lightness > 0.5) ? "C_ACT_main" : "C_BG_selected_color";
    };

    handleBlur = () => {
        this.setState({ isOpen: false });
    };

    render() {
        const styledSelect = ({ ref }: ReferenceChildrenProps) => (
            <StyledSelect ref={ref}
                          auditTrailType={this.props.auditTrailData?.type}
                          data-testid={TestIds.ColorPicker}
                          onMouseDown={this.handleMouseDown}
                          isDisabled={this.isDisabled()}
            >
                <Circle color={this.props.value}
                        data-testid={TestIds.Color}/>
                <IconWrapper
                    isActive={this.state.isOpen}>
                    <IconDelimiter/>
                    <CaretIcon width={IconSize.M}/>
                </IconWrapper>
                <input ref={this.colorInputRef}
                       onKeyDown={this.handleKeyDown}
                       onBlur={this.handleBlur}/>
            </StyledSelect>
        );

        return (
            <Popover reference={styledSelect}
                     offsetX={POPOVER_INPUT_SHARP_LEFT_OFFSET_X}
                     isOpen={this.state.isOpen}>
                <StyledMenu data-testid={TestIds.ColorPickerMenu}>
                    {this.props.colors.map(color => {
                        return <Circle key={color}
                                       data-testid={TestIds.Color}
                                       onMouseDown={() => this.select(color)}
                                       selected={color === this.props.value}
                                       focused={color === this.state.focusedValue}
                                       color={color}>
                            {color === this.props.value
                                && <CheckIcon width={IconSize.S} color={this.getCheckIconColor(color)} preventHover/>}
                        </Circle>;
                    })}
                </StyledMenu>
            </Popover>
        );
    }
}

export default withTheme(ColorPicker);