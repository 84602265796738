import { QueryParam } from "../enums";
import * as History from "history";
import { IHistoryState } from "../model/Model";
import { TRecordString } from "../global.types";

interface IGetQueryParameters {
    exclude?: QueryParam[];
    historyLocation?: History.Location;
}

export const getQueryParameters = (args: IGetQueryParameters = {}): Record<QueryParam, string> => {
    const search = new URLSearchParams(args.historyLocation?.search ?? window.location.search);
    if (args.exclude?.length) {
        for (const e of args.exclude) {
            search.delete(e);
        }
    }
    return Object.fromEntries(
        search
    ) as Record<QueryParam, string>;
};

export const removeQueryParam = (history: History.History<IHistoryState>, param: QueryParam, replaceState?: boolean): void => {
    const search = history.location.search;
    const params = new URLSearchParams(search);
    params.delete(param);

    const historyFn = replaceState ? history.replace : history.push;

    historyFn({
        pathname: history.location.pathname,
        search: params.toString()
    });
};

interface ISetQueryParams {
    history: History.History<IHistoryState>;
    newParams: TRecordString;
    replaceState?: boolean;
}

export const setQueryParams = (args: ISetQueryParams): void => {
    const search = args.history.location.search;
    const params = new URLSearchParams(search);
    for (const [key, val] of Object.entries(args.newParams)) {
        params.set(key, val);
    }

    const historyFn = args.replaceState ? args.history.replace : args.history.push;

    historyFn({
        pathname: args.history.location.pathname,
        search: params.toString()
    });
};

export const getRedirectUriQueryParam = (): string => {
    const { pathname, search } = window.location;
    if (!pathname || pathname === "/") {
        return "";
    }
    return `${QueryParam.RedirectUri}=${encodeURIComponent(pathname + (search ?? ""))}`;
};