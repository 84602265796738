import { IGetValueArgs, not, TTemporalDialogSettings } from "@components/smart/FieldInfo";
import { withDisplayName } from "@components/smart/GeneralFieldDefinition";
import { FilterBarGroup, getDefaultFilterGroupDef } from "@components/smart/smartFilterBar/SmartFilterBar.types";
import { ISummaryItem } from "@components/smart/smartSummaryItem/SmartSummaryItem";
import { TFormatterFn } from "@components/smart/smartTable/SmartTable.utils";
import { TCellValue } from "@components/table";
import { createPath } from "@odata/BindingContext";
import {
    EntitySetName,
    EntityTypeName,
    IPrDeductionStatusEntity,
    IPrDeductionTemporalEntity,
    PrDeductionEntity,
    PrDeductionStatusEntity,
    PrDeductionTemporalEntity,
    PrIndividualDeductionEntity
} from "@odata/GeneratedEntityTypes";
import { PrDeductionCalculationTypeCode, PrDeductionStatusCode, PrDeductionTypeCode } from "@odata/GeneratedEnums";
import { getEnumDisplayValue, getEnumNameSpaceName, getEnumSelectItems } from "@odata/GeneratedEnums.utils";
import { IFormatOptions } from "@odata/OData.utils";
import { getTemporalPropertyFieldDefinition } from "@odata/TemporalUtils";
import { getBankAccountFieldsDef, getBankAccountsFieldBaseDef } from "@pages/banks/bankAccounts/BankAccounts.utils";
import { getBusinessPartnerFieldDef } from "@pages/businessPartner/BusinessPartner.utils";
import {
    getNumberOursSummaryDef,
    getNumberRangeFieldDefs,
    NumberRangeAdditionalProperties
} from "@pages/numberRange/NumberRange.utils";
import { getItemBreadCrumbsText, IDefinition } from "@pages/PageUtils";
import {
    DeductionType,
    DEFAULT_EMPLOYEE_PERCENTAGE,
    DEFAULT_EMPLOYER_PERCENTAGE,
    isAmongTypes
} from "@pages/payroll/deduction/Deduction.utils";
import GroupDeductionFormView from "@pages/payroll/deduction/groupDeductions/GroupDeductionFormView";
import IndividualDeductionFormView from "@pages/payroll/deduction/individualDeductions/IndividualDeductionFormView";
import { getCompanyCurrency } from "@utils/CompanyUtils";
import { forEachKey } from "@utils/general";
import { logger } from "@utils/log";
import i18next from "i18next";
import React from "react";
import { DefaultTheme } from "styled-components";

import { IAppContext } from "../../../contexts/appContext/AppContext.types";
import { BasicInputSizes, FieldType, GroupedField, QueryParam, Sort } from "../../../enums";
import { ColoredText } from "../../../global.style";
import { TValue } from "../../../global.types";
import { Model } from "../../../model/Model";
import { getQueryParameters } from "../../../routes/Routes.utils";
import CurrencyType from "../../../types/Currency";
import { getUtcDayjs } from "../../../types/Date";
import { currencyScaleFormatter } from "../../../types/Number";
import { IFormDef } from "../../../views/formView/Form";
import { ISplitPageTableDef } from "../../../views/table/TableView.utils";

export const getStatusColor = (StatusCode: PrDeductionStatusCode): keyof DefaultTheme => {
    let color: keyof DefaultTheme;

    switch (StatusCode) {
        case PrDeductionStatusCode.Deposited:
        case PrDeductionStatusCode.Ongoing:
            color = "C_SEM_text_good";
            break;
        case PrDeductionStatusCode.Suspended:
            color = "C_SEM_text_warning";
            break;
        default:
            color = "C_TEXT_primary";
            break;
    }

    return color;
};

const statusFormatter: TFormatterFn = (val: TValue, args?: IFormatOptions): TCellValue => {
    const title = val as string;
    const color: keyof DefaultTheme = getStatusColor(args.entity.Status?.Code);

    const colorWrapper = <ColoredText color={color}> {val} </ColoredText>;

    return { tooltip: title, value: colorWrapper };
};

export const getCommonDeductionDefinitions = (context: IAppContext, type: DeductionType): IDefinition => {
    const table: ISplitPageTableDef = {
        id: type === DeductionType.GroupDeduction ? `${EntityTypeName.PrGroupDeduction}Table` : `${EntityTypeName.PrIndividualDeduction}Table`,
        filterBarDef: [{
            ...getDefaultFilterGroupDef(FilterBarGroup.Filters),
            defaultFilters: [
                PrDeductionEntity.NumberOurs,
                PrDeductionEntity.Name,
                PrDeductionEntity.Type,
                PrDeductionEntity.Status,
                PrDeductionEntity.DateStart,
                PrDeductionEntity.DateEnd
            ],
            filterDefinition: {
                [PrDeductionEntity.NumberOurs]: {},
                [PrDeductionEntity.Name]: {},
                ...withDisplayName(PrDeductionEntity.Type),
                ...withDisplayName(PrDeductionEntity.Status),
                [PrDeductionEntity.DateStart]: {},
                [PrDeductionEntity.DateEnd]: {}
            },
            isValueHelp: true
        }],
        initialSortBy: [{
            id: type === DeductionType.GroupDeduction ? PrDeductionEntity.NumberOurs : PrIndividualDeductionEntity.Employee,
            sort: Sort.Desc
        }],
        columns: [
            PrDeductionEntity.NumberOurs,
            PrDeductionEntity.Name,
            PrDeductionEntity.Type,
            PrDeductionEntity.Status,
            PrDeductionEntity.DateStart,
            PrDeductionEntity.DateEnd
        ],
        columnDefinition: {
            [PrDeductionEntity.NumberOurs]: {},
            [PrDeductionEntity.Name]: {},
            ...withDisplayName(PrDeductionEntity.Type),
            ...withDisplayName(PrDeductionEntity.Status, "Name", { formatter: statusFormatter }),
            [PrDeductionEntity.DateStart]: {},
            [PrDeductionEntity.DateEnd]: {}
        },
        title: i18next.t(`${type}:TableTitle`)
    };

    const summary: ISummaryItem[] = [{
        id: PrDeductionEntity.Name,
        label: null
    }, {
        ...getNumberOursSummaryDef(type)
    }, {
        id: PrDeductionEntity.TypeCode,
        label: i18next.t(`${type}:Form.Type`),
        formatter: (val, args) => {
            return getEnumDisplayValue(EntityTypeName.PrDeductionType, args.entity.TypeCode as PrDeductionTypeCode);
        },
    }, {
        id: PrDeductionEntity.Status,
        label: i18next.t("IndividualDeduction:Form.Status"),
        additionalProperties: [{ id: PrDeductionStatusEntity.Name }],
        formatter: (val: TValue) => {
            const status = val as IPrDeductionStatusEntity;
            return status?.Name;
        },
        colorFormatter: (val: TValue) => {
            const status = val as IPrDeductionStatusEntity;
            return getStatusColor(status?.Code as PrDeductionStatusCode);
        },
        isVisible: (args: IGetValueArgs) => {
            return !args.storage.data.bindingContext.isNew();
        }
    }];

    const bankAccountFields = getBankAccountsFieldBaseDef();

    const mealVoucherPercentageTemporalDialogSettings: TTemporalDialogSettings = {
        dialogTitle: i18next.t("IndividualDeduction:Form.PlanDeductionTitle"),
        columns: [
            PrDeductionTemporalEntity.PercentageEmployer,
            PrDeductionTemporalEntity.PercentageEmployee
        ]
    };

    const calculationTypeTemporalDialogSettings: TTemporalDialogSettings = {
        dialogTitle: i18next.t("IndividualDeduction:Form.PlanDeductionTitle"),
        columns: [
            PrDeductionTemporalEntity.CalculationType,
            PrDeductionTemporalEntity.Amount
        ]
    };

    const form: IFormDef = {
        id: type === DeductionType.GroupDeduction ? `${EntityTypeName.PrGroupDeduction}Form` : `${EntityTypeName.PrIndividualDeduction}Form`,
        getItemBreadCrumbText: (storage: Model) =>
                getItemBreadCrumbsText(storage, i18next.t(`${type}:NewDeduction`), storage.data.entity?.NumberOurs),
        translationFiles: getCommonDeductionDefinitions.translationFiles,
        isDeletable: true,
        formControl: type === DeductionType.GroupDeduction ? GroupDeductionFormView : IndividualDeductionFormView,
        summary,
        additionalProperties: [
            { id: PrDeductionEntity.TypeCode },
            ...NumberRangeAdditionalProperties,
        ],
        fieldDefinition: {
            [PrDeductionEntity.Name]: {
                width: BasicInputSizes.L
            },
            [PrDeductionEntity.TypeCode]: {
                isVisible: false,
                clearIfInvisible: false,
                defaultValue: (args: IGetValueArgs) => {
                    const key = getQueryParameters()[QueryParam.Type];
                    if (Object.values(PrDeductionTypeCode).includes(key as PrDeductionTypeCode)) {
                        return key;
                    }
                    logger.error("Invalid type code");
                    return null;
                },
                customizationData: {
                    useForCustomization: false
                }
            },
            ...getTemporalPropertyFieldDefinition({
                propName: PrDeductionTemporalEntity.Amount,
                definition: {
                    width: BasicInputSizes.M,
                    isVisible: not(isAmongTypes([PrDeductionTypeCode.Distrainment, PrDeductionTypeCode.Insolvency])),
                    fieldSettings: {
                        formatter: currencyScaleFormatter,
                        unit: ({ storage, bindingContext }): string => {
                            const deductionTemporalEntity = storage.getValue(bindingContext.getParent()) as IPrDeductionTemporalEntity;
                            if (deductionTemporalEntity.CalculationTypeCode !== PrDeductionCalculationTypeCode.Fixed) {
                                return "%";
                            }
                            return CurrencyType.getCurrencyUnit(getCompanyCurrency(context));
                        },
                        temporalDialog: calculationTypeTemporalDialogSettings
                    }
                },
                withOpeningButton: true
            }),
            ...getTemporalPropertyFieldDefinition({
                propName: PrDeductionTemporalEntity.CalculationType,
                definition: {
                    type: FieldType.ComboBox,
                    width: BasicInputSizes.L,
                    isVisible: isAmongTypes([PrDeductionTypeCode.Custom, PrDeductionTypeCode.ChildSupport]),
                    defaultValue: PrDeductionCalculationTypeCode.Fixed,
                    fieldSettings: {
                        items: getEnumSelectItems(EntityTypeName.PrDeductionCalculationType),
                        temporalDialog: calculationTypeTemporalDialogSettings
                    }
                },
                withOpeningButton: true
            }),
            ...getTemporalPropertyFieldDefinition({
                propName: PrDeductionTemporalEntity.PercentageEmployer,
                definition: {
                    width: BasicInputSizes.M,
                    groupedField: GroupedField.MultiStart,
                    isVisible: isAmongTypes([PrDeductionTypeCode.MealVoucher]),
                    defaultValue: DEFAULT_EMPLOYER_PERCENTAGE,
                    fieldSettings: {
                        temporalDialog: mealVoucherPercentageTemporalDialogSettings,
                        unit: args => {
                            // todo: count absolute value from entity
                            return "%";
                        }
                    }
                },
                withOpeningButton: false
            }),
            ...getTemporalPropertyFieldDefinition({
                propName: PrDeductionTemporalEntity.PercentageEmployee,
                definition: {
                    width: BasicInputSizes.M,
                    groupedField: GroupedField.MultiEnd,
                    isVisible: isAmongTypes([PrDeductionTypeCode.MealVoucher]),
                    defaultValue: DEFAULT_EMPLOYEE_PERCENTAGE,
                    fieldSettings: {
                        temporalDialog: mealVoucherPercentageTemporalDialogSettings,
                        unit: args => {
                            return "%";
                        }
                    }
                },
                withOpeningButton: true
            }),
            [PrDeductionEntity.DateStart]: {
                type: FieldType.MonthYear,
                defaultValue: getUtcDayjs().startOf("month").startOf("day").toDate()
            },
            [PrDeductionEntity.DateEnd]: {
                type: FieldType.MonthYear
            },
            ...getNumberRangeFieldDefs("IndividualDeduction"),
            ...getBusinessPartnerFieldDef(false, null, PrDeductionEntity.Recipient),
            ...getBankAccountFieldsDef({
                addPaymentMethod: false,
                areFieldsDisabled: false,
                type: null
            })
        },
        groups: []
    };


    forEachKey(bankAccountFields, (key) => {
        form.fieldDefinition[createPath(PrDeductionEntity.BankAccount, key)] = {
            ...bankAccountFields[key],
            isRequired: isAmongTypes([PrDeductionTypeCode.Distrainment, PrDeductionTypeCode.Insolvency, PrDeductionTypeCode.ChildSupport, PrDeductionTypeCode.Custom]),
            customizationData: {
                ...(bankAccountFields[key].customizationData ?? {}),
                useForCustomization: true
            }
        };
    });

    return {
        entitySet: type === DeductionType.GroupDeduction ? EntitySetName.PrGroupDeductions : EntitySetName.PrIndividualDeductions,
        table,
        form
    };
};

getCommonDeductionDefinitions.translationFiles = [
    "Common",
    "NumberRange",
    "Error",
    "Components",
    getEnumNameSpaceName(EntityTypeName.PrDeductionType),
    getEnumNameSpaceName(EntityTypeName.PrDistrainmentDeductionType),
    getEnumNameSpaceName(EntityTypeName.PrChildSupportDeductionType),
    getEnumNameSpaceName(EntityTypeName.PrDeductionCalculationType)
];