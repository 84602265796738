import React from "react";
import Dialog, { DialogContext } from "../dialog/Dialog";
import AuditTrail, { IBasicProps } from "./AuditTrail";

interface IProps extends IBasicProps {
    onClose: () => void;
}

export default class AuditTrailDialog extends React.Component<IProps> {
    render() {
        return (
            <Dialog
                disableScroll
                width="100%"
                height="100%"
                isEditableWindow
                isOpaqueBusyIndicator
                onConfirm={null}
                onClose={this.props.onClose}>
                <DialogContext.Consumer>
                    {context => {
                        return (
                            <>
                                <AuditTrail
                                    dialogContext={context}
                                    {...this.props}
                                    defaultVersionId={this.props.defaultVersionId}/>
                            </>
                        );
                    }}
                </DialogContext.Consumer>
            </Dialog>
        );
    }
}