import React from "react";

import { BasicSelect, IProps } from "./BasicSelect";


export class Autocomplete extends React.Component<IProps> {
    renderContent = (): React.ReactElement => {
        const sharedProps: IProps = {
            closeOnSelection: true,
            openOnClick: false,
            // there are 2 possibilities.
            // 1/ user often select from the menu, so we preselect the most relevant item to him and shows autocomplete in the input.
            // 2/ user often type the whole value, so we don't preselect anything and shows the input with the value he typed,
            //     suggest alternatives in the menu.
            useAutoSelection: this.props.useAutoSelection ?? true,
            useTypingForward: this.props.useTypingForward ?? true,
            dontDisplayNoDataFound: true,
            allowCreate: true,
            minCharsToOpenMenu: this.props.minCharsToOpenMenu
        };

        return <BasicSelect
            {...this.props}
            {...sharedProps}
            isMultiLine={false}/>;
    };

    render() {
        return this.renderContent();
    }
}
