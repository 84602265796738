import styled from "styled-components/macro";
import Checkbox from "../../inputs/checkbox";
import { ButtonGroup, StyledButton } from "../../button";
import { SmartActionButton } from "../smartFormGroup/SmartActionButton";
import { Label } from "../../inputs/field";
import { VIEW_PADDING_VALUE } from "../../../views/View.styles";
import { PrimaryContent, StyledFastEntryList } from "../../fastEntry/FastEntryList.styles";
import { T_PLAIN_small_hig } from "../../../global.style";
import { ExpandArea, MouseCatcher } from "../../collapsibleContent/CollapsibleContent.styles";

export const StyledSmartFastEntryList = styled.div<{
    showLineNumbers: boolean;
}>`
    position: relative;
    // item indices of FastEntryList are supposed to render inside the view padding
    // but would get hidden by overflow if positioned outside of FastEntryList absolutely 
    // => use negative margin
    ${StyledFastEntryList} {
        left: ${props => props.showLineNumbers ? `-${VIEW_PADDING_VALUE}px` : 0};
    }

    ${PrimaryContent} {
        width: calc(100% - 12px - ${`${VIEW_PADDING_VALUE}px`});
    }

    ${MouseCatcher}, ${ExpandArea} {
        margin-left: ${`${VIEW_PADDING_VALUE}px`};
    }

    ${PrimaryContent} {
        width: calc(100% - 12px - ${VIEW_PADDING_VALUE});
    }

    ${MouseCatcher}, ${ExpandArea} {
        margin-left: ${VIEW_PADDING_VALUE};
    }
`;

export const ConfirmationButtonsWrapper = styled(ButtonGroup)`
    margin-top: 20px;
    margin-right: 12px;
    padding-bottom: 12px;
    justify-content: flex-end;
`;

export const CheckboxStyledMainToggle = styled(Checkbox)`
    position: absolute;
    left: 8px;
    top: -3px;
    z-index: 3;
`;

export const ButtonsFooter = styled.div<{ hasMargin?: boolean; width?: number; }>`
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    row-gap: 15px;
    width: ${props => props.width ? `${props.width}px` : "100%"};

    ${StyledButton}:first-of-type {
        margin-left: ${props => props.hasMargin ? 31 : 0}px;
    }
`;

export const SmartActionButtonStyled = styled(SmartActionButton)<{
    isBefore: boolean;
}>`
    margin-left: ${props => props.isBefore ? "" : "15px"};
    margin-right: ${props => props.isBefore ? "15px" : ""};
`;

export const StyledHeaderLabelAbs = styled(Label) <{
    _opacity: number
}> `
    opacity: ${props => props._opacity};
`;

export const FastEntryLabelWrapper = styled.div `
    position: absolute;
`;

export const StyledErrorStepper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    ${T_PLAIN_small_hig};
`;