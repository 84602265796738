import { isDefined, isNotDefined, roundToDecimalPlaces } from "@utils/general";
import { fixMinusSign } from "@utils/string";
import i18next from "i18next";
import { ICurrencyEntity } from "@odata/GeneratedEntityTypes";
import BindingContext from "../odata/BindingContext";
import { Model } from "../model/Model";

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#parameters
export enum NumberFormatStyle {
    Decimal = "decimal",
    Currency = "currency",
    Percent = "percent",
    Unit = "unit"
}

export interface IParserArgs {
    maximumFractionDigits?: number;
}

export interface IScaleArgs {
    scale: number;
    minorUnit: number;
    isCurrency: boolean;
}

class NumberType {
    /** Parse number based on current locale.
     * Returns NaN if the provided string is wrong and null if no value provided. */
    static parse(value: string, strict?: boolean, args?: IParserArgs): number | null {
        if (isNotDefined(value) || value === "") {
            return null;
        }

        let num = NumberType.getParser().parse(value);
        if (isDefined(args?.maximumFractionDigits) && !isNaN(num)) {
            num = roundToDecimalPlaces(args.maximumFractionDigits, num);
        }

        return num;
    }

    static format(value: number | string, {
        useGrouping = true,
        style = NumberFormatStyle.Decimal,
        maximumFractionDigits = 20, // max possible value to avoid unintentional rounding
        ...rest
    }: Intl.NumberFormatOptions = {}): string {
        if (isNotDefined(value) || value === "" || isNaN(value as number)) {
            return "";
        } else {
            return fixMinusSign(
                new Intl.NumberFormat(i18next.language, {
                    useGrouping,
                    style,
                    maximumFractionDigits, ...rest
                }).format(value as number)
            );
        }
    }

    static isValid(value: number): boolean {
        return isDefined(value) && !isNaN(value) && value <= Number.MAX_SAFE_INTEGER && value >= Number.MIN_SAFE_INTEGER;
    }

    // reusable numberParser instance
    static _parser: NumberParser;

    static getParser(): NumberParser {
        if (!NumberType._parser) {
            NumberType._parser = new NumberParser(i18next.language);
        }
        return NumberType._parser;
    }

    static localeDidChange(): void {
        NumberType._parser = null;
    }
}

export default NumberType;

// localized number parsing https://observablehq.com/@mbostock/localized-number-parsing
export class NumberParser {
    _groupChar: string;
    _group: RegExp;
    _decimalChar: string;
    _decimal: RegExp;
    _numeral: RegExp;
    _minusSign: string;
    _index: (d: string) => string;

    constructor(locale: string) {
        const parts = new Intl.NumberFormat(locale).formatToParts(-12345.6);
        const numerals = [...new Intl.NumberFormat(locale, { useGrouping: false }).format(9876543210)].reverse();
        const index = new Map(numerals.map((d, i) => [d, i]));
        this._groupChar = parts.find(d => d.type === "group").value;
        this._group = new RegExp(`[${this._groupChar}]`, "g");
        this._decimalChar = parts.find(d => d.type === "decimal").value;
        this._decimal = new RegExp(`[${this._decimalChar}]`);
        this._numeral = new RegExp(`[${numerals.join("")}]`, "g");
        this._minusSign = parts.find(d => d.type === "minusSign").value;
        this._index = d => index.get(d).toString();
    }

    parse(string: string | number) {
        string = string.toString().trim()
            .replace(this._group, "")
            // group returns non breaking space (ascii 160) instead of space (32) for cs-CZ
            // just to be sure, replace all white space characters with empty string
            .replace(/\s/g, "")
            .replace(this._decimal, ".")
            .replace(this._numeral, this._index)
            // in a case there would be some special character for minus sign in some locale
            .replace(this._minusSign, "-");

        string = fixMinusSign(string, true);

        return string ? +string : NaN;
    }
}

export const DEFAULT_SCALE_CURRENCY_VALUE = 2;
export const currencyScaleFormatter = (value: number, currency?: ICurrencyEntity): string => {
    const minorUnit = currency?.MinorUnit ?? DEFAULT_SCALE_CURRENCY_VALUE;
    return NumberType.format(value, {
        maximumFractionDigits: minorUnit,
        minimumFractionDigits: minorUnit
    });
};

export const currencyScaleParser = (value: string, currency: ICurrencyEntity): number => {
    const minorUnit = currency?.MinorUnit ?? DEFAULT_SCALE_CURRENCY_VALUE;
    return NumberType.parse(value, true, {
        maximumFractionDigits: minorUnit
    });
};


export const getScale = (bc: BindingContext, storage: Model): IScaleArgs => {
    const prop = bc?.getProperty();
    const defScale = prop?.getScale();
    let currencyBc = prop?.getCurrency() && bc.getParent().navigate(prop.getCurrency());
    if (!currencyBc && bc?.isAnyPartCollection()) {
        // document items doesn't have link to currency, try to get same property from root
        let rootProp;
        try {
            const rootBc = bc.getParent()?.getParent();
            rootProp = rootBc?.navigate(bc.getPath()).getProperty();
            currencyBc = rootProp?.getCurrency() && rootBc.navigate(rootProp?.getCurrency());
        } catch (e) {
            // fall silently
        }
    }

    let scale: number;
    if (currencyBc) {
        scale = storage.getValue(currencyBc)?.MinorUnit;
    }
    return { scale: scale ?? defScale, minorUnit: scale ?? 2, isCurrency: !!currencyBc };
};

export const isNumber = (n: unknown): boolean => {
    return /^-?[\d.]+(?:e-?\d+)?$/.test(n?.toString());
};